import styled from 'styled-components'

export const Container = styled.div`
display: flex;
justify-content: space-between;
border-radius: 4px;
font-size: .875rem;
width: 14rem;
border: 2px solid var(--c6);
`

export const EndIcon = styled.div`
display: flex;
align-items: start;

svg{
    color: var(--Red);
    cursor: pointer;
    margin: .3rem;
    :hover{
        color: var(--Red-hover);
    }
}
`

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
margin-left: 12px;

span{
font-weight: 400;
font-size: 0.875rem;
}
`

export const AvatarTitle = styled.div`
display: flex;
align-items: center;
margin: 9px 0px 9px 13px;
`

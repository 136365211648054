import styled from 'styled-components'
import { Column, Flex } from '../../../../styles/styledGlobal'

export const FlexItems = styled(Flex)`
align-items: center;
input{
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 1rem
}

span{
    margin-right: 1rem;
}
`

export const Titulo = styled.span`
font-weight: 700;
font-size: 1.375rem;
color: var(--a4);
margin-bottom: 2rem;
`

export const CheckTitulo = styled.span`
font-size: 1.375rem;
color: var(--a4);
`

export const ColumnItem = styled(Column)`
margin-bottom: 1.5rem;
padding: 0rem 1.5rem;
`

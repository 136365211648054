/* eslint-disable @typescript-eslint/no-floating-promises */
import { Flex, FormControl, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { iNomeId, iEtapa } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { ObjetoVazio, PropInvalida } from '../../Utils/Helper'
import { ButtonCmp } from '../ButtonCmp'
import { CheckboxCmp } from '../CheckboxCmp'
import { InputCmp } from '../InputCmp'
import { SelectCmp } from '../SelectCmp'
import { IsOffboarding } from '../TimeLineOnboard'
import { WhiteContainer } from '../WhiteContainer'
import { ContainerFlex, LabelTitle } from './styles'

interface iProps {
  onModelChange?: (form: iEtapa) => void
  onGreyAction?: () => void
  onGreenAction?: (etapa: iEtapa) => void
  keyWord: string
  EditEtapa?: iEtapa
  ignoreId?: string
  variant?: 'Box' | 'Etapa'
  isPdi?: boolean
  isDisabled?: boolean
  tipoEtapa?: number
  etapaBase?: iEtapa
}

export const CreateEtapaBox: React.FC<iProps> = ({
  keyWord,
  EditEtapa,
  ignoreId,
  variant,
  isPdi,
  isDisabled,
  tipoEtapa,
  etapaBase,
  onModelChange,
  onGreyAction,
  onGreenAction
}) => {
  const { id, processoId, trilhaId, etapaId, colaboradorId } = useParams<{ id: string, trilhaId: string, processoId: string, etapaId?: string, colaboradorId?: string }>()
  const [InicioEtapa, setInicioEtapa] = useState('')
  const [Model, setModel] = useState<iEtapa>()
  const [Offboarding, setOffboarding] = useState(false)
  const [Etapas, setEtapas] = useState<iNomeId[]>([])
  const [Load, setLoad] = useState(false)
  const { pathname } = useLocation()

  function getEtapas(): void {
    const url = (trilhaId ?? id) ? `Etapa/Select/${trilhaId ?? id ?? ''}?etapaId=${etapaId ?? ''}` : (processoId ?? colaboradorId) ? `EtapaColaborador/Select/${processoId ?? colaboradorId ?? ''}?etapaId=${etapaId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        setEtapas(res.data)
        setLoad(true)
      })
      .catch(err => console.log(err))
  }

  function Atualizar(etapa: iEtapa): void {
    let convert: iEtapa = etapa
    if (!Offboarding) {
      if (InicioEtapa === '1') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoInicio: etapa.tempoInicio
        }
      } else if (InicioEtapa === '0') {
        convert = {
          dataInicio: undefined,
          etapaPai: etapa.etapaPai,
          tempoDuracao: etapa.tempoDuracao
        }
      } else if (InicioEtapa === '2') {
        convert = {
          dataInicio: etapa.dataInicio,
          dataFim: etapa.dataFim,
          tempoDuracao: etapa.tempoDuracao
        }
      } else if (InicioEtapa === '3') {
        console.log('hehe')
      }
    } else {
      if (InicioEtapa === '1') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoAntesDesligamento: etapa.tempoAntesDesligamento
        }
      } else if (InicioEtapa === '2') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoAposDesligamento: etapa.tempoAposDesligamento
        }
      } else if (InicioEtapa === '0') {
        convert = {
          etapaPai: etapa.etapaPai
        }
      } else if (InicioEtapa === '3') {
        convert = {
          dataInicio: etapa.dataInicio,
          dataFim: etapa.dataFim
        }
      }
    }
    if (etapa.manterRespostasIntegracao) convert.manterRespostasIntegracao = etapa.manterRespostasIntegracao
    if (onModelChange !== undefined) {
      onModelChange(convert)
    } else {
      setModel(convert)
    }
  }

  useEffect(() => {
    if (!ObjetoVazio(EditEtapa) && Load) {
      if (!PropInvalida(EditEtapa?.tempoAntesDesligamento)) {
        setInicioEtapa('1')
      } else if (!PropInvalida(EditEtapa?.tempoAposDesligamento)) {
        setInicioEtapa('2')
      } else if (!PropInvalida(EditEtapa?.etapaPai)) {
        setInicioEtapa('0')
      } else if (!PropInvalida(EditEtapa?.tempoInicio)) {
        setInicioEtapa('1')
      }
      setModel(EditEtapa)
    }
  }, [Load, EditEtapa])

  useEffect(() => {
    getEtapas()
    IsOffboarding(!!trilhaId || !!id, id ?? trilhaId ?? processoId ?? colaboradorId ?? '').then(res => setOffboarding(res))
  }, [])

  useEffect(() => {
    if (Etapas.length > 0) {
      setLoad(true)
    }
  }, [Etapas])

  if (variant === 'Etapa') {
    return (
      <Flex className={isDisabled ? 'disabled' : ''} flexDir={'column'} gap={'.5rem'}>
        <h3>Como definir a data da {keyWord}?</h3>
        {!Offboarding && <Flex flexDir={'column'}>
          <RadioGroup onChange={setInicioEtapa} value={InicioEtapa} size='sm'>
            <Stack direction='column' spacing={'1'}>
              {(pathname.includes('Processo') || pathname.includes('VisualizarPerfil')) && !pathname.includes('eNps') && (
                <>
                  <Radio size={'sm'} borderColor={'var(--Gray4)'} value='2'>Data escolhida pelo calendário</Radio>
                  {InicioEtapa === '2' &&
                    <Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'} py={'.25rem'}>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex}>
                        <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                        <InputCmp
                          width={'12rem'}
                          type={keyWord.includes('reunião') || pathname.includes('VisualizarPerfil') ? 'date' : 'datetime-local'}
                          min={etapaBase?.dataFim?.slice(0, 16) ?? new Date().toISOString().slice(0, 16)}
                          max={'2099-12-31T23:59'}
                          value={Model?.dataInicio }
                          OnChange={(e) => { Atualizar({ ...Model, dataInicio: e }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      {<FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                        <InputCmp
                          width={'12rem'}
                          type={keyWord.includes('reunião') || pathname.includes('VisualizarPerfil') ? 'date' : 'datetime-local'}
                          min={Model?.dataInicio}
                          max={'2099-12-31T23:59'}
                          value={Model?.dataFim }
                          OnChange={(e) => { Atualizar({ ...Model, dataFim: e }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      }
                    </Flex>}
                </>
              )}
              <Radio size={'sm'} borderColor={'var(--Gray4)'} value='1'>Data definida após o inicio da trilha (exemplo: a cada 30 dias após o início da trilha)</Radio>
              {InicioEtapa === '1' && <Flex flexDir={'column'} gap={'.5rem'} py={'.25rem'}>
                <ContainerFlex>
                  <Flex as={'span'} alignItems={'center'} gap={'.5rem'}>A partir de
                    <InputCmp
                      type={'number'}
                      min={etapaBase ? ((etapaBase?.tempoDuracao ?? 0) + 1) : 0}
                      placeholder='Digite quantos dias'
                      value={Model?.tempoInicio}
                      OnChange={(e: string) => { Atualizar({ ...Model, tempoInicio: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                    />
                    Dias de trilha</Flex>
                </ContainerFlex>
                <ContainerFlex>
                  <Flex as={'span'} alignItems={'center'} gap={'.5rem'}>Máximo de
                    <InputCmp
                      placeholder='Digite quantos dias'
                      type={'number'}
                      value={Model?.tempoDuracao}
                      min={0}
                      OnChange={(e: string) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                    />
                    Dias de trilha</Flex>
                </ContainerFlex>
              </Flex>}
              <Radio size={'sm'} borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
              {InicioEtapa === '0' &&
                <Flex flexDir={'column'} gap={'.5rem'}>
                  <Flex flexDir={'column'} gap={'.75rem'} mt={'.25rem'}>
                    <SelectCmp value={Model?.etapaPai } OnChange={(e) => Atualizar({ ...Model, etapaPai: e })} title={'Selecione a etapa'}>
                      <option value={''}>Nenhuma</option>
                      {
                        Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                          return (
                            <option key={i} value={e.id}>{e.nome}</option>
                          )
                        })
                      }
                    </SelectCmp>
                    {(!isPdi) &&
                      <Flex fontSize={'.75rem'} fontWeight={'600'} alignItems={'center'} gap={'.5rem'} w={'fit-content'}>Máximo de
                        <InputCmp
                          type={'number'}
                          width={'14rem'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao}
                          OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                        />
                        dias após a liberação da etapa
                      </Flex>
                    }
                  </Flex>
                  {isPdi &&
                    <FormControl>
                      <Flex alignItems={'center'} gap={'.5rem'}>
                        <FormLabel margin={'0'} fontSize={'.75rem'}>Prazo para subir PDI</FormLabel>
                        <InputCmp
                          margin={'0'}
                          width='10rem'
                          borderColor={'var(--Gray4)'}
                          type={'number'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao}
                          OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                        />
                        <FormLabel margin={'0'} fontSize={'.75rem'}>Dias</FormLabel>
                      </Flex>
                    </FormControl>}
                </Flex>}
            </Stack>
          </RadioGroup>
          {tipoEtapa && (tipoEtapa === 1 || tipoEtapa === 3) && <Flex mt={'1rem'}><CheckboxCmp isChecked={Model?.manterRespostasIntegracao} OnChange={(e) => Atualizar({ ...Model, manterRespostasIntegracao: e })}>Duplicar as respostas da {tipoEtapa === 1 ? 'integração' : 'imersão'}</CheckboxCmp></Flex>}
        </Flex>
        }

        {Offboarding && <Flex flexDir={'column'}>
          <RadioGroup onChange={setInicioEtapa} value={InicioEtapa} size='sm'>
            <Stack direction='column'>
              {pathname.includes('Processo') && (
                <>
                  <Radio borderColor={'var(--Gray4)'} value='3'>Data escolhida pelo calendário</Radio>
                  {InicioEtapa === '3' &&
                    (<Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                        <InputCmp
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={new Date().toISOString().slice(0, 16)}
                          max={'2099-12-31T23:59'}
                          value={Model?.dataInicio }
                          OnChange={(e) => { Atualizar({ ...Model, dataInicio: e }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                        <InputCmp
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={Model?.dataInicio}
                          max={'2099-12-31T23:59'}
                          value={Model?.dataFim }
                          OnChange={(e) => { Atualizar({ ...Model, dataFim: e }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                    </Flex>)}
                </>
              )}
              <Radio borderColor={'var(--Gray4)'} value='1'>Datas definidas antes do desligamento do colaborador (exemplo: 10 dias antes do desligamento)</Radio>
              {InicioEtapa === '1' &&
                <Flex flexDir={'column'} gap='.5rem'>
                  <ContainerFlex>
                    <span>
                      <InputCmp
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoAntesDesligamento }
                        OnChange={(e) => Atualizar({ ...Model, tempoAntesDesligamento: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                      />
                      antes do desligamento
                    </span>
                  </ContainerFlex>

                  <ContainerFlex>
                    <span>
                      <InputCmp
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoDuracao }
                        OnChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                      />
                      de duração
                    </span>
                  </ContainerFlex>
                </Flex>
              }

              <Radio borderColor={'var(--Gray4)'} value='2'>Datas definidas após o desligamento do colaborador (exemplo: 15 dias após o desligamento)</Radio>
              {InicioEtapa === '2' &&
                <Flex flexDir={'column'} gap='.5rem'>
                  <ContainerFlex>
                    <span>
                      <InputCmp
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoAposDesligamento }
                        OnChange={(e) => Atualizar({ ...Model, tempoAposDesligamento: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                      />
                      após o desligamento
                    </span>
                  </ContainerFlex>

                  <ContainerFlex>
                    <span>
                      <InputCmp
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoDuracao }
                        OnChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                      />
                      de duração
                    </span>
                  </ContainerFlex>
                </Flex>
              }

              <Radio borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
              {InicioEtapa === '0' && <Flex flexDir={'column'} gap={'.5rem'}>
                <h4>Selecione o período de liberação da pesquisa</h4>
                <SelectCmp value={Model?.etapaPai } OnChange={(e) => Atualizar({ ...Model, etapaPai: e })}>
                  <option value={''}>Selecione uma etapa</option>
                  {
                    Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                      return (
                        <option key={i} value={e.id}>{e.nome}</option>
                      )
                    })
                  }
                </SelectCmp>
                <Flex fontSize={'.75rem'} fontWeight={'600'} alignItems={'center'} gap={'.5rem'} w={'fit-content'}>Máximo de
                  <InputCmp
                    type={'number'}
                    width={'14rem'}
                    placeholder='Digite quantos dias'
                    value={Model?.tempoDuracao }
                    OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                  />
                  dias após a liberação da etapa
                </Flex>
              </Flex>}
            </Stack>
          </RadioGroup>
        </Flex>
        }
      </Flex>
    )
  } else {
    return (
      <>
        <WhiteContainer>
          <Flex justifyContent={'space-between'} height={'25rem'} flexDir={'column'}>
            <Flex flexDir={'column'}>
              <LabelTitle>Como definir a data da {keyWord}?</LabelTitle>
              {!Offboarding && <Flex flexDir={'column'}>
                <RadioGroup onChange={setInicioEtapa} value={InicioEtapa} size='sm'>
                  <Stack direction='column'>
                    {pathname.includes('Processo') && !pathname.includes('eNps') && (
                      <>
                        <Radio borderColor={'var(--Gray4)'} value='2'>Data escolhida pelo calendário</Radio>
                        {InicioEtapa === '2' &&
                          <Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
                            <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                              <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                              <InputCmp
                                width={'15rem'}
                                type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                                min={new Date().toISOString().slice(0, 16)}
                                max={'2099-12-31T23:59'}
                                value={Model?.dataInicio }
                                OnChange={(e) => { Atualizar({ ...Model, dataInicio: e }) }}
                                isInvalid={false}
                              />
                            </FormControl>
                            {
                              (
                                <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                                  <FormLabel fontSize={'.75rem'} mb={'0'} fontWeight={'600'}>{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                                  <InputCmp
                                    width={'15rem'}
                                    type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                                    min={Model?.dataInicio}
                                    max={'2099-12-31T23:59'}
                                    value={Model?.dataFim }
                                    OnChange={(e) => { Atualizar({ ...Model, dataFim: e }) }}
                                    borderColor='var(--Gray4)'
                                    isInvalid={false}
                                  />
                                </FormControl>
                              )
                            }
                          </Flex>}
                      </>
                    )}
                    <Radio value='1'>Data definida após o inicio da trilha (exemplo: a cada 30 dias após o início da trilha)</Radio>
                    {InicioEtapa === '1' && <div>
                      <ContainerFlex>
                        <Flex as={'span'} alignItems={'center'} gap={'.5rem'}>A partir de
                          <InputCmp
                            min={0}
                            placeholder='Digite quantos dias'
                            value={Model?.tempoInicio }
                            OnChange={(e) => { Atualizar({ ...Model, tempoInicio: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                          />
                          Dias de trilha</Flex>
                      </ContainerFlex>
                      <ContainerFlex>
                        <Flex as={'span'} alignItems={'center'} gap={'.5rem'}>Máximo de
                          <InputCmp
                            borderColor={'var(--Gray4)'}
                            min={0}
                            placeholder='Digite quantos dias'
                            value={Model?.tempoDuracao }
                            OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                          />
                          Dias de trilha</Flex>
                      </ContainerFlex>
                    </div>}
                    <Radio value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
                    {InicioEtapa === '0' && <Flex flexDir={'column'} gap={'.5rem'}>
                      <h4>Selecione o período de liberação da pesquisa</h4>
                      <SelectCmp value={Model?.etapaPai} OnChange={(e) => Atualizar({ ...Model, etapaPai: e })}>
                        <option value={''}>Selecione uma etapa</option>
                        {
                          Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                            return (
                              <option key={i} value={e.id}>{e.nome}</option>
                            )
                          })
                        }
                      </SelectCmp>
                      <Flex fontSize={'.75rem'} fontWeight={'600'} alignItems={'center'} gap={'.5rem'} w={'fit-content'}>Máximo de
                        <InputCmp
                          type={'number'}
                          width={'14rem'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao}
                          OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                        />
                        dias após a liberação da etapa</Flex>
                    </Flex>}
                  </Stack>
                </RadioGroup>
              </Flex>
              }

              {Offboarding && <Flex flexDir={'column'}>
                <RadioGroup onChange={setInicioEtapa} value={InicioEtapa} size='sm'>
                  <Stack direction='column'>
                    <Radio borderColor={'var(--Gray4)'} value='1'>Datas definidas antes do desligamento do colaborador (exemplo: 10 dias antes do desligamento)</Radio>
                    {InicioEtapa === '1' &&
                      <Flex flexDir={'column'} gap='.5rem'>
                        <ContainerFlex>
                          <span>
                            <InputCmp
                              margin={'0 .5rem'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoAntesDesligamento }
                              OnChange={(e) => Atualizar({ ...Model, tempoAntesDesligamento: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                            />
                            antes do desligamento
                          </span>
                        </ContainerFlex>

                        <ContainerFlex>
                          <span>
                            <InputCmp
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoDuracao}
                              OnChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                            />
                            de duração
                          </span>
                        </ContainerFlex>
                      </Flex>
                    }

                    <Radio borderColor={'var(--Gray4)'} value='2'>Datas definidas após o desligamento do colaborador (exemplo: 15 dias após o desligamento)</Radio>
                    {InicioEtapa === '2' &&
                      <Flex flexDir={'column'} gap='.5rem'>
                        <ContainerFlex>
                          <span>
                            <InputCmp
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoAposDesligamento }
                              OnChange={(e) => Atualizar({ ...Model, tempoAposDesligamento: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                            />
                            após o desligamento
                          </span>
                        </ContainerFlex>

                        <ContainerFlex>
                          <span>
                            <InputCmp
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoDuracao }
                              OnChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) })}
                            />
                            de duração
                          </span>
                        </ContainerFlex>
                      </Flex>
                    }

                    <Radio borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
                    {InicioEtapa === '0' && <Flex flexDir={'column'} gap={'.5rem'}>
                      <h4>Selecione o período de liberação da pesquisa</h4>
                      <SelectCmp value={Model?.etapaPai } OnChange={(e) => Atualizar({ ...Model, etapaPai: e })}>
                        <option value={''}>Selecione uma etapa</option>
                        {
                          Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                            return (
                              <option key={i} value={e.id}>{e.nome}</option>
                            )
                          })
                        }
                      </SelectCmp>
                      <Flex fontSize={'.75rem'} fontWeight={'600'} alignItems={'center'} gap={'.5rem'} w={'fit-content'}>Máximo de
                        <InputCmp
                          borderColor={'var(--Gray4)'}
                          type={'number'}
                          width={'14rem'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao }
                          OnChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e)) ? undefined : parseInt(e) }) }}
                        />
                        dias após a liberação da etapa
                      </Flex>
                    </Flex>}
                  </Stack>
                </RadioGroup>
              </Flex>
              }
            </Flex>
          </Flex>
        </WhiteContainer>
        <Flex marginTop={'1rem'} justifyContent={'end'} gap={'1rem'}>
          <ButtonCmp onClick={onGreyAction} VarColor='c6'>Voltar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onGreenAction !== undefined ? onGreenAction(Model ?? {}) : ''}>{EditEtapa !== undefined ? 'Atualizar' : 'Criar'} Etapa</ButtonCmp>
        </Flex>
      </>
    )
  }
}

import styled from 'styled-components'

export const Container = styled.table`
margin-top: 1rem;
border-spacing: 0 .5rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        color: var(--a2);
        font-weight: 600;
        font-size: 1rem;
    }
}

tbody{

    tr{
        background-color: var(--c2);
    }

    tr,th{
        border-collapse: collapse;
        padding: .5rem;
    }

    th:first-child{
        display: flex;
        align-items: center;
        
        & > div:last-child{
            display: flex;
            flex-direction: column;
            align-items: start;

            span{
                font-weight: 400;
                font-size: 1rem;
            }

            strong{
                font-size: 14px;
                font-weight: 700;
            }
        }
    }

    th:last-child{
        & > div > div{
            padding:.2rem;
            border-radius: 4px;
            height: fit-content;
            display: flex;
            align-items: center;
        }

        & > div div:first-child{
            border: 1px solid var(--Blue2);
            color: var(--Blue2);
            margin-right: .5rem;
            &:hover{
                background-color: var(--Blue2);
                color: white;
                cursor: pointer;
            }
        }
 
        & > div div:last-child{
            & > div {
                border: 1px solid var(--Green2);
            color: var(--Green2);
            &:hover{
                background-color: var(--Green2);
                color: white;
                cursor: pointer;            
            }        
            }
        } 
    }
}
`

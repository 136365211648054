import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: 0.25rem;
height: 6rem;
color: var(--c1);
background: var(--Azul);
min-width: 7.813rem;
padding: 0 .35rem 0 .25rem;
box-shadow: var(--SombraBtns);
gap: 0 .5rem;
h4{
    font-size: 1.125rem;
    line-height: 1rem;
    font-weight: 700;
}
span{
    font-size: .875rem;
}
div{
    width: min-content;
}
`

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
background: var(--AzulOpacoBg);
padding: .5rem 1rem .5rem 1rem;
width: 100%;

span{
    font-size: .813rem;
    font-weight: 600;
}
`

export const TabelaPersonalizada = styled.table`
    gap: 0;
    border-collapse: collapse;
    border: 1px solid #b9b9b9;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    box-shadow: 0 0 0 1px #00000050;
    width: 100%;
    -webkit-border-radius: 16px;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    td {
        border: 1px solid #b9b9b9;
        text-align: center;
        padding-top: .5rem;
        padding-bottom: .5rem;
        min-width: 6.5rem;
    }
    thead > tr {
        background-color: var(--Azul);
        color: #fff;
        th {
            border-left: 1px solid #b9b9b9;
            border-right: 1px solid #b9b9b9;
            :nth-child(2){
                border-left: 1px solid #b9b9b9;
                border-right: 1px solid #b9b9b9;
            }
            :nth-child(3){
                border-left: 1px solid #b9b9b9;
                border-right: 1px solid #b9b9b9;
            }
        }
    }
    tbody > tr {
        :first-child{
            background-color: #b9b9b980;
            td {
                border: 1px solid #00000050;
            }
        }
        td {
            :first-child {
                background-color: #b9b9b950;
                border: 1px solid #00000050;
            }
        }
            td{  
                :nth-child(7) {
                    font-size: 12px;
                }
                :nth-child(8) {
                    font-size: 14px;
                }
        }
    }
`

import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { iFileData, iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { parseJwt } from '../../../services/token'
import { PDFImersao } from '../../Imersao/Components/Pdf'
import { TextoImersao } from '../../Imersao/Components/Texto'
import { VideoImersao } from '../../Imersao/Components/Video'
import { Body } from '../../Layouts/Body'
import { ModalSucess } from '../Components/ModalSucess'
import { FlexCenter, Main, NavElipse } from './styles'

type navProps ={
  interacaoId: string
  etapaId: string
}

interface iRespostaChecklist {
  itemId: string
  respostas: iChekIntegracao[]
}

interface iChekIntegracao {
  texto: string
  check: Boolean
  observacaoIson: Boolean
  observacao?: string
  arquivo?: iFileData
}

export const ImersaoColaborador: React.FC = () => {
  const { etapaId, interacaoId } = useParams<navProps>()
  const nav = useNavigate()
  const { pathname } = useLocation()

  const [Switch, setSwitch] = useState(0)

  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])
  const [PopUpisOpen, setPopUpisOpen] = useState(false)
  const [Respostas, setRespostas] = useState<iRespostaChecklist[]>([])

  function GetImersoesById(): void {
    appApi.get(`Imersao/${interacaoId as string}?colabId=${pathname.includes('Visualizar') ? parseJwt().cid : ''}`)
      .then(response => {
        setImersao(response.data.itensIntegracao)
      }).catch(err => console.error(err))
  }

  function onRequestClosePopUp(): void {
    setPopUpisOpen(false)
    nav('/Colaborador')
  }

  function ConcluirEtapaImersao(): void {
    appApi.patch(`EtapaColaborador/${etapaId as string}/Imersao/Concluir`, Respostas)
      .then(res => {
        setPopUpisOpen(true)
      })
      .catch(err => console.log(err))
  }

  function UpdateRespostaChecklist(resposta: iChekIntegracao[], itemId: string): void {
    const copy = [...Respostas]
    const pos = copy.findIndex(e => e.itemId === itemId)
    if (pos !== -1) {
      copy[pos].respostas = resposta
      setRespostas(copy)
    } else {
      setRespostas(oldArray => [...oldArray,
        {
          itemId: itemId,
          respostas: resposta
        }])
    }
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <Body>
      <Main>
        <ModalSucess
          isOpen={PopUpisOpen}
          onRequestClose={onRequestClosePopUp}
          onConfirm={onRequestClosePopUp}
          Titulo={'Imersão cultural'}
        />
        {
          Imersao.map((e: iItemIntegracao, i) => {
            if (e.tipo === 1) {
              return (
                <VideoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={UpdateRespostaChecklist}
                  readonly={pathname.includes('Visualizar')}
                />
              )
            } else if (e.tipo === 2) {
              return (
                <TextoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={UpdateRespostaChecklist}
                  readonly={pathname.includes('Visualizar')}
                />
              )
            } else if (e.tipo === 3) {
              return (
                <PDFImersao
                  key={i}
                  isVisible={i === Switch}
                  UpdateResposta={UpdateRespostaChecklist}
                  readonly={pathname.includes('Visualizar')}
                  item={e}
                />
              )
            }
            return null
          })
        }

        <NavElipse>
          {
            Imersao.map((e: iItemIntegracao, i) => {
              return (
                <div
                  key={i}
                  title={e.titulo}
                  onClick={() => setSwitch(i)}
                  style={{ background: Switch === i ? 'var(--DegradeAzul)' : '' }}>
                </div>
              )
            })
          }
        </NavElipse>

        <FlexCenter>
          {
            Switch === 0
              ? <ButtonCmp onClick={() => nav('/Colaborador')} VarColor='c6'>Voltar</ButtonCmp>
              : <ButtonCmp onClick={() => setSwitch(Switch - 1)} VarColor='c6'>Voltar</ButtonCmp>
          }

          {
            Switch === Imersao.length - 1
              ? (pathname.includes('Visualizar') ? <ButtonCmp onClick={() => nav(-1)} VarColor='Rosa'>Encerrar visualização</ButtonCmp> : <ButtonCmp onClick={ConcluirEtapaImersao} VarColor='Green2'>Finalizar</ButtonCmp>)
              : <ButtonCmp onClick={() => setSwitch(Switch + 1)} VarColor='Green2'>Próximo</ButtonCmp>
          }
        </FlexCenter>
      </Main>
    </Body>
  )
}

import { FormControl, FormLabel, Textarea } from '@chakra-ui/react'
import { iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iDiscursivaProps{
  questao: iQuestaoFlexivel
}

export const Discursiva: React.FC<iDiscursivaProps> = ({ questao }) => {
  return (
        <FormControl>
            <FormLabel fontSize={'xl'}>{questao.enunciado}</FormLabel>
            <Textarea
              isDisabled
              placeholder='Digite seu comentário aqui'
              borderColor={'var(--Gray4)'}
            />
        </FormControl>
  )
}

import { Flex } from '@chakra-ui/layout'
import { MetaButton } from '../../../Buttons/MetaButton'
import { ModalBase } from '../../../ModalBase'

interface iProps {
  onReenviar: () => void
  onRequestClose: () => void
}

export const ModalReenviarPrimeiroAcesso: React.FC<iProps> = ({ onReenviar, onRequestClose }) => {
  return (
    <ModalBase
      isOpen
      onClose={onRequestClose}
    >
      <Flex p={'1.5rem'} flexDir={'column'} gap='1rem'>
        <h2 style={{ color: 'var(--secundario5)', textAlign: 'center' }}>Atenção!</h2>
        <Flex flexDir={'column'} gap='.5rem' width={'25.5rem'}>
          <strong>Ao reenviar o primeiro acesso a senha do colaborador será resetada novamente. Deseja reenviar mesmo assim?</strong>
          <Flex justifyContent={'end'} gap={'1rem'} mt={'.5rem'}>
            <MetaButton bg='c5' size='md' onClick={onRequestClose}>Cancelar</MetaButton>
            <MetaButton bg='v2' size='md' onClick={onReenviar}>Continuar</MetaButton>
          </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { iItemIntegracao } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Checklist } from '../../Components/Checklist'
import { PDFImersao } from '../../Components/Pdf'
import { TextoImersao } from '../../Components/Texto'
import { VideoImersao } from '../../Components/Video'
import { FlexCenter, NavElipse } from './styles'

type ParamProps = {
  imersaoId: string
}

export const PrevisualizarImersao: React.FC = () => {
  const { imersaoId } = useParams<ParamProps>()
  const nav = useNavigate()

  const [Switch, setSwitch] = useState(0)
  const [IsLoading, setIsLoading] = useState(false)

  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])

  function GetImersoesById(): void {
    setIsLoading(true)
    appApi.get(`ImersaoExterna/${imersaoId as string}/Item`)
      .then(response => {
        setImersao(response.data)
      }).catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <Body>
      <LoadingContainer linhas={22} loading={IsLoading}>
        {
          Imersao.map((e: iItemIntegracao, i) => {
            if (e.tipo === 0) {
              return (
                <Checklist
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                />
              )
            } else if (e.tipo === 1) {
              return (
                <VideoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={() => {}}
                  preview
                />
              )
            } else if (e.tipo === 2) {
              return (
                <TextoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={() => {}}
                  preview
                />
              )
            } else if (e.tipo === 3) {
              return (
                <PDFImersao
                  key={i}
                  isVisible={i === Switch}
                  item={e}
                  UpdateResposta={() => {}}
                  preview
                />
              )
            }
            return null
          })
        }

        <NavElipse>
          {
            Imersao.map((e: iItemIntegracao, i) => {
              return (
                <div
                  key={i}
                  title={e.titulo}
                  onClick={() => setSwitch(i)}
                  style={{ background: Switch === i ? 'var(--DegradeAzul)' : '' }}>
                </div>
              )
            })
          }
        </NavElipse>
      </LoadingContainer>

      <FlexCenter>
        {
          Switch === 0
            ? <ButtonCmp onClick={() => nav(-1)} VarColor='c6'>Voltar</ButtonCmp>
            : <ButtonCmp onClick={() => setSwitch(Switch - 1)} VarColor='c6'>Voltar</ButtonCmp>
        }

        {
          Switch === Imersao.length - 1
            ? <ButtonCmp onClick={() => nav(-1)} VarColor='Green2'>Finalizar pré-visualização</ButtonCmp>
            : <ButtonCmp onClick={() => setSwitch(Switch + 1)} VarColor='Green2'>Próximo</ButtonCmp>
        }
      </FlexCenter>
    </Body>
  )
}

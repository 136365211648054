/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Text, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, useToast, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../components/Timeline'
import { BoxMultiText } from '../../PesquisaPercepcao/EnviarPesquisa/Components/BoxMultiText'
import { ModalTag } from '../../PesquisaPercepcao/EnviarPesquisa/Components/ModalTag'
import { FindUser } from '../../../components/FindBox'
import { iNomeId } from '../../../interfaces'
import { iPDIPesquisa } from '../../PesquisaPercepcao/EnviarPesquisa'
import { ButtonTag } from '../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'
import { EditorTexto } from '../../../components/EditorTexto/Index'
import { iPDIPermissoes } from '../../PDI/Criar'
import { InputCmp } from '../../../components/InputCmp'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import LoadingContainer from '../../../components/LoadingContainer'

interface iModel {
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
  pdi?: iPDIPesquisa
  reenviarDias?: number[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export const TextosIndicativa: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)

  const { pesquisaId } = useParams()
  const toast = useToast()

  const textPadraoEmail = `<p>Olá, [NOME DO RESPONDENTE]!</p><p>Você está recebendo o convite para o preenchimento da pesquisa de ${pathname.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'perpepção'} do(a) [NOME DO AVALIADO].</p><p>A pesquisa é simples e rápida.</p><p>O prazo para preenchimento é até o dia [DATA FIM]</p><p>[Link]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar o responsável.</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const textPadraoAbertura = `<p>Bem-vindo a pesquisa de ${pathname.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'percepção'} do [NOME DO AVALIADO].</p><br/> <p>A pesquisa é rápida e fácil, mas seja sincero e atento para o melhor desenvolvimento do avaliado.</p><br/> <p>Contamos com sua participação!</p>`
  const textPadraoEncerramento = '<p>Obrigado pela participação!</p>'
  const textPadraoPDI = '<p>Olá, [NOME DO RESPONSAVEL]!</p><p>A pesquisa do(a) [NOME DO AVALIADO] foi concluída e você já pode iniciar o cadastro do PDI para o desenvolvimento dele!</p><p>É muito simples, basta acessa o link com os resultados do avaliado e Clicar no botão Adicionar PDI, criar as ações especificando o que, por que e como ele deve trabalhar cada uma das competências definidas.</p><p>Você tem até o dia [DATA FIM PDI] para cadastrar o PDI.</p><p>Link dos resultados da pesquisa do(a) [NOME DO AVALIADO]:</p><p>[LINK]</p><p>Se tiver qualquer dúvida pode entrar em contato com nosso RH.</p><p>Atenciosamente,</p><p>Equipe [NOME DA EMPRESA]</p>'
  const [PermissoesPDI, setPermissoesPDI] = useState<iPDIPermissoes>({ colaboradorFazerProprioPDI: false, gestorAtribuirPDI: false })

  const pdiDefault: iPDIPesquisa = {
    dias: 1,
    destinatarioEmail: 1,
    notificacaoCadastro: [],
    textoCriacao: textPadraoPDI,
    pdiGerado: false
  }

  const [Model, setModel] = useState<iModel>({
    textosAbertura: [{ texto: textPadraoAbertura, participantes: [0, 1, 2, 3, 4, 6] }],
    textosEncerramento: [{ texto: textPadraoEncerramento, participantes: [0, 1, 2, 3, 4, 6] }],
    textosConvite: [{ texto: textPadraoEmail, participantes: [0, 1, 2, 3, 4, 6] }]
  })

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [OutroPDI, setOutroPDI] = useState(false)
  const [EnviarPdi, setEnviarPdi] = useState(false)

  const [isLoading, setIsLoading] = useState(true)
  const [IsSending, setIsSending] = useState(false)

  const [ReenviarPesquisa, setReenviarPesquisa] = useState(false)

  function UpdateTexto(): void {
    appApi.put(`PesquisaIndicativa/Textos/${pesquisaId as string}`, Model)
      .then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaIndicativa/${pesquisaId as string}/Textos`)
      .then((res) => {
        setModel(res.data)
        if (res.data.pdi?.destinatarioEmail === 3) {
          setOutroPDI(true)
        }
        if (res.data.reenviarDias != null) {
          setReenviarPesquisa(true)
        }
        if (res.data.pdi != null) {
          setEnviarPdi(true)
        }
      })
      .catch((err) => { console.log(err) })
  }

  function handleContinuar(enviar: boolean): void {
    setIsSending(true)
    appApi.put(`PesquisaIndicativa/${pesquisaId as string}/Enviar?enviar=${enviar}`, Model)
      .then(() => {
        toast({
          title: `Pesquisa ${enviar ? 'programada' : 'salva'} com sucesso!`,
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (enviar) {
          navigate(`${pathname.includes('Percepcao') ? '/Jornada/Percepcao/Gestao' : '/Desempenho/AnaliseDesempenho/Gestao'}`)
        } else {
          navigate(-1)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsSending(false))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function getPermissoesPDI(): void {
    appApi.get('PDI/Permissoes?outsidePdiPage=true')
      .then(res => setPermissoesPDI(res.data))
      .catch(err => console.log(err))
  }

  function UpdateDestinatarioPDI(value: string): void {
    setModel((prevModel) => ({
      ...prevModel,
      pdi: {
        ...prevModel.pdi ?? pdiDefault,
        destinatarioEmail: parseInt(value),
        responsavelId: value !== '3' ? undefined : prevModel.pdi?.responsavelId
      }
    }))
    if (value === '3') {
      setOutroPDI(true)
      return
    }
    setOutroPDI(false)
  }

  function handleNotificacaoCadastroChange(value: number): void {
    const copy = [...Model.pdi?.notificacaoCadastro ?? pdiDefault.notificacaoCadastro]

    if (copy.some(e => e === value)) {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: copy.filter(e => e !== value),
          notificadoId: undefined
        }
      })
    } else {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: [...copy, value]
        }
      })
    }
  }

  function SelecionarEnvioDias(value: string): void {
    if (Model.reenviarDias === null) {
      const copy = []
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else if (Model.reenviarDias?.find(e => e.toString() === value) === undefined) {
      const copy = [...Model.reenviarDias ?? []]
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else {
      const copy = [...Model.reenviarDias ?? []].filter(e => e.toString() !== value)
      setModel({ ...Model, reenviarDias: copy })
    }
  }

  useEffect(() => {
    getPesquisa()
    getColaboradores()
    getPermissoesPDI()
  }, [])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />

      <TimelineCriarPesquisa color={['var(--Azul)', 'var(--Azul)', 'var(--Azul)', 'var(--Rosa)']} />
      <Flex flexDir={'column'} gap={'.75rem'}>
        <LoadingContainer linhas={20} loading={IsSending}>
          <h1>Enviar convites</h1>
          <WhiteContainer>
            <Flex flexDir={'column'} gap='1rem'>
              {pathname.includes('Desempenho') &&
                <Flex flexDir={'column'}>
                  <Flex direction={'column'}>
                    <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'}>
                      Adicionar fluxo de PDI após a pesquisa encerrar?</Text>
                    <RadioGroup value={EnviarPdi ? '1' : '2'} defaultValue='1' size={'sm'} mb={'.25rem'} onChange={() => { setEnviarPdi(!EnviarPdi); if (EnviarPdi) { setModel({ ...Model, pdi: undefined }); setOutroPDI(false) } }}>
                      <Stack spacing={3} direction='row'>
                        <Radio value='1'>
                          <Text fontSize={'.875rem'} ml={'-.125rem'}>Sim</Text>
                        </Radio>
                        <Radio value='2'>
                        <Text fontSize={'.875rem'} ml={'-.125rem'}>Não</Text>
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                  {(EnviarPdi) &&
                    <Flex gap={'.5rem'} mt={'.25rem'}>
                      <Flex flexDir={'column'} gap={'.25rem'} justifyContent={'space-between'}>
                        <Text fontSize={'.875rem'} fontWeight={'700'}>Tempo para preencher PDI após pesquisa encerrada</Text>
                        <FormControl alignItems={'center'} as={Flex} gap={'.5rem'}>
                          <InputCmp
                            type={'number'}
                            width={'5rem'}
                            min={1}
                            value={Model?.pdi?.dias ?? 1}
                            OnChange={(e) => setModel((prevState) => ({
                              ...prevState,
                              pdi: {
                                ...prevState.pdi ?? pdiDefault,
                                dias: parseInt(e)
                              }
                            }))}
                          />
                          <Text fontSize={'.875rem'}>Dias</Text>
                        </FormControl>
                      </Flex>

                      <Box h={'100%'} my={'auto'} bg={'var(--c5)'} w={'.125rem'} mx={'.25rem'}></Box>

                      <Flex flexDir={'column'} gap={'.25rem'} justifyContent={'space-between'}>
                        <Text fontSize={'.875rem'} fontWeight={'700'}>
                          Quem irá inserir o PDI do avaliado?</Text>
                        <RadioGroup size={'sm'} value={Model?.pdi?.destinatarioEmail?.toString()} defaultValue='1'
                          onChange={UpdateDestinatarioPDI}>
                          <Stack spacing={3} direction='row'>
                            {PermissoesPDI.gestorAtribuirPDI && <Radio value='0'><Text fontSize={'.875rem'} ml={'-.125rem'}>Gestor</Text></Radio>}
                            {!PermissoesPDI.gestorAtribuirPDI && <ElementoTooltipCmp label='Caso queira designar o gestor para adicionar o PDI para seus subordinados, modifique nas configurações da empresa' bg={'var(--Azul)'}><Flex alignItems={'center'} opacity={'0.5'}><Radio disabled value={Model.pdi?.destinatarioEmail}><Text fontSize={'.875rem'} ml={'-.125rem'}>Gestor</Text></Radio></Flex></ElementoTooltipCmp>}
                            {PermissoesPDI.colaboradorFazerProprioPDI && <Radio value='1'><Text fontSize={'.875rem'} ml={'-.125rem'}>Colaborador</Text></Radio>}
                            {!PermissoesPDI.colaboradorFazerProprioPDI && <ElementoTooltipCmp label='Caso queira designar o colaborador para adicionar o seu próprio PDI, modifique nas configurações da empresa' bg={'var(--Azul)'}><Flex alignItems={'center'} opacity={'0.5'}><Radio disabled value={Model.pdi?.destinatarioEmail}><Text fontSize={'.875rem'} ml={'-.125rem'}>Colaborador</Text></Radio></Flex></ElementoTooltipCmp>}
                            <Radio value='2'><Text fontSize={'.875rem'} ml={'-.125rem'}>RH</Text></Radio>
                            <Radio value='3'><Text fontSize={'.875rem'} ml={'-.125rem'}>Outro</Text></Radio>
                            <ElementoTooltipCmp label={!OutroPDI ? 'Selecione a opção "Outro" para adicionar um usuário' : ''}>
                              <Flex flexDir={'column'} pointerEvents={!OutroPDI ? 'none' : 'auto'} opacity={!OutroPDI ? 0.5 : 1}>
                                <FindUser
                                  lista={Colaboradores}
                                  onChoice={(e) => setModel((prevState) => ({
                                    ...prevState,
                                    pdi: {
                                      ...prevState.pdi ?? pdiDefault,
                                      responsavelId: e
                                    }
                                  }))}
                                  minWInput={'11rem'}
                                  placeholder='Digite o nome do participante'
                                  selecionado={Model.pdi?.responsavelId}
                                />
                              </Flex>
                            </ElementoTooltipCmp>
                          </Stack>
                        </RadioGroup>
                        {((!PermissoesPDI.colaboradorFazerProprioPDI && Model?.pdi?.destinatarioEmail === 1) || (!PermissoesPDI.gestorAtribuirPDI && Model?.pdi?.destinatarioEmail === 0)) && <Text color={'var(--Red)'} mt={'.5rem'}>Cuidado! O responsável por inserir o PDI do avaliado não terá permissão de criá-lo, mude as configurações da empresa ou o responsável por inserir o PDI</Text>}
                      </Flex>

                      <Box h={'100%'} my={'auto'} bg={'var(--c5)'} w={'.125rem'} mx={'.25rem'} maxW={'.125rem'}></Box>

                      <Flex flexDir={'column'} gap={'.25rem'}>
                        <Text fontSize={'.875rem'} fontWeight={'700'}>
                          Quem deve receber notificações de cadastro de PDI em atraso?</Text>
                        <Stack spacing={3} direction='row'>
                          <CheckboxCmp
                            isChecked={Model.pdi?.notificacaoCadastro.includes(0)}
                            OnChange={() => handleNotificacaoCadastroChange(0)}
                          >
                            Gestor
                          </CheckboxCmp>
                          <CheckboxCmp
                            isChecked={Model.pdi?.notificacaoCadastro.includes(2)}
                            OnChange={() => handleNotificacaoCadastroChange(2)}
                          >
                            RH
                          </CheckboxCmp>
                          <CheckboxCmp
                            isChecked={Model.pdi?.notificacaoCadastro.includes(3)}
                            OnChange={() => handleNotificacaoCadastroChange(3)}
                          >
                            Outro:
                          </CheckboxCmp>
                          <ElementoTooltipCmp label={!Model?.pdi?.notificacaoCadastro.includes(3) ? 'Selecione a opção "Outro" para adicionar um usuário' : ''}>
                            <Flex flexDir={'column'} pointerEvents={!Model?.pdi?.notificacaoCadastro.includes(3) ? 'none' : 'auto'} opacity={!Model?.pdi?.notificacaoCadastro.includes(3) ? 0.5 : 1}>
                              <FindUser
                                lista={Colaboradores}
                                onChoice={(e) => setModel((prevState) => ({
                                  ...prevState,
                                  pdi: {
                                    ...prevState.pdi ?? pdiDefault,
                                    notificadoId: e
                                  }
                                }))}
                                minWInput={'11rem'}
                                placeholder='Digite o nome do participante'
                                selecionado={Model?.pdi?.notificadoId}
                              />
                            </Flex>
                          </ElementoTooltipCmp>
                        </Stack>
                      </Flex>
                    </Flex>
                  }
                </Flex>
              }
              <Flex direction={'column'}>
                <FormControl>
                  <FormLabel mb={'.25rem'} fontSize={'.875rem'} fontWeight={'700'}>Reenviar convites para quem não respondeu</FormLabel>
                  <RadioGroup value={ReenviarPesquisa ? '1' : '2'} size={'sm'} defaultValue='1' onChange={() => { if (ReenviarPesquisa) { setModel({ ...Model, reenviarDias: [] }) }; setReenviarPesquisa(!ReenviarPesquisa) }}>
                    <Stack spacing={3} direction='row'>
                      <Radio value='1'>
                        Sim
                      </Radio>
                      <Radio value='2'>
                        Não
                      </Radio>
                    </Stack>
                  </RadioGroup>

                </FormControl>
                {ReenviarPesquisa &&
                  <Stack mt={'.75rem'} direction={'row'} gap='.5rem'>
                    <CheckboxCmp
                        isChecked={Model?.reenviarDias?.includes(5)}
                        OnChange={() => { SelecionarEnvioDias('5') }
                        }
                      >
                        5 dias antes de encerrar a pesquisa
                      </CheckboxCmp>

                      <CheckboxCmp
                        isChecked={Model?.reenviarDias?.includes(3)}
                        OnChange={() => { SelecionarEnvioDias('3') }
                        }
                      >
                        3 dias antes de encerrar a pesquisa
                      </CheckboxCmp>

                      <CheckboxCmp
                        isChecked={Model?.reenviarDias?.includes(2)}
                        OnChange={(e) => { SelecionarEnvioDias('2') }
                        }
                      >
                        2 dias antes de encerrar a pesquisa
                      </CheckboxCmp>

                      <CheckboxCmp
                        isChecked={Model?.reenviarDias?.includes(1)}
                        OnChange={() => { SelecionarEnvioDias('1') }
                        }
                      >
                        1 dia antes de encerrar a pesquisa
                      </CheckboxCmp>
                  </Stack>
                }
              </Flex>
            </Flex>
          </WhiteContainer>

          <WhiteContainer>
            <BoxMultiText
              titulo='Texto do convite para preenchimento da pesquisa'
              descricao='Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa.'
              textoPadrao={textPadraoEmail}
              openModalTag={() => setModalTagIsOpen(true)}
              model={Model.textosConvite}
              onChange={(e) => setModel({ ...Model, textosConvite: e })}
              onSave={UpdateTexto}
            />
          </WhiteContainer>

          <WhiteContainer>
            <BoxMultiText
              titulo='Texto de abertura da pesquisa'
              descricao='O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.'
              textoPadrao={textPadraoAbertura}
              openModalTag={() => setModalTagIsOpen(true)}
              model={Model.textosAbertura}
              onChange={(e) => setModel({ ...Model, textosAbertura: e })}
              onSave={UpdateTexto}
            />
          </WhiteContainer>

          <WhiteContainer>
            <BoxMultiText
              titulo='Texto de encerramento da pesquisa'
              descricao='Esse texto será apresentado quando o respondente terminar de responder uma pesquisa.'
              textoPadrao={textPadraoEncerramento}
              openModalTag={() => setModalTagIsOpen(true)}
              model={Model.textosEncerramento}
              onChange={(e) => setModel({ ...Model, textosEncerramento: e })}
              onSave={UpdateTexto}
            />
          </WhiteContainer>

          {(EnviarPdi) && (
            <WhiteContainer>
              <Flex justifyContent={'space-between'}>
                <div style={{ maxWidth: '55rem', marginBottom: '1rem' }}>
                  <h3>Texto para criação do PDI</h3>
                  <small>Esse é o texto que será enviado ao e mail do responsável por subir o PDI do avaliado</small>
                </div>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>

              <EditorTexto isLoading={isLoading} initialValue={Model.pdi?.textoCriacao ?? textPadraoPDI}
                onChange={(e: string) => setModel((prevState) => ({
                  ...prevState,
                  pdi: {
                    ...prevState.pdi ?? pdiDefault,
                    textoCriacao: e
                  }
                }))} />
              <Flex justifyContent={'space-between'} marginTop='1rem'>
                <Flex gap={'1rem'}>
                  <ButtonCmp
                    VarColor='c6'
                    onClick={() => {
                      setIsLoading(true)
                      setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi ?? pdiDefault,
                          textoCriacao: textPadraoPDI
                        }
                      }))
                      setTimeout(() => setIsLoading(false), 1000)
                    }}
                  >Restaurar texto padrão</ButtonCmp>
                </Flex>
              </Flex>
            </WhiteContainer>)
          }
        </LoadingContainer>

        <Flex justifyContent={'end'} gap={'.5rem'} mt={'.325rem'}>
          <ButtonCmp VarColor='Rosa'
            onClick={() => handleContinuar(false)}
          >
            Salvar e voltar
          </ButtonCmp>
          <ButtonCmp
            onClick={() => handleContinuar(true)}
            VarColor='Green2'
            rightIcon={<FaChevronRight />}
          >Finalizar criação</ButtonCmp>
        </Flex>
      </Flex>
    </Body>
  )
}

import { ButtonProps } from '@chakra-ui/button'
import { FaFileCsv } from 'react-icons/fa'
import { ButtonCmp } from '../../ButtonCmp'

export const CsvButton: React.FC<ButtonProps> = ({ ...rest }) => {
  return (
    <ButtonCmp {...rest} VarColor='Green2' px={'1.5rem'} fontSize={'12px'} pt={'.1rem'} leftIcon={<FaFileCsv />}>
      Baixar CSV
    </ButtonCmp>
  )
}

import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: white;
border-radius: 0.25rem;
height: 4.625rem;
width: 9.25rem;
gap: .625rem;
box-shadow: var(--SombraBtns);
`

export const TabelaAvaliacoes = styled.table`
    border-collapse: collapse;
    width: 100%;
    tr {
        :first-child {
            padding: 0;
        }
    }
    tbody > tr {
        background-color: #EDF2F7;
        :first-child {
            padding: 0;
        }
        :nth-child(even) {
            background: #fff;
        }
    }
    td {
        font-size: 13px;
        padding: .75rem;
        :first-child {
            text-align: start;
            padding: .75rem 1.5rem .75rem 1.5rem; 
        }
        svg {
            font-size: 22px;
        }
        text-align: center;
    }
    th {
        color: var(--Gray2);
        font-size: 12px;
        padding: 1rem 1.5rem 1rem 1.5rem; 
        :first-child {
            text-align: start;
            padding: .75rem 1.5rem .75rem 1.5rem; 
        }
        text-align: center;
    }
`

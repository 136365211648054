/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
import { Divider, Flex, FormControl, Grid, Img, Text } from '@chakra-ui/react'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { Body } from '../../Layouts/Body'
import { Tabela } from './Components/Tabela'
import { OutlineContainer, StatusContainer, TipoPesquisa } from './styles'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useAuth } from '../../../contexts/AuthContext'
import { FaChartBar, FaChartPie, FaCheckCircle, FaClipboardList, FaEnvelope, FaPeopleArrows, FaRegWindowRestore, FaStreetView, FaTachometerAlt, FaUser, FaUserCheck, FaUsers } from 'react-icons/fa'
import { parseJwt } from '../../../services/token'
import LoadingContainer from '../../../components/LoadingContainer'
import { SelectCmp } from '../../../components/SelectCmp'
import { PropInvalida } from '../../../Utils/Helper'
import { OutlineColumn, MediaGeralContainer, BoxColor } from '../../Desempenho/Dashboard/styles'
import { GaugeChart } from './Components/GaugeChart'
import IconeStake from '../../../assets/IconeStakeAzul.svg'
import IconeCritico from '../../../assets/IconeCritico.svg'
import IconeOutros from '../../../assets/IconeOutrosAzul.svg'
import IconeLider from '../../../assets/IconeLider.svg'
import { iGestaoPesquisaInterna } from '../PesquisaInterna/Gestao'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { iGraficoGestaoPesquisa } from '../../RH/GestaoOnboard'
import { PieChart } from './Components/PieChart'

interface StatusPesquisaPercepcao {
  totalEmAndamento: number
  totalFinalizadas: number
  emElaboracao: number
  totalGeral: number
}

interface StatusTurnOver {
  turnOver: number
  totalOnboarding: number
  totalDesligados: number
  totalProcessos: number
}

interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  tipo: string
  inicio?: string
  progresso: number
  concluido: boolean
}

interface iProximoEvento {
  id: string
  dataInicio: string
  nomeParticipante: string
  assunto: string
}

interface iModel {
  statusPesquisaPercepcao: StatusPesquisaPercepcao
  statusPesquisaInterna: StatusPesquisaPercepcao
  statusTurnOver: StatusTurnOver
  pesquisasPercepcaoEmAndamento: number
  pesquisasInternaEmAndamento: number
  proximosEventos: iProximoEvento[]
  tabela: iDadoTabela[]
  pesquisaseNps: iNomeId[]
  quantidade?: number
  pesquisas: iNomeId[]
  pesquisasInterna: iGestaoPesquisaInterna[]
  trilhaBox: iTrilhaBox
}

interface iTrilhaBox {
  graficoEtapa: iGraficoGestaoPesquisa
  qtdTrilhaDesenvolvimento: number
  qtdDesligados: number
  emAndamento: number
  concluidas: number
  interrompidas: number
  atrasados: number
}

interface iResumoResultado {
  id: string
  nomePesquisa: string
  statusCategorias: iStatusCategoria[]
  countGeral: iCountGeral
  mediaStakeholders: iMediaCount
  mediaAutoavaliacao: iMediaCount
  maiorMedia: iMediaCount
  menorMedia: iMediaCount
  departamentos: iNomeId[]
  indicativa: boolean
}

interface iStatusCategoria {
  total: number
  totalRespondido: number
  nome: string
}

interface iMediaCount {
  nome: string
  media: number
}

interface iCountGeral {
  totalAvaliacoes: number
  totalRespostas: number
  totalAvaliadores: number
  totalAdesao: number
}

interface iTheme {
  textColor: string
  buttonCmpColor: string
}

export const DashboardJornada: React.FC = () => {
  const { permitirFuncao } = useAuth()
  const nav = useNavigate()

  const theme: iTheme = {
    buttonCmpColor: 'var(--Blue2)',
    textColor: 'var(--Azul)'
  }

  const [Model, setModel] = useState<iModel>()
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingTabela, setIsLoadingTabela] = useState(false)
  const [IsLoadingDesligados, setIsLoadingDesligados] = useState(false)
  const [Resumo, setResumo] = useState<iResumoResultado>()
  const [PesquisaId, setPesquisaId] = useState<string>('')
  const [DepartamentoId, setDepartamentoId] = useState<string>('')
  const [Periodo, setPeriodo] = useState<string>('')

  function GetDashhboard(texto: string, pagina: number, tamanho: number, isTabela?: boolean, periodo?: string): void {
    if (periodo) {
      setIsLoadingDesligados(true)
    } else if (!isTabela) {
      setIsLoading(true)
    } else {
      setIsLoadingTabela(true)
    }
    appApi.get(`ProcessoOnboard/Jornada/Dashboard/?busca=${texto}&pagina=${pagina}&tamanho=${tamanho}&periodo=${periodo ?? Periodo}`)
      .then(res => {
        setModel(res.data)
        if (res.data.pesquisaSelecionada && !isTabela) {
          getResumo(res.data.pesquisaSelecionada, '')
        }
        setPeriodo(res.data.periodo)
      })
      .catch(err => console.log(err))
      .finally(() => { setIsLoading(false); setIsLoadingTabela(false); setIsLoadingDesligados(false) })
  }

  function getIcon(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={22} style={{ marginRight: '.25rem' }} />
      case 'Pares': return <FaPeopleArrows size={22} style={{ marginRight: '.25rem' }} />
      case 'Lider': return <Img height={'2rem'} marginRight={'.25rem'} src={IconeLider} />
      case 'Liderados': return <FaUsers size={22} style={{ marginRight: '.25rem' }} />
      case 'Outros': return <img src={IconeOutros} width={22} height={22} style={{ marginRight: '.25rem' }}/>
      default: return <FaUserCheck size={22} style={{ marginRight: '.25rem' }} />
    }
  }

  function getIconAvaliados(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={40} />
      case 'Pares': return <FaPeopleArrows size={40} />
      case 'lider': return <Img height={'4rem'} marginRight={'.5rem'} src={IconeCritico} width={44}/>
      case 'Liderados': return <FaUsers size={40} />
      case 'Outros': return <img src={IconeOutros} width={44}/>
      default: return <FaUserCheck size={40} />
    }
  }

  function getResumo(pesqusaiId: string, departamentoId: string): void {
    setIsLoading(true)
    setPesquisaId(pesqusaiId)
    setDepartamentoId(departamentoId)
    setResumo(undefined)
    appApi.get(`AvaliacaoPesquisa/${pesqusaiId}/ResumoResultadoGeral?departamentoId=${departamentoId}`)
      .then(res => {
        setResumo(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    GetDashhboard('', 0, 25)
  }, [])

  return (
        <Body>
            {Model && <Flex gap={'1rem'} m={'1rem 0'}>
              <Flex padding={'1rem'} alignItems={'center'} justifyContent='center' background={'var(--c1)'} borderRadius={'.25rem'} flexDir={'column'} w={'100%'} gap={'1rem'} boxShadow={'var(--SombraBackground)'}>
                <Flex justifyContent={'center'}>
                    <h2>Trilhas de onboarding</h2>
                </Flex>
                <Flex gap={'2rem'} alignItems={'center'}>
                    {Model?.trilhaBox.graficoEtapa && !(Model.trilhaBox?.graficoEtapa.emAndamento === 0 && Model.trilhaBox?.graficoEtapa.concluidos === 0 && Model.trilhaBox?.graficoEtapa.atrasados === 0) && <Flex border={'1px solid var(--c5)'} flexDir={'column'} bg={'var(--c1)'} gap={'.25rem'} alignItems={'center'} justifyContent={'center'} borderRadius={'.325rem'} p={'1rem'}>
                      <Flex marginBottom={'.5rem'} justifyContent={'center'}>
                        <Text fontWeight={700}>Status das etapas</Text>
                      </Flex>
                      <Flex>
                        <PieChart
                          id='etapa'
                          grafico={Model.trilhaBox?.graficoEtapa}
                          variant={'etapa'}
                          theme={theme.textColor}
                        />
                      </Flex>
                    </Flex>}
                    <Divider orientation='vertical' borderColor={'var(--c4)'} opacity={'1'} height={'11rem'}></Divider>
                    <Flex flexDir={'column'} justifyContent='center' fontWeight={600} fontSize={'14px'}>
                      <Text fontWeight={700} fontSize={'1rem'} mb={'.325rem'}>Status das trilhas</Text>
                      <Text color={'var(--Red)'}>{Model?.trilhaBox.emAndamento ?? 0} Trilhas de onboarding em andamento</Text>
                      <Text color={'var(--Green2)'}>{Model?.trilhaBox.concluidas ?? 0} Trilhas de onboarding concluídas</Text>
                      <Text color={'var(--Rosa)'}>{Model?.trilhaBox.concluidas ?? 0} Trilhas de onboarding atrasadas</Text>
                      <Text color={'var(--Azul)'}>{Model?.trilhaBox.interrompidas ?? 0} Trilhas de onboarding interrompidas</Text>
                    </Flex>
                </Flex>
              </Flex>
              <Flex flexDir={'column'} gap={'1rem'} w={'40%'}>
                <Flex boxShadow={'var(--SombraBackground)'} borderRadius={'.25rem'} background={'var(--c1)'} height={'100%'} p={'1rem'} alignItems={'center'} justifyContent={'space-between'} flexDir='column' gap={'1rem'}>
                  <LoadingContainer linhas={4} loading={IsLoadingDesligados}>
                    <h3>Colaboradores desligados</h3>
                    <Flex gap={'.5rem'} fontSize={'.875rem'} alignItems={'center'}>
                      Selecionar período:
                      <SelectCmp OnChange={(e) => { setPeriodo(e); GetDashhboard('', 0, 25, undefined, e) }} value={Periodo}>
                        <option value={'ano'}>Último ano</option>
                        <option value={'mes'}>Último mês</option>
                        <option value={'trimestre'}>Último trimestre</option>
                        <option value={'semestre'}>Último semestre</option>
                      </SelectCmp>
                    </Flex>
                    <Flex gap={'.5rem'} lineHeight={'16px'} alignItems={'center'}>
                      <Text fontSize={'15px'}>
                        Total de desligados nesse período:
                        <Text as={'span'} fontSize={'18px'} ml={'.5rem'} fontWeight={700} color={'var(--Azul)'}>
                        {Model?.trilhaBox.qtdDesligados}
                        </Text>
                      </Text>
                    </Flex>
                  </LoadingContainer>
                </Flex>
                <Flex boxShadow={'var(--SombraBackground)'} borderRadius={'.25rem'} background={'var(--c1)'} height={'100%'} p={'1rem'} alignItems={'center'} gap={'.5rem'} justifyContent={'space-between'} flexDir={'column'}>
                  <h3>Trilha de desenvolvimento</h3>
                  <Text fontSize={'16px'} w={'13rem'}>
                    Colaboradores em trilha:
                    <Text as={'span'} fontSize={'19px'} ml={'.5rem'} fontWeight={700} color={'var(--Azul)'}>
                      {Model?.trilhaBox.qtdTrilhaDesenvolvimento}
                    </Text>
                  </Text>
                  <div></div>
                </Flex>
              </Flex>
            </Flex>}
            <WhiteContainer>

                  {/* <Flex gap={'.75rem'}>
                    {permitirFuncao('Jornada', '1on1') && <ButtonCmp size={'lg'} VarColor='Green2' onClick={() => nav('/Jornada/NovaReuniaoOne/Dashboard/Individual')}>Reunião 1:1</ButtonCmp>}
                    {permitirFuncao('Jornada', 'imersão-cultural') && <ButtonCmp size={'lg'} VarColor='Green2' onClick={() => nav('/Jornada/Imersao')}>Imersão cultural</ButtonCmp>}
                    {permitirFuncao('Jornada', 'trilha') && <ButtonCmp size={'lg'} VarColor='Green2' onClick={() => nav('/Jornada/Onboard?tipo=0')}>Trilha de onboarding</ButtonCmp>}
                  </Flex> */}
                <LoadingContainer linhas={3} loading={IsLoading} my={'1rem'}>
                  <Flex gap={'.75rem'}>
                    <OutlineContainer style={{ justifyContent: 'space-between', flex: '1' }} theme={'AzulOpaco'}>
                      <Flex justifyContent={'center'}>
                          <StatusContainer>
                              <FaStreetView size={62}/>
                              <Flex marginLeft={'1rem'} flexDir={'column'}>
                                  {
                                      (Model) && (
                                          <h3>{(Model?.statusPesquisaPercepcao.totalGeral)} Pesquisas de percepção</h3>
                                      )
                                  }
                                  <Text fontSize={'.875rem'} fontWeight={'700'} mb={'.5rem'}>Desenvolvidas no último ano</Text>
                                  {(permitirFuncao('Jornada', 'pesquisa-percepcao') || parseJwt().adm === 'True') &&
                                    <Flex gap={'.5rem'}>
                                        <ButtonCmp onClick={() => nav('/Jornada/Percepcao/Gestao')} VarColor='Rosa'>
                                            Gestão de pesquisas
                                        </ButtonCmp>
                                      {parseJwt().adm === 'True' &&
                                        <ButtonCmp onClick={() => nav('/Jornada/Percepcao/SelecionarModelo')} VarColor='Green2'>
                                            Criar nova pesquisa
                                        </ButtonCmp>
                                      }
                                    </Flex>
                                  }
                              </Flex>
                              <hr />
                              <Flex flexDir={'column'} gap={'.125rem'}>
                                  <TipoPesquisa onClick={() => nav('/Jornada/Percepcao/Gestao?filtro=0')}>{Model?.statusPesquisaPercepcao.emElaboracao ?? 0} Pesquisas em elaboração <FaRegWindowRestore size={14}/></TipoPesquisa>
                                  <TipoPesquisa onClick={() => nav('/Jornada/Percepcao/Gestao?filtro=1')}>{Model?.statusPesquisaPercepcao.totalEmAndamento ?? 0} Pesquisas em andamento <FaRegWindowRestore size={14}/></TipoPesquisa>
                                  <TipoPesquisa onClick={() => nav('/Jornada/Percepcao/Gestao?filtro=2')}>{Model?.statusPesquisaPercepcao.totalFinalizadas ?? 0} Pesquisas finalizadas <FaRegWindowRestore size={14}/></TipoPesquisa>
                              </Flex>
                          </StatusContainer>
                      </Flex>
                    </OutlineContainer>
                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'.75rem'}>
                      <Flex onClick={() => nav('/Jornada/PesquisaInterna/Gestao')} alignItems={'center'} justifyContent={'center'} gap={'.5rem'} bg={'var(--Green2)'} color={'var(--c1)'} padding={'1rem'} borderRadius={'.25rem'} cursor={'pointer'} boxShadow={'var(--SombraBtns)'} _hover={{ filter: 'brightness(1.05)', transform: 'translateY(-3px)', transition: 'all 0.2s ease-in-out' }}>
                        <FaClipboardList size={52}/>
                        <Flex flexDir='column'>
                            <h2>{Model?.pesquisasInternaEmAndamento}</h2>
                            <Text fontSize={'.813rem'} w={'6rem'} fontWeight={'thin'} lineHeight={'.813rem'}>Pesquisas Internas em andamento</Text>
                        </Flex>
                      </Flex>
                      <Flex onClick={() => nav('/Jornada/eNps')} alignItems={'center'} justifyContent={'center'} gap={'.75rem'} bg={'var(--Green2)'} color={'var(--c1)'} padding={'1rem'} borderRadius={'.25rem'} cursor={'pointer'} boxShadow={'var(--SombraBtns)'} _hover={{ filter: 'brightness(1.05)', transform: 'translateY(-3px)', transition: 'all 0.2s ease-in-out' }}>
                          <FaTachometerAlt size={52}/>
                          <Flex flexDir='column'>
                              <h2>{Model?.pesquisaseNps}</h2>
                              <Text fontSize={'.875rem'} w={'6rem'} fontWeight={'thin'} lineHeight={'.875rem'}>eNPS em andamento</Text>
                          </Flex>
                      </Flex>
                    </Grid>
                  </Flex>
                </LoadingContainer>
            </WhiteContainer>

            {(!Model || Model?.pesquisas.length > 0) && <WhiteContainer margin='1rem 0 0 0'>
                <LoadingContainer my='1rem' linhas={5} loading={!Model}>
                {
                    (Model?.pesquisas && Model?.pesquisas?.length > 0) && (
                          <>
                            <Flex marginBottom={'.75rem'} gap={'.75rem'} width='70%'>
                                <FormControl>
                                    <SelectCmp title='Pesquisa' OnChange={(e) => { setDepartamentoId(''); getResumo(e, '') }} value={PesquisaId} borderColor={'var(--Gray4)'}>
                                        {
                                            Model?.pesquisas?.map((e) => {
                                              return (
                                                    <option key={e.id} value={e.id}>{e.nome}</option>
                                              )
                                            })
                                        }
                                    </SelectCmp>
                                </FormControl>

                                <FormControl isDisabled={Resumo && Resumo.countGeral.totalRespostas === 0}>
                                    <SelectCmp title='Área' OnChange={(e) => getResumo(PesquisaId, e)} value={DepartamentoId} borderColor={'var(--Gray4)'}>
                                        <option value={''}>Todas</option>
                                        {
                                            Resumo?.departamentos?.map((e) => {
                                              return (
                                                    <option key={e.id} value={e.id}>{e.nome}</option>
                                              )
                                            })
                                        }
                                    </SelectCmp>
                                </FormControl>
                            </Flex>

                            <LoadingContainer linhas={3} loading={!Resumo}>
                                {
                                    (Resumo) && (
                                        <>
                                            <Flex marginBottom={'1rem'} alignItems={'center'}>
                                                <h2>{Resumo.nomePesquisa}</h2>
                                                {parseJwt().adm === 'True' && <ButtonCmp
                                                    isDisabled={Resumo.countGeral.totalRespostas === 0}
                                                    VarColor='Green2' onClick={() => nav(`/Desempenho/AnaliseDesempenho/${Resumo.indicativa ? 'Indicativa' : 'Quantitativa'}/ResultadoGeral/${Resumo.id}`)}
                                                    size={'lg'} marginLeft={'1rem'}
                                                    leftIcon={<FaChartBar />}
                                                >Visualizar resultados</ButtonCmp>}
                                            </Flex>
                                            <Flex width={'100%'} gap='.75rem' justifyContent={'space-between'} marginBottom={'.75rem'}>
                                                <OutlineContainer theme={'Azul'} style={{ flex: '1' }}>
                                                    <Flex height={'100%'} justifyContent={'center'}>
                                                        <Flex alignItems={'center'}>
                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalAvaliacoes}</h3>
                                                                <h4>Avaliados</h4>
                                                            </OutlineColumn>

                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalAvaliadores}</h3>
                                                                <h4>Avaliadores</h4>
                                                            </OutlineColumn>

                                                            <OutlineColumn>
                                                                <h3>{Resumo.countGeral.totalRespostas}</h3>
                                                                <h4>Respostas</h4>
                                                            </OutlineColumn>

                                                        </Flex>
                                                        <Flex alignItems={'center'} width='12rem' mb={'-.5rem'} mt={'.5rem'}>
                                                            <GaugeChart id='sa' val={Resumo.countGeral.totalAdesao} />
                                                        </Flex>
                                                    </Flex>
                                                </OutlineContainer>

                                                <OutlineContainer theme={'Azul'} style={{ flex: '1' }}>
                                                    <h4 style={{ marginBottom: '.75rem', fontSize: '1.125rem' }}>Respostas por categoria</h4>
                                                    <Flex justifyContent={'start'} color={'var(--Azul)'} flexWrap='wrap'>
                                                        {
                                                            Resumo?.statusCategorias.map((e, i) => {
                                                              return (
                                                                    <Flex flexDir={'column'} fontSize={'.825rem'} alignItems={'center'} opacity={(e.totalRespondido === 0 && e.total === 0) ? '.5' : ''} cursor={(e.totalRespondido === 0 && e.total === 0) ? 'not-allowed' : ''} borderRight={Resumo?.statusCategorias.length !== (i + 1) ? '2px dashed var(--AzulOpaco)' : ''} p={Resumo?.statusCategorias.length !== (i + 1) ? '0 .75rem 0 .75rem' : '0 0 0 .75rem'}>
                                                                        <Flex alignItems={'center'}>
                                                                            {getIcon(e.nome)}
                                                                            <strong>{e.totalRespondido}/{e.total}</strong>
                                                                        </Flex>
                                                                        <strong style={{ color: 'black' }}>{e.nome}</strong>
                                                                    </Flex>
                                                              )
                                                            })
                                                        }
                                                    </Flex>

                                                </OutlineContainer>
                                            </Flex>
                                            {
                                                (!Resumo.indicativa && Resumo.countGeral.totalRespostas > 0) && (
                                                    <Flex gap={'.75rem'}>
                                                        <OutlineContainer theme={'Azul'}>
                                                            <Flex marginBottom={'.75rem'} justifyContent={'center'}>
                                                                <Text fontSize={'1rem'} fontWeight={'900'}>Média geral</Text>
                                                            </Flex>
                                                            <MediaGeralContainer>
                                                                <Flex>
                                                                    <Flex alignItems={'center'} marginRight='.75rem' flexDir={'column'}>
                                                                        <img src={IconeStake} width={42}/>
                                                                        <span style={{ color: 'black', fontWeight: 600 }}>Stakeholders</span>
                                                                    </Flex>
                                                                    <Flex flexDir={'column'} fontWeight={500}>
                                                                        <h3 style={{ color: 'black', fontSize: '.9rem' }}>Nota média</h3>
                                                                        <h2 style={{ color: 'var(--Azul)' }}>{Resumo?.mediaStakeholders.media.toFixed(2)}</h2>
                                                                    </Flex>
                                                                </Flex>
                                                                {
                                                                    (!PropInvalida(Resumo?.mediaAutoavaliacao)) && (
                                                                        <>
                                                                            <hr />
                                                                            <Flex>
                                                                                <Flex color={'black'} alignItems={'center'} marginRight='.75rem' flexDir={'column'}>
                                                                                    <FaUserCheck size={40} color={'var(--Azul)'}/>
                                                                                    <span style={{ fontWeight: 600 }}>Autoavaliação</span>
                                                                                </Flex>
                                                                                <Flex flexDir={'column'} color={'black'} fontWeight={500}>
                                                                                    <h3 style={{ fontSize: '.9rem' }}>Nota média</h3>
                                                                                    <h2 style={{ color: 'var(--Azul)' }}>{Resumo?.mediaAutoavaliacao.media.toFixed(2)}</h2>
                                                                                </Flex>
                                                                            </Flex>
                                                                        </>
                                                                    )
                                                                }
                                                            </MediaGeralContainer>
                                                        </OutlineContainer>
                                                        {
                                                            (Resumo.maiorMedia !== null || Resumo.menorMedia !== null) && (
                                                                <Flex gap={'.75rem'}>
                                                                    <OutlineContainer theme={'Azul'}>
                                                                        <Flex width={'13rem'} marginBottom={'.75rem'} justifyContent={'center'}>
                                                                            <Text fontSize={'1rem'} fontWeight={'900'}>Grupo que melhor avaliou</Text>
                                                                        </Flex>
                                                                        <BoxColor theme={'Azul'}>
                                                                            <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                                {Resumo.maiorMedia !== null && getIconAvaliados(Resumo?.maiorMedia.nome)}
                                                                                <span style={{ color: 'black', fontWeight: 600 }}>{Resumo?.maiorMedia?.nome}</span>
                                                                            </Flex>
                                                                            <Flex flexDir={'column'}>
                                                                                <span style={{ color: 'black' }}>Nota média</span>
                                                                                <h2>{Resumo?.maiorMedia?.media.toFixed(2)}</h2>
                                                                            </Flex>
                                                                        </BoxColor>
                                                                    </OutlineContainer>
                                                                    <OutlineContainer theme={'Azul'}>
                                                                        <Flex width={'13rem'} marginBottom={'.75rem'} justifyContent={'center'}>
                                                                            <Text fontSize={'1rem'} fontWeight={'900'}>Grupo mais crítico</Text>
                                                                        </Flex>
                                                                        <BoxColor theme={'terc3'}>
                                                                            <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                                {Resumo.menorMedia !== null && getIconAvaliados(Resumo?.menorMedia.nome)}
                                                                                <span style={{ color: 'black', fontWeight: 600 }}>{Resumo?.menorMedia?.nome}</span>
                                                                            </Flex>
                                                                            <Flex flexDir={'column'}>
                                                                                <span style={{ color: 'black' }}>Nota média</span>
                                                                                <h2>{Resumo?.menorMedia?.media.toFixed(2)}</h2>
                                                                            </Flex>
                                                                        </BoxColor>
                                                                    </OutlineContainer>
                                                                </Flex>
                                                            )
                                                        }

                                                    </Flex>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </LoadingContainer>
                      </>
                    )
                }
              </LoadingContainer>
            </WhiteContainer>}

            {Model?.pesquisasInterna && Model?.pesquisasInterna.length > 0 && <WhiteContainer styles={{ boxSizing: 'border-box', overflow: 'hidden' }} margin='1rem 0 0 0'>
              <LoadingContainer linhas={12} loading={IsLoading}>
                <Flex flexDir={'column'} gap={'1rem'} pos={'relative'}>
                  <Flex justifyContent={'space-between'} px={'1rem'}>
                    <h2>Pesquisas Internas em andamento</h2>
                    <ButtonCmp VarColor='Green2' onClick={() => nav('/Jornada/PesquisaInterna/Gestao')}>Gestão de pesquisas</ButtonCmp>
                  </Flex>
                  <Flex gap={'1.25rem'}>
                    <Carousel infiniteLoop showArrows>
                        {Model.pesquisasInterna.map(pesquisa => {
                          return <Flex zIndex={'999'} flexDir={'column'} padding={'1rem 2.75rem'} gap={'.75rem'} fontSize={'.875rem'} lineHeight={'.875rem'} alignItems={'center'}>
                            <Flex justifyContent={'space-between'} w={'full'}>
                              <Flex flex={1} flexDir={'column'} alignItems={'self-start'} gap={'.125rem'}>
                                  <Text fontWeight={'700'} fontSize={'1.25rem'}>{pesquisa.nomePesquisa}</Text>
                                  {/* <Text fontWeight={'400'} fontSize={'.875rem'}>Status: <Text as={'span'} fontWeight={'700'} color={pesquisa.statusPesquisa === 'Não enviado' ? 'var(--Yellow)' : pesquisa.statusPesquisa === 'Finalizado' ? 'var(--Red)' : 'var(--Green3)'}>{pesquisa.statusPesquisa}</Text></Text> */}
                              </Flex>
                              <Flex gap={'.5rem'} alignItems={'center'}>
                                {(pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa !== 'Finalizado') && <ButtonCmp VarColor='Rosa' small onClick={pesquisa.trilhaId === null
                                  ? () => nav(`/Jornada/PesquisaInterna/Convites/${pesquisa.id}`)
                                  : () => nav(`/Jornada/Configuracao/Trilha/${pesquisa.trilhaId}/PesquisaInterna/Convites/${pesquisa.id}`)}>
                                <FaEnvelope style={{ marginRight: '.375rem' }} size={'14'}/>
                                  Gestão de envios
                                </ButtonCmp>}
                                {(((!pesquisa.anonima && pesquisa.respostas > 0) || (pesquisa.anonima && pesquisa.respostas >= 5)) || (pesquisa.trilhaId && pesquisa.respostas > 0 && pesquisa.statusPesquisa === 'Em andamento')) && <ButtonCmp VarColor='Green2' small onClick={() => nav(`/Jornada/PesquisaInterna/Resultado/${pesquisa.id}`)}>
                                  <FaChartPie style={{ marginRight: '.375rem' }} size={'14'}/>
                                      Resultado
                                </ButtonCmp>}
                              </Flex>
                            </Flex>
                            <Flex gap={'.75rem'} alignItems={'center'} w={'full'} mb={'.75rem'}>
                              <Flex flexDir={'column'} gap={'.25rem'}>
                                <Flex flex={1} gap={'.25rem'} minW={'11rem'} maxW={'11rem'}>
                                    <span>Início: </span>
                                    <strong>{pesquisa.dataInicio || 'Pesquisa não configurada'}</strong>
                                </Flex>
                                <Flex flex={1} gap={'.25rem'}>
                                    <span>Fim: </span>
                                    <strong>{pesquisa.dataFim ?? 'Indefinido'}</strong>
                                </Flex>
                              </Flex>
                              <Divider orientation='vertical' borderColor={'var(--c6)'} mr={'1rem'} borderWidth={'1px'} h={'40px'}/>
                              <Flex flexDir={'column'} flex={1} justifyContent={'center'} gap={'.25rem'}>
                                  <Flex fontWeight={'400'} color={'black'} gap={'.325rem'}>
                                      <FaUser color='var(--Gray2)'/>
                                      <span>Convites: <strong>{pesquisa.convites}</strong></span>
                                  </Flex>
                                  <Flex fontWeight={'400'} color={'black'} gap={'.325rem'}>
                                      <FaCheckCircle color='var(--Azul)'/>
                                      <span>Respostas: <strong>{pesquisa.respostas}</strong></span>
                                  </Flex>
                              </Flex>
                          </Flex>
                        </Flex>
                        })}
                    </Carousel>
                    <Flex flexDir={'column'} w={'100%'} gap={'.125rem'} borderRadius={'.25rem'} padding={'1rem'} border={'1px solid var(--c5)'} justifyContent={'center'}>
                        <TipoPesquisa style={{ color: 'var(--Orange)' }} onClick={() => nav('/Jornada/PesquisaInterna/Gestao?filtro=Não enviado')}>{Model?.statusPesquisaInterna?.emElaboracao ?? 0} Pesquisas não enviadas <FaRegWindowRestore size={14}/></TipoPesquisa>
                        <TipoPesquisa style={{ color: 'var(--Green2)' }} onClick={() => nav('/Jornada/PesquisaInterna/Gestao?filtro=Em andamento')}>{Model?.statusPesquisaInterna?.totalEmAndamento ?? 0} Pesquisas em andamento <FaRegWindowRestore size={14}/></TipoPesquisa>
                        <TipoPesquisa style={{ color: 'var(--Azul)' }} onClick={() => nav('/Jornada/PesquisaInterna/Gestao?filtro=Finalizado')}>{Model?.statusPesquisaInterna?.totalFinalizadas ?? 0} Pesquisas finalizadas <FaRegWindowRestore size={14}/></TipoPesquisa>
                    </Flex>
                  </Flex>
                </Flex>
              </LoadingContainer>
            </WhiteContainer>}

            {<WhiteContainer margin='1rem 0'>
              {(!Model || Model?.tabela) && <Flex flexDir={'column'}>
                  <Tabela isLoading={IsLoadingTabela || !Model} quantidade={Model?.quantidade ?? 0} dados={Model?.tabela} onFilter={(texto, pagina, tamanho) => { GetDashhboard(texto, pagina, tamanho, true) }}/>
              </Flex>}
                {/* <LoadingContainer linhas={3} loading={IsLoading} my={'1rem'}>
                  <Flex flexDir={'column'} gap='.75rem' width='30%'>
                      {
                          (Model?.proximosEventos && Model.proximosEventos.length > 0) && (
                              <OutlineContainer theme={'c4'}>
                                  <Text fontSize={'1.25rem'} fontWeight={800} textAlign={'center'}>Próximos eventos</Text>
                                  <ul>
                                      {
                                          Model?.proximosEventos.map((e, i) => {
                                            return (
                                                  <Flex alignItems={'center'} flexDir={'column'}>
                                                    <Flex flexDir={'column'} bg={'var(--AzulOpacoBg)'} border={'2px solid var(--Azul)'} p={'1rem'} borderRadius={'.325rem'} key={e.id} fontSize={'.813rem'} lineHeight={'.925rem'}>
                                                      <Text fontWeight={'500'}>
                                                        {e.dataInicio ? `${e.dataInicio} -` : ''} {e.assunto}
                                                      </Text>
                                                      <Text fontWeight={900}>{e.nomeParticipante}</Text>
                                                    </Flex>
                                                    {(i + 1 !== Model?.proximosEventos.length) && <Box h={'17px'} w={'4px'} bg={'var(--Azul)'}></Box>}
                                                  </Flex>
                                            )
                                          })
                                      }
                                  </ul>
                              </OutlineContainer>
                          )
                      }
                  </Flex>
                </LoadingContainer> */}
            </WhiteContainer>}
        </Body>
  )
}

import { Image, Text, View } from '@react-pdf/renderer'
import { iColaboradorBase } from '../../../../../../../interfaces'

interface iProps {
  assinaturaGestor?: boolean
  assinaturaColaborador?: boolean
  avaliado?: iColaboradorBase
}

export const Footer: React.FC<iProps> = ({ assinaturaColaborador, assinaturaGestor, avaliado }) => {
  return (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '40px', justifyContent: 'center', marginBottom: '16px' }}>
                {assinaturaColaborador &&
                    <View style={{ display: 'flex', gap: '6px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '150px', backgroundColor: '#000', height: '1px' }}></View>
                        <Text style={{ fontWeight: 'bold', fontSize: '12px', textAlign: 'center', fontFamily: 'Roboto' }}>{avaliado?.nome === '' ? 'Avaliado' : avaliado?.nome}</Text>
                    </View>
                }
                {assinaturaGestor &&
                    <View style={{ display: 'flex', gap: '6px', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <View style={{ width: '150px', backgroundColor: '#000', height: '1px' }}></View>
                        <Text style={{ fontWeight: 'bold', fontSize: '12px', textAlign: 'center', fontFamily: 'Roboto' }}>{avaliado?.gestorNome === '' ? 'Gestor' : avaliado?.gestorNome}</Text>
                    </View>
                }
            </View>
            <View
                style={{
                  backgroundColor: '#66046B',
                  paddingVertical: 4,
                  alignItems: 'center'
                }}
            >
                <View>
                    <Image
                        style={{
                          height: 35,
                          objectFit: 'contain'
                        }}
                        source="https://sigah.blob.core.windows.net/onboard/0acc742b-1a78-4d9e-9bfd-26b6c1f46c19.png"
                    />
                </View>
            </View>
        </View>
  )
}

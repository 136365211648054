import { StyleSheet } from '@react-pdf/renderer'

export const GlobalStyles = StyleSheet.create({
  Container: {
    flexDirection: 'column',
    marginVertical: 0.8
  },
  AvatarGroup: {
    flexDirection: 'row',
    marginRight: 6.4
  },
  Avatar: {
    width: 20,
    objectFit: 'scale-down',
    backgroundColor: '#000',
    borderRadius: 14
  },
  TextCheckBox: {
    fontSize: 14
  },
  Row: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 6.4
  }
})

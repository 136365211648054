import { Button, ButtonProps, MenuButton } from '@chakra-ui/react'

interface iStyledProps extends ButtonProps {
  disabled?: boolean
}

export const StyledMenuButton: React.FC<iStyledProps> = ({ onClick, children, disabled, ...rest }) => {
  return (
        <MenuButton
          px={'.75rem'}
          height={'1.925rem'}
          borderRadius='.25rem'
          transition='all 0.2s'
          _hover={{ bg: 'var(--Rosa)' }}
          bg={'none'}
          fontWeight={'600'}
          fontSize={'14px'}
          as={Button}
          _focus={{ bg: 'var(--Rosa)' }}
          _active={{ bg: 'var(--Rosa)' }}
          {...rest}
          onClick={onClick}
        >
            {children}
        </MenuButton>
  )
}

import PesquisaImg from '../../../../../assets/Pesquisa.svg'
import { Container } from './styles'
import { FaCog, FaEdit, FaEye } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iCardEtapa } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'

interface ICardPesquisa {
  etapaAnterior?: string
  etapa: iCardEtapa
  trilhaPadrao: boolean
  isOpen: boolean
}

interface iPesquisaInternaStatus {
  respondente: string
  nome: string
  totalQuestoes: number
  formularioAprovado: boolean
}

export const CardPesquisa: React.FC<ICardPesquisa> = ({ etapaAnterior, etapa, isOpen, trilhaPadrao }) => {
  const { pathname } = useLocation()
  const [Pesquisa, setPesquisa] = useState<iPesquisaInternaStatus>()
  const nav = useNavigate()

  function ObterPesquisaPorId(): void {
    appApi.get(`PesquisaInterna/${etapa.interacoes[0].idExterno}/Status?etapaId=${etapa.id}`)
      .then(response => { setPesquisa(response.data) })
      .catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Pesquisa)) {
      ObterPesquisaPorId()
    }
  }, [isOpen])

  return (
    <Flex flexDir={'column'} gap={'1rem'}>
      <Container>
        {(!Pesquisa?.formularioAprovado) && (
          <Flex onClick={() => nav(`PesquisaInterna/Formulario/${etapa.interacoes[0].idExterno}`)} className={Pesquisa?.formularioAprovado ? 'disabled' : ''}>
            <img src={PesquisaImg}/>
            <div>
              <FaEdit color='var(--terc10)' size={45} />
            </div>
          </Flex>
        )}
        {(Pesquisa?.formularioAprovado) && (
          <Flex onClick={() => nav(`/Jornada/PesquisaInterna/Gestao/${etapa.interacoes[0].idExterno}/Previsualizar`)}>
            <img src={PesquisaImg}/>
            <div>
              <FaEye color='var(--terc10)' size={45} />
            </div>
          </Flex>
        )
        }

        <div>
          <span>Título: <strong>{Pesquisa?.nome}</strong></span>
          <span>Respondente: <strong>{Pesquisa?.respondente}</strong></span>
          {
            etapa.tempoInicio !== null
              ? <span>Quando: <strong>{etapa.tempoInicio} dias de onboarding</strong></span>
              : <span>Após a etapa: <strong>{etapaAnterior}</strong></span>
          }
          <span>Total de questões:<strong> {Pesquisa?.totalQuestoes}</strong></span>
        </div>

      </Container>
      <Flex justifyContent={'center'}>
        <ButtonCmp
          VarColor='Green2'
          leftIcon={<FaCog/>}
          isDisabled={trilhaPadrao}
          fontSize={'14px'}
          onClick={() => nav(`${pathname}/PesquisaInterna/Configuracao/${etapa.interacoes[0].idExterno}`)}
        >Configurações de envio</ButtonCmp>
      </Flex>
    </Flex>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
background: #${props => props.theme};
color: var(--c1);
border-radius: 0.25rem;
flex-direction: column;
position: relative;
text-align: start;
padding: 1rem;
font-size: .875rem;

small{
    font-family: 'Roboto','sans-serif';
}

div:last-child{
    margin-top: .5rem;
    display: flex;
    justify-content: center;
}

&:hover{
    border:1px solid var(--Rosa);
    cursor: pointer;
    transform: translate(0rem,-0.25rem);
    transition: all 0.2s ease;
    box-shadow: var(--SombraBackground);
    filter: brightness(1.03);
}
`

export const NavButton = styled.a`
    background: var(--terc6);
    padding: 0.5rem;
    border-radius: 0.625rem;
    font-weight: bold;
    font-family: 'Roboto','sans-serif';

    span{
        margin-right: 0.5rem;
    }
`

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
background: var(--c2);
border-radius: 0.25rem;
box-shadow: var(--SombraBtns);
min-height: 6rem;
max-height: 12rem;
margin-top: .5rem;
width: 100%;
`

export const RadiusAmarelo = styled.div`
 background:var(--Azul);
 width: .325rem;
 height: 100%;
 border-top-left-radius:0.25rem ;
 border-bottom-left-radius:0.25rem ;
 margin-right: .75rem;
`

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
font-size: .875rem;
`

import { Flex, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { iPutOrdenacao } from '../..'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { iCardEtapa, iEtapa } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iModalProps {
  onClose: () => void
  onSave: (etapa: iPutOrdenacao) => void
  etapa?: iCardEtapa
  etapas: iCardEtapa[]
}

export const ModalChangeTrilha: React.FC<iModalProps> = ({ onClose, onSave, etapa, etapas }) => {
  const toast = useToast()
  const [Etapa, setEtapa] = useState<iEtapa>({
    tempoInicio: 0,
    tempoDuracao: 0
  })
  const [RadioValue, setRadioValue] = useState('1')
  const [EtapaSuperior, setEtapaSuperior] = useState<iCardEtapa>()
  const [EtapaInferior, setEtapaInferior] = useState<iCardEtapa>()
  const [InputDataMaxAtivo, setInputDataMaxAtivo] = useState(false)

  const [Validador, setValidador] = useState({
    max: 180,
    min: 0
  })

  function DefinirModal(): void {
    const posEtapa = etapas.findIndex(r => r.id === etapa?.id)
    if (posEtapa === -1) return

    if (!PropInvalida(etapas[posEtapa - 1])) {
      setEtapaSuperior(etapas[posEtapa - 1])
    }

    if (!PropInvalida(etapas[posEtapa + 1])) {
      setEtapaInferior(etapas[posEtapa + 1])
    }
  }

  function ObterTextoAssociar(): string {
    if ((!PropInvalida(EtapaSuperior?.etapaPai) || PropInvalida(EtapaSuperior?.etapaPai)) && !PropInvalida(EtapaInferior?.etapaPai)) {
      return `<span>Deseja vincular a etapa <strong>${etapa?.nome ?? ''}</strong> para terminar apenas após a conclusão da etapa <strong>${EtapaSuperior?.nome ?? ''}</strong> e vincular a etapa <strong>${EtapaInferior?.nome ?? ''}</strong></span>`
    } else {
      return `<span>Deseja vincular a etapa <strong>${etapa?.nome ?? ''}</strong> para terminar apenas após a conclusão da etapa <strong>${EtapaSuperior?.nome ?? ''}</strong></span>`
    }
  }

  useEffect(() => {
    DefinirModal()
  }, [])

  function TopEtapaValida(id: string): iCardEtapa | undefined {
    const etapaBuscada = etapas.find(e => e.id === id)
    if (etapaBuscada) {
      if (!PropInvalida(etapaBuscada.tempoInicio)) {
        return etapaBuscada
      } else if (!PropInvalida(etapaBuscada?.etapaPai)) {
        let etapaTopo = etapas.find(e => e.id === etapaBuscada?.etapaPai)
        while (etapaTopo != null) {
          const tes = etapas.find(e => e.id === etapaTopo?.etapaPai)
          if (tes) {
            etapaTopo = tes
          } else {
            break
          }
        }
        return etapaTopo
      }
    }
    return undefined
  }

  function VerificaDataMax(option: string): void {
    if (option === '2') {
      if ((!PropInvalida(EtapaSuperior?.etapaPai) || PropInvalida(EtapaSuperior?.etapaPai)) && !PropInvalida(EtapaInferior?.etapaPai)) {
        setInputDataMaxAtivo(false)
      } else {
        setInputDataMaxAtivo(true)
      }
    }
  }

  function DownEtapaValida(id: string): iCardEtapa | undefined {
    const etapaBuscada = etapas.find(e => e.id === id)
    if (etapaBuscada) {
      if (!PropInvalida(etapaBuscada.tempoInicio)) {
        return etapaBuscada
      } else if (!PropInvalida(etapaBuscada?.etapaPai)) {
        const filhos: iCardEtapa[] = []
        let etapaInferior = etapas.find(e => e.etapaPai === etapaBuscada?.id)
        while (etapaInferior != null) {
          filhos.push(etapaInferior)
          etapaInferior = etapas.find(e => e.etapaPai === etapaInferior?.id)
        }
        if (filhos.length > 0) {
          const posUltimoFilho = etapas.findIndex(r => r.id === filhos[filhos.length - 1]?.id)
          if (!PropInvalida(etapas[posUltimoFilho + 1])) {
            return etapas[posUltimoFilho + 1]
          }
        }
      }
    }
    return undefined
  }

  useEffect(() => {
    if (!PropInvalida(EtapaSuperior?.etapaPai) && !PropInvalida(EtapaInferior?.etapaPai)) {
      setRadioValue('2')
    }

    const etapaSuperiorValida = TopEtapaValida(EtapaSuperior?.id ?? '')
    const etapaInferiorValida = DownEtapaValida(EtapaInferior?.id ?? '')

    setValidador({
      min: etapaSuperiorValida?.tempoInicio ?? 0,
      max: etapaInferiorValida?.tempoInicio ?? etapaSuperiorValida?.tempoInicio ?? 185
    })

    // if (PropInvalida(etapaSuperiorValida)) {
    //   setEtapa({ ...Etapa, tempoInicio: etapaSuperiorValida?.tempoInicio ?? 0 })
    // }
  }, [EtapaSuperior, EtapaInferior])

  useEffect(() => {
    setEtapa({
      ...Etapa,
      tempoInicio: Validador.min,
      tempoDuracao: Validador.min + 1
    })
  }, [Validador])

  function InternalSave(): void {
    if ((Etapa?.tempoInicio && Etapa?.tempoInicio < Validador.min) ?? (InputDataMaxAtivo && Etapa?.tempoDuracao)) {
      toast({
        title: 'Valores inválidos!',
        status: 'error',
        duration: 2000,
        isClosable: false
      })
      return
    }
    onSave({
      etapaId: etapa?.id ?? '',
      etapaInferior: EtapaInferior?.id,
      etapaSuperior: EtapaSuperior?.id,
      tempoDuracao: RadioValue === '1' ? Etapa.tempoDuracao : InputDataMaxAtivo ? Etapa.tempoDuracao : undefined,
      tempoInicio: RadioValue === '1' ? Etapa.tempoInicio : undefined,
      etapaPai: RadioValue === '2' ? EtapaSuperior?.id : undefined
    })
  }
  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Titulo={'Deseja alterar a ordenação da etapa?'}
      Width={'35rem'}
    >
      <hr style={{ border: '0.063rem solid var(--c3)' }}></hr>
      <Flex fontSize={'14px'} flexDirection={'column'} px={'1rem'} py={'1rem'} gap={'.75rem'}>
        <Text as={'h4'}>Coloque uma data válida dentro as permitidas nos campos abaixo.</Text>
        <RadioGroup onChange={(e) => { setRadioValue(e); VerificaDataMax(e) }} value={RadioValue} size={'sm'}>
          <Stack direction='row'>
            <Radio value='1'>Data</Radio>
            {!PropInvalida(EtapaSuperior) && EtapaSuperior?.tipo !== 0 &&
              <Radio value='2'>Vincular</Radio>
            }
          </Stack>
        </RadioGroup>
        {RadioValue === '1' && (
          <>
            <Flex alignItems={'center'} gap={'.75rem'}>
              <Text w={'28px'}>De</Text>
              <InputCmp
                type={'number'}
                width={'200px'}
                min={Validador.min}
                max={Validador.max}
                value={Etapa.tempoInicio}
                OnChange={(e) => setEtapa({ ...Etapa, tempoInicio: parseInt(e) })}
              />
              <Text>dias de onboarding</Text>
            </Flex>
            <Flex alignItems={'center'} gap={'.75rem'}>
              <Text w={'28px'}>Até</Text>
              <InputCmp
                type={'number'}
                width={'200px'}
                value={Etapa.tempoDuracao}
                OnChange={(e) => setEtapa({ ...Etapa, tempoDuracao: parseInt(e) })}
              />
              <Text>dias de onboarding</Text>
            </Flex>
          </>
        )}
        {RadioValue === '2' && (
          <Flex flexDirection={'column'} gap={'1rem'}>
            <span dangerouslySetInnerHTML={{ __html: ObterTextoAssociar() }}></span>
            {InputDataMaxAtivo &&
              <Flex gap={'1rem'} alignItems={'center'}>
                <Text>Até:</Text>
                <InputCmp
                  type={'number'}
                  min={Validador.min + 1}
                  width={'10rem'}
                  value={Etapa.tempoDuracao}
                  OnChange={(e) => setEtapa({ ...Etapa, tempoDuracao: parseInt(e) })}
                />
                <Text w={'400px'}>dias após a liberação da etapa</Text>
              </Flex>
            }
          </Flex>
        )}
      </Flex>
      <Flex gap={'.5rem'} justifyContent={'end'} p={'1rem'}>
        <ButtonCmp onClick={onClose} VarColor='c6'>Cancelar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={InternalSave}>Salvar alterações</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Flex, FormControl, Grid, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { FaChevronDown, FaChevronUp, FaClipboardList, FaFilter } from 'react-icons/fa'
import { MdGroups } from 'react-icons/md'
import { Body } from '../../Layouts/Body'
import { InfoBox, TabelaAvaliacoes } from './styles'
import { useLocation, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iCompetenciaPDI, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeTrilha.svg'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { PropInvalida } from '../../../Utils/Helper'
import { ImgSah } from '../../../components/ImgSah'
import { Recomendacao } from './components/Recomendacao'
import { BarChartGeral } from './components/BarChart'
import { RecomendacaoMedia } from './components/RecomendacaoMedia'
import { SelectCmp } from '../../../components/SelectCmp'
import LoadingContainer from '../../../components/LoadingContainer'

export interface iColaboradorRecomedacao {
  nome: string
  cargo: string
  departamento: string
  id: string
  avatar: string
  media: number
}

interface iModel {
  nomePesquisa: string
  totalAvaliadores: number
  totalRespostas: number
  totalAvaliacoes: number
  filtroDepartamento: iNomeId[]
  filtroCargo: iNomeId[]
  recomendacoesApontamentos: iRecomendacoes[]
  recomendacoesMedia: iRecomendacoes[]
  tabelaVisaoGeral: iTabela
  avaliadores: number[]
}
interface iTabela {
  geralAvaliados: iLinhaTabela[]
  autoavaliacoes: iLinhaTabela[]
  tipoAvaliadores: iLinhaTabela2[]
}

interface iLinhaTabela {
  competencia: string
  melhor: string
  pior: string
  maisMotiva: string
  menosMotiva: string
  priorizado: string
}

interface iLinhaTabela2 {
  competencia: string
  avaliador: iNotaAvaliador[]
}

interface iNotaAvaliador {
  tipo: number
  melhor: number
  pior: number
}

interface iRecomendacoes {
  tipo: number
  individual: boolean
  cards: iCompetenciaPDI[]
}

export interface IDadoGrafico {
  media: string
  nome: string
}

interface iModelFiltro {
  departamentoId?: string
  cargoId?: string
  gestor?: string
  dataInicio?: string
  dataFim?: string
  auto?: boolean
}

export const ResultadoGeralIndicativa: React.FC = () => {
  const ModelDefault: iModel = {
    filtroCargo: [],
    filtroDepartamento: [],
    nomePesquisa: '',
    totalAvaliadores: 0,
    totalRespostas: 0,
    totalAvaliacoes: 0,
    recomendacoesApontamentos: [],
    recomendacoesMedia: [],
    tabelaVisaoGeral: { autoavaliacoes: [], tipoAvaliadores: [], geralAvaliados: [] },
    avaliadores: []
  }

  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const { pathname } = useLocation()

  const [UltimoFiltro, setUltimoFiltro] = useState<number>()
  const [Ordenacao, setOrdenacao] = useState(false)
  const [TabelaOrdenada, setTabelaOrdenada] = useState(0)
  const [Grafico, setGrafico] = useState<IDadoGrafico[]>([])
  const [AvaliadoresTabela, setAvaliadoresTabela] = useState<number[]>([])
  const [Model, setModel] = useState<iModel>(ModelDefault)
  const [ModelFiltro, setModelFiltro] = useState<iModelFiltro>()
  const [IsLoading, setIsLoading] = useState(false)

  function GerarQuery(): string {
    let query = ''
    if (ModelFiltro?.departamentoId) {
      query = `departamentoId=${ModelFiltro.departamentoId}`
    }

    if (ModelFiltro?.cargoId) {
      query = query + `${query.length > 0 ? '&' : ''}cargoId=${ModelFiltro.cargoId}`
    }

    if (ModelFiltro?.gestor) {
      query = query + `${query.length > 0 ? '&' : ''}gestor=${ModelFiltro.gestor === '0'}`
    }

    if (ModelFiltro?.dataInicio) {
      query = query + `${query.length > 0 ? '&' : ''}dataInicio=${ModelFiltro.dataInicio}`
    }

    if (ModelFiltro?.dataFim) {
      query = query + `${query.length > 0 ? '&' : ''}dataFim=${ModelFiltro.dataFim}`
    }

    if (ModelFiltro?.auto) {
      query = query + `${query.length > 0 ? '&' : ''}auto=${ModelFiltro.auto}`
    }
    return query
  }

  function getResultado(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/ResultadoGeralIndicativa?${GerarQuery()}`)
      .then(res => {
        setModel(res.data)
        setGrafico(res.data.graficoMediaGeralCompetencias)
        setIsLoading(false)
        const arr = res.data.tabelaVisaoGeral.tipoAvaliadores as iLinhaTabela2[]
        const resp: number[] = []
        if (arr && arr.length > 0) {
          arr.forEach(e => e.avaliador.forEach(a => {
            if (!resp.includes(a.tipo)) {
              resp.push(a.tipo)
            }
          }))
          setAvaliadoresTabela(resp)
        }
      })
      .catch(err => console.log(err))
  }

  function OrdenarTabela(filtro: number, tabelaOrdenacao: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setTabelaOrdenada(tabelaOrdenacao)
    setUltimoFiltro(filtro)
  }

  function Ordenar(): void {
    let copy: iLinhaTabela[] = []
    let copy2: iLinhaTabela2[] = []
    if (TabelaOrdenada === 1) {
      copy = [...Model.tabelaVisaoGeral.autoavaliacoes]
    }
    if (TabelaOrdenada === 2) {
      copy2 = [...Model.tabelaVisaoGeral.tipoAvaliadores]
    }
    if (TabelaOrdenada === 3) {
      copy = [...Model.tabelaVisaoGeral.geralAvaliados]
    }
    if (UltimoFiltro === 1) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return b.competencia.localeCompare(a.competencia)
        } else if (Ordenacao) {
          return a.competencia.localeCompare(b.competencia)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.melhor) > parseFloat(b.melhor) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.melhor) > parseFloat(a.melhor) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 3) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.pior) > parseFloat(b.pior) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.pior) > parseFloat(a.pior) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 4) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.maisMotiva) > parseFloat(b.maisMotiva) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.maisMotiva) > parseFloat(a.maisMotiva) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 5) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.menosMotiva) > parseFloat(b.menosMotiva) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.menosMotiva) > parseFloat(a.menosMotiva) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 6) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.priorizado) > parseFloat(b.priorizado) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.priorizado) > parseFloat(a.priorizado) ? 1 : -1
        }
        return 0
      })
    }

    if (TabelaOrdenada === 1) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, autoavaliacoes: copy } })
    }
    if (TabelaOrdenada === 2) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, tipoAvaliadores: copy2 } })
    }
    if (TabelaOrdenada === 3) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, geralAvaliados: copy } })
    }
  }

  function IconeOrdenar(filtro: number, tabelaOrdenacao: number): React.ReactElement {
    if (UltimoFiltro === filtro && !Ordenacao) {
      return (
        <FaChevronUp onClick={() => OrdenarTabela(filtro, tabelaOrdenacao)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
      )
    } else {
      return (
        <FaChevronDown onClick={() => OrdenarTabela(filtro, tabelaOrdenacao)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
      )
    }
  }

  function ObterNomeAvaliador(tipo: number): string {
    const data = {
      nomeAvaliador: ''
    }

    switch (tipo) {
      case 6:
        data.nomeAvaliador = 'Alta liderança'
        break
      case 4:
        data.nomeAvaliador = 'Outros'
        break
      case 3:
        data.nomeAvaliador = 'Liderados'
        break
      case 2:
        data.nomeAvaliador = 'Pares'
        break
      case 1:
        data.nomeAvaliador = 'Gestores'
        break
      case 0:
        data.nomeAvaliador = 'Autoavaliação'
        break
      default:
        break
    }

    return data.nomeAvaliador
  }

  useEffect(() => {
    Ordenar()
  }, [Ordenacao, UltimoFiltro])

  useEffect(() => {
    getResultado()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: pathname.includes('Desempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          },
          {
            nome: 'Resultado geral',
            path: ''
          }
        ]} />
      </Flex>

      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap='wrap'>
        <Flex width={'50%'} flexDir={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
        </Flex>
        {!!(Model.totalRespostas) && (
          <Flex margin={'1rem 0'} gap={'.75rem'}>
            <InfoBox>
              <Flex width={'2rem'}>
                <img src={IconeAvaliadores} width={'32px'} height={'32px'}/>
              </Flex>
              <div>
                <Text align={'start'} fontSize={'1.25rem'} lineHeight={'1.325rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliacoes}</Text>
                <Text fontSize={'.875rem'}>Avaliados</Text>
              </div>
            </InfoBox>
            <InfoBox>
              <Flex>
                <FaClipboardList size={28} />
              </Flex>
              <div>
                <Text align={'start'} fontSize={'1.25rem'} lineHeight={'1.325rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliadores}</Text>
                <Text fontSize={'.875rem'}>Avaliadores</Text>
              </div>
            </InfoBox>
            <InfoBox>
              <Flex>
                <MdGroups size={32}/>
              </Flex>
              <div>
                <Text align={'start'} fontSize={'1.25rem'} lineHeight={'1.325rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{((Model.totalRespostas / (Model.totalAvaliadores)) * 100).toFixed(0)}%</Text>
                <Text fontSize={'.875rem'}>Adesão</Text>
              </div>
            </InfoBox>
          </Flex>
        )}
      </Flex>

      <Flex marginTop={'1rem'} gap={'.75rem'} alignItems='end'>
        <FormControl>
          <SelectCmp title={'Área'} isDisabled={!PropInvalida(ModelFiltro?.cargoId ?? '')} OnChange={(e) => setModelFiltro({ ...ModelFiltro, departamentoId: e })}>
            <option value={''}>Todos</option>
            {
              Model?.filtroDepartamento?.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </FormControl>

        <FormControl>
          <SelectCmp title='Cargo' isDisabled={!PropInvalida(ModelFiltro?.departamentoId ?? '')} value={ModelFiltro?.cargoId} OnChange={(e) => setModelFiltro({ ...ModelFiltro, cargoId: e })}>
            <option value={''}>Todos</option>
            {
              Model?.filtroCargo?.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </FormControl>

        <FormControl>
          <SelectCmp title='Gestor' value={ModelFiltro?.gestor} OnChange={(e) => setModelFiltro({ ...ModelFiltro, gestor: e })}>
            <option value={''}>Todos</option>
            <option value={'0'}>Sim</option>
            <option value={'1'}>Não</option>
          </SelectCmp>
        </FormControl>

        <Flex>
          <ButtonCmp onClick={getResultado} VarColor='Green2' leftIcon={<FaFilter/>}>Filtrar</ButtonCmp>
        </Flex>
      </Flex>
      {
        (Model?.totalRespostas > 0) && (
          <Tabs variant='unstyled' marginTop={'1rem'}>
            <TabList >
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Destaques</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Visão geral</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Gráfico</Tab>
            </TabList>
            <TabPanels background={'white'} borderBottomLeftRadius='.25rem' borderBottomRightRadius='.25rem'>
              <TabPanel padding={'1rem'}>
                {Model.recomendacoesApontamentos?.map(e => {
                  return (
                    <Box mb={'1.5rem'}>
                      <>
                        <Text mb={'.75rem'} fontSize={'1rem'} lineHeight={'1.5rem'}>Competências com mais citação como <strong>{e.tipo === 0 ? 'ponto forte,' : 'prioridade de desenvolvimento,'}</strong> considerando a quantidade de apontamentos individuais</Text>
                        <Grid gap={'.813rem'} templateColumns={'repeat(3, 1fr)'}>
                          {e.cards?.map((card, i) => {
                            return (
                              <Recomendacao pos={i + 1} recomendacao={card} tipo={e.tipo} />
                            )
                          })}
                        </Grid>
                      </>
                    </Box>
                  )
                })}
                {Model.recomendacoesMedia?.map(e => {
                  return (
                    <Box mb={'1.5rem'}>
                      <>
                        <Text mb={'.75rem'} fontSize={'1rem'} lineHeight={'1.5rem'}>Competências com mais citação como <strong>{e.tipo === 0 ? 'ponto forte,' : 'prioridade de desenvolvimento,'}</strong> considerando a média de apontamentos</Text>
                        <Grid gap={'.813rem'} templateColumns={'repeat(3, 1fr)'}>
                          {e.cards?.map((card, i) => {
                            return (
                              <RecomendacaoMedia pos={i + 1} recomendacao={card} tipo={e.tipo} />
                            )
                          })}
                        </Grid>
                      </>
                    </Box>
                  )
                })}
              </TabPanel>
              <TabPanel>
                <Flex justifyContent={'space-between'} my={'1rem'} alignItems={'center'} fontSize={'1.125rem'}><Text fontWeight={'700'}>Visão das competências considerando todos os avaliados</Text></Flex>
                {/* <CheckboxCmp OnChange={(e) => setModelFiltro({ ...ModelFiltro, auto: e })}>Considerar autoavaliação no resultado geral</CheckboxCmp> */}
                <Box p={'.5rem 1rem'} border={'1px solid #b9b9b970'} borderRadius={'.25rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências {IconeOrdenar(1, 3)}</th>
                        <th>Melhor {IconeOrdenar(2, 3)}</th>
                        <th>Pior {IconeOrdenar(3, 3)}</th>
                        <th>Mais se motiva {IconeOrdenar(4, 3)}</th>
                        <th>Menos se motiva {IconeOrdenar(5, 3)}</th>
                        <th>Foi priorizado {IconeOrdenar(6, 3)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.geralAvaliados?.map(e => {
                        return (
                          <tr>
                            <td>{e.competencia}</td>
                            <td style={{ color: 'green' }}>{e.melhor === '0' ? '-' : e.melhor}</td>
                            <td style={{ color: 'red' }}>{e.pior === '-0' ? '-' : e.pior}</td>
                            <td style={{ color: 'green' }}>{e.maisMotiva === '0' ? '-' : e.maisMotiva}</td>
                            <td style={{ color: 'red' }}>{e.menosMotiva === '-0' ? '-' : e.menosMotiva}</td>
                            <td>{e.priorizado === '0' ? '-' : e.priorizado}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Text fontWeight={'700'} mt={'2rem'} mb={'1rem'} fontSize={'1.125rem'}>Visão das competências considerando somente autoavaliações</Text>
                <Box p={'.5rem 1rem'} border={'1px solid #b9b9b970'} borderRadius={'.25rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências {IconeOrdenar(1, 1)}</th>
                        <th>Melhor {IconeOrdenar(2, 1)}</th>
                        <th>Pior {IconeOrdenar(3, 1)}</th>
                        <th>Mais se motiva {IconeOrdenar(4, 1)}</th>
                        <th>Menos se motiva {IconeOrdenar(5, 1)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.autoavaliacoes?.map(e => {
                        return (
                          <tr>
                            <td>{e.competencia}</td>
                            <td style={{ color: 'green' }}>{e.melhor === '0' ? '-' : e.melhor}</td>
                            <td style={{ color: 'red' }}>{e.pior === '-0' ? '-' : e.pior}</td>
                            <td style={{ color: 'green' }}>{e.maisMotiva === '0' ? '-' : e.maisMotiva}</td>
                            <td style={{ color: 'red' }}>{e.menosMotiva === '-0' ? '-' : e.menosMotiva}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Text fontWeight={'700'} mt={'2rem'} mb={'1rem'} fontSize={'1.125rem'}>Visão das competências considerando cada tipo de avaliadores</Text>
                <Box p={'.5rem 1rem'} border={'1px solid #b9b9b970'} borderRadius={'.25rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências</th>
                        {AvaliadoresTabela.map((e, i) => {
                          return (
                            <th key={i}>{ObterNomeAvaliador(e)}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.tipoAvaliadores?.map(e => {
                        return (
                          <tr>
                            <td width={100 / e.avaliador.length} >{e.competencia}</td>
                            {e.avaliador?.map((r, i) => {
                              return (
                                <td key={i}>
                                  <Flex justifyContent='center' gap={'1rem'} >
                                    {r.melhor === 0 && r.pior === 0
                                      ? (
                                        <Text>-</Text>
                                        )
                                      : (
                                        <>
                                          <Text color={'green'}>{r.melhor}</Text>
                                          <Text color={'red'}>{r.pior}</Text>
                                        </>
                                        )}

                                  </Flex>
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Flex direction={'column'} my={'1rem'}>

                  <Flex justifyContent={'center'} gap={'.875rem'} fontSize={'.875rem'}>

                    <Flex gap={'.325rem'} alignItems={'center'}><Box w={'1.25rem'} h={'1.25rem'} bgColor={'var(--Green1)'}></Box> Melhores competências</Flex>

                    <Flex gap={'.325rem'} alignItems={'center'}><Box w={'1.25rem'} h={'1.25rem'} bgColor={'var(--Red)'}></Box> Piores competências</Flex>

                  </Flex>

                </Flex>
              </TabPanel>
              <TabPanel>
                <Text fontSize={'22px'} fontWeight={'700'}>Média geral das competências</Text>
                  {!IsLoading && <BarChartGeral
                    model={Grafico}
                  />}
                  {IsLoading && <LoadingContainer loading={true} linhas={22} my={'1rem'}></LoadingContainer>}
              </TabPanel>
            </TabPanels>
          </Tabs>
        )
      }
      {
        (Model?.totalRespostas === 0) && (
          <ImgSah textoPersonalizado='Não encontramos resultados com os filtros selecionados' />
        )
      }
    </Body>
  )
}

import { Divider, Flex, Menu, MenuList, Text } from '@chakra-ui/react'
import { FaCalendar, FaChevronDown, FaCopy, FaEnvelope, FaEye, FaPen, FaRegPlayCircle, FaTasks, FaTrash, FaUser } from 'react-icons/fa'
import { MdOutlineStopCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { MenuButtonCmp } from '../../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { iIntegracao } from '../../../../../interfaces'

interface iCardProps {
  Imersao: iIntegracao
  onHabilitar: (id: string) => void
  onDelete: (id: string) => void
  onCopy: (id: string) => void
}

export const CardImersao: React.FC<iCardProps> = ({ Imersao, onHabilitar, onDelete, onCopy }) => {
  const nav = useNavigate()
  return (
        <Flex bg={'var(--c1)'} borderLeft={`3px solid ${Imersao.habilitado ? 'var(--Green2)' : 'var(--Red)'}`} padding={'1rem'} boxShadow={'var(--SombraBackground)'}>
            <Flex justifyContent={'space-between'} width={'100%'}>
                <Flex flexDir={'column'}>
                    <Flex gap={'.5rem'} height={'fit-content'}>
                        <Text fontWeight={'700'}>{Imersao.nome}</Text>
                    </Flex>
                    <Text fontSize={'.875rem'} color={Imersao.habilitado ? 'var(--Green2)' : 'var(--Red)'}>Status: <strong>{Imersao.habilitado ? 'Habilitada' : 'Desabilitada'}</strong></Text>
                </Flex>
                <Flex alignItems={'center'} justifyContent={'end'} gap={'2rem'}>
                    {Imersao.dataCriacao && <>
                        <Flex gap={'.75rem'} alignItems={'center'}>
                            <FaCalendar color='var(--Azul)' size={24}/>
                            <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                                <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'} w='6rem'>
                                    Data de criação
                                </Text>
                                <Text lineHeight={'1.125rem'} fontSize={'.875rem'} fontWeight={'800'}>{Imersao.dataCriacao}</Text>
                            </Flex>
                        </Flex>
                        <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                    </>}
                    <Flex gap={'.75rem'} alignItems={'center'}>
                        <FaTasks color='var(--Azul)' size={24}/>
                        <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                            <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{Imersao.itensIntegracao.length}</Text>
                            <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>Itens </Text>
                        </Flex>
                    </Flex>
                    <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                    <Flex gap={'.75rem'} alignItems={'center'}>
                        <FaUser color='var(--Azul)' size={24}/>
                        <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                            <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{Imersao.disparos}</Text>
                            <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>Disparos </Text>
                        </Flex>
                    </Flex>
                    <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                    <Flex gap={'.75rem'} alignItems={'center'}>
                        <FaEye color='var(--Azul)' size={24}/>
                        <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                            <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{Imersao.visualizacoes}</Text>
                            <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>Visualizações </Text>
                        </Flex>
                    </Flex>
                    <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                    <Flex>
                    <Menu>
                        <MenuButtonCmp
                            Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                            VarColor='Green2'
                        >Opções
                        </MenuButtonCmp>
                        <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
                            {Imersao.habilitado && <StyledMenuItem
                                onClick={() => nav(`${Imersao.id}/Convites`)}
                            >
                                <FaEnvelope style={{ marginRight: '.375rem' }} size={'14'}/>
                                Gestão de envios
                            </StyledMenuItem>}
                            <StyledMenuItem
                                onClick={() => onCopy(Imersao.id)}
                            >
                                <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                                Duplicar
                            </StyledMenuItem>
                            <StyledMenuItem
                                onClick={() => nav(Imersao.id)}
                            >
                                <FaPen style={{ marginRight: '.375rem' }} size={'14'}/>
                                Editar imersão
                            </StyledMenuItem>
                            {
                            Imersao.habilitado
                              ? <StyledMenuItem onClick={() => onHabilitar(Imersao.id)}
                            >
                              <MdOutlineStopCircle style={{ marginRight: '.375rem' }} size={'14'}/>
                              Desabilitar
                          </StyledMenuItem>
                              : <StyledMenuItem onClick={() => onHabilitar(Imersao.id)}
                              >
                                  <FaRegPlayCircle style={{ marginRight: '.375rem' }} size={'14'}/>
                                  Habilitar
                              </StyledMenuItem>
                            }
                            <StyledMenuItem
                            onClick={() => onDelete(Imersao.id)}
                            >
                                <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                                Excluir
                            </StyledMenuItem>
                        </MenuList>
                    </Menu>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}

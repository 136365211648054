import styled from 'styled-components'

export const Container = styled.table`
margin-top: 1rem;
border-spacing: 0 .25rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        color: var(--Gray2);
        font-weight: 700;
        font-size: .875rem;
    }
}


tbody{

    tr{
        background-color: var(--AzulOpacoBg);
        & td {
            font-size: .813rem;
            padding: .75rem;
        }

        .tdCenter{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    tr,th{
        border-collapse: collapse;
        padding: .5rem;
    }

    ul{
        width: fit-content;
    }
}
`

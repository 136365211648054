import { Flex } from '@chakra-ui/react'
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalAlerta: React.FC<iModal> = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-poupUp'
    >
      <Flex justifyContent={'space-between !important'} mb={'.75rem'}>
          <div style={{ width: '2rem' }}></div>
          <h2 style={{ color: 'var(--Red)' }}>Atenção!</h2>
          <MdClose color='var(--Red)' style={{ width: '2rem' }} cursor={'pointer'} size={24} onClick={onRequestClose} />
      </Flex>

      <Flex flexDir={'column'} gap={'.75rem'}>
          <p style={{ fontSize: '.875rem' }}>Todas as avaliações desta pesquisa estão encerradas! Para reenviar os convites aos participantes que ainda não preencheram, é necessário reabrir as avaliações específicas.</p>
          <p style={{ fontSize: '.875rem', fontWeight: 700 }}>Por favor, reabra as avaliações desejadas antes de prosseguir.</p>
          <Flex>
            <ButtonCmp VarColor='Green2' onClick={onRequestClose}>Continuar</ButtonCmp>
          </Flex>
      </Flex>
    </Modal>
  )
}

import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { ElementoTooltip } from '../../../../../../../components/ElementoTooltip'
import { iQuestao, iRespostaXColaborador } from '../../../../../../Jornada/Feedback/VisualizarRespostas'

interface iProps {
  resposta: iRespostaXColaborador
  questao: iQuestao
}

export const QuestaoComentario: React.FC<iProps> = ({ resposta, questao }) => {
  const res = resposta.respostas.find(e => e.questaoId === questao.id)
  return (
    <Flex flexDir={'column'} gap='.5rem'>
      <Box bgColor={'var(--Gray6)'} p={'.5rem'}>
        <Flex alignItems={'center'} gap={'.5rem'}>
          <ElementoTooltip label={resposta.nome}>
            <Avatar src={resposta.avatar} size={'2xs'} />
          </ElementoTooltip>
          <Text fontSize={'12px'} fontWeight={'400'}>{res?.data}</Text>
        </Flex>
        <Text>{res?.comentario}</Text>
      </Box>
    </Flex>
  )
}

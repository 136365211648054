import { Flex, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ImgSah } from '../../../components/ImgSah'
import { ModalCreateForm } from '../../../components/ModalCreateForm'
import { ModalDelete } from '../../../components/ModalDelete'
import { SearchCmp } from '../../../components/SearchCmp'
import { SelectCmp } from '../../../components/SelectCmp'
import { iIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { CardImersao } from './Components/CardImersao'

export const GestaoImersao: React.FC = () => {
  const nav = useNavigate()
  const [Imersoes, setImersoes] = useState<iIntegracao[]>([])
  const [TxtPesquisa, setTxtPesquisa] = useState('')

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  const [Filter, setFilter] = useState('4')

  function getImersoes(): void {
    setIsLoading(true)
    appApi.get('ImersaoExterna')
      .then(res => {
        setImersoes(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function converterData(val: string): Date {
    const [day, month, year] = val.split('/')
    const date = new Date(+year, +month - 1, +day)
    return date
  }

  function FilterBy(tipo: number): void {
    const arr = [...Imersoes]

    if (tipo === 1) {
      console.log('Aqui')
    } else if (tipo === 2) {
      arr.sort((a, b) => {
        return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      })
      arr.reverse()
    } else if (tipo === 4) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao as string)) > new Date(converterData(a.dataCriacao as string)) ? 1 : -1
      })
    } else if (tipo === 5) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao as string)) > new Date(converterData(a.dataCriacao as string)) ? 1 : -1
      })
      arr.reverse()
    }
    setImersoes(arr)
  }

  function HabilitarDesabilitar(id: string): void {
    appApi.patch(`ImersaoExterna/${id}/AlterarHabilitar`).then(() => {
      getImersoes()
      setFilter('4')
    }).catch(err => console.log(err))
  }

  function DeletarImersao(): void {
    appApi.delete(`ImersaoExterna/${Id as string}`)
      .then(() => {
        getImersoes()
        setFilter('4')
      })
      .catch(err => console.log(err))
  }

  function CriarImersao(Texto: string): void {
    appApi.post('ImersaoExterna', { nome: Texto }).then(response => {
      console.log(response.data)
      nav(response.data as string)
    }
    ).catch(e => console.log(e))
  }

  function CopiarImersao(nome: string): void {
    const form = {
      texto: nome
    }
    appApi.post(`ImersaoExterna/Copy/${Id as string}`, form)
      .then(() => {
        setModalCopyIsOpen(false)
        getImersoes()
        setFilter('4')
      })
      .catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onOpenModalCopy(id: string): void {
    setModalCopyIsOpen(true)
    setId(id)
  }

  function onCloseModalCopy(): void {
    setModalCopyIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    getImersoes()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta imersão?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarImersao}
      />

      <ModalCreateForm
        isOpen={ModalCreateIsOpen}
        onClose={() => setModalCreateIsOpen(false)}
        onCreate={CriarImersao}
      />

      <ModalCreateForm
        isOpen={ModalCopyIsOpen}
        onClose={onCloseModalCopy}
        onCreate={CopiarImersao}
        title={'Duplicar'}
      />
      <Flex justifyContent={'space-between'}>
        <h1>Imersão cultural </h1>
        <ButtonCmp
          VarColor='Green2'
          onClick={() => setModalCreateIsOpen(true)}
        >
          Criar nova imersão
        </ButtonCmp>
      </Flex>
      <Flex justifyContent={'space-between'} alignItems={'end'} my='1rem'>
          <FormControl>
            <SelectCmp title='Ordenação' width='14rem' value={Filter} OnChange={(e) => {
              setFilter(e)
              if (e === '2') {
                FilterBy(2)
              } else if (e === '3') {
                FilterBy(3)
              } else if (e === '4') {
                FilterBy(4)
              } else if (e === '5') {
                FilterBy(5)
              }
            }}>
              <option value={'2'}>A-Z</option>
              <option value={'3'}>Z-A</option>
              <option value={'4'}>Imersão mais recente</option>
              <option value={'5'}>Imersão mais antiga</option>
            </SelectCmp>
          </FormControl>

          <SearchCmp
            EnterAction={() => {}}
            OnChange={setTxtPesquisa}
            placeholder='Pesquisa'
            width='19rem'
          />
      </Flex>
      {
        (Imersoes.length > 0) && (
            <Flex display={'grid'} gap={'1rem 0rem'}>
              {
                Imersoes.filter((e) => {
                  if (TxtPesquisa === '') {
                    return e
                  } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                    return e
                  }
                  return null
                }).map((e, i) => {
                  return (
                    <CardImersao
                      key={i}
                      Imersao={e}
                      onHabilitar={HabilitarDesabilitar}
                      onDelete={onOpenModalDelete}
                      onCopy={onOpenModalCopy}
                    />
                  )
                })
              }
            </Flex>
        )
      }

      {
        (Imersoes.length === 0 && !IsLoading) && (
            <ImgSah entidade='imersão'>
              <ButtonCmp
                VarColor='Green2'
                onClick={() => setModalCreateIsOpen(true)}
              >
                Criar nova imersão
              </ButtonCmp>
            </ImgSah>
        )
      }
    </Body>
  )
}

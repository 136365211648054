import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { PropInvalida } from '../../../../../../../../Utils/Helper'
import { iLinhaTabela } from '../../../../../../../PesquisaPercepcao/ResultadoIndividual'

interface iProps {
  model: iLinhaTabela[]
}
export const Tabela: React.FC<iProps> = ({ model }) => {
  function CountEixos(): number {
    let contador = 1
    if (model.some(e => e.autoAvaliacao)) contador++
    if (model.some(e => e.gestor)) contador++
    if (model.some(e => e.pares)) contador++
    if (model.some(e => e.liderados)) contador++
    if (model.some(e => e.outros)) contador++
    if (model.some(e => e.altalideranca)) contador++
    if (model.some(e => e.consolidado)) contador++
    return contador
  }

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: `${(100 / CountEixos())}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: `${(100 / CountEixos())}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#66046B'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableCellCompetencia: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B32'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B15'
    }
  })

  function temColuna(tipo: number): boolean {
    if (tipo === 0 && model.some(e => e.autoAvaliacao)) return true
    else if (tipo === 1 && model.some(e => e.gestor)) return true
    else if (tipo === 2 && model.some(e => e.pares)) return true
    else if (tipo === 3 && model.some(e => e.liderados)) return true
    else if (tipo === 4 && model.some(e => e.outros)) return true
    else if (tipo === 6 && model.some(e => e.altalideranca)) return true
    else if (tipo === 7 && model.some(e => e.consolidado)) return true
    else return false
  }

  function ObterTabela(): iLinhaTabela[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaConsolidado: 0,
      mediaSemAuto: 0
    }
    model.forEach((e): void => {
      const parse = parseFloat(e.pares?.replace(',', '.') ?? '')
      medias.mediaPares = medias.mediaPares + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.autoAvaliacao?.replace(',', '.') ?? '')
      medias.mediaAutoAval = medias.mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.gestor?.replace(',', '.') ?? '')
      medias.mediaGestor = medias.mediaGestor + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.liderados?.replace(',', '.') ?? '')
      medias.mediaLiderados = medias.mediaLiderados + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto.replace(',', '.') ?? '')
      medias.mediaSemAuto = medias.mediaSemAuto + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.outros?.replace(',', '.') ?? '')
      medias.mediaOutros = medias.mediaOutros + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.altalideranca?.replace(',', '.') ?? '')
      medias.mediaAltaLideranca = medias.mediaAltaLideranca + (isNaN(parse) ? 0 : parse)
    })
    model.forEach((e): void => {
      const parse = parseFloat(e.consolidado?.replace(',', '.') ?? '')
      medias.mediaConsolidado = medias.mediaConsolidado + (isNaN(parse) ? 0 : parse)
    })

    const Medias = [{
      mediaAltaLideranca: medias.mediaAltaLideranca / model.filter(r => r.altalideranca).length,
      mediaPares: medias.mediaPares / model.filter(r => r.pares).length,
      mediaAutoAval: medias.mediaAutoAval / model.filter(r => r.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / model.filter(r => r.gestor).length,
      mediaLiderados: medias.mediaLiderados / model.filter(r => r.liderados).length,
      mediaOutros: medias.mediaOutros / model.filter(r => r.outros).length,
      mediaConsolidado: medias.mediaConsolidado / model.filter(r => r.consolidado).length,
      mediaSemAuto: medias.mediaSemAuto / model.filter(r => r.mediaSemAuto).length
    }]

    return [...model, {
      idQuestao: '',
      competencia: 'Média geral',
      autoAvaliacao: Medias[0].mediaAutoAval.toFixed(2),
      mediaSemAuto: Medias[0].mediaSemAuto.toFixed(2),
      altalideranca: Medias[0].mediaAltaLideranca.toFixed(2),
      gestor: Medias[0].mediaGestor.toFixed(2),
      liderados: Medias[0].mediaLiderados.toFixed(2),
      outros: Medias[0].mediaOutros.toFixed(2),
      pares: Medias[0].mediaPares.toFixed(2),
      consolidado: Medias[0].mediaConsolidado.toFixed(2),
      questoes: []
    }]
  }
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={styles.tableHeaderCol}>
          <Text style={styles.tableCell}>Competencia</Text>
        </View>
        {temColuna(0) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Autoavaliação</Text>
          </View>
        )}

        {temColuna(1) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Gestor</Text>
          </View>
        )}

        {temColuna(2) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Pares</Text>
          </View>
        )}

        {temColuna(3) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Liderados</Text>
          </View>
        )}

        {temColuna(4) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Outros</Text>
          </View>
        )}

        {temColuna(6) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Alta liderança</Text>
          </View>
        )}

        {temColuna(7) && (
          <View style={styles.tableHeaderCol}>
            <Text style={styles.tableCell}>Nota final</Text>
          </View>
        )}
      </View>

      {ObterTabela().map((e, i) => {
        return (
          <View style={{
            margin: 'auto',
            flexDirection: 'row'
          }}
            wrap={false}
            key={i}
          >
            <View style={styles.tableCol}>
              <Text style={e.competencia.includes('>_<') ? styles.tableCellQuestao : styles.tableCellCompetencia}>{e.competencia.replace('>_<', '')}</Text>
            </View>

            {temColuna(0) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10
                }}>{PropInvalida(e.autoAvaliacao) ? '-' : e.autoAvaliacao}</Text>
              </View>
            }

            {temColuna(1) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10
                }}>{PropInvalida(e.gestor) ? '-' : e.gestor}</Text>
              </View>
            }

            {temColuna(2) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10
                }}>{PropInvalida(e.pares) ? '-' : e.pares}</Text>
              </View>
            }

            {temColuna(3) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10
                }}>{PropInvalida(e.liderados) ? '-' : e.liderados}</Text>
              </View>
            }

            {temColuna(4) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10,
                  padding: 1
                }}>{PropInvalida(e.outros) ? '-' : e.outros}</Text>
              </View>
            }

            {temColuna(6) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10,
                  padding: 1
                }}>{PropInvalida(e.altalideranca) ? '-' : e.altalideranca}</Text>
              </View>
            }
            {temColuna(7) &&
              <View style={styles.tableCol}>
                <Text style={{
                  margin: 'auto',
                  fontSize: 10,
                  padding: 1
                }}>{PropInvalida(e.consolidado) ? '-' : e.consolidado}</Text>
              </View>
            }

          </View>
        )
      })}
    </View>
  )
}

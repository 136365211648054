/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'

interface iPieChartData{
  nome: string
  series: string[]
  labels: string[]
}

interface PiechartProps {
  id: string
  data: iPieChartData
}

export const PieChart: React.FC<PiechartProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: data.series,
      chart: {
        type: 'pie'
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        fontSize: data.series.length < 4 ? '16px' : '11px',
        formatter: function(val: string, opts: { w: { globals: { series: { [x: string]: string } } }, seriesIndex: string | number }) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return val.length > 13 ? val.substring(0, 13) + '...' : val + ' - ' + opts.w.globals.series[opts.seriesIndex]
        },
        onItemHover: {
          highlightDataSeries: false
        }
      },
      labels: data.labels,
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
        <Flex id={`chart-${id}`} w={'400px'}/>
  )
}

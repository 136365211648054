/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@chakra-ui/layout'
import { useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { iModalAgendamentoInput } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { DeleteElementByPosition, UpdateElementByPosition } from '../../../../../../Utils/Helper'

interface iProps {
  max?: string
  min?: string
  alvo?: iAvatarXInformacoes
  onClose: () => void
  onReagendar: (form: iModalAgendamentoInput) => void
}

export const ModalReagendar: React.FC<iProps> = ({ alvo, max, min, onClose, onReagendar }) => {
  const [DatasDisponiveis, setDatasDisponiveis] = useState<string[]>([new Date().toISOString().split('T')[0] + 'T00:00'])
  const [Comentario, setComentario] = useState<string>()

  return (
        <ModalBase
            isOpen
            Width='45rem'
            Titulo='Você está prestes a reagendar essa reunião'
            styleHeader={{ color: 'var(--Azul)' }}
            onClose={onClose}
        >
            <Flex padding={'0 1rem 1rem 1rem'} flexDir='column'>
                {alvo &&
                    <Text>Selecione uma opção para enviar a resposta para {alvo.info1}</Text>
                }
                <Flex flexDirection={'column'}>
                    {DatasDisponiveis?.map((e, i) => {
                      return (
                            <Flex alignItems={'center'} gap={'.5rem'} my={'.7rem'} key={i}>
                                <InputCmp
                                    w={'14.5rem'}
                                    value={e}
                                    OnChange={(data) => setDatasDisponiveis(UpdateElementByPosition([...DatasDisponiveis], i, data))}
                                    type={'datetime-local'}
                                    min={min?.replace('Z', '') ?? undefined}
                                    max={max?.replace('Z', '') ?? undefined}
                                />
                                {i > 0 &&
                                    <ElementoTooltip label='Excluir' bg={'var(--Red)'}>
                                        <MdOutlineClose
                                            onClick={() => setDatasDisponiveis(DeleteElementByPosition([...DatasDisponiveis], i))}
                                            cursor={'pointer'} color='var(--Red)'
                                        />
                                    </ElementoTooltip>
                                }
                            </Flex>
                      )
                    })}
                    {/* <ButtonCmp
                        px={'2rem'}
                        py={'.5rem'}
                        w={'12rem'}
                        h={'1.7rem'}
                        mt={'.3rem'}
                        onClick={() => setDatasDisponiveis([...DatasDisponiveis ?? [], new Date().toISOString().split('T')[0] + 'T00:00'])}
                        fontSize={'0.625rem'}
                        VarColor='Rosa'
                        leftIcon={<FaPlus />}
                    >Adicionar outra opção de data</ButtonCmp> */}
                </Flex>
                <TextAreaCmp
                    placeholder='Deixe uma justificativa'
                    OnChange={setComentario}
                />
            </Flex>
            <Flex padding={'0 1rem 1rem 1rem'} gap={'1rem'} justifyContent='end'>
                <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
                <ButtonCmp VarColor='Green2' onClick={() => onReagendar({
                  comentario: Comentario,
                  datas: DatasDisponiveis
                })}>Solicitar reagendamento</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Flex, Img, Input, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { iQuestao, iRespostaEscala } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import {
  Container,
  HeaderContainer,
  SkillAnswer,
  SkillQuestion,
  ComentarioContainer
} from './styles'
import { FormatarMensagemEditorDeTexto, PropInvalida } from '../../../Utils/Helper'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Body } from '../../Layouts/Body'
import { ButtonCmp } from '../../../components/ButtonCmp'

interface iResposta {
  questaoId: string
  respostaId: string
  traducoes?: iTraducao[]
  observacao?: string
}

interface iTraducao {
  idioma: string
  texto: string
}

interface iRespostaDiscursiva {
  questaoId: string
  observacao: string
}

interface iFormQuestao extends iQuestao {
  isError: boolean
  observacao?: string
}

interface iPostResposta {
  respostas: iResposta[]
  respostasDiscursivas?: iRespostaDiscursiva[]
}

interface LocationProps {
  pesquisaId: string
  tokenId: string
  idioma?: string
}

export const RespostaPesquisa: React.FC = () => {
  const nav = useNavigate()
  const { pesquisaId, tokenId, idioma } = useLocation().state as LocationProps
  const { pathname } = useLocation()
  const toast = useToast()

  const [Questoes, setQuestoes] = useState<iFormQuestao[]>([])
  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [NomePesquisa, setNomePesquisa] = useState('')
  const [NomeAvaliado, setNomeAvaliado] = useState('')
  const [ExibirCompetencia, setExibirCompetencia] = useState(false)
  const [isRespondido, setisRespondido] = useState(false)
  const [ExibirPeso, setExibitPeso] = useState(false)
  const [TxtEncerramento, setTxtEncerramento] = useState('')
  const [Obrigatorias, setObrigatorias] = useState<string[]>([])
  const [LogoEmpresa, setLogoEmpresa] = useState<string>('')
  const [Justificativa, setJustificativa] = useState(false)
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])

  const [IsLoading, setIsLoading] = useState(false)

  const refs = useRef<any>([])

  function obterTraduzido(ptBR: string, enUs: string, esES: string): string {
    return idioma === 'pt-BR'
      ? ptBR
      : idioma === 'en-US'
        ? enUs
        : esES
  }

  function getFormulario(): void {
    appApi.get(`TokenPesquisa/${pathname.includes('RespostaPesquisa') ? `Preenchimento/${tokenId}` : `Previsualizar/${pesquisaId ?? ''}`}`)
      .then(resp => {
        setQuestoes(resp.data.questoes)
        setEscalaRespostas(resp.data.respostas)
        setNomePesquisa(resp.data.nomePesquisa)
        setTxtEncerramento(resp.data.txtEncerramento)
        setObrigatorias(resp.data.obrigatorias)
        setLogoEmpresa(resp.data.logoEmpresa)
        setNomeAvaliado(resp.data.nomeAvaliado)
        setJustificativa(resp.data.justificativa)
        setExibirCompetencia(resp.data.exibirCompetencia)
        setExibitPeso(resp.data.exibirPeso)
      }).catch(() => setisRespondido(true))
  }

  function UpdateResposta(questaoId: string, respostaId: string): void {
    const respostas = [...Respostas]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].respostaId = respostaId
      setRespostas(respostas)
    }
  }

  function UpdateRespostaDiscursiva(questaoId: string, texto: string): void {
    const pos = Questoes.findIndex(e => e.id === questaoId)
    if (pos !== -1) {
      const quest = [...Questoes]
      quest[pos].observacao = texto
      setQuestoes([])
      setQuestoes(quest)
    }
  }

  function SelResposta(questaoId: string, resposId: string): void {
    if (!isSelectQuestao(questaoId)) {
      setRespostas(oldArray => [...oldArray, { questaoId: questaoId, respostaId: resposId }])
    } else {
      if (!isSelect(questaoId, resposId)) {
        UpdateResposta(questaoId, resposId)
      }
    }
  }

  const isSelect = (perguntaId: string, resposId: string): boolean => Respostas.find(e => e.questaoId === perguntaId && e.respostaId === resposId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => Respostas.find(e => e.questaoId === perguntaId) !== undefined

  function AddErrorStats(questoesId: string[]): void {
    const quests = [...Questoes].map((e) => {
      return { ...e, isError: questoesId.includes(e.id) }
    }).sort((a, b) => { return a.tipo - b.tipo })
    if (quests.some(e => e.isError)) {
      refs.current[quests.findIndex(e => e.isError)]?.focus()
    }
    setQuestoes([...quests])
  }

  function postForm(): void {
    const naoRespondidas = Questoes.filter(e => e.tipo !== 1 || Obrigatorias.some(a => a === e.id)).filter(e => (e.tipo !== 1 && Respostas.find(r => r.questaoId === e.id) === undefined) || (e.tipo === 1 && !e.observacao))
    if (naoRespondidas.length > 0) {
      AddErrorStats(naoRespondidas.map((e) => {
        return e.id
      }))
      toast({
        title: obterTraduzido('Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
          'Some fields were not filled in, review the form and see if it has fields in red',
          'Algunos campos no fueron llenados, revise el formulario y vea si tiene campos en rojo'),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    } else {
      const form: iPostResposta = {
        respostas: Respostas.map((e) => {
          const comentario = Questoes.find(q => q.id === e.questaoId)
          if (comentario) {
            return {
              questaoId: e.questaoId,
              respostaId: e.respostaId,
              observacao: comentario.observacao
            } as iResposta
          } else {
            return e
          }
        })
      }

      if (Questoes.some(e => e.tipo === 1)) {
        form.respostasDiscursivas = Questoes.filter(e => e.tipo === 1).map((e) => {
          return {
            questaoId: e.id,
            observacao: e.observacao
          } as iRespostaDiscursiva
        })
        const obrigatorios = form.respostasDiscursivas.filter(e => Obrigatorias.find(r => r === e.questaoId))
        if (obrigatorios.length > 0 && !obrigatorios.every(e => e.observacao !== undefined && e.observacao.length >= 3)) {
          toast({
            title: obterTraduzido('Algumas questões discursivas obrigatórias não foram preenchidas com no mínimo 3 caracteres ',
              'Some mandatory discursive questions were not filled in with at least 3 characters',
              'Algunas preguntas discursivas obligatorias no se completaron con al menos 3 caracteres'),
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        }
      }
      setIsLoading(true)
      appApi.post(`RespostaPesquisa/Responder/${tokenId}`, form)
        .then(() => {
          setisRespondido(true)
        })
        .catch(e => {
          setIsLoading(false)
          console.log(e)
        })
    }
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <WhiteContainer padding='0'>
        <HeaderContainer>
          <Flex flexDir={'column'}>
            <h1>{NomePesquisa}</h1>
            {!pathname.includes('Previsualizar') && <h3>Avaliado: {NomeAvaliado}</h3>}
          </Flex>
          <img alt='Logo da empresa' src={LogoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
        </HeaderContainer>
        <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
        {
          (!isRespondido && Questoes.length > 0) && (
            <Container>
              {
                Questoes?.filter(e => e.tipo !== 1).map((e, i) => {
                  return (
                    <Flex tabIndex={0} ref={(el) => (refs.current[i] = el)} flexDir={'column'} key={e.id}>
                      <SkillQuestion>
                        <div>
                          {ExibirCompetencia
                            ? <Flex gap={'.5rem'} alignItems={'center !important'} py={'.25rem'}>
                              <Img src={e.competenciaObj?.icone} h={'2.25rem'} w={'2.25rem'} objectFit={'contain'}/>
                              <Flex flexDirection={'column'} fontSize={'16px'} w={'100%'}>
                                <Text color={'var(--Gray3)'} fontSize={'13px'}>{e.competencia}</Text>
                                <Text fontSize={'16px'}>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</Text>
                              </Flex>
                            </Flex>
                            : <Text fontSize={'16px'} my={'.25rem'}>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</Text>}
                          {e.isError &&
                            <span
                            >
                              {obterTraduzido('* resposta requerida', 'required answer', 'respuesta requerida')}
                            </span>}
                        </div>
                        {PropInvalida(e.escalaAlternativaId) &&
                          EscalaRespostas.map((r: iRespostaEscala, i2) => {
                            return (
                              <SkillAnswer
                                key={i2}
                                type="button"
                                isSelected={isSelect(e.id, r.id)}
                                onClick={() => SelResposta(e.id, r.id)}
                              >
                                <div>
                                  <FaCheckCircle />
                                </div>
                                <span>{idioma ? r.traducoes?.find(re => re.idioma === idioma)?.texto ?? r.resposta : r.resposta}</span>
                                {ExibirPeso && r.peso !== -1 && (
                                  <span style={{ marginLeft: '.4rem', fontWeight: 700 }}>({r.peso})</span>
                                )}
                              </SkillAnswer>
                            )
                          })
                        }
                        {!PropInvalida(e.escalaAlternativaId) &&
                          e.escalaAlternativa?.map((r: iRespostaEscala, i2) => {
                            return (
                              <SkillAnswer
                                key={i2}
                                type="button"
                                isSelected={isSelect(e.id, r.id)}
                                onClick={() => SelResposta(e.id, r.id)}
                              >
                                <div>
                                  <FaCheckCircle />
                                </div>
                                <span>{idioma ? r.traducoes?.find(re => re.idioma === idioma)?.texto ?? r.resposta : r.resposta}</span>
                                {ExibirPeso && r.peso !== -1 && (
                                  <span style={{ marginLeft: '.4rem', fontWeight: 700 }}>({r.peso})</span>
                                )}
                              </SkillAnswer>
                            )
                          })
                        }
                        {(Justificativa || !PropInvalida(e.escalaAlternativaId)) && <Input
                          marginTop={'1rem'}
                          borderColor='var(--Gray4)'
                          placeholder='Deixe sua justificativa para a nota atribuída a esta questão (opcional)'
                          onBlur={(p) => UpdateRespostaDiscursiva(e.id, p.target.value)}
                        />}
                      </SkillQuestion>
                    </Flex>
                  )
                })
              }

              {
                Questoes?.filter(e => e.tipo === 1).map((e: iFormQuestao, i) => {
                  return (
                    <ComentarioContainer key={e.id}>
                      <Flex alignItems={'center'}>
                        <h3>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</h3>
                        {e.isError &&
                        <span
                          style={{ color: 'var(--terc3)' }}
                        >
                          {obterTraduzido('* resposta requerida', 'required answer', 'respuesta requerida')}
                        </span>}
                      </Flex>
                      <textarea
                        ref={(el) => (refs.current[i + Questoes?.filter(e => e.tipo !== 1).length] = el)}
                        placeholder={obterTraduzido('Digite um comentário sobre o avaliado', 'Leave a comment about the reviewer', 'Ingrese un comentario sobre el revisor')}
                        onChange={(r) => UpdateRespostaDiscursiva(e.id, r.target.value)}
                        minLength={Obrigatorias.find(r => r === e.id) !== undefined ? 3 : 0}
                      />
                    </ComentarioContainer>
                  )
                })
              }
              {!pathname.includes('Previsualizar') &&
                <Flex justifyContent={'center'} margin={'1rem 0rem'}>
                  <ButtonCmp isLoading={IsLoading} onClick={postForm} h={'2.125rem'} fontSize={'1rem'} VarColor='Green2'>{
                    obterTraduzido('Enviar resposta', 'Send answer', 'Enviar respuesta')
                  }</ButtonCmp>
                </Flex>}
              {pathname.includes('Previsualizar') &&
                <Flex marginTop={'1rem'} justifyContent={'flex-end'}>
                  <ButtonCmp
                    h={'2.125rem'}
                    fontSize={'1rem'}
                    VarColor='Rosa'
                    onClick={() => nav(-1)}
                  >Finalizar pré-visualização</ButtonCmp>
                </Flex>
              }
            </Container>
          )
        }

        {
          (isRespondido) && (
            <Flex background={'white'} height={'100%'}>
              <Flex padding={'3rem 3rem'} flexDirection={'column'}>
                <h3 dangerouslySetInnerHTML={{
                  __html: FormatarMensagemEditorDeTexto(TxtEncerramento ?? obterTraduzido('Obrigado pela participação!',
                    'Thank you for your participation!',
                    '¡Gracias por su participación!'))
                }}></h3>
                <Text
                  fontWeight={'400'}
                  style={{
                    color: 'var(--Gray3)',
                    marginTop: '.25rem'
                  }}>
                  {obterTraduzido('Respostas enviadas com sucesso!',
                    'Answers sent successfully!',
                    '¡Respuestas enviadas con éxito!')}</Text>
                {
                  (localStorage.getItem('token')) && (
                    <Flex marginTop={'.75rem'}>
                      <ButtonCmp
                        h={'2.125rem'}
                        fontSize={'1rem'}
                        VarColor='Green2'
                        onClick={() => nav('/')}
                      >Voltar</ButtonCmp>
                    </Flex>
                  )
                }
              </Flex>
            </Flex>
          )
        }
      </WhiteContainer>
    </Body>
  )
}

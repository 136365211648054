import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { iLinhaTabela } from '../../../..'
import { PropInvalida } from '../../../../../../../Utils/Helper'

interface iProps {
  model: iLinhaTabela[]
}
export const Tabela: React.FC<iProps> = ({ model }) => {
  function CountEixos(): number {
    let contador = 1
    if (model.some(e => e.autoAvaliacao)) contador++
    if (model.some(e => e.gestor)) contador++
    if (model.some(e => e.pares)) contador++
    if (model.some(e => e.liderados)) contador++
    if (model.some(e => e.outros)) contador++
    if (model.some(e => e.altalideranca)) contador++
    if (model.some(e => e.consolidado)) contador++
    return contador
  }

  const styles = StyleSheet.create({
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: `${(100 / CountEixos())}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: `${(100 / CountEixos())}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#66046B'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableCellCompetencia: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B32'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B15'
    }
  })

  function temColuna(tipo: number): boolean {
    if (tipo === 0 && model.some(e => e.autoAvaliacao)) return true
    else if (tipo === 1 && model.some(e => e.gestor)) return true
    else if (tipo === 2 && model.some(e => e.pares)) return true
    else if (tipo === 3 && model.some(e => e.liderados)) return true
    else if (tipo === 4 && model.some(e => e.outros)) return true
    else if (tipo === 6 && model.some(e => e.altalideranca)) return true
    else if (tipo === 7 && model.some(e => e.consolidado)) return true
    else return false
  }

  return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableHeaderCol}>
                    <Text style={styles.tableCell}>Competencia</Text>
                </View>
                {temColuna(0) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Autoavaliação</Text>
                    </View>
                )}

                {temColuna(1) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Gestor</Text>
                    </View>
                )}

                {temColuna(2) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Pares</Text>
                    </View>
                )}

                {temColuna(3) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Liderados</Text>
                    </View>
                )}

                {temColuna(4) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Outros</Text>
                    </View>
                )}

                {temColuna(6) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Alta liderança</Text>
                    </View>
                )}

                {temColuna(7) && (
                    <View style={styles.tableHeaderCol}>
                        <Text style={styles.tableCell}>Nota final</Text>
                    </View>
                )}
            </View>

            {model.map((e, i) => {
              return (
                        <View style={{
                          margin: 'auto',
                          flexDirection: 'row'
                        }}
                            wrap={false}
                            key={i}
                        >
                            <View style={styles.tableCol}>
                                <Text style={e.competencia.includes('>_<') ? styles.tableCellQuestao : styles.tableCellCompetencia}>{e.competencia.replace('>_<', '')}</Text>
                            </View>

                            {temColuna(0) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10
                                    }}>{PropInvalida(e.autoAvaliacao) ? '-' : e.autoAvaliacao}</Text>
                                </View>
                            }

                            {temColuna(1) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10
                                    }}>{PropInvalida(e.gestor) ? '-' : e.gestor}</Text>
                                </View>
                            }

                            {temColuna(2) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10
                                    }}>{PropInvalida(e.pares) ? '-' : e.pares}</Text>
                                </View>
                            }

                            {temColuna(3) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10
                                    }}>{PropInvalida(e.liderados) ? '-' : e.liderados}</Text>
                                </View>
                            }

                            {temColuna(4) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1
                                    }}>{PropInvalida(e.outros) ? '-' : e.outros}</Text>
                                </View>
                            }

                            {temColuna(6) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1
                                    }}>{PropInvalida(e.altalideranca) ? '-' : e.altalideranca}</Text>
                                </View>
                            }
                            {temColuna(7) &&
                                <View style={styles.tableCol}>
                                    <Text style={{
                                      margin: 'auto',
                                      fontSize: 10,
                                      padding: 1
                                    }}>{PropInvalida(e.consolidado) ? '-' : e.consolidado}</Text>
                                </View>
                            }
                        </View>
              )
            })}
        </View>
  )
}

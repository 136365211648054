import styled, { css } from 'styled-components'

interface ISmallColumnProps {
  isActive?: boolean
}

export const Container = styled.div`
  table {
    width: 100%;
    border-spacing: 0 .625rem;
    font-size: .875rem;

    th {
      font-weight: normal;
      border-bottom: 2px solid var(--c4);
      text-align: left;

      padding: .5rem 1.5rem !important;
    }
    
    tbody{
      tr{
        box-shadow:var(--SombraBtns);
        :hover{
          background: var(--AzulOpacoBg);
          box-shadow:0px 0px 2px .125px var(--Azul);
        }
      }
    }

    td {
      padding: .75rem 1.5rem !important;

      strong {
        font-weight: normal;
      }

      span {
        font-size: .813rem;
        color: var(--c6);
      }
    }
  }
`

export const SmallColumnHeader = styled.th`
  text-align: center !important;
`

export const AvatarColumn = styled.td`
  width: 6rem;

  div {
    display: flex;


    img:hover{
      transition: all .4s ease;
      cursor: pointer;
      filter: brightness(.825);
    }
  }
`

export const SmallColumn = styled.td<ISmallColumnProps>`
  text-align: center;
  z-index: 999;
  > svg {
    font-size: .875rem;
    color: var(--c6);

    ${(props) =>
      props.isActive &&
      css`
        color: var(--Azul);
      `}
  }
  div {
    display: flex;
    justify-content: flex-end;

    button {
      background: transparent;
      padding: 0.25rem;
      border: 0;
      outline: 0;

      font-size: 1rem;

      svg {
        display: block;
      }

      & + button {
        color: var(--Red);

        margin-left: 0.5rem;
      }
    }
  }
`

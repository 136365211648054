/* eslint-disable array-callback-return */
import {
  Avatar, Flex, FormControl, FormLabel, Image, Menu, MenuItem, MenuList, Tab,
  TabList, TabPanel, TabPanels, Tabs, Text, Link, Box, Checkbox, NumberInput, NumberInputField,
  NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Spinner, useToast, Grid
} from '@chakra-ui/react'
import { FaClipboard, FaDownload, FaEye, FaEyeSlash, FaFileDownload, FaSave } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ComentariosContainer, InfoBox, TabelaPersonalizada } from './styles'
import { Recomendacao } from './components/Recomendacao'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeAvaliadores.svg'
import { PropInvalida } from '../../../Utils/Helper'
import { DashBox } from '../../../components/PerfilColaborador/styles'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { AiFillTool } from 'react-icons/ai'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { BarChartIndicativa } from './components/BarChart'
import { AxiosResponse } from 'axios'
import { ModalPDI } from '../../PesquisaPercepcao/ResultadoIndividual/components/ModalPDI'
import { parseJwt } from '../../../services/token'
import { TabResultadoIndividual } from './components/Tabs/ResultadoIndividual'
import { TabTabelaAvaliacoes } from './components/Tabs/TabelaAvaliacoes'
import { iAcao, TabRecomendacao } from './components/Tabs/Recomendacoes'
import { TabHistorico } from './components/Tabs/Historico'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './components/RelatorioPDF'
import { BarChartIndicativaPDF } from './components/BarChartPDF'
import { MenuButtonCmp } from '../../../components/MenuButtonCmp'

export interface iResultadoIndividualIndicativa {
  tabelaPotenciaisMelhorias: iPotenciaisMelhorias
  tabelaAvaliacoes2: iLinhaTabelaAvaliacoes[]
  discursivas: string[]
  tabelaRecomendacoes: iRecomendacoes
  qtdEscolhas: number
  competencias: iCompetencia[]
  resumoCompetencias: iCompetenciaPDI[]
  recomendacoes: iCompetenciaPDI[]
  grafico: IDadoGrafico2[]
  itemHistorico: iItemHistorico[]
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  participantes: number[]
  isResponsavel: boolean
  permitirAlterarPeso: boolean
  adicionarPDI: boolean
  pdiNormal?: string
  pdiAvaliacao?: string
  acoes?: iAcao[]
  liberarResultado: boolean
}

interface iPotenciaisMelhorias {
  pesosAvaliadores: iPesosAvaliadores[]
  alterarPesos: boolean
  respostas: iResposta[]
  autoavaliacao: iAutoavaliacao[]
  stakes: number[]
}

interface iPesosAvaliadores {
  tipo: number
  peso: number
}

interface iResposta {
  notasStakes: iNotasStake[]
  notaPonderada: number
  competencia: string
  competenciaId: string
  priorizada: boolean
}

interface iAutoavaliacao {
  notas: number
  competencia: string
  competenciaId: string
}

interface iNotasStake {
  stake: string
  notas: number
  autoAvaliacao: boolean
}

export interface IDadoGrafico2 {
  media: string
  nome: string
  selo?: number[]
  melhoria: boolean
}

export interface iLinhaTabela {
  competencia: string
  autoAvaliacao: string
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  questoes: iLinhaTabela[]
}

export interface iLinhaTabela2 {
  competencia: string
  autoAvaliacao: string
  destaque?: number
  motiva?: number
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  pontuacaoPonderada: string
  questoes: iLinhaTabela2[]
  priorizar: boolean
}

export interface iTabela2 {
  linhas: iLinhaTabela2[]
  pesos: iPesos
  recomendacoes: iRecomendacoes
  tabelaAvaliacoes: iLinhaTabelaAvaliacoes[]
  itemHistorico: iItemHistorico[]
}

export interface iLinhaTabelaAvaliacoes {
  competencia: string
  selos2: iSelo[]
}

export interface iSelo {
  selo: number
  stake: number
}

export interface iItemHistorico {
  pesquisa: string
  avaliacaoId: string
  data: string
  destaques: iDestaqueHistorico[]
  potencialMelhoria: iDestaqueHistorico[]
}

interface iDestaqueHistorico {
  competencia: string
  avatar: string
}

export interface iRecomendacoes {
  melhorias: iRecomendacao[]
  destaques: iRecomendacao[]
  outros: iRecomendacao[]
}

interface iRecomendacao {
  nome: string
  nota: number
  selos: number[]
  selosStake: number[]
}

interface iPesos {
  lider: string
  pares: string
  liderados: string
  outros: string
}

export const ResultadoIndividualIndicativa: React.FC = () => {
  const [ComentariosVisiveis, setComentariosVisiveis] = useState(false)
  const PotenciaisMelhoriasDefault: iPotenciaisMelhorias = {
    pesosAvaliadores: [],
    alterarPesos: false,
    respostas: [],
    stakes: [],
    autoavaliacao: []
  }

  const RecomendacoesDefault: iRecomendacoes = {
    melhorias: [],
    destaques: [],
    outros: []
  }

  const ModeloDefault: iResultadoIndividualIndicativa = {
    tabelaPotenciaisMelhorias: PotenciaisMelhoriasDefault,
    isResponsavel: false,
    permitirAlterarPeso: false,
    tabelaAvaliacoes2: [],
    discursivas: [],
    tabelaRecomendacoes: RecomendacoesDefault,
    recomendacoes: [],
    resumoCompetencias: [],
    grafico: [],
    itemHistorico: [],
    competencias: [],
    qtdEscolhas: 2,
    avaliado: {
      id: '',
      nome: ''
    },
    nomePesquisa: '',
    notaMaximaEscala: 0,
    participantes: [],
    pesquisaId: '',
    totalAvaliadores: 0,
    totalRespostas: 0,
    liberarResultado: false,
    adicionarPDI: false
  }

  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const { cid } = parseJwt()
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const [ModoEdicao, setModoEdicao] = useState(false)
  const [PotenciaisMelhorias, setPotenciaisMelhorias] = useState<iPotenciaisMelhorias>(PotenciaisMelhoriasDefault)
  const [CompetenciasPriorizadas, setCompetenciasPriorizadas] = useState<string[]>([])
  const [Modelo, setModelo] = useState<iResultadoIndividualIndicativa>(ModeloDefault)
  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)
  const [Grafico, setGrafico] = useState<string>()
  const [IsLoading, SetIsLoading] = useState(false)
  const [BaixarRelatorio, SetBaixarRelatorio] = useState(false)

  const redirectPage = (url: string): any => { window.location.href = url }
  function getResultadoIndicativa(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/ResultadoIndividualIndicativa`)
      .then((res: AxiosResponse<iResultadoIndividualIndicativa>) => {
        setPotenciaisMelhorias(res.data.tabelaPotenciaisMelhorias)
        setCompetenciasPriorizadas(res.data.tabelaPotenciaisMelhorias.respostas.filter(e => e.priorizada).map(e => e.competenciaId))
        setModelo(res.data)
        if (res.data.acoes == null || res.data.acoes === undefined || res.data.acoes.length === 0) {
          ObterPlanoAcao()
        }
      })
      .catch(err => console.log(err))
      .finally(() => SetIsLoading(false))
  }

  function ObterNomeAvaliador(tipo: number): string {
    const data = {
      nomeAvaliador: ''
    }

    switch (tipo) {
      case 6:
        data.nomeAvaliador = 'Alta liderança'
        break
      case 4:
        data.nomeAvaliador = 'Outros'
        break
      case 3:
        data.nomeAvaliador = 'Liderados'
        break
      case 2:
        data.nomeAvaliador = 'Pares'
        break
      case 1:
        data.nomeAvaliador = 'Gestores'
        break
      default:
        break
    }

    return data.nomeAvaliador
  }

  function ObterMotivacao(tipo: number): string {
    const data = {
      motivacao: ''
    }

    switch (tipo) {
      case 1:
        data.motivacao = 'Mais motiva'
        break
      case -1:
        data.motivacao = 'Menos motiva'
        break
      default:
        break
    }

    return data.motivacao
  }

  function ObterDestaque(tipo: number): string {
    const data = {
      motivacao: ''
    }

    switch (tipo) {
      case 1:
        data.motivacao = 'Destaque'
        break
      case -1:
        data.motivacao = 'Necessita de melhora'
        break
      default:
        break
    }

    return data.motivacao
  }

  function SalvarPesoAvaliador(tipo: number, peso: number): void {
    const copy = [...PotenciaisMelhorias.pesosAvaliadores]
    const pos = copy.findIndex(e => e.tipo === tipo)
    if (pos !== -1) {
      copy[pos] = {
        peso: peso,
        tipo: tipo
      }
      setPotenciaisMelhorias({ ...PotenciaisMelhorias, pesosAvaliadores: copy })
    }
  }

  function SalvarPriorizacoes(id: string): void {
    const copy = [...CompetenciasPriorizadas]
    const pos = copy.findIndex(e => e === id)

    if (pos !== -1) {
      setCompetenciasPriorizadas([...CompetenciasPriorizadas].filter(e => e !== id))
    } else {
      setCompetenciasPriorizadas([...CompetenciasPriorizadas, id])
    }
  }

  function AtualizarCampos(): void {
    const data = {
      prioridades: CompetenciasPriorizadas,
      pesos: PotenciaisMelhorias.pesosAvaliadores
    }

    appApi.put(`AvaliacaoPesquisa/${avaliacaoId as string}/Salvar/Campos`, data)
      .then(() => ObterPlanoAcao())
      .catch((err) => { console.log(err) })
  }

  function SalvarAlteracoes(): void {
    setModoEdicao(false)
    AtualizarCampos()
  }

  function ObterCorPonderada(nota: number): string {
    switch (true) {
      case nota > 0.3:
        return '#6FCF97'
      case nota > -0.3 && nota < 0.3:
        return '#E0E0E0'
      case nota < -0.3:
        return '#f6b881'
      default:
        return ''
    }
  }

  function ObterBackgroundFundo(nota: number, tipo: number): string {
    if (tipo === 1) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return 'var(--Green2Opaco)'
        case nota < 0:
          return 'var(--MostardaOpaco)'
        default:
          return ''
      }
    }

    if (tipo === 2) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return 'var(--Green2Opaco)'
        case nota < 0:
          return '#F6B881'
        default:
          return ''
      }
    }

    if (tipo === 3) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return '#8CB0F0'
        case nota < 0:
          return 'var(--MostardaOpaco)'
        default:
          return ''
      }
    }

    return ''
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${cid === Modelo.avaliado.id ? Modelo.avaliado.gestorId ?? cid : cid}&colaboradorId=${Modelo.avaliado.id}&avaliacaoId=${avaliacaoId ?? ''}${pathname.toLowerCase().includes('indicativa') ? '&ia=false' : ''}`)
    } else {
      appApi.post(`PDI/${Modelo.pdiNormal ?? ''}/${pathname.toLowerCase().includes('indicativa') ? 'IA' : 'Avalicao'}/${avaliacaoId ?? ''}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${Modelo.pdiNormal ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  function AlterarVisibiliade(visibilidade: boolean): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/AlterarVisibilidade?visibilidade=${visibilidade ? 0 : 1}`)
      .then(() => {
        setModelo({ ...Modelo, liberarResultado: !Modelo.liberarResultado })
      })
      .catch(err => console.log(err))
  }

  function ObterPlanoAcao(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId}/PlanoAcao`)
      .then(res => {
        getResultadoIndicativa()
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Não foi possível gerar as recomendações por IA, recarregue a página ou tente novamente mais tarde!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => SetIsLoading(false))
  }

  function CheckComentariosVisiveis(): void {
    appApi.get('Empresa/Comentarios')
      .then(res => {
        setComentariosVisiveis(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    CheckComentariosVisiveis()
    getResultadoIndicativa()
  }, [avaliacaoId])

  useEffect(() => {
    if (BaixarRelatorio) {
      setTimeout(() => {
        const botao: any = document.getElementById('botaoBaixarRelatorio')
        console.log(botao.href)
        const link = document.createElement('a')
        link.href = botao.href
        link.download = `Relatorio_Pesquisa_Percepcao_${Modelo.avaliado.nome.replace(/\s/g, '_')}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        SetBaixarRelatorio(false)
      }, 3000)
    }
  }, [BaixarRelatorio])

  return (
    <Body isLoading={IsLoading}>
      <ModalPDI
        recomendacoes={Modelo.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      <Flex pos={'absolute'} left={'-9999px'}>
        <BarChartIndicativaPDF
          model={Modelo.grafico}
          setGrafico={(e) => setGrafico(e)}
        />
      </Flex>

      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: pathname.includes('Desempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'
          },
          {
            nome: Modelo?.nomePesquisa ?? '',
            path: -1
          }, {
            nome: Modelo?.avaliado?.nome ?? '',
            path: ''
          }
        ]} />
      </Flex>

      <Flex marginBottom={'1rem'} alignItems={'center'} justifyContent={'space-between'} flexFlow='nowrap'>
        <Flex flexDir={'column'} marginBottom='1rem'>
          <Flex>
            <Avatar
              marginRight={'.625rem'}
              w={'75px'}
              h={'75px'}
              name={Modelo?.avaliado.nome}
              src={Modelo?.avaliado.avatar}
            />
            <Flex flexDir={'column'} justifyContent={'center'}>
              <h4>Avaliado</h4>
              <Text fontSize={'1.25rem'} fontWeight={'700'} lineHeight={'1.25rem'}>{Modelo?.avaliado.nome}</Text>
              <h4>{Modelo?.avaliado?.nomeDepartamento}</h4>
            </Flex>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          {(Modelo.avaliado.perfilPI) && (
            <DashBox style={{ padding: '0 .5rem', position: 'relative', width: '15rem', height: '6rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
              <Flex flexDirection={'column'} gap={'.5rem'}>
                <Flex flexDir='column' width='100%' alignItems={'center'}>
                  {
                    (Modelo.avaliado.perfilPI?.nomeSelo) && (
                      <Text fontWeight={'500'} fontSize={'12px'}>Perfil comportamental: <Text as={'span'} fontWeight={900}>{Modelo.avaliado.perfilPI?.nomeSelo}</Text></Text>
                    )
                  }
                  {
                    (Modelo.avaliado.perfilPI?.urlSelo) && (
                      <Image mt={'.3rem'} width={'2rem'} src={Modelo.avaliado.perfilPI.urlSelo} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButtonCmp small VarColor='Green2'>
                    Baixar Relatorios
                  </MenuButtonCmp>
                  <MenuList bgColor='var(--Azul)'>
                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlResultado)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlResultado}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Perfil comportamental
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlQuadro)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlQuadro}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento pessoal
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlPlacard)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlPlacard}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Placard
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem bgColor={'var(--c1)'}>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'13px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlQuadroGestor}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento gestores
                          </Box>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
          <InfoBox>
            <Flex pl={'1rem'}>
              <Image w={'2rem'} h={'2rem'} src={IconeAvaliadores} />
            </Flex>
            <Flex pr={'.75rem'} flexDir={'column'} gap={'.325rem'}>
              <h4>{Modelo?.totalRespostas}/{Modelo.totalAvaliadores}</h4>
              <span>Respostas/Total</span>
            </Flex>
          </InfoBox>
        </Flex>
      </Flex>

      {(ModoEdicao) && (
        <WhiteContainer>
          <Text fontSize={'24px'} fontWeight={'700'} mb={'1rem'} color={'var(--a4)'}>Potenciais para melhorias</Text>

          <TabelaPersonalizada >
            <thead>
              <tr>
                <th></th>
                <th colSpan={(PotenciaisMelhorias?.stakes?.length ?? 0) + 1}>Stakeholders</th>
                <th colSpan={2}>Autoavaliação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontSize: '14px' }}>Competência</td>
                {PotenciaisMelhorias?.stakes.map((stakes, i) => {
                  return (
                    <td key={i} style={{ fontSize: '14px' }}>{ObterNomeAvaliador(stakes)}</td>
                  )
                })}
                <td style={{ fontSize: '14px' }}>Pontuação <br /> ponderada</td>
                <td style={{ fontSize: '14px' }}>Destaques/Melhorias</td>
                <td style={{ fontSize: '14px' }}>O que motiva?</td>
                <td style={{ fontSize: '14px' }}>Priorizar</td>
              </tr>

              {PotenciaisMelhorias?.respostas.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ fontSize: '14px' }}>{e.competencia}</td>

                    {e.notasStakes.filter(e => e.stake !== 'AutoAvaliacao').map((r, i) => {
                      return (
                        // <td key={i} style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 1) }}>{r.notas !== 0 ? r.notas : ''}</td>
                        <td key={i} style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 1) }}></td>
                      )
                    })}

                    {/* <td style={{ fontSize: '12px', background: e.competencia ? ObterCorPonderada(e.notaPonderada) : '' }}>{e.notaPonderada.toFixed(2)}</td> */}
                    <td style={{ fontSize: '12px', background: e.competencia ? ObterCorPonderada(e.notaPonderada) : '' }}></td>

                    {e.notasStakes.filter(e => e.stake === 'AutoAvaliacao' && !e.autoAvaliacao).map((r, i) => {
                      return (
                        <td key={i} style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 2) }}>{ObterDestaque(r.notas)}</td>
                      )
                    })}

                    {e.notasStakes.filter(e => e.stake === 'AutoAvaliacao' && e.autoAvaliacao).map((r, i) => {
                      return (
                        <td key={i} style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 3) }}>{ObterMotivacao(r.notas)}</td>
                      )
                    })}

                    {!PotenciaisMelhorias?.respostas.some(e => e.notasStakes.some(r => r.autoAvaliacao)) &&
                      <>
                        <td></td>
                        <td></td>
                      </>
                    }

                    <td>
                      <Checkbox
                        isDisabled={!Modelo.isResponsavel}
                        isChecked={CompetenciasPriorizadas.some(compId => compId === e.competenciaId)}
                        mt={'.2rem'}
                        onChange={() => SalvarPriorizacoes(e.competenciaId)}
                      />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </TabelaPersonalizada>

          <Box mt={'2.2rem'}>
            <Text mb={'.4rem'} fontWeight={'600'}>Pesos definidos para os tipos de avaliadores</Text>

            <Flex gap='1rem' >
              {PotenciaisMelhorias?.pesosAvaliadores.map((e, i) => {
                return (
                  <FormControl w={'unset'} key={i} gap={'.5rem'} alignItems={'center'} display={'flex'}>
                    <FormLabel whiteSpace={'nowrap'} m={'0'} fontSize={'12px'} fontWeight={'400'}>{ObterNomeAvaliador(e.tipo)}</FormLabel>
                    <NumberInput width={'4rem'} size={'sm'} isDisabled={!Modelo.permitirAlterarPeso} onChange={(peso) => SalvarPesoAvaliador(e.tipo, Number(peso))} value={e.peso}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormControl>
                )
              })}
            </Flex>

            <Flex mt={'2rem'} justifyContent={'space-between'}>
              <ButtonCmp fontSize={'13px'} VarColor='c6' onClick={() => setModoEdicao(false)}>Voltar</ButtonCmp>
              <ButtonCmp fontSize={'13px'} VarColor='Green1' rightIcon={<FaSave />} onClick={SalvarAlteracoes}>Salvar alterações</ButtonCmp>
            </Flex>

          </Box>
        </WhiteContainer>
      )}
      {(!ModoEdicao) && (
        <>
          {(Modelo.recomendacoes.length > 0) && (
            <>
              <Flex justifyContent={'start'}>
                <h3>Competências a desenvolver</h3>
              </Flex>

              <WhiteContainer margin='.75rem 0'>
                <Grid templateColumns='repeat(2, 1fr)' gap={'1rem'}>
                  {
                    Modelo?.recomendacoes.map((e, i) => {
                      return (
                        <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                      )
                    })
                  }
                </Grid>
              </WhiteContainer>
            </>
          )}

          <Flex gap={'.5rem'}>
            <ButtonCmp isLoading={IsLoading || BaixarRelatorio} VarColor='Green2' leftIcon={<FaDownload/>} onClick={() => SetBaixarRelatorio(true)}>Baixar relatório</ButtonCmp>
            {BaixarRelatorio && <PDFDownloadLink
              document={
                <RelatorioPDF
                  model={Modelo}
                  Grafico={Grafico}
                  GraficoDados={Modelo.grafico}
                />
              }
              id='botaoBaixarRelatorio'
            >
              {() =>
                <Box pos={'absolute'}></Box>
              }
            </PDFDownloadLink>}
            {(Modelo.adicionarPDI && pathname.includes('AnaliseDesempenho') && PropInvalida(Modelo.pdiAvaliacao)) && (
              <ButtonCmp
                fontWeight={'500'}
                onClick={() => {
                  !PropInvalida(Modelo.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
                }}
                leftIcon={<FaClipboard />} VarColor='Green2'
              >Adicionar PDI</ButtonCmp>
            )}

            {(pathname.includes('AnaliseDesempenho') && !PropInvalida(Modelo.pdiAvaliacao)) && (
              <ButtonCmp
                fontWeight={'500'}
                onClick={() => nav(`/Desempenho/PDI/Editar/${Modelo.pdiAvaliacao ?? ''}`)}
                leftIcon={<FaClipboard />} VarColor='Green2'
              >Editar PDI</ButtonCmp>
            )}

            {parseJwt().adm === 'True' &&
              <ButtonCmp
                onClick={() => redirectPage(`${urlApi}/AvaliacaoPesquisa/${avaliacaoId as string}/baseAvaliacaoCsv/?token=${localStorage.getItem('token') as string}`)}
                VarColor='Green2'
                leftIcon={<FaFileDownload />}
              >Base</ButtonCmp>
            }

            {Modelo.isResponsavel && (
              <ButtonCmp
                VarColor='Gray3'
                leftIcon={<AiFillTool />}
                onClick={() => setModoEdicao(true)}
              >Modificar competências para melhoria</ButtonCmp>
            )}

            {(Modelo.liberarResultado && (parseJwt().adm === 'True' || parseJwt().cid === Modelo.avaliado.gestorId)) && (
              <ButtonCmp
                borderRadius={'.5rem'}
                VarColor='Rosa'
                leftIcon={<FaEyeSlash />}
                onClick={() => AlterarVisibiliade(false)}
              >Ocultar resultado</ButtonCmp>
            )}

            {(!Modelo.liberarResultado && (parseJwt().adm === 'True' || parseJwt().cid === Modelo.avaliado.gestorId)) && (
                <ButtonCmp
                  leftIcon={<FaEye />}
                  borderRadius={'.5rem'}
                  VarColor='Rosa'
                  onClick={() => AlterarVisibiliade(true)}
                >Liberar resultado</ButtonCmp>
            )}
          </Flex>

          <Tabs marginTop={'1rem'} variant='unstyled'>
            <TabList >
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Resultado individual</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Tabela de avaliações</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Gráfico</Tab>
              {ComentariosVisiveis && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Comentários</Tab>}
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Histórico</Tab>
                <Tab
                  backgroundColor={'white'}
                  color={'var(--c6)'}
                  borderRadius={'0 .25rem 0 0'}
                  _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}
                >
                {Modelo.acoes
                  ? 'Recomendações'
                  : <Flex alignItems={'center'} gap={'1rem'}>
                    Carregando recomendações
                    <Spinner size={'sm'}/>
                  </Flex>
                }
                </Tab>
            </TabList>

            <TabPanels background={'white'} boxShadow={'var(--SombraBackground)'} borderBottomLeftRadius='.25rem' borderBottomRightRadius='.25rem'>
              <TabPanel px={'1rem'}>
                <TabResultadoIndividual model={Modelo}/>
              </TabPanel>

              <TabPanel px={'1rem'}>
                <TabTabelaAvaliacoes modelo={Modelo}/>
              </TabPanel>

              <TabPanel px={'1rem'}>
                <BarChartIndicativa
                  model={Modelo.grafico}
                />
              </TabPanel>

              {ComentariosVisiveis && <TabPanel>
              {(Modelo && Modelo?.discursivas?.length > 0)
                ? <Flex flexDir={'column'}>
                    <Flex gap={'1rem'} marginBottom='1rem' width='50%'>
                      <h3>Comentários: {Modelo?.discursivas?.length}</h3>
                    </Flex>
                    <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
                      {
                        Modelo?.discursivas?.map((e, i) => {
                          if (e.replaceAll(' ', '').length > 0) {
                            return (
                              <ComentariosContainer key={i}>
                                <span>{e}</span>
                              </ComentariosContainer>
                            )
                          }
                        })
                      }
                    </Flex>
                </Flex>
                : <Flex>
                  <h4>Não há comentários</h4>
                </Flex>
              }
              </TabPanel>}

              <TabPanel>
                  <TabHistorico model={Modelo}/>
              </TabPanel>

              <TabPanel px={'1rem'} >
                <TabRecomendacao model={Modelo} onGerarRecomendacoes={ObterPlanoAcao}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Body>
  )
}

import { Avatar, Flex, Grid, Img, Text } from '@chakra-ui/react'
import { FaChartBar, FaCheckCircle, FaClipboardList, FaRegPlayCircle, FaRegTimesCircle, FaCalendarCheck, FaRegStopCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { Container, ColumnText } from './styles'
import IAvaliadores from '../../../../../assets/IAvaliadores.svg'
import { parseJwt } from '../../../../../services/token'
import { PropInvalida, SubstringRoute } from '../../../../../Utils/Helper'
import { iAvaliado } from '../..'
import { AiOutlineStop } from 'react-icons/ai'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface iCardProps {
  avaliado: iAvaliado
  avaliacaoAnterior?: iAvaliado
  proximaAvaliacao?: iAvaliado
  interromperPesquisa?: boolean
  pesquisaEncerrada?: boolean
  onEncerrar: (id: string) => void
  onReabrir: (id: string) => void
  openConfiguracao?: (avaliado: iAvaliado) => void
  onOpenModalReabrir: (id: string) => void
}

export const CardAvaliado: React.FC<iCardProps> = ({ avaliado, avaliacaoAnterior, proximaAvaliacao, interromperPesquisa, pesquisaEncerrada, openConfiguracao, onEncerrar, onReabrir, onOpenModalReabrir }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()

  const interrompidaSemResposta = ((avaliado?.interrompida && !avaliado?.totalRespostas))

  function VerificarBloqueio(): boolean {
    if (!PropInvalida(avaliado.dataInicio) && avaliado.encerrada) { // Requisitos necessarios
      if (PropInvalida(avaliacaoAnterior)) {
        if (PropInvalida(proximaAvaliacao) || !proximaAvaliacao?.iniciada) {
          return true
        }
      } else {
        if (avaliacaoAnterior?.encerrada) {
          return true
        }
      }
    }
    return false
  }

  function CorPDI(): string {
    if (avaliado.pdi) {
      return 'Azul'
    } else if (avaliado.pdiAtrasado && !avaliado.pdi) {
      return 'Red'
    } else {
      return 'Orange'
    }
  }

  return (
        <Container>
            <Flex width={'100%'} justifyContent={'space-between'} opacity={interrompidaSemResposta ? '50%' : 1} cursor={interrompidaSemResposta ? 'not-allowed' : ''}>
                <Flex gap={'1rem'} flex={'1'} maxW={'70%'}>
                    {
                        (PropInvalida(avaliado.dataFim)) && (
                            <Flex alignItems={'center'} flex={'1'}>
                                <Avatar size={'md'} marginRight='.35rem' name={avaliado.colaborador.nome} src={avaliado.colaborador.avatar} />
                                <ColumnText >
                                    <h4 style={{ width: '10rem', fontWeight: 600 }}>{avaliado.colaborador.nome}</h4>
                                    {
                                        (avaliado.colaborador.nomeDepartamento !== null) && (
                                            <span style={{ fontSize: '12px' }} title={avaliado.colaborador?.nomeDepartamento}>Área: {avaliado.colaborador.nomeDepartamento?.substring(0, 22)}</span>
                                        )
                                    }
                                    {
                                        (avaliado.colaborador.gestorId !== null) && (
                                            <span style={{ fontSize: '12px' }} title={avaliado.colaborador.gestorNome}>Gestor: {avaliado.colaborador.gestorNome?.substring(0, 22)}</span>
                                        )
                                    }
                                </ColumnText>
                            </Flex>
                        )
                    }
                    <Grid gap={'2.5rem'} templateColumns='repeat(4, 1fr)'>

                        {
                            (!PropInvalida(avaliado.dataFim)) && (
                                <ColumnText style={{ fontSize: '.813rem' }}>
                                    <span>Início: <strong>{avaliado.dataInicio}</strong></span>
                                    <span>Fim: <strong>{avaliado.dataFim}</strong></span>
                                </ColumnText>
                            )
                        }

                        <Flex alignItems={'center'}>
                            <Img width={'36px'} height={'36px'} src={IAvaliadores} />
                            <ColumnText>
                                <h4>{avaliado.totalAvaliadores}</h4>
                                <span>Avaliadores</span>
                            </ColumnText>
                        </Flex>

                        <Flex alignItems={'center'}>
                            <FaClipboardList size={22} style={{ marginRight: '.25rem', color: 'var(--a4)' }} />
                            <ColumnText>
                                <h4>{avaliado.totalRespostas}</h4>
                                <span>Respostas</span>
                            </ColumnText>
                        </Flex>

                        {
                            (avaliado.autoavaliacao !== null || avaliado.gestor !== null) && (
                                <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'} gap={'.25rem'}>
                                    {
                                        (avaliado.autoavaliacao !== null) && (
                                            <Flex alignItems='center'>
                                                {(avaliado.autoavaliacao) && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.25rem' }} />}
                                                {!avaliado.autoavaliacao && <FaRegTimesCircle color='var(--Red)' style={{ marginRight: '.25rem' }} />}
                                                <small>Autoavaliação</small>
                                            </Flex>
                                        )
                                    }
                                    {
                                        (avaliado.gestor !== null) && (
                                            <Flex alignItems='center'>
                                                {avaliado.gestor && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.25rem' }} />}
                                                {!avaliado.gestor && <FaRegTimesCircle color='var(--Red)' style={{ marginRight: '.25rem' }} />}
                                                <small>Gestor</small>
                                            </Flex>
                                        )
                                    }
                                </Flex>
                            )
                        }
                    </Grid>
                </Flex>

                <Flex w={'25rem'} alignItems={'center'} paddingRight='.5rem' gap={'.75rem'} justifyContent={'end'}>
                    {avaliado.encerrada && !PropInvalida(avaliado.pdiAtrasado) && (
                        <Flex>
                            <Flex justifyContent={'center'} alignItems='center' gap={'.25rem'}>
                                <FaCalendarCheck
                                    color={CorPDI()}
                                    size={18}
                                />
                                <Text
                                    color={CorPDI()}
                                    ml='.25rem' fontSize={'.813rem'}
                                    fontWeight={'bold'}
                                >PDI {avaliado.pdi ? 'cadastrado' : avaliado.pdiAtrasado && !avaliado.pdi ? 'atrasado' : 'não cadastrado'}</Text>
                            </Flex>
                        </Flex>
                    )}

                    {!avaliado.interrompida &&
                        <Flex flexDirection={'column'} alignItems={'center'}>
                            <Flex gap={'.75rem'}>
                                {
                                    (((!avaliado.encerrada && !PropInvalida(avaliado.dataInicio) && avaliado.iniciada) || (!avaliado.encerrada && PropInvalida(avaliado.dataInicio))) && parseJwt().adm === 'True') && (
                                        <ButtonCmp
                                            leftIcon={<FaRegStopCircle />}
                                            VarColor='Red'
                                            small
                                            onClick={() => onEncerrar(avaliado.id)}
                                        >Encerrar</ButtonCmp>
                                    )
                                }

                                {(!pesquisaEncerrada && (PropInvalida(avaliado.dataInicio) && avaliado.encerrada) && parseJwt().adm === 'True') && (
                                    <ButtonCmp
                                        leftIcon={<FaRegPlayCircle />}
                                        OutlineColor={'Rosa'}
                                        small
                                        onClick={() => onReabrir(avaliado.id)}
                                    >Reabrir</ButtonCmp>
                                )}

                                {(!pesquisaEncerrada && (VerificarBloqueio() && parseJwt().adm === 'True') && !interromperPesquisa) && (
                                    <ButtonCmp
                                        leftIcon={<FaRegPlayCircle />}
                                        OutlineColor={'Rosa'}
                                        small
                                        onClick={() => onReabrir(avaliado.id)}
                                    >Reabrir</ButtonCmp>
                                )}

                                {(pesquisaEncerrada && (PropInvalida(avaliado.dataInicio) && avaliado.encerrada) && parseJwt().adm === 'True') && (
                                    <ButtonCmp
                                        leftIcon={<FaRegPlayCircle />}
                                        OutlineColor={'Rosa'}
                                        small
                                        onClick={() => onOpenModalReabrir(avaliado.id)}
                                    >Reabrir</ButtonCmp>
                                )}

                                {avaliado.encerrada &&
                                    (((parseJwt().cid === avaliado.colaborador.id && avaliado.liberarResultado === 0) ||
                                        parseJwt().cid === avaliado.colaborador.gestorId) ||
                                        parseJwt().role === '2' || parseJwt().adm === 'True') &&
                                    (
                                        <ButtonCmp
                                            VarColor='Green2'
                                            leftIcon={<FaChartBar />}
                                            onClick={() => nav(`${SubstringRoute(pathname, 'ResultadoPesquisa')}ResultadoIndividual/${avaliado.id}`)}
                                            small
                                            disabled={avaliado.totalRespostas === 0 || (!avaliado.apenasAutoAvaliacao && (avaliado.autoavaliacao && avaliado.totalRespostas === 1))}
                                        >Resultados</ButtonCmp>
                                    )}
                            </Flex>
                        </Flex>
                    }

                    {avaliado.interrompida &&
                        <ElementoTooltipCmp label='Avaliação interrompida!'>
                            <AiOutlineStop size='24' color='red' />
                        </ElementoTooltipCmp>
                    }
                </Flex>
            </Flex>
        </Container>
  )
}

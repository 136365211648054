/* eslint-disable  @typescript-eslint/restrict-template-expressions */

import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { ModalDelete } from '../../../../components/ModalDelete'
import { SectionHeader } from '../Components/SectionHeader'
import { CreateJobTitleModal } from './Components/CreateJobTitleModal'
import { UpdateJobTitleModal } from './Components/UpdateJobTitleModal'
import { JobTitleCard } from './Components/JobTitlesCard'
import { Main } from './styles'
import { NavBar } from '../Components/NavBar'
import { IJobTitle } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { useAuth } from '../../../../contexts/AuthContext'
import { parseJwt } from '../../../../services/token'
import { useToast } from '@chakra-ui/react'
import { Paginador } from '../../../../components/Paginador'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { FaPlus } from 'react-icons/fa'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

export const CargoDesenvolvimento: React.FC = () => {
  const toast = useToast()
  const { permitirFuncao } = useAuth()

  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(15)

  function ObterRotas(): INavLink[] {
    const rotas: INavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }

  const [Cargos, setCargos] = useState<IJobTitle[]>([])
  const [EditCargo, setEditCargo] = useState<IJobTitle>({
    id: '',
    nome: ''
  })

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalUpdateIsOpen, setModalUpdateIsOpen] = useState(false)

  function getCargos(): void {
    setIsLoading(true)
    appApi.get(`Cargo/?tamanho=${Tamanho}&pagina=${Pagina}`)
      .then(res => { setCargos(res.data); setIsLoading(false) })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function GetQuantidadeCargos(): void {
    appApi.get('Cargo/Quantidade')
      .then(res => setQtd(res.data))
      .catch(err => console.log(err))
  }

  function onDeleteCargo(): void {
    appApi.delete(`Cargo/${EditCargo.id}`).then(getCargos).catch(err => console.log(err))
  }

  function onCreateCargo(obj: IJobTitle): void {
    const form = {
      nome: obj.nome
    }
    if (Cargos.some(e => e.nome.toLowerCase() === obj.nome.toLowerCase())) {
      toast({
        title: 'Nome de função já existente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post('Cargo', form).then(() => {
      getCargos()
      onRequestModalCreateClose()
    }).catch(err => console.log(err))
  }

  function onUpdateCargo(obj: IJobTitle): void {
    const form = {
      Nome: obj.nome
    }
    if (Cargos.some(e => e.nome.toLowerCase() === obj.nome.toLowerCase())) {
      toast({
        title: 'Nome de função já existente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.put(`Cargo/${obj.id}`, form).then(res => {
      console.log(res)
      getCargos()
      onRequestModalUpdateClose()
    }).catch(err => console.log(err))
  }

  function onRequestModalCreateClose(): void {
    setModalCreateIsOpen(false)
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setEditCargo({
      id: '',
      nome: ''
    })
  }

  function onRequestModalUpdateClose(): void {
    setModalUpdateIsOpen(false)
    setEditCargo({
      id: '',
      nome: ''
    })
  }

  function onOpenModalUpdate(obj: IJobTitle): void {
    setModalUpdateIsOpen(true)
    setEditCargo(obj)
  }

  function onOpenModalDelete(obj: IJobTitle): void {
    setModalDeleteIsOpen(true)
    setEditCargo(obj)
  }

  useEffect(() => {
    getCargos()
  }, [Tamanho, Pagina])

  useEffect(() => {
    setPagina(0)
  }, [Tamanho])

  useEffect(() => {
    GetQuantidadeCargos()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <Main>
        <ModalDelete
          isOpen={ModalDeleteIsOpen}
          onRequestClose={onRequestModalDeleteClose}
          onConfirm={onDeleteCargo}
          center
          message={'Deseja realmente excluir esta função?'}
        />

        <CreateJobTitleModal
          isOpen={ModalCreateIsOpen}
          onCreateJobTitle={onCreateCargo}
          onRequestClose={onRequestModalCreateClose}
        />

        <UpdateJobTitleModal
          isOpen={ModalUpdateIsOpen}
          onUpdateJobTittle={onUpdateCargo}
          onRequestClose={onRequestModalUpdateClose}
          initialData={EditCargo}
        />

        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />

        <SectionHeader title="Funções" Button={<ButtonCmp onClick={() => setModalCreateIsOpen(true)} leftIcon={<FaPlus/>} VarColor='Green2'>Adicionar função</ButtonCmp>}/>

        <Paginador
          next={(e) => setPagina(e)}
          prev={(e) => setPagina(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          paginaAtual={Pagina}
          qtdElementos={Qtd}
          opcoesTamanho={['15', '30', '45', '90']}
          hideTop
        >
          <section style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
            {
              Cargos.map((e: IJobTitle, i) => {
                return (
                  <JobTitleCard
                    key={i}
                    jobTitle={e}
                    onEdit={() => onOpenModalUpdate(e)}
                    onDelete={() => onOpenModalDelete(e)}
                  />
                )
              })
            }
          </section>
        </Paginador>
      </Main>
    </Body>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Alert, AlertDescription, AlertIcon, AlertTitle, Divider, Flex, FormControl, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaEye, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { InputCmp } from '../../../../components/InputCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iConfigPesquisaInterna } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { ButtonTag } from '../../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'
import { ModalPermissoes } from '../Criar/components/ModalPermissoes'
import { ModalGrupos } from '../Criar/components/ModalGrupos'
import { ModalTag } from './Components/ModalTag/Index'
import { OutlineContainer } from './styles'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'

type iLocationProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

interface iPutConfiguracao {
  dataInicio: string
  periodoCiclo: number
  validadeDias: number
  enviarFimDeSemana: boolean
  textoAbertura: string
  textoEmail: string
  tipoEncerramentoPesquisa: number
  titulo?: string
  linkRedirect?: string
  dataFim?: string
  textoEncerramento?: string
  grupoId?: string
  permissoesColab?: string[]
}

interface iPutEtapaPesquisa extends Pick<iPutConfiguracao, 'textoAbertura' | 'textoEmail' | 'grupoId' | 'linkRedirect' | 'tipoEncerramentoPesquisa' | 'textoEncerramento' | 'titulo' | 'permissoesColab'> { }

export const ConfiguracaoPesquisaInterna: React.FC = () => {
  const nav = useNavigate()
  const param = useParams<iLocationProps>()
  const { pathname } = useLocation()
  const textoPadraoEmail = `<p>Olá, </p><p>${(pathname.includes('Processo') || pathname.includes('Trilha')) ? 'Você foi convidado para participar da pesquisa relacionada a trilha do [NOME DO AVALIADO], que abre [DATA INICIO].' : 'Você foi convidado para participar da pesquisa que abre [DATA INICIO].'}</p><p>A pesquisa é simples e rápida.</p><b> O prazo para preenchimento é até o dia [DATA FIM]</b><p>[LINK]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar: [EMAIL DO RESPONSÁVEL]</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const toast = useToast()

  const [NomeIsInvalid, setNomeIsInvalid] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [GerenciarGruposIsOpen, setGerenciarGruposIsOpen] = useState(false)
  const [GerenciarPermissaoIsOpen, setGerenciarPermissaoIsOpen] = useState(false)

  const [Model, setModel] = useState<iConfigPesquisaInterna>({
    dataFim: '',
    dataInicio: '',
    hInicio: '',
    periodoCiclo: 0,
    textoAbertura: '',
    textoEmail: textoPadraoEmail,
    validadeDias: 0,
    textoEncerramento: 'Obrigada pela participação!',
    enviarFimDeSemana: false,
    tipoEncerramentoPesquisa: 0,
    titulo: '',
    permissoesColab: []
  })

  const [NaoDefinirDataEncerramento, setNaoDefinirDataEncerramento] = useState(false)

  function getConfiguracao(): void {
    setIsLoading(true)
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Configuracao`)
      .then(res => {
        setModel(res.data)
        if (!res.data.textoEmail) {
          setModel({ ...res.data, textoEmail: textoPadraoEmail })
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function UpdateConfiguracao(preVisualizar: boolean): void {
    UpdateConfiguracaoTextoEmail(false)
    const form: iPutConfiguracao = {
      enviarFimDeSemana: Model.enviarFimDeSemana,
      periodoCiclo: Model.periodoCiclo,
      validadeDias: Model.validadeDias,
      dataInicio: `${Model.dataInicio}T${Model.hInicio}`,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail ? Model.textoEmail : textoPadraoEmail,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      linkRedirect: Model.linkRedirect,
      textoEncerramento: Model.textoEncerramento,
      dataFim: !NaoDefinirDataEncerramento ? `${Model.dataFim}T${Model.hFim}` : undefined,
      titulo: Model.titulo,
      grupoId: Model.grupoId,
      permissoesColab: Model.permissoesColab
    }

    if ((form.titulo && form.titulo.length < 5)) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    setTimeout(() => {
      appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao`, form)
        .then(() => {
          preVisualizar ? nav(`/Jornada/PesquisaInterna/Abertura/${param.pesquisaId as string}/Previsualizar`) : nav(`/Jornada/PesquisaInterna/Convites/${param.pesquisaId}`)
        })
        .catch(err => {
          console.log(err)
          preVisualizar
            ? nav(`/Jornada/PesquisaInterna/Abertura/${param.pesquisaId as string}/Previsualizar`)
            : toast({
              title: 'Há campos sem preenchimento',
              status: 'error',
              isClosable: false,
              position: 'top-right'
            })
        })
    }, 1000)
  }

  function UpdateConfiguracaoEncerramento(): void {
    const form: iPutConfiguracao = {
      enviarFimDeSemana: Model.enviarFimDeSemana,
      periodoCiclo: Model.periodoCiclo,
      validadeDias: Model.validadeDias,
      dataInicio: `${Model.dataInicio}T${Model.hInicio}`,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      linkRedirect: Model.linkRedirect,
      textoEncerramento: Model.textoEncerramento,
      grupoId: Model.grupoId,
      permissoesColab: Model.permissoesColab
    }

    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/Encerramento`, form)
      .then(() => toast({
        title: 'Encerramento atualizado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      }))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoTextoAbertura(): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/TextoAbertura`, { texto: Model.textoAbertura })
      .then(() => toast({
        title: 'Texto de abertura atualizado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      }))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoTextoEmail(toastShow: boolean): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/TextoEmail`, { texto: Model.textoEmail })
      .then(() => {
        if (toastShow) {
          toast({
            title: 'Texto de email atualizado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
      }
      )
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoPesquisaEtapa(): void {
    const form: iPutEtapaPesquisa = {
      textoAbertura: Model.textoAbertura,
      linkRedirect: Model.linkRedirect,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      textoEncerramento: Model.textoEncerramento,
      textoEmail: Model.textoEmail,
      titulo: Model.titulo,
      grupoId: Model.grupoId === '' ? undefined : Model.grupoId,
      permissoesColab: Model.permissoesColab
    }

    if (form.titulo && form.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    appApi.put(`PesquisaInternaEtapa/${param.pesquisaId as string}/Configuracao`, form)
      .then(() => {
        if (pathname.includes('Trilha')) {
          nav(`/Jornada/Configuracao/Trilha/${param.trilhaId}`)
        } else {
          nav(`/Rh/VisualizarPerfil/${param.processoId}`)
        }
      })
      .catch(err => console.log(err))
  }

  function onSavePermissoes(e: string[]): void {
    appApi.post(`PesquisaInterna/${param.pesquisaId as string}/Permissoes`, { ids: e })
      .then(res => {
        toast({
          title: 'Permissões atualizadas com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top',
          duration: 4000
        })
        setGerenciarPermissaoIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function onSaveGrupo(e: string): void {
    appApi.post(`PesquisaInterna/${param.pesquisaId as string}/Grupo?grupoId=${e}`)
      .then(res => {
        toast({
          title: 'Grupo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top',
          duration: 4000
        })
        setGerenciarGruposIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConfiguracao()
  }, [])

  useEffect(() => {
    if (Model.dataFim === undefined || Model.dataFim === null) {
      setNaoDefinirDataEncerramento(true)
    }
  }, [Model.dataFim])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />
      <ModalPermissoes
        isOpen={GerenciarPermissaoIsOpen}
        onCreate={(e) => {
          setModel({ ...Model, permissoesColab: e })
        }}
        selecionados={Model.permissoesColab ?? []}
        onClose={() => setGerenciarPermissaoIsOpen(false)}
        onSave={(e) => onSavePermissoes(e)}
      />
      <ModalGrupos
        isOpen={GerenciarGruposIsOpen}
        onCreate={(e) => {
          setModel({ ...Model, grupoId: e })
        }}
        selecionado={Model.grupoId ?? ''}
        onClose={() => setGerenciarGruposIsOpen(false)}
        onSave={(e) => onSaveGrupo(e)}
      />
      {(param.trilhaId || param.processoId) && <Alert borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'.75rem'} py={'.5rem'}>
        <Flex flexDir={'column'} fontSize={'.75rem'}>
          <Flex>
            <AlertIcon color={'#FF9900'} mr={'0.325rem'}/>
            <AlertTitle>IMPORTANTE: </AlertTitle>
            <AlertDescription>Todas as alterações feitas nessa tela irão impactar nas demais etapas que utilizem dessa pesquisa.</AlertDescription>
          </Flex>
        </Flex>
      </Alert>}
      <WhiteContainer margin='.75rem 0'>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <h2>Configure a sua pesquisa</h2>
          <Flex gap={'.5rem'}>
            <ButtonCmp VarColor='Green2' onClick={() => setGerenciarGruposIsOpen(true)}>Grupos (pastas)</ButtonCmp>
            <ButtonCmp VarColor='Rosa' onClick={() => setGerenciarPermissaoIsOpen(true)}>Permissões</ButtonCmp>
          </Flex>
        </Flex>
        <Flex margin={'1rem 0.25rem 0 0'} flexDirection={'column'} gap={'1rem 0'}>
          <LoadingContainer linhas={2} my={'.25rem'} loading={IsLoading}>
            <FormControl>
              <InputCmp
                label='Nome da pesquisa'
                isInvalid={NomeIsInvalid}
                placeholder='Digite o nome da pesquisa'
                value={Model.titulo}
                type={'text'}
                OnChange={(e) => setModel({ ...Model, titulo: e })}
                borderColor={'var(--Gray4)'}
              />
            </FormControl>
          </LoadingContainer>
        </Flex>
      </WhiteContainer>
      {(!pathname.includes('Trilha') && !pathname.includes('Processo')) && (
        <WhiteContainer margin='.5rem 0'>
          <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
                <OutlineContainer>
                  <h2>Período</h2>
                  <Flex flexDir={'column'} gap={'1rem'} mb={'.75rem'}>
                    <Flex w={'100%'} justifyContent={'space-between'} alignItems={'end'}>
                          <Flex alignItems={'end'} gap={'.5rem'} w={'45%'}>
                            <InputCmp
                              label='Início da pesquisa'
                              min={new Date().toISOString().split('T')[0]}
                              value={Model?.dataInicio ?? ''}
                              OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                              type={'date'}
                              max={'2099-12-31T23:59'}
                            />
                            <InputCmp
                              value={Model.hInicio ?? ''}
                              OnChange={(e) => setModel({ ...Model, hInicio: e })}
                              type={'time'}
                            />
                          </Flex>
                          <Divider orientation='vertical' height={'3rem'} borderColor={'var(--c6)'}/>
                          <Flex alignItems={'end'} gap={'.5rem'} w={'45%'}>
                            <InputCmp
                              label='Fim da pesquisa'
                              isDisabled={NaoDefinirDataEncerramento}
                              min={new Date().toISOString().split('T')[0]}
                              value={Model.dataFim ?? ''}
                              OnChange={(e) => setModel({ ...Model, dataFim: e })}
                              type={'date'}
                              marginRight={'.5rem'}
                              max={'2099-12-31T23:59'}
                              extraLabel={<CheckboxCmp
                                styles={{ position: 'absolute', top: '0', right: '-15rem' }}
                                isChecked={NaoDefinirDataEncerramento}
                                OnChange={(e) => { setNaoDefinirDataEncerramento(e); if (e) { setModel({ ...Model, dataFim: undefined, hFim: undefined }) } }}
                              >Não definir data de encerramento</CheckboxCmp>}
                            />
                            <InputCmp
                              isDisabled={NaoDefinirDataEncerramento}
                              value={Model.hFim ?? ''}
                              OnChange={(e) => setModel({ ...Model, hFim: e })}
                              type={'time'}
                            />
                          </Flex>
                    </Flex>

                    {/* <Flex gap={'.75rem 0'} flexDirection={'column'}>
                      <Flex justifyContent={'space-between'} gap={'1rem'}>
                        <FormControl>
                          <SelectCmp title='Ciclo pulso' value={Model.periodoCiclo.toString()}
                            OnChange={(e) => setModel({ ...Model, periodoCiclo: parseInt(e) })}
                            borderColor={'var(--Gray4)'}
                          >
                            <option value={'0'}>Único</option>
                            <option value={'2'}>Mensal</option>
                            <option value={'3'}>Bimestral</option>
                            <option value={'4'}>Trimestral</option>
                            <option value={'5'}>Semestral</option>
                            <option value={'6'}>Anual</option>
                          </SelectCmp>
                        </FormControl>

                        <FormControl isDisabled={Model.periodoCiclo === 0}>
                          <InputCmp
                            label='Validade da pesquisa'
                            type={'number'}
                            placeholder={'7'}
                            isDisabled={Model.periodoCiclo === 0}
                            value={Model.validadeDias}
                            OnChange={(e) => setModel({ ...Model, validadeDias: parseInt(e) })}
                          />
                        </FormControl>
                      </Flex>

                      <CheckboxCmp
                        isChecked={Model.enviarFimDeSemana}
                        OnChange={(e) => setModel({ ...Model, enviarFimDeSemana: e })}
                      >Enviar durante o fim de semana</CheckboxCmp>
                    </Flex> */}

                  </Flex>
                </OutlineContainer>
          </LoadingContainer>
        </WhiteContainer>
      )}
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <Flex justifyContent={'space-between'}>
                <Flex flexDir={'column'} mb={'.5rem'}>
                  <h3>Texto do convite para preenchimento da pesquisa</h3>
                  <Text
                    fontWeight={'500'}
                    fontSize='0.875rem'
                    style={{ color: 'var(--a4)' }}
                  >Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa.</Text>
                </Flex>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>
              <EditorTexto isLoading={IsLoading} initialValue={Model.textoEmail ?? ''}
                onChange={(e: string) => setModel({ ...Model, textoEmail: e })}
              />
            </Flex>
            <Flex marginTop={'.75rem'} justifyContent={'end'} gap='.5rem'>
              <ButtonCmp
                VarColor={'c6'}
                onClick={() => {
                  setIsLoading(true)
                  setModel((prevState) => ({
                    ...prevState,
                    textoEmail: textoPadraoEmail
                  }))
                  setTimeout(() => setIsLoading(false), 1000)
                }}
              >Restaurar texto padrão
              </ButtonCmp>
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaSave />}
                onClick={() => UpdateConfiguracaoTextoEmail(true)}
              >Salvar</ButtonCmp>
            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <h3>Texto de abertura da pesquisa</h3>
              <Text
                fontWeight={'500'}
                fontSize='0.875rem'
                style={{ color: 'var(--a4)', marginBottom: '.5rem' }}
              >O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.</Text>
              <EditorTexto isLoading={IsLoading} initialValue={Model.textoAbertura ?? ''}
                onChange={(e: string) => setModel({ ...Model, textoAbertura: e })}
              />

            </Flex>
            <Flex marginTop={'.75rem'} gap={'.5rem'} justifyContent={'end'}>
              <ButtonCmp
                onClick={() => { UpdateConfiguracao(true) }}
                VarColor='Rosa' leftIcon={<FaEye />}
              >Pré-visualização do início da pesquisa</ButtonCmp>
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaSave />}
                onClick={UpdateConfiguracaoTextoAbertura}
              >Salvar</ButtonCmp>
            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <WhiteContainer margin='.5rem 0'>
        <LoadingContainer linhas={10} my='.5rem' loading={IsLoading}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <h3>Opções após encerrar pesquisa</h3>
              <Text
                fontWeight={'500'}
                fontSize={'0.875rem'}
                style={{ color: 'var(--a4)', fontSize: '14px' }}
              >Escolha uma opção abaixo para o encerramento da pesquisa</Text>
              <RadioGroup onChange={(e) => setModel({ ...Model, tipoEncerramentoPesquisa: parseInt(e) })} value={Model.tipoEncerramentoPesquisa.toString()} size={'sm'} my={'.75rem'}>
                <Stack color={'var(--a4)'} direction='column'>
                  <Radio borderColor={'var(--Gray4)'} value='0'>Redirecione o usuário para o site do sistema.</Radio>
                  <Radio borderColor={'var(--Gray4)'} value='1'>Redirecione o usuário para o site de sua empresa.</Radio>
                  <Radio borderColor={'var(--Gray4)'} value='2'>Exiba uma mensagem.</Radio>
                </Stack>
              </RadioGroup>
              {
                (Model.tipoEncerramentoPesquisa === 1) && (
                  <FormControl>
                    <InputCmp
                      label='Escreva o endereço do site:'
                      placeholder={'www.meusite.com.br'}
                      OnChange={(e) => setModel({ ...Model, linkRedirect: e })}
                      value={Model.linkRedirect}
                    />
                  </FormControl>
                )
              }

              {
                (Model.tipoEncerramentoPesquisa === 2) && (
                  <EditorTexto isLoading={IsLoading} initialValue={Model.textoEncerramento ?? ''}
                    onChange={(e: string) => setModel({ ...Model, textoEncerramento: e })}
                  />
                )
              }
            </Flex>
            <Flex marginTop={'.75rem'} justifyContent={'end'}>
              <ButtonCmp VarColor='Green2' leftIcon={<FaSave />} onClick={UpdateConfiguracaoEncerramento}>Salvar</ButtonCmp>
            </Flex>
          </OutlineContainer>
        </LoadingContainer>
      </WhiteContainer>
      <Flex justifyContent={'end'} gap={'.5rem'} mt={'1rem'}>
        <ButtonCmp
          onClick={() => nav(-1)}
          leftIcon={<FaAngleLeft />}
          VarColor='c6'
        >Voltar</ButtonCmp>
        {
          (pathname.includes('Trilha') || pathname.includes('Processo')) && (
            <ButtonCmp
              VarColor='Green2'
              onClick={UpdateConfiguracaoPesquisaEtapa}
            >Salvar</ButtonCmp>
          )
        }

        {
          (!pathname.includes('Trilha') && !pathname.includes('Processo')) && (
            <ButtonCmp
              rightIcon={<FaAngleRight />}
              VarColor='Green2'
              onClick={() => UpdateConfiguracao(false)}
            >Salvar e ir para o próximo</ButtonCmp>
          )
        }
      </Flex>
    </Body>
  )
}

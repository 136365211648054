import { Box, Flex, Text } from '@chakra-ui/react'
import { FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { iResultadoIndividualIndicativa } from '../../..'
import { TabelaAvaliacoes } from './components/Tabela'

interface iProps{
  modelo: iResultadoIndividualIndicativa
}
export const TabTabelaAvaliacoes: React.FC<iProps> = ({ modelo }) => {
  return (
        <Flex flexDir={'column'}>
            <h3>Visão das competências considerando cada tipo de avaliadores</h3>
            <TabelaAvaliacoes model={modelo.tabelaAvaliacoes2} />
            <Box my={'1rem'} fontSize={'.875rem'} lineHeight={'.95rem'}>
                <Flex gap={'1rem'} justifyContent={'center'} mb={'.5rem'}>
                    <Text display={'flex'} gap={'.325rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} />  Competências consideradas destaques positivos</Text>
                    <Text display={'flex'} gap={'.325rem'} alignItems={'center'}><FaTools style={{ color: 'var(--Orange)' }} />  Competências consideradas prioridades de desenvolvimento</Text>
                </Flex>
                <Flex gap={'1rem'} justifyContent={'center'}>
                    <Text display={'flex'} gap={'.325rem'} alignItems={'center'}><FaHeart style={{ color: 'var(--Azul)' }} />  Que você mais se motiva</Text>
                    <Text display={'flex'} gap={'.325rem'} alignItems={'center'}><FaFrown style={{ color: 'var(--Roxo)' }} />  Que você menos se motiva</Text>
                </Flex>
            </Box>
        </Flex>
  )
}

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Flex, FormLabel, Grid, Img, Radio, RadioGroup, Stack, Tag, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaClipboard, FaCog, FaComment, FaEye, FaEyeSlash, FaFrown, FaHeart, FaPen, FaPencilAlt, FaPlus, FaSave, FaTasks } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { NavLine } from '../../../../components/NavLine'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iAvatarXInformacoes, iCompetenciaPDI, iImg } from '../../../../interfaces'
import { appApi, urlLocal } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { PropInvalida, useQuery, ConverterDataHora, SubstringRoute, returnDate } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { iAcao } from '../../../PDI/Dashboard/Individual'
import { AcaoCard } from '../../../PDI/Dashboard/Individual/Components/Acao'
import { BoxConfiguracao } from './components/BoxConfiguracao'
import { CardPesquisa } from './components/CardPesquisa'
import { CardPontoDiscussao } from './components/CardPontoDiscussao'
import { CardReuniao } from './components/CardReuniao'
import { ModalRecusarAgendamento } from './components/ModalRecusarAgendamento'
import { ModalReagendar } from './components/ModalReagendar'
import { VisualizarPontoDiscussao } from './components/VisualizarPontoDiscussao'
import { CardFeedback } from './components/CardFeedback'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ModalVisualizarComentarios } from '../../../PDI/Dashboard/Individual/Components/ModalVisualizarComentarios'
import { ModalFinalizarAcao } from '../../../PDI/Dashboard/Individual/Components/ModalFinalizarAcao'
import { PopUpAceitar } from './components/PopUpAceitar'
import { BoxAgendamento } from './components/BoxAgendamento'
import { ElementoTooltipCmp } from '../../../../components/ElementoTooltipCmp'
import { ModalPDI } from '../../../PesquisaPercepcao/ResultadoIndividual/components/ModalPDI'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { iPDFReuniao, RelatorioPDF } from './components/RelatorioPDF'
import LoadingContainer from '../../../../components/LoadingContainer'
import { InputCmp } from '../../../../components/InputCmp'
import { Recomendacao } from '../../../PesquisaPercepcao/ResultadoIndividual/components/Recomendacao'

export interface iModel {
  configuracao: iConfiguracao
  datasDisponiveis?: string[]
  pontosDiscussao: iPontosDiscussao[]
  reunioes: iReuniao[]
  onboard: boolean
  agendamento: boolean
  feedback?: iFeedback
  pdf: iPDFReuniao
}

interface iPDI {
  id: string
  acoes?: iAcao[]
}

export interface iFeedback {
  id: string
  nome: string
  dataEnvio: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  respondente: number
  visualizarResposta: boolean
  formulario: boolean
}

export interface iConfiguracao {
  tipo: string
  status: number
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  assunto: string
  local: string
  link?: string
  descricao: string
  data: string
  duracao: number
  liberarFinalizar: boolean
  presencial: boolean
  dataLimiteInicio?: string
  dataLimiteFim?: string
  reagendar: boolean
}

export interface iParticipante {
  id: string
  nome: string
  avatar: string
  complemento: string
}

export interface iPontosDiscussao {
  id: string
  concluido: boolean
  proximoPasso: boolean
  adicionarPdi: boolean
  comentarios: iComentario[]
  anexos: iAnexo[]
  tipo: number
  titulo: string
  avatar: string
  descricao: string
  criadorId: string
  pesquisa?: iPesquisa
}

export interface iComentario {
  id: string
  colaborador: iAvatarXInformacoes
  comentario: string
}

export interface iAnexo {
  id: string
  colaborador: iAvatarXInformacoes
  anexo: iImg
}

export interface iReuniao {
  id: string
  assunto: string
  status: number
  participante: iParticipante
  dataInicio: string
}

export interface iPesquisa {
  id: string
  avaliacaoId: string
  percepcao: boolean
  nome: string
  dataInicio: string
  dataFim: string
  autoavaliacao: boolean
  lider: boolean
  avaliadores: number
  respostas: number
  resultado: boolean
  quantitativa: boolean
  participante: iParticipante
  recomendacoes?: iCompetenciaPDI[]
  motivam?: iCompetenciaPDI[]
  desmotivam?: iCompetenciaPDI[]
  pdi?: iPDI
  pdiNormal?: string
  atribuirNota: boolean
}

export interface iModalAgendamentoInput {
  excluirConvite?: boolean
  comentario?: string
  datas?: string[]
}

interface iModalFializar {
  pdiId: string
  acao: iAcao
}

export function FormatarDataCSharp(data: string): string {
  const [day, month, yearComHora] = data.split('/')
  const year = yearComHora.split(' ')[0]
  const [Hours, Minutes] = yearComHora.split(' ')[1].split(':')
  return `${year}-${month}-${day}T${Hours}:${Minutes}`
}

export const AssuntoReuniaoOne: React.FC = () => {
  const { reuniaoId } = useParams<{ reuniaoId: string }>()
  const { pathname } = useLocation()
  const { cid } = parseJwt()
  const nav = useNavigate()
  const toast = useToast()
  const query = useQuery()

  const [DataSelecionada, setDataSelecionada] = useState<string>()
  const [LoadingPonto, setLoadingPonto] = useState('')
  const [Model, setModel] = useState<iModel>({
    onboard: false,
    agendamento: false,
    configuracao: {
      assunto: '',
      reagendar: false,
      data: '',
      presencial: false,
      descricao: '',
      duracao: 0,
      local: '',
      liberarFinalizar: false,
      convidado: {
        avatar: '',
        info1: '',
        id: ''
      },
      criador: {
        avatar: '',
        info1: '',
        id: ''
      },
      status: 4,
      tipo: 'Padrão'
    },
    pontosDiscussao: [],
    reunioes: [],
    pdf: {
      competencias: [],
      questoesFeedback: [],
      respostasFeedback: [],
      tabela: []
    }
  })
  const [PontoDiscussaoSelecionado, setPontoDiscussaoSelecionado] = useState<iPontosDiscussao>()

  const [CriarPonto, setCriarPonto] = useState(false)
  const [CriarPasso, setCriarPasso] = useState(false)
  const [AddComentario, setAddComentario] = useState(false)
  const [Texto, setTexto] = useState('')
  const [CriandoPonto, setCriandoPonto] = useState(false)
  const [CriandoPasso, setCriandoPasso] = useState(false)

  const [ModalRecusarAgendamentoIsOpen, setModalRecusarAgendamentoIsOpen] = useState(false)
  const [ModalReagendamentoIsOpen, setModalReagendamentoIsOpen] = useState(false)
  const [ModalDeletarIsOpen, setModalDeletarIsOpen] = useState(false)
  const [PopUpAceitarIsOpen, setPopUpAceitarIsOpen] = useState(false)
  const [DeletarFeedbackIsOpen, setDeletarFeedbackIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [ModalVisualizarIsOpen, setModalVisualizarIsOpen] = useState(false)
  const [ModalFinalizarAcaoIsOpen, setModalFinalizarAcaoIsOpen] = useState(false)
  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)

  const [Acao, setAcao] = useState<iModalFializar>()

  const [AceitarIsLoading, setAceitarIsLoading] = useState(false)
  const isHost = Model.configuracao.criador.id === cid

  function Carregar(): void {
    appApi.get(`ReuniaoOne/${reuniaoId ?? ''}/Visualizar`)
      .then(res => { setModel(res.data); setCriandoPonto(false); setCriandoPasso(false) })
      .catch(err => { console.log(err); setCriandoPonto(false); setCriandoPasso(false) })
  }

  function ConcluirPontoDiscussao(id: string): void {
    setLoadingPonto(id)
    appApi.put(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${id}/Concluir`)
      .then(() => {
        const pos = Model.pontosDiscussao.findIndex(e => e.id === id)
        if (pos === -1) return
        const copy = [...Model.pontosDiscussao]
        copy[pos].concluido = !copy[pos].concluido
        setModel({ ...Model, pontosDiscussao: copy })

        toast({
          title: copy[pos].concluido ? 'Ponto de discussão concluido com sucesso' : 'Ponte de discussão reaberto com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setLoadingPonto('')
      })
      .catch(err => { console.log(err); setLoadingPonto('') })
  }

  function ExcluirPontoDiscussao(): void {
    appApi.delete(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${Id}`)
      .then(() => {
        setModel({ ...Model, pontosDiscussao: [...Model.pontosDiscussao.filter(r => r.id !== Id)] })
        toast({
          title: 'Ponto de discussão excluido com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => console.log(err))
  }

  function ResponderSolicitacao(aceita: boolean): void {
    if (aceita) {
      setAceitarIsLoading(true)
      appApi.patch(`ReuniaoOne/${reuniaoId}/Aceitar?dataEscolhida=${FormatarDataCSharp(DataSelecionada ?? '')}`)
        .then(() => {
          setAceitarIsLoading(false)
          setPopUpAceitarIsOpen(true)
          Carregar()
        })
        .catch(() => {
          toast({
            title: 'Erro ao agendar a reunião reunião!',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setAceitarIsLoading(false)
        })
    } else {
      setModalRecusarAgendamentoIsOpen(true)
    }
  }

  function FinalizarReuniao(): void {
    if (!Model.onboard) {
      appApi.patch(`ReuniaoOne/${reuniaoId ?? ''}/Finalizar`)
        .then(() => {
          toast({
            title: 'Reuniao finalizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          Carregar()
        })
        .catch(err => console.log(err))
    } else {
      appApi.patch(`EtapaColaborador/${Model.configuracao.convidado.id}/Etapa/${reuniaoId ?? ''}/Concluir`)
        .then(() => {
          toast({
            title: 'Reuniao finalizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          Carregar()
        })
        .catch(err => console.log(err))
    }
  }

  function CriarPontoDiscussao(proximoPasso: boolean): void {
    if (!proximoPasso) {
      setCriandoPonto(true)
    } else {
      setCriandoPasso(true)
    }
    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao?proximoPasso=${proximoPasso}`, {
      texto: Texto
    })
      .then(() => {
        toast({
          title: proximoPasso ? 'Próximo passo criado com sucesso' : 'Ponto de discussão criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setCriarPasso(false)
        setCriarPonto(false)
        setTexto('')
        //   setModel({
        //   ...Model,
        //   pontosDiscussao: [...Model.pontosDiscussao, res.data]
        // })
        Carregar()
      }
      )
      .catch(err => { console.log(err); setCriandoPonto(false); setCriandoPasso(false) })
  }

  function AdionarComentario(): void {
    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/Comentario`, {
      texto: Texto
    })
      .then(() => {
        toast({
          title: 'Comentário criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setAddComentario(false)
        setTexto('')
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function AdionarAnexo(event: any): void {
    const formdata = new FormData()
    formdata.append('arquivo', event.target.files[0])

    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/Anexo`, formdata)
      .then(() => {
        toast({
          title: 'Anexo adicionado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ExcluirItemPontoDiscussao(id: string, variant: 'comentario' | 'anexo'): void {
    const tipo = variant === 'anexo' ? 'Anexo' : 'Comentario'
    appApi.delete(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/${tipo}/${id}`)
      .then(() => {
        toast({
          title: `${tipo} excluido com successo!`,
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        const pos = Model.pontosDiscussao.findIndex(e => e.id === PontoDiscussaoSelecionado?.id ?? '')
        if (pos !== -1) {
          const copyArr = [...Model.pontosDiscussao]
          if (Model.pontosDiscussao[pos].anexos) {
            copyArr[pos].anexos = copyArr[pos].anexos.filter(r => r.id !== id)
            setModel({ ...Model, pontosDiscussao: copyArr })
          }
          if (Model.pontosDiscussao[pos].comentarios) {
            copyArr[pos].comentarios = copyArr[pos].comentarios.filter(r => r.id !== id)
            setModel({ ...Model, pontosDiscussao: copyArr })
          }
        }
      })
      .catch(err => console.log(err))
  }

  function RecusarConvite(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${reuniaoId}/Recusar`, form)
      .then(() => {
        toast({
          title: 'Reunião recusada com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalRecusarAgendamentoIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${reuniaoId}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalReagendamentoIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ReenviarFeedback(): void {
    appApi.post(`Feedback/${Model.feedback?.id}/ReenviarConvite`)
      .then(() => {
        toast({
          title: 'Convite de feedback renviado com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  function VerificarBotao(nome: string): boolean {
    if (nome === 'responder') {
      return (isHost && (Model.feedback?.respondente === 0 || Model.feedback?.respondente === 2) && !Model.feedback.criador.info2?.includes('Respondido')) ||
        (!isHost && (Model.feedback?.respondente === 1 || Model.feedback?.respondente === 2) && !Model.feedback.convidado.info2?.includes('Respondido'))
    } else if (nome === 'visuzaliar resposta') {
      return (isHost && (Model.feedback?.criador.info2?.includes('Respondido') ?? false)) ||
        (!isHost && (Model.feedback?.visualizarResposta ?? false) && (Model.feedback?.convidado.info2?.includes('Respondido') ?? false))
    }
    return false
  }

  function onOpenModalDelete(id: string): void {
    setId(id)
    setModalDeletarIsOpen(true)
  }

  function onCloseModalDelete(): void {
    setId(undefined)
    setModalDeletarIsOpen(false)
  }

  function onOpenDeletarFeedback(id: string): void {
    setId(id)
    setDeletarFeedbackIsOpen(true)
  }

  function onCloseDeletarFeedback(): void {
    setId(undefined)
    setDeletarFeedbackIsOpen(false)
  }

  function onOpenModalVisualizarComentario(id: string, acao: iAcao): void {
    setModalVisualizarIsOpen(true)
    setAcao({ ...Acao, acao: acao, pdiId: id })
  }

  function onCloseModalVisualizarComentario(): void {
    setAcao(undefined)
    setModalVisualizarIsOpen(false)
  }

  function onOpenModalFinalizarAcao(id: string, acao: iAcao): void {
    setAcao({ ...Acao, acao: acao, pdiId: id })
    setModalFinalizarAcaoIsOpen(true)
  }

  function onCloseModalFinalizarAcao(): void {
    setAcao(undefined)
    setModalFinalizarAcaoIsOpen(false)
  }

  function FinalizarAcao(pdiId: string, acaoId: string, comentario: string, comProgresso: boolean): void {
    appApi.post(`PDI/${pdiId}/Acao/${acaoId}/Finalizar?comProgresso=${comProgresso}`, { texto: comentario })
      .then(() => {
        Carregar()
        onCloseModalFinalizarAcao()
      })
      .catch(err => console.log(err))
  }

  function ExcluirFeedback(): void {
    appApi.delete(`Feedback/${Id ?? ''}`)
      .then(() => Carregar())
      .catch(err => console.log(err))
  }

  function AtualizarTituloPontoDiscussao(id: string, texto: string): void {
    setLoadingPonto(id)
    appApi.put(`ReuniaoOne/${reuniaoId}/PontoDiscussao/${id}`, {
      texto: texto
    })
      .then(() => {
        const pos = Model.pontosDiscussao.findIndex(e => e.id === id)
        if (pos === -1) return
        const copy = [...Model.pontosDiscussao]
        copy[pos].titulo = texto
        setModel({ ...Model, pontosDiscussao: copy })

        toast({
          title: 'Titulo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setLoadingPonto('')
      })
      .catch(err => { console.log(err); setLoadingPonto('') })
  }

  function CriarBasePDI(opcao: string, avaliacaoId?: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${Model.configuracao.criador.id}&colaboradorId=${Model.configuracao.convidado.id}&avaliacaoId=${PontoDiscussaoSelecionado?.pesquisa?.avaliacaoId ?? avaliacaoId}`)
    } else {
      appApi.post(`PDI/${PontoDiscussaoSelecionado?.pesquisa?.pdiNormal}/Avaliacao/${PontoDiscussaoSelecionado?.pesquisa?.avaliacaoId}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${PontoDiscussaoSelecionado?.pesquisa?.pdiNormal}`))
        .catch(err => console.log(err))
    }
  }

  function AtualizarVisualizarResultado(): void {
    appApi.patch(`Feedback/${Model.feedback?.id ?? ''}/VisualizarResultado`)
      .then(() => {
        toast({
          title: Model.feedback?.visualizarResposta === true ? 'O colaborador não pode mais visualizar os resultados do feedback!' : 'Agora o colaborador pode visualizar os resultados do feedback!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        if (Model.feedback) {
          setModel({
            ...Model,
            feedback:
            {
              ...Model.feedback,
              visualizarResposta: !Model.feedback.visualizarResposta
            }
          })
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    Carregar()
  }, [])

  useEffect(() => {
    if (Model.pontosDiscussao.length > 0 && query.get('pontoId')) {
      setPontoDiscussaoSelecionado(Model.pontosDiscussao.find(r => r.id === query.get('pontoId')))
    } else {
      setPontoDiscussaoSelecionado(undefined)
    }
  }, [Model, query])

  useEffect(() => {
    if (Model.configuracao.status !== 2 && !PropInvalida(Model.datasDisponiveis) && Model.datasDisponiveis && Model.datasDisponiveis.length === 1) {
      setDataSelecionada(Model.datasDisponiveis[0])
    }
  }, [Model])

  return (
    <Body>
      <ModalPDI
        recomendacoes={PontoDiscussaoSelecionado?.pesquisa?.recomendacoes?.length ?? 0}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      <ModalVisualizarComentarios
        isOpen={ModalVisualizarIsOpen}
        onRequestClose={onCloseModalVisualizarComentario}
        acao={Acao?.acao}
      />

      <ModalFinalizarAcao
        isOpen={ModalFinalizarAcaoIsOpen}
        onRequestClose={onCloseModalFinalizarAcao}
        FinalizarAcao={FinalizarAcao}
        id={Acao?.pdiId as string}
        acao={Acao?.acao}
      />

      {PopUpAceitarIsOpen &&
        <PopUpAceitar
          onClose={() => setPopUpAceitarIsOpen(false)}
        />
      }

      {ModalDeletarIsOpen && <ModalDelete
        isOpen
        message='Tem certeza que deseja excluir esse item? todos os comentários e anexos vinculados serão apagados do sistema!'
        onRequestClose={onCloseModalDelete}
        onConfirm={ExcluirPontoDiscussao}
        center
      />}

      {DeletarFeedbackIsOpen && <ModalDelete
        isOpen
        message='Tem certeza que deseja desvincular e excluir o feedback dessa reunião? Todas as informações relacionadas serão permanentemente excluídas!'
        onRequestClose={onCloseDeletarFeedback}
        onConfirm={ExcluirFeedback}
      />}

      {ModalRecusarAgendamentoIsOpen &&
        <ModalRecusarAgendamento
          max={Model.configuracao.dataLimiteFim}
          min={Model.configuracao.dataLimiteInicio}
          alvo={parseJwt().cid === Model.configuracao.convidado.id ? Model.configuracao.criador : Model.configuracao.convidado}
          onClose={() => setModalRecusarAgendamentoIsOpen(false)}
          onRecusar={RecusarConvite}
        />
      }

      {ModalReagendamentoIsOpen &&
        <ModalReagendar
          max={Model.configuracao.dataLimiteFim}
          min={Model.configuracao.dataLimiteInicio}
          onReagendar={ReagendarReuniao}
          onClose={() => setModalReagendamentoIsOpen(false)}
        />
      }

      <NavLine options={[
        { nome: 'Reunião 1:1', path: `${SubstringRoute(pathname, 'Visualizar')}Dashboard/Individual` },
        { nome: 'Assunto da reunião' }
      ]}
      />

      {((parseJwt().adm === 'True' || isHost) && VerificarBotao('visuzaliar resposta')) &&
        <Flex mb={'1rem'}>
          <ButtonCmp
            VarColor='Orange'
            leftIcon={Model.feedback?.visualizarResposta === true ? <FaEye /> : <FaEyeSlash />}
            onClick={AtualizarVisualizarResultado}
          >{Model.feedback?.visualizarResposta === true ? 'Ocultar' : 'Liberar'} visualização do feedback</ButtonCmp>
        </Flex>
      }
      <Flex gap={'1rem'}>
        <Flex flexDirection={'column'} gap='1rem' width={'100%'}>
          {Model.agendamento && Model.configuracao.status !== 5 &&
            <BoxAgendamento
              model={Model.configuracao}
              onReload={Carregar}
            />
          }

          {PropInvalida(PontoDiscussaoSelecionado) &&
            <WhiteContainer>
              {Model.configuracao.status === 2 &&
                <Tag
                  color={'#fff'}
                  bgColor={'var(--Red)'}
                  w={'fit-content'}
                  mb='1rem'
                  size={'lg'}
                >
                  Recusada
                </Tag>
              }

              {Model.configuracao.status !== 2 && !PropInvalida(Model.datasDisponiveis) && Model.datasDisponiveis && Model.datasDisponiveis.length > 0 &&
                <Flex mb={'1rem'} flexDir={'column'} alignItems={'center'} justifyContent={'center'}>
                  <h3>Envie a resposta dessa solicitação</h3>
                  <RadioGroup
                    onChange={(e) => Model.datasDisponiveis && setDataSelecionada(Model.datasDisponiveis[parseInt(e)])}
                    mt={'.75rem'}
                    size={'sm'}
                    value={Model.datasDisponiveis?.findIndex(e => e === DataSelecionada).toString()}
                  >
                    <FormLabel fontSize={'14px'} textAlign={'center'}>Datas disponíveis</FormLabel>
                    <Stack direction={'column'}>
                      {
                        Model.datasDisponiveis.map((e, i) => {
                          return (
                            <Radio key={i} value={i.toString()}>{e}</Radio>
                          )
                        })
                      }
                    </Stack>
                  </RadioGroup>
                  <Flex mt={'.75rem'} gap={'.5rem'}>
                    <Box
                      as={ButtonCmp}
                      VarColor='Green2'
                      isDisabled={PropInvalida(DataSelecionada)}
                      onClick={() => ResponderSolicitacao(true)}
                      isLoading={AceitarIsLoading}
                      fontSize={'.875rem'}
                    >Aceitar</Box>
                    <Box
                      as={ButtonCmp}
                      VarColor='Red'
                      onClick={() => ResponderSolicitacao(false)}
                    >Recusar</Box>
                  </Flex>
                </Flex>
              }
              <Flex mb={'1rem'} justifyContent={'space-between'} alignItems={'start'}>
                <Flex flexDir={'column'}>
                  <h3>{Model.configuracao.assunto}</h3>
                  <Text mt={'1rem'} fontSize={'14px'}>Local:
                    {PropInvalida(Model.configuracao.link) &&
                      <strong> {Model.configuracao.local}</strong>
                    }

                    {Model.configuracao.link &&
                      <a
                        style={{ textDecoration: 'underline' }}
                        href={!Model.configuracao.link.includes('https') ? Model.configuracao.link.replace('www', 'https://www') : Model.configuracao.link}
                        target={'_blank'}
                      > {Model.configuracao.local}</a>
                    }
                  </Text>
                </Flex>
                <Flex gap={'1rem'}>
                  {(process.env.REACT_APP_AH_EMPRESA_ID === parseJwt().eid || process.env.REACT_APP_JOAO_EMPRESA_ID === parseJwt().eid) && Model.pontosDiscussao.filter(e => e.tipo === 1).length >= 2 &&
                    <PDFDownloadLink
                      document={
                        <RelatorioPDF
                          model={Model.pdf}
                        />
                      }
                      fileName={'Relatorio_geral.pdf'}
                    >
                      {({ loading }) =>
                        (loading && PropInvalida(Model.pdf))
                          ? (
                            <ButtonCmp VarColor='Green2' isLoading>Baixar relatório</ButtonCmp>
                            )
                          : <ElementoTooltipCmp label={Model.pdf.competencias.length === 0 && Model.pdf.tabela.length === 0 && Model.pdf.questoesFeedback.length === 0 && Model.pdf.respostasFeedback.length === 0 ? 'Resultados indisponíveis!' : ''}>
                            <ButtonCmp isDisabled={Model.pdf.competencias.length === 0 && Model.pdf.tabela.length === 0 && Model.pdf.questoesFeedback.length === 0 && Model.pdf.respostasFeedback.length === 0} VarColor='Green2'>Baixar relatório</ButtonCmp>
                          </ElementoTooltipCmp>

                      }
                    </PDFDownloadLink>
                  }

                  {Model.configuracao.liberarFinalizar &&
                    <ButtonCmp
                      VarColor='Green2'
                      onClick={FinalizarReuniao}
                    >Finalizar</ButtonCmp>
                  }

                  {Model.pontosDiscussao.some(r => r.pesquisa?.quantitativa) && !PropInvalida(Model.pontosDiscussao.find(r => r.pesquisa?.quantitativa === true)?.pesquisa?.recomendacoes) && Model.pontosDiscussao.some(e => e.adicionarPdi) &&
                    <Flex justifyContent={'center'}>
                      <ElementoTooltipCmp
                        label={!PropInvalida(Model.pontosDiscussao.find(r => r.pesquisa?.quantitativa === true)?.pesquisa?.pdi) ? 'PDI já cadastrado!' : ''}
                      >
                        <ButtonCmp
                          isDisabled={!PropInvalida(Model.pontosDiscussao.find(r => r.pesquisa?.quantitativa === true)?.pesquisa?.pdi)}
                          onClick={() => {
                            !PropInvalida(Model.pontosDiscussao.find(r => r.pesquisa?.quantitativa === true)?.pesquisa?.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1', Model.pontosDiscussao.find(r => r.pesquisa?.quantitativa === true)?.pesquisa?.avaliacaoId)
                          }}
                          leftIcon={<FaClipboard />} VarColor='Green2'
                        >Adicionar PDI</ButtonCmp>
                      </ElementoTooltipCmp>
                    </Flex>
                  }

                  {Model.pontosDiscussao.some(e => e.pesquisa?.atribuirNota === true) &&
                    <ButtonCmp
                      VarColor='Rosa'
                      onClick={() => nav(`/Jornada/Percepcao/Quantitativa/ConsolidarResposta/${Model.pontosDiscussao.find(e => e.pesquisa?.atribuirNota === true)?.pesquisa?.avaliacaoId}`)}
                    >Realizar alinhamento final</ButtonCmp>
                  }

                  {isHost && (Model.configuracao.status === 5 || Model.configuracao.status === 2) &&
                    <ButtonCmp
                      VarColor='Green2'
                      onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Criar?reuniaoId=${reuniaoId}`)}
                    >Solicitar nova reunião</ButtonCmp>
                  }
                </Flex>
              </Flex>

              {!PropInvalida(Model.feedback) &&
                <Box border={'1px solid var(--Gray5)'} p={'1rem'} display={'flex'} justifyContent={'space-between'} mb={'1rem'} borderRadius={'.25rem'}>
                  <Box>
                    <h4>Feedback</h4>
                    <Flex gap={'2rem'} my={'.5rem'} flexWrap={'wrap'}>
                      {(Model.feedback?.respondente === 0 || Model.feedback?.respondente === 2) &&
                        <CardFeedback
                          host={true}
                          participante={Model.feedback?.criador ?? {
                            avatar: '',
                            id: '',
                            info1: ''
                          }} />
                      }
                      {(Model.feedback?.respondente === 1 || Model.feedback?.respondente === 2) &&
                        <CardFeedback
                          host={!isHost}
                          onReenviar={ReenviarFeedback}
                          blockReenviar={(ConverterDataHora(Model.feedback?.dataEnvio ?? '') > new Date())}
                          participante={Model.feedback?.convidado ?? {
                            avatar: '',
                            id: '',
                            info1: ''
                          }} />
                      }
                    </Flex>
                    <Text fontSize={'12px'} fontWeight={'400'} color={'var(--Gray2)'}>{Model.feedback?.dataEnvio}</Text>
                  </Box>
                  {VerificarBotao('responder') && !PropInvalida(Model.feedback?.dataEnvio) && !(new Date(returnDate(Model.feedback?.dataEnvio.split(' ')[0] ?? '')) <= new Date()) &&
                    <ElementoTooltipCmp label={`O formulário só estará disponível para ser respondido no dia ${Model.feedback?.dataEnvio.split(' ')[0]}`}>
                      <ButtonCmp
                        onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${Model.feedback?.id ?? ''}`, '_blank')}
                        VarColor='Green2'
                        disabled
                        rightIcon={<FaPencilAlt />}
                      >Responder formulário
                      </ButtonCmp>
                    </ElementoTooltipCmp>
                  }
                  {VerificarBotao('responder') && !PropInvalida(Model.feedback?.dataEnvio) && (new Date(returnDate(Model.feedback?.dataEnvio.split(' ')[0] ?? '')) <= new Date()) &&
                    <ButtonCmp
                      onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${Model.feedback?.id ?? ''}`, '_blank')}
                      VarColor='Green2'
                      rightIcon={<FaPencilAlt />}
                    >Responder formulário</ButtonCmp>
                  }

                  {isHost && !Model.feedback?.formulario &&
                    <ButtonCmp
                      VarColor='Green2'
                      onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${Model.feedback?.id ?? ''}/Formularios`)}
                      rightIcon={<FaTasks style={{ color: '#fff' }}
                      />}
                    >Configurar</ButtonCmp>
                  }

                  {isHost && Model.feedback?.formulario && PropInvalida(Model.feedback.dataEnvio) &&
                    <ButtonCmp
                      VarColor='Green2'
                      onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${Model.feedback?.id ?? ''}/Configuracao`)}
                      rightIcon={<FaCog style={{ color: '#fff' }}
                      />}
                    >Configurar</ButtonCmp>
                  }

                  {VerificarBotao('visuzaliar resposta') &&
                    <ButtonCmp
                      VarColor='a1'
                      rightIcon={<FaEye />}
                      onClick={() => nav(`Feedback/${Model.feedback?.id ?? ''}/Visualizar`)}
                    >Visualizar formulário</ButtonCmp>
                  }
                </Box>
              }

              <Flex flexDir={'column'} gap={'.75rem'} border={'1px solid var(--Gray5)'} p={'1rem'} borderRadius={'.25rem'} mb={'1rem'}>
                <h4>Pontos para discussão</h4>
                {Model.pontosDiscussao.filter(e => !e.proximoPasso).map((e, i) => {
                  return (
                    <LoadingContainer linhas={3} loading={LoadingPonto === e.id} my={'.5rem'}>
                      <CardPontoDiscussao
                        key={i}
                        model={e}
                        onConcluir={ConcluirPontoDiscussao}
                        onExcluir={onOpenModalDelete}
                        onAtualizar={AtualizarTituloPontoDiscussao}
                        statusReuniao={Model.configuracao.status}
                      />
                    </LoadingContainer>
                  )
                })}
                <LoadingContainer loading={CriandoPonto} linhas={3} my='.5rem'></LoadingContainer>
                {!CriarPonto && !CriandoPonto && Model.configuracao.status !== 5 && <ButtonCmp
                  fontSize={'12px'}
                  w={'100%'}
                  VarColor='Green2'
                  rightIcon={<FaPlus />}
                  onClick={() => setCriarPonto(true)}
                >Adicionar discussão</ButtonCmp>}
                {CriarPonto && !CriandoPonto &&
                  <Flex gap={'.5rem'} flexDir={'column'}>
                    <Flex gap={'.5rem'}>
                      <InputCmp
                        placeholder='Titulo do ponto de discussão'
                        OnChange={(e) => setTexto(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            CriarPontoDiscussao(false)
                          }
                        }}
                      />
                      <ButtonCmp onClick={() => CriarPontoDiscussao(false)} VarColor='Rosa' rightIcon={<FaSave />}>Salvar</ButtonCmp>
                    </Flex>
                    <ButtonCmp
                      fontSize={'12px'}
                      w={'100%'}
                      VarColor='c4'
                      mt={'.5rem'}
                      onClick={() => setCriarPonto(false)}
                    >Cancelar</ButtonCmp>
                  </Flex>
                }
              </Flex>

              <Flex flexDir={'column'} gap={'.75rem'} border={'1px solid var(--Gray5)'} p={'1rem'} borderRadius={'.25rem'}>
                <h4>Próximos passos</h4>
                {Model.pontosDiscussao.filter(e => e.proximoPasso).map((e, i) => {
                  return (
                    <LoadingContainer linhas={3} loading={LoadingPonto === e.id} my={'.5rem'}>
                      <CardPontoDiscussao
                        key={i}
                        model={e}
                        statusReuniao={Model.configuracao.status}
                        onConcluir={ConcluirPontoDiscussao}
                        onExcluir={onOpenModalDelete}
                        onAtualizar={AtualizarTituloPontoDiscussao}
                      />
                    </LoadingContainer>
                  )
                })}
                <LoadingContainer loading={CriandoPasso} linhas={3} my='.5rem'></LoadingContainer>
                {!CriarPasso && !CriandoPasso && Model.configuracao.status !== 5 && <ButtonCmp
                  fontSize={'12px'}
                  VarColor='Green2'
                  rightIcon={<FaPlus />}
                  onClick={() => setCriarPasso(true)}
                >Adicionar tópico</ButtonCmp>}
                {CriarPasso && !CriandoPasso &&
                  <Flex gap={'.5rem'} flexDir={'column'}>
                    <Flex gap={'.5rem'}>
                      <InputCmp
                        placeholder='Titulo do ponto de discussão'
                        OnChange={(e) => setTexto(e)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            CriarPontoDiscussao(true)
                          }
                        }}
                      />
                      <ButtonCmp onClick={() => CriarPontoDiscussao(true)} VarColor='Rosa' rightIcon={<FaSave />}>Salvar</ButtonCmp>
                    </Flex>
                    <ButtonCmp
                      fontSize={'12px'}
                      w={'100%'}
                      VarColor='c4'
                      mt={'.5rem'}
                      onClick={() => setCriarPasso(false)}
                    >Cancelar</ButtonCmp>
                  </Flex>
                }
              </Flex>
            </WhiteContainer>
          }

          {PontoDiscussaoSelecionado &&
            <WhiteContainer>
              {Model.configuracao.status === 2 &&
                <Tag
                  color={'#fff'}
                  bgColor={'var(--Red)'}
                  w={'fit-content'}
                  mb='1rem'
                  size={'lg'}
                >
                  Recusada
                </Tag>
              }
              <Flex mb={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
                <Flex flexDir={'column'}>
                  <h3>{Model.configuracao.assunto}</h3>
                  <Text mt={'1rem'} fontSize={'14px'}>Local:
                    {PropInvalida(Model.configuracao.link) &&
                      <strong> {Model.configuracao.local}</strong>
                    }

                    {Model.configuracao.link &&
                      <a
                        style={{ textDecoration: 'underline' }}
                        target={'_blank'}
                        href={!Model.configuracao.link.includes('https') ? Model.configuracao.link.replace('www', 'https://www') : Model.configuracao.link}
                      > {Model.configuracao.local}</a>
                    }
                  </Text>
                </Flex>
              </Flex>
              <Flex mb={'1rem'} justifyContent='space-between'>
                <ButtonCmp onClick={() => nav(-1)} VarColor='Gray3'>Voltar</ButtonCmp>
                {Model.pontosDiscussao.some(e => e.pesquisa?.atribuirNota === true) &&
                  <ButtonCmp
                    VarColor='Rosa'
                    onClick={() => nav(`/Jornada/Percepcao/Quantitativa/ConsolidarResposta/${Model.pontosDiscussao.find(e => e.pesquisa?.atribuirNota === true)?.pesquisa?.avaliacaoId}`)}
                  >Realizar alinhamento final</ButtonCmp>
                }
              </Flex>

              <Flex flexDir={'column'}>
                <Flex>
                  {PontoDiscussaoSelecionado.tipo === 1 &&
                    <h3>{PontoDiscussaoSelecionado.pesquisa?.nome}</h3>
                  }
                </Flex>
                {PontoDiscussaoSelecionado.pesquisa && PontoDiscussaoSelecionado.tipo === 1 &&
                  <Flex flexDir={'column'} gap='1rem'>
                    <CardPesquisa pesquisa={PontoDiscussaoSelecionado.pesquisa} />
                    {(!PropInvalida(PontoDiscussaoSelecionado.pesquisa.recomendacoes)) && (
                      <Flex flexDir={'column'} mt='1rem' gap='.75rem'>
                        <h3>Potencial para melhorias</h3>
                        <Grid templateColumns='repeat(2, 1fr)' gap={'1rem'}>
                          {
                            PontoDiscussaoSelecionado.pesquisa?.recomendacoes?.map((e, i) => {
                              return (
                                <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                              )
                            })
                          }
                        </Grid>
                      </Flex>
                    )}

                    {(!PropInvalida(PontoDiscussaoSelecionado.pesquisa.desmotivam) || !PropInvalida(PontoDiscussaoSelecionado.pesquisa.motivam)) && <Flex gap={'1rem'}>
                      {!PropInvalida(PontoDiscussaoSelecionado.pesquisa.motivam) &&
                        <Box w={'50%'}>
                          <Flex
                            mb={'1rem'}
                            alignItems={'center'}
                            gap={'1rem'}
                            fontSize={'18px'}
                            fontWeight={'700'}
                            color={'var(--Azul)'}>
                            <FaHeart /> Você mais se motiva
                          </Flex>
                          <Flex gap={'1.5rem'}>
                            {PontoDiscussaoSelecionado.pesquisa.motivam?.map((e, i) => {
                              return (
                                <Flex flexDir={'column'} key={i} alignItems='center'>
                                  <Img w={'5.5rem'} h={'5.5rem'} src={e.avatar} />
                                  <Text fontWeight={'600'} mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                                </Flex>
                              )
                            })}
                          </Flex>
                        </Box>
                      }

                      {!PropInvalida(PontoDiscussaoSelecionado.pesquisa.desmotivam) &&
                        <Box w={'50%'}>
                          <Flex
                            mb={'1rem'}
                            alignItems={'center'}
                            gap={'1rem'}
                            fontSize={'18px'}
                            fontWeight={'700'}
                            color={'var(--Roxo)'}>
                            <FaFrown /> Você menos se motiva
                          </Flex>
                          <Flex gap={'1.5rem'}>
                            {PontoDiscussaoSelecionado.pesquisa.desmotivam?.map((e, i) => {
                              return (
                                <Flex flexDir={'column'} key={i} alignItems='center'>
                                  <Img w={'5.5rem'} h={'5.5rem'} src={e.avatar} />
                                  <Text fontWeight={'600'} mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                                </Flex>
                              )
                            })}
                          </Flex>
                        </Box>
                      }
                    </Flex>}

                    {PontoDiscussaoSelecionado.pesquisa.quantitativa && !PropInvalida(PontoDiscussaoSelecionado.pesquisa.recomendacoes) && PontoDiscussaoSelecionado.adicionarPdi &&
                      <Flex justifyContent={'center'} my='1rem'>
                        <ElementoTooltipCmp
                          label={!PropInvalida(PontoDiscussaoSelecionado.pesquisa.pdi) ? 'PDI já cadastrado!' : ''}
                        >
                          <ButtonCmp
                            isDisabled={!PropInvalida(PontoDiscussaoSelecionado.pesquisa.pdi)}
                            onClick={() => {
                              !PropInvalida(PontoDiscussaoSelecionado.pesquisa?.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
                            }}
                            leftIcon={<FaClipboard />} VarColor='Green2'
                          >Adicionar PDI</ButtonCmp>
                        </ElementoTooltipCmp>
                      </Flex>
                    }
                  </Flex>
                }

                <Flex gap={'.5rem'} flexDir={'column'}>
                  {PontoDiscussaoSelecionado?.comentarios.map((e, i) => {
                    return (
                      <VisualizarPontoDiscussao
                        key={i}
                        comentario={e}
                        participante={e.colaborador}
                        variant='comentario'
                        onExcluir={ExcluirItemPontoDiscussao}
                      />
                    )
                  })}
                  {PontoDiscussaoSelecionado?.anexos.map((e, i) => {
                    return (
                      <VisualizarPontoDiscussao
                        key={i}
                        anexo={e}
                        participante={e.colaborador}
                        variant='anexo'
                        onExcluir={ExcluirItemPontoDiscussao}
                      />
                    )
                  })}

                  {AddComentario &&
                    <Flex gap={'.75rem'} mb={'.25rem'}>
                      <InputCmp
                        placeholder='Digite um comentário'
                        OnChange={(e) => setTexto(e)}
                      />

                      <ButtonCmp
                        onClick={AdionarComentario}
                        VarColor='Rosa'
                        rightIcon={<FaSave />}
                      >Salvar</ButtonCmp>
                    </Flex>}
                  <Flex gap={'1rem'} mt={'1rem'}>
                    <ButtonCmp
                      width={'50%'}
                      VarColor='Blue1'
                      onClick={() => setAddComentario(true)}
                      leftIcon={<FaComment />}
                      isDisabled={Model.configuracao.status === 5}
                    >Adicionar comentário</ButtonCmp>

                    {PontoDiscussaoSelecionado.anexos.length < 2 &&
                      <ButtonCmp
                        width={'50%'}
                        py='12px'
                        VarColor='Purple1'
                        leftIcon={<FaComment />}
                        isDisabled={Model.configuracao.status === 5}
                        cursor='pointer'
                      ><label htmlFor='file' style={{ cursor: 'pointer' }}>Adicionar anexo</label>
                        <input id='file' style={{ display: 'none' }} onChange={AdionarAnexo} type={'file'} />
                      </ButtonCmp>
                    }
                    {PontoDiscussaoSelecionado.anexos.length === 2 &&
                      <ElementoTooltipCmp
                        label='Você só pode adicionar no máximo 2 anexos por ponto de discussão!'
                        bg={'var(--Azul)'}
                      >
                        <Box width={'50%'}>
                          <ButtonCmp
                            VarColor='Purple1'
                            isDisabled
                            leftIcon={<FaComment />}
                          >Adicionar anexo</ButtonCmp>
                        </Box>
                      </ElementoTooltipCmp>
                    }

                  </Flex>
                </Flex>
              </Flex>
            </WhiteContainer>
          }

          {PontoDiscussaoSelecionado?.tipo === 1 && !PropInvalida(PontoDiscussaoSelecionado.pesquisa?.pdi) &&
            <WhiteContainer>
              <Flex flexDir={'column'} gap='1rem'>
                <Flex justifyContent={'space-between'} alignItems='center'>
                  <h3>PDI da pesquisa</h3>
                  <ButtonCmp onClick={() => nav(`/Desempenho/PDI/Editar/${PontoDiscussaoSelecionado.pesquisa?.pdi?.id}`)} VarColor='Green2' leftIcon={<FaPen />}>Editar PDI</ButtonCmp>
                </Flex>
                <Grid gap={'1rem'} templateColumns='repeat(3, 1fr)'>
                  {PontoDiscussaoSelecionado.pesquisa?.pdi?.acoes?.map((a, i2) => {
                    return (
                      <AcaoCard
                        key={i2}
                        onOpenComentario={onOpenModalVisualizarComentario}
                        onOpenFinalizar={onOpenModalFinalizarAcao}
                        acao={a}
                        pdiId={PontoDiscussaoSelecionado.pesquisa?.pdi?.id ?? ''}
                        onSendProgresso={() => Carregar()}
                      />
                    )
                  })}
                </Grid>
              </Flex>
            </WhiteContainer>
          }

          {!PropInvalida(Model.reunioes) && Model.reunioes.length > 0 &&
            <WhiteContainer>
              <h3>Outras reuniões 1:1 com a mesma pessoa</h3>
              <Grid mt={'1rem'} templateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
                {Model.reunioes?.map((e, i) => {
                  return (
                    <CardReuniao key={i} model={e} />
                  )
                })}
              </Grid>
            </WhiteContainer>
          }

        </Flex>
        <BoxConfiguracao
          model={Model}
          onReagendar={() => setModalReagendamentoIsOpen(true)}
          onDeleteFeedback={onOpenDeletarFeedback}
        />
      </Flex>
    </Body>
  )
}

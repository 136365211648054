import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { FaClipboardList, FaCog, FaMapMarkerAlt } from 'react-icons/fa'
import { iCardEtapa } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { useNavigate, useParams } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { parseJwt } from '../../../../../../../services/token'
interface iCardReuniao {
  etapa: iCardEtapa
  isOpen: boolean
}

interface iModel {
  descricao: string
  local: string
  dataInicio: string
  duracao: number
  responsavelId: string
}

export const CardNovaReuniaoOne: React.FC<iCardReuniao> = ({ etapa, isOpen }) => {
  const { adm, cid } = parseJwt()
  const nav = useNavigate()
  const { colaboradorId } = useParams<{colaboradorId: string}>()

  const [Model, setModel] = useState<iModel>()

  function ObterEtapa(): void {
    appApi.get(`ReuniaoOneEtapaColaborador/${colaboradorId ?? ''}/Etapa/${etapa.id}/Detalhe`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      ObterEtapa()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon>
        <FaMapMarkerAlt size={18} style={{ marginTop: !Model?.local ? '0' : '' }}/>
        <div>
          <h4>Local da reunião</h4>
          <strong>{Model?.local}</strong>
        </div>
      </FlexIcon>
      {(Model?.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={18} style={{ marginTop: !Model?.descricao ? '0' : '' }}/>
            <div>
              <h4>Descrição</h4>
              <small dangerouslySetInnerHTML={{ __html: Model?.descricao ?? '' }}></small>
            </div>
          </FlexIcon>
      )}
      <Flex justifyContent={'center'}>
       {(Model?.responsavelId === cid || adm === 'True') &&
        <ButtonCmp
          mt={'.25rem'}
          VarColor='Rosa'
          rightIcon={<FaCog/>}
          fontSize={'14px'}
          h={'2rem'}
          onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${etapa.interacoes[0].idExterno}`)}
       >{PropInvalida(Model?.dataInicio) && Model?.responsavelId === cid ? 'Configurar reunião' : 'Visualizar'}</ButtonCmp>
       }
      </Flex>
    </Container>
  )
}

import { Avatar, Flex, Image } from '@chakra-ui/react'
import { iColaboradorReuniao } from '../../../../../../interfaces'

interface iCardProps{
  colaborador: iColaboradorReuniao
}

export const CardInformacao: React.FC<iCardProps> = ({ colaborador }) => {
  return (
        <Flex width={'fit-content'} alignItems={'center'} padding={'.75rem 1rem'} borderRadius={'.25rem'} border={'1px solid var(--Azul)'} flexDir={'column'} fontSize={'.875rem'}>
            <Flex justifyContent={'center'} gap={'1rem'} alignItems={'center'}>
              <h4>Colaborador em onboarding</h4>
              {(colaborador.urlSelf) && (<Image height={'2rem'} src={colaborador.urlSelf}/>)}
            </Flex>
            <Flex gap='.5rem' alignItems={'center'} w={'full'} mt={'.5rem'}>
                <Flex w={'9.5rem'}>
                    <Avatar marginRight={'.5rem'} src={colaborador.avatar} w={'2.25rem'} h={'2.25rem'} name='t'/>
                    <Flex flexDir={'column'} justifyContent={'center'}>
                        <h5>{colaborador.nome}</h5>
                        <span>{colaborador.nomeDepartamento}</span>
                    </Flex>
                </Flex>
                <Flex justifyContent={'center'} alignItems={'center'} w={'9.5rem'} pl={'2rem'} borderLeft={'2px solid var(--c4)'}>
                    <h5>{colaborador.diasDeOnboarding} dias de onboarding</h5>
                </Flex>
            </Flex>
        </Flex>
  )
}

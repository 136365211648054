import { Avatar, Flex } from '@chakra-ui/react'
import { iAvaliado, iCardAvaliador } from '../../../../../interfaces'
import { Container, EndIcon, ColumnText } from './styles'
import { FaCheck, FaEdit } from 'react-icons/fa'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { ElementoTooltip } from '../../../../../components/ElementoTooltip'

interface iCardProps {
  avaliacao: iAvaliado
  enviado: boolean
  tipo: number
  avaliador: iCardAvaliador
  onEdit: (avaliacaoId: string, avaliador: iCardAvaliador) => void
}

export const CardAssociado: React.FC<iCardProps> = ({ avaliador, avaliacao, tipo, enviado, onEdit }) => {
  function ObterTipo(type: number): string {
    switch (type) {
      case 1:
        return 'Gestor'

      case 2:
        return 'Par'

      case 3:
        return 'Liderados'

      case 4:
        return 'Outros'

      case 6:
        return 'Alta liderança'

      default:
        return 'null'
    }
  }
  return (
    <Container>
      <Flex alignItems={'center'} margin={'.5rem .125rem .5rem .75rem'}>
        <Avatar size={'sm'} name={avaliador.nome} src={avaliador.avatar} />
        <ColumnText>
          {
            avaliador.nome?.length > 21
              ? <span title={avaliador.nome}>{avaliador.nome.substring(0, 22)}...</span>
              : <span>{avaliador.nome}</span>
          }
          <strong style={{ lineHeight: '.85rem', fontSize: '.813rem' }}>{ObterTipo(tipo)}</strong>
          <span>{ }</span>
        </ColumnText>
      </Flex>
      <EndIcon>
        <FaEdit size={14} onClick={() => onEdit(avaliacao.id, avaliador)}/>
        <ElementoTooltip bg={avaliador.respondida ? 'var(--Green3)' : 'var(--Red)'} label={avaliador.respondida ? 'Já respondido' : 'Ainda não foi respondido'}>
          {avaliador.respondida && <FaCheck color='var(--Green3)' />}
          {!avaliador.respondida && <IoMdCloseCircleOutline color='var(--Red)'/>}
        </ElementoTooltip>
      </EndIcon>
    </Container >
  )
}

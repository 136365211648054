import { Avatar, Text } from '@chakra-ui/react'
import { iColaboradorRecomedacao } from '../..'
import { Container } from './styles'

interface iTabelaProps {
  dados: iColaboradorRecomedacao[]
}

export const TabelaColaboradores: React.FC<iTabelaProps> = ({ dados }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th>Colaboradores</th>
          <th>Nota média na competência</th>
        </tr>
      </thead>
      <tbody>
        {dados.map((e, i) => {
          return (<tr key={i}><th style={{ display: 'flex', alignItems: 'center', fontSize: '12px', gap: '1rem' }}><Avatar src={e.avatar} size='xs'/><Text>{e.nome}</Text> </th><th>{e.media}</th></tr>
          )
        })
          }
      </tbody>
    </Container>
  )
}

import { Flex, Text } from '@chakra-ui/react'
import Stonks from '../../../../../assets/Stonks.svg'
import { iCompetenciaPDI } from '../../../../../interfaces'

interface iRecomendacaoProps {
  pos: number
  tipo: number
  recomendacao: iCompetenciaPDI
}

export const RecomendacaoMedia: React.FC<iRecomendacaoProps> = ({ recomendacao, pos, tipo }) => {
  return (
        <Flex width={'100%'} height={'100%'} boxShadow={'var(--SombraBackground)'} minH={'10rem'}>
            <Flex borderBottomLeftRadius={'.25rem'} borderTopLeftRadius={'.25rem'} background={'var(--c2)'} pos={'relative'}>
                <h3 style={{ position: 'absolute', top: '3px', left: '8px', fontSize: '1.125rem' }}>{pos}°</h3>
                <Flex width={'10rem'} textAlign={'center'} justifyContent={'center'} alignItems='center' flexDir={'column'} padding={'.5rem'} fontSize={'.8325rem'}>
                    <Flex width={'4rem'}>
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={recomendacao.avatar} />
                    </Flex>
                    <strong>{recomendacao.nome}</strong>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='1rem' borderBottomRightRadius={'.325rem'} borderTopRightRadius={'.325rem'} padding={'1rem'} background={tipo === 0 ? 'var(--Green2)' : '#F2994A'} width={'-webkit-fill-available'}>
                <Text fontSize={'0.813rem'} lineHeight={'105%'} fontWeight={'400'} color='white'>{recomendacao.descricao}</Text>
                <Flex height={'100%'} alignItems={'end'} justifyContent='space-between'>
                    <Flex color={'white'} flexDir={'column'}>
                        <h3>{recomendacao.notaPonderada?.toFixed(2)}</h3>
                        <Text fontWeight={'400'}></Text>
                    </Flex>
                    <Flex height='.875rem'>
                        <img src={Stonks} />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}

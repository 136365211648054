import styled from 'styled-components'

export const Container = styled.div`
display: flex;
min-width: 65%;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
background-color: white;

.button-transition {
    transition: background-color 0.5s ease;
  }
  
  .gradient-blue {
    background-image: var(--DegradeAzul);
    background-size: 100% auto;
    background-position: 0% 50%;
    transition: 1s;
  }
  
  .gradient-blue:hover {     
    background-image: var(--DegradeAzul);
    box-shadow: var(--SombraBtns);
    background-size: 300% auto;
    background-position: 50% 100%;
    background-position: 100% 50%;
  }

  @media screen and (max-width: 1080px) {
    .d-none-login {
      display: none;
    }
    min-width: 100%;
  }
`

export const Logo = styled.img`
height: 87px;
object-fit: scale-down;
`

export const StyledSpan = styled.span`
color: black;
font-size: .875rem;
font-weight: 500;

&:hover{
    text-decoration: underline;
    cursor: pointer;
}
`

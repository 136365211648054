import { Text, View } from '@react-pdf/renderer'

interface iProps{
  cor: string
  texto: string
}

export const LegendaGrafico: React.FC<iProps> = ({ cor, texto }) => {
  return (
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5 }}>
            <View style={{ backgroundColor: cor, height: 15, width: 15 }}/>
            <Text style={{ marginLeft: 5 }}>{texto}</Text>
        </View>
  )
}

import { Box } from '@chakra-ui/react'
import { HTMLAttributes } from 'react'
import { Container, ContainerButton } from './styles'

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  Texto?: string
  BoldText?: string
  Icon?: any
  width?: string
  color?: string
  hasAction?: boolean
}

export const StatusFeedback: React.FC<ButtonProps> = ({ Texto, BoldText, Icon, width, onClick, hasAction, color }) => {
  return (
    <Container
      width={width}
      onClick={onClick}
      color={color}
    >
      <ContainerButton hasAction={hasAction}>
        <div>
          <Box fontWeight={'700'} fontSize={'1.75rem'} lineHeight={'1.6rem'}>{BoldText}</Box>
          <span>{Texto}</span>
        </div>
        {Icon}
      </ContainerButton>
    </Container>
  )
}

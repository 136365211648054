import { Text, Flex, InputGroup, InputProps, InputRightElement, Box, Tooltip } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { Container } from '../../../../../../components/FindBox/styles'
import { InputCmp } from '../../../../../../components/InputCmp'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { iGrupoPesquisaInterna } from '../../../Gestao'

interface iFindProps extends InputProps {
  onChoice: (id: string) => void
  _onChange?: (e: string) => void
  lista: iGrupoPesquisaInterna[]
  selecionado?: string
  leftIcon?: React.ReactNode
  createGrupo: (e: string) => void
}

export const FindGrupo: React.FC<iFindProps> = ({ lista, selecionado, placeholder, createGrupo, isInvalid, isReadOnly, isDisabled, onBlur, onChoice, _onChange }) => {
  const [Selecionado, setSelecionado] = useState<string>()
  const [Texto, setTexto] = useState('')

  function internalSelect(id: string): void {
    onChoice(id)
    setSelecionado(id)

    const grupo = lista.find(e => e.id === id)
    if (grupo) {
      setTexto(grupo.nome)
    }
  }

  useEffect(() => {
    if (!PropInvalida(selecionado) && lista !== undefined && lista.length > 0) {
      setSelecionado(selecionado)
      const buscar = lista.find(e => e.id === selecionado)
      if (buscar !== undefined) {
        setTexto(buscar.nome)
      }
    } else if (!PropInvalida(Selecionado)) {
      Clean()
    }
  }, [lista, selecionado])

  function Clean(): void {
    setTexto('')
    setSelecionado(undefined)
    onChoice('')
  }

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex flexDir={'column'} width='100%'>
        <InputGroup width={'100%'} h={'1.95rem'}>
          {
            (!isDisabled) && (
              <InputRightElement
                h={'1.95rem'}
                cursor={'pointer'}
                children={Selecionado ? <MdClose onClick={Clean} color='var(--terc3)' /> : <></>}
              />
            )
          }
          <InputCmp
            value={Texto}
            placeholder={placeholder}
            isDisabled={isDisabled ?? !PropInvalida(Selecionado)}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
            onBlur={onBlur}
            OnChange={(e) => {
              setTexto(e)
              if (_onChange !== undefined) {
                _onChange(e)
              }
            }}
          />
          {
            (!isDisabled && !Selecionado) && (
                <InputRightElement onClick={() => createGrupo(Texto)} cursor={'pointer'} h={'1.95rem'}>
                    <Tooltip hasArrow label='Adicionar grupo' placement='top' bg='var(--Azul)'>
                        <Box>
                            <FaPlus size={14} color={'var(--Azul)'}/>
                        </Box>
                    </Tooltip>
                </InputRightElement>
            )
          }
        </InputGroup>

      </Flex>
      {lista.length > 0 &&
        <Flex width={'100%'} flexDir={'column'} gap={'.5rem'} mt={'.25rem'} fontSize={'14px'}>
          {
            lista.filter((e) => {
              if (isDisabled) {
                return null
              } else if (Texto === '' || Selecionado) {
                return null
              } else if (e.nome.toLocaleLowerCase().includes(Texto.toLocaleLowerCase())) {
                return e
              }
              return null
            }).slice(0, 3).map((e, i) => {
              return (
                <Container onClick={() => internalSelect(e.id)} key={i} background='var(--c2)'>

                  <Flex padding={'.5rem 1rem'} width={'100%'} justifyContent='center' alignItems={'start'} flexDir={'column'}>
                    <Text>{e.nome}</Text>
                  </Flex>
                </Container>
              )
            })
          }
        </Flex>
      }
    </Flex>
  )
}

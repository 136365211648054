import { Flex } from '@chakra-ui/react'
import Stonks from '../../../../../assets/Stonks.svg'
import { iCompetenciaPDI } from '../../../../../interfaces'

interface iRecomendacaoProps{
  pos: number
  recomendacao: iCompetenciaPDI
}

export const Recomendacao: React.FC<iRecomendacaoProps> = ({ recomendacao, pos }) => {
  return (
        <Flex width={'100%'}>
            <Flex borderBottomLeftRadius={'.25rem'} borderTopLeftRadius={'.25rem'} background={'var(--c2)'} pos={'relative'}>
                <h3 style={{ position: 'absolute', top: '2px', left: '6px', fontSize: '1.25rem' }}>{pos}°</h3>
                <Flex width={'10rem'} textAlign={'center'} justifyContent={'center'} alignItems='center' flexDir={'column'} padding={'.5rem'} fontSize={'.8325rem'}>
                    <Flex width={'3.5rem'}>
                        <img style={{ width: '100%', height: '100%' }} src={recomendacao.avatar} />
                    </Flex>
                    <strong>{recomendacao.nome?.replace('/', ' / ')}</strong>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} borderBottomRightRadius={'.25rem'} borderTopRightRadius={'.25rem'} padding={'1rem'} color='white' fontWeight={'600'} background={'var(--Orange)'} width={'-webkit-fill-available'} fontSize={'.925rem'}>
                <span>{recomendacao.pergunta}</span>
                <Flex height={'100%'} alignItems={'end'} justifyContent={'end'} >
                    <Flex height='1rem'>
                        <img src={Stonks}/>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}

import { useState, useEffect } from 'react'
import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react'
import { EixoNineBox } from './styles'

import seta from './assets/seta.svg'
import pensativo from './assets/pensativo.svg'
import feliz from './assets/feliz.svg'
import oculos from './assets/oculos.svg'
import triste from './assets/triste.svg'
import sorrindo from './assets/Sorrindo.svg'
import piscada from './assets/Piscada.svg'
import lagrima from './assets/lagrima.svg'
import surpresa from './assets/surpresa.svg'
import contente from './assets/contente.svg'
import { FaEye } from 'react-icons/fa'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'
import { iPostNineBox, iQuadrante } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'

interface iProps {
  model: iPostNineBox
  variant?: 'editar' | 'avaliar'
  quadranteSelecionado?: string
  onChangeModel?: (model: iPostNineBox) => void
  selecionarQuadrante?: (id: string) => void
}

export function ObterImgPorNome(quadrantes: iQuadrante[], nome: string): string {
  switch (quadrantes.findIndex(p => p.titulo === nome).toString()) {
    case '0': return pensativo
    case '1': return feliz
    case '2': return oculos
    case '3': return triste
    case '4': return sorrindo
    case '5': return piscada
    case '6': return lagrima
    case '7': return surpresa
    case '8': return contente
    default: return ''
  }
}

export const NineBox: React.FC<iProps> = ({ model, variant, quadranteSelecionado, onChangeModel, selecionarQuadrante }) => {
  if (PropInvalida(variant)) {
    variant = 'editar'
  }

  const [Model, setModel] = useState<iPostNineBox>({
    padrao: false,
    areas: [],
    configuracaoEixoX: {
      descricao: '',
      titulo: ''
    },
    configuracaoEixoY: {
      descricao: '',
      titulo: ''
    },
    eixosX: [],
    eixosY: [],
    quadrantes: [],
    squads: [],
    tags: [],
    tipoMeta: 0,
    titulo: ''
  })

  function ObterImgPorPosicao(y: string, x: string): string {
    switch (Model.quadrantes.findIndex(p => p.eixoY === y && p.eixoX === x).toString()) {
      case '0': return pensativo
      case '1': return feliz
      case '2': return oculos
      case '3': return triste
      case '4': return sorrindo
      case '5': return piscada
      case '6': return lagrima
      case '7': return surpresa
      case '8': return contente
      default: return ''
    }
  }

  function ObterCorQuadrantePorPosicao(quadrantes: iQuadrante[], y: string, x: string): string {
    switch (quadrantes.findIndex(p => p.eixoY === y && p.eixoX === x).toString()) {
      case '0': return 'var(--Blue1)'
      case '1': return 'var(--Green3)'
      case '2': return 'var(--Green2)'
      case '3': return 'var(--Orange)'
      case '4': return 'var(--Blue1)'
      case '5': return 'var(--Green3)'
      case '6': return 'var(--Red)'
      case '7': return 'var(--Orange)'
      case '8': return 'var(--Blue1)'
      default: return ''
    }
  }

  useEffect(() => {
    setModel(model)
  }, [model])

  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <Flex justifyContent={'center'}>
        <Box as='div' height={'25.25rem'} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'4.25rem'}>
          <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'} transform={'rotate(-90deg)'}>
            <Text
              display={'flex'}
              justifyContent={'center'}
              fontWeight={'700'}
              gap={'.325rem'}
              alignItems={'center'}
              fontSize={'1.125rem'}
            >{Model.configuracaoEixoY.titulo.toUpperCase()}
              <ElementoTooltipCmp
                backgroundColor={'var(--Azul)'}
                label={Model.configuracaoEixoY.descricao}
              ><Flex alignItems={'center'}><FaEye /></Flex>
              </ElementoTooltipCmp>
            </Text>
            <Image width={'25.25rem'} src={seta} />
          </Box>
        </Box>
        <Box as='div'>
          <Grid gridTemplateColumns={'repeat(1, 1fr)'} gap={'.75rem'}>
            {Model.eixosY.map((e, i) => {
              return (
                <Grid key={i} gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
                  <GridItem alignSelf={'center'} w={'4.25rem'}>
                    <Text textAlign={'center'} color={'var(--Azul)'} display={'flex'} justifyContent={'center'} gap={'.5rem'} fontWeight={'700'} fontSize={'.925rem'}>
                      <span>{e.titulo}</span>
                    </Text>
                    <Text
                      textAlign={'center'}
                      fontSize={'0.75rem'}
                      color={'var(--Azul)'}
                    >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                  </GridItem>
                  {Model.quadrantes.filter(t => t.eixoY === e.id).map((q, i2) => {
                    return (
                      <GridItem cursor={'pointer'} key={i2}>
                        <EixoNineBox onClick={() => {
                          if (selecionarQuadrante !== undefined) {
                            selecionarQuadrante(q.id)
                          }
                        }} isSelect={q.id === quadranteSelecionado}
                          theme={ObterCorQuadrantePorPosicao(Model.quadrantes, q.eixoY, q.eixoX)}
                          heigth='7rem'
                          width='7rem'
                        >
                          <Text as={'span'} textAlign={'center'}>
                            {q.titulo}
                          </Text>
                          <img src={ObterImgPorPosicao(q.eixoY, q.eixoX)} alt="" />
                        </EixoNineBox>
                      </GridItem>
                    )
                  })}
                </Grid>
              )
            })}
            <Grid gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
              <GridItem maxW={'2rem'} mr={'-5rem'}></GridItem>
              {Model.eixosX.map((e, i) => {
                return (
                  <GridItem key={i} alignSelf={'center'}>
                    <Text
                      textAlign={'center'}
                      color={'var(--Azul)'}
                      display={'flex'}
                      justifyContent={'center'}
                      gap={'.5rem'}
                      fontWeight={'700'}
                      fontSize={'.925rem'}
                    >
                      <span>{e.titulo}</span>
                    </Text>
                    <Text
                      textAlign={'center'}
                      fontSize={'0.75rem'}
                      color={'var(--Azul)'}
                    >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                  </GridItem>
                )
              })}
            </Grid>
          </Grid>
        </Box>
      </Flex>
      <Flex pl={'5rem'} my={'2rem'}>
        <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'}>
          <Image width={'27rem'} src={seta} />
          <Text
            display={'flex'}
            justifyContent={'center'}
            fontWeight={'700'}
            gap={'.325rem'}
            alignItems={'center'}
            fontSize={'1.125rem'}
          >{Model.configuracaoEixoX.titulo.toUpperCase()}
            <ElementoTooltipCmp
              backgroundColor={'var(--Azul)'}
              label={Model.configuracaoEixoX.descricao}
            ><Flex alignItems={'center'}><FaEye /></Flex>
            </ElementoTooltipCmp>
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

import { InputGroup, InputRightElement } from '@chakra-ui/input'
import { Flex } from '@chakra-ui/layout'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../ButtonCmp'
import { FindUser } from '../FindBox'
import { iNomeId } from '../../interfaces'
import { InputCmp } from '../InputCmp'

interface iProps {
  colaboradores: iNomeId[]
  selecionados: string[]
  placeholder?: string
  onChoice: (value: string) => void
  onDelete: (id: string) => void
}

export const MultiColaborador: React.FC<iProps> = ({ placeholder, colaboradores, selecionados, onChoice, onDelete }) => {
  const [Add, setAdd] = useState(true)
  function InternalChoice(e: string): void {
    setAdd(false)
    onChoice(e)
  }
  return (
    <Flex flexDir={'column'} gap='.5rem'>
      {colaboradores.filter(r => selecionados?.some(t => t === r.id)).length > 0 && <Flex flexDir={'column'} gap={'.5rem'}>
        {colaboradores.filter(r => { return selecionados?.some(t => t === r.id) }).map((e, i) => {
          return (
            <InputGroup width={'100%'} key={i}>
              <InputRightElement
                cursor={'pointer'}
                h={'1.75rem'}
                children={<MdClose onClick={() => onDelete(e.id)} color='var(--Red)' />}
              />
              <InputCmp
                readOnly
                value={e.nome}
                borderColor={'var(--Gray4)'}
                OnChange={() => {}}
              />
            </InputGroup>
          )
        })}
      </Flex>}
      {(Add || selecionados.length === 0) &&
        <FindUser
          mt={'.5rem'}
          lista={colaboradores.filter(r => !selecionados?.some(t => t === r.id))}
          onChoice={InternalChoice}
          placeholder={placeholder}
        />
      }
      <Flex>
        <ButtonCmp onClick={() => setAdd(true)} VarColor='Green2'>Adicionar</ButtonCmp>
      </Flex>
    </Flex>
  )
}

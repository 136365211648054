import { Flex, Grid, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter, FaFolder, FaFolderMinus, FaFolderOpen } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ImgSah } from '../../../../components/ImgSah'
import LoadingContainer from '../../../../components/LoadingContainer'
import { ModalDelete } from '../../../../components/ModalDelete'
import { SearchCmp } from '../../../../components/SearchCmp'
import { SwitchCmp } from '../../../../components/SwitchCmp'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { CardGestao } from '../Gestao/Components/CardGestao'
import { ModalCopy } from '../Gestao/Components/ModalCopy'
import { ModalCreateGrupo } from './Components/ModalCreateGrupo'
import { ModalExcluirGrupo } from './Components/ModalExcluir'

interface iGestaoPesquisaInterna {
  id: string
  nomePesquisa: string
  grupoId?: string
  descricao: string
  dataInicio: string
  dataFim?: string
  ciclo: string
  statusPesquisa: string
  statusForm: string
  convites: number
  respostas: number
  anonima: boolean
  trilhaId: string
}

export interface iGrupoPesquisaInterna {
  nome: string
  id: string
}

interface iCopyPesquisa {
  nome: string
  descricao: string
}

export const GestaoGruposPesquisaInternaJornada: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()

  const [Pesquisas, setPesquisas] = useState<iGestaoPesquisaInterna[]>([])
  const [Grupos, setGrupos] = useState<iGrupoPesquisaInterna[]>([])
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalEncerrarIsOpen, setModalEncerrarIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalExcluirIsOpen, setModalExcluirIsOpen] = useState(false)

  const [PesquisaId, setPesquisaId] = useState<string>()
  const [GrupoAtivo, setGrupoAtivo] = useState('')
  const [NomePesquisa, setNomePesquisa] = useState('')

  const [Copy, setCopy] = useState<iCopyPesquisa>()
  const [IsLoading, setIsLoading] = useState(false)

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setPesquisaId(id)
  }

  function onOpenModalEncerrar(id: string): void {
    setModalEncerrarIsOpen(true)
    setPesquisaId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setModalEncerrarIsOpen(false)
  }

  function getGrupos(): void {
    appApi.get('PesquisaInterna/Grupos')
      .then(res => {
        setGrupos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getPesquisas(): void {
    setIsLoading(true)
    appApi.get(`PesquisaInterna/Gestao/?busca=${NomePesquisa}&tamanho=${99999}&pagina=${0}&grupoAtivo=${GrupoAtivo === 'geral' ? '' : GrupoAtivo}`)
      .then(res => {
        setPesquisas(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => { setIsLoading(false) })
  }

  function onDelete(): void {
    appApi.delete(`PesquisaInterna/${PesquisaId ?? ''}`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function EncerrarPesquisa(): void {
    appApi.patch(`PesquisaInterna/${PesquisaId ?? ''}/Encerrar`)
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function DuplicarPesquisa(texto: string, descricao: string): void {
    const form = {
      texto: texto,
      descricao: descricao
    }

    appApi.post(`PesquisaInterna/Copy/${PesquisaId as string}`, form)
      .then(res => {
        nav(`/Jornada/PesquisaInterna/Formulario/${res.data as string}`)
      })
      .catch(err => console.log(err))
  }

  function onOpenModalCopy(id: string): void {
    setPesquisaId(id)
    setModalCopyIsOpen(true)
    const pesquisa = Pesquisas?.find(e => e.id === id)
    if (pesquisa !== undefined) {
      setCopy({
        descricao: pesquisa.descricao,
        nome: pesquisa.nomePesquisa
      })
    }
  }

  function onCloseModalCopy(): void {
    setPesquisaId(undefined)
    setCopy(undefined)
    setModalCopyIsOpen(false)
  }

  function createGrupo(e: string): void {
    appApi.post('PesquisaInterna/Grupos', { texto: e })
      .then(() => {
        getGrupos()
        toast({
          title: 'Grupo criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (GrupoAtivo !== '') {
      getPesquisas()
    }
  }, [GrupoAtivo])

  useEffect(() => {
    getGrupos()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={onDelete}
      />

      <ModalDelete
        isOpen={ModalEncerrarIsOpen}
        message='Deseja realmente encerrar esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={EncerrarPesquisa}
      />

      <ModalCopy
        isOpen={ModalCopyIsOpen}
        onClose={onCloseModalCopy}
        onCreate={DuplicarPesquisa}
        copy={Copy}
      />

      <ModalCreateGrupo
        isOpen={ModalCreateIsOpen}
        onClose={() => setModalCreateIsOpen(false)}
        onCreate={(e) => createGrupo(e)}
      />
      <ModalExcluirGrupo
        isOpen={ModalExcluirIsOpen}
        onClose={() => { setModalExcluirIsOpen(false) }}
        grupos={Grupos}
        onDelete={() => {
          toast({
            title: 'Grupo excluido com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          getGrupos()
        }}
      />

      <Flex justifyContent={'space-between'} mt={'1rem'} mb={'1.6rem'}>
        <h1>Gestão de pesquisas internas</h1>
        <Flex flexDir={'column'} gap={'.75rem'} alignItems={'end'}>
          <ButtonCmp w={'10rem'} onClick={() => nav('/Jornada/PesquisaInterna/Criar')} VarColor='Green2' size={'lg'}>Criar nova pesquisa</ButtonCmp>
          <SwitchCmp
            OnChange={() => nav('/Jornada/PesquisaInterna/Gestao')}
            value={true}
            legendaEsquerda={'Visão por lista'}
            legendaDireita={'Visão por grupo'}
          />
        </Flex>
      </Flex>

        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'end'} marginBottom={'1.5rem'}>

          {/* <Flex gap={'1rem'} width='80%'>
            <FormControl width={'30%'} >
              <SelectCmp title='Tipo' OnChange={setTipo}>
                <option value={''}>Todas</option>
                <option value={'0'}>Nova</option>
                <option value={'1'}>Vinculada ao onboard</option>
              </SelectCmp>
            </FormControl>

            <FormControl width={'30%'} >
              <SelectCmp title='Status' OnChange={setStatusPesquisa}>
                <option value={''}>Nenhum</option>
                <option value={'Não enviado'}>🟡 Não enviado</option>
                <option value={'Em andamento'}>🔴 Em andamento</option>
                <option value={'Finalizado'}>🟢 Finalizado</option>
              </SelectCmp>
            </FormControl>
          </Flex> */}
          <Flex gap={'.75rem'}>
            {Grupos.length > 0 && <ButtonCmp VarColor='Red' onClick={() => setModalExcluirIsOpen(true)}>Excluir grupos</ButtonCmp>}
            {!(Grupos?.length === 0 && !IsLoading) && <ButtonCmp VarColor='Rosa' onClick={() => setModalCreateIsOpen(true)}>Criar grupo</ButtonCmp>}
          </Flex>

          <Flex w={'32%'} gap={'.5rem'} justifyContent={'end'}>
            <SearchCmp
              width='19rem'
              EnterAction={getPesquisas}
              OnChange={setNomePesquisa}
              placeholder='Pesquisa'
            />

            <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { getPesquisas() }}>Filtrar</ButtonCmp>
          </Flex>
        </Flex>
        {Grupos.length > 0 && GrupoAtivo === '' &&
          <Grid gridTemplateColumns={'repeat(6, 1fr)'} gap={'1rem'}>
            <Flex fontSize={'18px'} flexDir={'column'} onClick={() => setGrupoAtivo('geral')} cursor={'pointer'} borderRadius={'.5rem'} p={'1rem 1.5rem'} border={'2px solid var(--c4)'} fontWeight={'600'} alignItems={'center'} justifyContent={'center'} lineHeight={'24px'} _hover={{ border: '2px solid var(--Azul)', color: 'var(--Azul)' }}>
              <FaFolder size={110}/>
              <strong>Geral</strong>
            </Flex>
            {Grupos.map(e => {
              return (
                <Flex fontSize={'18px'} flexDir={'column'} onClick={() => setGrupoAtivo(e.id)} cursor={'pointer'} borderRadius={'.5rem'} p={'1rem 1.5rem'} border={'2px solid var(--c4)'} fontWeight={'600'} alignItems={'center'} justifyContent={'center'} lineHeight={'24px'} _hover={{ border: '2px solid var(--Azul)', color: 'var(--Azul)' }}>
                  <Flex gap={'.125rem'}>
                    <FaFolder size={110}/>
                  </Flex>
                  <strong>{e.nome}</strong>
                </Flex>
              )
            })}
          </Grid>
        }
        {GrupoAtivo !== '' && (
          <>
            <Grid gridTemplateColumns={'repeat(6, 1fr)'} gap={'1rem'}>
              <Flex fontSize={'18px'} flexDir={'column'} onClick={() => setGrupoAtivo('')} cursor={'pointer'} borderRadius={'.5rem'} p={'1rem 1.5rem'} border={'2px solid var(--c4)'} fontWeight={'600'} alignItems={'center'} justifyContent={'center'} lineHeight={'24px'} _hover={{ border: '2px solid var(--Azul)', color: 'var(--Azul)' }}>
                <FaFolderMinus size={102}/>
                <strong>Voltar</strong>
              </Flex>
              <Flex fontSize={'18px'} flexDir={'column'} borderRadius={'.5rem'} p={'1rem 1.5rem'} border={'2px solid var(--c4)'} fontWeight={'600'} alignItems={'center'} justifyContent={'center'} lineHeight={'24px'}>
                <FaFolderOpen size={110}/>
                <strong>{Grupos.find(e => e.id === GrupoAtivo)?.nome ?? 'Geral'}</strong>
              </Flex>
            </Grid>
            <LoadingContainer loading={IsLoading} linhas={20} my={'1rem'}>
              {Pesquisas.length > 0 &&
                <Flex flexDir={'column'} gap={'.75rem'} my={'1rem'}>
                  {Pesquisas?.length > 0 &&
                    Pesquisas.map((e, i) => {
                      return (
                        <CardGestao
                          key={i}
                          pesquisa={e}
                          onDelete={onOpenModalDelete}
                          onEncerrar={onOpenModalEncerrar}
                          onCopy={onOpenModalCopy}
                        />
                      )
                    })
                  }

                </Flex>
              }
              {
                (Pesquisas?.length === 0 && !IsLoading) && (
                  <Flex ml={'-3rem'} justifyContent={'center'}>
                    <ImgSah entidade='pesquisa'>
                      <ButtonCmp onClick={() => nav('/Jornada/PesquisaInterna/Criar')}
                        VarColor='Green2' size={'lg'}
                      >Criar nova pesquisa</ButtonCmp>
                    </ImgSah>
                  </Flex>
                )
              }
            </LoadingContainer>
          </>
        )}
        {
          (Grupos?.length === 0 && !IsLoading) && (
            <ImgSah entidade='grupo' masc>
              <ButtonCmp onClick={() => setModalCreateIsOpen(true)}
                VarColor='Green2'
              >Criar novo grupo</ButtonCmp>
            </ImgSah>
          )
        }

    </Body>
  )
}

import { Avatar, Box, Button, CircularProgress, CircularProgressLabel, Flex, Menu, MenuButton } from '@chakra-ui/react'
import { Container } from './styles'
import { useEffect, useState } from 'react'

import { iColaboradorBase } from '../../../../../interfaces'
import { FaDoorOpen, FaFilter, FaPlane, FaUserClock } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { useNavigate } from 'react-router-dom'
import { Paginador } from '../../../../../components/Paginador'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { SearchCmp } from '../../../../../components/SearchCmp'
import { parseJwt } from '../../../../../services/token'
import { MdMenu } from 'react-icons/md'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { StyledMenuList } from '../../../../../components/NavBar/Components/StyledMenuList'

export interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  tipo: string
  inicio?: string
  progresso: number
  concluido: boolean
}

interface iTabelaProps {
  dados?: iDadoTabela[]
  quantidade: number
  onFilter: (texto: string, pagina: number, tamanho: number) => void
  isLoading: boolean
}

export const Tabela: React.FC<iTabelaProps> = ({ dados, onFilter, quantidade, isLoading }) => {
  const nav = useNavigate()
  const [Pagina, setPagina] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [Texto, setTexto] = useState('')
  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  useEffect(() => {
    onFilter(Texto, Pagina, Tamanho)
  }, [Tamanho, Pagina])

  useEffect(() => {
    if (Pagina !== 0 && dados?.length === 0) {
      setPagina(0)
    }
  }, [dados])

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'space-between'} mb={'.75rem'}>
        <Flex gap={'.75rem'}>
          <h3>Colaboradores em acompanhamento</h3>
          <Menu>
            <MenuButton
              px={'0'}
              py={'.75rem'}
              _hover={{ bg: 'var(--Rosa)', color: 'var(--c1)' }}
              bg={'var(--c1)'}
              as={Button}
              _focus={{ bg: 'var(--c1)', color: 'var(--Rosa)' }}
              _active={{ bg: 'var(--Rosa)', color: 'var(--c1)' }}
              color={'var(--Rosa)'}
              border={'2px solid var(--Rosa)'}
              borderRadius={'.25rem'}
              fontSize={'.875rem'}
              display={'flex'}
              h={'1.85rem'}
              alignItems={'center'}
            ><MdMenu size={'1.25rem'} style={{ marginBottom: '-.3rem' }}/></MenuButton>
            <StyledMenuList>
              <StyledMenuItem
                onClick={() => nav('/Jornada/Onboard?tipo=0')}
              >
                <FaUserClock style={{ marginRight: '.4rem' }} />
                Onboarding
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => nav('/Jornada/Onboard?tipo=1')}
              >
                <FaPlane style={{ marginRight: '.4rem' }} />
                Desenvolvimento
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => nav('/Jornada/Onboard?tipo=3')}
              >
                <FaDoorOpen style={{ marginRight: '.4rem' }} />
                Offboarding
              </StyledMenuItem>
            </StyledMenuList>
          </Menu>
        </Flex>
        <Flex gap={'.5rem'} justifyContent={'end'}>
          <SearchCmp
            EnterAction={() => onFilter(Texto, Pagina, Tamanho)}
            OnChange={setTexto}
            placeholder='Digite o nome do colaborador'
          />
          <ButtonCmp VarColor='Green2' leftIcon={<FaFilter />} onClick={() => { onFilter(Texto, Pagina, Tamanho) }}>Filtrar</ButtonCmp>
        </Flex>
      </Flex>
      <LoadingContainer linhas={12} loading={isLoading} my={'1rem'}>
      {(dados && dados?.length > 0)
        ? <Paginador
          next={(e) => setPagina(e)}
          prev={(e) => setPagina(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          paginaAtual={Pagina}
          qtdElementos={quantidade}
          opcoesTamanho={['25', '50', '75', '100']}
          hideTop
        >
          <Container>
            <thead>
              <tr>
                <th>Colaborador</th>
                <th>Tipo</th>
                <th>Início</th>
                <th>Progresso</th>
              </tr>
            </thead>
            <tbody>
              {
                dados?.map((e, i) => {
                  return (
                    <Box as={'tr'} onClick={() => parseJwt().adm === 'True' ? nav(`/Rh/VisualizarPerfil/${e.colaborador.id}`) : {}} key={i} _hover={parseJwt().adm === 'True' ? { bg: 'var(--Green2)', cursor: 'pointer', color: 'var(--c1)' } : {}}>
                      <th>
                        <Flex alignItems={'center'}>
                          <Avatar marginRight={'1rem'} w={'2rem'} h={'2rem'} src={e.colaborador.avatar} name={e.colaborador.nome} />
                          <span>{e.colaborador.nome}</span>
                        </Flex>
                      </th>
                      <th>{e.tipo}</th>
                      <th>{e.inicio}</th>
                      <th>
                        <CircularProgress display={'flex'} justifyContent={'center'} size={10} value={e.progresso} color={getColor(e.progresso)}>
                          <CircularProgressLabel top={'49%'} fontSize={'.625rem'}>{e.progresso.toFixed(0)}%</CircularProgressLabel>
                        </CircularProgress>
                      </th>
                    </Box>
                  )
                })
              }
            </tbody>
          </Container>
        </Paginador>
        : <Flex alignItems={'center'} justifyContent={'center'} my={'1rem'} fontWeight={'600'}>Nenhum colaborador em acompanhamento</Flex>}
      </LoadingContainer>
    </Flex>
  )
}

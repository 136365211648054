import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Flex, FormControl, Grid, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { iIndicador } from '../..'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { ModalBase } from '../../../../../components/ModalBase'
import { iColaboradorBase, iNineBox, iPerfilColaborador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { iNota } from '../../../../Desempenho/NineBox/UsuarioEixo'
import { iPDI } from '../../../../PDI/Dashboard/Individual'
import { iResultadoIndividualResponse } from '../../../../PesquisaPercepcao/ResultadoIndividual'
import { iPostAvliacao } from '../../../../RH/VisualizarTrilhaColaborador/Components/TabNineBox'
import { IndicadorChart } from '../IndicadorChart'
import { AcaoCard } from './components/Acao'
import { NineBox } from './components/NineBox'
import { RadarChartJs } from './components/RadarChart'
import { Tabela } from './components/Tabela'
import { TabelaIndicador } from './components/TabelaIndicador'
import { Container } from './styles'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  indicador: iIndicador
}

interface iModel {
  pdi?: iPDI
  avaliacao?: iResultadoIndividualResponse
  nineBox: iNineBox
  colaborador: iPerfilColaborador
}

export const ModalNineBox: React.FC<iModalProps> = ({ isOpen, onConfirm, onClose, indicador }) => {
  const toast = useToast()
  const nav = useNavigate()
  const [Colaborador, setColaborador] = useState<iColaboradorBase>()
  const [Comite, setComite] = useState<string[]>([])
  const [Comentario, setComentario] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [Model, setModel] = useState<iModel>()
  const [NomeQuadrante, setNomeQuadrante] = useState<string>()
  const [NotaXInconentente, setNotaXInconentente] = useState(false)
  const [NotaYInconentente, setNotaYInconentente] = useState(false)
  const [PeriodoSelecionado, setPeriodoSelecionado] = useState(0)

  const [Notas, setNotas] = useState<iNota>({
    notaX: 0,
    notaY: 0
  })

  function PrevPage(): void {
    if (PeriodoSelecionado !== 0) {
      setPeriodoSelecionado(PeriodoSelecionado - 1)
    } else {
      setPeriodoSelecionado(indicador.periodos.length - 1)
    }
  }

  function NextPage(): void {
    if (PeriodoSelecionado !== indicador.periodos.length - 1) {
      setPeriodoSelecionado(PeriodoSelecionado + 1)
    } else {
      setPeriodoSelecionado(0)
    }
  }

  function SelecionarQuadrante(id: string, notaX?: number, notaY?: number): void {
    setNomeQuadrante(id)
    VerificarIncoerencia(id, notaX, notaY)
  }

  function VerificarIncoerencia(nome: string, notaX?: number, notaY?: number): void {
    setNotaXInconentente(false)
    setNotaYInconentente(false)
    const quadrante = Model?.nineBox.quadrantes.find(e => e.id === nome)
    if (quadrante) {
      const configx = Model?.nineBox.eixosX.find(e => e.id === quadrante.eixoX)
      const configy = Model?.nineBox.eixosY.find(e => e.id === quadrante.eixoY)
      if (notaX && notaY && configx && configy) {
        if (!(notaX >= configx?.rangeMinimo && notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(notaY >= configy?.rangeMinimo && notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      } else if (configx && configy) {
        if (!(Notas.notaX >= configx?.rangeMinimo && Notas.notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(Notas.notaY >= configy?.rangeMinimo && Notas.notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      }
    }
  }

  function TemColuna(tipo: number): boolean {
    if (Model?.avaliacao) {
      return Model?.avaliacao?.participantes.includes(tipo)
    }
    return false
  }

  function AlterarNota(notas: iNota): void {
    setNotas(notas)
    const configY = Model?.nineBox.eixosY.find(r => notas.notaY >= r.rangeMinimo && notas.notaY <= r.rangeMaximo)
    const configX = Model?.nineBox.eixosX.find(r => notas.notaX >= r.rangeMinimo && notas.notaX <= r.rangeMaximo)
    if (configY && configX) {
      const quadrante = Model?.nineBox.quadrantes.find(r => r.eixoX === configX.id && r.eixoY === configY.id)
      if (quadrante) {
        setNomeQuadrante(quadrante.id)
        if (NotaXInconentente || NotaYInconentente) {
          VerificarIncoerencia(quadrante.id)
        }
      }
    }
  }

  function AvaliarColaborador(): void {
    const form: iPostAvliacao = {
      boxId: Model?.nineBox.id ?? '',
      colaboradorId: Colaborador?.id ?? '',
      comentario: Comentario,
      comite: Comite,
      notaX: Notas.notaX,
      notaY: Notas.notaY,
      quandranteId: NomeQuadrante ?? ''
    }

    appApi.post('Colaborador/NineBox', form)
      .then(() => {
        toast({
          title: 'Avaliação computada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav(`/Desempenho/9Box?quadranteId=${NomeQuadrante}`)
      })
      .catch(err => console.log(err))
      .finally(() => onConfirm())
  }

  useEffect(() => {
    if (Colaborador) {
      setIsLoading(true)
      setComite([Colaborador.id])
      appApi.get(`Indicador/${indicador.id}/9Box/${Colaborador.id}`)
        .then(res => {
          setModel(res.data)
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false))
    }
  }, [Colaborador])

  useEffect(() => {
    if (!PropInvalida(Model?.colaborador?.avaliacaoNineBox)) {
      setNotas({
        notaX: Model?.colaborador?.avaliacaoNineBox?.notaX ?? 0,
        notaY: Model?.colaborador?.avaliacaoNineBox?.notaY ?? 0
      })
      SelecionarQuadrante(Model?.colaborador?.avaliacaoNineBox?.idQuadrante ?? '', Model?.colaborador?.avaliacaoNineBox?.notaX, Model?.colaborador?.avaliacaoNineBox?.notaY)
    }
  }, [Model?.colaborador])

  return (
    <ModalBase
      Titulo={Colaborador ? 'Avalie o 9Box do colaborador ' + Colaborador.nome : 'Selecione um dos participantes para avaliar seu 9Box'}
      isOpen={isOpen}
      onClose={onClose}
      Width={Colaborador ? '84rem' : '60rem'}
    >
      {!Colaborador && <Grid gridTemplateColumns={'repeat(3, 1fr)'} px={'1.125rem'} py={'1rem'} flexDir={'column'} gap={'1rem'}>
        {indicador.responsavel && <Flex
            alignItems="center"
            gap=".5rem"
            bg="var(--AzulOpacoBg)"
            border="1px solid var(--c4)"
            borderRadius=".25rem"
            boxShadow="var(--SombraBackground)"
            px="1rem"
            py=".5rem"
          >
            <Avatar size="sm" src={indicador.responsavel.avatar} />
            <Flex justifyContent={'space-between'} lineHeight="15px" w={'100%'} alignItems={'center'}>
              <Text fontSize="13px"fontWeight={700} maxW={'140px'}>{indicador.responsavel.nome}</Text>
              <ButtonCmp VarColor='Green2' small onClick={() => setColaborador(indicador.responsavel)}>Avaliar</ButtonCmp>
            </Flex>
          </Flex>}
        {indicador.participantes?.map(colab => {
          return (<Flex
            alignItems="center"
            gap=".5rem"
            bg="var(--AzulOpacoBg)"
            border="1px solid var(--c4)"
            borderRadius=".25rem"
            boxShadow="var(--SombraBackground)"
            px="1rem"
            py=".5rem"
          >
            <Avatar size="sm" src={colab.avatar} />
            <Flex justifyContent={'space-between'} lineHeight="15px" w={'100%'} alignItems={'center'}>
              <Text fontSize="13px"fontWeight={700} maxW={'140px'}>{colab.nome}</Text>
              <ButtonCmp VarColor='Green2' small onClick={() => setColaborador(colab)}>Avaliar</ButtonCmp>
            </Flex>
          </Flex>)
        })}
      </Grid>}
      {Colaborador && <LoadingContainer loading={IsLoading} linhas={16} my='1rem' px='1.5rem'>
          {Model && <Flex gap={'1rem'} px={'1rem'} py={'.5rem'}>
            <Container>
              <Accordion allowToggle w={'full'}>
                {Model.avaliacao && <AccordionItem>
                  <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--c1)'} color={'var(--Azul)'} gap={'.3rem'} mt={'.5rem'} px={'0'} borderBottom={'1px solid var(--Azul)'}>
                    <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                      Pesquisa de desempenho - {Model.avaliacao?.nomePesquisa}
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel py={'0.25rem'} px={'0.75rem'} display={'flex'} flexDir='column' gap={'.75rem'}>
                    <Flex flexDir={'column'} gap={'.5rem'} pb={'1.25rem'} borderBottom={'1px solid var(--AzulOpaco)'}>
                      <Text fontSize={'14px'} fontWeight={600}>Tabela dos resultados por hierarquia</Text>
                      <Tabela
                        cores={Model.avaliacao.cores}
                        discursivas={Model.avaliacao.discursivas}
                        linhas={Model.avaliacao.tabela}
                        mediaGeral={Model.avaliacao.mediaGeral}
                        recomendacoes={Model.avaliacao.recomendacoes}
                        temColuna={TemColuna}
                        apenasAutoAvaliacao={Model.avaliacao.apenasAutoAvaliacao}
                      />
                    </Flex>
                    <Flex flexDir={'column'} gap={'.5rem'}>
                      <Text fontSize={'14px'} fontWeight={600}>Gráfico de hierarquia</Text>
                      <RadarChartJs
                        competencias={Model?.avaliacao?.graficoHierarquia?.competencias ?? []}
                        dados={Model?.avaliacao?.graficoHierarquia?.dados ?? []}
                        notaMinimaEscala={0}
                        notaMaximaEscala={Model.avaliacao.escala?.respostas[Model.avaliacao.escala?.respostas.length]?.peso}
                        stepSize={((Model.avaliacao.escala?.respostas[0]?.peso ?? 0) - (Model.avaliacao.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                      />
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>}
                {indicador && <AccordionItem>
                  <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--c1)'} color={'var(--Azul)'} gap={'.3rem'} mt={'.5rem'} px={'0'} borderBottom={'1px solid var(--Azul)'}>
                    <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                      Indicador - {indicador.nome}
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel py={'0.25rem'} px={'0.75rem'} display={'flex'} flexDir='column' gap={'1.25rem'}>
                    <Flex flexDir={'column'} gap={'.75rem'}>
                      <HStack mt={'1rem'} fontSize={'.875rem'}>
                        <Text>Ano:</Text>
                        <Text>{indicador.periodos[PeriodoSelecionado].ano}</Text>
                        {indicador.periodos.length > 1 && <><ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { PrevPage() }} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
                        <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { NextPage() }} VarColor='Green2'><FaArrowRight/></ButtonCmp></>}
                      </HStack>
                      <TabelaIndicador
                        status={indicador.status}
                        tipo={indicador.tipoMeta}
                        periodo={indicador.periodos[PeriodoSelecionado]}
                        isResponsavel={indicador.isResponsavel}
                      />
                    </Flex>
                    <IndicadorChart
                      indicador={indicador}
                      data={indicador.periodos[PeriodoSelecionado]?.meses}
                      id={'indicador-chart' + '-' + `${indicador.id}-modal-ninebox`}
                    />
                  </AccordionPanel>
                </AccordionItem>}
                {Model.pdi && <AccordionItem>
                  <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--c1)'} color={'var(--Azul)'} gap={'.3rem'} mt={'.5rem'} px={'0'} borderBottom={'1px solid var(--Azul)'}>
                    <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                      Último PDI do colaborador - {Model.pdi.acoes.length} Ações trabalhadas
                      <AccordionIcon />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel py={'0.25rem'} px={'0.75rem'} display={'flex'} flexDir='column' gap={'.75rem'}>
                    <Flex flexDir={'column'} marginTop='1rem' gap={'.75rem'}>
                      <Text fontSize={'.875rem'} fontWeight={600}>Ações do PDI</Text>
                      <Grid gap={'.75rem'} templateColumns='repeat(3, 1fr)'>
                        {
                          Model.pdi.acoes.map((a, i2) => {
                            return (
                              <AcaoCard
                                key={i2}
                                acao={a}
                              />
                            )
                          })
                        }
                      </Grid>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>}
              </Accordion>
            </Container>
            <Flex minH={'100%'} w={'2px'} bg={'var(--c6)'}></Flex>
            <Flex w={'532px'} pr={'1rem'} flexDir={'column'}>
              <Flex mb={'2rem'} gap={'1.25rem'} pl={'1.5rem'}>
                  <Flex flexDirection={'column'}>
                    <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'}>
                      {Model.nineBox.configuracaoEixoY.titulo}
                    </Box>
                    <NumberInput
                      isInvalid={NotaYInconentente}
                      onChange={(e) => AlterarNota({ ...Notas, notaY: parseFloat(e) })}
                      value={Notas.notaY} width={'7.9rem'}
                      min={0}
                      max={200}
                      step={0.1}
                      h={'1.75rem'}
                      size={'sm'}
                      borderRadius={'.25rem'}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                  <Flex flexDirection={'column'}>
                    <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'}>
                      {Model.nineBox.configuracaoEixoX.titulo}
                    </Box>
                    <NumberInput
                      isInvalid={NotaXInconentente}
                      onChange={(e) => AlterarNota({ ...Notas, notaX: parseFloat(e) })}
                      width={'7.9rem'}
                      value={Notas.notaX}
                      step={0.1}
                      min={0}
                      max={200}
                      h={'1.75rem'}
                      size={'sm'}
                      borderRadius={'.25rem'}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
              </Flex>
              <NineBox
                quadranteSelecionado={NomeQuadrante}
                selecionarQuadrante={SelecionarQuadrante}
                model={Model?.nineBox}
                variant='avaliar'
              />
              <FormControl>
                <Text fontWeight={'700'} fontSize={'.875rem'} mb={'.25rem'}>Deixe um comentário para registro da calibração</Text>
                <Textarea
                  color={'var(--Gray2)'}
                  placeholder='Deixe seu comentário'
                  height={'8.25rem'}
                  borderColor='var(--Gray4)'
                  onChange={(e) => setComentario(e.target.value)}
                />
              </FormControl>
            </Flex>
          </Flex>}
        </LoadingContainer>}
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => { if (Colaborador) { setColaborador(undefined) } else { onClose() } }}>{Colaborador ? 'Voltar' : 'Sair'}</ButtonCmp>
        {Colaborador && <ButtonCmp VarColor='Green2' isDisabled={!NomeQuadrante} onClick={AvaliarColaborador} leftIcon={<FaSave/>}>Avaliar colaborador</ButtonCmp>}
      </Flex>
    </ModalBase>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
cursor: pointer;
background: var(--c1);
width: fit-content;
padding: .5rem 1rem;
border-radius: .25rem;
margin-left: 1.5rem;
color: var(--Azul);
border: 3px solid var(--Azul);

div:nth-child(1){
    background: var(--Azul);
    color: white;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div:nth-child(2){
    display: flex;
    align-items: center;
    padding-left: .75rem;
    span{
        font-weight: 700;
        font-size: .875rem;
        font-family: 'Poppins';
    }
}

&:hover{
    transition: .3s;
    color: white;
    background: var(--Azul);
    box-shadow: var(--SombraBtns);

    div:nth-child(1){
        background: var(--c1);
        color: var(--Azul);
    }
}
`

// height: 80px;
//

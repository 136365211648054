import { Checkbox, Divider, Flex, FormLabel, Input, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { iAlternativaFlexivel, iPutQuestaoCaixaSelecao, iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iCaixaSelecaoProps {
  questao: iQuestaoFlexivel
  onCreate: (id: string) => void
  onDelete: (questaoId: string, alternativaId: string) => void
  onUpdate: (questaoId: string, alternativaId: string, texto: string) => void
  onUpdateMaxMin: (questaoId: string, form: iPutQuestaoCaixaSelecao) => void
}

export const CaixaDeSelecao: React.FC<iCaixaSelecaoProps> = ({ questao, onCreate, onDelete, onUpdate, onUpdateMaxMin }) => {
  const [Alternativas, setAlternativas] = useState<iAlternativaFlexivel[]>([])
  const [Editado, setEditado] = useState('')

  const [Min, setMin] = useState('')
  const [Max, setMax] = useState('')

  useEffect(() => {
    if (questao.minCaixaSelecao !== undefined && questao.minCaixaSelecao !== null) {
      setMin(questao.minCaixaSelecao.toString())
    }
    if (questao.maxCaixaSelecao !== undefined && questao.maxCaixaSelecao !== null) {
      setMax(questao.maxCaixaSelecao.toString())
    }
  }, [questao])

  useEffect(() => {
    setAlternativas(questao.alternativas)
    setEditado('')
  }, [questao.alternativas])

  function UpdateArray(pos: number, texto: string): void {
    const copy = [...Alternativas]
    copy[pos].texto = texto
    setAlternativas(copy)
    setEditado(copy[pos].id)
  }

  return (
        <Flex flexDir={'column'} width='100%'>
            <RadioGroup width={'100%'}>
                <FormLabel mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} wordBreak={'keep-all'}>Opções</FormLabel>
                <Stack direction={'column'} width={'100%'}>
                    {
                        Alternativas.map((e, i) => {
                          return (
                                <Flex
                                    key={e.id}
                                    borderRadius={'4px'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    border={'1px solid var(--c4)'}
                                    width={'100%'}
                                    padding={'0 .875rem'}
                                >
                                    <Flex width={'100%'}>
                                        <Checkbox marginRight={'.75rem'} value={e.id} />
                                        <Input
                                            type={'text'}
                                            border={'none'}
                                            _focus={{ border: 'none' }}
                                            padding={'0'}
                                            h={'2.125rem'}
                                            fontSize={'.875rem'}
                                            value={e.texto}
                                            placeholder={'Digite sua alternativa aqui'}
                                            onChange={(r) => UpdateArray(i, r.target.value)}
                                            onBlur={() => {
                                              if (e.id === Editado) { onUpdate(questao.id, e.id, e.texto) }
                                            }}
                                        // borderBottom={'2px solid var(--Gray4)'}
                                        // boxShadow='none'
                                        // borderRadius={0}
                                        />
                                    </Flex>
                                    <Flex alignItems={'center'} justifyContent={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }} onClick={() => onDelete(questao.id, e.id)}>
                                      <MdClose
                                          size={18}
                                          cursor={'pointer'}
                                      />
                                    </Flex>
                                </Flex>
                          )
                        })
                    }
                </Stack>
            </RadioGroup>

            <Flex m={'1rem 0 .5rem 0'} justifyContent={'space-between'} alignItems={'center'}>
                <Flex gap={'.5rem'} alignItems={'center'}>
                  <Checkbox isChecked={true} colorScheme={'blue'} />
                  <Input
                      border={'none'}
                      value={'Adicione mais uma opção'}
                      borderBottom={'1px solid var(--Gray4)'}
                      width={'fit-content'}
                      boxShadow='none'
                      borderRadius={0}
                      h={'2.125rem'}
                      color={'var(--c6)'}
                      fontSize={'.875rem'}
                      _hover={{ borderBottom: '1px solid var(--Azul)', boxShadow: 'var(--SombraBtns)', color: 'var(--Azul)' }}
                      _focus={{ borderBottom: '1px solid var(--Gray4)' }}
                      _active={{ borderBottom: '1px solid var(--Gray4)' }}
                      readOnly
                      cursor={'pointer'}
                      onClick={() => onCreate(questao.id)}
                  />
                </Flex>
                <Divider orientation='vertical' borderWidth={'1px'} height={'1.5rem'} borderColor={'var(--Gray3)'}/>
                <Flex alignItems={'center'} fontSize={'.875rem'} gap={'.325rem'}>
                    <span style={{ marginTop: '.25rem' }}>Mínimo de alternativas</span>
                    <Input
                        border={'none'}
                        placeholder={'Quantidade'}
                        borderBottom={'1px solid var(--Gray4)'}
                        boxShadow='none'
                        borderRadius={0}
                        width={'fit-content'}
                        type={'number'}
                        h={'2.125rem'}
                        color={'var(--c6)'}
                        fontSize={'.875rem'}
                        _hover={{ borderBottom: '1px solid var(--Azul)', color: 'var(--Azul)' }}
                        _focus={{ borderBottom: '1px solid var(--Azul)' }}
                        _active={{ borderBottom: '1px solid var(--Azul)' }}
                        min={0}
                        marginLeft={'.5rem'}
                        value={Min}
                        onChange={(e) => setMin(parseInt(e.target.value) > Alternativas.length ? Alternativas.length.toString() : e.target.value)}
                        onBlur={() => onUpdateMaxMin(questao.id, {
                          maxCaixaSelecao: parseInt(Max),
                          minCaixaSelecao: parseInt(Min)
                        })}
                    />
                </Flex>
                <Divider orientation='vertical' borderWidth={'1px'} height={'1.5rem'} borderColor={'var(--Gray3)'}/>
                <Flex alignItems={'center'} fontSize={'.875rem'} gap={'.325rem'}>
                      <span style={{ marginTop: '.25rem' }}>Máximo de alternativas</span>
                      <Input
                          border={'none'}
                          placeholder={'Quantidade'}
                          borderBottom={'1px solid var(--Gray4)'}
                          boxShadow='none'
                          borderRadius={0}
                          width={'fit-content'}
                          type={'number'}
                          h={'2.125rem'}
                          color={'var(--c6)'}
                          fontSize={'.875rem'}
                          _hover={{ borderBottom: '1px solid var(--Azul)', color: 'var(--Azul)' }}
                          _focus={{ borderBottom: '1px solid var(--Azul)' }}
                          _active={{ borderBottom: '1px solid var(--Azul)' }}
                          marginLeft={'.5rem'}
                          value={Max}
                          onChange={(e) => setMax((parseInt(e.target.value) > Alternativas.length || parseInt(e.target.value) < parseInt(Min)) ? Alternativas.length.toString() : e.target.value)}
                          onBlur={() => onUpdateMaxMin(questao.id, {
                            maxCaixaSelecao: parseInt(Max),
                            minCaixaSelecao: parseInt(Min)
                          })}
                      />
                </Flex>
            </Flex>
        </Flex>
  )
}

import { Avatar, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { PropInvalida } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { CardPDI } from '../../../PDI/Criar/styles'

interface iModel {
  tipoReuniao: number
  assunto: string
  descricao: string
  presencial: boolean
  local?: string
  dataInicio?: string
  duracao?: number
}

interface iParticipante {
  id: string
  nome: string
  area: string
  avatar: string
}

export const AgendamentoReuniaoOne: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { etapaId, processoId } = useParams<{ etapaId: string, processoId: string }>()
  const [Reagendar, setReagendar] = useState(false)

  const [Model, setModel] = useState<iModel>({
    tipoReuniao: 0,
    assunto: '',
    descricao: '',
    presencial: false,
    duracao: 5
  })

  const [Participante, setParticipante] = useState<iParticipante>()
  const [IsLoading, setIsLoading] = useState(false)
  const [DataLimiteInicio, setDataLimiteInicio] = useState('')
  const [DataLimiteFim, setDataLimiteFim] = useState('')

  const [EditorIsLoading, setEditorIsLoading] = useState(false)

  function ObterReuniao(): void {
    setEditorIsLoading(true)
    appApi.get(`ReuniaoOneOldEtapaColaborador/${processoId ?? ''}/Agendar/${etapaId ?? ''}`)
      .then(res => {
        const model: iModel = res.data.reuniao
        setModel({
          tipoReuniao: model.tipoReuniao,
          assunto: model.assunto,
          descricao: model.descricao,
          presencial: model.presencial,
          duracao: model.duracao,
          dataInicio: model.dataInicio,
          local: model.local
        })
        setDataLimiteFim(res.data.dataLimiteFim)
        setDataLimiteInicio(res.data.dataLimiteInicio)
        setParticipante(res.data.participante)
        setReagendar(!PropInvalida(model.dataInicio))
        setTimeout(() => setEditorIsLoading(false), 1000)
      })
      .catch(err => console.log(err))
  }

  function AgendarReuniao(): void {
    setIsLoading(true)
    if (PropInvalida(Model.dataInicio)) {
      toast({
        title: 'Selecione uma data para a reunião',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    if (PropInvalida(Model.duracao)) {
      toast({
        title: 'Selecione um tempo de duração para a reunião',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    if (PropInvalida(Model.local)) {
      toast({
        title: 'Digite um local ou link para a reunião',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Reagendar) {
      appApi.put(`ReuniaoOneOldEtapaColaborador/${processoId ?? ''}/Reagendar/${etapaId ?? ''}`, Model)
        .then(() => {
          toast({
            title: 'Reunião agendada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          nav('/')
        })
        .catch(({ response }) => {
          toast({
            title: 'Verifique os campos para agendar',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setIsLoading(false)
        }
        )
    } else {
      appApi.post(`ReuniaoOneOldEtapaColaborador/${processoId ?? ''}/Agendar/${etapaId ?? ''}`, Model)
        .then(() => {
          toast({
            title: 'Reunião agendada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          nav('/')
        })
        .catch(({ response }) => {
          toast({
            title: 'Verifique os campos para agendar',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setIsLoading(false)
        }
        )
    }
  }

  useEffect(() => {
    ObterReuniao()
  }, [])

  return (
    <Body>
      <WhiteBox>
        <h1>Agendamento de reunião 1:1</h1>
        <Flex marginTop={'1rem'}>
          {
            (Participante) && (
              <Flex flexDir={'column'}>
                <CardPDI>
                  <Avatar size={'lg'} marginRight={'1rem'} src={Participante.avatar} name={Participante.nome} />
                  <Flex textAlign={'center'} flexDir={'column'}>
                    <span>{Participante.nome}</span>
                    <strong>{Participante.area}</strong>
                  </Flex>
                </CardPDI>
              </Flex>
            )
          }
        </Flex>
        <Flex margin={'1.5rem 0'} alignItems='start' width={'75%'}>
          <FormControl width={'100%'} marginRight={'1rem'}>
            <FormLabel color={'var(--Gray1)'} fontSize={'xl'}>Tipo da reunião</FormLabel>
            <Select
              width={'20rem'}
              isDisabled={true}
              marginRight='1rem'
              value={Model.tipoReuniao.toString()}
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, tipoReuniao: parseInt(e.target.value) })}
            >
              <option value={'0'}>Feedback com o gestor</option>
              <option value={'1'}>Feedback com o RH</option>
            </Select>
          </FormControl>
        </Flex>
        <Flex gap={'1.5rem'} flexDirection={'column'}>
          <FormControl>
            <FormLabel fontSize={'xl'}>Assunto da reunião</FormLabel>
            <Input
              value={Model.assunto}
              type={'text'}
              disabled={true}
              placeholder={'Digite o assunto da reunião'}
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, assunto: e.target.value })}
            />
          </FormControl>
          <Flex justifyContent={'space-around'}>
            <FormControl width={'30%'}>
              <FormLabel fontSize={'xl'}>Data e hora de início</FormLabel>
              <Input
                onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                value={(Model.dataInicio === '0001-01-01T00:00:00' || Model.dataInicio === '0001-01-01T00:00:00') ? '' : Model.dataInicio}
                type={'datetime-local'}
                borderColor={'var(--Gray4)'}
                min={DataLimiteInicio}
                max={DataLimiteFim}
              />
            </FormControl>

            <FormControl width={'30%'}>
              <FormLabel fontSize={'xl'}>Duração</FormLabel>
              <Select value={Model.duracao ? Model.duracao.toString() : '5'} onChange={(e) => setModel({ ...Model, duracao: parseInt(e.target.value) })} borderColor={'var(--Gray4)'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </Select>
            </FormControl>
          </Flex>

          <Flex gap={'1rem'} flexDir={'column'}>
            <RadioGroup onChange={(e) => setModel({ ...Model, presencial: e === '1' })} value={Model.presencial ? '1' : '0'}>
              <FormLabel fontSize={'xl'}>Local</FormLabel>
              <Stack direction={'row'} borderColor={'var(--Gray4)'}>
                <Radio value={'0'}>Online</Radio>
                <Radio value={'1'}>Presencial</Radio>
              </Stack>
            </RadioGroup >

            <Input
              value={Model.local}
              placeholder={'Digite o local da reunião'}
              flexDir={'column'}
              onChange={(e) => setModel({ ...Model, local: e.target.value })}
            />
          </Flex>

          <FormControl>
            <FormLabel fontSize={'xl'}>Descrição</FormLabel>
            <EditorTexto
              isLoading={EditorIsLoading}
              initialValue={Model.descricao}
              placeholder='Deixe uma descrição aqui'
              onChange={(e: string) => setModel({ ...Model, descricao: e })}
            />
          </FormControl>
        </Flex>

      </WhiteBox>
      <Flex gap={'1rem'} marginTop={'1rem'} justifyContent={'end'}>
        <Button
          VarColor='c5'
          size={'lg'}
          onClick={() => nav(-1)}
        >Cancelar</Button>

        <Button
          isLoading={IsLoading}
          VarColor='v2'
          size={'lg'}
          onClick={AgendarReuniao}
        >{Reagendar ? 'Reagendar' : 'Agendar'} reunião</Button>
      </Flex>
    </Body>
  )
}

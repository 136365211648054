import { Avatar, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iCardOrganograma } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { Card, ImgAssociado } from './styles'

interface iProps {
  colaboradores: iCardOrganograma[]
  tipo: string
}

export const ContainerOrganograma: React.FC<iProps> = ({ colaboradores, tipo }) => {
  const nav = useNavigate()
  const { cid } = parseJwt()
  const navegar = (colab: iCardOrganograma): any => colab.id === cid ? nav('/') : colab.visualizar ? nav(`/Rh/VisualizarPerfil/${colab.id}`) : ''
  return (
    <Flex justifyContent={'center'} gap='.625rem' marginBottom='.5rem'>
      {colaboradores?.length <= 2 && colaboradores.map((e, i) => {
        return (
          <Card
            navegavel={e.visualizar}
            key={i}
            onClick={() => navegar(e)}
          >
            <Avatar
              height={'2.25rem'}
              width={'2.25rem'}
              marginRight={'.5rem'}
              size={'sm'}
              name={e.nome}
              src={e?.avatar}
            />
            <Flex flexDir={'column'} fontSize={'.875rem'}>
              <span style={{ lineHeight: '1rem' }}>{e?.nome.substring(0, 10)}...</span>
              <strong style={{ lineHeight: '1rem' }}>{tipo}</strong>
            </Flex>
          </Card>
        )
      })}

      {colaboradores?.length >= 3 &&
        <>
          <Card
            navegavel={colaboradores[0].visualizar}
            onClick={() => navegar(colaboradores[0])}
          >
            <ImgAssociado src={colaboradores[0].avatar} />
            <Flex flexDir={'column'} fontSize={'.875rem'}>
              <span>{colaboradores[0].nome.substring(0, 10)}...</span>
              <strong>{tipo}</strong>
            </Flex>
          </Card>

          <div>
            <Card
              navegavel={colaboradores[1].visualizar}
              style={{ position: 'absolute', zIndex: 4 }}
              onClick={() => navegar(colaboradores[1])}
            >
              <ImgAssociado src={colaboradores[1].avatar} />
              <Flex flexDir={'column'} fontSize={'.875rem'}>
                <span>{colaboradores[1].nome.substring(0, 8)}...</span>
                <strong>{tipo}</strong>
              </Flex>
            </Card>

            {!PropInvalida(colaboradores[2]) &&
              <Card style={{ position: 'relative', left: '.325rem', marginTop: '-.25rem', zIndex: 3 }}>
                <ImgAssociado src='' />
                <Flex flexDir={'column'}>
                  <span></span>
                  <strong></strong>
                </Flex>
              </Card>
            }

            {PropInvalida(colaboradores[3]) &&
              <Card style={{ position: 'relative', left: '.625rem', marginTop: '-3.4rem', zIndex: 2 }}>
                <ImgAssociado src='' />
                <Flex flexDir={'column'}>
                  <span></span>
                  <strong></strong>
                </Flex>
              </Card>
            }
          </div>
        </>
      }
    </Flex>
  )
}

import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { useState } from 'react'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (texto: string) => void
}

export const ModalChamadoRh: React.FC<props> = ({ isOpen, onConfirm, onRequestClose }) => {
  const [Text, setText] = useState('')

  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
        <Flex width={'43.75rem'}>
          <Flex flexDir={'column'} padding='1rem 0 0rem 1rem'>
            <h3>Nos conte mais sobre o que esta acontecendo</h3>
            <span style={{ fontSize: '.875rem' }}>Ao enviar a mensagem, o departamento de RH receberá o chamado e irá entrar em contato o mais breve possível para solucionar suas dúvidas!</span>
          </Flex>
          <Flex padding={'.5rem'}>
                <MdClose cursor={'pointer'} color={'var(--Red)'} size={24} onClick={onRequestClose} />
          </Flex>
        </Flex>
        <Flex padding={'.75rem 1rem'}>
          <TextAreaCmp value={Text} OnChange={(e) => setText(e)} height={'8rem'}/>
        </Flex>
        <Flex padding={'0 1rem 1rem 1rem'}>
          <ButtonCmp onClick={() => onConfirm(Text)} width={'100%'} VarColor='Green2'>Enviar chamado</ButtonCmp>
        </Flex>
    </Modal>
  )
}

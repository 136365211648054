import styled from 'styled-components'

export const Container = styled.div`
padding: 4rem;
display: flex;
flex-direction: column;
align-items: center;

.navElipse{
    div{
        height: 12px;
        width: 12px;
        border-radius: 6px;
        background-color: var(--c4);
        cursor: pointer;

        &:hover{
            background-color: var(--c7);
            transition: ease .5s all ;
        }
    }

    div:not(:last-child){
        margin-right: 1rem;
    }
}
`

export const BoxGenero = styled.div`
border-radius: 8px;
background: var(--c2);
padding: 1rem;
box-shadow: var(--SombraBtns);
margin-top:1rem;

span{
    padding: 0.3rem 1rem;
    font-weight: 500;
    font-family:'Roboto','sans-serif';
    color: var(--a3); 

    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

span:not(:last-child){
    border-right: 1px solid var(--c7);
}
`

export const GreyBox = styled.div`
border: 3px solid var(--c5);
padding: 1rem;
display: flex;
align-items: center;
border-radius: 10px;


label{
    font-family: 'Roboto','sans-serif';
    font-weight: bold;
    color: var(--a4);
}

label:hover{
    text-decoration: underline;
    cursor: pointer;
}

input{
    display: none;
}
`

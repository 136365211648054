import { Flex, Text } from '@chakra-ui/react'
import { FaTrash } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { iGrupoPesquisaInterna } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { appApi } from '../../../../../../services/appApi'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  grupos: iGrupoPesquisaInterna[]
}

export const ModalExcluirGrupo: React.FC<iModalProps> = ({ isOpen, onClose, onDelete, grupos }) => {
  // function onOpenDeleteGrupo(id: string): void {
  //   setGrupoId(id)
  //   setDeleteGrupoIsOpen(true)
  // }

  function deleteGrupo(id: string): void {
    appApi.delete(`PesquisaInterna/Grupos/${id ?? ''}`)
      .then(() => { onDelete() })
      .catch(err => console.log(err))
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
        <Flex flexDir={'column'} width='30rem'>
            <Flex borderBottom={'1px solid var(--c6)'}>
                <Flex width={'100%'} justifyContent={'space-between'} padding='1rem'>
                    <h3>Excluir grupos</h3>
                    <MdClose onClick={onClose} size={18} cursor={'pointer'}/>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='.75rem' padding={'1rem'}>
                {grupos.map(e => {
                  return (
                    <Flex fontSize={'.875rem'} fontWeight={'800'} justifyContent={'space-between'} p={'.75rem 1rem'} bg={'var(--c2)'} border={'2px solid var(--c4)'} borderRadius={'.25rem'}>
                      <Text>{e.nome}</Text>
                      <ElementoTooltipCmp bg={'var(--Red)'} label={'Clique aqui para excluir esse grupo'}>
                        <Flex color='var(--Red)' _hover={{ color: 'var(--Red-hover)' }}>
                          <FaTrash size={18} onClick={() => deleteGrupo(e.id)} cursor={'pointer'}/>
                        </Flex>
                      </ElementoTooltipCmp>
                    </Flex>
                  )
                })}
            </Flex>
            <Flex padding={'0 1rem 1rem 1rem'} justifyContent={'end'} gap={'.5rem'}>
                <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
            </Flex>
        </Flex>
    </Modal>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
background: white;
border-radius: 0.25rem;
display: flex;
width: 100%;
margin-bottom: 1.5rem;
padding: .25rem .25rem .5rem .25rem;
`

export const CompetenciaMenu = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding-right: .25rem;
flex-direction: column;
border-right: 2px dashed var(--Azul);
`

export const CompetenciaContent = styled.div`
display: flex;
flex-direction: column;
padding: .25rem .5rem .25rem .5rem;
width: 100%;
`

export const CompetenciaLine = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 0.938rem;
border: 0.188rem solid white;
cursor: pointer;

svg {    
    min-width: 25px;
    min-height: 25px;
}

span{
    font-weight: 700;
    font-size: 1.125rem;
    color: var(--a4);
}
`
export const StyledQuestion = styled.div`
 &:hover{
        background: #3984C420;
        transition: all .4s ease;
    }

    border-radius: .25rem;
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%;

    svg{
        margin-right: .5rem;
    } 
    
    span{
    font-weight: 700;
    font-size: .875rem;
    color: black;
}
`

export const ImgText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        font-weight: 400;
        font-size: 0.813rem;
        color:var(--a3);
        text-align: center;
        width: 8.5rem;
    }
    img{
        max-height: 4.50rem;
        max-width: 4.50rem;
        border-radius: 3.125rem;
        margin-bottom: .325rem;
        border: 2px solid var(--Azul);
    }
`

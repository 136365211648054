import { FaCalendarDay, FaClipboard } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import AutoPP from '../../../assets/AutoPP.svg'
import IconeHierarquia from '../../../assets/IconeHierarquia.svg'
import SuperioresPP from '../../../assets/SuperioresPP.svg'
import LiderPP from '../../../assets/LiderPP.svg'
import ParesPP from '../../../assets/ParesPP.svg'
import GestorPP from '../../../assets/GestorPP.svg'
import OutrosPP from '../../../assets/OutrosPP.svg'
import { Box, Flex } from '@chakra-ui/react'
import { WhiteContainer } from '../../../components/WhiteContainer'

interface iResumoPesquisaData {
  nomePesquisa: string
  dataInicio: string
  dataFim: string
  lideres: number
  pares: number
  liderados: number
  altaLideranca: number
  superiores: number
  outros: number
  autoavaliacoes: number
  avaliacoes: number
  avaliadoresDiferentes: number
}

type navProps = {
  pesquisaId: string
}

export const ResumoPesquisaPercepcao: React.FC = () => {
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()
  const nav = useNavigate()

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [Pesquisa, setPesquisa] = useState<iResumoPesquisaData>({} as iResumoPesquisaData)

  function getInfoPesquisa(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId as string}/Resumo`)
      .then(response => setPesquisa(response.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getInfoPesquisa()
  }, [])

  return (
        <Body>
            <WhiteContainer margin='1rem 0 0 0'>
                <h1>Resumo da pesquisa</h1>
                <Flex mt={'.5rem'} gap={'1rem'}>
                    <Flex flexDirection={'column'} gap={'.85rem'} py={'.75rem'} fontSize={'1.125rem'}>
                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <FaClipboard color='var(--a4)' />
                            <span>{Pesquisa.nomePesquisa}</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <FaCalendarDay color='var(--a4)' />
                            <span>Início da pesquisa:<strong> {Pesquisa.dataInicio}</strong></span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <FaCalendarDay color='var(--a4)' />
                            <span>Final da pesquisa:<strong> {Pesquisa.dataFim}</strong></span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <FaClipboard color='var(--a4)' />
                            <span><strong>{Pesquisa.avaliacoes}</strong> Avalia{Pesquisa.avaliacoes > 1 ? 'ções' : 'ção'}</span>
                        </Flex>
                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img src={IconeHierarquia} style={{ height: '1.25rem', width: '1.25rem' }} />
                            <span><strong>{Pesquisa.avaliadoresDiferentes}</strong> Avaliador{Pesquisa.avaliadoresDiferentes > 1 ? 'es' : ''} diferente{Pesquisa.avaliadoresDiferentes > 1 ? 's' : ''}</span>
                        </Flex>
                    </Flex>

                    <Box h={'full'} w={'2px'} bg={'var(--c4)'}></Box>

                    <Flex flexDir={'column'} border={'2px solid var(--Rosa)'} gap={'.5rem'} background={'var(--RosaOpaco)'} px={'.5rem'} py={'.75rem'}>
                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={AutoPP}/>
                            <span><strong>{Pesquisa.autoavaliacoes}</strong> Auto avalia{Pesquisa.autoavaliacoes > 1 ? 'ções' : 'ção'}</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={GestorPP}/>
                            <span><strong>{Pesquisa.superiores}</strong> Gestor{Pesquisa.superiores > 1 ? 'es' : ''}</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={ParesPP}/>
                            <span><strong>{Pesquisa.pares}</strong> Par{Pesquisa.pares > 1 ? 'es' : ''}</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={LiderPP}/>
                            <span><strong>{Pesquisa.liderados}</strong> Liderado{Pesquisa.liderados ? 's' : ''}</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={SuperioresPP}/>
                            <span><strong>{Pesquisa.altaLideranca}</strong> Alta liderança</span>
                        </Flex>

                        <Flex alignItems={'center'} gap={'.25rem'}>
                            <img style={{ height: '1.25rem', width: '1.25rem' }} src={OutrosPP}/>
                            <span><strong>{Pesquisa.outros}</strong> Outro{Pesquisa.outros > 1 ? 's' : ''}</span>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex justifyContent={'end'} marginTop={'.5rem'}>
                    <ButtonCmp
                        VarColor='Green2'
                        onClick={() => nav(`${pathname.includes('AnaliseDesempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'}`)}
                    >Gestão de pesquisas</ButtonCmp>
                </Flex>
            </WhiteContainer>
        </Body>
  )
}

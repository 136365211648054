/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable array-callback-return */
import { Avatar, Flex, FormLabel, Grid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaRetweet } from 'react-icons/fa'
import { IoReturnDownBackSharp } from 'react-icons/io5'
import { MdGroup, MdManageAccounts } from 'react-icons/md'
import { AddBox } from '../../../../../components/AddBox'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { FindUser } from '../../../../../components/FindBox'
import { ModalBase } from '../../../../../components/ModalBase'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { iColaboradorBase } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { CardParticipante } from '../CardParticipante'

interface iModalProps {
  isOpen: boolean
  indicadorId: string
  onClose: () => void
  onUpdate: () => void
  participantes?: iColaboradorBase[]
  responsavel?: iColaboradorBase
  status?: number
  isResponsavel: boolean
}

export const ModalVisualizarParticipantes: React.FC<iModalProps> = ({ isOpen, status, participantes, responsavel, onClose, onUpdate, indicadorId, isResponsavel }) => {
  const toast = useToast()
  const [isTrocarResponsavel, setIsTrocarResponsavel] = useState(false)
  const [isTrocarParticipantes, setIsTrocarParticipantes] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [DeleteId, setDeleteId] = useState<string>()
  const [ResponsavelId, setResponsavelId] = useState<string>()
  const [ParticipantesId, setParticipantesId] = useState<string[]>([])
  const [Colabs, setColabs] = useState<iColaboradorBase[]>([])

  function GetColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => {
        setColabs(res.data)
      })
      .catch(err => console.log(err))
  }

  function onUpdateResponsavel(): void {
    appApi.patch(`Indicador/${indicadorId}/Responsavel?responsavelId=${ResponsavelId ?? ''}`)
      .then(() => {
        toast({
          title: 'Responsável atualizado com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        onUpdate()
        onClose()
      })
      .catch((err) => console.log(err))
  }

  function onAddParticipantes(): void {
    appApi.patch(`Indicador/${indicadorId}/Participantes`, { ids: ParticipantesId })
      .then(() => {
        toast({
          title: 'Participantes adicionados com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        onUpdate()
        onClose()
      })
      .catch((err) => console.log(err))
  }

  function onDeleteParticipante(): void {
    appApi.delete(`Indicador/${indicadorId}/Participante/${DeleteId}`)
      .then(() => {
        toast({
          title: 'Participante removido com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        setDeleteId(undefined)
        onUpdate()
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    GetColaboradores()
  }, [])

  return (
    <ModalBase
      Titulo="Colaboradores envolvidos nesse indicador"
      isOpen={isOpen}
      onClose={onClose}
      Width='54rem'
    >
      {DeleteId && <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja remover esse participante?'
        onConfirm={onDeleteParticipante}
        center
        onRequestClose={() => setModalDeleteIsOpen(false)}
      />}
      <Flex p={'.5rem 1rem 1.25rem 1rem'} gap={'1rem'} alignItems='center' flexDir={'column'}>
        {!isTrocarParticipantes && responsavel && <Flex flexDir={'column'} width={'14rem'} gap='.625rem'>
          <Flex gap={'.75rem'} alignItems='center'>
            <Text color={'var(--Azul)'} fontWeight={700}>Responsável {isTrocarResponsavel ? 'atual' : ''}</Text>
            {status !== 2 && status !== 4 && isResponsavel && <ElementoTooltipCmp background={'var(--Rosa)'} label={isTrocarResponsavel ? 'Voltar' : 'Trocar responsável'}>
              <ButtonCmp small VarColor='Rosa' onClick={() => setIsTrocarResponsavel(!isTrocarResponsavel)}>{isTrocarResponsavel ? <IoReturnDownBackSharp/> : <FaRetweet />}</ButtonCmp>
            </ElementoTooltipCmp>}
          </Flex>
          <CardParticipante
            responsavel
            participante={responsavel}
          />
        </Flex>}
        {isTrocarResponsavel && <Flex justifyContent={'center'} width={'24rem'} flexDir='column' gap={'.325rem'}>
          <Text fontWeight={600} fontSize='14px'>Selecione o novo responsável</Text>
          <FindUser
            lista={Colabs.filter(e => e.id !== responsavel?.id)}
            onChoice={(e) => setResponsavelId(e)}
            placeholder='Digite o nome do responsável'
            selecionado={ResponsavelId}
          />
        </Flex>}
        {!isTrocarResponsavel && participantes && participantes?.filter(p => p.id !== responsavel?.id)?.length > 0 && <Flex flexDir={'column'} flex={'1'} gap='.625rem'>
          <Flex gap={'.75rem'} alignItems='center'>
            <Text color={'var(--Azul)'} fontWeight={700}>Participantes {isTrocarResponsavel ? 'atuais' : ''}</Text>
            {status !== 2 && status !== 4 && isResponsavel && <ElementoTooltipCmp background={'var(--Rosa)'} label={isTrocarResponsavel ? 'Voltar' : 'Trocar participantes'}>
              <ButtonCmp small VarColor='Rosa' onClick={() => setIsTrocarParticipantes(!isTrocarParticipantes)}>{isTrocarParticipantes ? <IoReturnDownBackSharp/> : <FaRetweet />}</ButtonCmp>
            </ElementoTooltipCmp>}
          </Flex>
          <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
            {participantes?.filter(p => p.id !== responsavel?.id).map(e => {
              return (
                <CardParticipante
                  participante={e}
                  onRemove={isTrocarParticipantes ? (id) => { setDeleteId(id); setModalDeleteIsOpen(true) } : undefined}
                />
              )
            })}
          </Grid>
        </Flex>}
        {isTrocarParticipantes && <Flex width={'100%'} flexDir={'column'}>
            <Text fontWeight={600} fontSize='14px'>Digite os colaboradores que deseja adicionar</Text>
            <AddBox
              lista={Colabs.filter(e => e.id !== responsavel?.id && !participantes?.some(p => p.id === e.id))}
              onChoice={(e) => { setParticipantesId([...ParticipantesId ?? [], e]) }}
              selecionados={ParticipantesId ?? []}
              placeholder='Digite o nome do colaborador'
              messageSucess='Colaborador selecionado com sucesso'
            />
            <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1rem'} mt={'1rem'}>
              {Colabs?.filter(p => ParticipantesId.some(a => a === p.id)).map(e => {
                return (
                  <CardParticipante
                    participante={e}
                    onRemove={isTrocarParticipantes ? (id) => { setParticipantesId(ParticipantesId.filter(p => p !== id)) } : undefined}
                  />
                )
              })}
            </Grid>
          </Flex>}
      </Flex>
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
        {isTrocarResponsavel && <ButtonCmp VarColor='Green2' isDisabled={!ResponsavelId} onClick={onUpdateResponsavel}>Salvar</ButtonCmp>}
        {isTrocarParticipantes && <ButtonCmp VarColor='Green2' isDisabled={ParticipantesId.length === 0} onClick={onAddParticipantes}>Adicionar participantes</ButtonCmp>}
      </Flex>
    </ModalBase>
  )
}

import styled from 'styled-components'

export const Card = styled.div<{navegavel?: boolean}>`
 display:flex;
 align-items: center;
 background: var(--c2);
 border-radius: .25rem;
 border: 2px solid var(--AzulOpaco);
 width: 8.6rem;
 font-size: .875rem;
 line-height: .95rem;
 height: 3.2rem;
 padding: 0.25rem;
 transition: .1s;
 cursor:${props => props.navegavel ? 'pointer' : 'not-allowed'};

 ${props => props.navegavel
? `:hover{
  box-shadow: var(--SombraBtns);
  color: white;
  background: var(--Azul);
  border: 2px solid var(--Azul);
 }
 `
: `:hover{
  opacity: .6;
 }`}
`

export const ImgAssociado = styled.img`
width:2rem;
height:2rem;
border-radius: 50%;
margin-right: .325rem;
object-fit: cover;
`

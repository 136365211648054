import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

import { useRef } from 'react'
import { Radar } from 'react-chartjs-2'
import { FormatarCompetencia } from '../../../../../../../Utils/Helper'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

interface IDadoGrafico {
  medias: number[]
  nome: string
}

interface iRadarData {
  competencias: string[]
  dados: IDadoGrafico[]
  notaMaximaEscala: number
  notaMinimaEscala: number
  stepSize: number
  pdi?: boolean
}

export const RadarChartJs: React.FC<iRadarData> = ({
  competencias,
  dados,
  notaMaximaEscala,
  notaMinimaEscala,
  stepSize,
  pdi
}) => {
  function AbreviarNomesGrandes(): string[] {
    return competencias.map((e) => {
      if (e.length > 19) {
        return FormatarCompetencia(e.substring(0, 19) + '...')
      } else {
        return FormatarCompetencia(e)
      }
    })
  }

  function ObterCor(pos: number): string {
    if (dados.length === 1) {
      return colors[pos]
    } else {
      return dados[1].nome.includes('Média sem autoavaliação') && pos === dados.length - 1 ? colors[colors.length - 1] : colors[pos]
    }
  }
  const canvasReference = useRef(null)

  const colors = [
    'rgb(0, 143, 251)',
    'rgb(0, 227, 150)',
    '#F2C94C',
    'rgb(255, 69, 96)',
    '#fe00f4',
    '#BB6BD9',
    '#219653',
    '#F2994A'
  ]

  const Shapes = ['circle', 'star', 'triangle', 'rectRot', 'rectRounded']

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 12
          }
        }
      }
    },
    scales: {
      r: {
        min: notaMinimaEscala,
        max: pdi ? 5 : notaMaximaEscala,
        ticks: {
          min: 0,
          max: pdi ? 5 : 10,
          stepSize: stepSize,
          font: {
            size: 10
          },
          z: 1
        },
        pointLabels: {
          color: '#000',
          font: {
            size: 12
          }
        },

        angleLines: {
          color: '#bdbdbd'
        },

        grid: {
          color: '#bdbdbd'
        }
      }
    }
  }

  const data = {
    labels: AbreviarNomesGrandes(),
    datasets: dados.map((e, i) => {
      return {
        label: e.nome,
        data: e.medias,
        fill: false,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: ObterCor(i),
        backgroundColor: ObterCor(i),
        borderColor: ObterCor(i),
        pointRadius: i === 0 ? 4 : 6,
        pointStyle: Shapes[i]
      }
    })
  }

  const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart: any) => {
      const { ctx } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  return (
    <Radar
      width={300}
      height={300}
      options={options}
      data={data}
      ref={canvasReference}
      plugins={[plugin]}
    />
  )
}

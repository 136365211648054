import styled from 'styled-components'

export const LabelTitle = styled.label`
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size:1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
`

export const ContainerFlex = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
gap: .5rem;

span{
    display: flex;
    gap: 1rem;
    white-space: nowrap;
    font-weight: 600;
    font-size: .75rem; 
    align-items: center;
    margin: .25rem 0;
}
`

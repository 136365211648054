/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Avatar, CircularProgress, CircularProgressLabel, Flex, FormLabel, Text, useToast } from '@chakra-ui/react'
import { ModalBody } from './styles'
import React, { useState } from 'react'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { PropInvalida } from '../../../../../Utils/Helper'
import { EditorTexto } from '../../../../../components/EditorTexto/Index'
import { FaEye } from 'react-icons/fa'
import { ButtonTag } from '../../../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'
import { ModalTag } from './components/ModalTag'
import { appApi } from '../../../../../services/appApi'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { iCriterios } from '../..'
import { BarChart } from './components/BarChart'
import { ColumnChart } from './components/ColumnChart'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  id: string
  onConfirm: () => void
  criterios?: iCriterios
  tipoMeta: number
  medidaMeta: string
}

export const ModalCriterios: React.FC<iModal> = ({ onRequestClose, isOpen, criterios, id, onConfirm, medidaMeta, tipoMeta }) => {
  const toast = useToast()
  const [Criterios, setCriterios] = useState(criterios?.criterios ?? '')
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [ModoEdit, setModoEdit] = useState(false)
  const [IsLoading, SetIsLoading] = useState(false)

  const replaceMetaAndColaboradores = (text: string) => {
    if (!text) return <></>

    // Split the text into parts using all the tags you want to replace
    const parts = text.split(
      /(\[META%\]|\[META\]|\[colaborador\d+\]|\[\d+ DA META\]|\[VALOR NEGATIVO \{\d+\}\]|\[VALOR POSITIVO \{\d+\}\]|\[grafico\d+\])/
    )

    return parts.map((part: string, index: number) => {
      if (criterios?.progressoTotal && criterios.meta) {
        if (part === '[META%]') {
          return (
            <Flex key={index} ml={'.2rem'}>
              <CircularProgress
                value={(criterios.progressoTotal / criterios.meta) * 100}
                color="green.400"
                size="50px"
                height="60px"
              >
                <CircularProgressLabel mt=".125rem" fontSize="11px">
                  {`${((criterios.progressoTotal / criterios.meta) * 100).toFixed(1)}%`}
                </CircularProgressLabel>
              </CircularProgress>
            </Flex>
          )
        } else if (part === '[META]') {
          return (
            <strong style={{ color: 'var(--Green2)' }} key={index}>
              {(tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(criterios.meta)}` : `${FormatarNumero(criterios.meta)}${medidaMeta ?? ''}`)}
            </strong>
          )
        } else if (/\[colaborador\d+\]/.test(part)) {
          const colabIndex = parseInt(part.replace(/\D/g, ''))
          const colab = criterios.colaboradores?.[colabIndex]
          if (colab) {
            return (
              <Flex
                key={index}
                alignItems="center"
                gap=".325rem"
                bg="var(--AzulOpacoBg)"
                border="1px solid var(--c4)"
                borderRadius=".25rem"
                boxShadow="var(--SombraBackground)"
                px="1rem"
                py=".5rem"
              >
                <Avatar size="sm" src={colab.avatar} />
                <Flex flexDir="column" lineHeight="15px">
                  <Text fontSize="14px">{colab.nome}</Text>
                  <Text fontSize="12px" fontWeight={700}>
                    {colab.email}
                  </Text>
                </Flex>
              </Flex>
            )
          } else {
            return <></>
          }
        } else if (/\[\{\d+\} DA META\]/.test(part)) {
          const numeroMeta = part.match(/\d+/)?.[0]
          const [beforeTag, afterTag] = part.split(/\[\{\d+\} DA META\]/)
          return (
            <Flex key={index} gap={'.325rem'}>
              {beforeTag}
              <strong>{(tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(criterios.meta / 100 * parseInt(numeroMeta ?? '0'))}` : `${FormatarNumero(criterios.meta / 100 * parseInt(numeroMeta ?? '0'))}${medidaMeta ?? ''}`)} {tipoMeta !== 5 ? `(${numeroMeta}% da meta)` : 'da meta'}</strong>
              {afterTag}
            </Flex>
          )
        } else if (/\[VALOR NEGATIVO \{\d+\}\]/.test(part)) {
          return <Flex as={'strong'} color={'var(--Red)'} key={index}>{part.match(/\d+/)?.[0]}</Flex>
        } else if (/\[VALOR POSITIVO \{\d+\}\]/.test(part)) {
          return <Flex fontWeight={700} color={'var(--Green2)'} key={index}>{part.match(/\d+/)?.[0]}</Flex>
        } else if (/\[grafico\d+\]/.test(part)) {
          const graficoIndex = part.match(/\d+/)?.[0]
          const grafico = criterios.graficos[parseInt(graficoIndex ?? '0')]
          if (!grafico) return <></>
          if (grafico.tipoGrafico === 0) {
            return (
              <Flex key={index} width={'100%'}>
                <BarChart
                  dados={grafico}
                  id={`grafico-${graficoIndex}-indicador-${id}`}
                  medidaMeta={medidaMeta}
                  tipoMeta={tipoMeta}
                />
              </Flex>
            )
          } else {
            return (
              <Flex key={index} width={'100%'}>
                <ColumnChart
                  Grafico={grafico}
                  id={`grafico-analise-${graficoIndex}-indicador-${id}`}
                  medidaMeta={medidaMeta}
                  tipoMeta={tipoMeta}
                />
              </Flex>
            )
          }
        } else {
          return <React.Fragment key={index}>{part}</React.Fragment>
        }
      } else {
        return <></>
      }
    })
  }

  // Function to wrap parsed HTML with Flex
  const replaceTagsWithFlex = (htmlText: string) => {
    const div = document.createElement('div')
    div.innerHTML = htmlText.replaceAll('\n', '<br/>')

    const elements = Array.from(div.childNodes).map((node: any, index) => {
      if (node.nodeName === 'BR') {
        return (
          <Flex my={'.325rem'}>
            {node.textContent}
          </Flex>
        )
      } else if (node.nodeName !== 'STRONG') {
        return (
          <Flex key={index} gap={'.325rem'} alignItems={'center'}>
            {replaceMetaAndColaboradores(node.innerHTML)}
          </Flex>
        )
      } else if (node.nodeName === 'STRONG') {
        return <strong key={index}>{node.innerHTML}</strong>
      } else {
        return <React.Fragment key={index}>{node.textContent}</React.Fragment>
      }
    })

    return elements
  }

  function FormatarNumero(num: number): string {
    let response = ''
    const toFixed = tipoMeta === 5 || tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }

  function OnSave(): void {
    SetIsLoading(true)
    appApi.patch(`Indicador/${id}/Criterios`, { texto: Criterios })
      .then(() => {
        toast({
          title: 'Critérios salvos com sucesso',
          duration: 4000,
          status: 'success',
          position: 'top'
        })
        onConfirm()
        setTimeout(() => {
          setModoEdit(false)
          SetIsLoading(false)
        }, 2000)
      })
      .catch((err) => console.log(err))
  }

  return (
    <ModalBase
      Titulo=''
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='80rem'
    >
      <ModalTag
        isOpen={ModalTagIsOpen}
        onClose={() => setModalTagIsOpen(false)}
      />
      <ModalBody>
        <LoadingContainer linhas={14} loading={IsLoading}>
          {!ModoEdit
            ? <Flex flexDir={'column'} w={'100%'}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <FormLabel bg={'none'} pos={'relative'} mr={'0'} fontWeight={700} fontSize={'18px'} wordBreak={'keep-all'}>Critérios do indicador</FormLabel>
                  <Flex gap={'1rem'}>
                    <ButtonCmp leftIcon={<FaEye />} onClick={() => setModoEdit(!ModoEdit)} VarColor='Green2'>Modo de edição</ButtonCmp>
                  </Flex>
                </Flex>
                {replaceTagsWithFlex(criterios?.textoExibicao ?? '')}
                {criterios?.textoExibicao === '' && <Flex mt={'1rem'} py={'1rem'} px={'4rem'} bg={'var(--AzulOpacoBg)'} border={'1px solid var(--c4)'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'} fontSize={'18px'} fontWeight={700} justifyContent={'center'} alignItems={'center'}>Ainda não foi definido nenhum critério para esse indicador</Flex>}
            </Flex>
            : <Flex flexDir={'column'} gap='.75rem' w={'100%'}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <FormLabel bg={'none'} pos={'relative'} mr={'0'} fontWeight={700} fontSize={'18px'} wordBreak={'keep-all'}>Digite os critérios do indicador</FormLabel>
            <Flex gap={'.75rem'}>
              <ButtonCmp maxH={'28px'} leftIcon={<FaEye />} onClick={() => setModoEdit(!ModoEdit)} VarColor='Green2'>Modo de visualização</ButtonCmp>
              <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
            </Flex>
          </Flex>
          <EditorTexto
            initialValue={Criterios}
            placeholder='Digite os critérios desse indicador'
            className='editorSt-v4'
            onChange={setCriterios}
          /></Flex>}
        </LoadingContainer>
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={onRequestClose}
          VarColor='c6'
        >Voltar</ButtonCmp>

        {ModoEdit && <ButtonCmp
          isDisabled={PropInvalida(Criterios)}
          onClick={OnSave}
          VarColor='Green2'
        >Salvar critérios</ButtonCmp>}
      </Flex>
    </ModalBase>
  )
}

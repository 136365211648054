import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaEye, FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { iFormFeedback } from '../..'
import { Button } from '../../../../../../components/Button'
import { WhiteBox } from '../../../../../../components/WhiteBox'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { OutlineButton } from '../../../../Feedback/Components/OutlineButton'
import { BoxMultiText } from '../../../../Feedback/Configurar/components/BoxMultiText'
import { ModalTag } from '../../../../Feedback/Configurar/components/ModalTag'
import { ModalFormulariosFeedback } from '../ModalFormulariosFeedback'

interface iProps {
  model: iFormFeedback
  onChange: (form: iFormFeedback) => void
}

export const FormFeedback: React.FC<iProps> = ({ onChange, model }) => {
  const nav = useNavigate()
  const textoPadraoEmail = '<p><strong>Olá, [NOME DO RESPONDENTE]!</strong></p><p><strong>Você está recebendo um link para preenchimento relacionado ao feedback entre você e o [NOME DO AVALIADO].</strong></p><p><strong>Para preencher o feedback clique no link abaixo:</strong></p><p><strong><a>[LINK]</a></strong></p><p><strong>Qualquer dúvida contacte o responsável!</strong></p>'
  const textoPadraoAbertura = '<p><strong>Bem vindo ao questionário relacionado ao feedback entre o colaborador [NOME DO AVALIADO] e o colaborador [NOME DO RESPONDENTE]</strong></p>'

  const [Formularios, setFormularios] = useState<iNomeId[]>([])
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)

  const [FormularioAtivo, setFormularioAtivo] = useState<iNomeId>()
  const [IsModalFormulariosOpen, setIsModalFormulariosOpen] = useState(false)

  function Duplicar(id: string): void {
    appApi.post(`FormularioFeedback/${id}/Duplicar`)
      .then(res => {
        nav(`Feedback/Formulario/${res.data}`)
      })
      .catch(err => console.log(err))
  }

  function onChangeEditarResposta(tipoRespondente: number): void {
    if (model.edicaoResposta.includes(tipoRespondente)) {
      onChange({ ...model, edicaoResposta: [...model.edicaoResposta.filter(t => t !== tipoRespondente)] })
    } else {
      onChange({ ...model, edicaoResposta: [...model.edicaoResposta, tipoRespondente] })
    }
  }

  function ObterFormularios(): void {
    appApi.get('FormularioFeedback')
      .then(res => {
        if (res.status === 200) {
          setFormularios(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterFormularios()
  }, [])
  return (
        <Flex flexDir={'column'} gap='2rem'>
            <ModalTag
                isOpen={ModalTagIsOpen}
                onClose={() => setModalTagIsOpen(false)}
            />

            <ModalFormulariosFeedback
                formularios={Formularios}
                isOpen={IsModalFormulariosOpen}
                onClose={() => setIsModalFormulariosOpen(false)}
                onDuplicar={(e) => Duplicar(e)}
                activeForm={FormularioAtivo?.id}
                onSelectForm={(e) => {
                  setFormularioAtivo(e)
                  onChange({ ...model, formularioId: e?.id })
                }
                }
            />

            <Flex flexDir={'column'} mb={'-.5rem'}>
                <Button w={'15rem'} VarColor='Green1' rightIcon={<FaPlus />} onClick={() => setIsModalFormulariosOpen(true)}>
                    Selecionar formulário
                </Button>
                {FormularioAtivo &&
                    <Box mt={'1rem'}>
                        <h3>Formulário selecionado</h3>
                        <Flex key={FormularioAtivo.id} gap={'.5rem'} mt={'1rem'} mb={'4px'} p={'1rem'} alignItems={'center'} bgColor={'var(--Gray6)'}>
                            <Text flex={'1'} fontWeight={'700'}>{FormularioAtivo.nome}</Text>
                            <Button
                                h={'2rem'}
                                VarColor='Rosa'
                                w={'6.9rem'}
                                fontSize={'0.875rem'}
                                onClick={() => { setFormularioAtivo(undefined) }}
                            >
                                Deselecionar
                            </Button>
                            <OutlineButton
                                onClick={() => Duplicar(FormularioAtivo.id)}
                                varColor='AzulClaro2'
                                leftIcon={<FaCopy />}
                                w={'6.6rem'}
                            >Duplicar</OutlineButton>

                            <OutlineButton
                                varColor='AzulClaro2'
                                leftIcon={<FaEye />}
                                w={'8.75rem'}
                                onClick={() => nav(`/Feedback/Preenchimento/${FormularioAtivo.id}/Previsualizar`)}
                            >Pré-visualizar</OutlineButton>
                        </Flex>
                    </Box>
                }
            </Flex>

            <Flex flexDir={'column'} gap='1.5rem' mb={'-1rem'}>
                <FormControl alignItems={'center'} gap={'.5rem'} as={Flex}>
                    <FormLabel whiteSpace={'nowrap'} m={0}>Data para envio</FormLabel>
                    <Input
                        onChange={(e) => onChange({ ...model, dataInicio: e.target.value })}
                        w={'15rem'}
                        type={'datetime-local'}
                        max={'2099-12-31T23:59'}
                        value={model.dataInicio}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel>Quem deve responder ao feedback</FormLabel>
                    <RadioGroup
                        value={model.tipoRespondente?.toString() ?? '2'}
                        onChange={(e) => onChange({
                          ...model,
                          tipoRespondente: parseInt(e),
                          textosEmail: [{
                            participantes: [0, 1],
                            texto: model.textosEmail[0].texto
                          }],
                          textosAbertura:
                                [{
                                  participantes: [0, 1],
                                  texto: model.textosAbertura[0].texto
                                }]
                        })}
                    >
                        <Stack gap={'1rem'} direction={'row'}>
                            <Radio value={'2'}>Gestor e colaborador</Radio>
                            <Radio value={'0'}>Somente o gestor</Radio>
                            <Radio value={'1'}>Somente o colaborador</Radio>
                        </Stack>
                    </RadioGroup>
                </FormControl>

                <Checkbox
                    onChange={() => onChange({ ...model, visualizarResposta: !model.visualizarResposta })}
                    my={0}
                    isChecked={model.visualizarResposta}
                >O colaborador pode visualizar respostas do formulário</Checkbox>

                <FormControl>
                    <FormLabel>Quem pode editar as respostas</FormLabel>
                    <Flex gap={'1rem'}>
                        <Checkbox
                            isChecked={model.edicaoResposta.includes(0)}
                            onChange={() => onChangeEditarResposta(0)}
                        >Gestor</Checkbox>
                        <Checkbox
                            isChecked={model.edicaoResposta.includes(1)}
                            onChange={() => onChangeEditarResposta(1)}
                        >Colaborador</Checkbox>
                    </Flex>
                </FormControl>
                <Accordion allowToggle>
                    <AccordionItem>
                        <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} marginBottom={'1rem'} px={'0'}>
                            <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                                Configurar textos de disparo de feedback
                                <AccordionIcon />
                            </Flex>
                            <hr style={{ border: '0.031rem solid var(--Azul)', flex: '1' }}></hr>
                        </AccordionButton>
                        <AccordionPanel display={'flex'} flexDir='column' gap={'3rem'}>
                            <WhiteBox>
                                <BoxMultiText
                                    titulo='Texto para o convite por e-mail'
                                    descricao='Esse texto será apresentado no e-mail ou notificação antes do respondente abrir a pesquisa.'
                                    model={model.textosEmail}
                                    textoPadrao={textoPadraoEmail}
                                    onChange={(e) => onChange({ ...model, textosEmail: e })}
                                    openModalTag={() => setModalTagIsOpen(true)}
                                    ocultarParticipantes={model.tipoRespondente !== 2}
                                />
                            </WhiteBox>

                            <WhiteBox>
                                <BoxMultiText
                                    titulo='Texto de abertura antes do preenchimento'
                                    descricao='Esse texto será apresentado quando o respondente abrir o link antes de começar a responder a pesquisa, para ver exatamente como irá ficar, você pode pré-visualizar clicando no botão a baixo da caixa de edição de texto.'
                                    model={model.textosAbertura}
                                    textoPadrao={textoPadraoAbertura}
                                    onChange={(e) => onChange({ ...model, textosAbertura: e })}
                                    openModalTag={() => setModalTagIsOpen(true)}
                                    ocultarParticipantes={model.tipoRespondente !== 2}
                                />
                            </WhiteBox>
                            <hr style={{ border: '0.063rem solid var(--c2)', marginTop: '1.7rem' }}></hr>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </Flex>
        </Flex>
  )
}

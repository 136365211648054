import { Avatar, Flex } from '@chakra-ui/react'
import { Container, EndIcon, ColumnText } from './styles'
import { FaEdit } from 'react-icons/fa'
import { IEmployee } from '../../../../../../interfaces'

interface iCardProps {
  tipo: number
  avaliador: IEmployee
  onEdit: (avaliador: IEmployee) => void
}

export const CardAssociado: React.FC<iCardProps> = ({ avaliador, tipo, onEdit }) => {
  function ObterTipo(type: number): string {
    switch (type) {
      case 1:
        return 'Gestor'

      case 2:
        return 'Par'

      case 3:
        return 'Liderados'

      case 4:
        return 'Outros'

      case 6:
        return 'Alta liderança'

      default:
        return 'null'
    }
  }
  return (
    <Container>
      <Flex alignItems={'center'} margin={'.5rem .125rem .5rem .75rem'}>
        <Avatar size={'sm'} name={avaliador.nome} src={avaliador.avatar} />
        <ColumnText>
          {
            avaliador.nome?.length > 21
              ? <span title={avaliador.nome}>{avaliador.nome.substring(0, 22)}...</span>
              : <span>{avaliador.nome}</span>
          }
          <strong style={{ lineHeight: '.85rem', fontSize: '.813rem' }}>{ObterTipo(tipo)}</strong>
          <span>{ }</span>
        </ColumnText>
      </Flex>
      <EndIcon>
        <FaEdit size={14} onClick={() => onEdit(avaliador)}/>
      </EndIcon>
    </Container >
  )
}

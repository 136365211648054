import styled from 'styled-components'

export const DateText = styled.div`
margin-top: .5rem;
display: flex;
gap: .5rem;
span{
    font-weight: 500;
    font-size: .875rem;
    color:var(--Azul);
}

span:not(:last-child){
    border-right: 2px solid var(--AzulOpaco);
    padding-right: .5rem;
    }
`

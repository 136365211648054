/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Avatar, Flex, Table, TableContainer, Tbody, Thead, Tr, Text, AvatarGroup } from '@chakra-ui/react'
import { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { iLinhaGeral } from '../..'
import { CsvButton } from '../../../../../../../components/Buttons/CsvButton'
import { urlApi } from '../../../../../../../services/appApi'
import { ObterToken } from '../../../../../../../Utils/Helper'
import { OutlineTable, StyledSpan } from './styles'

interface iProps {
  tabelaPorColaborador: iLinhaGeral[]
  onOrdenar: (filtro: number, ascendente: boolean) => void
}

export const TabListaColaboradores: React.FC<iProps> = ({ tabelaPorColaborador, onOrdenar }) => {
  const [UltimoFiltro, setUltimoFiltro] = useState<number>(0)
  const [Ordenacao, setOrdenacao] = useState(false)

  function Ordenar(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
    onOrdenar(filtro ?? 0, Ordenacao)
  }

  function ReturnIcon(filtro: number): any {
    return UltimoFiltro === filtro && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(filtro)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(filtro)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
  }

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'} my={'.325rem'}>
        <h2>Lista por colaborador</h2>
        <CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Gestor?token=${ObterToken()}`} />
      </Flex>
      <OutlineTable>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <th>Gestores {ReturnIcon(1)}</th>
                <th>Colaboradores {ReturnIcon(2)}</th>
                <th>Finalizadas {ReturnIcon(3)}</th>
                <th>Aceitas {ReturnIcon(4)}</th>
                <th>Aguardando {ReturnIcon(5)}</th>
                <th>Atrasadas {ReturnIcon(6)}</th>
                <th>Total {ReturnIcon(7)}</th>
              </Tr>
            </Thead>
            <Tbody>
              {tabelaPorColaborador?.map((e, i) => {
                return (
                  <Tr key={i}>
                    <td>
                      <StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
                        <Avatar src={e.gestor.avatar} size={'sm'} />
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.gestor.info1}
                        </Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <AvatarGroup size={'sm'} max={3} spacing={'-3.5'}>
                          {e.colaboradores.map((colaborador, i) => {
                            return (
                              <Avatar key={i} name={colaborador.info1} src={colaborador.avatar} />
                            )
                          })}
                        </AvatarGroup>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text>{e.finalizadas}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text>{e.aceitas}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text>{e.aguardando}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text>{e.atrasadas}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text>{e.finalizadas + e.aceitas + e.aguardando + e.atrasadas}</Text>
                      </StyledSpan>
                    </td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </OutlineTable>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { iGraficoCriterios } from '../../../..'

interface IGrafico {
  id: string
  dados: iGraficoCriterios
  medidaMeta: string
  tipoMeta: number
}

export const BarChart: React.FC<IGrafico> = ({ id, dados, medidaMeta, tipoMeta }) => {
  function FormatarNumero(num: number): string {
    let response = ''
    const toFixed = tipoMeta === 5 || tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }

  function GetSeries(): any {
    return [{
      name: 'Real',
      data: dados.data.map((e, i) => {
        return {
          x: dados.labels[i],
          y: e[0],
          fillColor: '#27AE60'
        }
      })
    }, {
      name: 'Previsto',
      data: dados.data.map((e, i) => {
        return {
          x: dados.labels[i],
          y: e[1],
          fillColor: '#F2994A'
        }
      })
    }]
  }
  async function GerarGrafico(): Promise<void> {
    const options = {
      series: GetSeries(),
      colors: ['#27AE60', '#F2994A'],
      labels: dados.labels,
      chart: {
        type: 'bar',
        height: 450,
        width: '100%'
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return `${(tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(val)}` : `${FormatarNumero(val)}${medidaMeta ?? ''}`)}`
          }
        }
      },
      plotOptions: {
        bar: {
          distributed: true,
          dataLabels: {
            textAnchor: 'middle',
            orientation: 'vertical'
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        show: true,
        labels: {
          formatter: function (val: number) {
            if (val === -1) {
              return '#'
            }
            return `${(tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(val)}` : `${FormatarNumero(val)}${medidaMeta ?? ''}`)}`
          }
        }
      },
      xaxis: {
        categories: dados.labels,
        labels: {
          hideOverlappingLabels: false,
          trim: true
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            if (val === -1) {
              return '#'
            }
            return `${(tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(val)}` : `${FormatarNumero(val)}${medidaMeta ?? ''}`)}`
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [dados])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}

import { Flex } from '@chakra-ui/react'
import styled, { css } from 'styled-components'
import { Column } from '../../../../../../styles/styledGlobal'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: .875rem;
padding: 1rem 1rem 0 1rem;

strong{
    color: var(--Azul);
    font-weight: 900;
}

small{
    color: var(--Red);
    font-weight: 500;
}

span{
    color:var(--a5);
}

label{
    background: var(--c2);
    border-radius: 4px;
    padding: .5rem;
    width: 100%;
    cursor: pointer;
    margin-top: 1rem;
}

input[type=file]{
    display: none;
}
`

export const ColumnText = styled(Column)`
justify-content: start;

span{
font-weight: 400;
font-size: 0.875rem;
}
`

export const PreViewParticipante = styled.div`
display: flex;
border-left: .25rem solid var(--Rosa);
background: var(--c2);
padding-left: .5rem;
border-radius: 0.5rem .25rem .25rem .5rem;
height: 3.8rem;
`

export const BackgroundCollapse = styled.div<{active?: boolean}>`
background: var(--c1);
border-radius: 0.25rem;
box-shadow: var(--SombraBtns);
overflow-y: hidden;
max-height: 3.8rem;
width:100%;

${props => props.active && css`
    transition: max-height 4.2s;
    max-height: 800rem;
`}
`

export const SectionAssociados = styled.div`
border-radius: 0.25rem;
min-width: 46rem;
min-height: 4.5rem;
width: 100%;
border: 0.125rem solid var(--c3);
box-sizing: border-box;
padding: .5rem;
margin-bottom: .5rem;

h4{
    font-weight: 700;
}
`

export const HeaderSection = styled(Flex)`
justify-content: space-between;
align-items: center;
margin-bottom: .325rem;
`

export const SectionBody = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);
gap: .25rem .5rem;
`

export const CardBody = styled.div<{column: number}>`
display: grid;
grid-template-columns: repeat(${props => props.column},1fr);
width: 100%;

& > div{
display: flex;
align-items: center;
}
`

import React from 'react'

import { Container } from './styles'

interface ICountItem {
  value: number
  title: string
}

interface ICountCardProps {
  countItems: ICountItem[]
}

export const CountBar: React.FC<ICountCardProps> = ({ countItems }) => {
  return (
    <Container style={{ padding: '0', margin: '2rem 0' }}>
      {countItems.map(({ value, title }) => (
        <div key={title}>
          <strong>{value}</strong>
          <span>{title}</span>
        </div>
      ))}
    </Container>
  )
}

import styled from 'styled-components'

export const Container = styled.table`
    border-collapse: collapse;
    width: 100%;
    tr {
        :first-child {
            padding: 0;
        }
    }
    tbody > tr {
        background-color: var(--AzulOpacoBg);
        :nth-child(even) {
            background: #fff;
        }
    }
    td {
        font-size: 14px;
        padding: .75rem;
        :first-child {
            text-align: start;
            padding: .75rem 1.5rem;
        }
        svg {
            font-size: 18px;
        }
        text-align: center;
    }
    th {
        color: var(--Gray2);
        font-size: 13px;
        font-weight: 700;
        padding: .75rem;
        :first-child {
            text-align: start;
            padding: .75rem 1.5rem;
        }
        text-align: center;
    }
`

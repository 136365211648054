import { Box, Divider, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaEye, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../components/ElementoTooltipCmp'
import { ModalDelete } from '../../../../components/ModalDelete'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { ModalCreate } from './components/ModalCreate'
import { ColumnText, ContainerFormulario } from './styles'

interface iFormulario {
  id: string
  nome: string
  objetiva: number
  caixaDeSelecao: number
  discursiva: number
  escala: number
  obrigatorias: boolean
  pesquisas: string[]
}

export const FormulariosPesquisaInterna: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Formularios, setFormularios] = useState<iFormulario[]>()

  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  function get(): void {
    appApi.get('PesquisaInterna/Formularios')
      .then(res => {
        if (res.status === 200) {
          setFormularios(res.data)
        } else {
          setFormularios([])
        }
      })
      .catch(err => console.log(err))
  }

  function Criar(nomeForm: string): void {
    appApi.post('PesquisaInterna/Formulario', {
      texto: nomeForm
    })
      .then((res) => Selecionar(res.data, true))
      .catch(err => console.log(err))
  }

  function Selecionar(formId: string, novo: boolean): void {
    appApi.patch(`PesquisaInterna/${pesquisaId ?? ''}/Formulario/${formId}`)
      .then(() => nav(`${pathname.replace(`${pesquisaId ?? ''}/Formularios`, 'Formulario')}/${pesquisaId ?? ''}`, { state: { formularioEditavel: novo } }))
      .catch(err => console.log(err))
  }

  function DuplicarForm(id: string): void {
    appApi.post(`PesquisaInterna/${pesquisaId}/Duplicar/Formulario/${id}`)
      .then(() => nav(`${pathname.replace(`${pesquisaId ?? ''}/Formularios`, 'Formulario')}/${pesquisaId ?? ''}`, { state: { formularioEditavel: true } }))
      .catch(err => console.log(err))
  }

  function onCloseModalCreateForm(): void {
    setModalCreateIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function Delete(): void {
    appApi.delete(`PesquisaInterna/Formulario/${Id ?? ''}`)
      .then(get)
      .catch(err => console.log(err))
  }

  useEffect(() => {
    get()
  }, [])

  return (
        <Body>
            <ModalDelete
                isOpen={ModalDeleteIsOpen}
                message='Tem certeza que deseja excluir este formulário?'
                onRequestClose={onCloseModalDelete}
                center
                onConfirm={Delete}
            />
            <ModalCreate
                isOpen={ModalCreateIsOpen}
                onRequestClose={onCloseModalCreateForm}
                onConfirm={Criar}
            />
            <Flex marginBottom={'1.5rem'} justifyContent={'space-between'}>
              <h1>Formulários salvos</h1>
              <ButtonCmp
                  VarColor='Green2'
                  leftIcon={<FaPlus />}
                  onClick={() => setModalCreateIsOpen(true)}
              >Criar novo Formulário</ButtonCmp>
            </Flex>
            <Flex flexDir={'column'} gap='1rem'>
                    <section>
                        {
                            Formularios?.map((e, i) => (
                                <ContainerFormulario key={i}>
                                  <Flex w={'21rem'} flexDir={'column'} justifyContent={'center'}>
                                    <Flex fontWeight={'700'} lineHeight={'16px'}>{e.nome}</Flex>
                                    {e.pesquisas?.length > 0 ? <Flex lineHeight={'14px'} gap={'.25rem'} alignItems={'center'} fontWeight={'500'} maxW={'83%'} fontSize={'.7rem'} flexWrap={'wrap'}><Box bg={'var(--Green2)'} w={'.5rem'} h={'.5rem'} borderRadius={'50%'}></Box> Pesquisas ativas: {e.pesquisas?.slice(0, 3).map((pesq, i) => { return (<Box fontWeight={'700'}>{pesq}{(i + 1) === 3 ? '' : ', '}</Box>) })}</Flex> : <Flex gap={'.25rem'} alignItems={'center'} fontSize={'.7rem'} color={'var(--c7)'} lineHeight={'14px'}>Sem pesquisas ativas no momento com esse formulário</Flex>}
                                  </Flex>
                                  <Flex gap={'1.75rem'} flex={'1'}>
                                    <Divider orientation='vertical' height={'3rem'} borderWidth={'1px'} borderColor={'var(--c5)'}/>
                                    <ColumnText>
                                        <h4>Caixa de seleção:</h4>
                                        <span>{e.caixaDeSelecao} {e.caixaDeSelecao === 1 ? 'questão' : 'questões'}</span>
                                    </ColumnText>
                                    <Divider orientation='vertical' height={'3rem'} borderWidth={'1px'} borderColor={'var(--c5)'}/>
                                    <ColumnText>
                                        <h4>Objetiva:</h4>
                                        <span>{e.objetiva} {e.objetiva === 1 ? 'questão' : 'questões'}</span>
                                    </ColumnText>
                                    <Divider orientation='vertical' height={'3rem'} borderWidth={'1px'} borderColor={'var(--c5)'}/>
                                    <ColumnText>
                                        <h4>Discursiva:</h4>
                                        <span>{e.discursiva} {e.discursiva === 1 ? 'questão' : 'questões'}</span>
                                    </ColumnText>
                                    <Divider orientation='vertical' height={'3rem'} borderWidth={'1px'} borderColor={'var(--c5)'}/>
                                    <ColumnText>
                                        <h4>Escala:</h4>
                                        <span>{e.escala} {e.escala === 1 ? 'questão' : 'questões'}</span>
                                    </ColumnText>
                                    <Divider orientation='vertical' height={'3rem'} borderWidth={'1px'} borderColor={'var(--c5)'}/>
                                  </Flex>
                                  <Flex gap={'.5rem'}>
                                      <ButtonCmp small OutlineColor='Green2' onClick={() => Selecionar(e.id, false)}>Selecionar</ButtonCmp>
                                      <ElementoTooltipCmp bg={'var(--Rosa)'} label={'Duplicar formulário'}>
                                        <ButtonCmp small OutlineColor='Azul' onClick={() => DuplicarForm(e.id)}><FaCopy size={11} /></ButtonCmp>
                                      </ElementoTooltipCmp>
                                      <ElementoTooltipCmp bg={'var(--Rosa)'} label={'Pré-visualizar formulário'}>
                                        <ButtonCmp small OutlineColor='Rosa' onClick={() => nav(`/Jornada/PesquisaInterna/Formulario/${e.id}/Previsualizar`)}><FaEye size={11} /></ButtonCmp>
                                      </ElementoTooltipCmp>
                                      <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir formulário'}>
                                        <ButtonCmp small OutlineColor='Red' onClick={() => onOpenModalDelete(e.id)}><FaTrashAlt size={11} /></ButtonCmp>
                                      </ElementoTooltipCmp>
                                  </Flex>
                                </ContainerFormulario>
                            ))
                        }
                    </section>
            </Flex>
        </Body>
  )
}

export const Interpriseimg: React.FC = () => {
  return (
    <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.8901 15.7999V39.9849H27.5484V37.6531H29.5019V39.9849H33.2136V17.4039L23.8901 15.7999ZM27.8623 36.0089H26.0273V34.1551H27.8629L27.8623 36.0089ZM27.8623 32.9527H26.0273V31.0988H27.8629L27.8623 32.9527ZM27.8623 29.8964H26.0273V28.0426H27.8629L27.8623 29.8964ZM27.8623 26.8402H26.0273V24.9863H27.8629L27.8623 26.8402ZM27.8623 23.784H26.0273V21.9301H27.8629L27.8623 23.784ZM27.8623 20.7277H26.0273V18.8739H27.8629L27.8623 20.7277ZM31.0257 36.0089H29.1907V34.1551H31.0263L31.0257 36.0089ZM31.0257 32.9527H29.1907V31.0988H31.0263L31.0257 32.9527ZM31.0257 29.8964H29.1907V28.0426H31.0263L31.0257 29.8964ZM31.0257 26.8402H29.1907V24.9863H31.0263L31.0257 26.8402ZM31.0257 23.784H29.1907V21.9301H31.0263L31.0257 23.784ZM31.0257 20.7277H29.1907V18.8739H31.0263L31.0257 20.7277Z" fill="url(#paint0_linear_726_14011)" />
      <path d="M1.74048 25.7988V39.8671L5.40006 39.9815V37.653H7.35358V39.9848H11.0653V25.7988H1.74048ZM5.71262 36.0089H3.87632V34.155H5.71262V36.0089ZM5.71262 32.9526H3.87632V31.0988H5.71262V32.9526ZM5.71262 29.8964H3.87632V28.0425H5.71262V29.8964ZM8.87601 36.0102H7.04101V34.1563H8.87667L8.87601 36.0102ZM8.87601 32.9539H7.04101V31.1001H8.87667L8.87601 32.9539ZM8.87601 29.8977H7.04101V28.0438H8.87667L8.87601 29.8977Z" fill="url(#paint1_linear_726_14011)" />
      <path d="M22.1183 28.8789H12.7903V40.0001H16.4499V37.6683H18.4034V40.0001H22.1151L22.1183 28.8789ZM16.7605 36.0242H14.9242V34.1703H16.7605V36.0242ZM16.7605 32.9673H14.9242V31.1141H16.7605V32.9673ZM19.9297 36.0242H18.0934V34.1703H19.9297V36.0242ZM19.9297 32.9673H18.0934V31.1141H19.9297V32.9673Z" fill="url(#paint2_linear_726_14011)" />
      <path d="M16.5808 15.1668H7.25342V23.9957L10.9124 23.9562H12.8659V27.3195H16.5775L16.5808 15.1668ZM11.223 22.3121H9.38666V20.4602H11.223V22.3121ZM11.223 19.2558H9.38666V17.402H11.223V19.2558ZM14.3864 22.3121H12.554V20.4602H14.3896L14.3864 22.3121ZM14.3864 19.2558H12.554V17.402H14.3896L14.3864 19.2558Z" fill="url(#paint3_linear_726_14011)" />
      <path d="M14.7464 0H1.28802C0.946414 0 0.618802 0.136999 0.377251 0.380858C0.135701 0.624718 0 0.955462 0 1.30033V10.0897C0.000345245 10.4343 0.136219 10.7647 0.37775 11.0081C0.619282 11.2516 0.946699 11.3883 1.28802 11.3881H9.67836L13.0925 14.6435L12.8053 11.3881H14.7464C14.9156 11.3882 15.0831 11.3546 15.2394 11.2893C15.3957 11.224 15.5378 11.1282 15.6574 11.0074C15.777 10.8867 15.8719 10.7433 15.9366 10.5855C16.0013 10.4277 16.0345 10.2585 16.0344 10.0878V1.30033C16.0344 0.955462 15.8987 0.624718 15.6572 0.380858C15.4156 0.136999 15.088 0 14.7464 0ZM6.42837 6.96184C6.42837 7.10515 6.37197 7.2426 6.27159 7.34394C6.17121 7.44528 6.03506 7.50222 5.8931 7.50222H5.70687C5.56502 7.50204 5.42904 7.44503 5.3288 7.34371C5.22856 7.24239 5.17225 7.10504 5.17225 6.96184V4.86408C5.17225 4.72088 5.22856 4.58353 5.3288 4.48221C5.42904 4.38088 5.56502 4.32388 5.70687 4.3237H5.8931C6.03506 4.3237 6.17121 4.38063 6.27159 4.48197C6.37197 4.58331 6.42837 4.72076 6.42837 4.86408V6.96184ZM10.8615 7.60214C10.3868 7.95648 8.42746 7.91966 8.42746 7.91966C8.24224 7.91856 8.0613 7.8633 7.90652 7.76057L7.37777 7.4654C7.28126 7.40907 7.20125 7.32793 7.14589 7.23024C7.09053 7.13255 7.06179 7.02179 7.06261 6.90924V4.93705C7.06154 4.80845 7.08635 4.68097 7.13554 4.56234L7.84076 3.00233C7.88926 2.88866 7.96955 2.79173 8.0718 2.72343C8.17404 2.65512 8.29379 2.6184 8.41639 2.61775C8.55835 2.61775 8.6945 2.67469 8.79488 2.77603C8.89526 2.87737 8.95165 3.01482 8.95165 3.15814C8.9365 3.63388 8.81613 4.10019 8.59937 4.52289C8.4203 4.87131 10.9768 4.43546 10.9768 4.43546C11.1405 4.46847 11.2845 4.56574 11.3772 4.7059C11.4699 4.84606 11.5036 5.01764 11.471 5.18292C11.471 5.18292 11.7536 6.93554 10.8615 7.60214Z" fill="url(#paint4_linear_726_14011)" />
      <defs>
        <linearGradient id="paint0_linear_726_14011" x1="0" y1="20" x2="33.2136" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
        <linearGradient id="paint1_linear_726_14011" x1="0" y1="20" x2="33.2136" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
        <linearGradient id="paint2_linear_726_14011" x1="0" y1="20" x2="33.2136" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
        <linearGradient id="paint3_linear_726_14011" x1="0" y1="20" x2="33.2136" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
        <linearGradient id="paint4_linear_726_14011" x1="0" y1="20" x2="33.2136" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export const InterpriseimgWhite: React.FC = () => {
  return (
    <svg width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.8901 15.7999V39.9849H27.5484V37.6531H29.5019V39.9849H33.2136V17.4039L23.8901 15.7999ZM27.8623 36.0089H26.0273V34.1551H27.8629L27.8623 36.0089ZM27.8623 32.9527H26.0273V31.0988H27.8629L27.8623 32.9527ZM27.8623 29.8964H26.0273V28.0426H27.8629L27.8623 29.8964ZM27.8623 26.8402H26.0273V24.9863H27.8629L27.8623 26.8402ZM27.8623 23.784H26.0273V21.9301H27.8629L27.8623 23.784ZM27.8623 20.7277H26.0273V18.8739H27.8629L27.8623 20.7277ZM31.0257 36.0089H29.1907V34.1551H31.0263L31.0257 36.0089ZM31.0257 32.9527H29.1907V31.0988H31.0263L31.0257 32.9527ZM31.0257 29.8964H29.1907V28.0426H31.0263L31.0257 29.8964ZM31.0257 26.8402H29.1907V24.9863H31.0263L31.0257 26.8402ZM31.0257 23.784H29.1907V21.9301H31.0263L31.0257 23.784ZM31.0257 20.7277H29.1907V18.8739H31.0263L31.0257 20.7277Z" fill="white" />
      <path d="M1.74048 25.7988V39.8671L5.40006 39.9815V37.653H7.35358V39.9848H11.0653V25.7988H1.74048ZM5.71262 36.0089H3.87632V34.155H5.71262V36.0089ZM5.71262 32.9526H3.87632V31.0988H5.71262V32.9526ZM5.71262 29.8964H3.87632V28.0425H5.71262V29.8964ZM8.87601 36.0102H7.04101V34.1563H8.87667L8.87601 36.0102ZM8.87601 32.9539H7.04101V31.1001H8.87667L8.87601 32.9539ZM8.87601 29.8977H7.04101V28.0438H8.87667L8.87601 29.8977Z" fill="white" />
      <path d="M22.1183 28.8789H12.7903V40.0001H16.4499V37.6683H18.4034V40.0001H22.1151L22.1183 28.8789ZM16.7605 36.0242H14.9242V34.1703H16.7605V36.0242ZM16.7605 32.9673H14.9242V31.1141H16.7605V32.9673ZM19.9297 36.0242H18.0934V34.1703H19.9297V36.0242ZM19.9297 32.9673H18.0934V31.1141H19.9297V32.9673Z" fill="white" />
      <path d="M16.5808 15.1668H7.25342V23.9957L10.9124 23.9562H12.8659V27.3195H16.5775L16.5808 15.1668ZM11.223 22.3121H9.38666V20.4602H11.223V22.3121ZM11.223 19.2558H9.38666V17.402H11.223V19.2558ZM14.3864 22.3121H12.554V20.4602H14.3896L14.3864 22.3121ZM14.3864 19.2558H12.554V17.402H14.3896L14.3864 19.2558Z" fill="white" />
      <path d="M14.7464 0H1.28802C0.946414 0 0.618802 0.136999 0.377251 0.380858C0.135701 0.624718 0 0.955462 0 1.30033V10.0897C0.000345245 10.4343 0.136219 10.7647 0.37775 11.0081C0.619282 11.2516 0.946699 11.3883 1.28802 11.3881H9.67836L13.0925 14.6435L12.8053 11.3881H14.7464C14.9156 11.3882 15.0831 11.3546 15.2394 11.2893C15.3957 11.224 15.5378 11.1282 15.6574 11.0074C15.777 10.8867 15.8719 10.7433 15.9366 10.5855C16.0013 10.4277 16.0345 10.2585 16.0344 10.0878V1.30033C16.0344 0.955462 15.8987 0.624718 15.6572 0.380858C15.4156 0.136999 15.088 0 14.7464 0ZM6.42837 6.96184C6.42837 7.10515 6.37197 7.2426 6.27159 7.34394C6.17121 7.44528 6.03506 7.50222 5.8931 7.50222H5.70687C5.56502 7.50204 5.42904 7.44503 5.3288 7.34371C5.22856 7.24239 5.17225 7.10504 5.17225 6.96184V4.86408C5.17225 4.72088 5.22856 4.58353 5.3288 4.48221C5.42904 4.38088 5.56502 4.32388 5.70687 4.3237H5.8931C6.03506 4.3237 6.17121 4.38063 6.27159 4.48197C6.37197 4.58331 6.42837 4.72076 6.42837 4.86408V6.96184ZM10.8615 7.60214C10.3868 7.95648 8.42746 7.91966 8.42746 7.91966C8.24224 7.91856 8.0613 7.8633 7.90652 7.76057L7.37777 7.4654C7.28126 7.40907 7.20125 7.32793 7.14589 7.23024C7.09053 7.13255 7.06179 7.02179 7.06261 6.90924V4.93705C7.06154 4.80845 7.08635 4.68097 7.13554 4.56234L7.84076 3.00233C7.88926 2.88866 7.96955 2.79173 8.0718 2.72343C8.17404 2.65512 8.29379 2.6184 8.41639 2.61775C8.55835 2.61775 8.6945 2.67469 8.79488 2.77603C8.89526 2.87737 8.95165 3.01482 8.95165 3.15814C8.9365 3.63388 8.81613 4.10019 8.59937 4.52289C8.4203 4.87131 10.9768 4.43546 10.9768 4.43546C11.1405 4.46847 11.2845 4.56574 11.3772 4.7059C11.4699 4.84606 11.5036 5.01764 11.471 5.18292C11.471 5.18292 11.7536 6.93554 10.8615 7.60214Z" fill="white" />
    </svg>

  )
}

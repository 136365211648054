import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { Button } from '../../../../../../components/Button'
import { ModalBody, ModalFooter, ModalHeader } from './styles'

interface props {
  titulo: string
  isOpen: boolean
  onRequestClose: () => void
  onRemarcar: () => void
  onConcluir: () => void
}

export const ModalRemarcar: React.FC<props> = ({ titulo, isOpen, onRemarcar, onConcluir, onRequestClose }) => {
  Modal.setAppElement('#root')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <ModalHeader>
        <h2>{titulo}</h2>
        <MdClose size={24} onClick={onRequestClose} />
      </ModalHeader>

      <ModalBody>
        <Flex flexDir={'column'}>
          <Flex fontSize={'20px'} marginTop={'1rem'}>
            <span>Esta reunião está atrasada. Caso já tenha sido realizada,
              clique em concluir.
              Caso não tenha realizado a reunião você pode remarcá-la.
            </span>
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button VarColor='Green2' onClick={onConcluir}>Concluir</Button>
        <Button VarColor='Rosa' onClick={onRemarcar}>Remarcar</Button>
      </ModalFooter>
    </Modal>
  )
}

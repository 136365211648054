/* eslint-disable @typescript-eslint/no-unused-vars */
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { iFeedbackRespostaXColaborador } from '../../../../..'
import { iQuestao } from '../../../../../../../Jornada/Feedback/VisualizarRespostas'
import { Alternativa } from '../components/Alternativa'
import { CaixaSelecao } from '../components/CaixaSelecao'
import { Discursiva } from '../components/Discursiva'

interface iProps {
  questao: iQuestao
  respostas: iFeedbackRespostaXColaborador[]
}
export const FormResposta: React.FC<iProps> = ({ questao, respostas }) => {
  const styles = StyleSheet.create({
    Container: {
      flexDirection: 'column',
      margin: 16
    },
    Questao: {
      fontSize: 18,
      marginBottom: 5
    }
  })

  return (
    <View style={styles.Container}>
      <Text style={styles.Questao}>{questao.texto}</Text>
      <View style={{ flexDirection: 'column', margin: -12 }}>
        {respostas.map((f, i) => {
          return (
            <View key={i} style={{ margin: 12, flexDirection: 'column', padding: 8, borderRadius: 12, border: '1px solid black' }} wrap={false}>
              {f.respostas.filter(r => r.respostas.some(t => t.questaoId === questao.id)).map((r, i2) => {
                return (
                  <View key={i2} style={{ flexDirection: 'column' }}>
                    {questao.tipo === 1 &&
                      <Discursiva
                        questao={questao}
                        resposta={r}
                      />
                    }
                  </View>
                )
              })}
            </View>
          )
        })}
      </View>
    </View>
  )
}

/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { CountBar } from '../Components/CountBar'
import { IDepartment, IEmployee, iPerfilColaborador, iPostColaborador, ISquad, iStatusOrganograma } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { NavBar } from '../Components/NavBar'
import { Container, Content, Main, ScrollContainer, StyledButton } from './styles'
import { Flex, Image, Img, Text } from '@chakra-ui/react'
import { SquadOrgChart } from './Components/SquadOrgChart'
import { parseJwt } from '../../../../services/token'
import { useAuth } from '../../../../contexts/AuthContext'
import { ZoomContainer } from '../Dashboard/Components/ZoomContainer'
import { CreateColaboradorModal } from '../../../../components/ModalCreateColaborador'
import { Tree, TreeNode } from 'react-organizational-chart'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { SelectCmp } from '../../../../components/SelectCmp'
import { BsPersonPlus } from 'react-icons/bs'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}
interface IModel {
  departamentos: IDepartment[]
  organograma: IDepartment[]
}
export const DashboardDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  const nav = useNavigate()
  function ObterRotas(): INavLink[] {
    const rotas: INavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }
  const [Status, setStatus] = useState<iStatusOrganograma>()
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()
  const [Squads, setSquads] = useState<ISquad[]>([])
  const [View, setView] = useState(0)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [listaTodosDepartamentos, setListaTodosDepartamentos] = useState<IModel>({ departamentos: [], organograma: [] })
  const [OrganogramaCompleto, setOrganogramaCompleto] = useState<IDepartment[]>([])
  const [depId, setDepId] = useState<string>('todos')

  function getStatus(): void {
    appApi.get('Organograma/ObterStatus')
      .then(res => setStatus(res.data))
      .catch(err => console.log(err))
  }

  function getOrganograma(depId?: string): void {
    appApi.get(`Departamento/Populado?depId=${depId ?? ''}`)
      .then(res => {
        setListaTodosDepartamentos(res.data)
        setOrganogramaCompleto(res.data.organograma)
      })
      .catch(err => console.log(err))
  }

  function getSquads(): void {
    appApi.get('Organograma/VisualizarSquads')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }
  function getColaborador(): void {
    appApi.get('Colaborador/Perfil')
      .then(res => setColaborador(res.data))
      .catch(err => console.log(err))
  }

  // function onOpenModalCreate(id: string): void {
  //   setModalCreateIsOpen(true)
  //   setDemografiaId(id)
  // }

  function onCreateColaborador(form: iPostColaborador): void {
    appApi.post('Colaborador', form).then(res => {
      setModalCreateIsOpen(false)
      if (Colaborador?.id) {
        getOrganograma()
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getStatus()
    getSquads()
    getColaborador()
  }, [])

  useEffect(() => {
    if (Colaborador?.id) {
      getOrganograma()
    }
  }, [Colaborador])

  function listaTodosDeps(depId?: string): void {
    if (depId) {
      getOrganograma(depId)
    } else {
      setListaTodosDepartamentos({ ...listaTodosDepartamentos, organograma: OrganogramaCompleto })
    }
  }

  function returnOrganograma(): any {
    const result = <Flex flexDir={'column'} mt={'8rem'} gap={'2.5rem'} className='center'>
            {listaTodosDepartamentos.organograma.map(e => {
              return (
                <Tree label={<Text bg={'var(--DegradeAzul)'} mx={'auto'} width={'fit-content'} color={'var(--c1)'} p={'.5rem 2rem'} borderRadius={'.25rem'} fontWeight={'800'} fontSize={'1.25rem'}>{e.nome}</Text>} lineColor='var(--Azul)' lineWidth='2px' nodePadding='.5rem'>
                  {returnRamosOrganograma(e, true)}
                </Tree>
              )
            })}
          </Flex>
    return result
  }

  function returnRamosOrganograma(e: IDepartment, first: boolean): any {
    const result = <TreeNode label={<Flex flexDir={'column'} boxShadow={'var(--SombraBackground)'} border={'1.5px solid var(--Azul)'} p={'.75rem 3rem'} bg={'var(--c1)'} w={'fit-content'} alignItems={'center'}>
      <Flex w={'full'} gap={'2rem'} justifyContent={'space-between'}>
        <div></div>
        {!first && <Text bg={'var(--DegradeAzul)'} width={'fit-content'} color={'var(--c1)'} p={'.5rem 2rem'} borderRadius={'0 0 .5rem .5rem'} fontWeight={'800'} fontSize={'1.25rem'} mb={'1rem'}>{e.nome}</Text>}
        <BsPersonPlus size={30} color='Rosa' cursor={'pointer'} onClick={() => setModalCreateIsOpen(true)}/>
      </Flex>
      <Flex gap={'.5rem'} justifyContent={'center'} py={'1rem'}>
        {e.colaboradores && e.colaboradores?.map(employee => {
          return (<Flex flexDir={'column'} alignItems={'center'}>
            <Container onClick={() => nav(`/Rh/VisualizarPerfil/${employee.id}`)}>
              <Content>
                <Flex justifyContent={'center'} height={'8.5rem'} w={'full'}>
                  <Img w={'full'} h={'full'} objectFit={'cover'} src={employee.avatar || 'https://sigah.blob.core.windows.net/onboard/da193183-666b-42cd-ae52-da86bc76f065.png'}/>
                </Flex>
                <div className='boxAzul'>
                  <p>{employee.nome}</p>
                  <p>{employee.sobrenome}</p>
                </div>
              </Content>
            </Container>
            <Flex mt={'.5rem'}>
              {employee.subordinados && employee.subordinados.map(a => {
                return (gerarSubordinados(a))
              })}
            </Flex>
          </Flex>)
        })}
      </Flex>
    </Flex>}>
        {e.departamentoFilhos && e.departamentoFilhos.map(deps => {
          return (
            returnRamosOrganograma(deps, false)
          )
        })}
    </TreeNode>

    return result
  }

  function gerarSubordinados(employee: IEmployee): any {
    const result = <TreeNode label={<Flex flexDir={'column'} alignItems={'center'}>
      <Container onClick={() => nav(`/Rh/VisualizarPerfil/${employee.id}`)}>
        <Content>
          <Flex justifyContent={'center'} height={'8.5rem'} w={'full'}>
            <Img w={'full'} h={'full'} objectFit={'cover'} src={employee.avatar || 'https://sigah.blob.core.windows.net/onboard/da193183-666b-42cd-ae52-da86bc76f065.png'}/>
          </Flex>
          <div className='boxAzul'>
            <p>{employee.nome}</p>
            <p>{employee.sobrenome}</p>
          </div>
        </Content>
      </Container>
      <Flex mt={'.5rem'}>
        {employee.subordinados && employee.subordinados.map(a => {
          return (gerarSubordinados(a))
        })}
      </Flex></Flex>}>
    </TreeNode>

    return result
  }

  function zoomIn(): void {
    const button: any = document.querySelector('#zoomIn')
    if (button) {
      button.click()
    }
  }

  function zoomOut(): void {
    const button: any = document.querySelector('#zoomOut')
    if (button) {
      button.click()
    }
  }

  useEffect(() => {
    if (depId !== 'todos') {
      listaTodosDeps(depId)
    } else {
      listaTodosDeps()
    }
  }, [depId])

  return (
    <Body>
      <Main>
        {
          (ModalCreateIsOpen) && (
            <CreateColaboradorModal
              isOpen={ModalCreateIsOpen}
              onCreateEmployee={onCreateColaborador}
              onRequestClose={() => setModalCreateIsOpen(false)}
            />
          )
        }
        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />

        <CountBar
          countItems={[
            {
              value: Status?.colaboradores ?? 0,
              title: 'Colaboradores'
            },
            {
              value: Status?.cargos ?? 0,
              title: 'Funções'
            },
            {
              value: Status?.departamentos ?? 0,
              title: 'Áreas'
            }
          ]}
        />

        <main>
          <Flex marginBottom={'1rem'} alignItems={'end'} justifyContent={'space-between'} flexDir={'row'} marginLeft={'2rem'} marginRight={'2rem'}>
            <Flex>
              { listaTodosDepartamentos.departamentos.length > 1 && View === 0 && (<SelectCmp value={depId} OnChange={(e) => setDepId(e) }>
                { listaTodosDepartamentos.departamentos.length !== 1 && <option value='todos'>Todos</option> }
                { listaTodosDepartamentos.departamentos.map((e) => {
                  return (
                    <option value={e.id}>{e.nome}</option>
                  )
                })}
              </SelectCmp>)}
            </Flex>
            <Flex flexDir={'column'}>
              <strong>Tipo de visualização</strong>
              <Flex marginTop={'.5rem'} gap={'.75rem'}>
                <StyledButton onClick={() => { setView(0); setTimeout(() => zoomOut(), 400) }} selected={View === 0}>Matricial</StyledButton>
                <StyledButton onClick={() => setView(1)} selected={View === 1}>Squad</StyledButton>
              </Flex>
            </Flex>
          </Flex>

          {(!!Squads.length && View === 1) && (
            <SquadOrgChart reload={getSquads} squads={Squads} />
          )}

          {
            (Squads.length === 0 && View === 1) && (
              <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                <h3>Ainda não há nenhum colaborador atribuído a um Squad...</h3>
              </Flex>
            )
          }
          {View === 0 && <Flex pos={'relative'}>
            <Flex gap={'.5rem'} zIndex='999' mt={'.5rem'} pos={'absolute'} top={'20px'} left={'20px'}>
              <ButtonCmp VarColor='Red' onClick={zoomOut} rightIcon={<MdOutlineZoomOut/>}>Zoom</ButtonCmp>
              <ButtonCmp VarColor='Green1' onClick={zoomIn} rightIcon={<MdOutlineZoomIn/>}>Zoom</ButtonCmp>
            </Flex>
            <ScrollContainer>
              <ZoomContainer height={listaTodosDepartamentos.departamentos.length * 600}>
                {returnOrganograma()}
              </ZoomContainer>
            </ScrollContainer>
          </Flex>}
        </main>
      </Main>
    </Body>
  )
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { iLinhaTabela } from '../../Individual'
import { parseJwt } from '../../../../../../services/token'

interface iChartProps{
  model: iLinhaTabela[]
  variant?: 'individual'|'corporativo'
}

export const FeedbackChart: React.FC<iChartProps> = ({ model, variant }) => {
  variant = variant === undefined ? 'individual' : 'corporativo'
  const id = variant
  const { cid } = parseJwt()
  const labels: string[] = variant === 'individual' ? ['Recebidos', 'Enviados'] : ['Finalizadas', 'Aguardando', 'Outros']
  const colors: string[] = ['#2F80ED', '#828282', '#EB5757', '#6FCF97', '#9B51E0', '#CC42A2']

  function ObterDados(): number[] {
    if (variant === 'individual') {
      const recebidos = model.filter(e => e.convidado.id === cid).reduce((accumulator, value) => {
        return accumulator + 1
      }, 0)

      const enviados = model.filter(e => e.criador.id === cid).reduce((accumulator, value) => {
        return accumulator + 1
      }, 0)

      return [recebidos, enviados]
    } else {
      const finalizadas = model.filter(e => e.status === 5).reduce((accumulator, value) => {
        return accumulator + 1
      }, 0)

      const aguardando = model.filter(e => e.status === 3 || e.status === 4 || e.status === 6).reduce((accumulator, value) => {
        return accumulator + 1
      }, 0)

      return [finalizadas, aguardando, model.filter(e => e.status !== 5 && e.status !== 3 && e.status !== 4 && e.status !== 6).length]
    }
  }

  function GerarGrafico(): void {
    const options = {
      series: ObterDados(),
      colors: colors,
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: labels,
      legend: {
        show: true,
        enabled: false,
        position: 'right',
        offsetY: -20
      },
      chart: {
        width: 215,
        height: 215,
        type: 'donut',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      fill: {
        colors: colors
      },
      plotOptions: {
        pie: {
          size: 50,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '5px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
                offsetY: -3,
                formatter: function (w: any) {
                  return 'Total'
                }
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                offsetY: 0
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '12px',
                color: '#373d3f',
                formatter: function () {
                  return model.length
                }
              }
            }
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [model])

  return (
        <div style={{ marginTop: '2.5rem' }} id={id}></div>
  )
}

import styled from 'styled-components'

export const ModalBody = styled.div`
display:flex;
flex-direction:column;
width: 37rem;
text-align: center;
gap:.5rem;
h2{
    color:var(--secundario5);
    font-size: 1.5rem;
    line-height: 2.5rem;
}
`

/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, AvatarGroup, Box, Flex, Grid, GridItem, Image, Tag, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye, FaFileUpload, FaFilter, FaSave } from 'react-icons/fa'
import { MdGroups, MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { Body } from '../../Layouts/Body'
import { ContainerFiltro, EixoNineBox, EixoUnicoNineBox, OutlineContainer } from './styles'
import seta from './assets/seta.svg'
import azul from './assets/azul.svg'
import laranja from './assets/laranja.svg'
import verde from './assets/verde.svg'
import verdeClaro from './assets/verdeClaro.svg'
import vermelho from './assets/vermelho.svg'

import { useNavigate } from 'react-router-dom'
import { NavLine } from '../../../components/NavLine'
import { appApi, urlApi } from '../../../services/appApi'
import { iNineBox, iNomeId, iQuadrante } from '../../../interfaces'
import { ObterCorQuadrantePorNome } from '../../../components/PerfilColaborador/indesx'
import { obterDataParaCsv, ObterToken, PropInvalida, useQuery } from '../../../Utils/Helper'
import { ModalComite } from './components/ModalComite'
import { PopUpAviso } from './components/PopUpAviso'
import { ModalCadastroEmMassa } from './components/ModalCadastroEmMassa'
import { parseJwt } from '../../../services/token'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { DrawerFiltro } from './components/DrowerFiltro'
import { SearchCmp } from '../../../components/SearchCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import LoadingContainer from '../../../components/LoadingContainer'

interface iColaborador {
  id: string
  avatar: string
  nome: string
  departamentoId: string
  tags: string[]
  squads: string[]
  notaX: number
  notaY: number
}

interface iFiltro {
  areas: string[]
  squads: string[]
  gestores: string[]
}

export const NineBox: React.FC = () => {
  const isAdm = parseJwt().adm === 'True'
  const toast = useToast()
  const query = useQuery()
  const nav = useNavigate()

  const departamentosId = localStorage.getItem('departamentosIdNineBox') ?? ''
  const squadsId = localStorage.getItem('squadsIdNineBox') ?? ''
  const gestoresId = localStorage.getItem('gestoresIdNineBox') ?? ''

  const [Areas, setAreas] = useState<iNomeId[]>([])
  const [Squads, setSquads] = useState<iNomeId[]>([])
  const [Gestores, setGestores] = useState<iNomeId[]>([])
  const [Alterados, setAlterados] = useState<string[]>([])

  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingBaixarCsv, setIsLoadingBaixarCsv] = useState(false)
  const [IsLoading9Box, setIsLoading9Box] = useState(false)
  const [QuadranteAtual, setQuadranteAtual] = useState<string>()
  const [Colaboradores, setColaboradores] = useState<iColaborador[]>([])

  const [QuadranteSelecionado, setQuadranteSelecionado] = useState<iQuadrante>()

  const [TextoPesquisa, setTextoPesquisa] = useState<string>('')

  const [ModalComiteIsOpen, setModalComiteIsOpen] = useState(false)
  const [DrowerIsOpen, setDrowerIsOpen] = useState(false)
  const [Tags, setTags] = useState<string[]>([])
  const [Model, setModel] = useState<iNineBox>({
    padrao: false,
    id: '',
    titulo: '',
    tags: [],
    squads: [],
    areas: [],
    tipoMeta: 1,
    configuracaoEixoX: {
      titulo: '',
      descricao: ''
    },
    configuracaoEixoY: {
      titulo: '',
      descricao: ''
    },
    eixosX: [],
    eixosY: [],
    quadrantes: []
  })

  const [Comite, setComite] = useState<string[]>()

  const [PopUpIsOpen, setPopUpIsOpen] = useState(false)
  const [ModalCsvIsOpen, setModalCsvIsOpen] = useState(false)

  function onDragDrop(id: string): void {
    setAlterados([...Alterados, id])
    const quadrantes = [...Model.quadrantes]
    const pos = quadrantes.findIndex(r => r.id === QuadranteAtual)
    if (pos === -1) return

    if (!quadrantes[pos].colaboradores?.some(t => t === id)) {
      if (quadrantes[pos].colaboradores) {
        quadrantes[pos].colaboradores?.push(id)
      } else {
        quadrantes[pos].colaboradores = [id]
      }
    } else {
      quadrantes[pos].colaboradores = quadrantes[pos].colaboradores?.filter(r => r !== id)
    }
    setModel({ ...Model, quadrantes: quadrantes })
    setQuadranteAtual(undefined)
  }

  function onZoneChange(id: string): void {
    setAlterados([...Alterados, id])
    const quadrantes = [...Model.quadrantes]
    const ondeEstapresente = quadrantes.filter(t => t.colaboradores?.some(w => w === id))
    ondeEstapresente.forEach((r) => {
      const posQuad = quadrantes.findIndex(t => t.id === r.id)
      if (posQuad !== -1) {
        quadrantes[posQuad].colaboradores = quadrantes[posQuad].colaboradores?.filter(q => q !== id)
      }
    })

    const pos = quadrantes.findIndex(r => r.id === QuadranteAtual)
    if (pos !== -1) {
      if (quadrantes[pos].colaboradores) {
        quadrantes[pos].colaboradores?.push(id)
      } else {
        quadrantes[pos].colaboradores = [id]
      }
    }
    setModel({ ...Model, quadrantes: quadrantes })
  }

  function ObterColaboradores(): void {
    setIsLoading9Box(true)
    const filter = `${departamentosId.length > 0 ? `&DepartamentosId=${departamentosId}` : ''}${squadsId.length > 0 ? `&SquadsId=${squadsId}` : ''}${gestoresId.length > 0 ? `&GestoresId=${gestoresId}` : ''}`
    appApi.get(`Colaborador/Select/NineBox?${filter}`)
      .then(res => { setColaboradores(res.data); setIsLoading(false) })
      .catch(err => { console.log(err); setIsLoading(false) })
      .finally(() => {
        setIsLoading9Box(false)
      })
  }

  function RedefinirFiltros(): void {
    setIsLoading(true)
    localStorage.removeItem('departamentosIdNineBox')
    localStorage.removeItem('squadsIdNineBox')
    localStorage.removeItem('gestoresIdNineBox')
    ObterTags({ areas: [], gestores: [], squads: [] })
    appApi.get('Colaborador/Select/NineBox')
      .then(res => { setColaboradores(res.data); setIsLoading(false) })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function ObterNineBoxPadrao(): void {
    appApi.get('NineBox/Padrao')
      .then(res => {
        setModel(res.data)
      })
      .catch(({ response }) => {
        if (response.status === 404) {
          setPopUpIsOpen(true)
        }
      })
  }

  function SalvarTudo(quadranteId?: string): void {
    setAlterados([])
    appApi.put('NineBox', Model)
      .then(() => {
        toast({
          title: '9Box geral atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        if (quadranteId) {
          nav(`?quadranteId=${quadranteId ?? ''}`)
        }
      }
      )
      .catch(err => console.log(err))
  }

  function ObterSvg(nome: string): any {
    switch (Model.quadrantes.findIndex(t => t.titulo === nome).toString()) {
      case '0': return azul
      case '1': return verdeClaro
      case '2': return verde
      case '3': return laranja
      case '4': return azul
      case '5': return verdeClaro
      case '6': return vermelho
      case '7': return laranja
      case '8': return azul
    }
  }

  function ObterComite(): void {
    appApi.get('Comite')
      .then(res => setComite(res.data))
      .catch(err => console.log(err))
  }

  function CriarComite(participantes: string[]): void {
    appApi.post('Comite', participantes)
      .then(() => {
        setComite(participantes)
        toast({
          title: 'Comitê criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function AtualizarComite(participantes: string[]): void {
    appApi.put('Comite', participantes)
      .then(() => {
        setComite(participantes)
        toast({
          title: 'Comitê atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function EncerrarComite(): void {
    appApi.patch('Comite/Encerrar')
      .then(() => {
        setComite(undefined)
        toast({
          title: 'Comitê encerrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function ObterTags(model: iFiltro): void {
    setTags([])
    const newArray: string[] = []
    model.areas.forEach(e => {
      const resp = Areas.find(a => a.id === e)
      if (resp) {
        newArray.push(resp.nome)
      }
    })
    model.gestores.forEach(e => {
      const resp = Gestores.find(a => a.id === e)
      if (resp) {
        newArray.push(resp.nome)
      }
    })
    model.squads.forEach(e => {
      const resp = Squads.find(a => a.id === e)
      if (resp) {
        newArray.push(resp.nome)
      }
    })
    setTags(newArray.length > 4 ? [...newArray.slice(0, 4), 'Outros...'] : newArray)
  }

  function ReturnStringFromArray(arr: any[]): string {
    let response = ''
    arr.forEach(e => {
      response += `${e};`
    })

    return response
  }

  function FiltrarDashboard(params: iFiltro): void {
    ObterTags(params)
    localStorage.setItem('departamentosIdNineBox', ReturnStringFromArray(params.areas))
    localStorage.setItem('squadsIdNineBox', ReturnStringFromArray(params.squads))
    localStorage.setItem('gestoresIdNineBox', ReturnStringFromArray(params.gestores))

    const filter = `${params.areas.length > 0 ? `&DepartamentosId=${ReturnStringFromArray(params.areas)}` : ''}${params.squads.length > 0 ? `&SquadsId=${ReturnStringFromArray(params.squads)}` : ''}${params.gestores.length > 0 ? `&GestoresId=${ReturnStringFromArray(params.gestores)}` : ''}`
    appApi.get(`Colaborador/Select/NineBox?${filter}`)
      .then(res => { setColaboradores(res.data); setIsLoading(false) })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function ObterDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function ObterGestores(): void {
    appApi.get('Colaborador/Select/Gestor')
      .then(res => {
        const data: iNomeId[] = res.data
        setGestores(data)
      })
      .catch(err => console.log(err))
  }

  function BaixarCsv(): void {
    setIsLoadingBaixarCsv(true)
    appApi.get(`${urlApi}/Colaborador/NineBoxCsv/?token=${ObterToken()}`, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.href = url
        a.download = `BaseColaboradores_${obterDataParaCsv()}.csv`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadingBaixarCsv(false))
  }

  useEffect(() => {
    ObterDepartamentos()
    ObterGestores()
    ObterSquads()
    ObterColaboradores()
    ObterNineBoxPadrao()
    ObterComite()
  }, [])

  useEffect(() => {
    if (Areas && Squads && Gestores) {
      ObterTags({ gestores: gestoresId.split(';'), areas: departamentosId.split(';'), squads: squadsId.split(';') })
    }
  }, [Areas, Squads, Gestores])

  useEffect(() => {
    const val = query.get('quadranteId')
    if (val) {
      setQuadranteSelecionado(Model.quadrantes.find(r => r.id === val))
    } else {
      setQuadranteSelecionado(undefined)
    }
  }, [query])

  return (
    <Body>
      <ModalCadastroEmMassa
        reload={ObterNineBoxPadrao}
        isOpen={ModalCsvIsOpen}
        onRequestClose={() => setModalCsvIsOpen(false)}
      />

      <DrawerFiltro
        areas={Areas}
        squads={Squads}
        gestores={Gestores}
        isOpen={DrowerIsOpen}
        startValue={{ gestores: gestoresId.split(';'), areas: departamentosId.split(';'), squads: squadsId.split(';') }}
        onClose={() => setDrowerIsOpen(false)}
        onRedefinir={RedefinirFiltros}
        onFilter={FiltrarDashboard}
      />

      {ModalComiteIsOpen &&
        <ModalComite
          onClose={() => setModalComiteIsOpen(false)}
          onCreate={CriarComite}
          participantes={Comite}
          onEncerrar={EncerrarComite}
          onUpdate={AtualizarComite}
        />}
      {PopUpIsOpen && <PopUpAviso />}
      <NavLine options={
        [
          { nome: 'Desempenho' },
          { nome: '9Box', path: '/Desempenho/9box' },
          QuadranteSelecionado ? { nome: 'Enigma' } : { nome: '' }
        ]} />
      <Flex my={'1rem'} justifyContent={'space-between'}>
        <Box as='h1' flex={'1'}>9Box</Box>
        <Box as={'div'} display={'flex'} fontSize={'1rem'} border={'2px solid var(--Azul)'} p={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1rem'} borderRadius={'.25rem'} maxH={'3rem'} boxShadow={'var(--SombraBackground)'} bg={'white'}>
          <Flex alignItems={'center'} style={{ fontSize: '1.75rem' }}>
            <MdGroups />
          </Flex>
          <Box as='div'>
            <Text lineHeight={'1.138rem'}><strong>{Colaboradores.length}</strong> Colaboradores</Text>
          </Box>
        </Box>
      </Flex>
      <OutlineContainer theme={'c3'}>
        <ContainerFiltro>
          <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Flex alignItems={'center'}>
              <Text fontWeight={'500'} marginRight={'.75rem'} fontSize={'.875rem'}>Filtros aplicados</Text>
              <Flex padding={'0 1rem'} borderLeft={'2px solid var(--c4)'} gap={'.5rem'}>
                {Tags.length === 0 && <Tag fontSize={'.875rem'}>Todos</Tag>}

                {
                  Tags.map((e) => {
                    return (
                      <Tag fontSize={'.875rem'}>{e}</Tag>
                    )
                  })
                }
              </Flex>
            </Flex>
            <FaFilter onClick={() => setDrowerIsOpen(true)} color='var(--c6)' cursor={'pointer'} size={16} />
          </Flex>
        </ContainerFiltro>
      </OutlineContainer>
      {PropInvalida(QuadranteSelecionado) && (
        <WhiteContainer margin='1rem 0 0 0' IsLoading={IsLoading} styles={{ minHeight: '24rem', padding: '1rem 1.5rem' }}>
          <LoadingContainer linhas={22} loading={IsLoading9Box}>
            <Flex mb={'.75rem'} alignItems={'center'} justifyContent='space-between'>
              <h3>Visão de grupo</h3>
              <Flex justifyContent={'end'} gap={'.5rem'} flex={'1'}>
                {isAdm && !PropInvalida(Comite) && <ButtonCmp
                  leftIcon={<MdOutlineStopCircle />}
                  VarColor='Red'
                  onClick={() => setModalComiteIsOpen(true)}
                >Comitê de calibração em andamento</ButtonCmp>}

                {isAdm && PropInvalida(Comite) && <ButtonCmp
                  leftIcon={<MdOutlinePlayCircle />}
                  VarColor='Rosa'
                  onClick={() => setModalComiteIsOpen(true)}
                >Iniciar comitê de calibração</ButtonCmp>}

                {isAdm &&
                  <ButtonCmp
                    VarColor='Rosa'
                    leftIcon={<FaFileUpload />}
                    py={'0.75rem'}
                    onClick={BaixarCsv}
                    isLoading={IsLoadingBaixarCsv}
                  >Baixar CSV da base</ButtonCmp>
                }

                {Comite &&
                  <ButtonCmp
                    VarColor='Green2'
                    leftIcon={<FaFileUpload />}
                    py={'0.75rem'}
                    onClick={() => setModalCsvIsOpen(true)}
                  >Subir dados em massa</ButtonCmp>
                }

                {PropInvalida(Comite) &&
                  <ElementoTooltipCmp
                    label='Antes de subir o csv você deve iniciar um comitê!'
                  >
                    <ButtonCmp
                      VarColor='Green2'
                      isDisabled
                      leftIcon={<FaFileUpload />}
                      py={'0.75rem'}
                    >Subir dados em massa</ButtonCmp>
                  </ElementoTooltipCmp>
                }

                <SearchCmp
                  EnterAction={() => {}}
                  OnChange={setTextoPesquisa}
                  placeholder={'Buscar colaborador'}
                  width={'14rem'}
                />
              </Flex>
            </Flex>

            <Box pointerEvents={!isAdm ? 'none' : 'unset'} as='div' mb={'1rem'} width={'64rem'} backgroundColor={'var(--AzulOpacoBg)'} borderRadius={'.25rem'} px={'.75rem'} ml={'auto'} border={'1px solid var(--c4)'}>
              <Text
                mt={'.5rem'}
                mb={'.325rem'}
                fontSize={'.875rem'}
                textAlign={'center'}
                fontWeight={'700'}
              >COLABORADORES NÃO AVALIADOS</Text>
              <Box mb={'.75rem'} onDragEnter={() => setQuadranteAtual(undefined)}>
                <AvatarGroup max={34} size='sm' fontSize={'.875rem'}>
                  {Colaboradores.filter((r) => {
                    if (TextoPesquisa === '') {
                      return r
                    } else if (r?.nome.toLowerCase().includes(TextoPesquisa.toLocaleLowerCase())) {
                      return r
                    }
                    return null
                  }).filter(t => !Model.quadrantes.some(y => y.colaboradores?.some(q => q === t.id)))
                    .map((c, i) => {
                      return (
                        <ElementoTooltipCmp bg={'var(--Gray2)'} label={c?.nome}>
                          <Avatar
                            key={c.id}
                            onDragEnd={() => onDragDrop(c.id)}
                            draggable
                            src={c?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : c?.avatar}
                            name={c?.nome}
                            size='sm'
                            cursor={'pointer'}
                            ml={i !== 0 ? '-4px' : ''}
                            _hover={{
                              brightness: '1.25',
                              transition: '.2s',
                              opacity: '.85',
                              boxShadow: '0 2px 8px 1px #00000025'
                            }}
                          />
                        </ElementoTooltipCmp>
                      )
                    })}
                </AvatarGroup>
              </Box>
            </Box>
            <div>
              <Flex flexDirection={'column'} alignItems={'end'}>
                <Flex justifyContent={'center'}>
                  <Box as='div' height={'38.75rem'} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'7.25rem'}>
                    <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'} transform={'rotate(-90deg)'}>
                      <Text display={'flex'} justifyContent={'center'} gap={'.75rem'} fontWeight={'900'} fontSize={'2rem'}><span>{Model.configuracaoEixoY.titulo.toUpperCase()}</span></Text>
                      <Text fontSize={'1.125rem'} textAlign={'center'}>{Model.configuracaoEixoY.descricao}</Text>
                      <Image src={seta} width='41.75rem' marginRight={'3rem'} />
                    </Box>
                  </Box>
                  <Box as='div'>
                    <Grid gridTemplateColumns={'1fr'} gap={'.75rem'}>
                      {Model.eixosY.map((e, i) => {
                        return (
                          <Grid key={i} gridColumn={'1'} gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
                            <GridItem alignSelf={'center'}>
                              <Text textAlign={'center'} color={'var(--Azul)'} display={'flex'} justifyContent={'center'} gap={'.75rem'} fontWeight={'700'} fontSize={'1.125rem'}>
                                <span>{e.titulo}</span>
                              </Text>
                              <Text
                                textAlign={'center'}
                                fontSize={'0.875rem'}
                                color={'var(--Azul)'}
                              >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                            </GridItem>
                            {Model.quadrantes.filter(t => t.eixoY === e.id).map((q, i2) => {
                              return (
                                <EixoNineBox
                                  onDragEnter={() => setQuadranteAtual(q.id)}
                                  key={i2}
                                  theme={ObterCorQuadrantePorNome(Model.quadrantes, q.titulo)}
                                >
                                  <Text fontSize={'.875rem'} fontWeight={'700'} mt={'.75rem'} mb={'.325rem'}>{q.titulo}</Text>
                                  <Box flex={'1'} w={'full'}>
                                    <Flex flex={'1'} pl={'.8rem'} w={'full'} alignSelf={'start'} flexWrap={'wrap'} maxH={'32px'}>
                                      {q.colaboradores?.slice(0, 22).filter((r) => {
                                        const colab = Colaboradores.find(t => t.id === r)
                                        if (TextoPesquisa === '') {
                                          return r
                                        } else if (colab?.nome.toLowerCase().includes(TextoPesquisa.toLocaleLowerCase())) {
                                          return r
                                        }
                                        return null
                                      }).map((c: string, i3) => {
                                        const colab = Colaboradores.find(t => t.id === c)
                                        if (PropInvalida(colab)) return null
                                        return (
                                          <ElementoTooltipCmp bg={ObterCorQuadrantePorNome(Model.quadrantes, q.titulo)} label={`${colab?.nome} - (${Model.configuracaoEixoX.titulo.length <= 5 ? Model.configuracaoEixoX.titulo : (Model.configuracaoEixoX.titulo.substring(0, 5) + '.')}: ${colab?.notaX}; ${Model.configuracaoEixoY.titulo.length <= 5 ? Model.configuracaoEixoY.titulo : (Model.configuracaoEixoY.titulo.substring(0, 5) + '.')}: ${colab?.notaY})`}>
                                            <Avatar
                                              key={i3}
                                              onDragEnd={() => onZoneChange(c)}
                                              src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                                              name={Colaboradores.find(t => t.id === c)?.nome ?? ''}
                                              size='sm'
                                              draggable
                                              border={Alterados.some(e => e === colab?.id) ? '3px solid var(--Red)' : ''}
                                              boxShadow={Alterados.some(e => e === colab?.id) ? '0 0 6px 1px var(--Red)' : ''}
                                              ml={i3 !== 0 ? '-2px' : ''}
                                              pointerEvents={!isAdm ? 'none' : 'unset'}
                                              _hover={{
                                                cursor: 'pointer',
                                                brightness: '1.25',
                                                transition: '.2s',
                                                opacity: '.85'
                                              }}
                                              onDoubleClick={() => { nav(`/Desempenho/9Box/${colab?.id}`); SalvarTudo(undefined) }}
                                            />
                                          </ElementoTooltipCmp>
                                        )
                                      })}

                                      <AvatarGroup
                                        size='sm'
                                        max={1}
                                        position={'absolute'}
                                        top={'7.275rem'}
                                        left={'10.9rem'}
                                        spacing={'-0.188rem'}
                                      >
                                        {q.colaboradores?.slice(22, q.colaboradores.length).map((c, i3) => {
                                          const colab = Colaboradores.find(t => t.id === c)
                                          if (PropInvalida(colab)) return null
                                          return (
                                            <Avatar
                                              key={i3}
                                              onDragEnd={() => onZoneChange(c)}
                                              src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                                              name={colab?.nome}
                                              size='sm'
                                              ml={i3 !== 0 ? '-2px' : ''}
                                              pointerEvents={!isAdm ? 'none' : 'unset'}
                                              _hover={{
                                                cursor: 'pointer',
                                                brightness: '1.25',
                                                transition: '.2s',
                                                opacity: '.85',
                                                boxShadow: '0 2px 8px 1px #00000035'
                                              }}
                                              onDoubleClick={() => { nav(`/Desempenho/9Box/${colab?.id}`); SalvarTudo(undefined) }}
                                            />
                                          )
                                        })}
                                      </AvatarGroup>
                                    </Flex>
                                  </Box>
                                  {PropInvalida(Comite) && isAdm && q.colaboradores && q.colaboradores.length > 0 &&
                                    <Box
                                      height={'1rem'}
                                      display={'flex'}
                                      width={'100%'}
                                      justifyContent={'flex-end'}
                                      alignItems={'flex-end'}
                                    >
                                      <Box
                                        overflow={'hidden'}
                                        color={'var(--c1)'}
                                        pt={'.5rem'}
                                        pl={'.7rem'}
                                        backgroundImage={ObterSvg(q.titulo)}
                                        backgroundSize={'cover'}
                                        cursor={'pointer'}
                                        backgroundPosition={'center'}
                                        transition={'all .2s'}
                                        borderRadius={'0 0 .25rem 0'}
                                        width={'2rem'}
                                        height={'1.8rem'}
                                        _hover={{ height: '2.3rem', width: '2.75rem', paddingTop: '.85rem', paddingLeft: '1rem' }}
                                      >
                                        <ElementoTooltipCmp
                                          label='Antes de prosseguir você deve iniciar um comitê!'
                                        >
                                          <div>
                                            <FaEye className='disabled' size={13}/>
                                          </div>
                                        </ElementoTooltipCmp>
                                      </Box>
                                    </Box>
                                  }
                                  {isAdm && Comite && q.colaboradores && q.colaboradores.length > 0 && (
                                    <Box
                                      height={'1rem'}
                                      display={'flex'}
                                      width={'100%'}
                                      justifyContent={'flex-end'}
                                      alignItems={'flex-end'}
                                    >
                                      <Box
                                        overflow={'hidden'}
                                        color={'var(--c1)'}
                                        pt={'.5rem'}
                                        pl={'.7rem'}
                                        backgroundImage={ObterSvg(q.titulo)}
                                        backgroundSize={'cover'}
                                        cursor={'pointer'}
                                        backgroundPosition={'center'}
                                        transition={'all .2s'}
                                        borderRadius={'0 0 .25rem 0'}
                                        width={'2rem'}
                                        height={'1.8rem'}
                                        _hover={{ height: '2.3rem', width: '2.75rem', paddingTop: '.85rem', paddingLeft: '1rem' }}
                                        onClick={() => SalvarTudo(q.id)}>
                                        <ElementoTooltipCmp label={''}>
                                          <div><FaEye size={13}/></div>
                                        </ElementoTooltipCmp>
                                      </Box>
                                    </Box>
                                  )}
                                </EixoNineBox>
                              )
                            })}
                          </Grid>
                        )
                      })}
                      <Grid gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
                        <GridItem></GridItem>
                        {Model.eixosX.map((e, i) => {
                          return (
                            <GridItem key={i} alignSelf={'center'}>
                              <Text
                                textAlign={'center'}
                                color={'var(--Azul)'}
                                display={'flex'}
                                justifyContent={'center'}
                                gap={'.75rem'}
                                fontWeight={'700'}
                                fontSize={'1.125rem'}
                              >
                                <span>{e.titulo}</span>
                              </Text>
                              <Text
                                textAlign={'center'}
                                fontSize={'0.875rem'}
                                color={'var(--Azul)'}
                              >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                            </GridItem>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                </Flex>
                <Flex pl={'11.5rem'} my={'2rem'}>
                  <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'}>
                    <Image width={'56rem'} src={seta} />
                    <Text display={'flex'} justifyContent={'center'} gap={'.75rem'} fontWeight={'900'} fontSize={'2rem'}><span>{Model.configuracaoEixoX.titulo.toUpperCase()}</span></Text>
                    <Text fontSize={'1.125rem'} textAlign={'center'}>{Model.configuracaoEixoX.descricao}</Text>
                  </Box>
                </Flex>
              </Flex>
            </div>
            {isAdm &&
              <Flex justifyContent={'flex-end'} gap={'.5rem'} mb={'.75rem'}>
                <ButtonCmp
                  VarColor='Gray4'
                  onClick={ObterNineBoxPadrao}
                >Cancelar alterações</ButtonCmp>
                {
                  PropInvalida(Comite) &&
                  <ElementoTooltipCmp
                    bg={'var(--Azul)'}
                    label='Para salvar você deve iniciar um comitê!'
                  >
                    <div>
                      <ButtonCmp
                        VarColor='Green2'
                        disabled
                        leftIcon={<FaSave />}
                      >Salvar definição</ButtonCmp>
                    </div>
                  </ElementoTooltipCmp>
                }
                {!PropInvalida(Comite) &&
                  <ButtonCmp
                    VarColor='Green2'
                    leftIcon={<FaSave />}
                    onClick={() => SalvarTudo(undefined)}
                  >Salvar definição</ButtonCmp>
                }
              </Flex>
            }
          </LoadingContainer>
        </WhiteContainer>
      )}
      {!PropInvalida(QuadranteSelecionado) && (
        <WhiteContainer margin='1rem 0 0 0' IsLoading={IsLoading} styles={{ minHeight: '24rem' }}>
          <Flex alignItems={'center'}>
            <Text flex={'1'} fontWeight={'700'} fontSize={'1.25rem'}>Visão de grupo</Text>
          </Flex>
          <div>
            <ButtonCmp
              onClick={() => nav('/Desempenho/9Box')}
              VarColor='Gray4'
              my={'1rem'}
            >Voltar</ButtonCmp>
          </div>
          <EixoUnicoNineBox
            theme={ObterCorQuadrantePorNome(Model.quadrantes, QuadranteSelecionado?.titulo ?? '')}
          >
            <Box as='h2' fontWeight={'600'} mb={'.5rem'} textAlign={'center'}>{QuadranteSelecionado?.titulo}</Box>
            <Flex flex={'1'} pl={'.8rem'} alignSelf={'start'} width={'100%'} flexWrap={'wrap'}>
              {QuadranteSelecionado?.colaboradores?.map((e: string, i: number) => {
                const colab = Colaboradores.find(t => t.id === e)
                if (colab) {
                  return (
                    <ElementoTooltipCmp bg={ObterCorQuadrantePorNome(Model.quadrantes, QuadranteSelecionado.titulo)} label={`${colab?.nome} - (${Model.configuracaoEixoX.titulo.length <= 5 ? Model.configuracaoEixoX.titulo : (Model.configuracaoEixoX.titulo.substring(0, 5) + '.')}: ${colab?.notaX}; ${Model.configuracaoEixoY.titulo.length <= 5 ? Model.configuracaoEixoY.titulo : (Model.configuracaoEixoY.titulo.substring(0, 5) + '.')}: ${colab?.notaY})`}>
                      <Avatar
                        key={i}
                        onClick={() => nav(`/Desempenho/9Box/${Colaboradores.find(t => t.id === e)?.id ?? ''}`)}
                        src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                        name={colab?.nome ?? ''}
                        size='md'
                        ml={i !== 0 ? '-4px' : ''}
                        _hover={{
                          cursor: 'pointer',
                          brightness: '1.25',
                          transition: '.2s',
                          opacity: '.85',
                          boxShadow: '0 2px 8px 1px #00000075'
                        }}
                      />
                    </ElementoTooltipCmp>
                  )
                }
              })}
            </Flex>
          </EixoUnicoNineBox>
        </WhiteContainer>
      )}
    </Body>
  )
}

import styled from 'styled-components'

export const ModalHeader = styled.div`
display: flex;
margin-bottom:.5rem;
justify-content: space-between;
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
padding: 0 .75rem 0 .75rem;

section{
    display: flex;
    flex-wrap: wrap;
    border:2px solid var(--Azul);
    border-style: dashed;
    padding: .75rem .5rem .75rem .5rem;
    gap:.5rem .75rem;

    img{
        height: 3.25rem;
        width: 3.25rem;
        border-radius: 50%;
        border: 1px solid var(--AzulOpaco);
        &:hover{
            transition: all .2s ease;
            border: 2px solid var(--Azul);
            cursor: pointer; 
        }
    }
}

overflow: auto;
max-height:60vh;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { FiSave } from 'react-icons/fi'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { FindUser } from '../../../../../components/FindBox'
import { InputCmp } from '../../../../../components/InputCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { iNomeId, iPostAvaliador, iCardAvaliador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { Body, Footer } from './styles'

interface iModalprops {
  isOpen: boolean
  iniciada: boolean
  avaliador?: iCardAvaliador
  avaliacaoId?: string
  onRequestClose: () => void
  onConfirm: (form: iPostAvaliador) => void
  onDeleteAvaliador: (avaliacaoId: string, avaliador: iCardAvaliador) => void
  onReenviarAvaliador: (avaliacaoId: string, avaliador: iCardAvaliador) => void
  onUpdateAvaliador: (form: iPostAvaliador) => void
}

export const ModalAddParticipante: React.FC<iModalprops> = ({ iniciada, isOpen, avaliador, avaliacaoId, onConfirm, onRequestClose, onReenviarAvaliador, onDeleteAvaliador, onUpdateAvaliador }) => {
  const [DoSistema, setDoSistema] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState<string>()
  const [Email, setEmaill] = useState('')
  const [NomeColaborador, setNomeColaborador] = useState('')
  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
    if (id === '') {
      setEmaill('')
    }
  }

  function ExtrairValorInput(texto: string): void {
    setNomeColaborador(texto)
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  useEffect(() => {
    if (Colaboradores.some(e => e.email === avaliador?.email)) {
      const col = Colaboradores.find(t => t.email === avaliador?.email)
      setDoSistema(true)
      SelecionarGestor(col?.id ?? '')
    } else {
      setNomeColaborador(avaliador?.nome ?? '')
      setEmaill(avaliador?.email ?? '')
    }
  }, [avaliador, Colaboradores])

  useEffect(() => {
    if (Colaboradores.some(e => e.id === Selecionado)) {
      const col = Colaboradores.find(t => t.id === Selecionado)
      setEmaill(col?.email ?? '')
      console.log(col)
    }
  }, [Selecionado])
  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onRequestClose}
      Titulo={avaliador ? 'Editar participante' : 'Adicione um participante'}
      Subtitulo='Caso o usuário já esteja cadastrado no sistema, preencha o campo nome, caso não, preencha o campo de nome e email'
    >
      <Body>
        <Flex flexDir={'column'} gap={'.125rem'}>
          <Text fontWeight={'500'} fontSize={'.875rem'} color={'var(--Gray1)'}>Nome do colaborador</Text>
          {(PropInvalida(avaliador) || DoSistema) &&
            <FindUser
              lista={Colaboradores}
              isDisabled={!PropInvalida(avaliador)}
              onChoice={SelecionarGestor}
              placeholder='Digite o nome do participante'
              selecionado={Selecionado}
              _onChange={ExtrairValorInput}
            />
          }

          {(!PropInvalida(avaliador) && !DoSistema) &&
            <InputCmp
              value={NomeColaborador}
              placeholder='Digite o nome do participante'
              OnChange={setNomeColaborador}
            />
          }
        </Flex>
        <Flex marginTop={'.5rem'} flexDir={'column'}>
          <InputCmp
            label='Email'
            isDisabled={!!Selecionado}
            value={Email}
            OnChange={setEmaill}
          />
        </Flex>
      </Body>
      <Footer>
        {!avaliador && (
          <ButtonCmp
            VarColor='Green2'
            onClick={() => onConfirm({
              colaboradorId: Selecionado === '' ? undefined : Selecionado,
              email: Email,
              nome: NomeColaborador,
              TipoAvaliador: 0
            })}
          >{iniciada ? 'Adicionar e enviar' : 'Adicionar'}</ButtonCmp>
        )}
        {avaliador && (
          <Flex gap={'.5rem'}>
            <ButtonCmp
              VarColor={'Red'}
              leftIcon={<FaTrash />}
              onClick={() => onDeleteAvaliador(avaliacaoId ?? '', avaliador)}
            >Excluir</ButtonCmp>

            <ElementoTooltipCmp
              label={!iniciada ? 'Pesquisa ainda não possui disparo programado' : ''}
              bg={'var(--Rosa)'}
            >
              <ButtonCmp
                VarColor={'Rosa'}
                isDisabled={!iniciada || avaliador.respondida}
                leftIcon={<AiOutlineMail />}
                onClick={() => onReenviarAvaliador(avaliacaoId ?? '', avaliador)}
              >Reenviar</ButtonCmp>
            </ElementoTooltipCmp>

            <ElementoTooltipCmp
              label={DoSistema ? 'Você não pode editar informações de um colaborador do sistema por aqui!' : ''}
            >
              <ButtonCmp
                  VarColor='Green2'
                  isDisabled={DoSistema}
                  leftIcon={<FiSave />}
                  onClick={() =>
                    onUpdateAvaliador({
                      email: Email,
                      nome: NomeColaborador,
                      TipoAvaliador: 0
                    })}
              >Salvar</ButtonCmp>
            </ElementoTooltipCmp>
          </Flex>
        )}
      </Footer>
    </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex, Text, useCallbackRef } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { InputFile, Label } from './styles'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './cropImage'
import { appApi } from '../../../../services/appApi'
import { ModalBase } from '../../../ModalBase'
import { Button } from '../../../Button'

interface iCrop {
  height: number
  width: number
  x: number
  y: number
}

interface iModalProps {
  isOpen: boolean
  onConfirm: (model: FormData) => void
  onRequestClose: () => void
}

export const ModalCropColaborador: React.FC<iModalProps> = ({ isOpen, onRequestClose, onConfirm }) => {
  const [Model, setModel] = useState<iCrop>({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  })

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [Rotacao, setRotacao] = useState(0)
  const onCropComplete = useCallbackRef((croppedArea, croppedAreaPixels) => {
    setModel(croppedAreaPixels)
  }, [])

  const [Url, setUrl] = useState('')
  const [Nome, setNome] = useState('')

  const [Base64, setBase64] = useState<string>()

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])
    appApi.post('UploadFile', formdata).then(resposnse => {
      setUrl(resposnse.data.uri)
      setNome(resposnse.data.name)
    }).catch(e => console.log(e))
  }

  function getBase64(): void {
    getCroppedImg(Url ?? '', Model, Rotacao)
      .then(res => setBase64(res))
      .catch(err => console.log(err))
  }

  function Salvar(): void {
    const file = DataURIToBlob(Base64 ?? '')
    const formData = new FormData()
    formData.append('request', file, Nome)

    onConfirm(formData)
  }

  function DataURIToBlob(dataURI: string): any {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].includes('base64') ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i) }

    return new Blob([ia], { type: mimeString })
  }

  useEffect(() => {
    if (Base64) {
      Salvar()
    }
  }, [Base64])

  return (
    <ModalBase
      Titulo='Trocar foto de perfil'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='50rem'
    >
      <Flex flexDir={'column'}>
        <Flex position={'relative'} height={'20rem'}>
          {Url === '' &&
            <Flex alignItems={'center'} width='100%' justifyContent={'center'}>
              <Label htmlFor='inp'>Clique aqui para selecionar imagem</Label>
              <InputFile
                id='inp'
                type={'file'}
                onChange={(e) => { AtualizarArquivo(e) }}
              />
            </Flex>
          }
          {Url && Url.length > 3 &&
            <Flex flexDir={'column'}>
              <Flex zIndex={100}>
                <Cropper
                  image={Url}
                  crop={crop}
                  zoom={zoom}
                  rotation={Rotacao}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape={'round'}
                />
              </Flex>
            </Flex>
          }
        </Flex>
        <Flex flexDir={'column'} padding={'1rem'} alignItems='center' justifyContent='center'>
          {Url && Url.length > 3 &&
            <Flex marginBottom={'1rem'} flexDir={'column'} gap='1rem'>
              <Flex alignItems={'center'} gap='.5rem'>
                <Text>Zoom</Text>
                <input
                  type={'range'}
                  value={zoom}
                  min={1}
                  max={30}
                  step={0.01}
                  onChange={(e) => setZoom(parseInt(e.target.value))}
                  aria-labelledby="Zoom"
                />
              </Flex>
              <Flex alignItems={'center'} gap='.5rem'>
                <Text>Rotação</Text>
                <input
                  aria-labelledby="Rotation"
                  step={1}
                  min={'0'}
                  max='360'
                  type={'range'}
                  onChange={(e) => setRotacao(parseInt(e.target.value))}
                />
              </Flex>
            </Flex>
          }
          <Flex gap={'3rem'}>
            <Button onClick={onRequestClose} VarColor='Gray4'>Cancelar</Button>
            {Url && <Button onClick={getBase64} VarColor='Rosa'>Salvar imagem</Button>}
          </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/avatar'
import { Flex, Stack } from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { iQuestao, iRespostaXColaborador } from '../..'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'

interface iProps {
  variant: 'editar' | 'visualizar'
  questao: iQuestao
  respostas: iRespostaXColaborador[]
  selecionada?: string
  Selelecionar: (questaoId: string, alternativaId: string) => void
}

export const QuestaoAlternativa: React.FC<iProps> = ({ variant, questao, respostas, selecionada, Selelecionar }) => {
  if (variant === 'editar') {
    return (
      <RadioGroup
        ml={'1rem'}
        my={'.8rem'}
        display={'flex'}
        justifyContent={'start'}
        onChange={(e) => Selelecionar(questao.id, e)}
        value={selecionada}
      >
        <Stack direction={'column'}>
          {questao.alternativas?.map((alternativa, i2) => {
            return (
              <Radio
                key={i2}
                value={alternativa.id}
              >{alternativa.texto}</Radio>
            )
          })}
        </Stack>
      </RadioGroup>
    )
  } else {
    return (
      <Stack direction={'column'}>
        {questao.alternativas?.filter((a) => {
          if (!respostas.some(r => r.respostas.some(t => t.alternativas?.includes(a.id)))) {
            return null
          }
          return a
        })?.map((alternativa, i2) => {
          const respostasFiltradas = respostas.filter(r => r.respostas.some(t => t.alternativas?.includes(alternativa.id)))
          return (
            <Flex key={i2} gap='.4rem'>
              <AvatarGroup w={'2.2rem'} spacing={'-10px'}>
                {respostasFiltradas.map((r, i) => {
                  return (
                      <Avatar
                        src={r.avatar}
                        key={i}
                        name={r.colaboradorId}
                        size={'xs'}
                      >
                        <ElementoTooltip
                          label={r.nome}
                          position={'absolute'}
                          right={'12px'}
                          bottom={'12px'}
                        >
                          <AvatarBadge
                            boxSize='1.8rem'
                            position={'absolute'}
                            right={'4px'}
                            bottom={'4px'}
                            opacity={0}
                          />
                        </ElementoTooltip>
                      </Avatar>
                  )
                })}
              </AvatarGroup>
              <Radio isChecked value={alternativa.id}>{alternativa.texto}</Radio>
            </Flex>
          )
        })}
      </Stack>
    )
  }
}

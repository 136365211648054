import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { RadarChartJs } from './components/RadarChart'
import { Tabela } from './components/Tabela'
import { useEffect, useState } from 'react'
import { ChartPercentual } from './components/ChartPercentual'
import { StackBarChart } from './components/StackChart'
import { iAvatarXInformacoes, iEscala, iGrafico, iNomeId } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Feedback } from './components/Feedback'
import { iQuestao, iRespostaXColaborador } from '../../Jornada/Feedback/VisualizarRespostas'
import { useLocation, useNavigate } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './components/RelatorioPDF'
import { ColumnChart } from './components/ColumnChart'
import { FaSearch, FaFilter, FaCalendarCheck, FaClipboardList, FaPeopleArrows, FaStreetView, FaMedal, FaTools, FaHeart, FaFrown, FaPencilAlt, FaChartBar, FaCheck } from 'react-icons/fa'
import { iRecomendacoes } from '../../PesquisaIndicativa/ResultadoIndividualIndicativa'
import { DualColabs } from './components/DualColabs'
import { StatusFeedback } from './components/StatusFeedback'
import { InfoBox, TabelaIndividual } from './styles'
import { Legenda } from '../../Jornada/ReuniaoOne/Configuracao/styles'
import { formatarData } from '../../../Utils/Helper'

export interface iLinhaTabela {
  competencia: string
  autoAvaliacao?: string
  gestor?: string
  pares?: string
  gestores?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  consolidado?: string
  mediaSemAuto?: string
}

export interface iFeedbackRespostaXColaborador {
  id: string
  respostas: iRespostaXColaborador[]
}

export interface iFeedback {
  questoes: iQuestao[]
  respostas: iFeedbackRespostaXColaborador[]
}

export interface iCompetenciaPDF {
  id: string
  avatar: string
  nome: string
  pergunta: string
  descricao: string
}

interface iLista {
  id: string
  feedback?: iFeedbackResponse
  pesquisa90?: iPesquisa90Response
  pesquisa360?: iPesquisa360Response
  reuniao?: iReuniaoResponse
  responsavel?: iAvatarXInformacoes
  colaborador?: iAvatarXInformacoes
  status: number
}

interface iFeedbackResponse {
  feedbackId: string
  name: string
  responder: boolean
  visualizar: boolean
  dataInicio: Date
  responsavel: iAvatarXInformacoes
  colaborador: iAvatarXInformacoes
}

interface iPesquisa90Response {
  pesquisaId: string
  mediaGeral: number
  name: string
  liberarResultados: boolean
  responsavel: iAvatarXInformacoes
  colaborador: iAvatarXInformacoes
}

interface iPesquisa360Response {
  pesquisaId: string
  name: string
  mediaGeral?: number
  isIndicativa: boolean
  liberarResultados: boolean
  responsavel: iAvatarXInformacoes
  colaborador: iAvatarXInformacoes
  tabelaRecomendacoes?: iRecomendacoes
}

interface iReuniaoResponse {
  reuniaoId: string
  Status: number
  isResponsavel: boolean
}

interface iModel {
  tabela: iLinhaTabela[]
  escala: iEscala
  graficoRadar: iGrafico
  graficoCompetencia?: iGrafico
  resumoCompetenciaAutoavaliacao?: iGrafico
  resumoCompetenciaGestor?: iGrafico
  graficoPercentualGestor: iGrafico
  graficoPercentualAutoavaliacao: iGrafico
  feedback?: iFeedback
  competencias: iCompetenciaPDF[]
}

export const DashboardFluxoPersonalizado: React.FC = () => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Options, setOptions] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iModel>()
  const [Lista, setLista] = useState<iLista[]>([])
  const [Id, setId] = useState<string>('')
  const [Busca, setBusca] = useState('')
  const [IsLoading, setIsLoading] = useState(false)

  function getDashboard(id: string): void {
    setId(id)
    if (id) {
      setIsLoading(true)
      appApi.get(`FluxoPersonalizado/${id}/Dashboard`)
        .then(res => {
          setModel(res.data)
        })
        .catch(err => {
          setModel(undefined)
          console.log(err)
        })
      appApi.get(`FluxoPersonalizado/Lista?fluxoId=${id}&busca=${Busca}`)
        .then(res => {
          setLista(res.data)
          setIsLoading(false)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
          setLista([])
        })
    } else {
      setModel(undefined)
    }
  }

  function getLista(): void {
    setIsLoading(true)
    appApi.get(`FluxoPersonalizado/Lista?fluxoId=${Id}&busca=${Busca}`)
      .then(res => {
        setLista(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function getOptions(): void {
    appApi.get('FluxoPersonalizado/Select')
      .then(res => {
        if (res.status === 200) {
          setOptions(res.data)
        } else {
          setOptions([])
        }
      })
      .catch(err => console.log(err))
  }

  function ObterBoxStatus(item: iLista): any {
    const status = item.status
    if (status === 0) {
      return (
      <StatusFeedback
        Texto='Aguardando reunião
        de alinhamento'
        hasAction={item.reuniao?.isResponsavel}
        Icon={<FaCalendarCheck size={42}/>}
        onClick={() => { if (item.reuniao?.isResponsavel) { nav(`/Jornada/NovaReuniaoOne/Visualizar/${item.reuniao?.reuniaoId}`) } }}
      />)
    } else if (status === 1) {
      return (
        <StatusFeedback
          Texto='Feedback discursivo'
          Icon={<FaClipboardList size={42}/>}
          onClick={() => {}}
        />)
    } else if (status === 2) {
      return (
        <StatusFeedback
          BoldText='90°'
          Texto=''
          Icon={<FaPeopleArrows size={42}/>}
          onClick={() => {}}
        />)
    } else if (status === 3) {
      return (
        <StatusFeedback
          BoldText='360°'
          Texto={item.pesquisa360?.isIndicativa ? 'Indicativa' : 'Quantitativa'}
          Icon={<FaStreetView size={42}/>}
          onClick={() => {}}
        />)
    }

    return <StatusFeedback
      Texto='Fluxo finalizado'
      color='var(--Green1)'
      Icon={<FaCheck color={'var(--Green1)'}/>}
      onClick={() => {}}
    />
  }

  useEffect(() => {
    getOptions()
  }, [])

  useEffect(() => {
    if (Options.length > 0) {
      getDashboard(Options[0].id)
    }
  }, [Options])

  return (
    <Body isLoading={IsLoading}>
      <WhiteBox>
        <Flex mb={'1rem'} justifyContent='space-between'>
          <PDFDownloadLink
            document={
              <RelatorioPDF
                model={{
                  feedback: Model?.feedback,
                  tabela: Model?.tabela ?? [],
                  graficoCompetencia: Model?.graficoCompetencia,
                  graficoPercentualAutoavaliacao: Model?.graficoPercentualAutoavaliacao,
                  graficoPercentualGestor: Model?.graficoPercentualGestor,
                  resumoCompetenciaAutoavaliacao: Model?.resumoCompetenciaAutoavaliacao,
                  resumoCompetenciaGestor: Model?.resumoCompetenciaGestor,
                  graficoRadar: Model?.graficoRadar,
                  escala: Model?.escala ?? { fator: 0, id: '', nome: '', respostas: [] }
                }}
              />
            }
            fileName={'Relatorio_geral.pdf'}
          >
            {({ loading }) =>
              (loading)
                ? (
                  <Button size={'lg'} VarColor='Green2' isLoading>Baixar relatório</Button>
                  )
                : <Button size={'lg'} VarColor='Green2'>Baixar relatório</Button>

            }
          </PDFDownloadLink>
          <Button
            onClick={() => nav(pathname.replace('Dashboard', ''))}
            VarColor='Green2'
            size={'lg'}
          >Criar um novo feedback</Button>
        </Flex>
        <Flex flexDir={'column'} gap='1rem'>
          <FormControl>
            <FormLabel>Selecione um fluxo</FormLabel>
            <Select value={Id} onChange={(e) => getDashboard(e.target.value)}>
              <option value={''}>Nenhum</option>
              {Options.map((e, i) => {
                return (
                  <option key={i} value={e.id}>{e.nome}</option>
                )
              })}
            </Select>
          </FormControl>

          {Model && <Tabs variant='soft-rounded'>
            <TabList>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico geral</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Tabela</Tab>
              {Model?.graficoCompetencia && <Tab backgroundColor={'white'} color={'var(--c6)'}>Competências</Tab>}
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Percentual de respostas</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Resumo das competências</Tab>
              {Model?.feedback && <Tab backgroundColor={'white'} color={'var(--c6)'}>Feedback</Tab>}
            </TabList>
            <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
              {Model?.graficoRadar &&
                <TabPanel>
                  <Flex flexDir={'column'}>
                    <Flex justifyContent={'center'} width='100%'>
                      <Flex width={'60%'}>
                        <RadarChartJs
                          model={Model.graficoRadar}
                          stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </TabPanel>
              }

              {Model?.tabela &&
                <TabPanel>
                  <Tabela model={Model.tabela} />
                </TabPanel>
              }

              {Model?.graficoCompetencia &&
                <TabPanel>
                  <ColumnChart
                    id='11111'
                    grafico={Model.graficoCompetencia}
                  />
                </TabPanel>
              }

              {Model?.graficoPercentualAutoavaliacao && Model.graficoPercentualGestor && Model.escala &&
                <TabPanel>
                  <Flex flexDir={'column'} gap='2rem'>
                    <ChartPercentual
                      id='asdas'
                      grafico={Model.graficoPercentualAutoavaliacao}
                    />

                    <Divider />

                    <ChartPercentual
                      id='22'
                      grafico={Model.graficoPercentualGestor}
                    />
                  </Flex>
                </TabPanel>
              }

              {Model?.graficoPercentualAutoavaliacao && Model.graficoPercentualGestor &&
                <TabPanel>
                  <Flex flexDir={'column'} gap='2rem'>
                    {Model.resumoCompetenciaAutoavaliacao &&
                      <StackBarChart
                        id='1'
                        data={Model.resumoCompetenciaAutoavaliacao}
                        titulo='Resumo das competências (Autoavaliação)'
                      />
                    }
                    <Divider />

                    {Model.resumoCompetenciaGestor &&
                      <StackBarChart
                        id='2'
                        data={Model.resumoCompetenciaGestor}
                        titulo='Resumo das competências (Gestor)'
                      />
                    }
                  </Flex>
                </TabPanel>
              }

              {Model?.feedback &&
                <TabPanel>
                  <Feedback model={Model?.feedback ?? { questoes: [], respostas: [] }} />
                </TabPanel>
              }
            </TabPanels>
          </Tabs>}
        </Flex>
        <Box mt={'1rem'}>
          <h2>Lista de feedbacks</h2>
          <Flex margin={'2rem 0'} justifyContent={'space-between'} alignItems={'end'}>
            <Flex gap={'1.5rem'} alignItems={'end'} flex={'1'}>
              <Flex w={'44rem'} gap={'.75rem'} justifyContent={'end'}>
                <InputGroup width={'44rem'}>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<FaSearch color='var(--Gray4)' />}
                  />
                  <Input
                    onChange={(e) => {
                      setBusca(e.target.value)
                    }}
                    value={Busca}
                    borderColor={'var(--Gray4)'}
                    type='text'
                    placeholder='Pesquisar por nome do convidado ou responsável'
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        getLista()
                      }
                    }}
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex justifyContent='end' alignItems='center'>
              <Button VarColor='a1' rightIcon={<FaFilter />} onClick={() => { getLista() }}>Filtrar</Button>
            </Flex>
          </Flex>
          <Flex flexDir={'column'} gap={'1rem'}>
            {Lista.length > 0 && Lista?.map((e, i) => {
              return (
                <Flex key={i} flexDir={'column'} p={'1rem'} border={'1px solid var(--Gray5)'} borderRadius={'.5rem'}>
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Flex flexDir={'column'} gap={'1rem'}>
                      <h3>{e.pesquisa360?.name}</h3>
                      {(e.responsavel && e.colaborador) && <DualColabs colab1={e.responsavel} colab2={e.colaborador}/>}
                    </Flex>
                    {ObterBoxStatus(e)}
                  </Flex>
                  <Accordion allowToggle>
                    <AccordionItem>
                      <AccordionButton _hover={{ bg: 'none' }} _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} cursor={'default'} borderRadius={'1.25rem'} marginBottom={'.25rem'} px={'1rem'} display={'flex'} justifyContent={'end'}>
                        <Flex cursor={'pointer'} as='span' textAlign={'left'} fontSize={'0.875rem'} alignItems={'center'} gap={'.2rem'}>
                          Detalhes
                          <AccordionIcon />
                        </Flex>
                      </AccordionButton>
                      <AccordionPanel gap={'1rem'}>
                        <Flex flexDir={'column'} gap={'2rem'}>
                          {e.pesquisa360?.isIndicativa && e.pesquisa360?.tabelaRecomendacoes !== null && <TabelaIndividual>
                            <thead style={{ height: '5rem' }}>
                              <tr>
                                <th>Resumo dos Resultados da pesquisa 360°</th>
                                <th>Competência</th>
                                <th>Gestor</th>
                                <th>Autoavaliação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.pesquisa360?.tabelaRecomendacoes?.melhorias?.map((a, i) => {
                                return (
                                  <tr key={i}>
                                    {i === 0 ? <td rowSpan={e.pesquisa360?.tabelaRecomendacoes?.melhorias.length}>Competências a serem trabalhadas</td> : <></>}
                                    <td>{a.nome}</td>
                                    <td>{a.selosStake.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                    <td>{a.selos.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                  </tr>
                                )
                              })}
                              {e.pesquisa360?.tabelaRecomendacoes?.outros?.map((a, i) => {
                                return (
                                  <tr key={i}>
                                    {i === 0 ? <td rowSpan={e.pesquisa360?.tabelaRecomendacoes?.outros.length}>Outras oportunidades de melhoria segundo os stakeholders</td> : <></>}
                                    <td>{a.nome}</td>
                                    <td>{a.selosStake.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                    <td>{a.selos.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                  </tr>
                                )
                              })}
                              {e.pesquisa360?.tabelaRecomendacoes?.destaques?.map((a, i) => {
                                return (
                                  <tr key={i}>
                                    {i === 0 ? <td rowSpan={e.pesquisa360?.tabelaRecomendacoes?.destaques.length}>Destaques positivos segundo stakeholders</td> : <></>}
                                    <td>{a.nome}</td>
                                    <td>{a.selosStake.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                    <td>{a.selos.map((a, i2) => {
                                      if (a === 4) {
                                        return (<FaMedal key={i2} style={{ color: 'var(--Green2)' }} />)
                                      } else if (a === 1) {
                                        return (<FaTools key={i2} style={{ color: 'var(--Orange)' }} />)
                                      } else if (a === 2) {
                                        return (<FaHeart key={i2} style={{ color: 'var(--Azul)' }} />)
                                      } else if (a === 3) {
                                        return (<FaFrown key={i2} style={{ color: 'var(--Roxo)' }} />)
                                      } else {
                                        return null
                                      }
                                    })}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </TabelaIndividual>}
                          {!e.pesquisa360?.isIndicativa &&
                            <Flex flexDir={'column'} p={'1rem'} border={'1px solid var(--Gray5)'} borderRadius={'.5rem'}>
                              <Flex justifyContent={'space-between'} alignItems={'start'}>
                                <Flex flexDir={'column'} gap={'1.25rem'}>
                                  <h3>{e.pesquisa360?.name}</h3>
                                  <DualColabs colab1={e.pesquisa360?.responsavel} colab2={e.pesquisa360?.colaborador}/>
                                </Flex>
                                {e.pesquisa360?.mediaGeral !== null &&
                                <InfoBox style={{ flexDirection: 'column' }}>
                                  <span>Média geral</span>
                                  <h3>{e.pesquisa360?.mediaGeral?.toFixed(2)}</h3>
                                </InfoBox>}
                                {e.pesquisa360?.mediaGeral === null &&
                                <StatusFeedback
                                  BoldText=''
                                  Texto='Pesquisa pendente'
                                  onClick={() => {}}
                                />}
                              </Flex>
                              {e.pesquisa360?.mediaGeral !== null &&
                                <Button mt={'-1rem'} VarColor='Green2' w={'10rem'} rightIcon={<FaChartBar />} onClick={() => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/ResultadoGeral/${e.pesquisa90?.pesquisaId}`)}>Resultados</Button>
                              }
                            </Flex>
                          }
                          {e.feedback && <Flex flexDir={'column'} p={'1rem'} border={'1px solid var(--Gray5)'} borderRadius={'.5rem'}>
                            <Flex justifyContent={'space-between'} alignItems={'start'}>
                              <Flex flexDir={'column'} gap={'1.25rem'}>
                                <h3>{e.feedback?.name}</h3>
                                <DualColabs colab1={e.feedback?.responsavel} colab2={e.feedback?.colaborador}/>
                              </Flex>
                              {(e.status === 1 && e.feedback?.responder) && <Button VarColor='Rosa' rightIcon={<FaPencilAlt />} onClick={() => nav(`/Feedback/Abertura/${e.feedback?.feedbackId}`)}>Responder formulário</Button>}
                              {(e.status !== 1 && e.feedback?.responder) &&
                              <StatusFeedback
                                BoldText=''
                                Texto='Feedback pendente'
                                onClick={() => {}}
                              />}
                              {(e.status !== 1 && !e.feedback?.responder && e.feedback?.visualizar) &&
                              <Button VarColor='Rosa' rightIcon={<FaPencilAlt />} onClick={() => nav(`/Jornada/Feedback/Visualizar/${e.feedback?.feedbackId}`)}>Visualizar feedback</Button>}
                            </Flex>
                            <Legenda>
                              {e.feedback?.dataInicio && formatarData(e.feedback?.dataInicio?.toString() ?? '')}
                            </Legenda>
                          </Flex>}
                          {e.pesquisa90 &&
                          <Flex flexDir={'column'} p={'1rem'} border={'1px solid var(--Gray5)'} borderRadius={'.5rem'}>
                            <Flex justifyContent={'space-between'} alignItems={'start'}>
                              <Flex flexDir={'column'} gap={'1.25rem'}>
                                <h3>{e.pesquisa90?.name}</h3>
                                <DualColabs colab1={e.pesquisa90?.responsavel} colab2={e.pesquisa90?.colaborador}/>
                              </Flex>
                              {e.pesquisa90?.mediaGeral !== null &&
                              <InfoBox style={{ flexDirection: 'column' }}>
                                <span>Média geral</span>
                                <h3>{e.pesquisa90?.mediaGeral.toFixed(2)}</h3>
                              </InfoBox>}
                              {e.pesquisa90?.mediaGeral === null &&
                              <StatusFeedback
                                BoldText=''
                                Texto='Pesquisa pendente'
                                onClick={() => {}}
                              />}
                            </Flex>
                            {e.pesquisa90?.mediaGeral !== null &&
                              <Button mt={'-1rem'} VarColor='Green2' w={'10rem'} rightIcon={<FaChartBar />} onClick={() => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/ResultadoGeral/${e.pesquisa90?.pesquisaId}`)}>Resultados</Button>
                            }
                          </Flex>}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      </WhiteBox>
    </Body>
  )
}

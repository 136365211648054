import { ModalBody, Container, ModalFooter, ModalHeader } from './styles'
import { MdClose } from 'react-icons/md'
import { iModalPadrao } from '../../../../../interfaces'
import { useEffect, useState } from 'react'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import Modal from 'react-modal'
import { InputCmp } from '../../../../../components/InputCmp'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface IModal extends iModalPadrao {
  competenciaId: string
  duplicar: boolean
  questaoId?: string
}

export const ModalQuestao: React.FC<IModal> = ({ OnCloseModal, isOpen, competenciaId, questaoId, duplicar }) => {
  if (!isOpen) {
    return null
  }
  const { CriarQuestao, AtualizarQuestao, ctxCompetencias } = useFormulario()
  const [Texto, setTexto] = useState('')
  const [ITipo, setITipo] = useState(0)

  function GetQuestao(): void {
    const selecao = ctxCompetencias.find(e => e.id === competenciaId)
    if (selecao !== undefined) {
      const questao = selecao.questoes.find(r => r.id === questaoId)
      if (questao !== undefined) {
        setITipo(questao.tipo)
        setTexto(questao.enunciado)
      }
    }
  }

  function isDiscursiva(): boolean {
    const selecao = ctxCompetencias.find(e => e.id === competenciaId)
    if (selecao !== undefined) {
      return selecao.discursiva
    }
    return false
  }

  useEffect(() => {
    if (questaoId !== undefined) { GetQuestao() }
    setITipo(isDiscursiva() ? 1 : 0)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={OnCloseModal}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Container>
        <ModalHeader>
          <h3>{questaoId === undefined ? 'Criar' : 'Editar'} Questão</h3>
          <MdClose
            onClick={() => OnCloseModal()}
            cursor={'pointer'}
            size={18}
          />
        </ModalHeader>

        <ModalBody>
          <InputCmp
            placeholder='Digite uma questão aqui'
            OnChange={setTexto}
            value={Texto}
          />
        </ModalBody>

        <ModalFooter>
          {
            (questaoId === undefined && !duplicar) && (
              <ButtonCmp
                VarColor='Green2'
                onClick={() => { CriarQuestao(competenciaId, Texto, ITipo); OnCloseModal() }}
              >Criar questão</ButtonCmp>
            )
          }

          {
            (questaoId !== undefined && !duplicar) && (
              <ButtonCmp
              VarColor='Green2'
              onClick={() => { AtualizarQuestao(competenciaId, questaoId, Texto); OnCloseModal() }}
              >Editar questão</ButtonCmp>
            )
          }

          {
            (questaoId !== undefined && duplicar) && (
              <ButtonCmp
                VarColor='Green2'
                onClick={() => { CriarQuestao(competenciaId, Texto, ITipo); OnCloseModal() }}
              >Duplicar questão</ButtonCmp>
            )
          }
        </ModalFooter>
      </Container>
    </Modal>
  )
}

import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
width: 592px;
text-align: center;
align-items: center;
h2{
    color:var(--a4);
    font-size: 1.5rem;
    line-height: 2.5rem;
}
`

export const TextoDescricao = styled.span`
  font-weight: 400;
  color: var(--a4);
  font-size: 20px;
  width:15rem;
`

import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.nav`
  background: var(--c1);
  border-radius: 0.25rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--SombraBackground);
`

export const LeftContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  align-items: center;

  img {
    height: 5rem;
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  gap: .75rem;
  justify-content: end;
`

export const NavButton = styled(NavLink)`
  padding: .5rem .75rem;
  border: 1px solid var(--Azul);
  text-decoration: none;
  border-radius: .25rem;
  color: var(--Azul);

  display: flex;
  align-items: center;
  justify-content: end;

  :hover {
    color: var(--c1);
    background: var(--Azul);
  }

  span {
    display: block;
    font-size: .875rem;
    font-weight: 600;
    transition: filter 0.2s;
  }
`

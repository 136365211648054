import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

export const FlexIcon = styled.div`
display: flex;
align-items: center;
margin-bottom: .25rem;
label{
    margin-right: .5rem;
}

svg{
    cursor: pointer;
}
`

export const CardColor = styled.div`
    height: 20rem;
    min-width: 14rem;
    max-width: 14rem;
    background: #${props => props.theme};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    padding: .5rem;
    font-family: 'Poppins','sans-serif';
    small{
        width: 8.813rem;
        word-break: break-word;
        font-size: 1.125rem;
        text-align: center;
    }
`

export const PaletaDeCor = styled(Column)`
margin-top: 1rem;

div{
    display: flex;
    flex-wrap: wrap;
}

`

export const ColorBox = styled.div`
background: #${props => props.theme};
height: 1.95rem;
width: 1.95rem;
margin-right: 0.4rem;
margin-bottom: 0.5rem;
cursor: pointer;
border-radius: 50%;
`

export const InputColor = styled.div`
div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--c3);
    padding: 0rem .5rem 0rem .5rem;
    color: var(--c6);
    height: 1.95rem;
    font-size: 1rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

input{
    height: 2rem;
    padding-left: 0.6rem;
    border-top-right-radius: 0.25;
    border-bottom-right-radius: 0.25;
    border-bottom-left-radius:0rem;
    border-top-left-radius: 0rem;
    margin-bottom: .5rem;
    width: 8rem;
    box-sizing: border-box;
    outline: none;
    border: 0.063rem solid var(--c2);
    padding: 0.5rem;
    font-size: .875rem;
    background-color: var(--c2);
    
    ::placeholder {
          opacity: 70%;  
    }

    &:focus {
      border: 0.063rem solid var(--a1);
    }
}
`

export const TagBox = styled(Column)`
margin-top: .5rem;
& > div{
display: flex;
flex-wrap: wrap;
}
`

import { Flex } from '@chakra-ui/layout'
import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi2'
import { FiEdit } from 'react-icons/fi'

import { Container } from './styles'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface IDepartment {
  id: string
  nome: string
  departamentoPai?: IDepartment
  qtdColaboradores?: number
}

interface IDepartmentCardProps {
  department: IDepartment
  onEdit: (department: IDepartment) => void
  onDelete: (department: IDepartment) => void
}

export const DepartmentCard: React.FC<IDepartmentCardProps> = ({
  department,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <Flex justifyContent={'space-between'} mt={'.25rem'}>
        <Flex flexDir={'column'} gap={'.25rem'}>
          <strong>{department.nome}</strong>
          <span>{department.departamentoPai?.nome}</span>
        </Flex>
        <Flex alignItems={'center'} gap={'.5rem'} w={'10rem'} justifyContent={'end'}>
          <ButtonCmp small OutlineColor='Rosa' onClick={() => onEdit(department)}>
            <FiEdit />
          </ButtonCmp>

          <ButtonCmp small OutlineColor='Red' onClick={() => onDelete(department)}>
            <FaTrashAlt />
          </ButtonCmp>
        </Flex>
      </Flex>
      {department.qtdColaboradores !== undefined &&
          <Flex gap={'.325rem'} bg={'var(--Azul)'} color={'var(--c1)'} pos={'absolute'} top={'0'} left={'50%'} transform={'translate(-50%, -0%)'} p={'.325rem 1.5rem'} alignItems={'center'} borderRadius={'0 0 .5rem .5rem'}>
            <HiUserGroup size={14}/>
            <strong style={{ fontSize: '.813rem', fontWeight: 900 }}>{department.qtdColaboradores}</strong>
          </Flex>
        }
    </Container>
  )
}

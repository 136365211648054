import styled from 'styled-components'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: .875rem;
padding: 1rem 1rem 0 1rem;

strong{
    color: var(--Azul);
    font-weight: 900;
}

small{
    color: var(--Red);
    font-weight: 500;
}

span{
    color:var(--a5);
}

label{
    background: var(--c2);
    border-radius: 4px;
    padding: .5rem;
    width: 100%;
    cursor: pointer;
    margin-top: 1rem;
}

input[type=file]{
    display: none;
}
`

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { iQuestao, iRespostaXColaborador } from '../../../../../../../../Jornada/Feedback/VisualizarRespostas'
import { GlobalStyles } from '../../styles'

interface iProps {
  questao: iQuestao
  resposta: iRespostaXColaborador
}

export const Discursiva: React.FC<iProps> = ({ questao, resposta }) => {
  const styles = StyleSheet.create({
    Container: {
      flexDirection: 'column',
      marginVertical: 0.8
    },
    Resposta: {
      marginVertical: 4,
      backgroundColor: '#F2F2F2',
      padding: 8
    },
    Data: {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 8
    }
  })
  const respostaBuscada = resposta.respostas.find(e => e.questaoId === questao.id)
  return (
    <View style={styles.Container}>
      <View style={styles.Resposta}>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
          <Image src={resposta.avatar} style={GlobalStyles.Avatar} />
          <Text style={styles.Data}>{respostaBuscada?.data}</Text>
        </View>
        <Text style={GlobalStyles.TextCheckBox}>{respostaBuscada?.comentario}</Text>
      </View>
    </View>
  )
}

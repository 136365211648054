
import { Box, Flex, Link, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../../services/appApi'
import { Container, HeaderContainer } from './styles'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { FormatarMensagemEditorDeTexto } from '../../../../Utils/Helper'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'

interface iAberturaPesquisa {
  nomePesquisa: string
  anonima: boolean
  msgAbertura?: string
  dataInicio?: string
  dataFim?: string
  status: number
}

type iParam = {
  paramId: string
}

export const AberturaPesquisaInterna: React.FC = () => {
  const param = useParams<iParam>()
  const { pathname, search } = useLocation()
  const nav = useNavigate()

  const [isAccepted, setIsAccepted] = useState<boolean>(false)
  const [isAcceptedAnonimo, setIsAcceptedAnonimo] = useState<boolean>(false)
  const [Pesquisa, setPesquisa] = useState<iAberturaPesquisa>()

  function getPesquisa(): void {
    appApi.get(`PesquisaInterna/${param.paramId ?? ''}/Abertura`)
      .then(res => setPesquisa(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPesquisa()
    console.log(Pesquisa?.msgAbertura)
  }, [])

  return (
        <Body>
            <WhiteContainer padding='0'>
                <HeaderContainer>
                    <h1>{Pesquisa?.nomePesquisa}</h1>
                    <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
                </HeaderContainer>
                <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
                <Container>
                    <div>
                        {(Pesquisa?.status === 0) && (
                            <>
                                <main>
                                    <section>
                                        <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Pesquisa.msgAbertura ?? '') }}></span>
                                    </section>
                                    <br />

                                    <p>Essa pesquisa {Pesquisa.anonima ? 'é anônima' : 'não é anônima'}!</p>
                                    <br />
                                    <p> Disponível de <strong>{Pesquisa?.dataInicio}{Pesquisa.dataFim && ` até ${Pesquisa.dataFim}`}.</strong>
                                    </p>
                                    <br />
                                    <p>
                                        Para iniciar o preenchimento da pesquisa, aceite os termos e clique no botão abaixo.
                                    </p>
                                    <br />
                                    <p>
                                        Ao clicar, você estará concordando com nossa política de
                                        privacidade, disponível nesse&nbsp;link:&nbsp;
                                        <Link
                                            href="https://sah.arquiteturahumana.com.br/Privacidade"
                                            isExternal
                                            fontWeight={'bold'}
                                            textDecoration={'underline'}
                                        > Política de privacidade
                                        </Link>
                                    </p>
                                </main>
                                <Flex marginTop={'1.5rem'} flexDir={'column'} gap={'.75rem'} alignItems={'center'}>
                                    <Stack>
                                        <CheckboxCmp
                                            OnChange={setIsAccepted}
                                        >Aceito os termos e política de privacidade</CheckboxCmp>
                                        {!Pesquisa.anonima &&
                                            <CheckboxCmp
                                                OnChange={setIsAcceptedAnonimo}
                                            >Estou ciente que a pesquisa não é anônima</CheckboxCmp>}
                                    </Stack>
                                    {(!pathname.includes('Previsualizar')) && (
                                        <ButtonCmp
                                            onClick={() => nav(`${pathname.replace('Abertura', 'Preenchimento')}${search}`)}
                                            VarColor='DegradeAzul'
                                            w={'20rem'}
                                            isDisabled={(!isAccepted || (!Pesquisa.anonima && !isAcceptedAnonimo))}
                                        >Responder pesquisa</ButtonCmp>
                                    )}
                                    {(pathname.includes('Previsualizar')) && (
                                        <ButtonCmp
                                            VarColor='c6'
                                            onClick={() => nav(-1)}
                                        >Voltar</ButtonCmp>
                                    )}
                                </Flex>
                            </>
                        )}

                        {(Pesquisa?.status === 1) && (/// Não iniciada
                            <main>
                                <section>
                                    <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Pesquisa.msgAbertura ?? '') }}></span>
                                    <br />
                                    <br />
                                </section>
                                <p>
                                    Essa pesquisa estará disponível em <strong>{Pesquisa.dataInicio}{Pesquisa.dataFim ? `e se encerrará até ${Pesquisa.dataFim}.` : '.'}</strong>
                                </p>
                                <p>
                                    A partir da data de início, você poderá responder neste mesmo
                                    link.
                                </p>
                                <p>Te vejo em breve</p>
                            </main>
                        )}

                        {Pesquisa?.status === 2 && (
                            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                                <h1>Pesquisa já encerrada</h1>
                            </Flex>
                        )}

                        {Pesquisa?.status === 3 && (
                            <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                                <h2>Obrigado pela participação!!</h2>
                                <h3>Você já respondeu esta pesquisa</h3>
                            </Flex>
                        )}
                    </div>
                </Container>
            </WhiteContainer>
        </Body>
  )
}

import { Flex, Text } from '@chakra-ui/react'
import { FaAt, FaMobile, FaQrcode } from 'react-icons/fa'
import { iConvite } from '../../../../../../interfaces'
import { CircleText } from '../CircleText'

interface iBoxProps {
  convites: iConvite[]
  linkAberto?: number
  tipo: 'email' | 'qrcode' | 'usuario'
}

export const BoxCount: React.FC<iBoxProps> = ({ convites, tipo, linkAberto, children }) => {
  return (
        <Flex width={'50%'} justifyContent='space-between' padding={'1rem'} flexDir={'column'} border={'1px solid var(--c5)'} borderRadius={'4px'} alignItems={'center'} maxHeight={'190px'} fontSize={'.875rem'}>
            <Flex marginBottom={'1rem'} justifyContent={'center'} alignItems={'center'} height='100%' gap={'.75rem'}>
                        <Flex color={'black'} flexDir={'column'} alignItems={'center'} maxHeight={'100px'}>
                    {
                        tipo === 'email'
                          ? <FaAt size={40} />
                          : tipo === 'qrcode'
                            ? <FaQrcode size={40} />
                            : <FaMobile size={40} />
                    }
                    <Text fontWeight={'bold'} style={{ marginTop: '.5rem' }}>{tipo === 'email' ? 'E-mail' : tipo === 'qrcode' ? 'Link aberto' : 'Usuários'}</Text>
                </Flex>
                <Flex marginLeft={'.8rem'} gap={'.3rem 0rem'} flexDirection={'column'}>
                    {
                        (tipo !== 'qrcode') && (
                            <>
                                <CircleText
                                    fontsize='14px'
                                    varColorCircle='Rosa'
                                    varColorText='Preto'
                                    titulo={convites.filter(e => e.status === 'Enviado').length}
                                >Convites enviados
                                </CircleText>

                                <CircleText
                                    fontsize='14px'
                                    varColorCircle='c4'
                                    varColorText='Preto'
                                    titulo={convites.filter(e => e.status === 'Não enviado').length}
                                >Convites a enviar
                                </CircleText>
                            </>
                        )

                    }

                    {
                        (tipo === 'email') && (
                            <CircleText
                                fontsize='14px'
                                varColorCircle='secundario5'
                                varColorText='Preto'
                                titulo={convites.filter(e => e.status === 'Erro').length}
                            >Convites com erro
                            </CircleText>
                        )
                    }

                    {
                        (tipo === 'qrcode') && (
                            <CircleText
                                fontsize='14px'
                                varColorCircle='Azul'
                                varColorText='Preto'
                                titulo={linkAberto ?? 0}
                            >Respostas
                            </CircleText>
                        )
                    }
                </Flex>
            </Flex>
            <Flex justifyContent={'center'} height='fit-content' alignItems={'end'}>
                {children}
            </Flex>
        </Flex>
  )
}

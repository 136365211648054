/* eslint-disable no-constant-condition */
import { CircularProgress, CircularProgressLabel, Flex, Grid, HStack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaCalendarAlt } from 'react-icons/fa'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import LoadingContainer from '../../../components/LoadingContainer'
import { appApi } from '../../../services/appApi'
import { UpdateElementByPosition } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { iIndicador, iPeriodo } from '../gestao'
import { CardParticipante } from './components/CardParticipante'
import { IndicadorChart } from './components/IndicadorChart'
import { Tabela } from './components/Tabela'

interface iPeriodoAprovado {
  indicadorId: string
  ano: number
  aprovado: boolean
}

export const IndicadoresAprovar: React.FC = () => {
  const toast = useToast()
  const [Indicadores, setIndicadores] = useState<iIndicador[]>([])
  const [IsLoadingId, setIsLoadingId] = useState<string>()
  const [Limit, setLimit] = useState(5)
  const [PeriodosAprovados, setPeriodosAprovados] = useState<iPeriodoAprovado[]>([])
  const [PeriodoSelecionado, setPeriodoSelecionado] = useState<Array<{ id: string, val: number }>>([])

  function formatarDataAno(dataString: string): string {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ]

    const [ano, mes] = dataString.split('-')

    const nomeMes = meses[parseInt(mes) - 1]

    return `${nomeMes} de ${ano}`
  }

  function OnAprovar(id: string): void {
    appApi.patch(`Indicador/${id}/Aprovar`, { periodos: PeriodosAprovados.filter(e => e.indicadorId === id) })
      .then(res => {
        toast({
          title: 'Indicador salvo com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        GetIndicadores()
      })
      .catch((err) => console.log(err))
  }

  function GetIndicadores(id?: string): void {
    setIsLoadingId(id)
    appApi.get('Indicador/Adm')
      .then(res => setIndicadores(res.data))
      .catch(err => console.log(err))
      .finally(() => { setTimeout(() => { setIsLoadingId(undefined) }, 500) })
  }

  function GetValorAcumulado(periodos: iPeriodo[], tipoMeta: number, meta?: number, metaInversa?: boolean): number {
    let response = 0
    if (tipoMeta === 5) {
      const meses = periodos.sort((a, b) => b.ano - a.ano)[0].meses.filter(e => e.real).sort((a, b) => b.numeroMes - a.numeroMes)
      if (meses.length > 0) {
        response = meses[0].real ?? 0
      }
    } else {
      periodos.forEach(e => e.meses.forEach(m => {
        if (m.real) {
          response += m.real
        }
      }))
    }

    if (meta) {
      return (metaInversa ? response === 0 ? 0 : (meta / response) : (response / meta)) * 100
    } else {
      return response
    }
  }

  function PrevPage(idPeriodo: string): void {
    let periodo = PeriodoSelecionado.find(e => e.id === idPeriodo)
    const index = PeriodoSelecionado.findIndex(e => e.id === idPeriodo)

    if (!periodo) {
      periodo = { val: 0, id: idPeriodo }
    }
    const periodoSelecionado = periodo?.val
    if (periodoSelecionado !== 0) {
      periodo.val = periodoSelecionado - 1
    } else {
      periodo.val = (Indicadores.find(e => e.id === idPeriodo)?.periodos.length ?? 0) - 1 ?? 0
    }
    if (index !== -1) {
      setPeriodoSelecionado(UpdateElementByPosition(PeriodoSelecionado, index, periodo))
    } else {
      setPeriodoSelecionado([...PeriodoSelecionado, periodo])
    }
  }

  function NextPage(idPeriodo: string): void {
    let periodo = PeriodoSelecionado.find(e => e.id === idPeriodo)
    const index = PeriodoSelecionado.findIndex(e => e.id === idPeriodo)

    if (!periodo) {
      periodo = { val: 0, id: idPeriodo }
    }
    const periodoSelecionado = periodo?.val
    if (periodoSelecionado !== (Indicadores.find(e => e.id === idPeriodo)?.periodos.length ?? 0) - 1) {
      periodo.val = periodoSelecionado + 1
    } else {
      periodo.val = 0
    }
    if (index !== -1) {
      setPeriodoSelecionado(UpdateElementByPosition(PeriodoSelecionado, index, periodo))
    } else {
      setPeriodoSelecionado([...PeriodoSelecionado, periodo])
    }
  }

  function FormatarNumero(num: number, indicador: iIndicador): string {
    let response = ''
    const toFixed = indicador.tipoMeta === 5 || indicador.tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response.replace('-', '')
  }

  function GetInfoAprovado(ind: iIndicador, isCor: boolean): string {
    const periodoSelecionado = PeriodoSelecionado.find(a => a.id === ind.id)
    const periodoAprovado = PeriodosAprovados?.find(pa => pa.indicadorId === ind.id && pa.ano === (periodoSelecionado ? ind.periodos[periodoSelecionado.val].ano : ind.periodos[0].ano))
    if (isCor) {
      return !periodoAprovado ? 'var(--c6)' : periodoAprovado.aprovado ? 'var(--Green2)' : 'var(--Red)'
    } else {
      return !periodoAprovado ? 'Aguardando' : periodoAprovado.aprovado ? 'Aprovado' : 'Requisitar alterações'
    }
  }

  useEffect(() => {
    GetIndicadores()
  }, [])

  return (
    <Body>
      <Flex justifyContent={'space-between'}>
        <h1>Indicadores para aprovar</h1>
      </Flex>
      {Indicadores.length === 0 && <Flex fontWeight={700} py={'5rem'} alignItems={'center'} justifyContent={'center'}>
          Ainda não há nenhum indicador para aprovar!
        </Flex>}
      {Indicadores.length > 0 && <Grid gridTemplateColumns={'repeat(1, 1fr)'} fontWeight={700} gap={'1rem'} my={'1rem'}>
        {Indicadores.map((e, index) => {
          const ValorAcumulado = GetValorAcumulado(e.periodos, e.tipoMeta)
          const ProgressoMeta = GetValorAcumulado(e.periodos, e.tipoMeta, e.meta, e.metaInversa)
          return (
            <Flex flexDir={'column'} p={'1.25rem'} pb={e.periodos.length > 0 ? '0' : ''} borderRadius={'.25rem'} border={'1px solid var(--c5)'} boxShadow={'var(--SombraBackground)'} bg={'var(--c1)'} gap={'1rem'}>
              <Flex flex={1} gap={'2rem'} alignItems={'center'} justifyContent='space-between'>
                <Flex alignItems={'center'} fontSize={'14px'} gap={'.5rem'} fontWeight={'500'}><strong style={{ fontWeight: 700, fontSize: '18px' }}>{e.nome}</strong> | {e.dataInicio ? formatarDataAno(e.dataInicio.slice(0, 7)) : 'Indefinido'} - {e.prazo ? formatarDataAno(e.prazo.slice(0, 7)) : 'Indefinido'} <FaCalendarAlt color='var(--Azul)'/></Flex>
                <Flex alignItems={'center'} gap='1rem'>
                  <Flex gap={'.325rem'} fontSize='14px' fontWeight={500}>Períodos visualizados: <strong>{PeriodosAprovados.filter(pa => pa.indicadorId === e.id).length} / {e.periodos.length}</strong></Flex>
                  <ElementoTooltipCmp bg={'var(--Red)'} label={PeriodosAprovados.filter(pa => pa.indicadorId === e.id).length !== e.periodos.length ? 'Você precisa selecionar em todos os períodos se aprova ou se deseja requisitar alterações' : ''}>
                    <ButtonCmp isDisabled={PeriodosAprovados.filter(pa => pa.indicadorId === e.id).length !== e.periodos.length} VarColor='Green2' onClick={() => OnAprovar(e.id)}>Salvar</ButtonCmp>
                  </ElementoTooltipCmp>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} gap={'3rem'}>
                  {e.responsavel && e.participantes && e.participantes?.length > 0 && <Flex gap={'.625rem'} w={'50%'} flexDir={'column'} flex={'1'}>
                    <Text fontWeight={700} fontSize={'14px'}>Participantes:</Text>
                    <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
                      <CardParticipante
                        responsavel
                        participante={e.responsavel}
                      />
                      {e.participantes?.slice(0, Limit).filter(p => p.id !== e.responsavel?.id).map(part => {
                        return (
                          <CardParticipante
                            participante={part}
                          />
                        )
                      })}
                    </Grid>
                    {(e.participantes.length + 1) > (Limit) && <Text fontSize={'14px'} color={'var(--Azul)'} _hover={{ textDecoration: 'underline', cursor: 'pointer' }} textAlign='end' onClick={() => { setLimit(Limit + 3) }}>Ver mais</Text>}
                  </Flex>}
                  <Flex gap={'1rem'} alignItems={'center'} justifyContent='end'>
                    <Flex flexDir={'column'}>
                      <Text fontSize={'14px'}>Meta final: <strong>{e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(e.meta, e)}` : `${FormatarNumero(e.meta, e)}${e.medidaMeta ?? ''}`}</strong></Text>
                      <Text fontSize={'14px'}>Valor acumulado: <strong>{ValorAcumulado === 0 ? 'N/A' : (e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(ValorAcumulado, e)}` : `${FormatarNumero(ValorAcumulado, e)}${e.medidaMeta ?? ''}`)}</strong></Text>
                      <Text fontSize={'14px'}>Valor {(e.metaInversa ? !(e.meta < ValorAcumulado) : e.meta < ValorAcumulado) ? 'excedido' : 'pendente'}: <strong style={{ color: (e.metaInversa ? !(e.meta < ValorAcumulado) : e.meta < ValorAcumulado) ? 'var(--Green2)' : 'var(--Red)' }}>{(ValorAcumulado === 0) ? 'N/A' : (e.tipoMeta === 0 ? `${e.medidaMeta ?? ''}${FormatarNumero(e.metaInversa ? e.meta - ValorAcumulado : ValorAcumulado - e.meta, e)}` : `${FormatarNumero(e.metaInversa ? e.meta - ValorAcumulado : ValorAcumulado - e.meta, e)}${e.medidaMeta ?? ''}`)}</strong></Text>
                    </Flex>
                    <Flex flexDir={'column'}>
                      <CircularProgress value={ProgressoMeta} color='green.400' size={'140px'} height={'180px'}>
                        <CircularProgressLabel mt={'.325rem'} fontSize={'18px'}>{ProgressoMeta === 0 ? 'N/A' : `${ProgressoMeta.toFixed(1)}%`}</CircularProgressLabel>
                      </CircularProgress>
                    </Flex>
                  </Flex>
              </Flex>
              {e.periodos.length > 0 && <Flex flexDir={'column'}>
                  <LoadingContainer linhas={20} loading={e.periodos.some(p => p.id === IsLoadingId)} my='1rem'>
                    <Flex pb={'1rem'} flexDir={'column'} gap={'1rem'}>
                      <Flex justifyContent={'space-between'}>
                        <Text fontSize={'14px'} color={GetInfoAprovado(e, true)}>{GetInfoAprovado(e, false)}</Text>
                        <HStack mt={0} fontSize={'.875rem'}>
                          <Text>Ano:</Text>
                          <Text>{e.periodos[(PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0)].ano}</Text>
                          {e.periodos.length > 1 && <><ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { PrevPage(e.id) }} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
                          <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => { NextPage(e.id) }} VarColor='Green2'><FaArrowRight/></ButtonCmp></>}
                        </HStack>
                      </Flex>
                      {!!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                      <>
                        <Tabela
                          onUpdate={(aprovado) => { setPeriodosAprovados([...PeriodosAprovados.filter(pa => pa.ano !== e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0].ano), { ano: e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0].ano, aprovado: aprovado, indicadorId: e.id }]) }}
                          tipoMeta={e.tipoMeta}
                          isAprovado={PeriodosAprovados.find(pa => pa.ano === e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0].ano)?.aprovado}
                          // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                          periodo={e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0]}
                        />
                        <IndicadorChart
                          indicador={e}
                          data={e.periodos[PeriodoSelecionado.find(a => a.id === e.id)?.val ?? 0]?.meses}
                          id={'indicador-chart' + '-' + index}
                          // valorMaximo={e.meta}
                        />
                      </>}
                      {!PeriodoSelecionado?.find(a => a.id === e.id)?.val &&
                        <>
                          <Tabela
                            onUpdate={(aprovado) => { setPeriodosAprovados([...PeriodosAprovados.filter(pa => pa.ano !== e.periodos[0].ano), { ano: e.periodos[0].ano, aprovado: aprovado, indicadorId: e.id }]) }}
                            tipoMeta={e.tipoMeta}
                            isAprovado={PeriodosAprovados.find(pa => pa.ano === e.periodos[0].ano)?.aprovado}
                            // acumuladoAnterior={GetValorAcumuladoAnterior(e.id, e.periodos)}
                            periodo={e.periodos[0]}
                          />
                          <IndicadorChart
                            indicador={e}
                            data={e.periodos[0]?.meses}
                            id={'indicador-chart' + '-' + index}
                            // valorMaximo={e.meta}
                          />
                        </>
                      }
                    </Flex>
                  </LoadingContainer>
              </Flex>}
            </Flex>
          )
        })}
      </Grid>}
    </Body>
  )
}

import { FormControl } from '@chakra-ui/react'
import { TextAreaCmp } from '../../../../../../../../components/TextareaCmp'
import { iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iDiscursivaProps{
  questao: iQuestaoFlexivel
}

export const Discursiva: React.FC<iDiscursivaProps> = ({ questao }) => {
  return (
        <FormControl>
            <TextAreaCmp
              title={questao.enunciado}
              isDisabled
              placeholder='Digite seu comentário aqui'
              borderColor={'var(--Gray4)'}
              OnChange={() => {}}
            />
        </FormControl>
  )
}

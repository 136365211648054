import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iImg } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { CardVideo } from './Components/CardVideo'
import { Legenda } from '../../PesquisaIndicativa/Criar/styles'
import { TextAreaCmp } from '../../../components/TextareaCmp'

export interface iVideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: iImg
}

export interface iModel {
  textoBoasVindas: string
  videoIntroducao: iVideoIntroducao
}

export interface VideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: Arquivo
}

export interface Arquivo {
  uri: string
  name: string
  size: string
}

export const PrimeroAcesso: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const [Model, setModel] = useState<iModel>({
    textoBoasVindas: '',
    videoIntroducao: {
      descricao: '',
      tipo: 3,
      titulo: '',
      arquivo: {
        name: '',
        size: '',
        uri: ''
      }
    }
  })

  useEffect(() => {
    ObterPrimeiroAcesso()
  }, [])

  function ObterPrimeiroAcesso(): void {
    appApi.get('PrimeiroAcesso/ByEmpresaId').then(response => {
      setModel(response.data)
    }).catch(e => console.log(e))
  }

  function AtualizarPrimeiroAcesso(): void {
    appApi.put('PrimeiroAcesso', Model).then(response => {
      if (response.status === 200) {
        toast({
          title: 'Primeiro acesso atualizado com sucesso',
          status: 'success',
          isClosable: false
        })
        navigate(-1)
      } else {
        toast({
          title: 'Não foi possível atualizar',
          status: 'error',
          isClosable: false
        })
      }
    }).catch(error => console.log(error))
  }

  function AtualizarItemIntegracao(item: iVideoIntroducao): void {
    setModel({ ...Model, videoIntroducao: item })
  }

  return (
    <Body>
      <Flex flexDir={'column'}>
        <Flex flexDir={'column'} mb={'1rem'}>
          <h1>Configurando primeiro acesso</h1>
          <Text fontWeight={'400'} color={'var(--Gray2)'} lineHeight={'1.125rem'}>Aqui você pode configurar as mensagens de boas vindas ao novo colaborador. Obs.: O primeiro acesso é o mesmo para todos os novos usuários.</Text>
        </Flex>
        <WhiteContainer>
            <h3>Faça um breve texto de boas-vindas </h3>
            <Legenda>Esse texto aparecerá junto com a logomarca da empresa assim que o colaborador realizar o login no aplicativo ou navegador</Legenda>
            <TextAreaCmp
              height='10rem'
              value={Model.textoBoasVindas}
              placeholder='Digite o texto'
              OnChange={(e) => setModel({ ...Model, textoBoasVindas: e })}
            />

          <Flex flexDir={'column'} mt={'.75rem'}>
            <h3>Deixe um vídeo sobre a organização</h3>
            <Legenda>O vídeo de introdução pode fazer com que o colaborador se sinta mais a vontade e faça uma breve apresentação da cultura, da liderança e até dos ambientes da organização.</Legenda>
            <CardVideo
              onUpdateInput={AtualizarItemIntegracao}
              ItemIntegracao={Model.videoIntroducao}
            />
          </Flex>
        </WhiteContainer>
        <Flex marginTop={'1rem'} justifyContent={'end'} gap={'.5rem'}>
          <ButtonCmp w={'5rem'} VarColor='c6' onClick={() => navigate(-1)}>Voltar</ButtonCmp>
          {
            <ButtonCmp VarColor='Green2' onClick={AtualizarPrimeiroAcesso}>Atualizar primeiro acesso</ButtonCmp>
          }
        </Flex>
      </Flex>
    </Body>
  )
}

import { ModalBody, TextoDescricao } from './styles'
import { useNavigate } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../interfaces'
import { Button } from '../../../../../components/Button'
import { PropInvalida } from '../../../../../Utils/Helper'
import { ModalBase } from '../../../../../components/ModalBase'
import { Flex } from '@chakra-ui/react'

interface props {
  etapa?: iEtapaColaborador
  onRequestClose: () => void
}

export const ModalPesquisa: React.FC<props> = ({ onRequestClose, etapa }) => {
  const nav = useNavigate()
  const rota = etapa?.tipo === 5 ? `/Abertura/${etapa?.conviteId ?? ''}` : `/PI/Abertura/${etapa?.conviteId ?? ''}`
  return (
    <ModalBase
      Width='37rem'
      isOpen
      onClose={onRequestClose}
      Titulo='Pesquisa'
    >
      {PropInvalida(etapa?.conviteId)
        ? <ModalBody>
          <h2>Você já respondeu esta pesquisa!</h2>
          <TextoDescricao>Estamos aguardando os demais participantes responderem!</TextoDescricao>
        </ModalBody>
        : <ModalBody>
          <h2>Olá,</h2>
          <TextoDescricao>sua pesquisa já pode ser repondida! Clique no botão abaixo para iniciar...</TextoDescricao>
        </ModalBody>
      }

      <Flex justifyContent={'center'} py={'1rem'}>
        {PropInvalida(etapa?.conviteId)
          ? <Button
            onClick={onRequestClose}
            VarColor={'c6'}
          >Voltar</Button>
          : <Button
            onClick={() => nav(rota)}
            VarColor='vg3'
            isDisabled={PropInvalida(etapa?.conviteId)}
          >Responder pesquisa</Button>}
      </Flex>
    </ModalBase>
  )
}

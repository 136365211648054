import { Text } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Container, HeadBox } from './styles'

interface TextBoxProps{
  onClick: () => void
}

export const TextBoxAdd: React.FC<TextBoxProps> = ({ onClick }) => {
  return (
    <Container>
        <HeadBox>
            <Text fontSize={'.875rem'} fontWeight={'700'} onClick={onClick}>Clique para adicionar um tópico</Text>
            <AiOutlinePlus size={18} onClick={onClick}/>
        </HeadBox>
    </Container>
  )
}

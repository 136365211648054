import { Flex, Img, Text } from '@chakra-ui/react'
import { ModalBase } from '../../../../../../components/ModalBase'
import gifCheck from '../../../../../../assets/checkGif.gif'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface iProps {
  onClose: () => void
  agendar: boolean
}

export const PopUpCriar: React.FC<iProps> = ({ onClose, agendar }) => {
  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Width='35rem'
    >
      <Flex alignItems={'center'} gap='.5rem' justifyContent='center' flexDir={'column'} p='0 2rem 2rem 2rem'>
        <h3>Convite enviado com sucesso!</h3>
        <Img
          w={'3.5rem'}
          h={'3.5rem'}
          src={gifCheck}
          mb={'1rem'}
        />
        {!agendar &&
          <Text
            fontSize={'14px'}
            fontWeight={'700'}
            mb={'.5rem'}
          >Se for necessário fazer o reagendamento, você deve realiza-lo aqui mesmo em nossa plataforma, para garantir a melhor experiência!</Text>
        }
        <ButtonCmp
          VarColor='Green2'
          onClick={onClose}
        >Continuar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable multiline-ternary */
import { useEffect, useRef, useState } from 'react'
import { TagContainer } from '../TagContainer'
import { iCardPesquisa, IEmployee, iGrupoTrilha, iNomeId, iPostColaborador, iProcessoOnboard, iPutColaborador } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { BoxCards, Card, ContainerBody, DashedBox, DivPadding } from './styles'
import { Avatar, Box, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { FindUser } from '../FindBox'
import { CompleteTagContainer } from '../CompleteTagContainer'
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaBriefcase, FaCheckCircle, FaClipboardList, FaStreetView, FaUserClock, FaUsers } from 'react-icons/fa'
import { ModalBase } from '../ModalBase'
import { PropInvalida } from '../../Utils/Helper'
import { ButtonCmp } from '../ButtonCmp'
import { ModalReenviarPrimeiroAcesso } from './components/ModalReenviarPrimeiroAcesso'
import { RiSendPlaneFill } from 'react-icons/ri'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'
import { InputCmp } from '../InputCmp'
import { CheckboxCmp } from '../CheckboxCmp'
import { iGestaoPesquisaInterna } from '../../pages/Jornada/PesquisaInterna/Gestao'
import { SearchCmp } from '../SearchCmp'
import { Descricao } from '../../pages/RH/Trilhas/Components/Card/styles'
import { MdChecklist } from 'react-icons/md'
import { CardPesquisa } from './components/CardPesquisa'
import { CardGestao } from './components/CardGestao'
import { FaRegCircleXmark } from 'react-icons/fa6'
import LoadingContainer from '../LoadingContainer'

interface iProps {
  isOpen: boolean
  onRequestClose: () => void
  onCreateEmployee?: (newEmployee: iPostColaborador) => void
  onUpdateEmployee?: (upEmployee: iPutColaborador) => void
  departamentoId?: string
  colaborador?: IEmployee
}

interface iAddItens {
  trilhas?: iGrupoTrilha[]
  pesquisasDePercepcao?: iCardPesquisa[]
  pesquisasInternas?: iGestaoPesquisaInterna[]
}

export const CreateColaboradorModal: React.FC<iProps> = ({
  isOpen,
  onRequestClose,
  onCreateEmployee,
  onUpdateEmployee,
  colaborador
}) => {
  const $divScroll = useRef<HTMLDivElement>(null)
  const [Scroll, setScroll] = useState(1042)

  const isEditar = !PropInvalida(colaborador)
  const toast = useToast()
  const [NomeIsInValid, setNomeIsInValid] = useState(false)
  const [CPFIsInValid, setCPFIsInValid] = useState(false)
  const [EmailIsInValid, setEmailIsInValid] = useState(false)
  const [EmailExist, setEmailExist] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [CpfExist, setCpfExist] = useState(false)
  const [DataIngressoIsInValid, setDataIngressoIsInValid] = useState(false)
  const [CargoIsInvalid, setCargoIsInvalid] = useState(false)
  const [DepartamentoIsInvalid, setDepartamentoIsInvalid] = useState(false)
  const [GestorIsInvalid, setGestorIsInvalid] = useState(false)
  const [SobrenomeIsInvalid, setSobrenomeIsInvalid] = useState(false)
  const [InputPesquisa, setInputPesquisa] = useState('')

  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iPostColaborador>({
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    celular: '',
    departamentoId: '',
    dataIngresso: '',
    tipoUsuario: 0,
    administrador: false,
    temUsuario: false,
    cargoId: '',
    primeiroAcesso: false
  })
  const [TagsSelecionadas, setTagsSelecionadas] = useState<string[]>([])

  const [NovoCargoInputCmp, setNovoCargoInputCmp] = useState('')
  const [GestorInputCmp, setGestorInputCmp] = useState('')
  const [NovoDepartamentoInputCmp, setNovoDepartamentoInputCmp] = useState('')
  const [HasAddItems, setHasAddItems] = useState(false)
  const [AddItems, setAddItems] = useState<iAddItens>({ pesquisasDePercepcao: [], pesquisasInternas: [], trilhas: [] })
  const [ModalStep, setModalStep] = useState(1)

  const [ModalPrimeiroAcessoIsOpen, setModalPrimeiroAcessoIsOpen] = useState(false)

  function onCreateCargo(nome: string): void {
    const form = {
      nome: nome
    }
    appApi.post('Cargo', form)
      .then((res) => {
        getCargos()
        SelecionarCargo(res.data.id)
        toast({
          title: 'Cargo criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => {
        toast({
          title: 'Não foi possível criar o cargo',
          description: 'Tente novamente',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        console.log(err)
      }
      )
  }

  function onCreateDepartamento(nome: string): void {
    const form = {
      nome: nome
    }
    appApi.post('Departamento', form)
      .then((res) => {
        getDepartamentos()
        SelecionarArea(res.data.id)
        toast({
          title: 'Departamento criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => {
        toast({
          title: 'Não foi possível criar o Departamento',
          description: 'Tente novamente',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        console.log(err)
      }
      )
  }

  function SelecionarGestor(id: string): void {
    setModel({ ...Model, gerenteId: id })
    setGestorInputCmp('')
    setGestorIsInvalid(false)
  }

  function SelecionarCargo(id: string): void {
    setModel({ ...Model, cargoId: id })
    setCargoIsInvalid(false)
    setNovoCargoInputCmp('')
  }

  function SelecionarArea(id: string): void {
    setModel({ ...Model, departamentoId: id })
    setDepartamentoIsInvalid(false)
    setNovoDepartamentoInputCmp('')
    // if (PropInvalida(id)) {
    //   SelecionarGestor('')
    // }
  }

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Model.avatar !== undefined) {
      appApi.post(`Colaborador/uploadfile?url=${Model.avatar}`, formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    } else {
      appApi.post('Colaborador/uploadfile', formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    }
  }

  function VerificarPreenchimento(): boolean {
    setNomeIsInValid(false)
    setCPFIsInValid(false)
    setEmailIsInValid(false)
    setDataIngressoIsInValid(false)
    setCargoIsInvalid(false)
    setDepartamentoIsInvalid(false)
    setSobrenomeIsInvalid(false)
    setGestorIsInvalid(false)

    if (Model.nome === '') {
      setNomeIsInValid(true)
      toast({
        title: 'Nome não foi informado!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.sobrenome === '') {
      setSobrenomeIsInvalid(true)
      toast({
        title: 'Sobrenome não foi informado!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.email === '') {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail não foi informado!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (EmailExist) {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail já cadastrado. Cadastre um novo e-mail ou edite o colaborador já cadastrado.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.cpf === '') {
      setCPFIsInValid(true)
      toast({
        title: 'CPF não foi informado!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (CpfExist) {
      setCPFIsInValid(true)
      toast({
        title: 'CPF já cadastrado. Verifique o colaborador e procure o administrador caso necessário.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (PropInvalida(Model.cargoId)) {
      setCargoIsInvalid(true)
      toast({
        title: 'Cargo não selecionado!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (PropInvalida(Model.departamentoId)) {
      setDepartamentoIsInvalid(true)
      toast({
        title: 'Área não selecionada!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (GestorInputCmp.length > 0 && PropInvalida(Model.gerenteId)) {
      setGestorIsInvalid(true)
      toast({
        title: 'Gestor inválido!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.dataIngresso === '') {
      setDataIngressoIsInValid(true)
      toast({
        title: 'Data de admissão não foi informada!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    return true
  }

  function InternalOnCreate(semProcessos?: boolean): void {
    if (VerificarPreenchimento() && onCreateEmployee !== undefined) {
      if (HasAddItems && ModalStep !== 2) {
        setModalStep(2)
      } else {
        onCreateEmployee({
          email: Model.email.toLowerCase(),
          nome: Model.nome,
          sobrenome: Model.sobrenome,
          avatar: Model.avatar,
          departamentoId: Model.departamentoId,
          cargoId: Model.cargoId,
          gerenteId: PropInvalida(Model.gerenteId) ? undefined : Model.gerenteId,
          celular: Model.celular,
          cpf: Model.cpf.replace('.', '').replace('-', ''),
          dataIngresso: Model.dataIngresso,
          tipoUsuario: Model.tipoUsuario,
          tags: TagsSelecionadas,
          administrador: Model.administrador,
          temUsuario: Model.temUsuario,
          primeiroAcesso: Model.primeiroAcesso,
          pesquisaInternaId: semProcessos ? undefined : Model.pesquisaInternaId,
          pesquisaPercepId: semProcessos ? undefined : Model.pesquisaPercepId,
          trilhaId: semProcessos ? undefined : Model.trilhaId
        })
      }
    }
  }

  function InternalOnUpdate(): void {
    if (VerificarPreenchimento() && onUpdateEmployee !== undefined) {
      onUpdateEmployee({
        id: colaborador?.id ?? '',
        email: Model.email.toLowerCase(),
        nome: Model.nome,
        sobrenome: Model.sobrenome,
        avatar: Model.avatar,
        departamentoId: Model.departamentoId,
        cargoId: Model.cargoId,
        gerenteId: PropInvalida(Model.gerenteId) ? undefined : Model.gerenteId,
        celular: Model.celular,
        cpf: Model.cpf.replace('.', '').replace('-', ''),
        dataIngresso: Model.dataIngresso,
        tipoUsuario: Model.tipoUsuario,
        tags: TagsSelecionadas,
        administrador: Model.administrador
      })
    }
  }

  function VerificarEmail(): void {
    setEmailExist(false)
    setEmailIsInValid(false)
    appApi.get(`Colaborador/Email?email=${Model.email.toLowerCase()}&colaboradorId=${colaborador?.id}`)
      .then(res => {
        if (res.data === true) {
          setEmailExist(true)
          setEmailIsInValid(true)
        }
      })
      .catch(err => console.log(err))
  }

  function VerificarCPF(): void {
    setCpfExist(false)
    setCPFIsInValid(false)
    appApi.get(`Colaborador/Cpf?Cpf=${Model.cpf.replace('.', '').replace('-', '')}&colaboradorId=${colaborador?.id ?? ''}`)
      .then(res => {
        if (res.data === true) {
          setCpfExist(true)
          setCPFIsInValid(true)
        }
      })
      .catch(err => console.log(err))
  }

  function getDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => {
        setDepartamentos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getCargos(): void {
    appApi.get('Cargo/Select')
      .then(res => {
        setCargos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getHasAddItens(): void {
    appApi.get('ProcessoOnboard/AddItems')
      .then(res => {
        const data: iAddItens = res.data
        setAddItems(res.data)
        if ((data.pesquisasDePercepcao && data.pesquisasDePercepcao.length > 0) || (data.pesquisasInternas && data.pesquisasInternas.length > 0) || (data.trilhas && data.trilhas.length > 0)) {
          setHasAddItems(true)
        }
      })
      .catch(err => console.log(err))
  }

  function getColaboradores(departamentoId: string): void {
    setIsLoading(true)
    appApi.get(`Colaborador/Select/Gestor/?departamentoId=${departamentoId}`)
      .then(res => {
        const data: iNomeId[] = res.data
        if (PropInvalida(colaborador)) {
          setColaboradores(data)
        } else {
          setColaboradores(data.filter(e => e.id !== colaborador?.id))
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function addTag(tag: string): void {
    setTagsSelecionadas(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSelecionadas(TagsSelecionadas.filter(r => r !== id))
  }

  function ReenviarPrimeiroAcesso(): void {
    appApi.post(`Colaborador/ReenviarPrimeiroAcesso/${colaborador?.id ?? ''}`)
      .then(() =>
        toast({
          title: 'Email de primeiro acesso reenviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        }))
      .catch(err => console.log(err))
    setModalPrimeiroAcessoIsOpen(false)
  }

  function ScrollDireita(): void {
    if ($divScroll) {
      setScroll(1042 + Scroll)
      const TamanhoDiv: number = $divScroll.current?.clientWidth ? $divScroll.current.clientWidth : 0
      if (Scroll > TamanhoDiv) {
        setScroll(TamanhoDiv)
      }
      $divScroll.current?.scroll(Scroll, 0)
    }
  }

  function ScrollEsquerda(): void {
    if ($divScroll) {
      setScroll(Scroll - 1042)
      if (Scroll < 0 || Scroll === 0) {
        setScroll(0)
      }
      $divScroll.current?.scroll(Scroll, 0)
    }
  }

  useEffect(() => {
    getDepartamentos()
    getCargos()
    getHasAddItens()
  }, [])

  useEffect(() => {
    if (Model.departamentoId) {
      getColaboradores(Model.departamentoId)
    }
  }, [Model.departamentoId])

  useEffect(() => {
    if (colaborador) {
      getColaboradores(colaborador.departamentoId ?? '')
      setModel({
        avatar: colaborador.avatar,
        nome: colaborador.nome,
        sobrenome: colaborador.sobrenome,
        email: colaborador.email,
        cpf: colaborador.cpf,
        celular: colaborador.celular,
        cargoId: colaborador.cargoId,
        departamentoId: colaborador.departamentoId ?? '',
        gerenteId: colaborador.gerenteId,
        dataIngresso: colaborador.dataIngresso,
        tipoUsuario: colaborador.tipoUsuario,
        tags: colaborador.tags,
        administrador: colaborador.administrador,
        temUsuario: false
      })
      setTagsSelecionadas(colaborador.tags ?? [])
    }
  }, [colaborador])

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onRequestClose}
      className='react-modal-content-styled-scroll'
      Titulo={ModalStep === 1 ? `${isEditar ? 'Atualizar' : 'Novo'} colaborador` : 'Incluir colaborador'}
      Subtitulo={ModalStep === 2 ? 'Você pode optar por não selecionar nenhum processo prévio e concluir o cadastro clicando no botão "Cadastrar Colaborador" ao final da tela.' : ''}
      styleHeader={{ alignItems: 'center', paddingLeft: '1.5rem', minWidth: '42rem', maxWidth: '42rem', textAlign: 'left' }}
    >
      {ModalPrimeiroAcessoIsOpen &&
        <ModalReenviarPrimeiroAcesso
          onRequestClose={() => setModalPrimeiroAcessoIsOpen(false)}
          onReenviar={ReenviarPrimeiroAcesso}
        />
      }
      <LoadingContainer linhas={16} loading={IsLoading} px={'2rem'} my={'1rem'}>
        {ModalStep === 1 ? <Flex flexDir={'column'} padding='0 1.5rem 1.25rem 1.5rem' gap={'.75rem'}>
          <Flex alignItems='center' w={'full'} gap={'1rem'}>
            <DashedBox htmlFor='m-file'>
              <Avatar src={Model.avatar} w={'110px'} h={'110px'}/>
              <small>Clique para selecionar</small>
              <input
                id='m-file'
                onChange={(e) => { AtualizarArquivo(e) }}
                type={'file'}
              />
            </DashedBox>
            <Flex flexDir={'column'} gap={'.5rem'} flex={'1'}>
              <FormControl>
                <InputCmp label='Nome' isInvalid={NomeIsInValid}
                  OnChange={(e) => setModel({ ...Model, nome: e })}
                  placeholder='Digite o primeiro nome (Obrigatório)'
                  value={Model.nome}
                />
              </FormControl>

              <FormControl>
                <InputCmp
                  label='Sobrenome'
                  OnChange={(e) => setModel({ ...Model, sobrenome: e })}
                  placeholder='Digite o sobrenome (Obrigatório)'
                  isInvalid={SobrenomeIsInvalid}
                  value={Model.sobrenome}
                />
              </FormControl>

              <FormControl>
                <InputCmp
                  label='E-mail'
                  isInvalid={EmailIsInValid}
                  onBlur={() => Model.email && VerificarEmail()}
                  OnChange={(e) => setModel({ ...Model, email: e })}
                  placeholder='Digite o e-mail (Obrigatório)'
                  value={Model.email}
                />
                {EmailExist &&
                  <Text marginTop={'.5rem'} color={'var(--Red)'} fontSize={'.813rem'}>O e-mail já foi cadastrado!</Text>
                }
              </FormControl>
            </Flex>
          </Flex>

          <Flex gap={'.75rem'}>
            <FormControl>
              <InputCmp
                label='CPF'
                isInvalid={CPFIsInValid}
                OnChange={(e) => setModel({ ...Model, cpf: e })}
                onBlur={() => Model.cpf && VerificarCPF()}
                placeholder='Digite o CPF (Obrigatório)'
                value={Model.cpf}
              />
              {CpfExist &&
                <Text marginTop={'.5rem'} color={'var(--Red)'} fontSize={'.813rem'}>O CPF já foi cadastrado!</Text>
              }
            </FormControl>

            <FormControl>
              <InputCmp
                label='Celular'
                OnChange={(e) => setModel({ ...Model, celular: e })}
                placeholder='Digite o celular'
                value={Model.celular}
              />
            </FormControl>
          </Flex>

          <Flex gap={'.75rem'}>
            <Flex flexDir={'column'} w={'full'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Função</FormLabel>
              <FindUser
                lista={Cargos}
                onChoice={SelecionarCargo}
                _onChange={(e) => setNovoCargoInputCmp(e)}
                placeholder='Digite o nome da função (Obrigatório)'
                isNotUser
                leftIcon={<FaBriefcase size={'1.3rem'} />}
                isInvalid={CargoIsInvalid}
                selecionado={Model.cargoId}
                onBlur={() => {
                  if (PropInvalida(Model.cargoId)) {
                    setCargoIsInvalid(true)
                  } else {
                    setCargoIsInvalid(false)
                  }
                }}
              />
              {CargoIsInvalid &&
                <Flex mt={'.125rem'} alignItems={'center'} gap='.5rem'>
                  <Text color={'var(--Red)'} fontSize={'.813rem'}>{NovoCargoInputCmp.length === 0 ? 'Campo inválido!' : 'Atenção! Essa função não existe, deseja criar agora?'}</Text>
                  {NovoCargoInputCmp.length > 0 &&
                    <ButtonCmp
                      VarColor={'Green2'}
                      size={'sm'}
                      onClick={() => onCreateCargo(NovoCargoInputCmp)}
                    >Criar</ButtonCmp>
                  }
                </Flex>
              }
            </Flex>

            <Flex flexDir={'column'} w={'full'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Área</FormLabel>
              <FindUser
                lista={Departamentos}
                selecionado={Model.departamentoId}
                onChoice={SelecionarArea}
                _onChange={(e) => setNovoDepartamentoInputCmp(e)}
                placeholder='Digite o nome da área (Obrigatório)'
                isNotUser
                leftIcon={<FaBriefcase size={'1.3rem'} />}
                isInvalid={DepartamentoIsInvalid}
                onBlur={() => {
                  if (PropInvalida(Model.departamentoId)) {
                    setDepartamentoIsInvalid(true)
                  } else {
                    setDepartamentoIsInvalid(false)
                  }
                }}
              />
              {DepartamentoIsInvalid &&
                <Flex mt={'.125rem'} alignItems={'center'} gap='.5rem'>
                  <Text color={'var(--Red)'} fontSize={'.813rem'}>{NovoDepartamentoInputCmp.length === 0 ? 'Campo inválido!' : 'Atenção! Essa área não existe, deseja criar agora?'}</Text>
                  {NovoDepartamentoInputCmp.length > 0 &&
                    <ButtonCmp
                      VarColor={'Green2'}
                      size={'sm'}
                      onClick={() => onCreateDepartamento(NovoDepartamentoInputCmp)}
                    >Criar</ButtonCmp>
                  }
                </Flex>
              }
            </Flex>
          </Flex>

          <Flex gap={'.75rem'}>
            <Flex w={'100%'} flexDir={'column'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Gestor</FormLabel>
              <FindUser
                lista={Colaboradores}
                isInvalid={GestorIsInvalid}
                selecionado={Model.gerenteId}
                onChoice={SelecionarGestor}
                _onChange={(e) => setGestorInputCmp(e)}
                // placeholder='Digite o nome do gestor (Apenas gestores relacionados com área selecionada)'
                placeholder='Digite o nome do gestor'
                onBlur={() => {
                  if (PropInvalida(Model.gerenteId) && !Colaboradores.some(e => e.nome.toLocaleLowerCase().includes(GestorInputCmp.toLocaleLowerCase()))) {
                    setGestorIsInvalid(true)
                  } else {
                    setGestorIsInvalid(false)
                  }
                }}
              />
              {GestorIsInvalid &&
                <Text mt={'.5rem'} color={'var(--Red)'} fontSize={'.813rem'}>Não foi possível encontrar um gestor com nome de "{GestorInputCmp}" na área selecionada!</Text>
              }
            </Flex>

            <FormControl w={'full'}>
              <InputCmp
                label='Data de admissão'
                isInvalid={DataIngressoIsInValid}
                type={'date'}
                max={'2099-12-31T23:59'}
                OnChange={(e) => setModel({ ...Model, dataIngresso: e })}
                value={Model.dataIngresso}
              />
            </FormControl>
          </Flex>

          <Flex width={'40rem'} gap={'.75rem'} flexDir={'column'}>

            <Flex flexDir={'column'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Nível de usuário</FormLabel>
              <RadioGroup marginLeft={'.5rem'} value={Model.tipoUsuario.toString()} onChange={(e) => setModel({ ...Model, tipoUsuario: parseInt(e) })} size={'sm'}>
                <Stack direction={'row'} spacing={3}>
                  <Radio value={'2'}>Gestor da área</Radio>
                  <Radio value={'1'}>Gestor</Radio>
                  <Radio value={'0'}>Colaborador</Radio>
                </Stack>
              </RadioGroup>
            </Flex>

            <Flex gap={'.75rem'}>
              <FormControl minW={'30%'} maxW={'30%'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Squads</FormLabel>
                <TagContainer
                  isGrey
                  addTag={addTag}
                  removeTag={removeTag}
                  Selecionadas={TagsSelecionadas}
                  tipo={2}
                  wfull
                  placeholder={'Digite o nome do squad'}
                />
              </FormControl>

              <FormControl flex={'1'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Papéis</FormLabel>
                <CompleteTagContainer
                  isGrey
                  addTag={addTag}
                  removeTag={removeTag}
                  selecionadas={TagsSelecionadas}
                />
              </FormControl>
            </Flex>

            <Flex alignItems={'center'} mt={'.325rem'}>
              <CheckboxCmp OnChange={(e) => setModel({ ...Model, administrador: e })} isChecked={Model.administrador}>
                Marque para dar permissão de administrador ao usuário
              </CheckboxCmp>
            </Flex>

            {!isEditar &&
              <Flex alignItems={'center'} mt={'.325rem'}>
                <CheckboxCmp
                  OnChange={(e) => setModel({ ...Model, temUsuario: e })}
                  isChecked={Model.temUsuario}
                >
                  Já possui usuário no SIGAH
                </CheckboxCmp>
              </Flex>
            }

            {!isEditar &&
              <Flex alignItems={'center'} mt={'.325rem'}>
                <CheckboxCmp
                  OnChange={(e) => setModel({ ...Model, primeiroAcesso: e })}
                  isChecked={Model.primeiroAcesso}
                >
                  Não enviar email de primeiro acesso
                </CheckboxCmp>
              </Flex>
            }
          </Flex>

          <Flex justifyContent={'end'} mt={'.75rem'} gap={'.5rem'}>
            {isEditar &&
              <ElementoTooltipCmp
                label={colaborador?.primeiroAcesso ? 'Reenviar email de primeiro acesso' : 'Enviar email de primeiro acesso'}
                bg={colaborador?.primeiroAcesso ? 'var(--Gray3)' : 'var(--Azul)'}
              >
                <Box>
                  <ButtonCmp
                    VarColor={colaborador?.primeiroAcesso ? 'Gray3' : 'Rosa'}
                    onClick={() => setModalPrimeiroAcessoIsOpen(true)}
                  ><RiSendPlaneFill /></ButtonCmp>
                </Box>
              </ElementoTooltipCmp>
            }

            {!isEditar &&
              <ButtonCmp
                VarColor='Green2'
                onClick={() => { InternalOnCreate(false) }}
              >{HasAddItems ? 'Continuar cadastro' : 'Cadastrar colaborador'}</ButtonCmp>
            }

            {isEditar &&
              <ButtonCmp
                VarColor='Green2'
                onClick={InternalOnUpdate}
              >Atualizar colaborador</ButtonCmp>
            }
          </Flex>
        </Flex> : <Flex flexDir={'column'} padding='0 1.5rem 1.25rem 1.5rem' gap={'.75rem'}>
          <Flex flexDir={'column'} px={'.75rem'}>
            <Tabs>
              <TabList gap={'.5rem'} border={'none'}>
                <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'} w={'full'}><FaUserClock /> Trilha de onboarding</Tab>
                <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'} w={'full'}><FaStreetView /> Pesquisa de desempenho</Tab>
                {/* <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'} w={'full'}><FaClipboardList />Pesquisa interna</Tab> */}
              </TabList>
              <TabPanels>
                <TabPanel p={'0'} pt={'.75rem'}>
                  <BoxCards>
                    <Flex justifyContent={'space-between'}>
                      <h3>Selecione uma trilha</h3>
                      <SearchCmp
                        EnterAction={() => {}}
                        OnChange={(e) => setInputPesquisa(e)}
                        placeholder='Pesquisa'
                        width={'15rem'}
                      />
                    </Flex>

                    <Flex>
                      {AddItems.trilhas?.filter(t => {
                        if (InputPesquisa === '') { return t }

                        if (t.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) { return t }

                        return null
                      }).length === 0 &&
                        <Flex gap={'.5rem'} flexDir={'column'} py={'2rem'} justifyContent={'center'} alignItems='center' w={'100%'}>
                          <h3>Configure uma trilha antes de adicionar</h3>
                        </Flex>
                      }

                      {
                        (AddItems.trilhas && AddItems.trilhas?.filter(t => {
                          if (InputPesquisa === '') { return t }

                          if (t.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) { return t }

                          return null
                        }).length > 3) && (
                          <DivPadding onClick={ScrollEsquerda} onMouseOver={ScrollEsquerda}>
                            <FaAngleDoubleLeft className='Esquerda' size={28} />
                          </DivPadding>
                        )
                      }

                      <ContainerBody ref={$divScroll}>
                        {
                          AddItems.trilhas?.filter(t => {
                            if (InputPesquisa === '') { return t }

                            if (t.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) { return t }

                            return null
                          }).map((e: iGrupoTrilha, i) => {
                            return (
                              <Card
                                key={i}
                                theme={e.cor}
                                selecionado={e.id === Model.trilhaId}
                                onClick={() => { if (e.id === Model.trilhaId) { setModel({ ...Model, trilhaId: undefined }) } else { setModel({ ...Model, trilhaId: e.id }) } }}
                              >
                                <Text fontSize={'.875rem'} fontWeight={'bold'} mb={'.5rem'} lineHeight={'1.125rem'}>{e.nome}</Text>
                                <Flex flexDir={'column'} gap='.5rem' fontSize={'.813rem'}>
                                  <Descricao><MdChecklist fontSize={16} /><strong>{e.countEtapas}</strong> Etapas</Descricao>
                                  <Descricao><FaUsers fontSize={16} /><strong>{e.countProcessos}</strong> Processos ativos</Descricao>
                                </Flex>
                              </Card>
                            )
                          })
                        }
                      </ContainerBody>

                      {
                        (AddItems.trilhas && AddItems.trilhas?.filter(t => {
                          if (InputPesquisa === '') { return t }

                          if (t.nome.toLowerCase().includes(InputPesquisa.toLowerCase())) { return t }

                          return null
                        }).length > 3) && (
                          <DivPadding onClick={ScrollDireita} onMouseOver={ScrollDireita}>
                            <FaAngleDoubleRight className='Direita' size={28} />
                          </DivPadding>
                        )
                      }
                    </Flex>
                  </BoxCards>
                </TabPanel>
                <TabPanel p={'0'} pt={'.75rem'}>
                  <BoxCards>
                    {AddItems.pesquisasDePercepcao?.length !== 0 && <Flex mb={'1rem'}>
                      <h3>Selecione uma pesquisa de desempenho</h3>
                    </Flex>}
                    <Flex gap={'1rem'} flexDir={'column'}>
                      {AddItems.pesquisasDePercepcao?.length === 0 && <Flex justifyContent={'center'} alignItems={'center'} fontWeight={700} fontSize={'14px'} py={'2.5rem'}>Ainda não há pesquisas cíclicas criadas e/ou em andamento</Flex>}
                      {AddItems.pesquisasDePercepcao?.map(e => {
                        return <CardPesquisa selecionada={Model.pesquisaPercepId} pesquisa={e} onSelect={(id) => { if (id === Model.pesquisaPercepId) { setModel({ ...Model, pesquisaPercepId: undefined }) } else { setModel({ ...Model, pesquisaPercepId: id }) } }}/>
                      })}
                    </Flex>
                  </BoxCards>
                </TabPanel>
                {/* <TabPanel p={'0'} pt={'.75rem'}>
                  <BoxCards>
                    <Flex mb={'1rem'}>
                      <h3>Selecione uma pesquisa interna</h3>
                    </Flex>
                    <Flex gap={'1rem'} flexDir={'column'}>
                      {AddItems.pesquisasInternas.map(e => {
                        return <CardGestao selecionada={Model.pesquisaInternaId} pesquisa={e} onSelect={(id) => { if (id === Model.pesquisaInternaId) { setModel({ ...Model, pesquisaInternaId: undefined }) } else { setModel({ ...Model, pesquisaInternaId: id }) } }}/>
                      })}
                    </Flex>
                  </BoxCards>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </Flex>
          <Flex gap={'1rem'} fontSize={'.813rem'} flexDir={'column'} maxW={'42rem'}>
            {!Model.trilhaId ? <Flex gap={'.325rem'} alignItems={'center'}>
              <FaRegCircleXmark color='var(--Red)'/>
              Nenhuma trilha foi selecionada
            </Flex> : <Flex gap={'.325rem'} alignItems={'center'}>
              <FaCheckCircle color='var(--Green2)'/>
              Trilha selecionada: <strong>{AddItems.trilhas?.find(e => e.id === Model.trilhaId)?.nome}</strong>
            </Flex>}
            {!Model.pesquisaPercepId ? <Flex gap={'.325rem'} alignItems={'center'}>
              <FaRegCircleXmark color='var(--Red)'/>
              Nenhuma pesquisa de desempenho foi selecionada
            </Flex> : <Flex gap={'.325rem'} alignItems={'center'}>
              <FaCheckCircle color='var(--Green2)'/>
              Pesquisa de desempenho selecionada: <strong>{AddItems.pesquisasDePercepcao?.find(e => e.id === Model.pesquisaPercepId)?.nomePesquisa}</strong>
            </Flex>}
            {/* {!Model.pesquisaInternaId ? <Flex gap={'.325rem'} alignItems={'center'}>
              <FaRegCircleXmark color='var(--Red)'/>
              Nenhuma pesquisa interna foi selecionada
            </Flex> : <Flex gap={'.325rem'} alignItems={'center'}>
              <FaCheckCircle color='var(--Green2)'/>
              Pesquisa interna selecionada: <strong>{AddItems.pesquisasInternas.find(e => e.id === Model.pesquisaInternaId)?.nomePesquisa}</strong>
            </Flex>} */}
          </Flex>
          <Flex justifyContent={'end'} mt={'.75rem'} gap={'.5rem'}>
            <ButtonCmp onClick={() => setModalStep(1)} VarColor={'c6'}>Voltar</ButtonCmp>
            <ButtonCmp
              VarColor='Rosa'
              onClick={() => { InternalOnCreate(true) }}
            >Cadastrar colaborador sem incluir nenhum processo</ButtonCmp>
            <ButtonCmp
              VarColor='Green2'
              onClick={() => { InternalOnCreate(false) }}
            >Cadastrar colaborador</ButtonCmp>
          </Flex>
        </Flex>}
      </LoadingContainer>
    </ModalBase>
  )
}

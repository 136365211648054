import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Container = styled(Column)<{open?: boolean, isFisrt?: boolean}>`
padding: 1rem 0rem;
font-size: .875rem;

${({ isFisrt }) => isFisrt && `
    border-top: 2px solid var(--c4);
`}

border-bottom: 2px solid var(--c4);

span{
    line-height: 110%;
    max-height: 0rem;
    transition: max-height 1.2s;
    overflow-y: hidden;
    ${({ open }) => open && `
          max-height: 30rem;
    `}
}
`

export const HeadBox = styled(Flex)<{open?: boolean}>`
justify-content: space-between;
align-items: center;
transition: margin-bottom .6s;

${({ open }) => open && `
       margin-bottom: 1rem;
  `}

svg{
    cursor: pointer;
}

svg:nth-child(2){
    transition: transform 1.2s;
    ${({ open }) => open && `
        transition: transform 1.2s;
       transform: rotate(135deg);
    `}
}
`

/* eslint-disable @typescript-eslint/no-misused-promises */
import { Flex } from '@chakra-ui/react'
import { FaCopy } from 'react-icons/fa'
import QRCode from 'react-qr-code'
import { urlLocal } from '../../../../../services/appApi'
import { ModalBase } from '../../../../../components/ModalBase'
import { TextContainer } from './styles'
import { InputCmp } from '../../../../../components/InputCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface iModalProps{
  imersaoId: string
  isOpen: boolean
  onClose: () => void
}

export const ModalLinkAberto: React.FC<iModalProps> = ({ imersaoId, isOpen, onClose }) => {
  const Text = `${urlLocal as string}/VisualizarImersao/${imersaoId}`
  return (
    <ModalBase
        Titulo='Link aberto'
        Width='45rem'
        isOpen={isOpen}
        onClose={onClose}
    >
    <Flex padding={'1rem'} flexDir={'column'}>
        <Flex alignItems={'center'} gap={'1.5rem'} border={'1px solid var(--AzulOpaco)'} borderRadius={'.25rem'} bg={'var(--c2)'} padding={'1.5rem'}>
            <Flex bg={'white'} padding={'1rem'}>
                <QRCode value={Text}/>
            </Flex>
            <TextContainer>
                <strong></strong>
                <span>
                    Com o link aberto você pode distribuir o
                    <strong> QR CODE</strong> pela empresa e para os colaboradores especificados para responder
                    a pesquisa. Vale ressaltar que essa opção possibilita a cada colaborador
                    <strong> responder a pesquisa quantas vezes quiser</strong>. Se a intenção for que cada pessoa
                    responda apenas uma vez, recomendamos que use um dos outros três
                    métodos de envio.
                </span>
            </TextContainer>
        </Flex>
        <Flex margin={'.75rem 0'} alignItems={'end'} gap={'.5rem'}>
            <InputCmp
                label='Link'
                value={Text}
                width={'100%'}
                readOnly
                OnChange={() => {}}
            />

            <ElementoTooltipCmp bg={'var(--Rosa)'} label='Copiar link'>
                <ButtonCmp
                    VarColor='Rosa'
                    onClick={async () => await navigator.clipboard.writeText(Text)}
                ><FaCopy/></ButtonCmp>
            </ElementoTooltipCmp>
        </Flex>

        <Flex pt={'1.25rem'} justifyContent={'end'}>
            <ButtonCmp VarColor='Green2' onClick={onClose}>Continuar</ButtonCmp>
        </Flex>
    </Flex>
    </ModalBase>
  )
}

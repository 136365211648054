import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { iNomeId } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Legenda, Main } from './styles'
import {
  Accordion, AccordionButton, AccordionIcon, AccordionItem,
  AccordionPanel, Box, Flex, FormControl, Radio, RadioGroup,
  Stack, useToast, Text
} from '@chakra-ui/react'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { TimelineCriarPesquisa } from '../components/Timeline'
import { FaChevronRight } from 'react-icons/fa'
import { FindUser } from '../../../components/FindBox'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { InputCmp } from '../../../components/InputCmp'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import { SelectCmp } from '../../../components/SelectCmp'

interface iPesquisaPercepcao {
  nomePesquisa: string
  participantes: number[]
  responsaveisPrioridades: number[]
  responsavelId: string | undefined
  maximoCompetencias: number
  dataInicio: string
  dataFim: string
  aparecerCompetencia?: boolean
  liberarAvaliacaoAutomatica?: boolean
  permitirAlterarPeso?: boolean
  comentario?: boolean
  pesosAvaliadores: iPesosAvaliadores[]
  encerrarAutomatica?: boolean
}

interface iPesosAvaliadores {
  tipo: number
  peso: number
}

export const CriarPesquisaIndicativa: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { pesquisaId } = useParams<{pesquisaId: string}>()
  const path = useLocation().pathname

  const ctDefault = path.includes('Desempenho') ? 'AnaliseDesempenhoIndicativa' : path.includes('Indicativa') ? 'Indicativa' : ''
  const isEdit = path.includes('Editar')
  const pesquisaDefault: iPesquisaPercepcao = {
    nomePesquisa: '',
    participantes: [],
    responsaveisPrioridades: [0],
    responsavelId: '',
    comentario: false,
    aparecerCompetencia: false,
    liberarAvaliacaoAutomatica: false,
    permitirAlterarPeso: false,
    maximoCompetencias: 2,
    dataInicio: '',
    dataFim: '',
    encerrarAutomatica: false,
    pesosAvaliadores: [{ tipo: 6, peso: 1 }, { tipo: 4, peso: 1 }, { tipo: 3, peso: 1 }, { tipo: 2, peso: 1 }, { tipo: 1, peso: 1 }]
  }

  const [Pesquisa, setPesquisa] = useState<iPesquisaPercepcao>(pesquisaDefault)
  const [isValid, setisValid] = useState(true)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [OutroPrioridade, setOutroPrioridade] = useState(false)

  const handleCheckboxParticipanteClick = (value: number): void => {
    if (Pesquisa.participantes.find(e => e === value) !== undefined || (Pesquisa.participantes.length === 6 && value === 7)) {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [] })
      } else {
        const copy = [...Pesquisa.participantes].filter(e => e !== value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    } else {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [0, 1, 2, 3, 4, 6] })
      } else {
        const copy = [...Pesquisa.participantes]
        copy.push(value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    }
  }

  const handleCheckboxPrioridadeClick = (value: number): void => {
    if (Pesquisa.responsaveisPrioridades.find(e => e === value) !== undefined) {
      const copy = [...Pesquisa.responsaveisPrioridades].filter(e => e !== value)
      setPesquisa({ ...Pesquisa, responsaveisPrioridades: copy })
      if (value === 3) setOutroPrioridade(false)
    } else {
      const copy = [...Pesquisa.responsaveisPrioridades]
      copy.push(value)
      setPesquisa({ ...Pesquisa, responsaveisPrioridades: copy })
      if (value === 3) setOutroPrioridade(true)
    }
  }

  function criarPesquisa(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.dataInicio === '' || Pesquisa.dataFim === '') {
      toast({
        title: 'Selecione uma data para a pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (new Date(Pesquisa.dataFim ?? '') < new Date(Pesquisa.dataInicio ?? '')) {
      toast({
        title: 'A data de fim deve ser posterior a data de início da pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Pesquisa.participantes.length === 0 || (Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0)) {
      toast({
        title: 'Selecione no mínimo um stakeholder' + ((Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0) ? ' além da autoavaliação' : ''),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.responsaveisPrioridades.length === 0) {
      toast({
        title: 'Selecione quem definirá as prioridades a serem trabalhadas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (OutroPrioridade && Pesquisa.responsavelId === '') {
      toast({
        title: 'Digite o nome do participante que irá definir as propriedades a serem trabalhadas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!OutroPrioridade) {
      Pesquisa.responsavelId = undefined
    }
    appApi.post(`Pesquisa${ctDefault}`, Pesquisa)
      .then(response => {
        nav(`${path.replace('Criar', 'Formularios')}/${response.data as string}`)
      }).catch(({ response }) => {
        toast({
          title: 'Erro',
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      }).finally(() => {
        toast({
          title: 'Pesquisa criada com sucesso',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function Atualizar(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.dataInicio === '' && Pesquisa.dataInicio === '') {
      toast({
        title: 'Selecione uma data para a pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0 || (Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0)) {
      toast({
        title: 'Selecione no mínimo um stakeholder' + ((Pesquisa.participantes.length === 1 && Pesquisa.participantes[0] === 0) ? ' além da autoavaliação' : ''),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.responsaveisPrioridades.length === 0) {
      toast({
        title: 'Selecione quem definirá as prioridades a serem trabalhadas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!OutroPrioridade) {
      Pesquisa.responsavelId = undefined
    }
    appApi.put(`Pesquisa${ctDefault}/${pesquisaId ?? ''}`, Pesquisa)
      .then(() => {
        toast({
          title: 'Pesquisa editada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        nav(`${path.replace('Editar', 'Formularios')}`)
      }).catch(({ response }) => {
        toast({
          title: 'Erro',
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function AtualizarPeso(tipoAvaliado: number, peso: number): void {
    setPesquisa({
      ...Pesquisa,
      pesosAvaliadores: [...Pesquisa?.pesosAvaliadores.filter(p => p.tipo !== tipoAvaliado), { tipo: tipoAvaliado, peso: peso }]
    })
  }

  function ObterPesquisa(): void {
    appApi.get(`Pesquisa${ctDefault}/${pesquisaId ?? ''}`)
      .then(res => setPesquisa(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
    if (isEdit) {
      ObterPesquisa()
    }
  }, [])

  return (
    <Body>
      <TimelineCriarPesquisa color={['var(--Rosa)']} />
      <Main>
        <h1>Criar pesquisa</h1>
        <WhiteContainer>
          <FormControl marginBottom={'.75rem'} isInvalid={!isValid} >
            <InputCmp
              label='Nome da pesquisa'
              type={'text'}
              placeholder={'Digite um nome'}
              value={Pesquisa.nomePesquisa}
              OnChange={(e) => setPesquisa({ ...Pesquisa, nomePesquisa: e })}
            />
          </FormControl>
          <Flex gap={'.75rem'} mb={'.75rem'}>
            <Box>
              <InputCmp
                label='Início'
                w={'14.4rem'}
                type={'datetime-local'}
                min={new Date().toISOString().slice(0, 16)}
                max={Pesquisa?.dataFim}
                OnChange={(e) => setPesquisa({ ...Pesquisa, dataInicio: e })}
                value={Pesquisa.dataInicio}
              />
            </Box>
            <Box>
              <InputCmp
                label='Fim'
                w={'14.4rem'}
                type={'datetime-local'}
                min={Pesquisa?.dataInicio}
                max={'2099-12-31T23:59'}
                OnChange={(e) => setPesquisa({ ...Pesquisa, dataFim: e })}
                value={Pesquisa.dataFim}
              />
            </Box>
          </Flex>
          <Flex alignItems={'end'} mb={'1.25rem'}>
            <CheckboxCmp isChecked={Pesquisa?.encerrarAutomatica}
              OnChange={(e) => setPesquisa({ ...Pesquisa, encerrarAutomatica: !Pesquisa?.encerrarAutomatica })}>Encerrar quando todos os participantes responderem </CheckboxCmp>
            </Flex>
          <Flex flexDir={'column'}>
            <h3>Defina os stakeholders iniciais</h3>
            <Legenda>Ao adicionar um avaliado, os avaliadores serão preenchidos automaticamente com os eixos selecionados, os demais eixos poderão ser adicionados posteriormente de forma manual.</Legenda>
            <Stack direction={'row'} gap={'.75rem'}>
              <CheckboxCmp
                isChecked={Pesquisa.participantes.includes(0)}
                OnChange={() => handleCheckboxParticipanteClick(0)}
              >Autoavaliação</CheckboxCmp>

              <CheckboxCmp
                isChecked={Pesquisa.participantes.includes(1)}
                OnChange={() => handleCheckboxParticipanteClick(1)}
              >Gestor</CheckboxCmp>

              <Flex gap={'1rem'}>
                <CheckboxCmp
                  isChecked={Pesquisa.participantes.includes(2)}
                  OnChange={() => handleCheckboxParticipanteClick(2)}>
                  Pares
                </CheckboxCmp>

              </Flex>

              <Flex gap={'1rem'}>
                <CheckboxCmp
                  isChecked={Pesquisa.participantes.includes(3)}
                  OnChange={() => handleCheckboxParticipanteClick(3)}
                >Liderados</CheckboxCmp>
              </Flex>

              <Flex gap={'1rem'}>
                <CheckboxCmp
                  isChecked={Pesquisa.participantes.includes(4)}
                  OnChange={() => handleCheckboxParticipanteClick(4)}
                >Outros</CheckboxCmp>
              </Flex>

              <Flex gap={'1rem'}>
                <CheckboxCmp
                  isChecked={Pesquisa.participantes.includes(6)}
                  OnChange={() => handleCheckboxParticipanteClick(6)}
                >Alta liderança</CheckboxCmp>
              </Flex>

              <Flex gap={'1rem'}>
                <CheckboxCmp
                  isChecked={Pesquisa.participantes.length === 6}
                  OnChange={() => handleCheckboxParticipanteClick(7)}
                >Todos</CheckboxCmp>
              </Flex>
            </Stack>
          </Flex>

          <h3 style={{ marginTop: '1.25rem' }}>Quem irá definir as prioridades a serem trabalhadas?</h3>
          <Legenda style={{ marginLeft: '2px', marginBottom: !OutroPrioridade ? '.595rem' : '' }}>Você pode definir mais de uma opção</Legenda>
          <Stack direction={'row'} gap={'.75rem'}>
            <CheckboxCmp
              isChecked={Pesquisa.responsaveisPrioridades.includes(0)}
              OnChange={() => handleCheckboxPrioridadeClick(0)}
            >Automático</CheckboxCmp>
            <CheckboxCmp
              isChecked={Pesquisa.responsaveisPrioridades.includes(1)}
              OnChange={() => handleCheckboxPrioridadeClick(1)}
            >Gestor</CheckboxCmp>
            <CheckboxCmp
              isChecked={Pesquisa.responsaveisPrioridades.includes(2)}
              OnChange={() => handleCheckboxPrioridadeClick(2)}
            >Administrador</CheckboxCmp>
            <Flex gap={'.7rem'}>
              <CheckboxCmp

                isChecked={Pesquisa.responsaveisPrioridades.includes(3)}
                OnChange={() => handleCheckboxPrioridadeClick(3)}
              >
                Outro:
              </CheckboxCmp>

              {OutroPrioridade &&
                <Flex flexDir={'column'}>
                  <FindUser
                    lista={Colaboradores}
                    onChoice={(e) => setPesquisa((prevState) => ({
                      ...prevState,
                      responsavelId: e
                    }))}
                    minWInput={'17rem'}
                    placeholder='Digite o nome do participante'
                    selecionado={Pesquisa.responsavelId}
                  />
                </Flex>
              }
            </Flex>
          </Stack>
          <h3 style={{ marginTop: '1.25rem' }}>Campo para comentário ao final da seleção das competências do avaliador?</h3>
          <RadioGroup mt={'.325rem'} value={Pesquisa?.comentario ? '1' : '2'} onChange={(e) => setPesquisa({ ...Pesquisa, comentario: e === '1' })} size={'sm'}>
            <Stack direction={'row'} gap={'.5rem'}>
              <Radio value={'1'}>Sim</Radio>
              <Radio value={'2'}>Não</Radio>
            </Stack>
          </RadioGroup>
          <h3 style={{ marginTop: '1.25rem' }}>A quantidade de competências que cada avaliador poderá selecionar por categoria</h3>
          <Legenda style={{ marginLeft: '2px' }}>Corresponde a quantidade de itens que serão selecionadas por categoria quando responder a pesquisa</Legenda>
          <SelectCmp defaultValue={'2'} value={Pesquisa.maximoCompetencias.toString()} OnChange={(e) => setPesquisa({ ...Pesquisa, maximoCompetencias: Number(e) })}>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </SelectCmp>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.125rem'} borderRadius={'.25rem'} mt={'.75rem'} px={'0'}>
                <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                  Configurações avançadas
                  <AccordionIcon />
                </Flex>
                <hr style={{ border: '1px solid var(--Azul)', flex: '1' }}></hr>
              </AccordionButton>
              <AccordionPanel p={'.25rem'}>
                <Flex direction='column' gap={'.75rem'} mb={'.75rem'}>
                  <CheckboxCmp
                    isChecked={Pesquisa?.aparecerCompetencia}
                    OnChange={() => setPesquisa({ ...Pesquisa, aparecerCompetencia: !Pesquisa?.aparecerCompetencia })}
                  >Aparecer o nome da competência na hora de responder a pesquisa</CheckboxCmp>
                  <CheckboxCmp
                    isChecked={Pesquisa?.liberarAvaliacaoAutomatica}
                    OnChange={() => setPesquisa({ ...Pesquisa, liberarAvaliacaoAutomatica: !Pesquisa?.liberarAvaliacaoAutomatica })}
                  >Liberar avaliação completa usando priorização automática</CheckboxCmp>
                </Flex>
                <Text fontWeight={'700'} fontSize={'1rem'} mb={'.5rem'}>Definição do peso da resposta por tipo de avaliador</Text>
                <Flex gap={'.75rem'} fontSize={'.875rem'} mb={'.75rem'}>
                  <SelectCmp title='Gestor' my={'.5rem'} OnChange={(e) => AtualizarPeso(1, Number(e))}
                  >
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                  </SelectCmp>
                  <SelectCmp title='Pares' my={'.5rem'} OnChange={(e) => AtualizarPeso(2, Number(e))}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                  </SelectCmp>
                  <SelectCmp title='Liderados' my={'.5rem'} OnChange={(e) => AtualizarPeso(3, Number(e))}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                  </SelectCmp>
                  <SelectCmp title='Outros' my={'.5rem'} OnChange={(e) => AtualizarPeso(4, Number(e))}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                  </SelectCmp>
                  <SelectCmp title='Alta liderança' my={'.5rem'} OnChange={(e) => AtualizarPeso(6, Number(e))}>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                  </SelectCmp>
                </Flex>
                <CheckboxCmp
                  isChecked={Pesquisa?.permitirAlterarPeso}
                  OnChange={() => setPesquisa({ ...Pesquisa, permitirAlterarPeso: !Pesquisa?.permitirAlterarPeso })}
                >Permitir mudança de pesos na hora da priorização das competências</CheckboxCmp>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </WhiteContainer>

        <Flex justifyContent={'end'} marginTop='1rem' gap={'1rem'}>
          <ButtonCmp
            VarColor={'c6'}
            onClick={() => nav(-1)}
          >Voltar</ButtonCmp>

          {isEdit &&
            <ButtonCmp
              onClick={Atualizar}
              VarColor='Green2'
              rightIcon={<FaChevronRight />}
            >Editar pesquisa</ButtonCmp>
          }

          {!isEdit &&
            <ButtonCmp
              onClick={criarPesquisa}
              VarColor='Green2'
              rightIcon={<FaChevronRight />}
            >Criar pesquisa</ButtonCmp>
          }
        </Flex>
      </Main>
    </Body>
  )
}

import { Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text, Textarea } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { useState, useEffect } from 'react'
import { Container, Header } from './styles'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { iNomeId, iPutDesligamento } from '../../../../../../interfaces'
import { FindUser } from '../../../../../../components/FindBox'
import { appApi } from '../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'

interface iModalProps {
  onClose: () => void
  onDesligar: (form: iPutDesligamento) => void
  mensagemAlternativa?: string
  idDesligado?: string
}

export const PopUpDesligarColaborador: React.FC<iModalProps> = ({ mensagemAlternativa, onClose, onDesligar, idDesligado }) => {
  const { Permissoes } = useAuth()
  const [Passo, setPasso] = useState<'Substituir' | 'Padrao' | 'Sim' | 'Nao'>('Padrao')
  const [Trilha, setTrilha] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [Model, setModel] = useState<iPutDesligamento>({
    desligamentoVoluntario: false,
    manterAcesso: false
  })
  const [ColaboradoresProcesso, setColaboradoresProcesso] = useState<iNomeId[]>([])
  const [ColaboradoresGestor, setColaboradoresGestor] = useState<iNomeId[]>([])

  function InternalDesligar(): void {
    setIsLoading(true)
    onDesligar({
      desligamentoVoluntario: Model.desligamentoVoluntario,
      observacao: Model.observacao,
      dataDesligamento: Model.dataDesligamento,
      manterAcesso: Trilha,
      gestorSubstitutoId: Model.gestorSubstitutoId,
      responsavelSubstitutoId: Model.responsavelSubstitutoId
    })
  }

  function getColaboradores(): void {
    appApi.get(`ProcessoOnboard/Responsavel/${idDesligado}`)
      .then(() => {
        appApi.get('Colaborador/Rh')
          .then(e => setColaboradoresProcesso(e.data))
          .catch(err => console.log(err))
      })
      .catch(err => console.log(err))
    appApi.get(`Colaborador/TipoUsuario/${idDesligado}`)
      .then((res) => {
        if (res.data === 1 || res.data === 2) {
          appApi.get('Colaborador/Gestores')
            .then(e => setColaboradoresGestor(e.data))
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (Passo === 'Sim') {
      setTrilha(true)
    }
  }, [Passo])

  useEffect(() => {
    if (!Permissoes.jornada) {
      setPasso('Nao')
    }
    getColaboradores()
  }, [])

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className={'react-modal-content-scroll'}
    >
      <Container width={'50rem'}>
        <Header>
          <Flex width={'100%'} justifyContent={'center'}>
            <h3>Atenção!</h3>
          </Flex>
          <Flex>
            <MdClose size={24} onClick={onClose} />
          </Flex>
        </Header>
        {Passo === 'Substituir' &&
          <Flex justifyContent={'center'} p={'1rem'} flexDir='column' gap={'1rem'}>
            <Text
              fontFamily={'Roboto'}
              fontWeight={'700'}
              textAlign='center'
              fontSize={'1.25rem'}
            >
              Antes de prosseguir com o desligamento, defina os responsáveis substitutos!
            </Text>
            {ColaboradoresProcesso?.length > 0 && <FormControl>
              <FormLabel>Responsável pelos processos de onboarding substituto</FormLabel>
              <FindUser
                selecionado={Model.responsavelSubstitutoId ?? ''}
                lista={ColaboradoresProcesso.filter(e => e.id !== idDesligado)}
                onChoice={(e) => setModel({ ...Model, responsavelSubstitutoId: e })}
              />
            </FormControl>}

            {ColaboradoresGestor?.length > 0 && <FormControl>
              <FormLabel>Gestor substituto</FormLabel>
              <FindUser
                selecionado={Model.gestorSubstitutoId ?? ''}
                lista={ColaboradoresGestor.filter(e => e.id !== idDesligado)}
                onChoice={(e) => setModel({ ...Model, gestorSubstitutoId: e })}
              />
            </FormControl>}

            <Flex gap={'1rem'} justifyContent='center'>
              <ButtonCmp
                VarColor='c6'
                onClick={() => onClose()}
              >Cancelar</ButtonCmp>

              <ElementoTooltip label={((ColaboradoresProcesso.length > 0 && PropInvalida(Model.responsavelSubstitutoId)) || (ColaboradoresGestor.length > 0 && PropInvalida(Model.gestorSubstitutoId))) ? 'Preencha todos os campos!' : ''}>
                <ButtonCmp
                  VarColor='Green2'
                  onClick={InternalDesligar}
                  isDisabled={((ColaboradoresProcesso.length > 0 && PropInvalida(Model.responsavelSubstitutoId)) || (ColaboradoresGestor.length > 0 && PropInvalida(Model.gestorSubstitutoId)))}
                  isLoading={IsLoading}
                >{Model.dataDesligamento ? 'Seguir para seleção da trilha' : 'Desligar colaborador' }</ButtonCmp>
              </ElementoTooltip>
            </Flex>
          </Flex>
        }

        {Passo === 'Padrao' &&
          <Flex flexDir={'column'} padding={'1rem'}>
            <Text fontFamily={'Roboto'} fontWeight={'700'} textAlign='center' fontSize={'.875rem'}>
              {mensagemAlternativa ?? 'Você está desligando um colaborador, gostaria de adicionar uma trilha de offboarding para acompanhar esse deligamento?'}</Text>
            <Flex marginTop={'1rem'} gap={'.5rem'} justifyContent={'center'}>
              <ButtonCmp onClick={onClose} VarColor='c6'>Voltar</ButtonCmp>
              <ButtonCmp onClick={() => setPasso('Nao')} VarColor='Rosa'>Não</ButtonCmp>
              <ButtonCmp onClick={() => setPasso('Sim')} VarColor='Green2'>Sim</ButtonCmp>
            </Flex>
          </Flex>}
        {(Passo === 'Sim' || Passo === 'Nao') &&
          <Flex padding={'1rem'} flexDir={'column'}>
            <Text
              marginTop={'1rem'}
              marginBottom='1rem'
              textAlign={'center'}
              fontSize='1.25rem'
              fontWeight={'700'}
            >Qual o motivo para o desligamento?</Text>
            <Flex justifyContent={'center'}>
              <RadioGroup justifyContent={'center'} onChange={(e) => setModel({ ...Model, desligamentoVoluntario: e === '0' })}>
                <Stack direction={'column'}>
                  <Radio value={'0'}>Colaborador pediu desligamento por vontade própria</Radio>
                  <Radio value={'1'}>A empressa desligou o colaborador</Radio>
                </Stack>
              </RadioGroup>
            </Flex>
            {Passo === 'Sim' &&
              <Flex justifyContent={'center'} marginTop='1rem'>
                <FormControl width={'25rem'}>
                  <FormLabel>Data de desligamento</FormLabel>
                  <Input
                    onChange={(e) => setModel({ ...Model, dataDesligamento: e.target.value })}
                    type={'date'}
                    max={'2099-12-31T23:59'}
                  />
                </FormControl>
              </Flex>}

            {Passo === 'Nao' &&
              <FormControl margin={'1rem 0rem'}>
                <FormLabel fontSize={'md'}>Deixe um comentário abaixo</FormLabel>
                <Textarea
                  height={'8rem'}
                  borderColor={'var(--Gray4)'}
                  placeholder='Digite seu comentário aqui'
                  onChange={(e) => setModel({ ...Model, observacao: e.target.value })}
                />
              </FormControl>}

            <Flex marginTop={'2rem'} gap={'1rem'} justifyContent={'center'}>
              {Passo === 'Sim' && <ButtonCmp
                VarColor='c6'
                onClick={onClose}
              >Cancelar</ButtonCmp>}

              {Passo === 'Sim' && <ButtonCmp
                VarColor='Green2'
                onClick={() => (ColaboradoresGestor.length > 0 || ColaboradoresProcesso.length > 0) ? setPasso('Substituir') : onDesligar({ ...Model, manterAcesso: Trilha })}
                isLoading={IsLoading}
              >{(ColaboradoresGestor.length > 0 || ColaboradoresProcesso.length > 0) ? 'Continuar' : 'Seguir para seleção da trilha'}</ButtonCmp>}

              {Passo === 'Nao' && <ButtonCmp
                VarColor='c6'
                onClick={onClose}
              >Cancelar</ButtonCmp>}

              {Passo === 'Nao' && <ButtonCmp
                VarColor='Green2'
                onClick={() => (ColaboradoresGestor.length > 0 || ColaboradoresProcesso.length > 0) ? setPasso('Substituir') : onDesligar({ ...Model, manterAcesso: Trilha })}
                isLoading={IsLoading}
              >{(ColaboradoresGestor.length > 0 || ColaboradoresProcesso.length > 0) ? 'Continuar' : 'Desligar colaborador'}</ButtonCmp>}
            </Flex>
          </Flex>}
      </Container>
    </Modal>
  )
}

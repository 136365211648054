import styled from 'styled-components'
import { Flex, Column } from '../../../../../styles/styledGlobal'

export const Content = styled.div`
padding:2rem;
width: 592px;
`

export const ModalBody = styled(Column)`
padding-bottom: 1rem;
padding-top: 2rem;
justify-content: center;
align-items:center;
h2{
    color:var(--a3);
    line-height: 2.5rem;
}

span{
    color: var(--a4);
    font-weight: bold;
    font-family: 'Roboto','sans-serif';
    margin-top: .5rem;
}
`

export const ModalFooter = styled(Flex)`
justify-content: center;
padding: 1rem 0rem;
`

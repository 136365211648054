import { Flex, Text } from '@chakra-ui/react'

interface iProps{
  titulo?: number
  varColorCircle: string
  varColorText: string
  fontsize: '16px'|'14px'
}
export const CircleText: React.FC<iProps> = ({ varColorCircle, varColorText, fontsize, titulo, children }) => {
  return (
        <Flex alignItems={'center'}>
            <Flex
                width={'11px'}
                bg={`var(--${varColorCircle})`}
                height={'11px'}
                borderRadius={'full'}
                marginRight='.5rem'
                alignItems={'center'}
            />
            {titulo ? <Text fontWeight={'bold'} style={{ fontSize: '.875rem' }}>{titulo}</Text> : <Text fontWeight={'bold'} style={{ fontSize: '.875rem' }}>0</Text>}
            <span style={{ marginLeft: '.5rem', lineHeight: fontsize, fontSize: fontsize, color: `var(--${varColorText})` }}>{children}</span>
        </Flex>
  )
}

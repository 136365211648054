import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { IJobTitle } from '../../../../../../interfaces'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IUpdateJobTitleModalProps {
  isOpen: boolean
  initialData: IJobTitle
  onRequestClose: () => void
  onUpdateJobTittle: (updatedJobTitle: IJobTitle) => void
}

export const UpdateJobTitleModal: React.FC<IUpdateJobTitleModalProps> = ({
  isOpen,
  initialData,
  onRequestClose,
  onUpdateJobTittle
}) => {
  const [Nome, setNome] = useState('')

  useEffect(() => {
    setNome(initialData.nome)
  }, [initialData.nome])

  function ClearFields(): void {
    setNome('')
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { ClearFields(); onRequestClose() }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h3>Editar função</h3>
        <ModalBody>
          <InputCmp
            mt={'.5rem'}
            OnChange={setNome}
            placeholder='Digite um nome'
            value={Nome}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonCmp VarColor='c6' onClick={() => { ClearFields(); onRequestClose() }}>Fechar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => {
            onUpdateJobTittle({
              id: initialData.id,
              nome: Nome
            }); ClearFields()
          }} >Salvar</ButtonCmp>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
border: 1px solid var(--c4);
border-radius: 0.25rem;
box-shadow: var(--SombraBtns);
padding: .625rem;
max-height: 8rem;
small{
    font-size: .813rem;
}
`

export const RadiusAmarelo = styled.div`
 background:var(--terc5);
 width: 1rem;
 height: 100%;
 border-top-left-radius:0.625rem ;
 border-bottom-left-radius:0.625rem ;
 margin-right: .5rem;
`

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
font-size: .875rem;
`

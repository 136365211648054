/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, Text } from '@chakra-ui/react'
import { HTMLAttributes } from 'react'
import { FaPencilAlt, FaSignal, FaTrash } from 'react-icons/fa'
import { iEscala, iRespostaEscala } from '../../interfaces'
import { parseJwt } from '../../services/token'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'
import { Container } from './styles'
interface iBoxProps extends HTMLAttributes<HTMLDivElement>{
  Selecionado?: boolean
  Escala: iEscala
  jaSelecionada?: string
  escalaPesquisa?: string
  onEdit?: (escala: iEscala) => void
  onDelete?: (escala: iEscala) => void
}

export const BoxEscala: React.FC<iBoxProps> = ({ Selecionado, Escala, onClick, jaSelecionada, escalaPesquisa, onEdit, onDelete }) => {
  return (
        <Container
            active={Selecionado}
            jaSelecionada={jaSelecionada === Escala.id}
            onClick={onClick}
        >
            <FaSignal size={20} />
            <Flex flexDir={'column'} gap={'.25rem'} w={'100%'}>
                <Flex flexDir={'column'}>
                  <Flex alignItems={'center'} gap={'1rem'} justifyContent={'space-between'}>
                    <Flex alignItems={'center'} gap={'.4rem'} as='h3'>{Escala.nome} {jaSelecionada === Escala.id ? <Flex as='span' color={'var(--Green2)'}>(Já selecionado)</Flex> : ''}</Flex>
                    {(onEdit || onDelete) && <Flex alignItems={'center'} gap={'.75rem'}>
                      {onEdit && <ElementoTooltipCmp label='Editar' bg={'var(--Orange)'}>
                        <Flex alignItems={'center'} justifyContent='center' cursor={'pointer'} zIndex={'999'} color={'var(--Orange) !important'} _hover={{ color: 'var(--OrangeHover) !important' }} onClick={() => onEdit(Escala)}>
                          <FaPencilAlt/>
                        </Flex>
                      </ElementoTooltipCmp>}
                      {onDelete && <ElementoTooltipCmp label='Excluir' bg={'var(--Red)'}>
                        <Flex alignItems={'center'} justifyContent='center' cursor={'pointer'} zIndex={'999'} color={'var(--Red) !important'} _hover={{ color: 'var(--RedHover) !important' }} onClick={() => onDelete(Escala)}>
                          <FaTrash/>
                        </Flex>
                      </ElementoTooltipCmp>}
                    </Flex>}
                  </Flex>
                  {escalaPesquisa && escalaPesquisa === Escala.id && <Text fontSize={'12px'} color={'var(--Gray2)'} lineHeight={'12px'}>Escala padrão da pesquisa</Text>}
                </Flex>
                {parseJwt().eid !== '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {(e.peso !== -1) ? `( ${e.peso} )` : ''}</li>
                        )
                      })
                    }
                </ul>}

                {parseJwt().eid === '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {`( ${e.peso} )`}</li>
                        )
                      })
                    }
                </ul>}
            </Flex>
        </Container>
  )
}

import { FaCalendar, FaChevronDown, FaCopy, FaFileImage, FaFileVideo, FaPen, FaTrashAlt } from 'react-icons/fa'
import { Main } from './styles'
import { useEffect, useState } from 'react'
import { iIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Divider, Flex, Menu, MenuList, Text, useToast } from '@chakra-ui/react'
import { Body } from '../../../Layouts/Body'
import { ModalDelete } from '../../../../components/ModalDelete'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { ModalCreateForm } from '../../../../components/ModalCreateForm'
import { ValidarEtapa } from '../../../../Utils/Helper'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { MenuButtonCmp } from '../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../components/NavBar/Components/StyledMenuItem'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'

type LocationProps = {
  trilhaId: string
  processoId?: string
}

export const ConfigImersao: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const { trilhaId, processoId } = useParams<LocationProps>()

  const [Imersoes, setImersoes] = useState<iIntegracao[]>([])
  const [showModal, setshowModal] = useState(false)

  const [Selecionar, setSelecionar] = useState(false)

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)
  const [Id, setId] = useState('')

  const [Imersao, setImersao] = useState<iIntegracao>({
    id: '',
    itensIntegracao: [],
    nome: ''
  })

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId('')
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalCopy(): void {
    setModalCopyIsOpen(false)
    setId('')
  }

  function onOpenModalCopy(id: string): void {
    setModalCopyIsOpen(true)
    setId(id)
  }

  function DeletarImersao(): void {
    appApi.delete(`Imersao/${Id}`)
      .then(response => {
        if (response.status === 200) {
          setImersoes(Imersoes.filter(e => e.id !== Id))
          setModalDeleteIsOpen(false)
        }
      }).catch(err => console.error(err))
  }

  function getImersoes(): any {
    appApi.get('Imersao').then(response => { setImersoes(response.data) })
      .catch(err => console.log(err))
  }

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ImersaoEtapa/${trilhaId}` : processoId ? `ImersaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?imersaoId=${Imersao.id}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function CopyImersao(Texto: string): void {
    const form = {
      texto: Texto
    }
    appApi.post(`Imersao/Copy/${Id}`, form)
      .then(response => {
        if (response.status === 200) {
          getImersoes()
          setModalCopyIsOpen(false)
        }
      })
      .catch(err => console.log(err))
  }

  function CriarImersao(Texto: string): void {
    appApi.post('Imersao', { nome: Texto }).then(response => {
      navigate(response.data)
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    getImersoes()
  }, [])

  return (
    <Body>
      <ModalDelete
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarImersao}
        isOpen={ModalDeleteIsOpen}
        message='Realmente deseja excluir esta imersão?'
      />

      <ModalCreateForm
        onClose={() => setshowModal(false)}
        onCreate={CriarImersao}
        isOpen={showModal}
      />

      <ModalCreateForm
        onClose={onCloseModalCopy}
        onCreate={CopyImersao}
        isOpen={ModalCopyIsOpen}
      />
      <Main>
        {
          !Selecionar
            ? <>
              <Flex justifyContent={'space-between'} mb={'1rem'}>
                <h1>Imersões salvas</h1>
                <Flex gap={'.5rem'}>
                  <ButtonCmp
                    VarColor='c6'
                    onClick={() => navigate(-1)}
                  >Voltar</ButtonCmp>
                  <ButtonCmp VarColor='Green2' onClick={() => setshowModal(true)}>Criar nova lista de imersão</ButtonCmp>
                </Flex>
              </Flex>
              <Flex flexDir={'column'} gap={'.75rem'}>
                {
                  Imersoes.map((e: iIntegracao, i) => {
                    return (
                      <Flex bg={'var(--c1)'} key={i} borderLeft={'3px solid var(--Azul)'} padding={'1rem'} boxShadow={'var(--SombraBackground)'}>
                        <Flex justifyContent={'space-between'} width={'100%'}>
                            <Flex flexDir={'column'} justifyContent='center'>
                                <Flex gap={'.5rem'} height={'fit-content'}>
                                    <Text fontWeight={'700'}>{e.nome}</Text>
                                </Flex>
                            </Flex>
                            <Flex alignItems={'center'} justifyContent={'end'} gap={'2rem'}>
                              {e.dataCriacao && <>
                                    <Flex gap={'.75rem'} alignItems={'center'}>
                                        <FaCalendar color='var(--Azul)' size={24}/>
                                        <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                                            <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'} w='6rem'>
                                                Data de criação
                                            </Text>
                                            <Text lineHeight={'1.125rem'} fontSize={'.875rem'} fontWeight={'800'}>{e.dataCriacao}</Text>
                                        </Flex>
                                    </Flex>
                                    <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                                </>}
                                <Flex gap={'.75rem'} alignItems={'center'}>
                                    <FaFileVideo color='var(--Azul)' size={24}/>
                                    <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                                        <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{e.itensIntegracao.filter(e => e.tipo === 1).length}</Text>
                                        <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>Vídeos </Text>
                                    </Flex>
                                </Flex>
                                <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                                <Flex gap={'.75rem'} alignItems={'center'}>
                                    <FaFileImage color='var(--Azul)' size={24}/>
                                    <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                                        <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{e.itensIntegracao.filter(e => e.tipo === 2).length}</Text>
                                        <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>Imagens </Text>
                                    </Flex>
                                </Flex>
                                <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                                <Flex gap={'.75rem'} alignItems={'center'}>
                                    <BsFillFileEarmarkPdfFill color='var(--Azul)' size={24}/>
                                    <Flex flexDir={'column'} fontWeight={'400'} color={'black'}>
                                        <Text lineHeight={'1.125rem'} fontSize={'1rem'} fontWeight={'800'}>{e.itensIntegracao.filter(e => e.tipo === 3).length}</Text>
                                        <Text lineHeight={'1rem'} fontSize={'.813rem'} fontWeight={'400'}>PDFs </Text>
                                    </Flex>
                                </Flex>
                                <Divider borderWidth={'.5px'} h={'3rem'} borderColor={'var(--c5)'}></Divider>
                                <Flex gap={'.5rem'}>
                                  <ButtonCmp onClick={() => { setSelecionar(true); setImersao(e) }} VarColor='Green2'>
                                    Selecionar
                                  </ButtonCmp>
                                  <Menu>
                                      <MenuButtonCmp
                                          Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                                          VarColor='Rosa'
                                      >Opções
                                      </MenuButtonCmp>
                                      <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
                                          <StyledMenuItem
                                          onClick={() => onOpenModalCopy(e.id)}
                                          >
                                              <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                                              Copiar imersão
                                          </StyledMenuItem>
                                          <StyledMenuItem
                                            onClick={() => {
                                              navigate(`${e.id}`)
                                            }}
                                          >
                                              <FaPen style={{ marginRight: '.375rem' }} size={'14'}/>
                                              Editar
                                          </StyledMenuItem>
                                          <StyledMenuItem
                                            onClick={() => onOpenModalDelete(e.id)}
                                          >
                                              <FaTrashAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                                              Excluir
                                          </StyledMenuItem>
                                      </MenuList>
                                  </Menu>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    )
                  })
                }
              </Flex>
            </>
            : <>
              <h1 style={{ marginBottom: '.75rem' }}>Imersão</h1>
              <CreateEtapaBox
                onGreenAction={AdicionarEtapa}
                onGreyAction={() => setSelecionar(false)}
                keyWord={'imersão'}
              />
            </>
        }
      </Main>
    </Body>
  )
}

import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { sleepAsync } from '../../../../../../../../Utils/Helper'

interface iProps {
  nome?: string
  onUpdate: (form: any) => void
}

export const FormInpuFile: React.FC<iProps> = ({ nome, onUpdate }) => {
  const [Reset, setReset] = useState(false)
  async function ResetInput(): Promise<void> {
    onUpdate(undefined)
    setReset(true)
    await sleepAsync(100)
    setReset(false)
  }
  return (
        <FormControl
            p={'1rem'}
            h='1.95rem'
            display={'flex'}
            alignItems={'center'}
            borderRadius={'.25rem'}
            border={'1px solid var(--Gray4)'}
            bg={nome ? 'green.100' : ''}
        >
            <FormLabel
                w={'100%'}
                cursor={'pointer'}
                color={nome ? 'var(--Gray2)' : 'var(--Gray3)'}
                fontWeight={'400'} m={0} fontSize={'.875rem'}
            >{nome ?? 'Selecione um arquivo'}</FormLabel>
            {!Reset &&
                <Input onChange={(e) => onUpdate(e)} type='file' display={'none'} />
            }
            {nome &&
                <MdClose cursor={'pointer'} color='var(--terc3)' onClick={ResetInput} />
            }
        </FormControl>
  )
}

import styled from 'styled-components'
import { Flex } from '../../styles/styledGlobal'

export const Container = styled(Flex)`
width: 100%;
background: var(--DegradeAzul);
align-items: center;
justify-content: space-between;
`

export const LogoContainer = styled(Flex)`
background: white;

img{
    height: 2.5rem;
    object-fit: cover;
    margin: .5rem 0rem .5rem 1rem;
}

hr{
    width: 0.25rem;
    margin-right: 0.25rem;
    margin-left: .5rem;
    background-color: var(--Azul);
    border: none;
}
`

export const FlexAlignCenter = styled(Flex)`
align-items: center;
`

export const FlexIcon = styled(FlexAlignCenter)`
svg{
    cursor: pointer;
    margin-right: 1rem;
}
`

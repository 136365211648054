/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { iGraficoGestaoPesquisa } from '../../../../RH/GestaoOnboard'

interface iChartProps {
  id: string
  variant: 'etapa' | 'pdi' | 'turnOver'
  theme: string
  grafico: iGraficoGestaoPesquisa
  height?: number
}

export const PieChart: React.FC<iChartProps> = ({ id, grafico, variant, theme, height }) => {
  function getSeries(): any {
    return variant === 'etapa'
      ? [grafico.concluidos, grafico.emAndamento, grafico.atrasados, grafico.aguardandoAprovacao ?? 0]
      : variant === 'pdi'
        ? [grafico.concluidos, grafico.emAndamento, grafico.aguardandoAprovacao, grafico.atrasados]
        : variant === 'turnOver' ? [grafico.voluntario, grafico.involuntario] : []
  }

  const labels =
    variant === 'etapa'
      ? ['Concluídos', 'Em andamento', 'Atrasados', 'Aguardando']
      : variant === 'pdi'
        ? ['Concluídos', 'Em andamento', 'em aprovação', 'Atrasados']
        : variant === 'turnOver' ? ['Involuntária', 'Voluntária'] : []

  const colors =
    variant === 'etapa'
      ? ['#27AE60', '#FFBF2A', '#FF9900', '#828282']
      : variant === 'pdi'
        ? ['#27AE60', '#FFBF2A', '#828282', '#FF9900']
        : variant === 'turnOver' ? ['#CC42A2', '#F2C94C'] : []

  function GerarGrafico(): void {
    const options = {
      series: getSeries(),
      labels: labels,
      colors: colors,
      chart: {
        type: 'donut',
        height: 370,
        width: 340
      },
      legend: {
        show: true,
        enabled: false,
        position: 'right',
        formatter: function (seriesName: any, opts: any) {
          return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]]
        },
        offsetY: -20,
        labels: {
          colors: [theme],
          useSeriesColors: false
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            return ((val / ((grafico.concluidos ?? 0) + (grafico.emAndamento ?? 0) + (grafico.atrasados ?? 0) + (grafico.aguardandoAprovacao ?? 0) + (grafico.involuntario ?? 0) + (grafico.voluntario ?? 0))) * 100).toFixed(0) + '%'
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      responsive: [{
        breakpoint: 620,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico.atrasados, grafico.emAndamento, grafico.concluidos])

  return (
    <div id={id}></div>
  )
}

import { Flex, HStack, Text } from '@chakra-ui/react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { numberFormatter } from '../../pages/AssessmentPI/Dashboard'
import { ButtonCmp } from '../ButtonCmp'
import { SelectCmp } from '../SelectCmp'

interface PaginadorProps {
  qtdElementos: number
  tamanho: number
  paginaAtual: number
  opcoesTamanho?: string[]
  hideTop?: boolean
  setTamanho: (val: number) => void
  next: (val: number) => void
  prev: (val: number) => void
}

export const Paginador: React.FC<PaginadorProps> = ({ children, paginaAtual, qtdElementos, tamanho, hideTop, setTamanho, next, prev, opcoesTamanho }) => {
  function PrevPage(): void {
    if (paginaAtual !== 0) {
      prev(paginaAtual - 1)
    } else {
      prev(Math.ceil(qtdElementos / tamanho) - 1)
    }
  }

  function NextPage(): void {
    if (paginaAtual < Math.ceil(qtdElementos / tamanho) - 1) {
      next(paginaAtual + 1)
    } else {
      next(0)
    }
  }

  return (
    <div>

      {(!hideTop && !(!qtdElementos || qtdElementos === 0)) &&
        <Flex justifyContent={'space-between'} alignItems={'end'} my={'.5rem'}>
          <Flex fontSize={'.875rem'} alignItems={'center'} gap={'.5rem'}>
            <p>Itens por Página</p>
            {<SelectCmp value={tamanho.toString()} OnChange={(e) => setTamanho(parseInt(e))} width={'5.5rem'}>
              {opcoesTamanho?.length
                ? opcoesTamanho.map((e, i) => {
                  return <option key={i} value={e}>{e}</option>
                })
                : <>
                  <option value={'10'}>10</option>
                  <option value={'15'}>15</option>
                  <option value={'25'}>25</option>
                  <option value={'50'}>50</option>
                  <option value={'100'}>100</option>
                </>
              }
            </SelectCmp>}
          </Flex>
          <HStack mt={0} mx={'1.25rem'} p={'.25rem'} fontSize={'.875rem'}>
            <Text>Página:</Text>
            <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={PrevPage} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
            <Text>{numberFormatter.format(paginaAtual + 1)} / {Math.ceil(qtdElementos / tamanho)}</Text>
            <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={NextPage} VarColor='Green2'><FaArrowRight/></ButtonCmp>
          </HStack>
        </Flex>
      }

      {children}

      {!(!qtdElementos || qtdElementos === 0) && <Flex justifyContent={'space-between'} alignItems={'end'} mt={'.5rem'}>
        <Flex fontSize={'.875rem'} alignItems={'center'} gap={'.5rem'}>
          <p>Itens por Página</p>
          <SelectCmp value={tamanho.toString()} OnChange={(e) => setTamanho(parseInt(e))} width={'5.5rem'}>
            {opcoesTamanho?.length
              ? opcoesTamanho.map((e, i) => {
                return <option key={i} value={e}>{e}</option>
              })
              : <>
                <option value={'10'}>10</option>
                <option value={'15'}>15</option>
                <option value={'25'}>25</option>
                <option value={'50'}>50</option>
                <option value={'100'}>100</option>
              </>
            }
          </SelectCmp>
        </Flex>

        <HStack mt={0} mx={'1.25rem'} p={'.25rem'} fontSize={'.875rem'}>
          <Text>Página:</Text>
          <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={PrevPage} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
          <Text>{numberFormatter.format(paginaAtual + 1)} / {Math.ceil(qtdElementos / tamanho)}</Text>
          <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={NextPage} VarColor='Green2'><FaArrowRight/></ButtonCmp>
        </HStack>
      </Flex>}

    </div>
  )
}

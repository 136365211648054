import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { appApi } from '../../../../../services/appApi'
import { FaClipboardList, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { iCardEtapa } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'
interface iCardReuniao {
  etapa: iCardEtapa
  isOpen: boolean
}

interface iReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
}

export const CardReuniaoOne: React.FC<iCardReuniao> = ({ etapa, isOpen }) => {
  const [Model, setModel] = useState<iReuniao>()
  function ObterEtapa(): void {
    appApi.get(`ReuniaoOneOld/${etapa.interacoes[0].idExterno ?? ''}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      ObterEtapa()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={20}/>
        <div>
          <h4>Tipo de reunião</h4>
          <strong>{Model?.tipoReuniao === 0 ? 'Feedback com o gestor' : 'Feedback com o RH'}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={20} style={{ marginTop: Model?.local ? '0' : '' }}/>
        <div>
          <h4>Local da reunião</h4>
          <strong>{Model?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Model?.descricao !== null) && (
          <>
            <FlexIcon>
              <FaClipboardList size={20} />
              <div>
                <h4>Descrição</h4>
                <small dangerouslySetInnerHTML={{ __html: Model?.descricao ?? '' }}></small>
              </div>
            </FlexIcon>
          </>
        )
      }
    </Container>
  )
}

import {
  CardColor, ColorBox,
  InputColor,
  PaletaDeCor, TagBox, FlexIcon
} from './styles'
import { FaQuestionCircle } from 'react-icons/fa'
import { useState } from 'react'
import { Flex, FormControl, FormLabel } from '@chakra-ui/react'
import { TagContainer } from '../../../../../components/TagContainer'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { InputCmp } from '../../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface IModalNovoGrupo {
  isOpen: boolean
  onRequestClose: () => void
  onCreate: (cor: string, nome: string, tags: string[], descricao: string) => void
}
export const ModalNovoGrupo: React.FC<IModalNovoGrupo> = ({ onRequestClose, onCreate, isOpen }) => {
  const [Cor, setCor] = useState('FF6900')
  const [NomeGrupo, setNomeGrupo] = useState('Nome do grupo')
  const [Descricao, setDescricao] = useState('')
  const [TagsSel, setTagsSel] = useState<string[]>([])

  function TrocaCorInput(val: string): void {
    if (val.length === 3 || val.length === 6) {
      setCor(val)
    }
  }

  function addTag(tag: string): void {
    setTagsSel(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSel(TagsSel.filter(r => r !== id))
  }

  const colors = ['FF6900', 'FCB900', '7BDCB5', '00D084', '8ED1FC', '0693E3', 'ABB8C3', 'EB144C', 'F78DA7', '9900EF']

  return (
    <ModalBase isOpen={isOpen ?? false} onClose={onRequestClose} Titulo='Criação da nova trilha'>
      <Flex padding={'1rem'} gap={'2rem'}>
        <CardColor data-cy="cardGrupo" theme={Cor}><small>{NomeGrupo}</small></CardColor>
        <Flex flexDir={'column'}>
          <FormControl>
            <InputCmp
              label='Nome do grupo'
              borderColor={'var(--Gray4)'}
              autoComplete='off'
              type={'text'}
              maxLength={50}
              placeholder='Digite o nome do grupo'
              OnChange={setNomeGrupo}
            />
            {
              NomeGrupo.length === 50 ? <small>Máximo 50 caracteres</small> : ''
            }
          </FormControl>

          <FormControl mt={'1rem'}>
            <TextAreaCmp
              label='Descrição (opcional)'
              borderColor={'var(--Gray4)'}
              autoComplete='off'
              maxLength={120}
              placeholder='Digite o nome do grupo'
              value={Descricao}
              OnChange={setDescricao}
            />
            {
              Descricao.length === 120 ? <small>Máximo 120 caracteres</small> : ''
            }
          </FormControl>

          <PaletaDeCor>
            <FormLabel color={'var(--Gray1)'} fontSize={'.875rem'} mb={'.25rem'}>Selecione uma cor para o card</FormLabel>
            <div>
              {colors.map((e, i) => {
                return (
                  <ColorBox key={i} theme={e} onClick={() => setCor(e)}></ColorBox>
                )
              })}
              <InputColor>
                <div>#</div>
                <input style={{ height: '1.95rem' }} maxLength={6} type={'text'} id='I-PaletaDeCor' placeholder='fff' onChange={(e) => { TrocaCorInput(e.target.value) }} />
              </InputColor>
            </div>
          </PaletaDeCor>
          <TagBox>
            <FlexIcon>
              <FormLabel color={'var(--Gray1)'} fontSize={'.875rem'} mb={'0'}>Adicionar tags</FormLabel>
              <ElementoTooltipCmp background={'var(--Azul)'} label='As tags são utilizadas para facilitar na busca de algum grupo, assim você pode encontrá-los mais facilmente com o texto adicionado na tag'>
                <Flex color={'var(--Azul)'} alignItems={'center'}><FaQuestionCircle /></Flex>
              </ElementoTooltipCmp>
            </FlexIcon>
            <TagContainer
              Selecionadas={TagsSel}
              addTag={addTag}
              removeTag={removeTag}
              isGrey
              tipo={0}
            />
          </TagBox>
          <Flex gap={'.5rem'} justifyContent={'end'}>
            <ButtonCmp VarColor='c6' size='md' onClick={onRequestClose}>Cancelar</ButtonCmp>
            <ButtonCmp VarColor='Green2' size='md' onClick={() => onCreate(Cor, NomeGrupo, TagsSel, Descricao)}>Criar grupo</ButtonCmp>
          </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

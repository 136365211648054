import { Flex, FormControl } from '@chakra-ui/react'
import { useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../ButtonCmp'
import { InputCmp } from '../InputCmp'
import { WhiteModal } from './styles'

interface iModalProps {
  isOpen: boolean
  onCreate: (texto: string) => void
  onClose: () => void
}

export const ModalDuplicarForm: React.FC<iModalProps> = ({ isOpen, onClose, onCreate }) => {
  const [Texto, setTexto] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal>
        <FormControl as={Flex} flexDir={'column'} gap={'.5rem'}>
          <h3>Defina um nome para o formulário duplicado</h3>
          <InputCmp
            value={Texto}
            OnChange={setTexto}
            placeholder='Digite o nome'
          />
        </FormControl>

        <Flex justifyContent={'end'} marginTop={'.75rem'} gap={'.5rem'}>
          <ButtonCmp VarColor='c5' onClick={onClose}>Cancelar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onCreate(Texto)}>Salvar</ButtonCmp>
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

import { Flex, FormControl } from '@chakra-ui/react'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'

interface iModalProps {
  isOpen: boolean
  onCreate: (texto: string) => void
  onClose: () => void
}

export const ModalCreateGrupo: React.FC<iModalProps> = ({ isOpen, onClose, onCreate }) => {
  const [Texto, setTexto] = useState('')

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
        <Flex flexDir={'column'} width='50rem'>
            <Flex borderBottom={'1px solid var(--c6)'}>
                <Flex width={'100%'} justifyContent={'space-between'} padding='1rem'>
                    <h3>Criar grupo</h3>
                    <MdClose onClick={onClose} size={18} cursor={'pointer'}/>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='1rem' padding={'1rem'}>
                <FormControl>
                    <InputCmp label='Nome do grupo' value={Texto} borderColor={'var(--Gray4)'} OnChange={(e) => setTexto(e)}/>
                </FormControl>
            </Flex>
            <Flex padding={'0 1rem 1rem 1rem'} justifyContent={'end'} gap={'.5rem'}>
                <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
                <ButtonCmp VarColor='Green2' onClick={() => { onCreate(Texto); setTexto(''); onClose() }}>Criar</ButtonCmp>
            </Flex>
        </Flex>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { OutlineBox } from '../Components/OutlineBox'
import { HeartChart } from './Components/HeartChart'
import { PieChart } from './Components/PieChart'
import { FlexBetween, FlexChart } from './styles'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { Button, Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import { iNomeId } from '../../../interfaces'
import { parseJwt } from '../../../services/token'

interface iResultado {
  periodicidade: string
  dataInicio: string
  dataFim: string
  filtroDemografia: iNomeId[]
  graficos: iGrafico[]
}

interface iGrafico{
  tipo: number
  series: iHeartSeries[]
  totalPromotores: number
  totalNeutros: number
  totalDetratores: number
  valorNps: number
}

interface iHeartSeries {
  nome: string
  dados: iDados[]
}

interface iDados {
  x: string
  y: string
}

export const EngajamentoeNps: React.FC = () => {
  const dataFimDefault = new Date()
  dataFimDefault.setMonth(dataFimDefault.getMonth() - 12)

  const nav = useNavigate()
  const nomes = ['eNPS', 'NPS', 'Personalizada']

  const [Resultado, setResultado] = useState<iResultado>({} as iResultado)
  const [DemografiaId, setDemografiaId] = useState('')
  const [Periodicidade, setPeriodicidade] = useState('3')
  const [Inicio, setInicio] = useState('')
  const [Fim, setFim] = useState('')

  const [isLoad, setisLoad] = useState(false)

  function getData(): void {
    setisLoad(true)
    const form = {
      periodicidadeGrafico: parseInt(Periodicidade),
      demografiaId: DemografiaId || undefined,
      dataFim: Fim.length > 0 ? Fim + '-01' : undefined,
      dataInicio: Inicio.length > 0 ? Inicio + '-01' : undefined
    }

    appApi.put('NPS/Resultado', form)
      .then(res => {
        setResultado(res.data)
        setisLoad(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getData()
  }, [])

  return (
        <Body>
            <WhiteBox>
                <FlexBetween>
                    <h3>Engajamento eNPS</h3>
                    {
                        (parseJwt().adm === 'True') && (
                            <Button onClick={() => nav('/Engajamento/ConfigNps')}
                                size={'lg'} borderRadius={'12px'} fontWeight={'500'}
                                _hover={{ bg: 'var(--Azul)' }} bg={'var(--Azul)'}
                            >Configurar eNPS</Button>
                        )
                    }
                </FlexBetween>

                <OutlineBox margin='1rem 0rem'>
                    <Flex flexDirection={'column'} width={'100%'}>
                        <Flex width={'100%'} gap={'1rem 1rem'}>

                            <FormControl marginBottom={'1rem'}>
                                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Demografia</FormLabel>
                                <Select onChange={(e) => setDemografiaId(e.target.value)} value={DemografiaId} borderColor={'var(--Gray4)'} size='md'>
                                    <option value={''}>Todas</option>
                                    {
                                        Resultado.filtroDemografia?.map((e) => {
                                          return (
                                                <option key={e.id} value={e.id}>{e.nome}</option>
                                          )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl isDisabled={DemografiaId.length > 0} marginBottom={'1rem'}>
                                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Período</FormLabel>
                                <Select onChange={(e) => setPeriodicidade(e.target.value)} value={Periodicidade} borderColor={'var(--Gray4)'} size='md'>
                                    <option value={'3'}>Mensal</option>
                                    <option value={'4'}>Bimestral</option>
                                    <option value={'5'}>Trimestral</option>
                                    <option value={'6'}>Semestral</option>
                                    <option value={'7'}>Anual</option>
                                </Select>
                            </FormControl>

                            <FormControl marginBottom={'1rem'}>
                                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Início</FormLabel>
                                <Input
                                    size="md"
                                    type="month"
                                    value={Inicio === '' ? new Date().toISOString().split('T')[0].substring(0, 7) : Inicio}
                                    borderColor={'var(--Gray4)'}
                                    onChange={(e) => setInicio(e.target.value)}
                                    max={new Date().toISOString().split('T')[0].substring(0, 7)}
                                />
                            </FormControl>

                            <FormControl marginBottom={'1rem'}>
                                <FormLabel fontSize={'xl'} color={'var(--Gray1)'}>Fim</FormLabel>
                                <Input
                                    size="md"
                                    type="month"
                                    value={Fim === '' ? dataFimDefault.toISOString().split('T')[0].substring(0, 7) : Fim}
                                    borderColor={'var(--Gray4)'}
                                    onChange={(e) => setFim(e.target.value)}
                                    max={new Date().toISOString().split('T')[0].substring(0, 7)}
                                />
                            </FormControl>

                        </Flex>
                        <Flex justifyContent={'end'}>
                            <Button
                                size={'md'}
                                _hover={{ bg: 'var(--Azul)' }}
                                borderRadius={'12px'}
                                fontWeight={'500'}
                                bg={'var(--Azul)'}
                                onClick={() => getData()}
                            >Aplicar filtros</Button>
                        </Flex>
                    </Flex>
                </OutlineBox>

                {
                    Resultado?.graficos?.map((e, i) => {
                      return (
                        <OutlineBox key={i} margin='1rem 0rem'>
                            <Flex flexDir={'column'} width={'100%'}>
                                <h3>{nomes[i]}</h3>
                                <FlexChart>
                                    {
                                        (!isLoad) && (
                                            <PieChart
                                                id={`pieChart-enps-${i}`}
                                                promotores={parseInt(e.totalPromotores.toFixed())}
                                                detratores={parseInt(e.totalDetratores.toFixed())}
                                                neutros={parseInt(e.totalNeutros.toFixed())}
                                                nps={parseInt(e.valorNps.toFixed())}
                                            />
                                        )
                                    }
                                    {
                                        (e.series.length > 0) && (
                                            <HeartChart
                                                id={`HeartMap-enps-${i}`}
                                                Data={e.series}
                                            />
                                        )
                                    }
                                </FlexChart>
                            </Flex>
                         </OutlineBox>
                      )
                    })
                }
            </WhiteBox>
        </Body>
  )
}

import { FaPlus } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { ModalDelete } from '../../../components/ModalDelete'
import { Flex, useToast } from '@chakra-ui/react'
import { iForm } from '../../Desempenho/GestaoFormularios'
import { iCompetencia } from '../../../interfaces'
import { ModalVisualizarQuestoes } from '../../Desempenho/GestaoFormularios/components/ModalVisualizarQuestões'
import { ModalCreateForm } from '../../Desempenho/GestaoFormularios/components/ModalCreateForm'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FormCard } from '../../../components/FormCard'

type navProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const Formularios: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()
  const [IsLoading, setIsLoading] = useState(false)
  const [Formularios, setFormularios] = useState<iForm[]>([])
  const [isOpen, setisOpen] = useState(false)
  const [CompetenciaAtiva, setCompetenciaAtiva] = useState<iCompetencia>()
  const [IsOpenVisualizarQuestoes, setIsOpenVisualizarQuestoes] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  function getFormularios(): void {
    appApi.get('Formulario?modo=0')
      .then(response => setFormularios(response.data))
      .catch(err => console.log(err))
  }

  function DelteFormulario(): void {
    appApi.delete(`Formulario/${Id ?? ''}`).then(() => getFormularios()).catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
    setId(undefined)
  }

  function OnCloseVisualizarQuestoes(): void {
    setIsOpenVisualizarQuestoes(false)
    setCompetenciaAtiva(undefined)
  }

  function SelectFormulario(FormId: string, rota: string): void {
    appApi.put(`FormularioPesquisa/${pesquisaId as string}/Formulario/${FormId}`)
      .then(() => nav(`${pathname.replace('Formularios', rota)}`))
      .catch(err => console.log(err))
  }

  function CriarFormulario(texto: string): void {
    if (texto === '') {
      toast({
        title: 'Informe um nome para o formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post(`FormularioPesquisa/${pesquisaId as string}`, { nome: texto }).then(() => {
      nav(`${pathname.replace('Formularios', 'Formulario')}`)
    }).catch(err => console.log(err))
  }

  function Duplicar(FormName: string): void {
    const form = { texto: FormName }
    setIsLoading(true)
    appApi.post(`Formulario/${Id ?? ''}/Duplicar`, form)
      .then(resp => {
        SelectFormulario(resp.data, 'Formulario')
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormularios()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={isOpen}
        onCreate={CriarFormulario}
        value={false}
        hiddenSwitch
        onClose={() => setisOpen(false)}
      />

      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(texto) => Duplicar(texto)}
        onClose={onCloseModalDuplicar}
      />

      <ModalVisualizarQuestoes
        isOpen={IsOpenVisualizarQuestoes}
        onClose={OnCloseVisualizarQuestoes}
        competencia={CompetenciaAtiva}
      />
      <Flex flexDir={'column'} gap={'1rem'}>
        <Flex alignItems={'center'} justifyContent={'space-between'}>
          <h1>Formulários salvos</h1>
          <Flex gap={'.5rem'}>
            <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar</ButtonCmp>
            <ButtonCmp onClick={() => setisOpen(true)} leftIcon={<FaPlus />} VarColor='Green2'>Criar novo formulário</ButtonCmp>
          </Flex>
        </Flex>

        <Flex flexDir={'column'} as='section' gap={'.75rem'}>
          {
            Formularios.map((e, i) => (
              <FormCard
                formulario={e}
                onEditar={(formId) => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/Formulario/${formId}`)}
                onDelete={(formId) => onOpenModalDelete(formId)}
                onDuplicar={(formId) => { setModalDuplicarIsOpen(true); setId(formId) }}
                onSelect={(formId) => SelectFormulario(formId, 'VisualizarFormulario')}
                onViewCompetencia={(e) => { setCompetenciaAtiva(e); setIsOpenVisualizarQuestoes(true) }}
              />
            ))
          }
        </Flex>
      </Flex>
    </Body>
  )
}

import {
  Button, Flex, FormControl, FormLabel, Input,
  InputGroup, InputRightElement
} from '@chakra-ui/react'
import { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdClose, MdSave } from 'react-icons/md'
import { iPostDemografia } from '../../../../../interfaces'
import { ModalBase } from '../../../../../components/ModalBase'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onCreate: (form: iPostDemografia) => void
}

export const ModalCreateDemografia: React.FC<iModalProps> = ({ isOpen, onClose, onCreate }) => {
  const [Demografias, setDemografias] = useState<string[]>(['Grupo'])
  const [Nome, setNome] = useState('')
  function UpdateDemografiasArray(index: number, Texto: string): void {
    const array = [...Demografias]
    array[index] = Texto
    setDemografias(array)
  }

  function DeleteItemArray(index: number): void {
    const dems: string[] = []
    Demografias.forEach((e, i) => {
      if (index !== i) { dems.push(e) }
    })
    setDemografias(dems)
  }

  return (
        <ModalBase
            Titulo="Nova demografia (grupo para análise)"
            Subtitulo="A análise só poderá ser feita com dados a partir da data que a demografia foi inserida."
            onClose={onClose}
            isOpen={isOpen}
            Width='60rem'
        >
            <Flex flexDirection={'column'} padding={'1rem'} borderBottom={'1px solid var(--c4)'}>
                <FormControl marginBottom={'1rem'}>
                    <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Nome da demografia</FormLabel>
                    <Input
                        placeholder='Digite o nome'
                        width={'18rem'}
                        borderColor={'var(--Gray4)'}
                        value={Nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Grupos</FormLabel>
                    <Flex display={'grid'} gap={'1rem 1rem'}>
                        {
                            Demografias.map((e, i) => {
                              return (
                                    <InputGroup key={i}>
                                        {
                                            (i > 0) && (
                                                <InputRightElement
                                                    children={<MdClose cursor={'pointer'} onClick={() => DeleteItemArray(i)} color='gray.300' />}
                                                />
                                            )
                                        }
                                        <Input
                                            placeholder={`Grupo ${i !== 0 ? i : ''}`}
                                            borderColor={'var(--Gray4)'}
                                            value={e}
                                            onChange={(e) => UpdateDemografiasArray(i, e.target.value)}
                                        />
                                    </InputGroup>
                              )
                            })
                        }

                    </Flex>
                </FormControl>

                <Flex marginTop={'1rem'}>
                    <Button
                        borderRadius={'12px'}
                        fontWeight={'500'}
                        bg={'var(--v2)'}
                        _hover={{ bg: 'var(--v2)' }}
                        leftIcon={<FaPlus />}
                        onClick={() => setDemografias(oldArray => [...oldArray, ''])}
                    >Adicionar</Button>
                </Flex>
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'}>
                <Button
                    borderRadius={'12px'}
                    fontWeight={'500'}
                    marginLeft={'1rem'}
                    bg={'var(--v2)'}
                    _hover={{ bg: 'var(--v2)' }}
                    leftIcon={<MdSave />}
                    onClick={() => onCreate({
                      grupos: Demografias,
                      nomeDemografia: Nome
                    })}
                >Salvar</Button>
            </Flex>
        </ModalBase>
  )
}

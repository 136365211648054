import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { iQuestao, iRespostaXColaborador } from '../../../../../../../Feedback/VisualizarRespostas'
import { Alternativa } from '../components/Alternativa'
import { CaixaSelecao } from '../components/CaixaSelecao'
import { Discursiva } from '../components/Discursiva'

interface iProps {
  questao: iQuestao
  respostas: iRespostaXColaborador[]
}
export const FormResposta: React.FC<iProps> = ({ questao, respostas }) => {
  const styles = StyleSheet.create({
    Container: {
      flexDirection: 'column',
      margin: 16
    },
    Questao: {
      fontSize: 18,
      marginBottom: 5
    }
  })
  return (
    <View style={styles.Container} wrap={false}>
      <Text style={styles.Questao}>{questao.texto}</Text>
      <View style={{ flexDirection: 'column' }}>
        {questao.tipo === 0 &&
          <Alternativa
            questao={questao}
            respostas={respostas}
          />
        }

        {questao.tipo === 5 &&
          <CaixaSelecao
            questao={questao}
            respostas={respostas}
          />
        }

        {questao.tipo === 1 &&
          <Discursiva
            questao={questao}
            respostas={respostas}
          />
        }
      </View>
    </View>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Accordion, Box, Checkbox, Flex, FormControl, FormLabel, HStack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaEdit, FaPlus } from 'react-icons/fa'
import { IoCloseOutline } from 'react-icons/io5'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { SearchCmp } from '../../../components/SearchCmp'
import { SelectCmp } from '../../../components/SelectCmp'
import { SwitchCmp } from '../../../components/SwitchCmp'
import { iSelecao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../../PesquisaIndicativa/components/Timeline'
import { ModalCompetencia } from './components/ModalCompetencia'

export interface iCompetenciaQuestao {
  nome: string
  icone: string
  questao: iQuestao
  id: string
  sistema: boolean
  dataCriacao: string
  quantidadeUsos: number
}
interface iQuestao {
  id: string
  tipo: number
  enunciado: string
  doSistema: boolean
}

interface iModel {
  id: string
  selecoes?: iSelecao[]
  qtdMinQuestoes: number
  fluxoSemPesq: boolean
}

export const FormularioIndicativa: React.FC = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()
  const toast = useToast()
  const [IsLoading, setIsLoading] = useState(false)
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const [Filter, setFilter] = useState<string>()
  const [SlicePorPagina, setSlicePorPagina] = useState<number>(15)

  const [Model, setModel] = useState<iModel>({
    id: '',
    qtdMinQuestoes: 4,
    fluxoSemPesq: false
  })
  const [Competencias, setCompetencias] = useState<iCompetenciaQuestao[]>()
  const [Icones, setIcones] = useState<string[]>([])

  const [IsOpenModal, setIsOpenModal] = useState(false)
  const [Selecionado, setSelecionado] = useState<iCompetenciaQuestao>()
  const [Search, setSearch] = useState('')
  const [PaginaAtual, setPaginaAtual] = useState(1)

  const [Habilitadas, setHabilitadas] = useState(false)

  function onCloseModal(): void {
    setIsOpenModal(false)
    setSelecionado(undefined)
  }

  function onOpenModal(competencia?: iCompetenciaQuestao): void {
    setSelecionado(competencia)
    setIsOpenModal(true)
  }

  async function SelecionarQuestao(CompetenciaId: string, QuestaoId: string): Promise<boolean> {
    try {
      setIsLoading(true)
      const form: iSelecao = {
        competenciaId: CompetenciaId,
        questoesId: [
          QuestaoId
        ]
      }
      const resposta = await appApi.put(`Formulario/${Model?.id ?? ''}/competencia?indicativa=true`, form)
      getFormulario()
      return resposta.status === 200
    } catch (erro) {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  function CriarCompetencia(avatar: string, nome: string, questao: string): void {
    const form = {
      nome: nome,
      icone: avatar,
      questoes: [{
        enunciado: questao,
        tipo: 0
      }]
    }
    setIsLoading(true)
    appApi.post(`Competencia?formId=${Model.id}`, form)
      .then(res => {
        toast({
          title: 'Competência criada e selecionada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onCloseModal()
        getCompetencias()
      })
      .catch((e) => {

      }).finally(() => {
        setIsLoading(false)
      })
  }

  async function AtualizarCompetencia(avatar: string, nome: string, questao: string): Promise<void> {
    const form = {
      nome: nome,
      icone: avatar
    }

    try {
      setIsLoading(true)
      const requestCompetencia = await appApi.put(`Competencia/${Selecionado?.id ?? ''}`, form)
      const requestQuestao = await appApi.put(`Competencia/${Selecionado?.id}/questao/${Selecionado?.questao.id}`, { enunciado: questao })
      if (requestCompetencia.status === 204 && requestQuestao.status === 204) {
        toast({
          title: 'Competência atualizada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }
      getFormulario()
      getCompetencias()
      onCloseModal()
    } catch (erro) {
      toast({
        title: 'Erro ao atualizar a competência',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      })
      console.error('Ocorreu um erro na requisição:', erro)
    }
  }

  async function CriarQuestao(nome: string): Promise<void> {
    const form = {
      enunciado: nome,
      tipo: 0
    }

    const selecao = Model.selecoes?.find(e => e.competenciaId === Selecionado?.id)
    if (selecao) {
      await SelecionarQuestao(Selecionado?.id ?? '', selecao.questoesId[0])
    }
    setIsLoading(true)
    appApi.post(`Competencia/${Selecionado?.id ?? ''}/questao`, form)
      .then(res => {
        toast({
          title: 'Competência atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })

        SelecionarQuestao(Selecionado?.id ?? '', res.data)
        getCompetencias()
        onCloseModal()
      })
      .catch((e) => {
        toast({
          title: 'Ocorreu um erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      }).finally(() => {
        setIsLoading(false)
      })
  }

  function getImgs(): void {
    appApi.get('Assets').then(response => {
      setIcones((response.data as string[]).filter(e => e.includes('svg')))
    }).catch(err => console.log(err))
  }

  function getCompetencias(): void {
    appApi.get('Competencia/categorias').then(response => {
      setCompetencias(response.data)
      getFormulario()
    }).catch(err => console.log(err))
  }

  function getFormulario(): void {
    setIsLoading(true)
    appApi.get(`Formulario/Indicativa?Id=${pesquisaId ?? ''}`).then((res) => {
      setModel(res.data)
    }).catch((err) => console.log(err)).finally(() => setIsLoading(false))
  }

  function ChangePage(valor: number): void {
    if (PaginaAtual === 1 && valor === -1 && Competencias?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length) {
      setPaginaAtual(Math.ceil(Competencias?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length / SlicePorPagina))
    } else if (Competencias?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length && PaginaAtual === Math.ceil(Competencias?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length / SlicePorPagina) && valor === 1) {
      setPaginaAtual(1)
    } else {
      setPaginaAtual(PaginaAtual + valor)
    }
  }

  function FilterBy(tipo: number): void {
    const arr = [...Competencias ?? []]

    if (tipo === 1) {
      arr.sort((a, b) => {
        return b.quantidadeUsos - a.quantidadeUsos
      })
    } else if (tipo === 2) {
      arr.sort((a, b) => {
        return a.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') > b.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return b.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') > a.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') ? 1 : -1
      })
    } else if (tipo === 4) {
      arr.sort((a, b) => {
        return Date.parse(a.dataCriacao) > Date.parse(b.dataCriacao) ? 1 : -1
      })
    }
    setCompetencias(arr)
  }

  function ObterQuantidadeFiltrada(): number {
    if (Competencias === undefined) return 0
    return Math.ceil(Competencias?.filter(e => {
      const blockComp = Model.selecoes?.some(r => r.competenciaId === e.id && !r.questoesId.includes(e.questao.id))
      if (blockComp && Habilitadas) return null
      if (Search === '') {
        return e
      } else if (e.nome.toLocaleLowerCase().includes(Search.toLocaleLowerCase())) {
        return e
      }
      return null
    }
    ).length / SlicePorPagina)
  }

  useEffect(() => {
    getImgs()
    getCompetencias()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      {IsOpenModal &&
        <ModalCompetencia
          competencia={Selecionado}
          icones={Icones}
          onClose={onCloseModal}
          onCreate={CriarCompetencia}
          onUpdate={AtualizarCompetencia}
          onCreateQuestao={CriarQuestao}
        />
      }
      {!Model.fluxoSemPesq && <TimelineCriarPesquisa color={['var(--Azul)', 'var(--Rosa)']} />}
      <Flex gap={'1rem'} maxW={'100%'}>
        <Flex bg={'var(--c1)'} position={'relative'} padding={'1rem'} flexDir={'column'} width={'70%'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'}>
          <h1>{!Model.fluxoSemPesq ? 'Criando' : 'Editando'} formulário</h1>
          <Text mb={'.75rem'} fontSize={'14px'} color={'var(--Gray2)'}>Selecione as competências no menu lateral para serem adicionadas na pesquisa. Você pode alterar sua descrição para melhor compreenção da competência durante a pesquisa</Text>
          <Flex gap={'1rem'}>
            <Box display={'flex'} flexDirection={'column'} w={'50rem'} p={'1rem'} border={'2px solid var(--c2)'} borderRadius={'.25rem'}>
              <Flex justifyContent={'space-between'} mb={'1rem'}>
                <h3>Competências</h3>
                <ButtonCmp fontSize={'12px'} VarColor='Green2' leftIcon={<FaPlus />} onClick={() => onOpenModal()}>Adicionar nova competência</ButtonCmp>
              </Flex>

              <Flex justifyContent={'space-between'} alignItems={'flex-end'} mb={'1rem'}>

                <Flex w={'100%'} gap={'2rem'}>
                  <FormControl w={'fit-content'} flex={'column'} >
                    <SelectCmp title='Ordenação' w={'10rem'} value={Filter} OnChange={(e) => {
                      setFilter(e)
                      FilterBy(parseInt(e))
                    }} borderColor={'var(--Gray4)'}>
                      <option value={'1'}>Mais utilizadas</option>
                      <option value={'2'}>A-Z</option>
                      <option value={'3'}>Z-A</option>
                      <option value={'4'}>Criação</option>
                    </SelectCmp>
                  </FormControl>

                  <FormControl display={'flex'} flexDir='column' justifyContent='center'>
                    <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Aparecer somente as habilitadas</FormLabel>
                    <SwitchCmp value={Habilitadas} OnChange={() => setHabilitadas(!Habilitadas)}/>
                  </FormControl>
                </Flex>

                <SearchCmp
                  OnChange={(e) => { setSearch(e); if (PaginaAtual !== 1) { setPaginaAtual(1) } }}
                  EnterAction={() => {}}
                  placeholder='Busca'
                />

              </Flex>

              <Accordion allowMultiple>
                <Flex flexDir={'column'} gap={'.75rem'}>
                  {ObterQuantidadeFiltrada() > 0 &&
                    Competencias?.filter((e) => {
                      const blockComp = Model.selecoes?.some(r => r.competenciaId === e.id && !r.questoesId.includes(e.questao.id))
                      if (blockComp && Habilitadas) return null

                      if (Search === '') {
                        return e
                      } else if (e.nome.toLocaleLowerCase().includes(Search.toLocaleLowerCase())) {
                        return e
                      }
                      return null
                    }).slice((PaginaAtual - 1) * SlicePorPagina, (PaginaAtual * SlicePorPagina)).map((r, i) => {
                      const blockComp = Model.selecoes?.some(e => e.competenciaId === r.id && !e.questoesId.includes(r.questao.id))

                      return (
                        <Box px={'1rem'} py={'.75rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} style={{ opacity: blockComp ? '0.5' : '1', justifyContent: 'start', color: Model.selecoes?.some(e => e.competenciaId === r.id && e.questoesId.includes(r.questao.id)) ? 'var(--c1)' : '', fontWeight: 700, background: Model.selecoes?.some(e => e.competenciaId === r.id && e.questoesId.includes(r.questao.id)) ? 'var(--Azul)' : '' }} key={i} _hover={(Model.selecoes?.some(e => e.competenciaId === r.id && e.questoesId.includes(r.questao.id)) || blockComp) ? {} : { background: 'var(--AzulOpacoBg)', borderColor: 'var(--Azul)' }} cursor={'pointer'} boxShadow={'var(--SombraBackground)'}>
                          <ElementoTooltipCmp label={blockComp ? 'Uma questão da competência já foi escolhida.' : ''} bg={'var(--Rosa)'}>
                            <Flex w={'100%'} alignItems={'center'} gap={'1rem'} justifyContent='space-between' >
                              <Checkbox colorScheme={'pink'} borderColor={Model.selecoes?.some(e => e.competenciaId === r.id && e.questoesId.includes(r.questao.id)) ? 'white' : 'var(--Gray3)'} w={'100%'} isDisabled={blockComp} isChecked={Model.selecoes?.some(e => e.competenciaId === r.id && e.questoesId.includes(r.questao.id))} key={r.id}
                                onChange={async () => await SelecionarQuestao(r.id, r.questao.id)}
                              >
                                <Flex alignItems={'center'} pl={'.5rem'}>
                                  <div>
                                    <Text fontWeight={'700'} fontSize={'.95rem'}>{r.nome}</Text>
                                    <Text fontWeight={'400'} fontSize={'.813rem'}>{r.questao.enunciado}</Text>
                                  </div>
                                </Flex>
                              </Checkbox>
                              <Flex _hover={{ color: blockComp ? '' : 'var(--Rosa-hover)' }}>
                                <FaEdit onClick={() => onOpenModal(r)} size={18} cursor={'pointer'} />
                              </Flex>
                            </Flex>
                          </ElementoTooltipCmp>
                        </Box>
                      )
                    })
                  }
                  {ObterQuantidadeFiltrada() === 0 &&
                    <h3 style={{ textAlign: 'center', margin: '1rem 0', color: 'var(--Azul)' }}>Não foi encontrado nenhuma competência com essa busca</h3>
                  }
                </Flex>
              </Accordion>

              {(ObterQuantidadeFiltrada() > 0 && (Competencias?.length)) &&
                <Flex justifyContent={'space-between'} alignItems={'end'} my={'.5rem'} w={'full'}>
                  <Flex alignItems={'center'} gap={'0.5rem'} flex={'1'}>
                    <Text fontSize={'.813rem'} fontWeight='medium'> Quantidade de competências por página</Text>
                    <SelectCmp w='8rem' value={SlicePorPagina.toString()} OnChange={(e) => {
                      setSlicePorPagina(Number(e))
                    }} width={'30%'}>
                      <option value={15}>15</option>
                      <option value={30}>30</option>
                      <option value={60}>60</option>
                    </SelectCmp>
                  </Flex>
                  <HStack mt={0} mx={'1.25rem'} p={'.25rem'} fontSize={'.875rem'}>
                    <Text>Página:</Text>
                    <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => ChangePage(-1)} VarColor='a1'><FaArrowLeft/></ButtonCmp>
                    <Text>{`${PaginaAtual}/${ObterQuantidadeFiltrada()}`}</Text>
                    <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={() => ChangePage(+1)} VarColor='a1'><FaArrowRight/></ButtonCmp>
                  </HStack>
                </Flex>
              }

            </Box>
          </Flex>

        </Flex >
        <Flex bg={'var(--c1)'} position={'relative'} padding={'1rem'} flexDir={'column'} width={'70%'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'}>
          <Box>
            <h3>Competências selecionadas</h3>
            <Flex gap={'.5rem'} flexDir={'column'} mt={'.75rem'}>
              {Competencias?.filter(r => Model.selecoes?.some(c => c.questoesId.includes(r.questao.id)))?.map((a, i) => {
                return (
                  <Flex key={i} gap={'.25rem'} borderRadius={'4px'} px={'1rem'} py={'.5rem'} boxShadow={'0 4px 4px var(--AzulOpaco)'} justifyContent={'space-between'} bg={'var(--Azul)'} color={'var(--c1)'}>
                    <Text flex={'1'}>{a.nome}</Text>
                    <Flex mt={'.1rem'} _hover={{ color: 'var(--Rosa-hover)' }}>
                      <FaEdit onClick={() => onOpenModal(a)} cursor={'pointer'} size={18} />
                    </Flex>
                    <Flex _hover={{ color: 'var(--Rosa-hover)' }}>
                      <IoCloseOutline cursor={'pointer'} size={22} onClick={async () => await SelecionarQuestao(a.id, a.questao.id)} />
                    </Flex>
                  </Flex>
                )
              })}
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Flex justifyContent={'end'} mt={'1.5rem'} gap={'.5rem'}>
        {Model.fluxoSemPesq && <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>
          Voltar para gestão de formulários
        </ButtonCmp>}
        {!Model.fluxoSemPesq && <>
          {<ButtonCmp VarColor='c6' onClick={() => nav(-1)}>
            Voltar
          </ButtonCmp>}
          {!(Model.selecoes && Model.qtdMinQuestoes <= Model.selecoes?.length) &&
          <ElementoTooltipCmp bg={'var(--Rosa)'} label={`Seu formulário precisa ter no mínimo ${Model.qtdMinQuestoes} competências selecionadas`}>
            <ButtonCmp
              VarColor='Green2'
              disabled
            >
              Continuar
            </ButtonCmp>
          </ElementoTooltipCmp>}
          {(Model.selecoes && Model.qtdMinQuestoes <= Model.selecoes?.length) && <ButtonCmp
            onClick={() => nav(pathname.replace('Formulario', 'VisualizarFormulario'))}
            VarColor='Green2'
          >Continuar</ButtonCmp>}
        </>}
      </Flex>
    </Body >
  )
}

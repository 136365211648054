import styled from 'styled-components'

export const Flex = styled.div`
display: flex;
`

export const Column = styled(Flex)`
flex-direction: column;
`

export const ColumnNotasLabel = styled(Flex)`
flex-direction: row;
gap: .325rem;
align-items: center;
font-size: .875rem;
color: black;
span{
  font-weight: 900;
  color: var(--Azul);
}
`

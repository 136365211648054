import { FaDownload, FaEye, FaFile, FaFileCsv, FaFileImage, FaFileVideo, FaFolder, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Checkbox, Divider, Flex, FormControl, FormLabel, InputGroup, InputRightElement, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iItemChecklist, iItemIntegracao } from '../../../../../interfaces'
import { BoxIntegracao, BoxHeader, BoxBody, FileBox, LabelFile, GuideText, TabCard } from './styles'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { useState, useEffect } from 'react'
import { PropInvalida, DeleteElementByPosition, FormatarMensagemEditorDeTexto } from '../../../../../Utils/Helper'
import { InputCmp } from '../../../../../components/InputCmp'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'
import { appApi } from '../../../../../services/appApi'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { EditorTexto } from '../../../../../components/EditorTexto/Index'
import { MdClose } from 'react-icons/md'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { LuLayoutList } from 'react-icons/lu'
import { ModalLayouts } from '../ModalLayouts'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

interface iCardProps {
  id: string
  position: number
  qtdItens: number
  hideCheckbox: boolean
  ItemIntegracao: iItemIntegracao
  onDelete: (id: string) => void
  onUpdateInput: (item: iItemIntegracao) => void
  onChangeTipo: (item: iItemIntegracao, tipo?: number) => void
  onChangeTipoDescricao: (item: iItemIntegracao, tipoDescricao?: number) => void
  onReordenar: () => void
}

export const CardImersao: React.FC<iCardProps> = ({ hideCheckbox, onReordenar, qtdItens, ItemIntegracao, position, onDelete, onUpdateInput, id, onChangeTipo, onChangeTipoDescricao }) => {
  const toast = useToast()
  const nav = useNavigate()
  const [SpinnerOn, setSpinnerOn] = useState(false)
  const [Arquivo, setArquivo] = useState('')
  const [Tamanho, setTamanho] = useState('')
  const [NomeArquivo, setNomeArquivo] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('')
  const [TipoDescricao, setTipoDescricao] = useState<number>()
  const [ModalTipoDescricaoIsOpen, setModalTipoDescricaoIsOpen] = useState(false)
  const [TipoFile, setTipoFile] = useState<number>()
  const [ModalTipoFileIsOpen, setModalTipoFileIsOpen] = useState(false)
  const [ModalLayoutIsOpen, setModalLayoutIsOpen] = useState(false)

  const [Model, setModel] = useState<iItemIntegracao>({
    id: '',
    tipo: 0,
    titulo: ''
  })

  function UpdateTextoItemArray(index: number, Texto: string): void {
    const array = [...Model.checklist ?? []]
    array[index].texto = Texto
    onUpdateInput({ ...Model, checklist: array })
  }

  function CheckFileName(fileName: string): boolean {
    const splitArr = fileName.split('.')
    const formato = fileName.split('.')[splitArr.length - 1].toLowerCase()

    if (ItemIntegracao.tipo === 1) {
      switch (formato) {
        case 'mp4':
        case 'mov':
        case 'mpg':
        case 'avi':
        case 'flv':
          return true
        default:
          return false
      }
    }
    if (ItemIntegracao.tipo === 2) {
      switch (formato) {
        case 'jpg':
        case 'png':
        case 'svg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
        case 'webp':
          return true
        default:
          return false
      }
    }
    if (ItemIntegracao.tipo === 3) {
      switch (formato) {
        case 'pdf':
          return true
        default:
          return false
      }
    }

    return false
  }

  const AtualizarArquivo = (event: any): void => {
    setSpinnerOn(true)
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])
    if (!CheckFileName(event.target.files[0].name)) {
      toast({
        title: 'Formato de arquivo inválido',
        status: 'error',
        isClosable: false,
        position: 'top'
      })
      setSpinnerOn(false)
      return
    }

    appApi.post(`ImersaoExterna/${id}/Item/${ItemIntegracao.id}/uploadfile`, formdata)
      .then(resposnse => {
        setTamanho(resposnse.data.size)
        setArquivo(resposnse.data.uri)
        setNomeArquivo(resposnse.data.name)
        setSpinnerOn(false)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false
        })
      }).catch(e => {
        toast({
          title: 'Tamanho do arquivo excede o limite permitido',
          status: 'error',
          position: 'top',
          isClosable: false
        }); setSpinnerOn(false)
      })
  }

  function onInternalReordenar(direction: string): void {
    appApi.put(`Imersao/${id}/Item/${ItemIntegracao.id}/Ordenar?direction=${direction}`)
      .then(resposnse => {
        onReordenar()
        toast({
          title: 'Ordem atualizada com sucesso',
          status: 'success',
          isClosable: false
        })
      }).catch(e => { console.log(e); setSpinnerOn(false) })
  }

  function AtualizarEnvio(val: number): void {
    if (PropInvalida(ItemIntegracao.envioEmail) || ItemIntegracao.envioEmail?.length === 0) {
      onUpdateInput({ ...Model, envioEmail: [val] })
    } else if (ItemIntegracao.envioEmail?.includes(val)) {
      console.log('valu aqui')
      onUpdateInput({ ...Model, envioEmail: [...Model.envioEmail?.filter(t => t !== val) ?? []] })
    } else {
      onUpdateInput({ ...Model, envioEmail: [...Model.envioEmail ?? [], val] })
    }
  }

  function DeletarItemArray(pos: number): void {
    const arr = DeleteElementByPosition([...Model.checklist ?? []], pos)
    onUpdateInput(
      {
        ...Model,
        checklist: arr
      })
  }

  function GetTipoArquivo(tipo: number): string {
    switch (tipo) {
      case 1:
        return 'MP4 ou MOV'
      case 2:
        return 'JPG ou PNG'
      case 3:
        return 'PDF'
      default:
        return ''
    }
  }

  function GetFileIcon(tipo: string): any {
    const tipoFormatado = tipo.toLowerCase()
    switch (tipoFormatado) {
      case 'csv':
        return <FaFileCsv/>
      case 'svg':
      case 'jpeg':
      case 'png':
      case 'jpg':
        return <FaFileImage/>
      case 'pdf':
        return <BsFillFileEarmarkPdfFill/>
      case 'mov':
      case 'mp4':
        return <FaFileVideo/>
      default:
        return <FaFile/>
    }
  }

  function OpenModalChangeFile(tipoFile: number): void {
    if (tipoFile === ItemIntegracao.tipo) return
    setTipoFile(tipoFile)
    setModalTipoFileIsOpen(true)
  }

  function onCloseModalChangeFile(): void {
    setTipoFile(undefined)
    setModalTipoFileIsOpen(false)
  }

  function OpenModalChangeDescricao(tipoDescricao: number): void {
    if (tipoDescricao === ItemIntegracao.tipoDescricao) return
    setTipoDescricao(tipoDescricao)
    setModalTipoDescricaoIsOpen(true)
  }

  function onCloseModalChangeDescricao(): void {
    setTipoDescricao(undefined)
    setModalTipoDescricaoIsOpen(false)
  }

  function PossuiSeta(direcao: string): boolean {
    if (position === 1 && direcao === 'up') return false
    if (position === qtdItens && direcao === 'down') return false

    return true
  }

  useEffect(() => {
    setIsLoading(true)
    setModel(ItemIntegracao)
    setTitulo(ItemIntegracao.titulo)
    if (ItemIntegracao.descricao) {
      setDescricao(ItemIntegracao.descricao)
    }
    if (ItemIntegracao.arquivo !== null) {
      if (ItemIntegracao.arquivo !== undefined) {
        setArquivo(ItemIntegracao.arquivo.uri)
        setTamanho(ItemIntegracao.arquivo.size)
        setNomeArquivo(ItemIntegracao.arquivo.name)
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
  }, [ItemIntegracao])

  return (
    <BoxIntegracao>
      <ModalDelete
        isOpen={ModalTipoDescricaoIsOpen}
        message='Ao alterar o conteúdo que acompanhará o arquivo, seu checklist será apagada'
        onRequestClose={onCloseModalChangeDescricao}
        center
        onConfirm={() => { onChangeTipoDescricao(ItemIntegracao, TipoDescricao) }}
      />
      <ModalDelete
        isOpen={ModalTipoFileIsOpen}
        message='Ao trocar o tipo de arquivo, o anterior será excluído, tem certeza disto?'
        onRequestClose={onCloseModalChangeFile}
        onConfirm={() => { onChangeTipo(ItemIntegracao, TipoFile); setArquivo('') }}
        center
      />
      <ModalLayouts
        selecionado={ItemIntegracao.layout}
        isOpen={ModalLayoutIsOpen}
        onConfirm={(e) => {
          onUpdateInput({ ...ItemIntegracao, descricao: Descricao, titulo: Titulo, layout: e })
          toast({
            title: 'Layout atualizado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top',
            duration: 3000
          })
        }}
        onRequestClose={() => setModalLayoutIsOpen(false)}
        IsChecklist={ItemIntegracao.tipoDescricao === 1}
        type={ItemIntegracao.tipo}
      />
      <BoxHeader>
        <Flex gap={'.5rem'} alignItems={'center'}>
          <Flex flexDir={'column'}>
            {PossuiSeta('up') && <Flex alignItems={'center'} justifyContent='center' _hover={{ color: 'var(--Azul)' }}><AiFillCaretUp cursor={'pointer'} onClick={() => onInternalReordenar('up')} /></Flex>}
            {PossuiSeta('down') && <Flex alignItems={'center'} justifyContent='center' _hover={{ color: 'var(--Azul)' }}><AiFillCaretDown cursor={'pointer'} onClick={() => onInternalReordenar('down')} /></Flex>}
          </Flex>
          <span>{position}.</span>
        </Flex>
        <ElementoTooltipCmp bg={'var(--Red)'} label='Excluir item da integração'>
          <FaTrashAlt color='var(--Red)' onClick={() => onDelete(ItemIntegracao.id)} />
        </ElementoTooltipCmp>
      </BoxHeader>

      <BoxBody>
        <Flex gap={'2rem'}>
          {
            <Flex flexDir={'column'} gap={'.625rem'}>
              <FileBox>
                {
                  Arquivo.length === 0
                    ? <Flex flexDir={'column'} gap={'.75rem'} justifyContent={'center'} alignItems={'center'}>
                      <FaDownload color='var(--Azul)' size={35} />
                      {
                        SpinnerOn
                          ? <Spinner my={'2rem'}></Spinner>
                          : <>
                        <Flex flexDir={'column'} gap={'.25rem'}>
                          <GuideText>Arraste e solte o arquivo no formato <strong>{GetTipoArquivo(ItemIntegracao.tipo)}</strong> para fazer o envio</GuideText>
                          <small>Tamanho limite: 25 MB</small>
                        </Flex>
                        <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                        <LabelFile theme={'1961E0'} htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelFile></>
                      }
                    </Flex>
                    : <>
                      {
                        SpinnerOn
                          ? <Spinner my={'2rem'}></Spinner>
                          : <>
                        <Flex marginBottom={'1rem'} fontSize={'4rem'} flexDir={'column'}>
                          <Flex fontSize={'24px'} pos={'absolute'} right={'1rem'} top={'1rem'} justifyContent={'end'} _hover={{ color: 'var(--Azul)' }} cursor={'pointer'} transition={'.3s'} onClick={() => setModalLayoutIsOpen(true)}>
                            <ElementoTooltipCmp bg={'var(--Azul)'} label='Alterar layout de apresentação'>
                              <LuLayoutList />
                            </ElementoTooltipCmp>
                          </Flex>
                          <Text color={'var(--Azul)'}>
                            {GetFileIcon(NomeArquivo.split('.')[NomeArquivo.split('.').length - 1])}
                          </Text>
                          <Flex flexDir={'column'} fontSize={'.875rem'}>
                            <span>{NomeArquivo}</span>
                            <small>Tamanho: <strong>{Tamanho}</strong></small>
                          </Flex>
                        </Flex>
                        <div>
                          <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} /><LabelFile theme={'1961E0'} htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelFile>
                        </div>
                        </>
                      }
                    </>
                }
              </FileBox>
              <Flex gap={'1rem'} justifyContent={'center'} fontSize={'.875rem'}>
                <TabCard isSelected={ItemIntegracao.tipo === 1} onClick={() => { if (Arquivo.length !== 0) { OpenModalChangeFile(1) } else { onChangeTipo(ItemIntegracao, 1) } }}>Vídeo</TabCard>
                <TabCard isSelected={ItemIntegracao.tipo === 2} onClick={() => { if (Arquivo.length !== 0) { OpenModalChangeFile(2) } else { onChangeTipo(ItemIntegracao, 2) } }}>Imagem</TabCard>
                <TabCard isSelected={ItemIntegracao.tipo === 3} onClick={() => { if (Arquivo.length !== 0) { OpenModalChangeFile(3) } else { onChangeTipo(ItemIntegracao, 3) } }}>PDF</TabCard>
              </Flex>
            </Flex>
          }
          <Tabs index={ItemIntegracao.tipoDescricao} w={'100%'} variant='line'>
            <TabList>
              <Tab onClick={() => { if (Model.checklist && Model.checklist?.length > 0) { OpenModalChangeDescricao(0) } else { onChangeTipoDescricao(ItemIntegracao, 0); onCloseModalChangeDescricao() } }} borderColor={'#E2E8F0'} backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderColor: 'var(--Azul)' }}>Título e descrição</Tab>
              {!hideCheckbox && <Tab onClick={() => { onChangeTipoDescricao({ ...ItemIntegracao, descricao: 'Marque apenas o que foi feito ou realizado até o momento, caso queira, você pode adicionar uma observação:' }, 1); onCloseModalChangeDescricao() }} borderColor={'#E2E8F0'} backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderColor: 'var(--Azul)' }}>Checklist</Tab>}
            </TabList>
            <TabPanels width={'100%'}>
              <TabPanel px={'0'}>
                <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
                  <FormControl>
                    <InputCmp
                      label='Título'
                      value={Titulo}
                      OnChange={setTitulo}
                      placeholder='Propósito, missão, visão, valores'
                      onBlur={() => onUpdateInput({
                        ...ItemIntegracao,
                        titulo: Titulo,
                        descricao: Descricao
                      })}
                    />
                  </FormControl>

                  <FormControl my={'1rem'} style={ItemIntegracao.layout === 3 ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                    <Flex flexDir={'column'} pointerEvents={ItemIntegracao.layout === 3 ? 'none' : 'all'} minW={'100%'}>
                      <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Descrição</FormLabel>
                      <EditorTexto
                        isLoading={IsLoading}
                        initialValue={Descricao}
                        placeholder='Deixe um texto para aparecer junto a imagem'
                        className='editorSt-v2'
                        onChange={(e: string) => { setDescricao(e) }}
                        onBlur={() => onUpdateInput({
                          id: ItemIntegracao.id,
                          titulo: Titulo,
                          descricao: FormatarMensagemEditorDeTexto(Descricao),
                          tipo: ItemIntegracao.tipo,
                          tipoDescricao: ItemIntegracao.tipoDescricao
                        })}
                      />
                    </Flex>
                  </FormControl>
                </Flex>
              </TabPanel>
              <TabPanel px={'0'}>
                  <FormControl marginBottom={'1rem'}>
                    <InputCmp
                      label='Título'
                      value={Model.titulo}
                      OnChange={(e) => setModel({
                        ...Model, titulo: e
                      })}
                      onBlur={(e) => onUpdateInput({
                        ...Model, titulo: e.target.value
                      })}
                      placeholder='Digite o titulo'
                    />
                  </FormControl>

                  <FormControl marginBottom={'1rem'} style={ItemIntegracao.layout === 3 ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                    <Flex flexDir={'column'} pointerEvents={ItemIntegracao.layout === 3 ? 'none' : 'all'} minW={'100%'}>
                      <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Caso queira, edite a instrução para o preenchimento do check list pelo colaborador</FormLabel>
                      <EditorTexto
                        isLoading={IsLoading}
                        initialValue={Descricao}
                        placeholder='Deixe um texto para aparecer junto a imagem'
                        className='editorSt-v2-mini'
                        onChange={(e: string) => { setDescricao(e) }}
                        onBlur={() => onUpdateInput({
                          ...ItemIntegracao,
                          titulo: Titulo,
                          descricao: FormatarMensagemEditorDeTexto(Descricao)
                        })}
                      />
                    </Flex>
                  </FormControl>

                  <Flex mt='1rem' direction={'column'}>
                    <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Quem deve receber o e-mail das respostas</FormLabel>
                    <Stack spacing={5} direction='row'>
                      <CheckboxCmp
                        isChecked={Model.envioEmail?.includes(0)}
                        OnChange={() => AtualizarEnvio(0)}
                      >
                        Gestor
                      </CheckboxCmp>
                      <CheckboxCmp
                        isChecked={Model.envioEmail?.includes(1)}
                        OnChange={() => AtualizarEnvio(1)}
                      >
                        RH
                      </CheckboxCmp>

                    </Stack>
                  </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        {ItemIntegracao.tipoDescricao === 1 && <Flex flexDir={'column'}>
          <Divider borderWidth={'1.25px'} borderColor={'var(--c6)'} mb={'1rem'}/>
          <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.5rem'} wordBreak={'keep-all'}>Coloque abaixo os itens que são entregáveis a um colaborador em onboarding</FormLabel>
          <Flex gap={'.75rem'} flexDirection={'column'}>
            {
              Model.checklist?.map((e: iItemChecklist, i) => {
                return (
                  <Flex key={i}>
                    <Checkbox size={'lg'} marginRight={'1rem'} borderColor={'var(--Gray4)'} />
                    <InputGroup width={'100%'}>
                      <InputRightElement
                        h={'1.95rem'}
                        children={<ElementoTooltipCmp bg={'var(--Red)'} label='Excluir item da checklist'><MdClose cursor={'pointer'}
                          onClick={() => DeletarItemArray(i)}
                          color='var(--terc3)'
                        /></ElementoTooltipCmp>}
                      />

                      <InputCmp
                        value={e.texto}
                        onBlur={() => onUpdateInput(Model)}
                        OnChange={(r) => UpdateTextoItemArray(i, r)}
                        placeholder='Digite o nome'
                      />
                    </InputGroup>
                  </Flex>
                )
              })
            }
            <ButtonCmp width={'fit-content'} VarColor='Green2' onClick={() => onUpdateInput({ ...Model, checklist: [...Model.checklist ?? [], { check: false, texto: '' }] })
            } leftIcon={<FaPlus />}>Adicionar checkbox</ButtonCmp>
          </Flex>
          <Alert borderRadius={'.25rem'} mb={'1rem'} justifyContent='space-between' status='warning' px={'.75rem'} mt={'1rem'} py={'.75rem'}>
            <Flex flexDir={'column'} fontSize={'.75rem'}>
              <Flex alignItems={'center'}>
                <AlertIcon color={'#FF9900'} mr={'0.325rem'} h={'1.325rem'}/>
                <AlertTitle lineHeight={'1.25rem'}>IMPORTANTE: </AlertTitle>
                <AlertDescription lineHeight={'1.25rem'}>Se o checkbox não for selecionado você receberá uma notificação por e-mail com um aviso sobre a informação sem o check do colaborador</AlertDescription>
              </Flex>
            </Flex>
          </Alert>
        </Flex>}
      </BoxBody>
      <Flex justifyContent={'end'}>
        <ElementoTooltipCmp bg={'var(--Red)'} label={Arquivo.length === 0 ? 'Adicione um arquivo no formato escolhido para pré-visualizar o item' : ''}>
          <ButtonCmp
            leftIcon={<FaEye />}
            fontWeight={'500'}
            borderRadius={'12px'}
            VarColor={'Rosa'}
            isDisabled={Arquivo.length === 0}
            onClick={() => nav(`Item/${ItemIntegracao.id}`)}
          >Pré-visualizar</ButtonCmp>
        </ElementoTooltipCmp>
      </Flex>
    </BoxIntegracao>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { iPropsGraficoGestor } from '../..'
import { v4 as uuidv4 } from 'uuid'

interface iProps {
  informacoes: iPropsGraficoGestor
}

export const MediaAvaliacoesChartGestor: React.FC<iProps> = ({ informacoes }) => {
  const id = uuidv4()
  function AbreviarNomesGrandes(): string[] {
    return informacoes.competencias.map((e) => {
      if (e.length <= 20) {
        return e
      } else {
        return e.substring(0, 20) + '...'
      }
    })
  }

  function getSeries(): any {
    const data = [{
      name: 'Média do colaborador',
      data: informacoes.dados
    }]

    return data
  }

  const colors = [
    'rgb(227, 34, 0)',
    'rgb(227, 193, 0)',
    'rgb(0, 227, 150)',
    'rgb(0, 153, 102)'
  ]

  function GerarGrafico(): void {
    const data = {
      texto: `${informacoes.escala}`
    }

    if (informacoes.dados.length === 0) {
      data.texto = 'Não há resultados nesse período'
    }

    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 120 * informacoes.competencias.length,
        width: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 2,
          dataLabels: {
            position: 'top',
            orientation: 'horizontal'
          },
          colors: {
            ranges: [
              {
                from: 0,
                to: informacoes.fator === 10 ? 2.9 : informacoes.fator === 5 ? 1.45 : 1.74,
                color: colors[0]
              },
              {
                from: informacoes.fator === 10 ? 3 : informacoes.fator === 5 ? 1.5 : 1.8,
                to: informacoes.fator === 10 ? 5.9 : informacoes.fator === 5 ? 2.95 : 3.54,
                color: colors[1]
              },
              {
                from: informacoes.fator === 10 ? 6 : informacoes.fator === 5 ? 3 : 3.6,
                to: informacoes.fator === 10 ? 8.9 : informacoes.fator === 5 ? 4.45 : 5.34,
                color: colors[2]
              },
              {
                from: informacoes.fator === 10 ? 9 : informacoes.fator === 5 ? 4.5 : 5.4,
                to: informacoes.fator === 10 ? 10 : informacoes.fator === 5 ? 5 : 6,
                color: colors[3]
              }
            ]
          }
        }
      },
      title: {
        text: data.texto,
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: '700',
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: 'rgb(55, 61, 63)'
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: 0,
        offsetX: 50,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function (val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: false
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        show: false,
        categories: AbreviarNomesGrandes(),
        labels: {
          show: false
        }
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        show: true,
        min: 0,
        max: informacoes.fator
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [informacoes])

  return (
      <Flex justifyContent='center' alignItems='center' w={'100%'}>
        <Flex w={'90%'} id={`chart-${id}`} />
      </Flex>
  )
}

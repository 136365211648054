/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Button } from '../../../../../components/Button'
import { appApi } from '../../../../../services/appApi'
import { ModalBase } from '../../../../../components/ModalBase'
import { FileInput } from './styles'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface iModalComunicacao{
  isOpen: boolean
  imersaoId: string
  onClose: () => void
}

interface iComunicao{
  descricao: string
  uri: string
}

export const ModalComunicao: React.FC<iModalComunicacao> = ({ isOpen, imersaoId, onClose }) => {
  const toast = useToast()

  const [Url, setUrl] = useState('')
  const [Nome, setNome] = useState('')
  const [Descriacao, setDescriacao] = useState('')
  const [isEdit, setisEdit] = useState(false)

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Url !== undefined && Url.length > 0) {
      appApi.post(`UploadFile?url=${Url}`, formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        setNome(resposnse.data.name)
        toast({
          title: 'Arquivo atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    } else {
      appApi.post('UploadFile', formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        setNome(resposnse.data.name)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    }
  }

  const getComunicao = (): void => {
    appApi.get(`ImersaoExterna/${imersaoId}/Comunicacao`).then(res => {
      if (res.status === 200) {
        setUrl(res.data.uri)
        setDescriacao(res.data.descricao)
        setisEdit(true)
      }
    }).catch(err => console.log(err))
  }

  const PostComunicacao = (): void => {
    const form: iComunicao = {
      descricao: Descriacao,
      uri: Url ?? ''
    }

    appApi.post(`ImersaoExterna/${imersaoId}/Comunicacao`, form)
      .then(() => {
        toast({
          title: 'Comunicação criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getComunicao()
      })
      .catch(err => console.log(err))
  }

  const PutComunicacao = (): void => {
    const form: iComunicao = {
      descricao: Descriacao,
      uri: Url ?? ''
    }
    appApi.put(`ImersaoExterna/${imersaoId}/Comunicacao`, form)
      .then(() => {
        toast({
          title: 'Comunicação atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getComunicao()
  }, [])

  return (
    <ModalBase
      Titulo="Adicionar comunicação"
      isOpen={isOpen}
      onClose={onClose}
      Width='70rem'
      Subtitulo='Adicione uma imagem, vídeo ou texto para ser exbido no aplicativo juntamente com a pesquisa a ser realizada'
    >
      <Flex padding={'1rem'} flexDir={'column'} fontSize={'.875rem'}>
        <Flex onClick={() => console.log(Url.length)} boxShadow={'var(--SombraBackground)'} flexDir={'column'} marginBottom={'1rem'} bg={'var(--c2)'} padding={'1rem'} borderRadius={'.25rem'}>
          <span>Selecione um arquivo de imagem (*.jpg ou *.png) ou de vídeo(*.mp4) com o vídeo de sensibilização (tamanho max. 30MB)</span>
          <FileInput>
            <input
              id='i-Com'
              type={'file'}
              onChange={AtualizarArquivo}
              style={{ display: Url.length > 0 ? 'none' : 'inherit', borderRadius: '.25rem' }}
            />
            {Url.length > 0 &&
              <Flex alignItems={'center'}>
                <label htmlFor='i-Com'>Trocar arquivo</label>
                <span>Arquivo selecionado: <strong>{Nome}</strong></span>
              </Flex>
            }
          </FileInput>
        </Flex>
        <FormControl>
          <TextAreaCmp
            label='Digite o texto que será exibido na tela de início:'
            OnChange={setDescriacao}
            value={Descriacao}
          />
        </FormControl>
      </Flex>
      <Flex justifyContent={'end'} padding={'0 1rem 1rem 1rem'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={onClose}>Fechar</ButtonCmp>
        {
          isEdit
            ? <ButtonCmp onClick={PutComunicacao} VarColor='Green2'>Confirmar alterações e editar</ButtonCmp>
            : <ButtonCmp onClick={PostComunicacao} VarColor='Green2'>Adicionar</ButtonCmp>
        }
      </Flex>
    </ModalBase>
  )
}

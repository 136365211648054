/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Flex, Text } from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { iBox } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface iProps {
  box: iBox
}

export const ItemNineBox: React.FC<iProps> = ({ box }) => {
  const nav = useNavigate()
  return (
    <Flex border={'2px solid var(--c4)'} borderRadius={'.25rem'} padding={'.75rem 1rem'} flexDirection={'column'} gap={'.75rem'} alignItems={'center'}>
      <Text fontWeight={'800'}>{box.titulo}</Text>
      {/* <Flex flexDirection={'row'} flexWrap={'wrap'} gap={'.4rem'}>
        {
          box.tags.map((tag, i) => {
            return (
              <Box
                key={i}
                as='small'
                bg={'var(--Azul)'}
                boxShadow={'0 0.125rem .5rem rgba (0, 0, 0, 0.25)'}
                borderRadius={'.5rem'}
                color={'white'}
                px={'.5rem'}
                fontSize={'0.75rem'}
                py={'.25rem'}
              >{tag.substring(0, 10)}</Box>
            )
          })
        }
      </Flex> */}
      <Flex boxSizing={'border-box'} mr={'1.325rem'}>
        <img src={'https://sigah.blob.core.windows.net/onboard/f4008767-1667-43cf-9c80-83cfe4e37ee9.png'} />
      </Flex>
      <ButtonCmp
        OutlineColor='Green2'
        leftIcon={<BsPencilSquare />}
        onClick={() => nav(`NineBox/${box.id}`)}
      >Editar</ButtonCmp>
    </Flex>
  )
}

import { Flex, Tooltip } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { PropInvalida } from '../../../../../Utils/Helper'
import { iLinhaTabela } from '../../../ResultadoIndividual'
import { StyledTd, TableDiv } from './styles'

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaConsolidado: number
  mediaSemAuto: number
}

interface iProps {
  linhas: iLinhaTabela[]
  mediaGeral: number
  temColuna: (tipo: number) => boolean
}

export const Tabela: React.FC<iProps> = ({ linhas, temColuna, mediaGeral }) => {
  const [open, setOpen] = useState<number[]>([])

  function CriaDropQuestoes(e: iLinhaTabela[], nomeCompetencia: string): ReactNode {
    return (
      <>
        {e.map((questao, index) => {
          return (
            <tr key={index}>
              <td className='dropDownTr'>
                <Flex alignItems={'center'}>
                  <Flex flex={'1'} marginRight={'4px'}>
                    <Tooltip hasArrow label={questao.competencia} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                      {nomeCompetencia + ` ${index + 1}`}
                    </Tooltip>
                  </Flex>
                </Flex>
              </td>
              {temColuna(0) && <StyledTd >{PropInvalida(questao.autoAvaliacao) ? '-' : questao.autoAvaliacao}</StyledTd>}
              {temColuna(1) && <StyledTd >{PropInvalida(questao.gestor) ? '-' : questao.gestor}</StyledTd>}
              {temColuna(2) && <StyledTd >{PropInvalida(questao.pares) ? '-' : questao.pares}</StyledTd>}
              {temColuna(3) && <StyledTd >{PropInvalida(questao.liderados) ? '-' : questao.liderados}</StyledTd>}
              {temColuna(4) && <StyledTd >{PropInvalida(questao.outros) ? '-' : questao.outros}</StyledTd>}
              {temColuna(6) && <StyledTd >{PropInvalida(questao.altalideranca) ? '-' : questao.altalideranca}</StyledTd>}
              {temColuna(7) && <StyledTd >{PropInvalida(questao.consolidado) ? '-' : questao.consolidado}</StyledTd>}
              {!temColuna(7) && <StyledTd>{PropInvalida(questao.mediaSemAuto) ? '-' : questao.mediaSemAuto}</StyledTd>}
            </tr>
          )
        })}
      </>
    )
  }

  function TooltipLabel(e: iLinhaTabela): string {
    if (e.questoes.length > 1) {
      return `${e.questoes.length} Questões`
    } else {
      return e.questoes[0].competencia ?? 'Sem descrição'
    }
  }

  const toggle = (e: number): void => {
    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaConsolidado: 0,
      mediaSemAuto: 0
    }

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.autoAvaliacao ?? '')
      if (!isNaN(mediaCompetencia)) {
        medias.mediaAutoAval += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.gestor ?? '')
      if (!isNaN(mediaCompetencia)) {
        medias.mediaGestor += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.pares ?? '')
      if (!isNaN(mediaCompetencia)) {
        medias.mediaPares += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.liderados ?? '')
      if (!isNaN(mediaCompetencia)) { medias.mediaLiderados += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.outros ?? '')
      if (!isNaN(mediaCompetencia)) { medias.mediaOutros += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.altalideranca ?? '')
      if (!isNaN(mediaCompetencia)) { medias.mediaAltaLideranca += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = parseFloat(e.consolidado ?? '')
      if (!isNaN(mediaCompetencia)) { medias.mediaConsolidado += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      medias.mediaSemAuto = mediaGeral
    })

    const mediasCalculado = [{
      mediaAutoAval: medias.mediaAutoAval / linhas.filter(e => e.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / linhas.filter(e => e.gestor).length,
      mediaPares: medias.mediaPares / linhas.filter(e => e.pares).length,
      mediaLiderados: medias.mediaLiderados / linhas.filter(e => e.liderados).length,
      mediaOutros: medias.mediaOutros / linhas.filter(e => e.outros).length,
      mediaAltaLideranca: medias.mediaAltaLideranca / linhas.filter(e => e.altalideranca).length,
      mediaConsolidado: medias.mediaConsolidado / linhas.filter(e => e.consolidado).length,
      mediaSemAuto: medias.mediaSemAuto
    }]

    return mediasCalculado
  }

  return (
    <Flex width={'100%'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>
                <Flex alignItems='center' justifyContent={'space-between'}>
                  <Flex width={'100%'} justifyContent='center'>
                    Competência
                  </Flex>
                  <Flex width={'1.25rem'}></Flex>
                </Flex></th>
              {temColuna(0) && <th>Autoavaliação </th>}
              {temColuna(1) && <th>Gestor</th>}
              {temColuna(2) && <th>Pares</th>}
              {temColuna(3) && <th>Liderados</th>}
              {temColuna(4) && <th>Outros</th>}
              {temColuna(6) && <th>Alta liderança</th>}
              {temColuna(7) && <th>Nota final</th>}
              {!temColuna(7) && <th>Média do avaliado sem autoavaliação</th>}
            </tr>
          </thead>
          <tbody>
            {linhas.map((e, i) => {
              return (
                <>
                  <tr onClick={() => toggle(i)} key={i}>
                    <td>
                      <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                        <Flex width={'100%'} justifyContent={'center'}>
                          <Tooltip hasArrow label={TooltipLabel(e)} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                            {e.competencia}
                          </Tooltip>
                        </Flex>
                        {e.questoes.length === 1 ? '' : !open.some(valor => valor === i) ? <FaAngleDown /> : <FaAngleUp />}
                      </Flex>
                    </td>
                    {temColuna(0) && <StyledTd>{e.autoAvaliacao ?? '-'}</StyledTd>}
                    {temColuna(1) && <StyledTd>{e.gestor ?? '-'}</StyledTd>}
                    {temColuna(2) && <StyledTd>{e.pares ?? '-'}</StyledTd>}
                    {temColuna(3) && <StyledTd>{e.liderados ?? '-'}</StyledTd>}
                    {temColuna(4) && <StyledTd>{e.outros ?? '-'}</StyledTd>}
                    {temColuna(6) && <StyledTd>{e.altalideranca ?? '-'}</StyledTd>}
                    {temColuna(7) && <StyledTd>{e.consolidado ?? '-'}</StyledTd>}
                    {!temColuna(7) && <StyledTd>{e.mediaSemAuto}</StyledTd>}
                  </tr>
                  {open.some(valor => valor === i) && e.questoes.length > 1 && CriaDropQuestoes(e.questoes, e.competencia)}
                </>
              )
            })}

            {
              linhas && handleMedia().map((e, i) => {
                return (
                  <tr key={i}>
                    <td>
                      Média geral
                    </td>
                    {temColuna(0) && <StyledTd>{isNaN(e.mediaAutoAval) ? '-' : e.mediaAutoAval.toFixed(2)}</StyledTd>}
                    {temColuna(1) && <StyledTd>{isNaN(e.mediaGestor) ? '-' : e.mediaGestor.toFixed(2)}</StyledTd>}
                    {temColuna(2) && <StyledTd>{isNaN(e.mediaPares) ? '-' : e.mediaPares.toFixed(2)}</StyledTd>}
                    {temColuna(3) && <StyledTd>{isNaN(e.mediaLiderados) ? '-' : e.mediaLiderados.toFixed(2)}</StyledTd>}
                    {temColuna(4) && <StyledTd>{isNaN(e.mediaOutros) ? '-' : e.mediaOutros.toFixed(2)}</StyledTd>}
                    {temColuna(6) && <StyledTd>{isNaN(e.mediaAltaLideranca) ? '-' : e.mediaAltaLideranca.toFixed(2)}</StyledTd>}
                    {temColuna(7) && <StyledTd>{isNaN(e.mediaConsolidado) ? '-' : e.mediaConsolidado.toFixed(2)}</StyledTd>}
                    {!temColuna(7) && <StyledTd>{e.mediaSemAuto.toFixed(2)}</StyledTd>}
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

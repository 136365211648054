import { Box, Flex, Text } from '@chakra-ui/react'
import { Body } from '../Body'
import { LayoutFull } from '../LayoutFull'
import { Container, HeaderContainer } from './styles'

interface iLayoutProps{
  bg?: string
  isRespondido: boolean
  titulo: string
}

export const LayoutFullAnswer: React.FC<iLayoutProps> = ({ bg, titulo, isRespondido, children }) => {
  return (
        <Body>
            <LayoutFull>
                <HeaderContainer>
                    <h1>{titulo}</h1>
                    <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
                </HeaderContainer>
                <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
                {
                    (!isRespondido) && (
                        <Container>
                            {children}
                        </Container>
                    )
                }

                {
                    (isRespondido) && (
                        <Flex background={'white'} height={'100%'}>
                            <Flex padding={'3rem 3rem'} flexDirection={'column'}>
                                <h3>Obrigado pela participação!</h3>
                                <Text
                                    fontWeight={'400'}
                                    style={{
                                      color: 'var(--c6)',
                                      marginTop: '1rem'
                                    }}>Participação contabilizada</Text>
                            </Flex>
                        </Flex>
                    )
                }

            </LayoutFull>
        </Body>
  )
}

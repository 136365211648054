import styled from 'styled-components'

export const Main = styled.span`
input[type=checkbox]{
    height: 1.25rem;
    width: 1.25rem;
    margin-right:.5rem;
}

font-size: .75rem;

small{
    font-size: 0.7rem;
    font-weight: bold;
}
`

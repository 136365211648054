import { Flex, Box, Avatar, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Textarea, MenuButton, Link, Menu, MenuItem, MenuList, Image, FormControl, useToast, Text } from '@chakra-ui/react'
import { FaSave, FaTrash } from 'react-icons/fa'
import { NavLine } from '../../../../components/NavLine'
import { ColumnTexts } from '../../../../components/PerfilColaborador/styles'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { DashBox } from './styles'
import { useEffect, useState } from 'react'
import { NineBox } from '../../../../components/NineBox'
import { iNineBoxAvaliacao, iNomeId, iPerfilColaborador } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { PropInvalida } from '../../../../Utils/Helper'
import { useNavigate, useParams } from 'react-router'
import { iPostAvliacao } from '../../../RH/VisualizarTrilhaColaborador/Components/TabNineBox'
import { MultiColaborador } from '../../../../components/MultiColaborador'
import { parseJwt } from '../../../../services/token'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../components/ElementoTooltipCmp'

export interface iNota {
  notaY: number
  notaX: number
}

export const UsuarioEixo: React.FC = () => {
  const isAdm = parseJwt().adm === 'True'
  const nav = useNavigate()
  const toast = useToast()
  const { colaboradorId } = useParams<{ colaboradorId: string }>()

  const [Model, setModel] = useState<iNineBoxAvaliacao>({
    comiteAtivo: false,
    nineBox: {
      id: '',
      padrao: false,
      areas: [],
      eixosX: [],
      eixosY: [],
      quadrantes: [],
      tags: [],
      squads: [],
      tipoMeta: 0,
      titulo: '',
      configuracaoEixoX: {
        descricao: '',
        titulo: ''
      },
      configuracaoEixoY: {
        descricao: '',
        titulo: ''
      }
    },
    comites: []
  })

  const [Notas, setNotas] = useState<iNota>({
    notaX: 0,
    notaY: 0
  })
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()

  const [NomeQuadrante, setNomeQuadrante] = useState<string>()
  const [NotaXInconentente, setNotaXInconentente] = useState(false)
  const [NotaYInconentente, setNotaYInconentente] = useState(false)
  const [IsOpenDelete, setIsOpenDelete] = useState(false)
  const [AtualizacaoId, setAtualizacaoId] = useState('')

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Comite, setComite] = useState<string[]>([])
  const [Comentario, setComentario] = useState<string>()

  const [VisualizarTudo, setVisualizarTudo] = useState(false)

  function obterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarQuadrante(id: string, notaX?: number, notaY?: number): void {
    setNomeQuadrante(id)
    VerificarIncoerencia(id, notaX, notaY)
  }

  function onCloseModalDelete(): void {
    setAtualizacaoId('')
    setIsOpenDelete(false)
  }

  function VerificarIncoerencia(nome: string, notaX?: number, notaY?: number): void {
    setNotaXInconentente(false)
    setNotaYInconentente(false)
    const quadrante = Model.nineBox.quadrantes.find(e => e.id === nome)
    console.log(nome)
    if (quadrante) {
      const configx = Model.nineBox.eixosX.find(e => e.id === quadrante.eixoX)
      const configy = Model.nineBox.eixosY.find(e => e.id === quadrante.eixoY)
      if (notaX && notaY && configx && configy) {
        if (!(notaX >= configx?.rangeMinimo && notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(notaY >= configy?.rangeMinimo && notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      } else if (configx && configy) {
        if (!(Notas.notaX >= configx?.rangeMinimo && Notas.notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(Notas.notaY >= configy?.rangeMinimo && Notas.notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      }
    }
  }

  function ExcluirAtualizacao(atualizacaoId: string): void {
    appApi.delete(`NineBox/Atualizacao/${colaboradorId ?? ''}/${atualizacaoId}`)
      .then()
      .catch(err => console.log(err))
    window.location.reload()
  }

  function AlterarNota(notas: iNota): void {
    setNotas(notas)
    const configY = Model.nineBox.eixosY.find(r => notas.notaY >= r.rangeMinimo && notas.notaY <= r.rangeMaximo)
    const configX = Model.nineBox.eixosX.find(r => notas.notaX >= r.rangeMinimo && notas.notaX <= r.rangeMaximo)
    if (configY && configX) {
      const quadrante = Model.nineBox.quadrantes.find(r => r.eixoX === configX.id && r.eixoY === configY.id)
      if (quadrante) {
        setNomeQuadrante(quadrante.id)
        if (NotaXInconentente || NotaYInconentente) {
          VerificarIncoerencia(quadrante.id)
        }
      }
    }
  }

  function obterColaborador(): void {
    appApi.get(`Colaborador/${colaboradorId ?? ''}/Perfil`)
      .then(res => setColaborador(res.data))
      .catch(err => console.log(err))
  }

  function ObterNineBox(): void {
    appApi.get(`NineBox/Colaborador/${colaboradorId ?? ''}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function AvaliarColaborador(): void {
    const form: iPostAvliacao = {
      boxId: Model.nineBox.id,
      colaboradorId: Colaborador?.id ?? '',
      comentario: Comentario,
      comite: Comite,
      notaX: Notas.notaX,
      notaY: Notas.notaY,
      quandranteId: NomeQuadrante ?? ''
    }

    appApi.post('Colaborador/NineBox', form)
      .then(() => {
        if (PropInvalida(Colaborador?.avaliacaoNineBox)) {
          toast({
            title: 'Avaliação computada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          toast({
            title: 'Avaliação alterada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
        nav(`/Desempenho/9Box?quadranteId=${NomeQuadrante}`)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    obterColaborador()
    obterColaboradores()
    ObterNineBox()
  }, [])

  useEffect(() => {
    if (!PropInvalida(Colaborador?.avaliacaoNineBox)) {
      setNotas({
        notaX: Colaborador?.avaliacaoNineBox?.notaX ?? 0,
        notaY: Colaborador?.avaliacaoNineBox?.notaY ?? 0
      })
      SelecionarQuadrante(Colaborador?.avaliacaoNineBox?.idQuadrante ?? '', Colaborador?.avaliacaoNineBox?.notaX, Colaborador?.avaliacaoNineBox?.notaY)
    }
  }, [Colaborador])

  useEffect(() => {
    if (AtualizacaoId !== '') {
      setIsOpenDelete(true)
    }
  }, [AtualizacaoId])

  return (
    <Body>
      <ModalDelete
        onRequestClose={onCloseModalDelete}
        isOpen={IsOpenDelete}
        center={true}
        message={'Deseja reverter as ultimas alterações feitas neste colaborador?'}
        onConfirm={() => ExcluirAtualizacao(AtualizacaoId)}
      />
      <NavLine
        options={[
          { nome: 'Desempenho' },
          { nome: '9Box', path: '/Desempenho/9box' },
          { nome: 'Enigma', path: -1 },
          { nome: Colaborador?.nome ?? '' }]
        }
      />
      <Flex mb={'.75rem'} mt={'.325rem'} justifyContent={'space-between'}>
        <Box as='h1' flex={'1'}>9Box</Box>
      </Flex>
      <WhiteContainer styles={{ marginTop: '1rem' }}>
        <Flex>
          <Flex gap={'1rem'} flexDir={'column'} pt={'3rem'} pl={'1rem'}>
              <Flex alignItems={'center'} w={'100%'}>
                <Avatar
                  marginRight={'1rem'}
                  size={'2xl'}
                  name={'nome'}
                  src={Colaborador?.avatar}
                />
                <ColumnTexts>
                  <h3>{Colaborador?.nome}</h3>
                  <span>Área: {Colaborador?.nomeDepartamento}</span>
                  <span>Função: {Colaborador?.nomeCargo}</span>
                  <span>{Colaborador?.email}</span>
                  {Colaborador && <span>{Colaborador?.celular}</span>}
                </ColumnTexts>
              </Flex>
              {!PropInvalida(Colaborador?.perfilPI) &&
                <DashBox theme={'RosaOpaco'}>
                  <Flex gap={'1rem'} flexDir={'column'} alignItems={'center'}>
                    <Flex gap={'1rem'}>
                      <Image width={'4rem'} src={Colaborador?.perfilPI?.urlSelo} />
                      <Image width={'10rem'} src={Colaborador?.perfilPI?.urlSelf} />
                    </Flex>
                    <Menu>
                      <MenuButton fontSize={'.875rem'} bg={'var(--Rosa)'} h={'1.75rem'} py={'.125rem'} px={'.75rem'} borderRadius={'.25rem'} _active={{ bg: 'var(--Rosa-hover)' }} _hover={{ bg: 'var(--Rosa-hover)' }}>
                        Baixar Relatorios
                      </MenuButton>
                      <MenuList bgColor='var(--Rosa)'>
                        {
                          (!PropInvalida(Colaborador?.perfilPI?.urlResultado)) && (
                            <MenuItem>
                              <ButtonCmp as={Link} borderColor='var(--Rosa)'
                                onClick={() => nav(Colaborador?.perfilPI?.urlResultado ?? '')}
                                color='var(--Rosa)'>
                                Perfil comportamental
                              </ButtonCmp>
                            </MenuItem>
                          )
                        }

                        {
                          (!PropInvalida(Colaborador?.perfilPI?.urlQuadro)) && (
                            <MenuItem>
                              <ButtonCmp as={Link} borderColor='var(--Rosa)'
                                onClick={() => nav(Colaborador?.perfilPI?.urlQuadro ?? '')}
                                color='var(--Rosa)'>
                                Quadro desenvolvimento pessoal
                              </ButtonCmp>
                            </MenuItem>
                          )
                        }

                        {
                          (!PropInvalida(Colaborador?.perfilPI?.urlPlacard)) && (
                            <MenuItem>
                              <ButtonCmp as={Link} borderColor='var(--Rosa)'
                                onClick={() => nav(Colaborador?.perfilPI?.urlPlacard ?? '')}
                                color='var(--Rosa)'>
                                Placard
                              </ButtonCmp>
                            </MenuItem>
                          )
                        }

                        {
                          (!PropInvalida(Colaborador?.perfilPI?.urlQuadroGestor)) && (
                            <MenuItem>
                              <ButtonCmp as={Link} borderColor='var(--Rosa)'
                                onClick={() => nav(Colaborador?.perfilPI?.urlQuadroGestor)}
                                color='var(--Rosa)'>
                                Quadro desenvolvimento gestores
                              </ButtonCmp>
                            </MenuItem>
                          )
                        }

                      </MenuList>
                    </Menu>
                  </Flex>
                </DashBox>
              }
              <Flex mb={'1rem'} gap={'1.25rem'}>
                <Flex flexDirection={'column'}>
                  <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'}>
                    {Model.nineBox.configuracaoEixoY.titulo}
                  </Box>
                  <NumberInput
                    isInvalid={NotaYInconentente}
                    onChange={(e) => AlterarNota({ ...Notas, notaY: parseFloat(e) })}
                    value={Notas.notaY} width={'7.9rem'}
                    min={0}
                    max={200}
                    step={0.1}
                    h={'1.75rem'}
                    size={'sm'}
                    borderRadius={'.25rem'}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
                <Flex flexDirection={'column'}>
                  <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'}>
                    {Model.nineBox.configuracaoEixoX.titulo}
                  </Box>
                  <NumberInput
                    isInvalid={NotaXInconentente}
                    onChange={(e) => AlterarNota({ ...Notas, notaX: parseFloat(e) })}
                    width={'7.9rem'}
                    value={Notas.notaX}
                    step={0.1}
                    min={0}
                    max={200}
                    h={'1.75rem'}
                    size={'sm'}
                    borderRadius={'.25rem'}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Flex>
            </Flex>
          </Flex>

          <Flex flexDir={'column'} gap={'.75rem'} flex={'1'}>
            <Flex fontSize={'1.125rem'} ml={'9.125rem'} mb={'1rem'} fontWeight={'700'} width={'35.625rem'} justifyContent={'center'}>
              Definição padrão
            </Flex>
            <Flex ml={'3.125rem'} flexDirection={'column'} width={'35.625rem'} alignItems={'center'}>
              <NineBox
                quadranteSelecionado={NomeQuadrante}
                selecionarQuadrante={SelecionarQuadrante}
                model={Model.nineBox}
                variant='avaliar'
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex gap={'1rem'} flexDir={'column'} mb='.5rem'>
          {Model.comites.slice(0, VisualizarTudo ? Model?.comites?.length : 2).map((r, i) => {
            return (
              <Flex flexDir={'column'} borderRadius={'.25rem'} border={'1px solid var(--c4)'} bg={'var(--AzulOpacoBg)'} padding='1rem' key={i}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Box>
                    <Text fontWeight={'bold'} fontSize={'0.813rem'}>Alterado por {r.responsavel.nome} em {r.dataAvaliacao}</Text>
                    {PropInvalida(r.avaliacaoAnterior) && (
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Avaliou no quadrante {r.avaliacaoAtual}
                      </Text>
                    )}

                    {(!PropInvalida(r.avaliacaoAnterior) && (r.avaliacaoAnterior !== r.avaliacaoAtual)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.avaliacaoAnterior} para {r.avaliacaoAtual}
                      </Text>
                    }

                    {((r.notaAnteriorX !== null && r.notaAnteriorX !== -1) && (r.notaNovaX !== -1) && (r.notaNovaX !== r.notaAnteriorX)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.notaAnteriorX?.toFixed(1)} para {r.notaNovaX?.toFixed(1)} no eixo de {Model.nineBox.configuracaoEixoX.titulo}
                      </Text>
                    }
                    {((r.notaAnteriorX !== null && (r.notaAnteriorX === -1)) && (r.notaNovaX !== -1)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Atribuiu {r.notaNovaX?.toFixed(1)} como a primeira nota no eixo de {Model.nineBox.configuracaoEixoX.titulo}
                      </Text>
                    }

                    {((r.notaAnteriorY !== null && r.notaAnteriorY !== -1) && (r.notaNovaY !== -1) && (r.notaNovaY !== r.notaAnteriorY)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.notaAnteriorY?.toFixed(1)} para {r.notaNovaY?.toFixed(1)} no eixo de {Model.nineBox.configuracaoEixoY.titulo}
                      </Text>
                    }
                    {((r.notaAnteriorY !== null && (r.notaAnteriorY === -1)) && (r.notaNovaY !== -1)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Atribuiu {r.notaNovaY?.toFixed(1)} como a primeira nota no eixo de {Model.nineBox.configuracaoEixoY.titulo}
                      </Text>
                    }
                  </Box>
                  {r.atualizacaoId && Model.comites.length > 1 &&
                  <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir'}>
                    <FaTrash style={{ color: 'var(--Red)', cursor: 'pointer' }} size={14} onClick={() => setAtualizacaoId(r.atualizacaoId ?? '')} />
                  </ElementoTooltipCmp>
                  }
                </Flex>
                {r.comentario && <Flex flexDir={'column'} mt={'.5rem'} padding={'.5rem 1rem'} bg='var(--c1)' border={'1px solid var(--AzulOpaco)'}>
                  <Text fontWeight={'700'} fontSize={'0.8135rem'}>Comentário</Text>
                  <Text fontSize={'0.875rem'}>{r.comentario}</Text>
                </Flex>}

                <Text my={'.5rem'} fontSize={'1.125rem'} fontWeight={'700'}>Comitê que realizou a calibração</Text>
                <Flex gap={'2rem'}>
                  {r.comite.map((c, i2) => {
                    return (
                      <Flex gap={'.325rem'} key={i2} alignItems={'center'}>
                        <Avatar size={'sm'} src={c.avatar} name={c.nome} />
                        <Flex flexDir={'column'}>
                          <Text fontSize={'.875rem'} fontWeight={'700'} lineHeight={'1.125rem'}>{c.nome}</Text>
                          <Text fontSize={'.875rem'} lineHeight={'.925rem'}>{c.complemento}</Text>
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
        {!VisualizarTudo && Model?.comites?.length > 2 &&
          <Text cursor={'pointer'} onClick={() => setVisualizarTudo(true)} mb={'.5rem'} fontWeight={'700'} textDecoration='underline' color={'var(--Azul)'}>Mostrar alterações anteriores </Text>
        }

        {isAdm &&
          <Flex flexDir={'column'} gap={'.75rem'} mt={'.25rem'}>
            <FormControl>
              <Text fontWeight={'700'} fontSize={'.875rem'} mb={'.25rem'}>Deixe um comentário para registro da calibração</Text>
              <Textarea
                color={'var(--Gray2)'}
                placeholder='Deixe seu comentário'
                height={'8.25rem'}
                borderColor='var(--Gray4)'
                onChange={(e) => setComentario(e.target.value)}
              />
            </FormControl>

            {!Model.comiteAtivo && <FormControl gap={'.25rem'}>
              <Text fontWeight={'700'} fontSize={'.875rem'}>Participantes do comite</Text>
              <MultiColaborador
                colaboradores={Colaboradores}
                selecionados={Comite}
                onDelete={(e) => setComite([...Comite.filter(t => t !== e)])}
                onChoice={(e) => !PropInvalida(e) && setComite([...Comite])}
              />
            </FormControl>}

            <Flex justifyContent={'flex-end'} gap={'.5rem'}>
              <ButtonCmp
                VarColor='c6'
                onClick={() => nav(-1)}
              >Cancelar</ButtonCmp>
              <ButtonCmp
                VarColor='Green2'
                leftIcon={<FaSave />}
                onClick={AvaliarColaborador}
              >{PropInvalida(Colaborador?.avaliacaoNineBox) ? 'Salvar' : 'Editar nota'}</ButtonCmp>
            </Flex>
          </Flex>
        }
      </WhiteContainer>
    </Body>
  )
}

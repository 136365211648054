import { Avatar, Box, Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import { FaRegComment, FaSave, FaTrashAlt } from 'react-icons/fa'
import { FiLink } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { iPontosDiscussao } from '../..'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { parseJwt } from '../../../../../../services/token'

interface iProps {
  statusReuniao: number
  model: iPontosDiscussao
  onConcluir: (id: string) => void
  onAtualizar: (id: string, texto: string) => void
  onExcluir: (id: string) => void
}

export const CardPontoDiscussao: React.FC<iProps> = ({ model, statusReuniao, onConcluir, onExcluir, onAtualizar }) => {
  const nav = useNavigate()
  const [Edit, setEdit] = useState(false)
  const [Texto, setTexto] = useState('')

  function InternalAtualizar(): void {
    setEdit(false)
    onAtualizar(model.id, Texto)
  }
  useEffect(() => {
    if (Edit) {
      setTexto(model.titulo)
    }
  }, [Edit])
  return (
        <Flex bgColor={'var(--AzulOpacoBg)'} border={'1px solid var(--c5)'} p={'.5rem 1rem'} alignItems={'center'} justifyContent='space-between'>
            <Flex gap={'1rem'} alignItems={'center'}>
                {!model.concluido && !model.proximoPasso && statusReuniao !== 5 &&
                    <Flex alignItems={'center'} borderRadius={'50%'} justifyContent={'center'} bg={'var(--c1)'} _hover={{ boxShadow: 'var(--SombraBtns)', width: '30px', height: '30px' }}>
                        <AiOutlineCheckCircle
                            onClick={() => onConcluir(model.id)}
                            cursor={'pointer'}
                            size={30}
                            style={{
                              color: 'var(--c6)'
                            }}
                        />
                    </Flex>
                }

                {model.concluido && !model.proximoPasso && statusReuniao !== 5 &&
                    <Flex alignItems={'center'} borderRadius={'50%'} justifyContent={'center'} _hover={{ boxShadow: 'var(--SombraBtns)', width: '30px', height: '30px' }}>
                    <AiFillCheckCircle
                            onClick={() => onConcluir(model.id)}
                            cursor={'pointer'}
                            size={30}
                            style={{
                              color: 'var(--Azul)'
                            }}
                        />
                    </Flex>
                }
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {(model.tipo === 1 || model.tipo === 4) && <Image src='https://sigah.blob.core.windows.net/onboard/icones/Group 116_d2fd30c1-9812-4037-8a47-715f9afb39fe.png' />}
                    {model.tipo === 2 && <Avatar size={'sm'} name={model.titulo} src={model.avatar} />}
                    <Box gap={'.75rem'}>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                            {!Edit &&
                                <Text
                                    onClick={() => nav(`?pontoId=${model.id}`)}
                                    color={'var(--Gray1)'}
                                    textDecor={'underline'}
                                    cursor={'pointer'}
                                    fontWeight={'700'}
                                    fontSize={'.875rem'}
                                >{model.titulo}</Text>
                            }
                            {Edit &&
                                <InputCmp
                                    value={Texto}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        InternalAtualizar()
                                      }
                                    }}
                                    bg={'white'}
                                    OnChange={(e) => setTexto(e)}
                                />
                            }
                            {!Edit &&
                                <ElementoTooltipCmp
                                    bg={'var(--Rosa)'}
                                    label='Editar'
                                >
                                    <BsPencilSquare
                                        cursor={'pointer'}
                                        size={15}
                                        onClick={() => setEdit(true)}
                                        color='var(--Rosa)'
                                    />
                                </ElementoTooltipCmp>
                            }

                            {Edit &&
                                <ElementoTooltipCmp
                                    bg={'var(--Rosa)'}
                                    label='Salvar'
                                >
                                    <FaSave
                                        cursor={'pointer'}
                                        size={15}
                                        onClick={InternalAtualizar}
                                        color='var(--Rosa)'
                                    />
                                </ElementoTooltipCmp>
                            }
                        </Flex>
                        <Text fontSize={'12px'}>{model.descricao}</Text>
                    </Box>
                </Flex>
            </Flex>
            <Flex gap={'1rem'}>
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {model.comentarios.length}
                    <FaRegComment />
                </Flex>
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {model.anexos.length}
                    <FiLink />
                </Flex>
                {(model.tipo !== 1 && model.tipo !== 4 &&
                    statusReuniao !== 5 &&
                    model.criadorId === parseJwt().cid) &&
                    <Flex
                        cursor={'pointer'}
                        alignItems={'center'}
                        color={'var(--Red)'}
                        gap='.5rem'
                    >
                        {model.anexos.length === 0 &&
                            model.comentarios.length === 0 &&
                            <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir'}>
                                <FaTrashAlt
                                    onClick={() => onExcluir(model.id)}
                                />
                            </ElementoTooltipCmp>
                        }
                    </Flex>
                }
            </Flex>
        </Flex>
  )
}

import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const NavElipse = styled(Flex)`
    justify-content: center;
    gap: .75rem;
    margin: 1rem 0rem;
    div{
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 50%;
        background: var(--c4);
        box-shadow: var(--SombraBtns);
        cursor: pointer;

        :hover{
            background: var(--Azul);
            transition: ease .2s all ;
        }
    }
`

import styled, { css } from 'styled-components'

interface IAvatarContainerProps {
  backgroundImg: string
}

export const Main = styled.div`
margin-top: 2rem;

&>div{
    padding: 2rem;
}

.sah_Img{
  transform: scaleX(-1);
}
`

export const ScrollContainer = styled.div`
width: 75rem;
height: 44rem;
overflow: scroll;
display: flex;
border:2px dashed var(--Gray3);
padding:1rem;
border-radius: 2px;
position: relative;

.center{
  ul > li > ul > li{
  display: flex;
  flex-direction: column;
  align-items: center;
  }
}
`

export const StyledButton = styled.a<{margin?: string, selected?: boolean}>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.25rem;
 font-weight: 500;
 font-size: .875rem;
 color: var(--Azul);
 background:none;
 padding: var(--mdPaddingBtn);
 height: 1.95rem;
 line-height: 1px;
 border: 2px solid var(--Azul);
 margin: ${props => props.margin};

 ${props => !props.selected && css`
    &:hover{
      transition:var(--transitionBtns);
      background:var(--Azul);
      color: white;
    }
 `}

 ${props => props.selected && css`
     background: var(--Azul);
     color:white;
 `}
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  background: var(--white);
  border-radius: 0.25rem;
  max-width: 9rem;
  min-width: 9rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .boxAzul{
    padding: .5rem 1rem;
    background: var(--Azul);
  }

  p {
    font-size: 0.813rem;
    width: 9rem;
    color: var(--c1);
    :first-child{
      font-weight: 700;
    }
  }

  border: 1px solid var(--Gray2);
  box-shadow: var(--SombraBtns);
  :hover{
    transform: translateY(-5px);
    box-shadow: 0 1px 4px 0 var(--Rosa);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
`

export const AvatarContainer = styled.div<IAvatarContainerProps>`
  height: 7rem;
  width: 6rem;
  border-radius: 0.25rem 0.25rem 0 0;
  background: var(--c2);
  background-image: url(${(props) => props.backgroundImg});
  background-size: 6rem 7rem;
  background-repeat: no-repeat;

  display: flex;
  align-items: flex-end;

  div {
    padding: 0.125rem 0.25rem;
    background: var(--a5);

    text-align: left;
    flex: 1;
    margin-bottom: 0.25rem;

    p {
      font-size: 0.75rem;
      color: var(--c1);
      text-transform: capitalize;
    }
  }
`

import { Flex, MenuList, Text, Menu, MenuButton } from '@chakra-ui/react'
import { FaCopy, FaEdit, FaTrash, FaUsers } from 'react-icons/fa'
import { MdChecklist, MdMoreVert } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { iGrupoTrilha } from '../../../../../interfaces'
import { parseJwt } from '../../../../../services/token'
import { Background, Descricao } from './styles'

interface ICard {
  trilha: iGrupoTrilha
  onDelete: (id: string) => void
  onEdit: (e: iGrupoTrilha) => void
  onCopy: (id: string) => void
}

export const Card: React.FC<ICard> = ({ trilha, onDelete, onEdit, onCopy }) => {
  const nav = useNavigate()
  return (
    <ElementoTooltipCmp isDisabled={(trilha.padrao !== null && !trilha.padrao) ? (trilha.descricao === null || trilha.descricao === '') : false}
      label={(trilha.descricao && trilha.descricao?.length > 0) ? trilha.descricao : 'Essa é uma trilha padrão para visualização, porém para customiza-la  você deve duplica-la.'} bg='var(--Azul)'>

      <Background theme={trilha.cor}>
        <Flex justifyContent={'end'} pos={'relative'}>
          <Menu>
            <MenuButton
              bg={'transparent'}
              pos={'absolute'}
              p={'.25rem'}
              borderRadius={'.25rem'}
              color={'var(--c1)'}
              _hover={{ color: 'black !important', bg: 'var(--c1)' }}
            >
              <MdMoreVert size={20}/>
            </MenuButton>
            <MenuList bg={'white'} color={'black'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
              <StyledMenuItem onClick={() => onCopy(trilha.id)}>
                <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                Duplicar
              </StyledMenuItem>
              {
                ((trilha.padrao !== null && !trilha.padrao) || parseJwt().uid === '252110c3-295e-4c81-987c-b260b528bdf0') && (
                  <Flex flexDir={'column'}>
                    <StyledMenuItem onClick={() => onEdit(trilha)}>
                      <FaEdit style={{ marginRight: '.375rem' }} size={'14'}/>
                      Editar
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => onDelete(trilha.id)}>
                      <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                      Excluir
                    </StyledMenuItem>
                  </Flex>
                )
              }
              </MenuList>
          </Menu>
        </Flex>
        <Flex onClick={() => nav(`/Jornada/Configuracao/Trilha/${trilha.id}`)} flexDir={'column'} justifyContent='center' alignItems={'center'} gap='1rem' height='80%'>
          <Text fontSize={'1rem'} fontWeight={'bold'}>{trilha.nome}</Text>
          <Flex flexDir={'column'}>
            <Descricao><MdChecklist fontSize={24}/><strong>{trilha.countEtapas}</strong> Etapas</Descricao>
            <Descricao><FaUsers fontSize={24}/><strong>{trilha.countProcessos}</strong> Processos ativos</Descricao>
          </Flex>
        </Flex>
      </Background>
    </ElementoTooltipCmp>
  )
}

import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { Header } from './styles'
import { Flex, FormLabel } from '@chakra-ui/react'
import { iAvatarXInformacoes, iNomeId } from '../../../../../../interfaces'
import { Button } from '../../../../../../components/Button'
import { FindUser } from '../../../../../../components/FindBox'
import { appApi } from '../../../../../../services/appApi'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'

interface iModalAddAvaliado {
  onRequestClose: () => void
  onConfirm: (responsavel: iAvatarXInformacoes, participante: iAvatarXInformacoes) => void
  exists: (responsavelId: string, participanteId: string) => boolean
}

export const ModalAdicionarReuniao: React.FC<iModalAddAvaliado> = ({ onConfirm, onRequestClose, exists }) => {
  Modal.setAppElement('#root')
  const [Options, setOptions] = useState<iNomeId[]>([])
  const [Responsavel, setResponsavel] = useState<iAvatarXInformacoes>()
  const [Participante, setParticipante] = useState<iAvatarXInformacoes>()

  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setOptions(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarParticipante(id: string): void {
    const colab = Options.find(e => e.id === id)
    if (colab) {
      setParticipante({
        id: colab.id,
        avatar: colab.avatar ?? '',
        info1: colab.nome
      })
    }
  }

  function SelecionarResponsavel(id: string): void {
    const colab = Options.find(e => e.id === id)
    if (colab) {
      setResponsavel({
        id: colab.id,
        avatar: colab.avatar ?? '',
        info1: colab.nome
      })
    }
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Header>
        <Flex justifyContent={'space-between'}>
          <div>
            <h2>Adicione um avaliado</h2>
            <span>O usuário deve estar cadastrado no sistema para ser adicionado como participante na pesquisa. No entanto, seus stakeholders podem ser adicionados apenas com o e-mail, posteriormente na lista de participantes</span>
          </div>
          <MdClose color='var(--a3)' cursor={'pointer'} onClick={onRequestClose} />
        </Flex>
      </Header>
      <Flex gap={'1rem'} flexDir={'column'} padding={'1rem'}>
        <Flex flexDir={'column'}>
          <FormLabel>Nome do responsável</FormLabel>
          <FindUser
            lista={Options.filter(e => e.id !== Participante?.id)}
            placeholder='Digite o nome do responsável'
            onChoice={(e) => SelecionarResponsavel(e)}
            selecionado={Responsavel?.id}
          />
        </Flex>

        <Flex flexDir={'column'}>
          <FormLabel>Nome do participante</FormLabel>
          <FindUser
            lista={Options.filter(e => e.id !== Responsavel?.id)}
            placeholder='Digite o nome do participante'
            onChoice={(e) => SelecionarParticipante(e)}
            selecionado={Participante?.id}
          />
        </Flex>
      </Flex>
      <Flex alignItems={'center'} justifyContent={'end'} padding={'0rem 1rem 1rem 0rem'}>
        <ElementoTooltip label={exists(Responsavel?.id ?? '', Participante?.id ?? '') ? 'Já existe uma reunião com selecionada com a mesma configuração' : ''}>
          <Button
            size='lg'
            VarColor='v2'
            isDisabled={exists(Responsavel?.id ?? '', Participante?.id ?? '')}
            onClick={() => {
              onConfirm(Responsavel ?? { avatar: '', id: '', info1: '' }, Participante ?? { avatar: '', id: '', info1: '' })
              onRequestClose()
            }}
          >Adicionar</Button>
        </ElementoTooltip>
      </Flex>
    </Modal>
  )
}

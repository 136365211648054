/* eslint-disable no-tabs */
import styled from 'styled-components'

export const OutlineTable = styled.div`
  border: 2px solid var(--c2);
  padding: .75rem;
  border-radius: 4px;
  margin-top: .75rem;
	th {
		padding: .5rem 0 .5rem 0;
		font-size: 12px;
		font-weight: 700;
		color: #4A5568;
		:first-child{
			text-align: start;
			padding-left: 1.25rem;
		}
	}
	tr {
		:hover{
			background: var(--Green2);
			color: var(--c1);
			button{
				color: var(--c1);
				:hover{
					background: var(--Azul);
				}
			}
		}
	}
	td {
		background-color: var(--AzulOpacoBg);
		border-bottom: 8px solid #fff;
		border-top: 3px solid #fff;
		:last-child {
			padding-top: 0;
			height: 0;
		}
	}
`
export const StyledSpan = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5rem;
`

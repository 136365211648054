import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--c6);
  margin-top: .5rem;
  padding: 1rem 1rem;

  div {
    display: flex;
    flex: 1;
    align-items: center;
  }

  aside {
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 1rem;

    background: var(--gray-200);

    input {
      border: 0;
      border-radius: 0.25rem;

      font-size: 1rem;
      padding: 0.5rem;

      & + input {
        margin-left: 0.5rem;
      }
    }
  }
`

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
padding: 1.5rem 1rem 1rem 1rem;
border-radius: .25rem;
border:1px solid var(--c4);
box-shadow: var(--SombraBackground);
`

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

import { useEffect, useRef } from 'react'

import { Radar } from 'react-chartjs-2'
import { iEscala, iGrafico } from '../../../../../interfaces'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

interface iRadarData {
  grafico: iGrafico
  escala: iEscala
  getBlob: (base64: string) => void
}

interface iChartProviderProps {
  generateChartBlob: () => string
}

function useChartState(ChartRef: any): iChartProviderProps {
  function generateChartBlob(): string {
    return ChartRef.current.canvas.toDataURL()
  }

  return { generateChartBlob }
}

export const RadarChartJs: React.FC<iRadarData> = ({
  grafico,
  escala,
  getBlob
}) => {
  const stepSize = (escala.respostas[0]?.peso ?? 0) - (escala.respostas[1]?.peso ?? 0) ?? 0
  function AbreviarNomesGrandes(): string[] {
    return grafico.competencias.map((e) => {
      if (e.length > 19) {
        return e.substring(0, 19) + '...'
      } else {
        return e
      }
    })
  }

  const canvasReference = useRef(null)

  const { generateChartBlob } = useChartState(canvasReference)

  const colors = [
    'rgb(0, 143, 251)',
    'rgb(0, 227, 150)',
    '#F2C94C',
    'rgb(255, 69, 96)',
    '#fe00f4',
    '#BB6BD9',
    '#219653',
    '#F2994A'
  ]

  const Shapes = ['circle', 'star', 'triangle', 'rectRot', 'rectRounded']

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14
          },
          padding: 20
        }
      }
    },
    scales: {
      r: {
        min: 0,
        max: Math.max(...escala.respostas.map(e => e.peso)),
        ticks: {
          min: 0,
          max: 10,
          stepSize: stepSize,
          font: {
            size: 12
          },
          z: 1
        },
        pointLabels: {
          color: '#000',
          font: {
            size: 14
          }
        },

        angleLines: {
          color: '#bdbdbd'
        },

        grid: {
          color: '#bdbdbd'
        }
      }
    }
  }

  const data = {
    labels: AbreviarNomesGrandes(),
    datasets: grafico.dados.map((e, i) => {
      return {
        label: e.nome,
        data: e.medias,
        fill: false,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: grafico.dados[1].nome.includes('Média sem autoavaliação') && i === grafico.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        backgroundColor: grafico.dados[1].nome.includes('Média sem autoavaliação') && i === grafico.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        borderColor: grafico.dados[1].nome.includes('Média sem autoavaliação') && i === grafico.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        pointRadius: i === 0 ? 4 : 6,
        pointStyle: Shapes[i]
      }
    })
  }

  const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart: any) => {
      const { ctx } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getBlob(generateChartBlob())
    }, 1000)
  }, [grafico.dados])

  return (
    <Radar
      options={options}
      data={data}
      ref={canvasReference}
      plugins={[plugin]}
    />
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaCog, FaEye, FaPaperPlane, FaPlus, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { SearchCmp } from '../../../../components/SearchCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iGestaoConvite } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { BoxCount } from './Components/BoxCount'
import { CircleText } from './Components/CircleText'
import { ModalConviteEmail } from './Components/ModalConviteEmail'
import { ModalConviteUsuario } from './Components/ModalConviteUsuario'
import { ModalLinkAberto } from './Components/ModalLinkAberto'
import { TableConvites } from './Components/Tabela'

type iParamProps = {
  pesquisaId: string
}

interface iAberturaPesquisa {
  dataInicio?: string
  hInicio?: string
}

export const ConvitesPesquisaInterna: React.FC = () => {
  const param = useParams<iParamProps>()
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const [ModalUsuarioIsOpen, setModalUsuarioIsOpen] = useState(false)
  const [ModalEmailIsOpen, setModalEmailIsOpen] = useState(false)
  const [ModalLinkAbertoIsOpen, setModalLinkAbertoIsOpen] = useState(false)

  const [Model, setModel] = useState<iGestaoConvite>()
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [PesquisaNaoIniciada, setPesquisaNaoIniciada] = useState<boolean>(false)

  function getConvites(): void {
    appApi.get(`PesquisaInternaConvite/${param.pesquisaId as string}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function EnviarConvite(id: string): void {
    appApi.post(`PesquisaInternaConvite/${param.pesquisaId as string}/Enviar`, [id])
      .then(() => {
        getConvites()
        if (Model?.convites.find(e => e.id === id)?.status !== 'Enviado') {
          toast({
            title: 'Convite enviado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
      })
      .catch(err => console.log(err))
  }

  function EnviarConvitePorStatus(query: string): void {
    appApi.post(`PesquisaInternaConvite/${param.pesquisaId as string}/status?status=${query}`)
      .then(() => {
        toast({
          title: 'Convites enviados com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getConvites()
      })
      .catch(err => console.log(err))
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Configuracao`)
      .then((res: AxiosResponse<iAberturaPesquisa>) => {
        const DataString = `${res?.data?.dataInicio ?? ''} ${res?.data?.hInicio ?? ''}`
        setPesquisaNaoIniciada(new Date(Date.parse(DataString)).getTime() > new Date().getTime())
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConvites()
    getPesquisa()
  }, [])

  return (
        <Body>
            {
                (Model) && (
                    <>
                        <ModalConviteUsuario
                            isOpen={ModalUsuarioIsOpen}
                            rows={Model.convites.filter(e => e.colaborador)}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalUsuarioIsOpen(false)}
                            Reload={getConvites}
                        />
                        <ModalConviteEmail
                            isOpen={ModalEmailIsOpen}
                            rows={Model.convites.filter(e => !e.colaborador)}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalEmailIsOpen(false)}
                            Reload={getConvites}
                        />
                    </>
                )
            }

            <ModalLinkAberto
                pesquisaId={param.pesquisaId as string}
                isOpen={ModalLinkAbertoIsOpen}
                onClose={() => setModalLinkAbertoIsOpen(false)}
            />

            <WhiteContainer>
                <Flex justifyContent={'space-between'}>
                    <h1>{Model?.titulo}</h1>
                    {
                        (Model) && (
                            <Flex gap={'0rem 1rem'} justifyContent={'end'} flexDir={'row'}>
                                <CircleText
                                    titulo={Model.linkAberto + Model.convites.filter(e => e.status === 'Respondido').length}
                                    fontsize='14px'
                                    varColorCircle='Azul'
                                    varColorText='Preto'
                                >Respostas</CircleText>

                                <CircleText
                                    titulo={Model.convites.filter(e => e.status === 'Enviado').length}
                                    fontsize='14px'
                                    varColorCircle='Rosa'
                                    varColorText='Preto'
                                >Convites enviados</CircleText>

                                <CircleText
                                    titulo={Model.convites.filter(e => e.status === 'Não enviado').length}
                                    fontsize='14px'
                                    varColorCircle='c5'
                                    varColorText='Preto'
                                >Novos convites a enviar</CircleText>

                                <CircleText
                                    titulo={Model.convites.filter(e => e.status === 'Erro').length}
                                    fontsize='14px'
                                    varColorCircle='secundario5'
                                    varColorText='Preto'
                                >Convites com erro</CircleText>
                            </Flex>
                        )
                    }
                </Flex>
                <Flex flexDirection={'column'} margin={'1rem 0 1.5rem 0'}>
                    {
                        (Model) && (
                            <Flex gap={'0rem 1rem'}>
                                <BoxCount
                                    convites={Model?.convites.filter(e => e.colaborador)}
                                    tipo='usuario'
                                >
                                    <ButtonCmp
                                        VarColor='Green2'
                                        disabled={Model.encerrada}
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalUsuarioIsOpen(true)}
                                    >Adicionar convite</ButtonCmp>
                                </BoxCount>

                                <BoxCount
                                    convites={Model?.convites.filter(e => !e.colaborador)}
                                    tipo='email'
                                >
                                    <ButtonCmp
                                        VarColor='Green2'
                                        disabled={Model.encerrada}
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalEmailIsOpen(true)}
                                    >Adicionar convite</ButtonCmp>
                                </BoxCount>

                                <BoxCount
                                    convites={[]}
                                    linkAberto={Model.linkAberto}
                                    tipo='qrcode'
                                >
                                    <ButtonCmp onClick={() => setModalLinkAbertoIsOpen(true)}
                                        VarColor={'Rosa'}
                                        disabled={Model.encerrada}
                                        leftIcon={<FaEye color='white' />}
                                    >Visualizar link</ButtonCmp>
                                </BoxCount>
                            </Flex>
                        )
                    }
                </Flex>

                {
                    (Model && Model.convites?.length > 0) && (
                        <>
                            <Flex borderTop={'1px solid var(--c5)'} justifyContent={'space-between'} p={'1.5rem 0'}>
                                <h3>Lista de convidados</h3>
                                <SearchCmp
                                    EnterAction={() => {}}
                                    OnChange={setTxtPesquisa}
                                    placeholder='Buscar participante'
                                    width='18rem'
                                />
                            </Flex>
                            <TableConvites
                                rows={Model.convites.filter((e) => {
                                  if (TxtPesquisa === '') return e
                                  else if (e?.nome?.toLowerCase().includes(TxtPesquisa.toLowerCase())) return e
                                  return null
                                })}
                                onCheck={() => { }}
                                onSend={EnviarConvite}
                                anonima={Model.anonima}
                                pesquisaEncerrada={Model.encerrada}
                            />
                        </>
                    )
                }
                <Flex flexDirection={'row'} justifyContent={'end'} marginTop={'1.5rem'} gap='.5rem' >
                    <ButtonCmp onClick={() => nav(-1)} leftIcon={<FaAngleLeft />}VarColor='c6'>Voltar</ButtonCmp>
                    <ButtonCmp leftIcon={<FaCog />}VarColor='Rosa'
                        onClick={() => nav(pathname.replace('Convites', 'Configuracao'))}>Configuração da pesquisa</ButtonCmp>
                    <ButtonCmp isDisabled={Model?.encerrada || !Model?.convites.some(e => e.liberado)} leftIcon={<FaPaperPlane />}VarColor='Green2' onClick={() => EnviarConvitePorStatus('1')}>Reenviar convites não respondidos</ButtonCmp>
                    <ButtonCmp isDisabled={Model?.encerrada || !Model?.convites.some(r => r.status === 'Não enviado')}
                        leftIcon={PesquisaNaoIniciada && !Model?.convites.some(e => e.liberado) ? <FaSave /> : <FaPaperPlane />}VarColor='Roxo' onClick={() => EnviarConvitePorStatus('0')}>{PesquisaNaoIniciada && !Model?.convites.some(e => e.liberado) ? 'Salvar' : 'Disparar novos'}</ButtonCmp>
                </Flex>
            </WhiteContainer>
        </Body>
  )
}

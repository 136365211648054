import styled from 'styled-components'

interface ICardAbsolute{
  position?: string
  left?: string
  width?: string
}

export const Container = styled.div`
display: flex;
margin-bottom: 1rem;
justify-content: space-between;

overflow-x: auto;

::-webkit-scrollbar {
  height: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

export const Icone = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    padding:1rem;
    background: aliceblue;
    border: 0.188rem solid var(--terc4);
    background: #${props => props.theme};
    svg{
      color: white;
    }
`

export const TextIcon = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 78px;
    text-align: center;
    span{
      font-family: 'Roboto','sans-serif';
      font-weight: 500;
      font-size: 12px;
      margin-top:.5rem;
    }
`

export const WidthContainer = styled.div<ICardAbsolute>`
    display: flex;
    width: ${props => props.width};
    hr{
      width:100%;
      min-width: 1rem;
      height:0.313rem ;
      background: var(--terc4);
      border: none;
      margin-top: 2.4rem;
    }
`

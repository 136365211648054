import styled from 'styled-components'

interface MetaProps{
  fontsize: string
  padding: string
  heigth: string
  margin?: string
  marginR?: string
}

export const Content = styled.a<MetaProps>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 color: var(--c1);
 background:var(--${props => props.theme});
 padding: var(--${props => props.padding});
 font-size:var(--${props => props.fontsize});
 height:var(--${props => props.heigth});
 line-height: 1px;
 margin-right: ${props => props.marginR};
 margin: ${props => props.margin};

 &:hover{
    transition:var(--transitionBtns);
    box-shadow: var(--SombraBtns);
    filter: brightness(1.05);
}
`

import styled from 'styled-components'

export const BtnPerfil = styled.button`
border: 2px solid var(--${props => props.theme});
color: var(--${props => props.theme});
border-radius: 0.25rem;
background: none;
font-size: 0.875rem;
font-family: 'Roboto','sans-serif';
font-weight: bold;
height: 1.875rem;
display: flex;
justify-content: center;
align-items: center;

&:hover{
    background: var(--${props => props.theme});
    color: white;
}
`

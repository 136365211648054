/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Divider, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../../../components/InputCmp'
import { WhiteModal } from '../../../../../../../../components/ModalCreateForm/styles'
import { appApi } from '../../../../../../../../services/appApi'
import { iEscalaFlexivel } from '../Escala'
import { v4 as uuidv4 } from 'uuid'
import { CheckboxCmp } from '../../../../../../../../components/CheckboxCmp'

interface iModalProps {
  isOpen: boolean
  onSave: (selecionado: string) => void
  onClose: () => void
  escala?: iEscalaFlexivel
}

export const ModalEscala: React.FC<iModalProps> = ({ isOpen, onClose, onSave, escala }) => {
  const [Escala, setEscala] = useState<iEscalaFlexivel>({ id: '', nome: '', questoes: [{ alternativa: '', id: uuidv4() }], ocultarPeso: false })
  const [IsLoading, setIsLoading] = useState(false)

  function postEscala(): void {
    setIsLoading(true)
    if (escala) {
      appApi.put(`EscalaFlexivel/${escala.id}`, Escala)
        .then(res => {
          onSave(res.data)
          setEscala({ id: '', nome: '', questoes: [{ alternativa: '', id: uuidv4() }], ocultarPeso: false })
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    } else {
      appApi.post('EscalaFlexivel', Escala)
        .then(res => {
          onSave(res.data)
          setEscala({ id: '', nome: '', questoes: [{ alternativa: '', id: uuidv4() }], ocultarPeso: false })
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    }
  }

  function UpdateArray(pos: number, texto: string, isPeso: boolean): void {
    const copy = [...Escala.questoes]
    if (!isPeso) {
      copy[pos].alternativa = texto
    } else {
      copy[pos].peso = parseFloat(texto)
    }
    setEscala({ ...Escala, questoes: copy })
  }

  useEffect(() => {
    if (escala) {
      setEscala(escala)
    } else {
      setEscala({ id: '', nome: '', questoes: [{ alternativa: '', id: uuidv4() }], ocultarPeso: false })
    }
  }, [escala])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal style={{ width: '52rem' }}>
        <FormControl>
          <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} mb={'.25rem'}>
            <Text fontSize={'1.375rem'} lineHeight={'1.5rem'} fontWeight={'700'} textAlign={'center'}>Escala</Text>
					  <Text fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'600'} textAlign={'center'}>Crie e/ou edite uma escala</Text>
          </Flex>
          <InputCmp label='Nome' OnChange={(e) => { setEscala({ ...Escala, nome: e }) }} value={Escala?.nome}/>
          <CheckboxCmp my={'.75rem'} OnChange={(e) => setEscala({ ...Escala, ocultarPeso: e })} isChecked={Escala.ocultarPeso}>Mostrar o peso das alternativas durante o preenchimento</CheckboxCmp>
          <RadioGroup width={'100%'}>
                <FormLabel mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} wordBreak={'keep-all'}>Questões</FormLabel>
                <Stack direction={'column'} width={'100%'}>
                    {
                        Escala.questoes.map((e, i) => {
                          return (
                            <Flex gap={'.5rem'}>
                              <Input
                                alignItems={'center'}
                                justifyContent={'center'}
                                textAlign={'center'}
                                border={'1px solid var(--c4)'}
                                h={'2.25rem'}
                                width={'6rem'}
                                value={e.peso}
                                step={'0.1'}
                                type={'number'}
                                fontSize={'.875rem'}
                                placeholder={'Peso'}
                                onChange={(r) => UpdateArray(i, r.target.value, true)}
                              />
                              <Flex
                                  key={e.id}
                                  borderRadius={'4px'}
                                  alignItems={'center'}
                                  h={'2.25rem'}
                                  justifyContent={'space-between'}
                                  border={'1px solid var(--c4)'}
                                  width={'100%'}
                                  padding={'0 .875rem'}
                              >
                                  <Flex width={'100%'}>
                                      <Radio marginRight={'.75rem'} value={e.id}/>
                                      <Input
                                          type={'text'}
                                          border={'none'}
                                          _focus={{ border: 'none' }}
                                          padding={'0'}
                                          h={'2.125rem'}
                                          mr={'.5rem'}
                                          value={e.alternativa}
                                          fontSize={'.875rem'}
                                          placeholder={'Digite sua alternativa aqui'}
                                          onChange={(r) => UpdateArray(i, r.target.value, false)}
                                      />
                                  </Flex>
                                  <Flex alignItems={'center'} justifyContent={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }} onClick={() => setEscala({ ...Escala, questoes: Escala.questoes.filter(a => { if (a.id !== e.id) { return a } }) })}>
                                    <MdClose
                                        size={18}
                                        cursor={'pointer'}
                                    />
                                  </Flex>
                              </Flex>
                            </Flex>
                          )
                        })
                }
            </Stack>
        </RadioGroup>

        <Flex marginTop={'.75rem'}>
            <Radio isReadOnly isChecked={true} borderColor={'var(--c5)'}/>
            <Input
                border={'none'}
                value={'Adicione mais uma opção'}
                borderBottom={'1px solid var(--Gray4)'}
                width={'fit-content'}
                boxShadow='none'
                h={'2.125rem'}
                borderRadius={0}
                color={'var(--c6)'}
                fontSize={'.875rem'}
                _hover={{ borderBottom: '1px solid var(--Azul)', boxShadow: 'var(--SombraBtns)', color: 'var(--Azul)' }}
                _focus={{ borderBottom: '1px solid var(--Gray4)' }}
                _active={{ borderBottom: '1px solid var(--Gray4)' }}
                readOnly
                cursor={'pointer'}
                onClick={() => setEscala({ ...Escala, questoes: [...Escala.questoes, { alternativa: '', id: uuidv4() }] })}
            />
          </Flex>
        </FormControl>

        <Flex justifyContent={'end'} gap={'.5rem'} mt={'.75rem'}>
          <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
          <ButtonCmp isLoading={IsLoading} VarColor='Green2' onClick={() => postEscala()}>Salvar</ButtonCmp>
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

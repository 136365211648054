import styled from 'styled-components'

export const HeadBox = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
margin-bottom:.5rem;
`

export const DropMenu = styled.ul<{display?: string}>`
list-style-type: none;
background: white;
box-shadow: var(--SombraMenuDropdown);
position: absolute;
width: 17.3rem;
padding: 0.5rem;
border-bottom-left-radius: .5rem;
border-bottom-right-radius: .5rem;
display:${props => props.display};
z-index: 100;

li{
    padding: .8rem;
    cursor: pointer;
    background: var(--c2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    small{
        font-weight: 400;
        font-size: 0.875rem;
    }

div,button{
    padding: .5rem 1rem .5rem 1rem;
}

div{
    svg{
        color: var(--v2);
    }
}

    button{
        background: var(--v2);
        color: var(--c1);
        border-radius: .625rem;
        display: flex;
        align-items: center;

        &:hover{
            transition:var(--transitionBtns);
            filter: brightness(1.05);
            box-shadow: var(--SombraBtns);
        }
    }
}

li:not(:last-child){
    margin-bottom:.5rem;
}

li:hover{
    background: var(--c3);
    small{
        font-weight: 500;
    }
}

li:last-child{
    margin-bottom:.2rem;
}

li:first-child{
    margin-top:.2rem;
}
`

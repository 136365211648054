import { Flex, Tooltip, TooltipProps } from '@chakra-ui/react'

export const ElementoTooltipCmp: React.FC<TooltipProps> = ({ w, children, placement, label, hasArrow, bg, color, p, mr, ...rest }) => {
  const config = {
    placement: placement ?? 'top',
    label: label ?? '',
    fontSize: '.875rem',
    radius: '.25rem',
    hasArrow: hasArrow ?? true,
    bg: bg ?? 'var(--Azul)',
    color: color ?? 'white',
    p: p ?? '.625rem',
    mr: mr ?? ''
  }
  return (
        <Tooltip
            placement={config.placement}
            label={config.label}
            hasArrow={config.hasArrow}
            bg={config.bg}
            borderRadius={config.radius}
            fontSize={config.fontSize}
            lineHeight={'.875rem'}
            color={config.color}
            w={'100%'}
            p={config.p}
            mr={config.mr}
            {...rest}
        >
            <Flex justifyContent={'center'} minW={w}>
                {children}
            </Flex>
        </Tooltip>
  )
}

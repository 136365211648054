import styled from 'styled-components'

export const SectionOnboard = styled.div`
display: flex;
flex-direction: column;
gap: .75rem;
& > div {
    margin-top: .75rem;
}
`

export const ColumnTitle = styled.div`
display: flex;
flex-direction: column;
margin: .75rem 0rem;

h2{
    color:var(--a3);
}
`

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Text, Flex, FormControl, FormLabel, Radio, RadioGroup, Stack, useToast, Box, Avatar, InputRightElement, InputGroup } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleRight, FaChevronRight, FaClock, FaPlus, FaSave, FaTimes, FaTrash } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { FindUser } from '../../../../components/FindBox'
import { NavLine } from '../../../../components/NavLine'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iAvatarXInformacoes, iEtapa, iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { AdicionarDia, DeleteElementByPosition, FluxoEtapa, PropInvalida, UpdateElementByPosition, useQuery, ValidarEtapa } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { Legenda } from './styles'
import LogoZoom from '../../../../assets/logo-zoom.svg'
import LogoMicrosoft from '../../../../assets/microsoft-teams-logo.svg'
import { parseJwt } from '../../../../services/token'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { useAuth } from '../../../../contexts/AuthContext'
import { ElementoTooltipCmp } from '../../../../components/ElementoTooltipCmp'
import { MdOutlineClose } from 'react-icons/md'
import { PopUpCriar } from './Components/PopUpCriar'
import { ModalAgendar } from './Components/ModalAgendar'
import { ButtonMenu } from '../../../RH/VisualizarTrilhaColaborador/Components/ContainerTrilha/styles'
import { ModalCadastroEmMassa } from './Components/ModalCadastroEmMassa'
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs'
import { ModalAdicionarReuniao } from './Components/ModalAdicionarReuniao'
import { FormFeedback } from './Components/FormFeedback'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { SelectCmp } from '../../../../components/SelectCmp'
import { InputCmp } from '../../../../components/InputCmp'

export interface iModel {
  participanteId?: string
  responsavelId?: string
  baseId?: string
  pesquisaId?: string
  alvoPesquisaId?: string
  periodicidade?: number
  assunto: string
  local: string
  descricao: string
  duracao?: number
  tipoId: string
  lembrete: boolean
  presencial: boolean
  datasDisponiveis?: string[]
  dataInicio?: string
  liberarFinalizar: boolean
  tipoResponsavel: number
  planilha?: iParticipantesPlanilha[]
}

export interface iParticipantesPlanilha {
  responsavel: iAvatarXInformacoes
  participante: iAvatarXInformacoes
}

export interface iFormFeedback {
  formularioId?: string
  dataInicio: string
  tipoRespondente: number
  visualizarResposta: boolean
  edicaoResposta: number[]
  textosEmail: iTextosPesquisa[]
  textosAbertura: iTextosPesquisa[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export const ConfigReuniaoOne: React.FC = () => {
  const { adm } = parseJwt()
  const { Permissoes } = useAuth()
  const { pathname } = useLocation()
  const { reuniaoId, etapaId, trilhaId, processoId } = useParams<{ reuniaoId: string, etapaId: string, trilhaId: string, processoId: string }>()
  const toast = useToast()
  const isTrilha = FluxoEtapa(pathname)
  const isEditar = pathname.includes('Editar')
  const nav = useNavigate()
  const query = useQuery()
  const textoPadraoEmail = '<p><strong>Olá, [NOME DO RESPONDENTE]!</strong></p><p><strong>Você está recebendo um link para preenchimento relacionado ao feedback entre você e o [NOME DO AVALIADO].</strong></p><p><strong>Para preencher o feedback clique no link abaixo:</strong></p><p><strong><a>[LINK]</a></strong></p><p><strong>Qualquer dúvida contacte o responsável!</strong></p>'
  const textoPadraoAbertura = '<p><strong>Bem vindo ao questionário relacionado ao feedback entre o colaborador [NOME DO AVALIADO] e o colaborador [NOME DO RESPONDENTE]</strong></p>'

  const [AdicionarPesquisa, setAdicionarPesquisa] = useState(false)
  const [Ciclica, setCiclica] = useState(false)
  const [AdicionarFeedback, setAdicionarFeedback] = useState(false)
  const [ReuniaoOutra, setReuniaoOutra] = useState(false)
  const [EditorIsLoading, setEditorIsLoading] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)

  const [NovaFuncao, setNovaFuncao] = useState({
    on: false,
    texto: ''
  })

  const [Model, setModel] = useState<iModel>({
    assunto: '',
    liberarFinalizar: true,
    datasDisponiveis: !isTrilha ? [new Date().toISOString().split('T')[0] + 'T00:00'] : [],
    descricao: '',
    duracao: 5,
    lembrete: true,
    local: '',
    presencial: true,
    planilha: [],
    tipoId: '',
    participanteId: pathname.includes('Processo') ? processoId : undefined,
    tipoResponsavel: 1
  })

  const [Etapa, setEtapa] = useState<iEtapa>({})
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Reunioes, setReunioes] = useState<iNomeId[]>([])
  const [Pesquisas, setPesquisas] = useState<iNomeId[]>([])
  const [Tipos, setTipos] = useState<iNomeId[]>([])
  const [EtapaBase, setEtapaBase] = useState<{ etapa: iEtapa, reuniao: iModel }>()

  const [PopUpIsOpen, setPopUpIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [TipoIsInvalid, setTipoIsInvalid] = useState(false)
  const [AssuntoIsInvalid, setAssuntoIsInvalid] = useState(false)
  const [LocalIsInvalid, setLocalIsInvalid] = useState(false)

  const [ModalAgendamentoIsOpen, setModalAgendamentoIsOpen] = useState(false)
  const [ModalCadastroMassaIsOpen, setModalCadastroMassaIsOpen] = useState(false)
  const [ModalAddReuiaoIsOpen, setModalAddReuiaoIsOpen] = useState(false)
  const [TipoLocal, setTipoLocal] = useState<'zoom' | 'microsoft' | 'outro'>('outro')

  const [Opcao, setOpcao] = useState(0)

  const [Planilha, setPlanilha] = useState<iParticipantesPlanilha[]>([])
  const [Feedback, setFeedback] = useState<iFormFeedback>({
    formularioId: '',
    dataInicio: '',
    textosAbertura: [{ texto: textoPadraoAbertura, participantes: [0, 1] }],
    textosEmail: [{ texto: textoPadraoEmail, participantes: [0, 1] }],
    edicaoResposta: [],
    tipoRespondente: 2,
    visualizarResposta: false
  })

  function SelecionarUsuario(id: string): void {
    setModel({ ...Model, participanteId: id })
  }

  function SelecionarResponsavel(id: string): void {
    setModel({ ...Model, responsavelId: id })
  }

  function getColaboradores(): void {
    appApi.get('ReuniaoOne/Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterReunioes(id: string): void {
    const rota = pathname.includes('Processo') ? 'ReuniaoOneEtapaColaborador' : pathname.includes('Trilha') ? 'ReuniaoOneEtapa' : 'ReuniaoOne'
    appApi.get(`${rota}/Select/${id}`)
      .then(res => {
        if (res.status === 200) {
          setReunioes(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function ObterPesquisas(id: string): void {
    setModel({ ...Model, alvoPesquisaId: id })
    appApi.get(`ReuniaoOne/Pesquisas?colaboradorId=${id}`)
      .then(res => {
        if (res.status === 200) {
          setPesquisas(res.data)
        } else {
          if (parseJwt().cid !== id) {
            toast({
              title: 'Nenhuma pesquisa encontrada para este participante, talvez você não tenha permissão para visualizar!',
              status: 'warning',
              isClosable: false,
              position: 'top-right',
              duration: 3000
            })
          }
          setPesquisas([])
        }
      })
      .catch(err => console.log(err))
  }

  function ObterTipos(): void {
    appApi.get('TipoXEmpresa/Select?tipo=2')
      .then(res => {
        setTipos(res.data)
      })
      .catch(err => console.log(err))
  }

  function CriarTipos(): void {
    appApi.post('TipoXEmpresa', {
      texto: NovaFuncao.texto,
      tipo: 2
    })
      .then(() => {
        toast({
          title: 'Tipo criado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setNovaFuncao({
          on: false,
          texto: ''
        })
        ObterTipos()
      })
      .catch(err => console.log(err))
  }

  function CriarReuniao(): void {
    if (!ValidarCampos()) { return }

    if (!isTrilha) {
      if (PropInvalida(Model.participanteId)) {
        toast({
          title: 'Selecione um participante!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }
      const form: iModel = {
        ...Model,
        datasDisponiveis: Opcao !== 1 ? Model.datasDisponiveis?.filter(e => e !== '00-00-00T00:00') : undefined,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        duracao: Opcao !== 1 ? Model.duracao : undefined
      }
      setIsLoading(true)
      appApi.post(`ReuniaoOne?temFeedback=${AdicionarFeedback}`, form)
        .then(res => {
          onOpenPopUp(res.data)
          setIsLoading(false)
        })
        .catch(({ response }) => {
          setIsLoading(false)
          toast({
            title: response.data,
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        })
    } else if (pathname.includes('Trilha')) {
      if (!ValidarEtapa(Etapa)) {
        toast({
          title: 'Preencha os campos de etapa',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: []
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }

      setIsLoading(true)
      appApi.post(`ReuniaoOneEtapa/${trilhaId}`, form)
        .then(() => {
          toast({
            title: 'Etapa criada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          nav(-1)
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    } else if (pathname.includes('Processo')) {
      if (!ValidarEtapa(Etapa)) {
        toast({
          title: 'Preencha os campos de etapa',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: Model.datasDisponiveis,
        alvoPesquisaId: !PropInvalida(Model.pesquisaId) ? Model.participanteId : undefined
      }
      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }
      setIsLoading(true)
      appApi.post(`ReuniaoOneEtapaColaborador/${processoId}`, form)
        .then(res => {
          onOpenPopUp(res.data)
          setIsLoading(false)
        })
        .catch(({ response }) => {
          toast({
            title: response.data,
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setIsLoading(false)
        })
    }
  }

  function AtualizarReuniao(): void {
    if (!ValidarCampos()) { return }

    if (!isTrilha) {
      const form: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined
      }

      setIsLoading(true)
      appApi.put(`ReuniaoOne/${reuniaoId}/Configuracao`, form)
        .then(res => {
          toast({
            title: 'Reunião atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          nav(-1)
        })
        .catch(({ response }) => {
          toast({
            title: response.data,
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setIsLoading(false)
        })
    } else if (pathname.includes('Trilha')) {
      if (!ValidarEtapa(Etapa)) {
        toast({
          title: 'Preencha os campos de etapa',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }

      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: []
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }

      setIsLoading(true)
      appApi.put(`ReuniaoOneEtapa/${etapaId}`, form)
        .then(() => {
          toast({
            title: 'Etapa atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })

          nav(-1)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err)
        })
    } else if (pathname.includes('Processo')) {
      if (!ValidarEtapa(Etapa)) {
        toast({
          title: 'Preencha os campos de etapa',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }

      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: [],
        alvoPesquisaId: !PropInvalida(Model.pesquisaId) ? Model.participanteId : undefined
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }

      setIsLoading(true)
      appApi.put(`ReuniaoOneEtapaColaborador/${processoId}/Etapa/${etapaId}`, form)
        .then(() => {
          toast({
            title: 'Etapa atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })

          nav(-1)
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  function ObterReuniao(id: string): void {
    setEditorIsLoading(true)
    if (!isTrilha) {
      appApi.get(`ReuniaoOne/${id}/Configuracao`)
        .then(res => {
          const data: iModel = res.data
          setModel({
            ...Model,
            baseId: id,
            assunto: data.assunto,
            descricao: data.descricao,
            duracao: data.duracao,
            lembrete: data.lembrete,
            local: data.local,
            participanteId: data.participanteId,
            tipoId: data.tipoId,
            presencial: data.presencial,
            periodicidade: data.periodicidade,
            pesquisaId: data.pesquisaId,
            alvoPesquisaId: data.alvoPesquisaId,
            liberarFinalizar: data.liberarFinalizar
          })
          if (!PropInvalida(data.pesquisaId)) {
            setAdicionarPesquisa(true)
          }

          if (!PropInvalida(data.periodicidade)) {
            setCiclica(true)
          }

          if (!data.presencial) {
            setTipoLocal(data.local === 'microsoft' ? 'microsoft' : data.local === 'zoom' ? 'zoom' : 'outro')
          }
          setEditorIsLoading(false)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Trilha')) {
      appApi.get(`ReuniaoOneEtapa/${etapaId}`)
        .then(res => {
          const dataReuniao: iModel = res.data.reuniao
          const dataEtapa: iEtapa = res.data.etapa
          setModel({
            ...Model,
            baseId: id,
            assunto: dataReuniao.assunto,
            descricao: dataReuniao.descricao,
            duracao: dataReuniao.duracao,
            lembrete: dataReuniao.lembrete,
            local: dataReuniao.local,
            participanteId: dataReuniao.participanteId,
            tipoId: dataReuniao.tipoId,
            presencial: dataReuniao.presencial,
            periodicidade: dataReuniao.periodicidade,
            pesquisaId: dataReuniao.pesquisaId,
            tipoResponsavel: dataReuniao.tipoResponsavel,
            liberarFinalizar: dataReuniao.liberarFinalizar,
            responsavelId: dataReuniao.responsavelId
          })
          if (!PropInvalida(dataReuniao.pesquisaId)) {
            setAdicionarPesquisa(true)
          }
          if (!dataReuniao.presencial) {
            setTipoLocal(dataReuniao.local === 'microsoft' ? 'microsoft' : dataReuniao.local === 'zoom' ? 'zoom' : 'outro')
          }
          setEtapa(dataEtapa)
          setEditorIsLoading(false)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Processo')) {
      appApi.get(`ReuniaoOneEtapaColaborador/${processoId}/Etapa/${etapaId}`)
        .then(res => {
          const dataReuniao: iModel = res.data.reuniao
          const dataEtapa: iEtapa = res.data.etapa
          setModel({
            ...Model,
            baseId: id,
            assunto: dataReuniao.assunto,
            descricao: dataReuniao.descricao,
            duracao: dataReuniao.duracao,
            lembrete: dataReuniao.lembrete,
            local: dataReuniao.local,
            participanteId: dataReuniao.participanteId,
            tipoId: dataReuniao.tipoId,
            presencial: dataReuniao.presencial,
            periodicidade: dataReuniao.periodicidade,
            pesquisaId: dataReuniao.pesquisaId,
            alvoPesquisaId: dataReuniao.alvoPesquisaId,
            tipoResponsavel: dataReuniao.tipoResponsavel,
            liberarFinalizar: dataReuniao.liberarFinalizar,
            responsavelId: dataReuniao.responsavelId
          })
          if (!PropInvalida(dataReuniao.pesquisaId)) {
            setAdicionarPesquisa(true)
          }
          if (!dataReuniao.presencial) {
            setTipoLocal(dataReuniao.local === 'microsoft' ? 'microsoft' : dataReuniao.local === 'zoom' ? 'zoom' : 'outro')
          }
          setEtapa(dataEtapa)
          setEditorIsLoading(false)
        })
        .catch(err => console.log(err))
    }
  }

  function ObterEtapaBase(id: string): void {
    const rota = pathname.includes('Processo')
      ? `ReuniaoOneEtapaColaborador/${processoId}/Etapa/${id}`
      : pathname.includes('Trilha') ? `ReuniaoOneEtapa/${id}` : ''

    appApi.get(`${rota}`)
      .then(res => {
        setEditorIsLoading(true)
        const dataReuniao: iModel = res.data.reuniao
        const dataEtapa: iEtapa = res.data.etapa
        setEtapaBase({
          ...EtapaBase,
          reuniao: {
            assunto: dataReuniao.assunto,
            descricao: dataReuniao.descricao,
            duracao: dataReuniao.duracao,
            lembrete: dataReuniao.lembrete,
            local: dataReuniao.local,
            tipoId: dataReuniao.tipoId,
            presencial: dataReuniao.presencial,
            periodicidade: dataReuniao.periodicidade,
            pesquisaId: dataReuniao.pesquisaId,
            liberarFinalizar: dataReuniao.liberarFinalizar,
            alvoPesquisaId: dataReuniao.alvoPesquisaId,
            tipoResponsavel: dataReuniao.tipoResponsavel,
            datasDisponiveis: []
          },
          etapa: dataEtapa
        })
        setModel({
          ...Model,
          assunto: dataReuniao.assunto,
          descricao: dataReuniao.descricao,
          duracao: dataReuniao.duracao,
          lembrete: dataReuniao.lembrete,
          local: dataReuniao.local,
          baseId: id ?? undefined,
          tipoId: dataReuniao.tipoId,
          presencial: dataReuniao.presencial,
          periodicidade: dataReuniao.periodicidade,
          pesquisaId: dataReuniao.pesquisaId,
          liberarFinalizar: dataReuniao.liberarFinalizar,
          alvoPesquisaId: dataReuniao.alvoPesquisaId,
          tipoResponsavel: dataReuniao.tipoResponsavel,
          datasDisponiveis: []
        })
        if (!PropInvalida(dataReuniao.pesquisaId)) {
          setAdicionarPesquisa(true)
        }
        setEditorIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function CriarEmMassa(): void {
    if (!ValidarCampos() || (AdicionarFeedback && !ValidarFeedback())) { return }

    setIsLoading(true)
    appApi.post('ReuniaoOne/EmMassa', {
      configuracao: Model,
      feedback: AdicionarFeedback ? Feedback : undefined,
      Reunioes: Planilha.map(e => {
        return {
          responsavelId: e.responsavel.id,
          participanteId: e.participante.id
        }
      })
    })
      .then(() => {
        nav(-1)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  function onOpenPopUp(id: string): void {
    setId(id)
    setPopUpIsOpen(true)
  }

  function onClosePopUp(): void {
    if (!isTrilha) {
      if (AdicionarFeedback) {
        nav(pathname.replace('Criar', `Feedback/${Id}/Formularios`))
      } else {
        nav(pathname.replace('Criar', `Visualizar/${Id}`))
      }
    } else if (pathname.includes('Trilha')) {
      if (AdicionarFeedback) {
        nav(pathname.replace('Criar', `Feedback/${Id}/Formularios`))
      } else {
        toast({
          title: 'Etapa criada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        nav(pathname.replace('/NovaReuniaoOne/Criar', ''))
      }
    } else if (pathname.includes('Processo')) {
      if (AdicionarFeedback) {
        nav(pathname.replace('Criar', `Feedback/${Id}/Formularios`))
      } else {
        toast({
          title: 'Etapa criada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        nav(-1)
      }
    }
  }

  function AlterarData(dataInicio: string): void {
    setModel({
      ...Model,
      dataInicio: dataInicio
    })

    setEtapa({
      ...Etapa,
      dataInicio: dataInicio,
      dataFim: AdicionarDia(dataInicio, 1)
    })

    toast({
      title: 'Data e duração definidas com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
    setModalAgendamentoIsOpen(false)
  }

  function ValidarCampos(): boolean {
    setTipoIsInvalid(false)
    setAssuntoIsInvalid(false)
    setLocalIsInvalid(false)

    if (Model.assunto.length < 5) {
      setAssuntoIsInvalid(true)
      toast({
        title: 'O assunto da reunião deve conter no mínimo 5 caracteres!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (PropInvalida(Model.tipoId)) {
      setTipoIsInvalid(true)
      toast({
        title: 'Selecione um tipo para a sua reunião!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.local.length === 0) {
      setLocalIsInvalid(true)
      toast({
        title: 'Preencha o local da reunião!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function ConverterDataToBr(data: string): string {
    const result = data.split('T')
    const [year, month, day] = result[0].split('-')
    const [Hours, Minutes] = result[1].split(':')

    return `${day}/${month}/${year} ${Hours}:${Minutes}`
  }

  function ValidarFeedback(): boolean {
    if (PropInvalida(Feedback.dataInicio)) {
      toast({
        title: 'Preencha a data de início do Feedback!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (PropInvalida(Feedback.formularioId)) {
      toast({
        title: 'Selecione um formulário para o feedback!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    return true
  }

  useEffect(() => {
    ObterTipos()
    if (pathname.includes('Editar') || query.get('reuniaoId')) {
      ObterReuniao(reuniaoId ?? query.get('reuniaoId') ?? '')
    }
    getColaboradores()
  }, [])

  useEffect(() => {
    if (Model.participanteId && !isTrilha) {
      ObterReunioes(Model.participanteId)
    }
  }, [Model.participanteId])

  useEffect(() => {
    if (processoId || trilhaId) {
      ObterReunioes(processoId ?? trilhaId ?? '')
    }
  }, [processoId, trilhaId])

  useEffect(() => {
    if (AdicionarPesquisa) {
      ObterPesquisas(Model.participanteId ?? '')
    }
  }, [AdicionarPesquisa])

  return (
    <Body>
      {ModalAgendamentoIsOpen &&
        <ModalAgendar
          onClose={() => setModalAgendamentoIsOpen(false)}
          data={Model.dataInicio}
          onSave={AlterarData}
          min={EtapaBase?.etapa?.dataFim}
        />
      }

      {PopUpIsOpen &&
        <PopUpCriar
          agendar={!PropInvalida(Model.dataInicio)}
          onClose={onClosePopUp}
        />
      }

      <NavLine
        options={[
          {
            nome: 'Reunião 1:1',
            path: (pathname.includes('Jornada') ? '/Jornada' : '/Desempenho') + '/NovaReuniaoOne/Dashboard/Individual'
          },
          { nome: 'Solicitação de reunião 1:1' }
        ]}
      />

      {!isTrilha && !isEditar &&
        <Flex gap={'1rem'} mb={'1rem'}>
          {(adm === 'True' || Permissoes?.funcionalidades?.some(e => e.tipo === 4 && e.liberado)) &&
            <ButtonMenu
              selected={Opcao === 0}
              onClick={() => setOpcao(0)}
            >Solicitar reunião 1:1</ButtonMenu>
          }

          {(adm === 'True' || Permissoes?.funcionalidades?.some(e => e.tipo === 4 && e.liberado)) &&
            <ButtonMenu
              selected={Opcao === 1}
              onClick={() => setOpcao(1)}
            >Criar reunião 1:1</ButtonMenu>
          }

          {(adm === 'True' || Permissoes?.funcionalidades?.some(e => e.tipo === 4 && e.liberado)) &&
            <ButtonMenu
              selected={Opcao === 2}
              onClick={() => setOpcao(2)}
            >Gerar reuniões 1:1 em massa</ButtonMenu>
          }
        </Flex>
      }

      <WhiteContainer>
        <Flex justifyContent={'space-between'}>
          <h1>{Opcao === 0 ? 'Solicitar reunião 1:1' : Opcao === 1 ? 'Criar reunião 1:1 para outros colaboradores' : 'Gerar reuniões 1:1 em massa'}</h1>
        </Flex>
        {!isTrilha &&
          <Flex flexDir={'column'} gap={'.75rem'} mt={'.75rem'}>
            <CheckboxCmp OnChange={() => setCiclica(!Ciclica)} isChecked={Ciclica}>Reunião recorrente</CheckboxCmp>
            {(Ciclica) && (
              <SelectCmp value={Model.periodicidade?.toString()} OnChange={(e) => setModel({ ...Model, periodicidade: parseInt(e) })}>
                <option value={''}>Nenhuma</option>
                <option value={'1'}>Diário</option>
                <option value={'2'}>Semanal</option>
                <option value={'3'}>Quinzenal</option>
                <option value={'4'}>Mensal</option>
                <option value={'5'}>Bimestral</option>
                <option value={'6'}>Trimestral</option>
                <option value={'7'}>Semestral</option>
              </SelectCmp>
            )}
          </Flex>
        }

        {Opcao === 1 &&
          <Flex mt={'.75rem'} flexDirection={'column'}>
            <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Responsável</FormLabel>
            <FindUser
              isDisabled={isEditar}
              lista={Colaboradores}
              onChoice={(e) => {
                SelecionarResponsavel(e)
              }}
              placeholder='Digite o nome do responsável'
              selecionado={Model.responsavelId}
            />
          </Flex>
        }

        {!isTrilha && Opcao !== 2 &&
          <Flex mt={'.75rem'} flexDirection={'column'}>
            <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Participante</FormLabel>
            <FindUser
              isDisabled={isEditar}
              lista={Colaboradores}
              onChoice={(e) => {
                SelecionarUsuario(e)
                setReuniaoOutra(false)
                setAdicionarPesquisa(false)
              }}
              placeholder='Digite o nome do participante'
              selecionado={Model.participanteId}
            />
          </Flex>
        }

        {!isTrilha && !isEditar && Opcao !== 2 &&
          <Flex flexDir={'column'} mt={'.5rem'}>
            <CheckboxCmp
              OnChange={() => setReuniaoOutra(!ReuniaoOutra)}
              isDisabled={!Model.participanteId || Reunioes.length === 0}
              isChecked={ReuniaoOutra && !!Model.participanteId}
            >Criar reunião a partir de outra já realizada com esse participante</CheckboxCmp>
            {ReuniaoOutra &&
              (<>
                <Text mb={'.5rem'}>Selecione uma reunião</Text>
                <SelectCmp value={Model.baseId} OnChange={(e) => {
                  setModel({ ...Model, baseId: e })
                  ObterReuniao(e)
                }}
                  w={'42rem'}
                >
                  <option value={''}>Nenhuma</option>
                  {Reunioes.map((e, i) => {
                    return (
                      <option value={e.id} key={i}>{e.nome}</option>
                    )
                  })}
                </SelectCmp>
                <Legenda>A nova reunião iniciada já será incrementada com os tópicos que não foram concluídos da última reunião e com os próximos passos acrescentados como tópicos</Legenda>
              </>)
            }

            {Model.participanteId && Reunioes.length === 0 &&
              <Text color={'var(--Red)'} fontSize={'.813rem'} lineHeight={'1.5rem'}>Não há reuniões criadas para esse colaborador</Text>
            }
          </Flex>
        }

        {isTrilha &&
          <Flex mt={'1rem'} flexDir={'column'}>
            {
              Etapa === undefined
                ? <CreateEtapaBox
                  keyWord='reunião'
                  EditEtapa={Etapa}
                  etapaBase={EtapaBase?.etapa}
                  onModelChange={(e) => setEtapa(e)}
                  variant='Etapa'
                  isDisabled={!PropInvalida(processoId) && !isEditar && (Model.datasDisponiveis && Model?.datasDisponiveis?.length > 0)}
                />
                : <CreateEtapaBox
                  keyWord='reunião'
                  etapaBase={EtapaBase?.etapa}
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  onModelChange={(e) => setEtapa(e)}
                  variant='Etapa'
                  isDisabled={!PropInvalida(processoId) && !isEditar && !PropInvalida(Model.dataInicio)}
                />
            }
            {!PropInvalida(processoId) && !isEditar &&
              <Flex gap={'.5rem'} flexDir={'column'} my={'.75rem'}>
                <Text fontWeight={'bold'} fontSize={'.875rem'} lineHeight={'1rem'}>Ou</Text>
                <Flex alignItems={'center'} gap={'1rem'}>
                  {PropInvalida(Model.dataInicio) &&
                    <ButtonCmp w={'fit-content'}
                      VarColor='Green2'
                      rightIcon={<FaClock />}
                      onClick={() => setModalAgendamentoIsOpen(true)}
                    >Agendar agora</ButtonCmp>
                  }

                  {Model.dataInicio &&
                    <Flex fontWeight={600} flexDir={'column'}>
                      <Text>Agendada: {ConverterDataToBr(Model.dataInicio)}</Text>
                    </Flex>
                  }
                  {!PropInvalida(Model.dataInicio) &&
                    <ButtonCmp w={'fit-content'}
                      VarColor='Red'
                      onClick={() => setModel({ ...Model, dataInicio: undefined })}
                    >Cancelar</ButtonCmp>
                  }
                </Flex>
              </Flex>
            }
          </Flex>
        }

        {isTrilha && !isEditar &&
          <Flex flexDir={'column'} mt={'.5rem'}>
            <CheckboxCmp
              OnChange={() => setReuniaoOutra(!ReuniaoOutra)}
              isDisabled={Reunioes.length === 0}
              isChecked={ReuniaoOutra}
            >Criar reunião a partir de outra etapa de reunião 1:1</CheckboxCmp>
            {ReuniaoOutra &&
              (<>
                <Text mb={'.5rem'}>Selecione uma reunião</Text>
                <SelectCmp OnChange={(e) => {
                  ObterEtapaBase(e)
                }} w={'42rem'}>
                  <option value={''}>Nenhuma</option>
                  {Reunioes.map((e, i) => {
                    return (
                      <option value={e.id} key={i}>{e.nome}</option>
                    )
                  })}
                </SelectCmp>
                <Legenda>A reunião receberá os assuntos não finalizados e os próximos passos da reunião selecionada. O agendamento será liberado após a reunião vinculada ser finalizada.</Legenda>
              </>)
            }

            {Model.participanteId && Reunioes.length === 0 &&
              <Text color={'var(--Red)'} fontSize={'.875rem'} mt={'.25rem'}>Não há reuniões criadas nesse processo de onboard!</Text>
            }
          </Flex>
        }

        <Flex gap={'.75rem'} my={'.75rem'} alignItems={'center'}>
          <FormControl>
            <InputCmp
              label='Assunto da reunião'
              value={Model.assunto}
              placeholder={'Digite o assunto da reunião'}
              isInvalid={AssuntoIsInvalid}
              OnChange={(e) => setModel({ ...Model, assunto: e })}
            />
          </FormControl>
          <Flex flexDir={'column'} w={'100%'}>
            <Flex flexDir={'column'}>
              <Flex alignItems={'end'} gap={'.25rem'} mb={'.5rem'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'0'} lineHeight={'16px'}>Selecione o tipo da reunião</FormLabel>

                {!NovaFuncao.on && Permissoes?.funcionalidades?.some(e => e.tipo === 3 && e.liberado) &&
                  <ButtonCmp
                    onClick={() => setNovaFuncao({ ...NovaFuncao, on: true })}
                    leftIcon={<FaPlus />}
                    small
                    VarColor='Rosa'
                  >Adicionar novo tipo</ButtonCmp>
                }

                {NovaFuncao.on &&
                  <ButtonCmp
                    onClick={() => setNovaFuncao({
                      on: false,
                      texto: ''
                    })}
                    small
                    leftIcon={<FaTimes />}
                    backgroundColor={'var(--Red)'}
                  >Cancelar</ButtonCmp>
                }
              </Flex>
              {NovaFuncao.on &&
                <InputGroup>
                  <InputRightElement h={'1.95rem'} cursor={'pointer'} onClick={CriarTipos}>
                    <FaSave color='var(--Green2)' />
                  </InputRightElement>
                  <InputCmp
                    borderColor={'var(--Gray4)'}
                    placeholder='Digite o nome'
                    OnChange={(e) => setNovaFuncao({ ...NovaFuncao, texto: e })}
                  />
                </InputGroup>
              }

            </Flex>
            {!NovaFuncao.on &&
              <SelectCmp
                borderColor={'var(--Gray4)'}
                OnChange={(e) => setModel({ ...Model, tipoId: e })}
                value={Model.tipoId}
                isInvalid={TipoIsInvalid}
              >
                <option value={''}>Nenhum</option>
                {Tipos.map((r, i) => {
                  return (
                    <option key={i} value={r.id}>{r.nome}</option>
                  )
                })}
              </SelectCmp>
            }
          </Flex>
        </Flex>

        {isTrilha &&
          <Flex flexDir={'column'} marginBottom={'.75rem'}>
            <Flex mb={'1rem'} flexDir={'column'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Responsável pela reunião</FormLabel>
              <Legenda>Essa pessoa definirá a data e poderá alterar os participantes</Legenda>
            </Flex>
            <Flex flexDir={'column'}>
              <RadioGroup
                onChange={(e) => setModel({ ...Model, tipoResponsavel: parseInt(e), responsavelId: undefined })}
                value={Model.tipoResponsavel.toString()}
              >
                <Stack direction='row'>
                  <Radio value='0'>Rh</Radio>
                  <Radio value='1'>Gestor</Radio>
                  <Radio value='2'>Outro</Radio>
                </Stack>
              </RadioGroup>
              {Model.tipoResponsavel === 2 &&
                <Flex mt={'.5rem'}>
                  <FindUser
                    lista={Colaboradores}
                    onChoice={(id: string) => setModel({ ...Model, responsavelId: id })}
                    placeholder='Digite o nome do responsável'
                    selecionado={Model.responsavelId}
                  />
                </Flex>
              }
            </Flex>
          </Flex>
        }

        <Flex flexDirection={'column'} mt={!isTrilha && !isEditar && Opcao !== 1 ? '.75rem' : ''}>
          <Flex alignItems={'start'} gap='.75rem'>
            {!isTrilha && !isEditar && Opcao !== 1 &&
              <Flex flexDir={'column'} w={'full'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Data e hora</FormLabel>
                <Flex flexDir={'column'} gap={'.75rem'}>
                  {Model.datasDisponiveis?.map((e, i) => {
                    return (
                      <Flex gap={'.5rem'} alignItems={'center'} key={i} w={'full'}>
                        <InputCmp
                          value={e}
                          min={new Date().toISOString().slice(0, 16)}
                          max={'2099-12-31T23:59'}
                          OnChange={(data) => setModel({ ...Model, datasDisponiveis: UpdateElementByPosition(Model.datasDisponiveis ?? [], i, data) })}
                          type={'datetime-local'}
                        />
                        {i > 0 &&
                          <ElementoTooltipCmp label='Excluir' bg={'var(--Red)'}>
                            <MdOutlineClose
                              onClick={() => setModel({ ...Model, datasDisponiveis: DeleteElementByPosition([...Model.datasDisponiveis ?? []], i) })}
                              cursor={'pointer'} color='var(--Red)'
                            />
                          </ElementoTooltipCmp>
                        }
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>
            }

            {Opcao !== 1 && <FormControl w={'full'}>
              <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Duração</FormLabel>
              <SelectCmp value={Model.duracao?.toString()} OnChange={(e) => setModel({ ...Model, duracao: parseInt(e) })} borderColor={'var(--Gray4)'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </SelectCmp>
            </FormControl>}
          </Flex>
        </Flex>

        <Flex gap={'.75rem'} my={'1rem'}>
          <Box w={'full'} display={'flex'} flexDirection={'column'}>
            <CheckboxCmp
              isChecked={!Model.lembrete}
              OnChange={() => setModel({ ...Model, lembrete: !Model.lembrete })}
            >Desabilitar lembretes do envio e push</CheckboxCmp>
            <Legenda style={{ marginTop: '.125rem' }}>Ao desabilitar o lembrete você não receberá notificações momentos antes da reunião</Legenda>
          </Box>

          <Box w={'full'} display={'flex'} flexDirection={'column'}>
            <CheckboxCmp
              isChecked={Model.liberarFinalizar}
              OnChange={() => setModel({ ...Model, liberarFinalizar: !Model.liberarFinalizar })}
            >Colaborador pode finalizar reunião</CheckboxCmp>
            <Legenda style={{ marginTop: '.125rem' }}>Quando chegar a data de fim informada, habilitar que o participante encerre a reunião</Legenda>
          </Box>
        </Flex>

        <RadioGroup
          onChange={() => setModel({ ...Model, presencial: !Model.presencial })}
          value={Model.presencial ? '1' : '0'}
          mb={'.75rem'}
          size={'sm'}
        >
          <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Local</FormLabel>
          <Stack direction={'row'} borderColor={'var(--Gray4)'}>
            <Radio value={'0'}>Online</Radio>
            <Radio value={'1'}>Presencial</Radio>
          </Stack>
        </RadioGroup>

        {(!Model.presencial) && (
          <Flex gap={'.75rem'} mb={TipoLocal === 'outro' ? '.75rem' : ''} alignItems={'center'}>
            <RadioGroup
              display={'flex'}
              alignItems={'center'}
              size={'sm'}
              onChange={(e: 'zoom' | 'microsoft' | 'outro') => {
                setTipoLocal(e)
                if (e === 'zoom') {
                  setEditorIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'zoom',
                    descricao: '<p>Reunião do Zoom</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setTimeout(() => setEditorIsLoading(false), 10)
                } else if (e === 'microsoft') {
                  setEditorIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'microsoft',
                    descricao: '<p>Reunião do Microsoft Teams</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setTimeout(() => setEditorIsLoading(false), 10)
                } else {
                  setEditorIsLoading(true)
                  setModel({ ...Model, local: '', descricao: '' })
                  setTimeout(() => setEditorIsLoading(false), 10)
                }
              }}
              value={TipoLocal}
              gap='1rem'
              isDisabled={!Permissoes.zoom}
            >
              <Radio value={'zoom'}>
                <ElementoTooltipCmp
                  label={!Permissoes.zoom ? 'Zoom necessita de integração, vá até a configuração da empresa!' : ''}
                >
                  <img src={LogoZoom} alt="" />
                </ElementoTooltipCmp>
              </Radio>

              <Radio
                value={'microsoft'}
                onClick={() => {

                }}
              >
                <ElementoTooltipCmp
                  label={!Permissoes.teams ? 'Teams necessita de integração, vá até a configuração da empresa!' : ''}
                >
                  <img src={LogoMicrosoft} alt="" />
                </ElementoTooltipCmp>
              </Radio>

              <Radio value={'outro'}>Outro</Radio>
            </RadioGroup>
          </Flex>
        )}

        {(Model.presencial || TipoLocal === 'outro') && (
          <InputCmp
            value={Model.local}
            isInvalid={LocalIsInvalid}
            OnChange={(e) => setModel({ ...Model, local: e })}
            placeholder={Model.presencial ? 'Digite o local da reunião' : 'Cole o link da reunião aqui'}
          />
        )}

        <Flex flexDir={'column'} mt={'.5rem'}>
          <FormControl>
            <FormLabel color={'var(--Gray1)'} fontSize={'18px'} my={'.25rem'}>Descrição</FormLabel>
            <EditorTexto
              initialValue={Model.descricao}
              placeholder='Deixe uma descrição aqui'
              className='editorSt-v3'
              isLoading={EditorIsLoading}
              onChange={(e: string) => setModel({ ...Model, descricao: e })}
            />
          </FormControl>
          <Legenda style={{ marginTop: '.25rem' }}>Digite um texto com informações importantes ou pauta para registro do que será tratado na reunião</Legenda>
        </Flex>

        {!isEditar && !isTrilha && Opcao !== 2 &&
          <Flex flexDir={'column'} mt={'1rem'}>
            <CheckboxCmp OnChange={() => setAdicionarFeedback(!AdicionarFeedback)}>Acrescentar feedback</CheckboxCmp>
            <Legenda style={{ marginTop: '0' }}>As configurações para o feedback irão aparecer logo após a criação da reunião</Legenda>
          </Flex>
        }

        {!pathname.includes('Trilha') && Opcao !== 2 &&
          <Flex flexDir={'column'} mt={'.75rem'} mb={'1rem'}>
            <CheckboxCmp
              isChecked={AdicionarPesquisa && !!Model.participanteId}
              OnChange={(e) => { e ? setAdicionarPesquisa(e) : setAdicionarPesquisa(e); setModel({ ...Model, pesquisaId: '' }) }}
              isDisabled={!Model.participanteId}
            >Acrescentar pesquisa de desempenho ou percepção</CheckboxCmp>
            {(AdicionarPesquisa && Model.participanteId) && (
              <Flex flexDir={'column'} mt={'.75rem'} gap={'.75rem'}>
                <Flex flexDir={'column'} w={'100%'}>
                  <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Quem é o alvo dessa pesquisa</FormLabel>
                  <RadioGroup
                    onChange={ObterPesquisas}
                    value={Model.alvoPesquisaId}
                    size={'sm'}
                  >
                    <Stack gap={'1rem'} direction='row'>
                      {!isTrilha &&
                        <Radio value={parseJwt().cid}>Eu</Radio>
                      }
                      <Radio value={Model.participanteId}>Participante</Radio>

                    </Stack>
                  </RadioGroup>
                </Flex>
                <FormControl w={'42rem'}>
                  <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Selecione uma pesquisa</FormLabel>
                  <SelectCmp
                    value={Model.pesquisaId ?? ''}
                    OnChange={(e) => setModel({ ...Model, pesquisaId: e })}
                  >
                    <option value={''}>Nenhuma</option>
                    {Pesquisas.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>{e.nome}</option>
                      )
                    })}
                  </SelectCmp>
                  <Legenda>Só são apresentadas pesquisas que você ou o participante participaram como avaliado</Legenda>
                </FormControl>
              </Flex>
            )}
          </Flex>
        }

        {Opcao !== 2 &&
          <Flex marginTop={'1rem'} justifyContent={'end'} gap={'.5rem'}>
            <ButtonCmp
              VarColor='c6'
              onClick={() => nav(-1)}
            >Cancelar</ButtonCmp>

            {!isEditar && Opcao !== 2 &&
              <ButtonCmp
                onClick={CriarReuniao}
                VarColor='Green2'
                rightIcon={<FaAngleRight />}
                isLoading={IsLoading}
              >{isTrilha ? 'Criar etapa' : 'Solicitar'}</ButtonCmp>
            }

            {isEditar &&
              <ButtonCmp
                onClick={AtualizarReuniao}
                VarColor='Green2'
                rightIcon={<FaAngleRight />}
                isLoading={IsLoading}
              >Atualizar {isTrilha ? 'etapa' : 'reunião'}</ButtonCmp>
            }
          </Flex>
        }

        {Opcao === 2 &&
          <Flex flexDir={'column'} mt='2rem' gap={'1rem'}>
            {ModalCadastroMassaIsOpen &&
              <ModalCadastroEmMassa
                onRequestClose={() => setModalCadastroMassaIsOpen(false)}
                onPush={(model) => setPlanilha(
                  [...Planilha.concat(model.filter(e =>
                    !Planilha?.some(r =>
                      r.participante.id === e.participante.id &&
                      r.responsavel.id === e.responsavel.id
                    )
                  ))]
                )}
              />
            }
            {ModalAddReuiaoIsOpen &&
              <ModalAdicionarReuniao
                onConfirm={(responsavel, participante) => setPlanilha([...Planilha, {
                  responsavel: responsavel,
                  participante: participante
                }])}
                onRequestClose={() => setModalAddReuiaoIsOpen(false)}
                exists={(responsavelId, partivipanteId) => Planilha?.some(e => e.responsavel.id === responsavelId && e.participante.id === partivipanteId)}
              />
            }
            <Flex flexDir={'column'}>
              <CheckboxCmp OnChange={() => setAdicionarFeedback(!AdicionarFeedback)}>Acrescentar feedback</CheckboxCmp>
              <Legenda>As configurações para o feedback irão aparecer logo após a criação da reunião</Legenda>
            </Flex>
            {AdicionarFeedback &&
              <Flex flexDir={'column'} gap='2rem'>
                <FormFeedback
                  model={Feedback}
                  onChange={(model) => setFeedback(model)}
                />
              </Flex>
            }
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <h3>Relação de participantes</h3>
              <ButtonCmp
                onClick={() => setModalCadastroMassaIsOpen(true)}
                VarColor='Rosa'
                rightIcon={<BsFileEarmarkSpreadsheet />}
              >Cadastrar planilha</ButtonCmp>
            </Flex>
            <Box border={'2px solid var(--Gray5)'} p={'1rem'} borderRadius={'.25rem'}>
              <Flex justifyContent={'space-between'} mb={'.5rem'} alignItems={'center'}>
                <h4>Lista de reuniões</h4>
                <ButtonCmp
                  w={'12rem'}
                  onClick={() => setModalAddReuiaoIsOpen(true)}
                  VarColor='Green2'
                  small
                  rightIcon={<FaPlus />}
                >
                  Adicionar
                </ButtonCmp>
              </Flex>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Flex my={'.5rem'} flexWrap={'wrap'} w={'full'}>
                  {Planilha.map((e, i) => {
                    return (
                      <Flex key={i} px={'1rem'} py={'.75rem'} bgColor={(i % 2 === 0) ? 'var(--AzulOpacoBg)' : ''} justifyContent={'space-between'} w={'full'}>
                        <Flex flex={'1'} gap={'1rem'} alignItems={'center'}>
                          <Flex gap={'.5rem'} position={'relative'} minW={'14rem'}>
                            <Avatar border={'1px solid var(--Azul)'} src={e.responsavel.avatar} name={e.responsavel.info1} size={'sm'} />
                            <Flex alignItems={'center'} gap={'.3rem'}>
                              <Box>
                                <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'700'}>{e.responsavel.info1.length < 22 ? e.responsavel.info1 : `${e.responsavel.info1.substring(0, 22)}...`}</Text>
                                <Legenda>Responsável</Legenda>
                              </Box>
                            </Flex>
                          </Flex>
                          <Flex gap={'.5rem'} position={'relative'} minW={'14rem'}>
                            <Avatar border={'1px solid var(--Azul)'} src={e.participante.avatar} name={e.participante.info1} size={'sm'} />
                            <Flex alignItems={'center'} gap={'.3rem'}>
                              <Box>
                                <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'700'}>{e.participante.info1.length < 22 ? e.participante.info1 : `${e.participante.info1.substring(0, 22)}...`}</Text>
                                <Legenda>Participante</Legenda>
                              </Box>
                            </Flex>
                          </Flex>
                        </Flex>
                        <ButtonCmp
                          onClick={() => setPlanilha(DeleteElementByPosition([...Planilha], i))}
                          OutlineColor='Red'
                          w={'1.75rem'}
                          small>
                            <FaTrash />
                        </ButtonCmp>
                      </Flex>
                    )
                  })}
                </Flex>
              </Box>
            </Box>
            <Flex justifyContent={'end'}>
              <ButtonCmp
                fontWeight={'500'}
                VarColor='Green2'
                w={'14rem'}
                rightIcon={<FaChevronRight size={13}/>}
                isDisabled={Planilha.length === 0}
                onClick={CriarEmMassa}
                isLoading={IsLoading}
              >Disparar reuniões</ButtonCmp>
            </Flex>
          </Flex>
        }
      </WhiteContainer>
    </Body>
  )
}

import styled from 'styled-components'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
align-items: center;
font-size: .875rem;
padding: 1rem 1rem 0 1rem;

small{
    color: var(--Red);
    font-weight: 500;
}

input[type=file]{
    display: none;
}
`

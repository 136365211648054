import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, HStack, Image, Input, Spinner, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
// import imgp0 from '../../../../assets/pi/p0.png'
import imgp8 from '../../../../assets/pi/p8.png'
import imgp10 from '../../../../assets/pi/p10.png'
import imgp12 from '../../../../assets/pi/p12.png'
import imgp14 from '../../../../assets/pi/p14.png'
import imgp16 from '../../../../assets/pi/p16.png'
import imgp18 from '../../../../assets/pi/p18.png'
import imgp20 from '../../../../assets/pi/p20.png'
import imgp22 from '../../../../assets/pi/p22.png'
import imgp24 from '../../../../assets/pi/p24.png'
import imgp26 from '../../../../assets/pi/p26.png'
import imgp28 from '../../../../assets/pi/p28.png'
import imgp30 from '../../../../assets/pi/p30.png'
import imgp32 from '../../../../assets/pi/p32.png'
import imgp34 from '../../../../assets/pi/p34.png'
import imgp36 from '../../../../assets/pi/p36.png'
import imgp38 from '../../../../assets/pi/p38.png'
import imgp40 from '../../../../assets/pi/p40.png'
import imgp42 from '../../../../assets/pi/p42.png'
import imgp44 from '../../../../assets/pi/p44.png'
import imgp46 from '../../../../assets/pi/p46.png'
import imgp48 from '../../../../assets/pi/p48.png'
import imgp50 from '../../../../assets/pi/p50.png'
import imgp52 from '../../../../assets/pi/p52.png'
import imgp54 from '../../../../assets/pi/p54.png'
import imgp56 from '../../../../assets/pi/p56.png'
import imgp58 from '../../../../assets/pi/p58.png'
import imgp60 from '../../../../assets/pi/p60.png'
import imgp62 from '../../../../assets/pi/p62.png'
import imgp64 from '../../../../assets/pi/p64.png'
import imgp66 from '../../../../assets/pi/p66.png'
import imgp68 from '../../../../assets/pi/p68.png'
import imgp70 from '../../../../assets/pi/p70.png'
import imgp72 from '../../../../assets/pi/p72.png'
import imgp74 from '../../../../assets/pi/p74.png'
import imgp76 from '../../../../assets/pi/p76.png'
import imgp78 from '../../../../assets/pi/p78.png'
import imgp80 from '../../../../assets/pi/p80.png'
import imgp82 from '../../../../assets/pi/p82.png'
import imgp84 from '../../../../assets/pi/p84.png'
import imgp86 from '../../../../assets/pi/p86.png'
import imgp88 from '../../../../assets/pi/p88.png'
import imgp90 from '../../../../assets/pi/p90.png'
import imgp92 from '../../../../assets/pi/p92.png'
import imgp94 from '../../../../assets/pi/p94.png'
import imgp96 from '../../../../assets/pi/p96.png'
import { BotaoPerfil, IOnSelectProps } from './components'
import './components/styles.css'
import { FaCheck, FaSync, FaTrash } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
import { appApi } from '../../../../services/appApi'
import { AutorizarPI } from '../../../../Utils/Helper'

interface IperfilProp {
  perfil: string
  adaptativo: boolean
}
export interface IJobTargetProp {
  id?: string
  nome: string
  perfisAderentes: string[]
  perfisAdaptativos: string[]

}
export interface IJobTarget {
  id?: string
  nome: string
  descricao: string
  perfisAderentes: string[]
  perfisAdaptativos: string[]
  empresaId: string
  percentualPopulacaoAderente: number
  percentualPopulacaoAdaptativa: number
  numeroAderentes: number
  numeroAdaptativos: number
  numeroNaoAderentes: number
  ultimoProcessamento?: Date

}
type navProps = {
  jobId: string
}

export const JobTargetCreate: React.FC = () => {
  const jobDefault: IJobTarget = {
    nome: '',
    perfisAdaptativos: [],
    perfisAderentes: [],
    descricao: '',
    empresaId: '',
    numeroAdaptativos: 0,
    numeroAderentes: 0,
    numeroNaoAderentes: 0,
    percentualPopulacaoAdaptativa: 0,
    percentualPopulacaoAderente: 0,
    ultimoProcessamento: undefined

  }
  const navigate = useNavigate()
  const [selecionarAdaptativo, setSelecionarAdaptativo] = useState(false)
  const [modoEdit, setmodoEdit] = useState(false)
  const [isLoading, setisLoading] = useState(true)
  const { jobId } = useParams<navProps>()
  const { pathname } = useLocation()
  // const [populacaoAdaptativo, setPopulacaoAdaptativo] = useState(0)
  // const [populacaoAderente, setPopulacaoAderente] = useState(0)
  const [perfisSelecionados, setPerfisSelecionados] = useState<IperfilProp[]>([])
  const [jobTarget, setJobtarget] = useState<IJobTarget>(jobDefault)
  const toast = useToast()
  const populacaoPi: {[id: string]: number} = {
    0: 0.001,
    8: 3.04,
    10: 4.15,
    12: 5.20,
    14: 1.95,
    16: 0.05,
    18: 0.30,
    20: 1.74,
    22: 4.00,
    24: 5.33,
    26: 3.67,
    28: 1.81,
    30: 2.11,
    32: 1.30,
    34: 0.64,
    36: 2.85,
    38: 0.59,
    40: 1.96,
    42: 0.86,
    44: 0.58,
    46: 7.49,
    48: 1.83,
    50: 1.89,
    52: 0.61,
    54: 1.75,
    56: 0.79,
    58: 0.48,
    60: 6.45,
    62: 2.56,
    64: 4.35,
    66: 1.77,
    68: 0.94,
    70: 11.91,
    72: 0.88,
    74: 0.58,
    76: 0.11,
    78: 2.64,
    80: 2.58,
    82: 1.29,
    84: 0.18,
    86: 2.06,
    88: 1.57,
    90: 1.49,
    92: 1.55,
    94: 0.06,
    96: 1.21
  }

  function calcularPopulacao(adaptativo: boolean): number {
    let total = 0

    for (let index = 0; index < perfisSelecionados.length; index++) {
      if (perfisSelecionados[index].adaptativo === adaptativo) {
        total += populacaoPi[perfisSelecionados[index].perfil]
      }
    }
    return total
  }

  function handleSubmit(): void {
    const aderentes = perfisSelecionados.filter(p => !p.adaptativo).map(({ perfil }) => perfil)
    const adaptativos = perfisSelecionados.filter(p => p.adaptativo).map(({ perfil }) => perfil)
    console.log(aderentes)
    console.log(adaptativos)
    if (jobTarget.nome.length < 3) {
      toast({
        title: 'O nome do cargo deve conter no mínimo 3 caracteres',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }
    if (aderentes.length < 1) {
      toast({
        title: 'Você deve selecionar ao menos 1 perfil aderente',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }
    if (modoEdit) {
      appApi.put(`AssessmentPI/JobTarget/${jobId ?? ''}`, { ...jobTarget, perfisAdaptativos: adaptativos, perfisAderentes: aderentes })
        .then(res => {
          console.log(res.data)
          toast({
            title: 'Job target cadastrado com sucesso!',
            position: 'top-right',
            variant: 'left-accent',
            status: 'success',
            duration: 9000,
            isClosable: false
          })
          navigate('/AssessmentPi/Dashboard2')
        }
        ).catch(err => {
          toast({
            title: 'Não foi possível cadastrar o Job target',
            description: 'Tente novamente mais tarde',
            position: 'top-right',
            variant: 'left-accent',
            status: 'error',
            duration: 9000,
            isClosable: false
          })

          console.log(err)
        })
    } else {
      appApi.post('AssessmentPI/JobTarget', { ...jobTarget, perfisAdaptativos: adaptativos, perfisAderentes: aderentes })
        .then(res => {
          console.log(res.data)
          toast({
            title: 'Job target cadastrado com sucesso!',
            position: 'top-right',
            variant: 'left-accent',
            status: 'success',
            duration: 9000,
            isClosable: false
          })
          navigate('/AssessmentPi/Dashboard2')
        }
        ).catch(err => {
          toast({
            title: 'Não foi possível cadastrar o Job target',
            description: 'Tente novamente mais tarde',
            position: 'top-right',
            variant: 'left-accent',
            status: 'error',
            duration: 9000,
            isClosable: false
          })

          console.log(err)
        })
    }
  }
  function handleDelete(): void {
    appApi.delete(`AssessmentPI/JobTarget/${jobId ?? ''}`)
      .then(res => {
        toast({
          title: 'Job target removido com sucesso!',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })
        navigate('/AssessmentPi/Dashboard2')
      }
      ).catch(err => {
        toast({
          title: 'Não foi possível remover o Job target',
          description: 'Tente novamente mais tarde',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })

        console.log(err)
      })
  }

  useEffect(() => {
    if (!AutorizarPI()) {
      navigate('/')
    }
    if (!jobId) {
      setisLoading(false)
      setmodoEdit(false)
      return
    }

    setmodoEdit(true)
    // setJobtarget({ ...jobTarget, id: jobId })
    appApi.get<IJobTarget>(`AssessmentPI/JobTarget/${jobId ?? ''}`)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setJobtarget(res.data)
        }
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (pathname.includes('Edit') && jobTarget) {
      const perfisDb: IperfilProp[] = []
      jobTarget.perfisAdaptativos.forEach((s) => {
        perfisDb.push({ adaptativo: true, perfil: s })
      })
      jobTarget.perfisAderentes.forEach((s) =>
        perfisDb.push({ adaptativo: false, perfil: s }))
      setPerfisSelecionados(perfisDb)
      setisLoading(false)
    }
  }, [jobId, jobTarget])

  function selecionaPerfil({ selecionado, valor, adaptativo }: IOnSelectProps): void {
    if (perfisSelecionados.length > 0) {
      const posPerfil = perfisSelecionados.findIndex((obj) => { return obj.perfil === valor })
      if (posPerfil >= 0) {
        if (selecionado) {
          setPerfisSelecionados([...perfisSelecionados.slice(0, posPerfil), { adaptativo: adaptativo ?? selecionarAdaptativo, perfil: valor }, ...perfisSelecionados.slice(posPerfil + 1)])
        } else {
          setPerfisSelecionados([...perfisSelecionados.slice(0, posPerfil), ...perfisSelecionados.slice(posPerfil + 1)])
        }
      } else {
        setPerfisSelecionados([...perfisSelecionados, { adaptativo: adaptativo ?? selecionarAdaptativo, perfil: valor }])
      }
    } else {
      setPerfisSelecionados([...perfisSelecionados, { adaptativo: adaptativo ?? selecionarAdaptativo, perfil: valor }])
    }
  }

  interface IBotaoSelecao{
    selecionado: boolean
    adaptativo: boolean
  }

  function selecionado(valor: string): IBotaoSelecao {
    const posPerfil = perfisSelecionados.findIndex((obj) => { return obj.perfil === valor })
    if (posPerfil === -1) return { selecionado: false, adaptativo: selecionarAdaptativo }

    return { selecionado: true, adaptativo: perfisSelecionados[posPerfil].adaptativo }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  return (

  <Body>
    <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          Excluir cargo
        </AlertDialogHeader>

        <AlertDialogBody>
          Você tem certeza que deseja excluir o cargo? Você não poderá desfazer essa operação depois.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button colorScheme='blackAlpha' ref={cancelRef} onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme='red' onClick={() => { onClose(); handleDelete() }} ml={3}>
            Excluir cargo
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>

<Flex justify='center'>
<Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>
<Flex flexDirection='column' p={4}>
<Flex justifyContent='space-between'>

<Text fontSize='xl' mb={5} fontWeight='bold'>Aderência comportamental</Text>

{modoEdit && (

  <Button
  onClick={() => { onOpen() } }
 colorScheme='red' width={'5rem'} height='2.0rem' size='xs' m={3} leftIcon={<FaTrash/>}>Excluir</Button>
)}
</Flex>
<Text fontWeight='bold' mb={2}>Nome do cargo</Text>
<Input value={jobTarget.nome} placeholder='Adicione o nome do cargo' onChange={(value) => { setJobtarget({ ...jobTarget, nome: value.target.value }) }}/>
<Text fontWeight='bold' my={2}>Descrição</Text>
<Input value={jobTarget.descricao} placeholder='Digite a descrição do cargo (opcional)' onChange={(value) => { setJobtarget({ ...jobTarget, descricao: value.target.value }) }}/>

<Flex mt={4} justifyContent='space-around'>

<VStack width='35%' border='1px' p={3} borderRadius='2xl' borderColor='var(--Green1)'>

  <Text fontWeight='bold' mb={2} color='var(--Green1)'><FaCheck/> Perfis aderentes: {perfisSelecionados.filter(a => !a.adaptativo).length}<Image ml={5} height='1.7rem' src='https://sigah.blob.core.windows.net/assets/brasil1.png'/>{calcularPopulacao(false).toPrecision(3)} %</Text>

<Flex gap='0.5rem' wrap='wrap' maxWidth='80%'>

  {perfisSelecionados.filter(a => !a.adaptativo).sort((a, b) => {
    return parseInt(a.perfil) < parseInt(b.perfil) ? -1 : 1
    // a.perfil.localeCompare(b.perfil)
  })
    .map((s) => {
      return (<Text key={s.perfil} fontWeight='bold' mb={2}>
    {s.perfil}
    </Text>
      )
    })}
</Flex>
</VStack>
<VStack width='35%' height='11rem' border='1px' p={3} borderRadius='2xl' borderColor='var(--Blue1)'>
<Text fontWeight='bold' mb={0} pb={0} color='var(--Blue1)'><FaSync/> Perfis flexibilizados: {perfisSelecionados.filter(a => a.adaptativo).length} <Image ml={5} height='1.7rem' src='https://sigah.blob.core.windows.net/assets/brasil1.png'/>{calcularPopulacao(true).toPrecision(3)} %</Text>
<Flex gap='0.5rem' wrap='wrap' maxWidth='80%'>
  {perfisSelecionados.filter(a => a.adaptativo).sort((a, b) => parseInt(a.perfil) < parseInt(b.perfil) ? -1 : 1)
    .map((s) => {
      return (<Text key={s.perfil} fontWeight='bold' mb={2}>
    {s.perfil}
    </Text>
      )
    })}
</Flex>
</VStack>
</Flex>
<Flex gap={'1rem'} my={4}>
  <Button size='sm'
  border={!selecionarAdaptativo ? '4px' : '0'} borderColor='var(--Green3)' onClick={() => setSelecionarAdaptativo(false)}
  _hover={{ bg: 'var(--Green1)' }} animation='ease-in-out' transitionDuration='0.7s'
  backgroundColor={selecionarAdaptativo ? 'var(--Green3)' : 'var(--Green1)'} rightIcon={<FaCheck/>}>Selecionar perfis aderentes</Button>
  <Button size='sm' animation='ease-in-out' transitionDuration='0.3s'
    border={selecionarAdaptativo ? '4px' : '0'}
    borderColor= '#87b1ec'
  onClick={() => setSelecionarAdaptativo(true)} backgroundColor={!selecionarAdaptativo ? '#87b1ec' : 'var(--Blue1)'}
  _hover={{ bg: 'var(--Blue1)' }} rightIcon={<FaSync/>}>Selecionar perfis flexibilizados</Button>
</Flex>
{isLoading && (<Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl'
/>)}
{!isLoading && (

  <Flex my={4} wrap='wrap' justifyContent='space-between' gap='1rem'>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('8').selecionado} valor='8' adaptativo={selecionado('8').adaptativo}> <Image src={imgp8} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('10').selecionado} valor='10' adaptativo={selecionado('10').adaptativo}><Image src={imgp10} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('12').selecionado} valor='12' adaptativo={selecionado('12').adaptativo}><Image src={imgp12} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('14').selecionado} valor='14' adaptativo={selecionado('14').adaptativo}><Image src={imgp14} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('16').selecionado} valor='16' adaptativo={selecionado('16').adaptativo}><Image src={imgp16} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('18').selecionado} valor='18' adaptativo={selecionado('18').adaptativo}><Image src={imgp18} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('20').selecionado} valor='20' adaptativo={selecionado('20').adaptativo}><Image src={imgp20} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('22').selecionado} valor='22' adaptativo={selecionado('22').adaptativo}><Image src={imgp22} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('24').selecionado} valor='24' adaptativo={selecionado('24').adaptativo}><Image src={imgp24} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('26').selecionado} valor='26' adaptativo={selecionado('26').adaptativo}><Image src={imgp26} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('28').selecionado} valor='28' adaptativo={selecionado('28').adaptativo}><Image src={imgp28} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('30').selecionado} valor='30' adaptativo={selecionado('30').adaptativo}><Image src={imgp30} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('32').selecionado} valor='32' adaptativo={selecionado('32').adaptativo}><Image src={imgp32} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('34').selecionado} valor='34' adaptativo={selecionado('34').adaptativo}><Image src={imgp34} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('36').selecionado} valor='36' adaptativo={selecionado('36').adaptativo}><Image src={imgp36} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('38').selecionado} valor='38' adaptativo={selecionado('38').adaptativo}><Image src={imgp38} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('40').selecionado} valor='40' adaptativo={selecionado('40').adaptativo}><Image src={imgp40} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('42').selecionado} valor='42' adaptativo={selecionado('42').adaptativo}><Image src={imgp42} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('44').selecionado} valor='44' adaptativo={selecionado('44').adaptativo}><Image src={imgp44} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('46').selecionado} valor='46' adaptativo={selecionado('46').adaptativo}><Image src={imgp46} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('48').selecionado} valor='48' adaptativo={selecionado('48').adaptativo}><Image src={imgp48} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('50').selecionado} valor='50' adaptativo={selecionado('50').adaptativo}><Image src={imgp50} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('52').selecionado} valor='52' adaptativo={selecionado('52').adaptativo}><Image src={imgp52} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('54').selecionado} valor='54' adaptativo={selecionado('54').adaptativo}><Image src={imgp54} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('56').selecionado} valor='56' adaptativo={selecionado('56').adaptativo}><Image src={imgp56} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('58').selecionado} valor='58' adaptativo={selecionado('58').adaptativo}><Image src={imgp58} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('60').selecionado} valor='60' adaptativo={selecionado('60').adaptativo}><Image src={imgp60} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('62').selecionado} valor='62' adaptativo={selecionado('62').adaptativo}><Image src={imgp62} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('64').selecionado} valor='64' adaptativo={selecionado('64').adaptativo}><Image src={imgp64} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('66').selecionado} valor='66' adaptativo={selecionado('66').adaptativo}><Image src={imgp66} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('68').selecionado} valor='68' adaptativo={selecionado('68').adaptativo}><Image src={imgp68} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('70').selecionado} valor='70' adaptativo={selecionado('70').adaptativo}><Image src={imgp70} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('72').selecionado} valor='72' adaptativo={selecionado('72').adaptativo}><Image src={imgp72} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('74').selecionado} valor='74' adaptativo={selecionado('74').adaptativo}><Image src={imgp74} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('76').selecionado} valor='76' adaptativo={selecionado('76').adaptativo}><Image src={imgp76} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('78').selecionado} valor='78' adaptativo={selecionado('78').adaptativo}><Image src={imgp78} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('80').selecionado} valor='80' adaptativo={selecionado('80').adaptativo}><Image src={imgp80} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('82').selecionado} valor='82' adaptativo={selecionado('82').adaptativo}><Image src={imgp82} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('84').selecionado} valor='84' adaptativo={selecionado('84').adaptativo}><Image src={imgp84} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('86').selecionado} valor='86' adaptativo={selecionado('86').adaptativo}><Image src={imgp86} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('87').selecionado} valor='87' adaptativo={selecionado('87').adaptativo}><Image src={imgp88} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('90').selecionado} valor='90' adaptativo={selecionado('90').adaptativo}><Image src={imgp90} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('92').selecionado} valor='92' adaptativo={selecionado('92').adaptativo}><Image src={imgp92} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('94').selecionado} valor='94' adaptativo={selecionado('94').adaptativo}><Image src={imgp94} className='imgp'/></BotaoPerfil>
<BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('96').selecionado} valor='96' adaptativo={selecionado('96').adaptativo}><Image src={imgp96} className='imgp'/></BotaoPerfil>
{/* <BotaoPerfil onSelect={(props) => selecionaPerfil(props)} selecionado={selecionado('0').selecionado} valor='0' adaptativo={selecionado('0').adaptativo}><Image src={imgp0} className='imgp'/></BotaoPerfil> */}
</Flex>
)}

  <Flex justifyContent='end' p={4}>
<HStack>
<Button
onClick={() => navigate('/AssessmentPi/Dashboard2')}
bg='var(--c6)' width='5rem' height='2.5rem' size='xs' m={3}>Cancelar</Button>
<Button
onClick={() => { handleSubmit() } }
bg='var(--Rosa)' width={modoEdit ? '7rem' : '5rem'} height='2.5rem' size='xs' m={3}>Salvar{modoEdit ? ' alterações' : ''}</Button>
</HStack>

</Flex>

</Flex>
</Box>
  </Flex>
  </Body>
  )
}

import styled from 'styled-components'

export const TableDiv = styled.div`
    border-radius:.25rem;
    font-size: .875rem;
table{
    width: 100%;
}

table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

th,td{
    max-width: 12rem;
    padding: .5rem .75rem .5rem .75rem;
}

td{
    text-align: center;
}

th{
    font-weight: bold;
}

/* tr:hover{
background: var(--c3);
cursor: pointer;
} */

thead > tr > th:hover{
background: var(--Azul);
color: var(--c1);
cursor: pointer;
transition: all .3s ease;

svg{
    opacity: 100%;
    padding-left: 5px;
}
}

thead > tr > th > div{
display: flex;
align-items: center;
justify-content: center;

svg{
    opacity: 0%;
}
}
`

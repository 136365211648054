import { Avatar, Box, Button, Flex, HStack, Text, Image, MenuList, Menu, MenuButton, MenuItem, VStack, Checkbox } from '@chakra-ui/react'
import { FaBan, FaCheck, FaChevronDown, FaSync } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
interface IOutrosCargos{
  nome: string
  jobId: string
}
interface ICardColaboradorProps{
  avatar: any
  imgPerfil: any
  nome: string
  dataPesquisa: string
  cargos?: IOutrosCargos[]
  par?: boolean
  tipo: number
  perfil?: string
  id?: string
  selo?: string
  urlSelo?: string
  assessmentId?: string
  selecionado?: boolean
  onSelect: (id: string) => void

}
export const CardColaborador: React.FC<ICardColaboradorProps> = ({ assessmentId, onSelect, selecionado, imgPerfil, nome, avatar, dataPesquisa, cargos, par, tipo, perfil, id, selo, urlSelo }: ICardColaboradorProps) => {
  const navigate = useNavigate()
  return (
    <Box border='1px' borderColor='var(--c3)'
     my={0} bgColor={((par === undefined) || !par) ? '#EDF2F7' : 'white'} minHeight='4.6rem' alignContent='center' padding={4} borderRadius='0'>
       <Flex wrap='wrap' justifyContent='space-between' alignItems='center' height='100%'>
       <Checkbox mr={'.22rem'} isChecked={selecionado} onChange={e => onSelect(assessmentId ?? '')} />
        <Box cursor={'pointer'} alignItems='center' onClick={() => navigate(`/AssessmentPI/Perfil/${id ?? ''}`)} >
            <HStack alignItems='center' width='12rem'>
              <Avatar name={nome} size={'sm'} src={avatar}/>
            <Text width='10rem' fontSize={'xs'} fontWeight={'bold'} color='black' >{nome}</Text>
            </HStack>

          </Box>

            <Text width='5rem' fontSize={'10px'} fontWeight={'bold'} color='black' >{dataPesquisa}</Text>
<VStack width='10rem' spacing={'0.1rem'}>
        <Image src={urlSelo} width='2rem'/>
<Text fontSize='10px' fontWeight={'bold'}> {selo ?? ''}</Text>
</VStack>
<HStack width='10rem'>
        <Image src={imgPerfil} width='7rem'/>
<Text fontSize='xs'>: {perfil ?? ''}</Text>
</HStack>

<HStack width='8rem' justifyContent='center' m={0} p={0}>
     { tipo === 0 && (
        <>
        <FaCheck color='var(--Green2)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Green2)'>Aderente</Text>
        </>
     )
}

{ tipo === 1 && (
        <>
          <FaSync color='var(--a1)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--a1)'>Flexibilizado</Text>
        </>
)}

        { tipo === 2 && (<>
          <FaBan color='var(--Red)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Red)'>Não aderente</Text>
        </>
        )}
        </HStack>

          {cargos === undefined && <Text fontSize='xs'></Text>}
          {cargos && (
            <VStack width='10rem' justifyContent='space-around'>
          {cargos.length > 0 && (

            <Text fontWeight={'bold'} fontSize='xs'>{cargos?.length} cargo{cargos?.length > 1 ? 's' : ''}</Text>
          )}
          {/* <Button size={'xs'} width='7rem' backgroundColor='var(--Rosa)'>Visualizar</Button> */}

          {cargos.length > 0 && (

            <Menu>
  <MenuButton as={Button}
     _hover={{ bg: 'var(--Roxo)' }}
     _expanded={{ bg: 'var(--Roxo)' }}
     size={'xs'} width='7rem' backgroundColor='var(--Rosa)' rightIcon={<FaChevronDown />}>
    Visualizar
  </MenuButton>
  <MenuList backgroundColor='var(--Rosa)'

  >
    {cargos.map((c) => (<MenuItem onClick={() => navigate(`/AssessmentPi/JobTarget/${c.jobId}`)}>{c.nome}</MenuItem>))}

  </MenuList>
</Menu>
          )}
          </VStack>
          )}

       </Flex>
      </Box>
  )
}

import styled from 'styled-components'

export const HeadContainer = styled.div`
margin-bottom: 1rem;
display: flex;
justify-content: space-between;
`

export const ContainerFormulario = styled.div`
display: flex;
background: var(--c1);
justify-content: space-between;
margin-bottom: .5rem;
border-radius: 0.313rem;
padding: .75rem 0rem .75rem .5rem;
align-items: center;
font-size: 1.125rem;
font-weight: 500;
font-family: 'Roboto','sans-serif';
flex-wrap: wrap;
span{
    color: var(--a5);
}

button,a:nth-child(n){
    margin-right: 0.5rem;
    padding: .3rem .6rem .3rem .6rem;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: .85rem;
    height: 2rem;
}
`

/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, FormControl, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { WhiteModal } from '../../../../../../components/ModalCreateForm/styles'
import { appApi } from '../../../../../../services/appApi'
import { FindGrupo } from '../FindGrupo'
import { iGrupoPesquisaInterna } from '../../../Grupos'

interface iModalProps {
  isOpen: boolean
  onCreate: (selecionado: string) => void
  onSave?: (selecionado: string) => void
  onClose: () => void
  selecionado: string
}

export const ModalGrupos: React.FC<iModalProps> = ({ isOpen, onClose, onCreate, onSave, selecionado }) => {
  const [Selecionado, setSelecionado] = useState<string>('')
  const [Grupos, setGrupos] = useState<iGrupoPesquisaInterna[]>([])

  function getGrupos(): void {
    appApi.get('PesquisaInterna/Grupos')
      .then(res => {
        setGrupos(res.data)
      })
      .catch(err => console.log(err))
  }

  function postGrupo(e: string): void {
    appApi.post('PesquisaInterna/Grupos', { texto: e })
      .then(res => {
        onCreate(res.data)
        getGrupos()
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getGrupos()
  }, [])

  useEffect(() => {
    setSelecionado(selecionado)
  }, [selecionado])

  useEffect(() => {
    onCreate(Selecionado)
  }, [Selecionado])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal style={{ width: '52rem' }}>
        <FormControl>
          <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} mb={'.75rem'}>
            <Text fontSize={'1.375rem'} lineHeight={'1.5rem'} fontWeight={'700'} textAlign={'center'}>Grupo da pesquisa</Text>
					  <Text fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'600'} textAlign={'center'}>Crie e/ou escolha um grupo e mantenha suas pesquisas organizadas, facilitando sua visualização na tela de gestão de pesquisas</Text>
          </Flex>
          <FindGrupo
            lista={Grupos}
            onChoice={onCreate}
            createGrupo={postGrupo}
            selecionado={Selecionado}
            placeholder={'Caso a pesquisa pertença à um grupo escreva aqui o nome do mesmo.'}
          />
        </FormControl>

        <Flex justifyContent={'end'} gap={'.5rem'} mt={'.75rem'}>
          <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
          {onSave && <ButtonCmp VarColor='Green2' onClick={() => onSave(Selecionado)}>Salvar</ButtonCmp>}
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

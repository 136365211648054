/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Avatar, Box, Flex, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { iNomeId } from '../../interfaces'
import { PropInvalida } from '../../Utils/Helper'
import { InputCmp } from '../InputCmp'
import { Container, Nome, Sobrenome } from './styles'

interface iFindProps extends InputProps {
  onChoice: (id: string) => void
  extrairEmail?: (email: string) => void
  _onChange?: (e: string) => void
  lista: iNomeId[]
  selecionado?: string
  isNotUser?: boolean
  leftIcon?: React.ReactNode
  minWInput?: string
  onBlur?: () => void
  themeWhite?: boolean
  selectAuto?: boolean
  differentValueProp?: keyof iNomeId
}

export const FindUser: React.FC<iFindProps> = ({ lista, selecionado, isNotUser, placeholder, minWInput, themeWhite, isInvalid, isReadOnly, isDisabled, leftIcon, onBlur, onChoice, _onChange, extrairEmail, selectAuto, differentValueProp }) => {
  const [Selecionado, setSelecionado] = useState<string>()
  const [IsActive, setIsActive] = useState(false)
  const [Texto, setTexto] = useState('')

  function internalSelect(id: string): void {
    onChoice(id)
    setSelecionado(id)

    const colab = lista.find(e => e.id === id)
    if (differentValueProp && colab) {
      setTexto(colab[differentValueProp] as string)
    } else if (colab) {
      setTexto(colab.nome)
    }
  }

  useEffect(() => {
    if (!PropInvalida(selecionado) && lista !== undefined && lista.length > 0) {
      setSelecionado(selecionado)
      const buscar = lista.find(e => e.id === selecionado)
      if (buscar !== undefined) {
        setTexto(buscar.nome)
        if (extrairEmail !== undefined) {
          extrairEmail(buscar?.email ?? '')
        }
      }
      setTimeout(() => {
        setIsActive(false)
        if (onBlur) { onBlur() }
      }, 500)
    } else if (!PropInvalida(Selecionado)) {
      Clean()
    }
  }, [lista, selecionado])

  function Clean(): void {
    setTexto('')
    setSelecionado(undefined)
    onChoice('')
  }

  return (
    <Flex position={'relative'} flexDir={'column'} width={'100%'}>
      <Flex flexDir={'column'} width='100%'>
        <InputGroup width={'100%'}>
          {
            (!isDisabled) && (
              <InputRightElement
                h={'1.975rem'}
                cursor={'pointer'}
                children={Selecionado ? <MdClose onClick={Clean} color={'var(--Red)'} /> : <></>}
              />
            )
          }
          <InputCmp
            value={Texto}
            minW={minWInput}
            borderColor={'var(--Gray4)'}
            placeholder={placeholder}
            isDisabled={!PropInvalida(Selecionado) ?? isDisabled}
            isReadOnly={isReadOnly}
            isInvalid={isInvalid}
            themeWhite={themeWhite}
            onBlur={() => {
              if (selectAuto) {
                const item = lista.find(e => e.nome.toLowerCase() === Texto)
                if (item) {
                  internalSelect(item.id)
                } else {
                  if (onBlur) { onBlur() }
                }
              } else {
                if (onBlur) { onBlur() }
              }
              setTimeout(() => { setIsActive(false) }, 300)
            }}
            onFocus={() => { setTimeout(() => { setIsActive(true) }, 200) }}
            OnChange={(e) => {
              setTexto(e)
              if (_onChange !== undefined) {
                _onChange(e)
                setIsActive(true)
              }
            }}
          />
        </InputGroup>
      </Flex>
        {lista.length > 0 &&
          <Flex position={'absolute'} bg={'white'} zIndex={999} top={'32px'} w={'100%'} flexDir={'column'} gap={'.25rem'}>
            {
              lista.filter((e) => {
                if (isDisabled) {
                  return null
                } else if (!IsActive) {
                  return null
                } else if (Texto === '' || Selecionado) {
                  return null
                } else if (e.nome.toLocaleLowerCase().includes(Texto.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).slice(0, 3).map((e, i) => {
                return (
                  <Container onClick={() => internalSelect(e.id)} key={i} background='var(--c3)'>
                    {(!leftIcon) && (<Avatar margin={'.5rem 0rem .5rem .5rem'} size={'sm'} src={e.avatar} />)}

                    {(leftIcon) && (
                      <Box margin={'.25rem 0rem .25rem .25rem'} minWidth={'1.25rem'} minHeight={'1rem'}>
                        {leftIcon}
                      </Box>)}

                    <Flex padding={'0 .5rem'} width={'100%'} justifyContent='center' alignItems={'start'} flexDir={'column'}>
                      <Nome>{e.nome}</Nome>
                      {
                        (!isNotUser) && (
                          <Sobrenome>{e.complemento ?? 'Departamento não identificado'}</Sobrenome>
                        )
                      }
                    </Flex>
                  </Container>
                )
              })
            }
          </Flex>
        }
    </Flex>
  )
}

/* eslint-disable no-return-assign */
import { Body } from '../../Layouts/Body'
import { OutlineGreyBox } from '../components/OutlineGreyBox'
import { InfoBox, GridColumn } from './styles'
import { ModalAdicionarAvaliado } from './Components/ModalAdicionarAvaliado'
import { ModalCadastroEmMassa } from './Components/ModalCadastroEmMassa'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { iAvaliado, iCardAvaliador, iPostAvaliador } from '../../../interfaces'
import { ModalAddParticipante } from './Components/ModalAddParticipante'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ModalDelete } from '../../../components/ModalDelete'
import { ObterToken, PropInvalida } from '../../../Utils/Helper'
import { FaChevronRight, FaFileCsv, FaSearch } from 'react-icons/fa'
import { MdGroups } from 'react-icons/md'
import { BsFillFileEarmarkArrowDownFill } from 'react-icons/bs'
import { CardParticipante } from './Components/CardParticipante'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../components/InputCmp'
import { TimelineCriarPesquisa } from '../../PesquisaIndicativa/components/Timeline'
import LoadingContainer from '../../../components/LoadingContainer'
import { ModalJaTrabalhadas } from './Components/ModalJaTrabalhadas'

type navProps = {
  pesquisaId: string
}

interface iModel {
  nomePesquisa: string
  pesquisaIniciada: boolean
  ciclo?: string
  avaliados: iAvaliado[]
  formularioAprovado: boolean
  enviado: boolean
}

interface iTemp {
  avaliacaoId: string
  avaliador?: iCardAvaliador
}

export const PesquisaParticipantes: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()

  const [Temp, setTemp] = useState<iTemp>()

  const [IsLoading, setIsLoading] = useState(false)

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalDeleteAvaliacaoIsOpen, setModalDeleteAvaliacaoIsOpen] = useState(false)
  const [ModalAddAvaliadoIsOpen, setModalAddAvaliadoIsOpen] = useState(false)
  const [ModalAddMassaIsOpen, setModalAddMassaIsOpen] = useState(false)
  const [ModalAddParticipanteIsOpen, setModalAddParticipanteIsOpen] = useState(false)
  const [JaTrabalhadasIsOpen, setJaTrabalhadasIsOpen] = useState(false)
  const [AvaliacaoSelecionadaId, setAvaliacaoSelecionadaId] = useState('')
  const [Tipo, setTipo] = useState<number>(0)

  const [Search, setSearch] = useState('')

  const [Model, setModel] = useState<iModel>({
    avaliados: [],
    pesquisaIniciada: false,
    nomePesquisa: '',
    formularioAprovado: false,
    enviado: false
  })

  function getAvalidos(loading?: boolean): void {
    if (loading) {
      setIsLoading(true)
    }
    appApi.get(`AvaliacaoPesquisa/Pesquisa/${pesquisaId as string}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else {
          setModel({
            avaliados: [],
            nomePesquisa: '',
            pesquisaIniciada: false,
            formularioAprovado: false,
            enviado: false
          })
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function DeleteAvaliacao(): void {
    appApi.delete(`AvaliacaoPesquisa/${Temp?.avaliacaoId ?? ''}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function DeleteAvaliador(): void {
    appApi.delete(`AvaliacaoPesquisa/${Temp?.avaliacaoId ?? ''}/avaliador/${Temp?.avaliador?.id ?? ''}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function CreateAvaliador(form: iPostAvaliador): void {
    form.TipoAvaliador = Tipo
    appApi.post(`AvaliacaoPesquisa/${Temp?.avaliacaoId ?? ''}/avaliador`, form)
      .then(() => {
        getAvalidos()
        onCloseModalAddParticipante()
      })
      .catch(({ response }) => {
        toast({
          title: response.data,
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      })
  }

  function ReenviarConviteAvaliador(avaliacaoId: string, avaliador?: iCardAvaliador): void {
    appApi.post(`AvaliacaoPesquisa/${avaliacaoId}/Reenviar?avaliadorId=${avaliador?.id ?? ''}`)
      .then(() => {
        toast({
          title: 'Reenviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
        onCloseModalAddParticipante()
      })
      .catch(() => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  function Create(avaliadoId?: string, entidadeId?: string, apenasAvaliacao?: boolean): void {
    if (avaliadoId) {
      if (Model?.avaliados.map(e => e.colaboradorId).includes(avaliadoId)) {
        toast({
          title: 'Avaliado já adicionado',
          position: 'top-right',
          status: 'warning',
          duration: 2000,
          isClosable: false
        })
        return
      }
      const form = {
        pesquisaId: pesquisaId as string,
        colaboradorId: avaliadoId
      }

      setIsLoading(true)
      appApi.post(`AvaliacaoPesquisa?apenasAvaliacao=${apenasAvaliacao ?? ''}`, form).then(() => {
        getAvalidos()
        toast({
          title: 'Avaliado adicionado com sucesso',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: false
        })
      }
      ).catch(err => console.log(err))
        .finally(() => setIsLoading(false))
    } else {
      setIsLoading(true)
      appApi.post(`AvaliacaoPesquisa/Entidade?pesquisaId=${pesquisaId ?? ''}&entidadeId=${entidadeId ?? ''}&apenasAvaliacao=${apenasAvaliacao ?? ''}`)
        .then(() => {
          getAvalidos()
          setModalAddParticipanteIsOpen(false)
        })
        .catch(() => {
          toast({
            title: 'Há campos sem preenchimento',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 2000
          })
        })
        .finally(() => setIsLoading(false))
    }
  }

  function UpdateAvaliador(form: iPostAvaliador): void {
    appApi.put(`AvaliacaoPesquisa/${Temp?.avaliacaoId ?? ''}/avaliador/${Temp?.avaliador?.id ?? ''}`, form)
      .then(() => {
        getAvalidos()
        onCloseModalAddParticipante()
      })
      .catch(() => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  function onOpenModalParticipante(tipo: number, avaliacaoId: string): void {
    setModalAddParticipanteIsOpen(true)
    setTipo(tipo)
    setTemp({
      avaliacaoId: avaliacaoId
    })
  }

  function onOpenModalEditarParticipante(avaliacaoId: string, avaliador: iCardAvaliador): void {
    setModalAddParticipanteIsOpen(true)
    setTemp({
      avaliacaoId: avaliacaoId,
      avaliador: avaliador
    })
  }

  function onCloseModalAddParticipante(): void {
    setModalAddParticipanteIsOpen(false)
    setTipo(0)
    setTemp(undefined)
  }

  function onOpenModalDeletarAvaliacao(id: string): void {
    setModalDeleteAvaliacaoIsOpen(true)
    setTemp({
      avaliacaoId: id
    })
  }

  function onOpenModalDeleteAvaliador(avaliacaoId: string, avaliador: iCardAvaliador): void {
    setModalAddParticipanteIsOpen(false)
    setModalDeleteIsOpen(true)
    setTemp({
      avaliacaoId: avaliacaoId,
      avaliador: avaliador
    })
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setTemp(undefined)
  }

  function onRequestModalDeleteAvaliacaoClose(): void {
    setModalDeleteAvaliacaoIsOpen(false)
    setTemp(undefined)
  }

  useEffect(() => {
    getAvalidos(true)
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        onRequestClose={onRequestModalDeleteClose}
        onConfirm={DeleteAvaliador}
        message={'Deseja realmente excluir este avaliador?'}
      />

      <ModalDelete
        isOpen={ModalDeleteAvaliacaoIsOpen}
        onRequestClose={onRequestModalDeleteAvaliacaoClose}
        onConfirm={DeleteAvaliacao}
        message={'Deseja realmente excluir?'}
        center
      />

      {ModalAddParticipanteIsOpen && (
        <ModalAddParticipante
          isOpen={ModalAddParticipanteIsOpen}
          onConfirm={CreateAvaliador}
          onRequestClose={onCloseModalAddParticipante}
          iniciada={Model.pesquisaIniciada}
          avaliador={Temp?.avaliador}
          avaliacaoId={Temp?.avaliacaoId}
          onDeleteAvaliador={onOpenModalDeleteAvaliador}
          onReenviarAvaliador={ReenviarConviteAvaliador}
          onUpdateAvaliador={UpdateAvaliador}
        />
      )}

      <ModalAdicionarAvaliado
        isOpen={ModalAddAvaliadoIsOpen}
        onConfirm={(id, entidadeId, apenasAutoAvaliacao) => Create(id, entidadeId, apenasAutoAvaliacao)}
        loading={IsLoading}
        onRequestClose={() => setModalAddAvaliadoIsOpen(false)}
      />

      {ModalAddMassaIsOpen &&
        <ModalCadastroEmMassa
          onRequestClose={() => setModalAddMassaIsOpen(false)}
          isOpen={ModalAddMassaIsOpen}
          reload={getAvalidos}
        />
      }
      <ModalJaTrabalhadas
        OnCloseModal={() => setJaTrabalhadasIsOpen(false)}
        avaliacaoId={AvaliacaoSelecionadaId}
        isOpen={JaTrabalhadasIsOpen}
        pesquisaIniciada={Model.pesquisaIniciada}
      />
      {!pathname.includes('Editar') && pathname.includes('Indicativa') && <TimelineCriarPesquisa color={['var(--Azul)', 'var(--Azul)', 'var(--Rosa)']} />}
      <Flex flexDir={'column'} gap={'.75rem'}>
        <Flex justifyContent={'space-between'} flexWrap='wrap' marginTop={'1rem'}>
          <Flex flexDir={'column'}>
            <h2>Selecione os participantes de sua pesquisa</h2>
            <Flex gap={'1rem'} marginTop={'.5rem'}>
              <ButtonCmp
                VarColor='Green2'
                onClick={() => setModalAddAvaliadoIsOpen(true)}
                leftIcon={<MdGroups size={18} />}
              >Adicionar avaliados
              </ButtonCmp>

              <ButtonCmp
                VarColor='Green2'
                onClick={() => setModalAddMassaIsOpen(true)}
                leftIcon={<FaFileCsv />}
              > Adicionar em massa</ButtonCmp>

              <ElementoTooltipCmp
                label={Model.avaliados.length === 0 ? 'Não existem avaliados para serem exportados' : ''}
              >
                <ButtonCmp
                  VarColor='Green2'
                  isDisabled={Model.avaliados.length === 0}
                  onClick={() => window.location.href = `${urlApi}/AvaliacaoPesquisa/${pesquisaId ?? ''}/Participantes/Csv/?token=${ObterToken()}`}
                  leftIcon={<BsFillFileEarmarkArrowDownFill />}
                >Exportar participantes
                </ButtonCmp>
              </ElementoTooltipCmp>
            </Flex>
          </Flex>
          <InfoBox>
            <span>{Model.avaliados?.length}</span>
            <h3>Avaliados adicionados</h3>
          </InfoBox>
        </Flex>

        <h2>Participantes selecionados</h2>
        <OutlineGreyBox>
          <LoadingContainer linhas={8} loading={IsLoading}>
            {
              (Model.avaliados?.length === 0) && (
                <Flex justifyContent={'center'} color={'var(--Rosa)'}>
                  <h3>Nenhum participante selecionado</h3>
                </Flex>
              )
            }

            {
              (Model.avaliados?.length > 0) && (
                <GridColumn>
                  <Flex>
                    <InputGroup width={'15rem'}>
                      <InputLeftElement
                        h={'1.75rem'}
                        pointerEvents='none'
                        children={<FaSearch color='var(--Gray4)' />}
                      />
                      <InputCmp
                        pl={'2.25rem'}
                        OnChange={setSearch}
                        borderColor={'var(--Gray4)'}
                        type='text'
                        placeholder='Digite o nome do avaliado'
                        bg={'white'}
                      />
                    </InputGroup>
                  </Flex>
                  {
                    Model.avaliados.filter((e) => {
                      if (Search === '') {
                        return e
                      } else if (e.nome.toLowerCase().includes(Search.toLowerCase())) {
                        return e
                      } else {
                        return null
                      }
                    }).map((e: iAvaliado) => {
                      return (
                        <CardParticipante
                          key={e.id}
                          ciclica={!PropInvalida(Model.ciclo)}
                          enviado={Model.enviado}
                          Avaliacao={e}
                          onDeleteAvaliacao={onOpenModalDeletarAvaliacao}
                          onOpenModal={onOpenModalParticipante}
                          onOpenJaTrabalhadas={(avaliacaoId) => { setAvaliacaoSelecionadaId(avaliacaoId); setJaTrabalhadasIsOpen(true) }}
                          onEdit={onOpenModalEditarParticipante}
                          onReenviarAvaliador={ReenviarConviteAvaliador}
                        />
                      )
                    })
                  }
                </GridColumn>
              )
            }
          </LoadingContainer>
        </OutlineGreyBox>

        <Alert borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'.75rem'} py={'.5rem'}>
          <Flex flexDir={'column'} fontSize={'.75rem'}>
            <Flex>
              <AlertIcon color={'#FF9900'} mr={'0.325rem'}/>
              <AlertTitle>IMPORTANTE: </AlertTitle>
              <AlertDescription>Certifique-se de que para cada eixo de Pares, Liderados e Alta Liderança, tenham no mínimo 3 avaliadores cada. Se algum eixo não atingir esse mínimo, as respostas serão agrupadas em </AlertDescription>
            </Flex>
            <AlertDescription>'Outros'. Controle e se atente a quantidade de avaliadores.</AlertDescription>
          </Flex>
        </Alert>

        <Flex justifyContent={'end'} gap={'.5rem'}>
          <ButtonCmp VarColor='Gray3' onClick={() => navigate(-1)}>
            Voltar
          </ButtonCmp>
          <ButtonCmp
            onClick={() => pathname.includes('Indicativa') ? navigate(`${pathname.replace('Participantes', Model.enviado ? 'EnviarPesquisa' : 'Textos')}`) : navigate(`${pathname.replace('Participantes', 'EnviarPesquisa')}`)}
            VarColor='Green2'
            rightIcon={<FaChevronRight />}
          >Continuar</ButtonCmp>
        </Flex>
      </Flex>
    </Body>
  )
}

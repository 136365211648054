import { Flex, FormControl, Switch, Text } from '@chakra-ui/react'
import { FaMinusCircle } from 'react-icons/fa'
import { InputCmp } from '../../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { iAcao } from '../TabPDI'

interface iAcaoProps {
  acao: iAcao
  onRemove: (id: string) => void
  onUpdate: (acao: iAcao) => void
}

export const Acao: React.FC<iAcaoProps> = ({ acao, onRemove, onUpdate }) => {
  return (
    <Flex gap={'1rem'} flexDir={'column'} borderRadius={'.25rem'} padding={'1rem'} border={'1px solid var(--c4)'}>
      <Flex justifyContent={'space-between'}>
        <Flex alignItems={'center'} gap={'.5rem'}>
          <Text fontWeight={'500'}>Solicitar ajustes</Text>
          <Switch
            onChange={(e) => onUpdate({ ...acao, solicitarAjustes: e.target.checked })}
            isChecked={acao.solicitarAjustes}
          />
        </Flex>
        <FaMinusCircle onClick={() => onRemove(acao.id)} cursor={'pointer'} size={20} color='var(--terc3)' />
      </Flex>
      <Flex flexDir={'column'} gap={'.75rem'}>
        <Flex gap={'.75rem'}>
          <FormControl>
            <InputCmp
              label='Assunto a ser tratado'
              isReadOnly
              value={acao.assunto}
              borderColor={'var(--Gray4)'}
              OnChange={() => {}}
            />
          </FormControl>
          <FormControl>
            <InputCmp
              label='Horas a dedicar'
              isReadOnly
              marginRight={'.5rem'}
              type={'number'}
              borderColor={'var(--Gray4)'}
              value={acao.hora}
              OnChange={() => {}}
            />
          </FormControl>
        </Flex>
        <Flex gap={'.75rem'}>
          <FormControl>
            <InputCmp
              label='Data para iniciar'
              isReadOnly
              type={'date'}
              borderColor={'var(--Gray4)'}
              max={'2099-12-31T23:59'}
              value={acao.dataInicio}
              OnChange={() => {}}
            />
          </FormControl>
          <FormControl>
            <InputCmp
              label='Data para finalizar'
              isReadOnly
              type={'date'}
              borderColor={'var(--Gray4)'}
              max={'2099-12-31T23:59'}
              value={acao.dataFim}
              OnChange={() => {}}
            />
          </FormControl>
        </Flex>
      </Flex>

      <Flex flexDir={'column'} fontSize={'.875rem'}>
        <Text fontWeight='700'>O que vou fazer?</Text>
        {/* <Text fontWeight='400'>{acao.titulo}</Text> */}
        <InputCmp isReadOnly OnChange={() => {}} value={acao.titulo}/>
      </Flex>

      <Flex flexDir={'column'} fontSize={'.875rem'}>
        <Text fontWeight='700'>Como será realizada essa ação</Text>
        {/* <Text fontWeight='400'>{acao.descricao.split('\n').map(e => { return (<p>{e}</p>) })}</Text> */}
        <TextAreaCmp OnChange={() => {}} isReadOnly value={acao.descricao} height={'10rem'}/>
      </Flex>
      {/*
      <Flex flexDir={'column'}>
        <Text color={'var(--Gray2)'} fontWeight='500'>Qual o motivo para se trabalhar essa competência?</Text>
        <Text color={'var(--Gray2)'} fontWeight='400'>{acao.motivo}</Text>
      </Flex> */}
      <FormControl>
        <TextAreaCmp label='Deixe uma observação para seu colaborador' OnChange={(e) => {
          onUpdate({ ...acao, comentario: e })
        }} borderColor={'var(--Gray4)'} />
      </FormControl>
    </Flex>
  )
}

/* eslint-disable no-tabs */
import { Box, Flex } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface iProps {
  tipo: 'Quantitativa'|'Indicativa'
  isDisabled?: boolean
}

export const BoxModelo: React.FC<iProps> = ({ tipo, children, isDisabled }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()

  return (
    <ElementoTooltipCmp label={''}>
      <Flex
        className={''}
        borderRadius={'.325rem'}
        bg={tipo === 'Quantitativa' ? 'var(--v2Opaco)' : 'var(--AzulOpaco)'}
        cursor='pointer'
      >
        <Box
          onClick={() => nav(`${pathname.replace('SelecionarModelo', tipo)}/Criar`)}
          px={'1rem'}
          py={'.875rem'}
          bgColor={tipo === 'Quantitativa' ? 'var(--Green1)' : 'var(--Azul)'}
          color={'#fff'}
          borderRadius={'.325rem'}
          _hover={{
            transform: 'translate(0rem,-0.25rem)',
            transition: 'all 0.2s ease'
          }}
        >
          {children}
        </Box>
      </Flex>
    </ElementoTooltipCmp>
  )
}

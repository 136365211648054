import styled from 'styled-components'
import { Column } from '../../../../styles/styledGlobal'

interface iProps {
  color: string
}

export const PreViewParticipante = styled.div<iProps>`
display: flex;
width: 42rem;
position: relative;
justify-content: space-between;
background: var(--c1);
border-radius: 0.25rem;
box-shadow: 0rem 0.1rem 0.25rem ${props => props.color.replace(')', 'Opaco)')};
padding: .75rem .5rem .75rem 1.5rem;
max-height: 10rem;
border: 2px solid ${props => props.color};

.OpacityEfect{
    transition: all .5s ease;
    opacity: 100%;
    pointer-events: inherit;
}

.disable{
    cursor: not-allowed;
    li:not(:nth-child(6),:nth-child(7)){
        opacity: 50%;
        pointer-events: none;
    }
}
`

export const ColumnText = styled(Column)`
align-items: flex-start;
justify-content: center;
margin-right:.5rem;
h4 {
    font-size: .875rem;
    line-height: 0.75rem;
    font-weight: 400;
}
h3 {
    font-size: .875rem;
    line-height: .925rem;
    font-weight: 700;
}
span {
    font-size: .813rem;
    font-weight: 600;
}
`

export const GridContainer = styled.div`
display: grid;
align-items: center;
grid-template-columns: 1fr 1fr 1fr;
gap: 0rem .5rem;
width: 100%;
`

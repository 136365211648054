import styled from 'styled-components'

export const TableDiv = styled.div`
display: flex;
justify-content: center;
width: 100%;
table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    padding: .75rem;
}

td{
    transition: 2s;
    text-align: center;
}

th{
    background: var(--Azul);
    color: var(--c1);
    font-weight: 500;
}
`

export const StyledTd = styled.td`
    color: ${props => props.theme};
    font-weight: bold;
`

/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Avatar, Flex, FormControl, Grid, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { AddBox } from '../../../../../../components/AddBox'
import { WhiteModal } from '../../../../../../components/ModalCreateForm/styles'
import { iColaboradorBase } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'

interface iModalProps {
  isOpen: boolean
  onCreate: (selecionados: string[]) => void
  onSave?: (selecionados: string[]) => void
  onClose: () => void
  selecionados: string[]
}

export const ModalPermissoes: React.FC<iModalProps> = ({ isOpen, onClose, onCreate, onSave, selecionados }) => {
  const [Colabs, setColabs] = useState<iColaboradorBase[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>(selecionados)

  function GetColaboradores(): void {
    appApi.get('Colaborador/PesquisaInterna')
      .then(res => {
        setColabs(res.data)
      })
      .catch(err => console.log(err))
  }
  useEffect(() => {
    GetColaboradores()
  }, [])
  useEffect(() => {
    if (selecionados.length !== Selecionados.length) {
      setSelecionados(selecionados)
    }
  }, [selecionados])

  useEffect(() => {
    onCreate(Selecionados)
  }, [Selecionados])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal style={{ width: '52rem' }}>
        <FormControl as={Flex} flexDir={'column'}>
          <Text fontSize={'1.375rem'} lineHeight={'1.5rem'} fontWeight={'700'} textAlign={'center'}>Defina quem poderá acessar e configurar essa pesquisa na gestão</Text>
					<Text fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'600'} textAlign={'center'} mb={'.75rem'}>Administradores e o criador da pesquisa sempre terão acesso à ela na gestão</Text>
					<AddBox
						lista={Colabs}
						onChoice={(e) => { setSelecionados([...Selecionados, e]) }}
						selecionados={Selecionados}
						placeholder='Digite o nome do colaborador'
						messageSucess='Colaborador selecionado com sucesso'
					/>
					<Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'.75rem'} my={'.75rem'}>
						{Colabs.filter(e => { if (Selecionados.includes(e.id)) { return e } }).map(e => {
						  return (
								<Flex bg={'var(--c2)'} px={'1rem'} py={'.75rem'} fontSize={'.875rem'} gap={'.5rem'}>
										<Avatar src={e.avatar} w={'2rem'} h={'2rem'}/>
										<Flex flexDir={'column'} justifyContent={'center'} gap={'.2rem'}>
											<Text lineHeight='1rem' fontWeight={'700'}>{e.nome}</Text>
											<Text lineHeight='.875rem' fontWeight={'400'} fontSize={'.813rem'}>{e.nomeDepartamento}</Text>
										</Flex>
										<Flex flex={'1'} justifyContent={'end'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}>
											<ElementoTooltipCmp bg={'var(--Red)'} label={'Remover colaborador'}>
												<FaTrash cursor={'pointer'} onClick={() => { setSelecionados(Selecionados.filter(a => { if (a !== e.id) { return a } })) }} />
											</ElementoTooltipCmp>
										</Flex>
								</Flex>
						  )
						})}
					</Grid>
        </FormControl>

        <Flex justifyContent={'end'} gap={'.5rem'}>
          <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
          {onSave && <ButtonCmp VarColor='Green2' onClick={() => onSave(Selecionados)}>Salvar</ButtonCmp>}
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

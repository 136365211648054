import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPlus, FaRegPlayCircle, FaRegStopCircle, FaTrashAlt } from 'react-icons/fa'
import { MdClose, MdSave } from 'react-icons/md'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { iDemografia, iGrupo } from '../../../../../interfaces'
import { ModalBase } from '../../../../../components/ModalBase'

interface iModalProps {
  isOpen: boolean
  Demografia?: iDemografia
  isSelecionado: boolean
  onClose: () => void
  onUpdate: (form: iDemografia) => void
  onAlterSelecao: (id: string) => void
  onDelete: (id: string) => void
}
export const ModalUpdateDemografia: React.FC<iModalProps> = ({ isOpen, Demografia, isSelecionado, onClose, onUpdate, onAlterSelecao, onDelete }) => {
  const [Demografias, setDemografias] = useState<iGrupo[]>([])
  const [Habilitado, setHabilitado] = useState(false)
  const [Nome, setNome] = useState('')

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  function UpdateDemografiasArray(index: number, Texto: string): void {
    const array = [...Demografias]
    array[index].nome = Texto
    setDemografias(array)
  }

  function DeleteItemArray(index: number): void {
    const dems: iGrupo[] = []
    Demografias.forEach((e, i) => {
      if (index !== i) { dems.push(e) }
    })
    setDemografias(dems)
  }

  function AbstracatDelete(): void {
    onDelete(Demografia?.id as string)
    onClose()
  }

  useEffect(() => {
    setHabilitado(isSelecionado)
  }, [isSelecionado])

  useEffect(() => {
    if (Demografia !== undefined) {
      setDemografias(Demografia?.grupos)
      setNome(Demografia?.nome)
    }
  }, [Demografia])

  return (
        <ModalBase
            Titulo="Editando demografia (grupo para análise)"
            Subtitulo="Ao  adicionar ou remover grupos de demografia você pode demorar para obter dados para as análises"
            onClose={onClose}
            isOpen={isOpen}
            Width='60rem'
        >
          <ModalDelete
            isOpen={ModalDeleteIsOpen}
            message='Tem certeza que deseja excluir esta demografia?'
            onConfirm={AbstracatDelete}
            onRequestClose={() => setModalDeleteIsOpen(false)}
          />
            <Flex flexDirection={'column'} padding={'1rem'} borderBottom={'1px solid var(--c4)'}>
                <FormControl marginBottom={'1rem'}>
                    <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Nome da demografia</FormLabel>
                    <Input
                        placeholder='Digite o nome'
                        width={'18rem'}
                        borderColor={'var(--Gray4)'}
                        value={Nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel fontSize={'lg'} marginBottom={'.3rem'}>Grupos</FormLabel>
                    <Flex display={'grid'} gap={'1rem 1rem'}>
                        {
                           Demografias?.map((e, i) => {
                             return (
                                    <InputGroup key={i}>
                                        {
                                            (Demografias.length > 1) && (
                                                <InputRightElement
                                                    children={<MdClose cursor={'pointer'}
                                                    onClick={() => DeleteItemArray(i)}
                                                    color='gray.300' />}
                                                />
                                            )
                                        }
                                        <Input
                                            placeholder={`Grupo ${i !== 0 ? i : ''}`}
                                            borderColor={'var(--Gray4)'}
                                            value={e.nome}
                                            onChange={(e) => UpdateDemografiasArray(i, e.target.value)}
                                        />
                                    </InputGroup>
                             )
                           })
                        }

                    </Flex>
                </FormControl>

                <Flex marginTop={'1rem'}>
                    <Button
                        borderRadius={'12px'}
                        fontWeight={'500'}
                        bg={'var(--v2)'}
                        _hover={{ bg: 'var(--v2)' }}
                        leftIcon={<FaPlus />}
                        onClick={() => setDemografias(oldArray => [...oldArray, { nome: '' }])}
                    >Adicionar</Button>
                </Flex>
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'}>
                {
                    Habilitado
                      ? <Button
                            borderRadius={'12px'}
                            fontWeight={'500'}
                            bg={'var(--Orange)'}
                            _hover={{ bg: 'var(--Orange)' }}
                            leftIcon={<FaRegStopCircle />}
                            onClick={() => { setHabilitado(false); onAlterSelecao(Demografia?.id as string) }}
                        >Desabilitar</Button>
                      : <Button
                            borderRadius={'12px'}
                            fontWeight={'500'}
                            bg={'var(--Blue2)'}
                            _hover={{ bg: 'var(--Blue2)' }}
                            leftIcon={<FaRegPlayCircle />}
                            onClick={() => { setHabilitado(false); onAlterSelecao(Demografia?.id as string) }}
                        >Habilitar</Button>
                }

                <Button
                    borderRadius={'12px'}
                    fontWeight={'500'}
                    bg={'var(--terc3)'}
                    _hover={{ bg: 'var(--terc3)' }}
                    leftIcon={<FaTrashAlt />}
                    margin={'0rem 1rem'}
                    onClick={() => setModalDeleteIsOpen(true)}
                >Remover</Button>

                <Button
                    borderRadius={'12px'}
                    fontWeight={'500'}
                    bg={'var(--v2)'}
                    _hover={{ bg: 'var(--v2)' }}
                    leftIcon={<MdSave />}
                    onClick={() => onUpdate({
                      id: Demografia?.id as string,
                      nome: Nome,
                      grupos: Demografias,
                      area: false
                    })}
                >Salvar</Button>
            </Flex>
        </ModalBase>
  )
}

import styled from 'styled-components'
import { Column } from '../../../styles/styledGlobal'

export const MainColumn = styled(Column)<{padding: string, width: string}>`
padding: ${props => props.padding};
width: ${props => props.width};
box-shadow: var(--SombraBackground);
background: white;
`

export const MainContainer = styled.div`
display: flex;
flex-direction: column;
width: 60%;
gap: 1rem;
`

export const PreviewEtapaContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: .8rem .8rem;    
    max-width: 43rem;
`

export const HeadPreview = styled.div`
display: flex;
align-items: center;
margin-bottom: .75rem;

span{
    font-weight: 600;
    font-size: 1.125rem;
}
svg{
    margin-right: 1rem;
}
`

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@chakra-ui/layout'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { iTabelasPDIs } from '../..'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { iCompetencia } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida, useQuery } from '../../../../../Utils/Helper'
import { ArrowButton } from '../../styles'
import { Acao } from '../Acao'
import { CardAcao } from '../CardAcao'
import { TabelaAprovacao } from '../TabelaAprovacao'
import { TabelaEmAndamento } from '../TabelaEmAndamento'

export interface iAcao {
  id: string
  dataInicio: string
  dataFim: string
  hora: string
  assunto: string
  titulo: string
  descricao: string
  motivo: string
  comentario?: string
  solicitarAjustes: boolean
  status: number
  competencia?: iCompetencia
}

interface iPDIProviderProps {
  Acoes: iAcao[]
  Exluir: (id: string, acoes: iAcao[]) => void
  SolicitarAjustes: () => void
  AprovarPDI: () => void
  AtualizarAcao: (acao: iAcao) => void
}

interface iAtualizacaoPDI {
  id: string
  comentario?: string
}

interface iProps {
  pdis?: iTabelasPDIs
  getPdis: () => void
}

function usePDIState(pathname: string, nav: any, reload: () => void, pdiId?: string): iPDIProviderProps {
  const [Acoes, setAcoes] = useState<iAcao[]>([])

  function InternalReset(): void {
    reload()
    nav(`/${pathname.split('/').splice(1, 3).join('/')}`)
  }

  function Exluir(id: string, acoes: iAcao[]): void {
    appApi.delete(`PDI/${pdiId as string}/Acao/${id}`)
      .then(() => {
        const copy = [...acoes.filter(e => e.id !== id)]
        setAcoes(copy)
        if (copy.filter(e => e.status === 1).length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function SolicitarAjustes(): void {
    const form: iAtualizacaoPDI[] = Acoes.filter(r => r.solicitarAjustes).map((e) => {
      return {
        id: e.id,
        comentario: e.comentario
      }
    })

    appApi.post(`PDI/${pdiId as string}/Acao/SolicitarAjuste`, form)
      .then(() => {
        const copy = [...Acoes]
        Acoes.forEach(e => {
          if (e.solicitarAjustes) {
            e.status = 2
          }
        })
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AprovarPDI(): void {
    const form: iAtualizacaoPDI[] = Acoes.filter(r => !r.solicitarAjustes).map((e) => {
      return {
        id: e.id,
        comentario: e.comentario
      }
    })
    appApi.post(`PDI/${pdiId as string}/Acao/Aprovar`, form)
      .then(() => {
        const copy = [...Acoes]
        Acoes.forEach(e => {
          if (!e.solicitarAjustes) {
            e.status = 5
          }
        })
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AtualizarAcao(acao: iAcao): void {
    const copy = [...Acoes]
    const pos = copy.findIndex(e => e.id === acao.id)
    if (pos !== -1) {
      copy[pos] = acao
      setAcoes(copy)
    }
  }

  function ObterAcoesPorPdiId(): void {
    appApi.get(`PDI/${pdiId as string}/Acao`)
      .then(res => setAcoes(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (pdiId) {
      ObterAcoesPorPdiId()
    }
  }, [pdiId])

  return { Acoes: Acoes, AprovarPDI: AprovarPDI, AtualizarAcao: AtualizarAcao, Exluir: Exluir, SolicitarAjustes: SolicitarAjustes }
}

export const TabPDI: React.FC<iProps> = ({ pdis, getPdis }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const { Acoes, AprovarPDI, AtualizarAcao, Exluir, SolicitarAjustes } = usePDIState(pathname, nav, getPdis, query.get('pdiId') ?? undefined)

  const [ModalAcaoIsOpen, setModalAcaoIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [View, setView] = useState('0')

  function onOpenModalDeleteAcao(id: string): void {
    setModalAcaoIsOpen(true)
    setId(id)
  }

  function onCloseModalDeleteAcao(): void {
    setModalAcaoIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    if (query.get('pdiId')) {
      setView('1')
    } else {
      setView('0')
    }
  }, [query])

  return (
    <Flex flexDir={'column'}>
      <ModalDelete
        isOpen={ModalAcaoIsOpen}
        message={Acoes.length === 1 ? 'Esta é a ultima ação do PDI, após remove-la o pdi será excluído!' : 'Deseja realmente excluir esta ação?'}
        onRequestClose={onCloseModalDeleteAcao}
        onConfirm={() => Exluir(Id ?? '', Acoes)}
        center
      />
      {View === '0' && (
          <Flex gap='1rem' flexDirection='column' padding={'0 1rem'}>
            {(PropInvalida(pdis) || (pdis?.emAndamento?.length === 0 && pdis?.aprovacao.length === 0 && pdis?.emEdicao.length === 0)) && (
              <ArrowButton>
                <ButtonCmp
                  VarColor='Green2'
                  onClick={() => nav('/Desempenho/PDI/Criar')}
                >Começar novo PDI</ButtonCmp>
              </ArrowButton>
            )}
            {
              (pdis && pdis?.aprovacao.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI aguardando aprovação</h3>
                  <Flex flexDir={'column'}>
                    <TabelaAprovacao slice={4} dados={pdis?.aprovacao} />
                  </Flex>
                </Flex>
              )
            }
            {
              (pdis && pdis?.emAndamento.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI em andamento</h3>
                  <Flex flexDir={'column'}>
                    <TabelaEmAndamento slice={4} dados={pdis.emAndamento} />
                  </Flex>
                </Flex>
              )
            }
            {
              (pdis && pdis?.emEdicao.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI aguardando alteração</h3>
                  <Flex flexDir={'column'}>
                    <TabelaEmAndamento slice={4} dados={pdis.emEdicao} />
                  </Flex>
                </Flex>
              )
            }
          </Flex>
      )}

      {
        (View === '1') && (
          <Flex flexDir={'column'}>
            <ButtonCmp
              width={'fit-content'}
              VarColor='Green2'
              size={'md'}
              marginBottom='1rem'
              onClick={() => nav(`/${pathname.split('/').splice(1, 3).join('/')}`)}
            >Visualizar PDIs</ButtonCmp>

            <h3>Ações para aprovar</h3>
            <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
              {Acoes.filter(e => e.status === 1).length > 0
                ? Acoes.filter(e => e.status === 1).map((e, i) => {
                  return (
                  <Acao
                    key={i}
                    onRemove={onOpenModalDeleteAcao}
                    onUpdate={AtualizarAcao}
                    acao={e}
                  />
                  )
                })
                : <Text fontWeight={700} fontSize={'.875rem'} color={'var(--Azul)'}>Nenhuma ação pendente para aprovar!</Text>}
              <Flex gap={'.5rem'}>
                <ButtonCmp
                  disabled={Acoes.filter(e => e.status === 1).length === 0}
                  VarColor='Green2' onClick={AprovarPDI}
                >Aprovar PDI</ButtonCmp>
                <ButtonCmp
                  onClick={SolicitarAjustes}
                  isDisabled={Acoes.filter(e => e.status === 1).length === 0}
                  VarColor='Rosa'
                >Solicitar ajustes</ButtonCmp>
              </Flex>
              {Acoes.filter(e => e.status !== 1).length > 0 && <Flex flexDir={'column'} mt={'.75rem'}>
                <h3>Outras ações:</h3>
                <Flex gap={'1rem'} mt={'1rem'} flexWrap={'wrap'}>
                  {Acoes.filter(e => e.status !== 1).map((e, i) => {
                    return (
                      <CardAcao
                        key={i}
                        acao={e}
                      />
                    )
                  })}
                </Flex>
              </Flex>}
            </Flex>
          </Flex>
        )
      }
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { iGrafico } from '../../../../../interfaces'

interface iProps {
  id: string
  grafico: iGrafico
}

export const ColumnChart: React.FC<iProps> = ({ id, grafico }) => {
  async function GerarGrafico(): Promise<void> {
    const options = {
      series: [{
        name: '',
        data: grafico.dados[0].medias
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          color: '#4472C4',
          columnWidth: '80%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#58544D']
        },
        offsetY: -20,
        formatter: function (value: number): string {
          return `${value.toFixed(2)}`
        }
      },
      title: {
        text: grafico.dados[0].nome
      },
      yaxis: {
        show: false
      },
      xaxis: {
        categories: grafico.competencias
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    chart.updateSeries([{
      name: '',
      data: grafico.dados[0].medias
    }])
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])
  return (
    <Flex width={'100%'} id={`chart-${id}`} />
  )
}

import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
background: #fff;
justify-content: center;
color: var(--Azul);
border: 2px solid var(--Azul);
min-width: 5rem;
border-radius: 0.25rem;
padding: .25rem .5rem .25rem .5rem;
box-shadow: var(--SombraBtns);
span{
    margin-right:.625rem;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 3.5rem;
}
h3{
    font-size: 1.25rem;
    line-height: 1.125rem;
    max-width: 7.25rem;
}
`

export const AlertText = styled.span`
font-weight: 700;
font-size: 1.375rem;
color: var(--secundario5);
`

export const GridColumn = styled.div`
display: grid;
grid-template-columns: repeat(1,1fr);
gap: .75rem ;
`

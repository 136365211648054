import styled from 'styled-components'

export const Main = styled.div`
h2{
    color: var(--a3);
}

h4{
    color: var(--a4);
}
`

export const Title = styled.div`
margin-bottom: 1rem;
line-height: 1rem;

span{
    color: var(--Gray2);
    font-weight: 400;
}
`

import styled, { css } from 'styled-components'

export const VideoScreen = styled.video<{active: boolean}>`
width: 100%;
height: 100%;

${props => props.active && css`
    filter: brightness(0.5);
`}
`

export const ContentVideo = styled.div`
width: 100%; 
display: flex;
justify-content: center;
align-items: center;
`

export const PlayerContent = styled.div<{display: string}>`
display: ${props => props.display};
justify-content: center;
align-items: center;
flex-direction: column;
color: white;
z-index: 3;
position: absolute;

h2{
    margin-bottom: 1rem;
}

span{
    width: 35rem;
    text-align: center;
}
`

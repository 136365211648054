import { Avatar, Box, Flex, FormControl, FormLabel, Text, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { DeleteElementByPosition } from '../../../Utils/Helper'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FaChevronLeft, FaChevronRight, FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { appApi } from '../../../services/appApi'
import { iRespostaEscala } from '../../../interfaces'
import { isAuth } from '../../../services/token'

interface iPesquisa {
  nomePesquisa: string
  id: string
  questoes: iQuestao[]
  questoesIndicativa: iQuestao[]
  questoesIndicativaAutoAvaliacao: iQuestao[]
  autoAvaliacao: boolean
  maximoCompetencias: number
  avaliado: iAvaliado
  comentario: boolean
  avaliacaoId?: string
  aparecerNomeCompetencia: boolean
}

interface iQuestao {
  competencia: string
  nome: string
  id: string
  resposta?: number
  autoAvaliacao?: boolean
  traducoes?: iTraducao[]
  traducoesCompetencia?: iTraducao[]
  traducoesQuestao?: iTraducao[]
}

interface iAvaliado {
  nome: string
  area: string
  avatar: string
}

interface iResposta {
  questaoId: string
  respostaId: string
  autoAvaliacao: boolean
}

interface iRespostaDiscursiva {
  questaoId?: string
  observacao: string
}

interface iPostResposta {
  respostas: iResposta[]
  respostasDiscursivas?: iRespostaDiscursiva[]
}

interface LocationProps {
  tokenId: string
  idioma?: string
}

interface iTraducao {
  idioma: string
  texto: string
}

export const RespostaIndicativa: React.FC = () => {
  const { pathname } = useLocation()
  const { tokenId, idioma } = useLocation().state as LocationProps
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const isPrevisualizar = pathname.includes('Previsualizar')
  const nav = useNavigate()
  const toast = useToast()

  const [Block, setBlock] = useState<boolean>(false)
  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [Discursiva, setDiscursiva] = useState<iRespostaDiscursiva[]>([])
  const [TxtEncerramento, setTxtEncerramento] = useState('')
  const [isRespondido, setisRespondido] = useState(false)
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])
  const [Estagio, setEstagio] = useState(1)
  const [Comentario, setComentario] = useState('')
  const [Model, setModel] = useState<iPesquisa>({
    nomePesquisa: '',
    id: '',
    questoes: [],
    questoesIndicativa: [],
    questoesIndicativaAutoAvaliacao: [],
    autoAvaliacao: false,
    comentario: false,
    maximoCompetencias: 0,
    avaliado: { area: '', nome: '', avatar: '' },
    aparecerNomeCompetencia: false
  })

  const [IsLoading, setIsLoading] = useState(false)

  function ToggleResposta(quest: iQuestao, resp: number, auto: boolean): void {
    const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id

    if (IsSelecionada(quest, auto, resp)) {
      const posQuestao = Respostas.findIndex(e => e.respostaId === respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)
      if (posQuestao !== -1) {
        setRespostas(DeleteElementByPosition([...Respostas], posQuestao))
      }
      setBlock(false)
    } else if (BlockSelecao(auto, resp)) {
      console.log(':)')
    } else if (IsSelecionada(quest, auto)) {
      const posQuestao = Respostas.findIndex(e => e.respostaId !== respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)
      if (posQuestao !== -1) {
        setRespostas([...DeleteElementByPosition([...Respostas], posQuestao), {
          autoAvaliacao: auto,
          questaoId: quest.id,
          respostaId: respostaId ?? ''
        }])
      }
    } else {
      setRespostas([...Respostas, {
        autoAvaliacao: auto,
        questaoId: quest.id,
        respostaId: respostaId ?? ''
      }])
    }
  }

  function obterTraduzido(ptBR: string, enUs: string, esES: string): string {
    if (idioma === undefined) return ptBR

    return idioma === 'pt-BR'
      ? ptBR
      : idioma === 'en-US'
        ? enUs
        : esES
  }

  function IsSelecionada(quest: iQuestao, auto: boolean, resp?: number): boolean {
    if (resp) {
      const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id

      const respostaSimples = Respostas.find(e => e.respostaId === respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)

      return respostaSimples !== undefined
    } else {
      const respostaSimples = Respostas.find(e => e.questaoId === quest.id && e.autoAvaliacao === auto)

      return respostaSimples !== undefined
    }
  }

  function BlockSelecao(auto: boolean, resp: number): boolean {
    const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id

    const respostasSelecionadas = Respostas.filter(e => EscalaRespostas.some(r => r.id === e.respostaId))

    if (respostasSelecionadas.filter(e => e.respostaId === respostaId).length >= Model.maximoCompetencias) {
      setBlock(true)
      toast({
        title: obterTraduzido(`Não é possível selecionar mais de ${Model.maximoCompetencias} competências para destaque ou desenvolvimento. Desmarque uma para que você possa selecionar esta.`,
        `It is not possible to select more than ${Model.maximoCompetencias} competencies for highlight or development. Uncheck one so you can select this one.`,
        `No se pueden seleccionar más de ${Model.maximoCompetencias} competencias para destacar positivamente o para desarrollar. Desmarque una para que pueda seleccionar esta.
        `),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }
    setBlock(false)
    return false
  }

  function getFormulario(): void {
    appApi.get(`TokenPesquisa/${pathname.includes('RespostaPesquisa') ? `PreenchimentoIndicativa/${tokenId}` : `Previsualizar/${pesquisaId ?? ''}`}`)
      .then(resp => {
        setModel(resp.data)
        setEscalaRespostas(resp.data.respostas)
        setTxtEncerramento(resp.data.txtEncerramento)
      }).catch((err) => console.log(err))
  }

  function responderPesquisa(): void {
    const PostRespostas: iPostResposta = {
      respostas: Respostas,
      respostasDiscursivas: Discursiva
    }

    if (Model.comentario && Comentario.length < 5) {
      toast({
        title: obterTraduzido('O comentário deve conter no mínimo 5 caracteres', 'The comment must contain at least 5 characters.', 'El comentario debe contener al menos 5 caracteres.'),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (ValidarRespostas()) {
      setIsLoading(true)
      appApi.post(`RespostaPesquisa/Responder/${tokenId}`, PostRespostas)
        .then(() => {
          setisRespondido(true)
          setIsLoading(false)
        }).catch(() => {
          setIsLoading(false)
          toast({
            title: obterTraduzido('Erro tente novamente mais tarde', 'Error try again later', 'Error, inténtalo de nuevo más tarde.'),
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        })
    }
  }

  function ValidarRespostas(): boolean {
    if ((Estagio === 1 && Respostas.filter(e => !e.autoAvaliacao).length !== (Model.maximoCompetencias * 2)) || (Estagio === 2 && Respostas.filter(e => e.autoAvaliacao).length !== (Model.maximoCompetencias * 2))) {
      toast({
        title: obterTraduzido(`Responda no mínimo ${Model.maximoCompetencias} de cada!`,
        `Please, respond to at least ${Model.maximoCompetencias} of each!`,
        `¡Responda al menos ${Model.maximoCompetencias} de cada!`
        ),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function AvancarResposta(): void {
    if (ValidarRespostas()) {
      setEstagio(ObterProximoEstagio())
      setBlock(false)
    }
  }

  function ObterProximoEstagio(): number {
    if (Estagio === 1 && Model.autoAvaliacao) {
      return 2
    } else if (Estagio === 1 && !Model.autoAvaliacao && Model.comentario) {
      return 3
    } else if (Estagio === 2 && Model.comentario) {
      return 3
    } else {
      return Estagio
    }
  }

  function AdicionarComentario(texto: string): void {
    setDiscursiva([{ observacao: texto }])
    setComentario(texto)
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      {!isRespondido &&
        <WhiteContainer>
          <Flex justifyContent={'space-between'} pos={'relative'} mb={'.625rem'}>
            <h1>{Model.nomePesquisa}</h1>
            {Model.avaliado && <Flex pos={'absolute'} right={'0'} top={'0'} gap={'.825rem'} px={'.825rem'} py={'.5rem'} color={'var(--c1)'} bg={'var(--DegradeAzul)'} borderRadius={'.25rem'} alignItems={'center'}>
              <Avatar size={'md'} src={Model?.avaliado?.avatar} />
              <Flex flexDir={'column'} justifyContent={'center'}>
                <Text fontSize={'.813rem'} lineHeight={'.9rem'}>{obterTraduzido('Avaliado', 'Evaluated', 'Evaluado')}</Text>
                <h3 style={{ lineHeight: '1.2rem', fontSize: '1rem' }}>{Model?.avaliado?.nome.length > 23 ? `${Model?.avaliado?.nome.substring(0, 24)}...` : Model?.avaliado?.nome}</h3>
                <Text fontSize={'.813rem'} lineHeight={'.9rem'}>{Model?.avaliado?.area?.length > 23 ? `${Model?.avaliado?.area.substring(0, 24)}...` : Model?.avaliado?.area}</Text>
              </Flex>
            </Flex>}
          </Flex>
          {(Estagio === 1 || Estagio === 2) &&
            <Box mb='.5rem'>
              <Text fontWeight={'600'} fontSize={'.813rem'} color={'var(--Gray2)'} mb={'.25rem'}>{obterTraduzido('Na lista das competências abaixo, selecione:',
                'In the list of competencies below, select:',
                'En la lista de competencias a continuación, seleccione:')}
              </Text>
              <Text mb={'.5rem'} display={'flex'} fontSize='.875rem' alignItems={'center'}>
                <Flex alignItems={'center'} mr={'.5rem'}>
                  {(Model.autoAvaliacao && Estagio === 2)
                    ? <FaHeart size={16} style={{ color: 'var(--Azul)' }} />
                    : <FaMedal size={16} style={{ color: 'var(--Green2)' }} />}
                </Flex>
                <strong style={{ marginRight: '.325rem' }}>{Model.maximoCompetencias}</strong> {Model.autoAvaliacao && Estagio === 2
                  ? obterTraduzido('competências que você mais se motiva, quando as utiliza na função atual',
                    'competencies that motivate you the most when using',
                    'competencias que más lo motivan cuando las está utilizando')
                  : obterTraduzido('competências consideradas os maiores destaques positivos do avaliado na função atual',
                    'competencies that you consider to be the greatest positive highlights of the evaluated',
                    'competencias que considera son los mayores puntos destacados positivos del evaluado')}
              </Text>

              <Text fontSize='.875rem' display={'flex'}
                alignItems={'center'}>
                  <Flex alignItems={'center'} mr={'.5rem'}>
                  {(Model.autoAvaliacao && Estagio === 2)
                    ? <FaFrown size={16} style={{ color: 'var(--Roxo)' }} />
                    : <FaTools size={16} style={{ color: 'var(--Orange)' }} />}
                  </Flex>
                <strong style={{ marginRight: '.325rem' }}>{Model.maximoCompetencias}</strong> {Model.autoAvaliacao && Estagio === 2
                  ? obterTraduzido('competências que menos te motivam, quando as utiliza na função atual',
                    'competencies that motivate you the least when using',
                    'competencias que menos lo motivan cuando las está utilizando')
                  : obterTraduzido('competências consideradas prioridades de desenvolvimento do avaliado na função atual',
                    'competencies that you consider the evaluated needs to develop the most',
                    'competencias que considera que el evaluado necesita desarrollar más')
                }
              </Text>
            </Box>}
            <Flex flexDir={'column'} gap={'.75rem'} mt={'.25rem'}>
              {(Estagio === 1) && Model.questoesIndicativa?.map((e, i) => {
                return (
                  <Box
                      key={e.id}
                      boxShadow={'var(--SombraBtns)'}
                      borderRadius={'.25rem'}
                      bgColor={'var(--AzulOpacoBg)'}
                      border={'1px solid var(--c4)'}
                      gap={'.5rem'} px={'1rem'} py={'.75rem'} display={'flex'} alignItems={'center'}>
                      {Estagio === 1 && (
                        <Box
                          onClick={() => ToggleResposta(e, 1, false)}
                          bgColor={IsSelecionada(e, false, 1) ? 'var(--Green2)' : '#fff'}
                          _hover={{ bg: IsSelecionada(e, false, 1) ? '' : 'var(--Rosa)', color: IsSelecionada(e, false, 1) ? '' : '#fff' }}
                          boxShadow={IsSelecionada(e, false, 1) ? '0 4px 6px #00000020' : ''}
                          display={'flex'}
                          transition={'.15s'}
                          alignItems={'center'}
                          padding={'.3rem'}
                          fontSize={'15px'}
                          borderRadius={'50%'}
                          cursor='pointer'
                          color={IsSelecionada(e, false, 1) ? '#fff' : 'var(--Green2)'}
                        >
                          <FaMedal/>
                        </Box>
                      )}
                      {Estagio === 1 && (
                        <Box
                          onClick={() => ToggleResposta(e, -1, false)}
                          bgColor={IsSelecionada(e, false, -1) ? '#66046B' : '#fff'}
                          boxShadow={IsSelecionada(e, false, -1) ? '0 4px 6px #00000020' : ''}
                          _hover={{ bg: IsSelecionada(e, false, -1) ? '' : 'var(--Rosa)', color: IsSelecionada(e, false, -1) ? '' : '#fff' }}
                          display={'flex'}
                          transition={'.15s'}
                          alignItems={'center'}
                          padding={'.3rem'}
                          fontSize={'15px'}
                          borderRadius={'50%'}
                          cursor='pointer'
                          color={IsSelecionada(e, false, -1) ? '#fff' : '#F2994A'}
                        >
                          <FaTools/>
                        </Box>
                      )}
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} ml={'.325rem'}>
                        {Model.aparecerNomeCompetencia && (
                          <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'14px'}>{idioma ? e.traducoesCompetencia?.find(f => f.idioma === idioma)?.texto ?? e.competencia : e.competencia}</Text>
                        )}
                        <Text fontSize={'.875rem'} lineHeight={'16px'} fontWeight={'600'}>{idioma ? e.traducoesQuestao?.find(f => f.idioma === idioma)?.texto ?? e.nome : e.nome}</Text>
                      </Box>
                  </Box>
                )
              })}
            </Flex>
            <Flex flexDir={'column'} gap={'.75rem'}>
              {(Estagio === 2) && Model.questoesIndicativaAutoAvaliacao?.map((e, i) => {
                return (
                  <Box key={e.id}
                    boxShadow={'var(--SombraBtns)'}
                    borderRadius={'.25rem'}
                    bgColor={'var(--AzulOpacoBg)'}
                    border={'1px solid var(--c4)'}
                    gap={'.5rem'} px={'1rem'} py={'.75rem'} display={'flex'} alignItems={'center'}>
                    {Estagio === 2 && (
                      <Box
                        onClick={() => ToggleResposta(e, 1, true)}
                        transition={'.15s'}
                        bgColor={IsSelecionada(e, true, 1) ? 'var(--Azul)' : '#fff'}
                        boxShadow={IsSelecionada(e, true, 1) ? '0 4px 6px #00000020' : ''}
                        _hover={{ bg: IsSelecionada(e, true, 1) ? '' : 'var(--Rosa)', color: IsSelecionada(e, true, 1) ? '' : '#fff' }}
                        display={'flex'}
                        alignItems={'center'}
                        padding={'.3rem'}
                        fontSize={'15px'}
                        cursor='pointer'
                        borderRadius={'50%'}
                        color={IsSelecionada(e, true, 1) ? '#fff' : 'var(--Azul)'}>
                        <FaHeart/>
                      </Box>
                    )}
                    {Estagio === 2 && (
                      <Box
                        onClick={() => ToggleResposta(e, -1, true)}
                        bgColor={IsSelecionada(e, true, -1) ? '#66046B' : '#fff'}
                        boxShadow={IsSelecionada(e, true, -1) ? '0 4px 6px #00000020' : ''}
                        _hover={{ bg: IsSelecionada(e, true, -1) ? '' : 'var(--Rosa)', color: IsSelecionada(e, true, -1) ? '' : '#fff' }}
                        display={'flex'}
                        color={IsSelecionada(e, true, -1) ? '#fff' : '#66046B'}
                        alignItems={'center'}
                        padding={'.3rem'}
                        fontSize={'15px'}
                        cursor='pointer'
                        borderRadius={'50%'}>
                          <FaFrown/>
                      </Box>
                    )}

                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                      {Model.aparecerNomeCompetencia && (
                        <Text fontSize={'12px'} fontWeight={'400'} lineHeight={'14px'}>{idioma ? e.traducoesCompetencia?.find(f => f.idioma === idioma)?.texto ?? e.competencia : e.competencia}</Text>
                      )}
                      <Text fontSize={'.875rem'} lineHeight={'16px'} fontWeight={'600'}>
                      {idioma ? e.traducoesQuestao?.find(f => f.idioma === idioma)?.texto ?? e.nome : e.nome}
                      </Text>
                    </Box>
                  </Box>
                )
              })}
            </Flex>

          {Block && (
            <Text color={'var(--Red)'}>
              {obterTraduzido(`Não é possível selecionar mais de ${Model.maximoCompetencias} competências para destaque ou desenvolvimento. Desmarque uma para que você possa selecionar esta.`,
              `It is not possible to select more than ${Model.maximoCompetencias} competencies for highlight or development. Uncheck one so you can select this one.`,
              `No se pueden seleccionar más de ${Model.maximoCompetencias} competencias para destacar positivamente o para desarrollar. Desmarque una para que pueda seleccionar esta.`)}</Text>
          )}
          {Estagio === 3 && Model.comentario && (
            <FormControl>
              <FormLabel>{obterTraduzido('Deixe um comentário para auxiliar no desenvolvimento do avaliado:',
                'Leave a comment to help your development:',
                'Deja un comentario para ayudar a tu desarrollo:')}</FormLabel>
              <Textarea h={'8.725rem'} placeholder={obterTraduzido(
                'Digite seu comentário',
                'Enter your comment',
                'Introduce tu comentario')} onChange={(e) => AdicionarComentario(e.target.value)} />
            </FormControl>
          )}
          <Flex justifyContent={'end'} mt={'1rem'} gap={'.5rem'}>
            {isPrevisualizar &&
              <ButtonCmp
                VarColor='Green2'
                onClick={() => nav(-1)}
              >Finalizar pré-visualização</ButtonCmp>
            }

            {!isPrevisualizar && Estagio > 1 &&
              <ButtonCmp
                VarColor='c6'
                onClick={() => setEstagio(Estagio === 3 && Model.autoAvaliacao ? 2 : 1)}
                leftIcon={<FaChevronLeft/>}
              >{obterTraduzido('Voltar', 'Back', 'Volver')}</ButtonCmp>
            }

            {!isPrevisualizar && (Estagio === ObterProximoEstagio()) &&
              <ButtonCmp
                VarColor='Green2'
                onClick={responderPesquisa}
                isLoading={IsLoading}
              >{obterTraduzido('Responder pesquisa', 'Respond to survey', 'Responder encuesta')}</ButtonCmp>
            }

            {!isPrevisualizar && (Estagio !== ObterProximoEstagio()) &&
              <ButtonCmp
                VarColor='Green2'
                onClick={AvancarResposta}
                rightIcon={<FaChevronRight/>}
              >{obterTraduzido('Continuar', 'Continue', 'Continuar')}</ButtonCmp>
            }
          </Flex>
        </WhiteContainer>
      }
      {isRespondido &&
        <WhiteContainer>
          <Flex background={'white'} height={'100%'}>
            <Flex padding={'3rem 3rem'} flexDirection={'column'}>
              <h3 dangerouslySetInnerHTML={{
                __html: TxtEncerramento.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ??
                obterTraduzido('Obrigado pela participação!', 'Thank you for your participation!', '¡Gracias por participar!')
              }}></h3>
              <Text
                fontWeight={'400'}
                style={{
                  color: 'var(--c6)',
                  marginTop: '1rem'
                }}>{obterTraduzido('Respostas enviadas com sucesso!',
                  'Responses submitted successfully!',
                  'Respuestas enviadas exitosamente!')}</Text>
              {
                isAuth() && (
                  <Flex marginTop={'1rem'}>
                    <ButtonCmp
                      VarColor='c6'
                      onClick={() => nav('/')}
                    >{obterTraduzido('Voltar', 'Back', 'Volver')}</ButtonCmp>
                  </Flex>
                )
              }
            </Flex>
          </Flex>
        </WhiteContainer>
      }
    </Body>
  )
}

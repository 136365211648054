import { Flex, Img, Text } from '@chakra-ui/react'
import { ModalBase } from '../../../../../../components/ModalBase'
import gifCheck from '../../../../../../assets/checkGif.gif'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface iProps{
  onClose: () => void
}

export const PopUpAceitar: React.FC<iProps> = ({ onClose }) => {
  return (
        <ModalBase
            isOpen
            onClose={onClose}
            Width='35rem'
        >
        <Flex fontSize={'14px'} alignItems={'center'} gap='.5rem' justifyContent='center' flexDir={'column'} p='0 2rem 2rem 2rem'>
            <Text fontWeight={'700'}>Convite aceito e agendamento realizado com sucesso!</Text>
            <Img w={'4rem'} h={'4rem'} src={gifCheck} mb={'.5rem'}/>
            <Text>Se for necessário fazer o reagendamento, você deve realiza-lo aqui mesmo em nossa plataforma, para garantir a melhor experiência!</Text>
            <ButtonCmp VarColor='Green2' onClick={onClose}>Continuar</ButtonCmp>
        </Flex>
        </ModalBase>
  )
}

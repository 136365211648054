/* eslint-disable space-infix-ops */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { IEmployee, iNineBox, iPerfilColaborador, iPutColaborador, iQuadrante } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { BoxOrganogram } from './Components/BoxOrganogram'
import { DashBox, MainColumn, ColumnTexts, ImgPerfil } from './styles'
import { Avatar, Button, Flex, Image, Menu, MenuButton, MenuList, Text, Box, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ModalCropColaborador } from './Components/ModalCrop'
import { PropInvalida } from '../../Utils/Helper'
import { ObterImgPorNome } from '../NineBox'
import { CreateColaboradorModal } from '../ModalCreateColaborador'
import { parseJwt } from '../../services/token'
import { ButtonCmp } from '../ButtonCmp'
import { StyledMenuItem } from '../NavBar/Components/StyledMenuItem'
import LoadingContainer from '../LoadingContainer'

interface iPerfilColumn {
  Colaborador: iPerfilColaborador
  nineBox?: iNineBox
  margin?: string
  reload?: () => void
  IsLoading: boolean
}

export function ObterCorQuadrantePorNome(quadrantes: iQuadrante[], nome: string): string {
  switch (quadrantes.findIndex(p => p.titulo === nome).toString()) {
    case '0': return 'var(--Blue1)'
    case '1': return 'var(--Green3)'
    case '2': return 'var(--Green2)'
    case '3': return 'var(--Orange)'
    case '4': return 'var(--Blue1)'
    case '5': return 'var(--Green3)'
    case '6': return 'var(--Red)'
    case '7': return 'var(--Orange)'
    case '8': return 'var(--Blue1)'
    default: return ''
  }
}

export const PerfilColaborador: React.FC<iPerfilColumn> = ({ Colaborador, nineBox, margin, reload, IsLoading }) => {
  const toast = useToast()
  const [ColaboradorInterno, setColaboradorInterno] = useState<iPerfilColaborador>({} as iPerfilColaborador)
  const [ModalUpdateColaboradorIsOpen, setModalUpdateColaboradorIsOpen] = useState(false)
  const [UpdateColaborador, setUpdateColaborador] = useState<IEmployee>()

  const [ModalCropIsOpen, setModalCropIsOpen] = useState(false)

  function getColaborador(): void {
    appApi.get(`Colaborador/${Colaborador.id}`)
      .then(res => {
        setUpdateColaborador(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function onUpdateColaborador(form: iPutColaborador): void {
    appApi.put(`Colaborador/${UpdateColaborador?.id ?? ''}`, form).then(() => {
      toast({
        title: 'Colaborador atualizado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      setModalUpdateColaboradorIsOpen(false)
      if (reload !== undefined) {
        reload()
      }
    }).catch(({ response }) => {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    }
    )
  }

  function UpdateAvatar(form: FormData): void {
    appApi.put(`Colaborador/Avatar/${Colaborador.id}?url=${Colaborador.avatar}`, form)
      .then(res => {
        setColaboradorInterno({ ...ColaboradorInterno, avatar: res.data })
        setModalCropIsOpen(false)
      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    setColaboradorInterno(Colaborador)
  }, [Colaborador])

  useEffect(() => {
    if (ModalUpdateColaboradorIsOpen) {
      getColaborador()
    } else {
      setUpdateColaborador(undefined)
    }
  }, [ModalUpdateColaboradorIsOpen])

  return (
        <MainColumn padding='1rem' width='30%' margin={margin}>
            <LoadingContainer loading={IsLoading} linhas={24} my={'1rem'}>
                {ModalCropIsOpen && (
                    <ModalCropColaborador
                        isOpen={ModalCropIsOpen}
                        onRequestClose={() => setModalCropIsOpen(false)}
                        onConfirm={UpdateAvatar}
                    />
                )}

                {ModalUpdateColaboradorIsOpen && (
                    <CreateColaboradorModal
                        isOpen={ModalUpdateColaboradorIsOpen}
                        onRequestClose={() => setModalUpdateColaboradorIsOpen(false)}
                        colaborador={UpdateColaborador}
                        onUpdateEmployee={onUpdateColaborador}
                    />
                )}

                <Flex>
                    <Flex marginBottom={'.75rem'} alignItems={'center'}>
                        <ImgPerfil onClick={() => setModalCropIsOpen(true)}>
                            <Avatar
                                marginRight={'1rem'}
                                size={'xl'}
                                name={ColaboradorInterno?.nome}
                                src={ColaboradorInterno?.avatar}
                                boxShadow={'var(--SombraBtns)'}
                            />
                        </ImgPerfil>
                        <ColumnTexts>
                            <h3 style={{ wordBreak: 'break-word' }}>{ColaboradorInterno?.nome}</h3>
                            <span><strong>Área:</strong> {ColaboradorInterno?.nomeDepartamento}</span>
                            <span><strong>Função:</strong> {ColaboradorInterno?.nomeCargo}</span>
                            {
                                ColaboradorInterno?.email?.length > 19
                                  ? <span title={ColaboradorInterno?.email}><strong>Email: </strong>{ColaboradorInterno?.email.substring(0, 20)}...</span>
                                  : <span><strong>Email: </strong>{ColaboradorInterno?.email}</span>
                            }
                            {ColaboradorInterno.celular && <span><strong>Celular: </strong>{ColaboradorInterno?.celular}</span>}
                            <span><strong>Data de admissão: </strong> {ColaboradorInterno.dataAdmissao}</span>
                        </ColumnTexts>
                    </Flex>
                </Flex>
                {(parseJwt().adm === 'True') && (
                    <ButtonCmp VarColor='Green2' mb={'.75rem'} onClick={() => setModalUpdateColaboradorIsOpen(true)}>Editar perfil</ButtonCmp>)}
                {(ColaboradorInterno.avaliacaoNineBox && nineBox) && (
                    <Flex mb={'1rem'} mt={'.25rem'} gap={'.75rem'}>
                        <Box backgroundColor={ObterCorQuadrantePorNome(nineBox.quadrantes, Colaborador.avaliacaoNineBox?.tituloQuadrante ?? '')} w={'5.5rem'} h={'5.5rem'} display={'flex'} flexDirection={'column'} color={'#fff'} borderRadius={'.25rem'} justifyContent={'center'} alignItems={'center'}>
                            <Text fontSize={'0.75rem'}>{ColaboradorInterno.avaliacaoNineBox.tituloQuadrante}</Text>
                            <Image height={'3rem'} width={'3rem'} src={ObterImgPorNome(nineBox.quadrantes, Colaborador.avaliacaoNineBox?.tituloQuadrante ?? '')} />
                        </Box>
                        <Flex flexDir={'column'} justifyContent={'center'} fontSize={'.875rem'}>
                            <Text fontWeight={'700'}>Notas do 9Box</Text>
                            <Text color={'var(--Azul)'}><strong style={{ fontWeight: 600 }}>{ColaboradorInterno.avaliacaoNineBox.eixoY} | </strong>{ColaboradorInterno.avaliacaoNineBox.tituloY}: {ColaboradorInterno.avaliacaoNineBox.notaY === -1 ? '-' : ColaboradorInterno.avaliacaoNineBox.notaY}</Text>
                            <Text color={'var(--Green2)'}><strong style={{ fontWeight: 600 }}>{ColaboradorInterno.avaliacaoNineBox.eixoX} | </strong>{ColaboradorInterno.avaliacaoNineBox.tituloX}: {ColaboradorInterno.avaliacaoNineBox.notaX === -1 ? '-' : ColaboradorInterno.avaliacaoNineBox.notaX}</Text>
                        </Flex>
                    </Flex>
                )}
                <DashBox theme={'Rosa'}>
                    {
                        (ColaboradorInterno) && (
                            <BoxOrganogram
                                Lider={ColaboradorInterno.lider}
                                Pares={ColaboradorInterno.pares}
                                liderados={ColaboradorInterno.liderados}
                            />
                        )
                    }
                </DashBox>

                {
                    (!PropInvalida(Colaborador.perfilPI)) && (
                        <DashBox theme={'Rosa'}>
                            <Flex justifyContent={'center'}>
                                <span className='DashTitle'>Perfil comportamental</span>
                            </Flex>
                            <Flex flexDirection={'column'}>
                                <Flex marginBottom={'.5rem'} flexDir='column' width='100%' alignItems={'center'}>
                                    {
                                        (Colaborador.perfilPI?.nomeSelo) && (
                                            <Text marginBottom={'.5rem'} fontWeight={'600'} fontSize={'.875rem'}>{Colaborador.perfilPI?.nomeSelo}</Text>
                                        )
                                    }
                                    {
                                        (Colaborador.perfilPI?.urlSelo) && (
                                            <Image width={'3rem'} src={Colaborador.perfilPI.urlSelo} />
                                        )
                                    }
                                </Flex>

                                <Flex justifyContent={'space-around'} mb={'-.5rem'}>
                                    {
                                        (Colaborador.perfilPI?.urlSelf) && (
                                            <Image width={'16rem'} src={Colaborador.perfilPI.urlSelf} />
                                        )
                                    }
                                </Flex>

                                <Menu>
                                    <MenuButton as={Button} color={'var(--Rosa)'} fontSize={'.875rem'} borderRadius={'.25rem'} height={'1.95rem'} bgColor='var(--c1)' border={'2px solid var(--Rosa)'} _hover={{ bgColor: 'white', color: 'var(--c1)', border: '2px', bg: 'var(--Rosa)' }} _active={{ bgColor: 'white', color: 'var(--c1)', border: '2px', bg: 'var(--Rosa)' }}>
                                        Baixar Relatorios
                                    </MenuButton>
                                    <MenuList bgColor='var(--Rosa)' boxShadow={'var(--SombraBtns)'}>
                                        {
                                            (!PropInvalida(Colaborador.perfilPI?.urlResultado)) && (
                                                <StyledMenuItem onClick={() => { window.location.href = Colaborador.perfilPI?.urlResultado ??'#' }}>
                                                    Perfil comportamental
                                                </StyledMenuItem>
                                            )
                                        }

                                        {
                                            (!PropInvalida(Colaborador.perfilPI?.urlQuadro)) && (
                                                <StyledMenuItem onClick={() => { window.location.href = Colaborador.perfilPI?.urlQuadro ??'#' }}>
                                                    Quadro desenvolvimento pessoal
                                                </StyledMenuItem>
                                            )
                                        }

                                        {
                                            (!PropInvalida(Colaborador.perfilPI?.urlPlacard)) && (
                                                <StyledMenuItem onClick={() => { window.location.href = Colaborador.perfilPI?.urlPlacard ??'#' }}>
                                                    Placard
                                                </StyledMenuItem>
                                            )
                                        }

                                        {
                                            (!PropInvalida(Colaborador.perfilPI?.urlQuadroGestor)) && (
                                                <StyledMenuItem onClick={() => { window.location.href = Colaborador.perfilPI?.urlQuadroGestor ??'#' }}>
                                                    Quadro desenvolvimento gestores
                                                </StyledMenuItem>
                                            )
                                        }

                                    </MenuList>
                                </Menu>
                            </Flex>
                        </DashBox>
                    )
                }
            </LoadingContainer>
        </MainColumn>
  )
}

import { Flex, Menu } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia, iModalPadrao } from '../../../../../interfaces'
import { ModalCriarCompetencia } from '../ModalCriarCompetencia'
import { ModalPreVizualizarQuestao } from '../ModalPreVizualizarQuestoes'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { StyledMenuButton } from '../../../../../components/NavBar/Components/StyledMenuButton'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { StyledMenuList } from '../../../../../components/NavBar/Components/StyledMenuList'
import { InputCmp } from '../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../components/SelectCmp'

export const ModalTodasCompetencias: React.FC<iModalPadrao> = ({ OnCloseModal, isOpen }) => {
  const { SelectCompetencia, DeletarCompetencia, ctxFormulario, ctxCompetencias } = useFormulario()

  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [CompetenciaId, setCompetenciaId] = useState('')
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])

  const [CompetenciaEditId, setCompetenciaEditId] = useState<string>()

  const [PreviewisOpen, setPreviewisOpen] = useState(false)
  const [EditisOpen, setEditisOpen] = useState(false)

  function orderByName(isDesc: boolean): void {
    const arr = [...Competencias]
    arr.sort((a, b) => {
      return a.nome.localeCompare(b.nome, 'pt-BR')
    })
    if (isDesc) arr.reverse()
    setCompetencias(arr)
  }

  useEffect(() => {
    setCompetencias(ctxCompetencias)
  }, [ctxCompetencias])

  return (
    <ModalBase
      isOpen={isOpen as boolean}
      Titulo='Competências'
      onClose={OnCloseModal}
      Width='75rem'
    >
      <ModalPreVizualizarQuestao
        isOpen={PreviewisOpen}
        CompetenciaId={CompetenciaId}
        OnCloseModal={() => setPreviewisOpen(false)}
      />

      <ModalCriarCompetencia
        OnCloseModal={() => { setEditisOpen(false); setCompetenciaEditId(undefined) }}
        isOpen={EditisOpen}
        CompetenciaId={CompetenciaEditId}
      />

      <Flex justifyContent={'space-between'} alignItems='end' mx={'.5rem'} mb={'.75rem'} mt={'0'}>
        <SelectCmp
          width={'18rem'}
          title='Ordenar por'
          OnChange={(e) => {
            if (e === '0') {
              orderByName(false)
            } else if (e === '1') {
              orderByName(true)
            }
          }}
        >
          <option value={'0'}>A-Z</option>
          <option value={'1'}>Z-A</option>
        </SelectCmp>

        <Flex>
          <InputCmp
            position={'inherit'}
            value={TxtPesquisa}
            OnChange={setTxtPesquisa}
            placeholder='Buscar Competências'
            width={'17rem'}
          />
        </Flex>
      </Flex>

      <ModalBody>
        <section>
          {
            Competencias.filter(e => !e.discursiva).filter((e: iCompetencia) => {
              if (TxtPesquisa === '') {
                return e
              } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                return e
              }
              return null
            }).map((e: iCompetencia, i) => {
              return (
                <Flex key={i} className='VariantColor' justifyContent={'space-between'}>
                  <Flex>
                    <ImgVariant borderColor={e.doSistema ? 'Azul' : 'AzulOpaco'} src={e.icone} />
                    <Flex flexDirection={'column'}>
                      <h4>{e.nome}</h4>
                      <small>{e.doSistema ? 'Padrão' : e.dataCriacao}</small>
                    </Flex>
                  </Flex>

                  <Flex alignItems={'center'} gap={'.5rem'}>
                    <ButtonCmp
                      OutlineColor={'Rosa'}
                      onClick={() => { setCompetenciaId(e.id); setPreviewisOpen(true) }} leftIcon={<FaEye
                        />}>Pré-visualizar questões</ButtonCmp>
                    {
                      ctxFormulario.selecoes.find(r => r.competenciaId === e.id) === undefined
                        ? <ButtonCmp w={'5.5rem'} VarColor='Green2' onClick={() => SelectCompetencia(e.id)}>Adicionar</ButtonCmp>
                        : <ButtonCmp w={'5.5rem'} OutlineColor='Green2'>Adicionado</ButtonCmp>
                    }
                    <Flex w={'1.5rem'} justifyContent={'start'}>
                      {
                        (!e.doSistema) && (
                          <Flex justifyContent={'end'}>
                            <Menu>
                              <StyledMenuButton pl={'0'} color={'black'} _hover={{ bg: 'none' }} _active={{ bg: 'none' }} _focus={{ bg: 'none' }}>
                                <BsThreeDotsVertical size={14}/>
                              </StyledMenuButton>
                              <StyledMenuList>
                                <StyledMenuItem
                                  onClick={() => {
                                    setEditisOpen(true)
                                    setCompetenciaEditId(e.id)
                                  }}>
                                  <FaEdit style={{ marginRight: '.4rem' }} />
                                  Editar
                                </StyledMenuItem>
                                <StyledMenuItem
                                  onClick={(() => { DeletarCompetencia(e.id) })}
                                >
                                  <FaTrash style={{ marginRight: '.4rem' }} />
                                  Excluir
                                </StyledMenuItem>
                              </StyledMenuList>
                            </Menu>
                        </Flex>
                        // <Flex position={'relative'} justifyContent={'end'}>
                        //   <Menu>
                        //     <FaEllipsisV />
                        //     <Flex width={'6rem'}>
                        //       <DropMenu>
                        //         <li onClick={() => {
                        //           setEditisOpen(true)
                        //           setCompetenciaEditId(e.id)
                        //         }} style={{ color: 'var(--terc10)' }}><FaEdit /> Editar</li>
                        //         <li onClick={(() => { DeletarCompetencia(e.id) })} style={{ color: 'var(--terc3)' }}><FaTrash /> Excluir</li>
                        //       </DropMenu>
                        //     </Flex>
                        //   </Menu>
                        // </Flex>
                        )
                      }
                    </Flex>
                  </Flex>
                </Flex>
              )
            })
          }
        </section>
      </ModalBody>
      <ModalFooter>
        <ButtonCmp VarColor='Green2' onClick={() => OnCloseModal()}>Continuar</ButtonCmp>
      </ModalFooter>
    </ModalBase>
  )
}

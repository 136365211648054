/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { IDadoGrafico } from '../..'
import { Flex, Grid, Text } from '@chakra-ui/react'

interface iChartProps {
  model: IDadoGrafico[]
}

export const BarChartGeral: React.FC<iChartProps> = ({ model }) => {
  const id = 'teste'
  const valorOrdenado = model?.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))

  function ObterCor(value: IDadoGrafico[]): string[] {
    const arr: string[] = []
    value?.forEach(e => {
      if (Number(e.media) < 0) {
        arr.push('var(--Orange)')
      } else {
        arr.push('var(--Green2)')
      }
    })

    return arr
  }
  function ObterValorPos(value: IDadoGrafico[]): string[] {
    const arr: string[] = []
    value?.forEach(e => arr.push(e.media))

    return arr
  }

  function GerarGrafico(): void {
    const options = {
      series: [{
        data: ObterValorPos(valorOrdenado)
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: valorOrdenado?.length * 80,
        width: 1000,
        toolbar: {
          show: false
        }
      },
      annotations: {
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      dataLabels: {
        enabled: true,
        position: 'start',
        offsetX: 55,
        offsetY: -20,
        formatter: function (value: any, series: any) {
          return `C${((series.dataPointIndex as number) + 1)} (${valorOrdenado[series.dataPointIndex].media})`
        },
        style: {
          colors: ['#000']
        }
      },
      colors: ObterCor(valorOrdenado),
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: '20px',
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (value: any, series: any) {
              return valorOrdenado[series.dataPointIndex].nome + ' - '
            }
          },
          marker: {
            show: false
          }
        }
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        max: valorOrdenado.some(e => parseFloat(e.media) > 0.6) ? 1.2 : 0.6,
        min: valorOrdenado.some(e => parseFloat(e.media) < -0.6) ? -1.2 : -0.6
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [model])

  return (
    <Flex flexDir={'column'}>
      {/* <Flex direction={'column'} my={'1rem'}>
        <Flex justifyContent={'center'} gap={'.875rem'}>
          <Flex gap={'.325rem'} alignItems={'center'}><Box w={'1.25rem'} h={'1.25rem'} bgColor={'var(--Orange)'}></Box> Competências priorizadas para serem desenvolvidas</Flex>
          <Flex gap={'.325rem'} alignItems={'center'}><Box w={'1.25rem'} h={'1.25rem'} bgColor={'var(--Green2)'}></Box> Demais competências</Flex>
        </Flex>
      </Flex> */}
      <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
        <div id={id}></div>
      </Flex>
      <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap='.5rem' px={'1rem'}>
        {valorOrdenado?.map((e, i) => {
          return (
            <Text key={i}>C{i + 1}. {e.nome} ({e.media})</Text>
          )
        })}
      </Grid>
    </Flex>
  )
}

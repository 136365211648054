import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
border-radius: 0.25rem;
height: 6rem;
color: var(--c1);
background: var(--Azul);
min-width: 7.813rem;
padding: 0 .25rem 0 .25rem;
box-shadow: var(--SombraBtns);
gap: 0 .5rem;
h4{
    font-size: 1.125rem;
    line-height: 1rem;
    font-weight: 700;
}
span{
    font-size: .875rem;
}
div{
    width: min-content;
}
`

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
background: var(--AzulOpacoBg);
padding: .5rem 1rem .5rem 1rem;
width: 100%;
flex-direction: column;
gap: .25rem;
strong{
    font-size: .875rem;
    line-height: 1rem;
}
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 1.25rem;
`

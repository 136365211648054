/* eslint-disable no-return-assign */
import { Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { appApi, urlApi } from '../../../../../services/appApi'
import { ObterToken, PropInvalida } from '../../../../../Utils/Helper'
import { ModalBody } from './styles'
import { useState } from 'react'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  reload: () => void
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, reload, isOpen }) => {
  const toast = useToast()
  const [Erros, setErros] = useState<string[]>([])
  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState('')
  const AtualizarArquivo = (arquivo: any): void => {
    const formdata = new FormData()
    formdata.append('arquivo', arquivo.target.files[0])
    setArquivo(formdata)
    setNome(arquivo.target.files[0].name)
    toast({
      title: 'Arquivo selecionado com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
  }

  function EnviarEmMassa(): void {
    appApi.post('NineBox/Csv', Arquivo)
      .then(res => {
        toast({
          title: 'Colaboradores avaliados com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        reload()
        if (res.data.length > 0) {
          setErros(res.data)
        } else {
          onRequestClose()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <ModalBase
      Titulo='Avaliar colaboradores em massa'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='65rem'
    >
      <ModalBody style={{ fontSize: '.875rem' }}>
        <span><strong>Instruções para gerar o arquivo no excel: </strong>
          Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
          Após isso, clique em "Salvar como..." e selecione o formato
          <b> CSV UTF-8 (Delimitado por vírgulas). </b>
           As colunas que estão marcadas com “*” são campos obrigatórios.
          Por favor, realize o preenchimento completo para correto envio.
          <small> Obrigatório a inserção do endereço de email</small>
        </span>

        <label style={{ borderRadius: '.25rem', height: '1.95rem', fontSize: '.875rem', padding: '.25rem .75rem' }} htmlFor='Ifile'>{Arquivo ? Nome : 'Selecione um arquivo'}</label>
        <input onChange={(e) => AtualizarArquivo(e)} id='Ifile' type={'file'} />
        {Erros?.length > 0 &&
        <Flex flexDir={'column'} width={'100%'} border={'2px solid var(--Red)'} borderRadius='.25rem' mt={'.75rem'} as={'ul'} padding='1rem 1rem 1rem 2rem'>
          <Text textAlign={'center'} fontWeight='bold' mb={'1rem'} fontSize={'.875rem'}>Emails com erro</Text>
          <Grid templateColumns='repeat(4, 1fr)' gap={6}>
            {Erros?.map((e) => {
              return (
                <li style={{ fontSize: '.813rem' }}>{e}</li>
              )
            })}
          </Grid>
        </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={() => window.location.href = `${urlApi}/NineBox/Csv?token=${ObterToken()}`}
          VarColor='Rosa'
        >Baixar exemplo para preenchimento</ButtonCmp>
        <ButtonCmp isDisabled={PropInvalida(Arquivo)} onClick={EnviarEmMassa} VarColor='Green2' size='md'>Enviar arquivo</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

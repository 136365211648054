import { CSSProperties } from 'react'
import { Content } from './styles'

interface MetaButtonProps {
  size: string
  bg: string
  styles?: CSSProperties
  onClick?: () => void
  margin?: string
  marginR?: string
}

export const MetaButton: React.FC<MetaButtonProps> = ({ children, margin, bg, size, onClick, styles, marginR }) => {
  return (
    <Content
      margin={margin}
      marginR={marginR}
      style={styles}
      theme={bg}
      fontsize={size + 'FontBtn'}
      heigth={size + 'HeigthBtn'}
      padding={size + 'PaddingBtn'}
      onClick={onClick}
    >
      {children}
    </Content>
  )
}

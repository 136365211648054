/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Grid, Img, Text } from '@chakra-ui/react'
import { AiOutlineRise } from 'react-icons/ai'
import { FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { iResultadoIndividualIndicativa } from '../../..'
import { TabelaIndividual } from './styles'

interface iProps {
  model: iResultadoIndividualIndicativa
}

export const TabResultadoIndividual: React.FC<iProps> = ({ model }) => {
  return (
    <Flex flexDir={'column'}>
      {model.resumoCompetencias?.some(e => e.status === 0) && (

        <Box borderBottom={'2px dashed var(--Azul)'} pb={'1.5rem'}>
          <Flex
            mb={'.75rem'}
            alignItems={'center'}
            gap={'.625rem'}
            fontSize={'18px'}
            fontWeight={'700'}
            color={'var(--Orange)'}
          >
            <FaTools /> Competências consideradas prioridades de desenvolvimento do avaliado na função atual
          </Flex>
          <Flex flexDir={'column'} gap={'.75rem'}>
            {model.resumoCompetencias?.filter(e => e.status === 0)?.map((e, i) => {
              return (
                <Flex key={i} fontWeight={'600'} alignItems={'center'} bg={'var(--c2)'} boxShadow={'var(--SombraBackground)'} borderRadius={'0 .25rem .25rem 0'}>
                  <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'11rem'} py={'.75rem'} px={'1rem'} color={'white'} bg={'var(--Orange)'} borderRadius={'.25rem 0 0 .25rem'} h={'full'}>
                    <Img w={'5.5rem'} h={'5.5rem'} src={e.avatar} />
                    <Text mt={0} fontSize={'.875rem'} lineHeight={'.875rem'} textAlign={'center'} fontWeight={'900'}>{e.nome}</Text>
                  </Flex>
                  <Box px={'1rem'} fontSize={'.875rem'} flex={'1'}>
                    <Text fontSize={'.95rem'} fontWeight={'800'}>Descrição: {e.descricao}</Text>
                    {e.pergunta}
                  </Box>
                </Flex>
              )
            })}
          </Flex>
        </Box>
      )}

      {model.resumoCompetencias?.some(e => e.status === 1) && (

        <Box borderBottom={'2px dashed var(--Azul)'} mt={'1.25rem'} pb={'1.5rem'}>
          <Flex
            mb={'.75rem'}
            alignItems={'center'}
            gap={'.625rem'}
            fontSize={'18px'}
            fontWeight={'700'}
            color={'var(--Green2)'}
          >
            <FaMedal /> Competências consideradas os maiores destaques positivos do avaliado na posição atual
          </Flex>

          <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
            {model.resumoCompetencias?.filter(e => e.status === 1)?.map((e, i) => {
              return (
                <Flex key={i} fontWeight={'600'} alignItems={'center'} bg={'var(--c2)'} boxShadow={'var(--SombraBackground)'} borderRadius={'0 .25rem .25rem 0'}>
                  <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'7.25rem'} py={'.75rem'} px={'1rem'} color={'white'} bg={'var(--Green2)'} borderRadius={'.25rem 0 0 .25rem'} h={'full'}>
                    <Img w={'5rem'} h={'5rem'} p={'.5rem'} borderRadius={'50%'} src={e.avatar} />
                    <Text mt={0} fontSize={'.813rem'} wordBreak={'break-word'} lineHeight={'.875rem'} textAlign={'center'} fontWeight={'900'}>{e.nome}</Text>
                  </Flex>
                  <Box px={'1rem'} fontSize={'.925rem'} flex={'1'}>
                    <Text fontWeight={'500'}><strong style={{ fontWeight: 800 }}>Descrição:</strong> {e.descricao}</Text>
                  </Box>
                </Flex>
              )
            })}
          </Grid>
        </Box>
      )}

      {model.resumoCompetencias?.some(e => e.status === 2) && (

        <Box borderBottom={'2px dashed var(--Azul)'} mt={'1.25rem'} pb={'1.5rem'}>
          <Flex
            mb={'.75rem'}
            alignItems={'center'}
            gap={'.625rem'}
            fontSize={'18px'}
            fontWeight={'700'}
            color={'var(--Azul)'}>
            <FaHeart /> Você mais se motiva
          </Flex>
          <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
            {model.resumoCompetencias?.filter(e => e.status === 2)?.map((e, i) => {
              return (
                <Flex key={i} fontWeight={'600'} alignItems={'center'} bg={'var(--c2)'} boxShadow={'var(--SombraBackground)'} borderRadius={'0 .25rem .25rem 0'}>
                  <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'7.25rem'} py={'.75rem'} px={'1rem'} color={'white'} bg={'var(--Azul)'} borderRadius={'.25rem 0 0 .25rem'} h={'full'}>
                    <Img w={'5rem'} h={'5rem'} p={'.5rem'} borderRadius={'50%'} src={e.avatar} />
                    <Text mt={0} fontSize={'.813rem'} wordBreak={'break-word'} lineHeight={'.875rem'} textAlign={'center'} fontWeight={'900'}>{e.nome}</Text>
                  </Flex>
                  <Box px={'1rem'} fontSize={'.925rem'} flex={'1'}>
                    <Text fontWeight={'500'}><strong style={{ fontWeight: 800 }}>Descrição:</strong> {e.descricao}</Text>
                  </Box>
                </Flex>
              )
            })}
          </Grid>
        </Box>
      )}

      {model.resumoCompetencias?.some(e => e.status === 3) && (

        <Box borderBottom={'2px dashed var(--Azul)'} mt={'1.25rem'} pb={'1.5rem'}>
          <Flex
            mb={'.75rem'}
            alignItems={'center'}
            gap={'.625rem'}
            fontSize={'18px'}
            fontWeight={'700'}
            color={'var(--Roxo)'}>
            <FaFrown /> Você menos se motiva
          </Flex>
          <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
            {model.resumoCompetencias?.filter(e => e.status === 3)?.map((e, i) => {
              return (
                <Flex key={i} fontWeight={'600'} alignItems={'center'} bg={'var(--c2)'} boxShadow={'var(--SombraBackground)'} borderRadius={'0 .25rem .25rem 0'}>
                  <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'7.25rem'} py={'.75rem'} px={'1rem'} color={'white'} bg={'var(--Roxo)'} borderRadius={'.25rem 0 0 .25rem'} h={'full'}>
                    <Img w={'5rem'} h={'5rem'} p={'.5rem'} borderRadius={'50%'} src={e.avatar} />
                    <Text mt={0} fontSize={'.813rem'} wordBreak={'break-word'} lineHeight={'.875rem'} textAlign={'center'} fontWeight={'900'}>{e.nome}</Text>
                  </Flex>
                  <Box px={'1rem'} fontSize={'.925rem'} flex={'1'}>
                    <Text fontWeight={'500'}><strong style={{ fontWeight: 800 }}>Descrição:</strong> {e.descricao}</Text>
                  </Box>
                </Flex>
              )
            })}
          </Grid>
        </Box>
      )}

      {model.resumoCompetencias?.some(e => e.status === 4) && (
        <Box mt={'1.25rem'} pb={'1.5rem'}>
          <Flex
            mb={'.75rem'}
            alignItems={'center'}
            gap={'.625rem'}
            fontSize={'18px'}
            fontWeight={'700'}
            color={'var(--Gray3)'}>
            <AiOutlineRise /> Outras oportunidades de desenvolvimento
          </Flex>
          <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
            {model.resumoCompetencias?.filter(e => e.status === 4)?.map((e, i) => {
              return (
                <Flex key={i} fontWeight={'600'} alignItems={'center'} bg={'var(--c2)'} boxShadow={'var(--SombraBackground)'} borderRadius={'0 .25rem .25rem 0'}>
                  <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} w={'7.25rem'} py={'.75rem'} px={'1rem'} color={'white'} bg={'var(--Gray7)'} borderRadius={'.25rem 0 0 .25rem'} h={'full'}>
                    <Img w={'5rem'} h={'5rem'} p={'.5rem'} borderRadius={'50%'} src={e.avatar} />
                    <Text mt={0} fontSize={'.813rem'} wordBreak={'break-word'} lineHeight={'.875rem'} textAlign={'center'} fontWeight={'900'}>{e.nome}</Text>
                  </Flex>
                  <Box px={'1rem'} fontSize={'.925rem'} flex={'1'}>
                    <Text fontWeight={'500'}><strong style={{ fontWeight: 800 }}>Descrição:</strong> {e.descricao}</Text>
                  </Box>
                </Flex>
              )
            })}
          </Grid>
        </Box>
      )}
    </Flex>
  )
}

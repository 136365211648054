import { Divider, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BsIncognito } from 'react-icons/bs'
import { FaAngleRight, FaEye } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Main } from './styles'

interface iResumoPesquisa{
  nomePesquisa: string
  descricao: string
  dataInicio: string
  dataFim: string
  totalQuestoes: number
  totalQuestoesMultiplaEscolha: number
  totalQuestoesCaixaSelecao: number
  totalQuestoesDiscursiva: number
  totalQuestoesEscala: number
  aprovado: boolean
  anonima: boolean
}

type iLocationProps={
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const AprovacaoPesquisaInterna: React.FC = () => {
  const param = useParams<iLocationProps>()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iResumoPesquisa>()
  const [ModalIsOpen, setModalIsOpen] = useState(false)

  function getResumo(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Resumo`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function AprovarFormulario(): void {
    appApi.patch(`PesquisaInterna/${param.pesquisaId as string}/AprovarFormulario`)
      .then(() => nav(pathname.replace('Aprovacao', 'Configuracao')))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getResumo()
  }, [])
  return (
        <Body>
            <ModalDelete
                isOpen={ModalIsOpen}
                message={'Após aprovar este formulário, você não poderá mais editar as questões. Deseja continuar?'}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={AprovarFormulario}
                center
            />
            <WhiteContainer>
                <Flex justifyContent={'space-between'} alignItems={'end'}>
                    <Flex flexDir={'column'}>
                        <h1>Aprove o formulário</h1>
                        <Text
                            fontWeight={'bold'}
                        >Após aprovar o formulário, você não poderá mais editá-lo</Text>
                    </Flex>

                    {/* <Flex>
                        <ButtonCmp leftIcon={<FaFilePdf />} VarColor='Green2'>Exportar questões</ButtonCmp>
                        <ButtonCmp marginLeft={'1rem'} VarColor='Green2'>Pré-visualizar formulário</ButtonCmp>
                    </Flex> */}
                </Flex>

                <Main>
                    {Model?.anonima && <Flex as={'h4'} fontSize={'.875rem'} fontWeight={800} alignItems={'center'} gap={'.325rem'} pos={'absolute'} right={'1rem'} top={'.5rem'} color={'var(--Azul)'}>Pesquisa anônima <BsIncognito style={{ marginTop: '.125rem' }} size={19}/></Flex>}
                    <div>
                        <h3>Resumo da pesquisa</h3>
                        <span>Nome: <strong>{Model?.nomePesquisa}</strong></span>
                        <span>Descrição: <strong>{Model?.descricao}</strong></span>
                        <span>Data de início: <strong>{Model?.dataInicio ? Model.dataInicio : 'Data não definida'}</strong></span>
                        <span>Data de fim: <strong>{Model?.dataFim ? Model.dataFim : 'Data não definida'}</strong></span>
                    </div>
                    <Divider orientation='vertical' height={'8rem'}/>
                    <div>
                        <span>Questões: <strong>{Model?.totalQuestoes}</strong></span>
                        <span>Questões de múltipla escolha: <strong>{Model?.totalQuestoesMultiplaEscolha}</strong></span>
                        <span>Questões de caixa de seleção: <strong>{Model?.totalQuestoesCaixaSelecao}</strong></span>
                        <span>Questões discursivas: <strong>{Model?.totalQuestoesDiscursiva}</strong></span>
                        <span>Questões de escala: <strong>{Model?.totalQuestoesEscala}</strong></span>
                    </div>
                </Main>

                <Flex justifyContent={'end'} gap={'.5rem'}>

                    <ButtonCmp onClick={() => nav(-1)} VarColor='c6'>Voltar</ButtonCmp>
                    <ButtonCmp VarColor='Rosa' onClick={() => nav(`/Jornada/PesquisaInterna/Gestao/${param.pesquisaId as string}/Previsualizar`)} leftIcon={<FaEye />}>Pré-visualizar formulário</ButtonCmp>
                    { (
                        <ButtonCmp
                            onClick={() => setModalIsOpen(true)}
                            rightIcon={<FaAngleRight/>}
                            VarColor='Green2'
                        >{!Model?.aprovado ? 'Aprovar formulário' : 'Continuar'}</ButtonCmp>
                    )}
                </Flex>
            </WhiteContainer>
        </Body>
  )
}

import styled from 'styled-components'

interface MetaProps {
  fontsize: string
  padding: string
  heigth: string
  color: string
  margin?: string
}

export const Content = styled.a<MetaProps>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 color: var(--${props => props.color});
 background:none;
 padding: var(--${props => props.padding});
 font-size:var(--${props => props.fontsize});
 height:var(--${props => props.heigth});
 line-height: 1px;
 border: 2px solid var(--${props => props.color});
 margin: ${props => props.margin};

 &:hover{
   transition:var(--transitionBtns);
   background:var(--${props => props.color});
   color: white;
 }
`

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Body } from '../../Layouts/Body'
import { Main } from './styles'
import { useState, useEffect } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Flex, FormControl, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { EditorTexto } from '../../../components/EditorTexto/Index'
import { PropInvalida } from '../../../Utils/Helper'
import { ButtonTag } from './Components/ButtonTag'
import { ModalTag } from './Components/ModalTag'
import { FindUser } from '../../../components/FindBox'
import { iNomeId } from '../../../../src/interfaces'
import { BoxMultiText } from './Components/BoxMultiText'
import { FaSave } from 'react-icons/fa'
import { iPDIPermissoes } from '../../PDI/Criar'
import { ModalAviso } from './Components/ModalAviso'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { InputCmp } from '../../../components/InputCmp'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import { SelectCmp } from '../../../components/SelectCmp'
import { ButtonCmp } from '../../../components/ButtonCmp'

type iParamProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
  editar: string
}

interface iModel {
  dataInicio?: string
  dataFim?: string
  encerrarAutomaticamente: boolean
  tipo: number
  ciclo?: number
  validade?: number
  baseCiclo?: number
  reenviarDias?: number[]
  pdi?: iPDIPesquisa
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
  responsaveisPrioridades?: number[]
  aparecerCompetencia?: boolean
  liberarAvaliacaoAutomatica?: boolean
  pesosAvaliadores?: iPesosAvaliadores[]
  permitirAlterarPeso?: boolean
  nenhumaAvaliacaoAberta: boolean
  responsavelId?: string
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export interface iPDIPesquisa {
  dias: number
  destinatarioEmail?: number
  notificacaoCadastro: number[]
  responsavelId?: string
  notificadoId?: string
  textoCriacao: string
  pdiGerado?: boolean
}

export interface iPesosAvaliadores {
  tipo: number
  peso: number
}

export const EnviarPesquisa: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const path = useLocation().pathname
  const { pesquisaId } = useParams<iParamProps>()
  const isEditar = path.includes('Editar')
  const isIndicativa = path.includes('Indicativa')
  const [PermissoesPDI, setPermissoesPDI] = useState<iPDIPermissoes>({ colaboradorFazerProprioPDI: false, gestorAtribuirPDI: false })

  const textPadraoEncerramento = '<p>Obrigado pela participação!</p>'
  const textPadraoAbertura = `<p>Bem-vindo a pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'percepção'} do [NOME DO AVALIADO].</p><br/> <p>A pesquisa é rápida e fácil, mas seja sincero e atento para o melhor desenvolvimento do avaliado.</p><br/> <p>Contamos com sua participação!</p>`
  const textPadraoEmail = `<p>Olá, [NOME DO RESPONDENTE]!</p><p>Você está recebendo o convite para o preenchimento da pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'perpepção'} do(a) [NOME DO AVALIADO].</p><p>A pesquisa é simples e rápida.</p><p>O prazo para preenchimento é até o dia [DATA FIM]</p><p>[Link]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar o responsável.</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const textPadraoPDI = '<p>Olá, [NOME DO RESPONSAVEL]!</p><p>A pesquisa do(a) [NOME DO AVALIADO] foi concluída e você já pode iniciar o cadastro do PDI para o desenvolvimento dele!</p><p>É muito simples, basta acessa o link com os resultados do avaliado e Clicar no botão Adicionar PDI, criar as ações especificando o que, por que e como ele deve trabalhar cada uma das competências definidas.</p><p>Você tem até o dia [DATA FIM PDI] para cadastrar o PDI.</p><p>Link dos resultados da pesquisa do(a) [NOME DO AVALIADO]:</p><p>[LINK]</p><p>Se tiver qualquer dúvida pode entrar em contato com nosso RH.</p><p>Atenciosamente,</p><p>Equipe [NOME DA EMPRESA]</p>'

  const pdiDefault: iPDIPesquisa = {
    dias: 1,
    destinatarioEmail: PermissoesPDI.gestorAtribuirPDI ? 0 : 2,
    notificacaoCadastro: [],
    textoCriacao: textPadraoPDI,
    pdiGerado: false
  }

  const [isLoading, setIsLoading] = useState(true)
  const [Model, setModel] = useState<iModel>({
    encerrarAutomaticamente: false,
    reenviarDias: [],
    tipo: 0,
    textosAbertura: [{ texto: textPadraoAbertura, participantes: [0, 1, 2, 3, 4, 6] }],
    textosEncerramento: [{ texto: textPadraoEncerramento, participantes: [0, 1, 2, 3, 4, 6] }],
    textosConvite: [{ texto: textPadraoEmail, participantes: [0, 1, 2, 3, 4, 6] }],
    pdi: {
      dias: 1,
      destinatarioEmail: PermissoesPDI.gestorAtribuirPDI ? 0 : 2,
      notificacaoCadastro: [0],
      textoCriacao: textPadraoPDI,
      pdiGerado: false
    },
    pesosAvaliadores: [{ tipo: 6, peso: 1 }, { tipo: 4, peso: 1 }, { tipo: 3, peso: 1 }, { tipo: 2, peso: 1 }, { tipo: 1, peso: 1 }],
    permitirAlterarPeso: false,
    aparecerCompetencia: false,
    responsaveisPrioridades: [],
    liberarAvaliacaoAutomatica: false,
    nenhumaAvaliacaoAberta: false,
    ciclo: 0
  })

  const [ConfirmIsLoading, setConfirmIsLoading] = useState(false)
  const [ConfirmIsLoadingReturn, setConfirmIsLoadingReturn] = useState(false)

  const [DataInicioIsInvalid, setDataInicioIsInvalid] = useState(false)
  const [DataFimIsInvalid, setDataFimIsInvalid] = useState(false)
  const [ValidadeIsInvalid, setValidadeIsInvalid] = useState(false)
  const [CicloIsInvalid, setCicloIsInvalid] = useState(false)
  const [EnviarPdi, setEnviarPdi] = useState(false)
  const [ReenviarPesquisa, setReenviarPesquisa] = useState(false)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [OutroPDI, setOutroPDI] = useState(false)
  const [ModalAvisoIsOpen, setModalAvisoIsOpen] = useState(false)
  const [PesquisaNaoIniciada, setPesquisaNaoIniciada] = useState<boolean>(false)
  function EnviarPesquisa(): void {
    setDataInicioIsInvalid(false)
    setDataFimIsInvalid(false)
    setValidadeIsInvalid(false)
    setCicloIsInvalid(false)

    if (new Date(Model.dataFim ?? '') < new Date(Model.dataInicio ?? '')) {
      toast({
        title: 'A data de fim deve ser posterior a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.validade)) {
      setValidadeIsInvalid(true)
      toast({
        title: 'Preencha a validade',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.baseCiclo)) {
      toast({
        title: 'Escolha uma base para definir o ciclo da pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if ((Model.tipo === 0 && (Model.dataInicio === null || Model.dataInicio === undefined || Model.dataInicio === '')) || (Model.tipo === 1 && Model.baseCiclo === 1 && PropInvalida(Model.dataInicio))) {
      setDataInicioIsInvalid(true)
      toast({
        title: 'Preencha a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 0 && (Model.dataFim === null || Model.dataFim === undefined || Model.dataFim === '')) {
      setDataFimIsInvalid(true)
      toast({
        title: 'Preencha a data de fim',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }
    setConfirmIsLoading(true)

    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      pesosAvaliadores: Model.pesosAvaliadores,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : null,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.tipo === 1 && PropInvalida(Model.ciclo) ? 0 : Model.ciclo,
      validade: Model.validade
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Enviar`, form)
      .then(() => navigate(`${path.replace('EnviarPesquisa', 'ResumoPesquisa')}`))
      .catch(() => {
        setConfirmIsLoading(false)
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function onChangeTipo(value: string): void {
    if (value === '0') {
      setModel({
        ...Model,
        tipo: parseInt(value),
        baseCiclo: undefined,
        ciclo: undefined,
        validade: undefined
      })
    } else if (value === '1') {
      setModel({
        ...Model,
        encerrarAutomaticamente: false,
        tipo: parseInt(value),
        dataFim: undefined
      })
    }
  }

  function UpdateTexto(tipo: number): void {
    if (tipo === 0) {
      appApi.put(`PesquisaPercepcao/TextoEmail/${pesquisaId as string}`, Model.textosConvite)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 1) {
      appApi.put(`PesquisaPercepcao/TextoAbertura/${pesquisaId as string}`, Model.textosAbertura)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 2) {
      appApi.put(`PesquisaPercepcao/TextoEncerramento/${pesquisaId as string}`, Model.textosEncerramento).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    } else if (tipo === 3) {
      const data = {
        texto: Model.pdi ? Model.pdi.textoCriacao : ''
      }
      appApi.put(`PesquisaPercepcao/TextoPDI/${pesquisaId as string}`, data).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    }
  }

  function UpdateDestinatarioPDI(value: string): void {
    setModel((prevModel) => ({
      ...prevModel,
      pdi: {
        ...prevModel.pdi ?? pdiDefault,
        destinatarioEmail: parseInt(value),
        responsavelId: value !== '3' ? undefined : prevModel.pdi?.responsavelId
      }
    }))
    if (value === '3') {
      setOutroPDI(true)
      return
    }
    setOutroPDI(false)
  }

  function handleNotificacaoCadastroChange(value: number): void {
    const copy = [...Model.pdi?.notificacaoCadastro ?? pdiDefault.notificacaoCadastro]

    if (copy.some(e => e === value)) {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: copy.filter(e => e !== value),
          notificadoId: undefined
        }
      })
    } else {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: [...copy, value]
        }
      })
    }
  }

  function SelecionarEnvioDias(value: string): void {
    if (Model.reenviarDias === null) {
      const copy = []
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else if (Model.reenviarDias?.find(e => e.toString() === value) === undefined) {
      const copy = [...Model.reenviarDias ?? []]
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else {
      const copy = [...Model.reenviarDias ?? []].filter(e => e.toString() !== value)
      setModel({ ...Model, reenviarDias: copy })
    }
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId as string}/Configuracao`)
      .then(response => {
        const data: iModel = response.data
        setModel({ ...data, responsaveisPrioridades: data.responsaveisPrioridades ? data.responsaveisPrioridades : [] })
        const DataString = `${data?.dataInicio ?? ''}`
        setPesquisaNaoIniciada(Date.parse(DataString) > new Date().getTime())
        if (!PropInvalida(data.pdi) && !data.pdi?.pdiGerado) {
          setEnviarPdi(true)
        }
        if (data.reenviarDias !== null) {
          setReenviarPesquisa(true)
        }
        if (data.pdi?.destinatarioEmail === 3) {
          setOutroPDI(true)
        }
        setIsLoading(false)
      }).catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function getPermissoesPDI(): void {
    appApi.get('PDI/Permissoes?outsidePdiPage=true')
      .then(res => setPermissoesPDI(res.data))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoPesquisaEtapa(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    const splRoute = path.split('/')
    appApi.put(`PesquisaPercepcaoEtapa/Pesquisa/${pesquisaId as string}`, form)
      .then(() => {
        if (path.includes('Trilha')) {
          navigate(`/Jornada/Configuracao/Trilha/${splRoute[splRoute.length - 1]}`)
        } else if (path.includes('Processo')) {
          navigate(`/Rh/VisualizarPerfil/${splRoute[splRoute.length - 1]}`)
        }
      })
      .catch(err => console.log(err))
  }

  function ReenviarPesquisaEtapaColaborador(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    appApi.put(`PesquisaPercepcaoEtapaColaborador/${pesquisaId as string}/Reenviar`, form)
      .then(() => {
        navigate(-1)
      })
      .catch(err => console.log(err))
  }

  // function ReenviarNaoRespondidos(): void {
  //   setConfirmIsLoading(true)
  //   const form = {
  //     dataInicio: Model.dataInicio,
  //     dataFim: Model.dataFim,
  //     encerrarAutomaticamente: Model.encerrarAutomaticamente,
  //     pdi: EnviarPdi ? Model.pdi : null,
  //     reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null,
  //     responsavelId: isIndicativa ? Model.responsavelId : undefined,
  //     responsaveisPrioridades: isIndicativa ? Model.responsaveisPrioridades : undefined,
  //     aparecerCompetencia: isIndicativa ? Model.aparecerCompetencia : undefined,
  //     liberarAvaliacaoAutomatica: isIndicativa ? Model.liberarAvaliacaoAutomatica : undefined,
  //     pesosAvaliadores: isIndicativa ? Model.pesosAvaliadores : undefined,
  //     permitirAlterarPeso: isIndicativa ? Model.permitirAlterarPeso : undefined
  //   }
  //   appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Reenviar`, form).then(response => {
  //     if (response.status === 200) {
  //       navigate(-1)
  //     } else {
  //       alert('Erro')
  //     }
  //   }).catch(err => {
  //     console.log(err)
  //     setConfirmIsLoading(false)
  //   })
  // }

  function SalvarAlteracoes(reenviar?: boolean, manterPagina?: boolean): void {
    if (new Date(Model?.dataFim ?? '') < new Date(Model?.dataInicio ?? '')) {
      toast({
        title: 'A data de fim deve ser posterior a data de início da pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }
    if (reenviar) {
      setConfirmIsLoading(true)
    } else {
      setConfirmIsLoadingReturn(true)
    }
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : null,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.ciclo,
      validade: Model.validade,
      responsavelId: isIndicativa ? Model.responsavelId : undefined,
      responsaveisPrioridades: isIndicativa ? Model.responsaveisPrioridades : undefined,
      aparecerCompetencia: isIndicativa ? Model.aparecerCompetencia : undefined,
      liberarAvaliacaoAutomatica: isIndicativa ? Model.liberarAvaliacaoAutomatica : undefined,
      pesosAvaliadores: Model.pesosAvaliadores,
      permitirAlterarPeso: isIndicativa ? Model.permitirAlterarPeso : undefined
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Salvar`, form)
      .then(() => {
        if (reenviar) {
          appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Reenviar`).then(response => {
            if (response.status === 200) {
              if (Model.nenhumaAvaliacaoAberta) {
                toast({
                  title: 'Pesquisa atualizada com sucesso',
                  status: 'success',
                  isClosable: false,
                  position: 'top-right'
                })
                setModalAvisoIsOpen(true)
                setConfirmIsLoading(false)
                return
              }
              toast({
                title: 'Pesquisa atualizada e convites reenviados com sucesso',
                status: 'success',
                isClosable: false,
                position: 'top-right'
              })
              navigate(-1)
            } else {
              alert('Erro')
            }
          }).catch(err => {
            console.log(err)
            setConfirmIsLoading(false)
          })
        } else {
          toast({
            title: 'Pesquisa atualizada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
          if (!manterPagina) {
            navigate(path.includes('AnaliseDesempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao')
          }
          setConfirmIsLoadingReturn(false)
        }
      })
      .catch(() => {
        setConfirmIsLoadingReturn(false)
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function FluxoPDI(): any {
    if (path.includes('Percepcao')) return
    return (
      <Flex flexDir={'column'} gap={'.325rem'}>
        <ModalAviso
          isOpen={ModalAvisoIsOpen}
          onRedirect={() => navigate(`${path.replace('EnviarPesquisa', 'ResultadoPesquisa').replace('/Editar', '')}?modalAlerta=true`)}
          onRequestClose={() => setModalAvisoIsOpen(false)}
        />
        <Flex direction={'column'}>
          <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'}>
            Adicionar fluxo de PDI após a pesquisa encerrar?</Text>
          <RadioGroup value={EnviarPdi ? '1' : '2'} defaultValue='1' size={'sm'} mb={'.25rem'} onChange={() => { setEnviarPdi(!EnviarPdi); if (EnviarPdi) { setModel({ ...Model, pdi: undefined }); setOutroPDI(false) } }}>
            <Stack spacing={3} direction='row'>
              <Radio value='1'>
                <Text fontSize={'.875rem'} ml={'-.125rem'}>Sim</Text>
              </Radio>
              <Radio value='2'>
              <Text fontSize={'.875rem'} ml={'-.125rem'}>Não</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        {(EnviarPdi) &&
          <Flex gap={'.5rem'}>
            <Flex flexDir={'column'} gap={'.25rem'}>
              <Text fontSize={'.875rem'} fontWeight={'700'}>Tempo para preencher PDI após pesquisa encerrada</Text>
              <FormControl alignItems={'center'} as={Flex} gap={'.5rem'}>
                <InputCmp
                  type={'number'}
                  width={'5rem'}
                  min={1}
                  value={Model?.pdi?.dias ?? 1}
                  OnChange={(e) => setModel((prevState) => ({
                    ...prevState,
                    pdi: {
                      ...prevState.pdi ?? pdiDefault,
                      dias: parseInt(e)
                    }
                  }))}
                />
                <Text fontSize={'.875rem'}>Dias</Text>
              </FormControl>
            </Flex>

            <Box h={'100%'} my={'auto'} bg={'var(--c5)'} w={'.125rem'} mx={'.25rem'}></Box>

            <Flex flexDir={'column'} gap={'.25rem'} justifyContent={'space-between'}>
              <Text fontSize={'.875rem'} fontWeight={'700'}>
                Quem irá inserir o PDI do avaliado?</Text>
              <RadioGroup size={'sm'} value={Model?.pdi?.destinatarioEmail?.toString()} defaultValue='1'
                onChange={UpdateDestinatarioPDI}>
                <Stack spacing={3} direction='row'>
                  {PermissoesPDI.gestorAtribuirPDI && <Radio value='0'><Text fontSize={'.875rem'} ml={'-.125rem'}>Gestor</Text></Radio>}
                  {!PermissoesPDI.gestorAtribuirPDI && <ElementoTooltipCmp label='Caso queira designar o gestor para adicionar o PDI para seus subordinados, modifique nas configurações da empresa' bg={'var(--Azul)'}><Flex alignItems={'center'} opacity={'0.5'}><Radio disabled value={Model.pdi?.destinatarioEmail}><Text fontSize={'.875rem'} ml={'-.125rem'}>Gestor</Text></Radio></Flex></ElementoTooltipCmp>}
                  {PermissoesPDI.colaboradorFazerProprioPDI && <Radio value='1'><Text fontSize={'.875rem'} ml={'-.125rem'}>Colaborador</Text></Radio>}
                  {!PermissoesPDI.colaboradorFazerProprioPDI && <ElementoTooltipCmp label='Caso queira designar o colaborador para adicionar o seu próprio PDI, modifique nas configurações da empresa' bg={'var(--Azul)'}><Flex alignItems={'center'} opacity={'0.5'}><Radio disabled value={Model.pdi?.destinatarioEmail}><Text fontSize={'.875rem'} ml={'-.125rem'}>Colaborador</Text></Radio></Flex></ElementoTooltipCmp>}
                  <Radio value='2'><Text fontSize={'.875rem'} ml={'-.125rem'}>RH</Text></Radio>
                  <Radio value='3'><Text fontSize={'.875rem'} ml={'-.125rem'}>Outro</Text></Radio>
                  <ElementoTooltipCmp label={!OutroPDI ? 'Selecione a opção "Outro" para adicionar um usuário' : ''}>
                    <Flex flexDir={'column'} pointerEvents={!OutroPDI ? 'none' : 'auto'} opacity={!OutroPDI ? 0.5 : 1}>
                      <FindUser
                        lista={Colaboradores}
                        onChoice={(e) => setModel((prevState) => ({
                          ...prevState,
                          pdi: {
                            ...prevState.pdi ?? pdiDefault,
                            responsavelId: e
                          }
                        }))}
                        minWInput={'11rem'}
                        placeholder='Digite o nome do participante'
                        selecionado={Model.pdi?.responsavelId}
                      />
                    </Flex>
                  </ElementoTooltipCmp>
                </Stack>
              </RadioGroup>
              {((!PermissoesPDI.colaboradorFazerProprioPDI && Model?.pdi?.destinatarioEmail === 1) || (!PermissoesPDI.gestorAtribuirPDI && Model?.pdi?.destinatarioEmail === 0)) && <Text color={'var(--Red)'} mt={'.5rem'}>Cuidado! O responsável por inserir o PDI do avaliado não terá permissão de criá-lo, mude as configurações da empresa ou o responsável por inserir o PDI</Text>}
            </Flex>

            <Box h={'100%'} my={'auto'} bg={'var(--c5)'} w={'.125rem'} mx={'.25rem'} maxW={'.125rem'}></Box>

            <Flex flexDir={'column'} gap={'.25rem'}>
              <Text fontSize={'.875rem'} fontWeight={'700'}>
                Quem deve receber notificações de cadastro de PDI em atraso?</Text>
              <Stack spacing={3} direction='row'>
                <CheckboxCmp
                  isChecked={Model.pdi?.notificacaoCadastro.includes(0)}
                  OnChange={() => handleNotificacaoCadastroChange(0)}
                >
                  Gestor
                </CheckboxCmp>
                <CheckboxCmp
                  isChecked={Model.pdi?.notificacaoCadastro.includes(2)}
                  OnChange={() => handleNotificacaoCadastroChange(2)}
                >
                  RH
                </CheckboxCmp>
                <CheckboxCmp
                  isChecked={Model.pdi?.notificacaoCadastro.includes(3)}
                  OnChange={() => handleNotificacaoCadastroChange(3)}
                >
                  Outro:
                </CheckboxCmp>
                <ElementoTooltipCmp label={!Model?.pdi?.notificacaoCadastro.includes(3) ? 'Selecione a opção "Outro" para adicionar um usuário' : ''}>
                  <Flex flexDir={'column'} pointerEvents={!Model?.pdi?.notificacaoCadastro.includes(3) ? 'none' : 'auto'} opacity={!Model?.pdi?.notificacaoCadastro.includes(3) ? 0.5 : 1}>
                    <FindUser
                      lista={Colaboradores}
                      onChoice={(e) => setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi ?? pdiDefault,
                          notificadoId: e
                        }
                      }))}
                      minWInput={'11rem'}
                      placeholder='Digite o nome do participante'
                      selecionado={Model?.pdi?.notificadoId}
                    />
                  </Flex>
                </ElementoTooltipCmp>
              </Stack>
            </Flex>
          </Flex>
        }
      </Flex>
    )
  }

  function FluxoReenvio(): any {
    return (
      <Flex direction={'column'} pos={'relative'}>
        <FormControl as={Flex} gap={'.5rem'}>
          <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.5rem'}>Reenviar convites para quem não respondeu</Text>
          <RadioGroup size={'sm'} value={ReenviarPesquisa ? '1' : '2'} defaultValue='1' onChange={() => { if (ReenviarPesquisa) { setModel({ ...Model, reenviarDias: [] }) }; setReenviarPesquisa(!ReenviarPesquisa) }}>
            <Stack spacing={3} direction='row'>
              <Radio value='1'>
                <Text fontSize={'.875rem'} ml={'-.125rem'}>Sim</Text>
              </Radio>
              <Radio value='2'>
              <Text fontSize={'.875rem'} ml={'-.125rem'}>Não</Text>
              </Radio>
            </Stack>
          </RadioGroup>

        </FormControl>
        <Flex flexDir={'column'} pos={isEditar ? 'initial' : 'absolute'} top={'1.5rem'} gap={'.5rem'} pointerEvents={!ReenviarPesquisa ? 'none' : 'auto'} opacity={!ReenviarPesquisa ? 0.5 : 1}>
          <Stack direction={'row'} gap='.5rem' wordBreak={'keep-all'} w={'100%'}>
            <CheckboxCmp
              minW='190px'
              fontSize={'.75rem'}
              isChecked={Model?.reenviarDias?.includes(5)}
              OnChange={() => { SelecionarEnvioDias('5') }
              }
            >
              5 dias antes de encerrar a pesquisa
            </CheckboxCmp>

            <CheckboxCmp
              minW='190px'
              fontSize={'.75rem'}
              isChecked={Model?.reenviarDias?.includes(3)}
              OnChange={() => { SelecionarEnvioDias('3') }
              }
            >
              3 dias antes de encerrar a pesquisa
            </CheckboxCmp>
          </Stack>
          <Stack direction={'row'} gap='.5rem' wordBreak={'keep-all'} w={'100%'}>
            <CheckboxCmp
              minW='190px'
              fontSize={'.75rem'}
              isChecked={Model?.reenviarDias?.includes(2)}
              OnChange={() => { SelecionarEnvioDias('2') }
              }
            >
              2 dias antes de encerrar a pesquisa
            </CheckboxCmp>

            <CheckboxCmp
              minW='190px'
              fontSize={'.75rem'}
              isChecked={Model?.reenviarDias?.includes(1)}
              OnChange={() => { SelecionarEnvioDias('1') }
              }
            >
              1 dia antes de encerrar a pesquisa
            </CheckboxCmp>
          </Stack>
        </Flex>
      </Flex>
    )
  }

  function EncerrarAutomaticamente(): any {
    return (
      <Flex alignItems={'center'} >
        <CheckboxCmp
          isChecked={Model.encerrarAutomaticamente}
          OnChange={() => setModel({ ...Model, encerrarAutomaticamente: !Model.encerrarAutomaticamente })}
        />
        <small style={{ fontSize: '.75rem' }}>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
      </Flex>
    )
  }

  function AtualizarCiclica(): void {
    const form = {
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      pdi: EnviarPdi ? Model.pdi : null,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId ?? ''}/Ciclica`, form)
      .then(() => {
        toast({
          title: 'Configuração atualizada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function handleCheckboxCmpPrioridadeClick(value: number): void {
    if (Model.responsaveisPrioridades?.find(e => e === value) !== undefined) {
      const copy = [...Model.responsaveisPrioridades].filter(e => e !== value)
      setModel({ ...Model, responsaveisPrioridades: copy, responsavelId: undefined })
    } else if (Model.responsaveisPrioridades) {
      const copy = [...Model.responsaveisPrioridades]
      copy.push(value)
      setModel({ ...Model, responsaveisPrioridades: copy })
    }
  }

  function AtualizarPeso(tipoAvaliado: number, peso: number): void {
    setModel({
      ...Model,
      pesosAvaliadores: [...Model?.pesosAvaliadores?.filter(p => p.tipo !== tipoAvaliado) ?? [], { tipo: tipoAvaliado, peso: peso }]
    })
  }

  useEffect(() => {
    getColaboradores()
    getPesquisa()
    getPermissoesPDI()
  }, [])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />
      <Main>
        <h1 style={{ marginBottom: '1rem' }}>Enviar pesquisa</h1>
        {(!PropInvalida(Model.ciclo) && isEditar) && (
          <WhiteContainer>
            <Flex flexDir={'column'} gap='.5rem'>
              {EncerrarAutomaticamente()}
              {FluxoReenvio()}
              {FluxoPDI()}
              <Flex justifyContent={'end'}>
                <ButtonCmp
                  rightIcon={<FaSave />}
                  VarColor='Green2'
                  onClick={AtualizarCiclica}
                >Salvar</ButtonCmp>
              </Flex>
            </Flex>
          </WhiteContainer>
        )}

        {(!path.includes('Trilha') && !path.includes('Processo') && PropInvalida(Model.ciclo) && isEditar) && (
          <WhiteContainer>
            <Flex flexDir={'column'} gap={'1rem' }>
              <Flex flexDir={'column'}>
                <h2>Configuração da pesquisa</h2>

                <FormControl alignItems={'center'} margin={'1rem 0'} as={Flex}>
                  <Text w={'8.5rem'} fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'} marginRight={'.325rem'} minW={'6.1rem'}>Início da pesquisa</Text>
                  <InputCmp
                    width={'100%'}
                    type={'datetime-local'}
                    min={new Date().toISOString().slice(0, -8)}
                    value={Model?.dataInicio ?? ''}
                    max={Model?.dataFim ? new Date(Model?.dataFim).toISOString().slice(0, -8) : undefined}
                    OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                    borderColor='var(--Gray4)'
                    isInvalid={DataInicioIsInvalid}
                  />
                </FormControl>

                <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                  <Text w={'8.5rem'} fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'} marginRight={'.325rem'} minW={'6.1rem'}>Final da pesquisa</Text>
                  <InputCmp
                    width={'100%'}
                    type={'datetime-local'}
                    min={Model?.dataInicio ? new Date(Model?.dataInicio).toISOString().slice(0, -8) : new Date().toISOString().slice(0, -8)}
                    max={'2099-12-31T23:59'}
                    value={Model?.dataFim ?? ''}
                    OnChange={(e) => setModel({ ...Model, dataFim: e })}
                    borderColor='var(--Gray4)'
                    isInvalid={DataFimIsInvalid}
                  />

                </FormControl>

                {EncerrarAutomaticamente()}

                {path.includes('Indicativa') && (
                  <>
                    <h4 style={{ marginTop: '1rem', fontWeight: 700 }}>Quem irá definir as prioridades a serem trabalhadas?</h4>
                    <Stack mt={'.5rem'} direction={'row'} gap={'.625rem'} pb={'1rem'} borderBottom={'2px solid var(--c2)'}>
                      <CheckboxCmp
                        isChecked={Model?.responsaveisPrioridades?.includes(0)}
                        OnChange={() => handleCheckboxCmpPrioridadeClick(0)}
                      >Automático</CheckboxCmp>
                      <CheckboxCmp
                        isChecked={Model?.responsaveisPrioridades?.includes(1)}
                        OnChange={() => handleCheckboxCmpPrioridadeClick(1)}
                      >Gestor</CheckboxCmp>
                      <CheckboxCmp
                        isChecked={Model?.responsaveisPrioridades?.includes(2)}
                        OnChange={() => handleCheckboxCmpPrioridadeClick(2)}
                      >Administrador</CheckboxCmp>
                      <Flex gap={'.7rem'}>
                        <CheckboxCmp

                          isChecked={Model?.responsaveisPrioridades?.includes(3)}
                          OnChange={() => handleCheckboxCmpPrioridadeClick(3)}
                        >
                          Outro:
                        </CheckboxCmp>

                        {Model?.responsaveisPrioridades?.includes(3) &&
                          <Flex flexDir={'column'}>
                            <FindUser
                              lista={Colaboradores}
                              onChoice={(e) => setModel((prevState) => ({
                                ...prevState,
                                responsavelId: e
                              }))}
                              placeholder='Digite o nome do participante'
                              selecionado={Model.responsavelId}
                            />
                          </Flex>
                        }
                      </Flex>
                    </Stack>

                    <Flex gap={'.625rem'} direction='column' mt={'1rem'}>
                      <Flex direction='column' gap={'1rem'} mb={'.75rem'}>
                        <CheckboxCmp
                          isChecked={Model?.aparecerCompetencia}
                          OnChange={() => setModel({ ...Model, aparecerCompetencia: !Model?.aparecerCompetencia })}
                        >Aparecer o nome da competência na hora de responder a pesquisa</CheckboxCmp>
                        <CheckboxCmp
                          isChecked={Model?.liberarAvaliacaoAutomatica}
                          OnChange={() => setModel({ ...Model, liberarAvaliacaoAutomatica: !Model?.liberarAvaliacaoAutomatica })}
                        >Liberar avaliação completa usando priorização automática</CheckboxCmp>
                      </Flex>
                      <Text fontWeight={'700'} fontSize={'.875rem'} mb={'.25rem'}>Definição do peso da resposta por tipo de avaliador</Text>
                      <Flex gap={'1.25rem'} mb={'.25rem'}>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                          <Text>Gestor</Text>
                          <SelectCmp
                            value={Model.pesosAvaliadores?.find(e => e.tipo === 1)?.peso.toString()}
                            my={'.5rem'}
                            OnChange={(e) => AtualizarPeso(1, Number(e))}
                          >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </SelectCmp>
                        </Flex>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                          <Text>Pares</Text>
                          <SelectCmp
                            my={'.5rem'}
                            OnChange={(e) => AtualizarPeso(2, Number(e))}
                            value={Model.pesosAvaliadores?.find(e => e.tipo === 2)?.peso.toString()}
                          >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </SelectCmp>
                        </Flex>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                          <Text>Liderados</Text>
                          <SelectCmp
                            my={'.5rem'}
                            OnChange={(e) => AtualizarPeso(3, Number(e))}
                            value={Model.pesosAvaliadores?.find(e => e.tipo === 3)?.peso.toString()}
                          >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </SelectCmp>
                        </Flex>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                          <Text>Outros</Text>
                          <SelectCmp
                            my={'.5rem'}
                            OnChange={(e) => AtualizarPeso(4, Number(e))}
                            value={Model.pesosAvaliadores?.find(e => e.tipo === 4)?.peso.toString()}
                          >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </SelectCmp>
                        </Flex>
                        <Flex gap={'.5rem'} alignItems={'center'}>
                          <Text style={{ whiteSpace: 'nowrap' }}>Alta liderança</Text>
                          <SelectCmp
                            my={'.5rem'}
                            OnChange={(e) => AtualizarPeso(6, Number(e))}
                            value={Model.pesosAvaliadores?.find(e => e.tipo === 6)?.peso.toString()}
                          >
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                          </SelectCmp>
                        </Flex>
                      </Flex>
                      <CheckboxCmp
                        isChecked={Model?.permitirAlterarPeso}
                        OnChange={() => setModel({ ...Model, permitirAlterarPeso: !Model?.permitirAlterarPeso })}
                      >Permitir mudança de pesos na hora da priorização das competências</CheckboxCmp>
                    </Flex>
                  </>
                )}
                {!path.includes('Indicativa') && <Box mt={'1.5rem'}>
                  <Text fontWeight={'700'} mb={'.25rem'}>Definição do peso da resposta por tipo de avaliador</Text>
                    <Flex gap={'1.25rem'}>
                      <Flex gap={'.5rem'} alignItems={'center'}>
                        <Text>Gestor</Text>
                        <SelectCmp
                          value={Model.pesosAvaliadores?.find(e => e.tipo === 1)?.peso.toString()}
                          my={'.5rem'}
                          OnChange={(e) => AtualizarPeso(1, Number(e))}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.5rem'} alignItems={'center'}>
                        <Text>Pares</Text>
                        <SelectCmp
                          my={'.5rem'}
                          OnChange={(e) => AtualizarPeso(2, Number(e))}
                          value={Model.pesosAvaliadores?.find(e => e.tipo === 2)?.peso.toString()}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.5rem'} alignItems={'center'}>
                        <Text>Liderados</Text>
                        <SelectCmp
                          my={'.5rem'}
                          OnChange={(e) => AtualizarPeso(3, Number(e))}
                          value={Model.pesosAvaliadores?.find(e => e.tipo === 3)?.peso.toString()}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.5rem'} alignItems={'center'}>
                        <Text>Outros</Text>
                        <SelectCmp
                          my={'.5rem'}
                          OnChange={(e) => AtualizarPeso(4, Number(e))}
                          value={Model.pesosAvaliadores?.find(e => e.tipo === 4)?.peso.toString()}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                      <Flex gap={'.5rem'} alignItems={'center'}>
                        <Text style={{ whiteSpace: 'nowrap' }}>Alta liderança</Text>
                        <SelectCmp
                          my={'.5rem'}
                          OnChange={(e) => AtualizarPeso(6, Number(e))}
                          value={Model.pesosAvaliadores?.find(e => e.tipo === 6)?.peso.toString()}
                        >
                          <option value='1'>1</option>
                          <option value='2'>2</option>
                          <option value='3'>3</option>
                        </SelectCmp>
                      </Flex>
                    </Flex>
                  </Box>
                }
              </Flex>
              {FluxoReenvio()}
              {FluxoPDI()}
            </Flex>
            {(isEditar) && (
              <Flex justifyContent={'end'} mt={EnviarPdi ? '.75rem' : ''}>
                <ButtonCmp
                  VarColor='Green2'
                  onClick={() => SalvarAlteracoes(undefined, true)}
                  rightIcon={<FaSave />}
                >Salvar</ButtonCmp>
              </Flex>
            )}
          </WhiteContainer>
        )}

        {(!path.includes('Trilha') && !path.includes('Processo') && !isEditar) && (
          <WhiteContainer>
            <FormControl mb={'1rem'} as={Flex} alignItems='center'>
              <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'} marginRight={'.325rem'} minW={'6.1rem'}>Tipo da pesquisa</Text>
              <SelectCmp
                OnChange={(e) => onChangeTipo(e)}
                value={Model.tipo.toString()}
                width={'20rem'}
              >
                <option value={'0'}>Envio único</option>
                <option value={'1'}>Ciclica</option>
              </SelectCmp>
            </FormControl>

            {(Model.tipo === 0) && (
              <Flex flexDir={'column'} gap='.5rem'>
                <Flex gap={'1rem'}>
                  <Flex flexDir={'column'} gap='.5rem' w={'60%'}>
                    <FormControl alignItems={'center'} as={Flex}>
                      <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'} marginRight={'.325rem'} minW={'7.3rem'}>Início da pesquisa</Text>
                      <InputCmp
                        width={'100%'}
                        type={'datetime-local'}
                        min={new Date().toISOString().slice(0, -8)}
                        max={Model?.dataFim ? new Date(Model?.dataFim).toISOString().slice(0, -8) : undefined}
                        value={Model?.dataInicio ?? ''}
                        OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataInicioIsInvalid}
                      />
                    </FormControl>

                    <FormControl alignItems={'center'} as={Flex}>
                      <Text fontSize={'.875rem'} fontWeight={'600'} mb={'.25rem'} marginRight={'.325rem'} minW={'7.3rem'}>Final da pesquisa</Text>
                      <InputCmp
                        type={'datetime-local'}
                        width={'100%'}
                        min={Model?.dataInicio ? new Date(Model?.dataInicio).toISOString().slice(0, -8) : new Date().toISOString().slice(0, -8)}
                        value={Model?.dataFim ?? ''}
                        OnChange={(e) => setModel({ ...Model, dataFim: e })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataFimIsInvalid}
                      />
                    </FormControl>
                    {EncerrarAutomaticamente()}
                  </Flex>
                  <Box h={'90%'} mt={'-.325rem'} w={'2px'} bg={'var(--c4)'}></Box>
                  <Flex gap={'1rem'} w={'50%'}>
                    {FluxoReenvio()}
                  </Flex>
                </Flex>
                <hr style={{ border: '1px solid var(--c3)', margin: '.35rem 0 .25rem 0' }}></hr>
                {FluxoPDI()}
              </Flex>
            )}

            {(Model.tipo === 1) && (
              <Flex flexDir={'column'} gap='1rem'>
                <Flex>
                  <Flex flexDir={'column'} gap={'.875rem'} w={'55%'}>
                    <FormControl as={Flex} alignItems='center'>
                      <Text fontSize={'.875rem'} fontWeight={'600'} marginRight={'.5rem'}>Ciclo</Text>
                      <SelectCmp
                        OnChange={(e) => setModel({ ...Model, ciclo: parseInt(e) })}
                        value={Model.ciclo?.toString() ?? ''}
                        width={'full'}
                        placeholder='Selecione um ciclo'
                        isInvalid={CicloIsInvalid}
                      >
                        <option value={'0'}>Mensal</option>
                        <option value={'1'}>Trimestral</option>
                        <option value={'2'}>Semestral</option>
                        <option value={'3'}>Anual</option>
                        <option value={'4'}>Bianual</option>
                      </SelectCmp>
                    </FormControl>

                    <FormControl gap={'.5rem'} as={Flex} mb={'-.25rem'} position={'relative'} alignItems={'center'}>
                      <Text fontSize={'.75rem'} fontWeight={'600'}>Definir o ciclo baseado na data de</Text>
                      <RadioGroup
                        size={'sm'}
                        onChange={(e) => setModel({ ...Model, baseCiclo: parseInt(e) })}
                        value={Model.baseCiclo?.toString() ?? ''}
                      >
                        <Stack direction='row' alignItems={'center'}>
                          <Radio value='0' alignItems={'start'}>
                            <Text fontSize={'.813rem'} ml={'-.125rem'} alignItems={'start'}>ingresso do avaliado</Text>
                          </Radio>
                          <Flex gap={'1rem'} alignItems={'center'}>
                            <Radio value='1'>
                              <Text fontSize={'.813rem'} ml={'-.125rem'}>início da pesquisa</Text>
                            </Radio>
                            <Flex pointerEvents={!(Model.baseCiclo === 1) ? 'none' : 'auto'} opacity={!(Model.baseCiclo === 1) ? 0.5 : 1}>
                              <InputCmp
                                width={'165px'}
                                type={'datetime-local'}
                                min={new Date().toISOString().split('T')[0]}
                                max={'2099-12-31T23:59'}
                                value={Model?.dataInicio ?? ''}
                                OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                                borderColor='var(--Gray4)'
                                isInvalid={DataInicioIsInvalid}
                              />
                            </Flex>
                          </Flex>
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                    {EncerrarAutomaticamente()}
                  </Flex>

                  <Box h={'100%'} my={'auto'} bg={'var(--c5)'} w={'.125rem'} mx={'1rem'}></Box>

                  <Flex flexDir={'column'} w={'40%'} gap={'.5rem'}>
                    <FormControl alignItems='center' as={Flex}>
                      <Text fontSize={'.813rem'} w={'17rem'} fontWeight={'600'} marginRight={'.5rem'}>Tempo limite para resposta</Text>
                      <InputCmp
                        width={'100%'}
                        type={'number'}
                        placeholder={'Digite a validade'}
                        value={Model.validade?.toString() ?? ''}
                        OnChange={(e) => setModel({ ...Model, validade: parseInt(e) })}
                        isInvalid={ValidadeIsInvalid}
                      />
                    </FormControl>
                    {FluxoReenvio()}
                  </Flex>
                </Flex>
                {FluxoPDI()}
              </Flex>
            )}

            {(isEditar) && (
              <Flex justifyContent={'end'}>
                <ButtonCmp
                  VarColor='Rosa'
                  onClick={() => SalvarAlteracoes()}
                  rightIcon={<FaSave />}
                >Salvar e voltar</ButtonCmp>
              </Flex>
            )}
          </WhiteContainer>
        )}

        <Flex flexDir={'column'} gap={'.75rem'} my={'.75rem'}>
          <WhiteContainer IsDisabled={((Model.dataFim && Model.dataInicio) || Model.ciclo || Model.baseCiclo || Model.tipo === 1 || path.includes('Trilha') || path.includes('Processo')) ? '' : 'Selecione uma data de início e fim da pesquisa'}>
            <BoxMultiText
              descricao='Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa'
              onChange={(e) => setModel({ ...Model, textosConvite: e })}
              openModalTag={() => setModalTagIsOpen(true)}
              textoPadrao={textPadraoEmail}
              model={Model.textosConvite}
              titulo='Texto do convite para preenchimento da pesquisa'
              onSave={() => UpdateTexto(0)}
            />
          </WhiteContainer>

          <WhiteContainer IsDisabled={((Model.dataFim && Model.dataInicio) || Model.ciclo || Model.baseCiclo || Model.tipo === 1 || path.includes('Trilha') || path.includes('Processo')) ? '' : 'Selecione uma data de início e fim da pesquisa'}>
            <BoxMultiText
              descricao='O respondente irá visualizar este texto antes de começar a responder a pesquisa.'
              onChange={(e) => setModel({ ...Model, textosAbertura: e })}
              openModalTag={() => setModalTagIsOpen(true)}
              textoPadrao={textPadraoAbertura}
              model={Model.textosAbertura}
              titulo='Texto de abertura da pesquisa'
              onSave={() => UpdateTexto(1)}
            />
          </WhiteContainer>

          <WhiteContainer IsDisabled={((Model.dataFim && Model.dataInicio) || Model.ciclo || Model.baseCiclo || Model.tipo === 1 || path.includes('Trilha') || path.includes('Processo')) ? '' : 'Selecione uma data de início e fim da pesquisa'}>
            <BoxMultiText
              descricao='Esse texto será apresentado quando o respondente terminar de responder uma pesquisa.'
              openModalTag={() => setModalTagIsOpen(true)}
              titulo='Texto de encerramento da pesquisa'
              textoPadrao={textPadraoEncerramento}
              model={Model.textosEncerramento}
              onChange={(e) => setModel({ ...Model, textosEncerramento: e })}
              onSave={() => UpdateTexto(2)}
            />
          </WhiteContainer>

          {(EnviarPdi) && (
            <WhiteContainer IsDisabled={((Model.dataFim && Model.dataInicio) || Model.ciclo || Model.baseCiclo || Model.tipo === 1 || path.includes('Trilha') || path.includes('Processo')) ? '' : 'Selecione uma data de início e fim da pesquisa'}>
              <Flex justifyContent={'space-between'}>
                <div style={{ maxWidth: '55rem', marginBottom: '1rem' }}>
                  <h3>Texto para criação do PDI</h3>
                  <small>Esse é o texto que será enviado ao e mail do responsável por subir o PDI do avaliado</small>
                </div>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>

              <EditorTexto isLoading={isLoading} initialValue={Model.pdi?.textoCriacao ?? textPadraoPDI}
                onChange={(e: string) => setModel((prevState) => ({
                  ...prevState,
                  pdi: {
                    ...prevState.pdi ?? pdiDefault,
                    textoCriacao: e
                  }
                }))} />
              <Flex justifyContent={'space-between'} marginTop='1rem'>
                <Flex w={'100%'} gap={'1rem'} justifyContent='space-between'>
                  <ButtonCmp
                    VarColor='c6'
                    onClick={() => {
                      setIsLoading(true)
                      setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi ?? pdiDefault,
                          textoCriacao: textPadraoPDI
                        }
                      }))
                      setTimeout(() => setIsLoading(false), 1000)
                    }}
                  >Restaurar texto padrão</ButtonCmp>
                  {
                    (isEditar) && (<Flex>
                      {EnviarPdi &&
                        <ButtonCmp
                          VarColor='Green2'
                          rightIcon={<FaSave />}
                          onClick={() => UpdateTexto(3)}
                        >Salvar</ButtonCmp>}
                    </Flex>)
                  }
                </Flex>
              </Flex>
            </WhiteContainer>
          )}
        </Flex>

      </Main>
      <Flex gap={'.5rem'} justifyContent='end' mt={'.5rem'}>
        {
          (<Flex>
            <ButtonCmp VarColor='c6' onClick={() => navigate(-1)}>Voltar</ButtonCmp>
          </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !isEditar) && (
            <Flex>
              <ButtonCmp VarColor='Rosa' onClick={() => SalvarAlteracoes()}>Salvar e voltar</ButtonCmp>
            </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !isEditar) && (
            <ButtonCmp
              VarColor='Green2'
              isLoading={ConfirmIsLoading}
              onClick={EnviarPesquisa}
            >Programar disparo</ButtonCmp>
          )
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && isEditar && (Model.ciclo === null)) && (
            <ButtonCmp
              isLoading={ConfirmIsLoadingReturn}
              VarColor='Rosa'
              onClick={() => SalvarAlteracoes(false)}
            >{'Salvar e voltar'} </ButtonCmp>
          )
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !PesquisaNaoIniciada && isEditar && (Model.ciclo === null)) && (
            <ButtonCmp
              isLoading={ConfirmIsLoading}
              VarColor='Green2'
              onClick={() => SalvarAlteracoes(true)}
            >{'Salvar e reenviar convites não respondidos'} </ButtonCmp>
          )
        }

        {
          ((path.includes('Trilha') || path.includes('Processo')) && !path.includes('Processo/Editar')) && (
            <ButtonCmp onClick={UpdateConfiguracaoPesquisaEtapa} VarColor='Rosa'>Atualizar detalhes da etapa</ButtonCmp>
          )
        }

        {
          (path.includes('Processo/Editar')) && (
            <ButtonCmp onClick={ReenviarPesquisaEtapaColaborador} VarColor='Green2'>Reenviar convites não respondidos</ButtonCmp>
          )
        }
      </Flex>
    </Body>
  )
}

import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: white;
font-size: .875rem;
border-radius: 0.25rem;
height: 4.5rem;
width: 100%;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .75rem;
}
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 1.25rem;
`

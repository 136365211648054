/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import { Avatar, AvatarBadge, Flex } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { FaAngleDown, FaAngleUp, FaRegComment } from 'react-icons/fa'
import { CheckboxCmp } from '../../../../../../../components/CheckboxCmp'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'
import { iCompetenciaPDI } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { iLinhaTabela, iDiscursiva, iCorTabela } from '../../../../../../PesquisaPercepcao/ResultadoIndividual'
import { ModalComentarios } from './components/ModalComentarios'
import { StyledTd, TableDiv } from './styles'

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaConsolidado: number
  mediaSemAuto: number
}

interface iProps {
  linhas: iLinhaTabela[]
  discursivas: iDiscursiva[]
  cores: iCorTabela[]
  recomendacoes: iCompetenciaPDI[]
  temColuna: (tipo: number) => boolean
  apenasAutoAvaliacao: boolean
  mediaGeral: number
  priorizadas?: string[]
  onPriorizar?: (arr: string[]) => void
}

export const Tabela: React.FC<iProps> = ({ discursivas, linhas, cores, recomendacoes, temColuna, priorizadas, onPriorizar, mediaGeral, apenasAutoAvaliacao }) => {
  const [open, setOpen] = useState<number[]>([])
  const [Competencia, setCompetencia] = useState<string>()
  const [ModalComentariosIsOpen, setModalComentariosIsOpen] = useState(false)
  function CriaDropQuestoes(e: iLinhaTabela[], nomeCompetencia: string): ReactNode {
    return (
      <>
        {e.map((questao, index) => {
          const temDiscursiva = discursivas.filter(r => r.titulo.includes(questao.competencia)).length > 0
          return (
            <tr key={index}>
              <td className='dropDownTr'>
                <Flex alignItems={'center'}>
                  <Flex flex={'1'} marginRight={'4px'}>
                    <ElementoTooltipCmp label={questao.competencia} bg={'var(--Preto)'}>
                      {nomeCompetencia + ` ${index + 1}`}
                    </ElementoTooltipCmp>
                  </Flex>
                  {temDiscursiva &&
                    <Avatar
                      onClick={() => temDiscursiva ? onOpenModalCompetencia(questao.competencia) : {}}
                      _hover={{ backgroundColor: 'var(--Gray4)' }}
                      bg={'var(--c3)'}
                      width={'2rem'}
                      height={'2rem'} icon={<FaRegComment size={20} />}
                    >
                      <AvatarBadge
                        boxSize='1.75em'
                        bg='#b2c6ea'
                        fontSize={'.6rem'}
                        fontWeight='bold'
                      >
                        {discursivas.filter(d => d.titulo.includes(questao.competencia)).length}
                      </AvatarBadge>
                    </Avatar>
                  }
                  {!temDiscursiva && <Flex width={'1.25rem'}></Flex>}
                </Flex>
              </td>
              {temColuna(0) && <StyledTd theme={VerificarCorV2('autoavaliacao', questao.autoAvaliacao ?? '')}>{PropInvalida(questao.autoAvaliacao) ? '-' : questao.autoAvaliacao}</StyledTd>}
              {temColuna(1) && <StyledTd theme={VerificarCorV2('gestor', questao.gestor ?? '')}>{PropInvalida(questao.gestor) ? '-' : questao.gestor}</StyledTd>}
              {temColuna(2) && <StyledTd theme={VerificarCorV2('par', questao.pares ?? '')}>{PropInvalida(questao.pares) ? '-' : questao.pares}</StyledTd>}
              {temColuna(3) && <StyledTd theme={VerificarCorV2('subordinado', questao.liderados ?? '')}>{PropInvalida(questao.liderados) ? '-' : questao.liderados}</StyledTd>}
              {temColuna(4) && <StyledTd theme={VerificarCorV2('outro', questao.outros ?? '')}>{PropInvalida(questao.outros) ? '-' : questao.outros}</StyledTd>}
              {temColuna(6) && <StyledTd theme={VerificarCorV2('altaLideranca', questao.altaliderancao ?? '')}>{PropInvalida(questao.altalideranca) ? '-' : questao.altalideranca}</StyledTd>}
              {temColuna(7) && <StyledTd >{PropInvalida(questao.consolidado) ? '-' : questao.consolidado}</StyledTd>}
              {!temColuna(7) && <StyledTd theme={VerificarCorRecomendacao(questao.mediaSemAuto, index, questao.competencia, questao.questoes)}>{PropInvalida(questao.mediaSemAuto) ? '-' : questao.mediaSemAuto}</StyledTd>}
            </tr>
          )
        })}
      </>
    )
  }

  function TooltipLabel(e: iLinhaTabela): string {
    if (e.questoes.length > 1) {
      return `${e.questoes.length} Questões`
    } else {
      return e.questoes[0].competencia ?? 'Sem descrição'
    }
  }

  function VerificarCorV2(eixo: string, nota: string, questoes?: iLinhaTabela[]): string {
    const dado = cores.find(e => e.eixo === eixo)
    if (questoes && questoes.length > 1 && dado !== null) {
      return dado?.notasNegativas.some(r => questoes.some(e => e[ParseEixo(eixo)] === r.toFixed(2))) ? 'var(--Orange)' : dado?.notasPositivas.some(r => questoes.some(e => e[ParseEixo(eixo)] === r.toFixed(2))) ? '#00AA00' : 'black'
    }
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.notasNegativas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  function ParseEixo(eixo: string): string {
    switch (eixo) {
      case 'gestor':
        return 'gestor'
      case 'par':
        return 'pares'
      case 'subordinado':
        return 'liderados'
      case 'outro':
        return 'outros'
      case 'autoavaliacao':
        return 'autoAvaliacao'
      default:
        return ''
    }
  }

  const toggle = (e: number): void => {
    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }

  function VerificarCorRecomendacao(nota: string, index: number, questao?: string, questoes?: iLinhaTabela[]): string {
    const dado = cores.find(e => e.eixo === 'semAutoavaliacao')
    if (questao) {
      return recomendacoes.some((e: any) => e.nome === questao || (questao === e.pergunta && e?.media?.toFixed(2) === nota.replace(',', '.'))) ? 'var(--Orange)' : dado?.notasPositivas.some(r => questoes?.some(e => e.mediaSemAuto === r.toFixed(2)) || r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : 'black'
    }
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.recomendacoes.some(e => e === index) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  function MostrarComentario(e: iLinhaTabela): ReactNode {
    const temDiscursiva = discursivas.filter(r => r.titulo.includes(e.competencia)).length > 0
    return (
      <>
        <Flex justifyContent='flex-end'>
          {temDiscursiva && (
            <Avatar
              cursor={'pointer'}
              onClick={() => onOpenModalCompetencia(e.competencia)}
              _hover={{ backgroundColor: 'var(--Gray4)' }}
              bg={'var(--c3)'}
              width={'2rem'}
              height={'2rem'} icon={<FaRegComment size={20} />}
            >
              <AvatarBadge
                boxSize='1.75em'
                bg='#b2c6ea'
                fontSize={'.6rem'}
                fontWeight='bold'
              >
                {discursivas.filter(d => d.titulo.includes(e.competencia)).length}
              </AvatarBadge>
            </Avatar>
          )}
        </Flex>
      </>
    )
  }

  function onOpenModalCompetencia(nome: string): void {
    setCompetencia(nome)
    setModalComentariosIsOpen(true)
  }

  function onCloseModalCompetencia(): void {
    setCompetencia(undefined)
    setModalComentariosIsOpen(false)
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaConsolidado: 0,
      mediaSemAuto: 0
    }

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(0, e.questoes)
      if (!isNaN(mediaCompetencia)) {
        medias.mediaAutoAval += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(1, e.questoes)
      if (!isNaN(mediaCompetencia)) {
        medias.mediaGestor += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(2, e.questoes)
      if (!isNaN(mediaCompetencia)) {
        medias.mediaPares += (mediaCompetencia < 0 ? 0 : mediaCompetencia)
      }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(3, e.questoes)
      if (!isNaN(mediaCompetencia)) { medias.mediaLiderados += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(4, e.questoes)
      if (!isNaN(mediaCompetencia)) { medias.mediaOutros += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(6, e.questoes)
      if (!isNaN(mediaCompetencia)) { medias.mediaAltaLideranca += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      const mediaCompetencia = ObterMediaCompetencia(7, e.questoes)
      if (!isNaN(mediaCompetencia)) { medias.mediaConsolidado += (mediaCompetencia < 0 ? 0 : mediaCompetencia) }
    })

    linhas.forEach((e): void => {
      medias.mediaSemAuto = mediaGeral
    })

    const mediasCalculado = [{
      mediaAutoAval: medias.mediaAutoAval / linhas.filter(e => e.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / linhas.filter(e => e.gestor).length,
      mediaPares: medias.mediaPares / linhas.filter(e => e.pares).length,
      mediaLiderados: medias.mediaLiderados / linhas.filter(e => e.liderados).length,
      mediaOutros: medias.mediaOutros / linhas.filter(e => e.outros).length,
      mediaAltaLideranca: medias.mediaAltaLideranca / linhas.filter(e => e.altalideranca).length,
      mediaConsolidado: medias.mediaConsolidado / linhas.filter(e => e.consolidado).length,
      mediaSemAuto: medias.mediaSemAuto
    }]

    return mediasCalculado
  }

  function ObterMediaCompetencia(eixo: number, questoes: iLinhaTabela[]): number {
    let soma = 0
    if (eixo === 0) {
      questoes.filter(e => !isNaN(parseFloat((e.autoAvaliacao ?? '')))).forEach((e) => {
        soma += parseFloat(e.autoAvaliacao ?? '0')
      })
      return (soma / questoes.filter(e => e.autoAvaliacao).length)
    } else if (eixo === 1) {
      questoes.filter(e => !isNaN(parseFloat((e.gestor ?? '')))).forEach((e) => {
        soma += parseFloat(e.gestor ?? '0')
      })
      return (soma / questoes.filter(e => e.gestor).length)
    } else if (eixo === 2) {
      questoes.filter(e => !isNaN(parseFloat((e.pares ?? '')))).forEach((e) => {
        soma += parseFloat(e.pares ?? '0')
      })
      return (soma / questoes.filter(e => e.pares).length)
    } else if (eixo === 3) {
      questoes.filter(e => !isNaN(parseFloat((e.liderados ?? '')))).forEach((e) => {
        soma += parseFloat(e.liderados ?? '0')
      })
      return (soma / questoes.filter(e => e.liderados).length)
    } else if (eixo === 4) {
      questoes.filter(e => !isNaN(parseFloat((e.outros ?? '')))).forEach((e) => {
        soma += parseFloat(e.outros ?? '0')
      })
      return (soma / questoes.filter(e => e.outros).length)
    } else if (eixo === 6) {
      questoes.filter(e => !isNaN(parseFloat((e.altalideranca ?? '')))).forEach((e) => {
        soma += parseFloat(e.altalideranca ?? '0')
      })
      return (soma / questoes.filter(e => e.altalideranca).length)
    } else if (eixo === 7) {
      questoes.filter(e => !isNaN(parseFloat((e.consolidado ?? '')))).forEach((e) => {
        soma += parseFloat(e.consolidado ?? '0')
      })
      return (soma / questoes.filter(e => e.consolidado).length)
    } else {
      questoes.filter(e => !isNaN(parseFloat((e.mediaSemAuto ?? '')))).forEach((e) => {
        soma += parseFloat(e.mediaSemAuto ?? '0')
      })
      return (soma / questoes.filter(e => e.mediaSemAuto).length)
    }
  }

  return (
    <Flex width={'100%'}>
      <ModalComentarios
        isOpen={ModalComentariosIsOpen}
        onRequestClose={onCloseModalCompetencia}
        comentarios={discursivas.filter(r => r.titulo.includes(Competencia ?? ''))}
      />
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>
                <Flex alignItems='center' justifyContent={'space-between'}>
                  <Flex width={'100%'} justifyContent='center'>
                    Competência
                  </Flex>
                  <Flex width={'1.25rem'}></Flex>
                </Flex></th>
              {temColuna(0) && <th>Autoavaliação </th>}
              {temColuna(1) && <th>Gestor</th>}
              {temColuna(2) && <th>Pares</th>}
              {temColuna(3) && <th>Liderados</th>}
              {temColuna(4) && <th>Outros</th>}
              {temColuna(6) && <th>Alta liderança</th>}
              {(temColuna(7) && !apenasAutoAvaliacao) && <th>Nota final</th>}
              {(!temColuna(7) && !apenasAutoAvaliacao) && <th>Média do avaliado sem autoavaliação</th>}
              {(priorizadas && onPriorizar) && <th>Priorizar</th>}
            </tr>
          </thead>
          <tbody>
            {linhas.map((e, i) => {
              return (
                <>
                  <tr onClick={() => toggle(i)} key={i}>
                    <td>
                      <Flex width={'100%'} alignItems='center' justifyContent={'center'} cursor='pointer'>
                        <Flex width={'100%'} justifyContent={'center'}>
                          <ElementoTooltipCmp label={TooltipLabel(e)} bg={'var(--Preto)'}>
                            {e.competencia}
                          </ElementoTooltipCmp>
                        </Flex>
                        {e.questoes.length === 1 ? MostrarComentario(e.questoes[0]) : ''}
                        {!(priorizadas && onPriorizar) && (e.questoes.length === 1 ? '' : !open.some(valor => valor === i) ? <FaAngleDown /> : <FaAngleUp />)}
                      </Flex>
                    </td>
                    {temColuna(0) && <StyledTd theme={VerificarCorV2('autoavaliacao', e.autoAvaliacao ?? '', e.questoes)}>{(e.autoAvaliacao)}</StyledTd>}
                    {temColuna(1) && <StyledTd theme={VerificarCorV2('gestor', e.gestor ?? '', e.questoes)}>{PropInvalida(e.gestor) ? '-' : e.gestor}</StyledTd>}
                    {temColuna(2) && <StyledTd theme={VerificarCorV2('par', e.pares ?? '', e.questoes)} >{PropInvalida(e.pares) ? '-' : (e.pares)}</StyledTd>}
                    {temColuna(3) && <StyledTd theme={VerificarCorV2('subordinado', e.liderados ?? '', e.questoes)}>{PropInvalida(e.liderados) ? '-' : (e.liderados)}</StyledTd>}
                    {temColuna(4) && <StyledTd theme={VerificarCorV2('outro', e.outros ?? '', e.questoes)}>{PropInvalida(e.outros) ? '-' : (e.outros)}</StyledTd>}
                    {temColuna(6) && <StyledTd theme={VerificarCorV2('altaLideranca', e.altalideranca ?? '', e.questoes)}>{PropInvalida(e.altalideranca) ? '-' : (e.altalideranca)}</StyledTd>}
                    {(temColuna(7) && !apenasAutoAvaliacao) && <StyledTd>{PropInvalida(e.consolidado) ? '-' : (e.consolidado)}</StyledTd>}
                    {(!temColuna(7) && !apenasAutoAvaliacao) && <StyledTd theme={VerificarCorRecomendacao(e.mediaSemAuto ?? '', i)}>{e.mediaSemAuto}</StyledTd>}
                    {(priorizadas && onPriorizar) && <StyledTd theme={VerificarCorRecomendacao(e.mediaSemAuto ?? '', i)}><CheckboxCmp OnChange={(value) => { if (value) { onPriorizar([...priorizadas, e.idQuestao]) } else { onPriorizar(priorizadas.filter(a => { if (a !== e.idQuestao) { return a } })) } }} isChecked={priorizadas.some(a => e.idQuestao === a)}></CheckboxCmp></StyledTd>}
                  </tr>
                  {(!(priorizadas && onPriorizar) && open.some(valor => valor === i) && e.questoes.length > 1) && CriaDropQuestoes(e.questoes, e.competencia)}
                </>
              )
            })}

            {
              linhas && handleMedia().map((e, i) => {
                return (
                  <tr key={i}>
                    <td>
                      Média geral
                    </td>
                    {temColuna(0) && <StyledTd>{isNaN(e.mediaAutoAval) ? '-' : e.mediaAutoAval.toFixed(2)}</StyledTd>}
                    {temColuna(1) && <StyledTd>{isNaN(e.mediaGestor) ? '-' : e.mediaGestor.toFixed(2)}</StyledTd>}
                    {temColuna(2) && <StyledTd>{isNaN(e.mediaPares) ? '-' : e.mediaPares.toFixed(2)}</StyledTd>}
                    {temColuna(3) && <StyledTd>{isNaN(e.mediaLiderados) ? '-' : e.mediaLiderados.toFixed(2)}</StyledTd>}
                    {temColuna(4) && <StyledTd>{isNaN(e.mediaOutros) ? '-' : e.mediaOutros.toFixed(2)}</StyledTd>}
                    {temColuna(6) && <StyledTd>{isNaN(e.mediaAltaLideranca) ? '-' : e.mediaAltaLideranca.toFixed(2)}</StyledTd>}
                    {(temColuna(7) && !apenasAutoAvaliacao) && <StyledTd>{isNaN(e.mediaConsolidado) ? '-' : e.mediaConsolidado.toFixed(2)}</StyledTd>}
                    {(!temColuna(7) && !apenasAutoAvaliacao) && <StyledTd>{e.mediaSemAuto.toFixed(2)}</StyledTd>}
                    {(priorizadas && onPriorizar) && <StyledTd>-</StyledTd>}
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

import styled from 'styled-components'

export const Container = styled.table`
margin-top: 1rem;
width: 100%;
border-spacing: 0 .5rem;
font-family: 'Roboto','sans-serif';
font-size: .875rem;
thead{
    tr{
        color: var(--Azull);
        font-size: .95rem;
    }
    th{
        font-weight: 800;
    }
}

thead{
    th:first-child{
        padding-left: 4rem;
        text-align: start;
    }
}

tbody{

    tr{
        background-color: var(--AzulOpacoBg);
    }

    tr,th{
        border-collapse: collapse;
        padding: .5rem;
        font-weight: 400;
    }
}
`

import { Text } from '@chakra-ui/react'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'
import { ComentarioContainer } from '../../styles'

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
}

interface iDiscursivaProps {
  questao: iFormQuestaoResponse
  erro: boolean
  UpdateText: (id: string, upTexto: string) => void
}

export const Discursiva: React.FC<iDiscursivaProps> = ({ questao, erro, UpdateText }) => {
  return (
        <ComentarioContainer>
            <Text fontWeight={'600'}>{questao.enunciado}</Text>
            {erro && <span>* Preencha este campo</span>}
            <TextAreaCmp
                onBlur={(e) => UpdateText(questao.id, e.target.value)}
                placeholder="Deixe seu comentário"
                OnChange={() => {}}
            />
        </ComentarioContainer>
  )
}

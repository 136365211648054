import styled from 'styled-components'

export const Container = styled.div`
padding: 1rem;
width: 55rem;
font-size: .75rem;
`

export const ModalHeader = styled.div`
display: flex;
margin-bottom:.5rem;
justify-content: space-between;

h4{
  font-size: 1.25rem;
  font-weight: 700;
}

img{
    margin-right: .125rem;
    height: 2.25rem;
    width: 2.25rem;
    border-radius: 2.5rem;
}
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
border-top: 2px dashed var(--Azul);
padding-top: .5rem;
margin-bottom: .5rem;

div{
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    span{
        font-weight: 400;
        font-size: .925rem;
    }

    svg{
        margin-right: .5rem;
        min-height: 1.25rem;
        min-width: 1.25rem;
    }

    &:hover{
        background: #478bb335;
        span{
            font-weight: bold;
        }
    }
}

div:not(:last-child){
    margin-bottom:.5rem;
}

overflow: auto;
max-height:60vh;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

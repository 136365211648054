import { Avatar, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { OutlineMetaButton } from '../../../../../components/Buttons/OutlineMetaButton'
import { ModalBase } from '../../../../../components/ModalBase'
import { iEtapaColaborador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { ModalBody, TextoDescricao, TextoResponsavel } from './styles'

interface iProps {
  etapa?: iEtapaColaborador
  onRequestClose: () => void
}

interface iResponsavel {
  avatar?: string
  nome: string
  nomeArea?: string
}

interface iReuniao {
  responsavel: iResponsavel
  duracao: string
  data: string
  hora: string
}

export const ModalReuniao: React.FC<iProps> = ({ onRequestClose, etapa }) => {
  const route = etapa?.tipo === 2 ? 'Reuniao' : etapa?.tipo === 7 ? 'ReuniaoOneOld' : etapa?.tipo === 10 ? 'ReuniaoOne' : ''
  const [Model, setModel] = useState<iReuniao>()

  function getReuniao(): void {
    appApi.get(`${route}/${etapa?.interacoes[0].idExterno ?? ''}/Detalhe`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getReuniao()
  }, [])

  return (
    <ModalBase
      isOpen
      onClose={onRequestClose}
      Titulo={etapa?.tipo === 2 ? 'Reunião' : 'Reunião 1:1'}
    >
      <ModalBody>
        {
          !PropInvalida(Model?.data)
            ? <>
              <h2>Data da reunião: {Model?.data}</h2>
              <h2>Horário: {Model?.hora}</h2>
              <h2>Duração: {Model?.duracao}</h2>
              <Flex flexDir={'column'}>
                <TextoResponsavel>Responsável pela reunião:</TextoResponsavel>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <Avatar marginRight={'.5rem'} size={'sm'} src={Model?.responsavel.avatar} name={Model?.responsavel.nome}/>
                  <Flex textAlign={'start'} flexDir={'column'}>
                    <span>{Model?.responsavel.nome}</span>
                    {Model?.responsavel.nomeArea &&
                    <strong>{Model?.responsavel.nomeArea}</strong>
                    }
                  </Flex>
                </Flex>
              </Flex>
            </>
            : <>
              <h2>Aguardando confirmação do responsável...</h2>
              <TextoDescricao>O responsável ainda não definiu uma data e/ou hora para essa reunião... você será notificado assim que houver a confirmação.</TextoDescricao>
              <Flex mt={'.5rem'} flexDir={'column'}>
                <TextoResponsavel>Responsável pela reunião:</TextoResponsavel>
                <Flex justifyContent={'center'} alignItems={'center'}>
                  <Avatar marginRight={'.5rem'} size={'sm'} src={Model?.responsavel.avatar} name={Model?.responsavel.nome}/>
                  <Flex textAlign={'start'} flexDir={'column'}>
                    <span>{Model?.responsavel.nome}</span>
                    {Model?.responsavel.nomeArea &&
                    <strong>{Model?.responsavel.nomeArea}</strong>
                    }
                  </Flex>
                </Flex>
              </Flex>
            </>
        }
      </ModalBody>

      <Flex padding={'1rem 0'} justifyContent='center'>
        <OutlineMetaButton onClick={onRequestClose} size='md' color='c6'>Voltar</OutlineMetaButton>
      </Flex>
    </ModalBase>
  )
}

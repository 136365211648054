import { Container } from './styles'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { useNavigate } from 'react-router-dom'
import { iAvatarXInformacoes } from '../../../../../interfaces'
import { Avatar } from '@chakra-ui/avatar'
import { Flex, Text } from '@chakra-ui/layout'

export interface iDadoTabelaAprovacao {
  id: string
  origem: string
  totalAcoes: number
  dataFim: string
  colaboradorId: string
  colaborador: iAvatarXInformacoes
}

interface iTabelaProps {
  slice: number
  dados: iDadoTabelaAprovacao[]
}

export const TabelaAprovacao: React.FC<iTabelaProps> = ({ dados, slice }) => {
  const nav = useNavigate()

  return (
    <Container>
      <thead>
        <tr>
          {dados[0]?.colaborador && <th>Dono do PDI</th>}
          <th>Origem</th>
          <th>Quantidade de ações</th>
          <th>Data final</th>
        </tr>
      </thead>
      <tbody>
        {
          dados.slice(0, slice).map((e, i) => {
            return (
              <tr key={i}>
                {e.colaborador && <th>
                  <Flex gap={'1.5rem'} pt={'.125rem'} alignItems={'center'} mb={'.5rem'}>
                    <Avatar size={'sm'} src={e.colaborador?.avatar} name={e.colaborador?.info1} />
                    <Flex flexDir={'column'} gap={'.325rem'} alignItems={'start'} color={'black'}>
                      <Text fontSize={'14px'} lineHeight={'16px'} fontWeight={'400'} textAlign={'start'}>
                        {e.colaborador?.info1}
                      </Text>
                      <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
                        {e.colaborador?.info2}
                      </Text>
                    </Flex>
                  </Flex></th>}
                <th>{e.origem}</th>
                <th>{e.totalAcoes}</th>
                <th>{e.dataFim}</th>
                <th>
                  <ButtonCmp
                    VarColor='Rosa' onClick={() => nav(`/Rh/VisualizarPerfil/${e.colaboradorId}/?pdiId=${e.id}`)}
                  >Aprovar</ButtonCmp>
                </th>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}

import { Flex, Text } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { SkillAnswer, SkillQuestion } from '../../styles'

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
  alternativas?: iFormAlternativaResponse[]
  ocultarPeso?: boolean
}

interface iFormAlternativaResponse {
  id: string
  texto: string
  peso?: number
}

interface iEscalaProps {
  questao: iFormQuestaoResponse
  isSelect: (perguntaId: string, alternativaId: string) => boolean
  SelecionarQuestao: (perguntaId: string, alternativaId: string) => void
  erro: boolean
}

export const Escala: React.FC<iEscalaProps> = ({ questao, erro, isSelect, SelecionarQuestao }) => {
  return (
    <SkillQuestion>
      <div>
        <Text fontWeight={'600'}>{questao.enunciado}</Text>
        {erro && <span>* resposta requerida</span>}
      </div>
      {
        questao.alternativas?.map((r: iFormAlternativaResponse, i2) => {
          return (
            <SkillAnswer
              key={i2}
              type="button"
              isSelected={isSelect(questao.id, r.id)}
              onClick={() => SelecionarQuestao(questao.id, r.id)}
            >
              <FaCheckCircle />
              <Flex as={'span'}>{r.texto} <Flex fontWeight={'900'} ml={'.325rem'}>{!questao.ocultarPeso ? '' : `(${r.peso})`}</Flex></Flex>
            </SkillAnswer>
          )
        })
      }
    </SkillQuestion>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, FormControl, FormLabel, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { WhiteContainer } from '../../../../../../components/WhiteContainer'
import LogoZoom from '../../../../../../assets/logo-zoom.svg'
import LogoMicrosoft from '../../../../../../assets/microsoft-teams-logo.svg'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { useState, useEffect } from 'react'
import { DeleteElementByPosition, UpdateElementByPosition } from '../../../../../../Utils/Helper'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'
import { appApi } from '../../../../../../services/appApi'
import { useParams } from 'react-router-dom'
import { iConfiguracao } from '../..'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'

interface iModel {
  local: string
  duracao: number
  presencial: boolean
  datasDisponiveis: string[]
  descricao: string
}

interface iProps {
  onReload: () => void
  model: iConfiguracao
}

export const BoxAgendamento: React.FC<iProps> = ({ onReload, model }) => {
  const toast = useToast()
  const { reuniaoId } = useParams<{ reuniaoId: string }>()
  const { Permissoes } = useAuth()

  const [Model, setModel] = useState<iModel>({
    datasDisponiveis: [
      model.dataLimiteInicio?.replace('Z', '') ?? new Date().toISOString().split('T')[0] + 'T00:00'
    ],
    duracao: 5,
    local: '',
    presencial: false,
    descricao: ''
  })

  const [EditorIsLoading, setEditorIsLoading] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [IniciarIsLoading, setIniciarIsLoading] = useState(false)
  const [TipoLocal, setTipoLocal] = useState<'zoom' | 'microsoft' | 'outro'>('outro')
  const [LocalIsInvalid, setLocalIsInvalid] = useState(false)

  function Agendar(iniciar: boolean): void {
    setLocalIsInvalid(false)
    if (Model.local.length === 0) {
      setLocalIsInvalid(true)
      toast({
        title: 'Preencha o local da reunião!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    if (Model.datasDisponiveis.includes('00-00-00T00:00')) {
      toast({
        title: 'Preencha todos os campos de data e hora corretamente!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    if (iniciar) {
      setIniciarIsLoading(true)
    } else {
      setIsLoading(true)
    }
    appApi.post(`ReuniaoOneEtapaColaborador/${reuniaoId}/EnviarConvite?iniciar=${iniciar}`, Model)
      .then(() => {
        setIsLoading(false)
        onReload()
        toast({
          title: 'Convite enviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(({ response }) => {
        toast({
          title: response.data,
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setEditorIsLoading(true)
    setModel({
      ...Model,
      descricao: model.descricao,
      duracao: model.duracao,
      local: model.local.includes('Zoom') ? 'zoom' : model.local.includes('Teams') ? 'microsoft' : model.local,
      presencial: model.presencial
    })
    if (!model.presencial) {
      setTipoLocal(model.local.toLocaleLowerCase().includes('microsoft') ? 'microsoft' : model.local.toLocaleLowerCase().includes('zoom') ? 'zoom' : 'outro')
    }
    setTimeout(() => setEditorIsLoading(false), 100)
  }, [model])

  return (
    <WhiteContainer>
      <h3>Configure o envio</h3>
      <Flex mt={'1rem'}>
        <Flex flexDirection={'column'}>
          <Flex mb={'.5rem'} gap='.75rem'>
            <Flex flexDir={'column'}>
              <Flex flexDir={'column'} gap={'.75rem'}>
                {Model.datasDisponiveis.map((e, i) => {
                  return (
                    <Flex gap={'.5rem'} alignItems={'center'} key={i}>
                      <InputCmp
                        label='Data e hora'
                        borderColor={'var(--Gray4)'}
                        w={'15.2rem'}
                        value={e}
                        type={'datetime-local'}
                        min={model.dataLimiteInicio?.replace('Z', '')}
                        max={model.dataLimiteFim?.replace('Z', '')}
                        OnChange={(data) => setModel({ ...Model, datasDisponiveis: UpdateElementByPosition(Model.datasDisponiveis, i, data) })}
                      />
                      {i > 0 &&
                        <ElementoTooltipCmp label='Excluir' bg={'var(--Red)'}>
                          <MdOutlineClose
                            cursor={'pointer'} color='var(--Red)'
                            onClick={() => setModel({ ...Model, datasDisponiveis: DeleteElementByPosition([...Model.datasDisponiveis], i) })}
                          />
                        </ElementoTooltipCmp>
                      }
                    </Flex>
                  )
                })}
              </Flex>
              {/* <ButtonCmp
                px={'2rem'}
                py={'.5rem'}
                w={'12rem'}
                h={'1.7rem'}
                mt={'1rem'} fontSize={'0.625rem'}
                VarColor='Rosa'
                onClick={() => setModel({ ...Model, datasDisponiveis: [...Model.datasDisponiveis, '00-00-00T00:00'] })}
                leftIcon={<FaPlus />}
              >Adicionar outra opção de data</ButtonCmp> */}
            </Flex>

            <FormControl>
              <SelectCmp
                title='Duração'
                w={'14.5rem'}
                value={Model.duracao.toString()}
                OnChange={(e) => setModel({ ...Model, duracao: parseInt(e) })}
              >
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </SelectCmp>
            </FormControl>
          </Flex>
          {/* <small>Ao adicionar outra opção de data o convidado poderá escolher uma delas para aceitar a reunião</small> */}
        </Flex>
      </Flex>
      <Flex flexDir={'column'} mt='.75rem'>
        <RadioGroup
          onChange={() => setModel({ ...Model, presencial: !Model.presencial })}
          value={Model.presencial ? '1' : '0'}
          mb={'.75rem'}
          size={'sm'}
        >
          <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Local</FormLabel>
          <Stack direction={'row'} borderColor={'var(--Gray4)'}>
            <Radio value={'0'}>Online</Radio>
            <Radio value={'1'}>Presencial</Radio>
          </Stack>
        </RadioGroup >
        {(!Model.presencial) && (
          <Flex gap={'1rem'} mb={'.75rem'} alignItems={'center'}>
            <RadioGroup
              display={'flex'}
              alignItems={'center'}
              size={'sm'}
              onChange={(e: 'zoom' | 'microsoft' | 'outro') => {
                setTipoLocal(e)
                if (e === 'zoom') {
                  setEditorIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'zoom',
                    descricao: '<p>Reunião do Zoom</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setTimeout(() => setEditorIsLoading(false), 10)
                } else if (e === 'microsoft') {
                  setEditorIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'microsoft',
                    descricao: '<p>Reunião do Microsoft Teams</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setTimeout(() => setEditorIsLoading(false), 10)
                } else {
                  setEditorIsLoading(true)
                  setModel({ ...Model, local: '', descricao: '' })
                  setTimeout(() => setEditorIsLoading(false), 10)
                }
              }}
              value={TipoLocal}
              gap='1rem'
              isDisabled={!Permissoes.zoom}
            >
              <Radio value={'zoom'}>
                <ElementoTooltipCmp
                  label={!Permissoes.zoom ? 'Zoom necessita de integração, vá até a configuração da empresa!' : ''}
                >
                  <img src={LogoZoom} alt="" />
                </ElementoTooltipCmp>
              </Radio>

              <Radio
                value={'microsoft'}
                onClick={() => {

                }}
              >
                <ElementoTooltipCmp
                  label={!Permissoes.teams ? 'Teams necessita de integração, vá até a configuração da empresa!' : ''}
                >
                  <img src={LogoMicrosoft} alt="" />
                </ElementoTooltipCmp>
              </Radio>

              <Radio value={'outro'}>Outro</Radio>
            </RadioGroup>
          </Flex>
        )}
        {(Model.presencial || TipoLocal === 'outro') && (
          <InputCmp
            mb={'.75rem'}
            value={Model.local}
            isInvalid={LocalIsInvalid}
            OnChange={(e) => setModel({ ...Model, local: e })}
            placeholder={Model.presencial ? 'Digite o local da reunião' : 'Cole o link da reunião aqui'}
            flexDir={'column'}
          />
        )}

        <Flex flexDir={'column'}>
          <FormControl>
            <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Descrição</FormLabel>
            <EditorTexto
              initialValue={Model.descricao}
              placeholder='Deixe uma descrição aqui'
              className='editorSt-v3'
              isLoading={EditorIsLoading}
              onChange={(e: string) => setModel({ ...Model, descricao: e })}
            />
          </FormControl>
          <small>Digite um texto com informações importantes ou pauta para registro do que será tratado na reunião</small>
        </Flex>
      </Flex>
      <Flex mt={'1rem'} justifyContent={'end'} gap='.5rem'>
        <ElementoTooltipCmp label='Se você deseja iniciar a reunião agora, sem aguardar a resposta do colaborador, clique aqui!'>
          <ButtonCmp isLoading={IniciarIsLoading} onClick={() => Agendar(true)} VarColor='Rosa'>Iniciar agora</ButtonCmp>
        </ElementoTooltipCmp>
        <ButtonCmp
          VarColor='Green2'
          onClick={() => Agendar(false)}
          isLoading={IsLoading}
        >Enviar convite</ButtonCmp>
      </Flex>
    </WhiteContainer>
  )
}

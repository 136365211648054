import { Flex, Tooltip, Text } from '@chakra-ui/react'
import { CircleDiv, CircleCard, CardDiv, BackgroundCard, BodyCard, FlexElipse, EditMenu, FlexBetween } from './styles'
import { CardReuniao } from './components/CardReuniao'
import { CardIntegracao } from './components/CardIntegracao'
import { CardPesquisa } from './components/CardPesquisa'
import { CardPesquisaStackholder } from './components/CardPesquisaStackholder'
import { CardImersao } from './components/CardImersao'
import { iEtapaColaborador, iInteracaoEtapa } from '../../../../../interfaces'
import { FaAngleDown, FaAngleUp, FaCheck, FaCopy, FaEye, FaEyeSlash, FaPen, FaRegEdit, FaTrash } from 'react-icons/fa'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IsEq, ObterCorEtapaPeloTipo, ObterCorFundoEtapaPeloTipo, PropInvalida } from '../../../../../Utils/Helper'
import { CardEnps } from './components/CardEnps'
import { CardReuniaoOne } from './components/CardReuniaoOne'
import { CardPDI } from './components/CardPDI'
import { CardNovaReuniaoOne } from './components/CardNovaReuniaoOne'

interface ICardOboardConfig {
  etapa: iEtapaColaborador
  isLast: boolean
  disabledMenu?: boolean
  processoId: string
  colaboradorId: string
  onDeleteEtapa: (Id: string) => void
  onConcludeEtapa: (Id: string) => void
  onReabrirEtapa: (Id: string) => void
  onUpdateVisibility: (Id: string) => void
  onReload: () => void
  modalObservacao: () => void
  podeAddEtapa: boolean
  onDuplicar?: (etapaId: string, tipo?: number) => void
}

export const BoardBox: React.FC<ICardOboardConfig> = ({ processoId, colaboradorId, etapa, isLast, disabledMenu, onDuplicar, onDeleteEtapa, onConcludeEtapa, onUpdateVisibility, onReabrirEtapa, onReload, modalObservacao, podeAddEtapa }) => {
  const [isOpen, setisOpen] = useState(false)
  const Cor = ObterCorEtapaPeloTipo(etapa.tipo)
  const CorFundo = ObterCorFundoEtapaPeloTipo(etapa.tipo)

  function ObterComponentePeloTipoEtapa(key: number): any {
    if (etapa.tipo === 1) {
      return (
        <CardIntegracao
          key={key}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    } else if (etapa.tipo === 2) {
      return (
        <CardReuniao
          processoId={processoId}
          key={key}
          isOpen={isOpen}
          etapa={etapa}
          onReload={onReload}
        />
      )
    } else if (etapa.tipo === 3) {
      return (
        <CardImersao
          key={key}
          etapa={etapa}
          isOpen={isOpen}
          colabId={colaboradorId}
        />
      )
    } else if (etapa.tipo === 4) {
      return (
        <CardPesquisa key={key} etapa={etapa} isOpen={isOpen} />
      )
    } else if (etapa.tipo === 5) {
      return (
        <CardPesquisaStackholder
          key={key}
          etapa={etapa}
          onReabrirEtapa={onReabrirEtapa}
          processoId={processoId}
        />
      )
    } else if (etapa.tipo === 6) {
      return (
        <CardEnps
          key={key}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    } else if (etapa.tipo === 7) {
      return (
        <CardReuniaoOne
          key={key}
          etapa={etapa}
          isOpen={isOpen}
          onReload={onReload}
        />
      )
    } else if (etapa.tipo === 9) {
      return (
        <CardPDI
          key={key}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    } else if (etapa.tipo === 10) {
      return (
        <CardNovaReuniaoOne
          key={key}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    }
  }

  function ObterRedirectPeloTipo(Tipo: number): string {
    if (Tipo === 1) {
      return `/Jornada/Processo/${colaboradorId}/Integracao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (Tipo === 2) {
      return `/Jornada/Processo/${colaboradorId}/Reuniao/Editar/${etapa.id}`
    } else if (Tipo === 3) {
      return `/Jornada/Processo/${colaboradorId}/Imersao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (Tipo === 4) {
      return `/Jornada/Processo/${processoId}/PesquisaInterna/Editar/${etapa.id}`
    } else if (Tipo === 5) {
      return `/Jornada/Percepcao/Quantitativa/Editar/${etapa.id}/Processo/${processoId}`
    } else if (Tipo === 6) {
      return `/Jornada/Processo/${processoId}/eNps/Editar/${etapa.id}`
    } else if (Tipo === 7) {
      return `/Jornada/Processo/${processoId}/ReuniaoOne/Configuracao/${etapa.id}`
    } else if (etapa.tipo === 9) {
      return `/Jornada/Processo/${colaboradorId ?? ''}/PDI/${etapa.id}`
    } else if (Tipo === 10) {
      return `/Jornada/Processo/${processoId}/NovaReuniaoOne/Editar/${etapa.id}`
    }

    return ''
  }

  function ObterChildrenPorStatus(): any {
    if (etapa.status !== 2 && !PropInvalida(etapa.dataInicio) && (etapa.status === 0 || IsEq(etapa.tipo, 2, 7, 10)) && etapa.status !== 1) {
      return (
        <span style={{ fontSize: '12px' }}>{etapa.dataInicio}</span>
      )
    }

    if (etapa.status === 0) { // Liberado
      return (
        <span style={{ fontSize: '12px' }}>Liberado</span>
      )
    }

    if (etapa.status === 1) { // Etapa anterior
      return (
        <span style={{ fontSize: '12px' }}>Após a anterior</span>
      )
    }

    if (etapa.status === 2) { // Finalizado
      return (
        <FaCheck color='var(--terc6)' size={20} />
      )
    }

    if (etapa.status === 3 && PropInvalida(etapa.dataInicio)) { // Data indefinida
      return (
        <span style={{ fontSize: '12px' }}>Aguarde a data</span>
      )
    }
    if (etapa.status === 6) { // Atrasado
      return (
        <span style={{ fontSize: '12px' }}>Atrasada</span>
      )
    }

    if (etapa.status === 4 || etapa.dataInicio) { // Data de inicio
      return (
        <span style={{ fontSize: '12px' }}>{etapa.dataInicio}</span>
      )
    }

    if (etapa.status === 5) { // Demitido
      return (
        <span style={{ fontSize: '0.675rem' }}>Interrompido</span>
      )
    }

    if (etapa.status === 7) { // Em andamento
      return (
        <span style={{ fontSize: '0.80rem' }}>Em andamento</span>
      )
    }
  }

  return (
    <Flex>
      <CircleDiv>
        <CircleCard theme={etapa.status === 6 ? 'Red' : 'terc6'}>{ObterChildrenPorStatus()}</CircleCard>
        {
          (!isLast) && (
            <hr />
          )
        }
      </CircleDiv>
      <FlexElipse color={Cor}>
        <div></div>
        <div></div>
        <div></div>
      </FlexElipse>
      <BackgroundCard isOpen={isOpen} theme={CorFundo}>
        <CardDiv disable={etapa.tipo === 0 || etapa.tipo === 8} onClick={() => !PropInvalida(etapa.interacoes) && setisOpen(!isOpen)} theme={Cor}>
          <FlexBetween>
            <Flex alignItems={'center'}>
              <h4 style={{ fontSize: '16px', fontWeight: 700 }}>{etapa.nome}</h4>
            </Flex>
            {
              (etapa.tipo !== 8 && etapa.tipo !== 0 && !PropInvalida(etapa.interacoes)) && (
                !isOpen
                  ? <FaAngleDown size={'1.5rem'} />
                  : <FaAngleUp size={'1.5rem'} />
              )
            }
          </FlexBetween>
          {etapa.descricao === 'Integracão' &&
            <span style={{ fontSize: '14px' }}>Integração</span>
          }

          {etapa.descricao !== 'Integracão' &&
            <span style={{ fontSize: '14px' }}>{etapa.descricao}</span>
          }
        </CardDiv>
        {
          <BodyCard isOpen={isOpen}>
            {
              !PropInvalida(etapa.interacoes) && etapa.interacoes.map((obj: iInteracaoEtapa, i) => {
                return ObterComponentePeloTipoEtapa(i)
              })
            }
          {!PropInvalida(etapa.observacao) &&
              (<Flex gap={'.5rem'} mt={'.8rem'}>
                <Text><strong>Observação: </strong></Text>
                <Text>{etapa.observacao}</Text>
                </Flex>)
          }
          </BodyCard>
        }
      </BackgroundCard>

      {
        (!disabledMenu && !PropInvalida(etapa.interacoes)) && (
          <EditMenu isOpen={isOpen}>
            {
              (etapa.interacoes[0].tipo > 0 && etapa.status !== 2 && etapa.editar) && (
                <>
                  <Tooltip
                    padding='2'
                    borderRadius='4'
                    placement='top'
                    color={'white'}
                    background={'var(--Azul)'}
                    label='Clique aqui para editar a etapa'
                    hasArrow arrowSize={15}
                  >
                    <Link
                      data-bt="edit"
                      to={ObterRedirectPeloTipo(etapa.interacoes[0].tipo)}>
                      <FaPen style={{ marginLeft: '1rem' }} /></Link>
                  </Tooltip>

                  <Tooltip
                    padding='2'
                    borderRadius='4'
                    placement='top'
                    color={'white'}
                    background={'var(--Azul)'}
                    label='Clique aqui para excluir a etapa'
                    hasArrow arrowSize={15}
                  >
                    <a data-bt="trash" onClick={() => onDeleteEtapa(etapa.id)}><FaTrash /></a>
                  </Tooltip>
                </>

              )
            }
            {
              (etapa.status !== 2 && etapa.concluir) && (
                <Tooltip
                  padding='2'
                  borderRadius='4'
                  placement='top'
                  color={'white'}
                  background={'var(--Azul)'}
                  label='Clique aqui para dar esta etapa como concluida'
                  hasArrow arrowSize={15}
                >
                  <a style={{ marginBottom: '1rem' }} data-bt="check" onClick={() => onConcludeEtapa(etapa.id)}><FaCheck /></a>
                </Tooltip>
              )
            }
            {
              (etapa.status === 2) && (
                <>
                  {
                    (etapa.visivel) && (
                      <Tooltip
                        padding='2'
                        borderRadius='4'
                        placement='top'
                        color={'white'}
                        background={'var(--Azul)'}
                        label='Clique para ocultar esta etapa para o gestor e colaborador'
                        hasArrow arrowSize={15}
                      >
                        <a style={{ marginBottom: '1rem' }} data-bt="eye" onClick={() => onUpdateVisibility(etapa.id)}><FaEyeSlash /></a>
                      </Tooltip>
                    )
                  }

                  {
                    (!etapa.visivel) && (
                      <Tooltip
                        padding='2'
                        borderRadius='4'
                        placement='top'
                        color={'white'}
                        background={'var(--Azul)'}
                        label='Clique para tornar visível esta etapa'
                        hasArrow arrowSize={15}
                      >
                        <a style={{ marginBottom: '1rem' }} data-bt="eye" onClick={() => onUpdateVisibility(etapa.id)}><FaEye /></a>
                      </Tooltip>
                    )
                  }
                </>
              )
            }

            <Tooltip
              padding='2'
              borderRadius='4'
              placement='top'
              color={'white'}
              background={'var(--Azul)'}
              label='Clique para adicionar uma observação à essa etapa'
              hasArrow arrowSize={15}
            >
              <a style={{ marginBottom: '1rem' }} data-bt="reg" onClick={modalObservacao}><FaRegEdit /></a>
            </Tooltip>

            {podeAddEtapa && <Tooltip
              padding='2'
              borderRadius='4'
              placement='top'
              color={'white'}
              background={'var(--Azul)'}
              label='Clique para duplicar a etapa!'
              hasArrow
              arrowSize={15}
            >
              <a style={{ marginBottom: '1rem' }} data-bt="copy" onClick={() => onDuplicar !== undefined ? onDuplicar(etapa.id, etapa.tipo) : ''}><FaCopy /></a>
            </Tooltip>}
          </EditMenu>
        )
      }
    </Flex>
  )
}

import { Avatar, Flex, Text } from '@chakra-ui/react'
import { Container, ColumnText } from './styles'
import { FaBriefcase, FaIdCard } from 'react-icons/fa'
import { iCardAvaliador } from '../../../../../interfaces'

interface iCardProps {
  Avaliador: iCardAvaliador
}

export const CardParticipantes: React.FC<iCardProps> = ({ Avaliador }) => {
  return (
    <Container>
      <Flex alignItems={'center'} margin={'9px 0px 9px 13px'}>
        <Avatar name={Avaliador.nome} src={Avaliador.avatar} />
        <ColumnText>
          {
            Avaliador.nome?.length > 17
              ? <span title={Avaliador.nome}>{Avaliador.nome.substring(0, 18)}...</span>
              : <span style={{ fontWeight: 'bold' }}>{Avaliador.nome}</span>
          }
          <Flex color={'var(--c7)'} flexDir={'column'} gap='.05rem'>
            <Flex alignItems={'center'} gap={'.3rem'}>
              <FaBriefcase size={12} />
              <Text fontWeight={'medium'} mt={'.1rem'} fontSize={'x-small'}>{Avaliador.cargo}</Text>
            </Flex>
            <Flex alignItems={'center'} gap={'.3rem'}>
              <FaIdCard size={12} />
              <Text fontSize={'x-small'}>{Avaliador.departamento}</Text>
            </Flex>
          </Flex>
        </ColumnText>
      </Flex>
    </Container>
  )
}

import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IDepartment {
  id: string
  nome: string
  departamentoPaiId?: string
}

interface IUpdateDepartmentModalProps {
  isOpen: boolean
  initialData: IDepartment
  departmentList: IDepartment[]
  onRequestClose: () => void
  onUpdateDepartment: (updatedDepartment: IDepartment) => void
}

export const UpdateDepartmentModal: React.FC<IUpdateDepartmentModalProps> = ({
  isOpen,
  initialData,
  departmentList,
  onRequestClose,
  onUpdateDepartment
}) => {
  const [Nome, setNome] = useState('')
  const [IdPai, setIdPai] = useState<string>()

  useEffect(() => {
    setNome(initialData.nome)
    setIdPai(initialData.departamentoPaiId)
  }, [initialData.nome])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h3>Editar área</h3>
        <ModalBody>
        <InputCmp
            label='Nome da área'
            OnChange={setNome}
            placeholder='Digite um nome'
            value={Nome}
        />

        <SelectCmp value={IdPai} title='Área superior' OnChange={setIdPai}>
          <option value={undefined}>Nenhum</option>
          {
            departmentList.filter((e) => {
              if (e.departamentoPaiId !== initialData.id && e.id !== initialData.id) {
                return e
              }
              return null
            }).map((e: IDepartment, i) => {
              return (
                <option value={e.id} key={i}>{e.nome}</option>
              )
            })
          }
        </SelectCmp>
        </ModalBody>
        <ModalFooter>
          <ButtonCmp VarColor='c6' onClick={onRequestClose}>Fechar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onUpdateDepartment({ nome: Nome, departamentoPaiId: IdPai, id: initialData.id })} >Salvar</ButtonCmp>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { FormControl } from '@chakra-ui/form-control'
import { Flex, Text } from '@chakra-ui/layout'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { MdOutlineStopCircle } from 'react-icons/md'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { MultiColaborador } from '../../../../../components/MultiColaborador'
import { iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iProps {
  participantes?: string[]
  onClose: () => void
  onCreate: (participantes: string[]) => void
  onUpdate: (participantes: string[]) => void
  onEncerrar: () => void
}

export const ModalComite: React.FC<iProps> = ({ participantes, onUpdate, onEncerrar, onClose, onCreate }) => {
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])

  function getColaboradores(): void {
    appApi.get('Colaborador')
      .then(res => setColaboradores(res.data.map((e: any) => { return { avatar: e.avatar, nome: e.nome + ' ' + e.sobrenome, complemento: e.departamento?.nome, id: e.id } })))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
    if (participantes) {
      setSelecionados(participantes)
    }
  }, [])

  return (
    <ModalBase Titulo='Comitê de calibração' styleHeader={{ color: 'var(--Azul)' }} Width='62.5rem' isOpen onClose={onClose}>
      <Flex padding={'1rem'} borderTop='1px solid var(--c4)'>
        <FormControl>
          <Text fontSize='.85rem' fontWeight={'700'} mb={'.25rem'}>Participante do comitê</Text>
          <MultiColaborador
            placeholder='Digite o nome ou e-mail do usuário'
            colaboradores={Colaboradores}
            selecionados={Selecionados}
            onChoice={(e) => !PropInvalida(e) && setSelecionados([...Selecionados, e])}
            onDelete={(e) => setSelecionados([...Selecionados.filter(r => r !== e)])}
          />
        </FormControl>
      </Flex>
      <Flex padding={'0 1rem 1rem 1rem'} gap='.5rem' justifyContent={'end'}>
        <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
        {PropInvalida(participantes) && <ButtonCmp
          leftIcon={<FaSave />}
          VarColor='Green2'
          onClick={() => onCreate(Selecionados)}
        >Salvar e iniciar comitê</ButtonCmp>}
        {participantes &&
          <>
            <ButtonCmp
              leftIcon={<FaSave />}
              VarColor='Green2'
              onClick={() => onUpdate(Selecionados)}
            >Salvar alteração</ButtonCmp>

            <ButtonCmp
              leftIcon={<MdOutlineStopCircle />}
              VarColor='Red'
              onClick={onEncerrar}
            >Encerrar comitê</ButtonCmp>
          </>
        }
      </Flex>
    </ModalBase>
  )
}

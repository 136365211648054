/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex, Menu, MenuButton, Text, MenuList } from '@chakra-ui/react'
import { FaChevronDown, FaCloudDownloadAlt, FaEye, FaFileCsv, FaMapMarkerAlt, FaTachometerAlt, FaTrash, FaUsers, FaRedo, FaPaperPlane, FaCheck, FaClipboardList } from 'react-icons/fa'
import { FaPeopleGroup } from 'react-icons/fa6'
import { MdSend } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuButtonCmp } from '../../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { iCardPesquisa } from '../../../../../interfaces'
import { urlApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { PropInvalida } from '../../../../../Utils/Helper'
import { ColumnText, PreViewParticipante, GridContainer } from './styles'

interface CardProps {
  pesquisa: iCardPesquisa
  onDelete: () => void
  onReabrir: () => void
}

export const CardPesquisa: React.FC<CardProps> = ({ pesquisa, onDelete, onReabrir }) => {
  const { pathname } = useLocation()
  const modoPesquisa = pesquisa.indicativa ? 'Indicativa' : 'Quantitativa'

  const navigate = useNavigate()
  const redirectPage = (url: string): any => { window.location.href = url }

  function ObterCorStatus(): string {
    if (pesquisa.statusDaPesquisa === 0 || pesquisa.statusDaPesquisa === 3) return 'var(--Yellow)'
    if (pesquisa.statusDaPesquisa === 1) return 'var(--Red)'
    if (pesquisa.statusDaPesquisa === 2) return 'var(--Green3)'

    return 'none'
  }

  function ReturnInfoEnvioBox(): any {
    const info = pesquisa.envioInfo
    if (info?.status === 0) {
      return (
              <ColumnText>
                  <Text fontSize={'.875rem'}>{info.mensagem.split(': ')[0]}:</Text>
                  <Text fontSize={'.875rem'} fontWeight={'700'} mt={'-.25rem'}>{info.mensagem.split(': ')[1]}</Text>
              </ColumnText>
      )
    } else if (info?.status === 1) {
      return (
              <ColumnText style={{ wordBreak: 'break-word', width: '8.5rem', fontSize: '.875rem', lineHeight: '.875rem' }}>{info.mensagem}</ColumnText>
      )
    } else if (info?.status === 2) {
      return (
              <ColumnText style={{ wordBreak: 'break-word', width: '8.5rem', fontSize: '.875rem', lineHeight: '.875rem' }}>{info.mensagem}</ColumnText>
      )
    } else if (info?.status === 3) {
      return (
              <ColumnText style={{ wordBreak: 'break-word', width: '8.5rem', fontSize: '.875rem', lineHeight: '.875rem' }}>{info.mensagem}</ColumnText>
      )
    } else if (info?.status === 4) {
      return (
              <ColumnText style={{ wordBreak: 'break-word', width: '8.5rem', fontSize: '.875rem', lineHeight: '.875rem' }}>{info.mensagem}</ColumnText>
      )
    }
  }

  return (
        <PreViewParticipante color={ObterCorStatus()}>
            <Box w={'.3rem'} h={'.3rem'} bg={ObterCorStatus()} borderRadius={'50%'} pos={'absolute'} top={'10px'} left={'10px'}></Box>
            <GridContainer>
                <ColumnText>
                    <h4><strong>{pesquisa.nomePesquisa}</strong></h4>
                    {
                        (pesquisa.competenciasDoPdi) && (
                            <Flex alignItems={'center'} gap='.325rem' mt='.325rem'>
                                <FaCheck color='var(--Green2)' size={14}/>
                                <h4 style={{ color: 'var(--Green2)', fontSize: '.813rem', lineHeight: '.813rem' }}>Competências trabalhadas no ultimo PDI</h4>
                            </Flex>
                        )
                    }
                </ColumnText>

                {
                    (!PropInvalida(pesquisa.baseCiclo) && pesquisa.baseCiclo?.includes('Data de ingresso do avaliado')) && (
                        <ColumnText>
                            <h4>Base ciclo</h4>
                            <span>{pesquisa.baseCiclo}</span>
                        </ColumnText>
                    )
                }

                {
                    (PropInvalida(pesquisa.baseCiclo)) && (
                        <ColumnText>
                            <h4>Início</h4>
                            <span>{pesquisa.dataInicio !== null ? pesquisa.dataInicio : 'Não definido'}</span>
                        </ColumnText>
                    )
                }

                {
                    (PropInvalida(pesquisa.ciclo) && PropInvalida(pesquisa.baseCiclo)) && (
                        <ColumnText>
                            <h4>Término</h4>
                            <span>{pesquisa.dataFim !== null ? pesquisa.dataFim : 'Não definido'}</span>
                        </ColumnText>
                    )
                }

                {
                    (!PropInvalida(pesquisa.ciclo)) && (
                        <ColumnText>
                            <h4>Ciclo</h4>
                            <span>{pesquisa.ciclo}</span>
                        </ColumnText>
                    )
                }

                <Flex alignItems={'center'}>
                    <FaMapMarkerAlt size={22} style={{ marginRight: '.25rem', color: 'var(--a5)' }} />
                    <ColumnText>
                        <h3>{pesquisa.totalAvaliadores ?? 0}</h3>
                        <h4>Avaliadores</h4>
                    </ColumnText>
                </Flex>

                {
                (!PropInvalida(pesquisa.envioInfo)) && (
                    <Flex alignItems={'center'}>
                        {pesquisa.statusDaPesquisa === 0 && <Flex alignItems={'center'} justifyContent={'center'} w={'26px'} h={'28px'}><FaClipboardList size={26} style={{ marginRight: '.5rem', color: 'var(--a5)' }} /></Flex>}
                        {pesquisa.statusDaPesquisa === 1 && <Flex alignItems={'center'} justifyContent={'center'} w={'26px'} h={'28px'}><FaPaperPlane size={26} style={{ marginRight: '.5rem', color: 'var(--a5)' }} /></Flex>}
                        {pesquisa.statusDaPesquisa === 2 && <Flex alignItems={'center'} justifyContent={'center'} w={'26px'} h={'30px'}><FaCheck size={26} style={{ marginRight: '.5rem', color: 'var(--a5)' }} /></Flex>}
                        {ReturnInfoEnvioBox()}
                    </Flex>
                )
                }
            </GridContainer>
            <Flex alignItems={'center'} marginRight={'1rem'}>
                <Menu>
                    <MenuButtonCmp
                        Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                        VarColor='Green2'
                    >Opções
                    </MenuButtonCmp>
                    <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
                        {
                            (pesquisa.dataInicio !== null || (pesquisa.ciclo && pesquisa.enviada)) && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/ResultadoPesquisa`)}/${pesquisa.id}`)}
                                >
                                    <FaTachometerAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Visualizar resultados
                                </StyledMenuItem>
                            )
                        }
                        {
                            (pesquisa.resultadoGeral) && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/ResultadoGeral`)}/${pesquisa.id}`)}
                                >
                                    <FaPeopleGroup style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Visualizar resultado geral
                                </StyledMenuItem>
                            )
                        }
                        {
                            (((pesquisa.dataInicio !== null && PropInvalida(pesquisa.ciclo)) || !PropInvalida(pesquisa.ciclo)) && parseJwt().adm === 'True') && (
                                <StyledMenuItem

                                    onClick={() =>
                                      redirectPage(`${urlApi}/AvaliacaoPesquisa/${pesquisa.id}/statusCsv/?token=${localStorage.getItem('token') as string}`)
                                    }
                                >
                                    <FaCloudDownloadAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Baixar status preenchimento

                                </StyledMenuItem>
                            )
                        }

                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => redirectPage(`${urlApi}/AvaliacaoPesquisa/${pesquisa.id}/basePesquisaCsv/?token=${localStorage.getItem('token') as string}`)}
                                >
                                    <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Baixar CSV resultado
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard !== true && pesquisa.formularioAprovado && !pesquisa.encerrada && !pesquisa.configurada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/Participantes/${pesquisa.id}`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard !== true && pesquisa.formularioAprovado && !pesquisa.encerrada && pesquisa.configurada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/Participantes/${pesquisa.id}/Editar`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.processoOnboard === true && pesquisa.formularioAprovado && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/Participantes/${pesquisa.id}/Processo`)}`)}
                                >
                                    <FaUsers style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Gestão de participantes
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.encerrada && pesquisa.enviada && !pesquisa.processoOnboard && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/EnviarPesquisa`)}/${pesquisa.id}/Editar`)}
                                >
                                    <MdSend style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.formularioAprovado && pesquisa.processoOnboard !== true && !pesquisa.encerrada && !pesquisa.configurada && !pesquisa.enviada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/EnviarPesquisa`)}/${pesquisa.id}`)}
                                >
                                    <MdSend style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.encerrada && pesquisa.formularioAprovado && pesquisa.processoOnboard === true && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/EnviarPesquisa`)}/${pesquisa.id}/Processo/Editar`)}
                                >
                                    <MdSend style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Configurações de envio
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.formularioId !== null && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/VisualizarFormulario/${pesquisa.id}${pesquisa.encerrada ? '?leitura=true' : ''}`)}`)}
                                >
                                    <FaEye style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Visualizar formulário
                                </StyledMenuItem>
                            )
                        }

                        {
                            (!pesquisa.formularioAprovado && pesquisa.formularioId !== null && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/VisualizarFormulario`)}/${pesquisa.id}`)}
                                >
                                    <MdSend style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Aprovar formulário
                                </StyledMenuItem>
                            )
                        }

                        {
                            ((pesquisa.formularioId === null || pesquisa.formularioId === undefined) && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={() => navigate(`${pathname.replace('Gestao', `${modoPesquisa}/Formularios`)}/${pesquisa.id}`)}
                                >
                                    <FaTachometerAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Selecionar formulário
                                </StyledMenuItem>
                            )
                        }

                        {
                            (pesquisa.encerrada && parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={onReabrir}
                                >
                                    <FaRedo style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Reabrir pesquisa
                                </StyledMenuItem>
                            )
                        }

                        {
                            (parseJwt().adm === 'True') && (
                                <StyledMenuItem
                                    onClick={onDelete}
                                >
                                    <FaTrash style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Excluir pesquisa
                                </StyledMenuItem>
                            )
                        }

                    </MenuList>
                </Menu>
            </Flex>
        </PreViewParticipante>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, FormControl, Input, InputGroup, InputLeftElement, Text, Tooltip } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter, FaSearch, FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ImgSah } from '../../../../components/ImgSah'
import LoadingContainer from '../../../../components/LoadingContainer'
import { ModalDelete } from '../../../../components/ModalDelete'
import { Paginador } from '../../../../components/Paginador'
import { SearchCmp } from '../../../../components/SearchCmp'
import { SelectCmp } from '../../../../components/SelectCmp'
import { SwitchCmp } from '../../../../components/SwitchCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { CardGestao } from './Components/CardGestao'
import { ModalCopy } from './Components/ModalCopy'

export interface iGestaoPesquisaInterna {
  id: string
  nomePesquisa: string
  grupoId?: string
  descricao: string
  dataInicio: string
  dataFim?: string
  ciclo: string
  statusPesquisa: string
  statusForm: string
  convites: number
  respostas: number
  anonima: boolean
  trilhaId: string
}

export interface iGrupoPesquisaInterna {
  nome: string
  id: string
}

interface iCopyPesquisa {
  nome: string
  descricao: string
}

export const GestaoPesquisaInternaJornada: React.FC = () => {
  const nav = useNavigate()
  const filtro = new URLSearchParams(window.location.search).get('filtro')

  const [Pesquisas, setPesquisas] = useState<iGestaoPesquisaInterna[]>([])
  const [Grupos, setGrupos] = useState<iGrupoPesquisaInterna[]>([])
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [DeleteGrupoIsOpen, setDeleteGrupoIsOpen] = useState(false)
  const [ModalEncerrarIsOpen, setModalEncerrarIsOpen] = useState(false)

  const [Qtd, setQtd] = useState(0)
  const [Pagina, setPagina] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [PesquisaId, setPesquisaId] = useState<string>()
  const [StatusPesquisa, setStatusPesquisa] = useState(filtro ?? '')
  const [TipoPesquisa, setTipo] = useState('')
  const [GrupoAtivo, setGrupoAtivo] = useState('')
  const [GrupoId, setGrupoId] = useState('')
  const [NomePesquisa, setNomePesquisa] = useState('')

  const [Copy, setCopy] = useState<iCopyPesquisa>()
  const [IsLoading, setIsLoading] = useState(false)

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setPesquisaId(id)
  }

  function onOpenModalEncerrar(id: string): void {
    setModalEncerrarIsOpen(true)
    setPesquisaId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setModalEncerrarIsOpen(false)
  }

  function getGrupos(): void {
    appApi.get('PesquisaInterna/Grupos')
      .then(res => {
        setGrupos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getPesquisas(firstFiltro?: string): void {
    setIsLoading(true)
    appApi.get(`PesquisaInterna/Gestao/?busca=${NomePesquisa}&tipo=${TipoPesquisa}&status=${firstFiltro ?? StatusPesquisa}&tamanho=${Tamanho}&pagina=${Pagina}&grupoAtivo=${GrupoAtivo}`)
      .then(res => {
        if (res.data?.length > 0 || Pagina === 0) {
          setPesquisas(res.data)
          getQuantidade()
        } else {
          setPagina(0)
        }
      })
      .catch(err => console.log(err))
      .finally(() => { setIsLoading(false) })
  }

  function getQuantidade(): void {
    setIsLoading(true)
    appApi.get(`PesquisaInterna/Gestao/Quantidade?busca=${NomePesquisa}&tipo=${TipoPesquisa}&status=${StatusPesquisa}`)
      .then(res => {
        setQtd(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function onDelete(): void {
    appApi.delete(`PesquisaInterna/${PesquisaId ?? ''}`)
      .then(() => getPesquisas())
      .catch(err => console.log(err))
  }

  function onOpenDeleteGrupo(id: string): void {
    setGrupoId(id)
    setDeleteGrupoIsOpen(true)
  }

  function DeleteGrupo(): void {
    appApi.delete(`PesquisaInterna/Grupos/${GrupoId ?? ''}`)
      .then(() => { getGrupos(); setGrupoAtivo('') })
      .catch(err => console.log(err))
  }

  function EncerrarPesquisa(): void {
    appApi.patch(`PesquisaInterna/${PesquisaId ?? ''}/Encerrar`)
      .then(() => getPesquisas(filtro ?? undefined))
      .catch(err => console.log(err))
  }

  function DuplicarPesquisa(texto: string, descricao: string): void {
    const form = {
      texto: texto,
      descricao: descricao
    }

    appApi.post(`PesquisaInterna/Copy/${PesquisaId as string}`, form)
      .then(res => {
        nav(`/Jornada/PesquisaInterna/Formulario/${res.data as string}`)
      })
      .catch(err => console.log(err))
  }

  function onOpenModalCopy(id: string): void {
    setPesquisaId(id)
    setModalCopyIsOpen(true)
    const pesquisa = Pesquisas?.find(e => e.id === id)
    if (pesquisa !== undefined) {
      setCopy({
        descricao: pesquisa.descricao,
        nome: pesquisa.nomePesquisa
      })
    }
  }

  function onCloseModalCopy(): void {
    setPesquisaId(undefined)
    setCopy(undefined)
    setModalCopyIsOpen(false)
  }

  useEffect(() => {
    getPesquisas()
  }, [Pagina, Tamanho, GrupoAtivo])

  useEffect(() => {
    getGrupos()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={onDelete}
      />

      <ModalDelete
        isOpen={ModalEncerrarIsOpen}
        message='Deseja realmente encerrar esta pesquisa?'
        onRequestClose={() => onCloseModalDelete()}
        onConfirm={EncerrarPesquisa}
      />

    <ModalDelete
        onRequestClose={() => setDeleteGrupoIsOpen(false)}
        isOpen={DeleteGrupoIsOpen}
        center={true}
        message={'Deseja excluir esse grupo?'}
        onConfirm={() => DeleteGrupo()}
      />

      <ModalCopy
        isOpen={ModalCopyIsOpen}
        onClose={onCloseModalCopy}
        onCreate={DuplicarPesquisa}
        copy={Copy}
      />

      <Flex justifyContent={'space-between'} mt={'1rem'}>
        <h1>Gestão de pesquisas internas</h1>
        <Flex flexDir={'column'} gap={'.75rem'} alignItems={'end'}>
          <ButtonCmp w={'10rem'} onClick={() => nav('/Jornada/PesquisaInterna/Criar')} VarColor='Green2' size={'lg'}>Criar nova pesquisa</ButtonCmp>
          <SwitchCmp
            OnChange={() => nav('/Jornada/PesquisaInterna/Grupos')}
            value={false}
            legendaEsquerda={'Visão por lista'}
            legendaDireita={'Visão por grupo'}
          />
        </Flex>
      </Flex>

        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'end'} marginBottom={'1rem'}>

          <Flex gap={'1rem'} width='80%'>
            <FormControl width={'30%'} >
              <SelectCmp title='Tipo' OnChange={setTipo}>
                <option value={''}>Todas</option>
                <option value={'0'}>Nova</option>
                <option value={'1'}>Vinculada ao onboard</option>
              </SelectCmp>
            </FormControl>

            <FormControl width={'30%'} >
              <SelectCmp title='Status' OnChange={setStatusPesquisa} value={StatusPesquisa}>
                <option value={''}>Nenhum</option>
                <option value={'Não enviado'}>🟡 Não enviado</option>
                <option value={'Em andamento'}>🔴 Em andamento</option>
                <option value={'Finalizado'}>🟢 Finalizado</option>
              </SelectCmp>
            </FormControl>
          </Flex>

          <Flex w={'32%'} gap={'.5rem'} justifyContent={'end'}>
            <SearchCmp
              width='19rem'
              EnterAction={getPesquisas}
              OnChange={setNomePesquisa}
              placeholder='Pesquisa'
            />

            <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { getPesquisas() }}>Filtrar</ButtonCmp>
          </Flex>
        </Flex>
        {/* {Grupos.length > 0 &&
        <Flex gap={'1rem'}>
          <Box onClick={() => setGrupoAtivo('')} cursor={'pointer'} px={'1rem'} py={'.25rem'} borderRadius={'1rem'} fontWeight={'600'} border={'2px solid var(--Azul)'} color={GrupoAtivo === '' ? 'white' : ''} bgColor={GrupoAtivo === '' ? 'var(--Azul)' : ''}>
            <Text>Geral</Text>
          </Box>
          {Grupos.map(e => {
            return (
              <Flex alignItems={'center'} gap={'.5rem'} _hover={{ bgColor: 'var(--Azul)', color: 'white !important' }} borderRadius={'1rem'} fontWeight={'600'} border={'2px solid var(--Azul)'} color={GrupoAtivo === e.id ? 'white' : ''} bgColor={GrupoAtivo === e.id ? 'var(--Azul)' : ''}>
                <Text onClick={() => setGrupoAtivo(e.id)} cursor={'pointer'} pl={'1rem'} py={'.25rem'} >{e.nome}</Text>
                <Tooltip pr={'1rem'} hasArrow label='Excluir grupo' placement='top' bg='var(--Red)'>
                  <Box mr={'1rem'} cursor={'pointer'} onClick={() => onOpenDeleteGrupo(e.id)}>
                    <FaTrash size={14}/>
                  </Box>
                </Tooltip>
              </Flex>
            )
          })}
        </Flex>
        } */}
        <LoadingContainer loading={IsLoading} linhas={20}>
          {Pesquisas.length > 0 && <Paginador
            next={(e) => setPagina(e)}
            prev={(e) => setPagina(e)}
            tamanho={Tamanho}
            setTamanho={(e) => setTamanho(e)}
            paginaAtual={Pagina}
            qtdElementos={Qtd}
            hideTop
          >
            <Flex flexDir={'column'} gap={'.75rem'}>
              {Pesquisas?.length > 0 &&
                Pesquisas.map((e, i) => {
                  return (
                    <CardGestao
                      key={i}
                      pesquisa={e}
                      onDelete={onOpenModalDelete}
                      onEncerrar={onOpenModalEncerrar}
                      onCopy={onOpenModalCopy}
                    />
                  )
                })
              }

            </Flex>
          </Paginador>}
          {
            (Pesquisas?.length === 0 && !IsLoading) && (
              <ImgSah entidade='pesquisa'>
                <ButtonCmp onClick={() => nav('/Jornada/PesquisaInterna/Criar')}
                  VarColor='Green2' size={'lg'}
                >Criar nova pesquisa</ButtonCmp>
              </ImgSah>
            )
          }
        </LoadingContainer>
    </Body>
  )
}

import { Flex, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { Button } from '../../../../../../components/Button'
import { InputCmp } from '../../../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'

interface iCopyPesquisa{
  nome: string
  descricao: string
}

interface iModalProps {
  isOpen: boolean
  copy?: iCopyPesquisa
  onCreate: (texto: string, descricao: string) => void
  onClose: () => void
}

export const ModalCopy: React.FC<iModalProps> = ({ isOpen, copy, onClose, onCreate }) => {
  const [Texto, setTexto] = useState('')
  const [Descricao, setDescricao] = useState('')

  useEffect(() => {
    if (copy !== undefined) {
      setTexto(copy?.nome + ' - cópia')
      setDescricao(copy?.descricao)
    }
  }, [copy])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
        <Flex flexDir={'column'} width='50rem'>
            <Flex borderBottom={'1px solid var(--c6)'}>
                <Flex width={'100%'} justifyContent={'space-between'} padding='1rem'>
                    <h3>Duplicar pesquisa</h3>
                    <MdClose onClick={onClose} size={24} cursor={'pointer'}/>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='.75rem' padding={'1rem'}>
                <FormControl>
                    <InputCmp label='Nome da pesquisa' value={Texto} borderColor={'var(--Gray4)'} OnChange={setTexto}/>
                </FormControl>

                <FormControl>
                    <TextAreaCmp label='Descrição' value={Descricao} borderColor={'var(--Gray4)'} OnChange={setDescricao}/>
                </FormControl>
            </Flex>
            <Flex padding={'1rem'} justifyContent={'end'}>
                <Button VarColor='v2' onClick={() => onCreate(Texto, Descricao)}>Duplicar</Button>
            </Flex>
        </Flex>
    </Modal>
  )
}

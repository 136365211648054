import { Box, Button, Flex, VStack, Text, HStack, Select, Input, useClipboard, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBan, FaBriefcase, FaCheck, FaClipboard, FaEye, FaFileCsv, FaLink, FaSearch, FaSync, FaUsers } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi, urlLocal } from '../../services/appApi'
import { parseJwt } from '../../services/token'

import { AutorizarPI, ObterToken } from '../../Utils/Helper'

import { Body } from '../Layouts/Body'
// import imgPerfil from '../../assets/pi/perfil.png'//

import { TabelaAderencia } from './components/TabelaAderencia'
import { numberFormatter } from './Dashboard'
import { IJobTarget } from './JobTarget/Create'

type INavProps = {
  jobId: string
}

interface IperfilBa{
  id?: string
  nome: string
  dataPesquisa: string
  perfil: string
  urlImgPerfil?: string
}

interface IperfilDto{
  perfil: IperfilBa
  outrosCargos: IOutrosCargos[]
}

interface IOutrosCargos{
  nome: string
  jobId: string
}

interface IJobPerfis{
  jobTarget: IJobTarget
  numeroPerfis: number
  numeroPerfisAdaptativos: number
  numeroPerfisAderentes: number
  numeroPerfisNaoAderentes: number
  perfis: IperfilDto[]
}

export const DetalhesCargo: React.FC = () => {
  const navigate = useNavigate()
  const { jobId } = useParams<INavProps>()
  const [paginaAtual, setPaginaAtual] = useState(0)
  const [numeroPaginas, setNumeroPaginas] = useState(0)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState('')
  const [filtro, setFiltro] = useState('3')
  const [isLoading, setIsLoading] = useState(true)
  const [value, setValue] = useState(`http://${urlLocal}/pi/`)
  const { onCopy, hasCopied } = useClipboard(value)
  const [model, setModel] = useState<IJobPerfis>()
  const [Busca, setBusca] = useState('')

  function getJobTargets(jobId: string): void {
    console.log('obter perfil')
    appApi.get<IJobPerfis>(`AssessmentPI/JobTarget/${jobId}/perfis/?index=${paginaAtual}&filtro=${filtro}${dataInicio.length > 0 ? `&dataInicio=${dataInicio}` : ''}${dataFim.length > 0 ? `&dataFim=${dataFim}` : ''}&consultoria=${parseJwt().consultoria}${Busca.length > 0 ? `&busca=${Busca}` : ''}`)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setModel(res.data)
          const numeroP = Math.ceil(res.data.numeroPerfis / 50)
          setNumeroPaginas(numeroP > 1 ? numeroP - 1 : 1)
          setIsLoading(false)
        }
      })
      .catch(err => { console.log(err); setIsLoading(false) })
  }

  function obterTotal(): number {
    return (model?.numeroPerfis ?? 0) + (model?.numeroPerfisNaoAderentes ?? 0)
  }

  useEffect(() => {
    setPaginaAtual(0)
    if (jobId) {
      getJobTargets(jobId ?? '')
      setValue(`${urlLocal}/pi/${jobId}`)
    }
    setIsLoading(false)
    if (!AutorizarPI) {
      navigate('/')
    }
  }, [jobId])

  useEffect(() => {
    setIsLoading(true)
    getJobTargets(jobId ?? '')
  }, [paginaAtual, filtro, dataInicio, dataFim])

  useEffect(() => {
    console.log(`carregando:${isLoading ? 'Sim' : 'nao'}`)
  }, [isLoading])

  function obterNumeroPerfis(): number {
    switch (filtro) {
      case '1':
        return model?.numeroPerfisAderentes ?? 0

      case '2':
        return model?.numeroPerfisAdaptativos ?? 0

      case '3':
        return model?.numeroPerfis ?? 0

      case '4':
        return model?.numeroPerfisNaoAderentes ?? 0

      case '5':
        return (model?.numeroPerfisNaoAderentes ?? 0) + (model?.numeroPerfis ?? 0)

      default:
        return 0
    }
  }

  return (<Body>

<Flex justify='center'>
<Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>

<Button
onClick={() => navigate('/AssessmentPi/Dashboard2')}
bg='var(--c6)' width='5rem' height='2.5rem' size='xs' m={3}>Voltar</Button>
<Flex justifyContent='space-between'>

<VStack alignItems='start' p='4'>
<HStack>
<FaBriefcase size='1.5rem'/>
<Text fontWeight='bold' fontSize='1.8rem' >{model?.jobTarget.nome}</Text>
</HStack>

<HStack>
<FaCheck color='var(--Green2)' size='1.5rem' />
<Text ml={2} color='var(--Green2)' fontSize='1.1rem' >Perfis aderentes: { model?.jobTarget.perfisAderentes.map((s, i) => `${s}${i !== model.jobTarget.perfisAderentes.length - 1 ? ',' : ''} `)}</Text>
</HStack>
<HStack>
<FaSync color='var(--Blue1)' size='1.5rem'/>
<Text color='var(--a1)' fontSize='1.1rem' ml={2}>Perfis flexibilizados: {model?.jobTarget.perfisAdaptativos.map((s, i) => `${s}${i !== model.jobTarget.perfisAdaptativos.length - 1 ? ',' : ''}`)}</Text>
</HStack>
</VStack>
<HStack mr={3}>
{/* <Button onClick={() => { window.location.href = `${urlApi}/AssessmentPI/JobTarget/${jobId ?? ''}/csv/?filtro=${3}` }}
 bg='var(--Rosa)' width='10rem' height='2.5rem' size='xs' m={1}><Box mx={3}><FaFileCsv/></Box> Exportar CSV</Button> */}

{!parseJwt().consultoria && (<Button onClick={() => navigate(`/AssessmentPi/JobTarget/Edit/${jobId ?? ''}`)}
 bg='var(--a2)' width='7rem' height='2.5rem' size='xs' m={1}>Editar cargo</Button>)}
</HStack>

</Flex>

<Flex m='1rem' direction={'column'} >

  <Text fontWeight={'bold'}><FaLink/> Link para preenchimento do perfil</Text>
<Flex mb={2}>
        <Input
        color={'var(--c7)'}
        fontWeight={'medium'}
          placeholder={'URL da parada'}
          value={value}

          onChange={(e) => {
            setValue(value)
          }}
          mr={2}
          />
        <Button width='7rem' height='2.5rem' size='xs' bgColor={'blue.300'} onClick={onCopy} rightIcon={<FaClipboard/>}>{hasCopied ? 'Link copiado!' : 'Copiar link'} </Button>
      </Flex>

          </Flex>
<Flex justifyContent='space-around' wrap='wrap' gap='1rem' >
  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Roxo)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Roxo)'>Total de perfis coletados</Text>
        <HStack m={0} p={0}>
          <FaUsers color='var(--Roxo)' size='2.5rem'/>
          <Text fontSize='4xl' color='var(--Roxo)'>{numberFormatter.format(obterTotal()) }</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Green2)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Green2)'>Perfis aderentes</Text>
        <HStack m={0} p={0}>
          <FaCheck color='var(--Green2)' size='2.5rem'/>
          <Text fontSize='4xl' color='var(--Green2)'>{numberFormatter.format(model?.numeroPerfisAderentes ?? 0)}</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Blue1)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Blue1)'>Perfis flexibilizados</Text>
        <HStack m={0} p={0}>
          <FaSync color='var(--Blue1)' size='2.5rem'/>
          <Text fontSize='4xl' color='var(--Blue1)'>{numberFormatter.format(model?.numeroPerfisAdaptativos ?? 0)}</Text>
        </HStack>
      </VStack>
  </Box>

  <Box width='15rem' height='8rem' border='2px' borderColor='var(--Yellow)' borderRadius='xl'>
      <VStack>
        <Text mt={2} color='var(--Yellow)'>Perfis não aderentes</Text>
        <HStack m={0} p={0}>
          <FaBan color='var(--Yellow)' size='2.5rem'/>
          <Text fontSize='4xl' color='var(--Yellow)'>{numberFormatter.format(model?.numeroPerfisNaoAderentes ?? 0)}</Text>
        </HStack>
      </VStack>
  </Box>

</Flex>
<Flex mx={8} mt={8} mb='4' justifyContent={'space-between'} flexWrap='wrap' alignItems={'center'}>
  <HStack color='var(--c7)' m={4}>
  <FaEye/><Text>Exibir</Text>
    </HStack>
<Select value={filtro} width='18rem'
  bg='#fcfbf4'
  borderColor='tomato'
  color='var(--c7)'
  onChange={(value) => {
    setPaginaAtual(0)
    setFiltro(value.currentTarget.value)
  }}
>
<option value='5'>Todos os perfis</option>
<option value='1'>Perfis aderentes</option>
<option value='2'>Perfis flexibilizados</option>
<option value='3'>Perfis aderentes e flexibilizados</option>
<option value='4'>Perfis não aderentes</option>

</Select>
<HStack ml='1rem'>

  <Text>De</Text>
<Input width={'16rem'}
 placeholder="data início"
 size="md"
 type="date"
 value={dataInicio}
 onChange={(s => setDataInicio(s.target.value))}
/>
<HStack color='var(--c7)' mr={4}>
 <Text>até</Text>
    </HStack>
<Input width={'16rem'}
 onChange={(s => setDataFim(s.target.value))}
 placeholder="data início"
 size="md"
 type="date"
 value={dataFim}
/>
 </HStack>
 <InputGroup mt='1rem' width={'15rem'} >
    <InputLeftElement bgColor='white' color='gray.300' borderLeft={'1px'} borderTop={'1px'} borderBottom={'1px'}
    borderRadius='sm' borderColor={'gray.200'}
    pointerEvents='none'
    children={<FaSearch size={'.8rem'} color='gray.300' />}
    />
    <Input bgColor='white' type='tel' placeholder='Busca' value={Busca} onChange={(e) => setBusca(e.target.value)} onKeyPress={e => {
      if (e.key === 'Enter') { getJobTargets(jobId ?? '') }
    }} />
  </InputGroup>
  {parseJwt().consultoria === 'False' && <Button
  onClick={() => { window.location.href = `${urlApi}/AssessmentPI/JobTarget/${jobId ?? ''}/csv/?filtro=${filtro}&token=${ObterToken()}&busca=${Busca ?? ''}` }}
  backgroundColor='var(--Rosa)' mt='1rem' borderColor='var(--a1)' border='1px' size='sm' fontWeight='normal' borderRadius='xl'>
  <FaFileCsv /><Text ml={2}>Exportar CSV</Text></Button>}
  </Flex>
<TabelaAderencia
isLoading={isLoading}
prev={() => { setPaginaAtual(paginaAtual > 0 ? paginaAtual - 1 : 0) }}
next={() => { setPaginaAtual(paginaAtual < numeroPaginas ? paginaAtual + 1 : numeroPaginas) }}
numeroPaginas={ Math.ceil((obterNumeroPerfis() ?? 0) / 50)} paginaAtual={paginaAtual} titulo={'Nome da Tabela'}
job={model}
/>
</Box>

    </Flex>
  </Body>)
}

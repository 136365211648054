import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { iCardEtapa, iReuniao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { FaClipboardList, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { PropInvalida } from '../../../../../Utils/Helper'
interface ICardReuniao {
  etapa: iCardEtapa
  isOpen: boolean
}

export const CardReuniao: React.FC<ICardReuniao> = ({ etapa, isOpen }) => {
  const [Reuniao, setReuniao] = useState<Omit<iReuniao, 'id'>>()

  function GetReuniaoPorEtapaId(): void {
    appApi.get(`Reuniao/${etapa.interacoes[0].idExterno}`)
      .then(response => { setReuniao(response.data) }).catch(err => console.error(err))
  }

  function ObterNomePeloTipo(Tipo: number): string {
    if (Tipo === 0) {
      return 'RH'
    } else if (Tipo === 1) {
      return 'Colaborador'
    } else if (Tipo === 2) {
      return 'Gestor'
    } else if (Tipo === 3) {
      return 'Pares'
    } else if (Tipo === 4) {
      return 'Subordinados'
    } else if (Tipo === 5) {
      return 'Alta liderança'
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Reuniao)) {
      GetReuniaoPorEtapaId()
    }
  }, [isOpen])
  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={18} />
        <div>
          <h4>Responsável</h4>
          <strong>{Reuniao?.responsavel === 0 ? 'RH' : Reuniao?.responsavel === 1 ? 'Gestor' : Reuniao?.emailResponsavel}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaUserAlt size={18} />
        <div>
          <h4>Participantes</h4>
          {
            Reuniao?.participantes !== undefined && Reuniao.participantes.length > 0
              ? <div>
                {
                  Reuniao.participantes?.map((e: any, i) => {
                    return (
                      <strong key={i}>{ObterNomePeloTipo(e)}{Reuniao.participantes !== undefined && i === Reuniao.participantes?.length - 1 ? '' : '/'}</strong>
                    )
                  })
                }
              </div>
              : <strong>O responsável ainda não selecionou os participantes</strong>
          }

        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={18} />
        <div>
          <h4>Local da reunião</h4>
          <strong>{Reuniao?.tipo === 2 ? 'Definido pelo responsável' : Reuniao?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Reuniao?.descricao !== null) && (
          <>
            <FlexIcon>
              <FaClipboardList size={18} />
              <div>
                <h4>Descrição</h4>
                <small dangerouslySetInnerHTML={{ __html: Reuniao?.descricao ?? '' }}></small>
              </div>
            </FlexIcon>
          </>
        )
      }
    </Container>
  )
}

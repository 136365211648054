import React from 'react'

import { Container } from './styles'

interface ISectionHeaderProps {
  title: string
  showFilters?: boolean
  Button?: any
}

export const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title,
  showFilters = false,
  Button
}) => {
  return (
    <Container>
      <div>
        <h2>{title}</h2>
      </div>
      {showFilters && (
        <aside>
          <input type="text" />
          <input type="text" />
          <input type="text" />
        </aside>
      )}
      {Button}
    </Container>
  )
}

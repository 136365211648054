import styled from 'styled-components'

export const InputEmail = styled.input`
  width: 100%;
  height: 1.6rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--c5);
  font-size: 12px;
  padding-left: .25rem;

  :focus {
    outline: none;
    border-bottom: 1px solid var(--Azul);
  }
`

export const BoxEmail = styled.div`
 padding: .25rem 0 .25rem 0;
 display: flex;
 justify-content: center;
 border: 1px solid var(--c5);
 border-radius: .25rem;
 width: 5rem;
`

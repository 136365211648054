/* eslint-disable no-tabs */
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { FaFileSignature } from 'react-icons/fa'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { useLocation } from 'react-router-dom'
import { parseJwt } from '../../../services/token'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../../PesquisaIndicativa/components/Timeline'
import { BoxModelo } from './Components/BoxModelo/Index'

export const SelecionarModelo: React.FC = () => {
  const { pathname } = useLocation()
  const isAH = parseJwt().eid === process.env.REACT_APP_AH_EMPRESA_ID
  // const isIcecloud = parseJwt().eid === process.env.REACT_APP_ICECLOUD_EMPRESA_ID
  // const isCobb = parseJwt().eid === process.env.REACT_APP_COBB_EMPRESA_ID
  // const isPha = parseJwt().eid === process.env.REACT_APP_PHA_EMPRESA_ID
  return (
		<Body>
			{isAH &&
				<TimelineCriarPesquisa color={[]} />
			}
			<WhiteContainer>
				<h2>Selecione o estilo de pesquisa que deseja utilizar</h2>
				<Grid templateColumns='repeat(2, 1fr)' gap={'.75rem'} mt={'1rem'} justifyContent={'center'}>
					<GridItem>
						<BoxModelo tipo='Quantitativa'>
							<Flex gap={'.5rem'} fontSize={'1.125rem'} fontWeight={'700'} alignItems={'center'}>
								<FaFileSignature/>
								<Text>Quantitativa</Text>
							</Flex>
							<Text my={'.375rem'} fontSize={'.875rem'}>Com esta metodologia, os participantes irão responder uma pesquisa com <Text as={'span'} textDecoration={'underline'} fontWeight={'700'}>escalas de níveis.</Text></Text>

						</BoxModelo>
					</GridItem>
					<GridItem>
						<BoxModelo
							isDisabled={pathname.includes('Percepcao')}
							tipo='Indicativa'>
							<Flex gap={'.5rem'} fontSize={'1.125rem'} fontWeight={'700'} alignItems={'center'}>
								<FaFileSignature />
								<Text>Indicativa</Text>
							</Flex>
							<Text my={'.375rem'} fontSize={'.875rem'}>Com esta metodologia, os participantes irão <Text as={'span'} textDecoration={'underline'} fontWeight={'700'}>indicar as competências de destaque e para desenvolvimento.</Text></Text>
						</BoxModelo>
					</GridItem>
				</Grid>
			</WhiteContainer>
		</Body>
  )
}

import { Divider, Flex, Spinner, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { BsFillFileEarmarkPdfFill } from 'react-icons/bs'
import { FaDownload, FaFile, FaFileCsv, FaFileImage, FaFileVideo, FaFolder } from 'react-icons/fa'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalBase } from '../../../../components/ModalBase'
import { TextAreaCmp } from '../../../../components/TextareaCmp'
import { appApi } from '../../../../services/appApi'
import { FileBox, GuideText, LabelFile } from '../../Editar/Components/CardImersao/styles'

interface iModalProps {
  isOpen: boolean
  ItemId: string
  onClose: () => void
  onConfirm: (arquivo?: iFileData, texto?: string) => void
}

interface iFileData {
  uri: string
  size: string
  name: string
}

export const ModalObservacao: React.FC<iModalProps> = ({ isOpen, onConfirm, onClose, ItemId }) => {
  const toast = useToast()
  const [SpinnerOn, setSpinnerOn] = useState(false)
  const [Texto, setTexto] = useState('')
  const [Arquivo, setArquivo] = useState('')
  const [Tamanho, setTamanho] = useState('')
  const [NomeArquivo, setNomeArquivo] = useState('')

  function Clean(): void {
    setTexto('')
    setTamanho('')
    setArquivo('')
    setNomeArquivo('')
    onConfirm(Arquivo.length > 0 ? { name: NomeArquivo, uri: Arquivo, size: Tamanho } : undefined, Texto.length > 0 ? Texto : undefined)
  }

  const AtualizarArquivo = (event: any): void => {
    setSpinnerOn(true)
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    appApi.post('Integracao/Uploadfile', formdata)
      .then(resposnse => {
        setTamanho(resposnse.data.size)
        setArquivo(resposnse.data.uri)
        setNomeArquivo(resposnse.data.name)
        setSpinnerOn(false)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false
        })
      }).catch(e => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false
        })
        setSpinnerOn(false)
      })
  }

  function GetFileIcon(tipo: string): any {
    const tipoFormatado = tipo.toLowerCase()
    switch (tipoFormatado) {
      case 'csv':
        return <FaFileCsv/>
      case 'svg':
      case 'jpeg':
      case 'png':
      case 'jpg':
        return <FaFileImage/>
      case 'pdf':
        return <BsFillFileEarmarkPdfFill/>
      case 'mov':
      case 'mp4':
        return <FaFileVideo/>
      default:
        return <FaFile/>
    }
  }

  return (
    <ModalBase
      Titulo="Adicione uma observação para esse item do checklist"
      isOpen={isOpen}
      onClose={onClose}
      Width='48rem'
    >
      <Flex p={'1rem 1rem 1rem 1rem'} gap={'1rem'} alignItems='center' justifyContent={'center'}>
        <FileBox style={{ width: '38rem' }}>
          {
            Arquivo.length === 0
              ? <Flex flexDir={'column'} gap={'.75rem'} justifyContent={'center'} alignItems={'center'}>
                <FaDownload color='var(--Azul)' size={35} />
                {
                  SpinnerOn
                    ? <Spinner my={'2rem'}></Spinner>
                    : <>
                  <Flex flexDir={'column'}>
                    <GuideText>Arraste e solte um arquivo para acompanhar sua observação sobre este item (opcional)</GuideText>
                  </Flex>
                  <input id={ItemId} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                  <LabelFile theme={'1961E0'} htmlFor={ItemId}><FaFolder />Selecionar arquivo</LabelFile></>
                }
              </Flex>
              : <>
                {
                  SpinnerOn
                    ? <Spinner my={'2rem'}></Spinner>
                    : <>
                  <Flex marginBottom={'1rem'} fontSize={'4rem'} flexDir={'column'}>
                    <Text color={'var(--Azul)'}>
                      {GetFileIcon(NomeArquivo.split('.')[NomeArquivo.split('.').length - 1])}
                    </Text>
                    <Flex flexDir={'column'} fontSize={'.875rem'}>
                      <span>{NomeArquivo}</span>
                      <small>Tamanho: <strong>{Tamanho}</strong></small>
                    </Flex>
                  </Flex>
                  <div>
                    <input id={ItemId} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} /><LabelFile theme={'1961E0'} htmlFor={ItemId}><FaFolder />Selecionar arquivo</LabelFile>
                  </div>
                  </>
                }
              </>
          }
        </FileBox>
        <Divider orientation='vertical' borderColor={'var(--c6)'} mx={'.75rem'} opacity='1' height={'17.25rem'}/>
        <TextAreaCmp
          label='Descrição da observação'
          placeholder='Escreva aqui o texto da sua observação'
          value={Texto}
          height={'17.5rem'}
          OnChange={setTexto}
        />
      </Flex>
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={Clean}>Criar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Column, FileBox,
  FirstDiv, LabelFile,
  LabelSelecionado, GuideText, BoxIntegracao, VideoBox
} from './styles'
import { FaDownload, FaFolder } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import {
  Flex, FormControl, Spinner, useToast
} from '@chakra-ui/react'
import { iImg } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { FormatarMensagemEditorDeTexto } from '../../../../../Utils/Helper'
import { InputCmp } from '../../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'

export interface iVideoIntroducao {
  tipo: number
  titulo: string
  descricao: string
  arquivo: iImg
}

interface ITipoCardIntegracaoVideo {
  ItemIntegracao: iVideoIntroducao
  onUpdateInput: (item: iVideoIntroducao) => void
}

export const CardVideo: React.FC<ITipoCardIntegracaoVideo> = ({ ItemIntegracao, onUpdateInput }) => {
  const toast = useToast()

  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('')
  const [SpinnerOn, setSpinnerOn] = useState(false)

  const [Arquivo, setArquivo] = useState<iImg>({
    name: '',
    size: '',
    uri: ''
  })

  useEffect(() => {
    console.log(ItemIntegracao)
    setDescricao(ItemIntegracao.descricao)
    setTitulo(ItemIntegracao.titulo)
    setArquivo(ItemIntegracao.arquivo)
  }, [ItemIntegracao])

  const AtualizarArquivo = (event: any): void => {
    setSpinnerOn(true)
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    appApi.post('PrimeiroAcesso/uploadfile', formdata).then(resposnse => {
      onUpdateInput({
        titulo: Titulo,
        descricao: Descricao,
        tipo: ItemIntegracao.tipo,
        arquivo: resposnse.data
      })
      toast({
        title: 'Arquivo cadastrado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 2000

      })
      setSpinnerOn(false)
    }).catch(() => {
      toast({
        title: 'Vídeo excedeu o limite de 25 MB',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
      setSpinnerOn(false)
    })
  }

  return (
    <BoxIntegracao>
      <Flex margin={'1rem 0rem 1rem 0rem'}>
        <FirstDiv>
          {
            <FileBox>
              {
                Arquivo.uri.length === 0
                  ? <>
                    <FaDownload color='var(--c7)' size={35} />
                    {
                        SpinnerOn
                          ? <Spinner my={'2rem'}></Spinner>
                          : <>
                        <GuideText>Arraste e solte o arquivo de vídeo para fazer o envio</GuideText>
                        <small>(MP4 ou MOV)
                          <br/>Tamanho limite: 25 MB</small>
                        <input id={'iOne'} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} /></>
                    }
                    <LabelFile theme={'4578D6'} htmlFor={'iOne'} style={{ borderRadius: '.25rem', fontSize: '14px', padding: '.75rem 1rem' }}><FaFolder />Selecionar arquivo</LabelFile>
                  </>
                  : <>
                    {
                      SpinnerOn
                        ? <Spinner my={'2rem'}></Spinner>
                        : <Flex marginBottom={'1rem'}>
                        <VideoBox src={Arquivo.uri} />
                        <Column>
                          <span>{Arquivo.name}</span>
                          <small>Tamanho: {Arquivo.size}</small>
                        </Column>
                      </Flex>
                    }
                    <div>
                      <input id={'file2'} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                      <LabelSelecionado htmlFor={'file2'} style={{ borderRadius: '.25rem', fontSize: '14px', padding: '.75rem 1rem' }}><FaFolder />Selecionar arquivo</LabelSelecionado>
                    </div>
                  </>
              }
            </FileBox>
          }
        </FirstDiv>

        <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
          <FormControl>
            <InputCmp
              label='Titulo'
              value={Titulo}
              OnChange={setTitulo}
              placeholder='Seja muito bem-vindo'
              onBlur={() => onUpdateInput({
                titulo: Titulo,
                descricao: Descricao,
                tipo: ItemIntegracao.tipo,
                arquivo: Arquivo
              })}
            />
          </FormControl>

          <FormControl height={'100%'} marginTop={'1rem'}>
            <TextAreaCmp
              label='Descrição'
              value={Descricao}
              OnChange={setDescricao}
              minH={'14rem'}
              placeholder='Deixe um texto para aparecer junto o video'
              onBlur={() => onUpdateInput({
                titulo: Titulo,
                descricao: FormatarMensagemEditorDeTexto(Descricao ?? ''),
                tipo: ItemIntegracao.tipo,
                arquivo: Arquivo
              })}
            />
          </FormControl>
        </Flex>
      </Flex>
    </BoxIntegracao>
  )
}

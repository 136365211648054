import {
  Flex,
  FormControl, Radio, RadioGroup, Stack,
  Text, useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iEtapa, iNomeId, iReuniaoInput } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { SubtitleDiv, Main } from './styles'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { PropInvalida, ValidarEtapa } from '../../../../Utils/Helper'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { FaClock } from 'react-icons/fa'
import { ModalConfiguracao } from './components/ModalConfiguracao'
import { InfoTooltipCmp } from '../../../../components/InfoTooltipCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { FindUser } from '../../../../components/FindBox'

type LocationProps = {
  trilhaId: string
  etapaId?: string
  processoId?: string
}

interface iEtapaReuniao {
  etapa: iEtapa
  reuniao: iReuniaoInput
}

export const Reuniao: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { trilhaId, etapaId, processoId } = useParams<LocationProps>()
  const [Descricao, setDescricao] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  const [Model, setModel] = useState<iReuniaoInput>({
    responsavel: 0,
    tipo: 0,
    titulo: '',
    participantes: []
  })

  const [Salvar, setSalvar] = useState(false)
  const [EditEtapa, setEditEtapa] = useState<iEtapa>({})

  const [ModalIsOpen, setModalIsOpen] = useState(false)
  const isCheck = (value: string): boolean => Model.participantes.some(r => r === parseInt(value))

  const handleCheckboxParticipanteClick = (value: string): void => {
    if (value !== '6') {
      if (Model.participantes.some(e => e.toString() === value)) {
        const participantes = [...Model.participantes].filter(e => e.toString() !== value)
        setModel({ ...Model, participantes: participantes })
      } else {
        const participantes = [...Model.participantes]
        participantes.push(parseInt(value))
        setModel({ ...Model, participantes: participantes })
      }
    } else {
      if (!Model.participantes.some(e => e === 6)) {
        setModel({ ...Model, participantes: [0, 1, 2, 3, 4, 5, 6] })
      } else {
        setModel({ ...Model, participantes: [] })
      }
    }
  }

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => {
        setColaboradores(res.data)
      })
      .catch(err => console.log(err))
  }

  function AdicionarReuniao(): void {
    if (!ValidarEtapa(EditEtapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ReuniaoEtapa/${trilhaId}` : processoId ? `ReuniaoEtapaColaborador/${processoId}` : ''
    appApi.post(`${url}/`, { reuniao: Model, etapa: EditEtapa }).then(() => {
      toast({
        title: 'Reunião cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao cadastrar reunião',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ObterReuniao(): void {
    const url = trilhaId ? `ReuniaoEtapa/${etapaId ?? ''}` : processoId ? `ReuniaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}` : ''
    appApi.get(url).then(response => {
      const data: iEtapaReuniao = response.data
      setModel(data.reuniao)
      setEditEtapa(data.etapa)
      setIsLoading(false)
    }).catch(error => console.log(error))
    setDescricao(Model.descricao ?? '.')
  }

  function AtualizarEtapa(reenviar?: boolean): void {
    if (!ValidarEtapa(EditEtapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const url = trilhaId
      ? `ReuniaoEtapa/${etapaId ?? ''}`
      : processoId
        ? `ReuniaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}?reenviar=${reenviar ? 'true' : 'false'}`
        : ''
    appApi.put(`${url}`, { reuniao: Model, etapa: EditEtapa }).then(() => {
      toast({
        title: 'Reunião atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-1)
    }).catch(() => {
      toast({
        title: 'Erro ao atualizar reunião',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ContinuarConfiguracao(): void {
    if (Model.titulo.length < 5) {
      toast({
        title: 'O assunto da reunião deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.participantes.length <= 0) {
      toast({
        title: 'A reunião deve conter no mínimo 1 participante',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    setSalvar(true)
  }

  function Agendar(dataInicio: string, hInicio: string, duracao: string): void {
    const form = {
      reuniao: Model,
      configuracao: {
        dataInicio: `${dataInicio}T${hInicio}`,
        Duracao: parseInt(duracao),
        local: Model.local
      }
    }

    appApi.post(`ReuniaoEtapaColaborador/${processoId}/Salvar`, form)
      .then(res => navigate(`/Rh/Reuniao/Config/${res.data}`))
      .catch(() => console.log('Erro'))
  }

  useEffect(() => {
    if (etapaId !== undefined) {
      ObterReuniao()
    }
  }, [etapaId])

  useEffect(() => {
    ObterColaboradores()
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [Descricao])

  return (
    <Body>
      {ModalIsOpen &&
        <ModalConfiguracao
          onClose={() => setModalIsOpen(false)}
          onSave={Agendar}
        />
      }
      <Main>
        {
          !Salvar
            ? <WhiteContainer>
              <Flex flexDir={'column'} gap={'.75rem'}>
                  <h1>Reunião</h1>
                  <FormControl>
                    <InputCmp
                      label='Assunto da reunião'
                      value={Model.titulo}
                      OnChange={(e) => { setModel({ ...Model, titulo: e }) }}
                      placeholder='Assunto da reunião'
                      fontWeight='500'
                    />
                  </FormControl>

                  <Flex flexDir={'column'}>
                    <SubtitleDiv>
                      <Text mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Responsável pela reunião</Text>
                      <span>Essa pessoa definirá a data e poderá alterar os participantes</span>
                    </SubtitleDiv>

                    <Flex flexDir={'column'}>
                      <RadioGroup
                        onChange={(e) => setModel({ ...Model, responsavel: parseInt(e), emailResponsavel: e !== '2' ? undefined : Model.emailResponsavel })}
                        value={Model.responsavel.toString()}
                        size={'sm'}
                      >
                        <Stack direction='row'>
                          <Radio value='0'>Rh</Radio>
                          <Radio value='1'>Gestor</Radio>
                          <Radio value='2'><Flex alignItems={'center'} gap={'.5rem'}>Outros <InfoTooltipCmp VarColor='Green2' label='Digite o email da pessoa que irá organizar essa reunião'/></Flex></Radio>
                        </Stack>
                      </RadioGroup>
                      {
                        (Model.responsavel === 2) && (
                          <Flex w={'15rem'} mt={'.325rem'}>
                            <FindUser
                              lista={Colaboradores}
                              onChoice={e => { setModel({ ...Model, emailResponsavel: Colaboradores.find(a => a.id === e)?.email ?? '' }) }}
                              differentValueProp='email'
                              _onChange={(e) => setModel({ ...Model, emailResponsavel: e })}
                              placeholder='Digite o email do responsável'
                            />
                          </Flex>
                        )
                      }
                    </Flex>
                  </Flex>

                  <Flex flexDir={'column'} gap='.5rem'>
                    <Text mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Participantes</Text>
                    <Stack direction={'row'}>
                      <CheckboxCmp
                        isChecked={isCheck('1')}
                        OnChange={() => handleCheckboxParticipanteClick('1')}>Colaborador
                      </CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('0')}
                        OnChange={() => handleCheckboxParticipanteClick('0')}
                      >RH</CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('2')}
                        OnChange={() => handleCheckboxParticipanteClick('2')}
                        >Gestor</CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('3')}
                        OnChange={() => handleCheckboxParticipanteClick('3')}
                        >Pares</CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('4')}
                        OnChange={() => handleCheckboxParticipanteClick('4')}
                        >Subordinados</CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('5')}
                        OnChange={() => handleCheckboxParticipanteClick('5')}
                        >Alta liderança</CheckboxCmp>

                      <CheckboxCmp
                        isChecked={isCheck('6')}
                        OnChange={() => handleCheckboxParticipanteClick('6')}
                        >Todos</CheckboxCmp>
                    </Stack>
                    <Text fontSize={'sm'} fontWeight={500}>O responsável poderá alterar os participantes</Text>
                  </Flex>

                  <Flex flexDir={'column'}>
                    <Text mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Local</Text>
                    <RadioGroup
                      onChange={(e) => setModel({ ...Model, tipo: parseInt(e), local: e === '2' ? undefined : Model.local })}
                      value={Model.tipo.toString()}
                      size={'sm'}
                    >
                      <Stack direction='row'>
                        <Radio value='0'>Online</Radio>
                        <Radio value='1'>Presencial</Radio>
                        <Radio value='2'>Definido pelo responsável pela reunião</Radio>
                      </Stack>

                      {
                        (Model.tipo === 0 || Model.tipo === 1) && (
                          <InputCmp
                            marginTop={'.5rem'}
                            borderColor={'var(--Gray4)'}
                            value={Model.local ?? ''}
                            type={'text'}
                            width={'30rem'}
                            placeholder='Digite o local em que a reunião vai acontecer'
                            OnChange={(e) => setModel({ ...Model, local: e })}
                          />
                        )
                      }
                    </RadioGroup>
                  </Flex>

                  <div>
                    <SubtitleDiv>
                      <Text mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Descrição</Text>
                      <span>Digite um texto com informações importantes ou pauta que deve ser enviado para os participantes</span>
                    </SubtitleDiv>
                    {((!IsLoading) || (Descricao === '')) && (
                      <EditorTexto placeholder='Descreva a pauta da sua reunião' initialValue={Model.descricao ?? ''} onChange={(e: any) => { setModel({ ...Model, descricao: e }) }} />
                    )}
                  </div>
              </Flex>
              <Flex justifyContent={'end'} marginTop={'1rem'} gap={'.5rem'}>
                <ButtonCmp
                  VarColor='c6'
                  onClick={() => navigate(-1)}
                >Voltar</ButtonCmp>
                {
                  !etapaId
                    ? <ButtonCmp VarColor='Green2' onClick={() => ContinuarConfiguracao()}>Salvar e continuar</ButtonCmp>
                    : <ButtonCmp VarColor='Green2' onClick={() => ContinuarConfiguracao()}>{pathname.includes('Editar') ? 'Salvar' : 'Continuar'}</ButtonCmp>
                }
              </Flex>
            </WhiteContainer>
            : <WhiteContainer>
              <Flex height={'25rem'} flexDir='column'>
                {
                  EditEtapa === undefined
                    ? <CreateEtapaBox
                      keyWord='reunião'
                      EditEtapa={EditEtapa}
                      onModelChange={(e) => setEditEtapa(e)}
                      variant='Etapa'
                    />
                    : <CreateEtapaBox
                      keyWord='reunião'
                      EditEtapa={EditEtapa}
                      ignoreId={etapaId}
                      onModelChange={(e) => setEditEtapa(e)}
                      variant='Etapa'
                    />
                }
                {!PropInvalida(processoId) && PropInvalida(etapaId) &&
                  <Flex gap={'1rem'} flexDir={'column'} mt={'1rem'}>
                    <Text fontWeight={'bold'} color={'var(--a4)'} fontSize={'1.3rem'}>Ou</Text>
                    <ButtonCmp w={'fit-content'}
                      VarColor='Green2'
                      rightIcon={<FaClock />}
                      onClick={() => setModalIsOpen(true)}
                    >Agendar agora</ButtonCmp>
                  </Flex>
                }
              </Flex>
              <Flex marginTop={'1rem'} justifyContent={'end'} gap={'.5rem'}>
                <ButtonCmp
                  onClick={() => setSalvar(false)}
                  VarColor='c6'
                >Voltar</ButtonCmp>

                <ButtonCmp
                  VarColor='Green2'
                  onClick={() => PropInvalida(etapaId) ? AdicionarReuniao() : AtualizarEtapa()}
                >{PropInvalida(etapaId) ? 'Criar' : 'Atualizar'} etapa</ButtonCmp>
                {pathname.includes('Editar') && processoId && <ButtonCmp VarColor='Rosa' onClick={() => AtualizarEtapa(true)}>{PropInvalida(etapaId) ? 'Criar etapa' : 'Atualizar etapa e reenviar convites'} </ButtonCmp>}
              </Flex>
            </WhiteContainer>
        }
      </Main>
    </Body>
  )
}

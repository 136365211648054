/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-return-assign */
import { Box, Flex, FormControl, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaChevronLeft, FaFileCsv } from 'react-icons/fa'
import { IoMailSharp } from 'react-icons/io5'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { SelectCmp } from '../../../../components/SelectCmp'
import { iNomeId } from '../../../../interfaces'
import { appApi, urlApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { PropInvalida, useQuery, ObterToken } from '../../../../Utils/Helper'
import { numberFormatter } from '../../../AssessmentPI/Dashboard'
import { Body } from '../../../Layouts/Body'
import { BarChart } from './Components/BarChart'
import { CaixaDeSelecao } from './Components/CaixaDeSelecao'
import { Discursiva } from './Components/Discursiva'
import { Escala } from './Components/Escala'
import { MultiplaEscolha } from './Components/MultiplaEscolha'
import { PieChart } from './Components/PieChart'
import { ComentariosContainer } from './styles'

interface iDiscursiva {
  pergunta: string
  respostas: string[]
}

interface iPieChartData {
  nome: string
  series: string[]
  labels: string[]
  tipo?: number
  respostas: number
  media?: number
}

interface iModel {
  discursivas: iDiscursiva[]
  nome: string
  descricao: string
  encerrada: boolean
  graficos: iPieChartData[]
  escalas: iPieChartData[]
  respostaIndividual: iRespostaIndividual[]
  filtroColaboradores: iNomeId[]
  filtroRespostas: iNomeId[]
}

interface iRespostaIndividual {
  respondente: string
  respostas: iRespostaQuestao[]
}

interface iRespostaQuestao {
  enunciado: string
  tipo: number
  alternativaResposta: string[]
}

export const ResultadoPesquisaInterna: React.FC = () => {
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const query = useQuery()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const [ColabordorId, setColabordorId] = useState('')
  const [RespostaId, setRespostaId] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [SelectedIndividual, setSelectedIndividual] = useState(1)

  function PrevPage(): void {
    if (SelectedIndividual !== 1) {
      setSelectedIndividual(SelectedIndividual - 1)
    } else {
      setSelectedIndividual(Model?.respostaIndividual.length ?? 0)
    }
  }

  function NextPage(): void {
    if (SelectedIndividual < (Model?.respostaIndividual.length ?? 0)) {
      setSelectedIndividual(SelectedIndividual + 1)
    } else {
      setSelectedIndividual(1)
    }
  }

  function Resultado(): void {
    appApi.get(`PesquisaInterna/Resultado/${pesquisaId as string}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function FiltrarResultado(id: string): void {
    setIsLoading(true)
    setRespostaId('')
    appApi.get(`PesquisaInterna/Resultado/${pesquisaId as string}?colaboradorId=${id}`)
      .then(res => {
        setModel(undefined)
        setModel(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function FiltrarResultadoResposta(id: string): void {
    setIsLoading(true)
    setColabordorId('')
    appApi.get(`PesquisaInterna/Resultado/${pesquisaId as string}?respostaId=${id}`)
      .then(res => {
        setModel(undefined)
        setModel(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    const id = query.get('colaboradorId')
    if (!PropInvalida(id)) {
      setColabordorId(id ?? '')
      FiltrarResultado(id ?? '')
    } else {
      Resultado()
    }
  }, [])

  return (
    <Body>
      <Flex justifyContent={'space-between'} margin={'1rem 0 1.5rem 0'}>
        <Flex flexDir={'column'}>
          <h1>{Model?.nome}</h1>
          <span>{Model?.descricao}</span>
        </Flex>
        <Flex gap={'.5rem'}>
          <ButtonCmp onClick={() => nav(-1)} VarColor="c6" leftIcon={<FaChevronLeft/>}>Voltar</ButtonCmp>
          {parseJwt().adm === 'True' &&
            <Flex gap={'.5rem'}>
              <ButtonCmp
                VarColor='Rosa'
                onClick={() => window.location.href = `${urlApi}/PesquisaInterna/Csv?pesquisaId=${pesquisaId as string}&token=${ObterToken()}`}
                leftIcon={<FaFileCsv />}>
                Baixar base
              </ButtonCmp>
              {!Model?.encerrada &&
                <ButtonCmp
                  VarColor='Green2'
                  leftIcon={<IoMailSharp />}
                  onClick={() => nav(pathname.replace('Resultado', 'Convites'))}
                  >Gestão de convites
                </ButtonCmp>
              }

            </Flex>
          }
        </Flex>
      </Flex>
      <Flex gap={'1rem'} marginBottom={'.875rem'}>
        {
          (Model?.filtroRespostas && Model?.filtroRespostas?.length > 0) && (
            <FormControl width={'18rem'}>
              <SelectCmp value={RespostaId} title='Filtrar por resposta objetiva' OnChange={(e) => {
                FiltrarResultadoResposta(e)
                setRespostaId(e)
              }} borderColor={'var(--Gray4)'}>
                <option value={''}>Todos</option>
                {
                  Model?.filtroRespostas.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>{e.nome}</option>
                    )
                  })
                }
              </SelectCmp>
            </FormControl>
          )}

        {
          (Model?.filtroColaboradores && Model?.filtroColaboradores?.length > 0) && (
            <FormControl width={'18rem'}>
              <SelectCmp title='Colaborador' value={ColabordorId} OnChange={(e) => {
                FiltrarResultado(e)
                setColabordorId(e)
              }} borderColor={'var(--Gray4)'}>
                <option value={''}>Todos</option>
                {
                  Model?.filtroColaboradores.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>{e.nome}</option>
                    )
                  })
                }
              </SelectCmp>
            </FormControl>
          )
        }
      </Flex>
      <LoadingContainer loading={IsLoading} linhas={22}>
        <Tabs variant='unstyled'>
          <TabList marginBottom={'.01rem'}>
            {(Model?.graficos.length ?? 0) > 0 &&
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Resultados</Tab>
            }
            {(Model?.respostaIndividual.length ?? 0) > 0 &&
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Individual</Tab>
            }
            {Model?.escalas && (Model?.escalas.length > 0) && (
              <Tab backgroundColor={'white'} color={'var(--c6)'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Escalas</Tab>
            )}
            {(Model?.discursivas.length ?? 0) > 0 && (
              <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'0 .25rem 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Questões discursivas</Tab>
            )}
          </TabList>
          <TabPanels boxShadow={'var(--SombraBackground)'} background={'var(--c1)'} padding={'1rem 2rem'}>
            {(Model?.graficos.length ?? 0) > 0 && (
              <TabPanel>
                <Flex flexDir={'column'}>
                  {Model?.graficos.map((e, i) => {
                    if ((e.series.length <= 8 && e.tipo !== 5) || ColabordorId.length > 3) {
                      return (
                        <Flex key={i} flexDir={'column'} width='100%' pt={'2rem'} pb={'1rem'} borderBottom={i === Model?.graficos.length - 1 ? '' : '1px solid var(--c5)'}>
                          <Flex justifyContent={'space-between'} alignItems={'center'}>
                            <Flex flexDir={'column'}>
                              <Text fontSize={'1rem'}><strong style={{ fontWeight: 900 }}>{i + 1}</strong> - {e.nome}</Text>
                              <Text fontSize={'.813rem'} color={'var(--Gray3)'} ml={'.25rem'} fontWeight={'400'}>Respostas: {e.respostas}</Text>
                            </Flex>
                            {e.media && <Flex alignItems={'center'} justifyContent={'center'} p={'.625rem 1.25rem'} color={'var(--c1)'} bg={'var(--Azul)'} gap={'.325rem'} fontSize={'.875rem'} borderRadius={'.125rem'}>Média: <strong>{e.media.toFixed(2)}</strong></Flex>}
                          </Flex>
                          <Flex pt={'3rem'} justifyContent={'center'} alignItems={'center'} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'}>
                            <PieChart data={e} id={`chart-${i}`} />
                          </Flex>
                        </Flex>
                      )
                    } else {
                      return (
                        <Flex key={i} flexDir={'column'} width='100%' pt={'2rem'} pb={'1rem'} borderBottom={i === Model?.graficos.length - 1 ? '' : '1px solid var(--c5)'}>
                          <Flex flexDir={'column'}>
                            <Text fontSize={'1rem'}><strong style={{ fontWeight: 900 }}>{i + 1}</strong> - {e.nome}</Text>
                            <Text fontSize={'.813rem'} color={'var(--Gray3)'} ml={'.25rem'} fontWeight={'400'}>Respostas: {e.respostas}</Text>
                          </Flex>
                          <Flex justifyContent={'center'} alignItems={'center'} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'}>
                            <BarChart data={e} id={`chart-${i}`} />
                          </Flex>
                        </Flex>
                      )
                    }
                  })
                  }
                </Flex>
              </TabPanel>
            )}
            {(Model?.respostaIndividual.length ?? 0) > 0 && (
              <TabPanel>
                <Flex justifyContent={'space-between'} mb={'1rem'}>
                  <Text fontSize={'.875rem'}>Respondente: <strong>{Model?.respostaIndividual[SelectedIndividual - 1].respondente}</strong></Text>
                  <HStack mt={0} p={'.25rem'} fontSize={'.875rem'}>
                    <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={PrevPage} VarColor='Green2'><FaArrowLeft/></ButtonCmp>
                    <Text>{numberFormatter.format(SelectedIndividual)} / {Model?.respostaIndividual.length}</Text>
                    <ButtonCmp size={'xs'} h={'1.5rem'} w={'2rem'} onClick={NextPage} VarColor='Green2'><FaArrowRight/></ButtonCmp>
                  </HStack>
                </Flex>
                <Flex flexDir={'column'}>
                  {
                    Model?.respostaIndividual[SelectedIndividual - 1]?.respostas.map((e, i) => {
                      if (e.tipo === 0) {
                        return (
                          <MultiplaEscolha
                            key={i}
                            questao={e}
                          />
                        )
                      } else if (e.tipo === 5) {
                        return (
                          <CaixaDeSelecao
                            key={i}
                            questao={e}
                          />
                        )
                      } else if (e.tipo === 1) {
                        return (
                          <Discursiva
                            key={i}
                            questao={e}
                          />
                        )
                      } else if (e.tipo === 6) {
                        return (
                          <Escala
                            key={i}
                            questao={e}
                          />
                        )
                      }
                      return null
                    })
                  }
                </Flex>
              </TabPanel>
            )}
            {Model?.escalas && (Model?.escalas?.length > 0) && (
              <TabPanel>
                <Flex flexDir={'column'}>
                  {Model?.escalas.map((e, i) => {
                    return (
                      <Flex key={i} flexDir={'column'} width='100%' pt={'2rem'} pb={'1rem'} borderBottom={i === Model?.escalas.length - 1 ? '' : '1px solid var(--c5)'}>
                        <Flex justifyContent={'space-between'} alignItems={'center'}>
                          <Flex flexDir={'column'} mb={'1rem'}>
                            <Text fontSize={'1rem'}><strong style={{ fontWeight: 900 }}>{i + 1}</strong> - {e.nome}</Text>
                            <Text fontSize={'.813rem'} color={'var(--Gray3)'} ml={'.25rem'} fontWeight={'400'}>Respostas: {e.respostas}</Text>
                          </Flex>
                          {e.media ? <Flex alignItems={'center'} justifyContent={'center'} p={'.625rem 1.25rem'} color={'var(--c1)'} bg={'var(--Azul)'} gap={'.325rem'} fontSize={'.875rem'} borderRadius={'.125rem'}>Média Geral: <strong>{e.media.toFixed(2)}</strong></Flex> : <></>}
                        </Flex>
                        <Flex justifyContent={'center'} alignItems={'center'} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'}>
                          <BarChart data={e} id={`escala-chart-${i}`} />
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>
              </TabPanel>
            )}
            {(Model?.discursivas.length ?? 0) > 0 && (
              <TabPanel>
                {
                  Model?.discursivas.map((e, i) => {
                    return (
                      <Box paddingBottom={i === Model?.discursivas.length - 1 ? '' : '1rem'} borderBottom={i === Model?.discursivas.length - 1 ? '' : '2px solid var(--c5)'} key={i}>
                        <Flex justifyContent={'space-between'} alignItems={'center'}>
                          <Flex gap={'.325rem'} fontSize={'.925rem'}>
                            <Text fontWeight={'400'}>Pergunta:</Text>
                            <Text
                              fontWeight={'700'}
                            >{e.pergunta}</Text>
                          </Flex>
                          <Flex alignItems={'center'} gap={'.325rem'} justifyContent={'center'} border={'2px solid var(--Azul)'} p={'.25rem 1rem'} fontSize={'.813rem'} borderRadius={'.25rem'}>
                            Comentários: <strong>{e.respostas.length}</strong>
                          </Flex>
                        </Flex>
                        <ComentariosContainer>
                          <section>
                            {
                              e.respostas.map((e, i) => {
                                return (
                                  <div key={i}>
                                    <span key={i}>{e}</span>
                                  </div>
                                )
                              })
                            }
                          </section>
                        </ComentariosContainer>
                      </Box>
                    )
                  })
                }
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </LoadingContainer>
    </Body>
  )
}

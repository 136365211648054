/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Flex, FormControl, FormLabel, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { SelectCmp } from '../../../../components/SelectCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iChartNps, iNomeId } from '../../../../interfaces'
import { SigahApi, urlSigah } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { ObterToken, PropInvalida } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { ColumnChart } from './Components/ColumnChart'
import { PieChart } from './Components/PieChart'
import { ComentariosContainer } from './styles'

type iParam = {
  pesquisaId: string
}

interface iDiscursiva {
  pergunta: string
  respostas: string[]
}

interface iResultadoNps {
  graficosNps: iChartNps[]
  discursivas: iDiscursiva[]
  filtroDemografia: iNomeId[]
}

export const ResultadoEnpsJornada: React.FC = () => {
  const { adm, depid } = parseJwt()
  const param = useParams<iParam>()
  const Route = `${urlSigah}/PesquisaPulse`
  const redirectPage = (at: string, param: string): any => window.location.href = `${Route}/${at}/${param}`

  const nav = useNavigate()

  const [Resultado, setResultado] = useState<iResultadoNps>()
  const [IsLoading, setIsLoading] = useState(false)
  const [FiltroDemografia, setFiltroDemografia] = useState('')
  const [DemografiaNome, setDemografiaNome] = useState('')

  function getResultado(): void {
    SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${adm === 'True' ? '' : depid}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => setResultado(res.data))
      .catch(err => console.log(err))
  }

  function FiltrarGrafico(id: string): void {
    setIsLoading(true)
    setFiltroDemografia(id)
    setResultado(undefined)
    setTimeout(() => {
      SigahApi.get(`api/Onboard/eNps/Resultado/${param.pesquisaId as string}?itemDemografiaId=${id}`, {
        headers: {
          'content-type': 'application/json',
          authorization: ObterToken()
        }
      })
        .then(res => { setResultado(res.data); setIsLoading(false) })
        .catch(err => { console.log(err); setIsLoading(false) })
    }, 500)
  }

  function GetEscalaDiferente(valor: number): any {
    if (valor <= 0) {
      return <Text fontWeight={700} color='var(--Red)'>Crítica</Text>
    } else if (valor <= 50) {
      return <Text fontWeight={700} color='var(--Orange)'>Aperfeiçoamento</Text>
    } else if (valor <= 75) {
      return <Text fontWeight={700} color='var(--Yellow)'>Qualidade</Text>
    } else {
      return <Text fontWeight={700} color='var(--Green2)'>Excelência</Text>
    }
  }

  useEffect(() => {
    getResultado()
  }, [])

  useEffect(() => {
    if (PropInvalida(FiltroDemografia) && adm === 'False') {
      setFiltroDemografia(depid)
    }
  }, [Resultado?.filtroDemografia])

  return (
    <Body>
      <WhiteContainer>
        <Flex justifyContent={'space-between'} mb={'2rem'}>
          <h1>eNPS - 90 dias</h1>
          <Flex gap={'.75rem'}>
            <ButtonCmp onClick={() => nav('/Jornada/eNps')} VarColor="c4" size={'lg'}>Voltar</ButtonCmp>
            <ButtonCmp onClick={() => redirectPage('Participantes', param.pesquisaId as string)} VarColor='Green2' size={'lg'}>Gestão de convites</ButtonCmp>
          </Flex>
        </Flex>

        <Flex justifyContent={'space-between'}>
          {
            (Resultado && Resultado?.filtroDemografia?.length > 0) && (
              <FormControl marginBottom={'2rem'}>
                <SelectCmp
                  title='Demografia'
                  isDisabled={adm === 'False'}
                  value={FiltroDemografia}
                  borderColor={'var(--Gray4)'}
                  width={'18rem'} OnChange={(e) => {
                    if (e === '') { getResultado() }
                    setDemografiaNome(Resultado?.filtroDemografia?.find(a => a.id === e)?.nome ?? '')
                    FiltrarGrafico(e)
                  }}>
                  <option value={''}>Nenhum</option>
                  {
                    Resultado?.filtroDemografia?.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>{e.nome}</option>
                      )
                    })
                  }
                </SelectCmp>
              </FormControl>
            )
          }
          {
            !(Resultado && Resultado?.filtroDemografia?.length > 0) && (
              <FormControl marginBottom={'2rem'}>
                <SelectCmp
                  title='Demografia'
                  isDisabled
                  OnChange={() => {}}
                  borderColor={'var(--Gray4)'}
                  width={'18rem'}>
                  <option value={''}>{DemografiaNome}</option>
                </SelectCmp>
              </FormControl>
            )
          }
          {process.env.REACT_APP_FBS_EMPRESA_ID === parseJwt().eid && <Flex flexDir={'column'} border={'1px solid var(--c6)'} background={'var(--RosaOpacoBg)'} width='21rem' padding={'1rem'} borderRadius={'.325rem'}>
            <Flex justifyContent={'center'} fontWeight={700} mb={'.25rem'}>Classificação</Flex>
            <Flex gap={'.325rem'} alignItems='center' fontSize={'17px'} color={'var(--Red)'}><Box bg={'var(--Red)'} borderRadius={'50%'} w={'.625rem'} h={'.625rem'}></Box> Crítica <strong style={{ fontSize: '15px' }}>(-100% a 0%)</strong></Flex>
            <Flex gap={'.325rem'} alignItems='center' fontSize={'17px'} color={'var(--Orange)'}><Box bg={'var(--Orange)'} borderRadius={'50%'} w={'.625rem'} h={'.625rem'}></Box> Aperfeiçoamento <strong style={{ fontSize: '15px' }}>(1% a 50%)</strong></Flex>
            <Flex gap={'.325rem'} alignItems='center' fontSize={'17px'} color={'var(--Yellow)'}><Box bg={'var(--Yellow)'} borderRadius={'50%'} w={'.625rem'} h={'.625rem'}></Box> Qualidade <strong style={{ fontSize: '15px' }}>(51% a 75%)</strong></Flex>
            <Flex gap={'.325rem'} alignItems='center' fontSize={'17px'} color={'var(--Green2)'}><Box bg={'var(--Green2)'} borderRadius={'50%'} w={'.625rem'} h={'.625rem'}></Box> Excelência <strong style={{ fontSize: '15px' }}>(76% a 100%)</strong></Flex>
          </Flex>}
        </Flex>

        <Tabs size='md' variant='enclosed'>
          <TabList marginBottom={'.01rem'}>
            <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem', borderBotto: 'white' }} bg={'var(--c1)'} color={'black'}>Resultados</Tab>
            {
              (Resultado && Resultado.discursivas.length > 0) && (
                <Tab _selected={{ borderTopColor: 'var(--Gray4)', borderLeftColor: 'var(--Gray4)', borderRightColor: 'var(--Gray4)', borderRadius: '0rem' }} bg={'var(--c1)'} color={'black'}>Questões discursivas</Tab>
              )
            }
          </TabList>
          <TabPanels border={'1px solid var(--Gray4)'}>
            <TabPanel flexDir={'column'}>
              <LoadingContainer linhas={16} loading={IsLoading} my='.5rem'>
                {
                  (Resultado) && (
                    <Flex gap={'2rem'} flexDir={'column'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                      {
                        Resultado.graficosNps?.map((e, i) => {
                          return (
                            <>
                              <Flex key={i}
                                paddingBottom={'1rem'}
                                flexDir={'column'} alignItems={'center'}>
                                <Text fontSize={'16px'} alignItems={'center'} fontWeight={700}>{e.pergunta}</Text>
                                <Flex justifyContent={'center'} gap={'1rem'} alignItems={'start'}>
                                  <Flex marginTop={'1rem'} flexDir='column' alignItems={'center'} justifyContent={'center'} width={'100%'}>
                                    <PieChart
                                      id={`i-ChartNps-${i}`}
                                      detratores={e.detratores}
                                      neutros={e.neutros}
                                      promotores={e.promotores}
                                      qtdDetratores={e.qtdDetratores}
                                      qtdNeutros={e.qtdNeutros}
                                      qtdPromotores={e.qtdPromotores}
                                      nps={e.nps.toFixed(0)}
                                    />
                                    {process.env.REACT_APP_FBS_EMPRESA_ID === parseJwt().eid && <Flex alignItems={'center'} gap='.325rem' mt={'.325rem'}><Text>Zona:</Text> {GetEscalaDiferente(e.nps)}</Flex>}
                                  </Flex>
                                  <Flex fontSize={'16px'} pt={'2rem'} fontWeight={700} gap={'.5rem'} alignItems={'center'}>
                                    Total: <Flex borderRadius={'50%'} bg={'var(--Gray2)'} alignItems={'center'} justifyContent={'center'} h={'2rem'} w={'2rem'} color={'var(--c1)'}>{e.total}</Flex>
                                  </Flex>
                                </Flex>
                              </Flex>
                              {
                                e?.graficosDemografia?.map((a, index) => {
                                  return (
                                    <Flex key={a.nomeDemografia} width={'100%'} paddingBottom={'1rem'} marginBottom={'1rem'} borderBottom={'1px solid var(--Gray4)'}>
                                      <ColumnChart
                                        isFBS={process.env.REACT_APP_FBS_EMPRESA_ID === parseJwt().eid}
                                        id={`${i.toString()}-${index.toString()}`}
                                        Grafico={{
                                          titulo: a.nomeDemografia,
                                          labelX: a.demografias.map((b) => {
                                            return b.nomeItem
                                          }),
                                          series: [
                                            {
                                              nome: 'Processos Absolutos',
                                              dados: a.demografias.map((b) => {
                                                return b.nps
                                              })
                                            }
                                          ]
                                        }}
                                      />
                                    </Flex>
                                  )
                                })
                              }
                            </>
                          )
                        })
                      }
                    </Flex>
                  )
                }
              </LoadingContainer>
            </TabPanel>
            <TabPanel>
              {
                Resultado?.discursivas.map((e, i) => {
                  return (
                    <div key={i}>
                      <Flex flexDir={'column'}>
                        <h3 style={{ color: 'var(--Gray3)' }}>Pergunta:</h3>
                        <Text
                          fontWeight={'400'}
                          style={{ fontSize: '1.2rem', marginTop: '.5rem' }}
                        >{e.pergunta}</Text>
                      </Flex>
                      <ComentariosContainer>
                        <section>
                          <h3>Comentários: {e.respostas.length}</h3>
                          {
                            e.respostas.map((r, i2) => {
                              return (
                                <div key={i2}>
                                  <span>{r}</span>
                                </div>
                              )
                            })
                          }

                        </section>
                      </ComentariosContainer>
                    </div>
                  )
                })
              }
            </TabPanel>
          </TabPanels>
        </Tabs>
      </WhiteContainer>
    </Body>
  )
}

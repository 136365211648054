import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { FindUser } from '../../../../../../components/FindBox'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { IEmployee, iNomeId, iPostAvaliador } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { Body, Footer } from './styles'

interface iModalprops {
  isOpen: boolean
  colaborador?: IEmployee
  jaAdicionados: IEmployee[]
  onRequestClose: () => void
  onConfirm: (form: iPostAvaliador) => void
  onUpdateAvaliador: (form: iPostAvaliador, email: string) => void
  onDeleteAvaliador: (form: iPostAvaliador) => void
}

export const ModalAddParticipante: React.FC<iModalprops> = ({ isOpen, onConfirm, onRequestClose, onUpdateAvaliador, colaborador, onDeleteAvaliador, jaAdicionados }) => {
  const [DoSistema, setDoSistema] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState<string>()
  const [Email, setEmaill] = useState('')
  const [NomeColaborador, setNomeColaborador] = useState('')
  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
    if (id === '') {
      setEmaill('')
    }
  }

  function ExtrairValorInput(texto: string): void {
    setNomeColaborador(texto)
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  useEffect(() => {
    if (Colaboradores.some(e => e.email === colaborador?.email)) {
      const col = Colaboradores.find(t => t.email === colaborador?.email)
      setDoSistema(true)
      SelecionarGestor(col?.id ?? '')
    } else {
      setNomeColaborador(colaborador?.nome ?? '')
      setEmaill(colaborador?.email ?? '')
    }
  }, [colaborador, Colaboradores])

  useEffect(() => {
    if (Colaboradores.some(e => e.id === Selecionado)) {
      const col = Colaboradores.find(t => t.id === Selecionado)
      setEmaill(col?.email ?? '')
      console.log(col)
    }
  }, [Selecionado])

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onRequestClose}
      Titulo={colaborador ? 'Editar participante' : 'Adicione um participante'}
      Subtitulo='Caso o usuário já esteja cadastrado no sistema, preencha o campo nome, caso não, preencha o campo de nome e email'
    >
      <Body>
        <Flex flexDir={'column'} gap={'.125rem'}>
          <Text fontWeight={'500'} fontSize={'.875rem'} color={'var(--Gray1)'}>Nome do colaborador</Text>
          {(PropInvalida(colaborador) || DoSistema) &&
            <FindUser
              lista={Colaboradores.filter(e => !jaAdicionados.some(c => c.id === e.id))}
              isDisabled={!PropInvalida(colaborador)}
              onChoice={SelecionarGestor}
              placeholder='Digite o nome do participante'
              selecionado={Selecionado}
              _onChange={ExtrairValorInput}
            />
          }

          {(!PropInvalida(colaborador) && !DoSistema) &&
            <InputCmp
              value={NomeColaborador}
              placeholder='Digite o nome do participante'
              OnChange={setNomeColaborador}
            />
          }
        </Flex>
        <Flex marginTop={'.5rem'} flexDir={'column'}>
          <InputCmp
            label='Email'
            isDisabled={!!Selecionado}
            value={Email}
            OnChange={setEmaill}
          />
        </Flex>
      </Body>
      <Footer>
        {(!PropInvalida(colaborador)) && <ButtonCmp
          VarColor='Red'
          onClick={() => onDeleteAvaliador({
            colaboradorId: Selecionado === '' ? undefined : Selecionado,
            email: Email,
            nome: NomeColaborador,
            TipoAvaliador: 0
          })}
        >Remover</ButtonCmp>}
        {(!PropInvalida(colaborador) && !DoSistema) && <>
          <ButtonCmp
            VarColor='Green2'
            onClick={() => onUpdateAvaliador({
              colaboradorId: Selecionado === '' ? undefined : Selecionado,
              email: Email,
              nome: NomeColaborador,
              TipoAvaliador: 0
            }, colaborador?.email ?? '')}
          >Atualizar</ButtonCmp>
        </>}
        {(PropInvalida(colaborador)) && <ButtonCmp
          VarColor='Green2'
          onClick={() => onConfirm({
            colaboradorId: Selecionado === '' ? undefined : Selecionado,
            email: Email,
            nome: NomeColaborador,
            TipoAvaliador: 0
          })}
        >Adicionar</ButtonCmp>}
      </Footer>
    </ModalBase>
  )
}

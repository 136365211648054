import { Flex } from '@chakra-ui/react'
import { BsArrowRight } from 'react-icons/bs'
import { iInputModel } from '../../../Criar'
import { Card } from './components/Card'

interface iProps{
  selecionado: number
  model: iInputModel
}
export const TimeLine: React.FC<iProps> = ({ selecionado, model }) => {
  return (
        <Flex alignItems={'center'} px={'2rem'} gap={model.remover90 ? '2rem' : ''} justifyContent={model.remover90 ? 'center' : 'space-between'}>
            <Card
                tipo='360-indicativa'
                titulo='360° Indicativa'
                subtitulo='Pesquisa onde o gestor avalia o colaborador e o colaborador realiza a autoavaliação'
                isSelected={selecionado === 0}
                dataInicio={selecionado > 0 ? model.p360?.dataInicio : undefined}
                dataFim={selecionado > 0 ? model.p360?.dataFim : undefined}
            />
            <BsArrowRight size={32} color={'var(--Gray4)'}/>
            <Card
                tipo='Feedback-discursivo'
                titulo='Feedback Discursivo'
                subtitulo='Pesquisa onde o gestor avalia o colaborador e o colaborador realiza a autoavaliação'
                isSelected={selecionado === 1}
                dataInicio={selecionado > 1 ? model.feedbackDiscursivo?.dataInicio : undefined}
                dataFim={selecionado > 1 ? model.feedbackDiscursivo?.dataFim : undefined}
            />
            {!model.remover90 &&
            <>
                <BsArrowRight size={32} color={'var(--Gray4)'}/>
                <Card
                    tipo='90-quantitativa'
                    titulo='90°'
                    subtitulo='Pesquisa onde o gestor avalia o colaborador e o colaborador realiza a autoavaliação'
                    isSelected={selecionado === 2}
                    texto={selecionado > 2 ? 'Junto com o feedback discursivo' : undefined}
                />
            </>}
            <BsArrowRight size={32} color={'var(--Gray4)'}/>
            <Card
                tipo='Reuniao-one'
                titulo='Reunião 1:1'
                subtitulo='Pesquisa onde o gestor avalia o colaborador e o colaborador realiza a autoavaliação'
                isSelected={selecionado === 3}
                texto={selecionado > 2 ? 'Após todas as etapas anteriores' : undefined}
            />
        </Flex>
  )
}

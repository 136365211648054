/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from '@chakra-ui/react'
import { QuestaoAlternativa } from './components/Alternativa'
import { QuestaoCaixaSelecao } from './components/CaixaSelecao'
import { QuestaoComentario } from './components/Comentario'
import { useEffect, useState } from 'react'
import { iFeedback } from '../..'

interface iProps {
  model: iFeedback
}

export const Feedback: React.FC<iProps> = ({ model }) => {
  const [Model, setModel] = useState<iFeedback>({
    questoes: [],
    respostas: []
  })

  useEffect(() => {
    setModel(model)
  }, [model])

  return (
    <Flex flexDir={'column'}>
      {Model.questoes.map((questao, i) => {
        return (
          <Flex key={i} flexDirection={'column'} my={'1rem'}>
            <h4>{questao.texto}</h4>
            <Flex gap={'1rem'} flexDir={'column'}>
              {Model.respostas.map((f, i2) => {
                return (
                  <Flex key={i2} flexDir={'column'} gap='.5rem' p='.5rem' borderRadius={'12px'} border={'1px solid'}>
                    {f.respostas.filter(r => r.respostas.some(t => t.questaoId === questao.id)).map((r, i3) => {
                      return (
                        <Flex key={i3} flexDirection={'column'}>
                          {questao.tipo === 1 &&
                            <QuestaoComentario
                              questao={questao}
                              resposta={r}
                            />
                          }
                        </Flex>
                      )
                    })}
                  </Flex>
                )
              })}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

import { Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Select, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowRight, FaPlus, FaSave, FaTimes } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { EditorTexto2 } from '../../../../../../components/EditorTexto/index2'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../Utils/Helper'

export interface iFormReuniaoOne {
  assunto: string
  descricao: string
  duracao: number
  tipoId: string
}

interface iProps {
  onChange: (model: iFormReuniaoOne) => void
  onVoltar: () => void
  onAvancar: () => void
  model?: iFormReuniaoOne
}

export const FormReuniaoOne: React.FC<iProps> = ({ model, onChange, onAvancar, onVoltar }) => {
  const { Permissoes } = useAuth()
  const toast = useToast()

  const [NovaFuncao, setNovaFuncao] = useState({
    on: false,
    texto: ''
  })

  const [Tipos, setTipos] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iFormReuniaoOne>({
    assunto: 'Feedback',
    descricao: '',
    duracao: 5,
    tipoId: 'b1412506-4a93-4c1f-bace-5c0cf528c9c1'
  })

  const [AssuntoIsInvalid, setAssuntoIsInvalid] = useState(false)
  const [TipoIsInvalid, setTipoIsInvalid] = useState(false)
  function ObterTipos(): void {
    appApi.get('TipoXEmpresa/Select?tipo=2')
      .then(res => {
        setTipos(res.data)
      })
      .catch(err => console.log(err))
  }

  function CriarTipos(): void {
    appApi.post('TipoXEmpresa', {
      texto: NovaFuncao.texto,
      tipo: 2
    })
      .then(() => {
        toast({
          title: 'Tipo criado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setNovaFuncao({
          on: false,
          texto: ''
        })
        ObterTipos()
      })
      .catch(err => console.log(err))
  }

  function FormIsInvalid(): boolean {
    setAssuntoIsInvalid(false)
    setTipoIsInvalid(false)

    if (Model.assunto.length < 5) {
      setAssuntoIsInvalid(true)
      toast({
        title: 'O assunto da reunião deve conter no mínimo 5 caracteres!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.descricao.length === 0) {
      toast({
        title: 'A descrição é obrigatória!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }

    if (PropInvalida(Model.tipoId)) {
      setTipoIsInvalid(true)
      toast({
        title: 'Selecione um tipo para a sua reunião!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    return false
  }

  function InternalOnAvancar(): void {
    if (!FormIsInvalid()) {
      onAvancar()
    }
  }

  useEffect(() => {
    ObterTipos()
  }, [])

  useEffect(() => {
    if (model) {
      setModel(model)
    }
  }, [model])

  return (
    <Flex flexDir={'column'} gap='2rem'>
      <FormControl my={'2rem'}>
        <FormLabel fontSize={'xl'}>Assunto da reunião</FormLabel>
        <Input
          type={'text'}
          placeholder={'Digite o assunto da reunião'}
          borderColor={'var(--Gray4)'}
          onChange={(e) => onChange({ ...Model, assunto: e.target.value })}
          isInvalid={AssuntoIsInvalid}
          value={Model.assunto}
        />
      </FormControl>

      <Flex flexDir={'column'} mb='1.5rem'>
        <Flex flexDir={'column'}>
          <Flex>
            <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem'>Selecione o tipo da reunião</FormLabel>

            {!NovaFuncao.on && Permissoes?.funcionalidades?.some(e => e.tipo === 3 && e.liberado) &&
              <Button
                ml={'1rem'}
                onClick={() => setNovaFuncao({ ...NovaFuncao, on: true })}
                leftIcon={<FaPlus />}
                size={'xs'}
                backgroundColor={'#1aa0bb'}
              >Adicionar novo tipo</Button>
            }

            {NovaFuncao.on &&
              <Button
                ml={'1rem'}
                onClick={() => setNovaFuncao({
                  on: false,
                  texto: ''
                })}
                leftIcon={<FaTimes />}
                size={'xs'}
                backgroundColor={'#ea2010'}
              >Cancelar</Button>
            }
          </Flex>
          {NovaFuncao.on &&
            <InputGroup>
              <InputRightElement cursor={'pointer'} onClick={CriarTipos}>
                <FaSave color='var(--Green2)' />
              </InputRightElement>
              <Input
                borderColor={'var(--Gray4)'}
                placeholder='Digite o nome'
                onChange={(e) => setNovaFuncao({ ...NovaFuncao, texto: e.target.value })}
              />
            </InputGroup>
          }

        </Flex>
        {!NovaFuncao.on &&
          <Select
            borderColor={'var(--Gray4)'}
            onChange={(e) => onChange({ ...Model, tipoId: e.target.value })}
            value={Model.tipoId}
            isInvalid={TipoIsInvalid}
          >
            <option value={''}>Nenhum</option>
            {Tipos.map((r, i) => {
              return (
                <option key={i} value={r.id}>{r.nome}</option>
              )
            })}
          </Select>
        }
      </Flex>

      <FormControl>
        <FormLabel>Duração</FormLabel>
        <Select
          w={'14.5rem'}
          value={Model.duracao}
          onChange={(e) => onChange({ ...Model, duracao: parseInt(e.target.value) })}
          borderColor={'var(--Gray4)'}
        >
          <option value={'5'}>5 minutos</option>
          <option value={'10'}>10 minutos</option>
          <option value={'15'}>15 minutos</option>
          <option value={'30'}>30 minutos</option>
          <option value={'60'}>1 hora</option>
          <option value={'120'}>2 horas</option>
          <option value={'180'}>3 horas</option>
          <option value={'240'}>4 horas</option>
          <option value={'300'}>5 horas</option>
          <option value={'360'}>6 horas</option>
          <option value={'420'}>7 horas</option>
          <option value={'480'}>8 horas</option>
          <option value={'540'}>9 horas</option>
          <option value={'600'}>10 horas</option>
        </Select>
      </FormControl>

      <Flex flexDir={'column'} mt={'.5rem'}>
        <FormControl>
          <FormLabel fontSize={'xl'}>Descrição</FormLabel>
          <EditorTexto2
            initialValue={Model.descricao}
            placeholder='Deixe uma descrição aqui'
            className='editorSt-v3'
            onChange={(e: string) => onChange({ ...Model, descricao: e })}
          />
        </FormControl>
        <small>Digite um texto com informações importantes ou pauta para registro do que será tratado na reunião</small>
      </Flex>

      <Flex justifyContent={'end'} gap='1rem'>
        <Button
          fontWeight={'500'}
          VarColor='Gray3'
          onClick={onVoltar}
        >Voltar</Button>

        <Button
          fontWeight={'500'}
          VarColor='Green2'
          rightIcon={<FaArrowRight />}
          onClick={InternalOnAvancar}
        >Avançar</Button>
      </Flex>
    </Flex>
  )
}

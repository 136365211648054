import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const Main = styled(Flex)`
justify-content: space-between;
section{
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 49%;
    padding-right: .5rem;
}

img{
    width: 49%;
    height: 100%;
}
`

export const FlexHead = styled(Flex)`
justify-content: end;
margin-bottom: .5rem;
`

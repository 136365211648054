import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Menu, MenuList, Text, Checkbox } from '@chakra-ui/react'
import { FaChevronDown, FaEdit, FaPaperPlane, FaTrash } from 'react-icons/fa'
import { MenuButtonCmp } from '../../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { iCardAgendamento, iMensagens } from '../../../../../interfaces'
import { parseJwt } from '../../../../../services/token'
import { Legenda } from '../../../../PesquisaIndicativa/Criar/styles'
import { TabelaAgendamentos } from '../TabelaAgendamentos'

interface CardProps {
  isSelected: boolean
  agendamento: iCardAgendamento
  onDelete: (e: iCardAgendamento) => void
  onEditarEReenviar: (e: iCardAgendamento) => void
  onReenviar: (e: string) => void
  onDeleteMsg: (e: iMensagens) => void
  onView: (e: iMensagens) => void
  onSend: (e: iMensagens) => void
  onSelect: (id: string, selected: boolean) => void
}

export const CardAgendamento: React.FC<CardProps> = ({ isSelected, agendamento, onDelete, onDeleteMsg, onSend, onView, onReenviar, onEditarEReenviar, onSelect }) => {
  function GerarDescricao(): string {
    if (!agendamento.descricao) {
      switch (agendamento.tipo) {
        case 20:
          return 'Primeiro acesso - ' + (agendamento.colaborador?.email)
        case 15:
          return 'Processo de onboard'
        case 1:
        case 2:
        case 16:
          return 'Etapa de trilha'
        default:
          return agendamento.legenda ?? ''
      }
    }

    return ''
  }

  return (
        <Flex flexDir={'column'} position={'relative'} bg={'var(--c1)'} justifyContent={'center'} borderRadius={'0.25rem'} boxShadow={`0rem 0.2rem 0.5rem ${agendamento.enviado ? 'var(--Green3Opaco)' : 'var(--RedOpaco)'}`}borderLeft={`3px solid ${agendamento.enviado ? 'var(--Green3)' : 'var(--Red)'}`} pl={'1.25rem'} pr={'1rem'} pt={'.75rem'} pb={agendamento.mensagens.length > 0 ? '' : '.75rem'}>
          {/* <Box w={'.5rem'} h={'.5rem'} bg={agendamento.enviado ? 'var(--Green3)' : 'var(--Red)'} borderRadius={'50%'} pos={'absolute'} top={'13px'} left={'30px'}></Box> */}
          <Checkbox isChecked={isSelected} size={'sm'} onChange={(e) => onSelect(agendamento.id, e.target.checked)} pos={'absolute'} top={'6px'} left={'6px'} borderColor={'var(--Gray4)'}/>
          <Flex justifyContent={'space-between'} gap={'0 1rem'} w={'100%'}>
            <Flex flexDir={'column'} pt={'.3rem'}>
              <Text fontWeight={'700'} fontSize={'18px'} lineHeight={'21px'}>{agendamento.descricao ?? GerarDescricao()}</Text>
              <Legenda>{agendamento.legenda}</Legenda>
            </Flex>
            <Flex justifyContent={'end'} alignItems={'center'} gap={'1rem'}>
              <Flex gap={'.75rem'} alignItems={'center'} justifyContent={'center'}>
                <FaPaperPlane size={21}/>
                <Flex flexDir={'column'}>
                  <Text fontSize={'13px'} lineHeight={'15px'}>Data de envio:</Text>
                  <Text fontWeight={'700'} fontSize={'14px'} lineHeight={'17px'}>{agendamento.dataAgendamento}</Text>
                </Flex>
              </Flex>
              <Menu>
                <MenuButtonCmp Icon={<FaChevronDown />} VarColor={'Rosa'}
                >
                  Opções
                </MenuButtonCmp>
                <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>

                    {
                        (parseJwt().adm === 'True') && (
                            <StyledMenuItem
                                onClick={() => onEditarEReenviar(agendamento)}
                            >
                                <FaEdit style={{ marginRight: '1rem' }} />
                                Editar {agendamento.enviado ? 'e reenviar' : ''}
                            </StyledMenuItem>
                        )
                    }
                    {
                        (parseJwt().adm === 'True' && (agendamento.enviado || agendamento.mensagens.some(e => e.enviado))) && (
                            <StyledMenuItem
                                onClick={() => onReenviar(agendamento.id)}
                            >
                                <FaPaperPlane style={{ marginRight: '1rem' }} />
                                Reenviar agendamentos
                            </StyledMenuItem>
                        )
                    }

                    {
                        (parseJwt().adm === 'True' && !agendamento.enviado) && (
                            <StyledMenuItem
                                onClick={() => onDelete(agendamento)}
                            >
                                <FaTrash style={{ marginRight: '1rem' }} />
                                Cancelar agendamento
                            </StyledMenuItem>
                        )
                    }

                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          {agendamento.mensagens.length > 0 && <Accordion w={'full'} allowToggle>
              <AccordionItem>
                <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} gap={'.3rem'} borderRadius={'1.25rem'} px={'0'} pt={'.125rem'} pb={'.325rem'}>
                  <Flex w={'full'} justifyContent={'end'} as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'} fontWeight={'700'} color={'var(--Azul)'}>
                    Lista de emails
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <AccordionPanel gap={'1rem'} overflowX={'auto'} mb={'.5rem'} px={'0'}>
                  <TabelaAgendamentos
                    model={agendamento}
                    onDelete={(e) => onDeleteMsg(e)}
                    onSend={(e) => onSend(e)}
                    onView={(e) => onView(e)}
                  />
                </AccordionPanel>
              </AccordionItem>
          </Accordion>}
        </Flex>
  )
}

import styled from 'styled-components'

export const TableDiv = styled.div`
display: flex;
width: 100%;
font-size: .813rem;
table, th, td {
  border: 2px solid var(--c4);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    max-width: 12rem;
    padding: .325rem .75rem .325rem .75rem;
}

td{
    transition: 2s;
    text-align: center;
}

th{
    background: var(--Azul);
    color: var(--c1);
    font-weight: 500;
}
tr:last-child, tr:last-child:hover{
    background: var(--c3);
}
td:first-child{
    background: #3984C430;
    color: var(--Preto);
    font-weight: bold;
}
.dropDownTr{
    background: #3984C415 !important;
    color: var(--Preto);
    font-weight: bold;
}
tr:hover{
background: #3984C430;
}

tr:not(:first-child){
color: var(--Preto);
}
`

export const StyledTd = styled.td`
    color: ${props => props.theme};
    font-weight: bold;
`

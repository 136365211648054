/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'

export async function GenerateChartBlob(options: any): Promise<string> {
  const NoneContainer = document.createElement('div')
  NoneContainer.style.display = 'flex'
  NoneContainer.style.flexDirection = 'column'
  NoneContainer.style.justifyContent = 'center'
  NoneContainer.style.width = '100%'
  NoneContainer.style.maxWidth = '1168px'
  NoneContainer.style.opacity = '0'
  NoneContainer.style.zIndex = '-1'
  NoneContainer.style.position = 'absolute'

  const ChartContainer = document.createElement('div')
  ChartContainer.style.display = 'flex'
  ChartContainer.style.flexDirection = 'column'

  const PreChart = document.createElement('div')
  PreChart.style.width = '100%'
  PreChart.setAttribute('id', 'chart-dale')

  ChartContainer.appendChild(PreChart)

  NoneContainer.appendChild(ChartContainer)

  document.body.appendChild(NoneContainer)

  const chart = new ApexCharts(document.querySelector('#chart-dale'), options)
  chart.render()

  const dataURL = await chart.dataURI().then((value: any) => {
    return value
  })

  document.body.removeChild(NoneContainer)

  return dataURL.imgURI
}

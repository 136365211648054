import { Box, Flex, Img } from '@chakra-ui/react'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'
import { BoxLayout, ContainerLayout, GroupLayout } from './styles'
import AvatarSah from '../../../../../assets/AvatarAH.svg'

interface props {
  selecionado?: number
  isOpen: boolean
  type: number
  IsChecklist: boolean
  onRequestClose: () => void
  onConfirm: (layout: number) => void
}

export const ModalLayouts: React.FC<props> = ({ selecionado, isOpen, onRequestClose, onConfirm, type, IsChecklist }) => {
  const [LayoutHover, setLayoutHover] = useState<number>()
  const [LayoutSelecionado, setLayoutSelecionado] = useState(selecionado)
  Modal.setAppElement('#root')
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-default'
        >
          <Flex flexDir={'column'} p={'.75rem'} w={'48rem'} gap={'.825rem'}>
            <Flex justifyContent={'space-between'}>
                <div></div>
                <h3>Selecione o layout de exibição desse item</h3>
                <Flex alignItems={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}>
                  <MdClose size={19} onClick={onRequestClose} cursor={'pointer'}/>
                </Flex>
            </Flex>

            <Flex flexDir={'column'} gap={'.825rem'} fontSize={'.875rem'} fontWeight={'600'}>
                <Flex gap={'1rem'} alignItems={'center'} h={'full'}>
                    <Flex w={'80%'} p={'1rem'} gap={'1rem'} flexDir={LayoutSelecionado === 2 ? 'column' : 'row'} h={'24rem'}>
                      {LayoutSelecionado === 1
                        ? <>
                        <Flex flexDir={'column'} w={'60%'} gap={'1rem'} fontSize={'1.125rem'} fontWeight={'700'}>
                          <Flex justifyContent={'center'} alignItems={'center'} minW={'100%'} height={'20%'} border={'1px solid var(--c5)'}>Título do item</Flex>
                          <Flex minH={'6rem'} justifyContent={'center'} alignItems={'center'} minW={'100%'} height={'100%'} border={'1px solid var(--c5)'}>{IsChecklist
                            ? <Flex flexDir={'column'} gap={'1rem'} alignItems={'center'}>
                            <p>Instruções</p>
                            <Flex flexDir={'column'} gap={'.5rem'}>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                            </Flex>
                          </Flex>
                            : 'Descrição do item'}</Flex>
                        </Flex>
                        <Img boxShadow={'var(--SombraBackground)'} bg={'var(--c3)'} w={'100%'} minH={'22rem'} maxH={'22rem'} objectPosition={'center'} objectFit={'contain'} border={'1px solid var(--c5)'} src={AvatarSah}/>
                      </>
                        : LayoutSelecionado === 3
                          ? <Flex flexDir={'column'} w={LayoutSelecionado === 3 ? '100%' : '60%'} gap={'1rem'} fontSize={'1.125rem'} fontWeight={'700'}>
                            <Flex justifyContent={'center'} alignItems={'center'} minW={'100%'} height={'20%'} border={'1px solid var(--c5)'}>Título do item</Flex>
                            <Img boxShadow={'var(--SombraBackground)'} bg={'var(--c3)'} w={'100%'} minH={'18rem'} maxH={'18rem'} objectPosition={'center'} objectFit={'contain'} border={'1px solid var(--c5)'} src={AvatarSah}/>
                        </Flex>
                          : <>
                        <Img boxShadow={'var(--SombraBackground)'} bg={'var(--c3)'} w={'100%'} minH={LayoutSelecionado === 2 ? '12rem' : '22rem'} maxH={LayoutSelecionado === 2 ? '12rem' : '22rem'} objectPosition={'center'} objectFit={'contain'} border={'1px solid var(--c5)'} src={AvatarSah}/>
                        <Flex flexDir={'column'} w={LayoutSelecionado === 2 ? '100%' : '60%'} gap={'1rem'} fontSize={'1.125rem'} fontWeight={'700'}>
                          <Flex justifyContent={'center'} alignItems={'center'} minW={'100%'} height={'20%'} border={'1px solid var(--c5)'}>Título do item</Flex>
                          <Flex minH={'6rem'} justifyContent={'center'} alignItems={'center'} minW={'100%'} height={'100%'} border={'1px solid var(--c5)'}>{IsChecklist
                            ? <Flex flexDir={'column'} gap={'.5rem'} alignItems={'center'}>
                            <p>Instruções</p>
                            <Flex flexDir={'column'} gap={'.5rem'} pb={'.5rem'}>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                              <CheckboxCmp isChecked={false} OnChange={() => {}} >Itens da checklist....</CheckboxCmp>
                            </Flex>
                          </Flex>
                            : 'Descrição do item'}</Flex>
                        </Flex>
                      </>}
                    </Flex>
                    <Flex flexDir={'column'} pr={'1rem'} gap={'1.5rem'} pl={'2rem'} borderLeft={'1px solid var(--Azul)'} minH={'22rem'} justifyContent={'center'}>
                      <ContainerLayout onClick={() => setLayoutSelecionado(0)} isSelecionado={LayoutSelecionado === 0} isHover={LayoutHover === 0} onMouseEnter={() => setLayoutHover(0)} onMouseLeave={() => setLayoutHover(undefined)}>
                        <GroupLayout>
                          <BoxLayout className='boxLayout'></BoxLayout>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            <BoxLayout className='boxLayout'></BoxLayout>
                            <BoxLayout className='boxLayout'></BoxLayout>
                          </Flex>
                        </GroupLayout>
                      </ContainerLayout>
                      <ContainerLayout onClick={() => setLayoutSelecionado(1)} isSelecionado={LayoutSelecionado === 1} isHover={LayoutHover === 1} onMouseEnter={() => setLayoutHover(1)} onMouseLeave={() => setLayoutHover(undefined)}>
                        <GroupLayout>
                          <Flex flexDir={'column'} gap={'.75rem'}>
                            <BoxLayout className='boxLayout'></BoxLayout>
                            <BoxLayout className='boxLayout'></BoxLayout>
                          </Flex>
                          <BoxLayout className='boxLayout'></BoxLayout>
                        </GroupLayout>
                      </ContainerLayout>
                      {(type !== 3) && <ContainerLayout onClick={() => setLayoutSelecionado(2)} isSelecionado={LayoutSelecionado === 2} isHover={LayoutHover === 2} onMouseEnter={() => setLayoutHover(2)} onMouseLeave={() => setLayoutHover(undefined)}>
                        <Flex flexDir={'column'} gap={'.5rem'} h={'4rem'}>
                          <Box className='boxLayout' width={'100%'} h={'2.5rem'} bg={'var(--c4)'}></Box>
                          <Flex flexDir={'column'} gap={'.325rem'}>
                          <Box className='boxLayout' width={'100%'} h={'.5rem'} bg={'var(--c4)'}></Box>
                          <Box className='boxLayout' width={'100%'} h={'.625rem'} bg={'var(--c4)'}></Box>
                          </Flex>
                        </Flex>
                      </ContainerLayout>}
                      {!IsChecklist && <ContainerLayout onClick={() => setLayoutSelecionado(3)} isSelecionado={LayoutSelecionado === 3} isHover={LayoutHover === 3} onMouseEnter={() => setLayoutHover(3)} onMouseLeave={() => setLayoutHover(undefined)}>
                        <Flex flexDir={'column'} gap={'.5rem'} h={'4rem'}>
                          <Flex flexDir={'column'} gap={'.325rem'}>
                            <Box className='boxLayout' width={'100%'} h={'.5rem'} bg={'var(--c4)'}></Box>
                          </Flex>
                          <Box className='boxLayout' width={'100%'} h={'3.5rem'} bg={'var(--c4)'}></Box>
                        </Flex>
                      </ContainerLayout>}
                    </Flex>
                </Flex>
                <Flex justifyContent={'center'} gap={'.5rem'}>
                    <ButtonCmp VarColor={'c5'} onClick={onRequestClose}>Cancelar</ButtonCmp>
                    <ButtonCmp VarColor='Green2' onClick={() => {
                      onConfirm(LayoutSelecionado ?? 0)
                      onRequestClose()
                    }}>Continuar</ButtonCmp>
                </Flex>
            </Flex>
          </Flex>
        </Modal>
  )
}

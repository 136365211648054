/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { TagContainer } from '../../../../../components/TagContainer'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iModalPadrao, iEnunciado, iPostCompetencia } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { ModalBody } from './styles'

interface IModal extends iModalPadrao {
  CompetenciaId?: string
}

export const ModalCriarCompetencia: React.FC<IModal> = ({ OnCloseModal, isOpen, CompetenciaId }) => {
  if (!isOpen) {
    return null
  }

  const toast = useToast()
  const { ObterCompetencias, AtualizarCompetencia, SelectCompetencia, ctxCompetencias } = useFormulario()

  const [NomeCompet, setNomeCompet] = useState('')
  const [Questoes, setQuestoes] = useState<iEnunciado[]>([{ enunciado: '' }])

  const [Imagens, setImagens] = useState<string[]>([])
  const [imgSel, setimgSel] = useState(0)
  const [TagsSel, setTagsSel] = useState<string[]>([])

  function getImgs(): void {
    appApi.get('Assets').then(response => {
      setImagens((response.data as string[]).filter(e => e.includes('svg')))
    }).catch(err => console.log(err))
  }

  function UpdateQuetaoArray(index: number, Texto: string): void {
    const array = [...Questoes]
    array[index].enunciado = Texto
    setQuestoes(array)
  }

  function RemoveQuestaoArray(index: number): void {
    const array: iEnunciado[] = []
    Questoes.forEach((e, i) => {
      if (i !== index) {
        array.push(e)
      }
    })
    setQuestoes(array)
  }

  function GetCompetenciaById(): void {
    const competencia = ctxCompetencias.find(e => e.id === CompetenciaId)
    if (competencia) {
      setNomeCompet(competencia.nome)
      setimgSel(Imagens.indexOf(competencia.icone))
      if (competencia.tags !== null && competencia.tags !== undefined) {
        setTagsSel(competencia.tags)
      }
    }
  }

  function addTag(tag: string): void {
    setTagsSel(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSel(TagsSel.filter(r => r !== id))
  }

  function CriarCompetencia(form: iPostCompetencia): void {
    appApi.post('Competencia', form)
      .then(res => {
        ObterCompetencias()
        SelectCompetencia(res.data)
        toast({
          title: 'Competência criada e selecionada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        OnCloseModal()
      })
      .catch(() => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      })
  }

  useEffect(() => {
    getImgs()
  }, [])

  useEffect(() => {
    if (CompetenciaId !== undefined) {
      GetCompetenciaById()
    }
  }, [Imagens])

  return (
    <ModalBase
      Titulo='Criar competência'
      isOpen={isOpen}
      onClose={OnCloseModal}
      Width='50rem'
    >
      <ModalBody>
        <h4 style={{ marginBottom: '.25rem' }}>Selecione um ícone</h4>
        <section>
          {
            Imagens.map((e: string, i) => {
              if (i === imgSel) {
                return (
                  <img style={{ border: '3px solid var(--Rosa)' }} key={i} src={e} />
                )
              } else {
                return (
                  <img onClick={() => setimgSel(i)} key={i} src={e} />
                )
              }
            })
          }
        </section>

        <Flex margin={'.5rem 0'} flexDir={'column'}>
          <InputCmp
            label='Nome da competência'
            value={NomeCompet}
            placeholder='Digite o nome da competência'
            OnChange={setNomeCompet}
          />
        </Flex>

        <TagContainer
          Selecionadas={TagsSel}
          addTag={addTag}
          removeTag={removeTag}
          isGrey
          tipo={0}
        />
        {
          (CompetenciaId === undefined) && (
            <>
              <Flex marginTop={'.5rem'} flexDir={'column'} gap={'.25rem'}>
                <Text fontSize={'.875rem'}>Questões</Text>
                <Flex mb={'.5rem'} flexDir={'column'} gap={'.325rem'}>
                  {
                    Questoes.map((e: iEnunciado, i) => {
                      return (
                        <Flex justifyContent={'space-between'} alignItems={'center'}>
                          <InputCmp
                            key={i}
                            placeholder='Digite a questão'
                            value={e.enunciado}
                            OnChange={(e) => { UpdateQuetaoArray(i, e) }}
                          />
                          <MdClose style={{ color: 'red', cursor: 'pointer', opacity: Questoes.length > 1 ? '100%' : '50%' }} onClick={() => { Questoes.length > 1 ? RemoveQuestaoArray(i) : undefined }}/>
                        </Flex>
                      )
                    })
                  }
                </Flex>
              </Flex>
              <Flex>
                <ButtonCmp VarColor='Rosa' onClick={() => setQuestoes(oldArray => [...oldArray, { enunciado: '' }])}>Adicionar questão</ButtonCmp>
              </Flex>
            </>
          )

        }
      </ModalBody>
      <Flex justifyContent={'end'} px={'1rem'} py={'.5rem'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => OnCloseModal()}>Fechar</ButtonCmp>
        {
          CompetenciaId === undefined
            ? <ButtonCmp VarColor='Green2' onClick={() => {
              CriarCompetencia({
                nome: NomeCompet,
                tags: TagsSel,
                icone: Imagens[imgSel],
                questoes: Questoes
              })
            }}>Criar competência</ButtonCmp>
            : <ButtonCmp VarColor='Green2' onClick={() => {
              AtualizarCompetencia(CompetenciaId, {
                nome: NomeCompet,
                icone: Imagens[imgSel],
                tags: TagsSel
              }); OnCloseModal()
            }}>Atualizar competência</ButtonCmp>
        }
      </Flex>
    </ModalBase>
  )
}

import Roboto from '../../../../assets/fonts/Roboto-Bold.ttf'

import {
  Document,
  Font,
  Image as PDFImage,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import { ArrayToChunks, GroupBy } from '../../../../Utils/Helper'
import { iModel, Questao } from '..'

interface iPdf{
  model: iModel
}

export interface iCompetenciaPdf {
  nome: string
  icone: string
  questoes: string[]
  discursiva: boolean
}

export const RelatorioPDF: React.FC<iPdf> = ({ model }) => {
  function ObterSvgPorParticipante(Titulo: string): any {
    if (Titulo === 'Autoavaliação') {
      return (
        <PDFImage
          style={{
            objectFit: 'contain'
          }}
          src={'https://sigah.blob.core.windows.net/onboard/2cc77da2-54e1-44e6-9dc5-f2af4e34121e.png'}
        />
      )
    }
    if (Titulo === 'Liderado') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/efec41e6-c698-4847-af1a-5fbdb56d2dec.png'}
        />
      )
    }
    if (Titulo === 'Gestor') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/9aa7241d-0425-4d31-b956-4be1df45e594.png'}
        />
      )
    }
    if (Titulo === 'Alta liderança') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/5ffcbe97-7929-41ff-9f29-21128d9eccda.png'}
        />
      )
    }
    if (Titulo === 'Par') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/4a59583f-e8c4-4f87-99c7-2756462e7bd0.png'}
        />
      )
    }
    if (Titulo === 'Outro') {
      return (
        <PDFImage
          src={'https://sigah.blob.core.windows.net/onboard/de510452-2eb6-4ba9-878f-b26b3299fcc3.png'}
        />
      )
    }
  }

  function ObterNomeParticipante(tipo: number): string {
    if (tipo === 0) {
      return 'Autoavaliação'
    } else if (tipo === 1) {
      return 'Gestor'
    } else if (tipo === 2) {
      return 'Par'
    } else if (tipo === 3) {
      return 'Liderado'
    } else if (tipo === 4) {
      return 'Outro'
    } else if (tipo === 6) {
      return 'Alta liderança'
    } else if (tipo === 7) {
      return ''
    }
    return 'Não identificado'
  }

  function Header(titulo?: string, landscape?: boolean): any {
    return (
      <View style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingHorizontal: '48px' }}>
          <View>
            <PDFImage style={{ objectFit: 'cover', maxWidth: '110px', maxHeight: '60px' }} src={'https://sigah.blob.core.windows.net/onboard/icones/cee4d066-461c-40c1-88e2-a37b1dad0329_ce55ff27-67e2-4acf-85c3-3dc78db149a0.png'}/>
          </View>
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingRight: 16
            }}
          >
            {titulo
              ? <Text
              style={{
                fontSize: 22,
                color: '#203864',
                fontWeight: 'semibold',
                textAlign: 'center',
                marginHorizontal: 'auto'
              }}
            >
              {titulo}
            </Text>
              : <>
            <Text
              style={{
                fontSize: 22,
                color: '#203864',
                fontWeight: 'semibold',
                textAlign: 'center',
                marginHorizontal: 'auto'
              }}
            >
              Formulário de questões
            </Text>
            <Text
              style={{
                fontSize: 22,
                color: '#203864',
                fontWeight: 'semibold',
                textAlign: 'center'
              }}
            >
              da pesquisa de percepção
            </Text>
            </>}
          </View>
          <View>
            {/* <PDFImage style={{ objectFit: 'cover', maxWidth: '110px', maxHeight: '60px' }} src={localStorage.logo}/> */}
          </View>
        </View>
        <View style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: '-1px' }}>
          <View style={{ backgroundColor: '#CC42A2', width: landscape ? '215px' : '120px', height: '3px' }}></View>
          <View style={{ backgroundColor: '#1961E0', width: landscape ? '215px' : '120px', height: '3px' }}></View>
          <View style={{ backgroundColor: '#66046B', width: landscape ? '215px' : '120px', height: '3px' }}></View>
        </View>
      </View>
    )
  }

  function Footer(): any {
    return (
      <View
        style={{
          backgroundColor: '#66046B',
          paddingVertical: 4,
          alignItems: 'center'
        }}
      >
        <View>
          <PDFImage
            style={{
              height: 35,
              objectFit: 'contain'
            }}
            source="https://sigah.blob.core.windows.net/onboard/0acc742b-1a78-4d9e-9bfd-26b6c1f46c19.png"
          />
        </View>
      </View>
    )
  }

  function AgruparCompetencias(competencias: Questao[], filtroAlternativa: boolean): iCompetenciaPdf[] {
    const arr: iCompetenciaPdf[] = []
    const grupo = GroupBy(competencias, (e) => e.nomeCompetencia).toList()
    grupo.forEach((e) => {
      const ar: iCompetenciaPdf = {
        discursiva: false,
        icone: e.data[0].avatar,
        nome: e.key,
        questoes: e.data.filter(e => !filtroAlternativa || (filtroAlternativa && !model.escalasAlternativas?.some(esc => esc.questoes.some(q => q.id === e.id)))).map(e => e.pergunta)
      }
      if (ar.questoes.length > 0) {
        arr.push(ar)
      }
    })
    return arr
  }

  Font.register({
    family: 'Roboto',
    fonts: [{
      src: Roboto
    }]
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      paddingTop: 16,
      flexDirection: 'column',
      justifyContent: 'space-between'
    }
  })

  return (
    <Document>
        {model.escala &&
          <Page>
              <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingTop: 16 }}>
            <View>
              {Header('Avaliadores e escala')}
              <View style={{ paddingLeft: 48, paddingRight: 48, marginBottom: 32, paddingTop: 32 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 32,
                    alignItems: 'center'
                  }}
                >
                  <Svg width="13" height="13">
                    <Path
                      d="M1.05 12L0 10.95L4.95 6L0 1.05L1.05 0L7.05 6L1.05 12ZM7.375 12L6.325 10.95L11.275 6L6.325 1.05L7.375 0L13.375 6L7375 12Z"
                      fill="#2C4D8A"
                    />
                  </Svg>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      paddingLeft: 16,
                      color: '#2C4D8A'
                    }}
                  >
                    Seus avaliadores são
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  {model.participantes.map((e, i) => {
                    return (
                      <View
                        key={i}
                        style={{
                          paddingHorizontal: 32,
                          marginBottom: 16,
                          flexDirection: 'row',
                          alignItems: 'center'
                        }}
                      >
                        <View style={{ width: '40', height: '40' }}>
                          {ObterSvgPorParticipante(ObterNomeParticipante(e))}
                        </View>
                        <Text style={{ paddingLeft: 16, color: '#203864' }}>
                          {ObterNomeParticipante(e)}
                        </Text>
                      </View>
                    )
                  })}
                </View>
              </View>
            </View>
            <View>
                <View>
                <View
                  style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                    paddingHorizontal: 48,
                    backgroundColor: '#4578D6',
                    color: 'white'
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: 16,
                      alignItems: 'center'
                    }}
                  >
                    <Svg width="13" height="13">
                      <Path
                        d="M1.05 12L0 10.95L4.95 6L0 1.05L1.05 0L7.05 6L1.05 12ZM7.375 12L6.325 10.95L11.275 6L6.325 1.05L7.375 0L13.375 6L7.375 12Z"
                        fill="white"
                      />
                    </Svg>
                    <Text
                      style={{ fontSize: 20, fontWeight: 'bold', paddingLeft: 16 }}
                    >
                      Escala da avaliação
                    </Text>
                  </View>

                  <View style={{ width: '100%', alignItems: 'center' }}>
                    <View style={{ alignItems: 'flex-start' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginBottom: 8
                        }}
                      >
                        <Svg width="23" height="19">
                          <Path
                            d="M8.26253 11.1875H6.53753C6.21985 11.1875 5.96253 11.4532 5.96253 11.7812V18.9062C5.96253 19.2343 6.21985 19.5 6.53753 19.5H8.26253C8.58022 19.5 8.83753 19.2343 8.83753 18.9062V11.7812C8.83753 11.4532 8.58022 11.1875 8.26253 11.1875ZM3.66253 14.75H1.93753C1.61985 14.75 1.36253 15.0157 1.36253 15.3438V18.9062C1.36253 19.2343 1.61985 19.5 1.93753 19.5H3.66253C3.98022 19.5 4.23753 19.2343 4.23753 18.9062V15.3438C4.23753 15.0157 3.98022 14.75 3.66253 14.75ZM12.8625 7.625H11.1375C10.8198 7.625 10.5625 7.8907 10.5625 8.21875V18.9062C10.5625 19.2343 10.8198 19.5 11.1375 19.5H12.8625C13.1802 19.5 13.4375 19.2343 13.4375 18.9062V8.21875C13.4375 7.8907 13.1802 7.625 12.8625 7.625ZM17.4625 4.0625H15.7375C15.4198 4.0625 15.1625 4.3282 15.1625 4.65625V18.9062C15.1625 19.2343 15.4198 19.5 15.7375 19.5H17.4625C17.7802 19.5 18.0375 19.2343 18.0375 18.9062V4.65625C18.0375 4.3282 17.7802 4.0625 17.4625 4.0625ZM22.0625 0.5H20.3375C20.0198 0.5 19.7625 0.765703 19.7625 1.09375V18.9062C19.7625 19.2343 20.0198 19.5 20.3375 19.5H22.0625C22.3802 19.5 22.6375 19.2343 22.6375 18.9062V1.09375C22.6375 0.765703 22.3802 0.5 22.0625 0.5Z"
                            fill="white"
                          />
                        </Svg>
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: 'semibold',
                            marginLeft: 16
                          }}
                        >
                          {model.escala.nome.split('(')[0]}
                        </Text>
                      </View>
                      {model.escala.respostas?.map((e, i) => {
                        return (
                          <View
                            key={i}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8
                            }}
                          >
                            <View
                              style={{
                                height: 4,
                                width: 4,
                                backgroundColor: 'white',
                                borderRadius: 2,
                                marginRight: 8
                              }}
                            />
                            <Text style={{ fontSize: 14 }}>
                              {e.resposta + ' ' + e.peso.toString().split('-1')[0]}
                            </Text>
                          </View>
                        )
                      })}
                    </View>
                  </View>
                  </View>
                  <View
                    style={{
                      backgroundColor: '#CC42A2',
                      height: 16,
                      width: '100%',
                      marginBottom: 10
                    }}
                  />
                  {Footer()}
                </View>
            </View>
              </View>
          </Page>
        }
      {ArrayToChunks((AgruparCompetencias(model.questoes.filter(e => !e.discursiva), true)), 5)?.map((e, i) => {
        return (
           <Page key={i} style={styles.DefaultPage}>
             <View>
               {Header('Competências utilizadas')}
               <View
                 style={{
                   paddingTop: 16,
                   paddingHorizontal: 32
                 }}
               >
                 <View
                   style={{
                     flexDirection: 'column'
                   }}
                 >
                   {e.map((r, i2) => {
                     return (
                       <View key={i2}>
                         <View
                           style={{
                             flexDirection: 'row',
                             alignItems: 'center'
                           }}
                         >
                           <View
                             style={{
                               flexDirection: 'column',
                               alignItems: 'center'
                             }}
                           >
                             <PDFImage
                               src={r.icone.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png')}
                               style={{ width: 50, height: 50 }}
                             />
                             <Text
                               style={{
                                 marginTop: 8,
                                 width: 90,
                                 fontSize: 12,
                                 fontWeight: 'semibold',
                                 textAlign: 'center'
                               }}
                             >
                               {r.nome}
                             </Text>
                           </View>
                           <View style={{ flexDirection: 'column', width: '100%', left: 25 }}>
                             {r.questoes.map((questao, i3) => {
                               return (
                                  <Text
                                    key={i3}
                                    style={{
                                      fontSize: 12,
                                      width: '80%',
                                      textAlign: 'left',
                                      marginTop: 8
                                    }}
                                  >
                                    {`${(i3 + 1)}. ` + questao}
                                  </Text>
                               )
                             }
                             )}
                           </View>
                         </View>
                         <View
                           style={{
                             height: 3,
                             width: '100%',
                             backgroundColor: '#E3E3E3',
                             marginBottom: 16,
                             marginTop: 16
                           }}
                         />
                       </View>
                     )
                   }
                   )}
                 </View>
               </View>
             </View>
             {Footer()}
           </Page>
        )
      })}
      {model.escalasAlternativas?.map((e, i) => {
        return (
          <>
            {model.escala &&
              <Page>
                  <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', paddingTop: 16 }}>
                <View>
                  {Header(`${i + 1}ª Escala alternativa`)}
                  <View style={{ paddingLeft: 48, paddingRight: 48, marginBottom: 32, paddingTop: 32 }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginBottom: 32,
                        alignItems: 'center'
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          paddingLeft: 16
                        }}
                      >
                        Essa escala alternativa possuí questões no formulário para as seguintes competências: <Text style={{ color: '#2C4D8A', fontWeight: 'bold' }}>{GroupBy(e.questoes, (a) => a.nomeCompetencia).toList().map((a, i) => { return `${i === 0 ? '' : ', '}${a.key}` })}</Text>
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }}
                    >
                    </View>
                  </View>
                </View>
                <View>
                    <View>
                    <View
                      style={{
                        paddingTop: 40,
                        paddingBottom: 40,
                        paddingHorizontal: 48,
                        backgroundColor: '#4578D6',
                        color: 'white'
                      }}
                    >
                      <View style={{ width: '100%', alignItems: 'center' }}>
                        <View style={{ alignItems: 'flex-start' }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginBottom: 8
                            }}
                          >
                            <Svg width="23" height="19">
                              <Path
                                d="M8.26253 11.1875H6.53753C6.21985 11.1875 5.96253 11.4532 5.96253 11.7812V18.9062C5.96253 19.2343 6.21985 19.5 6.53753 19.5H8.26253C8.58022 19.5 8.83753 19.2343 8.83753 18.9062V11.7812C8.83753 11.4532 8.58022 11.1875 8.26253 11.1875ZM3.66253 14.75H1.93753C1.61985 14.75 1.36253 15.0157 1.36253 15.3438V18.9062C1.36253 19.2343 1.61985 19.5 1.93753 19.5H3.66253C3.98022 19.5 4.23753 19.2343 4.23753 18.9062V15.3438C4.23753 15.0157 3.98022 14.75 3.66253 14.75ZM12.8625 7.625H11.1375C10.8198 7.625 10.5625 7.8907 10.5625 8.21875V18.9062C10.5625 19.2343 10.8198 19.5 11.1375 19.5H12.8625C13.1802 19.5 13.4375 19.2343 13.4375 18.9062V8.21875C13.4375 7.8907 13.1802 7.625 12.8625 7.625ZM17.4625 4.0625H15.7375C15.4198 4.0625 15.1625 4.3282 15.1625 4.65625V18.9062C15.1625 19.2343 15.4198 19.5 15.7375 19.5H17.4625C17.7802 19.5 18.0375 19.2343 18.0375 18.9062V4.65625C18.0375 4.3282 17.7802 4.0625 17.4625 4.0625ZM22.0625 0.5H20.3375C20.0198 0.5 19.7625 0.765703 19.7625 1.09375V18.9062C19.7625 19.2343 20.0198 19.5 20.3375 19.5H22.0625C22.3802 19.5 22.6375 19.2343 22.6375 18.9062V1.09375C22.6375 0.765703 22.3802 0.5 22.0625 0.5Z"
                                fill="white"
                              />
                            </Svg>
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: 'semibold',
                                marginLeft: 16
                              }}
                            >
                              {e.escala.nome}
                            </Text>
                          </View>
                          {e.escala.respostas?.map((e, i) => {
                            return (
                              <View
                                key={i}
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  marginBottom: 8
                                }}
                              >
                                <View
                                  style={{
                                    height: 4,
                                    width: 4,
                                    backgroundColor: 'white',
                                    borderRadius: 2,
                                    marginRight: 8
                                  }}
                                />
                                <Text style={{ fontSize: 14 }}>
                                  {e.resposta + ' ' + e.peso.toString().split('-1')[0]}
                                </Text>
                              </View>
                            )
                          })}
                        </View>
                      </View>
                      </View>
                      <View
                        style={{
                          backgroundColor: '#CC42A2',
                          height: 16,
                          width: '100%',
                          marginBottom: 10
                        }}
                      />
                      {Footer()}
                    </View>
                </View>
                  </View>
              </Page>
            }
          {ArrayToChunks((AgruparCompetencias(e.questoes.filter(a => !a.discursiva), false)), 5)?.map((a, i) => {
            return (
              <Page key={i} style={styles.DefaultPage}>
                <View>
                  {Header('Competências utilizadas')}
                  <View
                    style={{
                      paddingTop: 16,
                      paddingHorizontal: 32
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'column'
                      }}
                    >
                      {a.map((r, i2) => {
                        return (
                          <View key={i2}>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: 'column',
                                  alignItems: 'center'
                                }}
                              >
                                <PDFImage
                                  src={r.icone.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png')}
                                  style={{ width: 50, height: 50 }}
                                />
                                <Text
                                  style={{
                                    marginTop: 8,
                                    width: 90,
                                    fontSize: 12,
                                    fontWeight: 'semibold',
                                    textAlign: 'center'
                                  }}
                                >
                                  {r.nome}
                                </Text>
                              </View>
                              <View style={{ flexDirection: 'column', width: '100%', left: 25 }}>
                                {r.questoes.map((questao, i3) => {
                                  return (
                                      <Text
                                        key={i3}
                                        style={{
                                          fontSize: 12,
                                          width: '80%',
                                          textAlign: 'left',
                                          marginTop: 8
                                        }}
                                      >
                                        {`${(i3 + 1)}. ` + questao}
                                      </Text>
                                  )
                                }
                                )}
                              </View>
                            </View>
                            <View
                              style={{
                                height: 3,
                                width: '100%',
                                backgroundColor: '#E3E3E3',
                                marginBottom: 16,
                                marginTop: 16
                              }}
                            />
                          </View>
                        )
                      }
                      )}
                    </View>
                  </View>
                </View>
                {Footer()}
              </Page>
            )
          })}
          </>
        )
      })}
    </Document>
  )
}

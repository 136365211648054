import { Container } from './styles'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { Flex, Image } from '@chakra-ui/react'

import IconeLove from '../../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../../assets/IconeTriste.svg'
import { useNavigate } from 'react-router-dom'

interface iDadoTabela {
  id: string
  origem: string
  totalAcoes: number
  totalConcluidas: number
  dataFim: string
  ultimaAvaliacao?: number
  temAcaoAtrasada: boolean
  temAcaoAlterada?: boolean
}

interface iTabelaProps {
  slice: number
  dados: iDadoTabela[]
}

export const TabelaEmAndamento: React.FC<iTabelaProps> = ({ dados, slice }) => {
  const nav = useNavigate()
  function UltimaAvaliacao(nota: number): any {
    if (nota === 1) {
      return <Image w={'2rem'} src={IconeTriste} />
    } else if (nota === 2) {
      return <Image w={'2rem'} src={IconeMagoado} />
    } else if (nota === 3) {
      return <Image w={'2rem'} src={IconeFeliz} />
    } else if (nota === 4) {
      return <Image w={'2rem'} src={IconePiscada} />
    } else {
      return <Image w={'2rem'} src={IconeLove} />
    }
  }

  return (
    <Container>
      <thead>
        <tr>
          <th>Origem</th>
          <th>Concluídas / <br /> total de ações</th>
          <th>Data final</th>
        </tr>
      </thead>
      <tbody>
        {
          dados.slice(0, slice).map((e, i) => {
            return (
              <tr style={{ color: e.temAcaoAtrasada ? 'var(--Red)' : '' }} key={i}>
                <th>{e.origem}</th>
                <th>{e.totalConcluidas}/{e.totalAcoes}</th>
                <th>{e.dataFim}</th>
                <th>
                  <Flex maxHeight={'2rem'}>
                    {e.ultimaAvaliacao && UltimaAvaliacao(e.ultimaAvaliacao)}
                  </Flex>
                </th>
                <th>
                  <Flex gap={'.75rem'} justifyContent={'center'}>
                    {!e.temAcaoAlterada && (
                      <ButtonCmp
                        size={'sm'}
                        VarColor='Green2'
                        onClick={() => nav(`/Desempenho/PDI/Avaliacao/${e.id}`)}
                      >Avaliar</ButtonCmp>
                    )}

                    <ButtonCmp
                      size={'sm'}
                      VarColor='Green2'
                      colorScheme={'blueDefault'}
                      onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)}
                    >Visualizar</ButtonCmp>
                  </Flex>
                </th>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}

import { ComentarioContainer } from '../../styles'

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
}

interface iDiscursivaProps {
  questao: iFormQuestaoResponse
  erro: boolean
  UpdateText: (id: string, upTexto: string) => void
}

export const Discursiva: React.FC<iDiscursivaProps> = ({ questao, erro, UpdateText }) => {
  return (
        <ComentarioContainer>
            <h3>{questao.enunciado}</h3>
            {erro && <span>* Preencha este campo</span>}
            <textarea
                onBlur={(e) => UpdateText(questao.id, e.target.value)}
                placeholder="Deixe seu comentário"
            />
        </ComentarioContainer>
  )
}

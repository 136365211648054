import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import Face from '../../../assets/Nps.svg'
import { Button, Checkbox, Flex, FormControl, FormLabel, Input, Select, useToast } from '@chakra-ui/react'
import { Form, Tags } from './styles'
import { Tag } from '../../../components/Tag'
import { FaAngleLeft, FaCheck, FaPen, FaPlus, FaRegPlayCircle, FaSave } from 'react-icons/fa'
import { ModalCreateDemografia } from './Components/ModalCreateDemografia'
import { ModalUpdateDemografia } from './Components/ModalUpdateDemografia'
import { useEffect, useState } from 'react'
import { appApi, SigahApi } from '../../../services/appApi'
import { iDemografia, iPostDemografia } from '../../../interfaces'
import { useNavigate } from 'react-router-dom'
import { ObterToken } from '../../../Utils/Helper'

export const ConfigNps: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const [ModalAddDemografia, setModalAddDemografia] = useState(false)
  const [ModalUpeDemografia, setModalUpDemografia] = useState(false)
  // const [ModalConfirm, setModalConfirm] = useState(false)

  const [Demografias, setDemografias] = useState<iDemografia[]>([])
  const [DemografiaEdit, setDemografiaEdit] = useState<iDemografia>()
  const [eNpsOn, seteNpsOn] = useState(false)
  const [NpsOn, setNpsOn] = useState(false)
  const [PersonalizadaOn, setPersonalizadaOn] = useState(false)
  const [DescricaoNps, setDescricaoNps] = useState('')
  const [DescricaoeNps, setDescricaoeNps] = useState('')
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [Periodicidade, setPeriodicidade] = useState('0')
  const [TemPrimeiroEnvio, setTemPrimeiroEnvio] = useState(true)

  const [eNpsEdit, seteNpsEdit] = useState(false)
  const [NpsEdit, setNpsEdit] = useState(false)

  function getDemografias(): void {
    SigahApi.get('api/Onboard/Demografia', {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    }).then(res => setDemografias(res.data))
      .catch(err => console.log(err))
  }

  function getConfig(): void {
    appApi.get('NPS/Config')
      .then(res => {
        seteNpsOn(res.data.enpsOn)
        setDescricaoeNps(res.data.descricaoEnps)
        setNpsOn(res.data.npsOn)
        setDescricaoNps(res.data.descricaoNps)
        setSelecionados(res.data.selecionados)
        setPersonalizadaOn(res.data.personalOn)
        setPeriodicidade(res.data.periodicidade)
        setTemPrimeiroEnvio(res.data.primeiroEnvio)
      })
      .catch(err => console.log(err))
  }

  function AlterarSelecaoDemografia(demId: string): void {
    appApi.patch(`NPS/Demografia/${demId}`)
      .then(getConfig)
      .catch(err => console.log(err))
  }

  function PostDemografia(form: iPostDemografia): void {
    SigahApi.post('api/Onboard/Demografia', form, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        setModalAddDemografia(false)
        AlterarSelecaoDemografia(res.data)
        getDemografias()
      })
      .catch(err => console.log(err))
  }

  function PutDemografia(form: iDemografia): void {
    SigahApi.put(`api/Onboard/Demografia/${form.id}`, form, {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(() => {
        setModalUpDemografia(false)
        getDemografias()
      })
      .catch(err => console.log(err))
  }

  function PutConfig(): void {
    const form = {
      periodicidade: parseInt(Periodicidade),
      EnpsOn: eNpsOn,
      descricaoEnps: DescricaoeNps,
      NpsOn: NpsOn,
      descricaoNps: DescricaoNps,
      PersonalOn: PersonalizadaOn,
      isEnvioAgora: false
    }

    appApi.put('NPS', form)
      .then(() => {
        toast({
          title: 'Salva com sucesso',
          status: 'success',
          isClosable: false
        })
      })
      .catch(err => console.log(err))
  }

  function onOpenEditDemografia(dem: iDemografia): void {
    setModalUpDemografia(true)
    setDemografiaEdit(dem)
  }

  function onCloseEditDemografia(): void {
    setModalUpDemografia(false)
    setDemografiaEdit(undefined)
  }

  function DeleteDemografia(Id: string): void {
    SigahApi.delete(`api/Onboard/Demografia/${Id}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    }).then(() => {
      getDemografias()
      toast({
        title: 'Removida com sucesso',
        status: 'success',
        isClosable: false
      })
    }).catch(err => console.log(err))
  }

  function PrimeiroEnvio(): void {
    appApi.post('NPS/PrimeiroEnvio')
      .then(() => {
        toast({
          title: 'Enviado com sucesso',
          status: 'success',
          isClosable: false
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getDemografias()
    getConfig()
  }, [])

  return (
    <Body>
      {/* <ModalDelete
        isOpen={ModalConfirm}
        message='Tem certeza que deseja sair sem salvar as alterações?'
        onConfirm={() => nav(-1)}
        onRequestClose={() => setModalConfirm(false)}
      /> */}

      {
        (ModalAddDemografia) && (
          <ModalCreateDemografia
            isOpen={ModalAddDemografia}
            onClose={() => setModalAddDemografia(false)}
            onCreate={PostDemografia}
          />
        )
      }

      <ModalUpdateDemografia
        isOpen={ModalUpeDemografia}
        Demografia={DemografiaEdit}
        isSelecionado={Selecionados.find(r => r === DemografiaEdit?.id) !== undefined}
        onClose={onCloseEditDemografia}
        onUpdate={PutDemografia}
        onAlterSelecao={AlterarSelecaoDemografia}
        onDelete={DeleteDemografia}
      />

      <WhiteBox>
        <h2>Engajamento {'>'} eNPS {'>'} Configurações</h2>
        <Flex marginTop={'1rem'} justifyContent={'center'}>
          <img src={Face} />
        </Flex>

        <FormControl margin={'2rem 0rem'}>
          <FormLabel fontSize={'lg'}>Periodicidade do envio para os colaboradores</FormLabel>
          <Select onChange={(e) => setPeriodicidade(e.target.value)} value={Periodicidade} borderColor={'var(--Gray4)'} size='md'>
            <option value={'3'}>Mensal</option>
            <option value={'4'}>Bimestral</option>
            <option value={'5'}>Trimestral</option>
            <option value={'6'}>Semestral</option>
            <option value={'7'}>Anual</option>
          </Select>
        </FormControl>

        <Form>
          <FormControl marginBottom={'1rem'}>
            <FormLabel fontSize={'lg'}>Selecione o que deseja acompanhar</FormLabel>
            <Flex marginBottom={'.5rem'} flexDirection={'column'}>
              <Flex>
                <Checkbox isChecked={eNpsOn} onChange={(e) => seteNpsOn(e.target.checked)} size={'lg'} marginRight={'.5rem'} />
                <span>eNPS</span>
              </Flex>
              <Flex marginLeft={'1.8rem'} alignItems={'center'}>
                {
                  (eNpsEdit) && (
                    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                      <Flex>
                        <li>Em uma escala de 0 a 10, qual a probabilidade de você recomendar</li>
                        {
                          <Input
                            size={'sm'}
                            borderTop={0}
                            borderLeft={0}
                            margin={'0rem 1rem'}
                            onChange={(e) => setDescricaoeNps(e.target.value)}
                            value={DescricaoeNps}
                            borderRight={0}
                            width={'8rem'}
                            variant={'flushed'}
                          />
                        }
                        <li style={{ listStyleType: 'none' }}>o local de trabalho a um amigo ou colega?</li>
                      </Flex>
                      <FaCheck cursor={'pointer'} color='var(--Green2)' onClick={() => seteNpsEdit(false)} />
                    </Flex>
                  )
                }
                {
                  (!eNpsEdit) && (
                    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                      <li>Em uma escala de 0 a 10, qual a probabilidade de você recomendar {DescricaoeNps} o local de trabalho a um amigo ou colega?</li>
                      <FaPen cursor={'pointer'} color='var(--Azul)' onClick={() => seteNpsEdit(true)} />
                    </Flex>
                  )
                }
              </Flex>
            </Flex>
          </FormControl>

          <FormControl>
            <Flex marginBottom={'.5rem'} flexDirection={'column'}>
              <Flex>
                <Checkbox isChecked={NpsOn} onChange={(e) => setNpsOn(e.target.checked)} size={'lg'} marginRight={'.5rem'} />
                <span>NPS</span>
              </Flex>
              <Flex marginLeft={'1.5rem'} alignItems={'center'}>
                {
                  (NpsEdit) && (
                    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                      <Flex>
                        <li>Em uma escala de 0 a 10, qual a probabilidade de você recomendar</li>
                        {
                          <Input
                            size={'sm'}
                            borderTop={0}
                            borderLeft={0}
                            margin={'0rem 1rem'}
                            value={DescricaoNps}
                            borderRight={0}
                            width={'12rem'}
                            variant={'flushed'}
                            onChange={(e) => setDescricaoNps(e.target.value)}
                          />
                        }
                        <li style={{ listStyleType: 'none' }}> a um amigo ou colega?</li>
                      </Flex>
                      <FaCheck cursor={'pointer'} color='var(--Green2)' onClick={() => setNpsEdit(false)} />
                    </Flex>
                  )
                }
                {
                  (!NpsEdit) && (
                    <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
                      <li>Em uma escala de 0 a 10, qual a probabilidade de você recomendar {DescricaoNps} a um amigo ou colega?</li>
                      <FaPen cursor={'pointer'} color='var(--Azul)' onClick={() => setNpsEdit(true)} />
                    </Flex>
                  )
                }
              </Flex>
            </Flex>
          </FormControl>

          <Flex margin={'1rem 0rem'} flexDirection={'column'}>
            <FormLabel fontSize={'lg'}>Demografias adicionadas</FormLabel>
            <Tags>
              {
                Demografias.map((e, i) => {
                  return (
                    <Tag
                      key={i}
                      color={Selecionados.find(r => r === e.id) === undefined ? 'var(--c6)' : undefined}
                      onclickText={() => onOpenEditDemografia(e)}
                    >{e.nome}</Tag>
                  )
                })
              }
            </Tags>
            <Flex>
              <Button
                borderRadius={'12px'}
                size={'md'}
                bg={'var(--v2)'}
                _hover={{ bg: 'var(--v2)' }}
                leftIcon={<FaPlus color='white' />}
                fontWeight={500}
                marginTop={'2rem'}
                onClick={() => setModalAddDemografia(true)}
              >Adicionar demografia</Button>
            </Flex>
          </Flex>

          <Flex justifyContent={'end'}>
            <Button
              borderRadius={'12px'}
              size={'lg'}
              bg={'var(--Gray4)'}
              _hover={{ bg: 'var(--Gray4)' }}
              leftIcon={<FaAngleLeft color='white' />}
              fontWeight={500}
              onClick={() => nav(-1)}
            >Voltar</Button>

            <Button
              borderRadius={'12px'}
              size={'lg'}
              bg={'var(--Green2)'}
              _hover={{ bg: 'var(--Green2)' }}
              rightIcon={<FaSave color='white' />}
              fontWeight={500}
              margin={'0rem 1rem'}
              onClick={PutConfig}
            >Salvar</Button>

            {
              (!TemPrimeiroEnvio) && (
                <Button
                  borderRadius={'12px'}
                  size={'lg'}
                  bg={'var(--Green2)'}
                  _hover={{ bg: 'var(--Green2)' }}
                  rightIcon={<FaRegPlayCircle color='white' />}
                  fontWeight={500}
                  onClick={PrimeiroEnvio}
                >Realizar o primeiro envio</Button>
              )
            }
          </Flex>
        </Form>
      </WhiteBox>
    </Body>
  )
}

import styled from 'styled-components'

export const TableDiv = styled.div`
display: flex;
justify-content: center;
width: 100%;
font-size: .75rem !important;
table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    padding: .25rem;
    overflow-x: hidden;
}

td{
    transition: 2s;
    text-align: center;
    min-width: 5rem;
    max-width: 5rem;
}

th{
    background: var(--Azul);
    color: var(--c1);
    font-weight: 500;
}

tr:nth-child(even) td {
    background-color: var(--c3)
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`

export const StyledTd = styled.td`
    color: ${props => props.theme};
    font-weight: bold;
`

import styled from 'styled-components'

export const BoxBody = styled.div`
margin-top: 1rem;
display: flex;
flex-direction: column;

input[type=file]{
    display: none;
}

div:first-child{
margin-bottom: 1rem;

label{
    color: var(--a4);
    font-weight: 500;
    margin-bottom: 0.25rem;
    font-size:1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
}
}
`

export const FileBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: var(--c2);
padding: 1rem;
width: 100%;
text-align: center;
font-family: 'Roboto','sans-serif';
margin-bottom: 1rem;

h3,span,h2{
color: var(--a5);
}

span{
    font-weight: bold;
}

h3{
margin-top: 1rem;
}

button{
display: flex;
align-items: center;
padding:0.6rem 2rem 0.6rem 2rem;
background: var(--a1);
border-radius: 8px;
color: var(--c1);
margin-top: 1rem;
    svg{
        margin: 0rem 0.3rem 0rem .3rem;
    }
}
`

export const Column = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
width:8.9rem;

span ,small{
    font-weight: bold;
}

span{
    color: var(--a2);
    margin-top: .25rem;
    word-break: break-all;
    text-align: start;
}

small{
    color: var(--c7);
}
`

export const FileBoxSelecionada = styled.div`
display: flex;
background: var(--c2);
padding: 1.5rem;
margin-bottom: 1rem;
font-family: 'Roboto','sans-serif';
display: flex;
flex-direction: column;
align-items: center;
`

export const LabelFile = styled.label`
display: flex;
align-items: center;
padding:0.6rem 2rem 0.6rem 2rem;
background:#${props => props.theme};
border-radius: 8px;
color: var(--c1);
margin-top: 1rem;

&:hover{
    filter: brightness(1.05);
    box-shadow: var(--SombraBtns);
}
cursor: pointer;
    svg{
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const LabelSelecionado = styled.label`
display: flex;
align-items: center;
padding:0.6rem 1rem 0.6rem 1rem;
border-radius: 8px;
border: 1px solid var(--c7);
width: 12rem;

&:hover{
    background: var(--c7);
    color: white;

    svg{
        color: white;
    }
}

cursor: pointer;
    svg{
        color:var(--c7) ;
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const BoxIntegracao = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border: 0.125rem solid var(--c4);
padding: 1rem;
border-radius: 0.938rem;
margin-bottom: 1rem;
`

export const BoxHeader = styled.div`
display: flex;
justify-content: space-between;
span{
    color: var(--a1);
    font-family: 'Poppins','sans-serif';
    font-weight: bold;
    font-size: 1.3rem;
}


svg{
    color: var(--terc3);
    cursor: pointer;
}
`

/* eslint-disable no-tabs */
import { Flex, FormControl, Img, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import gifCheck from '../../../../assets/checkGif.gif'
import { TextAreaCmp } from '../../../../components/TextareaCmp'

export const ReuniaoRealizada: React.FC = () => {
  const { processoId, etapaId } = useParams<{ processoId: string, etapaId: string }>()
  const [Comentario, setComentario] = useState('')
  const { pathname } = useLocation()
  const nav = useNavigate()
  const toast = useToast()
  const rota = pathname.includes('ReuniaoOne') ? `/ReuniaoOneOldEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Concluir` : `/ReuniaoEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Concluir`

  function ConcluirReuniao(): void {
    appApi.patch(rota, { texto: Comentario }).then(() => {
      toast({
        title: 'Concluído com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      nav('/')
    }
    )
      .catch(err => console.log(err))
  }
  return (
		<Body>
			<Flex width={'75rem'}flexDirection={'column'} p={'1rem'} mt={'3rem'} fontWeight={'700'} mx={'auto'} alignItems={'center'} justifyContent={'center'} bgColor={'#fff'} borderRadius={'.25rem'}>
				<h3>Reunião concluída com sucesso!</h3>
        <Img w={'5.5rem'} h={'5.5rem'} src={gifCheck} mb={'1rem'}/>
        {pathname.includes('ReuniaoOne') && <FormControl color={'#000'}>
          <TextAreaCmp label='Deixe um comentário para registro (Opcional)' placeholder='Escreva o comentário aqui' OnChange={setComentario}/>
        </FormControl>}
        <Flex justifyContent={'center'} my={'1rem'}>
          <ButtonCmp VarColor='Green2' onClick={ConcluirReuniao}>Concluir etapa {pathname.includes('ReuniaoOne') ? 'e enviar comentário' : ''}</ButtonCmp>
        </Flex>
			</Flex>
		</Body>
  )
}

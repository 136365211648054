import { Flex, FormControl, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Title, Main } from './styles'
import { FaEye, FaPlus } from 'react-icons/fa'
import { CreateEtapaBox } from '../../../components/CreateEtapaBox'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iItemIntegracao, iEtapa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { ValidarEtapa } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { InputCmp } from '../../../components/InputCmp'
import { CardImersao } from './Components/CardImersao'
import LoadingContainer from '../../../components/LoadingContainer'
type LocationProps = {
  trilhaId: string
  imersaoId: string
  etapaId?: string
  processoId?: string
  nomeIntegracao?: string
}

export const CriarImersao: React.FC = () => {
  const toast = useToast()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { imersaoId, trilhaId, etapaId, processoId } = useParams<LocationProps>()
  const { nomeIntegracao } = useLocation().state as LocationProps || {}
  const [integracoes, setIntegracoes] = useState<iItemIntegracao[]>([] as iItemIntegracao[])

  const [EditEtapa, setEditEtapa] = useState<iEtapa>()

  const [IsLoading, setIsLoading] = useState(false)
  const [Selecionar, setSelecionar] = useState(false)
  const [NomeIntegracao, setNomeIntegracao] = useState(nomeIntegracao ?? '')

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `IntegracaoEtapa/${trilhaId}` : processoId ? `IntegracaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?integracaoId=${imersaoId ?? ''}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-2)
    }).catch(err => {
      console.error(err)
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function GetIntegracoesById(): void {
    setIsLoading(true)
    appApi.get(`Integracao/${imersaoId ?? ''}`)
      .then(response => {
        setIntegracoes(response.data.itensIntegracao)
        setNomeIntegracao(response.data.nome)
      }).catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  function CreateItemIntegracao(Tipo: number): void {
    appApi.post(`Integracao/${imersaoId ?? ''}/Item`, {
      titulo: '',
      descricao: '',
      tipo: Tipo
    })
      .then(() => { GetIntegracoesById() }).catch(err => console.error(err))
  }

  function DeltarItemIntegracao(Id: string): void {
    const filterRes = [...integracoes.filter(e => e.id !== Id)]
    appApi.delete(`Integracao/${imersaoId ?? ''}/Item/${Id}`)
      .then(() => { setIntegracoes([]); setIntegracoes(filterRes) }).catch(err => console.error(err))
  }

  function AtualizarItemIntegracao(item: iItemIntegracao, tipo?: number, tipoDescricao?: number): void {
    if (tipoDescricao) setIsLoading(true)
    const idItem: string = item.id
    const changeLayout = tipoDescricao === 1 && item.tipoDescricao === 0 && item.layout === 3
    const form: Omit<iItemIntegracao, 'id'> = {
      titulo: item.titulo,
      descricao: item.descricao,
      tipo: tipo ?? item.tipo,
      checklist: tipoDescricao === 0 ? undefined : item.checklist,
      envioEmail: item.envioEmail,
      tipoDescricao: tipoDescricao ?? item.tipoDescricao,
      layout: changeLayout ? 0 : item.layout
    }
    appApi.put(`Integracao/${imersaoId ?? ''}/Item/${idItem}`, form).catch(e => console.log(e)).finally(() => setIsLoading(false))

    setIntegracoes((integracoesAnteriores) => {
      return integracoesAnteriores.map((e) => {
        if (e.id === idItem) {
          return {
            ...form,
            arquivo: item.arquivo,
            id: idItem
          }
        }
        return e
      })
    })
  }

  function AtualizarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    form.nome = NomeIntegracao
    const url = trilhaId
      ? `IntegracaoEtapa/${etapaId as string}`
      : processoId
        ? `IntegracaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}`
        : ''
    appApi.put(`${url}`, form).then(() => {
      toast({
        title: 'Etapa atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      if (trilhaId) {
        navigate(`/Jornada/Configuracao/Trilha/${trilhaId}`)
      } else {
        navigate(`/Rh/VisualizarPerfil/${processoId as string}`)
      }
    }).catch(() => {
      toast({
        title: 'Não foi possível atualizar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function ObterEtapaEdit(): void {
    const url = trilhaId ? `Etapa/${etapaId ?? ''}` : processoId ? `EtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        const data: iEtapa = res.data
        setEditEtapa(data)
        setNomeIntegracao(data?.nome ?? '')
      }).catch(err => console.log(err))
  }

  function IniciarImersao(): void {
    if (integracoes.length > 0) {
      navigate('Convites')
    } else {
      toast({
        title: 'Sua imersão precisa ter no mínimo um item para ser iniciada',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    }
  }

  function EditarIntegracao(Texto: string): void {
    const dados = {
      url: ''
    }
    dados.url = !pathname.includes('Editar') ? `Integracao/${imersaoId ?? ''}` : processoId ? `IntegracaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    if (pathname.includes('Trilha')) {
      dados.url = `IntegracaoEtapa/${etapaId ?? ''}`
    }
    const form = {
      ...EditEtapa,
      nome: Texto
    }
    appApi.put(dados.url, form).then(response => {
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    if (imersaoId !== undefined) {
      GetIntegracoesById()
    }
    if (etapaId !== undefined) {
      ObterEtapaEdit()
    }
  }, [imersaoId])

  return (
    <Body>
      <Title>
        <h1>Imersão cultural</h1>
        <span>Aqui você pode adicionar vídeos, imagens, documentos ou até um check list para acompanhar o que deve ou foi realizado na integração do colaborador</span>
      </Title>
      <Main>
        {
          !Selecionar
            ? <><WhiteContainer>
              <FormControl marginBottom={'1rem'}>
                <InputCmp
                  label='Nome da Imersão'
                  value={NomeIntegracao}
                  OnChange={setNomeIntegracao}
                  placeholder='Digite o nome'
                  onBlur={(e) => {
                    EditarIntegracao(e.target.value)
                    setNomeIntegracao(e.target.value)
                  }}
                />
              </FormControl>
              <LoadingContainer loading={IsLoading} linhas={22}>
                <Flex display={'grid'} gap={'1rem 0rem'}>
                  {
                    integracoes.map((e, i) => {
                      return (
                            <CardImersao
                              key={e.id}
                              position={i + 1}
                              id={imersaoId ?? ''}
                              ItemIntegracao={e}
                              qtdItens={integracoes.length}
                              onDelete={DeltarItemIntegracao}
                              onUpdateInput={AtualizarItemIntegracao}
                              onChangeTipo={AtualizarItemIntegracao}
                              hideCheckbox={!processoId}
                              onReordenar={() => {
                                if (imersaoId !== undefined) {
                                  GetIntegracoesById()
                                }
                                if (etapaId !== undefined) {
                                  ObterEtapaEdit()
                                }
                              }}
                              onChangeTipoDescricao={(e, tipo) => { AtualizarItemIntegracao(e, undefined, tipo) }}
                            />
                      )
                    })
                  }
                </Flex>
                <Flex justifyContent={'center'} m={'.75rem 0 0.25rem 0'}>
                  <ButtonCmp VarColor='Green2' onClick={() => { CreateItemIntegracao(1) }} leftIcon={<FaPlus/>}>Crie um novo item para essa imersão</ButtonCmp>
                </Flex>
              </LoadingContainer>
            </WhiteContainer>
            <Flex justifyContent={'end'} marginTop={'1rem'} gap={'.5rem'}>
              <ButtonCmp
                VarColor='c6'
                onClick={() => navigate(-1)}
              >Voltar</ButtonCmp>
              {(integracoes.length > 0) && <ButtonCmp
                VarColor='Rosa'
                leftIcon={<FaEye />}
                onClick={() => navigate(pathname + '/Previsualizar')}
              >
                Pré-visualização completa da imersão cultural
              </ButtonCmp>}
              {!(integracoes.length > 0) &&
                <ElementoTooltipCmp bg={'var(--Rosa)'} label='Adicione pelo menos um elemento na imersão para pré-visualizar'>
                  <ButtonCmp
                    VarColor='Rosa'
                    disabled
                    leftIcon={<FaEye />}
                    onClick={() => navigate(pathname + '/Previsualizar')}
                  >
                    Pré-visualização completa da imersão cultural
                  </ButtonCmp>
                </ElementoTooltipCmp>
              }
              <ButtonCmp
                VarColor='Green2'
                fontWeight={'500'}
                onClick={IniciarImersao}
              >
                Salvar imersão
              </ButtonCmp>
            </Flex></>
            : <>
              {
                EditEtapa === undefined
                  ? <CreateEtapaBox
                    keyWord='integração'
                    onGreenAction={AdicionarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                  />
                  : <CreateEtapaBox
                    keyWord='integração'
                    onGreenAction={AtualizarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                    ignoreId={etapaId}
                  />
              }
            </>
        }

      </Main>
    </Body>
  )
}

import { Flex, FormControl, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { iItemIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Title, Main } from './styles'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { FaEye, FaPlus } from 'react-icons/fa'
import { ValidarEtapa } from '../../../../Utils/Helper'
import { CardImersao } from '../../../Imersao/Editar/Components/CardImersao'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { InputCmp } from '../../../../components/InputCmp'
import LoadingContainer from '../../../../components/LoadingContainer'
import { WhiteContainer } from '../../../../components/WhiteContainer'

type LocationProps = {
  trilhaId: string
  imersaoId: string
  etapaId?: string
  processoId?: string
  nomeImersao?: string
}

export const ModifyImersao: React.FC = () => {
  const { pathname } = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const { nomeImersao } = useLocation().state as LocationProps || {}
  const [NomeImersao, setNomeImersao] = useState(nomeImersao ?? '')
  const { trilhaId, imersaoId, etapaId, processoId } = useParams<LocationProps>()
  const [Imersoes, setImersoes] = useState<iItemIntegracao[]>([])
  const [IsLoading, setIsLoading] = useState(false)

  const [Selecionar, setSelecionar] = useState(false)

  const [EditEtapa, setEditEtapa] = useState<iEtapa>()

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ImersaoEtapa/${trilhaId}` : processoId ? `ImersaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?imersaoId=${imersaoId ?? ''}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-2)
    }).catch(err => {
      console.error(err)
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function GetImersoesById(): void {
    setIsLoading(true)
    appApi.get(`Imersao/${imersaoId ?? ''}`)
      .then(response => { setImersoes(response.data.itensIntegracao); setNomeImersao(response.data.nome) })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  function CreateItemImersao(Tipo: number): void {
    appApi.post(`Imersao/${imersaoId ?? ''}/Item`, {
      titulo: '',
      descricao: '',
      tipo: Tipo
    })
      .then(() => { GetImersoesById() }).catch(err => console.error(err))
  }

  function DeltarItemIntegracao(Id: string): void {
    appApi.delete(`Imersao/${imersaoId ?? ''}/Item/${Id}`)
      .then(response => {
        if (response.status === 200) {
          const imersoes = [...Imersoes.filter(e => e.id !== Id)]
          setImersoes([])
          setImersoes(imersoes)
        }
      }).catch(err => console.error(err))
  }

  function AtualizarItemIntegracao(item: iItemIntegracao, tipo?: number, tipoDescricao?: number): void {
    if (tipoDescricao) setIsLoading(true)
    const idItem: string = item.id
    const changeLayout = tipoDescricao === 1 && item.tipoDescricao === 0 && item.layout === 3
    const form: Omit<iItemIntegracao, 'id'> = {
      titulo: item.titulo,
      descricao: item.descricao,
      tipo: tipo ?? item.tipo,
      checklist: tipoDescricao === 0 ? undefined : item.checklist,
      envioEmail: item.envioEmail,
      tipoDescricao: tipoDescricao ?? item.tipoDescricao,
      layout: changeLayout ? 0 : item.layout
    }
    appApi.put(`Integracao/${imersaoId ?? ''}/Item/${idItem}`, form).catch(e => console.log(e)).finally(() => setIsLoading(false))

    setImersoes((integracoesAnteriores) => {
      return integracoesAnteriores.map((e) => {
        if (e.id === idItem) {
          return {
            ...form,
            arquivo: item.arquivo,
            id: idItem
          }
        }
        return e
      })
    })
  }

  function ObterEtapaEdit(): void {
    const url = trilhaId ? `Etapa/${etapaId ?? ''}` : processoId ? `EtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        const data: iEtapa = res.data
        setEditEtapa(data)
        if (data.nome) {
          setNomeImersao(data?.nome ?? '')
        }
      }).catch(err => console.log(err))
  }

  function AtualizarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    form.nome = NomeImersao
    const url = trilhaId
      ? `ImersaoEtapa/${etapaId as string}`
      : processoId
        ? `ImersaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}`
        : ''
    appApi.put(`${url}/`, form).then(() => {
      toast({
        title: 'Etapa atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      if (trilhaId) {
        navigate(`/Jornada/Configuracao/Trilha/${trilhaId}`)
      } else {
        navigate(`/Rh/VisualizarPerfil/${processoId as string}`)
      }
    }).catch(() => {
      toast({
        title: 'Não foi possível atualizar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function EditarImersao(Texto: string): void {
    const dados = {
      url: ''
    }
    dados.url = !pathname.includes('Editar') ? `Integracao/${imersaoId ?? ''}` : processoId ? `IntegracaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    if (pathname.includes('Trilha')) {
      dados.url = `IntegracaoEtapa/${etapaId ?? ''}`
    }
    const form = {
      ...EditEtapa,
      nome: Texto
    }
    appApi.put(dados.url, form).then(response => {
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    if (imersaoId !== undefined) {
      GetImersoesById()
    }
    if (etapaId !== undefined) {
      ObterEtapaEdit()
    }
  }, [imersaoId])

  return (
    <Body>
      <Main>
        {
          !Selecionar
            ? <>
            <Title>
              <h1>Imersão cultural</h1>
              <span>Aqui você pode adicionar vídeos, imagens, documentos ou até um check list para acompanhar o que deve ou foi realizado na integração do colaborador</span>
            </Title>
            <Main>
              {
                !Selecionar
                  ? <><WhiteContainer>
                    <FormControl marginBottom={'1rem'}>
                      <InputCmp
                        label='Nome da Imersão'
                        value={NomeImersao}
                        OnChange={setNomeImersao}
                        placeholder='Digite o nome'
                        onBlur={(e) => {
                          EditarImersao(e.target.value)
                          setNomeImersao(e.target.value)
                        }}
                      />
                    </FormControl>
                    <LoadingContainer loading={IsLoading} linhas={22}>
                      <Flex display={'grid'} gap={'1rem 0rem'}>
                        {
                          Imersoes.map((e, i) => {
                            return (
                                  <CardImersao
                                    key={e.id}
                                    position={i + 1}
                                    id={imersaoId ?? ''}
                                    ItemIntegracao={e}
                                    qtdItens={Imersoes.length}
                                    onDelete={DeltarItemIntegracao}
                                    onUpdateInput={AtualizarItemIntegracao}
                                    onChangeTipo={AtualizarItemIntegracao}
                                    hideCheckbox={false}
                                    onReordenar={() => {
                                      if (imersaoId !== undefined) {
                                        GetImersoesById()
                                      }
                                      if (etapaId !== undefined) {
                                        ObterEtapaEdit()
                                      }
                                    }}
                                    onChangeTipoDescricao={(e, tipo) => { AtualizarItemIntegracao(e, undefined, tipo) }}
                                  />
                            )
                          })
                        }
                      </Flex>
                      <Flex justifyContent={'center'} m={'.75rem 0 0.25rem 0'}>
                        <ButtonCmp VarColor='Green2' onClick={() => { CreateItemImersao(1) }} leftIcon={<FaPlus/>}>Crie um novo item para essa imersão</ButtonCmp>
                      </Flex>
                        </LoadingContainer>
                      </WhiteContainer>
                      <Flex justifyContent={'end'} marginTop={'1rem'} gap={'.5rem'}>
                        <ButtonCmp
                          VarColor='c6'
                          onClick={() => navigate(-1)}
                        >Voltar</ButtonCmp>
                        <ButtonCmp
                          VarColor='Rosa'
                          leftIcon={<FaEye />}
                          fontWeight={'500'}
                          onClick={() => navigate(pathname + '/Previsualizar')}
                        >
                          Pré-visualização completa da integração
                        </ButtonCmp>
                        <ButtonCmp
                          VarColor='Green2'
                          onClick={() => setSelecionar(true)}>
                            Continuar
                        </ButtonCmp>
                      </Flex>
                    </>
                  : <>
                    {
                      EditEtapa === undefined
                        ? <CreateEtapaBox
                          keyWord='integração'
                          onGreenAction={AdicionarEtapa}
                          onGreyAction={() => setSelecionar(false)}
                          EditEtapa={EditEtapa}
                        />
                        : <CreateEtapaBox
                          keyWord='integração'
                          onGreenAction={AtualizarEtapa}
                          onGreyAction={() => setSelecionar(false)}
                          EditEtapa={EditEtapa}
                          ignoreId={etapaId}
                        />
                    }
                  </>
              }
            </Main>
          </>
            : <>
              {
                EditEtapa === undefined
                  ? <CreateEtapaBox
                    keyWord='imersão'
                    onGreenAction={AdicionarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                  />
                  : <CreateEtapaBox
                    keyWord='imersão'
                    onGreenAction={AtualizarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                    ignoreId={etapaId}
                  />
              }
            </>
        }
      </Main>
    </Body>
  )
}

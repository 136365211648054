import { Flex } from '@chakra-ui/react'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iModalProps {
  isOpen: boolean
  texto: string
  onClose: () => void
}
export const ModalReuniao: React.FC<iModalProps> = ({ isOpen, texto, onClose }) => {
  return (
        <ModalBase Titulo='Feedback' isOpen={isOpen} onClose={onClose} Width='35rem'>
            <Flex padding={'1rem'} flexDir={'column'}>
                <span dangerouslySetInnerHTML={{ __html: texto }}></span>
            </Flex>
        </ModalBase>
  )
}

import { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'// ContentState,
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { Box } from '@chakra-ui/react'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles.css'

interface props {
  onChange?: Function
  onBlur?: Function
  initialValue: string
  isLoading?: boolean
  className?: string
  placeholder?: string
}

export const EditorTexto2: React.FC<props> = ({ className, placeholder, onChange, onBlur, initialValue }: props) => {
  const [Init, setInit] = useState(false)
  const [editorState, setEditorState] = useState<EditorState>()

  const [Focus, setFocus] = useState(false)

  const mudarValor = (valor: String): void => {
    if (onChange) {
      onChange(valor)
    }
  }

  const handleBlur = (): void => {
    setFocus(false)
    if (onBlur) {
      if (editorState) { onBlur(draftToHtml(convertToRaw(editorState.getCurrentContent()))) }
    }
  }

  const handleFocus = (): void => {
    setFocus(true)
  }

  function UpdateState(val: string): void {
    const contentBlock = htmlToDraft(val)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)

    setEditorState(editorState)
  }

  useEffect(() => {
    if (!Init && initialValue) {
      setInit(true)
      UpdateState(initialValue)
    } else if (!Init) {
      UpdateState('')
    }
  }, [initialValue])

  return (
    <Box bgColor={'white'}>
      {editorState &&
        <Editor
          editorState={editorState}
          placeholder={placeholder}
          wrapperClassName="card"
          toolbarClassName='toolbar-class'
          editorClassName={className ? `${className} ${Focus ? className + '-focus' : ''}` : 'editorSt'}
          onFocus={handleFocus}
          onEditorStateChange={newState => {
            setEditorState(newState)
            mudarValor(draftToHtml(convertToRaw(newState.getCurrentContent())))
          }}
          onBlur={handleBlur}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'emoji', 'link'],
            link: {
              component: undefined,
              popupClassName: 'dropStyle',
              defaultTargetOption: '_self',
              options: ['link'],
              linkCallback: undefined
            },
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            history: { inDropdown: true }
          }}
        />
      }

    </Box>
  )
}

import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iCardEtapa, iItemIntegracao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Flex, Text } from '@chakra-ui/react'
import { FaCheck, FaRegCommentDots } from 'react-icons/fa'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
interface iImersao {
  etapa: iCardEtapa
  isOpen: boolean
}

export const CardImersao: React.FC<iImersao> = ({ etapa, isOpen }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterImersao(): void {
    appApi.get(`Imersao/${etapa.interacoes[0].idExterno}?etapaId=${etapa.id}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterImersao()
    }
  }, [isOpen])

  return (
    <Container>
      <h4>Lista de itens adicionados</h4>
      {
        Itens.map((e, i) => {
          const texto = e?.titulo ? `${e?.titulo}` : 'Sem assunto'
          return (
            <Flex key={i} flexDir={'column'}>
              <span style={{ fontWeight: 700 }}>{i + 1}.  {texto}</span>
              {<Flex direction={'column'} as={'ul'} mb={'2'}>
                {e.checklist?.map((r, i2) => {
                  return (
                    <Flex key={i2} ml={4} gap={'1'} alignItems='center' fontSize={'14px'}>
                      <Flex alignItems={'center'} w={'.875rem'} h={'.875rem'}><FaCheck color='var(--Green2)' size='.875rem' /></Flex>
                      <Text>{r.texto}</Text>
                      {r.comentario &&
                        <ElementoTooltipCmp
                          background={'var(--Azul)'}
                          label={`Comentário: ${r.comentario}`}
                        >
                          <FaRegCommentDots color='var(--Azul)' />
                        </ElementoTooltipCmp>
                      }
                    </Flex>
                  )
                })}
              </Flex>}
            </Flex>
          )
        })
      }
    </Container>
  )
}

import { Flex, Grid, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPen } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { DashboardSwitch } from '../../../../components/DashboardSwitch'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { Body } from '../../../Layouts/Body'
import { AcaoCard } from './Components/Acao'
import { ModalFinalizarAcao } from './Components/ModalFinalizarAcao'
import { ModalVisualizarComentarios } from './Components/ModalVisualizarComentarios'
import { Tabela } from './Components/Tabela'
import { OutlineContainer } from './styles'

export interface iDadoTabelaDashboardPDI {
  id: string
  origem: string
  totalAcoes: number
  totalAcoesRealizadas: number
  dataInicio: string
  dataFim: string
  ultimaAvaliacao?: number
  status: number
  temAcaoAtrasada: boolean
}

export interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
}

export interface iPDI {
  id: string
  origem: string
  acoes: iAcao[]
}

interface iModel {
  pdIs: iPDI[]
  tabela: iDadoTabelaDashboardPDI[]
  createPDI: boolean
}

interface iModalFializar {
  pdiId: string
  acao: iAcao
}

export const DashboardIndividualPDI: React.FC = () => {
  const { adm, role } = parseJwt()
  const [ModalVisualizarIsOpen, setModalVisualizarIsOpen] = useState(false)
  const [ModalFinalizarAcaoIsOpen, setModalFinalizarAcaoIsOpen] = useState(false)
  const [Model, setModel] = useState<iModel>()

  const [IsLoading, setIsLoading] = useState(false)

  const [Acao, setAcao] = useState<iModalFializar>()
  const nav = useNavigate()

  function onOpenModalVisualizarComentario(id: string, acao: iAcao): void {
    setModalVisualizarIsOpen(true)
    setAcao({ ...Acao, acao: acao, pdiId: id })
  }

  function onCloseModalVisualizarComentario(): void {
    setAcao(undefined)
    setModalVisualizarIsOpen(false)
  }

  function onOpenModalFinalizarAcao(id: string, acao: iAcao): void {
    setAcao({ ...Acao, acao: acao, pdiId: id })
    setModalFinalizarAcaoIsOpen(true)
  }

  function onCloseModalFinalizarAcao(): void {
    setAcao(undefined)
    setModalFinalizarAcaoIsOpen(false)
  }

  function ObterPDIs(): void {
    setIsLoading(true)
    appApi.get('PDI/Dashboard/Individual')
      .then(res => {
        setModel(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function FinalizarAcao(pdiId: string, acaoId: string, comentario: string, comProgresso: boolean): void {
    appApi.post(`PDI/${pdiId}/Acao/${acaoId}/Finalizar?comProgresso=${comProgresso}`, { texto: comentario })
      .then(res => {
        ObterPDIs()
        onCloseModalFinalizarAcao()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterPDIs()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalVisualizarComentarios
        isOpen={ModalVisualizarIsOpen}
        onRequestClose={onCloseModalVisualizarComentario}
        acao={Acao?.acao}

      />

      <ModalFinalizarAcao
        isOpen={ModalFinalizarAcaoIsOpen}
        onRequestClose={onCloseModalFinalizarAcao}
        FinalizarAcao={FinalizarAcao}
        id={Acao?.pdiId as string}
        acao={Acao?.acao}
      />

      <WhiteContainer>
        <Flex justifyContent={'space-between'} marginBottom='1rem'>
          <h1>Desempenho {'>'} PDI</h1>
          {
            (Model?.createPDI && (Model?.pdIs?.length > 0 || Model?.tabela?.length > 0)) && (
              <ButtonCmp
                onClick={() => nav('/Desempenho/PDI/Criar')}
                VarColor='Green2'
              >Criar PDI</ButtonCmp>
            )
          }
        </Flex>
          {
            (adm === 'True' || role === '1' || role === '2') && (
              <Flex marginBottom='1rem'>
                <DashboardSwitch/>
              </Flex>
            )
          }

        {
          (!IsLoading && (Model === undefined || (Model?.pdIs.length === 0 && Model?.tabela.length === 0))) && (
            <Flex flexDir={'column'} gap='1rem'>
              <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                <h3>Ainda não há PDI atribuído a você!<br />
                  {Model?.createPDI && <>O que acha de criar o seu Plano de Desenvolvimento, agora?</>}
                </h3>
              </Flex>
              {Model?.createPDI && <Flex justifyContent={'center'}>
                <ButtonCmp
                  width={'fit-content'}
                  onClick={() => nav('/Desempenho/PDI/Criar')}
                  VarColor='Green2'
                >Criar PDI</ButtonCmp>
              </Flex>}
            </Flex>
          )
        }

        {
          (Model && Model?.tabela.length > 0) && (
            <Tabela dados={Model?.tabela} slice={5} />
          )
        }

        {
          (Model && Model?.pdIs.length > 0) && (
            <Flex flexDir={'column'} marginTop='1rem' gap={'.75rem'}>
              <h3>Ações do PDI</h3>
              {
                Model?.pdIs.map((e, i) => {
                  return (
                    <OutlineContainer key={i} theme={'c3'}>
                      <Flex marginBottom={'.75rem'} justifyContent={'space-between'} alignItems={'center'}>
                        <Text fontWeight={'700'} color='var(--Gray1)' fontSize={'1.125rem'}>{e.origem}</Text>
                        <ButtonCmp VarColor='Green2' leftIcon={<FaPen />} onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)}>PDI completo</ButtonCmp>
                      </Flex>
                      <Grid gap={'.75rem'} templateColumns='repeat(3, 1fr)'>
                        {
                          e.acoes.map((a, i2) => {
                            return (
                              <AcaoCard
                                key={i2}
                                onOpenComentario={onOpenModalVisualizarComentario}
                                onOpenFinalizar={onOpenModalFinalizarAcao}
                                acao={a}
                                pdiId={e.id}
                                onSendProgresso={() => ObterPDIs()}
                              />
                            )
                          })
                        }
                      </Grid>
                    </OutlineContainer>
                  )
                })
              }
            </Flex>
          )
        }
      </WhiteContainer>
    </Body>
  )
}

import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { LayoutFullAnswer } from '../../Layouts/LayoutFullAnswer'
import { PDFImersao } from '../Components/Pdf'
import { TextoImersao } from '../Components/Texto'
import { VideoImersao } from '../Components/Video'
import { NavElipse } from './styles'

type ParamProps = {
  conviteId: string
}

interface iRespostaChecklist {
  itemId: string
  respostas: iChekIntegracao[]
}

interface iChekIntegracao {
  texto: string
  check: Boolean
  observacaoIson: Boolean
  observacao?: string
}

export const VisualizarImersao: React.FC = () => {
  const { conviteId } = useParams<ParamProps>()

  const [Switch, setSwitch] = useState(0)
  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])
  const [isRespondido, setisRespondido] = useState(false)
  const [Respostas, setRespostas] = useState<iRespostaChecklist[]>([])

  function GetImersoesById(): void {
    appApi.get(`ImersaoExternaConvite/${conviteId as string}/Visualizar`)
      .then(response => {
        if (response.data !== 'Imersão desabilitada') {
          setImersao(response.data)
        }
      }).catch(err => {
        setisRespondido(true)
        console.error(err)
      })
  }

  function PostVisualizar(): void {
    setisRespondido(true)
    appApi.post(`ImersaoExternaConvite/${conviteId as string}/Visualizar`)
      .then(res => console.log(res))
      .catch(err => console.log(err))
  }

  function UpdateRespostaChecklist(resposta: iChekIntegracao[], itemId: string): void {
    const copy = [...Respostas]
    const pos = copy.findIndex(e => e.itemId === itemId)
    if (pos !== -1) {
      copy[pos].respostas = resposta
      setRespostas(copy)
    } else {
      setRespostas(oldArray => [...oldArray,
        {
          itemId: itemId,
          respostas: resposta
        }])
    }
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <LayoutFullAnswer isRespondido={isRespondido} titulo='Imersão'>
      {(Imersao.length > 0) &&
      <>
        {
          Imersao.map((e: iItemIntegracao, i) => {
            if (e.tipo === 1) {
              return (
                <VideoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={UpdateRespostaChecklist}
                />
              )
            } else if (e.tipo === 2) {
              return (
                <TextoImersao
                  key={i}
                  item={e}
                  isVisible={i === Switch}
                  UpdateResposta={UpdateRespostaChecklist}
                />
              )
            } else if (e.tipo === 3) {
              return (
                <PDFImersao
                  key={i}
                  isVisible={i === Switch}
                  item={e}
                  UpdateResposta={UpdateRespostaChecklist}
                />
              )
            }
            return null
          })
        }

        <NavElipse>
          {
            Imersao?.map((e: iItemIntegracao, i) => {
              return (
                <div
                  key={i}
                  title={e.titulo}
                  onClick={() => setSwitch(i)}
                  style={{ background: Switch === i ? 'var(--DegradeAzul)' : '' }}>
                </div>
              )
            })
          }
        </NavElipse>

        <Flex justifyContent={'center'} gap={'.5rem'}>
          {Switch !== 0 && <ButtonCmp
            onClick={() => setSwitch(Switch - 1)}
            VarColor='c6'
          >Voltar</ButtonCmp>
          }

          {
            Switch === Imersao.length - 1
              ? <ButtonCmp
                onClick={PostVisualizar}
                VarColor='Green2'
              >Finalizar imersão</ButtonCmp>
              : <ButtonCmp
                  onClick={() => setSwitch(Switch + 1)}
                  VarColor='Green2'
                >Próximo</ButtonCmp>
          }
        </Flex>
        </>
      }
      {!(Imersao.length > 0) &&
      <Flex h={'350px'} w={'full'} alignItems={'center'} justifyContent={'center'} fontWeight={'700'}>
        Essa imersão está desabilitada, reative-a para que possa responder o formulário.
      </Flex>}
    </LayoutFullAnswer>
  )
}

import styled from 'styled-components'
import { Flex } from '../../../../../../styles/styledGlobal'

export const ModalBody = styled.div`
width: 40rem;
display: flex;
flex-direction: column;
gap: .5rem;
margin-top: .5rem;
`

export const ModalContent = styled.div`
padding: 1rem 1.25rem;
`

export const ModalFooter = styled(Flex)`
margin-top: .75rem;
justify-content: end;
gap: .5rem;

a:first-child{
  margin-right: 1rem;
}
`

import { Flex } from '@chakra-ui/react'
import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { HiUserGroup } from 'react-icons/hi2'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { IJobTitle } from '../../../../../../interfaces'

import { Container } from './styles'

interface IJobTitleCardProps {
  jobTitle: IJobTitle
  onEdit: (jobTitle: IJobTitle) => void
  onDelete: (jobTitle: IJobTitle) => void
}

export const JobTitleCard: React.FC<IJobTitleCardProps> = ({
  jobTitle,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <Flex justifyContent={'space-between'} mt={'.25rem'}>
        <Flex flexDir={'column'} gap={'.25rem'}>
          <strong>{jobTitle.nome}</strong>
        </Flex>
        <Flex alignItems={'center'} gap={'.5rem'} w={'10rem'} justifyContent={'end'}>
          <ButtonCmp small OutlineColor='Rosa' onClick={() => onEdit(jobTitle)}>
            <FiEdit />
          </ButtonCmp>

          <ButtonCmp small OutlineColor='Red' onClick={() => onDelete(jobTitle)}>
            <FaTrashAlt />
          </ButtonCmp>
        </Flex>
      </Flex>
      {jobTitle.colaboradores !== undefined &&
          <Flex gap={'.325rem'} bg={'var(--Azul)'} color={'var(--c1)'} pos={'absolute'} top={'0'} left={'50%'} transform={'translate(-50%, -0%)'} p={'.325rem 1.5rem'} alignItems={'center'} borderRadius={'0 0 .5rem .5rem'}>
            <HiUserGroup size={14}/>
            <strong style={{ fontSize: '.813rem', fontWeight: 900 }}>{jobTitle.colaboradores}</strong>
          </Flex>
        }
    </Container>
  )
}

import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Container } from './components/Container'
import { ModalEditar } from './components/ModalEditar'
import { useNavigate, useParams } from 'react-router-dom'
import { FaClipboard } from 'react-icons/fa'
import { PropInvalida, UpdateElementByPosition } from '../../../../../Utils/Helper'
import { parseJwt } from '../../../../../services/token'
import { appApi } from '../../../../../services/appApi'
import { ModalPDI } from '../ModalPDI'
import { BrainIA } from '../../../../../assets/BrainIA'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iResultadoIndividualResponse } from '../..'

export interface iAcao {
  acao: string
  icone: string
  oqueFazer: string
  comoFazer: string
  porqueFazer: string
}

interface iProps {
  model: iResultadoIndividualResponse
  onGerarRecomendacoes: () => void
}

export const TabRecomendacao: React.FC<iProps> = ({ model, onGerarRecomendacoes }) => {
  const pdiCadastrado = !PropInvalida(model.pdiAvaliacao)
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const { cid } = parseJwt()
  const nav = useNavigate()
  const toast = useToast()
  const [Arr, setArr] = useState<iAcao[]>([])

  const [ModalIsOpen, setModalIsOpen] = useState(false)
  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)

  const [Acao, setAcao] = useState<iAcao>()

  function onCloseModal(): void {
    setModalIsOpen(false)
    setAcao(undefined)
  }

  function onOpenModal(acao: iAcao): void {
    setModalIsOpen(true)
    setAcao(acao)
  }

  function Salvar(acao: iAcao): void {
    const pos = Arr.findIndex(e => e.acao === acao.acao)
    if (pos === -1) {
      return
    }
    appApi.post(`AvaliacaoPesquisa/${avaliacaoId}/Acao/${pos}`, acao)
      .then(() => {
        toast({
          title: 'Ação alterada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setArr(UpdateElementByPosition([...Arr], pos, acao))
        onCloseModal()
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao alterar a ação, tente novamente!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function Refresh(nome: string): void {
    const posRecomendacao = Arr.findIndex(e => e.acao === nome)
    if (posRecomendacao === -1) {
      return
    }
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId}/Acao?posicao=${posRecomendacao + 1}`)
      .then((e) => {
        if (e.status === 200) {
          const data: iAcao = e.data
          setAcao({
            ...Acao,
            acao: Acao?.acao ?? '',
            comoFazer: data.comoFazer,
            oqueFazer: data.oqueFazer,
            porqueFazer: data.porqueFazer,
            icone: data.icone
          })
          toast({
            title: 'Ação refeita com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        }
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao recarregar a ação, tente novamente!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function ObterTextoRecomendacoes(): any {
    if (model.recomendacoes.length === 1) {
      return <strong>“{model.recomendacoes[0].nome}”, </strong>
    } else {
      return (
        <span>
          {model.recomendacoes.map((e, i) => {
            if (model.recomendacoes.length - 2 === i) {
              return (
                <strong key={i}>“{e.nome}” <span style={{ fontWeight: 'normal' }}>e</span> </strong>
              )
            } else {
              return (
                <strong key={i}>“{e.nome}”, </strong>
              )
            }
          })}
        </span>
      )
    }
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${cid === model.avaliado.id ? model.avaliado.gestorId ?? cid : cid}&colaboradorId=${model.avaliado.id}&avaliacaoId=${avaliacaoId ?? ''}&ia=true`)
    } else {
      appApi.post(`PDI/${model.pdiNormal}/IA/${avaliacaoId}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${model.pdiNormal ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    if (model.acoes) {
      setArr(model.acoes)
    }
  }, [model.acoes])

  return (
    <Flex flexDir={'column'} gap='.75rem'>
      <ModalPDI
        recomendacoes={model.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      {ModalIsOpen &&
        <ModalEditar
          model={Acao}
          onClose={onCloseModal}
          onSave={Salvar}
          onRefresh={Refresh}
        />
      }
      <Flex alignItems='center' justifyContent={'space-between'}>
        <Flex gap={'.5rem'} alignItems='center'>
          <BrainIA size={26} />
          <Text
            fontSize={'1.25rem'}
            bgClip='text'
            bgGradient={'var(--DegradeAzul)'}
            fontWeight={'700'}
          >Estas recomendações são geradas por inteligência artificial</Text>
        </Flex>

        <Flex gap={'.5rem'}>
          <ButtonCmp onClick={onGerarRecomendacoes} VarColor='Rosa'>Gerar novas recomendações por IA</ButtonCmp>
          {pdiCadastrado && (
            <ButtonCmp
              onClick={() => nav(`/Desempenho/PDI/Editar/${model.pdiAvaliacao ?? ''}`)}
              leftIcon={<FaClipboard />}
              VarColor='Green2'
            >Editar PDI</ButtonCmp>
          )}

          {!pdiCadastrado && <ButtonCmp
            VarColor='Green2'
            onClick={() => {
              !PropInvalida(model.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
            }}
          >Gerar PDI através das recomendações</ButtonCmp>}
        </Flex>
      </Flex>

      <Text fontSize='.95rem'>Considerando as competências de {ObterTextoRecomendacoes()} nós geramos uma recomendações para seu plano de desenvolvimento pessoal.</Text>
      <Flex flexDir={'column'} gap={'1rem'}>
        {Arr.map((e, i) => {
          return (
            <Container
              key={i}
              model={e}
              index={i + 1}
              onEdit={onOpenModal}
            />
          )
        })}
      </Flex>

      <Flex gap={'.75rem'} flexDir={'column'}>
        <Flex textAlign={'center'} gap={'.5rem'} flexDir={'column'}>
          <Text
            fontSize={'.95rem'}
            fontWeight={'700'}
            color={'var(--Roxo)'}
          >Estamos confiantes de que estas ações irão impulsionar o seu crescimento e contribuição ainda mais.
            Juntos, estamos construindo um futuro de sucesso.
          </Text>
          {!pdiCadastrado &&
            <Text
              fontSize={'.875rem'}
              color={'var(--Roxo)'}
              fontWeight={'500'}
            >Clique no botão abaixo e inicie o caminho rumo ao seu desenvolvimento!</Text>
          }
        </Flex>
        <Flex justifyContent={'center'}>
          {!pdiCadastrado &&
            <ButtonCmp
              VarColor='Green2'
              w='fit-content'
              fontWeight={'500'}
              onClick={() => {
                !PropInvalida(model.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
              }}
              isDisabled={Arr.length === 0}
            >Gerar PDI através das recomendações</ButtonCmp>
          }

          {pdiCadastrado && (
            <ButtonCmp
              VarColor='Green2'
              fontWeight={'500'}
              onClick={() => nav(`/Desempenho/PDI/Editar/${model.pdiAvaliacao ?? ''}`)}
              leftIcon={<FaClipboard />}
            >Editar PDI</ButtonCmp>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

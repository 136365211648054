import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: .75rem;
background: var(--${props => props.theme});
color: var(--c1);
border-radius: .25rem;
padding: 1rem;
max-width: 10rem;
font-size: .875rem;
font-weight: 500;
line-height: 1.05rem;
span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
}
`

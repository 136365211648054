import styled, { css } from 'styled-components'

export const Container = styled.div`
background: white;
border-radius: 0.25rem;
display: flex;
width: 100%;
box-shadow: var(--SombraBtns);
border: 2px solid var(--AzulOpaco);
`

export const CompetenciaMenu = styled.div`
width: 20%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-bottom: .5rem;
border-right: 2px solid var(--Azul);
border-right-style: dashed;
`

export const CompetenciaContent = styled.div`
display: flex;
flex-direction: column;
padding: .75rem;
width: 100%;
`

export const CompetenciaLine = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 0.25rem;
border: 2px solid white;
cursor: pointer;

svg {    
    min-width: .85rem;
    min-height: .85rem;
}

span{
    font-weight: 700;
    font-size: .875rem;
}
`
export const StyledQuestion = styled.div`

    display: flex;
    align-items: center;
    padding: .5rem .5rem 0 .5rem;
    width: 100%;

    svg{
        margin-right: .5rem;
        min-width: 1.25rem;
        min-heigth: 1.25rem;
        max-width: 1.25rem;
        max-heigth: 1.25rem;
    } 
    
    span{
    font-weight: 700;
    font-size: .875rem;
}
`

export const CollapseText = styled.div`
padding: .5rem 0 .5rem .75rem;
display: flex;
align-items: center;
cursor: pointer;
color: var(--Rosa);
font-size: .95rem;
svg{
    margin-right: .25rem;
}

&:Hover{
    font-weight: 500;
}
`

export const CollapseLine = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 0.25rem;
border: 2px solid white;
cursor: pointer;

&:first-child{
    display: flex;
    align-items: center;
    padding: .5rem;
    width: 100%;

    &:hover{
        background: var(--c2);
        transition: all .4s ease;
    }
}

span{
font-family: 'Roboto';
font-weight: 700;
font-size: .875rem;
}

`

export const ColumnIcons = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: .5rem;
gap: .325rem;
font-size: .813rem;
font-weight: 500;

span{
    cursor: pointer;
    &:hover{
        font-weight: bold;
    }
}
`

export const SectionQuestoes = styled.section<{active: boolean}>`
overflow-y: hidden;
max-height: 0rem;


${({ active }) =>
    active &&
    css`
    transition: max-height 5s;
    max-height: 600rem;
`}
`

export const ImgText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .5rem;
    span{
        font-weight: 500;
        font-size: 0.875rem;
        text-align: center;
        width: 8rem;
    }
    img{
        max-height: 3.50rem;
        max-width: 3.50rem;
        border-radius: 50%;
        margin-bottom: .25rem;
        border: 2px solid var(--Azul);
    }
`

import { Box, Checkbox, CheckboxGroup, Flex, FormLabel, Input, Select, Stack } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'

export interface IContrato {
  opp: string
  inicio?: Date
  fim?: Date
  fee: number
  jornada: boolean
  desempenho: boolean
  engajamento: boolean
  pesquisa360: boolean
  plano?: boolean
  OnSave?: (dados: IContrato) => void

}

export const CriarContrato: React.FC<IContrato> = (data) => {
  const vref = useRef<HTMLInputElement>(null)

  const [contrato, setContrato] = useState<IContrato>(data)

  useEffect(() => {
    if (vref.current != null) {
      vref.current.scrollIntoView()
      vref.current.focus()
    }
    console.log(data)
    setContrato({
      desempenho: data.desempenho,
      engajamento: data.engajamento,
      fee: data.fee,
      jornada: data.jornada,
      opp: data.opp,
      fim: data.fim,
      inicio: data.inicio,
      plano: data.plano,
      pesquisa360: data.pesquisa360
    })
  }, [])
  useEffect(() => {
    if (data.OnSave) {
      data.OnSave(contrato)
    }
  }, [contrato])

  function converterData(data?: any): string {
    if (typeof data === 'string' || data instanceof String) { return data.split('T')[0] ?? '' }

    if (!data) return ''

    const str = data.toISOString()
    return str.split('T')[0] ?? ''
  }
  return (
    <Box my={'1rem'}>

      <Flex direction={'column'} borderRadius='2xl' border={'1px'} borderColor='var(--Azul)' p={'1rem'}>

        <FormLabel>OPP</FormLabel>
        <Input value={contrato.opp} onChange={(e) => setContrato({ ...contrato, opp: e.target.value })} ref={vref} placeholder="Digite a OPP " />
        <FormLabel mt={'1rem'}>Vigência do contrato</FormLabel>

        <Flex mt={'1rem'}>

          <FormLabel mr={'1rem'}>Início</FormLabel>
          <Input value={converterData(contrato.inicio)} onChange={e => setContrato({ ...contrato, inicio: new Date(e.target.value) })} type="date" placeholder="Selecione a data de início " />
          <FormLabel mx={'1rem'}>Fim</FormLabel>
          <Input value={converterData(contrato.fim)} onChange={e => setContrato({ ...contrato, fim: new Date(e.target.value) })} type="date" placeholder="Data de fim " />
        </Flex>

        <Flex my={'1rem'}>
          <FormLabel mr={'1rem'}>Plano</FormLabel>
          <Select defaultValue={!contrato.plano ? 'E' : 'P'} onChange={(e) => setContrato({ ...contrato, plano: e.target.value === 'P' })} placeholder='Selecione o plano'>
            <option value='E'>Explorer</option>
            <option value='P'>Pro</option>
          </Select>

          <FormLabel mx={'1rem'}>Fee</FormLabel>
          <Select defaultValue={contrato.fee.toString() ?? '900'} onChange={(e) => setContrato({ ...contrato, fee: parseInt(e.target.value) })} placeholder='Selecione o fee'>
            <option value='50'>1 a 50</option>
            <option value='100'>51 a 100</option>
            <option value='200'>101 a 200</option>
            <option value='300'>201 a 300</option>
            <option value='400'>301 a 400</option>
            <option value='500'>401 a 500</option>
            <option value='600'>501 a 600</option>
            <option value='700'>601 a 700</option>
            <option value='800'>701 a 800</option>
            <option value='900'>801 a 900</option>
            <option value='1000'>901 a 1000</option>
            <option value='true'>Personalizado</option>

          </Select>
        </Flex>
        <Flex direction={'column'}>
          <FormLabel mt={'1rem'}>Módulos contratados</FormLabel>
          <CheckboxGroup colorScheme='green' defaultValue={['naruto', 'kakashi']}>
            <Stack m={'1rem'} spacing={[1, 5]} direction={['column', 'row']}>
              <Checkbox
                isChecked={contrato.jornada}
                onChange={(e) => setContrato({ ...contrato, jornada: e.target.checked })}
              >Jornada </Checkbox>
              <Checkbox
                isChecked={contrato.desempenho}
                onChange={(e) => setContrato({ ...contrato, desempenho: e.target.checked })}
              >Desempenho </Checkbox>
              <Checkbox
                isChecked={contrato.engajamento}
                onChange={(e) => setContrato({ ...contrato, engajamento: e.target.checked })}
              >Engajamento </Checkbox>

              <Checkbox
                isChecked={contrato.pesquisa360}
                onChange={(e) => setContrato({ ...contrato, pesquisa360: e.target.checked })}
              >Pesquisa 360</Checkbox>
            </Stack>
          </CheckboxGroup>
        </Flex>
      </Flex>
    </Box>
  )
}

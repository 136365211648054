import styled from 'styled-components'

export const EixoUnicoNineBox = styled.div`
  border-radius: .25rem;
  border-left: 5px solid ${props => props.theme};
  background: var(--c2);
  box-shadow: var(--SombraBackground);
  width: 73rem;
  padding: 1rem 1rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
`

export const EixoNineBox = styled.div`
  width: 17rem;
  height: 12rem;
  border-radius: .25rem;
  gap: .25rem;
  display: flex;
  border-left: 5px solid ${props => props.theme};
  background: var(--c2);
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: var(--SombraBtns);
  input{
    width: 10rem;
  }
`

export const OutlineContainer = styled.div`
width: 100%;
border-radius: .25rem;
padding: 1rem;
background: white;
display: flex;
flex-direction: column;
border: 1px solid var(--${props => props.theme});
`

export const ContainerFiltro = styled.div`
display: flex;
align-items: center;
background-color: white;
border-radius: 8px;
width: 100%;
`

import { useState, useEffect } from 'react'
import { Checkbox, Flex, FormControl, FormLabel, Input, Text, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { NavLine } from '../../../components/NavLine'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { appApi } from '../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalDelete } from '../../../components/ModalDelete'
import { PropInvalida, useQuery } from '../../../Utils/Helper'
import { FaArrowRight } from 'react-icons/fa'
import { iCardAvaliador } from '../../../interfaces'
import { iFluxoEntidades } from './components/TimeLine/components/Card'
import { FormFeedbackDiscursivo, iFormFeedback } from './components/Forms/FeedbackDiscursivo'
import { Form90Quantitativa, iForm90 } from './components/Forms/90'
import { Form360, iForm360 } from './components/Forms/360'
import { FormReuniaoOne, iFormReuniaoOne } from './components/Forms/ReuniaoOne'
import { TimeLine } from './components/TimeLine'
import { CardParticipantes } from './components/CardParticipantes'

export interface iInputModel {
  nome: string
  remover90?: boolean
  p360?: iForm360
  p90?: iForm90
  feedbackDiscursivo?: iFormFeedback
  reuniaoOne?: iFormReuniaoOne
}

export const FluxoPersonalizado: React.FC = () => {
  const query = useQuery()
  const { id } = useParams<{ id: string }>()
  const nav = useNavigate()
  const toast = useToast()

  const [Selecionado, setSelecionado] = useState(-1)
  const [Criados, setCriados] = useState<iFluxoEntidades[]>([])
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalFinalizarIsOpen, setModalFinalizarIsOpen] = useState(false)
  const [Participantes, setParticipantes] = useState<iCardAvaliador[]>([])
  const [InputModel, setInputModel] = useState<iInputModel>({
    nome: ''
  })

  function handleAdvance(): void {
    if (query.get('step')) {
      nav(`/Jornada/FeedbackPersonalizado/${id}`)
    }
    switch (Selecionado) {
      case -1: Criar()
        break
      case 0: SelecionarInidicativa()
        break
      case 1: !Criados.includes('Feedback-discursivo') ? CriarFeedback() : AtualizarFeedback()
        break
      case 2: !Criados.includes('90-quantitativa') ? CriarNoventa() : AtualizarNoventa()
        break
      case 3: !Criados.includes('Reuniao-one') ? CriarReuniao() : AtualizarReuniao()
        break
    }
  }

  function ObterPorId(): void {
    appApi.get(`FluxoPersonalizado/${id}?preenchido=true`)
      .then(res => {
        const data: iInputModel = res.data
        data.p360 && setCriados(prevState => [...prevState, '360-indicativa'])
        data.p90 && setCriados(prevState => [...prevState, '90-quantitativa'])
        data.reuniaoOne && setCriados(prevState => [...prevState, 'Reuniao-one'])
        data.feedbackDiscursivo && setCriados(prevState => [...prevState, 'Feedback-discursivo'])

        if (PropInvalida(query.get('step'))) {
          if (PropInvalida(data.p360)) {
            setSelecionado(0)
          } else if (PropInvalida(data.feedbackDiscursivo)) {
            setSelecionado(1)
          } else if (PropInvalida(data.p90)) {
            setSelecionado(2)
          } else if (PropInvalida(data.reuniaoOne)) {
            setSelecionado(3)
          } else {
            setSelecionado(4)
          }
        }
        setInputModel({
          ...data,
          p90: {
            formularioId: query.get('step') === '2' && query.get('formularioId') ? (query.get('formularioId') ?? undefined) : data.p90?.formularioId
          },
          feedbackDiscursivo: data.feedbackDiscursivo
            ? {
                ...data.feedbackDiscursivo,
                formularioId: query.get('step') === '1' && query.get('formularioId') ? (query.get('formularioId') ?? undefined) : data.feedbackDiscursivo?.formularioId
              }
            : undefined
        })
      })
      .catch(err => console.log(err))
  }

  function Criar(): void {
    appApi.post('FluxoPersonalizado', {
      texto: InputModel.nome
    })
      .then(res => {
        toast({
          title: 'Feedback criado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        nav(res.data.id)
        setSelecionado((Selecionado + 1))
      })
      .catch(err => console.log(err))
  }

  function SelecionarInidicativa(): void {
    appApi.put(`FluxoPersonalizado/${id}/Indicativa`, InputModel.p360)
      .then(() => {
        toast({
          title: 'Pesquisa selecionada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + 1))
        InputModel.p360 && setCriados([...Criados, '360-indicativa'])
      })
      .catch(err => console.log(err))
  }

  function CriarFeedback(): void {
    appApi.post(`FluxoPersonalizado/${id}/Feedback`, InputModel.feedbackDiscursivo)
      .then(() => {
        toast({
          title: 'Feedback discursivo criado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + (1 + (InputModel.remover90 ? 1 : 0))))
        InputModel.feedbackDiscursivo && setCriados([...Criados, 'Feedback-discursivo'])
      })
      .catch(err => console.log(err))
  }

  function AtualizarFeedback(): void {
    appApi.put(`FluxoPersonalizado/${id}/Feedback`, InputModel.feedbackDiscursivo)
      .then(() => {
        toast({
          title: 'Feedback discursivo atualizado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + (1 + (InputModel.remover90 ? 1 : 0))))
      })
      .catch(err => console.log(err))
  }

  function CriarNoventa(): void {
    appApi.post(`FluxoPersonalizado/${id}/Noventa`, InputModel.p90)
      .then(() => {
        toast({
          title: 'Pesquisa 90° criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + 1))
        InputModel.p90 && setCriados([...Criados, '90-quantitativa'])
      })
      .catch(err => console.log(err))
  }

  function AtualizarNoventa(): void {
    appApi.put(`FluxoPersonalizado/${id}/Noventa`, InputModel.p90)
      .then(() => {
        toast({
          title: 'Pesquisa 90° atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + 1))
      })
      .catch(err => console.log(err))
  }

  function CriarReuniao(): void {
    appApi.post(`FluxoPersonalizado/${id}/Reuniao`, InputModel.reuniaoOne)
      .then(() => {
        toast({
          title: 'Reunião criada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + 1))
        InputModel.reuniaoOne && setCriados([...Criados, 'Reuniao-one'])
      })
      .catch(err => console.log(err))
  }

  function AtualizarReuniao(): void {
    appApi.put(`FluxoPersonalizado/${id}/Reuniao`, InputModel.reuniaoOne)
      .then(() => {
        toast({
          title: 'Reunião atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        setSelecionado((Selecionado + 1))
      })
      .catch(err => console.log(err))
  }

  function Excluir(): void {
    appApi.delete(`FluxoPersonalizado/${id}`)
      .then(res => {
        console.log(res.data)
        nav('/')
        toast({
          title: 'Feedback excluído com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
      })
      .catch(err => console.log(err))
  }

  function Iniciar(): void {
    appApi.get(`FluxoPersonalizado/${id}/Enviar`)
      .then(() => {
        toast({
          title: 'Feedback disparado com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: false
        })
        nav('/')
      })
      .catch(err => console.log(err))
  }

  function ObterParticpantes(): void {
    appApi.get(`FluxoPersonalizado/${id}/Participantes`)
      .then(res => setParticipantes(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (id) {
      ObterPorId()
    }
  }, [])

  useEffect(() => {
    if (Selecionado === 4 && Participantes.length === 0) {
      ObterParticpantes()
    }
  }, [Selecionado])

  useEffect(() => {
    if (query.get('step')) {
      setSelecionado(parseInt(query.get('step') ?? '1'))
    }
  }, [query])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir esse feedback?'
        onConfirm={Excluir}
        onRequestClose={() => setModalDeleteIsOpen(false)}
      />

      <ModalDelete
        isOpen={ModalFinalizarIsOpen}
        message='Tem certeza que deseja iniciar o feedback, após a confirmação você não poderá mais edita-lo!'
        onConfirm={Iniciar}
        onRequestClose={() => setModalFinalizarIsOpen(false)}
        center
      />

      <NavLine
        options={[
          { nome: 'Feedback' },
          { nome: 'Criando feedback' }
        ]}
      />

      <WhiteBox margin='1rem 0 0 0'>
        <Flex flexDir={'column'} gap='2rem'>
          {Selecionado === -1 &&
            <Flex flexDir={'column'} gap='1rem'>
              <h2>Criando o feedback</h2>
              <FormControl p={'2rem'}>
                <FormLabel fontSize={'large'}>Nome do feedback</FormLabel>
                <Input
                  onChange={(e) => setInputModel({ ...InputModel, nome: e.target.value })}
                  borderColor={'var(--Gray4)'}
                  placeholder='Digite o nome'
                />
                <Checkbox mt={'1rem'} onChange={(e) => setInputModel({ ...InputModel, remover90: e.target.checked })}>Realizar o feedback sem a pesquisa 90°</Checkbox>
              </FormControl>
              <Flex justifyContent={'end'}>
                <Button
                  fontWeight={'500'}
                  VarColor='Green2'
                  rightIcon={<FaArrowRight />}
                  onClick={handleAdvance}
                >Criar</Button>
              </Flex>
            </Flex>
          }

          {Selecionado > -1 && Selecionado < 4 &&
            <Flex flexDir={'column'} gap='2rem'>
              <TimeLine
                selecionado={Selecionado}
                model={InputModel}
              />
              {Selecionado === 0 &&
                <Form360
                  onChange={(model) => setInputModel({
                    ...InputModel,
                    p360: {
                      pesquisaId: model.pesquisaId,
                      reutilizarParticipantes: model.reutilizarParticipantes,
                      dataInicio: model.dataInicio,
                      dataFim: model.dataFim
                    }
                  })}
                  model={InputModel.p360}
                  onAvancar={handleAdvance}
                  onVoltar={() => setSelecionado(Selecionado - 1)}
                />
              }

              {Selecionado === 1 &&
                <FormFeedbackDiscursivo
                  onChange={(model) => setInputModel({
                    ...InputModel, feedbackDiscursivo: model
                  })}
                  model={InputModel.feedbackDiscursivo}
                  onAvancar={handleAdvance}
                  onVoltar={() => setSelecionado(Selecionado - 1)}
                />
              }

              {Selecionado === 2 &&
                <Form90Quantitativa
                  onChange={(model) => setInputModel({ ...InputModel, p90: model })}
                  onAvancar={handleAdvance}
                  onVoltar={() => setSelecionado(Selecionado - 1)}
                  model={InputModel.p90}
                />
              }

              {Selecionado === 3 &&
                <FormReuniaoOne
                  onChange={(model) => setInputModel({ ...InputModel, reuniaoOne: model })}
                  onAvancar={handleAdvance}
                  onVoltar={() => setSelecionado(Selecionado - 1)}
                  model={InputModel.reuniaoOne}
                />
              }
            </Flex>
          }

          {Selecionado === 4 &&
            <Flex flexDir={'column'} p='1rem' gap={'1rem'} justifyContent={'center'}>
              <Flex>
                <Text color={'var(--Gray1)'} fontSize='20px' fontWeight='bold'>
                  Você está prestes a disparar um fluxo de feedback para todos os colaboradores abaixo
                </Text>
              </Flex>
              <Flex gap={'1rem'} flexWrap={'wrap'} mt='.8rem'>
                {Participantes.map(res => {
                  return (
                    <CardParticipantes key={res.id} Avaliador={res}/>
                  )
                })}
              </Flex>
              <Flex gap={'1rem'} justifyContent={'center'}>
                <Button
                  VarColor='v2'
                  size={'lg'}
                  onClick={() => setModalFinalizarIsOpen(true)}
                >Finalizar criação</Button>

                <Button
                  VarColor='Green2'
                  size={'lg'}
                  onClick={() => setSelecionado(3)}
                >Voltar e editar</Button>

                <Button
                  VarColor='Red'
                  size={'lg'}
                  onClick={() => setModalDeleteIsOpen(true)}
                >Excluir</Button>
              </Flex>
            </Flex>
          }
        </Flex>
      </WhiteBox>
    </Body>
  )
}

import { Flex, FormControl, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { iCompetenciaQuestao } from '../..'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import LoadingContainer from '../../../../../components/LoadingContainer'
import { ModalBase } from '../../../../../components/ModalBase'
import { PropInvalida } from '../../../../../Utils/Helper'
import { Legenda } from '../../../../Jornada/ReuniaoOne/Configuracao/styles'
import { ModalBody } from './styles'

interface iProps {
  onClose: () => void
  icones: string[]
  competencia?: iCompetenciaQuestao
  onCreate: (avatar: string, nome: string, questao: string) => void
  onCreateQuestao: (questao: string) => void
  onUpdate: (avatar: string, nome: string, questao: string) => void
}

export const ModalCompetencia: React.FC<iProps> = ({ onClose, icones, competencia, onCreate, onUpdate, onCreateQuestao }) => {
  const toast = useToast()
  const isEdit = !PropInvalida(competencia)
  const [ImgSel, setImgSel] = useState(0)
  const [Nome, setNome] = useState<string>('')
  const [Questao, setQuestao] = useState<string>()
  const [IsLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setNome(competencia?.nome ?? '')
    setQuestao(competencia?.questao.enunciado ?? '')
    setImgSel(icones.findIndex(e => e === competencia?.icone.replace('/assets', '').replace('Icones-SISTEMA', 'Competencias').replace('.png', '.svg')))
  }, [competencia])

  function InternalExecution(): void {
    setIsLoading(true)
    if (isEdit) {
      if (!competencia?.sistema && (Questao !== competencia?.questao || Nome !== competencia?.nome)) {
        onUpdate(
          icones[ImgSel],
          Nome ?? '',
          Questao ?? ''
        )
      } else {
        onCreateQuestao(Questao ?? '')
      }
    } else {
      if (PropInvalida(icones[ImgSel])) {
        toast({
          title: 'Selecione um icone!',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
        return
      }

      onCreate(
        icones[ImgSel],
        Nome ?? '',
        Questao ?? ''
      )
      setIsLoading(false)
    }
  }

  return (
    <ModalBase isOpen onClose={onClose} Titulo={!isEdit ? 'Criar competência' : 'Editar competência'} Width='55rem'>
      <hr style={{ border: '1px solid var(--Gray5)' }} />
      <ModalBody>
        <LoadingContainer linhas={8} loading={IsLoading} my={'1rem'}>
          <h4 style={{ marginBottom: '.5rem', fontSize: '1rem', fontWeight: 600 }}>Selecione um ícone</h4>

          <section className={competencia?.sistema ? 'disabled' : ''}>
            {
              icones.map((e: string, i) => {
                if (i === ImgSel) {
                  return (
                    <img style={{ border: '3px solid var(--Rosa)' }} key={i} src={e} />
                  )
                } else {
                  return (
                    <img onClick={() => setImgSel(i)} key={i} src={e} />
                  )
                }
              })
            }
          </section>

          <FormControl my={'.75rem'}>
            <InputCmp
              label={'Nome da competência'}
              className={competencia?.sistema ? 'disabled' : ''}
              value={Nome}
              OnChange={(e) => setNome(e)}
              placeholder={'Digite o nome'}
            />
          </FormControl>

          <FormControl>
            <InputCmp
              label={'Questão da competência'}
              value={Questao}
              OnChange={(e) => setQuestao(e)}
              placeholder={'Digite a questão'}
            />
            <Legenda>Exemplo: Demonstra comprometimento e dedicação com os objetivos e os resultados da empresa</Legenda>
          </FormControl>

          <Flex gap={'.5rem'} justifyContent={'end'} mt={'.75rem'}>
            <ButtonCmp VarColor={'c6'} onClick={onClose}>Cancelar</ButtonCmp>
              <ButtonCmp
                VarColor='Green2'
                onClick={InternalExecution}
              >{isEdit ? 'Editar' : 'Criar'} competência</ButtonCmp>
          </Flex>

        </LoadingContainer>
      </ModalBody>
    </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { SwitchCmp } from '../../../../components/SwitchCmp'
import { VideoPlayer } from '../../../../components/VideoPlayer'
import { iItemChecklist, iItemIntegracao } from '../../../../interfaces'
import { FormatarMensagemEditorDeTexto } from '../../../../Utils/Helper'
import { ModalObservacao } from '../ModalObservacao'
import { Main } from '../Texto/styles'

interface iVideoProps {
  item: iItemIntegracao
  isVisible?: boolean
  UpdateResposta: (resposta: iChekIntegracao[], itemId: string) => void
  readonly?: boolean
  preview?: boolean
}

interface iChekIntegracao {
  texto: string
  check: boolean
  observacaoIson: boolean
  observacao?: string
  arquivo?: iFileData
}

interface iFileData {
  uri: string
  size: string
  name: string
}

export const VideoImersao: React.FC<iVideoProps> = ({ item, isVisible, UpdateResposta, readonly, preview }) => {
  if (!isVisible) {
    return null
  }

  const [ModalObservacaoIsOpen, setModalObservacaoIsOpen] = useState(false)
  const [CheckItemIndex, setCheckItemIndex] = useState<number>()
  const [ModoTeatro, setModoTeatro] = useState(false)
  const [ItensCheck, setItensCheck] = useState<iChekIntegracao[]>([])
  const [NoChangeItensCheck, setNoChangeItensCheck] = useState<iChekIntegracao[]>([])

  useEffect(() => {
    if (item.checklist && item.checklist.length > 0) {
      for (let i = 0; i < item.checklist.length; i++) {
        setItensCheck(oldArray => [...oldArray,
          {
            check: item.checklist ? item.checklist[i]?.check : false,
            observacaoIson: false,
            texto: item.checklist ? item.checklist[i]?.texto : '',
            arquivo: item.checklist ? item.checklist[i]?.arquivo : undefined,
            observacao: item.checklist ? item.checklist[i]?.comentario : undefined
          }])
        setNoChangeItensCheck(oldArray => [...oldArray,
          {
            check: item.checklist ? item.checklist[i]?.check : false,
            observacaoIson: false,
            texto: item.checklist ? item.checklist[i]?.texto : '',
            arquivo: item.checklist ? item.checklist[i]?.arquivo : undefined,
            observacao: item.checklist ? item.checklist[i]?.comentario : undefined
          }])
      }
    }
  }, [])

  useEffect(() => {
    UpdateResposta(ItensCheck, item.id)
  }, [ItensCheck])

  function UpdateItemArray(index: number, check: boolean, Observacao?: string, arquivo?: iFileData): void {
    const array = [...ItensCheck]
    array[index].check = check
    array[index].observacao = Observacao
    array[index].arquivo = arquivo
    setItensCheck(array)
    setModalObservacaoIsOpen(false)
  }

  return (
    <Flex flexDir={'column'} pos={'relative'}>
        <ModalObservacao
          isOpen={ModalObservacaoIsOpen}
          onClose={() => setModalObservacaoIsOpen(false)}
          onConfirm={(arquivo, observacao) => { if (CheckItemIndex !== undefined) { UpdateItemArray(CheckItemIndex, ItensCheck[CheckItemIndex].check, observacao, arquivo) } }}
          ItemId={item.id}
        />
        {item.layout !== 3 && item.layout !== 2 && <Flex justifyContent={'end'}>
          <SwitchCmp
            value={ModoTeatro}
            OnChange={setModoTeatro}
            legendaEsquerda={'Visualização padrão'}
            legendaDireita={'Visualização em tela cheia'}
          />
        </Flex>}
        <Main className={item.layout === 2 ? 'hide' : 'hideImersao'} style={{ flexDirection: item.layout === 2 ? 'column' : 'row' }}>
          {item.layout === 1
            ? <>
            <Flex left={0} flexDir={'column'} w={item.arquivo ? ModoTeatro ? '0%' : '40%' : '100%'} pos={'relative'} opacity={ModoTeatro ? '0' : '1'} minH={'32rem'} maxH={'32rem'}>
              <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
                <h1>{item.titulo}</h1>
              </Flex>
              <Flex flexDir={'column'} gap={'1rem'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
                <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
                {
                item.checklist?.map((r: iItemChecklist, i) => {
                  return (
                    <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                      <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                      {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                      {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                        {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                        {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                      </Flex>}
                    </Flex>
                  )
                })
              }
              </Flex>
            </Flex>
            {item.arquivo && <Flex flexDir={'column'} w={ModoTeatro ? '100%' : '60%'} justifyContent={'center'}>
              <VideoPlayer url={item.arquivo?.uri ?? ''} descricao={item.descricao} titulo={item.titulo}/>
            </Flex>}</>
            : item.layout === 0
              ? <>{item.arquivo && <Flex flexDir={'column'} w={ModoTeatro ? '100%' : '60%'} justifyContent={'center'}>
            <VideoPlayer url={item.arquivo?.uri ?? ''} descricao={item.descricao} titulo={item.titulo}/>
          </Flex>}
          <Flex right={0} flexDir={'column'} w={item.arquivo ? ModoTeatro ? '0%' : '40%' : '100%'} pos={'relative'} opacity={ModoTeatro ? '0' : '1'} minH={'32rem'} maxH={'32rem'}>
            <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
              <h1>{item.titulo}</h1>
            </Flex>
            <Flex flexDir={'column'} gap={'1rem'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
              <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
              {
                item.checklist?.map((r: iItemChecklist, i) => {
                  return (
                    <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                      <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                      {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                      {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                        {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                        {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                      </Flex>}
                    </Flex>
                  )
                })
              }
            </Flex>
          </Flex></>
              : item.layout === 3
                ? <Flex flexDir={'column'} w={'100%'} gap={'1rem'}>
              <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
                <h1>{item.titulo}</h1>
              </Flex>
              {item.arquivo && <VideoPlayer url={item.arquivo?.uri ?? ''} descricao={item.descricao} titulo={item.titulo}/>}
            </Flex>
                : <>{item.arquivo && <Flex flexDir={'column'} w={'100%'}>
            <VideoPlayer url={item.arquivo?.uri ?? ''} descricao={item.descricao} titulo={item.titulo}/>
          </Flex>}
          <Flex right={0} flexDir={'column'} w={'100%'} pos={'relative'} opacity={ModoTeatro ? '0' : '1'} minH={ModoTeatro ? '0rem' : 'fit-content'} maxH={ModoTeatro ? '0rem' : 'fit-content'}>
            <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
              <h1>{item.titulo}</h1>
            </Flex>
            <Flex flexDir={'column'} gap={'1rem'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
              <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
              {
                item.checklist?.map((r: iItemChecklist, i) => {
                  return (
                    <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                      <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                      {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                      {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                        {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                        {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                      </Flex>}
                    </Flex>
                  )
                })
              }
            </Flex>
          </Flex></>
        }
        </Main>
        <Main className={item.layout === 2 ? 'hideImersao' : 'hide'} style={{ flexDirection: 'column' }}>
          {item.layout === 3 && <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
            <h1>{item.titulo}</h1>
          </Flex>}
          {item.arquivo && <Flex flexDir={'column'} w={'100%'}>
            <VideoPlayer url={item.arquivo?.uri ?? ''} descricao={item.descricao} titulo={item.titulo}/>
          </Flex>}
          <Flex right={0} flexDir={'column'} w={'100%'} pos={'relative'} opacity={ModoTeatro ? '0' : '1'} minH={ModoTeatro ? '0rem' : 'fit-content'} maxH={ModoTeatro ? '0rem' : 'fit-content'}>
            {item.layout !== 3 && <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
              <h1>{item.titulo}</h1>
            </Flex>}
            {item.layout !== 3 && <Flex flexDir={'column'} gap={'1rem'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
              <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
              {
                item.checklist?.map((r: iItemChecklist, i) => {
                  return (
                    <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                      <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                      {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                      {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                        {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                        {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                      </Flex>}
                    </Flex>
                  )
                })
              }
            </Flex>}
          </Flex>
        </Main>
    </Flex>
  )
}

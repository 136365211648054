import styled from 'styled-components'

export const StyledText = styled.span`
background-color: ${props => props.theme};
padding: .25rem .5rem;
color: white;
padding: 4px, 16px, 4px, 16px;
border-radius: .5rem;
position: relative;
font-weight: 500;
top: -8%;
font-size:.75rem;
`

export const StyledContainer = styled.div`
border-radius: 50%;
border:6px solid ${props => props.theme};
background: white;
padding:.2rem;
span{
    margin: 0rem;
}
`

export const StyledName = styled.span`
font-weight: 600;
max-width: 8rem;
text-align: center;
font-size: .75rem;
`

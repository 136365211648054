import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem 1rem 1.5rem 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--c6);
  background: var(--c1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: var(--SombraBackground);
  position: relative;
  font-size: .813rem;
  line-height: 1rem;
  
  strong {
    font-size: .925rem;
  }
`

import { TagLabel, TagRightIcon, Tag as ChakraTag } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'

interface TagProps {
  onclickText?: () => void
  onDelete?: () => void
  onEdit?: () => void
  color?: string
  hasX?: boolean
  isDisabled?: boolean
  padding?: string
}

export const Tag: React.FC<TagProps> = ({ children, color, isDisabled, onclickText, onDelete, padding }) => {
  return (
    <ChakraTag width={'fit-content'} className={isDisabled ? 'disabled' : ''} size={'sm'} bg={color ?? 'var(--Azul)'} padding={padding ?? ''}>
      <TagLabel cursor={'pointer'} color={'white'} onClick={onclickText} fontSize={'.813rem'}>{children}</TagLabel>
      {onDelete !== undefined && <TagRightIcon color={'white'} cursor={'pointer'} onClick={onDelete} as={MdClose} />}
    </ChakraTag>
  )
}

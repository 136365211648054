/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { appApi } from '../../../../../../../services/appApi'
import { FaCheck, FaClipboardList, FaCog, FaComment, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { Flex, useToast } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { useNavigate, useParams } from 'react-router-dom'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'
import { iEtapaColaborador, iReuniao } from '../../../../../../../interfaces'
interface ITipoCardConfigReuniao {
  processoId: string
  etapa: iEtapaColaborador
  isOpen: boolean
  onReload: () => void
}

export const CardReuniao: React.FC<ITipoCardConfigReuniao> = ({ etapa, isOpen, processoId, onReload }) => {
  const { colaboradorId } = useParams<{ colaboradorId: string }>()
  const nav = useNavigate()
  const [Reuniao, setReuniao] = useState<Omit<iReuniao, 'id'>>()
  const toast = useToast()

  function GetReuniaoPorId(): void {
    appApi.get(`Reuniao/${etapa.interacoes[0].idExterno}`)
      .then(response => { setReuniao(response.data) })
      .catch(err => console.error(err))
  }

  function ObterNomePeloTipo(Tipo: number): string {
    if (Tipo === 0) {
      return 'RH'
    } else if (Tipo === 1) {
      return 'Colaborador'
    } else if (Tipo === 2) {
      return 'Gestor'
    } else if (Tipo === 3) {
      return 'Pares'
    } else if (Tipo === 4) {
      return 'Subordinados'
    } else if (Tipo === 5) {
      return 'Alta liderança'
    } else {
      return ''
    }
  }

  function FinalizarReuniao(): void {
    appApi.patch(`EtapaColaborador/${colaboradorId ?? ''}/Etapa/${etapa.id}/Concluir`)
      .then(res => console.log(res)).then(() => {
        toast({
          title: 'Reunião finalizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onReload()
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Reuniao)) {
      GetReuniaoPorId()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={18}/>
        <div>
          <h4>Responsável</h4>
          <strong style={{ wordBreak: 'break-word' }}>{Reuniao?.responsavel === 0 ? 'RH' : Reuniao?.responsavel === 1 ? 'Gestor' : Reuniao?.emailResponsavel}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaUserAlt size={18} style={{ marginTop: !Reuniao?.participantes ? '0' : '' }}/>
        <div>
          <h4>Participantes</h4>
          {
            Reuniao?.participantes !== undefined && Reuniao.participantes.length > 0
              ? <div>
                {
                  Reuniao.participantes?.map((e: any, i) => {
                    return (
                      <strong key={i}>{ObterNomePeloTipo(e)}{Reuniao.participantes !== undefined && i === Reuniao.participantes?.length - 1 ? '' : '/'}</strong>
                    )
                  })
                }
              </div>
              : <strong>O responsável ainda não selecionou os participantes</strong>
          }

        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={18} />
        <div>
          <h4>Local da reunião</h4>
          <strong>{PropInvalida(Reuniao?.local) ? 'Definido pelo responsável' : Reuniao?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Reuniao?.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={18} style={{ marginTop: !Reuniao?.descricao ? '0' : '' }}/>
            <div>
              <h4>Descrição</h4>
              <small dangerouslySetInnerHTML={{ __html: Reuniao?.descricao ?? '' }}></small>
            </div>
          </FlexIcon>
        )
      }
      {
        (!PropInvalida(Reuniao?.comentario)) && (
          <FlexIcon>
            <FaComment size={18} />
            <div>
              <h4>Comentário</h4>
              <small>{Reuniao?.comentario}</small>
            </div>
          </FlexIcon>
        )
      }

      <Flex justifyContent={'center'} direction={'column'} mt={'.75rem'} gap='.5rem'>
        {
          !Reuniao?.concluido && (
            <ElementoTooltipCmp
              bg={'var(--Azul)'}
              label={etapa.status === 1 ? 'Configuração da reunião será liberada quando a etapa anterior for finalizada.' : ''}
            >
              <ButtonCmp
                disabled={etapa.status === 1}
                onClick={() => nav(`/Rh/Reuniao/Config/${etapa.interacoes[0].idExterno}`)}
                VarColor='Green2'
                rightIcon={<FaCog />}
              >Configurar reunião</ButtonCmp>
            </ElementoTooltipCmp>

          )}
        {/* {
          !Reuniao?.concluido && Reuniao?.agendado && (
            <ButtonCmp
              onClick={FinalizarReuniao} VarColor='Rosa'
              rightIcon={<FaCheck />}
            >
              Finalizar reunião
            </ButtonCmp>
          )} */}
      </Flex>
    </Container>
  )
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iCardOrganograma } from '../../../../interfaces'
import { ContainerOrganograma } from './components/CardParticipante'
import { BtnPerfil } from './styles'

interface iProps {
  Lider?: iCardOrganograma
  Pares: iCardOrganograma[]
  liderados: iCardOrganograma[]
}

export const BoxOrganogram: React.FC<iProps> = ({ Lider, Pares, liderados }) => {
  const Nav = useNavigate()
  return (
        <div>
            <Flex justifyContent={'center'}>
                <h4 style={{ fontWeight: 600 }}>Organograma</h4>
            </Flex>
            <Flex flexDir={'column'} pt={'.325rem'} gap={'.2rem'}>
                {Lider && (
                    <ContainerOrganograma
                        colaboradores={[Lider]}
                        tipo='Gestor'
                    />
                )}

                <ContainerOrganograma
                    colaboradores={Pares}
                    tipo='Par'
                />

                <ContainerOrganograma
                    colaboradores={liderados}
                    tipo='Liderado'
                />

                <BtnPerfil
                    style={{ marginTop: '10px' }}
                    theme={'Rosa'}
                    onClick={() => Nav('/Organograma/Dashboard')}
                >Visualizar organograma</BtnPerfil>
            </Flex>
        </div>
  )
}

import { useEffect, useState } from 'react'
import { FaCog, FaEye, FaPen, FaPeopleArrows, FaUserCheck, FaUserFriends, FaUsers, FaUserTie } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import { Container, IconText } from './styles'
import { appApi } from '../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { useNavigate, useParams } from 'react-router-dom'
import { iCardEtapa } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iCardPesquisaStackHolder {
  etapa: iCardEtapa
  isOpen: boolean
}

interface iCardResponse {
  participantes: number[]
  formularioSelecionado: boolean
  formularioAprovado: boolean
}

export const CardPesquisaStackholder: React.FC<iCardPesquisaStackHolder> = ({ etapa, isOpen }) => {
  const nav = useNavigate()
  const { trilhaId } = useParams<{ trilhaId: string }>()
  const [Model, setModel] = useState<iCardResponse>()

  function ObterParticipantes(): void {
    appApi.get(`PesquisaPercepcao/${etapa.interacoes[0].idExterno}/Detalhe`)
      .then(response => {
        setModel(response.data)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      ObterParticipantes()
    }
  }, [isOpen])

  return (
    <Container>
      <h4>Quem irá participar:</h4>
      {
        Model?.participantes.map((e: number, i) => {
          if (e === 0) {
            return (
              <IconText key={i}>
                <FaUserCheck size={20} />
                <span>Autoavaliação</span>
              </IconText>
            )
          } else if (e === 1) {
            return (
              <IconText key={i}>
                <FaUserTie size={20} />
                <span>Gestor</span>
              </IconText>
            )
          } else if (e === 2) {
            return (
              <IconText key={i}>
                <FaPeopleArrows size={20} />
                <span>Par</span>
              </IconText>
            )
          } else if (e === 3) {
            return (
              <IconText key={i}>
                <FaUsers size={20} />
                <span>Subordinado</span>
              </IconText>
            )
          } else if (e === 4) {
            return (
              <IconText key={i}>
                <FaUserFriends size={20} />
                <span>Outro</span>
              </IconText>
            )
          } else if (e === 6) {
            return (
              <IconText key={i}>
                <FaUserFriends size={20} />
                <span>Alta liderança</span>
              </IconText>
            )
          }
          return null
        })
      }

      <Flex justifyContent={'center'} flexDir='column' gap={'.75rem'} mt={'.625rem'}>
        {
          (!Model?.formularioSelecionado) && (
            <ButtonCmp
              leftIcon={<FaCog />}
              VarColor='Green2'
              fontSize={'12px'}
              h={'2rem'}
              onClick={() => nav(`/Jornada/Percepcao/Quantitativa/Formularios/${etapa.interacoes[0].idExterno}/Trilha/${trilhaId ?? ''}`)}
            >Selecionar formulário</ButtonCmp>
          )
        }

        {
          (Model?.formularioSelecionado && !Model?.formularioAprovado) && (
            <ButtonCmp
              leftIcon={<FaPen />}
              VarColor='Green2'
              fontSize={'12px'}
              h={'2rem'}
              onClick={() => nav(`/Jornada/Percepcao/Quantitativa/Formulario/${etapa.interacoes[0].idExterno}/Trilha/${trilhaId ?? ''}`)}
            >Editar formulário</ButtonCmp>
          )
        }

        {
          (Model?.formularioSelecionado && Model?.formularioAprovado) && (
            <ButtonCmp
              VarColor='Green2'
              leftIcon={<BiMailSend />}
              fontSize={'12px'}
              h={'2rem'}
              onClick={() => nav(`/Jornada/Percepcao/Quantitativa/EnviarPesquisa/${etapa.interacoes[0].idExterno}/Trilha/${trilhaId ?? ''}`)}
            >Editar envio da pesquisa</ButtonCmp>
          )
        }

        {
          (Model?.formularioSelecionado && Model?.formularioAprovado) && (
            <ButtonCmp
              leftIcon={<FaEye />}
              VarColor='Green2'
              fontSize={'12px'}
              h={'2rem'}
              onClick={() => nav(`/Jornada/Percepcao/Quantitativa/VisualizarFormulario/${etapa.interacoes[0].idExterno}/Trilha/${trilhaId ?? ''}`)}
            >Visualizar formulário</ButtonCmp>
          )
        }
      </Flex>
    </Container>
  )
}

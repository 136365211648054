import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaEye, FaPen, FaPlus, FaTrash } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ModalDelete } from '../../../../components/ModalDelete'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { useAuth } from '../../../../contexts/AuthContext'
import { iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { OutlineButton } from '../Components/OutlineButton'

export const FormulariosFeedback: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()
  const { feedbackId } = useParams<{ feedbackId: string }>()
  const { Permissoes } = useAuth()

  const FluxoGestao = pathname.includes('Empresa')

  const [Model, setModel] = useState<iNomeId[]>([])
  const [Id, setId] = useState<string>()
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  function ObterFormularios(): void {
    appApi.get('FormularioFeedback')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`FormularioFeedback/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Formulário excluído com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModel([...Model.filter(e => e.id !== Id)])
      })
      .catch(err => console.log(err))
  }

  function Criar(): void {
    appApi.post('FormularioFeedback')
      .then(res => {
        if (FluxoGestao) {
          nav(pathname.replace('Formularios', `EditarFormulario/${res.data}`))
        } else {
          Selecionar(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function Duplicar(id: string): void {
    appApi.post(`FormularioFeedback/${id}/Duplicar`)
      .then(res => {
        if (!FluxoGestao) {
          Selecionar(res.data as string)
        } else {
          nav(pathname.replace('Formularios', `EditarFormulario/${res.data}`))
        }
      })
      .catch(err => console.log(err))
  }

  function Selecionar(formularioId: string): void {
    appApi.patch(`Feedback/${feedbackId ?? ''}/Formulario/${formularioId}`)
      .then(() => nav(`${pathname.replace('Formularios', 'EditarFormulario')}/${formularioId}`))
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(id: string): void {
    setId(id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setId(undefined)
    setModalDeleteIsOpen(false)
  }

  useEffect(() => {
    ObterFormularios()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir este formulário?'
        onConfirm={Deletar}
        onRequestClose={OnCloseModalDelete}
      />
      {pathname.includes('ReuniaoOne') &&
        <NavLine
          options={
            [{ nome: 'Reuniao 1:1', path: `${SubstringRoute(pathname, 'Feedback')}Dashboard/Individual` },
              { nome: 'Feedback' },
              { nome: 'Formularios' }
            ]}
        />
      }
      {FluxoGestao &&
        <Flex mb={'1rem'}>
          <Button onClick={() => nav(-1)} size={'lg'} VarColor='Gray3'>Voltar</Button>
        </Flex>
      }
      <WhiteBox>
        {!FluxoGestao &&
          <h2>Selecione ou crie um formulário novo</h2>
        }
        <Button
          my={'2rem'}
          w={'16rem'}
          VarColor={'v2'}
          leftIcon={<FaPlus />}
          isDisabled={!Permissoes.funcionalidades?.some(e => e.tipo === 5 && e.liberado) && !Permissoes.administrarFeedback
          }
          onClick={Criar}
        >Criar novo Formulário</Button>
        <Text fontWeight={'700'} fontSize={'22px'}>Formulários de feedback salvos:</Text>
        {Model.map(e => {
          return (
            <Flex key={e.id} gap={'.5rem'} my={'4px'} p={'1rem'} bgColor={'var(--Gray6)'}>
              <Text flex={'1'}>{e.nome}</Text>
              {!FluxoGestao &&
                <Button
                  h={'2rem'}
                  VarColor='v2'
                  fontSize={'0.875rem'}
                  onClick={() => Selecionar(e.id)}
                >Selecionar</Button>
              }

              {FluxoGestao &&
                <Button
                  h={'2rem'}
                  VarColor='v2'
                  fontSize={'0.875rem'}
                  leftIcon={<FaPen />}
                  onClick={() => nav(pathname.replace('Formularios', `EditarFormulario/${e.id}`))}
                >Editar</Button>
              }

              <OutlineButton
                onClick={() => Duplicar(e.id)}
                varColor='AzulClaro2'
                leftIcon={<FaCopy />}
                isDisabled={!Permissoes.funcionalidades?.some(e => e.tipo === 5 && e.liberado) && !Permissoes.administrarFeedback}
              >Duplicar</OutlineButton>

              <OutlineButton
                varColor='AzulClaro2'
                leftIcon={<FaEye />}
                onClick={() => nav(`/Feedback/Preenchimento/${e.id}/Previsualizar`)}
              >Pré-visualizar</OutlineButton>

              <OutlineButton
                onClick={() => OnOpenModalDelete(e.id)}
                varColor='Red'
                w={'1.75rem'}
                isDisabled={!Permissoes.funcionalidades?.some(e => e.tipo === 5 && e.liberado) && !Permissoes.administrarFeedback}
                leftIcon={<FaTrash />}
                pl={'1.2rem'}>
              </OutlineButton>
            </Flex>
          )
        })}
      </WhiteBox>
    </Body>
  )
}

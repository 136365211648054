/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { iGraficoCriterios } from '../../../..'

interface iProps{
  id: string
  Grafico: iGraficoCriterios
  medidaMeta: string
  tipoMeta: number
}

export const ColumnChart: React.FC<iProps> = ({ id, Grafico, medidaMeta, tipoMeta }) => {
  function FormatarNumero(num: number): string {
    let response = ''
    const toFixed = tipoMeta === 5 || tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }
  function GerarGrafico(): void {
    const options = {
      series: [{
        name: '',
        data: Grafico.data.map((e) => {
          return e[0]
        })
      }],
      colors: ['#EB5757', 'var(--Green2)'],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [{
              from: -99999999999,
              to: 0,
              color: '#EB5757'
            }, {
              from: 0,
              to: 999999999999999,
              color: 'var(--Green2)'
            }]
          },
          columnWidth: '70%'
        }
      },
      tooltip: {
        enabled: true
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '10px',
          colors: ['#000']
        },
        formatter: function (val: number) {
          const value = (tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(val)}` : `${FormatarNumero(val)}${medidaMeta ?? ''}`)
          return `${value.includes('-') ? `-${value.replace('-', '')}` : value}`
        }
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      yaxis: {
        tickAmount: 10,
        show: true,
        labels: {
          formatter: function (val: number) {
            const value = (tipoMeta === 0 ? `${medidaMeta ?? ''}${FormatarNumero(val)}` : `${FormatarNumero(val)}${medidaMeta ?? ''}`)
            return `${value.includes('-') ? `-${value.replace('-', '')}` : value}`
          }
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Abaixo do valor previsto', 'Acima do valor previsto'],
        position: 'bottom',
        fontSize: '16px',
        itemMargin: {
          horizontal: 10
        }
      },
      xaxis: {
        categories: Grafico.labels
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries([{
      name: '',
      data: Grafico.data.map((e) => {
        return e[0]
      })
    }])
  }

  useEffect(() => {
    GerarGrafico()
  }, [Grafico])
  return (
        <Flex width={'100%'} id={`chart-${id}`}/>
  )
}

import { Box } from '@chakra-ui/react'
import { iCompetenciasRecomendacoes } from '../..'
import { Container } from './styles'

interface iTabelaProps {
  dados: iCompetenciasRecomendacoes[]
  onToggle: (i: number) => void
}

export const TabelaCompetencias: React.FC<iTabelaProps> = ({ dados, onToggle }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th>Competências</th>
          <th>Total de colaboradores</th>
        </tr>
      </thead>
      <tbody>
        {
          dados.slice(3, dados.length).map((e, i) => {
            return (
              <Box as={'tr'} _hover={{ filter: 'brightness(.95)', bg: 'var(--c1)' }} style={{ cursor: 'pointer' }} key={i} onClick={() => onToggle(i + 3)}>
                <th><Box as='span' color={'var(--Azul)'} style={{ textDecoration: 'underline' }} _hover={{ textDecoration: 'underline' }}>{e.competencia}</Box></th>
                <th style={{ display: 'flex', justifyContent: 'end' }}>{e.quantidade}</th>
              </Box>
            )
          })
        }
      </tbody>
    </Container>
  )
}

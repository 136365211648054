import { Flex, FormLabel, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { iAlternativaFlexivel, iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iMultiplaEscolhaProps {
  questao: iQuestaoFlexivel
  onCreate: (id: string) => void
  onDelete: (questaoId: string, alternativaId: string) => void
  onUpdate: (questaoId: string, alternativaId: string, texto: string) => void
}

export const MultiplaEscolha: React.FC<iMultiplaEscolhaProps> = ({ questao, onCreate, onDelete, onUpdate }) => {
  const [Alternativas, setAlternativas] = useState<iAlternativaFlexivel[]>([])
  const [Editado, setEditado] = useState('')

  useEffect(() => {
    setAlternativas(questao.alternativas)
    setEditado('')
  }, [questao.alternativas])

  function UpdateArray(pos: number, texto: string): void {
    const copy = [...Alternativas]
    copy[pos].texto = texto
    setAlternativas(copy)
    setEditado(copy[pos].id)
  }

  return (
        <Flex flexDirection={'column'} width={'100%'}>
            <RadioGroup width={'100%'}>
                <FormLabel mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} wordBreak={'keep-all'}>Opções</FormLabel>
                <Stack direction={'column'} width={'100%'}>
                    {
                        Alternativas.map((e, i) => {
                          return (
                                <Flex
                                    key={e.id}
                                    borderRadius={'4px'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    border={'1px solid var(--c4)'}
                                    width={'100%'}
                                    padding={'0 .875rem'}
                                >
                                    <Flex width={'100%'}>
                                        <Radio marginRight={'.75rem'} value={e.id}/>
                                        <Input
                                            type={'text'}
                                            border={'none'}
                                            _focus={{ border: 'none' }}
                                            padding={'0'}
                                            h={'2.125rem'}
                                            value={e.texto}
                                            fontSize={'.875rem'}
                                            placeholder={'Digite sua alternativa aqui'}
                                            onChange={(r) => UpdateArray(i, r.target.value)}
                                            onBlur={() => {
                                              if (e.id === Editado) { onUpdate(questao.id, e.id, e.texto) }
                                            }}
                                            // borderBottom={'2px solid var(--Gray4)'}
                                            // boxShadow='none'
                                            // borderRadius={0}
                                        />
                                    </Flex>
                                    <Flex alignItems={'center'} justifyContent={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }} onClick={() => onDelete(questao.id, e.id)}>
                                      <MdClose
                                          size={18}
                                          cursor={'pointer'}
                                      />
                                    </Flex>
                                </Flex>
                          )
                        })
                    }
                </Stack>
            </RadioGroup>

            <Flex marginTop={'.75rem'}>
                <Radio isReadOnly isChecked={true} borderColor={'var(--c5)'}/>
                <Input
                    border={'none'}
                    value={'Adicione mais uma opção'}
                    borderBottom={'1px solid var(--Gray4)'}
                    width={'fit-content'}
                    boxShadow='none'
                    h={'2.125rem'}
                    borderRadius={0}
                    color={'var(--c6)'}
                    fontSize={'.875rem'}
                    _hover={{ borderBottom: '1px solid var(--Azul)', boxShadow: 'var(--SombraBtns)', color: 'var(--Azul)' }}
                    _focus={{ borderBottom: '1px solid var(--Gray4)' }}
                    _active={{ borderBottom: '1px solid var(--Gray4)' }}
                    readOnly
                    cursor={'pointer'}
                    onClick={() => onCreate(questao.id)}
                />
            </Flex>
        </Flex>
  )
}

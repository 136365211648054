import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
max-width: 34.375rem;
`

export const FlexIcon = styled.div`
display: flex;
margin-bottom: .5rem;
strong{
    color:var(--terc3);
    font-size: 14px;
}

svg,h4{
    color: var(--a3);
    margin-bottom: -.3rem;
}

svg{
    margin-top: .4rem;
    margin-right: .375rem;
    min-width: 2.5rem;
}

small{
font-family: 'Roboto','sans-serif';
font-weight: 700;
font-size: 12px;
color: var(--a5);
}
div {
display: flex;
flex-direction: column;
gap: .25rem;
}
`

import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/avatar'
import { Checkbox } from '@chakra-ui/checkbox'
import { FormControl } from '@chakra-ui/form-control'
import { Flex } from '@chakra-ui/layout'
import { iQuestao, iRespostaXColaborador } from '../..'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'

interface iProps {
  variant: 'editar' | 'visualizar'
  questao: iQuestao
  respostas: iRespostaXColaborador[]
  selecionadas?: string[]
  selecionar: (questaoId: string, alternativaId: string) => void
}

export const QuestaoCaixaSelecao: React.FC<iProps> = ({ variant, questao, respostas, selecionadas, selecionar }) => {
  if (variant === 'editar') {
    return (
      <FormControl
        flexDir={'column'}
        ml={'1rem'}
        my={'.8rem'}
        display={'flex'}
        gap={'.4rem'}
        justifyContent={'start'}
      >
        {questao.alternativas?.map((alternativa, i2) => {
          return (
            <Checkbox
              onChange={() => selecionar(questao.id, alternativa.id)}
              key={i2}
              value={alternativa.id}
              isChecked={selecionadas?.some(r => r === alternativa.id)}
            >{alternativa.texto}</Checkbox>
          )
        })}
      </FormControl>
    )
  } else {
    return (
      <FormControl flexDir={'column'} my={'.8rem'} display={'flex'} gap={'.4rem'}>
        {questao.alternativas?.filter((a) => {
          if (!respostas.some(r => r.respostas.some(t => t.alternativas?.includes(a.id)))) {
            return null
          }
          return a
        })?.map((alternativa, i2) => {
          const respostasFiltradas = respostas.filter(r => r.respostas.some(t => t.alternativas?.includes(alternativa.id)))
          return (
            <Flex key={i2} gap='.4rem'>
              <AvatarGroup w={'2.2rem'} spacing={'-10px'}>
                {respostasFiltradas.map((r, i) => {
                  return (
                    <Avatar src={r.avatar} key={i} name={r.colaboradorId} size={'xs'} >
                      <ElementoTooltip
                        label={r.nome}
                        position={'absolute'}
                        right={'12px'}
                        bottom={'12px'}
                      >
                        <AvatarBadge
                          boxSize='1.8rem'
                          position={'absolute'}
                          right={'4px'}
                          bottom={'4px'}
                          opacity={0}
                        />
                      </ElementoTooltip>
                    </Avatar>
                  )
                })}
              </AvatarGroup>
              <Checkbox isChecked key={i2} value={alternativa.id}>{alternativa.texto}</Checkbox>
            </Flex>
          )
        })}
      </FormControl>
    )
  }
}

import { Flex, Text } from '@chakra-ui/react'
import { FaCopy, FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iNomeId } from '../../../../../../interfaces'
import { OutlineButton } from '../../../../Feedback/Components/OutlineButton'

interface iModalComunicacao {
  isOpen: boolean
  onClose: () => void
  formularios: iNomeId[]
  activeForm?: string
  onDuplicar: (id: string) => void
  onSelectForm: (form?: iNomeId) => void
}

export const ModalFormulariosFeedback: React.FC<iModalComunicacao> = ({ isOpen, onClose, formularios, activeForm, onDuplicar, onSelectForm }) => {
  const nav = useNavigate()
  return (
    <ModalBase Titulo='Selecione um formulário' isOpen={isOpen} onClose={onClose} Width='52rem'>
      <Flex flexDir={'column'} pb={'2rem'} px={'1.25rem'}>
        <Text fontWeight={'700'} fontSize={'22px'}>Formulários de feedback salvos:</Text>
        {formularios.map(e => {
          return (
            <Flex key={e.id} gap={'.5rem'} my={'4px'} p={'1rem'} bgColor={'var(--Gray6)'}>
              <Text flex={'1'}>{e.nome}</Text>
              <Button
                h={'2rem'}
                VarColor={activeForm === e.id ? 'Rosa' : 'v2'}
                w={'6.9rem'}
                fontSize={'0.875rem'}
                onClick={() => { activeForm === e.id ? onSelectForm(undefined) : onSelectForm(e); onClose() }}
              >
               {activeForm === e.id ? 'Deselecionar' : 'Selecionar'}
              </Button>
              <OutlineButton
                onClick={() => onDuplicar(e.id)}
                varColor='AzulClaro2'
                leftIcon={<FaCopy />}
                w={'6.6rem'}
              >Duplicar</OutlineButton>

              <OutlineButton
                varColor='AzulClaro2'
                leftIcon={<FaEye />}
                w={'8.75rem'}
                onClick={() => nav(`/Feedback/Preenchimento/${e.id}/Previsualizar`)}
              >Pré-visualizar</OutlineButton>
            </Flex>
          )
        })}
      </Flex>
    </ModalBase>
  )
}

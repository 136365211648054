import styled from 'styled-components'

export const Main = styled.div`
h2{
    color: var(--a3);
}

h4{
    color: var(--a4);
}
`

export const BoxIntegracao = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border: 0.125rem solid var(--c4);
padding: 1rem;
border-radius: 0.938rem;
margin-bottom: 1rem;
`

export const Title = styled.div`
margin-bottom: 1rem;

span{
    color: var(--a4);
    font-weight: 500;
}
`

export const CardRoxo = styled.div`
color: var(--Iris80);
display: flex;
flex-direction: column;
border-radius: 1rem;
font-weight: 700;
width: 11.188rem;
height: 10.25rem;
padding: 1rem;
align-items: center;
justify-content: center;
text-align: center;
border: 0.188rem solid var(--Iris80);
svg{
    margin-bottom: .5rem;
}

&:Hover{
    background: var(--Iris80);
    color: var(--c1);
    cursor: pointer;
}
`

export const BodyCardRoxo = styled.div`
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
`

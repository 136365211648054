import { FaPlus } from 'react-icons/fa'
import { iCompetencia } from '../../../interfaces'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { ModalDelete } from '../../../components/ModalDelete'
import { Flex, useToast } from '@chakra-ui/react'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'
import { ModalVisualizarQuestoes } from './components/ModalVisualizarQuestões'
import LoadingContainer from '../../../components/LoadingContainer'
import { ModalCreateForm } from './components/ModalCreateForm'
import { useNavigate } from 'react-router-dom'
import { FormCard } from '../../../components/FormCard'
import { SwitchCmp } from '../../../components/SwitchCmp'
import { ButtonCmp } from '../../../components/ButtonCmp'

interface iModel {
  formsQuantitativa: iForm[]
  formsIndicativa: iForm[]
}

export interface iForm {
  id: string
  nome: string
  pesquisas: string[]
  exibirPeso: boolean
  status: number
  exibirCompetencia: boolean
  canSelect?: boolean
  justificativa: boolean
  competencias: iCompetencia[]
}

export const GestaoFormularios: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingForms, setIsLoadingForms] = useState(false)
  const [Model, setModel] = useState<iModel>({ formsIndicativa: [], formsQuantitativa: [] })
  const [isOpen, setisOpen] = useState(false)
  const [IsIndicativa, setIsIndicativa] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [IsOpenVisualizarQuestoes, setIsOpenVisualizarQuestoes] = useState(false)
  const [CompetenciaAtiva, setCompetenciaAtiva] = useState<iCompetencia>()
  const [Id, setId] = useState<string>()
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  function getFormularios(): void {
    setIsLoadingForms(true)
    appApi.get('Formulario/Gestao')
      .then(response => setModel(response.data))
      .catch(err => console.log(err))
      .finally(() => setIsLoadingForms(false))
  }

  function DelteFormulario(): void {
    setIsLoadingForms(true)
    appApi.delete(`Formulario/${Id ?? ''}`).then(() => getFormularios()).catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
    setId(undefined)
  }

  function OnCloseVisualizarQuestoes(): void {
    setIsOpenVisualizarQuestoes(false)
    setCompetenciaAtiva(undefined)
  }

  function CriarFormulario(texto: string, isIndicativa: boolean): void {
    if (texto === '') {
      toast({
        title: 'Informe um nome para o formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post('Formulario', { nome: texto, modoPesquisa: isIndicativa ? 1 : 0 }).then((res) => {
      nav(`/Desempenho/AnaliseDesempenho/${isIndicativa ? 'Indicativa' : 'Quantitativa'}/Formulario/${res.data}`)
    }).catch(err => console.log(err))
  }

  function Duplicar(texto: string): void {
    const form = { texto: texto }
    setIsLoading(true)
    appApi.post(`Formulario/${Id ?? ''}/Duplicar`, form)
      .then(resp => nav(`/Desempenho/AnaliseDesempenho/${IsIndicativa ? 'Indicativa' : 'Quantitativa'}/Formulario/${resp.data}`))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getFormularios()
  }, [])

  useEffect(() => {
    if (IsLoadingForms) {
      setIsLoadingForms(true)
      setTimeout(() => {
        setIsLoadingForms(false)
      }, 500)
    }
  }, [IsIndicativa])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={isOpen}
        onCreate={CriarFormulario}
        value={IsIndicativa}
        onClose={() => setisOpen(false)}
      />

      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(texto) => Duplicar(texto)}
        onClose={onCloseModalDuplicar}
      />
      <ModalVisualizarQuestoes
        isOpen={IsOpenVisualizarQuestoes}
        onClose={OnCloseVisualizarQuestoes}
        competencia={CompetenciaAtiva}
      />
      <Flex flexDir={'column'} gap={'.75rem'}>
        <Flex justifyContent={'space-between'}>
          <h2>Formulários salvos</h2>
          <ButtonCmp VarColor='Green2' onClick={() => setisOpen(true)} leftIcon={<FaPlus size={13} />}>Criar novo formulário</ButtonCmp>
        </Flex>
        <SwitchCmp OnChange={setIsIndicativa} value={IsIndicativa} legendaEsquerda='Quantitativa' legendaDireita='Indicativa'/>
        <LoadingContainer loading={IsLoadingForms} linhas={23}>
          <Flex flexDir={'column'} gap={'1rem'}>
            {!IsIndicativa &&
              Model?.formsQuantitativa?.map((e, i) => (
                  <FormCard
                    formulario={e}
                    onEditar={(formId) => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/Formulario/${formId}`)}
                    onDelete={(formId) => onOpenModalDelete(formId)}
                    onDuplicar={(formId) => { setModalDuplicarIsOpen(true); setId(formId) }}
                    onViewCompetencia={(e) => { setCompetenciaAtiva(e); setIsOpenVisualizarQuestoes(true) }}
                  />
              ))
            }
            {IsIndicativa &&
              Model?.formsIndicativa?.map((e, i) => (
                <FormCard
                  IsIndicativa
                  formulario={e}
                  onEditar={(formId) => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/Formulario/${formId}`)}
                  onDelete={(formId) => onOpenModalDelete(formId)}
                  onDuplicar={(formId) => { setModalDuplicarIsOpen(true); setId(formId) }}
                  onViewCompetencia={(e) => { setCompetenciaAtiva(e); setIsOpenVisualizarQuestoes(true) }}
                />
              ))
            }
          </Flex>
        </LoadingContainer>
      </Flex>
    </Body>
  )
}

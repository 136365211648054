import { Flex, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave, FaSyncAlt } from 'react-icons/fa'
import { MdBlock } from 'react-icons/md'
import { iAcao } from '../..'
import { ButtonCmp } from '../../../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../../../components/ModalBase'
import { TextAreaCmp } from '../../../../../../../../components/TextareaCmp'

interface iProps {
  model?: iAcao
  onClose: () => void
  onSave: (acao: iAcao) => void
  onRefresh: (nome: string) => void
}
export const ModalEditar: React.FC<iProps> = ({ model, onClose, onRefresh, onSave }) => {
  const padrao: iAcao = {
    acao: '',
    oqueFazer: '',
    comoFazer: '',
    porqueFazer: ''
  }
  const [Acao, setAcao] = useState<iAcao>(padrao)
  const [IsLoading, setIsLoading] = useState(false)

  function InternalRefresh(): void {
    setIsLoading(true)
    onRefresh(Acao.acao)
  }

  useEffect(() => {
    if (model) {
      setIsLoading(false)
      setAcao(model)
    }
  }, [model])

  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Titulo="Editando ação das recomendações"
      Subtitulo="Ao editar esta ação, lembre-se de que a recomendação original pode não ser completamente restaurada, uma vez que as sugestões são geradas por IA"
      Width="65rem"
    >
      <Flex flexDir={'column'} p='0 1rem 1rem 1rem' gap={'.5rem'}>
        <FormControl>
          <InputCmp
            label='Ação'
            borderColor={'var(--Gray4)'}
            value={Acao?.acao}
            isReadOnly
            OnChange={(e) => { setAcao({ ...Acao, acao: e }) }}
          />
        </FormControl>

        <FormControl>
          <TextAreaCmp
            label='O que fazer'
            value={Acao?.oqueFazer}
            OnChange={(e) => setAcao({ ...Acao, oqueFazer: e })}
          />
        </FormControl>

        <FormControl>
          <TextAreaCmp
            label='Como fazer'
            value={Acao?.comoFazer}
            OnChange={(e) => setAcao({ ...Acao, comoFazer: e })}
          />
        </FormControl>

        {/* <FormControl>
          <TextAreaCmp
            label={'Por que fazer'}
            value={Acao?.porqueFazer}
            OnChange={(e) => setAcao({ ...Acao, porqueFazer: e })}
          />
        </FormControl> */}
      </Flex>

      <Flex gap={'.5rem'} p={'0 1rem 1rem 0'} justifyContent={'end'}>
        <ButtonCmp
          leftIcon={<MdBlock/>}
          VarColor='Gray3'
          onClick={onClose}
        >Cancelar alterações</ButtonCmp>
        <ButtonCmp
          leftIcon={<FaSyncAlt/>}
          VarColor='Rosa'
          isLoading={IsLoading}
          onClick={InternalRefresh}
        >Refazer recomendações da IA</ButtonCmp>
        <ButtonCmp
          leftIcon={<FaSave/>}
          VarColor='Green2'
          onClick={() => onSave({ ...Acao, porqueFazer: '' })}
        >Salvar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Flex, Stack } from '@chakra-ui/layout'
import { Radio, RadioGroup } from '@chakra-ui/radio'
import { Textarea } from '@chakra-ui/textarea'
import { useEffect, useState } from 'react'
// import { FaPlus } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import { iModalAgendamentoInput } from '../..'
import { Button } from '../../../../../../components/Button'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { DeleteElementByPosition, UpdateElementByPosition } from '../../../../../../Utils/Helper'

interface iProps {
  max?: string
  min?: string
  alvo: iAvatarXInformacoes
  onClose: () => void
  onRecusar: (form: iModalAgendamentoInput) => void
}

export const ModalRecusarAgendamento: React.FC<iProps> = ({ alvo, max, min, onClose, onRecusar }) => {
  const [Value, setValue] = useState('0')
  const [DatasDisponiveis, setDatasDisponiveis] = useState<string[]>()
  const [Comentario, setComentario] = useState<string>()

  useEffect(() => {
    if (Value === '0') {
      setDatasDisponiveis(undefined)
    } else {
      setDatasDisponiveis([new Date().toISOString().split('T')[0] + 'T00:00'])
    }
  }, [Value])
  return (
    <ModalBase
      isOpen
      Width='45rem'
      Titulo='Você está prestes a recusar essa reunião'
      styleHeader={{ color: 'var(--Azul)' }}
      onClose={onClose}
    >
      <Flex padding={'0 1rem 1rem 1rem'} flexDir='column'>
        <FormControl>
          <FormLabel>Selecione uma opção para enviar a resposta para {alvo.info1}</FormLabel>
          <RadioGroup value={Value} onChange={(e) => setValue(e)}>
            <Stack direction={'column'}>
              <Radio value={'0'}>Recusar e excluir convite</Radio>
              <Radio value={'1'}>Solicitar nova data</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        {Value === '1' &&
          <Flex flexDirection={'column'}>
            {DatasDisponiveis?.map((e, i) => {
              return (
                <Flex alignItems={'center'} gap={'.5rem'} my={'.7rem'} key={i}>
                  <Input
                    borderColor={'var(--Gray4)'}
                    w={'14.5rem'}
                    value={e}
                    onChange={(data) => setDatasDisponiveis(UpdateElementByPosition([...DatasDisponiveis], i, data.target.value))}
                    type={'datetime-local'}
                    min={min?.replace('Z', '') ?? undefined}
                    max={max?.replace('Z', '') ?? undefined}
                  />
                  {i > 0 &&
                    <ElementoTooltip label='Excluir' bg={'var(--Red)'}>
                      <MdOutlineClose
                        onClick={() => setDatasDisponiveis(DeleteElementByPosition([...DatasDisponiveis], i))}
                        cursor={'pointer'} color='var(--Red)'
                      />
                    </ElementoTooltip>
                  }
                </Flex>
              )
            })}
            {/* <Button
              px={'2rem'}
              py={'.5rem'}
              w={'12rem'}
              h={'1.7rem'}
              mt={'.3rem'}
              onClick={() => setDatasDisponiveis([...DatasDisponiveis ?? [], new Date().toISOString().split('T')[0] + 'T00:00'])}
              fontSize={'0.625rem'}
              VarColor='Rosa'
              leftIcon={<FaPlus />}
            >Adicionar outra opção de data</Button> */}
          </Flex>
        }
        <Textarea
          borderColor={'var(--Gray4)'}
          mt={'1rem'}
          height={'6.375rem'}
          placeholder='Deixe uma justificativa'
          onChange={(e) => setComentario(e.target.value)}
        />
      </Flex>
      <Flex padding={'0 1rem 1rem 1rem'} gap={'1rem'} justifyContent='end'>
        <Button VarColor='c6' onClick={onClose}>Cancelar</Button>
        <Button VarColor='Red' onClick={() => onRecusar({
          excluirConvite: Value === '0',
          comentario: Comentario,
          datas: DatasDisponiveis
        })}>Enviar resposta</Button>
      </Flex>
    </ModalBase>
  )
}

import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaPlus, FaRegStopCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { FindUser } from '../../../../../components/FindBox'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { ModalReabrir } from '../../../../../components/ModalReabrir'
import { iEtapa, iNomeId, iPerfilColaborador, iPostProcessoOnboard, iProcessoOnboard, iPutDesligamento } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { useQuery } from '../../../../../Utils/Helper'
import { PopUpDesligarColaborador } from '../../../../Organograma/Desenvolvimento/Colaboradores/Components/PopUpDesligarColaborador'
import { ModalAdicionarEtapa } from '../../../ConfigurarTrilha/Components/ModalAdicionarEtapa'
import { ArrowButton } from '../../styles'
import { BoardBox } from '../BoardBox'
import { CardOnboard } from '../CardOnboard'
import { ContainerTrilha } from '../ContainerTrilha'
import { ModalDuplicarEtapa } from './Components/ModalDuplicarEtapa'
import { ModalObservacao } from './Components/ModalObservacao'
import { ColumnTitle, SectionOnboard } from './styles'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface iProps {
  processoEmAndamento?: iProcessoOnboard
  processosFinalizados: iProcessoOnboard[]
  colaborador: iPerfilColaborador
  Load: () => void
  rh: iNomeId[]
}

export const TabOnboard: React.FC<iProps> = ({ processoEmAndamento, rh, processosFinalizados, colaborador, Load }) => {
  const nav = useNavigate()
  const query = useQuery()
  const toast = useToast()

  const [ModalDesligamentoIsOpen, setModalDesligamentoIsOpen] = useState(false)
  const [EtapaIdAtual, setEtapaIdAtual] = useState('')
  const [TipoEtapa, setTipoEtapa] = useState<number>()
  const [Rh, setRh] = useState<iNomeId[]>(rh)
  const [ModalAddEtapaIsOpen, setModalAddEtapaIsOpen] = useState(false)
  const [ModalDeleteEtapaIsOpen, setModalDeleteEtapaIsOpen] = useState(false)
  const [ModalReabrirEtapaIsOpen, setModalReabrirEtapaIsOpen] = useState(false)
  const [ModalObservacaoIsOpen, setModalObservacaoIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  const [ResponsavelId, setResponsavelId] = useState<string>()

  function onCloseModalDeleteEtapa(): void {
    setModalDeleteEtapaIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDeleteEtapa(id: string): void {
    setModalDeleteEtapaIsOpen(true)
    setId(id)
  }

  function onCloseModalReabrirEtapa(): void {
    setModalReabrirEtapaIsOpen(false)
    setId(undefined)
  }

  function onOpenModalReabrirEtapa(id: string): void {
    setModalReabrirEtapaIsOpen(true)
    setId(id)
  }

  function onDeleteEtapa(): void {
    appApi.delete(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id ?? ''}`)
      .then(() => {
        Load()
        toast({
          title: 'Etapa excluída com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function onReabrirEtapa(dataFim: string): void {
    appApi.put(`EtapaColaborador/${colaborador.id}/${Id ?? ''}/Reabrir?dataFim=${dataFim}`)
      .then(() => {
        toast({
          title: 'Pesquisa reagendada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Load()
      })
      .catch(err => { console.log(err) })
  }

  function onConcludeEtapa(Id: string): void {
    appApi.patch(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id}/Concluir`)
      .then(res => console.log(res)).then(Load).catch(err => console.log(err))
  }

  function UpdateObservacao(texto: string): void {
    appApi.put(`ProcessoOnboard/${processoEmAndamento?.id ?? ''}/AtualizarObservacao/${EtapaIdAtual}`, { texto: texto })
      .then(res => console.log(res)).then(Load).catch(err => console.log(err))
    setModalObservacaoIsOpen(false)
  }

  function CriarProcesso(form: iPostProcessoOnboard): void {
    appApi.post('ProcessoOnboard', form).then(() => {
      Load()
      nav(`/Rh/VisualizarPerfil/${colaborador.id ?? ''}`)
      toast({
        title: 'Trilha cadastrada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      })
    }).catch(err => console.log(err))
  }

  function onUpdateVisibilidade(Id: string): void {
    appApi.patch(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id}/Visibilidade`)
      .then(res => console.log(res)).then(Load).catch(err => console.log(err))
  }

  function SelecionarResponsavel(id: string): void {
    if (id === '') {
      setResponsavelId(undefined)
    } else {
      setResponsavelId(id)
    }
  }

  function AtualizarResponsavel(): void {
    appApi.put(`ProcessoOnboard/${processoEmAndamento?.id ?? ''}/Responsavel/?responsavelId=${ResponsavelId as string}`)
      .then(() => {
        setResponsavelId(undefined)
        toast({
          title: 'Responsável pelo onboarding atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao atualizar o responsável pelo onboarding',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function InterromperOnboard(form: iPutDesligamento): void {
    appApi
      .put(`Colaborador/${colaborador.id ?? ''}/Desligar`, form)
      .then(() => {
        if (form.manterAcesso) {
          Load()
          nav('?criar=true')
          setModalDesligamentoIsOpen(false)
        } else {
          toast({
            title: 'Colaborador desligado com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: false
          })
        }
        window.location.reload()
      }).catch(err => {
        setModalDesligamentoIsOpen(false)
        console.log(err)
      })
  }

  function onOpenDuplicarEtapa(etapaId: string, tipo?: number): void {
    setId(etapaId)
    setTipoEtapa(tipo)
    setModalDuplicarIsOpen(true)
  }

  function onCloseDuplicarEtapa(): void {
    setId('')
    setModalDuplicarIsOpen(false)
  }

  function DuplicarEtapa(etapa: iEtapa): void {
    appApi.post(`EtapaColaborador/${colaborador.id}/Etapa/${Id ?? ''}/Duplicar`, etapa)
      .then(() => {
        Load()
        onCloseDuplicarEtapa()
        toast({
          title: 'Etapa duplicada com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch((err) => console.log(err))
  }

  function FinalizarTrilha(): void {
    appApi.patch(`ProcessoOnboard/${colaborador.id}/Finalizar`)
      .then(() => {
        toast({
          title: 'Trilha finalizada com sucesso',
          status: 'success',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
        Load()
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    setRh(rh)
  }, [rh])

  return (
    <Flex flexDir={'column'}>
      <ModalObservacao
        isOpen={ModalObservacaoIsOpen}
        onRequestClose={() => setModalObservacaoIsOpen(false)}
        onConfirm={(texto) => UpdateObservacao(texto)}
      />
      <ModalDelete
        isOpen={ModalDeleteEtapaIsOpen}
        message={processoEmAndamento?.etapas.find(e => e.etapaPai === Id) ? 'A etapa que está prestes a excluir tem outras vinculadas a ela, ao excluí-la você excluirá as etapa adicionadas a ela também. Deseja continuar?' : 'Deseja realmente excluir esta etapa?'}
        onRequestClose={onCloseModalDeleteEtapa}
        onConfirm={onDeleteEtapa}
        center
      />

      <ModalReabrir
        isOpen={ModalReabrirEtapaIsOpen}
        message='Para reabrir a pesquisa, você precisa alterar a data de término, deseja continuar?'
        onRequestClose={onCloseModalReabrirEtapa}
        dataMinima={processoEmAndamento?.etapas.find(e => e.interacoes[0].idExterno === Id)?.dataInicio ?? undefined}
        onConfirm={(e) => onReabrirEtapa(e)}
        status={true}
      />

      <ModalAdicionarEtapa
        ProcessoId={processoEmAndamento?.id}
        colabId={colaborador.id ?? ''}
        OnCloseModal={() => setModalAddEtapaIsOpen(false)}
        isOpen={ModalAddEtapaIsOpen}
        tipo={processoEmAndamento?.trilha.categoria === 'Offboarding' ? 3 : undefined}
      />

      {ModalDesligamentoIsOpen &&
        <PopUpDesligarColaborador
          onDesligar={InterromperOnboard}
          onClose={() => setModalDesligamentoIsOpen(false)}
          idDesligado={colaborador.id}
          mensagemAlternativa='Gostaria de adicionar uma trilha de offboarding para acompanhar esse deligamento?'
        />
      }

      {ModalDuplicarIsOpen &&
        <ModalDuplicarEtapa
          tipoEtapa={TipoEtapa}
          onClose={onCloseDuplicarEtapa}
          onSave={DuplicarEtapa}
        />
      }

      {processoEmAndamento &&
        <>
          <ColumnTitle>
            <Flex justifyContent={'space-between'} alignItems={'start'}>
              {(processoEmAndamento?.trilha !== null) && (
                <Flex flexDir={'column'}>
                  <h3>{processoEmAndamento?.trilha.categoria} - {processoEmAndamento?.trilha.nome}</h3>
                  <Text mt={'.125rem'} fontSize={'.875rem'}>Início: {processoEmAndamento.dataInicio}{processoEmAndamento.dias > 0 ? ` (${processoEmAndamento.dias} dias)` : ''}</Text>
                </Flex>
              )}{parseJwt().adm === 'True' && processoEmAndamento?.trilha.categoria !== 'Offboarding' &&
              <Flex justifyContent={'end'}>
                <ButtonCmp
                  OutlineColor='Red'
                  onClick={() => setModalDesligamentoIsOpen(true)}
                ><AiOutlineCloseCircle className='IconLeft' />Desligar colaborador</ButtonCmp>

              </Flex>}
            </Flex>
          </ColumnTitle>

          <Flex justifyContent={'space-between'} alignItems={'end'} margin='.75rem 0 1.5rem 0'>
            <Flex alignItems={'end'} gap='.5rem'>
              <Flex flexDir={'column'} gap={'.25rem'}>
                <Text fontSize={'.875rem'}
                  fontWeight={'500'}
                >Responsável do RH por esta trilha:
                </Text>
                <FindUser
                  selecionado={processoEmAndamento?.responsavelId}
                  onChoice={SelecionarResponsavel}
                  lista={Rh}
                  isReadOnly={parseJwt().adm === 'False'}
                />
              </Flex>
              <ButtonCmp
                isDisabled={ResponsavelId === undefined}
                VarColor='Green2'
                onClick={AtualizarResponsavel}
              >Atualizar</ButtonCmp>
            </Flex>
            {(parseJwt().adm === 'True' || processoEmAndamento.adicionarEtapa) &&
              <Flex gap={'.5rem'}>
                {parseJwt().adm === 'True' && <ButtonCmp leftIcon={<FaRegStopCircle/>} VarColor='Red' onClick={() => FinalizarTrilha()}>Finalizar trilha</ButtonCmp>}
                <ButtonCmp
                  VarColor='Rosa'
                  onClick={() => setModalAddEtapaIsOpen(true)}
                ><FaPlus className='IconLeft' />Adicionar etapa</ButtonCmp>
              </Flex>
            }
          </Flex>

          <Flex flexDir={'column'}>
            {
              processoEmAndamento?.etapas.map((e, i) => {
                return (
                  <BoardBox
                    key={i}
                    etapa={e}
                    processoId={processoEmAndamento.id}
                    colaboradorId={colaborador.id ?? ''}
                    isLast={i === processoEmAndamento.etapas.length - 1}
                    onDeleteEtapa={() => onOpenModalDeleteEtapa(e.id)}
                    onReabrirEtapa={onOpenModalReabrirEtapa}
                    onConcludeEtapa={onConcludeEtapa}
                    onUpdateVisibility={onUpdateVisibilidade}
                    onReload={Load}
                    podeAddEtapa={!!processoEmAndamento.adicionarEtapa}
                    onDuplicar={onOpenDuplicarEtapa}
                    modalObservacao={() => { setModalObservacaoIsOpen(true); setEtapaIdAtual(e.id) }}
                  />
                )
              })
            }
          </Flex>
        </>}

      {
        (!processoEmAndamento && !query.get('criar')) && (
          <ArrowButton>
            <ButtonCmp VarColor='Green2' onClick={() => nav('?criar=true')}>Começar nova trilha</ButtonCmp>
          </ArrowButton>
        )
      }

      {
        (query.get('criar') && !processoEmAndamento) && (
          <ContainerTrilha
            onCancel={() => nav(-1)}
            onCreate={CriarProcesso}
            rh={Rh}
            dataDesligamento={colaborador.dataDesligamento}
          />
        )
      }

      {
        (!query.get('criar') && processosFinalizados.length > 0) && (
          <Flex flexDir={'column'}>
            <h3>Trilhas realizadas</h3>
            <SectionOnboard>
              {
                processosFinalizados.map((e, i) => {
                  return (
                    <CardOnboard
                      key={i}
                      processo={e}
                    />
                  )
                })
              }
            </SectionOnboard>
          </Flex>
        )
      }
    </Flex>
  )
}

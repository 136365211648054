/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Img, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye, FaSave } from 'react-icons/fa'
import { iPeriodo } from '../..'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { appApi } from '../../../../../services/appApi'
import { UpdateElementByPosition } from '../../../../../Utils/Helper'
import { TableDiv } from './styles'

interface iProps {
  periodo: iPeriodo
  periodosSugestao?: iPeriodo[]
  // acumuladoAnterior: number
  status: number
  isResponsavel: boolean
  tipo: number
  periodoGeralId?: string
  onUpdate: (id: string) => void
}

export const Tabela: React.FC<iProps> = ({ onUpdate, periodo, isResponsavel, periodosSugestao, status, tipo, periodoGeralId }) => {
  const toast = useToast()
  const [Periodo, setPeriodo] = useState<iPeriodo>(periodo)
  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]

  function GetMesesDoPeriodoComValor(campo: 'real' | 'previsto', acumulado: boolean): any {
    const response: any[] = [<td>{acumulado ? 'Acumulado' : campo === 'real' ? 'Real' : 'Previsto'}</td>]
    let valorAcumulado: number = 0
    let primeiroMes: boolean = false

    for (let i = 1; i < 13; i++) {
      const mesAtual = Periodo?.meses?.find(e => e.numeroMes === i)
      if (!primeiroMes) primeiroMes = !!mesAtual
      if (mesAtual && !acumulado) {
        const indexMesAtual = Periodo.meses.findIndex(e => e.numeroMes === i)
        response.push(
          <td key={i}>
            <Flex w={'4.75rem'} justifyContent={'center'} mx={'auto'}>
              {status === 4
                ? <Flex w={'5rem'} justifyContent={'center'} mx={'auto'}>
                  {mesAtual[campo] ?? 0}
                </Flex>
                : <InputCmp
                type={'number'}
                value={mesAtual[campo] ?? 0}
                fontSize='11px'
                isDisabled={status === 2}
                OnChange={(e) => setPeriodo({
                  ...Periodo,
                  meses: UpdateElementByPosition(Periodo.meses, indexMesAtual, { ...mesAtual, [campo]: parseFloat(e) })
                })}
              />
              }
            </Flex>
          </td>
        )
      } else if (mesAtual && acumulado && primeiroMes) {
        if (tipo === 5 && !periodo.tabelaADM) {
          valorAcumulado = parseFloat(mesAtual?.real as any ?? 0)
        } else if (mesAtual?.real) {
          valorAcumulado = parseFloat(mesAtual?.real as any) + valorAcumulado
        }
        response.push(
          <td key={i}>
            <Flex w={'5rem'} justifyContent={'center'} mx={'auto'}>
              {(valorAcumulado % 1 === 0 ? valorAcumulado.toString() : valorAcumulado.toFixed(2)).replace('.', ',')}
              {tipo === 5 && !periodo.tabelaADM && '%'}
            </Flex>
          </td>
        )
      } else {
        response.push(
          <td key={i}>
            <Flex fontWeight={700} textAlign={'center'} justifyContent={'center'}>-</Flex>
          </td>
        )
      }
    }

    return response // Retorna os componentes JSX
  }

  function UpdatePeriodo(): void {
    const id = Periodo.id
    appApi.patch(`Indicador/${id}/Periodo?periodoGeralId=${periodoGeralId ?? ''}`, { meses: Periodo.meses })
      .then(res => {
        setTimeout(() => { onUpdate(id) }, 500)
        toast({
          title: 'Atualizações enviadas com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
      })
      .catch((err) => console.log(err))
  }

  function SugerirUpdatePeriodo(): void {
    const id = Periodo.id
    appApi.patch(`Indicador/${id}/SugestaoPeriodo`, { meses: Periodo.meses })
      .then(res => {
        setTimeout(() => { onUpdate(id) }, 500)
        toast({
          title: 'Atualizações enviadas com sucesso',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (Periodo && periodo.id !== Periodo.id) {
      UpdatePeriodo()
    }
    setPeriodo(periodo)
  }, [periodo])

  return (
    <Flex width={'100%'} flexDir={'column'} gap={'1.25rem'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Indicadores</th>
              {mesesAbreviados.map(e => {
                return (
                  <th>
                    {e}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>{GetMesesDoPeriodoComValor('real', false)}</tr>
            <tr>{GetMesesDoPeriodoComValor('previsto', false)}</tr>
            <tr>{GetMesesDoPeriodoComValor('real', true)}</tr>
          </tbody>

        </table>
      </TableDiv>
      <Flex justifyContent={'end'} mb={'1rem'}>
        {status !== 4 && <>
          {isResponsavel && <ButtonCmp isDisabled={status === 2} width='9.5rem' leftIcon={<FaSave/>} VarColor={'Green2'} onClick={() => UpdatePeriodo()}>Salvar alterações</ButtonCmp>}
          {!isResponsavel && <ElementoTooltipCmp background={'var(--Green2)'} label={periodosSugestao && periodosSugestao?.length > 0 ? 'Esse indicador já está aguardando a aprovação de um responsável' : ''}><ButtonCmp isDisabled={status === 2 || (periodosSugestao && periodosSugestao?.length > 0)} width='9.5rem' leftIcon={<FaSave/>} VarColor={'Green2'} onClick={() => SugerirUpdatePeriodo()}>Sugerir alterações</ButtonCmp></ElementoTooltipCmp>}
        </>}
      </Flex>
    </Flex>
  )
}

import { Flex } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { ModalBase } from '../../../../../components/ModalBase'
import { iCompetencia, iQuestao } from '../../../../../interfaces'
import { CompetenciaMenu, ImgText, CompetenciaContent, CompetenciaLine, StyledQuestion, Container } from './styles'

interface iModalProps {
  isOpen: boolean
  competencia?: iCompetencia
  onClose: () => void
}

export const ModalVisualizarQuestoes: React.FC<iModalProps> = ({ isOpen, competencia, onClose }) => {
  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      Width={'42rem'}
      Titulo='Visualizar as questões selecionadas'
    >
      {competencia && <Container>
        <CompetenciaMenu>
          <ImgText>
            <img src={competencia.icone} />
            <span>{competencia.nome}</span>
          </ImgText>
        </CompetenciaMenu>
        <Flex flex={'1'} justifyContent={'space-between'}>

          <CompetenciaContent>
            {
              competencia.questoes.map((e: iQuestao, i) => {
                if (e.tipo !== 1) {
                  return (
                    <CompetenciaLine key={i}>
                      <StyledQuestion>
                        <FaCheckCircle size={25} color='var(--Azul)' />
                        <span>{e.enunciado}</span>
                      </StyledQuestion>
                    </CompetenciaLine>
                  )
                } else {
                  return (
                    <CompetenciaLine key={i}>
                      <StyledQuestion>
                        <FaCheckCircle size={25} color='var(--Azul)' />
                        <span>{e.enunciado}</span>
                      </StyledQuestion>
                    </CompetenciaLine>
                  )
                }
              })
            }
          </CompetenciaContent>
        </Flex>
      </Container>}
    </ModalBase>
  )
}

import { useState } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Input } from '@chakra-ui/input'
import { Flex } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iProps {
  onSave: (dataInicio: string, duracao: string) => void
  onClose: () => void
}

export const ModalConfiguracao: React.FC<iProps> = ({ onClose, onSave }) => {
  const [DataInicio, setDataInicio] = useState('')
  const [Duracao, setDuracao] = useState('5')

  return (
        <ModalBase
            isOpen
            onClose={onClose}
            Titulo="Configuração"
            Width='45rem'
        >
            <Flex padding={'1rem'} gap='2rem' flexDir={'column'} alignItems='center'>
                <Flex flexDir={'column'} gap='1rem'>
                    <FormControl>
                        <FormLabel fontSize={'xl'}>Data e hora de início</FormLabel>
                        <Input
                            onChange={(e) => setDataInicio(e.target.value)}
                            type={'datetime-local'}
                            min={new Date().toISOString().slice(0, 16)}
                            max={'2099-12-31T23:59'}
                            borderColor={'var(--Gray4)'}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel fontSize={'xl'}>Duração</FormLabel>
                        <Select onChange={(e) => setDuracao(e.target.value)} borderColor={'var(--Gray4)'}>
                            <option value={'5'}>5 minutos</option>
                            <option value={'10'}>10 minutos</option>
                            <option value={'15'}>15 minutos</option>
                            <option value={'30'}>30 minutos</option>
                            <option value={'60'}>1 hora</option>
                            <option value={'120'}>2 horas</option>
                            <option value={'180'}>3 horas</option>
                            <option value={'240'}>4 horas</option>
                            <option value={'300'}>5 horas</option>
                            <option value={'360'}>6 horas</option>
                            <option value={'420'}>7 horas</option>
                            <option value={'480'}>8 horas</option>
                            <option value={'540'}>9 horas</option>
                            <option value={'600'}>10 horas</option>
                        </Select>
                    </FormControl>
                </Flex>

                <Flex gap={'1rem'}>
                    <Button VarColor='c4' onClick={onClose}>Cancelar</Button>
                    <Button
                        onClick={() => onSave(DataInicio, Duracao)}
                        VarColor='Green2'
                    >Continuar</Button>
                </Flex>
            </Flex>
        </ModalBase>
  )
}

import { Box, Button, Flex, HStack, Text, InputGroup, InputLeftElement, Input, VStack, Select, Tooltip } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBriefcase, FaEnvelope, FaFileCsv, FaSearch, FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { getToken, parseJwt } from '../../../services/token'
import { AutorizarPI, PropInvalida } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { IperfilDto, TabelaPerfis } from '../components/TabelaPerfis'

interface IListaPerfilDTO{
  quantidade: number
  cargos: number
  perfis: IperfilDto[]
}
export const numberFormatter = Intl.NumberFormat('pt-BR')
interface IRankPerfil{
  perfil: string
  quantidade: number
}
export const DashboardPi: React.FC = () => {
  const navigate = useNavigate()
  const [IsLoading, setIsLoading] = useState(false)
  const [Perfis, setPerfis] = useState<IListaPerfilDTO>({
    perfis: [],
    quantidade: 0,
    cargos: 0
  })
  const [QuantidadePerfil, setQuantidadePerfil] = useState<number>(0)
  const [Busca, setBusca] = useState('')
  const [Qtd, setQtd] = useState(25)
  const [Ranking, setRanking] = useState<IRankPerfil[]>([])
  const [PaginaAtual, setPaginaAtual] = useState(0)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState('')
  const [icone, setIcone] = useState('')
  const [perfilFiltro, setPerfilFiltro] = useState('')
  const [isDataCsvValida, setIsDataCsvValida] = useState(false)

  useEffect(() => {
    if (PropInvalida(dataFim) || PropInvalida(dataInicio)) {
      setIsDataCsvValida(false)
    } else {
      const startDate = new Date(dataInicio)
      const endDate = new Date(dataFim)

      const diffInMs = endDate.getTime() - startDate.getTime()

      const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

      setIsDataCsvValida(diffInDays < 31)
    }
  }, [dataInicio, dataFim])

  useEffect(() => {
    appApi.get('AssessmentPi/Assessment/Ranking').then(res => {
      if (res.status === 200) {
        const data: IRankPerfil[] = res.data
        setRanking(data)
      }
    }).catch(err => console.log(err))

    appApi.get(`AssessmentPi/Assessment?consultoria=${parseJwt().consultoria}`).then(res => {
      if (res.status === 200) {
        const data: IListaPerfilDTO = res.data
        setPerfis(data)
        console.log(data)
        console.log('data entrando')
      }
    }).catch(err => console.log(err))

    appApi.get(`AssessmentPi/Assessment/Count?tamanho=${Qtd}&pagina=${PaginaAtual}&?consultoria=${parseJwt().consultoria}`).then(res => {
      if (res.status === 200) {
        const data: number = res.data
        setQuantidadePerfil(data)
        console.log(data)
      }
    }).catch(err => {
      console.log(err)
      setQuantidadePerfil(0)
    })
  }, [])

  function buscar(): void {
    setIsLoading(true)
    appApi.get(`AssessmentPi/Assessment/?busca=${Busca}&tamanho=${Qtd}&pagina=${PaginaAtual}&dataInicio=${dataInicio}&dataFim=${dataFim}&consultoria=${parseJwt().consultoria}&selo=${perfilFiltro ?? ''}`).then(res => {
      if (res.status === 200) {
        const data: IListaPerfilDTO = res.data
        setPerfis(data)
        console.log(data)
        console.log('data entrando')
        setIsLoading(false)
      }
    }).catch(err => console.log(err))
  }
  const selos = [
    'Adaptativo',
    'Altruísta',
    'Artesão',
    'Aventureiro',
    'Capitão',
    'Colaborador',
    'Controlador',
    'Crítico-Analítico',
    'Empreendedor',
    'Especialista',
    'Estrategista',
    'Estudioso',
    'Guardião',
    'Individualista',
    'Operador',
    'Persuasivo',
    'Promotor'
  ]
  const imagemSelos = [
    'https://pi.predictiveindex.com/Content/img/AdapterBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/AltruistBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/CraftsmanBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/MaverickBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/CaptainBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/CollaboratorBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/ControllerBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/AnalyzerBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/VenturerBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/SpecialistBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/StrategistBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/ScholarBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/GuardianBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/IndividualistBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/OperatorBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/PersuaderBadge.en-US.png',
    'https://pi.predictiveindex.com/Content/img/PromoterBadge.en-US.png'
  ]
  useEffect(() => {
    buscar()
  }, [PaginaAtual, Qtd])

  useEffect(() => {
    if (!AutorizarPI()) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    buscar()
  }, [dataInicio, dataFim, perfilFiltro])

  return <Body isLoading={IsLoading}>

  <Flex direction={'column'} justify='center'>
    <Flex width='70rem' maxWidth='90vw' justifyContent={'space-between'}>

    <Text fontWeight='medium' mb='1rem' fontSize='1.8rem' >{'Assessment PI®'}</Text>
  <HStack>
   {icone !== '' && <img src={icone} width='34px' />}
    <Select value={perfilFiltro} onChange={(s) => {
      setPerfilFiltro(s.target.value)
      const iS = selos.indexOf(s.target.value)
      if (iS > -1) {
        setIcone(imagemSelos[iS])
      } else {
        setIcone('')
      }
    }}
     backgroundColor={'white'} color='gray.300' width='15rem' variant='outline'>
    <option value=''>Todos os perfis</option>
    {selos.map((s, i) => (
      <option value={s} > {s}</option>
    ))}
    </Select>
    <InputGroup width={'15rem'} >
    <InputLeftElement bgColor='white' color='gray.300' borderLeft={'1px'} borderTop={'1px'} borderBottom={'1px'}
    borderRadius='sm' borderColor={'gray.200'}
    pointerEvents='none'
    children={<FaSearch size={'.8rem'} color='gray.300' />}
    />
    <Input bgColor='white' type='tel' placeholder='Busca' value={Busca} onChange={(e) => setBusca(e.target.value)} onKeyPress={e => {
      if (e.key === 'Enter') { buscar() }
    }} />
  </InputGroup>
    </HStack>
      </Flex>
  <Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>

  <Flex justifyContent={'space-between'} p='1.5rem'>
    <Text fontWeight='bold' fontSize='1.8rem' >Dashboard</Text>

    <HStack spacing={4}>
    <Button leftIcon={<FaEnvelope size='.8rem'/>}
    onClick={() => navigate('/AssessmentPi/Send')}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >Enviar avaliação</Button>
   {parseJwt().consultoria === 'False' && (

    <Button leftIcon={<FaBriefcase size='.8rem'/>}
    onClick={() => navigate('/AssessmentPi/JobTarget/Create')}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >Novo cargo</Button>
   )}
    </HStack>
  </Flex>

  <Flex justifyContent='space-between' px='1rem'>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack>

      <Text>Total de perfis na empresa</Text>
      <HStack>
        <FaUsers size={'2.5rem'}/>
      <Text fontSize={'4xl'}>{numberFormatter.format(QuantidadePerfil)}</Text>
      </HStack>
      </VStack>

      </Flex>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack>

      <Text>Cargos</Text>
      <HStack>
        <FaBriefcase size={'2.3rem'}/>
      <Text fontSize={'4xl'}>{Perfis.cargos}</Text>
      </HStack>
      </VStack>

      </Flex>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack my='1rem'>

      <Text>Perfis mais encontrados</Text>
      <VStack width={'80%'} spacing='1' >
        <Flex width={'100%'}justifyContent={'space-between'} px='1rem'>
          <Text width={'2.5rem'} fontWeight={'medium'} textAlign='center' color={'gray.700'} fontSize={'sm'}>N°</Text>
          <Text width={'4.5rem'} fontWeight={'medium'} textAlign='center' color={'gray.700'} fontSize={'sm'}>Total</Text>
        </Flex>
      <VStack width={'100%'} spacing='1' >
{Ranking.map((i, k) => (
        <Flex width={'100%'}justifyContent={'space-between'} px='1rem' bgColor={'rgba(25, 97, 224, 0.1)'}>
          <Text fontWeight={'medium'} width={'2.5rem'} textAlign='center' color={'gray.700'} fontSize={'sm'}>P. {i.perfil}</Text>
          <Text fontWeight={'medium'} width={'4.5rem'} textAlign='center' color={'gray.700'} fontSize={'sm'}>{numberFormatter.format(i.quantidade)}</Text>
        </Flex>))}

      </VStack>

      </VStack>

      </VStack>

      </Flex>

  </Flex>

{(Busca === '' && PropInvalida(dataInicio) && PropInvalida(dataFim)) && PropInvalida(perfilFiltro) && (<Text m='1.5rem' fontWeight='bold' fontSize='1.8rem' >Todos os perfis</Text>)}
{(Busca !== '' || dataInicio !== '' || dataFim !== '' || perfilFiltro !== '') && (<Text m='1.5rem' fontWeight='bold' fontSize='1.8rem' >Perfis encontrados: {Perfis.quantidade}</Text>)}

<HStack ml='1rem'>

  <Text>De</Text>
<Input width={'16rem'}
 placeholder="data início"
 size="md"
 type="date"
 value={dataInicio}
 onChange={(s => setDataInicio(s.target.value))}
/>
<HStack color='var(--c7)' mr={4}>
 <Text>até</Text>
    </HStack>
<Input width={'16rem'}
 onChange={(s => setDataFim(s.target.value))}
 placeholder="data início"
 size="md"
 type="date"
 value={dataFim}
/>

{(parseJwt().adm.toLowerCase() === 'true') && (parseJwt().consultoria.toLowerCase() === 'false') && (<Tooltip label={ isDataCsvValida ? 'Baixar arquivo CSV' : 'Para baixar o arquivo CSV o periodo máximo deve ser de 30 dias'} fontSize='md'>
<div>
<Button leftIcon={<FaFileCsv size='.8rem'/>}
    disabled = {!isDataCsvValida}
    onClick={() => { window.location.href = `${urlApi}/AssessmentPI/Assessment/csv?dataInicio=${dataInicio}&dataFim=${dataFim}&token=${getToken()}` }}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >CSV</Button>
    </div>
    </Tooltip>)}
 </HStack>

  <TabelaPerfis
  isLoading={false}
  next={() => setPaginaAtual(PaginaAtual < Math.round(Perfis.quantidade / Qtd) ? PaginaAtual + 1 : Math.round(Perfis.quantidade / 25))}
  numeroPaginas={Math.round(Perfis.quantidade / Qtd)}
  paginaAtual={PaginaAtual}
  perfis={Perfis.perfis}
  prev = {() => setPaginaAtual(PaginaAtual > 0 ? PaginaAtual - 1 : 0)}

  />
  <Flex justifyContent={'end'} m='1.5rem'>

  <HStack align={'center'}>
  <Text fontSize={'xs'} fontWeight='medium'> Quantidade de perfis por página</Text>
    <Select onChange={e => setQtd(Number.parseInt(e.target.value))} size={'xs'} width={'4rem'} >
      <option value='25'>25</option>
      <option value='50'>50</option>
      <option value='100'>100</option>
    </Select>
  </HStack>
  </Flex>
  </Box>
  </Flex>
  </Body>
}

import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'
import { FormatarMensagemEditorDeTexto } from '../../../../../../Utils/Helper'
interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onSend: (texto: string) => void
}
export const ModalComentario: React.FC<iModalProps> = ({ isOpen, onClose, onSend }) => {
  const [Texto, setTexto] = useState('')

  return (
        <ModalBase
            Titulo="Deixe seu feedback para esta reunião"
            isOpen={isOpen}
            onClose={onClose}
            Width='32rem'
        >
            <Flex padding={'1rem 1rem 0 1rem'}>
                <EditorTexto initialValue={Texto}
                onChange={(e: string) => setTexto(e)}
                />
            </Flex>
            <Flex justifyContent={'end'} padding={'1rem'}>
                <Button onClick={onClose} marginRight={'1rem'} VarColor='c4'>Cancelar</Button>
                <Button VarColor='v2' onClick={() => onSend(FormatarMensagemEditorDeTexto(Texto ?? '')) }>Enviar e concluir</Button>
            </Flex>
        </ModalBase>
  )
}

import { useEffect, useState } from 'react'
import { Flex, FormControl, FormLabel, Select, Text, useToast } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'
import { iNomeId } from '../../../../../../interfaces'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { appApi } from '../../../../../../services/appApi'
import { Button } from '../../../../../../components/Button'

export interface iForm360 {
  pesquisaId: string
  reutilizarParticipantes: boolean
  dataInicio?: string
  dataFim?: string
}

interface iNomeIdPesquisa extends iNomeId{
  dataInicio: string
  dataFim: string
}

interface iProps {
  onChange: (model: iForm360) => void
  onVoltar: () => void
  onAvancar: () => void
  model?: iForm360
}

export const Form360: React.FC<iProps> = ({ onChange, onAvancar, onVoltar, model }) => {
  const toast = useToast()
  const [Model, setModel] = useState<iForm360>({
    pesquisaId: '',
    reutilizarParticipantes: true
  })
  const [Options, setOptions] = useState<iNomeIdPesquisa[]>([])
  const [PesquisaIdIsInvalid, setPesquisaIdIsInvalid] = useState(false)

  function get(): void {
    appApi.get('PesquisaIndicativa/Select')
      .then(res => setOptions(res.data))
      .catch(err => console.log(err))
  }

  function FormIsInvalid(): boolean {
    setPesquisaIdIsInvalid(false)
    if (PropInvalida(Model.pesquisaId)) {
      setPesquisaIdIsInvalid(true)
      toast({
        title: 'Selecione uma pesquisa!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }
    return false
  }

  function InternalOnAvancar(): void {
    if (!FormIsInvalid()) {
      onAvancar()
    }
  }

  useEffect(() => {
    get()
  }, [])

  useEffect(() => {
    if (model) {
      setModel(model)
    }
  }, [model])

  return (
    <Flex flexDir={'column'} gap='2rem'>
      <FormControl my={0}>
        <FormLabel>Selecione uma pesquisa</FormLabel>
        <Select
          isInvalid={PesquisaIdIsInvalid}
          onChange={(e) =>
            onChange({
              ...Model,
              pesquisaId: e.target.value,
              dataInicio: Options.find(r => r.id === e.target.value)?.dataInicio,
              dataFim: Options.find(r => r.id === e.target.value)?.dataFim
            })
          }
          borderColor={'var(--Gray4)'}
          value={Model.pesquisaId}
        >
          <option>Nenhum</option>
          {Options.map((e, i) => {
            return (
              <option value={e.id} key={i}>{e.nome}</option>
            )
          })}
        </Select>
      </FormControl>
      {/* <Checkbox
              my={0}
              onChange={() => setModel({ ...Model, reutilizarParticipantes: !Model.reutilizarParticipantes })}
            >Utilizar os participantes cadastrados na pesquisa para todo o feedback</Checkbox> */}
      <Text color={'var(--Gray4)'}>*Os participantes cadastrados na pesquisa serão fixos para todo o feedback</Text>
      <Flex justifyContent={'end'} gap='1rem'>
        <Button
          fontWeight={'500'}
          VarColor='Gray3'
          onClick={onVoltar}
        >Voltar</Button>

        <Button
          fontWeight={'500'}
          VarColor='Green2'
          rightIcon={<FaArrowRight />}
          onClick={InternalOnAvancar}
        >Avançar</Button>
      </Flex>
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { RiSendPlaneFill } from 'react-icons/ri'
import { Button } from '../../../../../components/Button'
import { ElementoTooltip } from '../../../../../components/ElementoTooltip'
import { iAvatarXInformacoes } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'

interface iProps {
  colab1?: iAvatarXInformacoes
  colab2?: iAvatarXInformacoes
}

export const DualColabs: React.FC<iProps> = ({ colab1, colab2 }) => {
  async function onReenviarConvite(colab?: iAvatarXInformacoes): Promise<void> {
    appApi.post(`/Feedback/${colab?.info3}/ReenviarConvite`)
      .then(() => {})
      .catch((err) => console.log(err))
  }

  return (
    <Flex gap={'3.5rem'}>
        <Flex gap={'.7rem'} alignItems={'center'} mb={'.5rem'}>
            <Avatar size={'sm'} src={colab1?.avatar} name={colab1?.info1} />
            <Box color={'var(--Gray3)'} display={'flex'} gap={'.5rem'}>
                <Box>
                    <Text fontSize={'14px'} fontWeight={'400'}>
                        {colab1?.info1}
                    </Text>
                    <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
                        {colab1?.info2}
                    </Text>
                </Box>
                {colab1?.info3 && <ElementoTooltip
                    placement='top'
                    label={'Reenviar convite para preenchimento'}
                    hasArrow
                    bg={'var(--Blue2)'}
                    color={'white'}
                    p={'.5rem'}
                    mr={'.4rem'}
                    >
                    <Box>
                        <Button
                        VarColor={'Blue2'}
                        p={'0'}
                        mr={'.7rem'}
                        onClick={() => { onReenviarConvite(colab1) }}
                        ><RiSendPlaneFill /></Button>
                    </Box>
                </ElementoTooltip>}
            </Box>
        </Flex>
        <Flex gap={'.7rem'} alignItems={'center'} mb={'.5rem'}>
            <Avatar size={'sm'} src={colab2?.avatar} name={colab2?.info1} />
            <Box color={'var(--Gray3)'} display={'flex'} gap={'.5rem'}>
                <Box>
                    <Text fontSize={'14px'} fontWeight={'400'}>
                        {colab2?.info1}
                    </Text>
                    <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
                        {colab2?.info2}
                    </Text>
                </Box>
                {colab2?.info3 &&
                <ElementoTooltip
                    placement='top'
                    label={'Reenviar convite para preenchimento'}
                    hasArrow
                    bg={'var(--Blue2)'}
                    color={'white'}
                    p={'.5rem'}
                    mr={'.4rem'}
                    >
                    <Box maxW={'3rem'}>
                        <Button
                        VarColor={'Blue2'}
                        p={'0'}
                        mr={'.7rem'}
                        onClick={() => { onReenviarConvite(colab2) }}
                        ><RiSendPlaneFill size={16} /></Button>
                    </Box>
                </ElementoTooltip>}
            </Box>
        </Flex>
    </Flex>
  )
}

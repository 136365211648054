import { Flex, Image, Text } from '@chakra-ui/react'
import { iImg } from '../../interfaces'
import { Container, VideoBox } from './styles'

interface iProps {
  arquivo: iImg
  size?: '2.031rem' | '4.062rem' | '8.125rem'
}

export const DetalheArquivo: React.FC<iProps> = ({ arquivo, size }) => {
  const isVideo = arquivo.uri.includes('mp3') || arquivo.uri.includes('mp4')
  return (
        <Container>
            <Flex marginBottom={'1rem'}>
                {!isVideo &&
                    <Image
                        boxSize={size ?? '4.062rem'}
                        objectFit='scale-down'
                        src={arquivo.uri}
                        alt={arquivo.name}
                        marginRight={'1rem'}
                        border={'2px solid var(--Gray3)'}
                    />
                }
                {isVideo &&
                    <VideoBox src={arquivo.uri} />
                }
                <Flex flexDir={'column'}>
                    <Text fontWeight={'700'} color={'var(--Azul)'}>{arquivo.name}</Text>
                    <small>Tamanho: {arquivo.size}</small>
                </Flex>
            </Flex>
        </Container>
  )
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, ReactNode, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { iCompetencia, iFormulario, iPutCompetencia, iSelecao } from '../interfaces'
import { appApi } from '../services/appApi'

interface FormularioProviderProps {
  children: ReactNode
}

interface iFormularioContext {
  ctxFormulario: iFormulario
  ctxCompetencias: iCompetencia[]
  SelectCompetencia: (id: string) => void
  DeletarCompetencia: (CompetenciaId: string) => void
  AtualizarCompetencia: (CompetenciaId: string, form: iPutCompetencia) => void
  SelectQuestao: (CompetenciaId: string, QuestaoId: string) => void
  CriarQuestao: (CompetenciaId: string, Enunciado: string, Tipo: number, event?: () => void) => void
  ObterCompetencias: () => void
  AtualizarQuestao: (CompetenciaId: string, QuestaoId: string, Texto: string) => void
  SetQuestaoObrigatoria: (id: string) => void
  PatchJustificativa: (id: string) => void
  loadForm: () => void
}

export const FormularioContext = createContext<iFormularioContext>({} as iFormularioContext)
export function FormularioProvider({ children }: FormularioProviderProps): any {
  const { pesquisaId, formularioId } = useParams<{pesquisaId: string, formularioId: string}>()

  const [Formulario, setFormulario] = useState<iFormulario>({
    id: '',
    nome: '',
    selecoes: [],
    status: 0,
    justificativa: false,
    escalaId: ''
  })

  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])

  function SelecionarCompetencia(CompetenciaId: string): void {
    if (Formulario.selecoes.find(e => e.competenciaId === CompetenciaId) === undefined) {
      const form: iSelecao = {
        competenciaId: CompetenciaId,
        questoesId: []
      }
      appApi.post(`Formulario/${Formulario.id}/competencia`, form).then(e => getFormulario()).catch(err => console.log(err))
    } else {
      appApi.delete(`Formulario/${Formulario.id}/competencia/${CompetenciaId}`).then(() => getFormulario()).catch(err => console.log(err))
    }
  }

  function SelecionarQuestao(CompetenciaId: string, QuestaoId: string): void {
    const form: iSelecao = {
      competenciaId: CompetenciaId,
      questoesId: [
        QuestaoId
      ]
    }
    appApi.put(`Formulario/${Formulario.id}/competencia`, form)
      .then(e => getFormulario())
      .catch(err => console.log(err))
  }

  function AtualizarCompetencia(CompetenciaId: string, form: iPutCompetencia): void {
    appApi.put(`Competencia/${CompetenciaId}`, form)
      .then(() => {
        getCompetencias()
        if (Formulario.selecoes.find(e => e.competenciaId === CompetenciaId) !== undefined) {
          getFormulario()
        }
      })
      .catch(err => console.log(err))
  }

  function getFormulario(): void {
    const data = pesquisaId ?? formularioId
    const rota = formularioId ? `Formulario/${data}` : pesquisaId ? `FormularioPesquisa/${data ?? ''}/Formulario` : ''
    appApi.get(rota)
      .then(response => setFormulario(response.data))
      .catch(err => console.log(err))
  }

  function getCompetencias(): void {
    appApi.get('Competencia')
      .then(response => setCompetencias(response.data))
      .catch(err => console.log(err))
  }

  function DeleteCompetencia(CompetenciaId: string): void {
    appApi.delete(`Competencia/${CompetenciaId}`).then(() => {
      if (Formulario.selecoes.find(e => e.competenciaId === CompetenciaId) !== undefined) {
        getCompetencias()
        getFormulario()
      } else {
        getCompetencias()
      }
    }).catch(err => console.log(err))
  }

  function CriarQuestao(CompetenciaId: string, Enunciado: string, Tipo: number): void {
    const form = {
      enunciado: Enunciado,
      tipo: Tipo
    }
    appApi.post(`Competencia/${CompetenciaId}/questao`, form)
      .then((res) => {
        getCompetencias()
        SelecionarQuestao(CompetenciaId, res.data)
      })
      .catch(err => console.log(err))
  }

  function AtualizarQuestao(CompetenciaId: string, QuestaoId: string, Texto: string): void {
    appApi.put(`Competencia/${CompetenciaId}/questao/${QuestaoId}`, { enunciado: Texto }).then(getCompetencias).catch(err => console.log(err))
  }

  function SetQuestaoObrigatoria(id: string): void {
    appApi.post(`Formulario/${Formulario.id}/SetObrigatoria/${id}`)
      .then(getFormulario)
      .catch(err => console.log(err))
  }

  function PathJustificativa(id: string): void {
    appApi.patch(`Formulario/${id}/Justificativa`)
      .then(getFormulario)
      .catch(err => console.log(err))
  }

  return (
    <FormularioContext.Provider value={{
      ctxFormulario: Formulario,
      ctxCompetencias: Competencias,
      SelectCompetencia: SelecionarCompetencia,
      SelectQuestao: SelecionarQuestao,
      DeletarCompetencia: DeleteCompetencia,
      CriarQuestao: CriarQuestao,
      ObterCompetencias: getCompetencias,
      AtualizarQuestao: AtualizarQuestao,
      AtualizarCompetencia: AtualizarCompetencia,
      SetQuestaoObrigatoria: SetQuestaoObrigatoria,
      loadForm: getFormulario,
      PatchJustificativa: PathJustificativa
    }}>
      {
        children
      }
    </FormularioContext.Provider>
  )
}

export function useFormulario(): iFormularioContext {
  const context = useContext(FormularioContext)
  const { ctxFormulario, ctxCompetencias, PatchJustificativa, loadForm, AtualizarQuestao, AtualizarCompetencia, ObterCompetencias, SelectCompetencia, SelectQuestao, DeletarCompetencia, CriarQuestao, SetQuestaoObrigatoria } = context
  return { ctxFormulario, ctxCompetencias, PatchJustificativa, loadForm, AtualizarQuestao, AtualizarCompetencia, ObterCompetencias, SelectCompetencia, SelectQuestao, DeletarCompetencia, CriarQuestao, SetQuestaoObrigatoria }
}

import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { CreateEtapaBox } from '../../../../../components/CreateEtapaBox'
import { ModalBase } from '../../../../../components/ModalBase'
import { iEtapa } from '../../../../../interfaces'

interface iModalProps {
  onClose: () => void
  onSave: (etapa: iEtapa) => void
  tipoEtapa?: number
}

export const ModalDuplicarEtapa: React.FC<iModalProps> = ({ onClose, onSave, tipoEtapa }) => {
  const [Etapa, setEtapa] = useState<iEtapa>({
    tempoInicio: 0,
    tempoDuracao: 0
  })

  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Titulo={'Deseja duplicar a etapa?'}
      Width={'40rem'}
    >
      <hr style={{ border: '0.063rem solid var(--c3)' }}></hr>
      <Flex flexDirection={'column'} px={'1rem'} py={'1rem'} gap={'.75rem'}>
        <CreateEtapaBox
          tipoEtapa={tipoEtapa}
          keyWord='etapa'
          EditEtapa={Etapa}
          variant='Etapa'
          onModelChange={(e) => setEtapa(e)}
        />
      </Flex>
      <Flex gap={'.5rem'} justifyContent={'end'} p={'1rem'}>
        <ButtonCmp onClick={onClose} VarColor='c6'>Cancelar</ButtonCmp>
        <ButtonCmp VarColor='Green2' onClick={() => onSave(Etapa)}>Salvar e duplicar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

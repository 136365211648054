import { Checkbox, Flex, FormLabel, Input, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { iAlternativaFlexivel, iPutQuestaoCaixaSelecao, iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iCaixaSelecaoProps {
  questao: iQuestaoFlexivel
  onCreate: (id: string) => void
  onDelete: (questaoId: string, alternativaId: string) => void
  onUpdate: (questaoId: string, alternativaId: string, texto: string) => void
  onUpdateMaxMin: (questaoId: string, form: iPutQuestaoCaixaSelecao) => void
}

export const CaixaDeSelecao: React.FC<iCaixaSelecaoProps> = ({ questao, onCreate, onDelete, onUpdate, onUpdateMaxMin }) => {
  const [Alternativas, setAlternativas] = useState<iAlternativaFlexivel[]>([])
  const [Editado, setEditado] = useState('')

  const [Min, setMin] = useState('')
  const [Max, setMax] = useState('')

  useEffect(() => {
    if (questao.minCaixaSelecao !== undefined && questao.minCaixaSelecao !== null) {
      setMin(questao.minCaixaSelecao.toString())
    }
    if (questao.maxCaixaSelecao !== undefined && questao.maxCaixaSelecao !== null) {
      setMax(questao.maxCaixaSelecao.toString())
    }
  }, [questao])

  useEffect(() => {
    setAlternativas(questao.alternativas)
    setEditado('')
  }, [questao.alternativas])

  function UpdateArray(pos: number, texto: string): void {
    const copy = [...Alternativas]
    copy[pos].texto = texto
    setAlternativas(copy)
    setEditado(copy[pos].id)
  }

  return (
    <Flex flexDir={'column'} width='100%'>
      <RadioGroup width={'100%'}>
        <FormLabel fontSize={'xl'}>Opções</FormLabel>
        <Stack direction={'column'} width={'100%'}>
          {
            Alternativas.map((e, i) => {
              return (
                <Flex
                  key={e.id}
                  borderRadius={'8px'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  border={'1px solid var(--Gray4)'}
                  width={'100%'}
                  padding={'.3rem 1rem'}
                >
                  <Flex width={'100%'}>
                    <Checkbox marginRight={'1rem'} value={e.id} />
                    <Input
                      type={'text'}
                      border={'none'}
                      _focus={{ border: 'none' }}
                      padding={'0'}
                      value={e.texto}
                      placeholder={'Escreva a opção'}
                      onChange={(r) => UpdateArray(i, r.target.value)}
                      onBlur={() => {
                        if (e.id === Editado) { onUpdate(questao.id, e.id, e.texto) }
                      }}
                    // borderBottom={'2px solid var(--Gray4)'}
                    // boxShadow='none'
                    // borderRadius={0}
                    />
                  </Flex>
                  <MdClose
                    size={24}
                    color='var(--Gray3)'
                    cursor={'pointer'}
                    onClick={() => onDelete(questao.id, e.id)}
                  />
                </Flex>
              )
            })
          }
        </Stack>
      </RadioGroup>

      <Flex marginTop={'1rem'}>
        <Checkbox isChecked={true} colorScheme={'green'} />
        <Input
          border={'none'}
          placeholder={'Adicione mais uma opção'}
          borderBottom={'1px solid var(--Gray4)'}
          width={'fit-content'}
          boxShadow='none'
          borderRadius={0}
          _focus={{ borderColor: 'var(--Gray4)' }}
          readOnly
          cursor={'pointer'}
          onClick={() => onCreate(questao.id)}
        />
      </Flex>

      <Flex flexDir={'column'} marginTop={'2rem'}>
        <FormLabel fontSize={'xl'}>Validação</FormLabel>
        <Flex alignItems={'center'} marginBottom={'1rem'}>
          <span>Mínimo de alternativas</span>
          <Input
            border={'none'}
            placeholder={'Número'}
            borderBottom={'1px solid var(--Gray4)'}
            boxShadow='none'
            min={0}
            borderRadius={0}
            _focus={{ borderColor: 'var(--Gray4)' }}
            width={'fit-content'}
            type={'number'}
            marginLeft={'.5rem'}
            value={Min}
            onChange={(e) => setMin(e.target.value)}
            onBlur={() => onUpdateMaxMin(questao.id, {
              maxCaixaSelecao: parseInt(Max),
              minCaixaSelecao: parseInt(Min)
            })}
          />
        </Flex>

        <Flex alignItems={'center'}>
          <span>Máximo de alternativas</span>
          <Input
            border={'none'}
            placeholder={'Número'}
            borderBottom={'1px solid var(--Gray4)'}
            boxShadow='none'
            borderRadius={0}
            width={'fit-content'}
            type={'number'}
            _focus={{ borderColor: 'var(--Gray4)' }}
            marginLeft={'.5rem'}
            value={Max}
            onChange={(e) => setMax(e.target.value)}
            onBlur={() => onUpdateMaxMin(questao.id, {
              maxCaixaSelecao: parseInt(Max),
              minCaixaSelecao: parseInt(Min)
            })}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

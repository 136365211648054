import { Container } from './styles'

interface iOutlineBox{
  width?: string
  margin?: string
  borderColor?: string
}

export const OutlineBox: React.FC<iOutlineBox> = ({ children, width, margin, borderColor }) => {
  return (
        <Container
          margin={margin}
          width={width}
          borderColor={borderColor}
        >{children}</Container>
  )
}

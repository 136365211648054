import styled from 'styled-components'

export const Header = styled.div<{hasTitle?: boolean}>`
display: flex;
justify-content: space-between;
padding: .75rem;

${({ hasTitle }) => hasTitle && `
    border-bottom: 2px solid var(--c4);
`}

span{
    margin-top: .3rem;  
}

svg{
    color: var(--Red);
    cursor: pointer;

    :hover{
        color: var(--Red-hover)
    }
}
`

export const Container = styled.div<{width?: string}>`
width: ${props => props.width};
`

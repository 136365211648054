import { Flex, FormControl, Input, Checkbox, FormLabel } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { useEffect, useState } from 'react'

interface iModal {
  isOpen: boolean
  dataDevolutiva?: string
  onRequestClose: () => void
  onConfirm: (data: string | null, checkbox: boolean) => void
  data?: string
  check: boolean
}

export const ModalDataDevolutiva: React.FC<iModal> = ({ isOpen, onRequestClose, onConfirm, data, check }) => {
  const [Data, setData] = useState<string | null>(null)
  const [checkbox, setCheckbox] = useState(false)

  useEffect(() => {
    setCheckbox(check)
    if (data) {
      const dataSplit = data.split('/')
      const str = dataSplit[2] + '-' + dataSplit[1] + '-' + dataSplit[0]
      setData(str)
    }
  }, [data, check])

  return (
        <ModalBase
            isOpen={isOpen}
            onClose={onRequestClose}
            Width="40rem"
            colorTitulo={!checkbox ? 'var(--Red)' : 'var(--Green1)'}
            Titulo={checkbox ? 'Devolutiva realizada' : 'Devolutiva pendente'}
        >
            <hr style={{ border: '1px solid var(--Gray5)', marginBottom: '1rem' }}></hr>
            <Checkbox ml={'1rem'} isChecked={checkbox} onChange={(e) => setCheckbox(e.target.checked)}>
                Devolutiva entregue
            </Checkbox>
            <Flex flexDir={'column'} padding={'1rem'}>
                {checkbox && (
                    <Flex flexDirection={'column'}>
                        <FormLabel>
                            Data da devolutiva
                        </FormLabel>
                        <FormControl>
                            <Input
                                value={Data ?? ''}
                                onChange={(e) => setData(e.target.value)}
                                type={'date'}
                                max={'2099-12-31T23:59'}
                            />
                        </FormControl>
                    </Flex>
                )}
                <Flex justifyContent={'end'} gap='1rem' marginTop={'1rem'}>
                    <Button VarColor='Gray4' onClick={onRequestClose}>Cancelar</Button>
                    <Button VarColor='Green2' onClick={() => { checkbox ? onConfirm(Data, checkbox) : onConfirm(null, checkbox) }}>Salvar</Button>
                </Flex>
            </Flex>
        </ModalBase>
  )
}

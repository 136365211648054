import { Flex, Text } from '@chakra-ui/react'
import { FaEdit } from 'react-icons/fa'
import { iAcao } from '../..'

interface iProps {
  model: iAcao
  index: number
  onEdit: (acao: iAcao) => void
}

export const Container: React.FC<iProps> = ({ model, index, onEdit }) => {
  return (
        <Flex bg={'var(--AzulOpacoBg)'} fontSize={'.875rem'} p={'1rem'} gap='.75rem' flexDir={'column'}>
            <Flex justifyContent={'space-between'} alignItems='center'>
                <Text fontSize={'1rem'} fontWeight='500'>Ação {index}: {model.acao}</Text>
                <FaEdit cursor={'pointer'} size={18} onClick={() => onEdit(model)}/>
            </Flex>

            <Flex flexDir={'column'}>
                <Text fontWeight={'700'}>O que fazer:</Text>
                <Text ml={'.75rem'} as={'li'}>{model.oqueFazer}</Text>
            </Flex>

            <Flex flexDir={'column'}>
                <Text fontWeight={'700'}>Como fazer:</Text>
                <Text ml={'.75rem'} as={'li'}>{model.comoFazer}</Text>
            </Flex>

            {/* <Flex flexDir={'column'}>
                <Text fontWeight={'700'}>Por que fazer:</Text>
                <Text ml={'.75rem'} as={'li'}>{model.porqueFazer}</Text>
            </Flex> */}
        </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'

interface iProps {
  id: string
  data: number[]
}

export const BarChart: React.FC<iProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: [{
        name: 'Quantidade de colaboradores nesse índice',
        data: data
      }],
      chart: {
        height: 320,
        width: '100%',
        type: 'bar'
      },
      colors: ['var(--Green2)', 'var(--v2)', 'var(--Yellow)', 'var(--Orange)'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#000']
        }
      },
      legend: {
        show: true,
        position: 'top',
        labels: {
          useSeriesColors: true
        }
      },
      xaxis: {
        categories: ['Todas as competências evoluíram', 'Metade ou mais das competências evoluíram', 'Pelo menos uma competência evoluíu', 'Nenhuma competência evoluíu'],
        labels: {
          formatter: function (value: any) {
            return value
          },
          show: false
        }
      },
      yaxis: {
        show: true
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [data])

  return (
        <div id={`chart-${id}`}/>
  )
}

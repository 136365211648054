import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { FaEye, FaMedal, FaTools } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iItemHistorico } from '../../../../..'
import { ButtonCmp } from '../../../../../../../../components/ButtonCmp'

interface iProps {
  model: iItemHistorico
}

export const BoxPesquisa: React.FC<iProps> = ({ model }) => {
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  return (
        <Box w={'44%'} position={'relative'}>
            <Box
                boxShadow={'0 0px 6px #00000030'}
                borderRadius={'0 .5rem .5rem .5rem'}
                p={'1rem'}
            >
                <Flex justifyContent={'space-between'} mb={'1rem'}>
                    <Text fontSize={'1.25rem'} fontWeight={700}>{model.pesquisa}</Text>
                    <ButtonCmp
                        onClick={() => nav(`${pathname.replace(avaliacaoId ?? '', model.avaliacaoId)}`)}
                        VarColor='Green2'
                        leftIcon={<FaEye />}
                    >Resultados</ButtonCmp>
                </Flex>
                <Flex gap={'1rem'} justifyContent={'space-between'}>
                    <Box>
                        <Flex gap={'.5rem'} alignItems={'center'} fontWeight={600} fontSize={'1rem'}><FaMedal style={{ color: 'var(--Green2)' }} /> Destaque</Flex>
                        {model.destaques.map((destaque, i) => {
                          return (
                                <Flex key={i} my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'14px'} gap={'.5rem'} fontWeight={'600'}>
                                    <Avatar
                                        w={'2.25rem'}
                                        h={'2.25rem'}
                                        bgColor={'transparent'}
                                        src={destaque.avatar}
                                    />
                                    {destaque.competencia}
                                </Flex>
                          )
                        })}
                    </Box>
                    <Box>
                        <Flex gap={'.5rem'} alignItems={'center'} fontWeight={600} fontSize={'1rem'}><FaTools style={{ color: '#F2994A' }} /> Potencial de melhoria</Flex>
                        {model.potencialMelhoria.map((pMelhoria, i) => {
                          return (
                                <Flex key={i} my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'14px'} gap={'.5rem'} fontWeight={'600'}>
                                    <Avatar
                                        w={'2.25rem'}
                                        h={'2.25rem'}
                                        bgColor={'transparent'}
                                        src={pMelhoria.avatar}
                                    /> {pMelhoria.competencia}
                                </Flex>
                          )
                        })}
                    </Box>
                </Flex>
            </Box>
        </Box>
  )
}

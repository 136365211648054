import { Flex, FormControl } from '@chakra-ui/react'
import { useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { iConfiguracaoEixo } from '../../../../interfaces'
import { ButtonCmp } from '../../../ButtonCmp'
import { InputCmp } from '../../../InputCmp'
import { ModalBase } from '../../../ModalBase'
import { TextAreaCmp } from '../../../TextareaCmp'

interface iProps {
  eixo?: iConfiguracaoEixo
  tipo: string
  isOpen: boolean
  onClose: () => void
  onUpdate: (eixo: iConfiguracaoEixo) => void
}

export const ModalTextoEixo: React.FC<iProps> = ({ isOpen, onClose, onUpdate, eixo, tipo }) => {
  const [Model, setModel] = useState<iConfiguracaoEixo>(eixo ?? {
    descricao: '',
    titulo: ''
  })
  return (
    <ModalBase Width='43rem' Titulo={`Eixo ${tipo}`} isOpen={isOpen} onClose={onClose}>
      <Flex gap={'1rem'} flexDir={'column'} padding='0 1rem 1rem 1rem'>
        <FormControl>
          <InputCmp label={'Título'} type="text" value={Model.titulo} OnChange={(e) => setModel({ ...Model, titulo: e })} />
        </FormControl>
        <FormControl>
          <TextAreaCmp label='Descrição' height={'8.25rem'} value={Model.descricao} OnChange={(e) => setModel({ ...Model, descricao: e })} />
        </FormControl>
        <Flex gap={'.5rem'} justifyContent={'flex-end'}>
          <ButtonCmp w={'8.5rem'} VarColor={'Gray4'} onClick={onClose}>Cancelar</ButtonCmp>
          <ButtonCmp leftIcon={<FaSave />} w={'8.5rem'} VarColor='Green2' onClick={() => onUpdate(Model)}>Salvar</ButtonCmp>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

import { Flex, FormLabel, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'
import { EditorTexto } from '../../../../../components/EditorTexto/Index'
import { ButtonTag } from '../ButtonTag'

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iProps {
  model: iTextosPesquisa[]
  onChange: (textos: iTextosPesquisa[]) => void
  onSave: () => void
  openModalTag: () => void
  textoPadrao: string
  titulo: string
  descricao: string
}
export const BoxMultiText: React.FC<iProps> = ({ titulo, descricao, textoPadrao, model, onChange, openModalTag, onSave }) => {
  const { pathname } = useLocation()
  const [Boxs, setBoxs] = useState<iTextosPesquisa[]>([{ texto: textoPadrao, participantes: [0, 1] }])
  const [isLoading, setIsLoading] = useState(true)

  function SelecionarEixo(e: string, index: number): void {
    if (!Boxs[index].participantes.includes(parseInt(e))) {
      AdicionarNovoEixo(e, index)
    } else {
      if (index === 0) {
        CriarNovoEditor(e, index)
      } else if (Boxs[index].participantes.length > 0) {
        ExcluirNovoEixo(parseInt(e), index)
      }
    }
  }

  function AdicionarNovoEixo(e: string, index: number): void {
    const copy = Boxs[index]
    copy.participantes.push(parseInt(e))
    const lista = [...Boxs]
    lista.shift()
    lista.unshift(copy)
    for (let a = 1; a < lista.length; a++) {
      if (lista[a].participantes[0] === parseInt(e)) {
        lista.splice(a, 1)
      }
    }
    setBoxs(lista)
  }

  function CriarNovoEditor(e: string, index: number): void {
    const indexEixo = (ind: number): number => {
      return Boxs[ind].participantes.findIndex(r => r === parseInt(e))
    }
    setBoxs(
      [...Boxs, {
        texto: textoPadrao,
        participantes: Boxs[index].participantes.splice(indexEixo(index), 1)
      }]
    )
  }

  function ExcluirNovoEixo(valorEixoAtual: number, index: number): void {
    const firstElement = Boxs[0]
    firstElement.participantes.push(valorEixoAtual)
    Boxs.shift()
    setBoxs({ ...Boxs.splice(index - 1, 1) })
    const copy = [...Boxs]
    copy.unshift(firstElement)
    setBoxs(copy)
  }

  function AtualizarTexto(value: string, index: number): void {
    const copy = [...Boxs]
    copy[index].texto = value
    onChange(copy)
  }

  useEffect(() => {
    setIsLoading(true)
    setBoxs(model)
    setTimeout(() => setIsLoading(false), 1000)
  }, [model])

  return (
    <Flex flexDir={'column'}>
      {Boxs?.map((elemento, index) => {
        return (
          <div key={index}>
            {index > 0 && (
              <hr style={{ marginTop: '1rem', marginBottom: '.125rem', borderTop: '1px solid var(--c3)' }} />
            )}
            <div>
              {index === 0 && (
                <Flex justifyContent={'space-between'}>
                  <Flex flexDir={'column'} gap={'.25rem'}>
                    <h4 style={{ fontWeight: 700 }}>{titulo}</h4>
                    <small style={{ fontSize: '.813rem', color: 'var(--Gray2)' }}>{descricao}</small>
                  </Flex>
                  <ButtonTag onClick={openModalTag}>Saiba como utilizar as TAGs</ButtonTag>
                </Flex>
              )}
              <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                <Flex flexDir={'column'} gap={'.75rem'} mt={'1rem'} mb={'.5rem'}>
                  <Text fontSize={'.875rem'} style={{ fontWeight: 600 }}>
                    {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                  </Text>
                  <Stack spacing={4} direction='row'>
                    {elemento.participantes.includes(0) && (
                      <CheckboxCmp
                        isDisabled={index === 0 && elemento.participantes.length === 1}
                        isChecked={elemento.participantes.includes(0)}
                        OnChange={() => SelecionarEixo('0', index)}
                      >
                        Autoavaliação
                      </CheckboxCmp>
                    )}

                    {elemento.participantes.includes(1) && (
                      <CheckboxCmp
                        isChecked={elemento.participantes.includes(1)}
                        OnChange={() => SelecionarEixo('1', index)}
                      >
                        Gestor
                      </CheckboxCmp>
                    )}

                    {elemento.participantes.includes(2) && (
                      <CheckboxCmp
                        isChecked={elemento.participantes.includes(2)}
                        OnChange={() => SelecionarEixo('2', index)}
                      >
                        Pares
                      </CheckboxCmp>
                    )}

                    {elemento.participantes.includes(3) && (
                      <CheckboxCmp
                        isChecked={elemento.participantes.includes(3)}
                        OnChange={() => SelecionarEixo('3', index)}
                      >
                        Liderados
                      </CheckboxCmp>
                    )}

                    {elemento.participantes.includes(6) && (
                      <CheckboxCmp
                        isChecked={elemento.participantes.includes(6)}
                        OnChange={() => SelecionarEixo('6', index)}
                      >
                        Alta liderança
                      </CheckboxCmp>
                    )}

                    {elemento.participantes.includes(4) && (
                      <CheckboxCmp
                        isChecked={elemento.participantes.includes(4)}
                        OnChange={() => SelecionarEixo('4', index)}
                      >
                        Outros
                      </CheckboxCmp>
                    )}
                  </Stack>
                </Flex>
                {index > 0 && (
                  <ButtonCmp
                    onClick={() => SelecionarEixo(elemento.participantes[0].toString(), index)}
                    VarColor='Red'
                  >Excluir mensagem</ButtonCmp>
                )}
              </Flex>
              <FormLabel marginRight={'.5rem'} color={'var(--c7)'} fontSize={'.875rem'} mt={'.5rem'}>
                Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
              </FormLabel>
            </div>
            <EditorTexto
              isLoading={isLoading}
              initialValue={elemento.texto}
              onBlur={(e: string) => AtualizarTexto(e, index)}
            />
            <Flex justifyContent={'space-between'} marginTop='1rem'>
              <Flex gap={'1rem'} justifyContent='space-between' w={'99%'}>
                <ButtonCmp
                  VarColor='c6'
                  onClick={() => {
                    AtualizarTexto(textoPadrao, index)
                  }}
                >Restaurar texto padrão</ButtonCmp>

                {pathname.includes('Editar') && <ButtonCmp
                  VarColor='Green2'
                  onClick={onSave}
                  rightIcon={<FaSave/>}
                >Salvar</ButtonCmp>}
              </Flex>
            </Flex>
          </div>
        )
      })}
    </Flex>
  )
}

import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const Main = styled(Flex)`
justify-content: space-between;
section{
    width: 49%;
}

img{
    width: 49%;
    height: 100%;
}
`

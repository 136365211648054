import { Button, Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaEnvelope } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { AutorizarPI, ValidaCPF, ValidarEmail } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'

export const SendAssessmentPI: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [Nome, setNome] = useState('')
  const [Email, setEmail] = useState('')
  const [CPF, setCPF] = useState('')
  useEffect(() => {
    if (!AutorizarPI()) {
      navigate('/')
    }
  }, [])
  function enviarAvaliacao(): void {
    if (Nome.length < 2) {
      toast({
        title: 'O nome do cargo deve conter no mínimo 2 caracteres',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }
    if (!ValidarEmail(Email)) {
      toast({
        title: 'Digite um email válido',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }

    if (!ValidaCPF(CPF)) {
      toast({
        title: 'Digite um CPF válido',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }

    appApi.post('AssessmentPI/Send', { nome: Nome, email: Email, cpf: CPF, enviarEmail: true })
      .then(res => {
        console.log(res.data)
        toast({
          title: 'Sua avaliação foi enviada com sucesso',
          description: `Em breve um e-mail será enviado a ${Nome} ${Email}`,
          position: 'top-right',
          variant: 'left-accent',
          status: 'success',
          duration: 12000,
          isClosable: false
        })
        navigate('/AssessmentPi/Dashboard')
      }).catch(err => {
        toast({
          title: 'Não foi possível enviar o perfil',
          description: 'Tente novamente mais tarde',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })
        console.log(err)
        navigate('/AssessmentPi/Dashboard')
      })
  }

  return <Body>

    <Flex width={'100%'} justifyContent='center'>

    <Flex width={'80%'} p='2rem' direction='column' bgColor='white' minHeight={'400px'} borderRadius='2xl'>
    <Text fontWeight={'bold'} fontSize='2xl'>Nova avaliação comportamental </Text>

    <Flex mt='2rem' alignSelf={'center'} direction='column' width={'45rem'}>

    <Text>A quem você gostaria de enviar a avaliação?</Text>

    <Text mt='1rem' fontWeight='bold' mb={2}>Nome</Text>
    <Input
      isInvalid={ Nome.length < 7}
      errorBorderColor='red.300'
    onChange={(s) => { setNome(s.target.value) }} placeholder='Digite o nome do avaliado' />
    <Text mt='1rem' fontWeight='bold' mb={2}>Email</Text>
    <Input
          isInvalid={ !ValidarEmail(Email)}
          errorBorderColor='red.300'
    onChange={(s) => { setEmail(s.target.value) }} value={Email} placeholder='Digite o email do avaliado' />
    <Text mt='1rem' fontWeight='bold' mb={2}>CPF</Text>
    <Input width={'25rem'}
          isInvalid={CPF.length < 11 || !ValidaCPF(CPF)}
          errorBorderColor='red.300'
    onChange={(s) => { setCPF(s.target.value) }} value={CPF} placeholder='Digite o CPF do avaliado' />
    </Flex>
    <Flex gap={'1rem'} mt='3rem'>
    <Button width={'100%'} bgColor={'var(--Rosa)'}
    onClick={enviarAvaliacao}
    rightIcon={<FaEnvelope/>}>Enviar avaliação por e-mail</Button>
    <Button onClick={() => navigate(-1)} width={'100%'} bgColor={'var(--c4)'} leftIcon={<FaArrowLeft/>}>Cancelar</Button>
    </Flex>

    </Flex>
    </Flex>
  </Body>
}

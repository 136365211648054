import styled from 'styled-components'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 0 1rem 0 1rem;
font-size: .875rem;

strong{
    font-weight: 900;
}

small{
    font-weight: 700;
    font-size: .875rem;
}

label{
    background: var(--c2);
    border-radius: 8px;
    padding: .5rem;
    width: 100%;
    cursor: pointer;
    margin-top: .5rem;
}

input[type=file]{
    display: none;
}
`

/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { iGrafico } from '../../../../../interfaces'

interface iProps {
  id: string
  data: iGrafico
  titulo: string
}
export const StackBarChart: React.FC<iProps> = ({ id, data, titulo }) => {
  const colors = [
    '#1961E0',
    '#27AE60',
    '#BDBDBD',
    '#F2C94C',
    '#F2994A',
    '#FF5050',
    '#9B51E0'
  ]

  function getSeries(): any {
    return data.dados.map((e) => {
      return {
        name: e.nome,
        data: e.medias
      }
    })
  }

  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 550,
        stacked: true,
        stackType: '100%'
      },
      dataLabels: {
        formatter: function (value: number): string {
          return value.toFixed(0)
        }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: titulo,
        style: {
          fontSize: 18
        }
      },
      xaxis: {
        categories: data.competencias,
        tickAmount: 10,
        labels: {
          formatter: function (val: any) {
            return `${val}%`
          },
          style: {
            fontSize: 14
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function (val: any) {
            return val
          },
          style: {
            fontSize: 14
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        fontWeight: 500,
        fontSize: 16,
        itemMargin: {
          horizontal: 16,
          vertical: 16
        },
        markers: {
          width: 18,
          height: 18,
          radius: 0,
          offsetX: -5
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [data])

  return (
    <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}

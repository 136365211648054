/* eslint-disable  @typescript-eslint/restrict-template-expressions */

import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { ModalDelete } from '../../../../components/ModalDelete'
import { SectionHeader } from '../Components/SectionHeader'
import { IDepartment } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { NavBar } from '../Components/NavBar'
import { CreateDepartmentModal } from './Components/CreateDepartmentModal'
import { DepartmentCard } from './Components/DepartmentCard'
import { UpdateDepartmentModal } from './Components/UpdateDepartmentModal'
import { Main } from './styles'
import { parseJwt } from '../../../../services/token'
import { useAuth } from '../../../../contexts/AuthContext'
import { useToast } from '@chakra-ui/react'
import { Paginador } from '../../../../components/Paginador'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { FaPlus } from 'react-icons/fa'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}

export const DepartamentoDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  const toast = useToast()

  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(15)

  function ObterRotas(): INavLink[] {
    const rotas: INavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }
  const [Departamentos, setDepartamentos] = useState<IDepartment[]>([])
  const [EditDepart, setEditDepart] = useState<IDepartment>({
    id: '',
    nome: ''
  })

  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalUpdateIsOPen, setModalUpdateIsOPen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  function GetDepartamentos(): void {
    setIsLoading(true)
    appApi.get(`Departamento/?tamanho=${Tamanho}&pagina=${Pagina}`).then(res => { setDepartamentos(res.data); setIsLoading(false) }).catch(err => { console.log(err); setIsLoading(false) })
  }

  function GetQuantidadeDepartamentos(): void {
    appApi.get('Departamento/Quantidade')
      .then(res => setQtd(res.data))
      .catch(err => console.log(err))
  }

  function onCreateDepartamentos(obj: IDepartment): void {
    const form: Omit<IDepartment, 'id'> = {
      nome: obj.nome,
      departamentoPaiId: obj.departamentoPaiId === 'Nenhum' ? undefined : obj.departamentoPaiId
    }
    if (Departamentos.some(e => (e.nome.toLowerCase() === obj.nome.toLowerCase() && e.id !== obj.id))) {
      toast({
        title: 'Nome de área já existente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post('Departamento', form).then(() => {
      GetDepartamentos()
      onRequestModalCreateClose()
    }).catch(err => console.log(err))
  }

  function onDeleteDepartamento(): void {
    appApi.delete(`Departamento/${EditDepart.id}`).then(GetDepartamentos).catch(err => console.log(err))
  }

  function onUpdateDepartamento(obj: IDepartment): void {
    const form: Omit<IDepartment, 'id'> = {
      nome: obj.nome,
      departamentoPaiId: obj.departamentoPaiId === 'Nenhum' ? undefined : obj.departamentoPaiId
    }
    if (Departamentos.some(e => (e.nome.toLowerCase() === obj.nome.toLowerCase() && e.id !== obj.id))) {
      toast({
        title: 'Nome de área já existente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.put(`Departamento/${obj.id}`, form).then(res => {
      GetDepartamentos()
      onRequestModalUpdateClose()
    }).catch(err => console.log(err))
  }

  function onRequestModalCreateClose(): void {
    setModalCreateIsOpen(false)
  }

  function onRequestModalUpdateClose(): void {
    setModalUpdateIsOPen(false)
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setEditDepart({
      id: '',
      nome: ''
    })
  }

  function onOpenModalDelete(obj: IDepartment): void {
    setModalDeleteIsOpen(true)
    setEditDepart(obj)
  }

  function onOpenModalUpdate(obj: IDepartment): void {
    setModalUpdateIsOPen(true)
    setEditDepart(obj)
  }

  useEffect(() => {
    GetDepartamentos()
  }, [Pagina, Tamanho])

  useEffect(() => {
    setPagina(0)
  }, [Tamanho])

  useEffect(() => {
    GetQuantidadeDepartamentos()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <Main>
        <CreateDepartmentModal
          isOpen={ModalCreateIsOpen}
          departmentList={Departamentos}
          onCreateDepartment={onCreateDepartamentos}
          onRequestClose={onRequestModalCreateClose}
        />

        <UpdateDepartmentModal
          isOpen={ModalUpdateIsOPen}
          departmentList={Departamentos}
          onUpdateDepartment={onUpdateDepartamento}
          onRequestClose={onRequestModalUpdateClose}
          initialData={EditDepart}
        />

        <ModalDelete
          isOpen={ModalDeleteIsOpen}
          onRequestClose={onRequestModalDeleteClose}
          onConfirm={onDeleteDepartamento}
          center
          message={'Deseja realmente excluir esta área?'}
        />

        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />
        <SectionHeader title="Áreas" Button={<ButtonCmp leftIcon={<FaPlus/>} onClick={() => setModalCreateIsOpen(true)} VarColor='Green2'>Adicionar área</ButtonCmp>}/>

        <Paginador
          next={(e) => setPagina(e)}
          prev={(e) => setPagina(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          paginaAtual={Pagina}
          qtdElementos={Qtd}
          hideTop
        >
          <section style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
            {
              Departamentos.map((e: IDepartment, i) => {
                return (
                  <DepartmentCard
                    key={i}
                    department={e}
                    onEdit={() => onOpenModalUpdate(e)}
                    onDelete={() => onOpenModalDelete(e)}
                  />
                )
              })
            }
          </section>
        </Paginador>
      </Main>
    </Body>
  )
}

import { Text, View } from '@react-pdf/renderer'

interface iProps{
  texto: string
  titulo: string
}
export const Header: React.FC<iProps> = ({ texto, titulo }) => {
  return (
        <View style={{ backgroundColor: '#CC42A2', paddingLeft: 8 }}>
            <View style={{ backgroundColor: '#1961E0', paddingLeft: 8 }}>
                <View style={{ backgroundColor: '#66046B', paddingLeft: 8 }}>
                    <View
                        style={{
                          backgroundColor: 'rgb(227, 227, 227)',
                          paddingTop: 16,
                          paddingBottom: 16,
                          paddingHorizontal: 32
                        }}
                    >
                        <Text style={{ fontSize: 16, color: '#203864' }}>
                            {texto}
                        </Text>
                        <Text
                            style={{
                              fontSize: 24,
                              color: '#203864',
                              fontWeight: 'semibold'
                            }}
                        >
                            {titulo}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
  )
}

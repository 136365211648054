import React, { useEffect, useState } from 'react'
import { MdGroup } from 'react-icons/md'
import { iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Flex, FormControl, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FindUser } from '../../../../../components/FindBox'
import { BsFillPersonFill } from 'react-icons/bs'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { InputCmp } from '../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'

interface iProps {
  isOpen: boolean
  loading: boolean
  onRequestClose: () => void
  onConfirm: (avaliadoId?: string, entidadeId?: string, apenasAvaliacao?: boolean) => void
}

export const ModalAdicionarAvaliado: React.FC<iProps> = ({ isOpen, onConfirm, onRequestClose, loading }) => {
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  const [Selecionado, setSelecionado] = useState<string>()
  const [ValorSelecionado, setValorSelecionado] = useState('')
  const [ApenasAvaliacao, setApenasAvaliacao] = useState(false)

  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Squads, setSquads] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])

  function Selecionar(id: string): void {
    setSelecionado(id)
  }

  function ObterDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function ObterTags(): void {
    appApi.get('Tag/Select?tipo=0')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function InternaConfirm(avaliadoId?: string, entidadeId?: string, apenasAvaliacao?: boolean): void {
    onConfirm(avaliadoId, entidadeId, apenasAvaliacao)
    Selecionar('')
  }

  useEffect(() => {
    ObterDepartamentos()
    ObterTags()
    ObterSquads()
    ObterColaboradores()
  }, [])

  return (
    <ModalBase
      Width='46rem'
      isOpen={isOpen}
      onClose={onRequestClose}
      Titulo='Adicione um avaliado'
      Subtitulo='O usuário deve estar cadastrado no sistema para ser adicionado como participante na pesquisa. No entanto, seus stakeholders podem ser adicionados apenas com o e-mail, posteriormente na lista de participantes'
    >
      <Flex flexDir={'column'} px={'.75rem'}>
        <Tabs>
          <TabList gap={'.5rem'} border={'none'}>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><BsFillPersonFill /> Convidar participante único</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><MdGroup /> Convidar por área/squad/tag</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><MdGroup />Convidar todos os colaboradores</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={'0'} py={'.75rem'}>
              <Flex gap={'.5rem'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'.25rem'}>
                  <h4>Nome do colaborador</h4>
                  <FindUser
                    lista={Colaboradores}
                    onChoice={Selecionar}
                    placeholder='Digite o nome do participante'
                    selecionado={Selecionado}
                  />
                </Flex>

                <FormControl gap={'.25rem'} as={Flex} flexDirection={'column'}>
                  <h4>Email</h4>
                  <InputCmp isDisabled value={Colaboradores?.find(e => e.id === Selecionado)?.email ?? ''} placeholder='Digite o email do participante' OnChange={() => {}} />
                </FormControl>
              </Flex>
              <Flex alignItems={'center'} justifyContent={'space-between'} mt={'.5rem'}>
                <CheckboxCmp isChecked={ApenasAvaliacao} OnChange={(e) => { setApenasAvaliacao(e) }}>
                  Cadastrar apenas a avaliação sem os avaliadores
                </CheckboxCmp>
                <ButtonCmp
                  size='lg'
                  VarColor='Rosa'
                  isLoading={loading}
                  onClick={() => {
                    InternaConfirm(Selecionado, undefined, ApenasAvaliacao)
                  }}
                >Adicionar</ButtonCmp>
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} py={'.75rem'}>
              <Flex gap={'1rem'} flexDir={'column'}>
                <Tabs variant='unstyled'>
                  <TabList gap={'.5rem'} px={'0'}>
                    <Tab
                      color={'var(--Rosa)'}
                      bg={'white'}
                      py={'.325rem'}
                      px={'.5rem'}
                      fontSize={'.813rem'}
                      border={'2px solid var(--Rosa)'}
                      borderRadius={'0.25rem'}
                      _selected={{ color: 'white', bg: 'var(--Rosa)' }}
                    >Área</Tab>

                    <Tab
                      color={'var(--Rosa)'}
                      bg={'white'}
                      py={'.325rem'}
                      px={'.5rem'}
                      fontSize={'.813rem'}
                      border={'2px solid var(--Rosa)'}
                      borderRadius={'0.25rem'}
                      _selected={{ color: 'white', bg: 'var(--Rosa)' }}
                    >Squad</Tab>

                    <Tab
                      color={'var(--Rosa)'}
                      bg={'white'}
                      py={'.325rem'}
                      px={'.5rem'}
                      fontSize={'.813rem'}
                      border={'2px solid var(--Rosa)'}
                      borderRadius={'0.25rem'}
                      _selected={{ color: 'white', bg: 'var(--Rosa)' }}
                    >Tag</Tab>
                  </TabList>
                  <TabPanels px={'0'}>
                    <TabPanel px={'0'}>
                      <Flex gap={'1rem'}>
                        <SelectCmp OnChange={setValorSelecionado} w={'100%'} title={'Selecione as áreas que utilizaram essa definição'}>
                          <option value={''}>Nenhum</option>
                          {Areas.filter(r => r.nome !== '').map((area) => {
                            return (
                              <option key={area.id} value={area.id}>{area.nome}</option>
                            )
                          })}
                        </SelectCmp>
                      </Flex>
                    </TabPanel>
                    <TabPanel px={'0'}>
                      <Flex gap={'1rem'}>
                        <SelectCmp OnChange={setValorSelecionado} w={'100%'} title={'Selecione os Squads que utilizaram essa definição'}>
                          <option value={''}>Nenhum</option>
                          {Squads.filter(r => r.nome !== '').map((squad) => {
                            return (
                              <option key={squad.id} value={squad.id}>{squad.nome}</option>
                            )
                          })}
                        </SelectCmp>
                      </Flex>
                    </TabPanel>
                    <TabPanel px={'0'}>
                      <Flex>
                        <SelectCmp OnChange={setValorSelecionado} w={'100%'} title={'Selecione as Tags que utilizaram essa definição'}>
                          <option value={''}>Nenhum</option>
                          {Tags.filter(r => r.nome !== '').map((tag) => {
                            return (
                              <option key={tag.id} value={tag.id}>{tag.nome}</option>
                            )
                          })}
                        </SelectCmp>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
              <Flex alignItems={'center'} justifyContent={'end'}>
                <ButtonCmp
                  VarColor='Rosa'
                  isLoading={loading}
                  onClick={() => InternaConfirm(Selecionado, ValorSelecionado)}
                >Adicionar</ButtonCmp>
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} py={'.75rem'}>
              <Flex justifyContent={'center'} alignItems='center' my={'.75rem'}>
                <ButtonCmp
                    VarColor='Rosa'
                    isLoading={loading}
                    p={'2rem'}
                    onClick={() => InternaConfirm()}
                  >Adicionar todos os colaboradores
                </ButtonCmp>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </ModalBase>
  )
}

import styled from 'styled-components'

interface iProps{
  width: string
  heigth: string
  isSelect?: boolean
}

export const EixoNineBox = styled.div<iProps>`
  width: ${props => props.width};
  background: ${props => props.theme};
  border: 2px solid ${props => props.theme};
  height: ${props => props.heigth};
  border-radius: .325rem;
  gap: .25rem;
  display: flex;
  font-size: .875rem;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: var(--SombraBackground);
  input{
    color: white;
    width: 10rem;
  }

  ${({ isSelect }) => !isSelect && `
    background: var(--c3);
    color: black;
    opacity: .45;
  `}
`

import styled from 'styled-components'

export const Main = styled.div`
display: flex;
justify-content: space-between;
flex-wrap: wrap;
`

export const MainColumn = styled.div<{padding: string, width: string}>`
display: flex;
flex-direction:column;
padding: ${props => props.padding};
width: ${props => props.width};
box-shadow: var(--SombraBackground);
background: white;
`

export const ArrowButton = styled.div`
display:flex;
padding: 0rem 0rem 1rem 0rem;
justify-content: center;
border-bottom: 2px solid var(--c5);
border-bottom-style: dashed;
margin-bottom: 1.5rem;
`

/* eslint-disable no-tabs */
import { Flex, FormControl } from '@chakra-ui/react'
import { useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { WhiteModal } from '../../../../../../components/ModalCreateForm/styles'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (nome: string) => void
}

export const ModalCreate: React.FC<props> = ({ isOpen, onRequestClose, onConfirm }) => {
  Modal.setAppElement('#root')
  const [Nome, setNome] = useState('')
  return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			overlayClassName='react-modal-overlay'
			className='react-modal-content-default'
		>
			<WhiteModal>
				<FormControl>
					<InputCmp
						label='Defina um nome para o seu formulário'
						OnChange={setNome}
						placeholder='Digite o nome'
					/>
				</FormControl>

				<Flex justifyContent={'end'} marginTop={'1rem'} gap={'.5rem'}>
					<ButtonCmp VarColor='c6' onClick={onRequestClose}>Cancelar</ButtonCmp>
					<ButtonCmp VarColor='Green2' onClick={() => onConfirm(Nome)}>Salvar</ButtonCmp>
				</Flex>
			</WhiteModal>
		</Modal>
  )
}

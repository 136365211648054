import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { FindUser } from '../../../../components/FindBox'
import { iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (id: string, email: boolean) => void
}

export const ModalResponsavel: React.FC<props> = ({ isOpen, onRequestClose, onConfirm }) => {
  const [ResponsavelId, setResponsavelId] = useState('')
  const [EnviarEmail, setEnviarEmail] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
  }, [])

  Modal.setAppElement('#root')
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
        <Flex flexDir={'column'} padding={'2rem'} w={'35rem'}>
          <Flex justifyContent={'space-between'} mb={'1rem'}>
            <h3>Alterar o responsável deste PDI</h3>
            <MdClose color='var(--Red)' cursor={'pointer'} size={20} onClick={onRequestClose}/>
          </Flex>
          <Flex flexDir={'column'} gap={'1rem'} justifyContent={'center'}>
              <FindUser
                lista={Colaboradores}
                onChoice={(e) => {
                  setResponsavelId(e)
                }}
                placeholder='Digite o nome do novo responsável por este PDI'
                selecionado={ResponsavelId}
              />
              <CheckboxCmp isChecked={EnviarEmail} OnChange={setEnviarEmail}>Enviar um email avisando o novo responsável</CheckboxCmp>
              <Flex gap={'.75rem'} justifyContent={'center'}>
                <ButtonCmp onClick={onRequestClose} VarColor='c6'>
                  Cancelar
                </ButtonCmp>
                <ButtonCmp VarColor='Green2' onClick={() => onConfirm(ResponsavelId, EnviarEmail)}>
                  Salvar
                </ButtonCmp>
              </Flex>
          </Flex>
        </Flex>
    </Modal>
  )
}

import { Box, Flex } from '@chakra-ui/react'
import { FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { iLinhaTabelaAvaliacoes, iSelo } from '../../../../..'
import { GroupBy } from '../../../../../../../../Utils/Helper'
import { Container } from './styles'

interface iProps {
  model: iLinhaTabelaAvaliacoes[]
}

export const TabelaAvaliacoes: React.FC<iProps> = ({ model }) => {
  function ObterIcone2(obj: iSelo): any {
    if (obj.selo === 4) {
      return <FaMedal style={{ color: 'var(--Green2)' }} />
    } else if (obj.selo === 1) {
      return <FaTools style={{ color: 'var(--Orange)' }} />
    } else if (obj.selo === 2) {
      return <FaHeart style={{ color: 'var(--Azul)' }} />
    } else if (obj.selo === 3) {
      return <FaFrown style={{ color: 'var(--Roxo)' }} />
    } else {
      return <></>
    }
  }

  function TemEixo(tipo: number): boolean {
    return model.some(e => e.selos2?.some(r => r.stake === tipo))
  }

  function RenderizarBloco(e: iLinhaTabelaAvaliacoes, stake: number): any {
    return (
    <td>
        <Flex justifyContent={'center'}
            gap={'.5rem'}
        >
            {GroupBy(e.selos2.filter(e => e.stake === stake), (r) => r.selo).toList().map((a, i) => {
              return (
                <Flex key={i} gap={'.325rem'} alignItems={'center'} fontWeight={'600'}>
                    {a.data.length > 1 ? a.data.length : ''}
                    {ObterIcone2(a.data[0])}
                </Flex>
              )
            })}
        </Flex>
    </td>
    )
  }
  return (
        <Box padding={'.5rem'} border={'2px solid var(--c3)'} borderRadius={'.25rem'} mt={'1rem'}>
            <Container>
                <thead>
                    <tr>
                        <th>Competências</th>
                        {TemEixo(0) && (<th>Autoavaliação</th>)}
                        {TemEixo(1) && (<th>Gestor imediato</th>)}
                        {TemEixo(2) && (<th>Pares</th>)}
                        {TemEixo(3) && (<th>Liderados</th>)}
                        {TemEixo(4) && (<th>Outros</th>)}
                        {TemEixo(6) && (<th>Alta Liderança</th>)}
                    </tr>
                </thead>
                <tbody>
                    {model.map((e, i) => {
                      return (
                            <tr key={i}>
                                <td width={120 / model.length}>{e.competencia}</td>
                                {TemEixo(0) && (
                                  RenderizarBloco(e, 0)
                                )}

                                {TemEixo(1) && (
                                  RenderizarBloco(e, 1)
                                )}

                                {TemEixo(2) && (
                                  RenderizarBloco(e, 2)
                                )}

                                {TemEixo(3) && (
                                  RenderizarBloco(e, 3)
                                )}

                                {TemEixo(4) && (
                                  RenderizarBloco(e, 4)
                                )}

                                {TemEixo(6) && (
                                  RenderizarBloco(e, 6)
                                )}

                            </tr>
                      )
                    })}

                </tbody>
            </Container>
        </Box>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'// ContentState,
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { Box } from '@chakra-ui/react'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './styles.css'

interface props {
  onChange?: Function
  onBlur?: Function
  initialValue: string
  isLoading?: boolean
  className?: string
  placeholder?: string
  readonly?: boolean
}

export const EditorTexto: React.FC<props> = ({ className, placeholder, onChange, onBlur, initialValue, isLoading, readonly }: props) => {
  // const [conteudo, setConteudo] = useState('')
  // const [content, setContent] = useState<string>('')
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())

  const [Focus, setFocus] = useState(false)

  useEffect(() => {
    const contentBlock = htmlToDraft(initialValue)
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)

    setEditorState(editorState)
  }, [isLoading])
  // useEffect(() => {
  //   const contentBlock = htmlToDraft(initialValue)
  //   const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  //   const editorState = EditorState.createWithContent(contentState)
  //   setEditorState(editorState)
  // }, [initialValue])

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(content)
  //   }
  // }, [content])
  const mudarValor = (valor: String): void => {
    if (onChange) {
      onChange(valor)
    }
  }

  const handleBlur = (): void => {
    setFocus(false)
    if (onBlur) {
      onBlur(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }
  }

  const handleFocus = (): void => {
    setFocus(true)
  }

  return (
    <Box bgColor={'white'}>
      <Editor
        editorState={editorState}
        placeholder={placeholder}
        wrapperClassName="card"
        toolbarClassName='toolbar-class'
        editorClassName={className ? `${className} ${Focus ? className + '-focus' : ''}` : 'editorSt'}
        onFocus={handleFocus}
        onEditorStateChange={newState => {
          if (!readonly) {
            setEditorState(newState)
            mudarValor(draftToHtml(convertToRaw(newState.getCurrentContent())))
          }
        }}
        onBlur={handleBlur}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'emoji', 'link'],
          link: {
            className: 'btnTeste',
            component: undefined,
            popupClassName: 'dropStyle',
            defaultTargetOption: '_self',
            options: ['link'],
            linkCallback: undefined
          },
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          history: { inDropdown: true }
        }}
      />

    </Box>
  )
}

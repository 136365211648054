import styled from 'styled-components'

export const Container = styled.div`
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-between;
gap: .5rem;
`

export const Header = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
span{
    font-size: 16px;
    font-weight: 700;
}
svg{
    margin-right: .325rem;
}
`

export const Body = styled.div`
flex: 1;
img{
    width: 100%;
    object-fit: scale-down;
}

div{
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        margin-right: .325rem;
    }
}
`

import styled from 'styled-components'

export const Container = styled.div`
max-width: 75rem;
max-height: 37.5rem;
overflow-y: auto;
border-top: 2px solid var(--Azul);
gap: .75rem;
padding: 1rem;

::-webkit-scrollbar {
  width: .5rem;
}

/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

import styled from 'styled-components'

export const BoxIntegracao = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border: 0.125rem solid var(--c4);
padding: 1rem;
border-radius: 0.938rem;
margin-bottom: 1rem;
`

export const BoxHeader = styled.div`
display: flex;
justify-content: space-between;
span{
    color: var(--a1);
    font-family: 'Poppins','sans-serif';
    font-weight: bold;
    font-size: 1.3rem;
}


svg{
    color: var(--terc3);
    cursor: pointer;
}
`

export const BoxBody = styled.div`
display: flex;
flex-direction: column;
padding-top: 1rem;
`

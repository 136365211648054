import { Checkbox, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaPaperPlane } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iConvite } from '../../../../../interfaces'
import { CircleText } from '../CircleText'
import { TableDiv } from './styles'

interface iTabelaprops {
  rows: iConvite[]
  selecionados?: string[]
  onCheck: (id: string, ids?: string[]) => void
  onSend: (id: string) => void
  isEdit?: boolean
}

export const TableConvites: React.FC<iTabelaprops> = ({ rows, isEdit, selecionados, onCheck, onSend }) => {
  const [Rows, setRows] = useState<iConvite[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [Ordenacao, setOrdenacao] = useState(false)
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }
  useEffect(() => {
    const copy = [...rows]
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.nome.localeCompare(a.nome)
        } else if (Ordenacao) {
          return a.nome.localeCompare(b.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.email.localeCompare(a.email)
        } else if (Ordenacao) {
          return a.email.localeCompare(b.email)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.area.localeCompare(a.area)
        } else if (Ordenacao) {
          return a.area.localeCompare(b.area)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.status.localeCompare(a.status)
        } else if (Ordenacao) {
          return a.status.localeCompare(b.status)
        } else {
          return 0
        }
      })
    }
    setRows(copy)
  }, [Ordenacao, UltimoFiltro, rows])

  useEffect(() => {
    if (selecionados !== undefined) { setSelecionados(selecionados) }
  }, [selecionados])

  function SelecionarTodos(): void {
    onCheck('', Rows.map((e) => { return e.id }))
  }

  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            {isEdit && <th>
              <Flex gap={'0.50rem'}>
                <span>Selecionar todos </span>
                <Checkbox marginLeft={'1rem'} borderColor={'var(--Gray4)'} onChange={SelecionarTodos} />
              </Flex></th>}
            <th onClick={() => OrdenarTabela(1)}>
              <div>Nome{UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp /> : <FaChevronDown />}</div>
            </th>
            <th onClick={() => OrdenarTabela(2)}>
              <div>Email{UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp /> : <FaChevronDown />}</div>
            </th>
            <th onClick={() => OrdenarTabela(3)}>
              <div>Área{UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp /> : <FaChevronDown />}</div>
            </th>
            {!isEdit &&
              <th onClick={() => OrdenarTabela(4)}>
                <div>Status {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp /> : <FaChevronDown />}</div>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {
            Rows.map((e, i) => {
              return (
                <tr key={i}>
                  {isEdit && <td>
                    <Checkbox isChecked={Selecionados.find(r => r === e.id) !== undefined} onChange={() => onCheck(e.id)} borderColor={'var(--a4)'} />
                  </td>
                  }
                  <td>{e.nome}</td>
                  <td>{e.email}</td>
                  <td>{e.area}</td>
                  {!isEdit && <td>
                    <Flex justifyContent={'center'} alignItems='center' gap={'1rem 1rem'} flexWrap={'wrap'}>
                      <CircleText fontsize='14px'
                        varColorCircle={e.status === 'Não enviado' ? 'c5' : e.status === 'Enviado' ? 'Rosa' : e.status === 'Visualizado' ? 'Azul' : ''}
                        varColorText='black'
                      >
                        {e.status}
                      </CircleText>
                      {
                        (e.status === 'Não enviado') && (
                          <ButtonCmp
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            small
                            VarColor='Green2'
                          >Enviar</ButtonCmp>
                        )
                      }

                      {
                        (e.status === 'Enviado') && (
                          <ButtonCmp
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            small
                            VarColor='Rosa'
                          >Reenviar</ButtonCmp>
                        )
                      }
                    </Flex>
                  </td>}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </TableDiv>
  )
}

import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Flex, Grid } from '@chakra-ui/layout'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FaPlus } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { Card } from './Components/Card'
import { appApi } from '../../../services/appApi'
import { iGrupoTrilha } from '../../../interfaces'
import { ModalDelete } from '../../../components/ModalDelete'
import { ModalNovoGrupo } from './Components/ModalNovoGrupo'
import { ModalEditarGrupo } from './Components/ModalEditarGrupo'
import { useLocation, useNavigate } from 'react-router'
import { useToast } from '@chakra-ui/react'
import { SearchCmp } from '../../../components/SearchCmp'
export const TrilhaOnboard: React.FC = () => {
  const toast = useToast()
  const { search } = useLocation()
  const nav = useNavigate()
  const tipo = search.split('tipo=')[1] ?? 0

  const [BarraPesquisa, setBarraPesquisa] = useState('')
  const [Cards, setCards] = useState<iGrupoTrilha[]>([])

  const [ModalCriarIsOpen, setModalCriarIsOpen] = useState(false)
  const [ModalEditisOpen, setModalEditIsOPen] = useState(false)

  const [EditGrupo, setEditGrupo] = useState<iGrupoTrilha>({
    categoria: 0,
    cor: '',
    id: '',
    nome: '',
    padrao: true,
    tags: [],
    countEtapas: 0,
    countProcessos: 0
  })

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState('')

  function ListarCards(): void {
    appApi.get(`GrupoTrilha?tipo=${tipo}`)
      .then(response => { setCards(response.data) }).catch(err => console.error(err))
  }

  function CriarGrupo(cor: string, nome: string, tags: string[], descricao: string): void {
    const form = {
      cor: cor,
      nome: nome,
      descricao: descricao,
      tags: tags
    }
    appApi.post(`GrupoTrilha?tipo=${tipo}`, form)
      .then(() => {
        toast({
          title: 'Trilha criada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        ListarCards()
        setModalCriarIsOpen(false)
      })
      .catch(err => console.error(err))
  }

  function AtualizarGrupo(cor: string, nome: string, tags: string[], descricao: string): void {
    const form = {
      cor: cor,
      nome: nome,
      descricao: descricao,
      categoria: tipo,
      tags: tags
    }
    appApi.put(`GrupoTrilha/${EditGrupo.id}`, form).then(() => {
      toast({
        title: 'Trilha atualizada com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: false
      })
      ListarCards()
      setModalEditIsOPen(false)
    }).catch(err => console.log(err))
  }

  function closeModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId('')
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function DeleteTrilha(): void {
    appApi.delete(`GrupoTrilha/${Id}`)
      .then(() => {
        toast({
          title: 'Trilha deletada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        ListarCards()
        closeModalDelete()
      })
      .catch(err => console.log(err))
  }

  function onRequestEditCloseModal(): void {
    setModalEditIsOPen(false)
    setEditGrupo({
      categoria: 0,
      cor: '',
      id: '',
      nome: '',
      padrao: true,
      tags: [],
      countEtapas: 0,
      countProcessos: 0
    })
  }

  function onRequestEditOpenModal(obj: iGrupoTrilha): void {
    setModalEditIsOPen(true)
    setEditGrupo(obj)
  }

  function CopiarTrilha(id: string): void {
    appApi.post(`GrupoTrilha/Copy/${id}`)
      .then(() => {
        ListarCards()
        window.location.reload()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ListarCards()
  }, [search])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        onConfirm={DeleteTrilha}
        onRequestClose={closeModalDelete}
        center
        message='Realmente deseja excluir essa trilha? As trilhas de colaborador criadas a partir desta continuarão em andamento'
      />

      <ModalNovoGrupo
        isOpen={ModalCriarIsOpen}
        onCreate={CriarGrupo}
        onRequestClose={() => { setModalCriarIsOpen(false) }}
      />

      <ModalEditarGrupo
        onRequestClose={onRequestEditCloseModal}
        onUpdate={AtualizarGrupo}
        isOpen={ModalEditisOpen}
        Card={EditGrupo}
      />
      {/* <Flex marginBottom={'1rem'}>
        <NavLine options={[{
          nome: 'Jornada',
          path: `/Jornada/Onboard/?tipo=${tipo}`
        },
        {
          nome: `${tipo === '0' ? 'Configuração de trilhas de onboarding' : tipo === '1' ? 'Configuração de trilhas de desenvolvimento' : tipo === '3' ? 'Configuração das etapas de offboarding' : ''}`
        }]} />
      </Flex> */}

      <Flex my={'1rem'} justifyContent={'space-between'}>
        <h1>{tipo === '0' ? 'Configuração de trilhas de onboarding' : tipo === '1' ? 'Configuração de trilhas de desenvolvimento' : tipo === '3' ? 'Configuração das etapas de offboarding' : ''}</h1>

        <Flex gap={'.5rem'}>
          <ButtonCmp onClick={() => nav(`/Jornada/Onboard/?tipo=${tipo}`)} VarColor='c6'>Voltar</ButtonCmp>
          <ButtonCmp VarColor='Green2' leftIcon={<FaPlus />} onClick={() => setModalCriarIsOpen(true)}>{tipo === '3' ? 'Adicionar novo offboarding' : 'Adicionar nova trilha'}</ButtonCmp>
        </Flex>
      </Flex>
      <WhiteContainer>
        <Flex marginBottom={'1rem'}>
          <SearchCmp
            EnterAction={() => {}}
            OnChange={setBarraPesquisa}
            placeholder='Pesquisa'
          />
        </Flex>
        <Grid gap={'1rem'} gridTemplateColumns={'repeat(6, 1fr)'}>
          {Cards.filter((val: iGrupoTrilha) => {
            if (BarraPesquisa === '') {
              return val
            } else if (val.nome.toLowerCase().includes(BarraPesquisa.toLowerCase())) {
              return val
            }
            return ''
          }).map((e, i) => {
            return (
              <Card
                key={i}
                trilha={e}
                onDelete={onOpenModalDelete}
                onEdit={onRequestEditOpenModal}
                onCopy={CopiarTrilha}
              />
            )
          })}
        </Grid>
      </WhiteContainer>
    </Body>
  )
}

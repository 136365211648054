import { Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaArrowRight, FaCopy, FaEye, FaPlus, FaTrash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../components/Button'
import { ModalDelete } from '../../../../../../components/ModalDelete'
import { WhiteBox } from '../../../../../../components/WhiteBox'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { OutlineButton } from '../../../../../Jornada/Feedback/Components/OutlineButton'
import { BoxMultiText } from '../../../../../Jornada/Feedback/Configurar/components/BoxMultiText'
import { ModalTag } from '../../../../../Jornada/Feedback/Configurar/components/ModalTag'

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export interface iFormFeedback {
  formularioId?: string
  dataInicio: string
  dataFim: string
  tipoRespondente: number
  visualizarResposta: boolean
  edicaoResposta: number[]
  textosEmail: iTextosPesquisa[]
  textosAbertura: iTextosPesquisa[]
}

interface iProps {
  onChange: (model: iFormFeedback) => void
  onVoltar: () => void
  onAvancar: () => void
  model?: iFormFeedback
}

export const FormFeedbackDiscursivo: React.FC<iProps> = ({ model, onChange, onAvancar, onVoltar }) => {
  const nav = useNavigate()
  const toast = useToast()
  const textoPadraoEmail = '<p><strong>Olá, [NOME DO RESPONDENTE]!</strong></p><p><strong>Você está recebendo um link para preenchimento relacionado ao feedback entre você e o [NOME DO AVALIADO].</strong></p><p><strong>Para preencher o feedback clique no link abaixo:</strong></p><p><strong><a>[LINK]</a></strong></p><p><strong>Qualquer dúvida contacte o responsável!</strong></p>'
  const textoPadraoAbertura = '<p><strong>Bem vindo ao questionário relacionado ao feedback entre o colaborador [NOME DO AVALIADO] e o colaborador [NOME DO RESPONDENTE]</strong></p>'

  const [Formularios, setFormularios] = useState<iNomeId[]>([])
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  const [DataIncioIsValid, setDataIncioIsValid] = useState(false)
  const [DataFimIsValid, setDataFimIsValid] = useState(false)

  const [Model, setModel] = useState<iFormFeedback>({
    formularioId: '',
    dataInicio: '',
    dataFim: '',
    textosAbertura: [{ texto: textoPadraoAbertura, participantes: [0, 1] }],
    textosEmail: [{ texto: textoPadraoEmail, participantes: [0, 1] }],
    edicaoResposta: [],
    tipoRespondente: 2,
    visualizarResposta: false
  })

  function onChangeEditarResposta(tipoRespondente: number): void {
    if (Model.edicaoResposta.includes(tipoRespondente)) {
      onChange({ ...Model, edicaoResposta: [...Model.edicaoResposta.filter(t => t !== tipoRespondente)] })
    } else {
      onChange({ ...Model, edicaoResposta: [...Model.edicaoResposta, tipoRespondente] })
    }
  }

  function ObterFormularios(): void {
    appApi.get('FormularioFeedback')
      .then(res => {
        if (res.status === 200) {
          setFormularios(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(id: string): void {
    setId(id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setId(undefined)
    setModalDeleteIsOpen(false)
  }

  function Duplicar(id: string): void {
    appApi.post(`FormularioFeedback/${id}/Duplicar`)
      .then(res => {
        nav(`Feedback/Formulario/${res.data}`)
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    if (Id === Model.formularioId) {
      onChange({ ...Model, formularioId: undefined })
    }
    appApi.delete(`FormularioFeedback/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Formulário excluído com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setFormularios([...Formularios.filter(e => e.id !== Id)])
      })
      .catch(err => console.log(err))
  }

  function Criar(): void {
    appApi.post('FormularioFeedback')
      .then(res => nav(`Feedback/Formulario/${res.data}`))
      .catch(err => console.log(err))
  }

  function FormIsInvalid(): boolean {
    setDataIncioIsValid(false)
    setDataFimIsValid(false)

    if (PropInvalida(Model.dataInicio)) {
      setDataIncioIsValid(true)
      toast({
        title: 'Preencha a data de início!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }

    if (PropInvalida(Model.dataFim)) {
      setDataFimIsValid(true)
      toast({
        title: 'Preencha a data de fim!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }

    if (PropInvalida(Model.formularioId)) {
      toast({
        title: 'Selecione um formulário!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }
    return false
  }

  function InternalOnAvancar(): void {
    if (!FormIsInvalid()) {
      onAvancar()
    }
  }

  useEffect(() => {
    ObterFormularios()
  }, [])

  useEffect(() => {
    if (model) {
      setModel(model)
    }
  }, [model])

  return (
    <Flex flexDir={'column'} gap='2rem'>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir este formulário?'
        onConfirm={Deletar}
        onRequestClose={OnCloseModalDelete}
      />

      <ModalTag
        isOpen={ModalTagIsOpen}
        onClose={() => setModalTagIsOpen(false)}
      />

      <Flex flexDir={'column'}>
        <h3>Selecione ou crie um formulário novo</h3>
        <Button
          my={'2rem'}
          w={'16rem'}
          VarColor={'v2'}
          leftIcon={<FaPlus />}
          onClick={Criar}
        >Criar novo Formulário</Button>
        <Text fontWeight={'700'} fontSize={'22px'}>Formulários de feedback salvos:</Text>
        {Formularios.map(e => {
          return (
            <Flex key={e.id} gap={'.5rem'} my={'4px'} p={'1rem'} bgColor={'var(--Gray6)'}>
              <Text flex={'1'}>{e.nome}</Text>
              {Model.formularioId !== e.id &&
                <Button
                  h={'2rem'}
                  VarColor='v2'
                  w={'6.9rem'}
                  fontSize={'0.875rem'}
                  onClick={() => onChange({ ...Model, formularioId: e.id })}
                >Selecionar</Button>
              }

              {Model.formularioId && Model.formularioId === e.id &&
                <Button
                  h={'2rem'}
                  VarColor='Rosa'
                  w={'6.9rem'}
                  fontSize={'0.875rem'}
                  onClick={() => onChange({ ...Model, formularioId: undefined })}
                >Deselecionar</Button>
              }

              <OutlineButton
                onClick={() => Duplicar(e.id)}
                varColor='AzulClaro2'
                leftIcon={<FaCopy />}
                w={'6.6rem'}
              >Duplicar</OutlineButton>

              <OutlineButton
                varColor='AzulClaro2'
                leftIcon={<FaEye />}
                w={'8.75rem'}
                onClick={() => nav(`/Feedback/Preenchimento/${e.id}/Previsualizar`)}
              >Pré-visualizar</OutlineButton>

              <OutlineButton
                onClick={() => OnOpenModalDelete(e.id)}
                varColor='Red'
                w={'1.75rem'}
                leftIcon={<FaTrash />}
                pl={'1.2rem'}>
              </OutlineButton>
            </Flex>
          )
        })}
      </Flex>

      <Flex flexDir={'column'} gap='1.5rem'>
        <Flex flexDir={'column'} gap='.5rem'>
          <FormControl alignItems={'center'} gap={'.5rem'} as={Flex}>
            <FormLabel whiteSpace={'nowrap'} m={0}>Data para envio</FormLabel>
            <Input
              onChange={(e) => onChange({ ...Model, dataInicio: e.target.value })}
              w={'15rem'}
              type={'datetime-local'}
              max={'2099-12-31T23:59'}
              isInvalid={DataIncioIsValid}
              value={Model.dataInicio}
            />
          </FormControl>

          <FormControl alignItems={'center'} gap={'.5rem'} as={Flex}>
            <FormLabel whiteSpace={'nowrap'} m={0}>Data máxima para responder</FormLabel>
            <Input
              w={'15rem'}
              type={'datetime-local'}
              onChange={(e) => onChange({ ...Model, dataFim: e.target.value })}
              isInvalid={DataFimIsValid}
              max={'2099-12-31T23:59'}
              value={Model.dataFim.replace('Z', '')}
            />
          </FormControl>
          <Text fontSize={'14px'} fontWeight='400'>Caso a data máxima chegue um e-mail será enviado até que o usuário submeta suas respostas</Text>
        </Flex>

        <FormControl>
          <FormLabel>Quem deve responder ao feedback</FormLabel>
          <RadioGroup
            value={Model.tipoRespondente?.toString() ?? '2'}
            onChange={(e) => setModel({
              ...Model,
              tipoRespondente: parseInt(e),
              textosEmail: [{
                participantes: [0, 1],
                texto: Model.textosEmail[0].texto
              }],
              textosAbertura:
              [{
                participantes: [0, 1],
                texto: Model.textosAbertura[0].texto
              }]
            })}
          >
            <Stack gap={'1rem'} direction={'row'}>
              <Radio value={'2'}>Gestor e colaborador</Radio>
              <Radio value={'0'}>Somente o gestor</Radio>
              <Radio value={'1'}>Somente o colaborador</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>

        <Checkbox
          onChange={() => onChange({ ...Model, visualizarResposta: !Model.visualizarResposta })}
          my={0}
          isChecked={Model.visualizarResposta}
        >O colaborador pode visualizar respostas do formulário</Checkbox>

        <FormControl>
          <FormLabel>Quem pode editar as respostas</FormLabel>
          <Flex gap={'1rem'}>
            <Checkbox
              isChecked={Model.edicaoResposta.includes(0)}
              onChange={() => onChangeEditarResposta(0)}
            >Gestor</Checkbox>
            <Checkbox
              isChecked={Model.edicaoResposta.includes(1)}
              onChange={() => onChangeEditarResposta(1)}
            >Colaborador</Checkbox>
          </Flex>
        </FormControl>

        <WhiteBox>
          <BoxMultiText
            titulo='Texto para o convite por e-mail'
            descricao='Esse texto será apresentado no e-mail ou notificação antes do respondente abrir a pesquisa.'
            model={Model.textosEmail}
            textoPadrao={textoPadraoEmail}
            onChange={(e) => onChange({ ...Model, textosEmail: e })}
            openModalTag={() => setModalTagIsOpen(true)}
            ocultarParticipantes={Model.tipoRespondente !== 2}
          />
        </WhiteBox>

        <WhiteBox>
          <BoxMultiText
            titulo='Texto de abertura antes do preenchimento'
            descricao='Esse texto será apresentado quando o respondente abrir o link antes de começar a responder a pesquisa, para ver exatamente como irá ficar, você pode pré-visualizar clicando no botão a baixo da caixa de edição de texto.'
            model={Model.textosAbertura}
            textoPadrao={textoPadraoAbertura}
            onChange={(e) => onChange({ ...Model, textosAbertura: e })}
            openModalTag={() => setModalTagIsOpen(true)}
            ocultarParticipantes={Model.tipoRespondente !== 2}
          />
        </WhiteBox>
      </Flex>
      <Flex justifyContent={'end'} gap='1rem'>
        <Button
          fontWeight={'500'}
          VarColor='Gray3'
          onClick={onVoltar}
        >Voltar</Button>

        <Button
          fontWeight={'500'}
          VarColor='Green2'
          rightIcon={<FaArrowRight />}
          onClick={InternalOnAvancar}
        >Avançar</Button>
      </Flex>
    </Flex>
  )
}

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'

import { useRef } from 'react'
import { Radar } from 'react-chartjs-2'
import { iGrafico } from '../../../../../interfaces'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

interface iRadarData {
  model: iGrafico
  stepSize: number
}

export const RadarChartJs: React.FC<iRadarData> = ({
  model,
  stepSize
}) => {
  function AbreviarNomesGrandes(): string[] {
    return model.competencias.map((e) => {
      if (e.length > 19) {
        return e.substring(0, 19) + '...'
      } else {
        return e
      }
    })
  }

  const canvasReference = useRef(null)

  const colors = [
    'rgb(0, 143, 251)',
    'rgb(0, 227, 150)',
    '#F2C94C',
    'rgb(255, 69, 96)',
    '#fe00f4',
    '#BB6BD9',
    '#219653',
    '#F2994A'
  ]

  const Shapes = ['circle', 'star', 'triangle', 'rectRot', 'rectRounded']

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16
          }
        }
      }
    },
    scales: {
      r: {
        min: 0,
        max: 10,
        ticks: {
          min: 0,
          max: 10,
          stepSize: stepSize,
          font: {
            size: 12
          },
          z: 1
        },
        pointLabels: {
          color: '#000',
          font: {
            size: 16
          }
        },

        angleLines: {
          color: '#bdbdbd'
        },

        grid: {
          color: '#bdbdbd'
        }
      }
    }
  }

  const data = {
    labels: AbreviarNomesGrandes(),
    datasets: model.dados.map((e, i) => {
      return {
        label: e.nome,
        data: e.medias,
        fill: false,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: model.dados[1].nome.includes('Média sem autoavaliação') && i === model.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        backgroundColor: model.dados[1].nome.includes('Média sem autoavaliação') && i === model.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        borderColor: model.dados[1].nome.includes('Média sem autoavaliação') && i === model.dados.length - 1 ? colors[colors.length - 1] : colors[i],
        pointRadius: i === 0 ? 4 : 6,
        pointStyle: Shapes[i]
      }
    })
  }

  const plugin = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart: any) => {
      const { ctx } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  return (
    <Radar
      options={options}
      data={data}
      ref={canvasReference}
      plugins={[plugin]}
    />
  )
}

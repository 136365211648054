import { Box, Flex, Img } from '@chakra-ui/react'
import { iResultadoIndividualIndicativa } from '../../..'
import IconLocation from './assets/iconLocation.svg'
import { BoxPesquisa } from './components/Box'

interface iProps {
  model: iResultadoIndividualIndicativa
}

export const TabHistorico: React.FC<iProps> = ({ model }) => {
  if (model?.itemHistorico.length > 0) {
    return (
            <div>
                {model?.itemHistorico?.map((e, i) => {
                  if (((i + 2) % 2) === 0) {
                    return (
                            <Flex m={'0 2rem'} mb={'.325rem'}>
                                  <Box
                                    display={'flex'}
                                    alignItems={'flex-start'}
                                    justifyContent={'flex-end'}
                                    w={'44%'}
                                    fontWeight={'700'}
                                    pt={'.2rem'}
                                >
                                    {e.data}
                                  </Box>
                                  <Box w={'5%'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'.5rem'}>
                                    <Box
                                        m={'0'}
                                        p={'.2rem'}
                                        w={'2rem'}
                                        h={'2rem'}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        bgColor={'var(--Azul)'} borderRadius={'50%'}
                                        color={'#fff'}
                                    >
                                        <Img src={IconLocation} />
                                    </Box>
                                    <Box height={'200px'} borderRadius={'100px'} bgColor={'#D9D9D9'} w={'.5rem'}></Box>
                                  </Box>
                                <BoxPesquisa model={e} />
                            </Flex>
                    )
                  } else {
                    return (
                            <Flex m={'0 2rem'}>
                                <BoxPesquisa model={e} />
                                <Box w={'5%'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'.5rem'}>
                                    <Box m={'0'} p={'.2rem'} w={'2rem'} h={'2rem'} d={'flex'} justifyContent={'center'} alignItems={'center'} bgColor={'var(--Azul)'} borderRadius={'50%'} color={'#fff'}>
                                        <Img src={IconLocation} />
                                    </Box>
                                    <Box height={'200px'} borderRadius={'100px'} bgColor={'#D9D9D9'} w={'.5rem'}></Box>
                                </Box>
                                <Box
                                  display={'flex'}
                                  alignItems={'flex-start'}
                                  justifyContent={'flex-start'}
                                  w={'45%'}
                                  fontWeight={'700'}
                                  pt={'.2rem'}
                                >
                                  {e.data}
                                </Box>
                            </Flex>
                    )
                  }
                })}
            </div>
    )
  } else {
    return (
            <Flex>
                <h4>Não há outras avaliações</h4>
            </Flex>
    )
  }
}

/* eslint-disable no-return-assign */
import { Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter, FaSearch } from 'react-icons/fa'
import { Button } from '../../../../components/Button'
import { ImgSah } from '../../../../components/ImgSah'
import { ModalDelete } from '../../../../components/ModalDelete'
import { Paginador } from '../../../../components/Paginador'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iPesquisaNps } from '../../../../interfaces'
import { SigahApi, urlSigah } from '../../../../services/appApi'
import { ObterToken } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { CardGestao } from './Components/CardGestao'

export const GestaoEnpsJornada: React.FC = () => {
  const [Pesquisas, setPesquisas] = useState<iPesquisaNps[]>([])
  const [TxtPesquisa, setTxtPesquisa] = useState('')

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalEncerrarIsOpen, setModalEncerrarIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [Ordenacao, setOrdenacao] = useState('0')
  const [TipoPesquisa, setTipoPesquisa] = useState('')

  function getPesquisas(): void {
    setIsLoading(true)
    SigahApi.get(`api/Onboard/eNps/Gestao/?busca=${TxtPesquisa}&tipoPesquisa=${TipoPesquisa}&tamanho=${Tamanho}&pagina=${Pagina}&ordenador=${Ordenacao}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        setPesquisas(res.data)
        getQuantidadePesquisas()
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function getQuantidadePesquisas(): void {
    setIsLoading(true)
    SigahApi.get(`api/Onboard/eNps/Gestao/Quantidade/?busca=${TxtPesquisa}&tipoPesquisa=${TipoPesquisa}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        setQtd(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onOpenModalEncerrar(id: string): void {
    setModalEncerrarIsOpen(true)
    setId(id)
  }

  function onCloseModalEncerrar(): void {
    setModalEncerrarIsOpen(false)
    setId(undefined)
  }

  function EncerrarPesquisa(): void {
    SigahApi.post(`api/Onboard/eNps/Encerrar/${Id ?? ''}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function ExcluirPesquisa(): void {
    SigahApi.delete(`api/Onboard/eNps/${Id ?? ''}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPesquisas()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta pesquisa?'
        onRequestClose={onCloseModalDelete}
        onConfirm={ExcluirPesquisa}
      />

      <ModalDelete
        isOpen={ModalEncerrarIsOpen}
        message='Deseja realmente encerrar esta pesquisa?'
        onRequestClose={onCloseModalEncerrar}
        onConfirm={EncerrarPesquisa}
      />

      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Jornada {'>'} Pesquisa eNPS</h1>
          <Button
            size={'lg'}
            VarColor='Green2'
            borderRadius={'12px'}
            _hover={{ bg: 'var(--Azul)' }}
            onClick={() => window.location.href = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}&ct=PesquisaPulse&at=WizNps`}
          >
            Criar nova pesquisa eNPS
          </Button>
        </Flex>
        <Flex margin={'2rem 0'} justifyContent={'space-between'} alignItems={'end'}>
          <Flex gap={'1rem'} width='40%'>
            <FormControl>
              <FormLabel fontSize={'xl'}>Tipo da pesquisa</FormLabel>
              <Select value={TipoPesquisa} onChange={(e) => setTipoPesquisa(e.target.value)} borderColor={'var(--Gray4)'} size='md'>
                <option value={''}>Todas</option>
                <option value={'0'}>Normal</option>
                <option value={'1'}>Onboarding</option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize={'xl'}>Ordenação</FormLabel>
              <Select value={Ordenacao} onChange={(e) => {
                setOrdenacao(e.target.value)
              }} borderColor={'var(--Gray4)'} size='md'>
                <option value={'0'}>Pesquisa mais recente</option>
                <option value={'1'}>Pesquisa mais antiga</option>
                <option value={'2'}>A-Z</option>
                <option value={'3'}>Z-A</option>
              </Select>
            </FormControl>
          </Flex>
          <Flex w={'32%'} gap={'.75rem'} justifyContent={'end'}>
            <InputGroup width={'20rem'}>
              <InputLeftElement
                pointerEvents='none'
                children={<FaSearch color='var(--Gray4)' />}
              />
              <Input
                onChange={(e) => {
                  setTxtPesquisa(e.target.value)
                  if (TipoPesquisa !== '') {
                    setTipoPesquisa('')
                  }
                }}
                borderColor={'var(--Gray4)'}
                type='text'
                placeholder='Pesquisa'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    getPesquisas()
                  }
                }}
              />
            </InputGroup>

          <Flex justifyContent='end' alignItems='center'>
            <Button VarColor='a1' rightIcon={<FaFilter />} onClick={() => { getPesquisas() }}>Filtrar</Button>
          </Flex>
        </Flex>
        </Flex>

        <Paginador
          next={(e) => setPagina(e)}
          prev={(e) => setPagina(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          paginaAtual={Pagina}
          qtdElementos={Qtd}
          hideTop={true}
        >
          <Flex display={'grid'} gap={'2rem 0rem'}>
            {
              Pesquisas.map((e, i) => {
                return (
                  <CardGestao
                    key={i}
                    pesquisa={e}
                    onDelete={onOpenModalDelete}
                    encerrarPesquisa={onOpenModalEncerrar}
                  />
                )
              })
            }
          </Flex>
        </Paginador>
        {
          (Pesquisas.length === 0 && !IsLoading) && (
            <ImgSah entidade='pesquisa'>
              <Button
                size={'lg'}
                VarColor='Green2'
                borderRadius={'12px'}
                _hover={{ bg: 'var(--Azul)' }}
                onClick={() => window.location.href = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}&ct=PesquisaPulse&at=WizNps`}
              >
                Criar nova pesquisa eNPS
              </Button>
            </ImgSah>
          )
        }
      </WhiteBox>
    </Body>
  )
}

import styled from 'styled-components'

export const BoxLayout = styled.div`
width: 2rem;
height: 100%;
background: var(--c4);
`

export const GroupLayout = styled.div`
height: 4rem;
width: 4.5rem;
display: flex;
gap: .5rem;
`

export const ContainerLayout = styled.div<{ isHover: boolean, isSelecionado: boolean }>`
padding: 1rem;
border: 1px solid var(--c4);
border-radius: .25rem;
${props => {
  if (props.isSelecionado) {
    return `
    border-color: var(--DegradeAzul);
    cursor: pointer;
    .boxLayout{
      background: var(--DegradeAzul);
    }
  `
  } else if (props.isHover) {
    return `
    border-color: var(--Azul);
    cursor: pointer;
    .boxLayout{
      background: var(--Azul);
    }
  `
}
}}
`

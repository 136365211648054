import styled from 'styled-components'
import { Column } from '../../styles/styledGlobal'

export const DashBox = styled.div`
margin-bottom: 1rem;
border:0.063rem dashed var(--${props => props.theme});
padding: 0.5rem;
border-radius: .25rem;

.DashTitle{
font-weight: bold;
font-size: 1rem;
margin-bottom:.25rem;
}

.PartSubtitle{
    font-weight: bold;
    font-size: 0.875rem;
    transform:rotate(90deg)
}
`

export const CardParticipante = styled.div`
border-radius: .25rem;
display: flex;
align-items: center;
justify-content: space-between;
width: fit-content;
background: var(--c2);
border: 0.125rem solid var(--v3);
width: 10.8rem;
padding: 0.5rem;
`

export const ImgAssociado = styled.img`
width:2.5rem;
height:2.5rem;
border-radius: .25rem;
`

export const MainColumn = styled(Column)<{padding: string, width: string, margin?: string}>`
padding: ${props => props.padding};
margin: ${props => props.margin};
width: ${props => props.width};
box-shadow: var(--SombraBackground);
background: white;
`

export const ColumnTexts = styled(Column)`
display: flex;
flex-direction: column;
gap: .125rem;
span{
    font-size: .875rem;
}
`

export const ImgPerfil = styled.label`
cursor: pointer;
&:hover {
    filter: brightness(0.5);
}

input[type=file]{
    display: none;
}
`

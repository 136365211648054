import { Flex, Img } from '@chakra-ui/react'
import { FaEye } from 'react-icons/fa'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { iCompetencia } from '../../../../../interfaces'
import { TableDiv } from './styles'

interface iProps {
  competencias: iCompetencia[]
  onView: (e: iCompetencia) => void
}

export const Tabela: React.FC<iProps> = ({ competencias, onView }) => {
  return (
    <Flex width={'100%'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>
                <Flex alignItems='center' gap={'.5rem'}>
                    Competências
                </Flex>
              </th>
            </tr>
          </thead>
          <tbody>
            {competencias.map((e, i) => {
              return (
                <tr key={i}>
                  <td style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Flex width={'100%'} alignItems='center' justifyContent={'start'} gap={'.5rem'} fontWeight={'700'}>
                      <Img src={e.icone} objectFit={'contain'} minW={'2.5rem'} h={'2.5rem'}/>
                      {e.nome}
                    </Flex>
                    <Flex alignItems='center' justifyContent={'end'}>
                      <ElementoTooltipCmp label={'Visualizar questões'}>
                        <Flex cursor={'pointer'} alignItems={'center'} justifyContent={'center'} color={'var(--Azul)'} _hover={{ color: 'var(--Azul-hover)' }}onClick={() => onView(e)}><FaEye size={18}/></Flex>
                      </ElementoTooltipCmp>
                    </Flex>
                  </td>
                </tr>
              )
            })}
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

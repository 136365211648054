
import { HTMLAttributes } from 'react'
import { Pesquisaimg, PesquisaimgWhite } from '../../Svg/Pesquisaimg'
import { ButtonOption } from '../ButtonOption'
import { Chartimg, ChartimgWhite } from '../../Svg/Chartimg'
import { Interpriseimg, InterpriseimgWhite } from '../../Svg/Interpriseimg'
import { iPendencia } from '../../../../../interfaces'

interface ButtomProps extends HTMLAttributes<HTMLDivElement> {
  pendencia: iPendencia
  clickExterno?: () => void
}

export const ButtomInicial: React.FC<ButtomProps> = ({ pendencia, clickExterno }) => {
  function ObterIconePeloTitulo(): any {
    if (pendencia.modulo === 'Jornada') {
      return <Pesquisaimg />
    } else if (pendencia.modulo === 'Desempenho') {
      return <Chartimg />
    } else if (pendencia.modulo === 'Engajamento') {
      return <Interpriseimg />
    }
  }

  function ObterHoverIcon(): any {
    if (pendencia.modulo === 'Jornada') {
      return < PesquisaimgWhite />
    } else if (pendencia.modulo === 'Desempenho') {
      return <ChartimgWhite />
    } else if (pendencia.modulo === 'Engajamento') {
      return <InterpriseimgWhite />
    }
  }

  return (
    <ButtonOption
      Titulo={pendencia.modulo}
      Subtitulo={pendencia.nomePendencia}
      Icon={ObterIconePeloTitulo()}
      HoverIcon={ObterHoverIcon()}
      onClick={() => pendencia.nomePendencia === 'Trilhas em andamento' && clickExterno !== undefined ? clickExterno() : pendencia.link ? window.open(pendencia.link, '_blank') : ''}
    />
  )
}

/* eslint-disable no-return-assign */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, Text, Box, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { WhiteContainer } from '../../../../../components/WhiteContainer'
import { Body } from '../../../../Layouts/Body'
import { BoxAzul, OutlineListaContainer } from './styles'
import { FaFilter, FaPeopleArrows } from 'react-icons/fa'
import { ReuniaoOneChart } from './Components/PieChart'
import { CardProximaReuniao } from './Components/CardProximaReuniao'
import { appApi, urlApi } from '../../../../../services/appApi'
import { ObterToken } from '../../../../../Utils/Helper'
import { ModalReagendar } from '../../Assunto/components/ModalReagendar'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { TabListaPorReuniao } from '../Corporativo/Components/TabListaPorReuniao'
import { CsvButton } from '../../../../../components/Buttons/CsvButton'
import { parseJwt } from '../../../../../services/token'
import { DashboardSwitch } from '../../../../../components/DashboardSwitch'
import { iAvatarXInformacoes } from '../../../../../interfaces'
import { Paginador } from '../../../../../components/Paginador'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { SearchCmp } from '../../../../../components/SearchCmp'
import { iModalAgendamentoInput } from '../../Assunto'

interface iModel {
  principal?: iPrincipal
  tabela: iLinhaTabela[]
}

export interface iPrincipal {
  id: string
  nomeParticipante: string
  avatar: string
  dataInicio: string
  diaSemana: string
  assunto: string
}

export interface iLinhaTabela {
  id: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  tipo: string
  assunto: string
  status: number
  dataInicio: string
  dataLimiteInicio?: string
  dataLimiteFim?: string
  isEtapa?: boolean
}

interface iFiltro {
  search: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardIndividualReuniaoOne: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iModel>({
    tabela: []
  })
  const [ModelSemFiltros, setModelSemFiltros] = useState<iModel>({
    tabela: []
  })

  const [Filtro, setFiltro] = useState<iFiltro>({
    dataFim: '',
    dataInicio: '',
    search: '',
    status: ''
  })
  const [IsLoading, setIsLoading] = useState(false)
  const [Ordenador, setOrdenador] = useState({ filtro: 0, ascendente: false })
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [Reuniao, setReuniao] = useState<iLinhaTabela>()
  const [ReagendarIsOpen, setReagendarIsOpen] = useState(false)
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    setIsLoading(true)
    appApi.get(`ReuniaoOne/Dashboard/Individual/?busca=${Filtro.search}&dataFim=${Filtro.dataFim}&dataInicio=${Filtro.dataInicio}&status=${Filtro.status}&tamanho=${Tamanho}&pagina=${Pagina}&ordenador=${Ordenador.filtro}&ascendente=${Ordenador.ascendente}`)
      .then(res => {
        if (res.data?.tabela?.length > 0 || Pagina === 0) {
          setModel({ principal: res.data.principal, tabela: res.status === 204 ? [] : res.data.tabela })
          CarregarQuantidade()
        } else {
          setPagina(0)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function CarregarSemFiltros(): void {
    setIsLoading(true)
    appApi.get('ReuniaoOne/Dashboard/Individual/?busca=&dataFim=&dataInicio=&status=')
      .then(res => {
        if (res.status === 200) {
          setModelSemFiltros(res.data)
        } else {
          setModelSemFiltros({ tabela: [] })
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function CarregarQuantidade(): void {
    setIsLoading(true)
    appApi.get(`ReuniaoOne/Dashboard/Individual/Quantidade/?busca=${Filtro.search}&dataFim=${Filtro.dataFim}&dataInicio=${Filtro.dataInicio}&status=${Filtro.status}`)
      .then(res => {
        setQtd(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`ReuniaoOne/${id}/ReenviarNotificacao`)
      .then(() => toast({
        title: 'Notificação reenviada com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${Reuniao?.id ?? ''}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setReagendarIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    setIsLoading(true)
    appApi.delete(`ReuniaoOne/${Reuniao?.id ?? ''}`)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Reunião excluida com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        CarregarSemFiltros()
        Carregar()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  function onOpenReagendar(reuniao: iLinhaTabela): void {
    setReagendarIsOpen(true)
    setReuniao(reuniao)
  }

  function onCloseReagendar(): void {
    setReagendarIsOpen(false)
    setReuniao(undefined)
  }

  function onOpenDelete(reuniao: iLinhaTabela): void {
    setDeletarIsOpen(true)
    setReuniao(reuniao)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setReuniao(undefined)
  }

  useEffect(() => {
    CarregarSemFiltros()
  }, [])

  useEffect(() => {
    Carregar()
  }, [Pagina, Tamanho, Ordenador])

  return (
    <Body isLoading={IsLoading}>
      {ReagendarIsOpen &&
        <ModalReagendar
          alvo={Reuniao?.convidado ?? {
            avatar: '',
            id: '',
            info1: ''
          }}
          onClose={onCloseReagendar}
          onReagendar={ReagendarReuniao}
          max={Reuniao?.dataLimiteFim}
          min={Reuniao?.dataLimiteInicio}
        />
      }

			{DeletarIsOpen &&
				<ModalDelete
					isOpen
					onConfirm={Deletar}
					onRequestClose={onCloseDelete}
					message={Reuniao?.isEtapa ? 'Ao excluir essa reunião, também será excluído a etapa na qual ela está vinculada, deseja realmente excluir essa reunião?' : 'Deseja realmente excluir essa reunião 1:1?'}
				/>
			}

      <WhiteContainer>
        <Flex mb={'1rem'} justifyContent={'space-between'}>
          <h1>Reunião 1:1</h1>
          <ButtonCmp onClick={() => nav(`${pathname.replace('Dashboard/Individual', 'Criar')}`)} VarColor='Green2'>Solicitar reunião 1:1</ButtonCmp>
        </Flex>
        {(parseJwt().adm === 'True' || parseJwt().role === '2') &&
          <DashboardSwitch />
        }
        <Flex justifyContent={'space-between'} mt='1rem' maxH={'9.5rem'} gap={'1rem'}>
          <CardProximaReuniao model={ModelSemFiltros.principal} />
          <Box borderRadius={'.25rem'} border={'2px solid var(--Azul)'}>
            <Flex alignItems={'center'} borderRadius={'1rem'} justifyContent={'center'} gap={'1rem'} backgroundColor={'#fff'} padding={'1rem'} overflow={'hidden'}>
              {!IsLoading && <ReuniaoOneChart
                id={'i-chart'}
                model={{
                  aguardando: ModelSemFiltros.tabela.filter(e => e.status === 0).length,
                  aceita: ModelSemFiltros.tabela.filter(e => e.status === 1).length,
                  recusada: ModelSemFiltros.tabela.filter(e => e.status === 2).length,
                  reagendada: ModelSemFiltros.tabela.filter(e => e.status === 3).length,
                  atrasada: ModelSemFiltros.tabela.filter(e => e.status === 4).length,
                  finalizada: ModelSemFiltros.tabela.filter(e => e.status === 5).length
                }} />}
            </Flex>
          </Box>
          <BoxAzul>
            <Flex alignItems={'center'} justifyContent={'center'} gap={'1rem'} px={'2.25rem'}>
              <FaPeopleArrows size={34} />
              <Box>
                <Text fontSize={'1.5rem'} lineHeight={'1.75rem'} fontWeight={'700'}>{ModelSemFiltros.tabela.filter(e => e.status === 5).length}</Text>
                <Text fontSize={'1rem'} lineHeight={'1.25rem'} fontWeight={'400'}>Realizadas</Text>
              </Box>
            </Flex>
          </BoxAzul>
        </Flex>
        <Flex gap={'2rem'} alignItems={'flex-end'} mt={'1rem'}>
          <FormControl>
            <SelectCmp title='Status' OnChange={(e) => setFiltro({ ...Filtro, status: e })}>
              <option value="">Todos</option>
              <option value="0">Aguardando resposta</option>
              <option value="1">Aceita</option>
              <option value="2">Recusada</option>
              <option value="3">Reagendar</option>
              <option value="4">Atrasada</option>
              <option value="5">Finalizada</option>
            </SelectCmp>
          </FormControl>
          <FormControl>
            <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Periodo</FormLabel>
            <Flex alignItems={'center'} gap={'.75rem'} fontSize='.875rem'>
              De: <InputCmp OnChange={(e) => setFiltro({ ...Filtro, dataInicio: e })} type={'date'} max={'2099-12-31T23:59'}/>
              Até: <InputCmp OnChange={(e) => setFiltro({ ...Filtro, dataFim: e })} type={'date'} max={'2099-12-31T23:59'}/>
            </Flex>
          </FormControl>
          <Flex minW={'23rem'} gap={'.75rem'} justifyContent={'end'}>
            <SearchCmp
              EnterAction={Carregar}
              OnChange={(e) => setFiltro({ ...Filtro, search: e })}
              placeholder='Pesquisa'
            />

            <Flex justifyContent='end' alignItems='center'>
              <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { Carregar() }}>Filtrar</ButtonCmp>
            </Flex>
          </Flex>
        </Flex>
        <OutlineListaContainer>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <h2>Lista de Reuniões 1:1</h2>
            <CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Colaborador?token=${ObterToken()}`} />
          </Flex>
          <Paginador
            next={(e) => setPagina(e)}
            prev={(e) => setPagina(e)}
            tamanho={Tamanho}
            setTamanho={(e) => setTamanho(e)}
            paginaAtual={Pagina}
            qtdElementos={Qtd}
            hideTop={true}
          >
            <TabListaPorReuniao
              onReenviar={ReenviarConvite}
              onRemarcar={onOpenReagendar}
              onExcluir={onOpenDelete}
              reunioes={Model.tabela}
              individual={true}
              onOrdenar={(filtro, ascendente) => { setOrdenador({ filtro: filtro, ascendente: ascendente }) }}
            />
          </Paginador>
        </OutlineListaContainer>
      </WhiteContainer>
    </Body>
  )
}

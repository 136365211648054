import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iEtapaColaborador, iItemIntegracao } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { FaCheck } from 'react-icons/fa'
import { Flex, Text } from '@chakra-ui/layout'
import { MdClose } from 'react-icons/md'

interface iCard {
  etapa: iEtapaColaborador
  isOpen: boolean
  colabId: string
}

export const CardImersao: React.FC<iCard> = ({ etapa, isOpen, colabId }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterImersao(): void {
    appApi.get(`Imersao/${etapa.interacoes[0].idExterno}?colabId=${colabId}&etapaId=${etapa.id}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterImersao()
    }
  }, [isOpen])
  return (
    <Container>
      <h4>Lista de itens adicionados</h4>
      {
        Itens.map((e, i) => {
          const texto = e?.titulo ? `${e?.titulo}` : 'Sem assunto'
          return (
            <Flex key={i} flexDir={'column'}>
              <span >{i + 1}.  {texto}</span>
              {((e.checklist && e.checklist.length > 0 && !e.checklist.some(e => e.check)) && etapa.status !== 2)
                ? (
                  <Flex ml={4} mb={'2'} alignItems='center' >
                    <Text fontWeight={'700'} fontSize={'13px'}>Ainda não foi respondido</Text>
                  </Flex>
                  )
                : (
                  <Flex direction={'column'} as={'ul'} mb={'2'}>
                    {e.checklist?.map((r, i2) => {
                      return (
                        <Flex flexDir={'column'} ml={4} fontSize={'.875rem'}>
                          <Flex key={i2} alignItems='center' gap={'.25rem'}>
                            {r.check
                              ? <Flex alignItems={'center'} w={'.875rem'} h={'.875rem'}><FaCheck color='var(--Green2)' size='.875rem' /></Flex>
                              : <Flex alignItems={'center'} w={'.875rem'} h={'.875rem'}><MdClose color='var(--Red)' size='.875rem' /></Flex>}
                            <Text>{r.texto}</Text>
                          </Flex>
                          {(r.arquivo ?? r.comentario) && <Flex flexDir={'column'} gap={'.25rem'} mb={'.25rem'}>
                            {r.arquivo &&
                              <Text fontSize={'.813rem'}><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={r?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado.</Text>
                            }
                            {r.comentario &&
                              <Text fontSize={'.813rem'}>Comentário: <strong>{r.comentario}</strong></Text>
                            }
                          </Flex>}
                        </Flex>
                      )
                    })}
                  </Flex>
                  )
              }
            </Flex>
          )
        })
      }
      {/* <ButtonCmp VarColor='Green2' onClick={() => nav(`/Colaborador/Imersao/${etapa.interacoes[0].idExterno}/Etapa/${etapa.id}/Visualizar`)}>Visualizar imersão</ButtonCmp> */}
    </Container>
  )
}

import { Image, Text, View } from '@react-pdf/renderer'

interface iProps {
  nomePesquisa: string
  nomeAvaliado: string
  numeroPagina?: number
  sumario?: boolean
}

export const Footer: React.FC<iProps> = ({ nomeAvaliado, nomePesquisa, numeroPagina, sumario }) => {
  return (
        <div
            style={{
              height: sumario ? '19px' : '24px',
              alignItems: 'start',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              marginTop: '32px',
              padding: '0 32px'
            }}
        >
            <View>
                <Image
                    style={{
                      height: 35,
                      objectFit: 'contain'
                    }}
                    source={sumario ? 'https://sigah.blob.core.windows.net/onboard/icones/Logo AH_Logo Horizontal Colorido 1_69a2b721-6f00-4afe-9409-4b8c74e9f527.png' : 'https://sigah.blob.core.windows.net/onboard/icones/logo_ah_1 1 %282%29_af6c5027-91e1-4be6-b2fb-922b46b4b035.png'}
                />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center', marginBottom: '16px', height: '100%' }}>
                <Text style={{ fontSize: '11px', color: sumario ? 'white' : 'black' }}>{nomePesquisa} - {nomeAvaliado}</Text>
                {numeroPagina && <View style={{ marginLeft: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', backgroundColor: '#66046B', width: '20px', height: '20px', color: '#FFFFFF', fontSize: '13px' }}><Text>{numeroPagina}</Text></View>}
            </View>
        </div>
  )
}

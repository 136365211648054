import styled from 'styled-components'

export const WhiteModal = styled.div`
    width: 50rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    top: 30%;
    
    h3{
        text-align: center;
        margin-bottom: 1rem;
    }
    background: white;
    border-radius: 0.25rem;
    box-shadow: var(--SombraBtns);
 `

import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: .25rem;
padding: 1rem;
display: flex;
flex-direction: column;
justify-content:center;
box-shadow: var(--SombraBackground);
border: 2px solid var(--${props => props.theme}Opaco);
`

export const OutlineColumn = styled.div`
display: flex;
flex-direction:column;
padding: 0.375rem;
border: 2px solid var(--c3);
border-radius: 0.25rem;
text-align: center;
margin-right: .5rem;
color: var(--Azul);
height: fit-content;
h4 {
    font-weight: 600;
    color: black;
}
`

export const InfoPDI = styled.div`
min-width: 8rem;
text-align: center;
h2{
    color: var(--Azul);
}
p{
    font-size: .85rem;
    font-weight: 600;
}
`

export const StatusContainer = styled.div`
display: flex;
align-items: center;
gap: .75rem;
hr{
    margin: 0 1rem;
    height: 100%;
}

div:last-child{

strong:nth-child(1){
color: var(--Orange);
}

strong:nth-child(2){
color: var(--Green2);
}

strong:nth-child(3){
color: var(--Azul);
}
}
`

export const MediaGeralContainer = styled.div`
display: flex;
justify-content: center;
hr{
    margin: 0 1rem;
    height: 100%;
}
& > div:first-child{
    color: var(--Azul);
    
    span{
        font-weight: 500;
    }
}

& > div:last-child{
    color: var(--Gray2);
    span{
        font-weight: 500;
    }
}
`

export const BoxColor = styled.div`
display: flex;
justify-content: center;
color: var(--${props => props.theme});
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 700;
margin-bottom: .75rem;
`

export const TipoPesquisa = styled.strong`
    display: flex;
    align-items: center;
    gap: .3rem;
    font-size: .9rem;
    cursor: pointer;
    :hover {
        filter: brightness(120%);
        text-decoration: underline;
    }

    svg {
        margin-top: .2rem;
    }
`

/* eslint-disable no-return-assign */
import { Divider, Flex, Menu, MenuList, Text } from '@chakra-ui/react'
import {
  FaChartPie,
  FaCheckCircle, FaCog, FaCopy, FaEnvelope
  , FaEye, FaPen,
  FaRegStopCircle, FaUser, FaFileCsv, FaChevronDown, FaTrashAlt
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MenuButtonCmp } from '../../../../../../components/MenuButtonCmp'
import { StyledMenuItem } from '../../../../../../components/NavBar/Components/StyledMenuItem'
import { urlApi } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'
import { ObterToken } from '../../../../../../Utils/Helper'

interface iGestaoPesquisaInterna {
  id: string
  nomePesquisa: string
  dataInicio: string
  dataFim?: string
  ciclo: string
  statusPesquisa: string
  statusForm: string
  convites: number
  respostas: number
  anonima: boolean
  trilhaId: string
  nomeColaborador?: string
}

interface iCardProps {
  pesquisa: iGestaoPesquisaInterna
  onDelete: (id: string) => void
  onEncerrar: (id: string) => void
  onCopy: (id: string) => void
}

export const CardGestao: React.FC<iCardProps> = ({ pesquisa, onDelete, onEncerrar, onCopy }) => {
  const nav = useNavigate()
  return (
        <Flex borderLeft={`4px solid ${pesquisa.statusPesquisa === 'Não enviado' ? 'var(--Yellow)' : pesquisa.statusPesquisa === 'Finalizado' ? 'var(--Red)' : 'var(--Green3)'}`} flexDir={'column'} padding={'.75rem 1rem'} borderRadius={'4px'} boxShadow={'var(--SombraBackground)'} bg={'var(--c1)'}>
            <Flex gap={'.75rem'} fontSize={'.875rem'} lineHeight={'.875rem'} alignItems={'center'}>
                <Flex flex={2} maxW={'25%'} minW={'25%'} flexDir={'column'} gap={'.25rem'}>
                    <Text fontWeight={'700'} fontSize={'1rem'}>{pesquisa.nomePesquisa}</Text>
                    <Text fontWeight={'400'} fontSize={'.813rem'}>Status: <Text as={'span'} fontWeight={'700'} color={pesquisa.statusPesquisa === 'Não enviado' ? 'var(--Yellow)' : pesquisa.statusPesquisa === 'Finalizado' ? 'var(--Red)' : 'var(--Green3)'}>{pesquisa.statusPesquisa}</Text></Text>
                    {pesquisa.nomeColaborador && <Text color={'var(--Gray3)'} fontWeight={'400'} fontSize={'.813rem'} lineHeight={'.8rem'}>Pesquisa da trilha do(a) <strong>{pesquisa.nomeColaborador}</strong></Text>}
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--c6)'} mr={'1rem'} borderWidth={'1px'} h={'40px'}/>
                <Flex flexDir={'column'} flex={1} minW={'11rem'} maxW={'11rem'}>
                    <span>Início: </span>
                    <strong>{pesquisa.dataInicio || 'Pesquisa não configurada'}</strong>
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--c6)'} mr={'1rem'} borderWidth={'1px'} h={'40px'}/>
                <Flex flexDir={'column'} flex={1}>
                    <span>Fim: </span>
                    <strong>{pesquisa.dataFim ?? 'Indefinido'}</strong>
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--c6)'} mr={'1rem'} borderWidth={'1px'} h={'40px'}/>
                <Flex flexDir={'column'} flex={1}>
                    <span>Ciclo:</span>
                    <strong>{pesquisa.ciclo}</strong>
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--c6)'} mr={'1rem'} borderWidth={'1px'} h={'40px'}/>
                <Flex flexDir={'column'} flex={1} justifyContent={'center'} gap={'.25rem'}>
                    <Flex fontWeight={'400'} color={'black'} gap={'.325rem'}>
                        <FaUser color='var(--Gray2)'/>
                        <span>Convites: <strong>{pesquisa.convites}</strong></span>
                    </Flex>
                    <Flex fontWeight={'400'} color={'black'} gap={'.325rem'}>
                        <FaCheckCircle color='var(--Azul)'/>
                        <span>Respostas: <strong>{pesquisa.respostas}</strong></span>
                    </Flex>
                </Flex>
                <Menu>
                    <MenuButtonCmp
                        Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                        VarColor='Rosa'
                    >Opções
                    </MenuButtonCmp>
                    <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>

                        {
                            (pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa !== 'Finalizado') && (
                                <StyledMenuItem onClick={pesquisa.trilhaId === null
                                  ? () => nav(`/Jornada/PesquisaInterna/Convites/${pesquisa.id}`)
                                  : () => nav(`/Jornada/Configuracao/Trilha/${pesquisa.trilhaId}/PesquisaInterna/Convites/${pesquisa.id}`)}>
                                    <FaEnvelope style={{ marginRight: '.375rem' }} size={'14'}/>
                                            Gestão de envios
                                </StyledMenuItem>
                            )
                        }
                        {
                            (pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa !== 'Finalizado') && (
                            <StyledMenuItem onClick={() => nav(`/Jornada/PesquisaInterna/Configuracao/${pesquisa.id}`)}>
                                <FaCog style={{ marginRight: '.375rem' }} size={'14'}/>
                                Configurar
                            </StyledMenuItem>
                            )
                        }
                        {
                            (((!pesquisa.anonima && pesquisa.respostas > 0) || (pesquisa.anonima && pesquisa.respostas >= 5)) || (pesquisa.trilhaId && pesquisa.respostas > 0 && pesquisa.statusPesquisa === 'Em andamento')) &&
                            <StyledMenuItem onClick={() => nav(`/Jornada/PesquisaInterna/Resultado/${pesquisa.id}`)}>
                                <FaChartPie style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Resultado
                            </StyledMenuItem>
                        }
                        {
                        (parseJwt().adm === 'True' && (((!pesquisa.anonima && pesquisa.respostas > 0) || (pesquisa.anonima && pesquisa.respostas >= 5)) || (pesquisa.trilhaId && pesquisa.respostas > 0 && pesquisa.statusPesquisa === 'Em andamento'))) &&
                            <StyledMenuItem onClick={() => window.location.href = `${urlApi}/PesquisaInterna/Csv?pesquisaId=${pesquisa.id}&token=${ObterToken()}`}>
                                <FaFileCsv style={{ marginRight: '.375rem' }} size={'14'}/>
                                Baixar base
                            </StyledMenuItem>
                        }
                        {
                            (pesquisa.trilhaId === null && pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa === 'Em andamento') &&
                            <StyledMenuItem onClick={() => onEncerrar(pesquisa.id)}>
                                <FaRegStopCircle style={{ marginRight: '.375rem' }} size={'14'}/>
                                Encerrar pesquisa
                            </StyledMenuItem>
                        }
                        <StyledMenuItem onClick={() => onCopy(pesquisa.id)}>
                            <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                            Duplicar pesquisa
                        </StyledMenuItem>
                        {
                            pesquisa.statusForm !== 'Aprovado' &&
                            <StyledMenuItem onClick={() => nav(`/Jornada/PesquisaInterna/Formulario/${pesquisa.id}`)}>
                                <FaPen style={{ marginRight: '.375rem' }} size={'14'}/>
                                Editar pesquisa
                            </StyledMenuItem>
                        }
                        {
                            (pesquisa.statusForm === 'Aprovado' && pesquisa.dataInicio !== null) && (
                                <StyledMenuItem onClick={() => nav(`${pesquisa.id}/Previsualizar`)}>
                                    <FaEye style={{ marginRight: '.375rem' }} size={'14'}/>
                                    Pré-visualizar formulário
                                </StyledMenuItem>
                            )
                        }
                        {
                            pesquisa.trilhaId === null && pesquisa.statusPesquisa !== 'Finalizado' &&
                            <StyledMenuItem onClick={() => onDelete(pesquisa.id)}>
                                <FaTrashAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                                Excluir
                            </StyledMenuItem>
                        }
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
  )
}

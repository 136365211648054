/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'

interface iPieChartData{
  nome: string
  series: string[]
  labels: string[]
}

interface BarChartProps {
  id: string
  data: iPieChartData
}

export const BarChart: React.FC<BarChartProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: [{
        data: data.series
      }],
      chart: {
        type: 'bar',
        height: data.series.length * 40 > 80 ? data.series.length * 40 : 80,
        width: 1000
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (seriesName: any) => seriesName,
          title: {
            formatter: () => ''
          }
        }
      },
      grid: {
        show: false
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -20
      },
      xaxis: {
        labels: {
          show: false
        },
        categories: data.labels,
        title: {
          style: {
            color: '#000',
            fontSize: '20px',
            fontWeight: 600
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
      <Flex id={`chart-${id}`} />
  )
}

import { Box, Button, Flex, HStack, Spinner, Text } from '@chakra-ui/react'
import { CardColaborador } from './components/CardColaborador'
// import imgPerfil from '../../../../assets/pi/perfil.png'
import { IJobTarget } from '../../JobTarget/Create'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { formatarData } from '../TabelaPerfis'
import { numberFormatter } from '../../Dashboard'
import { urlApi } from '../../../../services/appApi'
import { useEffect, useState } from 'react'
interface ITabelaAderencia{
  titulo: String
  job?: IJobPerfis
  paginaAtual: number
  numeroPaginas: number
  isLoading: boolean
  next: VoidFunction
  prev: VoidFunction
}

interface IperfilBa{
  assessmentId?: string
  id?: string
  nome: string
  dataPesquisa: string
  perfil: string
  urlImgPerfil?: string
  urlImgSelo?: string
  selo?: string
}
interface IperfilDto{
  perfil: IperfilBa
  outrosCargos: IOutrosCargos[]
}

interface IOutrosCargos{
  nome: string
  jobId: string
}
interface IJobPerfis{
  jobTarget: IJobTarget
  numeroPerfis: number
  numeroPerfisAdaptativos: number
  numeroPerfisAderentes: number
  numeroPerfisNaoAderentes: number
  perfis: IperfilDto[]

}

export const TabelaAderencia: React.FC<ITabelaAderencia> = ({ titulo, job, numeroPaginas, paginaAtual, next, prev, isLoading }: ITabelaAderencia) => {
  function obterTipoPerfil(perfil: string): number {
    if (job?.jobTarget !== undefined) {
      if (job.jobTarget.perfisAderentes.includes(perfil)) return 0
      if (job.jobTarget.perfisAdaptativos.includes(perfil)) return 1
    }
    return 2
  }
  const [Ids, setIds] = useState<string[]>([])

  function selecionar(valor: string): void {
    if (Ids.includes(valor)) {
      setIds(Ids.filter(i => i !== valor))
    } else {
      setIds([...Ids, valor])
    }
  }

  // function selecionarTodes(valor: boolean): void {
  //   console.log('Passou')
  //   if (Todes) {
  //     setTodes(false)
  //     setIds([])
  //   } else {
  //     const job2 = job?.perfis.map(c => c.perfil.id ?? '') ?? []
  //     setIds(job2)
  //   }
  // }
  useEffect(() => {
    console.log(Ids)
  }, [Ids])

  function baixarPerfis(): void {
    const link = document.createElement('a')
    link.download = 'arquivo.zip'
    link.href = `${urlApi}/AssessmentPI/ArquivosPerfil/?idsArray=${Ids.toString()}`
    link.click()
  }

  return (
    <>
    <Flex mx='2rem' mt={0} mb={'1rem'} justifyContent='end'>
    {Ids.length > 0 && <Button onClick={() => baixarPerfis()}
    width={'8rem'} height='2rem' fontSize='small' borderRadius={'xl'} backgroundColor={'var(--Rosa)'}>Baixar {Ids.length} relatório{Ids.length > 1 ? 's' : ''}</Button>}

    {numeroPaginas > 1 &&

(<HStack mt={0} mx={5} borderTop='2px'
 borderLeft='2px' borderRight='2px' borderTopLeftRadius='2xl' borderTopRightRadius='2xl' p={2} borderColor='var(--c4)' backgroundColor='#fcfbf4'>
      <Text>Página:</Text>
      <Button size='xs' onClick={prev} bgColor='var(--a1)'><FaArrowLeft/></Button>
      <Text>{numberFormatter.format(paginaAtual + 1)} / {numberFormatter.format(numeroPaginas)}</Text>
      <Button size='xs' onClick={next} bgColor='var(--a1)'><FaArrowRight/></Button>

      </HStack>
)}
</Flex>
 <Box border='2px' borderRadius='8px' borderColor='var(--c4)' mb={4} mx={4} px={4}>

  <Flex flexDir='column'>

<Flex justifyContent='space-between' mt={0} p={4} color='var(--Gray3)'>
{/* <Checkbox checked={Todes} onChange={(e) => selecionarTodes(e.target.checked)} >
  {Todes && <Text fontSize='12px' width='12rem' textAlign='center'
fontWeight='bold'>{(Ids.length)}</Text>}
</Checkbox> */}
<Text fontSize='12px' width='12rem' textAlign='center'
fontWeight='bold'
>Colaborador</Text>
<Text fontSize='12px' width='7rem' textAlign='center'
fontWeight='bold'
>Data da pesquisa</Text>
<Text fontSize='12px' width='6rem' textAlign='center'
fontWeight='bold'
></Text>
<Text fontSize='12px' width='12rem' textAlign='start'
fontWeight='bold'
>Perfil</Text>
<Text fontSize='12px' width='8rem' textAlign='center'
fontWeight='bold'
>Aderência</Text>
<Text
fontSize='12px' width='12rem' textAlign='center'
fontWeight='bold'
>Outros cargos aderentes</Text>

</Flex>
<Flex flexDir='column' overflowY='auto' height='22rem'>
  <Box alignSelf='end'>
      {isLoading && (

        <HStack>
      <Text m={2}>Carregando perfis...</Text>
      <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='red.500'
  size='lg'
/>
</HStack>
      )}

  </Box>
<>
{job?.perfis.map((j, i) => {
  return (<CardColaborador
    assessmentId={j.perfil.assessmentId ?? ''}
    selecionado={Ids.includes(j.perfil.assessmentId ?? '')}
    onSelect={selecionar}
    key={j.perfil.id}
    id={j.perfil.id}
    imgPerfil={j.perfil.urlImgPerfil}
    avatar={''}
    cargos={j.outrosCargos}
    dataPesquisa={formatarData(j.perfil.dataPesquisa)}
    nome={j.perfil.nome}
    tipo={obterTipoPerfil(j.perfil.perfil)}
    perfil={j.perfil.perfil}
    par={i % 2 !== 0}
    selo={j.perfil.selo}
    urlSelo={j.perfil.urlImgSelo}
    />)
})}
  </>

  </Flex>
  </Flex>
  </Box>
  </>
  )
}

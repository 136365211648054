import { Container } from './styles'
import { HTMLAttributes, useState } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'

interface iProps extends HTMLAttributes<HTMLDivElement> {
  titulo: string
  descricao: string
  theme: string
}

export const Card: React.FC<iProps> = ({ descricao, titulo, theme, ...rest }) => {
  const [IsHover, setIsHover] = useState(false)
  return (
        <Container {...rest} theme={theme} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <h3>{titulo}</h3>
            <Box>
                <span>{descricao}</span>
            </Box>
            <Flex justifyContent={'center'} h={'100%'} alignItems={'end'}>
              <Button transition={'.3s ease'} background={'var(--Rosa)'} borderLeft={'1px solid var(--c1)'} borderBottom={'1px solid var(--c1)'} fontSize={'10px'} h={IsHover ? '1.75rem' : '0' } minW={IsHover ? '1.75rem' : '0' } maxW={IsHover ? '1.75rem' : '0' } padding={'0'} borderRadius={'0 .25rem 0 .5rem'} pos={'absolute'} top={'0'} right={'0'} color='white' _hover={{ background: 'var(--Rosa-hover)' }}>
                <FaPlus/>
              </Button>
            </Flex>
        </Container>
  )
}

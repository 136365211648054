import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const Nome = styled.span`
margin-bottom: .125rem;
font-size: .875rem;
line-height: .9125rem;
`

export const Sobrenome = styled.span`
color: var(--c7);
font-size: .813rem;
line-height: .813rem;
`

export const Container = styled(Flex)`
border-radius: 0.25rem;
padding: .325rem 0 .325rem 0;
color: black;
&:hover{
    filter: brightness(.9);
    cursor: pointer;
}
`

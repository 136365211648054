import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: 4px;
display: flex;
position: relative;
flex-direction: column;
border: 1px solid var(--${props => props.theme});
`

export const Subtitulo = styled.span`
font-weight: 700;
color: var(--Gray1);
`

export const CardPDI = styled.div<{width?: string}>`
display: flex;
align-items: center;
width: 20rem;

${({ width }) => width && `
    width:${width};
  `}
`
export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
margin: 0 0 0.5rem 0;
width: 100%;
background: var(--c2);
padding: .75rem 1rem .75rem 1rem;
width: 100%;
border-radius: 4px;
font-size: .875rem;
flex-direction: column;
gap: .25rem;
`
export const ComentarioContainer = styled.div`
  h3 {
    margin: 1rem 1rem 1rem;
    font-weight: normal;
    color: black;
    font-size: 1.5rem;
  }
  textarea {
    background: white;
    border-radius: 0.25rem;
    padding: 1rem 2rem;
    border: 1;
    border-color: var(--c4);
    outline: 0;
    display: flex;
    width: 100%;
    min-height: 8rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    color:black;
  }
`

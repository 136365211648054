import { iModalPadrao } from '../../../../../interfaces'
import { Container } from './styles'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalBase } from '../../../../../components/ModalBase'
import { Card } from './components/Card'
import { useAuth } from '../../../../../contexts/AuthContext'
interface iModalAdicionarEtapa extends iModalPadrao {
  ProcessoId?: string
  colabId?: string
  tipo?: number
}

type iParams = {
  trilhaId: string
}
export const ModalAdicionarEtapa: React.FC<iModalAdicionarEtapa> = ({ OnCloseModal, isOpen, colabId, ProcessoId, tipo }) => {
  const { Permissoes } = useAuth()
  const { trilhaId } = useParams<iParams>()
  const Navigate = useNavigate()

  const rota = trilhaId ? `Configuracao/Trilha/${trilhaId}` : `Processo/${colabId ?? ''}`

  return (
        <ModalBase isOpen={isOpen ?? false} onClose={OnCloseModal} Titulo='Escolha uma etapa para adicionar' Subtitulo='Clique em um dos cards abaixo para selecionar o tipo de etapa que deseja criar'>
            <Container style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                {tipo !== 3 &&
                    <>
                        <Card
                            titulo='Pesquisa'
                            descricao='Crie formulários personalizados e encaminhe para o colaborador ou para seu gestor'
                            onClick={() => Navigate(`/Jornada/${rota}/PesquisaInterna/Criar`)}
                            theme={'6DA0FF'}
                        />

                        <Card
                            titulo='Reunião'
                            descricao='Adicione uma reunião e o próprio sistema marcará com as pessoas selecionadas na configuração, podendo ser presencial ou online.'
                            onClick={() => Navigate(`/Jornada/${rota}/Reuniao`)}
                            theme={'00B496'}
                        />

                        {/* <Card
                            titulo='Reunião 1:1'
                            descricao='Adicione um reunião 1:1, com o gestor ou de acompanhamento com o RH, no tempo certo para os colaboradores.'
                            onClick={() => Navigate(`/Jornada/${rota}/ReuniaoOne/Configuracao`)}
                            theme={'365ad0'}
                        /> */}

                        <Card
                            titulo='Reunião 1:1'
                            descricao='Adicione um reunião 1:1, com o gestor ou de acompanhamento com o RH, no tempo certo para os colaboradores.'
                            onClick={() => Navigate(`/Jornada/${rota}/NovaReuniaoOne/Criar`)}
                            theme={'365ad0'}
                        />

                        <Card
                            titulo='Imersão cultural'
                            descricao='Configure como será realizada, podendo acrescentar materiais para o colaborador como apresentações, vídeos ou textos.'
                            onClick={() => Navigate(`/Jornada/${rota}/Imersao`)}
                            theme={'7879F1'}
                        />

                        {trilhaId &&
                            <Card
                                titulo='Pesquisa de percepção'
                                descricao='Envie uma pesquisa de percepção no momento que desejar durante a trilha do colaborador (Ex.: 45 dias, 90 dias, 360 dias, ...).'
                                onClick={() => Navigate(`/Jornada/Percepcao/Quantitativa/Criar/Trilha/${trilhaId}`)}
                                theme={'FF9900'}
                            />}

                        {ProcessoId &&
                            <Card
                                titulo='Pesquisa de percepção'
                                descricao='Envie uma pesquisa de percepção no momento que desejar durante a trilha do colaborador (Ex.: 45 dias, 90 dias, 360 dias, ...).'
                                onClick={() => Navigate(`/Jornada/Percepcao/Quantitativa/Criar/Processo/${ProcessoId}/${colabId as string}`)}
                                theme={'FF9900'}
                            />}

                        <Card
                            titulo='eNPS'
                            descricao='Crie uma nova etapa de acompanhamento de eNPS. Assim você pode entender a aceitação e realizar de melhorias para a jornada de seus colaboradores.'
                            onClick={() => Navigate(`/Jornada/${rota}/eNps/Criar`)}
                            theme={'6FCF97'}
                        />

                        {Permissoes.desempenho &&
                            <Card
                            titulo='PDI'
                            descricao='Adicione ações praticas, materiais de referência e treinamentos para o desenvolvimento das competências priorizadas em sua jornada.'
                            onClick={() => Navigate(`/Jornada/${rota}/PDI`)}
                            theme={'E18787'}
                        />}
                    </>
                }

                {tipo === 3 &&
                    <>
                        <Card
                            titulo='Pesquisa'
                            descricao='Crie formulários personalizados e encaminhe para o colaborador ou para seu gestor'
                            onClick={() => Navigate(`/Jornada/${rota}/PesquisaInterna/Criar`)}
                            theme={'6DA0FF'}
                        />

                        <Card
                            titulo='Reunião de desligamento'
                            descricao='Adicione uma reunião e o próprio sistema marcará com as pessoas selecionadas na configuração, podendo ser presencial ou online.'
                            onClick={() => Navigate(`/Jornada/${rota}/Reuniao`)}
                            theme={'00B496'}
                        />

                        <Card
                            titulo='eNPS'
                            descricao='Crie uma nova etapa de acompanhamento de eNPS. Assim você pode entender a aceitação e realizar de melhorias para a jornada de seus colaboradores.'
                            onClick={() => Navigate(`/Jornada/${rota}/eNps/Criar`)}
                            theme={'6FCF97'}
                        />
                    </>
                }
            </Container>
        </ModalBase>
  )
}

import { Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iProps {
  onClose: () => void
  onSave: (data: string) => void
  data?: string
  min?: string
}

export const ModalAgendar: React.FC<iProps> = ({ onClose, onSave, data, min }) => {
  const [Data, setData] = useState<string>()
  useEffect(() => {
    if (data) {
      setData(data)
    }
  }, [data])

  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Titulo="Agendar"
      Width='25rem'
      styleHeader={{ alignItems: 'center', display: 'flex' }}
    >
      <Flex pb={'1rem'} px={'1.5rem'} alignItems={'center'} justifyContent={'center'}>
        <InputCmp
          borderColor={'var(--Gray4)'}
          value={Data}
          width={'15rem'}
          type={'datetime-local'}
          OnChange={(data) => setData(data)}
          min={min?.replace('Z', '') ?? (new Date().toISOString().split('T')[0] + 'T00:00')}
          max={'2099-12-31T23:59'}
        />
      </Flex>
      <Flex justifyContent={'center'} gap='.5rem' pb={'1rem'}>
        <ButtonCmp VarColor='c4' onClick={onClose}>Cancelar</ButtonCmp>
        <ButtonCmp
          onClick={() => onSave(Data ?? '')}
          VarColor='Green2'
        >Confirmar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

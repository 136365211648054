
import { Button, MenuButton } from '@chakra-ui/react'

interface iButtonProps {
  VarColor?: string
  Icon?: any
  small?: boolean
}
export const MenuButtonCmp: React.FC<iButtonProps> = ({ VarColor, Icon, children, small }) => {
  return (
      <MenuButton
        px={'.75rem'}
        height={small ? '1.5rem' : '1.925rem'}
        borderRadius='.25rem'
        transition='all 0.1s'
        _hover={{ bg: `var(--${VarColor})`, color: 'white' }}
        border={`2px solid var(--${VarColor})`}
        fontSize={small ? '12px' : '14px'}
        bg={'var(--c1)'}
        color={`var(--${VarColor})`}
        as={Button}
        rightIcon={Icon}
        _focus={{ bg: 'none' }}
        _active={{ bg: `var(--${VarColor})`, color: 'white' }}
      >{children}
    </MenuButton>
  )
}

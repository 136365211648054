import styled from 'styled-components'

export const FileInput = styled.div`
background: var(--c1);
padding: .5rem;
margin-top: .5rem;
border-radius: .25rem;

input{
    font-size: 1rem;
}

label{
    padding: .5rem;
    background-color: var(--Green2);
    border-radius:.5rem;
    color: white;

    margin-right: 1rem;

    &:hover{
        filter: brightness(.9);
        cursor: pointer;
    }
}

span{
    color: var(--v3);
    font-weight: 500;
}
`

import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'var(--DegradeFundo)'
      }
    }
  },
  colors: {
    pink: {
      50: 'var(--Rosa)',
      100: 'var(--Rosa)',
      200: 'var(--Rosa)',
      300: 'var(--Rosa)',
      400: 'var(--Rosa)',
      500: 'var(--Rosa)', // Cor principal personalizada
      600: 'var(--Rosa)',
      700: 'var(--Rosa)',
      800: 'var(--Rosa)',
      900: 'var(--Rosa)'
    }
  }
})

import { Flex, Input, Text, useToast } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { ModalBody } from './styles'
import { appApi, urlApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  reload: () => void
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, isOpen, reload }) => {
  const { pesquisaId } = useParams<{pesquisaId: string}>()
  const toast = useToast()

  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState('')
  const [Erros, setErros] = useState<string[]>([])

  const [IsLoading, setIsLoading] = useState(false)

  const AtualizarArquivo = (arquivo: any): void => {
    const formdata = new FormData()
    formdata.append('arquivo', arquivo.target.files[0])
    setArquivo(formdata)
    setNome(arquivo.target.files[0].name)
    toast({
      title: 'Arquivo selecionado com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
  }

  function EnviarEmMassa(): void {
    setIsLoading(true)
    appApi.post(`PesquisaIndicativa/${pesquisaId ?? ''}/Csv`, Arquivo)
      .then(res => {
        toast({
          title: 'Avaliados cadastrados na pesquisa com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        if (res.data.length > 0) {
          setErros(res.data)
        } else {
          onRequestClose()
        }
        reload()
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  return (
    <ModalBase Titulo='Cadastrar participantes em massa' isOpen={isOpen} onClose={onRequestClose} Width='65rem'>
      <ModalBody>
        <span><strong>Instruções para gerar o arquivo no excel:</strong>
          Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
          Após isso, clique em "Salvar como..." e selecione o formato
          <b> CSV UTF-8 (Delimitado por vírgulas).</b>
          As colunas que estão marcadas com “*” são campos obrigatórios.
          Por favor, realize o preenchimento completo para correto envio.
          <small>Obrigatório a inserção do endereço de email</small>
        </span>

        <label htmlFor='Ifile'>{Arquivo ? Nome : 'Selecione um arquivo'}</label>
        <Input onChange={(e) => AtualizarArquivo(e)} id='Ifile' type={'file'} />
        {Erros?.length > 0 &&
          <Flex flexDir={'column'} width={'100%'} border={'1px solid var(--Red)'} borderRadius='1rem' mt={'1rem'} as={'ul'} padding='.5rem .5rem .5rem 1rem'>
            <Text textAlign={'center'} fontWeight='bold' mb={'.325rem'}>Erros na planilha</Text>
            <Flex flexWrap={'wrap'} gap={'.5rem'}>
              {Erros?.map((e) => {
                return (
                  <li style={{ whiteSpace: 'nowrap' }}>{e}</li>
                )
              })}
            </Flex>
          </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} px='1rem' gap={'.5rem'} my={'.75rem'}>
        <ButtonCmp
          onClick={() => { window.location.href = `${urlApi}/PesquisaIndicativa/Csv` }}
          VarColor='Rosa'
        >Baixar exemplo para preenchimento</ButtonCmp>

        <ButtonCmp
          isDisabled={PropInvalida(Arquivo)}
          onClick={EnviarEmMassa}
          VarColor='Green2'
          isLoading={IsLoading}
        >Enviar arquivo</ButtonCmp>

      </Flex>
    </ModalBase>
  )
}

import { Box, Text } from '@chakra-ui/react'

interface iProps {
  statusNome: string
  statusQuantidade: number
  VarColor: string
}

export const BoxReunioesStatus: React.FC<iProps> = ({ statusNome, statusQuantidade, VarColor }) => {
  return (
    <Box w={'100%'} borderRadius={'.325rem'} display={'flex'} py={'1rem'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} bg={'white'} border={`2px solid var(--${VarColor})`} fontWeight={'600'} boxShadow={'var(--SombraBackground)'}>
      <Text pt={'.25rem'} fontSize={'14px'} lineHeight={'1rem'}>{statusNome}</Text>
      <Text fontSize={'1.75rem'} lineHeight={'1.95rem'}>{statusQuantidade}</Text>
    </Box>
  )
}

import {
  ColumnNotas, EmployContainer, EmployeBody,
  EmployeHeader,
  PerfilContainer
} from './styles'
import {
  Avatar, CircularProgress,
  CircularProgressLabel, Flex, Text, useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { MdBlock } from 'react-icons/md'
import { useState, useEffect } from 'react'
import { appApi } from '../../../../../services/appApi'
import { converterData, PropInvalida, useQuery } from '../../../../../Utils/Helper'
import { FaClock, FaEdit, FaEye, FaSave, FaTrash } from 'react-icons/fa'
import { ColumnNotasLabel } from '../../../../../styles/styledGlobal'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iOnboard } from '../..'
import { parseJwt } from '../../../../../services/token'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../../components/InputCmp'

interface iCardProps {
  color: string
  processo: iOnboard
  onReload?: () => void
  onOpenModalDelete: (id: string) => void
  loading: boolean
}

export const CardFuncionario: React.FC<iCardProps> = ({ processo, color, onReload, onOpenModalDelete, loading }) => {
  const query = useQuery()
  const toast = useToast()
  const nav = useNavigate()
  const [Editar, setEditar] = useState(false)
  const [NovaData, setNovaData] = useState<string>()
  const [Model, setModel] = useState<iOnboard>({
    id: '',
    andamentoPercentual: 0,
    nomeTrilha: '',
    status: 0,
    colaborador: {
      avatar: '',
      email: '',
      id: '',
      nome: '',
      ativo: true
    }
  })

  useEffect(() => {
    setModel(processo)
  }, [processo])

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function ModoEditarOn(): void {
    setEditar(true)
    setNovaData(converterData(Model.colaborador.dataDesligamento ?? '').toISOString().split('T')[0])
  }

  function Salvar(): void {
    appApi.patch(`ProcessoOnboard/${Model.colaborador.id}/Desligamento/?dataDesligamento=${NovaData ?? ''}`)
      .then(() => {
        setEditar(false)
        setNovaData(undefined)
        toast({
          title: 'Data editada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (onReload !== undefined) {
          onReload()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <EmployContainer>
      <Flex flexDir={'column'}>
        <Flex direction={'row'} justifyContent={'center'} alignItems={'center'} gap={'.5rem'}>
          <Text fontWeight={800}>{Model.nomeTrilha}</Text>
          {Model.status === 2 &&
            <ElementoTooltipCmp bg={'var(--Red)'} label='Atrasada'>
              <Flex fontWeight={500} color={'var(--Red)'} alignItems={'center'}><FaClock /></Flex>
            </ElementoTooltipCmp>
          }

        </Flex>
        <EmployeHeader>
          <Flex alignItems={'center'}>
            <Avatar w={'2.325rem'} h={'2.325rem'} marginRight={'.75rem'} name={Model.colaborador.nome} src={Model.colaborador.avatar} />
            <Flex flexDir={'column'} color={'black'}>
              <span>{Model.colaborador.nome}</span>
              <small>{Model.colaborador.nomeArea}</small>
            </Flex>
          </Flex>
          {
            Model.status === 4
              ? <MdBlock size={32} color={'var(--terc1)'} />
              : <CircularProgress size={'2.5rem'} value={Model.andamentoPercentual} color={getColor(Model.andamentoPercentual)}>
                <CircularProgressLabel fontSize={'.65rem'} top={'50%'}>{Model.andamentoPercentual.toFixed(0)}%</CircularProgressLabel>
              </CircularProgress>
          }
        </EmployeHeader>
      </Flex>

      <Flex flexDir={'column'} gap={'.25rem'}>
        {
          (!PropInvalida(Model.dataConclusao)) && (
            <Text fontWeight={'bold'} fontSize={'.875rem'}>
              {Model.status === 4 ? 'Interrupção: ' : 'Conclusão: '} {Model.dataConclusao}
            </Text>
          )
        }
        {
          (!PropInvalida(Model.colaborador.dataDesligamento)) && (query.get('tipo')?.toString() === '3') && (
            <Flex alignItems={'center'} gap={'.5rem'} fontSize={'.875rem'}>
              <Flex
                fontWeight={'600'}
                alignItems={'center'} gap={'.5rem'}
              >{'Desligamento: '}
                {
                  !Editar
                    ? Model.colaborador.dataDesligamento
                    : <InputCmp
                      _focus={{ border: 'none' }}
                      width={'8rem'}
                      type='date'
                      OnChange={setNovaData}
                      value={NovaData}
                      max={(Model.status === 1 || Model.status === 2) ? undefined : new Date().toISOString().split('T')[0]}
                    />
                }
              </Flex>
              {
                (!Editar && query.get('tipo')?.toString() === '3') && (
                  <FaEdit
                    onClick={() => ModoEditarOn()}
                    title='Editar data de desligamento'
                    cursor={'pointer'}
                    color='var(--Rosa)'
                  />
                )
              }

              {
                (Editar) && (
                  <FaSave
                    onClick={() => Salvar()}
                    title='Salvar data de desligamento'
                    cursor={'pointer'}
                    size={16}
                    color='var(--Rosa)'
                  />
                )
              }
            </Flex>
          )
        }
      </Flex>

      <EmployeBody>
        {
          (processo.colaborador.urlSelf) && (
            <PerfilContainer>
              <span>Perfil</span>
              <img src={processo.colaborador.urlSelf ?? ''} />
            </PerfilContainer>
          )
        }

        {

          <ColumnNotas>
              <ColumnNotasLabel style={{ display: Model.mediaStakeholders === null ? 'none' : '' }}>
                <p>Média dos stakeholders:</p>
                <span>{Model.mediaStakeholders?.toFixed(2)}</span>
              </ColumnNotasLabel>
              <ColumnNotasLabel style={{ display: Model.mediaAutoAvaliacao === 0 || Model.mediaAutoAvaliacao === null ? 'none' : '' }}>
                <p>Média da autoavaliação:</p>
                <span>{Model.mediaAutoAvaliacao?.toFixed(2)}</span>
              </ColumnNotasLabel>
          </ColumnNotas>

        }

      </EmployeBody>

      <Flex justifyContent='center' gap={'.5rem'} alignItems='center'>
        <ButtonCmp
          isDisabled={!Model.colaborador.ativo && (Model.status === 3 || Model.status === 4)}
          onClick={() => nav(`/Rh/VisualizarPerfil/${Model.colaborador.id}`)}
          VarColor='Green2'
          leftIcon={<FaEye />}
        >Visualização completa</ButtonCmp>
        {Model.status !== 3 && Model.status !== 4 && parseJwt().adm === 'True' && (
          <ButtonCmp isLoading={loading} onClick={() => onOpenModalDelete(Model.colaborador.id)} VarColor={'Red'}>
            <FaTrash style={{ color: 'white' }} />
          </ButtonCmp>
        )}
      </Flex>
    </EmployContainer>
  )
}

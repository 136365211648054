import styled from 'styled-components'

export const Container = styled.a`
display: flex;
align-items: center;
justify-content:center;
background-color: var(--${props => props.theme});
font-weight: 700;
font-size: 1.25rem;
padding: 1rem 1rem;
width: 16.5rem;
border-radius: 1.5rem;
color: white;
text-align: center;

&:hover{
    filter: brightness(1.1);
    transition: var(--transitionBtns);
}
`

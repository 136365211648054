import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Container = styled(Column)`
padding: 1rem 0;
border-bottom: 2px solid var(--c4);

h4{
font-family: 'Roboto';
font-weight: 700;
font-size: 1.5rem;
color: var(--a4);
}

span{
    line-height: 140%;
    color: var(--a5);
    overflow-y:hidden;
    max-height: 0rem;
    transition: max-height 1.2s;
}
`

export const HeadBox = styled(Flex)`
justify-content: space-between;
align-items: center;
opacity: 60%;
svg, p{
    cursor: pointer;
}
`

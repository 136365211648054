import styled from 'styled-components'

export const Container = styled.div`
display: flex;
justify-content: center;
table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    max-width: 12rem;
    padding: .5rem 1rem .5rem 1rem;
}

td{
    transition: 2s;
    text-align: center;
}

th{
    background: var(--Roxo);
    color: var(--c1);
    font-weight: 500;
}
tr:last-child, tr:last-child:hover{
    background: var(--c3);
}
td:first-child{
    background: var(--RoxoOpaco25);
    color: var(--Preto);
    font-weight: bold;
}
.dropDownTr{
    background: var(--RoxoOpaco15) !important;
    color: var(--Preto);
    font-weight: bold;
}
tr:hover{
background: var(--RoxoOpaco25);
}

tr:not(:first-child){
color: var(--Preto);
}
`

import { Box, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'
import { iCompetencia } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { iProgressoXAcao } from '../../../../Criar'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
  competencia?: iCompetencia
  progresso?: iProgressoXAcao
}

interface iModaProps{
  onRequestClose: () => void
  OnSendProgresso: () => void
  isOpen: boolean
  acao: iAcao
  pdiId: string
}

export const ModalProgressoAcao: React.FC<iModaProps> = ({ isOpen, acao, pdiId, onRequestClose, OnSendProgresso }) => {
  const toast = useToast()
  const [ObservacaoProgresso, setObservacaoProgresso] = useState('')
  const [PorcentagemProgresso, setPorcentagemProgresso] = useState(acao.progresso?.progresso ?? 0)
  function GetColorProgresso(e?: number): string {
    const variavel = e ?? 0
    if (variavel < 25) {
      return 'var(--Red)'
    } else if (variavel < 50) {
      return 'var(--Orange)'
    } else if (variavel < 75) {
      return 'var(--Yellow)'
    } else {
      return 'var(--Green2)'
    }
  }

  function onSendProgresso(): void {
    const data: iProgressoXAcao = { acaoId: acao.id, progresso: PorcentagemProgresso, comentario: ObservacaoProgresso }
    appApi.put(`PDI/${pdiId}/Acao/Progresso`, data)
      .then((res) => {
        toast({
          title: 'Atualização de progresso realizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        OnSendProgresso()
        onRequestClose()
        setObservacaoProgresso('')
      })
      .catch((err) => console.log(err))
  }
  return (
        <ModalBase Titulo='Você está prestes a finalizar essa ação...' isOpen={isOpen} onClose={onRequestClose} Width='60rem'>
            <Flex flexDir={'column'} w='100%' gap={'.75rem'} alignItems='center' padding={'1rem 1.5rem'}>
              <TextAreaCmp label='Escreva uma observação de como está o andamento desta ação' value={ObservacaoProgresso} OnChange={setObservacaoProgresso} placeholder='Escreva seu comentário sobre seu progresso nessa ação'/>
              <Flex gap={'1rem'} width={'100%'} pl={'6px'}>
                <Slider w={'99%'} value={PorcentagemProgresso} onChange={setPorcentagemProgresso}>
                  <SliderTrack bg={'var(--c5)'} height={'6px'}>
                    <SliderFilledTrack bg={GetColorProgresso(PorcentagemProgresso)} transition={'.1s'} height={'6px'} />
                  </SliderTrack>
                  <SliderThumb boxSize={6} borderColor={GetColorProgresso(PorcentagemProgresso)} >
                    <Box color={GetColorProgresso(PorcentagemProgresso)} transition={'.3s'} as={FaCheck} />
                  </SliderThumb>
                </Slider>
                <Text color={GetColorProgresso(PorcentagemProgresso)} transition={'.3s'} fontWeight={'700'}>{PorcentagemProgresso}%</Text>
              </Flex>
            </Flex>
            <Flex padding={'1rem'} pt={'.5rem'} justifyContent={'end'} gap='.75rem'>
              <ButtonCmp onClick={onRequestClose} VarColor='c4'>Fechar</ButtonCmp>
              <ButtonCmp VarColor='Green2' onClick={onSendProgresso}>Enviar atualização de progresso</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}

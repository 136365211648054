import { useEffect, useState } from 'react'
import { FormControl, Flex, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Text } from '@chakra-ui/react'
import { FaSave } from 'react-icons/fa'
import { iEixos } from '../../../../interfaces'
import { ButtonCmp } from '../../../ButtonCmp'
import { ModalBase } from '../../../ModalBase'
import { PropInvalida } from '../../../../Utils/Helper'
import { InputCmp } from '../../../InputCmp'

interface iProps {
  tipoEixo: string
  eixos: iEixos[]
  eixo?: iEixos
  isOpen: boolean
  padrao: boolean
  onClose: () => void
  onUpdate: (eixo: iEixos) => void
}

export const ModalEditarEixo: React.FC<iProps> = ({ tipoEixo, eixo, isOpen, eixos, padrao, onClose, onUpdate }) => {
  // const toast = useToast()
  const [IsInvalid, setIsInvalid] = useState(false)
  const [NumeroInvalido, setNumeroInvalido] = useState('')
  const [Model, setModel] = useState<iEixos>(eixo ?? {
    id: '',
    rangeMaximo: 0,
    rangeMinimo: 0,
    titulo: ''
  })

  function InternalUpdate(): void {
    // const copy = [...eixos].sort(r => r.rangeMinimo)
    // const posInterna = copy.findIndex(e => e.id === eixo?.id)
    // if (Model.rangeMinimo >= Model.rangeMaximo) {
    //   toast({
    //     title: 'Nota mínima deve ser menor que nota máxima!',
    //     status: 'error',
    //     isClosable: false,
    //     position: 'top-right',
    //     duration: 4000
    //   })
    //   return
    // }

    // if (!PropInvalida(copy[posInterna - 1])) {
    //   if (Model.rangeMaximo >= copy[posInterna - 1].rangeMinimo) {
    //     toast({
    //       title: 'Sua nota máxima excedeu o limite minimo do eixo superior!',
    //       status: 'error',
    //       isClosable: false,
    //       position: 'top-right',
    //       duration: 4000
    //     })
    //     return
    //   }
    // }

    // if (!PropInvalida(copy[posInterna + 1])) {
    //   if (Model.rangeMinimo <= copy[posInterna + 1].rangeMaximo) {
    //     toast({
    //       title: 'Sua nota mínima é menor que a nota máxima do eixo anterior!',
    //       status: 'error',
    //       isClosable: false,
    //       position: 'top-right',
    //       duration: 4000
    //     })
    //     return
    //   }
    // }
    onUpdate(Model)
  }
  useEffect(() => {
    if (eixo) {
      setIsInvalid(true)
      const copy = [...eixos].sort(r => r.rangeMinimo)
      const posInterna = copy.findIndex(e => e.id === eixo?.id)
      if (Model.rangeMinimo > Model.rangeMaximo) {
        setNumeroInvalido('Nota mínima deve ser menor que nota máxima!')
      } else if (tipoEixo === 'y' && !PropInvalida(copy[posInterna - 1]) && Model.rangeMaximo >= copy[posInterna - 1].rangeMinimo) {
        setNumeroInvalido('Sua nota máxima excedeu o limite minimo do eixo superior!')
      } else if (tipoEixo === 'y' && !PropInvalida(copy[posInterna + 1]) && Model.rangeMinimo <= copy[posInterna + 1].rangeMaximo) {
        setNumeroInvalido('Sua nota mínima é menor que a nota máxima do eixo anterior!')
      } else if (tipoEixo === 'x' && !PropInvalida(copy[posInterna + 1]) && Model.rangeMaximo >= copy[posInterna + 1].rangeMinimo) {
        setNumeroInvalido('Sua nota máxima excedeu o limite minimo do eixo superior!')
      } else if (tipoEixo === 'x' && !PropInvalida(copy[posInterna - 1]) && Model.rangeMinimo <= copy[posInterna - 1].rangeMaximo) {
        setNumeroInvalido('Sua nota mínima é menor que a nota máxima do eixo anterior!')
      } else {
        setIsInvalid(false)
      }
    }
  }, [Model])

  return (
    <ModalBase Width='43rem' Titulo={`Eixo ${tipoEixo.toUpperCase()}`} isOpen={isOpen} onClose={onClose}>
      <Flex flexDir={'column'} gap={'.75rem'} padding={'0 1rem 1rem 1rem'}>
        <FormControl>
          <Text fontSize={'.875rem'} fontWeight={'700'}>Título</Text>
          <InputCmp isDisabled={!padrao} type="text" value={Model.titulo} OnChange={(e) => setModel({ ...Model, titulo: e })} />
        </FormControl>
        <Text fontSize={'.875rem'} fontWeight={'700'} mb={'-.5rem'}>Intervalo de notas</Text>
        <FormControl display={'flex'} alignItems={'center'} gap={'0.75rem'}>
          <span>De</span>
          <NumberInput width={'6rem'} size='sm' value={Model.rangeMinimo}
            onChange={(e) => setModel({ ...Model, rangeMinimo: isNaN(parseFloat(e)) ? 0 : parseFloat(e) })}
            min={0}
            max={200}
            step={0.1}
            // isInvalid={IsInvalidInicial}
          >
            <NumberInputField _active={{ outline: 'none' }} _focus={{ outline: 'none' }} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <span>até</span>
          <NumberInput
            width={'6rem'}
            size='sm'
            value={Model.rangeMaximo}
            min={0}
            max={200}
            step={0.1}
            onChange={(e) => setModel({ ...Model, rangeMaximo: isNaN(parseFloat(e)) ? 0 : parseFloat(e) })}>
            <NumberInputField _active={{ outline: 'none' }} _focus={{ outline: 'none' }} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        {IsInvalid && (
          <Text color={'var(--Red)'} mt={'-6px'}>{NumeroInvalido}</Text>
        )}
        {/* <Box as='span' mt={'.25rem'} fontWeight={'400'} color={'var(--Red)'} fontSize={'0.875rem'}>{TextoErroInput}</Box> */}
        <Flex justifyContent={'flex-end'} gap={'.5rem'}>
          <ButtonCmp size={'md'} VarColor='Gray4' onClick={onClose}>Cancelar</ButtonCmp>
          <ButtonCmp leftIcon={<FaSave />} size={'md'} VarColor='Green2' onClick={InternalUpdate}>Salvar</ButtonCmp>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

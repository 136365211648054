import React from 'react'
import { Flex } from '@chakra-ui/react'
import { MdOutlineZoomIn, MdOutlineZoomOut } from 'react-icons/md'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface iProps {
  height: number
}

export const ZoomContainer: React.FC<iProps> = ({ children, height }) => {
  return (
    <TransformWrapper
    panning={{
      disabled: true
    }}
    initialScale={0.35}
    maxScale={1}
    minScale={0.5}
    centerZoomedOut={true}
    centerOnInit={true}
  >
    {({ zoomIn, zoomOut, resetTransform, ...rest }) => {
      return (

      <Flex flexDir={'column'} width='100%' height={`${height}px`}>
        <Flex display={'none'} gap={'.5rem'} mt={'.5rem'} width={'75rem'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
          <ButtonCmp id='zoomOut' VarColor='Red' onClick={() => resetTransform()} rightIcon={<MdOutlineZoomOut/>}>Zoom</ButtonCmp>
          <ButtonCmp id='zoomIn' VarColor='Green1' onClick={() => zoomIn(0.1)} rightIcon={<MdOutlineZoomIn/>}>Zoom</ButtonCmp>
        </Flex>
        <TransformComponent>
            {children}
        </TransformComponent>
      </Flex>
      )
    }}
  </TransformWrapper>
  )
}

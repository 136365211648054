/* eslint-disable no-return-assign */
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { FaCalendarAlt, FaChalkboardTeacher, FaClipboardList, FaTachometerAlt } from 'react-icons/fa'
import { Body, Container, Header } from './styles'
import { useEffect, useRef } from 'react'
import LogoPec from '../../../../../assets/PEC.svg'
import LogoPulse from '../../../../../assets/Pulse.svg'
import LogoPercepcao from '../../../../../assets/IconePercepcao.svg'
import CardImersao from '../../../../../assets/CardImersao.svg'
import CardNps from '../../../../../assets/CardNps.png'
import CardPI from '../../../../../assets/CardPI.png'
import CardPulse from '../../../../../assets/CardPulse.png'
import CardPec from '../../../../../assets/CardPec.png'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface iConviteXPesquisa{
  tipo: number
  titulo: string
  link: string
  comunicacao?: iComunicacao
  dataInicio: string
  dataFim: string
  avaliado?: string
  avatarAvaliado?: string
}

interface iComunicacao{
  descricao: string
  uri: string
}

interface iContainerPesquisaProps{
  convite: iConviteXPesquisa
}

export const ContainerPesquisa: React.FC<iContainerPesquisaProps> = ({ convite }) => {
  // const redirectPage = (): any => window.location.href = convite.link
  const spanRef = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerHTML = convite.comunicacao?.descricao ?? ''
    }
  }, [convite.comunicacao?.descricao])

  function getIcon(): any {
    switch (convite.tipo) {
      case 4:
        return <img width={'24px'} height={'24px'} style={{ marginRight: '.325rem' }} src={LogoPec}/>

      case 5:
        return <img width={'24px'} height={'24px'} style={{ marginRight: '.325rem' }} src={LogoPulse}/>

      case 6:
        return <FaTachometerAlt size={24}/>

      case 1:
        return <FaChalkboardTeacher size={24}/>

      case 0:
        return <FaClipboardList size={24}/>

      case 2:
        return <img width={'24px'} height={'24px'} style={{ marginRight: '.325rem' }} src={LogoPercepcao}/>

      default:
        break
    }
  }

  function getImageDefault(): any {
    if (convite.comunicacao?.uri !== undefined && convite.comunicacao?.uri !== '') {
      return convite.comunicacao.uri
    }
    switch (convite.tipo) {
      case 4:
        return CardPec

      case 5:
        return CardPulse

      case 6:
        return CardNps

      case 1:
        return CardImersao

      case 0:
        return CardPI

      default:
        return CardNps
    }
  }

  return (
        <Container>
            <Header>
                {getIcon()}
                <span>{convite.titulo}</span>
            </Header>
            <Body style={{ flex: 1 }}>
                <img style={{ maxHeight: '300px' }} src={getImageDefault()}/>
                {convite.comunicacao?.descricao && <Flex justifyContent={'start'} width={'100%'} margin={'1rem 0'}>
                  <span dangerouslySetInnerHTML={{ __html: convite.comunicacao?.descricao ?? '' }} />
                </Flex>}
                {(convite.avaliado !== null) && (
                <Flex alignItems={'center'} gap={'.7rem'} justifyContent={'center'} mt={'.325rem'}>
                  <Avatar style={{ width: '2.1rem', height: '2.1rem' }} src={convite.avatarAvaliado}/>
                  <Flex justifyContent={'center'} flexDir={'column'} gap={'.25rem'}>
                    <Text fontSize={'.875rem'} lineHeight={'1rem'} fontWeight={'700'} w={'full'}>{convite.avaliado}</Text>
                    {
                      (convite.dataFim && convite.dataInicio) && (
                        <Flex fontSize={'.75rem'} lineHeight={'.85rem'}>
                            <FaCalendarAlt style={{ marginTop: '-.2rem' }}/>
                            <span>{convite.dataInicio} a {convite.dataFim}</span>
                        </Flex>
                      )
                    }
                    {
                      (!convite.dataFim && convite.dataInicio) && (
                        <Flex fontSize={'.75rem'} lineHeight={'.85rem'} gap={'.5rem'} fontWeight={'400'}>
                            Data de inicio:
                            <FaCalendarAlt style={{ marginRight: '-.2rem' }}/>
                            <span style={{ marginTop: '.2rem' }}>{convite.dataInicio}</span>
                        </Flex>
                      )
                    }
                  </Flex>
                </Flex>
                )}

            </Body>
            <ButtonCmp mt={'.25rem'} onClick={() => { window.open(convite.link) }} VarColor='Green2'>
              {convite.tipo === 1 ? 'Visualizar imersão' : convite.tipo === 6 ? 'Responder Nps' : 'Responder pesquisa'}
            </ButtonCmp>
        </Container>
  )
}

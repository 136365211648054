import ApexCharts from 'apexcharts'

import { useEffect } from 'react'

interface iProps{
  id: string
  Grafico: iGrafico
}

interface iGrafico{
  labelX: string[]
  series: iSeries[]
}

interface iSeries{
  nome: string
  dados: number []
}

export const ColumnChart: React.FC<iProps> = ({ id, Grafico }) => {
  function getSeries(): any {
    return [{
      data:
        Grafico.series[2].dados.map((e: number) => {
          return e * 100
        })
    }]
  }

  function GerarGrafico(): void {
    const options = {
      series: [{
        data: getSeries()
      }],
      chart: {
        height: 350,
        width: '100%',
        type: 'bar'
      },
      colors: ['var(--v2)'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(value: number): string {
          if (isNaN(value) || value === undefined) {
            return '0'
          }
          if (value !== -100) {
            return `${value.toFixed(0)}%`
          } else {
            return ''
          }
        },
        offsetY: -20,
        style: {
          fontSize: '10px',
          colors: ['#000']
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: Grafico.labelX,
        labels: {
          formatter: function (value: any) {
            return value
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [Grafico.series])
  return (
        <div style={{ paddingLeft: '179px' }} id={`chart-${id}`}/>
  )
}

import { useState, useEffect } from 'react'
import { Box, Flex, Grid, GridItem, Image, Input, Text } from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'
import { EixoNineBox } from './styles'

import seta from './assets/seta.svg'
import pensativo from './assets/pensativo.svg'
import feliz from './assets/feliz.svg'
import oculos from './assets/oculos.svg'
import triste from './assets/triste.svg'
import sorrindo from './assets/Sorrindo.svg'
import piscada from './assets/Piscada.svg'
import lagrima from './assets/lagrima.svg'
import surpresa from './assets/surpresa.svg'
import contente from './assets/contente.svg'
import { PropInvalida } from '../../Utils/Helper'
import { FaEye } from 'react-icons/fa'
import { iConfiguracaoEixo, iEixos, iPostNineBox, iQuadrante } from '../../interfaces'
import { ModalEditarEixo } from './components/ModalEditarEixo'
import { ModalTextoEixo } from './components/ModalTextosEixo'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'

interface iProps {
  model: iPostNineBox
  variant?: 'editar' | 'avaliar'
  quadranteSelecionado?: string
  onChangeModel?: (model: iPostNineBox) => void
  selecionarQuadrante?: (id: string) => void
}

export function ObterImgPorNome(quadrantes: iQuadrante[], nome: string): string {
  switch (quadrantes.findIndex(p => p.titulo === nome).toString()) {
    case '0': return pensativo
    case '1': return feliz
    case '2': return oculos
    case '3': return triste
    case '4': return sorrindo
    case '5': return piscada
    case '6': return lagrima
    case '7': return surpresa
    case '8': return contente
    default: return ''
  }
}

export const NineBox: React.FC<iProps> = ({ model, variant, quadranteSelecionado, onChangeModel, selecionarQuadrante }) => {
  if (PropInvalida(variant)) {
    variant = 'editar'
  }

  const [Model, setModel] = useState<iPostNineBox>({
    padrao: false,
    areas: [],
    configuracaoEixoX: {
      descricao: '',
      titulo: ''
    },
    configuracaoEixoY: {
      descricao: '',
      titulo: ''
    },
    eixosX: [],
    eixosY: [],
    quadrantes: [],
    squads: [],
    tags: [],
    tipoMeta: 0,
    titulo: ''
  })

  const [IsOpenModalEixo, setIsOpenModalEixo] = useState(false)
  const [IsOpenModalConfigEixo, setIsOpenModalConfigEixo] = useState(false)
  const [TipoEixo, setTipoEixo] = useState('')

  const [ConfigEixo, setConfigEixo] = useState<iConfiguracaoEixo>()
  const [Eixo, setEixo] = useState<iEixos>()

  function onCloseModalEixo(): void {
    setIsOpenModalEixo(false)
  }

  function onCloseModalConfigEixo(): void {
    setConfigEixo(undefined)
    setIsOpenModalConfigEixo(false)
  }

  function onOpenModalEixo(tipoEixo: string, eixo: iEixos): void {
    setEixo(eixo)
    setTipoEixo(tipoEixo)
    setIsOpenModalEixo(true)
  }

  function onOpenModalConfigEixo(tipo: string, model: iConfiguracaoEixo): void {
    setTipoEixo(tipo)
    setConfigEixo(model)
    setIsOpenModalConfigEixo(true)
  }

  function AtualizarConfiguracaoEixo(config: iConfiguracaoEixo): void {
    if (TipoEixo.toUpperCase() === 'X') {
      UpdateModel({ ...Model, configuracaoEixoX: config })
    } else {
      UpdateModel({ ...Model, configuracaoEixoY: config })
    }
    onCloseModalConfigEixo()
  }

  function AtualizarEixo(config: iEixos): void {
    if (TipoEixo.toLocaleLowerCase() === 'x') {
      const copy = [...Model.eixosX]
      const pos = copy.findIndex(r => r.id === config.id)
      if (pos !== -1) {
        copy[pos].titulo = config.titulo
        copy[pos].rangeMaximo = config.rangeMaximo
        copy[pos].rangeMinimo = config.rangeMinimo
        UpdateModel({ ...Model, eixosX: copy })
      }
    } else {
      const copy = [...Model.eixosY]
      const pos = copy.findIndex(r => r.id === config.id)
      if (pos !== -1) {
        copy[pos].titulo = config.titulo
        copy[pos].rangeMaximo = config.rangeMaximo
        copy[pos].rangeMinimo = config.rangeMinimo
        UpdateModel({ ...Model, eixosY: copy })
      }
    }
    onCloseModalEixo()
  }

  function AtualizarQuadrande(quadrande: iQuadrante, titulo: string): void {
    const copy = [...Model.quadrantes]
    const pos = copy.findIndex(r => r.eixoX === quadrande.eixoX && r.eixoY === quadrande.eixoY)
    if (pos !== -1) {
      copy[pos].titulo = titulo
      UpdateModel({ ...Model, quadrantes: copy })
    }
  }

  function ObterImgPorPosicao(y: string, x: string): string {
    switch (Model.quadrantes.findIndex(p => p.eixoY === y && p.eixoX === x).toString()) {
      case '0': return pensativo
      case '1': return feliz
      case '2': return oculos
      case '3': return triste
      case '4': return sorrindo
      case '5': return piscada
      case '6': return lagrima
      case '7': return surpresa
      case '8': return contente
      default: return ''
    }
  }

  function UpdateModel(modelo: iPostNineBox): void {
    setModel(modelo)
    if (onChangeModel !== undefined) {
      onChangeModel(modelo)
    }
  }

  function ObterCorQuadrantePorPosicao(quadrantes: iQuadrante[], y: string, x: string): string {
    switch (quadrantes.findIndex(p => p.eixoY === y && p.eixoX === x).toString()) {
      case '0': return 'var(--Blue1)'
      case '1': return 'var(--Green3)'
      case '2': return 'var(--Green2)'
      case '3': return 'var(--Orange)'
      case '4': return 'var(--Blue1)'
      case '5': return 'var(--Green3)'
      case '6': return 'var(--Red)'
      case '7': return 'var(--Orange)'
      case '8': return 'var(--Blue1)'
      default: return ''
    }
  }

  useEffect(() => {
    setModel(model)
  }, [model])

  if (variant === 'editar') {
    return (
      <Flex flexDirection={'column'} alignItems={'center'}>
        {(IsOpenModalEixo) && (
          <ModalEditarEixo
            padrao={Model.padrao}
            tipoEixo={TipoEixo}
            eixos={TipoEixo.toLowerCase() === 'x' ? Model.eixosX : Model.eixosY}
            eixo={Eixo}
            onUpdate={AtualizarEixo}
            isOpen={IsOpenModalEixo}
            onClose={onCloseModalEixo}
          />
        )
        }

        {(IsOpenModalConfigEixo) && (
          <ModalTextoEixo
            tipo={TipoEixo}
            eixo={ConfigEixo}
            isOpen={IsOpenModalConfigEixo}
            onClose={onCloseModalConfigEixo}
            onUpdate={AtualizarConfiguracaoEixo}
          />
        )
        }
        <Flex justifyContent={'center'}>
          <Box as='div' height={'38.75rem'} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'7.25rem'}>
            <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'} transform={'rotate(-90deg)'}>
              <Text display={'flex'}
                justifyContent={'center'}
                gap={'.5rem'}
                fontWeight={'900'}
                fontSize={'2rem'}><span>{Model.configuracaoEixoY.titulo.toUpperCase()}</span>
                {Model.padrao && <Box
                  as='span'
                  color={'var(--Rosa)'}
                  cursor={'pointer'}
                  onClick={() => onOpenModalConfigEixo('Y', Model.configuracaoEixoY)}
                ><BsPencilSquare />
                </Box>}
              </Text>
              <Text fontSize={'1.25rem'} textAlign={'center'} pr={Model.padrao ? '2.6rem' : ''}>{Model.configuracaoEixoY.descricao}</Text>
              <Image width={'38.75rem'} src={seta} />
            </Box>
          </Box>
          <Box as='div'>
            <Grid gridTemplateColumns={'repeat(1, 1fr)'} gap={'1rem'}>
              {Model.eixosY.map((e, i) => {
                return (
                  <Grid key={i} gridTemplateColumns={'repeat(4, 1fr)'} gap={'1rem'}>
                    <GridItem alignSelf={'center'}>
                      <Text textAlign={'center'} color={((Model.eixosY[i - 1] && e.rangeMaximo >= Model.eixosY[i - 1].rangeMinimo) || (Model.eixosY[i + 1] && e.rangeMinimo <= Model.eixosY[i + 1].rangeMaximo) || e.rangeMinimo >= e.rangeMaximo) ? 'var(--Red)' : 'var(--Azul)'} display={'flex'} justifyContent={'center'} gap={'1rem'} fontWeight={'700'} fontSize={'1rem'}>
                        <span>{e.titulo}</span>
                        <Box
                          as='span'
                          color={'var(--Rosa)'}
                          cursor={'pointer'}
                          fontSize={'1rem'}
                          fontWeight={'700'}
                          onClick={() => onOpenModalEixo('y', e)}
                        ><BsPencilSquare /></Box>
                      </Text>
                      <Text
                        pr={Model.padrao ? '2rem' : ''}
                        textAlign={'center'}
                        fontSize={'0.875rem'}
                        color={((Model.eixosY[i - 1] && e.rangeMaximo >= Model.eixosY[i - 1].rangeMinimo) || (Model.eixosY[i + 1] && e.rangeMinimo <= Model.eixosY[i + 1].rangeMaximo) || e.rangeMinimo >= e.rangeMaximo) ? 'var(--Red)' : 'var(--Azul)'}
                      >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                    </GridItem>
                    {Model.quadrantes.filter(t => t.eixoY === e.id).map((q, i2) => {
                      return (
                        <GridItem key={i2}>
                          <EixoNineBox
                            isSelect={true}
                            theme={ObterCorQuadrantePorPosicao(Model.quadrantes, q.eixoY, q.eixoX)}
                            heigth='11rem'
                            width='11rem'
                          >
                            <Input
                              textAlign={'center'}
                              w={'10rem'}
                              borderRadius={'0.375rem'}
                              size='sm'
                              isReadOnly={!Model.padrao}
                              variant='outline'
                              onChange={(e) => AtualizarQuadrande(q, e.target.value)}
                              type='text'
                              value={q.titulo}
                              focusBorderColor='white'
                            />
                            <img src={ObterImgPorPosicao(q.eixoY, q.eixoX)} alt="Emoji" />
                          </EixoNineBox>
                        </GridItem>
                      )
                    })}
                  </Grid>
                )
              })}
              <Grid gridTemplateColumns={'repeat(4, 1fr)'} gap={'.75rem'}>
                <GridItem></GridItem>
                {Model.eixosX.map((e, i) => {
                  return (
                    <GridItem key={i} alignSelf={'center'}>
                      <Text
                        textAlign={'center'}
                        color={((Model.eixosX[i + 1] && e.rangeMaximo >= Model.eixosX[i + 1].rangeMinimo) || (Model.eixosX[i - 1] && e.rangeMinimo <= Model.eixosX[i - 1].rangeMaximo) || e.rangeMinimo >= e.rangeMaximo) ? 'var(--Red)' : 'var(--Azul)'}
                        display={'flex'}
                        justifyContent={'center'}
                        fontWeight={'700'}
                        gap={'.5rem'}
                        fontSize={'1rem'}
                      >
                        <span>{e.titulo}</span>
                        <Box
                          as='span'
                          color={'var(--Rosa)'}
                          cursor={'pointer'}
                          fontSize={'1rem'}
                          fontWeight={'700'}
                          onClick={() => onOpenModalEixo('x', e)}
                        ><BsPencilSquare />
                        </Box>
                      </Text>
                      <Text
                        pr={Model.padrao ? '2rem' : ''}
                        textAlign={'center'}
                        fontSize={'0.875rem'}
                        color={((Model.eixosX[i + 1] && e.rangeMaximo >= Model.eixosX[i + 1].rangeMinimo) || (Model.eixosX[i - 1] && e.rangeMinimo <= Model.eixosX[i - 1].rangeMaximo) || e.rangeMinimo >= e.rangeMaximo) ? 'var(--Red)' : 'var(--Azul)'}
                      >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                    </GridItem>
                  )
                })}
              </Grid>
            </Grid>
          </Box>
        </Flex>
        <Flex pl={'11.5rem'} my={'2rem'}>
          <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'}>
            <Image width={'42.75rem'} src={seta} />
            <Text display={'flex'} justifyContent={'center'}
              gap={'.5rem'}
              fontWeight={'900'}
              fontSize={'2rem'}>
              <span>{Model.configuracaoEixoX.titulo.toUpperCase()}</span>
              {Model.padrao && <Box as='span'
                color={'var(--Rosa)'}
                cursor={'pointer'}
                onClick={() => onOpenModalConfigEixo('X', Model.configuracaoEixoX)}><BsPencilSquare />
              </Box>}
            </Text>
            <Text fontSize={'1.25rem'} textAlign={'center'} pr={Model.padrao ? '2.6rem' : ''}>{Model.configuracaoEixoX.descricao}</Text>
          </Box>
        </Flex>
      </Flex>
    )
  } else if (variant === 'avaliar') {
    return (
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Flex justifyContent={'center'}>
          <Box as='div' height={'31.25rem'} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'7.25rem'}>
            <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'} transform={'rotate(-90deg)'}>
              <Text
                display={'flex'}
                justifyContent={'center'}
                fontWeight={'700'}
                gap={'.325rem'}
                alignItems={'center'}
                fontSize={'1.25rem'}
              >{Model.configuracaoEixoY.titulo.toUpperCase()}
                <ElementoTooltipCmp
                  backgroundColor={'var(--Azul)'}
                  label={Model.configuracaoEixoY.descricao}
                ><Flex alignItems={'center'}><FaEye /></Flex>
                </ElementoTooltipCmp>
              </Text>
              <Image width={'31.25rem'} src={seta} />
            </Box>
          </Box>
          <Box as='div'>
            <Grid gridTemplateColumns={'repeat(1, 1fr)'} gap={'.75rem'}>
              {Model.eixosY.map((e, i) => {
                return (
                  <Grid key={i} gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
                    <GridItem alignSelf={'center'} w={'4.25rem'}>
                      <Text textAlign={'center'} color={'var(--Azul)'} display={'flex'} justifyContent={'center'} gap={'.5rem'} fontWeight={'700'} fontSize={'1.125rem'}>
                        <span>{e.titulo}</span>
                      </Text>
                      <Text
                        textAlign={'center'}
                        fontSize={'0.75rem'}
                        color={'var(--Azul)'}
                      >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                    </GridItem>
                    {Model.quadrantes.filter(t => t.eixoY === e.id).map((q, i2) => {
                      return (
                        <GridItem cursor={'pointer'} key={i2}>
                          <EixoNineBox onClick={() => {
                            if (selecionarQuadrante !== undefined) {
                              selecionarQuadrante(q.id)
                            }
                          }} isSelect={q.id === quadranteSelecionado}
                            theme={ObterCorQuadrantePorPosicao(Model.quadrantes, q.eixoY, q.eixoX)}
                            heigth='9.25rem'
                            width='9.25rem'
                          >
                            <Text as={'span'} textAlign={'center'}>
                              {q.titulo}
                            </Text>
                            <img src={ObterImgPorPosicao(q.eixoY, q.eixoX)} alt="" />
                          </EixoNineBox>
                        </GridItem>
                      )
                    })}
                  </Grid>
                )
              })}
              <Grid gridTemplateColumns={'.5fr 1fr 1fr 1fr'} gap={'.75rem'}>
                <GridItem maxW={'2rem'} mr={'-5rem'}></GridItem>
                {Model.eixosX.map((e, i) => {
                  return (
                    <GridItem key={i} alignSelf={'center'}>
                      <Text
                        textAlign={'center'}
                        color={'var(--Azul)'}
                        display={'flex'}
                        justifyContent={'center'}
                        gap={'.5rem'}
                        fontWeight={'700'}
                        fontSize={'1.125rem'}
                      >
                        <span>{e.titulo}</span>
                      </Text>
                      <Text
                        textAlign={'center'}
                        fontSize={'0.75rem'}
                        color={'var(--Azul)'}
                      >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                    </GridItem>
                  )
                })}
              </Grid>
            </Grid>
          </Box>
        </Flex>
        <Flex pl={'11rem'} my={'2rem'}>
          <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'}>
            <Image width={'33.25rem'} src={seta} />
            <Text
              display={'flex'}
              justifyContent={'center'}
              fontWeight={'700'}
              gap={'.325rem'}
              alignItems={'center'}
              fontSize={'1.25rem'}
            >{Model.configuracaoEixoX.titulo.toUpperCase()}
              <ElementoTooltipCmp
                backgroundColor={'var(--Azul)'}
                label={Model.configuracaoEixoX.descricao}
              ><Flex alignItems={'center'}><FaEye /></Flex>
              </ElementoTooltipCmp>
            </Text>
          </Box>
        </Flex>
      </Flex>
    )
  } else {
    return (<></>)
  }
}

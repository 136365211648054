import styled from 'styled-components'

export const WhiteModal = styled.div`
    width: 50rem;
    display: flex;
    flex-direction: column;
    padding: .75rem;
    top: 30%;
    background: white;
    border-radius: 0.25rem;
    box-shadow: var(--SombraBtns);
 `

import styled from 'styled-components'
import { Flex, Column } from '../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
width: 37rem;
padding-bottom: 1rem;
padding-top: 2rem;
justify-content: center;
align-items:center;
h2{
    color:var(--Azul);
}

span{
    font-weight: bold;
    margin-top: .5rem;
}
`

export const ModalHeader = styled(Flex)`
align-items:center;
justify-content: space-between;
border-bottom: 0.063rem solid var(--c4);
padding: 1rem;

svg{
    color:var(--Red);
    cursor:pointer;
}
`

export const ModalFooter = styled(Flex)`
justify-content: center;
padding: 0 0 1rem 0rem;
`

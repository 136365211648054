import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Container, Header } from './styles'

interface iProps {
  isFirst?: boolean
  titulo: string
}

export const DropText: React.FC<iProps> = ({ children, isFirst, titulo }) => {
  const [isOpen, setisOpen] = useState(false)

  return (
    <Flex flexDir={'column'}>
      <hr style={{ border: '1px solid #d0d0d083' }} />
      <Container open={isOpen}>
        <Header open={isOpen}>
          <Text
            color={'black'}
            fontWeight='700'
            fontSize={'0.875rem'}
          >{titulo}</Text>
          <Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'}>
            <AiOutlinePlus onClick={() => setisOpen(!isOpen)} size={18} style={{ marginBottom: '0' }} />
          </Flex>
        </Header>
        <div className='drop'>
          {children}
        </div>
      </Container>
    </Flex>
  )
}

import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const FlexBetween = styled(Flex)`
justify-content: space-between;
`

export const FlexChart = styled(Flex)`
justify-content: center;
align-items: center;
width: 100%;
`

/* eslint-disable array-callback-return */
import { Flex, FormControl, Text, useToast } from '@chakra-ui/react'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { CardInformacao } from './Components/CardInformacao'
import { useState, useEffect } from 'react'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { FaAngleLeft, FaAngleRight, FaStopwatch } from 'react-icons/fa'
import { CardParticipante } from './Components/CardParticipante'
import { iCardAvaliador, iColaboradorReuniao, iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ModalDelete } from '../../../../components/ModalDelete'
import { useAuth } from '../../../../contexts/AuthContext'
import { InputCmp } from '../../../../components/InputCmp'
import { SelectCmp } from '../../../../components/SelectCmp'
import { FindUser } from '../../../../components/FindBox'

interface iParticipanteReuniao extends iCardAvaliador {
  tipo: number
}

interface iModel {
  nomeReuniao: string
  descricaoReuniao?: string
  dataInicio?: string
  hInicio?: string
  duracao?: number
  local?: string
  colaborador: iColaboradorReuniao
  participantes: iParticipanteReuniao[]
  dataLimiteInicio?: string
  dataLimiteFim?: string
  agendado?: boolean
  etapaId?: string
}

type iLocationProps = {
  reuniaoId: string
}

export const ConfiguracaoReuniao: React.FC = () => {
  const { isAuth } = useAuth()
  const { reuniaoId } = useParams<iLocationProps>()
  const { pathname } = useParams()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const reagendar = params.get('reagendar')

  const toast = useToast()
  const nav = useNavigate()

  const [Model, setModel] = useState<iModel>()
  const [View, setView] = useState('1')
  const [Email, setEmail] = useState('')
  const [DataInicio, setDataInicio] = useState('')
  const [HInicio, setHInicio] = useState('')
  const [Duracao, setDuracao] = useState('5')
  const [Local, setLocal] = useState('')
  const [ModaConfirmIsOpen, setModaConfirmIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Editado, setEditado] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [Id, setId] = useState<string>()
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  function AgendarReuniao(): void {
    setIsLoading(true)
    const form = {
      dataInicio: `${DataInicio}T${HInicio}`,
      Duracao: parseInt(Duracao),
      local: Local
    }
    appApi.post(`ReuniaoEtapaColaborador/Configuracao/${reuniaoId as string}`, form)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Reunião agendada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (isAuth()) {
          nav(-1)
        } else {
          nav('/')
        }
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err)
      })
  }

  function ReenviarConvites(): void {
    setIsLoading(true)
    const form = {
      dataInicio: `${DataInicio}T${HInicio}`,
      Duracao: parseInt(Duracao),
      local: Local
    }

    appApi.put(`ReuniaoEtapaColaborador/${reuniaoId as string}/Reenviar`, form)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Mensagens reenviadas com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav('/Colaborador')
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  function Validar(param: string): void {
    if (param === '2') {
      if (DataInicio === '') {
        toast({
          title: 'Escolha uma data válida para a reunião',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
        return
      }
      setView('2')
    }

    if (param === '3') {
      if (HInicio === '') {
        toast({
          title: 'Escolha uma hora válida para a reunião',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
        return
      }
      setView('3')
    }
  }

  function ObterReuniao(): void {
    appApi.get(`ReuniaoEtapaColaborador/${reuniaoId as string}`)
      .then(res => {
        const data: iModel = res.data
        setModel(data)
        if (data.dataInicio !== undefined && data.dataInicio !== null) {
          setDataInicio(data.dataInicio)
          setDuracao(data.duracao?.toString() ?? '5')
          setHInicio(data.hInicio ?? '')
          setLocal(data.local ?? '')
          setView('5')
        }
      })
      .catch(err => console.log(err))
  }

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => {
        setColaboradores(res.data)
      })
      .catch(err => console.log(err))
  }

  function PostParticipantes(): void {
    const form = {
      texto: Email
    }
    appApi.post(`Reuniao/${reuniaoId as string}/Participante`, form)
      .then(() => {
        setEditado(true)
        setEmail('')
        ObterReuniao()
      })
      .catch(({ response }) => {
        toast({
          title: response.data,
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function FinalizarReuniao(): void {
    appApi.put(`ReuniaoEtapaColaborador/${reuniaoId ?? ''}/Concluir`)
      .then(() => {
        toast({
          title: 'Reunião finalizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        nav('/Colaborador')
      })
      .catch(err => console.log(err))
  }

  function DeleteParticipantes(): void {
    appApi.delete(`Reuniao/${reuniaoId as string}/Participante/${Id}`)
      .then(() => {
        ObterReuniao()
        toast({
          title: 'Invite cancelado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function ObterView(): any {
    if (View === '1') {
      return (
        <Flex flexDir={'column'}>
          <h3>Selecione a data da reunião</h3>
          <Flex margin={'1rem 0'} justifyContent={'center'}>
            <InputCmp min={Model?.dataLimiteInicio} max={Model?.dataLimiteFim} OnChange={setDataInicio} type={'date'} width='15rem' borderColor={'var(--Gray4)'} />
          </Flex>
          <Flex justifyContent={'center'}>
            <ButtonCmp onClick={() => Validar('2')} VarColor='Green2' rightIcon={<FaStopwatch />}>Escolher horário</ButtonCmp>
          </Flex>
        </Flex>
      )
    }

    if (View === '2') {
      return (
        <Flex flexDir={'column'} alignItems='center'>
          <h3>Escolha o horário</h3>
          <Flex margin='1rem 0' gap={'1rem'}>
            <FormControl>
              <InputCmp label='Início' OnChange={setHInicio} width={'10rem'} type={'time'}/>
            </FormControl>

            <FormControl>
              <SelectCmp title='Duração' OnChange={setDuracao} width={'10rem'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </SelectCmp>
            </FormControl>
          </Flex>

          <Flex justifyContent={'center'} gap='.5rem'>
            <ButtonCmp onClick={() => setView('1')} VarColor='c6' leftIcon={<FaAngleLeft />}>Voltar</ButtonCmp>
            <ButtonCmp onClick={() => Validar('3')} VarColor='Green2' rightIcon={<FaAngleRight />}>Escolher local</ButtonCmp>
          </Flex>
        </Flex>
      )
    }

    if (View === '3') {
      return (
        <Flex flexDir={'column'} textAlign={'center'}>
          <h3>Informe o local da reunião</h3>
          <Flex margin={'1rem 0'} justifyContent={'center'}>
            <InputCmp placeholder={'Digite o local da reunião'} OnChange={setLocal} type={'text'} width='25rem'/>
          </Flex>
          <Flex justifyContent={'center'} gap='.5rem'>
            <ButtonCmp onClick={() => setView('2')} VarColor='c6' leftIcon={<FaAngleLeft />}>Voltar</ButtonCmp>
            <ButtonCmp onClick={() => setView('4')} VarColor='Green2' rightIcon={<FaAngleRight />}>Escolher participantes</ButtonCmp>
          </Flex>
        </Flex>
      )
    }

    if (View === '4') {
      return (
        <Flex flexDir={'column'}>
          <Flex justifyContent={'center'} flexDir='column' alignItems={'center'}>
            <h3>Selecione os participantes</h3>
            <Text fontSize={'13px'} color={'var(--Gray3)'} lineHeight={'16px'}>Escolha um colaborador ou coloque o email e clique em selecionar</Text>
          </Flex>

          <Flex alignItems='center' flexDir={'column'} margin='1rem 0' >
            <Flex alignItems={'end'} gap={'.5rem'} w={'21rem'}>
              <FindUser
                lista={Colaboradores}
                onChoice={e => { setEmail(Colaboradores.find(a => a.id === e)?.email ?? '') }}
                differentValueProp='email'
                _onChange={(e) => setEmail(e)}
                placeholder='colaborador@sah.com.br'
              />
              <ButtonCmp VarColor='Green2' w={'6.5rem'} onClick={PostParticipantes}>Selecionar</ButtonCmp>
            </Flex>
          </Flex>

          {
            (Model?.participantes !== undefined && Model?.participantes !== null) && (
              <Flex gap={'1rem'} flexWrap='wrap' marginBottom={'1rem'} justifyContent={'center'}>
                {
                  Model.participantes.map((e) => {
                    return (
                      <CardParticipante key={e.id} onDelete={onOpenModalDelete} participante={e} />
                    )
                  })
                }
              </Flex>
            )
          }

          <Flex justifyContent={'center'} gap='.5rem'>
            <ButtonCmp
              onClick={() => setView('3')}
              VarColor='c6' leftIcon={<FaAngleLeft />}
            >Voltar</ButtonCmp>

            <ButtonCmp
              VarColor='Green2'
              isLoading={IsLoading}
             onClick={() => setModaConfirmIsOpen(true)}
            >Agendar</ButtonCmp>
          </Flex>
        </Flex>
      )
    }

    if (View === '5') {
      return (
        <Flex flexDir={'column'}>
          <Flex justifyContent={'center'}>
            <h3>Selecione os participantes</h3>
          </Flex>
          <Flex margin='1rem 0' gap={'1rem'}>
            <FormControl>
              <InputCmp label='Data início' value={DataInicio} OnChange={setDataInicio} width={'10rem'} type={'date'} max={'2099-12-31T23:59'}/>
            </FormControl>

            <FormControl>
              <InputCmp label='Início' value={HInicio} OnChange={setHInicio} width={'10rem'} type={'time'} />
            </FormControl>

            <FormControl>
              <SelectCmp title='Duração' value={Duracao} OnChange={setDuracao} width={'10rem'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </SelectCmp>
            </FormControl>
          </Flex>

          <FormControl>
            <InputCmp label='Local' placeholder={'Digite o local da reunião'} value={Local} OnChange={setLocal} type={'text'}/>
          </FormControl>

          <Flex alignItems='center' flexDir={'column'} margin='1rem 0' >
            <Flex alignItems={'end'} gap={'.5rem'}>
              <InputCmp label='Selecione o e-mail dos participantes' value={Email} OnChange={setEmail} placeholder='colaborador@sah.com.br' marginRight={'1rem'} type={'email'} borderColor={'var(--Gray4)'} />
              <ButtonCmp VarColor='Green2' onClick={PostParticipantes}>Selecionar</ButtonCmp>
            </Flex>
          </Flex>

          {
            (Model?.participantes !== undefined && Model?.participantes !== null) && (
              <Flex gap={'1rem'} flexWrap='wrap' marginBottom={'1rem'}>
                {
                  Model.participantes.map((e) => {
                    return (
                      <CardParticipante key={e.id} onDelete={onOpenModalDelete} participante={e} />
                    )
                  })
                }
              </Flex>
            )
          }

          <Flex justifyContent={'center'} gap='1rem'>
            <ButtonCmp onClick={() => nav(-1)} VarColor='c5' leftIcon={<FaAngleLeft />}>Voltar</ButtonCmp>
            {
              ((Editado && Model?.agendado && !reagendar) ?? pathname?.includes('reagendar')) && (
                <ButtonCmp isLoading={IsLoading} onClick={ReenviarConvites} VarColor='Green2'>Editar e reenviar convites</ButtonCmp>
              )}
            {
              (!Model?.agendado || reagendar) && (
                <ButtonCmp isLoading={IsLoading} onClick={AgendarReuniao} VarColor='Green2'>Agendar e enviar convites</ButtonCmp>
              )
            }
            {
              (Model?.agendado && !reagendar) && (
                <ButtonCmp onClick={FinalizarReuniao} VarColor='Rosa'>Finalizar a reunião</ButtonCmp>
              )
            }
          </Flex>
        </Flex>
      )
    }
  }

  function onOpenModalDelete(id: string): void {
    setId(id)
    setModalDeleteIsOpen(true)
  }

  function onCloseModalDelete(): void {
    setId(undefined)
    setModalDeleteIsOpen(false)
  }

  useEffect(() => {
    ObterReuniao()
    ObterColaboradores()
  }, [])

  useEffect(() => {
    if (View === '5') {
      setEditado(true)
    }
  }, [DataInicio, HInicio, Duracao, Local])

  return (
    <Body>
      <ModalDelete
        isOpen={ModaConfirmIsOpen}
        message='Deseja confirmar os dados e agendar a reunião?'
        onConfirm={AgendarReuniao}
        center
        onRequestClose={() => setModaConfirmIsOpen(false)}
      />

      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja excluir o participante e cancelar o invite?'
        onConfirm={DeleteParticipantes}
        center
        onRequestClose={onCloseModalDelete}
      />

      <WhiteContainer>
        <Flex justifyContent={'space-between'} mb={'1rem'}>
          <h1>{Model?.nomeReuniao}</h1>
          {
            (Model) && (
              <Flex justifyContent={'center'}>
                <CardInformacao colaborador={Model?.colaborador} />
              </Flex>
            )
          }
        </Flex>

        <Flex padding={'1rem'} justifyContent={'center'} border={'2px solid var(--c4)'} borderRadius='.25rem'>
          {ObterView()}
        </Flex>

        {
          (Model?.descricaoReuniao !== undefined) && (
            <Flex flexDir={'column'} mt={'1rem'} fontSize={'.875rem'}>
              <h4>Descrição da reunião</h4>
              <strong dangerouslySetInnerHTML={{ __html: Model.descricaoReuniao }}></strong>
            </Flex>
          )
        }
      </WhiteContainer>
    </Body>
  )
}

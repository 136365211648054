/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Grid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { DashboardSwitch } from '../../../../../components/DashboardSwitch'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { Paginador } from '../../../../../components/Paginador'
import { Tag } from '../../../../../components/Tag'
import { WhiteContainer } from '../../../../../components/WhiteContainer'
import { iAvatarXInformacoes, iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { Body } from '../../../../Layouts/Body'
import { iModalAgendamentoInput } from '../../Assunto'
import { ModalReagendar } from '../../Assunto/components/ModalReagendar'
import { iLinhaTabela } from '../../Dashboard/Individual'
import { BoxReunioesStatus } from './Components/BoxReunioesStatus'
import { DrowerFiltroReuniaoOne } from './Components/DrowerFiltroReuniao'
import { TabListaColaboradores } from './Components/TabListaColaboradores'
import { TabListaPorReuniao } from './Components/TabListaPorReuniao'
import { ContainerFiltro, OutlineContainer } from './styles'

interface iModel {
  tabela: iLinhaTabela[]
  tabelaPorColaborador: iLinhaGeral[]
  quantidadeFiltrada: number
  quantidadePorColaborador: number
}

export interface iLinhaGeral {
  gestor: iAvatarXInformacoes
  colaboradores: iAvatarXInformacoes[]
  aceitas: number
  finalizadas: number
  aguardando: number
  atrasadas: number
}

export interface iFiltro {
  departamentoId: string
  cargoId: string
  tagId: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardCorporativoReuniaoOne: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()

  const { pathname } = useLocation()
  const [DrowerIsOpen, setDrowerIsOpen] = useState(false)
  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iModel>({
    tabela: [],
    quantidadeFiltrada: 0,
    tabelaPorColaborador: [],
    quantidadePorColaborador: 0
  })
  const [ModelSemFiltros, setModelSemFiltros] = useState<iModel>({
    tabela: [],
    quantidadeFiltrada: 0,
    tabelaPorColaborador: [],
    quantidadePorColaborador: 0
  })

  const [Filtro, setFiltro] = useState<iFiltro>({
    cargoId: '',
    dataFim: '',
    dataInicio: '',
    departamentoId: '',
    status: '',
    tagId: ''
  })
  const [IsLoading, setIsLoading] = useState(false)
  const [Pagina, setPagina] = useState(0)
  const [Ordenador, setOrdenador] = useState({ filtro: 0, ascendente: false })
  const [Tamanho, setTamanho] = useState(25)

  const [Reuniao, setReuniao] = useState<iLinhaTabela>()
  const [ReagendarIsOpen, setReagendarIsOpen] = useState(false)
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    appApi.get('ReuniaoOne/Dashboard/Corporativo')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
          setModelSemFiltros(res.data)
        } else {
          setModel({ tabela: [], quantidadeFiltrada: 0, tabelaPorColaborador: [], quantidadePorColaborador: 0 })
        }
      })
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function ObterAreas(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterCargos(): void {
    appApi.get('Cargo/Select')
      .then(res => setCargos(res.data))
      .catch(err => console.log(err))
  }

  function Filtrar(model: iFiltro): void {
    setFiltro(model)
    setIsLoading(true)
    appApi.get(`ReuniaoOne/Dashboard/Corporativo?departamentoId=${model.departamentoId}&cargoId=${model.cargoId}&squadId=${model.tagId}&status=${model.status}&dataInicio=${model.dataInicio}&dataFim=${model.dataFim}&ordenador=${Ordenador.filtro}&ascendente=${Ordenador.ascendente}&tamanho=${Tamanho}&pagina=${Pagina}`)
      .then(res => {
        if (res.data?.tabela?.length > 0 || Pagina === 0) {
          setModel(res.data)
        } else {
          setPagina(0)
        }
        setIsLoading(false)
        setDrowerIsOpen(false)
      })
      .catch(err => { console.log(err); setIsLoading(false); setDrowerIsOpen(false) })
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`ReuniaoOne/${id}/ReenviarNotificacao`)
      .then(() => toast({
        title: 'Notificação reenviada com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${Reuniao?.id ?? ''}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setReagendarIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`ReuniaoOne/${Reuniao?.id ?? ''}`)
      .then(() => {
        toast({
          title: 'Reunião excluida com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function onOpenReagendar(linha: iLinhaTabela): void {
    setReagendarIsOpen(true)
    setReuniao(linha)
  }

  function onCloseReagendar(): void {
    setReagendarIsOpen(false)
    setReuniao(undefined)
  }

  function onOpenDelete(linha: iLinhaTabela): void {
    setDeletarIsOpen(true)
    setReuniao(linha)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setReuniao(undefined)
  }

  useEffect(() => {
    Carregar()
    ObterSquads()
    ObterAreas()
    ObterCargos()
  }, [])

  useEffect(() => {
    Filtrar(Filtro)
  }, [Pagina, Tamanho, Ordenador])

  return (
    <Body isLoading={IsLoading}>
      {ReagendarIsOpen &&
        <ModalReagendar
          alvo={Reuniao?.convidado ?? {
            avatar: '',
            id: '',
            info1: ''
          }}
          onClose={onCloseReagendar}
          onReagendar={ReagendarReuniao}
          max={Reuniao?.dataLimiteFim}
          min={Reuniao?.dataLimiteInicio}
        />
      }

      {DeletarIsOpen &&
        <ModalDelete
          isOpen
          onConfirm={Deletar}
          onRequestClose={onCloseDelete}
          message={Reuniao?.isEtapa ? 'Ao excluir essa reunião, também será excluído a etapa na qual ela está vinculada, deseja realmente excluir essa reunião?' : 'Deseja realmente excluir essa reunião 1:1?'}
        />
      }

      <DrowerFiltroReuniaoOne
        isOpen={DrowerIsOpen}
        onClose={() => setDrowerIsOpen(false)}
        areas={Areas}
        cargos={Cargos}
        tags={Tags}
        onFilter={Filtrar}
        filtro={Filtro}
      />
        <Flex justifyContent={'space-between'} mb={'1rem'}>
          <Flex gap={'.5rem'} flexDir={'column'}>
            <h1>Reunião 1:1</h1>
            <Flex>
              <DashboardSwitch />
            </Flex>
          </Flex>
          <ButtonCmp onClick={() => nav(`${pathname.replace('Dashboard/Corporativo', 'Criar')}`)} VarColor='Green2'>Solicitar reunião 1:1</ButtonCmp>
        </Flex>
        <Grid templateColumns={'repeat(6, 1fr)'} gap={'1.5rem'}>
          <BoxReunioesStatus
            VarColor='Green2'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 5).length}
            statusNome='Finalizadas'
          />

          <BoxReunioesStatus
            VarColor='Green2'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 1).length}
            statusNome='Aceitas'
          />

          <BoxReunioesStatus
            VarColor='Purple1'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 3).length}
            statusNome='Reagendadas'
          />

          <BoxReunioesStatus
            VarColor='Red'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 2).length}
            statusNome='Recusadas'
          />

          <BoxReunioesStatus
            VarColor='Gray3'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 0).length}
            statusNome='Aguardando resposta'
          />

          <BoxReunioesStatus
            VarColor='Orange'
            statusQuantidade={ModelSemFiltros.tabela.filter(e => e.status === 4).length}
            statusNome='Atrasadas'
          />

        </Grid>

      <WhiteContainer margin='1rem 0'>
        <ContainerFiltro>
            <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
              <Flex alignItems={'center'}>
                <Text fontWeight={'500'} fontSize='.875rem' paddingRight={'1rem'} borderRight={'2px solid var(--c5)'}>Filtros aplicados</Text>
                <Flex padding={'0 1rem'} gap={'.5rem'}>
                  {!PropInvalida(Filtro.dataInicio) &&
                    <Tag
                      padding='.5rem'
                    >Início: {Filtro.dataInicio}</Tag>
                  }

                  {!PropInvalida(Filtro.dataFim) &&
                    <Tag
                      padding='.5rem'
                    >Fim: {Filtro.dataFim}</Tag>
                  }

                  {!PropInvalida(Filtro.cargoId) &&
                    <Tag
                      padding='.5rem'
                    >{Cargos.find(e => e.id === Filtro.cargoId)?.nome ?? ''}</Tag>
                  }

                  {!PropInvalida(Filtro.departamentoId) &&
                    <Tag
                      padding='.5rem'
                    >{Areas.find(e => e.id === Filtro.departamentoId)?.nome ?? ''}</Tag>
                  }

                  {!PropInvalida(Filtro.tagId) &&
                    <Tag
                      padding='.5rem'
                    >{Tags.find(e => e.id === Filtro.tagId)?.nome ?? ''}</Tag>
                  }
                </Flex>
              </Flex>
              <Box color='var(--c6)' _hover={{ color: 'var(--Azul)' }}><FaFilter onClick={() => setDrowerIsOpen(true)} cursor={'pointer'} size={22} /></Box>
            </Flex>
        </ContainerFiltro>
      </WhiteContainer>
        <Tabs colorScheme={'blue'} variant='enclosed-colored' mt={'.25rem'}>
          <TabList w={'80%'}>
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }} onClick={() => { setPagina(0); setOrdenador({ ...Ordenador, filtro: 0 }) }}>Lista por colaboradores</Tab>
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }} onClick={() => { setPagina(0); setOrdenador({ ...Ordenador, filtro: 0 }) }}>Lista por reunião</Tab>
          </TabList>
          <TabPanels bgColor={'#fff'}>
            <TabPanel p={'1rem'}>
              <Paginador
                next={(e) => setPagina(e)}
                prev={(e) => setPagina(e)}
                tamanho={Tamanho}
                setTamanho={(e) => setTamanho(e)}
                paginaAtual={Pagina}
                qtdElementos={Model.quantidadePorColaborador}
                hideTop={true}
              >
                <TabListaColaboradores
                  tabelaPorColaborador={Model.tabelaPorColaborador}
                  onOrdenar={(filtro, ascendente) => { setOrdenador({ filtro: filtro, ascendente: ascendente }) }}
                />
              </Paginador>
            </TabPanel>
            <TabPanel p={'1rem'}>
              <Paginador
                next={(e) => setPagina(e)}
                prev={(e) => setPagina(e)}
                tamanho={Tamanho}
                setTamanho={(e) => setTamanho(e)}
                paginaAtual={Pagina}
                qtdElementos={Model.quantidadeFiltrada}
                hideTop={true}
              >
                <TabListaPorReuniao
                  onReenviar={ReenviarConvite}
                  onRemarcar={onOpenReagendar}
                  onExcluir={onOpenDelete}
                  reunioes={Model.tabela}
                  individual={false}
                  onOrdenar={(filtro, ascendente) => { setOrdenador({ filtro: filtro, ascendente: ascendente }) }}
                />
              </Paginador>
            </TabPanel>
          </TabPanels>
      </Tabs>
    </Body>
  )
}

/* eslint-disable no-return-assign */
import { Avatar, CircularProgress, CircularProgressLabel, Flex, Image, Text } from '@chakra-ui/react'
import { Container } from './styles'

import IconeLove from '../../../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../../../assets/IconeTriste.svg'

import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { useNavigate, useLocation } from 'react-router-dom'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'

export interface iDadoTabela {
  id: string
  origem: string
  totalAcoes: number
  totalAcoesRealizadas: number
  dataInicio: string
  dataFim: string
  colaborador?: iAvatarXInformacoes
  colaboradorId?: string
  responsavelId?: string
  ultimaAvaliacao?: number
  status: number
  temAcaoAtrasada: boolean
}

interface iTabelaProps {
  slice: number
  dados: iDadoTabela[]
  fontSize?: string
  iconSize?: string
  variant?: 'progress'|'editar'|'criar'
  modoHome?: boolean
}

export const Tabela: React.FC<iTabelaProps> = ({ dados, slice, fontSize, iconSize, variant, modoHome }) => {
  variant = variant === 'editar' ? 'editar' : variant === 'criar' ? 'criar' : 'progress'
  const { pathname } = useLocation()
  const nav = useNavigate()
  // Status
  // Em criacao
  // Em andamento
  // Atrasado

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function acessarDashboard(variant: string): void {
    if (variant === 'progress') {
      nav('/Desempenho/PDI/Dashboard/Individual')
    }
  }

  function UltimaAvaliacao(nota: number, status: number): any {
    if (status === 0) {
      return <Text fontWeight={'900'} color={'var(--Azul)'}>Em criação</Text>
    }

    if (status === 1) {
      return <Text fontWeight={'900'} color={'var(--Azul)'}>Em aprovação</Text>
    }

    if (nota === 1) {
      return <Image src={IconeTriste} />
    } else if (nota === 2) {
      return <Image src={IconeMagoado} />
    } else if (nota === 3) {
      return <Image src={IconeFeliz} />
    } else if (nota === 4) {
      return <Image src={IconePiscada} />
    } else {
      return <Image src={IconeLove} />
    }
  }

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <h3>{variant === 'progress' ? 'PDIs em andamento' : variant === 'editar' ? 'PDIs aguardando alteração' : 'PDIs pendentes para subir'}</h3>
        {pathname?.includes('Colaborador') && <ButtonCmp VarColor='Green2' onClick={() => nav('/Desempenho/PDI/Dashboard/Individual')}>Gestão de PDIs</ButtonCmp>}
      </Flex>
      <ElementoTooltipCmp w={'100%'} label={modoHome && variant === 'progress' ? 'Clique para visualizar os PDIs em andamento' : ''} bg={'var(--Azul)'}>
        <Container style={{ fontSize: fontSize ?? '16px', cursor: modoHome && variant === 'progress' ? 'pointer' : undefined }} onClick={() => acessarDashboard(variant ?? '')} >
          <thead>
            <tr style={{ fontSize: fontSize ?? '16px' }}>
              {dados.some(e => e.colaborador) && <th style={{ paddingLeft: '.5rem' }}>Colaborador</th>}
              <th>{variant === 'criar' ? 'Pesquisa' : 'Origem'}</th>
              {variant !== 'criar' && <th>Quantidade de ações</th>}
              {/* {variant !== 'criar' && <th>Data inicial</th>} */}
              <th>Data final</th>
              {variant !== 'criar' && <th>Última avaliação</th>}
              {variant === 'progress' &&
                <th>Progresso </th>
              }
            </tr>
          </thead>
          <tbody>
            {
              dados.slice(0, slice).map((e, i) => {
                return (
                  <tr style={{ color: e.temAcaoAtrasada ? 'var(--Red)' : '' }} key={i}>
                    {dados.some(e => e.colaborador) &&
                      <th>
                        <Flex gap={'1.5rem'} pt={'.125rem'} alignItems={'center'} mb={'.5rem'}>
                          <Avatar size={'sm'} src={e.colaborador?.avatar} name={e.colaborador?.info1} />
                          <Flex flexDir={'column'} gap={'.325rem'} alignItems={'start'} color={'black'}>
                            <Text fontSize={'14px'} lineHeight={'16px'} fontWeight={'400'} textAlign={'start'}>
                              {e.colaborador?.info1}
                            </Text>
                            <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
                              {e.colaborador?.info2}
                            </Text>
                          </Flex>
                        </Flex>
                      </th>
                    }
                    <th>{e.origem}</th>
                    {variant !== 'criar' && <th>{e.totalAcoes}</th>}
                    {/* {variant !== 'criar' && <th>{e.dataInicio}</th>} */}
                    <th>{e.dataFim}</th>
                    {variant !== 'criar' && <th>
                      <Flex maxHeight={iconSize ?? '2.5rem'} justifyContent={'center'}>
                        {
                          e.ultimaAvaliacao ?? (e.status === 0 || e.status === 1) ? UltimaAvaliacao(e.ultimaAvaliacao as number, e.status) : '-'
                        }
                      </Flex>
                    </th>}
                    <th>
                      {variant === 'progress' &&
                        <Flex justifyContent={'center'} gap={'.5rem'}>
                          {e.status !== 0 && (
                            <Flex alignItems={'center'}>
                              <CircularProgress
                                size={'2.25rem'}
                                height={'2.125rem'}
                                pos={'relative'}
                                marginBottom={'.5rem'}
                                value={((e.totalAcoesRealizadas / e.totalAcoes) * 100)}
                                color={getColor(((e.totalAcoesRealizadas / e.totalAcoes) * 100))}
                                >
                                <CircularProgressLabel
                                  color={'black'}
                                  top={'65%'}
                                  >
                                  {((e.totalAcoesRealizadas / e.totalAcoes) * 100).toFixed(0)}%
                                </CircularProgressLabel>
                              </CircularProgress>
                              {e.temAcaoAtrasada && (
                                <Text
                                fontSize={'0.813rem'}
                                width='4rem'
                                lineHeight={'.9rem'}
                                marginLeft={'.325rem'}
                                fontWeight={'900'}
                                color={'var(--Red)'}
                                >Ação atrasada</Text>
                              )}
                            </Flex>
                          )}

                          {e.status === 0 && (
                            <ButtonCmp
                            small
                            OutlineColor='Green2'
                            onClick={() => window.location.href = `/Desempenho/PDI/Editar/${e.id}`}
                            >Continuar criação</ButtonCmp>
                          )}
                        </Flex>
                      }
                      {variant === 'editar' &&
                        <Flex justifyContent={'center'}>
                            <ButtonCmp
                              small
                              OutlineColor='Green2'
                              onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)}
                              >Editar</ButtonCmp>
                        </Flex>
                      }
                      {variant === 'criar' &&
                        <Flex justifyContent={'center'}>
                            <ButtonCmp
                              small
                              OutlineColor='Green2'
                              onClick={() => nav(`/Desempenho/PDI/Criar?responsavelId=${e.responsavelId}&colaboradorId=${e.colaboradorId}&avaliacaoId=${e.id}`)}
                              >Subir PDI</ButtonCmp>
                        </Flex>
                      }
                    </th>
                  </tr>
                )
              })
            }
          </tbody>
        </Container>
      </ElementoTooltipCmp>
    </Flex>
  )
}

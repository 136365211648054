import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
max-width: 34.375rem;
overflow-y: auto;
`

export const FlexIcon = styled.div`
display: flex;
margin-bottom: .5rem;
font-weight: 400;
strong{
    font-size: 12px;
    font-weight: 700;
    color: var(--a1);
}

svg,h4{
  font-size: 14px;
  margin-bottom: -.3rem;
}

svg{
  margin-top: .4rem;
  margin-right: .375rem;
  min-width: 2.5rem;
}

small{
font-family: 'Roboto','sans-serif';
font-weight: 700;
font-size: 12px;
color: var(--a5);
}
`

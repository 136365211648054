/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, useToast } from '@chakra-ui/react'
import { ModalBody } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iIndicador } from '../..'
import { useState } from 'react'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { UpdateElementByPosition } from '../../../../../Utils/Helper'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (response: iIndicadorSelecionado[]) => void
  id: string
  indicadores: iIndicador[]
}

export interface iIndicadorSelecionado {
  ano: number
  indicadorId?: string
}

export const ModalGerarComparativo: React.FC<iModal> = ({ onRequestClose, isOpen, indicadores, id, onConfirm }) => {
  const toast = useToast()
  function GetAnoMinimo(): number {
    let anoAtual = new Date().getFullYear()

    if (indicadores.some(e => e.periodos.some(p => p.ano === anoAtual))) {
      return anoAtual
    }

    while (!indicadores.some(e => e.periodos.some(p => p.ano === anoAtual))) {
      anoAtual--
    }

    return anoAtual
  }

  const [IndicadoresSelecionados, setIndicadoresSelecionados] = useState<iIndicadorSelecionado[]>([{ ano: GetAnoMinimo(), indicadorId: '' }])

  function OnSend(): void {
    if (IndicadoresSelecionados.some(e => !e.indicadorId)) {
      toast({
        title: 'Preencha corretamente todos os campos',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }

    if (IndicadoresSelecionados.length < 2) {
      toast({
        title: 'Selecione pelo menos dois anos para serem comparados',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }

    onConfirm(IndicadoresSelecionados)
  }

  function GetAnos(anoAtual?: number): number[] {
    const response: number[] = []

    indicadores.forEach(e => {
      e.periodos.forEach(p => {
        if (!response.includes(p.ano)) {
          response.push(p.ano)
        }
      })
    })
    if (anoAtual) {
      return response.filter(e => anoAtual === e || !IndicadoresSelecionados.some(i => i.ano === e)).sort((a, b) => a - b)
    }

    return response.sort((a, b) => a - b)
  }

  function GetAnoAdd(): number {
    const anosPossiveis = GetAnos()
    const anosJaUsados = IndicadoresSelecionados.map(e => e.ano).sort((a, b) => a - b)

    if (anosPossiveis.length === anosJaUsados.length) return -1

    for (let i = 0; i < anosPossiveis.length; i++) {
      if (!anosJaUsados.find(e => e === anosPossiveis[i])) {
        return anosPossiveis[i]
      }
    }

    return new Date().getFullYear()
  }

  return (
    <ModalBase
      Titulo='Gerar gráfico comparativo'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='38rem'
    >
      <ModalBody>
        <Flex flexDir={'column'} gap={'.75rem'}>
          {IndicadoresSelecionados.map((e, i) => {
            return <Flex gap={'1rem'}>
              <SelectCmp value={e.ano.toString()} OnChange={(str) => setIndicadoresSelecionados(UpdateElementByPosition(IndicadoresSelecionados, i, { ano: parseInt(str), indicadorId: '' }))}>
                {GetAnos(e.ano).map(ano => {
                  return <option value={ano.toString()}>{ano}</option>
                })}
              </SelectCmp>
              <SelectCmp value={e.indicadorId} OnChange={(str) => setIndicadoresSelecionados(UpdateElementByPosition(IndicadoresSelecionados, i, { ...e, indicadorId: str }))}>
                <option value={''}>Selecione um indicador para preencher esse ano no comparativo</option>
                {indicadores.filter(ind => ind.periodos.some(p => p.ano === e.ano)).map(ind => {
                  return <option value={ind.id}>{ind.nome}</option>
                })}
              </SelectCmp>
            </Flex>
          })}
        </Flex>
        <ElementoTooltipCmp bg={'var(--Red)'} label={GetAnos().length === IndicadoresSelecionados.length ? 'Todos os anos com um indicador para comparar já foram adicionados, crie um novo indicador caso queira comparar um ano diferente' : ''}>
          <ButtonCmp isDisabled={GetAnos().length === IndicadoresSelecionados.length} mt={'1.25rem'} VarColor='Green2' onClick={() => setIndicadoresSelecionados([...IndicadoresSelecionados, { ano: GetAnoAdd() }])}>Adicionar indicador no comparativo</ButtonCmp>
        </ElementoTooltipCmp>
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' mt={'.5rem'} gap={'.5rem'}>
        <ButtonCmp
          onClick={onRequestClose}
          VarColor='c6'
        >Voltar</ButtonCmp>

        <ButtonCmp
          onClick={OnSend}
          VarColor='Green2'
        >Gerar comparativo</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

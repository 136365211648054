/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { iColaboradorBase } from '../../../../../interfaces'

interface iProps {
  participante: iColaboradorBase
  responsavel?: boolean
  onRemove?: (id: string) => void
}

export const CardParticipante: React.FC<iProps> = ({ participante, responsavel, onRemove }) => {
  return (
    <Flex width={'100%'} gap={'1.25rem'} background={responsavel ? 'var(--AzulOpacoBg)' : ''} p={'.75rem'} border={responsavel ? '2px solid var(--Azul)' : '2px solid var(--c4)'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'} pos={'relative'}>
      <Flex gap={'.5rem'} alignItems={'center'}>
        <Avatar src={participante.avatar} w={'1.925rem'} h={'1.925rem'}/>
        <Flex flexDir={'column'}>
          {responsavel && <Text fontSize={'14px'} lineHeight={'14px'} color={'var(--Azul)'} fontWeight={700}>Responsável</Text>}
          <Text fontSize={'14px'} lineHeight={'14px'} fontWeight={700}>{participante.nome}</Text>
          <Text fontSize={'13px'} lineHeight={'13px'} fontWeight={400}>{participante.nomeDepartamento}</Text>
        </Flex>
        {onRemove && <Flex cursor={'pointer'} pos={'absolute'} top={'8px'} right={'12px'} onClick={() => onRemove(participante.id)}><FaTrash color='var(--Red)' size={14}/></Flex>}
      </Flex>
    </Flex>
  )
}

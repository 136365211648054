import styled from 'styled-components'

export const Main = styled.div` 
h1{
  margin: 1rem 0rem;
}
`

export const ContainerFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  span{
      display: flex;
      white-space: nowrap;
      font-weight: bold; 
      align-items: center;
      margin-bottom: 1rem;
      input{
          margin: 0rem .5rem 0rem .5rem 
  
      }
  }
  `

export const Legenda = styled.small`
  font-size: 13px;
  color: var(--Gray3);
  font-weight: 499;
  margin-top: -.325rem;
  margin-bottom: .5rem;
`

import styled from 'styled-components'

export const Container = styled.table`
border-radius: 4px;
border: 1px solid var(--gray200);
padding: .5rem 0;
margin-top: 1rem;
border-spacing: 0 .25rem;
font-size: .875rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        color: var(--Azul);
        font-weight: 600;
    }
    th:first-child{
        padding-left: 4rem;
        text-align: start;
    }
}

tbody{

    tr{
        height:4.125rem;
    }

    tr:nth-child(odd) {
        background-color: #EDF2F7;
    }
    
    tr,th{
        border-collapse: collapse;
        padding: .5rem;
        font-weight: 400;
    }
}
`

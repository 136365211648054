import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iCardEtapa, iItemIntegracao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Text } from '@chakra-ui/react'
interface ICardIntegracao {
  etapa: iCardEtapa
  isOpen: boolean
}

export const CardIntegracao: React.FC<ICardIntegracao> = ({ etapa, isOpen }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterNomeTipo(Tipo: number): string {
    if (Tipo === 1) {
      return 'Vídeo'
    } else if (Tipo === 2) {
      return 'Texto'
    } else if (Tipo === 0) {
      return 'Checklist'
    } else {
      return 'PDF'
    }
  }

  function ObterInformacoesCheck(): void {
    appApi.get(`Integracao/${etapa.interacoes[0].idExterno}`)
      .then(response => setItem(response.data.itensIntegracao)).catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterInformacoesCheck()
    }
  }, [isOpen])

  return (
    <Container>
      <h4>Informações a serem verificadas</h4>
      {
        Itens.map((e: iItemIntegracao, i): any => {
          return (
            <span key={i}><Text as={'span'} fontWeight={'900'}>{i + 1}. {ObterNomeTipo(e.tipo)}</Text>:  {e.titulo}</span>
          )
        })
      }
    </Container>
  )
}

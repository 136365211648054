import { FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave, FaTrashAlt } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { iPerguntasFrequentes } from '../../../../../interfaces'
import { ModalBody, ModalHeader, ModalFooter } from './styles'

interface props {
  isOpen: boolean
  pergunta?: iPerguntasFrequentes
  onRequestClose: () => void
  Create: (titulo: string, descricao: string) => void
  Update: (titulo: string, descricao: string) => void
  Delete: () => void
}

export const ModalTopico: React.FC<props> = ({ isOpen, pergunta, onRequestClose, Create, Update, Delete }) => {
  Modal.setAppElement('#root')
  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('')

  useEffect(() => {
    if (pergunta) {
      setTitulo(pergunta.titulo)
      setDescricao(pergunta.descricao)
    }
  }, [pergunta])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <ModalHeader>
        <h2>{pergunta ? 'Editar tópico' : 'Adicionar tópico'}</h2>
        <MdClose size={24} onClick={onRequestClose} />
      </ModalHeader>

      <ModalBody>
        <FormControl marginBottom='.75rem'>
          <InputCmp label='Título' value={Titulo} OnChange={(e) => setTitulo(e)} placeholder='Digite um título para o tópico'/>
        </FormControl>

        <FormControl>
          <TextAreaCmp label='Descricão' value={Descricao} OnChange={(e) => setDescricao(e)} placeholder='Digite uma pergunta para o tópico' />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        {
          (pergunta) && (
            <ButtonCmp
              VarColor='Red'
              onClick={Delete}
              leftIcon={<FaTrashAlt/>}
            >Remover</ButtonCmp>
          )
        }
        {
          pergunta
            ? <ButtonCmp
              VarColor='Green2'
              leftIcon={<FaSave/>}
              onClick={() => Update(Titulo, Descricao)}
            >Salvar</ButtonCmp>
            : <ButtonCmp
              VarColor='Green2'
              leftIcon={<FaSave/>}
              onClick={() => Create(Titulo, Descricao)}
            >Salvar</ButtonCmp>
        }
      </ModalFooter>
    </Modal>
  )
}

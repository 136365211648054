/* eslint-disable @typescript-eslint/no-unused-vars */
import { Divider, Flex, Img, Progress, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { FaCalendarAlt, FaCheckCircle, FaRegClock } from 'react-icons/fa'
import { MdTimer } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { iCompetencia } from '../../../../../../interfaces'
import { iProgressoXAcao } from '../../../../Criar'
import { ModalProgressoAcao } from '../ModalProgresso'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  competencia?: iCompetencia
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
  progresso?: iProgressoXAcao
}

interface iAcaoCardProps {
  acao: iAcao
  pdiId: string
  onOpenComentario: (id: string, acao: iAcao) => void
  onOpenFinalizar: (id: string, acao: iAcao) => void
  onSendProgresso: () => void
}

export const AcaoCard: React.FC<iAcaoCardProps> = ({ pdiId, acao, onOpenComentario, onOpenFinalizar, onSendProgresso }) => {
  const nav = useNavigate()
  const [ProgressoIsOpen, setProgressoIsOpen] = useState(false)

  return (
        <Flex border={`1px solid ${acao.status === 6 ? 'var(--Red)' : 'var(--Azul)'}`} px={'1rem'} py={'.75rem'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'} maxW={'23rem'}>
            <ModalProgressoAcao
                OnSendProgresso={onSendProgresso}
                acao={acao}
                isOpen={ProgressoIsOpen}
                onRequestClose={() => setProgressoIsOpen(false)}
                pdiId={pdiId}
            />
            <Flex gap={'1rem'} height={'100%'} justifyContent={'space-between'} flexDir={'column'} w={'full'}>
                <Flex flexDir={'column'} gap={'.5rem'}>
                    <Flex flexDir={'column'} gap={'.5rem'} w={'100%'}>
                        <Text fontWeight={'700'} display={'flex'} gap={'.325rem'} fontSize={'1.125rem'} alignItems={'center'}>{acao?.competencia && <Img src={acao?.competencia?.icone} w={'2rem'} h={'2rem'}/>} {acao.assunto}</Text>
                        <Text lineHeight={'115%'} marginBottom='0.5rem' fontWeight={'400'} fontSize={'1rem'}><strong>Título:</strong> {acao.titulo}</Text>
                    </Flex>
                    <Text fontWeight={'400'} fontSize={'.875rem'}><strong>Descrição:</strong> {acao.descricao}</Text>
                    <Flex justifyContent={'space-between'} w={'100%'}>
                        {
                            (acao.status === 5 || acao.status === 6) && (
                                <>
                                    <Flex color={acao.status === 6 ? 'var(--Red)' : 'var(--Azul)'} justifyContent={'center'} gap={'1rem'} textAlign='center' w={'100%'} alignItems={'center'}>
                                        <Flex gap={'.325rem'} alignItems={'center'}>
                                            <Text fontWeight={'400'} fontSize={'.813rem'} color={'var(--Gray3)'}>Entrega:</Text>
                                            <Text fontWeight={'700'} fontSize={'.875rem'}>{acao.dataEntrega}</Text>
                                            <Text fontWeight={'700'} fontSize={'.875rem'}><FaCalendarAlt/></Text>
                                        </Flex>
                                        <Text fontWeight={700} as={'strong'} pb={'.325rem'}>-</Text>
                                        <Flex gap={'.325rem'} alignItems={'center'} fontSize={'.875rem'}>
                                            <Text fontWeight={'400'} fontSize={'.813rem'} color={'var(--Gray3)'}>Tempo dedicado:</Text>
                                            <Text fontWeight={'700'} fontSize={'.875rem'}>{acao.tempoDedicacao === 0 ? '-' : `${acao.tempoDedicacao}h` }</Text>
                                            <FaRegClock/>
                                        </Flex>
                                    </Flex>
                                </>
                            )
                        }

                        {
                            (acao.status === 7) && (
                                <Flex justifyContent='center' alignItems={'center'} gap={'.325rem'} color={'var(--Azul)'} w={'100%'}>
                                    <FaCheckCircle size={17}/>
                                    <Text fontWeight={'600'} fontSize={'.875rem'}> Ação concluída</Text>
                                </Flex>
                            )
                        }

                        {
                            (acao.status === 2) && (
                                <Flex color={'var(--Azul)'} justifyContent='center' w={'100%'} alignItems={'center'}>
                                    <Text fontWeight={'700'} fontSize={'.875rem'}>Veja a solicitação de ajuste e atualize a ação</Text>
                                </Flex>
                            )
                        }
                    </Flex>
                </Flex>
                <Flex fontSize={'.875rem'} flexDir={'column'} gap={'.325rem'}>
                    <Text lineHeight={'1rem'}>Progresso</Text>
                    <Flex fontSize={'.813rem'} fontWeight={700} gap={'.5rem'} alignItems={'center'}>
                        <Progress colorScheme={'green'} hasStripe width={'100%'} value={acao.progresso?.progresso ?? 0} /> {acao.progresso?.progresso ?? 0}%
                    </Flex>
                </Flex>
                <Flex justifyContent={'center'} flexDir={'column'} gap={'.75rem'} alignItems={'center'}>
                    {
                        (acao.status === 5 || acao.status === 6) && (
                            <ButtonCmp VarColor='Green2' width={'14.5rem'} onClick={() => setProgressoIsOpen(true)}>
                                Enviar atualização de progresso
                            </ButtonCmp>
                        )
                    }
                    {
                        (acao.status === 5 || acao.status === 6) && (
                            <ButtonCmp
                                onClick={() => onOpenFinalizar(pdiId, acao)}
                                VarColor='Rosa'

                                width={'14.5rem'}
                            >Finalizar ação</ButtonCmp>
                        )
                    }

                    {
                        (acao.status === 2) && (
                            <ButtonCmp
                                size={'sm'}
                                borderRadius={'1.5rem'}
                                onClick={() => nav(`/Desempenho/PDI/Editar/${pdiId}`)}
                                VarColor='Rosa'

                                width={'14.5rem'}>Atualizar
                            </ButtonCmp>
                        )
                    }

                    {
                        (acao.status === 7) && (
                            <ButtonCmp size={'sm'} onClick={() => onOpenComentario(pdiId, acao)} borderRadius={'1.5rem'} VarColor='Rosa'

                            width={'14.5rem'}>Detalhes</ButtonCmp>
                        )
                    }

                    {
                        (acao.status === 1) && (
                            <Flex alignItems={'center'} gap='.5rem' justifyContent={'center'} color={'var(--Azul)'}>
                                <MdTimer size={21}/>
                                <Text
                                    fontWeight={'700'}
                                    fontSize={'.875rem'}
                                >Aguardando aprovação do responsável</Text>
                            </Flex>
                        )
                    }
                </Flex>
            </Flex>
        </Flex>
  )
}

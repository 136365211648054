import { TagLabel, Tag as ChackaraTag, TagRightIcon, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPen, FaSave } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { iNomeId } from '../../../../../../interfaces'

interface iProps {
  isDisabled?: boolean
  model: iNomeId
  onDelete: () => void
  onUpdate: (form: iNomeId) => void
}

export const Tag: React.FC<iProps> = ({ isDisabled, onDelete, onUpdate, model }) => {
  const [Edit, setEdit] = useState(false)
  const [Texto, setTexto] = useState(model.nome)

  function onSave(): void {
    setEdit(false)
    onUpdate({
      id: model.id,
      nome: Texto
    })
  }

  useEffect(() => {
    setTexto(model.nome)
  }, [model])
  return (
        <ChackaraTag
            className={isDisabled ? 'disabled' : ''}
            color={'white'}
            size={'sm'}
            borderRadius={'.25rem'}
            bg={'var(--Azul)'}
        >
            {Edit &&
                <Input
                    value={Texto}
                    onChange={(e) => setTexto(e.target.value)}
                    color='white'
                    p={0}
                    height={'unset'}
                    fontSize='.75rem'
                    fontWeight={'medium'}
                />
            }
            {!Edit && <TagLabel>{Texto}</TagLabel>}

            {!Edit && !isDisabled &&
                <ElementoTooltip label='Editar'>
                    <TagRightIcon
                        color={'white'}
                        cursor={'pointer'}
                        onClick={() => setEdit(true)}
                        as={FaPen}
                    />
                </ElementoTooltip>
            }

            {Edit && !isDisabled &&
                <TagRightIcon
                    color={'white'}
                    cursor={'pointer'}
                    onClick={onSave}
                    as={FaSave}
                />
            }
            {!isDisabled &&
                <ElementoTooltip
                    label='Excluir'
                >
                    <TagRightIcon
                        color={'white'}
                        cursor={'pointer'}
                        onClick={onDelete}
                        as={MdClose}
                    />
                </ElementoTooltip>
            }
        </ChackaraTag>
  )
}

import styled from 'styled-components'

export const BoxIntegracao = styled.div`
width: 100%;
display: flex;
flex-direction: column;
border: 1px solid var(--c4);
padding: 1rem;
border-radius: 0.25rem;
margin-bottom: 1rem;
`

export const FileBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: var(--c2);
border: 1px solid var(--c5);
border-radius: .25rem;
padding: 1rem;
text-align: center;
height: 19rem;
width: 23rem;
font-weight: 500;
position: relative;
input[type=file]{
    display: none;
}

strong{
    font-weight: 700;
}
`

export const TabCard = styled.div<{ isSelected: boolean }>`
    display: flex;
    font-size: .875rem;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    border-top: 2px solid var(--${props => props.isSelected ? 'Azul' : 'c4'});
    font-weight: ${props => props.isSelected ? '700' : '500'};
    
    ${props => {
    if (!props.isSelected) {
        return `:hover{
            color: var(--Azul);
            border-color: var(--AzulOpaco);
            font-weight: 600;
        }`
    }
}}
`

export const GuideText = styled.div`
font-family: 'Roboto','sans-serif';
font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 1.25rem;
`

export const LabelSelecionado = styled.label`
display: flex;
align-items: center;
padding:0.6rem 1rem 0.6rem 1rem;
border-radius: 0.5rem;
border: 1px solid var(--c7);
width: 12rem;

&:hover{
    background: var(--c7);
    color: white;

    svg{
        color: white;
    }
}

cursor: pointer;
    svg{
        color:var(--c7) ;
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const LabelFile = styled.label`
display: flex;
align-items: center;
font-size: .875rem;
padding:.325rem .75rem;
background:#${props => props.theme};
border-radius: 0.25rem;
color: var(--c1);
margin-top: .5rem;
gap: .25rem;

&:hover{
    filter: brightness(1.05);
    box-shadow: var(--SombraBtns);
}
cursor: pointer;
    svg{
        margin: 0rem 0.3rem 0rem .3rem;
    }
`

export const BoxHeader = styled.div`
display: flex;
justify-content: space-between;
span{
    color: var(--Azul);
    font-family: 'Poppins','sans-serif';
    font-weight: 900;
    font-size: 1.3rem;
}


svg{
    cursor: pointer;
}
`

export const BoxBody = styled.div`
display: flex;
flex-direction: column;
padding-top: 1rem;
gap: 1rem;
`

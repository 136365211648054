/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Flex, TableContainer, Table, Thead, Tr, Tbody, Box, Menu, MenuButton, MenuList, MenuItem, Text, Avatar } from '@chakra-ui/react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp, FaCog, FaEye, FaPen, FaRegComment, FaSyncAlt, FaTasks } from 'react-icons/fa'
import { OutlineTable, StyledSpan } from './styles'
import { MdOutlineCancel } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { iLinhaTabela } from '../../Individual'
import { Button } from '../../../../../../components/Button'
import { urlLocal } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'
import { SubstringRoute } from '../../../../../../Utils/Helper'

interface iProps {
  feedbacks: iLinhaTabela[]
  onReenviar?: (id: string) => void
  onExcluir?: (id: string) => void
  onOrdenar: (filtro: number, ascendente: boolean) => void
}
export const TableFeedback: React.FC<iProps> = ({ feedbacks, onExcluir, onReenviar, onOrdenar }) => {
  const { cid, role, adm } = parseJwt()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [UltimoFiltro, setUltimoFiltro] = useState<number>(1)
  const [Ordenacao, setOrdenacao] = useState(false)

  function Ordenar(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
    onOrdenar(filtro ?? 0, Ordenacao)
  }

  function ObterBotao(feedback: iLinhaTabela): any {
    const isGestor = feedback.criador.id === cid
    return (
      <Flex justifyContent={'center'}>
        {((isGestor && feedback.status === 3) || (!isGestor && feedback.status === 4))
          ? <Button
            fontSize={'12px'}
            VarColor='c5'
            rightIcon={<FaSyncAlt />}
          >Aguarde</Button>
          : ((!isGestor && feedback.status === 3) || (isGestor && feedback.status === 4))
              ? <Button
              fontSize={'12px'}
              VarColor='Green2'
              rightIcon={<FaPen />}
              onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${feedback.id}`, '_blank')}
            >Responder</Button>
              : feedback.status === 5
                ? <Button
                fontSize={'12px'}
                VarColor='Rosa'
                rightIcon={<FaEye />}
                onClick={() => nav(SubstringRoute(pathname, 'Dashboard') + `Visualizar/${feedback.id}`)}
              >Visualizar</Button>
                : (isGestor && (feedback.status === 1 || feedback.status === 4 || feedback.status === 3 || feedback.status === 5))
                    ? <Button
                  fontSize={'12px'}
                  VarColor='Green2'
                  rightIcon={<FaCog />}
                  onClick={() => nav(SubstringRoute(pathname, 'Dashboard') + `Configuracao/Editar/${feedback.id}`)}
                >Configurar</Button>
                    : feedback.status === 0
                      ? <Button
                    fontSize={'12px'}
                    VarColor='Green2'
                    rightIcon={<FaTasks />}
                    onClick={() => nav(`Desempenho/Feedback/${feedback.id}/Formularios`)}
                  >Configurar</Button>
                      : <></>
        }
      </Flex>)
  }

  return (
    <OutlineTable>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <th>Organizador {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              <th>Convidado {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              <th>Nome do feedback {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              <th>Data {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              <th>Comentários {UltimoFiltro === 5 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              <th></th>
            </Tr>
          </Thead>
          <Tbody>
            {feedbacks?.map(e => {
              return (
                <Tr key={e.id}>
                  <td>
                    <Flex alignItems={'center'} gap='.5rem' justifyContent='start' pl={'1rem'}>
                      <Avatar name={e.criador.info1} src={e.criador.avatar} size={'sm'} />
                      <Flex flexDir={'column'}>
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.criador.info1}
                        </Text>
                        {e.criador.info2 &&
                          <Text color={'var(--Gray3)'} mt={'.2rem'} fontWeight={'500'} fontSize={'0.625rem'}>{e.criador.info2}</Text>
                        }
                      </Flex>
                    </Flex>
                  </td>
                  <td>
                    <Flex alignItems={'center'} gap='.5rem' justifyContent='start' pl={'1rem'}>
                      <Avatar name={e.convidado.info1} src={e.convidado.avatar} size={'sm'} />
                      <Flex flexDir={'column'} >
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.convidado.info1}
                        </Text>
                        {e.convidado.info2 &&
                          <Text color={'var(--Gray3)'} mt={'.2rem'} fontWeight={'500'} fontSize={'0.625rem'}>{e.convidado.info2}</Text>
                        }
                      </Flex>
                    </Flex>
                  </td>
                  <td>
                    <StyledSpan>
                      <Text color={'Gray1'} title={e.nome} fontSize={'12px'} fontWeight={'400'}>{e.nome.length > 12 ? e.nome.substring(0, 12) + '...' : e.nome}</Text>
                    </StyledSpan>
                  </td>
                  <td>
                    <StyledSpan>
                      <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.dataInicio}</Text>
                    </StyledSpan>
                  </td>
                  <td>
                    <StyledSpan>
                      <Text
                        color={'Gray1'}
                        fontSize={'12px'}
                        fontWeight={'700'}
                        display='flex'
                        alignItems={'center'}
                        gap={'.5rem'}
                      >{e.comentarios} <FaRegComment size={20} /></Text>
                    </StyledSpan>
                  </td>
                  <td>
                    {ObterBotao(e)}
                  </td>

                  <td>
                    <Box width={'2.7rem'} height={'62.5px'}>
                      <Flex marginRight={'1rem'} pl={'.4rem'} pt={'1.4rem'} position={'absolute'} bgColor={'var(--Gray6)'}>
                        {(role !== '0' || adm === 'True') &&
                          <Menu>
                            <MenuButton as={Button} _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'} mr={'-.5rem'}><BsThreeDotsVertical /></MenuButton>
                            <MenuList padding={'0'} boxShadow={'0px 0px 12px rgb(0,0,0,0.3)'} bgColor={'var(--Gray6)'} borderRadius={'.5rem'}>
                              {e.criador.id === cid && (e.status === 1 || e.status === 4 || e.status === 3 || e.status === 5) &&
                                <MenuItem
                                  onClick={() => nav(SubstringRoute(pathname, 'Dashboard') + `Configuracao/Editar/${e.id}`)}
                                  borderRadius={'.5rem .5rem 0 0'}
                                  color={'var(--g1)'}
                                  _focus={{ backgroundColor: 'var(--c1)' }}
                                  backgroundColor={'var(--c1)'}
                                  _hover={{
                                    backgroundColor: 'var(--Azul)',
                                    color: '#ffff'
                                  }}
                                  gap={'1rem'}>
                                  <FaCog />
                                  <span>Configuração</span>
                                </MenuItem>
                              }
                              {(e.status === 3 || e.status === 4) &&
                                <MenuItem
                                  color={'var(--g1)'}
                                  _focus={{ backgroundColor: 'var(--c1)' }}
                                  backgroundColor={'var(--c1)'}
                                  _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
                                  gap={'1rem'}
                                  onClick={() => onReenviar !== undefined ? onReenviar(e.id) : ''}
                                >
                                  <AiOutlineMail />
                                  <span>Reenviar convite</span>
                                </MenuItem>
                              }

                              <MenuItem
                                borderRadius={'0 0.5rem .5rem'}
                                color={'var(--g1)'}
                                _focus={{ backgroundColor: 'var(--c1)' }}
                                backgroundColor={'var(--c1)'}
                                _hover={{ backgroundColor: 'var(--Red)', color: '#ffff' }}
                                gap={'1rem'}
                                onClick={() => onExcluir !== undefined ? onExcluir(e.id) : ''}
                              >
                                <MdOutlineCancel />
                                <span>Excluir</span>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        }
                      </Flex>
                    </Box>
                  </td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </OutlineTable>
  )
}

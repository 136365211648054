/* eslint-disable @typescript-eslint/no-floating-promises */
import { Checkbox, Flex } from '@chakra-ui/react'

interface iProps {
  isChecked?: boolean
  OnChange: (e: boolean) => void
  minW?: string
  fontSize?: string
  isDisabled?: boolean
  styles?: any
  readonly?: boolean
  my?: string
}

export const CheckboxCmp: React.FC<iProps> = ({ styles, isChecked, OnChange, minW, children, isDisabled, fontSize, readonly, my }) => {
  return (
    <Checkbox
      isDisabled={isDisabled}
      borderColor={'var(--Gray4)'}
      size={'md'}
      isChecked={isChecked}
      spacing={'.5rem'}
      style={styles}
      isReadOnly={readonly}
      my={my}
      onChange={(e) => OnChange(e.target.checked)}
    ><Flex lineHeight={'1rem'} fontSize={fontSize ?? '.875rem'} minW={minW}>{children}</Flex></Checkbox>
  )
}

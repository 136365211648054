/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable array-callback-return */
import { Avatar, AvatarBadge, Flex } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { FaRegComment } from 'react-icons/fa'
import { iDiscursiva, iLinhaTabela } from '../..'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'
import { PropInvalida } from '../../../../../Utils/Helper'
import { ModalComentarios } from './components/ModalComentarios'
import { StyledTd, TableDiv } from './styles'

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaConsolidado: number
  mediaSemAuto: number
}

interface iProps {
  linhas: iLinhaTabela[]
  discursivas: iDiscursiva[]
  temColuna: (tipo: number) => boolean
  priorizadas?: string[]
  onPriorizar?: (arr: string[]) => void
}

export const TabelaPDI: React.FC<iProps> = ({ discursivas, linhas, temColuna, onPriorizar, priorizadas }) => {
  const [Competencia, setCompetencia] = useState<string>()
  const [ModalComentariosIsOpen, setModalComentariosIsOpen] = useState(false)

  function MostrarComentario(e: iLinhaTabela): ReactNode {
    const temDiscursiva = discursivas.filter(r => r.titulo.includes(e.competencia)).length > 0
    return (
      <>
        <Flex justifyContent='flex-end'>
          {temDiscursiva && (
            <Avatar
              cursor={'pointer'}
              onClick={() => onOpenModalCompetencia(e.competencia)}
              _hover={{ backgroundColor: 'var(--Gray4)' }}
              bg={'var(--c3)'}
              width={'2rem'}
              height={'2rem'} icon={<FaRegComment size={20} />}
            >
              <AvatarBadge
                boxSize='1.75em'
                bg='#b2c6ea'
                fontSize={'.6rem'}
                fontWeight='bold'
              >
                {discursivas.filter(d => d.titulo.includes(e.competencia)).length}
              </AvatarBadge>
            </Avatar>
          )}
        </Flex>
      </>
    )
  }

  function onOpenModalCompetencia(nome: string): void {
    setCompetencia(nome)
    setModalComentariosIsOpen(true)
  }

  function onCloseModalCompetencia(): void {
    setCompetencia(undefined)
    setModalComentariosIsOpen(false)
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaConsolidado: 0,
      mediaSemAuto: 0
    }

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.autoAvaliacao ?? ''))) {
        medias.mediaAutoAval += (parseFloat(e.autoAvaliacao ?? '') < 0 ? 0 : parseFloat(e.autoAvaliacao ?? ''))
      }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.gestor ?? ''))) {
        medias.mediaGestor += (parseFloat(e.gestor ?? '') < 0 ? 0 : parseFloat(e.gestor ?? ''))
      }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.pares ?? ''))) {
        medias.mediaPares += (parseFloat(e.pares ?? '') < 0 ? 0 : parseFloat(e.pares ?? ''))
      }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.liderados ?? ''))) { medias.mediaLiderados += (parseFloat(e.liderados ?? '') < 0 ? 0 : parseFloat(e.liderados ?? '')) }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.outros ?? ''))) { medias.mediaOutros += (parseFloat(e.outros ?? '') < 0 ? 0 : parseFloat(e.outros ?? '')) }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.mediaSemAuto ?? ''))) { medias.mediaSemAuto += (parseFloat(e.mediaSemAuto ?? '') < 0 ? 0 : parseFloat(e.mediaSemAuto ?? '')) }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.altalideranca ?? ''))) { medias.mediaAltaLideranca += (parseFloat(e.altalideranca ?? '') < 0 ? 0 : parseFloat(e.altalideranca ?? '')) }
    })

    linhas.forEach((e): void => {
      if (!isNaN(parseFloat(e.consolidado ?? ''))) { medias.mediaConsolidado += (parseFloat(e.consolidado ?? '') < 0 ? 0 : parseFloat(e.consolidado ?? '')) }
    })

    const mediasCalculado = [{
      mediaAutoAval: medias.mediaAutoAval / linhas.filter(e => e.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / linhas.filter(e => e.gestor).length,
      mediaPares: medias.mediaPares / linhas.filter(e => e.pares).length,
      mediaLiderados: medias.mediaLiderados / linhas.filter(e => e.liderados).length,
      mediaOutros: medias.mediaOutros / linhas.filter(e => e.outros).length,
      mediaAltaLideranca: medias.mediaAltaLideranca / linhas.filter(e => e.altalideranca).length,
      mediaConsolidado: medias.mediaConsolidado / linhas.filter(e => e.consolidado).length,
      mediaSemAuto: medias.mediaSemAuto / linhas.filter(e => e.mediaSemAuto).length
    }]

    return mediasCalculado
  }

  return (
    <Flex width={'100%'}>
      <ModalComentarios
        isOpen={ModalComentariosIsOpen}
        onRequestClose={onCloseModalCompetencia}
        comentarios={discursivas.filter(r => r.titulo.includes(Competencia ?? ''))}
      />
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>
                <Flex alignItems='center' justifyContent={'space-between'}>
                  <Flex width={'100%'} justifyContent='center'>
                    Competência
                  </Flex>
                  <Flex width={'1.25rem'}></Flex>
                </Flex></th>
              {temColuna(0) && <th>Autoavaliação </th>}
              {temColuna(1) && <th>Gestor</th>}
              {temColuna(2) && <th>Pares</th>}
              {temColuna(3) && <th>Liderados</th>}
              {temColuna(4) && <th>Outros</th>}
              {temColuna(6) && <th>Alta liderança</th>}
              {(temColuna(7)) && <th>Nota final</th>}
              {(!temColuna(7)) && <th>Média do avaliado sem autoavaliação</th>}
              {(priorizadas && onPriorizar) && <th>Priorizar</th>}
            </tr>
          </thead>
          <tbody>
            {linhas.map((e, i) => {
              return (
                <>
                  <tr key={i}>
                    <td>
                      <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                        <Flex width={'100%'} justifyContent={'center'}>
                          {e.competencia}
                        </Flex>
                        {discursivas.filter(r => r.titulo.includes(e.competencia)).length > 0 ? MostrarComentario(e) : ''}
                      </Flex>
                    </td>
                    {temColuna(0) && <StyledTd>{PropInvalida(e.autoAvaliacao) ? '-' : (e.autoAvaliacao)}</StyledTd>}
                    {temColuna(1) && <StyledTd>{PropInvalida(e.gestor) ? '-' : e.gestor}</StyledTd>}
                    {temColuna(2) && <StyledTd>{PropInvalida(e.pares) ? '-' : (e.pares)}</StyledTd>}
                    {temColuna(3) && <StyledTd>{PropInvalida(e.liderados) ? '-' : (e.liderados)}</StyledTd>}
                    {temColuna(4) && <StyledTd>{PropInvalida(e.outros) ? '-' : (e.outros)}</StyledTd>}
                    {temColuna(6) && <StyledTd>{PropInvalida(e.altalideranca) ? '-' : (e.altalideranca)}</StyledTd>}
                    {(temColuna(7)) && <StyledTd>{PropInvalida(e.consolidado) ? '-' : (e.consolidado)}</StyledTd>}
                    {(!temColuna(7)) && <StyledTd>{PropInvalida(e.mediaSemAuto) ? '-' : e.mediaSemAuto}</StyledTd>}
                    {(priorizadas && onPriorizar) && <StyledTd><CheckboxCmp OnChange={(value) => { if (value) { onPriorizar([...priorizadas, e.idQuestao]) } else { onPriorizar(priorizadas.filter(a => { if (a !== e.idQuestao) { return a } })) } }} isChecked={priorizadas.some(a => e.idQuestao === a)}></CheckboxCmp></StyledTd>}
                  </tr>
                </>
              )
            })}

            {
              linhas && handleMedia().map((e, i) => {
                return (
                  <tr key={i}>
                    <td>
                      Média geral
                    </td>
                    {temColuna(0) && <StyledTd>{isNaN(e.mediaAutoAval) ? '-' : e.mediaAutoAval.toFixed(2)}</StyledTd>}
                    {temColuna(1) && <StyledTd>{isNaN(e.mediaGestor) ? '-' : e.mediaGestor.toFixed(2)}</StyledTd>}
                    {temColuna(2) && <StyledTd>{isNaN(e.mediaPares) ? '-' : e.mediaPares.toFixed(2)}</StyledTd>}
                    {temColuna(3) && <StyledTd>{isNaN(e.mediaLiderados) ? '-' : e.mediaLiderados.toFixed(2)}</StyledTd>}
                    {temColuna(4) && <StyledTd>{isNaN(e.mediaOutros) ? '-' : e.mediaOutros.toFixed(2)}</StyledTd>}
                    {temColuna(6) && <StyledTd>{isNaN(e.mediaAltaLideranca) ? '-' : e.mediaAltaLideranca.toFixed(2)}</StyledTd>}
                    {(temColuna(7)) && <StyledTd>{isNaN(e.mediaConsolidado) ? '-' : e.mediaConsolidado.toFixed(2)}</StyledTd>}
                    {(!temColuna(7)) && <StyledTd>{e.mediaSemAuto.toFixed(2)}</StyledTd>}
                    {(priorizadas && onPriorizar) && <StyledTd>-</StyledTd>}
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
font-family: 'Roboto','sans-serif';

h4{
    margin-bottom: 0.625rem;
}

span{
margin-bottom: .5rem;
font-size: 14px;
}
`

import React from 'react'
import { Container } from '../Tabela/styles'
import { iLinhaTabela } from '../../index'

interface iProps {
  model: iLinhaTabela[]
}

export const Tabela: React.FC<iProps> = ({ model }) => {
  function TemColuna(tipo: number): boolean {
    switch (tipo) {
      case 0: return model.some(e => e.autoAvaliacao != null)
      case 1: return model.some(e => e.gestor != null)
      case 2: return model.some(e => e.pares != null)
      case 3: return model.some(e => e.liderados != null)
      case 4: return model.some(e => e.outros != null)
      case 6: return model.some(e => e.altalideranca != null)
      case 7: return model.some(e => e.consolidado != null)
      default: return false
    }
  }
  return (
        <Container>
            <table>
                <thead>
                    <tr>
                        <th>Competências</th>
                        {TemColuna(0) && <th>Autoavaliações</th>}
                        {TemColuna(1) && <th>Gestores</th>}
                        {TemColuna(2) && <th>Pares</th>}
                        {TemColuna(3) && <th>Liderados</th>}
                        {TemColuna(4) && <th>Outros</th>}
                        {TemColuna(6) && <th>Alta liderança</th>}
                        <th>Média geral sem autoavaliação</th>
                        {TemColuna(7) && <th>Nota final</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        model.map((e, i) => {
                          return (
                                <tr key={i}>
                                    <td>{e.competencia}</td>
                                    {TemColuna(0) && <td>{e.autoAvaliacao}</td>}
                                    {TemColuna(1) && <td>{e.gestor}</td>}
                                    {TemColuna(2) && <td>{e.pares}</td>}
                                    {TemColuna(3) && <td>{e.liderados}</td>}
                                    {TemColuna(4) && <td>{e.outros}</td>}
                                    {TemColuna(6) && <td>{e.altalideranca}</td>}
                                    <td>{e.mediaSemAuto}</td>
                                    {TemColuna(7) && <td>{e.consolidado}</td>}
                                </tr>
                          )
                        })
                    }
                </tbody>
            </table>
        </Container>
  )
}

import styled from 'styled-components'

export const TextContainer = styled.div`
    span{
        font-weight: 400;
    }
    strong {
        font-weight: 800;
    }

`

import { Body } from '../../Layouts/Body'
import { QuestionBox } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Flex, useToast } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FaEye } from 'react-icons/fa'
import { TimelineCriarPesquisa } from '../components/Timeline'
import { WhiteContainer } from '../../../components/WhiteContainer'

export interface iModel {
  id: string
  totalCompetencias: number
  totalQuestoes: number
  nomeEscala: string
  status: number
  questoes: Questao[]
  justificativa: boolean
  exibirCompetencia: boolean
}

export interface Questao {
  id: string
  avatar: string
  pergunta: string
  nomeCompetencia: string
}

export const VisualizarFormularioIndicativa: React.FC = () => {
  const path = useLocation().pathname
  const { pesquisaId } = useParams()

  const [Formulario, setFormulario] = useState<iModel>({
    id: '',
    nomeEscala: '',
    questoes: [],
    status: 0,
    totalCompetencias: 0,
    totalQuestoes: 0,
    justificativa: false,
    exibirCompetencia: false
  })
  const nav = useNavigate()

  const toast = useToast()

  const [AprovarFormularioIsOpen, setAprovarFormularioIsOpen] = useState(false)

  function AprovarFormulario(): void {
    appApi.put(`Formulario/${Formulario.id}/Aprovar`).then(() => {
      if (path.includes('Trilha') || path.includes('Processo')) {
        nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)
      } else {
        nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)
      }
    }
    ).catch(() => {
      toast({
        title: 'Não foi inserida nenhuma questão no formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 3000
      })
    })
  }

  function getFormulario(): void {
    appApi.get(`FormularioPesquisa/${pesquisaId as string}/Visualizar`).then(resp => {
      setFormulario(resp.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={AprovarFormularioIsOpen}
        message='Após aprovar o formulário você não poderá mais editá-lo. Deseja continuar?'
        onRequestClose={() => setAprovarFormularioIsOpen(false)}
        onConfirm={() => AprovarFormulario()}
        center
      />
      {path.includes('VisualizarFormulario') &&
        <>
          <TimelineCriarPesquisa color={['var(--Azul)', 'var(--Rosa)']} />
          <Flex justifyContent={'space-between'} mt='.25rem' mb={'.875rem'}>
            <h1>Verifique e aprove o formulário</h1>
          </Flex>
        </>
      }
      <WhiteContainer>
        <Flex justifyContent={'space-between'} alignItems={'center'} mb={'1rem'}>
          <Flex gap={'.5rem'} alignItems={'center'}>
            <h3>Questões selecionadas</h3>
            <Flex alignItems={'center'} justifyContent={'center'} fontSize={'.75rem'} bg={'var(--Azul)'} color={'white'} borderRadius={'50%'} fontWeight={'700'} w={'1.5rem'} h={'1.5rem'}>{Formulario.questoes.length}</Flex>
          </Flex>
          <ButtonCmp onClick={() => nav({ pathname: `${path.concat('/Previsualizar')}` }, { state: { pesquisaId: pesquisaId } })} leftIcon={<FaEye />} VarColor='Green2'>Pré-visualização completa do formulário</ButtonCmp>
        </Flex>
        <Flex flexDir={'column'} gap={'.75rem'}>
          {
            Formulario.questoes.map((e: Questao, index) => {
              return (
                <div key={index}>
                  <QuestionBox>
                    <Flex borderRight={'2px dashed var(--Azul)'} flexDir={'column'} minWidth='8rem' maxWidth='8rem' wordBreak={'break-word'} alignItems={'center'} textAlign='center' margin='0rem 1rem' pb={'.25rem'}>
                      <img src={e.avatar} />
                      <small style={{ width: '80%' }}>{e.nomeCompetencia}</small>
                    </Flex>
                    <span>{e.pergunta}</span>
                  </QuestionBox>
                </div>
              )
            })
          }

        </Flex>
      </WhiteContainer>
      <Flex marginTop={'1rem'} justifyContent={'end'} gap={'.5rem'}>
        {
          (Formulario.status === 2) && (
            <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar</ButtonCmp>
          )
        }

        {
          (path.includes('Trilha')) && (
            <ButtonCmp
              VarColor='Rosa'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'Formularios')}`)}
            >Alterar formulário selecionado</ButtonCmp>
          )
        }

        {
          (Formulario.status !== 2) && (
            <>
              <ButtonCmp
                VarColor='Gray3'
                onClick={() => nav(-1)}
              >Voltar</ButtonCmp>
              <ButtonCmp
                VarColor='Rosa'
                onClick={() => nav(path.replace('VisualizarFormulario', 'Formulario'))}
              >Editar formulário</ButtonCmp>
              <ButtonCmp
                VarColor='Green2'
                isDisabled={!(Formulario.questoes.length > 0)}
                onClick={() => setAprovarFormularioIsOpen(true)}
              >Aprovar formulário</ButtonCmp>
            </>
          )
        }

        {
          (Formulario.status === 2) && (

            (!path.includes('Trilha') && !path.includes('Processo')) && (
              <>
                <ButtonCmp
                  VarColor='Green2'
                  onClick={() => nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)}
                >Visualizar participantes</ButtonCmp>
              </>
            )

          )
        }

        {
          (Formulario.status === 2 && (path.includes('Trilha') || path.includes('Processo'))) && (

            <ButtonCmp
              VarColor='Green2'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)}
            >Configurar envio da pesquisa</ButtonCmp>
          )
        }
      </Flex>
    </Body>
  )
}

import { Flex, Text, useToast } from '@chakra-ui/react'
import { ModalBody } from './styles'
import { useState } from 'react'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { iParticipantesPlanilha } from '../..'
import { FormInpuFile } from './components/FormInputFile'

interface iModal {
  onRequestClose: () => void
  onPush: (participantes: iParticipantesPlanilha[]) => void
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, onPush }) => {
  const toast = useToast()

  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState()
  const [Erros, setErros] = useState<string[]>([])
  const [IsLoading, setIsLoading] = useState(false)

  const AtualizarArquivo = (arquivo: any): void => {
    if (PropInvalida(arquivo)) {
      setNome(undefined)
      setArquivo(undefined)
    } else {
      const formdata = new FormData()
      formdata.append('arquivo', arquivo.target.files[0])
      setArquivo(formdata)
      setNome(arquivo.target.files[0].name)
      toast({
        title: 'Arquivo selecionado com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    }
  }

  function CriarPorPlanilha(): void {
    setIsLoading(true)
    appApi.post('ReuniaoOne/Csv', Arquivo)
      .then((res) => {
        if (res.data.erros.length > 0) {
          setErros(res.data.erros)
          toast({
            title: 'Alguns erros foram encontrados,remova os colaboradores que não fazem parte da lista de erros',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 10000
          })
        } else {
          toast({
            title: 'Colaboradores cadastrados com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        }
        onPush(res.data.sucesso)
      })
      .catch((er) => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function GerarCsvDeExemplo(): void {
    // Criar um blob com o conteúdo CSV
    const blob = new Blob(['Email do organizador(Obrigatorio);Email convidado(Obrigatorio);'], { type: 'text/csv;charset=utf-8' })

    // Criar um link de download
    const linkDownload = document.createElement('a')
    linkDownload.href = URL.createObjectURL(blob)
    linkDownload.download = 'Exemplo.csv'

    // Adicionar o link à página e clicar nele para iniciar o download
    document.body.appendChild(linkDownload)
    linkDownload.click()

    // Remover o link da página após o download
    document.body.removeChild(linkDownload)
  }

  return (
    <ModalBase
      Titulo='Cadastrar participantes em massa'
      isOpen
      onClose={onRequestClose}
      Width='55rem'
    >
      <ModalBody>
        <span style={{ fontSize: '.875rem' }}><strong>Instruções para gerar o arquivo no excel: </strong>
          Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
          Após isso, clique em "Salvar como..." e selecione o formato
          <b> CSV UTF-8 (Delimitado por vírgulas).</b>
          As colunas que estão marcadas com “*” são campos obrigatórios.
          Por favor, realize o preenchimento completo para correto envio.
          <small> Obrigatório a inserção do endereço de email</small>
        </span>

        <Flex mt={'1rem'} w={'100%'}>
          <FormInpuFile onUpdate={AtualizarArquivo} nome={Nome} />
        </Flex>
        {Erros?.length > 0 &&
          <Flex flexDir={'column'} width={'100%'} border={'1px solid var(--Red)'} borderRadius='1rem' mt={'1rem'} as={'ul'} padding='1rem 1rem 1rem 2rem'>
            <Text textAlign={'center'} fontWeight='bold' mb={'1rem'} fontSize={'.875rem'}>Erros na planilha</Text>
            <Flex flexWrap={'wrap'} gap={'1rem'}>
              {Erros?.map((e, i) => {
                return (
                  <li key={i} style={{ whiteSpace: 'nowrap', fontSize: '.813rem' }}>{e}</li>
                )
              })}
            </Flex>
          </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={GerarCsvDeExemplo}
          VarColor='Rosa'
        >Baixar exemplo para preenchimento</ButtonCmp>

        <ButtonCmp
          isDisabled={PropInvalida(Arquivo)}
          onClick={CriarPorPlanilha}
          VarColor='Green2'
          isLoading={IsLoading}
        >Enviar arquivo</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

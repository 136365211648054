/* eslint-disable no-tabs */
import { Flex, FormControl } from '@chakra-ui/react'
import { useState } from 'react'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../../components/ModalBase'
import { TextAreaCmp } from '../../../../../../../components/TextareaCmp'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (text: string) => void
}

export const ModalObservacao: React.FC<iModal> = ({ isOpen, onRequestClose, onConfirm }) => {
  const [Texto, setTexto] = useState('')
  return (
        <ModalBase isOpen={isOpen} onClose={onRequestClose} Titulo={'Observação de etapa'} Width='50rem'>
          <Flex flexDirection={'column'} alignItems={'end'} gap={'1rem'} px={'1.2rem'}>
						<FormControl>
							<TextAreaCmp label='Digite a observação que deseja anexar à esta etapa:' w={'47.5rem'} OnChange={setTexto}/>
						</FormControl>
						<Flex gap={'.5rem'} justifyContent={'end'} mb={'1rem'}>
							<ButtonCmp VarColor='c6' onClick={onRequestClose}>Cancelar</ButtonCmp>
							<ButtonCmp VarColor='Green2' onClick={() => onConfirm(Texto)}>Confirmar</ButtonCmp>
						</Flex>
					</Flex>
        </ModalBase>
  )
}

import { Flex } from '@chakra-ui/react'
import { FaAngleRight, FaCheck } from 'react-icons/fa'
import { iEtapaColaborador } from '../../../../../interfaces'
import { IsEq, ObterCorEtapaPeloTipo, PropInvalida } from '../../../../../Utils/Helper'
import { Card, Circle, Content, FlexElipse, Container } from './styles'

interface CardProps {
  etapa: iEtapaColaborador
  last: boolean
  onClick: () => void
}

export const CardEtapa: React.FC<CardProps> = ({ etapa, last, onClick }) => {
  let Cor = ObterCorEtapaPeloTipo(etapa.tipo)

  function ObterChildrenPorStatus(): any {
    if (etapa.status === 0 && etapa.tipo === 5 && PropInvalida(etapa.conviteId)) {
      return (
        <span style={{ fontSize: '11px' }}>Em <br/> andamento</span>
      )
    }

    if (etapa.status !== 2 && !PropInvalida(etapa.dataInicio) && (etapa.status === 0 || IsEq(etapa.tipo, 2, 7, 10))) {
      return (
        <span style={{ fontSize: '12px' }}>{etapa.dataInicio}</span>
      )
    }

    if (etapa.status === 0) { // Liberado
      return (
        <span style={{ fontSize: '12px' }}>Liberado</span>
      )
    }

    if (etapa.status === 1) { // etapa anterior
      return (
        <span style={{ fontSize: '12px' }}>Após a <br/>anterior</span>
      )
    }

    if (etapa.status === 2) { // Finalizado
      return (
        <FaCheck color='var(--terc6)' size={18} />
      )
    }

    if (etapa.status === 3) { // Data indefinida
      return (
        <span style={{ fontSize: '12px' }}>{etapa.dataInicio ?? 'Pendente'}</span>
      )
    }

    if (etapa.status === 4) { // Data de inicio
      return (
        <span style={{ fontSize: '12px' }}>{etapa.dataInicio}</span>
      )
    }

    if (etapa.status === 6) { // Atrasado
      return (
        <span style={{ fontSize: '12px' }}>Atrasada</span>
      )
    }
  }

  function ObterCorDoCirculo(): string {
    let CorPadrao = '02EA8D'
    if (etapa.status === 5 || etapa.status === 4 || etapa.status === 3) {
      CorPadrao = 'C9C9C9'
      Cor = 'C9C9C9'
    } else if (etapa.status === 6) {
      CorPadrao = 'FF5050'
    }

    return CorPadrao
  }

  function ObterCorContainer(): string {
    let CorPadrao = '02EA8D'
    if (etapa.status !== 0 && etapa.status !== 2 && etapa.status !== 6 && etapa.status !== 7) {
      CorPadrao = 'C9C9C9'
      Cor = 'C9C9C9'
    }
    return CorPadrao
  }

  return (
    <Container theme={ObterCorContainer()} >
      <Content>
        <Circle theme={ObterCorDoCirculo()}>{ObterChildrenPorStatus()}</Circle>
        <FlexElipse color={Cor}>
          <div></div>
          <div></div>
          <div></div>
        </FlexElipse>
        <Card
          disabled={etapa.status === 2 && etapa.tipo !== 3}
          onClick={onClick}
          theme={Cor}>
          <Flex flexDir={'column'}>
            <h4 style={{ fontSize: '16px', fontWeight: 700 }}>{etapa.nome}</h4>
            <span style={{ fontSize: '14px' }}>{etapa.descricao}</span>
          </Flex>
          <FaAngleRight size={24} />
        </Card>
      </Content>
      {
        (!last) && (<hr />)
      }
    </Container>
  )
}

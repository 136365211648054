import styled from 'styled-components'
import { Column } from '../../../../styles/styledGlobal'

export const ColumnTexts = styled(Column)`
color: var(--a3);
h3{
    margin-bottom:.5rem;
}

span:not(:last-child){
margin-bottom: .3rem;
}
`

export const ImgPerfil = styled.img`
cursor: pointer;
&:hover {
    filter: brightness(0.5);
}
`
export const DashBox = styled.div`
border:2px solid var(--${props => props.theme});
border-style: dashed;
padding: .75rem;
position: relative;
border-radius: 0.25rem;
`

import { BoxIntegracao, BoxHeader, BoxBody } from './styles'
import { FaCheck, FaEye, FaMinusCircle, FaPen, FaPlus } from 'react-icons/fa'
import { Alert, AlertIcon, Checkbox, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iItemChecklist, iItemIntegracao } from '../../../../../../interfaces'
import { useState, useEffect } from 'react'
import { Button } from '../../../../../../components/Button'
import { DeleteElementByPosition, PropInvalida } from '../../../../../../Utils/Helper'

interface iCardProps {
  id: string
  position: number
  ItemIntegracao: iItemIntegracao
  onDelete: (id: string) => void
  onUpdateInput: (item: iItemIntegracao) => void
}

export const CardChecklist: React.FC<iCardProps> = ({ ItemIntegracao, position, onDelete, onUpdateInput }) => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iItemIntegracao>({
    id: '',
    tipo: 0,
    titulo: '',
    tipoDescricao: 0
  })
  const [EditDescription, setEditDescription] = useState(false)

  function UpdateTextoItemArray(index: number, Texto: string): void {
    const array = [...Model.checklist ?? []]
    array[index].texto = Texto
    onUpdateInput({ ...Model, checklist: array })
  }

  function AtualizarEnvio(val: number): void {
    if (PropInvalida(ItemIntegracao.envioEmail) || ItemIntegracao.envioEmail?.length === 0) {
      onUpdateInput({ ...Model, envioEmail: [val] })
    } else if (ItemIntegracao.envioEmail?.includes(val)) {
      console.log('valu aqui')
      onUpdateInput({ ...Model, envioEmail: [...Model.envioEmail?.filter(t => t !== val) ?? []] })
    } else {
      onUpdateInput({ ...Model, envioEmail: [...Model.envioEmail ?? [], val] })
    }
  }

  function DeletarItemArray(pos: number): void {
    const arr = DeleteElementByPosition([...Model.checklist ?? []], pos)
    onUpdateInput(
      {
        ...Model,
        checklist: arr
      })
  }

  useEffect(() => {
    setModel(ItemIntegracao)
  }, [ItemIntegracao])

  return (
    <BoxIntegracao>
      <BoxHeader>
        <span>{position}. Check list</span>
        <FaMinusCircle onClick={() => onDelete(ItemIntegracao.id)} />
      </BoxHeader>

      <BoxBody>
        <FormControl marginBottom={'1rem'}>
          <FormLabel fontSize={'xl'}>Título</FormLabel>
          <Input
            borderColor={'var(--Gray4)'}
            value={Model.titulo}
            onChange={(e) => setModel({
              ...Model, titulo: e.target.value
            })}
            onBlur={(e) => onUpdateInput({
              ...Model, titulo: e.target.value
            })}
            type="text" placeholder='Digite o titulo'
          />
        </FormControl>

        <FormControl fontSize={'xl'} marginBottom={'1rem'}>
          <FormLabel fontSize={'xl'}>Caso queira, edite a instrução para o preenchimento do check list pelo colaborador</FormLabel>

          <InputGroup width={'100%'}>
            {
              EditDescription
                ? <InputRightElement
                  children={<FaCheck onClick={() => setEditDescription(false)} color='var(--v2)' />}
                />
                : <InputRightElement
                  children={<FaPen onClick={() => setEditDescription(true)} color='var(--terc2)' />}
                />
            }

            <Input
              value={Model.descricao}
              onChange={(e) => onUpdateInput({
                ...Model, descricao: e.target.value
              })}
              onBlur={(e) => onUpdateInput({
                ...Model, descricao: e.target.value
              })}
              borderColor={'var(--Gray4)'}
              type='text'
              readOnly={!EditDescription}

            />
          </InputGroup>
        </FormControl>

        <FormLabel fontSize={'xl'}>Coloque abaixo os itens que são entregáveis a um colaborador em onboarding</FormLabel>
        <Flex gap={'1rem'} flexDirection={'column'}>
          {
            Model.checklist?.map((e: iItemChecklist, i) => {
              return (
                <Flex key={i}>
                  <Checkbox size={'lg'} marginRight={'1rem'} borderColor={'var(--Gray4)'} />
                  <InputGroup width={'100%'}>
                    <InputRightElement
                      children={<FaMinusCircle cursor={'pointer'}
                        onClick={() => DeletarItemArray(i)}
                        color='var(--terc3)'
                      />}
                    />

                    <Input
                      value={e.texto}
                      onBlur={() => onUpdateInput(Model)}
                      onChange={(r) => UpdateTextoItemArray(i, r.target.value)}
                      borderColor={'var(--Gray4)'}
                      type='text'
                      placeholder='Digite o nome'
                    />
                  </InputGroup>
                </Flex>
              )
            })
          }
          <Button width={'fit-content'} VarColor='v2' onClick={() => onUpdateInput({ ...Model, checklist: [...Model.checklist ?? [], { check: false, texto: '' }] })
          } leftIcon={<FaPlus />}>Adicionar checkbox</Button>
        </Flex>

        <Flex mt='1rem' direction={'column'}>
          <FormLabel fontSize={'xl'}>Quem deve receber o e-mail das respostas</FormLabel>
          <Stack spacing={5} direction='row'>
            <Checkbox
              isChecked={Model.envioEmail?.includes(0)}
              onChange={() => AtualizarEnvio(0)}
            >
              Gestor
            </Checkbox>
            <Checkbox
              isChecked={Model.envioEmail?.includes(1)}
              onChange={() => AtualizarEnvio(1)}
            >
              RH
            </Checkbox>

          </Stack>
        </Flex>

        <Alert status='info' fontFamily={'Roboto'} borderRadius={'0.5rem'} margin={'1.5rem 0'} fontWeight={500} color={'var(--a3)'}>
          <AlertIcon />
          Se o checkbox não for selecionado você receberá uma notificação por e-mail com um aviso sobre a  a informação sem o check do colaborador
        </Alert>
      </BoxBody>
      <Flex justifyContent={'end'}>
        <Button
          leftIcon={<FaEye />}
          fontWeight={'500'}
          borderRadius={'12px'}
          VarColor={'Rosa'}
          onClick={() => nav(`Item/${ItemIntegracao.id}`)}
        >Pré-visualizar</Button>
      </Flex>
    </BoxIntegracao>
  )
}

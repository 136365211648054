/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Alert, AlertDescription, AlertIcon, Box, Flex, FormControl, FormLabel, Grid, Image, Img, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { FaChartArea, FaChartBar, FaClipboardList, FaFileCsv, FaFilter, FaIdCard } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { InfoBox, Titulo } from './styles'
import { RadarChartJs } from './components/RadarChart'
import { useLocation, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { ColumnChart } from './components/ColumnChart'
import { iCompetenciaPDI, iEscala, iGrafico, iNomeId, iPontoAtencaoPDI } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeTrilha.svg'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { Recomendacao } from './components/Recomendacao'
import { TabelaCompetencias } from './components/TabelaCompetencias'
import { DownloadImage, PropInvalida } from '../../../Utils/Helper'
import { ImgSah } from '../../../components/ImgSah'
import { TabelaColaboradores } from './components/TabelaColaboradores'
import { AxiosResponse } from 'axios'
import { MdDownload } from 'react-icons/md'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'
import { iGraficosAlternativos, iLinhaTabela, iTabelasEscalasAlternativas } from '../ResultadoIndividual'
import { Tabela } from './components/Tabela'
import LoadingContainer from '../../../components/LoadingContainer'
import { SelectCmp } from '../../../components/SelectCmp'
import { SwitchCmp } from '../../../components/SwitchCmp'
import { CardItemPDI } from './components/CardItemPDI'
import { CardDepartamentoPDI } from './components/CardDepartamentoPDI'
import { PieChart } from './components/PieChart'
import { BarChart } from './components/BarChart'
import IconeNegativo from '../../../assets/ICONE-AVISO.svg'
import IconePositivo from '../../../assets/ICONE-SAH.svg'
import { AccordionContainer } from '../../../components/AccordionContainer'

export interface iCompetenciasRecomendacoes {
  avatar: string
  colaborador?: string
  colaboradores: iColaboradorRecomedacao[]
  competencia: string
  competenciaId: string
  quantidade: number
  media: number
}

export interface iColaboradorRecomedacao {
  nome: string
  cargo: string
  departamento: string
  id: string
  avatar: string
  media: number
}

interface iModel {
  nomePesquisa: string
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  mediaAutoavaliacao: number
  graficoRadar: iGrafico
  graficoColuna: iGrafico
  graficosColunaAlternativo?: iGrafico[]
  graficoResultadoPorColaborador?: number[]
  graficoEvolucaoColaborador?: number[]
  tabela: iLinhaTabela[]
  tabelaReferencia?: string[]
  filtroDepartamento: iNomeId[]
  filtroCargo: iNomeId[]
  escala: iEscala
  ciclica: boolean
  recomendacoes: iCompetenciaPDI[]
  maisEvoluiuPDI?: iPontoAtencaoPDI[]
  maisPiorouPDI?: iPontoAtencaoPDI[]
  departamentoMaisEvoluiu?: iPontoAtencaoPDI
  departamentoMaisPiorou?: iPontoAtencaoPDI
  tabelasEscalasAlternativas?: iTabelasEscalasAlternativas[]
  graficosEscalasAlternativas?: iGraficosAlternativos[]
  participantes: number[]
}

interface iModelFiltro {
  departamentoId?: string
  cargoId?: string
  gestor?: string
  dataInicio?: string
  dataFim?: string
}

export const ResultadoGeral: React.FC = () => {
  const defaultModel: iModel = {
    nomePesquisa: '',
    ciclica: false,
    escala: { fator: 0, id: '', nome: '', respostas: [] },
    filtroCargo: [],
    filtroDepartamento: [],
    tabela: [],
    totalAvaliadores: 0,
    totalRespostas: 0,
    mediaAutoavaliacao: 0,
    mediaGeral: 0,
    recomendacoes: [],
    participantes: [],
    graficoColuna: { competencias: [], dados: [] },
    graficoRadar: { competencias: [], dados: [] }
  }
  const { pesquisaId } = useParams<{ pesquisaId: string }>()
  const [Model, setModel] = useState<iModel>(defaultModel)
  const [ModelFiltro, setModelFiltro] = useState<iModelFiltro>()
  const [GraficoHierarquia, setGraficoHierarquia] = useState(true)
  const [Base64Hierarquia, setBase64Hierarquia] = useState<string>()
  const [Base64Individual, setBase64Individual] = useState<string>()
  const [open, setOpen] = useState<number[]>([])
  const [Recomendacoes, setRecomendacoes] = useState<iCompetenciasRecomendacoes[]>([])
  const [GraficosEscalasAlternativasHierarquia, setGraficosEscalasAlternativasHierarquia] = useState<Array<{ escala: iEscala, grafico: string }>>([])
  const [GraficosEscalasAlternativasIndividual, setGraficosEscalasAlternativasIndividual] = useState<Array<{ escala: iEscala, grafico: string }>>([])

  const toggleOpen = (e: number): void => {
    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingHierarquia, setIsLoadingHierarquia] = useState(false)

  const { pathname } = useLocation()
  const redirectPage = (url: string): any => { window.location.href = url }

  function GerarQuery(): string {
    let query = ''
    if (ModelFiltro?.departamentoId) {
      query = `departamentoId=${ModelFiltro.departamentoId}`
    }

    if (ModelFiltro?.cargoId) {
      query = query + `${query.length > 0 ? '&' : ''}cargoId=${ModelFiltro.cargoId}`
    }

    if (ModelFiltro?.gestor) {
      query = query + `${query.length > 0 ? '&' : ''}gestor=${ModelFiltro.gestor === '0'}`
    }

    if (ModelFiltro?.dataInicio) {
      query = query + `${query.length > 0 ? '&' : ''}dataInicio=${ModelFiltro.dataInicio}`
    }

    if (ModelFiltro?.dataFim) {
      query = query + `${query.length > 0 ? '&' : ''}dataFim=${ModelFiltro.dataFim}`
    }
    return query
  }

  function getResultado(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/ResultadoGeral?${GerarQuery()}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else {
          setModel({ ...defaultModel, ciclica: Model.ciclica })
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function getPotencialMelhoria(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/statusPdiCompetencias?${GerarQuery()}`)
      .then((res: AxiosResponse<iCompetenciasRecomendacoes[]>) => {
        setRecomendacoes(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function gerarCSV(): void {
    redirectPage(`${urlApi}/AvaliacaoPesquisa/${pesquisaId as string}/statusPdiCsv/?token=${localStorage.getItem('token') as string}`)
  }

  function TemColuna(tipo: number): boolean {
    return Model?.participantes.includes(tipo) ?? false
  }

  function CalcularMediaGeral(tabela: iLinhaTabela[]): number {
    let mediaAutoAval = 0
    tabela.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto.replace(',', '.') ?? '')
      mediaAutoAval = mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })

    return mediaAutoAval / tabela.filter(e => e.autoAvaliacao).length
  }

  function GetGraficosEscalasAlternativas(): any {
    const graficosIndividual: Array<{ escala: iEscala, grafico: string }> = []
    const graficosHierarquia: Array<{ escala: iEscala, grafico: string }> = []
    return Model.graficosEscalasAlternativas?.map((e, i) => {
      if (e.hierarquia.escala && e.individual.escala) {
        return (
          <>
            <RadarChartJs
              getBlob={(g) => { if (e.hierarquia.escala) { graficosHierarquia.push({ escala: e.hierarquia.escala, grafico: g }) }; if (Model.graficosEscalasAlternativas && i === (Model.graficosEscalasAlternativas?.length - 1)) { setGraficosEscalasAlternativasHierarquia(graficosHierarquia) } }}
              grafico={e.hierarquia}
              escala={e.hierarquia.escala}
            />
            <RadarChartJs
              getBlob={(g) => { if (e.individual.escala) { graficosIndividual.push({ escala: e.individual.escala, grafico: g }) }; if (Model.graficosEscalasAlternativas && i === (Model.graficosEscalasAlternativas?.length - 1)) { setGraficosEscalasAlternativasIndividual(graficosIndividual) } }}
              grafico={e.individual}
              escala={e.individual.escala}
            />
          </>
        )
      }
    })
  }

  useEffect(() => {
    getResultado()
    getPotencialMelhoria()
  }, [])

  useEffect(() => {
    setIsLoadingHierarquia(true)
    setTimeout(() => {
      setIsLoadingHierarquia(false)
    }, 700)
  }, [GraficoHierarquia])

  return (
    <Body isLoading={IsLoading}>
      <Flex opacity={'0'} flexDir='column' position={'absolute'} zIndex={-1} justifyContent={'center'} width={'100%'} maxWidth='1168px'>
        {GetGraficosEscalasAlternativas()}
      </Flex>
      <Flex margin={'1rem 0 .5rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: pathname.includes('Desempenho') ? '/Desempenho/AnaliseDesempenho/Gestao' : '/Jornada/Percepcao/Gestao'
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          },
          {
            nome: 'Resultado geral',
            path: ''
          }
        ]} />
      </Flex>

      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap='wrap' mb={'.5rem'}>
        <Flex width={'50%'} flexDir={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
        </Flex>
      </Flex>

      <Flex marginTop={'1rem'} gap={'.5rem'} alignItems='end'>
        <FormControl>
          <SelectCmp title='Área' isDisabled={!PropInvalida(ModelFiltro?.cargoId ?? '')} OnChange={(e) => setModelFiltro({ ...ModelFiltro, departamentoId: e })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {Model?.filtroDepartamento.map((e) => {
              return (
                <option value={e.id} key={e.id}>{e.nome}</option>
              )
            })
            }
          </SelectCmp>
        </FormControl>

        <FormControl>
          <SelectCmp title='Cargo' isDisabled={!PropInvalida(ModelFiltro?.departamentoId ?? '')} value={ModelFiltro?.cargoId} OnChange={(e) => setModelFiltro({ ...ModelFiltro, cargoId: e })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {
              Model?.filtroCargo.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </FormControl>

        <FormControl>
          <SelectCmp title='Gestor' value={ModelFiltro?.gestor} OnChange={(e) => setModelFiltro({ ...ModelFiltro, gestor: e })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            <option value={'0'}>Sim</option>
            <option value={'1'}>Não</option>
          </SelectCmp>
        </FormControl>

        {
          (Model?.ciclica) && (
            <>
              <FormControl>
                <FormLabel fontSize={'xl'}>Período inicial</FormLabel>
                <Input value={ModelFiltro?.dataInicio} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataInicio: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xl'}>Período final</FormLabel>
                <Input value={ModelFiltro?.dataFim} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataFim: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
              </FormControl>
            </>
          )
        }

        <Flex>
          <ButtonCmp onClick={() => { getResultado(); getPotencialMelhoria() }} VarColor='Green2' w={'6rem'} leftIcon={<FaFilter size={12}/>}>Filtrar</ButtonCmp>
        </Flex>
      </Flex>

      <Flex margin={'1rem 0 0 0'} gap={'.5rem'}>
        <InfoBox>
          <FaClipboardList size={34} />
          <div>
            <Text align={'start'} marginBottom={'-0.325rem'} fontSize={'1.25rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalRespostas}</Text>
            <span>Respostas</span>
          </div>
        </InfoBox>
        <InfoBox>
          <Flex width={'1rem'} h={'2.5rem'}>
            <Image src={IconeAvaliadores} objectFit={'contain'}/>
          </Flex>
          <div>
            <Text align={'start'} marginBottom={'-0.325rem'} fontSize={'1.25rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliadores}</Text>
            <span>Avaliadores</span>
          </div>
        </InfoBox>

        <InfoBox style={{ flexDirection: 'column' }}>
          <Text align={'start'} marginBottom={'-0.125rem'} fontSize={'1.25rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.mediaGeral.toFixed(2)}</Text>
          <span>Média dos stakeholders</span>
        </InfoBox>

        <InfoBox style={{ flexDirection: 'column' }}>
          <Text align={'start'} marginBottom={'-0.125rem'} fontSize={'1.25rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.mediaAutoavaliacao.toFixed(2)}</Text>
          <span>Média da autoavaliação</span>
        </InfoBox>
      </Flex>

      {(Model.totalRespostas > 0) && (
        <Tabs marginTop={'1rem'} variant='unstyled'>
          <TabList >
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Gráfico {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 ? ' da escala padrão' : ''}</Tab>
            {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Demais gráficos</Tab>}
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Tabela</Tab>
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Competências</Tab>
            {(Model.maisPiorouPDI || Model.maisEvoluiuPDI) && <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>PDI</Tab>}
            <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Potenciais de melhoria</Tab>
          </TabList>
          <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
            <TabPanel>
              <Flex>
                {(GraficoHierarquia && Model?.escala) && (
                  <Flex flexDir={'column'} width='100%'>
                    <Flex justifyContent={'space-between'}>
                      <Flex gap={'.75rem'}>
                        <Titulo>Gráfico de hierarquia</Titulo>
                        <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                          <ButtonCmp
                            VarColor='Rosa'
                            small
                            onClick={() => DownloadImage(Base64Hierarquia ?? '')}
                          ><MdDownload size={14} /></ButtonCmp>
                        </ElementoTooltipCmp>
                      </Flex>
                      <SwitchCmp value={GraficoHierarquia} OnChange={(e) => { setGraficoHierarquia(!GraficoHierarquia) }} legendaDireita={'Gráfico de hierarquia'} legendaEsquerda={'Gráfico individual'}/>
                    </Flex>
                    <LoadingContainer linhas={12} loading={IsLoadingHierarquia} my='1rem'>
                      <Flex justifyContent={'center'} width='100%'>
                        <Flex width={'50%'}>
                          <RadarChartJs
                            grafico={Model?.graficoRadar ?? { competencias: [], dados: [] }}
                            getBlob={(e: string) => setBase64Hierarquia(e)}
                            escala={Model?.escala}
                          />
                        </Flex>
                      </Flex>
                    </LoadingContainer>
                  </Flex>
                )}

                {(!GraficoHierarquia && Model?.escala) && (
                  <Flex flexDir={'column'} width='100%'>
                    <Flex justifyContent={'space-between'}>
                      <Flex gap={'.75rem'}>
                        <Titulo>Gráfico individual</Titulo>
                        <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                          <ButtonCmp
                            VarColor='Rosa'
                            small
                            onClick={() => DownloadImage(Base64Individual ?? '')}
                          ><MdDownload size={14} /></ButtonCmp>
                        </ElementoTooltipCmp>
                      </Flex>
                      <SwitchCmp value={GraficoHierarquia} OnChange={(e) => { setGraficoHierarquia(!GraficoHierarquia) }} legendaDireita={'Gráfico de hierarquia'} legendaEsquerda={'Gráfico individual'}/>
                    </Flex>
                    <LoadingContainer linhas={12} loading={IsLoadingHierarquia} my='1rem'>
                      <Flex justifyContent={'center'} width='100%'>
                        <Flex width={'50%'}>
                          <RadarChartJs
                            grafico={
                              {
                                competencias: Model?.graficoRadar.competencias ?? [],
                                dados: [(Model?.graficoRadar.dados.find(e => e.nome === 'Autoavaliação') ?? { medias: [], nome: '' }), (Model?.graficoRadar.dados.find(e => e.nome === 'Média sem autoavaliação') ?? { medias: [], nome: '' })]
                              } ?? { competencias: [], dados: [] }
                            }
                            getBlob={(e: string) => setBase64Individual(e)}
                            escala={Model?.escala}
                          />
                        </Flex>
                      </Flex>
                    </LoadingContainer>
                  </Flex>
                )}
              </Flex>
            </TabPanel>
            {Model.graficosEscalasAlternativas && Model.graficosEscalasAlternativas.length > 0 && <TabPanel>
              <Flex flexDir={'column'} gap={'4rem'}>
                {Model.graficosEscalasAlternativas?.map(e => {
                  return (
                    <Flex flexDir={'column'} gap={'1.5rem'}>
                      <div>
                        <Flex flexDir={'column'} width={'100%'}>
                          <Flex flexDir={'column'} width='100%'>
                            <Flex justifyContent={'space-between'}>
                              <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
                                <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'} w={'100%'}>
                                  <Flex gap={'.75rem'} flex={'1'}>
                                    <Titulo>Gráfico individual da {e.individual.escala?.nome}</Titulo>
                                    <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                                      <ButtonCmp
                                        VarColor='Rosa'
                                        small
                                        onClick={() => DownloadImage(GraficosEscalasAlternativasIndividual.find(a => e.individual.escala && (a.escala.id === e.individual.escala.id))?.grafico ?? '')}
                                      ><MdDownload size={14} /></ButtonCmp>
                                    </ElementoTooltipCmp>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <LoadingContainer linhas={12} loading={IsLoading} my={'1rem'}>
                              <Flex justifyContent={'center'} width='100%' position={'relative'}>
                                <Flex width={'50%'}>
                                  {e.individual.escala && <RadarChartJs
                                    grafico={e.individual}
                                    getBlob={() => {}}
                                    escala={e.individual.escala}
                                  />}
                                </Flex>
                              </Flex>
                          </LoadingContainer>
                          </Flex>
                        </Flex>
                        <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                          {e.individual.escala?.respostas.map((es, i) => {
                            return (<span key={i}>{es.resposta}{es.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({es.peso})</strong>};</span>)
                          })}
                        </Flex>
                      </div>
                      <div>
                        <Flex flexDir={'column'} width={'100%'}>
                          <Flex flexDir={'column'} width='100%'>
                            <Flex justifyContent={'space-between'}>
                              <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
                                <Flex justifyContent={'space-between'} gap={'.35rem'} flex={'1'} w={'100%'}>
                                  <Flex gap={'.75rem'} flex={'1'}>
                                    <Titulo>Gráfico por hierarquia da {e.hierarquia.escala?.nome}</Titulo>
                                    <ElementoTooltipCmp label='Baixar gráfico' bg={'var(--Rosa)'}>
                                      <ButtonCmp
                                        VarColor='Rosa'
                                        small
                                        onClick={() => DownloadImage(GraficosEscalasAlternativasHierarquia.find(a => e.hierarquia.escala && (a.escala.id === e.hierarquia.escala.id))?.grafico ?? '')}
                                      ><MdDownload size={14} /></ButtonCmp>
                                    </ElementoTooltipCmp>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Flex>
                            <LoadingContainer linhas={12} loading={IsLoading} my={'1rem'}>
                              <Flex justifyContent={'center'} width='100%' position={'relative'}>
                                <Flex width={'50%'}>
                                  {e.hierarquia.escala && <RadarChartJs
                                    grafico={e.hierarquia}
                                    getBlob={() => {}}
                                    escala={e.hierarquia.escala}
                                  />}
                                </Flex>
                              </Flex>
                          </LoadingContainer>
                          </Flex>
                        </Flex>
                        <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                          {e.hierarquia.escala?.respostas.map((es, i) => {
                            return (<span key={i}>{es.resposta}{es.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({es.peso})</strong>};</span>)
                          })}
                        </Flex>
                      </div>
                    </Flex>
                  )
                })}
              </Flex>
            </TabPanel>}

            <TabPanel>
              <Titulo style={{ marginBottom: '.325rem' }}>Tabela dos resultados por hierarquia</Titulo>
              <Tabela
                linhas={Model?.tabela ?? []}
                temColuna={TemColuna}
                mediaGeral={Model?.mediaGeral}
              />
              {Model.tabelasEscalasAlternativas && Model.tabelasEscalasAlternativas.length > 0 && <Flex flexDir={'column'} gap={'2rem'} mt={'2rem'}>
                {Model.tabelasEscalasAlternativas.map(e => {
                  return (<Flex flexDir={'column'} gap='.5rem'>
                    <Titulo>Tabela da {e.escala?.nome}</Titulo>
                    <Tabela
                      linhas={e.tabela}
                      mediaGeral={CalcularMediaGeral(e.tabela)}
                      temColuna={TemColuna}
                    />
                    <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'} justifyContent={'center'}>
                      {e.escala?.respostas.map((e, i) => {
                        return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                      })}
                    </Flex>
                  </Flex>)
                })}
              </Flex>}
            </TabPanel>

            <TabPanel>
              <Flex gap={'.325rem'} alignItems='center'>
                <FaChartBar color='var(--Azul)'/>
                <Titulo>Média das competências {Model.graficosColunaAlternativo ? 'da escala padrão' : ''}</Titulo>
              </Flex>
              {(Model?.graficoColuna) && (
                <Flex flexDir={'column'}>
                  <ColumnChart
                    id='asdasd'
                    grafico={Model?.graficoColuna}
                  />
                  {
                    (Model.tabelaReferencia) && (
                      <Flex marginLeft={'1rem'} flexDir={'column'} gap={'1rem'}>
                        {
                          Model.tabelaReferencia.map((e, i) => {
                            return (
                              <Text fontSize={'.875rem'} fontWeight={'600'} key={i}>{e}</Text>
                            )
                          })
                        }
                      </Flex>
                    )
                  }
                </Flex>
              )}
              {(Model?.graficosColunaAlternativo) && <AccordionContainer title='Ver competências das demais escalas'>
                <Flex flexDir={'column'} gap={'2rem'} w='100%' py={'2.5rem'}>
                  {Model?.graficosColunaAlternativo.map((e, i) => {
                    return (
                      <Flex flexDir={'column'} gap='.25rem'>
                        <Flex gap={'.325rem'} alignItems='center'>
                          <FaChartBar color='var(--Azul)'/>
                          <Titulo>Média das competências da {e.escala?.nome}</Titulo>
                        </Flex>
                        <Flex flexDir={'column'}>
                          <ColumnChart
                            id={`alternativo-${i}`}
                            grafico={e}
                          />
                          {
                            (Model.tabelaReferencia) && (
                              <Flex marginLeft={'1rem'} flexDir={'column'} gap={'1rem'}>
                                {
                                  Model.tabelaReferencia.map((b, i) => {
                                    return (
                                      <Text fontSize={'.875rem'} fontWeight={'600'} key={i}>{b}</Text>
                                    )
                                  })
                                }
                              </Flex>
                            )
                          }
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>
              </AccordionContainer>}
              {(!PropInvalida(Model?.graficoColuna.dados[0]) && Model?.graficoColuna.dados[0].medias.every(e => e === 0)) && (
                <Alert marginBottom={'1rem'} borderRadius={'8px'} justifyContent='space-between' status='warning'>
                  <Flex>
                    <AlertIcon color={'#FF9900'} />
                    <AlertDescription fontWeight={'500'}>Os avaliadores ainda não responderam a pesquisa</AlertDescription>
                  </Flex>
                </Alert>
              )}
            </TabPanel>

            {(Model.maisPiorouPDI || Model.maisEvoluiuPDI) && <TabPanel>
              <Flex flexDir={'column'} gap={'1.25rem'}>
                {Model.maisEvoluiuPDI && Model.maisEvoluiuPDI.length > 0 && <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex alignItems={'center'} gap='.5rem'><Img w={'2.625rem'} h={'2.625rem'} src={IconePositivo} /> <Titulo>Competências que mais tiveram evolução</Titulo></Flex>
                  <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1.25rem'}>
                    {Model.maisEvoluiuPDI?.map((e, i) => {
                      return (
                        <CardItemPDI
                          model={e}
                          pos={i + 1}
                        />
                      )
                    })}
                  </Grid>
                </Flex>}
                {Model.maisPiorouPDI && Model.maisPiorouPDI.length > 0 && <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex alignItems={'center'} gap='.5rem'><Img w={'2.625rem'} h={'2.625rem'} src={IconeNegativo} /> <Titulo>Competências que mais tiveram piora</Titulo></Flex>
                  <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'1.25rem'}>
                    {Model.maisPiorouPDI?.map((e, i) => {
                      return (
                        <CardItemPDI
                          model={e}
                          pos={i + 1}
                        />
                      )
                    })}
                  </Grid>
                </Flex>}
              </Flex>
              {(Model.departamentoMaisEvoluiu || Model.departamentoMaisPiorou) && <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.75rem'} mt={'1.5rem'}>
                {Model.departamentoMaisEvoluiu && <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex alignItems={'center'} gap='.5rem'><FaIdCard size={22} color='var(--Green2)'/> <Titulo>Departamento com a maior evolução</Titulo></Flex>
                  <CardDepartamentoPDI
                    model={Model.departamentoMaisEvoluiu}
                  />
                </Flex>}
                {Model.departamentoMaisPiorou && <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex alignItems={'center'} gap='.5rem'><FaIdCard size={22} color='var(--Red)'/> <Titulo>Departamento com a maior piora</Titulo></Flex>
                  <CardDepartamentoPDI
                    model={Model.departamentoMaisPiorou}
                  />
                </Flex>}
              </Grid>}
              {(Model.graficoResultadoPorColaborador || Model.graficoEvolucaoColaborador) && <Flex flexDir={'column'} mt={'2rem'} gap={'1rem'}>
                <Flex gap={'.325rem'}>
                  <FaChartArea color='var(--Azul)' size={22}/>
                  <Titulo>Gráficos de índice dos colaboradores</Titulo>
                </Flex>
                <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
                  {Model.graficoResultadoPorColaborador && <Flex flexDir={'column'} border={'2px solid var(--c5)'} borderRadius={'.325rem'} p={'1rem'} gap={'1.25rem'}>
                    <Text fontSize={'1rem'} fontWeight={'600'} textAlign={'center'}>Quantidade de colaboradores por índice de acordo com sua média geral</Text>
                    <PieChart
                      id='grafico-colaboradores-evoluiram'
                      data={Model.graficoResultadoPorColaborador}
                    />
                  </Flex>}
                  {Model.graficoEvolucaoColaborador && <Flex flexDir={'column'} border={'2px solid var(--c5)'} borderRadius={'.325rem'} p={'1rem'} gap={'1.25rem'}>
                    <BarChart
                      data={Model.graficoEvolucaoColaborador}
                      id={'grafico-pessoas-evoluiram'}
                    />
                  </Flex>}
                </Grid>
              </Flex>}
            </TabPanel>}

            <TabPanel>
              <Flex mb={'.5rem'} justifyContent={'space-between'}>
                <Titulo>Competências com maiores potenciais de melhoria</Titulo>
                <ButtonCmp backgroundColor={'var(--Azul)'} onClick={gerarCSV} _active={{ backgroundColor: 'var(--AzulOpaco2)' }} leftIcon={<FaFileCsv />}>Baixar CSV com resultados</ButtonCmp>
              </Flex>
              <Flex margin={'1rem 0'} gap={'1rem'}>
                {
                  Recomendacoes.slice(0, 3).map((e, i) => {
                    return (
                      <Box key={i} as='div' cursor={'pointer'} borderRadius={'.25rem'} transition={'.2s'} _hover={{ boxShadow: '4px 4px 12px 1px #00000020', filter: 'brightness(1.025)' }} onClick={() => { toggleOpen(i) }} flex={1} height={'6.75rem'}>
                        <Recomendacao key={i} pos={i + 1} recomendacao={{ id: e.competenciaId, avatar: e.avatar, nome: e.competencia, pergunta: '', colaboradores: e.quantidade }} />
                      </Box>
                    )
                  })
                }
              </Flex>
              {(open.map((e) => {
                return (
                  <Flex key={Recomendacoes[e].competenciaId} border={'2px solid var(--c2)'} flexDirection={'column'} borderRadius={'.25rem'} marginBottom={'1rem'} pos={'relative'} p={'.75rem'}>
                    <Text
                      pos={'absolute'}
                      onClick={() => toggleOpen(e)}
                      fontWeight='700'
                      _hover={{ color: 'var(--Red)' }}
                      style={{
                        textAlign: 'right',
                        right: '12px',
                        top: '6px',
                        fontSize: '10pt',
                        cursor: 'pointer'
                      }}>X</Text>
                    <Flex alignItems={'center'} gap={'.5rem'}> <img src={Recomendacoes[e].avatar} width={'30px'} height={'30px'} /> <h3> {Recomendacoes[e].competencia} </h3></Flex>
                    <TabelaColaboradores dados={Recomendacoes[e].colaboradores ?? []} />
                  </Flex>
                )
              }))}
              {(Model && Model?.recomendacoes.length > 3) && (
                <Flex border={'1px solid var(--Gray4)'} borderRadius={'.25rem'}>
                  <TabelaCompetencias dados={Recomendacoes}
                    onToggle={(i: number) => toggleOpen(i)} />
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {(Model?.totalRespostas === 0) && (
        <ImgSah textoPersonalizado='Não encontramos resultados com os filtros selecionados' />
      )}
    </Body>
  )
}

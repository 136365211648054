/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'

interface iChartProps{
  id: string
  data: number[]
}

export const PieChart: React.FC<iChartProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: data,
      labels: ['Evoluíram (3,50 a 5,00)', 'Mantiveram (2,50 a 3,49)', 'Pioraram (1 a 2,49)'],
      colors: ['#27AE60', '#BBBBBB', '#EB5757'],
      chart: {
        type: 'pie',
        height: 440,
        width: 440
      },
      legend: {
        enabled: true,
        position: 'left'
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            return val + ' Colaboradores'
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val.toFixed(1) + '%'
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [data])

  return (
        <div id={id}></div>
  )
}

import { Flex, FormControl, Text } from '@chakra-ui/react'
import { CheckboxCmp } from '../../../../../../components/CheckboxCmp'
import { Container } from './styles'

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
  alternativas?: iFormAlternativaResponse[]
  minCaixaSelecao?: number
  maxCaixaSelecao?: number
}

interface iFormAlternativaResponse {
  id: string
  texto: string
}

interface iCaixaSelecaoProps {
  questao: iFormQuestaoResponse
  Selecionar: (questaoId: string, alternativaId: string) => void
  erro: boolean
  selecionadas?: number
}

export const CaixaDeSelecao: React.FC<iCaixaSelecaoProps> = ({ questao, Selecionar, erro, selecionadas }) => {
  function ValidarErro(questoesSelecionadas: number): boolean {
    if (questoesSelecionadas === 0) {
      return false
    } else if (questao.minCaixaSelecao && questoesSelecionadas < questao.minCaixaSelecao) {
      return true
    } else if (questao.maxCaixaSelecao && questoesSelecionadas > questao.maxCaixaSelecao) {
      return true
    }
    return false
  }
  return (
    <Container>
      <div>
        <Text fontWeight={'600'}>{questao.enunciado}</Text>
        {(erro || ValidarErro(selecionadas ?? 0)) && ((questao.maxCaixaSelecao !== null && questao.minCaixaSelecao !== null) ? <span style={{ fontSize: '.875rem', color: 'var(--Red)' }}>* Selecione no máximo {questao.maxCaixaSelecao} e no mínimo {questao.minCaixaSelecao}</span> : <span style={{ fontSize: '.875rem', color: 'var(--Red)' }}>* Essa questão é obrigatória</span>)}
      </div>
      <Flex gap={'1rem'} mt={'.75rem'} flexDir={'column'}>
        {
          questao.alternativas?.map((e, i) => {
            return (
              <FormControl key={i} as={Flex}>
                <CheckboxCmp
                  OnChange={() => Selecionar(questao.id, e.id)}
                >
                  {e.texto}
                </CheckboxCmp>
              </FormControl>
            )
          })
        }
      </Flex>
    </Container>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { IDadoGrafico2 } from '../..'
import { Flex } from '@chakra-ui/react'
import { HSelect } from '../../../../../Utils/Helper'

interface iChartProps {
  model: IDadoGrafico2[]
  setGrafico: (blob: string) => void
}

export const BarChartIndicativaPDF: React.FC<iChartProps> = ({ model, setGrafico }) => {
  const id = 'dalinho-PDF'
  const valorOrdenado = model.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))

  function ObterCor(value: IDadoGrafico2[]): string[] {
    const arr: string[] = []
    value?.forEach(e => {
      if (e.melhoria) {
        arr.push('#F2994A')
      } else {
        arr.push('#27AE60')
      }
    })

    return arr
  }

  async function GerarGrafico(): Promise<void> {
    const options = {
      series: [{
        data: HSelect(valorOrdenado, e => e.media, false)
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: (valorOrdenado.length * 80) < 721 ? (valorOrdenado.length * 80) : 720,
        width: 1000,
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      stroke: {
        show: false,
        colors: ['#ffffff']
      },
      annotations: {
        xaxis: [
          {
            x: 0,
            borderColor: '#c2c2c2',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      dataLabels: {
        enabled: true,
        position: 'start',
        offsetX: 55,
        offsetY: -14,
        formatter: function (value: any, series: any) {
          return `C${((series.dataPointIndex as number) + 1)} (${valorOrdenado[series.dataPointIndex].media})`
        },
        style: {
          colors: ['#000']
        }
      },
      colors: ObterCor(valorOrdenado),
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: '14px',
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (value: any, series: any) {
              return valorOrdenado[series.dataPointIndex].nome + ' - '
            }
          },
          marker: {
            show: false
          }
        }
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        max: 1.2,
        min: -1.2,
        axisBorder: {
          show: false
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })
    setGrafico(dataURL.imgURI)
  }

  useEffect(() => {
    GerarGrafico()
  }, [model])

  return (
    <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
      <div id={id}></div>
    </Flex>
  )
}

import { Flex, Text } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'

interface iModalProps {
  recomendacoes: number
  isOpen: boolean
  onRequestClose: () => void
  onCreate: (opcao: string) => void
}

export const ModalPDI: React.FC<iModalProps> = ({ isOpen, recomendacoes, onCreate, onRequestClose }) => {
  return (
        <ModalBase Width='52.688rem'
            onClose={onRequestClose}
            isOpen={isOpen}
            Titulo='Desenvolvimento do PDI'
        >
            <Flex padding={'0 1rem'}>
                <Text
                    fontSize={'.875rem'}
                    fontWeight={'500'}
                >
                    Para esse novo ciclo você irá trabalhar {recomendacoes} competências.
                    <br />
                    Neste momento você deve selecionar se deseja iniciar um novo ciclo de acompanhamento, gerando um novo PDI, ou se deseja adicionar essas competências no PDI atual.
                </Text>
            </Flex>
            <Flex gap={'.5rem'} justifyContent={'center'} padding='1rem 0'>
                <ButtonCmp
                    onClick={() => onCreate('1')}
                    VarColor='Rosa'
                >Iniciar um novo PDI </ButtonCmp>

                <ButtonCmp
                    onClick={() => onCreate('2')}
                    VarColor='Green2'
                >Continuar no PDI atual (recomendado)</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}

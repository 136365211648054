import styled from 'styled-components'

export const QuestionBox = styled.div`
display: flex;
align-items: center;
background: #3984C410;
border: 1px solid var(--c4);
border-radius:.25rem;
box-shadow: var(--SombraBackground);
padding: 1rem 1rem 1rem 0;
width: 100%;
img{
    height: 2.425rem;
    width: 2.425rem;
    border-radius: 1.563rem;
    border: 2px solid var(--Azul);
}

small{
    font-weight: 600;
    font-size: .75rem;
}

svg{
    color: var(--c5);
}

span{
font-weight: 700;
font-size: .875rem;
color: black;
}
`

export const ResumoBox = styled.div`
background: var(--c1);
display: flex;
flex-direction: column;
border-radius: 1rem;
padding: 1rem;
strong{
    color: var(--terc3);
}

h3{
    color: var(--a3);
    margin-bottom:1rem;
}

span{
    font-weight: 500;
    color: var(--a3);
}

span:not(:last-child){
    margin-bottom: .5rem;
}
`

export const ModalNomear = styled.div`
background: white;
border-radius: var(--RadiusModal);
padding: 1rem;
box-shadow: var(--SombraBackground);
width: 70rem;
display: none;
`

export const ModalHeader = styled.div`
display: flex;
justify-content: center;
margin-bottom:1rem;
h2{
    color:var(--terc4)
}
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
padding: 0.375;
margin-bottom: 1rem;
label{
    color: var(--a3);
    font-weight: bold;
    font-size: 1.4rem;
}
input{
    border: 0.063rem solid var(--c4);
    border-radius:1rem;
}
`

export const ModalFooter = styled.div`
display: flex;
justify-content: end;

a:first-child{
margin-right: 1rem;
}
`

/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Avatar, Table, TableContainer, Tbody, Thead, Tr, Text, AvatarGroup } from '@chakra-ui/react'
import { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { OutlineContainer, OutlineTable, StyledSpan } from './styles'

interface iProps {
  tabela: iLinhaGeral[]
  onOrdenar: (filtro: number, ascendente: boolean) => void
}

export interface iLinhaGeral {
  gestor: iAvatarXInformacoes
  colaboradores: iAvatarXInformacoes[]
  finalizadas: number
  aguardando: number
  total: number
}

export const TableColaboradores: React.FC<iProps> = ({ tabela, onOrdenar }) => {
  const [UltimoFiltro, setUltimoFiltro] = useState<number>(1)
  const [Ordenacao, setOrdenacao] = useState(false)

  function Ordenar(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
    onOrdenar(filtro ?? 0, Ordenacao)
  }

  return (
    <OutlineContainer>
      <OutlineTable>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <th>Gestores {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Colaboradores {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Aguardando {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Finalizadas {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Total {UltimoFiltro === 5 && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
              </Tr>
            </Thead>
            <Tbody>
              {tabela?.map((e, i) => {
                return (
                  <Tr key={i}>
                    <td>
                      <StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
                        <Avatar src={e.gestor.avatar} size={'sm'} />
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.gestor.info1}
                        </Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <AvatarGroup size={'sm'} max={3} spacing={'-3.5'}>
                          {e.colaboradores.map((colaborador, i) => {
                            return (
                              <Avatar key={i} name={colaborador.info1} src={colaborador.avatar} />
                            )
                          })}
                        </AvatarGroup>
                      </StyledSpan>
                    </td>

                    <td>
                      <StyledSpan>
                        <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.aguardando}</Text>
                      </StyledSpan>
                    </td>

                    <td>
                      <StyledSpan>
                        <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.finalizadas}</Text>
                      </StyledSpan>
                    </td>

                    <td>
                      <StyledSpan>
                        <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.total}</Text>
                      </StyledSpan>
                    </td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </OutlineTable>
    </OutlineContainer>
  )
}

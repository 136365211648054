/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Flex, TableContainer, Table, Thead, Tr, Tbody, Avatar, Box, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsArrowRepeat, BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp, FaEye } from 'react-icons/fa'
import { OutlineTable, StyledSpan } from './styles'
import { MdOutlineCancel } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { iLinhaTabela } from '../../../Individual'
import { ObterToken, PropInvalida } from '../../../../../../../Utils/Helper'
import { Button } from '../../../../../../../components/Button'
import { urlApi } from '../../../../../../../services/appApi'
import { CsvButton } from '../../../../../../../components/Buttons/CsvButton'
import { useState } from 'react'

interface iProps {
  reunioes: iLinhaTabela[]
  individual: boolean
  telaColaborador?: string
  onReenviar?: (id: string) => void
  onRemarcar?: (reuniao: iLinhaTabela) => void
  onExcluir?: (reuniao: iLinhaTabela) => void
  onOrdenar: (filtro: number, ascendente: boolean) => void
}
export const TabListaPorReuniao: React.FC<iProps> = ({ reunioes, individual, telaColaborador, onExcluir, onRemarcar, onReenviar, onOrdenar }) => {
  const nav = useNavigate()
  const [UltimoFiltro, setUltimoFiltro] = useState<number>(0)
  const [Ordenacao, setOrdenacao] = useState(false)

  function Ordenar(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
    onOrdenar(filtro ?? 0, Ordenacao)
  }

  function ObterNomeStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'Aguardando'
      case '1':
        return 'Aceita'
      case '2':
        return 'Recusada'
      case '3':
        return 'Reagendada'
      case '4':
        return 'atrasada'
      case '5':
        return 'Finalizada'
	  case '6':
	    return 'Agendamento'
      default: return ''
    }
  }

  function ObterCorStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'var(--Gray3)'
      case '1':
        return 'var(--Azul)'
      case '2':
        return 'var(--Rosa)'
      case '3':
        return 'var(--Purple1)'
      case '4':
        return 'var(--Red)'
      case '5':
        return 'var(--Green3)'
	  case '6':
	    return 'var(--Orange)'
      default: return ''
    }
  }

  function ReturnIcon(filtro: number): any {
    return UltimoFiltro === filtro && !Ordenacao ? <FaChevronUp onClick={() => Ordenar(filtro)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => Ordenar(filtro)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
  }

  return (
		<>
			{!individual &&
				<Flex justifyContent={'space-between'} alignItems={'center'} my={'.325rem'}>
          <h2>Lista de Reuniões 1:1</h2>
					<CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Colaborador?token=${ObterToken()}`} />
				</Flex>
			}
			<OutlineTable>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr _hover={{ bg: 'var(--c1) !important', color: 'black' }}>
								{(telaColaborador === 'solicitadas') && (
									<th>Organizador {ReturnIcon(1)} </th>
								)}
								{(telaColaborador === 'criadas') && (
									<th>Convidado {ReturnIcon(2)}</th>
								)}
								{(!telaColaborador?.length) && (
									<th>Organizador {ReturnIcon(1)}</th>
								)}
								{(!telaColaborador?.length) && (
									<th>Convidado {ReturnIcon(2)}</th>
								)}
								<th>Tipo {ReturnIcon(3)}</th>
								<th>Assunto {ReturnIcon(4)}</th>
								<th>Status {ReturnIcon(5)}</th>
								<th>Data {ReturnIcon(6)}</th>
							</Tr>
						</Thead>
						<Tbody>
							{reunioes?.map(e => {
							  return (
									<Tr key={e.id}>
										{(telaColaborador === 'solicitadas') && (
											<td>
												<StyledSpan style={{ justifyContent: 'start', padding: '.5rem 1rem' }}>
													<Avatar src={e.criador.avatar} width={'2rem'} h={'2rem'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.criador.info1}
													</Text>
												</StyledSpan>
											</td>
										)}
										{(telaColaborador === 'criadas') && (
											<td>
												<StyledSpan style={{ justifyContent: 'start', padding: '.5rem 1rem' }}>
													<Avatar src={e.convidado.avatar} width={'2rem'} h={'2rem'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.convidado.info1}
													</Text>
												</StyledSpan>
											</td>
										)}
										{(!telaColaborador?.length) && (
											<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)} >
												<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
													<Avatar name={e.criador.info1} src={e.criador.avatar} width={'2rem'} h={'2rem'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.criador.info1}
													</Text>
												</StyledSpan>
											</td>
										)}
										{(!telaColaborador?.length) && (
											<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)}>
												<StyledSpan style={{ justifyContent: 'center', paddingLeft: '1rem' }}>
													<Avatar name={e.convidado.info1} src={e.convidado.avatar} width={'2rem'} h={'2rem'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.convidado.info1}
													</Text>
												</StyledSpan>
											</td>
										)}

										<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)}>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.tipo}</Text>
											</StyledSpan>
										</td>
										<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)}>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} title={e.assunto} fontWeight={'400'}>{e.assunto.length > 12 ? e.assunto.substring(0, 12) + '...' : e.assunto}</Text>
											</StyledSpan>
										</td>
										<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)}>
											<StyledSpan>
												<Text
													borderRadius={'6px'}
													color={'#fff'}
													bgColor={ObterCorStatus(e.status)}
													px={'.5rem'} h={'1.4rem'}
													fontSize={'12px'}
													fontWeight={'400'}
												>
													{ObterNomeStatus(e.status)}
												</Text>
											</StyledSpan>
										</td>
										<td style={{ cursor: 'pointer' }} onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)}>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.dataInicio}</Text>
											</StyledSpan>
										</td>
										{
											PropInvalida(telaColaborador) &&
											<td>
												<Box width={'2.7rem'} height={'62.5px'}>
													<Flex marginRight={'1rem'} pl={'.4rem'} pt={'1.4rem'} position={'absolute'}>
														<Menu>
															<MenuButton as={Button} _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'} mr={'-.5rem'} mt={'-.6rem'}><BsThreeDotsVertical /></MenuButton>
															<MenuList padding={'0'} boxShadow={'0px 0px 12px rgb(0,0,0,0.3)'} mt={'4rem'} borderRadius={'.5rem'}>
																<MenuItem onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)} borderRadius={'.5rem .5rem 0 0'} color={'black !important'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} gap={'1rem'}>
																	<FaEye />
																	<span>Visualizar</span>
																</MenuItem>
																{e.status === 1 &&
																	<MenuItem
																		color={'black !important'}
																		_focus={{ backgroundColor: 'var(--c1)' }}
																		backgroundColor={'var(--c1)'}
																		_hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
																		gap={'1rem'}
																		onClick={() => onReenviar !== undefined ? onReenviar(e.id) : ''}
																	>
																		<AiOutlineMail />
																		<span>Reenviar convite</span>
																	</MenuItem>
																}

																{e.status === 1 &&
																	<MenuItem
																		color={'black !important'}
																		_focus={{ backgroundColor: 'var(--c1)' }}
																		backgroundColor={'var(--c1)'}
																		_hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
																		gap={'1rem'}
																		onClick={() => onRemarcar !== undefined ? onRemarcar(e) : ''}
																	>
																		<BsArrowRepeat />
																		<span>Remarcar</span>
																	</MenuItem>
																}
																<MenuItem
																	borderRadius={'0 0.5rem .5rem'}
																	color={'black !important'}
																	_focus={{ backgroundColor: 'var(--c1)' }}
																	backgroundColor={'var(--c1)'}
																	_hover={{ backgroundColor: 'var(--Red)', color: '#ffff' }}
																	gap={'1rem'}
																	onClick={() => onExcluir !== undefined ? onExcluir(e) : ''}
																>
																	<MdOutlineCancel />
																	<span>Excluir</span>
																</MenuItem>
															</MenuList>
														</Menu>
													</Flex>
												</Box>
											</td>
										}
									</Tr>
							  )
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</OutlineTable>
		</ >
  )
}

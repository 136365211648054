import { Flex, Img, Progress, Text } from '@chakra-ui/react'
import { FaCalendarAlt, FaCheckCircle, FaRegClock } from 'react-icons/fa'
import { iCompetencia } from '../../../../../../../interfaces'
import { iProgressoXAcao } from '../../../../../../PDI/Criar'

export interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  competencia?: iCompetencia
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
  progresso?: iProgressoXAcao
}

interface iAcaoCardProps {
  acao: iAcao
}

export const AcaoCard: React.FC<iAcaoCardProps> = ({ acao }) => {
  return (
        <Flex border={`1px solid ${acao.status === 6 ? 'var(--Red)' : 'var(--Azul)'}`} px={'1rem'} py={'.75rem'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'} w={'100%'}>
            <Flex gap={'1rem'} height={'100%'} justifyContent={'space-between'} flexDir={'column'} w={'full'}>
                <Flex flexDir={'column'} gap={'.5rem'}>
                    <Flex flexDir={'column'} gap={'.5rem'} w={'100%'}>
                        <Text fontWeight={'700'} display={'flex'} gap={'.325rem'} fontSize={'.875rem'} alignItems={'center'}>{acao?.competencia && <Img src={acao?.competencia?.icone} w={'2rem'} h={'2rem'}/>} {acao.assunto.replace('/', ' / ')}</Text>
                        <Text lineHeight={'115%'} marginBottom='0.5rem' fontWeight={'400'} fontSize={'.813rem'}><strong>Título:</strong> {acao.titulo}</Text>
                    </Flex>
                    <Text fontWeight={'400'} fontSize={'.813rem'}><strong>Descrição:</strong> {acao.descricao}</Text>
                    <Flex justifyContent={'space-between'} w={'100%'}>
                        {
                            (acao.status === 5 || acao.status === 6) && (
                                <>
                                    <Flex color={acao.status === 6 ? 'var(--Red)' : 'var(--Azul)'} justifyContent={'center'} gap={'1rem'} textAlign='center' w={'100%'} alignItems={'center'}>
                                        <Flex gap={'.325rem'} alignItems={'center'}>
                                            <Text fontWeight={'400'} fontSize={'.813rem'} color={'var(--Gray3)'}>Entrega:</Text>
                                            <Text fontWeight={'700'} fontSize={'.813rem'}>{acao.dataEntrega}</Text>
                                            <Text fontWeight={'700'} fontSize={'.813rem'}><FaCalendarAlt/></Text>
                                        </Flex>
                                        <Text fontWeight={700} as={'strong'} pb={'.325rem'}>-</Text>
                                        <Flex gap={'.325rem'} alignItems={'center'} fontSize={'.813rem'}>
                                            <Text fontWeight={'400'} fontSize={'.813rem'} color={'var(--Gray3)'}>Tempo dedicado:</Text>
                                            <Text fontWeight={'700'} fontSize={'.813rem'}>{acao.tempoDedicacao === 0 ? '-' : `${acao.tempoDedicacao}h` }</Text>
                                            <FaRegClock/>
                                        </Flex>
                                    </Flex>
                                </>
                            )
                        }

                        {
                            (acao.status === 7) && (
                                <Flex justifyContent='center' alignItems={'center'} gap={'.325rem'} color={'var(--Azul)'} w={'100%'}>
                                    <FaCheckCircle size={17}/>
                                    <Text fontWeight={'600'} fontSize={'.813rem'}> Ação concluída</Text>
                                </Flex>
                            )
                        }

                        {
                            (acao.status === 2) && (
                                <Flex color={'var(--Azul)'} justifyContent='center' w={'100%'} alignItems={'center'}>
                                    <Text fontWeight={'700'} fontSize={'.813rem'}>Veja a solicitação de ajuste e atualize a ação</Text>
                                </Flex>
                            )
                        }
                    </Flex>
                </Flex>
                <Flex fontSize={'.813rem'} flexDir={'column'}>
                    <Text lineHeight={'1rem'} fontWeight={700}>Progresso</Text>
                    <Flex fontSize={'.813rem'} fontWeight={700} gap={'.5rem'} alignItems={'center'}>
                        <Progress colorScheme={'green'} hasStripe width={'100%'} value={acao.progresso?.progresso ?? 0} /> {acao.progresso?.progresso ?? 0}%
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}

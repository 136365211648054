import { Flex, Text } from '@chakra-ui/react'

interface iProps {
  titulo?: number
  varColorCircle: string
  varColorText: string
  fontsize: '14px' | '12px'
}
export const CircleText: React.FC<iProps> = ({ varColorCircle, varColorText, fontsize, titulo, children }) => {
  return (
    <Flex alignItems={'center'}>
      <Flex
        width={'10px'}
        bg={`var(--${varColorCircle})`}
        height={'10px'}
        borderRadius={'full'}
        marginRight='.5rem'
        alignItems={'center'}
      />
      {titulo !== undefined && <Text fontWeight={'bold'} style={{ fontSize: fontsize }}>{titulo}</Text>}
      <span style={{ marginLeft: '.5rem', fontSize: fontsize, color: `var(--${varColorText})` }}>{children}</span>
    </Flex>
  )
}

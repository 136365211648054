import styled from 'styled-components'

export const Container = styled.div`
background: white;
border-radius: var(--RadiusModal);
padding: 1rem;
box-shadow: var(--SombraBackground);
width: 50rem;
`

export const ModalHeader = styled.div`
display: flex;
margin-bottom:.5rem;
justify-content: space-between;
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
margin-bottom: .75rem;
label{
    font-weight: bold;
    font-size: .75rem;
}
`

export const ModalFooter = styled.div`
display: flex;
justify-content:end;
`

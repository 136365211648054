import { Checkbox } from '@chakra-ui/react'
import { iPermissaoFuncionalidade, useAuth } from '../../../../../contexts/AuthContext'
import { Container } from './styles'

interface TabelaProps {
  permissao: iPermissaoFuncionalidade[]
}

export const TabelaPermissoes: React.FC<TabelaProps> = ({ permissao }) => {
  const { Atualizar } = useAuth()

  function TemPermissao(tipo: number, funcionaliade: string): boolean {
    return permissao.find(e => e.tipoUsuario === tipo && e.funcionalidade === funcionaliade)?.permitir as boolean
  }

  function DistinctFuncionaliades(): iPermissaoFuncionalidade[] {
    const arr: iPermissaoFuncionalidade[] = []

    permissao.forEach((e) => {
      if (arr.find(r => r.funcionalidade === e.funcionalidade) === undefined) {
        arr.push(e)
      }
    })

    return arr
  }

  function primeiraLetraMaiuscula(palavra: string): string {
    return palavra.charAt(0).toUpperCase() + palavra.slice(1)
  }

  function renomearFuncionalidades(nome: string): string {
    switch (nome.toLowerCase()) {
      case 'dash':
        nome = 'Dashboard'
        break
      case 'onboardings':
        nome = 'Onboarding'
        break

      case 'trilha-onboard':
        nome = 'Trilha de onboarding'
        break

      case 'trilha-desenvolvimento':
        nome = 'Trilha de desenvolvimento'
        break

      case 'trilha-offboard':
        nome = 'Offboarding'
        break

      case '1on1':
        nome = 'Reunião 1:1'
        break

      case 'feedback-sah':
        nome = 'Feedback'
        break

      case 'nova-reuniao-one':
        nome = 'Nova reunião 1:1'
        break

      case 'pesquisa-percepcao':
        nome = 'Pesquisa de percepção'
        break

      case 'pesquisa-interna':
        nome = 'Pesquisa interna'
        break

      case 'imersao-cultural':
        nome = 'Imersão cultural'
        break

      case 'turnover':
        nome = 'Turn Over'
        break

      case 'enps':
        nome = 'eNPS'
        break

      case 'pdi':
        nome = 'PDI'
        break

      case 'pesquisa-desempenho':
        nome = 'Pesquisa de desempenho'
        break

      case 'pec':
        nome = 'Pesquisa de engajamento'
        break

      case 'feedback':
        nome = 'Radar'
        break

      case 'visao-geral':
        nome = 'Visão geral'
        break

      case 'areas':
        nome = 'Áreas'
        break

      case 'funcoes':
        nome = 'Funções'
        break

      case 'colaboradores':
        nome = 'Colaboradores'
        break

      case '9box':
        nome = '9Box(Visualização)'
        break

      default:
        return primeiraLetraMaiuscula(nome)
    }
    return nome
  }

  function PermissoesOrdenadas(): iPermissaoFuncionalidade[] {
    const data: iPermissaoFuncionalidade[] = DistinctFuncionaliades()
    const arr: iPermissaoFuncionalidade[] = []

    if (data.find(r => r.modulo === 'Jornada')) {
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'dash') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'trilha-onboard') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'trilha-desenvolvimento') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'trilha-offboard') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === '1on1') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'feedback-sah') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'nova-reuniao-one') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'pesquisa-percepcao') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'pesquisa-interna') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'imersao-cultural') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Jornada' && r.funcionalidade === 'enps') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
    }

    if (data.find(r => r.modulo === 'Desempenho')) {
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'dash') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'pesquisa-desempenho') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === '9box') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === '1on1') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'feedback-sah') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'nova-reuniao-one') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'pdi') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'imersao-cultural') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Desempenho' && r.funcionalidade === 'indicadores') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
    }

    if (data.find(r => r.modulo === 'Engajamento')) {
      arr.push(data.find(r => r.modulo === 'Engajamento' && r.funcionalidade === 'dash') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Engajamento' && r.funcionalidade === 'pec') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Engajamento' && r.funcionalidade === 'pulse') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Engajamento' && r.funcionalidade === 'feedback') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Engajamento' && r.funcionalidade === 'enps') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
    }

    if (data.find(r => r.modulo === 'Organograma')) {
      arr.push(data.find(r => r.modulo === 'Organograma' && r.funcionalidade === 'visao-geral') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Organograma' && r.funcionalidade === 'areas') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Organograma' && r.funcionalidade === 'funcoes') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
      arr.push(data.find(r => r.modulo === 'Organograma' && r.funcionalidade === 'colaboradores') ?? { funcionalidade: '', modulo: '', permitir: false, tipoUsuario: 2 })
    }

    return arr
  }

  const blackList = ['trilha-onboard', 'trilha-desenvolvimento', 'trilha-offboard', '9box']

  return (
    <Container>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }}>Funcionalidade</th>
          <th>Colaborador</th>
          <th onClick={PermissoesOrdenadas}>Gestor</th>
        </tr>
      </thead>
      <tbody>
        {
          PermissoesOrdenadas().map((e, i) => {
            return (
              <tr key={i}>
                <td style={{ textAlign: 'start' }}>
                  {renomearFuncionalidades(e.funcionalidade)}
                </td>
                <td>
                  <div className='tdCenter'>
                    {!blackList?.some(r => r === e.funcionalidade) &&
                      <Checkbox
                        onChange={(c) => Atualizar(e.modulo, e.funcionalidade, c.target.checked, 0)}
                        isDisabled={e.tipoUsuario === 0 && e.funcionalidade.toLowerCase().includes('dash')}
                        isChecked={TemPermissao(0, e.funcionalidade)}
                        borderColor={'var(--Gray4)'}
                      />}
                  </div>
                </td>
                <td>
                  <div className='tdCenter'>
                    <Checkbox onChange={(c) => Atualizar(e.modulo, e.funcionalidade, c.target.checked, 1)}
                      isChecked={TemPermissao(1, e.funcionalidade)}
                      borderColor={'var(--Gray4)'}
                    />
                  </div>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}

import { Flex, Img, Text } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iCompetencia } from '../../../../../../interfaces'
import { FormatarMensagemEditorDeTexto } from '../../../../../../Utils/Helper'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
  competencia?: iCompetencia
}

interface iModaProps{
  onRequestClose: () => void
  isOpen: boolean
  acao?: iAcao
}

export const ModalVisualizarComentarios: React.FC<iModaProps> = ({ isOpen, acao, onRequestClose }) => {
  return (
        <ModalBase Titulo='Detalhes dessa ação' isOpen={isOpen} onClose={onRequestClose} Width={'50rem'}>
            <Flex flexDir={'column'} pl={'1rem'} pr={'4rem'} pt={'1rem'}>
              <Flex flexDir={'column'}>
                <Text fontSize={'1rem'} display={'flex'} alignItems={'center'}>Competência: <strong style={{ marginLeft: '.25rem' }}>{acao?.assunto}</strong> {acao?.competencia && <Img src={acao?.competencia?.icone} w={'2.25rem'} h={'2.25rem'}/>}</Text>
                <Flex gap={'.75rem'} marginBottom={'.75rem'} alignItems={'center'}>
                  <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Data de inicio: <strong>{acao?.dataInicio}</strong></Text>
                  <Text color={'var(--Gray2)'} fontWeight='700' fontSize={'1.125rem'}>-</Text>
                  <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Data de fim: <strong>{acao?.dataEntrega}</strong></Text>
                </Flex>
              </Flex>
              <Flex flexDir={'column'} marginBottom='.75rem' gap={'.5rem'}>
                <Text fontSize={'14px'}>Título: <strong>{acao?.titulo}</strong></Text>
                <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Descrição: <strong>{acao?.descricao}</strong></Text>
              </Flex>
              <Flex flexDir={'column'}>
                <Text fontSize={'1rem'}>Comentário</Text>
                {!acao?.comentario && <Text color={'var(--Gray2)'} fontSize={'14px'} fontWeight='700'>*Não há comentários nessa ação*</Text>}
                {acao?.comentario && <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(acao?.comentario ?? '') }}></span>}
              </Flex>
            </Flex>
            <Flex padding={'1rem'} justifyContent={'end'}>
              <ButtonCmp onClick={onRequestClose} VarColor='c6'>Fechar</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}

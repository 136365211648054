import { Flex, FormControl, Text } from '@chakra-ui/react'
import { CheckboxCmp } from '../../../../../../components/CheckboxCmp'
import { Container } from './styles'

interface iFormQuestaoResponse {
  enunciado: string
  alternativaResposta: string[]
}

interface iCaixaSelecaoProps {
  questao: iFormQuestaoResponse
}

export const CaixaDeSelecao: React.FC<iCaixaSelecaoProps> = ({ questao }) => {
  return (
    <Container>
      <div>
        <Text fontWeight={'600'}>{questao.enunciado}</Text>
      </div>
      <Flex gap={'1rem'} mt={'.75rem'} flexDir={'column'}>
        {
          questao.alternativaResposta?.map((e, i) => {
            return (
              <FormControl key={i} as={Flex}>
                <CheckboxCmp
                  isChecked={true}
                  OnChange={() => {}}
                >
                  {e}
                </CheckboxCmp>
              </FormControl>
            )
          })
        }
      </Flex>
    </Container>
  )
}

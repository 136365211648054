import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const Container = styled.div<{open?: boolean}>`
padding: 1rem 0rem;
display:flex;
flex-direction:column;
.drop{
    padding: 0rem .5rem;
    color: var(--a5);
    max-height: 0rem;
    transition: max-height 1.2s;
    overflow-y: hidden;
    ${({ open }) => open && `
          max-height: 30rem;
    `}
}
`

export const Header = styled(Flex)<{open?: boolean}>`
justify-content: space-between;
align-items: center;
transition: margin-bottom .7s;

${({ open }) => open && `
       margin-bottom: 1rem;
  `}

h4{
  color: var(--a4);
}


svg:nth-child(1){
    cursor: pointer;
    margin-bottom: 1rem;
    color:var(--a3);
    transition: transform 1.2s;
    ${({ open }) => open && `
        transition: transform 1.2s;
       transform: rotate(135deg);
    `}
}
`

import styled from 'styled-components'

export const Container = styled.div<{isDisabled?: boolean}>`
  padding: .75rem 2.25rem;
  border-radius: .25rem;
  border: 2px solid var(--Azul);
  display: flex;
  align-items: center;
  .gradText{
  background: var(--DegradeAzul);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  }

  ${({ isDisabled }) => isDisabled && `
    opacity:0;
  `}
`

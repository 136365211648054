/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, FormControl, useToast } from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { useState, useEffect } from 'react'
import { iEtapa } from '../../../../interfaces'
import { PropInvalida, ValidarEtapa } from '../../../../Utils/Helper'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { ModalPermissoes } from './components/ModalPermissoes'
import { SelectCmp } from '../../../../components/SelectCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { ElementoTooltipCmp } from '../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../components/TextareaCmp'
import { SwitchCmp } from '../../../../components/SwitchCmp'
import { ModalGrupos } from './components/ModalGrupos'

interface iPostPesquisa {
  titulo: string
  descricao: string
  respondente: number
  respostaGestor: boolean
  anonima: boolean
  pesquisaId?: string
  id?: string
  grupoId?: string
  permissoesColab?: string[]
  nomeEtapa: string
}

type iParams = {
  trilhaId: string
  processoId: string
  etapaId: string
}

interface iPropsForm {
  pesquisa: iPostPesquisa
  etapa: iEtapa
}

export const CriarPesquisaInterna: React.FC = () => {
  const toast = useToast()
  const { pathname } = useLocation()
  const { trilhaId, etapaId, processoId } = useParams<iParams>()
  const nav = useNavigate()
  const [Recorrente, setRecorrente] = useState(false)
  const urlPai = processoId ? 'PesquisaInternaEtapaColaborador' : 'PesquisaInternaEtapa'

  const pesquisaDefault = {
    descricao: '',
    titulo: '',
    respondente: 0,
    respostaGestor: true,
    anonima: false,
    permissoesColab: [],
    nomeEtapa: ''
  }
  const [Pesquisa, setPesquisa] = useState<iPostPesquisa>(pesquisaDefault)
  const [NomeIsInvalid, setNomeIsInvalid] = useState(false)
  const [NomeEtapaIsInvalid, setNomeEtapaIsInvalid] = useState(false)
  const [GerenciarPermissaoIsOpen, setGerenciarPermissaoIsOpen] = useState(false)
  const [GerenciarGruposIsOpen, setGerenciarGruposIsOpen] = useState(false)
  const [Etapa, setEtapa] = useState<iEtapa>({})
  const [Pesquisas, setPesquisas] = useState<iPostPesquisa[]>([])

  function CriarPesquisa(): void {
    setNomeIsInvalid(false)
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    if (!(trilhaId === undefined && etapaId === undefined && processoId === undefined) && Pesquisa.nomeEtapa.length < 5) {
      setNomeEtapaIsInvalid(true)
      toast({
        title: 'O nome da etapa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const pesquisa = { ...Pesquisa, grupoId: Pesquisa.grupoId === '' ? undefined : Pesquisa.grupoId }
    appApi.post('PesquisaInterna', pesquisa)
      .then(res => {
        nav(`${pathname.replace('Criar', `${res.data as string}`)}/Formularios`)
      })
      .catch(err => console.log(err))
  }

  function CreateEtapaComposta(): void {
    setNomeIsInvalid(false)
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.nomeEtapa.length < 5) {
      setNomeEtapaIsInvalid(true)
      toast({
        title: 'O nome da etapa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form: iPropsForm = {
      pesquisa: { ...Pesquisa, grupoId: Pesquisa.grupoId === '' ? undefined : Pesquisa.grupoId, permissoesColab: Pesquisa.permissoesColab ?? [] },
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => {
        if (PropInvalida(Pesquisa.pesquisaId)) {
          nav(`${pathname.replace('Criar', `${res.data as string}`)}/Formularios`)
        } else {
          nav(-1)
        }
      })
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa(res.data.pesquisa)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(): void {
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: { ...Pesquisa, grupoId: Pesquisa.grupoId === '' ? undefined : Pesquisa.grupoId },
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}`, form)
      .then(() => nav(-1))
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  function getPesquisas(): void {
    appApi.get('PesquisaInternaEtapa/Select')
      .then(response => {
        if (response.status === 200) {
          setPesquisas(response.data)
        }
      })
      .catch(err => console.log(err))
  }

  function filtroPesquisa(id: string): void {
    const index = Pesquisas.findIndex(e => e.pesquisaId === id)
    if (index !== -1) {
      const data = {
        descricao: Pesquisas[index].descricao ?? '',
        titulo: Pesquisas[index].titulo ?? '',
        respondente: Pesquisas[index].respondente,
        respostaGestor: Pesquisas[index].respostaGestor,
        anonima: Pesquisas[index].anonima,
        pesquisaId: Pesquisas[index].pesquisaId,
        grupoId: Pesquisas[index].grupoId === '' ? undefined : Pesquisas[index].grupoId,
        nomeEtapa: Pesquisas[index].nomeEtapa
      }
      setPesquisa(data)
    }
  }

  function onSaveGrupo(e: string): void {
    appApi.post(`PesquisaInterna/${Pesquisa.id}/Grupo?grupoId=${e}`)
      .then(res => {
        toast({
          title: 'Grupo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top',
          duration: 4000
        })
        setGerenciarGruposIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function onSavePermissoes(e: string[]): void {
    appApi.post(`PesquisaInterna/${Pesquisa.id}/Permissoes`, { ids: e })
      .then(res => {
        toast({
          title: 'Permissões atualizadas com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top',
          duration: 4000
        })
        setGerenciarPermissaoIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function handleUtilizarPesquisa(val: boolean): void {
    setRecorrente(val)
    if (!val) {
      setPesquisa(pesquisaDefault)
      return
    }
    const data = {
      descricao: Pesquisas[0].descricao ?? '',
      titulo: Pesquisas[0].titulo ?? '',
      respondente: Pesquisas[0].respondente,
      respostaGestor: Pesquisas[0].respostaGestor,
      anonima: Pesquisas[0].anonima,
      pesquisaId: Pesquisas[0].pesquisaId,
      nomeEtapa: Pesquisas[0].nomeEtapa
    }
    setPesquisa(data)
  }

  useEffect(() => {
    if (trilhaId ?? processoId ?? etapaId) {
      if (etapaId) {
        getById()
      }
    }
    getPesquisas()
  }, [])

  return (
    <Body>
      <ModalPermissoes
        isOpen={GerenciarPermissaoIsOpen}
        onCreate={(e) => {
          setPesquisa({ ...Pesquisa, permissoesColab: e })
        }}
        selecionados={Pesquisa.permissoesColab ?? []}
        onClose={() => setGerenciarPermissaoIsOpen(false)}
        onSave={((etapaId || processoId || trilhaId) && !pathname.includes('Criar')) ? onSavePermissoes : undefined}
      />
      <ModalGrupos
        isOpen={GerenciarGruposIsOpen}
        onCreate={(e) => {
          setPesquisa({ ...Pesquisa, grupoId: e })
        }}
        selecionado={Pesquisa.grupoId ?? ''}
        onClose={() => setGerenciarGruposIsOpen(false)}
        onSave={((etapaId || processoId || trilhaId) && !pathname.includes('Criar')) ? onSaveGrupo : undefined}
      />
      <WhiteContainer margin='1rem 0 0 0'>
        <Flex mb={'1rem'} justifyContent={'space-between'}>
          <h1>Criar pesquisa interna</h1>
          <Flex gap={'.75rem'}>
            <ButtonCmp VarColor='Green2' isDisabled={Recorrente} onClick={() => setGerenciarGruposIsOpen(true)}>Grupos (pastas)</ButtonCmp>
            <ButtonCmp VarColor='Rosa' onClick={() => setGerenciarPermissaoIsOpen(true)}>Permissões</ButtonCmp>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} gap='.75rem' marginBottom={'1rem'}>
          {(trilhaId ?? etapaId ?? processoId) && !pathname.includes('Editar') &&
            <Flex alignItems={'center'}>
              <ElementoTooltipCmp label={Pesquisas.length === 0 ? 'Não existem pesquisas disponíveis para selecionar' : ''}>
                <CheckboxCmp
                  OnChange={(e) => {
                    handleUtilizarPesquisa(e)
                  }}
                  isDisabled={Pesquisas.length === 0}
                  isChecked={Recorrente}
                >Utilizar pesquisa existente</CheckboxCmp>
              </ElementoTooltipCmp>
            </Flex>
          }

          {!PropInvalida(Pesquisa.pesquisaId) && (
            <FormControl>
              <SelectCmp
                title='Selecione uma pesquisa'
                value={Pesquisa.pesquisaId}
                OnChange={filtroPesquisa}
                width={'15rem'}
                borderColor={'var(--Gray4)'}
              >
                {
                  Pesquisas?.map((e, i) => {
                    return (
                      <option key={i} value={e.pesquisaId}>{e.titulo}</option>
                    )
                  })
                }
              </SelectCmp>
            </FormControl>
          )}
          {
            (trilhaId ?? etapaId ?? processoId) && (
              <FormControl>
                <InputCmp
                label='Nome da etapa'
                isInvalid={NomeEtapaIsInvalid}
                placeholder='Digite o nome da etapa'
                value={Pesquisa.nomeEtapa}
                type={'text'}
                OnChange={(e) => setPesquisa({ ...Pesquisa, nomeEtapa: e })}
              />
            </FormControl>
            )
          }
          <FormControl>
            <InputCmp
              label='Nome da pesquisa'
              isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
              isInvalid={NomeIsInvalid}
              placeholder='Digite o nome da pesquisa'
              value={Pesquisa.titulo}
              type={'text'}
              OnChange={(e) => setPesquisa({ ...Pesquisa, titulo: e })}
              borderColor={'var(--Gray4)'}
            />
          </FormControl>
          {
            (trilhaId ?? etapaId ?? processoId) && (
              <>
                <Flex flexDir={'column'}>
                  <FormControl marginBottom={'1rem'}>
                    <SelectCmp
                      title='Para quem será enviada a pesquisa'
                      value={Pesquisa.respondente.toString()}
                      OnChange={(e) => setPesquisa({ ...Pesquisa, respondente: parseInt(e) })}
                      width={'15rem'}
                      isDisabled={pathname.includes('Editar')}
                      borderColor={'var(--Gray4)'}
                    >
                      <option value={'0'}>Gestor</option>
                      <option value={'1'}>Colaborador</option>
                    </SelectCmp>
                  </FormControl>

                  <Flex alignItems={'center'}>
                    <CheckboxCmp
                      OnChange={(e) => setPesquisa({ ...Pesquisa, respostaGestor: !e })}
                      isChecked={!Pesquisa.respostaGestor}
                      isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
                    >
                      Selecione caso não queira disponibilizar as respostas ao gestor
                    </CheckboxCmp>
                  </Flex>
                </Flex>
                <CreateEtapaBox
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  variant='Etapa'
                  onModelChange={AtualizarEtapa}
                  keyWord='pesquisa interna'
                />
              </>
            )
          }

          <FormControl>
            <TextAreaCmp label='Descrição da pesquisa' placeholder='Digite a descrição da pesquisa' value={Pesquisa.descricao}
              OnChange={(e) => setPesquisa({ ...Pesquisa, descricao: e })}
              borderColor={'var(--Gray4)'}
              isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
            />
          </FormControl>

          {
            (!pathname.includes('Editar')) && (
              <FormControl as={Flex} justifyContent={'end'}>
                <SwitchCmp
                  value={Pesquisa.anonima}
                  legendaDireita='Pesquisa anônima'
                  OnChange={() => setPesquisa({ ...Pesquisa, anonima: !Pesquisa.anonima })}
                  isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
                />
              </FormControl>
            )
          }
        </Flex>
        <Flex gap={'.5rem'} justifyContent={'end'}>
          <ButtonCmp
            onClick={() => nav(-1)}
            VarColor='c5'
          >Voltar</ButtonCmp>

          {(etapaId === undefined && (trilhaId ?? processoId)) && (
            <ButtonCmp
              VarColor='Green2'
              onClick={CreateEtapaComposta}
            >Criar etapa</ButtonCmp>
          )}

          {(etapaId) && (
            <ButtonCmp
              VarColor='Green2'
              onClick={UpdateEtapaComposta}
            >Atualizar</ButtonCmp>
          )}

          {(trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
            <ButtonCmp
              VarColor='Green2'
              onClick={CriarPesquisa}
            >Próximo</ButtonCmp>
          )}
        </Flex>
      </WhiteContainer>
    </Body>
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, Flex, FormControl, useToast } from '@chakra-ui/react'
import { Body } from '../../Layouts/Body'
import { useState, useEffect } from 'react'
import { FaFilter, FaSearch } from 'react-icons/fa'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { Paginador } from '../../../components/Paginador'
import LoadingContainer from '../../../components/LoadingContainer'
import { ImgSah } from '../../../components/ImgSah'
import { CardAgendamento } from './Components/CardAgendamento'
import { iCardAgendamento, iMensagens } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { ModalDelete } from '../../../components/ModalDelete'
import { ModalAgendamento } from './Components/ModalAgendamento'
import { SelectCmp } from '../../../components/SelectCmp'
import { SearchCmp } from '../../../components/SearchCmp'

export const GestaoAgendamentos: React.FC = () => {
  const toast = useToast()
  const [Tipo, setTipo] = useState('')
  const [Enviado, setEnviado] = useState('')
  const [Ordenador, setOrdenador] = useState('0')
  const [SearchText, setSearchText] = useState('')
  const [selectedAgendamentos, setSelectedAgendamentos] = useState<string[]>([])
  const [Agendamentos, setAgendamentos] = useState<iCardAgendamento[]>([])
  const [IsLoading, setIsLoading] = useState(false)
  const [ModalEditIsOpen, setModalEditIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [IsOpenAgendamento, setIsOpenAgendamento] = useState(false)
  const [SelectedItem, setSelectedItem] = useState<iCardAgendamento>()
  const [SelectedMensagem, setSelectedMensagem] = useState<iMensagens>()
  const [Pagina, setPagina] = useState(0)
  const [Qtd, setQtd] = useState(1)
  const [Tamanho, setTamanho] = useState(25)

  function selectAgendamentos(id: string, bool: boolean): void {
    if (bool) {
      setSelectedAgendamentos([...selectedAgendamentos, id])
    } else {
      setSelectedAgendamentos(selectedAgendamentos.filter(e => { if (e !== id) { return e } }))
    }
  }

  function GetAgendamentos(): void {
    setIsLoading(true)
    appApi.get(`AgendamentoMensageria?pagina=${Pagina}&tamanho=${Tamanho}&enviado=${Enviado}&tipo=${Tipo}&busca=${SearchText}&ordenador=${Ordenador}`)
      .then((res) => {
        if (res.data.length === 0 && Pagina !== 0) {
          setPagina(0)
        } else {
          setAgendamentos(res.data)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  function GetQuantidadeAgendamentos(): void {
    appApi.get(`AgendamentoMensageria/Quantidade?enviado=${Enviado}&tipo=${Tipo}&busca=${SearchText}`)
      .then((res) => setQtd(res.data))
      .catch(err => console.log(err))
  }

  function OnReenviarEmMassa(data: any): void {
    setIsLoading(true)
    setIsOpenAgendamento(false)
    appApi.post(`AgendamentoMensageria/${SelectedItem?.id}/ReenviarMassa`, { assunto: data.assunto, texto: data.texto })
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Emails editados e reenviados com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => setIsLoading(false))
  }

  function ReenviarMensagens(id: string): void {
    setIsLoading(true)
    appApi.post(`AgendamentoMensageria/${id}/Reenviar`)
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Email editado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => setIsLoading(false))
  }

  function DeleteAgendamento(): void {
    appApi.delete(`AgendamentoMensageria/${SelectedItem?.id}`)
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Agendamento excluído com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function DeleteMensagem(): void {
    appApi.delete(`AgendamentoMensageria/mensagem/${SelectedMensagem?.id}`)
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Email excluído com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function UpdateMensagem(data: any): void {
    setIsLoading(true)
    setIsOpenAgendamento(false)
    appApi.put('AgendamentoMensageria/mensagem', data)
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Email editado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => setIsLoading(false))
  }

  function SendMensagem(a: iMensagens): void {
    setIsLoading(true)
    setIsOpenAgendamento(false)
    appApi.post(`AgendamentoMensageria/Mensagem/${a.id}/Reenviar`)
      .then(() => {
        GetAgendamentos()
        toast({
          title: 'Email reenviado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro! Tente novamente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .finally(() => setIsLoading(false))
  }

  // function PostDataAgendamento(data: string): void {
  //   appApi.get('AgendamentoMensageria/Quantidade')
  //     .then((res) => setQtd(res.data))
  //     .catch(err => console.log(err))
  // }

  function ExcluirSelecionados(): void {
    setIsLoading(true)
    appApi.post('AgendamentoMensageria/ExcluirVarios', selectedAgendamentos)
      .then(() => {
        toast({
          title: 'Agendamentos excluídos com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        GetAgendamentos()
        GetQuantidadeAgendamentos()
        setPagina(0)
      })
      .catch(err => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        console.log(err)
      })
      .finally(() => {
        setSelectedAgendamentos([])
        setIsLoading(false)
      })
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setSelectedItem(undefined)
    setSelectedMensagem(undefined)
  }

  function onCloseModalAgendamento(): void {
    setIsOpenAgendamento(false)
    setSelectedMensagem(undefined)
  }

  useEffect(() => {
    GetAgendamentos()
    GetQuantidadeAgendamentos()
  }, [])

  useEffect(() => {
    GetAgendamentos()
  }, [Pagina, Tamanho])

  return (
    <>
      <Body>
        <ModalDelete
          isOpen={ModalDeleteIsOpen}
          message={SelectedItem ? 'Você deseja realmente excluir esse agendamento?' : 'Você deseja realmente excluir essa mensagem?'}
          onConfirm={SelectedItem ? DeleteAgendamento : DeleteMensagem}
          onRequestClose={onCloseModalDelete}
          center
        />
        {/* <ModalEditar
          isOpen={ModalEditIsOpen}
          data={SelectedItem?.dataAgendamento ?? ''}
          onClose={() => setModalEditIsOpen(false)}
          onSave={(e) => PostDataAgendamento(e)}
        /> */}
        <ModalAgendamento
          isOpen={IsOpenAgendamento}
          mensagem={SelectedMensagem}
          onClose={onCloseModalAgendamento}
          onConfirm={SelectedMensagem?.email ? UpdateMensagem : OnReenviarEmMassa}
        />

        <Flex margin='1rem 0rem'>
          <h1>Gestão de agendamentos</h1>
        </Flex>
        <Flex alignItems={'end'} marginBottom='1.5rem' justifyContent={'space-between'}>
          <Flex flex={'1'} gap={'.75rem'}>
            <FormControl width={'12rem'}>
              <SelectCmp title='Tipo de agendamento' OnChange={(e) => setTipo(e)} value={Tipo} bg={'white'} borderColor={'var(--Gray4)'}>
                <option value={''}>Todos</option>
                <option value={'0'}>Pesquisas</option>
                <option value={'1'}>Primeiro acesso</option>
                <option value={'2'}>Etapas e trilhas</option>
                <option value={'3'}>Reuniões</option>
                <option value={'4'}>PDIs</option>
                <option value={'5'}>Imersões</option>
                <option value={'6'}>Integrações</option>
                <option value={'7'}>Enps</option>
                <option value={'8'}>Feedbacks</option>
                <option value={'9'}>Outros</option>
              </SelectCmp>
            </FormControl>

            <FormControl width={'12rem'}>
              <SelectCmp title='Status' OnChange={(e) => setEnviado(e)} value={Enviado} bg={'white'} borderColor={'var(--Gray4)'}>
                <option value={''}>Todos</option>
                <option value={'false'}>🔴 Pendentes</option>
                <option value={'true'}>🟢 Enviados</option>
              </SelectCmp>
            </FormControl>

            <FormControl width={'12rem'}>
              <SelectCmp title='Ordenar' OnChange={(e) => setOrdenador(e)} value={Ordenador} bg={'white'} borderColor={'var(--Gray4)'}>
                <option value={'0'}>Data de criação</option>
                <option value={'1'}>Data de envio</option>
                <option value={'2'}>Mais mensagens</option>
                <option value={'3'}>Menos mensagens</option>
              </SelectCmp>
            </FormControl>
          </Flex>

          <Flex gap={'.75rem'} justifyContent={'end'}>
            <SearchCmp
              EnterAction={() => { GetAgendamentos(); GetQuantidadeAgendamentos() }}
              OnChange={(e) => setSearchText(e)}
              placeholder='Buscar agendamentos'
            />

            <Flex justifyContent='end' alignItems='center'>
              <ButtonCmp VarColor='Green2' rightIcon={<FaFilter />} onClick={() => { GetAgendamentos(); GetQuantidadeAgendamentos() }}>Filtrar</ButtonCmp>
            </Flex>
          </Flex>
        </Flex>

        <Paginador
          next={(e) => setPagina(e)}
          prev={(e) => setPagina(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          paginaAtual={Pagina}
          qtdElementos={Qtd}
          hideTop
          opcoesTamanho={['10', '25', '50', '100', '250', '500']}
        >
          <LoadingContainer linhas={20} loading={IsLoading} my='1rem'>
            {Agendamentos && Agendamentos.length > 0 && <Checkbox isChecked={selectedAgendamentos.length === Agendamentos.length} mb={'1rem'} borderColor={'var(--Gray4)'} onChange={(e) => { if (e.target.checked) { setSelectedAgendamentos(Agendamentos.map(e => { return e.id })) } else { setSelectedAgendamentos([]) } }}>Selecionar todos os agendamentos dessa página</Checkbox>}
            <Flex flexDir={'column'} gap='1rem'>
              {Agendamentos && Agendamentos?.map((e: iCardAgendamento) => {
                return (
                  <CardAgendamento
                    isSelected={selectedAgendamentos.some(a => e.id === a)}
                    agendamento={e}
                    onReenviar={(a) => ReenviarMensagens(a)}
                    onDelete={(a) => { setSelectedItem(a); setModalDeleteIsOpen(true) }}
                    // onEditData={(a) => { setSelectedItem(a); setModalEditIsOpen(true) }}
                    onDeleteMsg={(a => { setSelectedMensagem(a); setModalDeleteIsOpen(true) })}
                    onSend={(a) => SendMensagem(a)}
                    onView={(a) => { setSelectedMensagem(a); setIsOpenAgendamento(true) }}
                    onSelect={(id, bool) => selectAgendamentos(id, bool)}
                    onEditarEReenviar={(a) => { setSelectedItem(a); setIsOpenAgendamento(true) }}
                  />
                )
              })}
            </Flex>

            {
              (Agendamentos?.length === 0 && !IsLoading) && (
                <ImgSah textoPersonalizado={'Nenhum agendamento foi encontrado'}/>
              )
            }
          </LoadingContainer>
        </Paginador>
      </Body>
      {selectedAgendamentos.length > 0 && <Flex bg={'white'} pos={'sticky'} bottom={'0'} w={'100%'} py={'1rem'} justifyContent={'center'}>
        <Flex gap={'.75rem'} w={'75rem'} justifyContent={'end'}>
          <ButtonCmp VarColor='c6' onClick={() => setSelectedAgendamentos([])}>Cancelar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={ExcluirSelecionados}>Excluir {selectedAgendamentos.length} selecionado(s)</ButtonCmp>
        </Flex>
      </Flex>}
    </>
  )
}

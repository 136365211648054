import { FaPlus } from 'react-icons/fa'
import { HeadContainer } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iCompetencia } from '../../../interfaces'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { ModalDelete } from '../../../components/ModalDelete'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'
import { Flex } from '@chakra-ui/react'
import { ModalVisualizarQuestoes } from '../../Desempenho/GestaoFormularios/components/ModalVisualizarQuestões'
import { iForm } from '../../Desempenho/GestaoFormularios'
import LoadingContainer from '../../../components/LoadingContainer'
import { TimelineCriarPesquisa } from '../components/Timeline'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FormCard } from '../../../components/FormCard'
import { ModalCreateForm } from '../../Desempenho/GestaoFormularios/components/ModalCreateForm'

type navProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const FormulariosIndicativa: React.FC = () => {
  const nav = useNavigate()
  const { pesquisaId } = useParams<navProps>()
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingForms, setIsLoadingForms] = useState(false)
  const [Formularios, setFormularios] = useState<iForm[]>([])
  const [isOpen, setisOpen] = useState(false)
  const [CompetenciaAtiva, setCompetenciaAtiva] = useState<iCompetencia>()
  const [IsOpenVisualizarQuestoes, setIsOpenVisualizarQuestoes] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)
  const [FormularioId, setFormularioId] = useState<string>()

  const path = useLocation().pathname

  function getFormularios(): void {
    appApi.get(`Formulario?modo=1&pesquisaId=${pesquisaId}`)
      .then(response => setFormularios(response.data))
      .catch(err => console.log(err))
  }

  function DelteFormulario(): void {
    setIsLoadingForms(true)
    appApi.delete(`Formulario/${Id ?? ''}`)
      .then(() => getFormularios())
      .catch(err => console.log(err))
      .finally(() => setIsLoadingForms(false))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
    setFormularioId(undefined)
  }

  function SelectFormulario(FormId: string, rota: string): void {
    appApi.put(`FormularioPesquisa/${pesquisaId as string}/Formulario/${FormId}`)
      .then(() => nav(`${path.replace('Formularios', rota)}`))
      .catch(err => console.log(err))
  }

  function Duplicar(FormName: string): void {
    const form = { Texto: FormName }
    setIsLoading(true)
    appApi.post(`Formulario/${FormularioId ?? ''}/Duplicar`, form)
      .then((resp) => {
        SelectFormulario(resp.data, 'Formulario')
      }).catch(err => console.log(err))
  }

  function OnCloseVisualizarQuestoes(): void {
    setIsOpenVisualizarQuestoes(false)
    setCompetenciaAtiva(undefined)
  }

  function CriarFormulario(texto: string): void {
    appApi.post(`FormularioPesquisa/${pesquisaId ?? ''}`, { nome: texto, modoPesquisa: 1 }).then((res) => {
      nav(`${path.replace('Formularios', 'Formulario')}`)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormularios()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={isOpen}
        onCreate={CriarFormulario}
        onClose={() => setisOpen(false)}
        value={false}
        hiddenSwitch
      />

      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(e) => Duplicar(e)}
        onClose={onCloseModalDuplicar}
      />

      <ModalVisualizarQuestoes
        isOpen={IsOpenVisualizarQuestoes}
        onClose={OnCloseVisualizarQuestoes}
        competencia={CompetenciaAtiva}
      />

      <TimelineCriarPesquisa color={['var(--Azul)', 'var(--Rosa)']} />
      <HeadContainer>
        <h1>Formulários salvos</h1>
        <Flex gap={'.5rem'}>
          <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => setisOpen(true)} leftIcon={<FaPlus/>}>Criar novo formulário</ButtonCmp>
        </Flex>
      </HeadContainer>

      <LoadingContainer linhas={18} loading={IsLoadingForms}>
        <Flex flexDir={'column'} as='section' gap={'1rem'}>
          {
            Formularios.map((e, i) => (
              <FormCard
                IsIndicativa
                formulario={e}
                onEditar={(formId) => nav(`/Desempenho/AnaliseDesempenho/Quantitativa/Formulario/${formId}`)}
                onDelete={(formId) => onOpenModalDelete(formId)}
                onDuplicar={(formId) => { setModalDuplicarIsOpen(true); setId(formId) }}
                onSelect={(formId) => SelectFormulario(formId, 'VisualizarFormulario')}
                onViewCompetencia={(e) => { setCompetenciaAtiva(e); setIsOpenVisualizarQuestoes(true) }}
              />
            ))
          }
        </Flex>
      </LoadingContainer>
    </Body>
  )
}

import styled from 'styled-components'

export const Title = styled.div`
margin-bottom: 2rem;
h2:first-child{
    color: var(--Gray1);
    font-weight: 700;
}

h2:last-child{
    color: var(--Gray1);
    font-weight: 400;
}
`

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
background-color: white;

`

export const Logo = styled.img`
height: 87px;
object-fit: scale-down;
`

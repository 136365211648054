import { Flex } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { iCardPesquisa } from '../../../../interfaces'
import { PropInvalida } from '../../../../Utils/Helper'
import { ButtonCmp } from '../../../ButtonCmp'
import { ColumnText, PreViewParticipante, GridContainer } from './styles'

interface CardProps {
  pesquisa: iCardPesquisa
  selecionada?: string
  onSelect: (id: string) => void
}

export const CardPesquisa: React.FC<CardProps> = ({ pesquisa, onSelect, selecionada }) => {
  function ObterCorStatus(): string {
    if (selecionada === pesquisa.id) {
      return 'var(--Green2)'
    }

    return 'var(--c6)'
  }

  return (
        <PreViewParticipante color={ObterCorStatus()}>
            <GridContainer>
                <ColumnText>
                    <h4><strong>{pesquisa.nomePesquisa}</strong></h4>
                    {
                        (pesquisa.competenciasDoPdi) && (
                            <Flex alignItems={'center'} gap='.325rem' mt='.325rem'>
                                <FaCheck color='var(--Green2)' size={14}/>
                                <h4 style={{ color: 'var(--Green2)', fontSize: '.813rem', lineHeight: '.813rem' }}>Competências trabalhadas no ultimo PDI</h4>
                            </Flex>
                        )
                    }
                </ColumnText>

                {
                    (!PropInvalida(pesquisa.baseCiclo) && pesquisa.baseCiclo?.includes('Data de ingresso do avaliado')) && (
                        <ColumnText>
                            <h4>Base ciclo</h4>
                            <span>{pesquisa.baseCiclo}</span>
                        </ColumnText>
                    )
                }

                {
                    (!PropInvalida(pesquisa.ciclo)) && (
                        <ColumnText>
                            <h4>Ciclo</h4>
                            <span>{pesquisa.ciclo}</span>
                        </ColumnText>
                    )
                }
            </GridContainer>
            <ButtonCmp small OutlineColor={pesquisa.id === selecionada ? 'Red' : undefined} VarColor={pesquisa.id === selecionada ? undefined : 'Green2'} w={'10rem'} onClick={() => onSelect(pesquisa.id)}>{pesquisa.id === selecionada ? 'Remover pesquisa' : 'Selecionar pesquisa'}</ButtonCmp>
        </PreViewParticipante>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable multiline-ternary */
import { Flex, HStack, Text, CircularProgress, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaDownload, FaPencilAlt } from 'react-icons/fa'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { iFileData, iItemChecklist, iItemIntegracao } from '../../../../interfaces'
import { FormatarMensagemEditorDeTexto, PropInvalida } from '../../../../Utils/Helper'
import { ModalObservacao } from '../ModalObservacao'
import './styles.css'

interface iPDFProps {
  isVisible?: boolean
  readonly?: boolean
  preview?: boolean
  item: iItemIntegracao
  UpdateResposta: (resposta: iChekIntegracao[], itemId: string) => void
}

interface iChekIntegracao {
  texto: string
  check: boolean
  observacaoIson: boolean
  observacao?: string
  arquivo?: iFileData
}

export const PDFImersao: React.FC<iPDFProps> = ({ isVisible, item, UpdateResposta, readonly, preview }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState(1)
  const [page, setPage] = useState(1)
  const [IsLoading, setIsLoading] = useState(true)

  function onDocumentLoadSuccess(resto: any): any {
    console.log(resto)
    const { numPages } = resto
    setNumPages(numPages)
  }

  useEffect(() => {
    if (item) {
      setIsLoading(false)
    }
  }, [])

  const [ModalObservacaoIsOpen, setModalObservacaoIsOpen] = useState(false)
  const [CheckItemIndex, setCheckItemIndex] = useState<number>()
  const [ItensCheck, setItensCheck] = useState<iChekIntegracao[]>([])
  const [NoChangeItensCheck, setNoChangeItensCheck] = useState<iChekIntegracao[]>([])

  useEffect(() => {
    if (item.checklist && item.checklist.length > 0) {
      for (let i = 0; i < item.checklist.length; i++) {
        setItensCheck(oldArray => [...oldArray,
          {
            check: item.checklist !== undefined ? item.checklist[i]?.check : false,
            observacaoIson: false,
            texto: item.checklist !== undefined ? item.checklist[i]?.texto : '',
            arquivo: item.checklist !== undefined ? item.checklist[i]?.arquivo : undefined,
            observacao: item.checklist !== undefined ? item.checklist[i]?.comentario : undefined
          }])
        setNoChangeItensCheck(oldArray => [...oldArray,
          {
            check: item.checklist !== undefined ? item.checklist[i]?.check : false,
            observacaoIson: false,
            texto: item.checklist !== undefined ? item.checklist[i]?.texto : '',
            arquivo: item.checklist !== undefined ? item.checklist[i]?.arquivo : undefined,
            observacao: item.checklist !== undefined ? item.checklist[i]?.comentario : undefined
          }])
      }
    }
  }, [])

  useEffect(() => {
    UpdateResposta(ItensCheck, item.id)
  }, [ItensCheck])

  function UpdateItemArray(index: number, check: boolean, Observacao?: string, arquivo?: iFileData): void {
    const array = [...ItensCheck]
    array[index].check = check
    array[index].observacao = Observacao
    array[index].arquivo = arquivo
    setItensCheck(array)
    setModalObservacaoIsOpen(false)
  }

  if (!isVisible) {
    return null
  }

  return (
    <>
    <Flex direction={'column'}>
      <ModalObservacao
        isOpen={ModalObservacaoIsOpen}
        onClose={() => setModalObservacaoIsOpen(false)}
        onConfirm={(arquivo, observacao) => { if (CheckItemIndex !== undefined) { UpdateItemArray(CheckItemIndex, ItensCheck[CheckItemIndex].check, observacao, arquivo) } }}
        ItemId={item.id}
      />
      <Box className={item.layout === 2 ? 'hide' : 'hideImersaoPDF'}>
        <Flex justifyContent={'space-between'} w={'60%'} maxW={'700px'} mr={item.layout === 3 ? 'auto' : ''} ml={item.layout === 0 ? '0' : 'auto'}>
          <Flex w={'120px'}></Flex>
          <HStack justifyContent={'center'} mb={'1rem'}>
            <ButtonCmp
              onClick={() => {
                if (page > 1) { setPage(page - 1) }
              }}
              small
              VarColor='Green2'>
              {'<<'}
            </ButtonCmp>
            <Text fontSize={'.875rem'} fontWeight='semibold' m='1'>{page} / {numPages}</Text>
            <ButtonCmp
              onClick={() => {
                if (page < numPages) { setPage(page + 1) }
              }}
              small
              VarColor='Green2'>
              {'>>'}
            </ButtonCmp>
          </HStack>
          <Flex w={'120px'} justifyContent='end'>
            <ButtonCmp leftIcon={<FaDownload/>} onClick={() => { window.location.href = item?.arquivo?.uri ?? '' }} VarColor='Rosa'>Download</ButtonCmp>
          </Flex>
        </Flex>
        <Flex gap={'1.5rem'} justifyContent={item.layout === 3 ? 'center' : 'flex-start'}>
          {item.layout === 3 ? <Flex flexDir={'column'}>
            <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
              <h1>{item.titulo}</h1>
            </Flex>
            {!IsLoading && !PropInvalida(item?.arquivo?.uri) && (<Document loading={<CircularProgress isIndeterminate color='green.300' width={'20rem'} />}
              file={item?.arquivo?.uri}
              onLoadSuccess={onDocumentLoadSuccess}
              className={'react-pdf__Page__canvas2'}>
              <Page
                scale={1}
                key={`page_${1}`}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
              {/* {Array.from(new Array(numPages), (el, index) => (
                <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                />
              ))} */}
            </Document>)}
            {!(!IsLoading && !PropInvalida(item?.arquivo?.uri)) && (
              <Box className='react-pdf__Page__canvas2'></Box>
            )}
          </Flex>
            : <>
              {item.layout === 1 && <Flex left={0} flexDir={'column'} w={'40%'} pos={'relative'} opacity={'1'} minH={'890px'} maxH={'890px'}>
              <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
                <h1>{item.titulo}</h1>
              </Flex>
              <Flex flexDir={'column'} p={'1rem'} gap={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
                <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
                {
                  item.checklist?.map((r: iItemChecklist, i) => {
                    return (
                      <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                        <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                        {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                        {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                          {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                          {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                        </Flex>}
                      </Flex>
                    )
                  })
                }
              </Flex>
            </Flex>}
            {!IsLoading && !PropInvalida(item?.arquivo?.uri) && (<Document loading={<CircularProgress isIndeterminate color='green.300' width={'20rem'} />}
              file={item?.arquivo?.uri}
              onLoadSuccess={onDocumentLoadSuccess}
              className={'react-pdf__Page__canvas2'}>
              <Page
                scale={1}
                key={`page_${1}`}
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
              {/* {Array.from(new Array(numPages), (el, index) => (
                <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                />
              ))} */}
            </Document>)}
            {!(!IsLoading && !PropInvalida(item?.arquivo?.uri)) && (
              <Box className='react-pdf__Page__canvas2'></Box>
            )}
            {item.layout === 0 && <Flex left={0} flexDir={'column'} w={'40%'} pos={'relative'} opacity={'1'} minH={'890px'} maxH={'890px'}>
              <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} mb={'1rem'}>
                <h1>{item.titulo}</h1>
              </Flex>
              <Flex flexDir={'column'} p={'1rem'} gap={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'100%'} overflowY={'auto'}>
                <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
                {
                  item.checklist?.map((r: iItemChecklist, i) => {
                    return (
                      <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                        <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                        {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                        {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                          {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                          {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                        </Flex>}
                      </Flex>
                    )
                  })
                }
              </Flex>
            </Flex>}
          </>}
        </Flex>
      </Box>
      <Flex className={item.layout === 2 ? 'hideImersaoPDF' : 'hide'} style={{ flexDirection: 'column' }} alignItems={'center'} justifyContent={'center'}>
        {item.layout === 3 && <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} my={'1rem'} w={'full'}>
          <h1>{item.titulo}</h1>
        </Flex>}
        <Flex justifyContent={'space-between'} w={'60%'} maxW={'700px'} mx={'auto'}>
          <Flex w={'120px'}></Flex>
          <HStack justifyContent={'center'} mb={'1rem'}>
            <ButtonCmp
              onClick={() => {
                if (page > 1) { setPage(page - 1) }
              }}
              small
              VarColor='Green2'>
              {'<<'}
            </ButtonCmp>
            <Text fontSize={'.875rem'} fontWeight='semibold' m='1'>{page} / {numPages}</Text>
            <ButtonCmp
              onClick={() => {
                if (page < numPages) { setPage(page + 1) }
              }}
              small
              VarColor='Green2'>
              {'>>'}
            </ButtonCmp>
          </HStack>
          <Flex w={'120px'} justifyContent='end'>
            <ButtonCmp leftIcon={<FaDownload/>} onClick={() => { window.location.href = item?.arquivo?.uri ?? '' }} VarColor='Rosa'>Download</ButtonCmp>
          </Flex>
        </Flex>
        {!IsLoading && !PropInvalida(item?.arquivo?.uri) && (<Document loading={<CircularProgress isIndeterminate color='green.300'width={'20rem'} />}
          file={item?.arquivo?.uri}
          onLoadSuccess={onDocumentLoadSuccess}
          className={'react-pdf__Page__canvas2'}>
          <Page
            scale={1}
            key={`page_${1}`}
            pageNumber={page}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
          {/* {Array.from(new Array(numPages), (el, index) => (
            <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            />
          ))} */}
        </Document>)}
        {!(!IsLoading && !PropInvalida(item?.arquivo?.uri)) && (
          <Box className='react-pdf__Page__canvas2'></Box>
        )}
        {item.layout !== 3 && <Flex justifyContent={'center'} p={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} my={'1rem'} w={'full'}>
          <h1>{item.titulo}</h1>
        </Flex>}
        {item.layout !== 3 && <Flex flexDir={'column'} p={'1rem'} gap={'1rem'} bg={'var(--c1)'} border={'1px solid var(--c5)'} h={'fit-content'} w={'full'} overflowY={'auto'}>
            <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(item.descricao ?? '') }}></span>
            {
              item.checklist?.map((r: iItemChecklist, i) => {
                return (
                  <Flex gap={'.75rem'} my={'.125rem'} ml={'1rem'} key={i} flexDir={(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) ? 'column' : 'row'}>
                    <CheckboxCmp isChecked={NoChangeItensCheck[i]?.check ? true : undefined} readonly={readonly} isDisabled={NoChangeItensCheck[i]?.check || readonly} OnChange={(e) => UpdateItemArray(i, e, ItensCheck[i].observacao, ItensCheck[i].arquivo)}>{r.texto}</CheckboxCmp>
                    {!(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && !readonly && <ButtonCmp OutlineColor='Rosa' small leftIcon={<FaPencilAlt/>} onClick={() => { setCheckItemIndex(i); setModalObservacaoIsOpen(true) }} isDisabled={preview}>Adicionar observação</ButtonCmp>}
                    {(ItensCheck[i]?.observacao || ItensCheck[i]?.arquivo) && <Flex flexDir={'column'} gap={'.25rem'} fontSize={'.813rem'} fontWeight={'400'} lineHeight={'.925rem'}>
                      {ItensCheck[i]?.arquivo && <Text as='p'><Text as='a' fontWeight={'700'} color={'var(--Azul)'} textDecor='underline' href={ItensCheck[i]?.arquivo?.uri}>Clique aqui</Text> para baixar o arquivo anexado nessa observação.</Text>}
                      {ItensCheck[i]?.observacao && <Text as='p'>Observação: <Text as={'span'} fontWeight='700'>{ItensCheck[i]?.observacao}</Text></Text>}
                    </Flex>}
                  </Flex>
                )
              })
            }
        </Flex>}
      </Flex>
    </Flex>
  </>
  )
}

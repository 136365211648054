import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { FaPencilAlt } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { urlLocal } from '../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { Container } from './styles'

interface iProps {
  model?: iAvatarXInformacoes
}

export const CardProximoFeedback: React.FC<iProps> = ({ model }) => {
  return (
    <Container isDisabled={PropInvalida(model)}>
      <Box>
        <Text fontSize={'18px'} fontWeight={'700'} color={'var(--Gray1)'}>Você tem um feedback para realizar</Text>
      </Box>
      <Box width={'2px'} mx={'1.5rem'} bgColor={'var(--Gray2)'} />
      <Flex gap={'1rem'} alignItems={'center'}>
        <Avatar name={model?.avatar} src={model?.avatar} size={'sm'} />
        <Text mt={'-4px'} fontWeight={'400'}>{model?.info1}</Text>
      </Flex>
      <Button
        onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${model?.id ?? ''}`, '_blank')}
        VarColor='Green2'
        fontSize={'12px'}
        h={'2.375rem'}
        rightIcon={<FaPencilAlt />}
      >Responder</Button>
    </Container>
  )
}

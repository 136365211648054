import { iGrafico } from '../../../../../../../../interfaces'
import { ObterPercentual } from '../../../../../../../../Utils/Helper'
import { GenerateChartBlob } from '../../../../../../../../Utils/Helper_chart'

export async function getBlobGraficoPercentual(grafico: iGrafico): Promise<string> {
  const colors = [
    '#1961E0',
    '#27AE60',
    '#BDBDBD',
    '#F2C94C',
    '#F2994A',
    '#FF5050'
  ]

  function getSeries(): any {
    const sum = grafico.dados[0].medias.reduce((partialSum, a) => partialSum + a, 0)
    const data = grafico.dados[0].medias.map((e) => {
      return ObterPercentual(e, sum)
    })
    return [{
      name: '',
      data: data
    }]
  }

  const options = {
    colors: colors,
    series: getSeries(),
    chart: {
      width: '100%',
      type: 'bar',
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        // columnWidth: '60%',
        distributed: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    title: {
      text: grafico.dados[0].nome,
      align: 'center',
      offsetY: 10,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica, Arial, sans-serif',
        color: 'rgb(55, 61, 63)'
      }
    },
    subtitle: {
      text: 'Respostas ' + grafico.dados[0].medias.reduce((partialSum, a) => partialSum + a, 0).toString(),
      align: 'center',
      margin: 15,
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#9699a2'
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (value: number): string {
        return `${value.toFixed(0)}%`
      },
      offsetY: -20,
      style: {
        fontSize: '16px',
        colors: ['#000']
      }
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontWeight: 500,
      fontSize: 16,
      itemMargin: {
        horizontal: 16,
        vertical: 16
      },
      markers: {
        width: 18,
        height: 18,
        radius: 0,
        offsetX: -5
      }
    },
    xaxis: {
      categories: grafico.competencias,
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 100
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    }
  }

  return await GenerateChartBlob(options)
}

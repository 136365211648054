/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaTrash } from 'react-icons/fa'
import { iPutQuestaoFlexivel, iQuestaoFlexivel } from '../../../../../../interfaces'
import { Container } from './styles'
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SwitchCmp } from '../../../../../../components/SwitchCmp'

interface iQuestaoProps{
  questao: iQuestaoFlexivel
  isInvalid: boolean
  onDelete: (id: string) => void
  onDuplicar: (id: string) => void
  onUpdate: (questao: iPutQuestaoFlexivel, id: string) => void
  onAcima: () => void
  onAbaixo: () => void
  verificaSetas: () => string
}

export const Questao: React.FC<iQuestaoProps> = ({ questao, children, onDelete, onUpdate, onDuplicar, onAcima, onAbaixo, verificaSetas, isInvalid }) => {
  const [Obrigatoria, setObrigatoria] = useState(false)
  const [Tipo, setTipo] = useState('0')
  const [Enunciado, setEnunciado] = useState('')

  useEffect(() => {
    setObrigatoria(questao.obrigatoria)
    setEnunciado(questao.enunciado)
    setTipo(questao.tipo.toString())
  }, [])

  function AbstracatUpdate(tipo?: string): void {
    onUpdate({
      enunciado: Enunciado,
      obrigatoria: Obrigatoria,
      tipo: tipo !== undefined ? parseInt(tipo) : parseInt(Tipo)
    }, questao.id)
  }

  return (
          <Container style={{ position: 'relative', border: (isInvalid && (questao.tipo === 0 || questao.tipo === 5) && (questao.alternativas.length === 0 || questao.alternativas.some(a => a.texto === ''))) ? '1px solid var(--Red)' : '' }}>
            <Box as='span' position={'absolute'} right={'.5rem'} top={'.5rem'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
              <ElementoTooltipCmp bg={'var(--Azul)'} label='Mover a questão uma posição acima'>
                <Box h={'16px'} _hover={{ color: 'var(--Azul)', cursor: 'pointer' }} onClick={onAcima}>
                  {(verificaSetas() !== '0') && (verificaSetas() !== '3') && (<AiFillCaretUp/>)}
                </Box>
              </ElementoTooltipCmp>
              <ElementoTooltipCmp bg={'var(--Azul)'} label='Mover a questão uma posição abaixo'>
                <Box h={'16px'} _hover={{ color: 'var(--Azul)', cursor: 'pointer' }} onClick={onAbaixo}>
                  {(verificaSetas() !== '1') && (verificaSetas() !== '3') && (<AiFillCaretDown/>)}
                </Box>
              </ElementoTooltipCmp>
            </Box>
            <Flex gap={'.75rem'}>
                <FormControl width={'35%'}>
                    <SelectCmp title='Formato da pergunta' OnChange={(e) => {
                      setTipo(e)
                      AbstracatUpdate(e)
                    }} value={Tipo}>
                        <option value={'0'}>Múltipla escolha</option>
                        <option value={'5'}>Caixa de seleção</option>
                        <option value={'1'}>Discursiva</option>
                        <option value={'6'}>Escala</option>
                    </SelectCmp>
                </FormControl>

                <FormControl width={'65%'}>
                    <InputCmp
                        label='Pergunta'
                        onBlur={() => AbstracatUpdate()}
                        OnChange={setEnunciado}
                        value={Enunciado}
                        isInvalid={isInvalid && Enunciado.length === 0}
                        placeholder={'Escreva a pergunta'}
                    />
                </FormControl>
            </Flex>
            <Flex margin={'1rem 0rem'}>
                {children}
            </Flex>

            <Flex justifyContent={'end'}>
                <FormControl width={'fit-content'} display='flex' alignItems='center'>
                    <SwitchCmp onBlur={() => AbstracatUpdate()} OnChange={setObrigatoria} value={Obrigatoria} id='email-alerts' legendaDireita='Obrigatória'/>
                </FormControl>
                <Flex marginLeft={'1.5rem'} gap={'0rem .75rem'}>
                    <ElementoTooltipCmp bg={'var(--Azul)'} label={'Duplicar questão'}>
                      <FaCopy onClick={() => onDuplicar(questao.id)} cursor={'pointer'} color='var(--Azul)' size={20} />
                    </ElementoTooltipCmp>
                    <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir questão'}>
                      <FaTrash cursor={'pointer'} onClick={() => onDelete(questao.id)} color='var(--Red)' size={20} />
                    </ElementoTooltipCmp>
                </Flex>
            </Flex>
        </Container>
  )
}

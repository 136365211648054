import styled from 'styled-components'

export const Header = styled.div`
display:flex;
flex-direction:column;
border-bottom: .1rem solid var(--c4);
padding: 1rem;
width: 65rem;


h2{
    color: var(--a3);
}

span{
font-weight: 500;
font-size: 1rem;
color: var(--a3);
}

svg{
    min-height: 1.5rem;
    min-width: 1.5rem;
}
`

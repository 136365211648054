/* eslint-disable @typescript-eslint/no-misused-promises */
import { useForm } from 'react-hook-form'
import { Alert, AlertDescription, Flex, FormControl, Text, useToast } from '@chakra-ui/react'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Body } from '../../Layouts/Body'
import { useState } from 'react'
import { ValidarSenha } from '../../../Utils/Helper'
import { SigahApi } from '../../../services/appApi'
import { parseJwt } from '../../../services/token'
import { useNavigate } from 'react-router-dom'
import { InfoTooltipCmp } from '../../../components/InfoTooltipCmp'
import { InputCmp } from '../../../components/InputCmp'
import { ButtonCmp } from '../../../components/ButtonCmp'

interface iPostPassword {
  senhaAtual: string
  novaSenha: string
  comfirmSenha: string
}

export const AlterarSenha: React.FC = () => {
  const toast = useToast()
  const [Model, setModel] = useState<iPostPassword>({ comfirmSenha: '', novaSenha: '', senhaAtual: '' })
  const [Erros, setErrors] = useState<number[]>([])

  const nav = useNavigate()

  const { register, handleSubmit } = useForm()
  const onSubmit = (e: any): void => {
    const data: iPostPassword = Model
    setErrors([])

    if (!ValidarSenha(data.novaSenha)) {
      toast({
        title: 'Nova senha inválida',
        status: 'error',
        isClosable: false,
        description: 'A senha deve conter uma letra maiscula, um número e no mínimo 6 caractéres.',
        position: 'top-right',
        duration: 4000
      })
      setErrors(oldArray => [...oldArray, 1])
      return
    }

    // if (!ValidarSenha(data.comfirmSenha)) {
    //   setErrors(oldArray => [...oldArray, 2])
    //   return
    // }

    if (data.comfirmSenha !== data.novaSenha) {
      setErrors(oldArray => [...oldArray, 1])
      setErrors(oldArray => [...oldArray, 2])
      toast({
        title: 'Nova senha inválida',
        status: 'error',
        isClosable: false,
        description: 'A nova senha e sua confirmação devem ser iguais.',
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      uid: parseJwt().uid,
      senhaAtual: data.senhaAtual,
      senhaNova: data.novaSenha
    }

    SigahApi.post('api/UsuarioExterno/AlterarSenha', form).then(() => {
      toast({
        title: 'Senha alterada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      nav('/Colaborador')
    }).catch(err => {
      setErrors(oldArray => [...oldArray, 0])
      toast({
        title: 'Senha atual incorreta',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      console.log(err)
    }
    )
  }

  return (
        <Body>
            <WhiteContainer>
                <Flex justifyContent={'center'}>
                    <Flex flexDirection={'column'} gap={'.75rem'}>
                        <Flex alignItems={'center'} gap={'.5rem'}>
                            <h3>Alterar senha</h3>
                            <InfoTooltipCmp VarColor='Green2' label='A senha deve conter entre 8 e 16 caracteres'/>
                        </Flex>
                        {
                            Erros.length > 0 && (
                                <Alert borderRadius={'.25rem'} p={'.5rem .75rem'} status='error' fontSize={'.875rem'}>
                                    <AlertDescription fontWeight={'semibold'}>O formulário possui campos inválidos</AlertDescription>
                                </Alert>
                            )
                        }

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormControl marginBottom={'.75rem'} isInvalid={Erros.includes(0)} >
                                <InputCmp
                                    label='Senha atual'
                                    OnChange={(e) => setModel({ ...Model, senhaAtual: e })}
                                    type={'password'}
                                    {...register('senhaAtual')}
                                />
                            </FormControl>

                            <FormControl marginBottom={'.75rem'} isInvalid={Erros.includes(1)} >
                                <InputCmp
                                    label='Nova senha'
                                    OnChange={(e) => setModel({ ...Model, novaSenha: e })}
                                    type={'password'}
                                    {...register('novaSenha')}
                                />
                            </FormControl>

                            <FormControl marginBottom={'.25rem'} isInvalid={Erros.includes(2)} >
                                <InputCmp
                                    label='Confirmar nova senha'
                                    OnChange={(e) => setModel({ ...Model, comfirmSenha: e })}
                                    type={'password'}
                                    {...register('comfirmSenha')}
                                />
                            </FormControl>
                            <Text fontSize={'.813rem'} marginBottom={'.75rem'} color='gray.500'>Necessário: mínimo de 7 caracteres, 1 letra maiúscula, 1 caracter especial e 1 número</Text>
                            <Flex justifyContent={'center'}>
                                <ButtonCmp
                                    type='submit'
                                    VarColor='Green2'
                                >Atualizar senha</ButtonCmp>
                            </Flex>
                        </form>
                    </Flex>
                </Flex>
            </WhiteContainer>
        </Body>
  )
}

import { Flex } from '@chakra-ui/react'
import { ModalBase } from '../../../../../components/ModalBase'
import { VideoPlayer } from '../../../../../components/VideoPlayer'

interface iModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ModalVideoIntroducao: React.FC<iModalProps> = ({ isOpen, onClose }) => {
  return (
        <ModalBase isOpen={isOpen} Titulo={'Vídeo de introdução'} Width={'45rem'} onClose={onClose}>
            <Flex padding={'1rem'}>
                <VideoPlayer
                    titulo='SAH'
                    descricao='Conheça como utilizar nosso sistema de forma rápida e simples. Veja como ele pode auxiliar sua jornada dentro da organização'
                    url='https://sigah.blob.core.windows.net/assets/videoInicial.mp4'
                />
            </Flex>
        </ModalBase>
  )
}

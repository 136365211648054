/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Flex, Text, Box, FormControl, FormLabel, Select, Input, InputRightElement, InputGroup, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BoxAzul, OutlineListaContainer } from '../styles'
import { FaComments, FaFilter, FaSearch } from 'react-icons/fa'
import { FeedbackChart } from '../Components/PieChart'
import { CardProximoFeedback } from '../Components/CardProximoFeedback'
import { CsvButton } from '../../../../../components/Buttons/CsvButton'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { WhiteBox } from '../../../../../components/WhiteBox'
import { appApi, urlApi } from '../../../../../services/appApi'
import { ObterToken, PropInvalida } from '../../../../../Utils/Helper'
import { Body } from '../../../../Layouts/Body'
import { Button } from '../../../../../components/Button'
import { TableFeedback } from '../Components/TableFeedback'
import { parseJwt } from '../../../../../services/token'
import { DashboardSwitch } from '../../../../../components/DashboardSwitch'
import { iAvatarXInformacoes } from '../../../../../interfaces'
import { Paginador } from '../../../../../components/Paginador'

interface iModel {
  principal?: iAvatarXInformacoes
  tabela: iLinhaTabela[]
  quantidade?: number
}

export interface iLinhaTabela {
  id: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  nome: string
  dataInicio: string
  comentarios: number
  status: number
}

interface iFiltro {
  search: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardIndividualFeedback: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iModel>({ tabela: [] })
  const [ModelSemFiltros, setModelSemFiltros] = useState<iModel>({ tabela: [] })

  const [Filtro, setFiltro] = useState<iFiltro>({
    dataFim: '',
    dataInicio: '',
    search: '',
    status: ''
  })
  const [IsLoading, setIsLoading] = useState(false)
  const [Ordenador, setOrdenador] = useState({ filtro: 0, ascendente: false })
  const [Pagina, setPagina] = useState(0)
  const [Tamanho, setTamanho] = useState(25)

  const [Id, setId] = useState<string>()
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    setIsLoading(true)
    appApi.get(`Feedback/Dashboard/Individual/?busca=${Filtro.search}&dataFim=${Filtro.dataFim}&dataInicio=${Filtro.dataInicio}&status=${Filtro.status}&tamanho=${Tamanho}&pagina=${Pagina}&ordenador=${Ordenador.filtro}&ascendente=${Ordenador.ascendente}`)
      .then(res => {
        if (res.data?.tabela?.length > 0 || Pagina === 0) {
          setModel(res.data)
        } else {
          setPagina(0)
        }
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(true)
      })
  }

  function CarregarSemFiltros(): void {
    setIsLoading(true)
    appApi.get('Feedback/Dashboard/Individual/?busca=&dataFim=&dataInicio=&status=')
      .then(res => {
        if (res.status === 200) {
          setModelSemFiltros(res.data)
        } else {
          setModelSemFiltros({ tabela: [] })
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`Feedback/${id}/ReenviarConvite`)
      .then(() => toast({
        title: 'Convite reenviado com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    setIsLoading(true)
    appApi.delete(`Feedback/${Id ?? ''}`)
      .then(() => {
        setIsLoading(false)
        toast({
          title: 'Feedback excluido com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Carregar()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  function onOpenDelete(id: string): void {
    setDeletarIsOpen(true)
    setId(id)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    CarregarSemFiltros()
  }, [])

  useEffect(() => {
    Carregar()
  }, [Pagina, Ordenador, Tamanho])

  return (
    <Body isLoading={IsLoading}>
      {DeletarIsOpen &&
        <ModalDelete
          isOpen
          onConfirm={Deletar}
          onRequestClose={onCloseDelete}
          message='Deseja realmente excluir esse feedback?'
        />
      }

      <WhiteBox>
        <Flex mb={'1rem'} justifyContent={'space-between'}>
          <h1>Feedback</h1>
          <Button
            onClick={() => nav(`${pathname.replace('Feedback/Dashboard/Individual', 'NovaReuniaoOne/Criar')}`)}
            VarColor='Green2'
            size={'lg'}
          >Solicitar reunião 1:1</Button>
        </Flex>

        {(parseJwt().adm === 'True' || parseJwt().role === '2') &&
          <DashboardSwitch />
        }
        <Flex justifyContent={'space-between'} mt='1rem' maxH={'10rem'} gap={'1rem'}>
          {!PropInvalida(ModelSemFiltros.principal) &&
            <CardProximoFeedback model={ModelSemFiltros.principal} />
          }
          <BoxAzul>
            <Flex alignItems={'center'} justifyContent={'center'} gap={'1rem'} px={'2rem'}>
              <FaComments size={40} style={{ color: 'white' }} />
              <Box>
                <Text fontSize={'28px'} fontWeight={'700'}>{ModelSemFiltros.tabela.length}</Text>
                <Text fontSize={'20px'} fontWeight={'400'} mt={'-5px'}>Feedbacks</Text>
              </Box>
            </Flex>
          </BoxAzul>
          <Box borderRadius={'1rem'} border={'1px solid var(--Azul)'} w={'100%'}>
            <Flex alignItems={'center'} borderRadius={'1rem'} justifyContent={'center'} gap={'1rem'} backgroundColor={'#fff'} padding={'1rem'} overflow={'hidden'}>
              {!IsLoading && <FeedbackChart
                model={ModelSemFiltros.tabela}
              />}
            </Flex>
          </Box>
        </Flex>
        <Flex gap={'2rem'} alignItems={'flex-end'} mt={'1rem'}>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select onChange={(e) => setFiltro({ ...Filtro, status: e.target.value })}>
              <option value="">Todos</option>
              <option value="2">Aguardando inicio</option>
              <option value="3">Pendentes por colaborador</option>
              <option value="4">Pendentes por gestor</option>
              <option value="5">Finalizadas</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Periodo</FormLabel>
            <Flex alignItems={'center'} gap={'1rem'}>
              De: <Input onChange={(e) => setFiltro({ ...Filtro, dataInicio: e.target.value })} type={'date'} max={'2099-12-31T23:59'}/>
              Até: <Input onChange={(e) => setFiltro({ ...Filtro, dataFim: e.target.value })} type={'date'} max={'2099-12-31T23:59'}/>
            </Flex>
          </FormControl>
          <Flex minW={'23rem'} gap={'.75rem'} justifyContent={'end'}>
            <InputGroup width={'100%'}>
              <Input
                borderColor={'var(--Gray4)'}
                type='text'
                placeholder='Pesquisa'
                onChange={(e) => setFiltro({ ...Filtro, search: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    Carregar()
                  }
                }}
              />
              <InputRightElement
                pointerEvents='none'
                children={<FaSearch color='var(--Gray4)' />}
              />
            </InputGroup>

            <Flex justifyContent='end' alignItems='center'>
              <Button VarColor='a1' rightIcon={<FaFilter />} onClick={() => { Carregar() }}>Filtrar</Button>
            </Flex>
          </Flex>
        </Flex>
        <OutlineListaContainer>
          <Flex justifyContent={'space-between'}>
            <Text fontWeight={'700'} fontSize={'24px'}>Listas de feedbacks</Text>
            {parseJwt().adm === 'True' &&
              <CsvButton
                onClick={() => window.location.href = `${urlApi}/Feedback/Csv/Colaborador?token=${ObterToken()}&filtrado=true`}
              />
            }
          </Flex>
          <Paginador
            next={(e) => setPagina(e)}
            prev={(e) => setPagina(e)}
            tamanho={Tamanho}
            setTamanho={(e) => setTamanho(e)}
            paginaAtual={Pagina}
            qtdElementos={Model.quantidade ?? 0}
            hideTop={true}
          >
            <TableFeedback
              onReenviar={ReenviarConvite}
              onExcluir={onOpenDelete}
              onOrdenar={(filtro, ascendente) => setOrdenador({ filtro: filtro, ascendente: ascendente })}
              feedbacks={Model.tabela}
            />
          </Paginador>
        </OutlineListaContainer>
      </WhiteBox>
    </Body>
  )
}

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { iQuestao, iRespostaXColaborador } from '../../../../../../../../Feedback/VisualizarRespostas'
import { GlobalStyles } from '../../styles'

interface iProps {
  questao: iQuestao
  respostas: iRespostaXColaborador[]
}

export const Alternativa: React.FC<iProps> = ({ questao, respostas }) => {
  const styles = StyleSheet.create({
    Container: {
      flexDirection: 'column',
      marginVertical: 0.8
    },
    Radio: {
      marginRight: 8,
      backgroundColor: '#3182ce',
      padding: 4,
      borderRadius: '50%'
    }
  })
  return (
    <View style={styles.Container}>
      {questao.alternativas?.filter((a) => {
        if (!respostas.some(r => r.respostas.some(t => t.alternativas?.includes(a.id)))) {
          return null
        }
        return a
      })?.map((alternativa, i2) => {
        const respostasFiltradas = respostas.filter(r => r.respostas.some(t => t.alternativas?.includes(alternativa.id)))
        return (
          <View key={i2} style={GlobalStyles.Row}>
            <View style={GlobalStyles.AvatarGroup}>
              {respostasFiltradas.map((r, i) => {
                return (
                  <Image
                    key={i}
                    src={r.avatar}
                    style={GlobalStyles.Avatar}
                  />
                )
              })}
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={styles.Radio}>
                <View style={{ height: 4, width: 4, borderRadius: '50%', backgroundColor: '#fff' }}/>
              </View>
              <Text style={GlobalStyles.TextCheckBox}>{alternativa.texto}</Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}

import styled, { css } from 'styled-components'

interface ISkillAnswerProps {
  isSelected?: boolean
}

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
margin-top: 1.25rem;
margin-bottom: 1rem;
font-size: .875rem;

div{
background: var(--c2);
padding: .5rem;
width: 100%;
}

div:not(:last-child){
    margin-bottom: .75rem;
}

section{
    max-width: 70rem;
    width: 100%;
}
`

export const SkillQuestion = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--c4);
  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    span {
      color: var(--Red);
      font-size: .875rem;
      margin-bottom: .25rem;
    }
  }
  div + div {
    margin-top: 0.5rem;
  }
`

export const SkillAnswer = styled.button<ISkillAnswerProps>`
background: var(--c2);
border: 1px solid var(--c6);
border-radius: 0.25rem;
padding: .75rem 1rem;
outline: 0;
display: flex;
width: 100%;
align-items: center;
margin-top: 0.325rem;
svg {
  font-size: 1.125rem;
  background: white;
  border-radius: 50%;
  color: var(--c4);
  ${(props) =>
    props.isSelected &&
    css`
      color: var(--Azul);
    `};
}
${(props) =>
  props.isSelected &&
  css`
    background: var(--AzulOpacoBg);
  `};
span {
  margin-left: .75rem;
  color: black;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: 800;
    `};
  font-size: .875rem;
}
:hover{
  ${(props) =>
    !props.isSelected &&
    css`
      background: var(--AzulOpacoBg);
    `};
}
`

export const ComentarioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: .125rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--c4);
  span{
      color: var(--Red);
      font-size: .875rem;
      margin-bottom: .4rem;
   }
`

import { Body } from '../../Layouts/Body'
import { QuestionBox, ResumoBox } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { FaEye, FaFilePdf } from 'react-icons/fa'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'
import { HSelect, PropInvalida, SubstringRoute, useQuery } from '../../../Utils/Helper'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './RelatorioPDF'
import { iEscala } from '../../../interfaces'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { CheckboxCmp } from '../../../components/CheckboxCmp'
import { InfoTooltipCmp } from '../../../components/InfoTooltipCmp'
import { ElementoTooltipCmp } from '../../../components/ElementoTooltipCmp'

export interface iModel {
  id: string
  status: number
  questoes: Questao[]
  justificativa: boolean
  exibirCompetencia: boolean
  exibirPeso: boolean
  escala?: iEscala
  nomesEscalasAlternativas?: string[]
  escalasAlternativas?: iEscalaExtraPDF[]
  participantes: number[]
}

export interface iEscalaExtraPDF{
  escala: iEscala
  questoes: Questao[]
}

export interface Questao {
  id: string
  avatar: string
  pergunta: string
  nomeCompetencia: string
  discursiva: boolean
}

export const VisualizarFormulario: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const query = useQuery()
  const { pathname } = useLocation()
  const { pesquisaId, formularioId } = useParams<{ pesquisaId: string, formularioId: string }>()

  const [IsLoading, setIsLoading] = useState(false)
  const [NovoFormId, setNovoFormId] = useState('')
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  const [AprovarFormularioIsOpen, setAprovarFormularioIsOpen] = useState(false)
  const [Formulario, setFormulario] = useState<iModel>({
    id: '',
    questoes: [],
    status: 0,
    justificativa: false,
    exibirCompetencia: false,
    exibirPeso: false,
    participantes: [],
    escala: {
      fator: 0,
      id: '',
      nome: '',
      respostas: []
    }
  })

  function AprovarFormulario(): void {
    appApi.put(`Formulario/${Formulario.id}/Aprovar`).then(() => {
      if (pathname.includes('Trilha') || pathname.includes('Processo')) {
        nav(`${pathname.replace('VisualizarFormulario', 'EnviarPesquisa')}`)
      } else if (pathname.includes('FeedbackPersonalizado')) {
        nav(SubstringRoute(pathname, '/Pesquisa') + `?step=2&formularioId=${formularioId}`)
      } else {
        nav(`${pathname.replace('VisualizarFormulario', 'Participantes')}`)
      }
    }
    ).catch(() => {
      toast({
        title: 'Não foi inserida nenhuma questão no formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 3000
      })
    })
  }

  function Duplicar(Nome: string): void {
    const form = { texto: Nome }
    setIsLoading(true)
    appApi.post(`Formulario/${Formulario.id}/Duplicar`, form).then(resp => {
      setNovoFormId(resp.data)
    }).catch(err => console.log(err))
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
  }

  function AtualizarExibirCompetencia(): void {
    appApi.patch(`Formulario/${Formulario.id}/ExibirCompetencia`)
      .then()
      .catch(err => console.log(err))
  }

  function AtualizarExibirPeso(): void {
    appApi.patch(`Formulario/${Formulario.id}/ExibirPeso`)
      .then()
      .catch(err => console.log(err))
  }

  function getFormulario(controller: string, id: string): void {
    appApi.get(`${controller}/${id}/Visualizar`).then(resp => {
      setFormulario(resp.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormulario(!PropInvalida(pesquisaId) ? 'FormularioPesquisa' : 'Formulario', pesquisaId ?? formularioId ?? '')
  }, [])

  useEffect(() => {
    setIsLoading(false)
    if (NovoFormId !== '') {
      nav(`${pathname.replace('VisualizarFormulario', 'Formulario')}`)
    }
  }, [NovoFormId])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={AprovarFormularioIsOpen}
        message='Após aprovar o formulário você não poderá mais editá-lo. Deseja continuar?'
        onRequestClose={() => setAprovarFormularioIsOpen(false)}
        onConfirm={AprovarFormulario}
        center
      />
      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(e) => Duplicar(e)}
        onClose={onCloseModalDuplicar}
      />

      <Flex justifyContent={'space-between'} marginBottom='1.75rem'>
        <h1>Verifique e aprove o formulário</h1>
      </Flex>

      <WhiteContainer>
        <Flex flexDir={'column'} gap={'.75rem'}>
          <Flex justifyContent={'space-between'}>
            <h3>Resumo</h3>
            <PDFDownloadLink
              document={
                <RelatorioPDF
                  model={Formulario}
                />
              }
              fileName={'Formulario_Pesquisa_Percepcao.pdf'}
            >
              {({ loading }) =>
                loading
                  ? (
                    <ButtonCmp VarColor='Green2' isLoading leftIcon={<FaFilePdf/>}>Baixar formulário</ButtonCmp>
                    )
                  : <ButtonCmp VarColor='Green2' leftIcon={<FaFilePdf/>}>Baixar formulário</ButtonCmp>

              }
            </PDFDownloadLink>
          </Flex>

          <ResumoBox>
            {Formulario.escala &&
              <span>Escala padrão:<strong> {Formulario.escala.nome}</strong></span>
            }
            {Formulario.nomesEscalasAlternativas && Formulario.nomesEscalasAlternativas.length > 0 &&
              <span>Escalas alternativas:<strong> {Formulario.nomesEscalasAlternativas.map((e, i) => { return <>{e}{Formulario.nomesEscalasAlternativas && i === (Formulario.nomesEscalasAlternativas?.length - 1) ? '' : ', '}</> })}</strong></span>
            }
            <span>Competências selecionadas:<strong> {HSelect(Formulario.questoes, (e) => e.nomeCompetencia, true).length}</strong></span>
            <Flex as={'span'} gap={'.2rem'} alignItems={'center'} h={'1.5rem'}>
              Comentários nas questões:<strong> {Formulario.justificativa ? 'Ativado' : 'Desativado'}</strong>
              <InfoTooltipCmp
                VarColor='Rosa'
                label='Ao acionar os comentários nas questões, um campo de justificativa aparecerá abaixo de cada questão, assim o avaliador pode justificar a resposta sempre que quiser. A justificativa nunca é obrigatória.'
              />
            </Flex>
          </ResumoBox>

          <Flex gap={'.5rem'} alignItems={'center'}>
            <h3>Questões selecionadas</h3>
            <Flex alignItems={'center'} justifyContent={'center'} fontSize={'.75rem'} bg={'var(--Azul)'} color={'white'} borderRadius={'50%'} fontWeight={'700'} w={'1.5rem'} h={'1.5rem'}>{Formulario.questoes.length}</Flex>
          </Flex>

          <Flex flexDir={'column'} gap={'.75rem'}>
            {
              Formulario.questoes.map((e: Questao, index) => {
                return (
                  <div key={index}>
                    <QuestionBox>
                      <Flex borderRight={'2px dashed var(--Azul)'} flexDir={'column'} minWidth='8rem' maxWidth='8rem' wordBreak={'break-word'} alignItems={'center'} textAlign='center' margin='0rem 1rem' pb={'.25rem'}>
                        <img src={e.avatar} />
                        <small style={{ width: '80%' }}>{e.nomeCompetencia}</small>
                      </Flex>
                      <Flex flexDir={'column'} gap={'.125rem'}>
                        <span>{e.pergunta}</span>
                        {Formulario.escalasAlternativas && Formulario.escalasAlternativas?.length > 0 && <Text fontSize={'12px'} fontWeight={'400'}>{Formulario.escalasAlternativas.find(esc => esc.questoes.some(q => q.id === e.id)) ? Formulario.escalasAlternativas.find(esc => esc.questoes.some(q => q.id === e.id))?.escala.nome : Formulario.escala?.nome.split('(')[0]}</Text>}
                      </Flex>
                    </QuestionBox>
                  </div>
                )
              })
            }

          </Flex>
          <Flex justifyContent={'space-between'}>
            {Formulario.escala &&
              <ButtonCmp
                leftIcon={<FaEye />}
                onClick={() => nav({ pathname: 'Previsualizar' }, { state: { pesquisaId: pesquisaId } })}
                VarColor='Green2' size={'lg'}
              >Pré-visualizar</ButtonCmp>
            }
            <Flex gap={'.75rem'}>
              {!query.get('leitura') && Formulario.escala && (
                <CheckboxCmp isChecked={Formulario.exibirPeso} OnChange={(e) => { setFormulario({ ...Formulario, exibirPeso: e }); AtualizarExibirPeso() }}>Mostrar o peso das alternativas</CheckboxCmp>
              )}
              {!query.get('leitura') && Formulario.escala && (
                <CheckboxCmp isChecked={Formulario.exibirCompetencia} OnChange={(e) => { setFormulario({ ...Formulario, exibirCompetencia: e }); AtualizarExibirCompetencia() }}>Mostrar nome da competência acima da questão</CheckboxCmp>
              )}
            </Flex>
          </Flex>
        </Flex>
      </WhiteContainer>
      <Flex marginTop={'.75rem'} justifyContent={'end'} gap={'.5rem'}>
        {(Formulario.status === 2) && (
          <ButtonCmp VarColor='c6' onClick={() => nav(-1)}>Voltar</ButtonCmp>
        )}

        {(pathname.includes('Trilha')) && (
          <ButtonCmp

            VarColor='Rosa'
            onClick={() => nav(`${pathname.replace('VisualizarFormulario', 'Formularios')}`)}
          >Alterar formulário selecionado</ButtonCmp>
        )}

        {(Formulario.status !== 2) && (
          <>
            <ButtonCmp
              VarColor='Rosa'

              onClick={() => nav(`${pathname.replace('VisualizarFormulario', 'Formulario')}`)}
            >Editar formulário</ButtonCmp>
            <ElementoTooltipCmp bg={'var(--Red)'} label={!Formulario.questoes.some(r => !r.discursiva) ? 'Formulário inválido, adicione no mínimo uma competência não discursiva' : ''}>
              <ButtonCmp
                VarColor='Green2'
                onClick={() => setAprovarFormularioIsOpen(true)}
                isDisabled={!Formulario.questoes.some(r => !r.discursiva)}
              >Aprovar formulário</ButtonCmp>
            </ElementoTooltipCmp>
          </>
        )}

        {(Formulario.status === 2) && (
          (!query.get('leitura') && !pathname.includes('Trilha') && !pathname.includes('Processo')) && (
            <>
              {/* <ButtonCmp

                VarColor='Rosa'
                onClick={() => setModalDuplicarIsOpen(true)}
              >Duplicar</ButtonCmp> */}
              <ButtonCmp

                VarColor='Green2'
                onClick={() => nav(`${pathname.replace('VisualizarFormulario', 'Participantes')}`)}
              >Visualizar participantes</ButtonCmp>
            </>
          )
        )}

        {(Formulario.status === 2 && (pathname.includes('Trilha') || pathname.includes('Processo'))) && (
          <ButtonCmp

            VarColor='Green2'
            onClick={() => nav(`${pathname.replace('VisualizarFormulario', 'EnviarPesquisa')}`)}
          >Configurar envio da pesquisa</ButtonCmp>
        )}
      </Flex>
    </Body>
  )
}

import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../../components/EditorTexto/Index'
import { ModalBase } from '../../../../../components/ModalBase'
import { iMensagens } from '../../../../../interfaces'
import { BoxEmail, InputEmail } from '../CardAgendamento/styles'

interface iProps {
  mensagem?: iMensagens
  isOpen: boolean
  onClose: () => void
  onConfirm: (model: any) => void
}

export const ModalAgendamento: React.FC<iProps> = ({ mensagem, isOpen, onClose, onConfirm }) => {
  const [Model, setModel] = useState<any>({ texto: '', id: '', assunto: '' })

  useEffect(() => {
    if (mensagem) {
      setModel({ texto: mensagem?.mensagem, id: mensagem?.id, assunto: mensagem?.assunto })
    }
  }, [mensagem])

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      Titulo="Configurar mensagem"
      Width="65rem"
    >
      <Flex flexDir={'column'} fontSize={'12px'} w={'full'} bg={'white'} borderRadius={'1.25rem'} pb={'.75rem'} px={'1.25rem'} gap={'.5rem'} position={'relative'}>
        <Flex gap={'1rem'} mt={'.5rem'}>
          <Flex flexDir={'column'} flex={'1'} gap={'.5rem'} alignItems={'center'}>
            {mensagem?.email && <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
              <BoxEmail>
                Para:
              </BoxEmail>
              <InputEmail value={mensagem.email} disabled/>
            </Flex>}
            <Flex w={'full'} alignItems={'center'} gap={'.5rem'}>
              <BoxEmail>
                Assunto:
              </BoxEmail>
              <InputEmail value={Model.assunto} onChange={(e) => setModel({ ...Model, assunto: e.target.value })}/>
            </Flex>
          </Flex>
        </Flex>
        <EditorTexto
          isLoading={false}
          onChange={(e: any) => setModel({ ...Model, texto: e })}
          initialValue={Model.texto ?? ''}
        />
        <Flex justifyContent={'end'} gap='.5rem' marginTop={'1rem'}>
            <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
            <ButtonCmp VarColor='Green2' onClick={() => onConfirm(Model)}>{mensagem ? mensagem.enviado ? 'Salvar e reenviar email' : 'Salvar alterações' : 'Salvar em massa'}</ButtonCmp>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

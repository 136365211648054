/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Document,
  Image,
  Page,
  StyleSheet,
  View
} from '@react-pdf/renderer'

import { useState, useEffect } from 'react'
import { FormResposta } from './components/Feedback/FormResposta'
import { Tabela } from './components/Tabela'
import { iFeedback, iLinhaTabela } from '../..'
import { Header } from './components/Header'
import { iEscala, iGrafico } from '../../../../../interfaces'
import { getBlobGraficoColuna } from './components/Charts/ColumnChart'
import { getBlobGraficoPercentual } from './components/Charts/ChartPercentual'
import { getBlobGraficoStacked } from './components/Charts/StackeChart'
import { getBlobGraficoRadar } from './components/Charts/RadarChart'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iPDF {
  tabela: iLinhaTabela[]
  feedback?: iFeedback
  graficoCompetencia?: iGrafico
  resumoCompetenciaAutoavaliacao?: iGrafico
  resumoCompetenciaGestor?: iGrafico
  graficoPercentualGestor?: iGrafico
  graficoPercentualAutoavaliacao?: iGrafico
  graficoRadar?: iGrafico
  escala: iEscala
}

interface iProps {
  model: iPDF
}

interface iChartChest {
  radarChart_geral?: string
  columnChart_autoavaliacao?: string
  columnChart_gestor?: string
  stackedChart_autoavaliacao?: string
  stackedChart_gestor?: string
  columnChart_competencia?: string
}

export const RelatorioPDF: React.FC<iProps> = ({ model }) => {
  const emptyChart: iGrafico = {
    competencias: [],
    dados: []
  }

  const [Model, setModel] = useState<iPDF>({
    tabela: [],
    escala: { fator: 0, id: '', nome: '', respostas: [] }
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      flexDirection: 'column'
    },
    Main: {
      flexDirection: 'column',
      padding: 32
    }
  })

  const [Charts, setCharts] = useState<iChartChest>({})

  useEffect(() => {
    setModel(model)
    const generateChartBlob = async (): Promise<void> => {
      try {
        if (!PropInvalida(model.graficoCompetencia) && PropInvalida(Charts.columnChart_competencia) && model.graficoCompetencia !== emptyChart) {
          const graficoCompetencias = await getBlobGraficoColuna(model.graficoCompetencia ?? emptyChart)
          setCharts(prevState => {
            return { ...prevState, columnChart_competencia: graficoCompetencias }
          })
        }

        if (!PropInvalida(model.graficoPercentualGestor) && PropInvalida(Charts.columnChart_gestor) && model.graficoPercentualGestor !== emptyChart) {
          const graficoPercentualGestor = await getBlobGraficoPercentual(model.graficoPercentualGestor ?? emptyChart)
          setCharts(prevState => {
            return { ...prevState, columnChart_gestor: graficoPercentualGestor }
          })
        }

        if (!PropInvalida(model.graficoPercentualAutoavaliacao) && PropInvalida(Charts.columnChart_autoavaliacao) && model.graficoPercentualAutoavaliacao !== emptyChart) {
          const graficoPercentualAutoavaliacao = await getBlobGraficoPercentual(model.graficoPercentualAutoavaliacao ?? emptyChart)
          setCharts(prevState => {
            return { ...prevState, columnChart_autoavaliacao: graficoPercentualAutoavaliacao }
          })
        }

        if (!PropInvalida(model.graficoPercentualAutoavaliacao) && PropInvalida(Charts.stackedChart_autoavaliacao) && model.resumoCompetenciaAutoavaliacao !== emptyChart) {
          const graficoStackedAutoavaliacao = await getBlobGraficoStacked('Resumo das competências (Autoavaliação)', model.resumoCompetenciaAutoavaliacao ?? emptyChart)
          setCharts(prevState => {
            return { ...prevState, stackedChart_autoavaliacao: graficoStackedAutoavaliacao }
          })
        }

        if (!PropInvalida(model.graficoPercentualGestor) && PropInvalida(Charts.stackedChart_gestor) && model.resumoCompetenciaGestor !== emptyChart) {
          const graficoStackedGestor = await getBlobGraficoStacked('Resumo das competências (Gestor)', model.resumoCompetenciaGestor ?? emptyChart)
          setCharts(prevState => {
            return { ...prevState, stackedChart_gestor: graficoStackedGestor }
          })
        }

        if (!PropInvalida(model.graficoRadar) && PropInvalida(Charts.radarChart_geral) && model.graficoRadar !== emptyChart) {
          const graficoRadar = await getBlobGraficoRadar(
            (model.graficoRadar ?? emptyChart),
            ((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0
          )
          setCharts(prevState => {
            return { ...prevState, radarChart_geral: graficoRadar }
          })
        }
      } catch (error) {
        console.error('Erro ao buscar dados assíncronos:', error)
      }
    }
    generateChartBlob()
  }, [model])

  return (
    <Document>
      {Charts.radarChart_geral &&
        <Page style={styles.DefaultPage}>
          <Header
            texto='Arquitetura Humana'
            titulo='Radar geral'
          />
          <View style={styles.Main}>
            <Image
              src={Charts.radarChart_geral}
            />
          </View>
        </Page>
      }

      {Charts.columnChart_competencia &&
        <Page orientation="landscape" style={styles.DefaultPage}>
          <Header
            texto='Arquitetura Humana'
            titulo='Médias das competências'
          />
          <View style={styles.Main}>
            <Image
              style={{ height: 380, objectFit: 'scale-down' }}
              src={Charts.columnChart_competencia}
            />
          </View>
        </Page>
      }

      {Charts.columnChart_autoavaliacao && Charts.columnChart_gestor &&
        <Page style={styles.DefaultPage}>
          <Header
            texto='Arquitetura Humana'
            titulo='Percentual de respostas'
          />
          <View style={styles.Main}>
            <Image
              src={Charts.columnChart_autoavaliacao}
            />
            <Image
              src={Charts.columnChart_gestor}
            />
          </View>
        </Page>
      }

      {Charts.stackedChart_autoavaliacao && Charts.stackedChart_gestor &&
        <Page style={styles.DefaultPage}>
          <Header
            texto='Arquitetura Humana'
            titulo='Resumo das competências'
          />
          <View style={styles.Main}>
            <Image
              src={Charts.stackedChart_autoavaliacao}
            />
            <Image
              src={Charts.stackedChart_gestor}
            />
          </View>
        </Page>
      }

      {Model.tabela.length > 0 &&
        <Page orientation="landscape" style={styles.DefaultPage} wrap>
          <Header
            texto='Arquitetura Humana'
            titulo='Tabela de notas por hierarquia'
          />
          <View style={styles.Main}>
            <Tabela model={Model.tabela} />
          </View>
        </Page>
      }

      {Model.feedback && Model.feedback.questoes.length > 0 &&
        <Page style={styles.DefaultPage} wrap>
          <Header
            texto='Arquitetura Humana'
            titulo='Respostas do feedback'
          />
          <View style={styles.Main}>
            <View
              style={{
                flexDirection: 'column',
                margin: -16
              }}
            >
              {Model.feedback?.questoes.map((questao, i) => {
                return (
                  <FormResposta
                    key={i}
                    questao={questao}
                    respostas={Model.feedback?.respostas ?? []}
                  />
                )
              })}
            </View>
          </View>
        </Page>
      }
    </Document>
  )
}

import { useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IJobTitle {
  id: string
  nome: string
}

interface ICreateJobTitleModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onCreateJobTitle: (newJobTitle: IJobTitle) => void
}

export const CreateJobTitleModal: React.FC<ICreateJobTitleModalProps> = ({
  isOpen,
  onRequestClose,
  onCreateJobTitle
}) => {
  const [Nome, setNome] = useState('')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h3>Nova função</h3>
        <ModalBody>
          <InputCmp
            mt={'.25rem'}
            OnChange={setNome}
            placeholder='Digite um nome'
          />
        </ModalBody>
        <ModalFooter>
          <ButtonCmp VarColor='c6' onClick={onRequestClose}>Fechar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onCreateJobTitle({ id: '', nome: Nome })} >Salvar</ButtonCmp>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

import { Flex } from '@chakra-ui/react'
import Modal from 'react-modal'
import { Button } from '../../../../../components/Button'

interface props {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalSucess: React.FC<props> = ({ isOpen, onRequestClose }) => {
  Modal.setAppElement('#root')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Flex flexDir={'column'} padding={'2rem 4rem'} gap={'5rem'}>
        <Flex color={'var(--a3)'} textAlign={'center'} flexDir={'column'}>
          <h2>PDI aprovado e enviado</h2>
          <h2>para Nome do colaborador!</h2>
        </Flex>

        <Flex justifyContent={'center'}>
          <Button VarColor='v2' onClick={onRequestClose}>Fechar</Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

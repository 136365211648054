import { Flex, FormControl } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { useState, useEffect } from 'react'
import { Container, Header } from './styles'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { iFormRealocar } from '../..'
import { InputCmp } from '../../../../../../components/InputCmp'
import { CheckboxCmp } from '../../../../../../components/CheckboxCmp'

interface iModalProps {
  onClose: () => void
  onRealocar: (formRealocado: iFormRealocar) => void
  idRealocado: string
}

export const ModalRealocarColab: React.FC<iModalProps> = ({ onClose, onRealocar, idRealocado }) => {
  const [Model, setModel] = useState<iFormRealocar>({
    dataInicio: '',
    id: '',
    mandarPrimeiroAcesso: false
  })

  useEffect(() => {
    setModel({ ...Model, id: idRealocado })
  }, [idRealocado])

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className={'react-modal-content-scroll'}
    >
      <Container width={'50rem'}>
        <Header>
          <Flex width={'100%'} justifyContent={'center'}>
            <h3>Deseja realocar esse colaborador?</h3>
          </Flex>
          <Flex>
            <MdClose size={24} onClick={onClose} />
          </Flex>
        </Header>

        <Flex flexDir={'column'} p={'1rem'} gap={'.75rem'}>
          <FormControl>
            <InputCmp
              label='Data de ingresso'
              type={'date'}
              value={Model.dataInicio}
              OnChange={(e) => setModel({ ...Model, dataInicio: e })}
            />
          </FormControl>
          <FormControl>
            <CheckboxCmp
              isChecked={Model.mandarPrimeiroAcesso}
              OnChange={(e) => setModel({ ...Model, mandarPrimeiroAcesso: e })}
            >Enviar email de primeiro acesso</CheckboxCmp>
          </FormControl>
          <Flex gap={'.5rem'} justifyContent={'center'}>
            <ButtonCmp onClick={onClose} VarColor='c6'>Cancelar</ButtonCmp>
            <ButtonCmp onClick={() => onRealocar(Model)} VarColor='Green2'>Realocar</ButtonCmp>
          </Flex>
        </Flex>
      </Container>
    </Modal>
  )
}

import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye, FaPlus } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { iPutQuestaoCaixaSelecao, iPutQuestaoFlexivel, iQuestaoFlexivel } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Questao } from './Components/Questao'
import { CaixaDeSelecao } from './Components/Questao/Components/CaixaDeSelecao'
import { Discursiva } from './Components/Questao/Components/Discursiva'
import { Escala } from './Components/Questao/Components/Escala'
import { MultiplaEscolha } from './Components/Questao/Components/MultiplaEscolha'

type iParam = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

interface iForm {
  nome: string
  questoes: iQuestaoFlexivel[]
}

interface LocationProps {
  formularioEditavel: boolean
}

export const FormularioPesquisaInterna: React.FC = () => {
  const toast = useToast()
  const param = useParams<iParam>()
  const nav = useNavigate()
  const { pathname } = useLocation()

  const [Formulario, setFormulario] = useState<iForm>()
  const [IsLoading, setIsLoading] = useState(false)
  const [QuestaoIsInvalid, setQuestaoIsInvalid] = useState(false)

  const val = useLocation().state as LocationProps
  const formularioEditavel = val?.formularioEditavel ?? true

  function getFormulario(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Formulario?isTrilha=${((pathname.includes('Trilha') || (pathname.includes('Processo'))) && !formularioEditavel) ? 'true' : 'false'}`)
      .then(res => setFormulario(res.data))
      .catch(err => console.log(err))
  }

  function AdicionarQuestao(): void {
    appApi.post(`PesquisaInternaQuestao/${param.pesquisaId as string}`)
      .then(getFormulario)
      .catch(err => console.log(err))
  }

  function OrdenarFormulario(): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId as string}/Ordenacao`, Formulario?.questoes.map(e => e.id))
      .then(() => toast({
        title: 'Ordenação salva com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function AtualizarFormulario(): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId as string}/AtualizarFormulario`)
      .then()
      .catch(err => console.log(err))
  }
  function onOrdenarFormulario(): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId as string}/Ordenacao`, Formulario?.questoes.map(e => e.id))
      .then()
      .catch(err => console.log(err))
  }

  function UpdatePropsEmComumQuestao(questao: iPutQuestaoFlexivel, id: string): void {
    appApi.put(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${id}`, questao)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === id)
          if (pos !== -1) {
            filtrado.questoes[pos].enunciado = questao.enunciado
            filtrado.questoes[pos].tipo = questao.tipo
            filtrado.questoes[pos].obrigatoria = questao.obrigatoria
            setFormulario(filtrado)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function UpdateCaixaDeSelecao(questaoId: string, form: iPutQuestaoCaixaSelecao): void {
    appApi.put(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${questaoId}/CaixaSelecao`, form)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === questaoId)
          if (pos !== -1) {
            filtrado.questoes[pos].maxCaixaSelecao = form.maxCaixaSelecao
            filtrado.questoes[pos].minCaixaSelecao = form.minCaixaSelecao
            setFormulario(filtrado)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function OnChangeEscala(escalaId: string, questaoId: string): void {
    appApi.put(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${questaoId}/Escala?escalaId=${escalaId}`)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === questaoId)
          if (pos !== -1) {
            filtrado.questoes[pos].escalaId = escalaId === '' ? undefined : escalaId
            setFormulario(filtrado)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function DeletarQuestao(id: string): void {
    appApi.delete(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${id}`)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          filtrado.questoes = filtrado.questoes.filter(e => e.id !== id)
          setFormulario(undefined)
          setFormulario(filtrado)
        }
      })
      .catch(err => console.log(err))
  }

  function DuplicarQuestao(id: string): void {
    appApi.post(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${id}/Duplicar`)
      .then(() => {
        getFormulario()
        toast({
          title: 'Questão duplicada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  function AdicionarAlternativa(id: string): void {
    appApi.post(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${id}/Alternativa`)
      .then(getFormulario)
      .catch(err => console.log(err))
  }

  function RemoverAlternativa(questaoId: string, alternativaId: string): void {
    appApi.delete(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${questaoId}/Alternativa/${alternativaId}`)
      .then(getFormulario)
      .catch(err => console.log(err))
  }

  function UpdateTextoAlternativa(questaoId: string, alternativaId: string, texto: string): void {
    appApi.put(`PesquisaInternaQuestao/${param.pesquisaId as string}/questao/${questaoId}/Alternativa/${alternativaId}`, { texto: texto })
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === questaoId)
          if (pos !== -1) {
            const posAlternativa = filtrado.questoes[pos].alternativas.findIndex(e => e.id === alternativaId)
            if (posAlternativa !== -1) {
              filtrado.questoes[pos].alternativas[posAlternativa].texto = texto
              setFormulario(filtrado)
            }
          }
        }
      })
      .catch(err => console.log(err))
  }

  function onAcima(i: number): void {
    setIsLoading(true)
    if (Formulario?.questoes) {
      const questaoCopiada: iQuestaoFlexivel = Formulario?.questoes[i]
      const questaoAnterior: iQuestaoFlexivel = Formulario?.questoes[i - 1]
      const arrayNovoQuestoes: iQuestaoFlexivel[] = []
      Formulario?.questoes.filter(e => e.id !== questaoCopiada.id).forEach((e) => {
        if (e.id === questaoAnterior.id) {
          arrayNovoQuestoes.push(Formulario.questoes[i])
          arrayNovoQuestoes.push(Formulario.questoes[i - 1])
        } else {
          arrayNovoQuestoes.push(e)
        }
      })
      setFormulario({ ...Formulario, questoes: arrayNovoQuestoes })
    }
    setIsLoading(false)
  }

  function onAbaixo(i: number): void {
    setIsLoading(true)
    if (Formulario?.questoes) {
      const questaoCopiada: iQuestaoFlexivel = Formulario?.questoes[i]
      const questaoSeguinte: iQuestaoFlexivel = Formulario?.questoes[i + 1]
      const arrayNovoQuestoes: iQuestaoFlexivel[] = []
      Formulario?.questoes.filter(e => e.id !== questaoCopiada.id).forEach((e) => {
        if (e.id === questaoSeguinte.id) {
          arrayNovoQuestoes.push(Formulario.questoes[i + 1])
          arrayNovoQuestoes.push(Formulario.questoes[i])
        } else {
          arrayNovoQuestoes.push(e)
        }
      })
      setFormulario({ ...Formulario, questoes: arrayNovoQuestoes })
    }
    setIsLoading(false)
  }

  function verificaSetas(i: number): string {
    if (Formulario?.questoes && Formulario.questoes.length === 1) {
      return '3'
    } else if (i === 0) {
      return '0'
    } else if (Formulario?.questoes && i === Formulario?.questoes.length - 1) {
      return '1'
    } else {
      return '2'
    }
  }

  function CheckQuestoes(): void {
    setQuestaoIsInvalid(false)

    if (formularioEditavel) {
      if (Formulario?.questoes.some(r => r.enunciado.length === 0)) {
        setQuestaoIsInvalid(true)
        toast({
          title: 'Existem questões sem enunciado definido, procure por campos em vermelho!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }

      if (Formulario?.questoes.some(r => (r.tipo === 0 || r.tipo === 5) && (r.alternativas.length === 0 || r.alternativas.some(a => a.texto === '')))) {
        setQuestaoIsInvalid(true)
        toast({
          title: 'Há questões de caixa de seleção ou múltipla escolha sem nenhuma alternativa e/ou alternativas sem texto',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }
    }

    OrdenarFormulario()
    nav(`${pathname.replace('Formulario', 'Aprovacao')}`)
    AtualizarFormulario()
  }

  useEffect(() => {
    getFormulario()
  }, [])

  useEffect(() => {
    onOrdenarFormulario()
  }, [Formulario])

  return (
    <Body>
      <WhiteContainer>
        <Flex margin={'0 0 1rem 0'}>
          <h1>{formularioEditavel ? 'Adicione as perguntas para o formulário' : 'Perguntas do formulário'}</h1>
        </Flex>

        {
          (Formulario) && (!IsLoading) && (

            <Flex pointerEvents={!formularioEditavel ? 'none' : 'inherit'} opacity={!formularioEditavel ? '0.5' : ''} flexDirection={'column'} gap={'1rem 0rem'}>
              {
                Formulario?.questoes.map((e, i) => {
                  return (
                    <Questao
                      key={e.id}
                      onDelete={DeletarQuestao}
                      onUpdate={UpdatePropsEmComumQuestao}
                      onDuplicar={DuplicarQuestao}
                      questao={e}
                      isInvalid={QuestaoIsInvalid}
                      onAcima={() => onAcima(i)}
                      onAbaixo={() => onAbaixo(i)}
                      verificaSetas={() => verificaSetas(i)}
                    >
                      {
                        e.tipo === 0
                          ? <MultiplaEscolha
                            onCreate={AdicionarAlternativa}
                            onDelete={RemoverAlternativa}
                            onUpdate={UpdateTextoAlternativa}
                            questao={e}
                          />
                          : e.tipo === 1
                            ? <Discursiva
                              questao={e} />
                            : e.tipo === 5
                              ? <CaixaDeSelecao
                              onCreate={AdicionarAlternativa}
                              onDelete={RemoverAlternativa}
                              onUpdate={UpdateTextoAlternativa}
                              onUpdateMaxMin={UpdateCaixaDeSelecao}
                              questao={e}
                            />
                              : <Escala
                                onChangeEscala={(escalaId: string) => OnChangeEscala(escalaId, e.id)}
                                escalaId={e.escalaId ?? ''}
                              />
                      }
                    </Questao>
                  )
                })
              }
            </Flex>
          )
        }

        <Flex margin={'2rem 0'} gap='.75rem'>
          <ButtonCmp VarColor='Rosa' onClick={() => nav(`/Jornada/PesquisaInterna/Gestao/${param.pesquisaId as string}/Previsualizar`)} leftIcon={<FaEye />}>Pré-visualizar formulário</ButtonCmp>
          <ButtonCmp isDisabled={!formularioEditavel} VarColor='Green2' onClick={AdicionarQuestao} leftIcon={<FaPlus />}>Adicionar pergunta</ButtonCmp>
        </Flex>
        <Flex justifyContent={'end'}>
            <ButtonCmp onClick={() => {
              CheckQuestoes()
            }}
              marginLeft={'1rem'} size={'lg'} VarColor='Green2'
            >Próximo</ButtonCmp>
        </Flex>
      </WhiteContainer>
    </Body>
  )
}

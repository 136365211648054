/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdCalendarMonth } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { iEtapaColaborador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { ModalBody } from './styles'

interface props {
  onRequestClose: () => void
  etapa?: iEtapaColaborador
}

interface iResponsavel {
  id: string
  avatar?: string
  nome: string
  nomeArea?: string
}

interface iModel {
  responsavel: iResponsavel
  cadastrado: boolean
  pdiId?: string
  dataInicio?: string
  dataFim?: string
  gestorId: string
}

export const ModalPDITrilha: React.FC<props> = ({ onRequestClose, etapa }) => {
  const nav = useNavigate()
  const { cid } = parseJwt()
  const [Model, setModel] = useState<iModel>()
  const [Tela, setTela] = useState(0)

  function getPDI(): void {
    appApi.get(`PDIEtapaColaborador/${cid}/Etapa/${etapa?.id}/Modal`)
      .then(res => {
        setModel(res.data)
        setTela(res.data?.responsavel.id !== cid ? 0 : 1)
      })
      .catch(err => console.log(err))
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${Model?.gestorId ?? cid}&colaboradorId=${cid}&etapaId=${etapa?.id ?? ''}`)
    } else {
      appApi.post(`PDIEtapaColaborador/${Model?.pdiId ?? ''}/Etapa/${etapa?.id ?? ''}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${Model?.pdiId ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    getPDI()
  }, [])

  return (
    <ModalBase
      isOpen
      onClose={onRequestClose}
      Titulo='PDI'
      Width='37rem'
    >
      <Flex flexDir={'column'} pb={'1rem'}>
        {Tela === 0 &&
          <ModalBody>
            <Flex justifyContent={'center'} flexDir={'column'}>
              <h2>Aguardando...</h2>
              <Text
                fontSize={'1.25rem'}
              >O responsável ainda não inseriu o PDI. Solicite que ele faça o preenchimento de seu plano de desenvolvimento</Text>
            </Flex>

            <Flex justifyContent={'center'} alignItems={'center'}>
              <Avatar
                marginRight={'.5rem'}
                size={'sm'}
                src={Model?.responsavel.avatar}
                name={Model?.responsavel.nome}
              />
              <Flex textAlign={'start'} flexDir={'column'}>
                <span>{Model?.responsavel.nome}</span>
                {Model?.responsavel.nomeArea &&
                  <strong>{Model?.responsavel.nomeArea}</strong>
                }
              </Flex>
            </Flex>
          </ModalBody>
        }
        {Tela === 1 &&
          <ModalBody>
            <Flex alignItems={'center'} flexDir={'column'} textAlign={'center'}>
              <Text fontSize={'1.375rem'} fontWeight={'bold'}>Olá,</Text>
              <Text width={'23rem'} fontSize={'1.2rem'}>Chegou a hora de estruturar algumas ações para sua jornada!</Text>
            </Flex>
            {Model?.dataInicio && Model.dataFim &&
              <Flex flexDir={'column'} textAlign={'center'}>
                <Text fontSize={'1.2rem'} fontWeight='bold'>
                  <MdCalendarMonth /> {Model.dataInicio} a {Model.dataFim}
                </Text>
              </Flex>
            }
          </ModalBody>
        }
        {Model?.responsavel.id === cid &&
          <Flex mt={'1rem'} gap={'1rem'} justifyContent={'center'}>
            <Button
              onClick={() => CriarBasePDI('1')}
              padding='2rem'
              VarColor='Rosa'
            >Iniciar um novo PDI </Button>
            {Model?.pdiId &&
              <Button
                onClick={() => CriarBasePDI('2')}
                padding='2rem'
                VarColor='Green2'
              >Continuar no PDI atual<br /> (recomendado)</Button>
            }
          </Flex>
        }
      </Flex>
    </ModalBase>
  )
}

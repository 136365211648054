import { Divider, Flex, Img, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'
import { ModalBase } from '../../../../../../components/ModalBase'
import { iCompetencia } from '../../../../../../interfaces'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
  competencia?: iCompetencia
}

interface iModaProps{
  onRequestClose: () => void
  FinalizarAcao: (pdiId: string, acaoId: string, comentario: string, comProgresso: boolean) => void
  isOpen: boolean
  id: string
  acao?: iAcao
}

export const ModalFinalizarAcao: React.FC<iModaProps> = ({ isOpen, acao, id, onRequestClose, FinalizarAcao }) => {
  const [Comentario, setComentario] = useState('')
  return (
        <ModalBase Titulo='Você está prestes a finalizar essa ação...' isOpen={isOpen} onClose={onRequestClose} Width='60rem'>
            <Flex flexDir={'column'} padding={'1rem'} pt={'0.5rem'}>
              <Flex justifyContent={'space-between'}>
                <Flex flexDir={'column'} w={'50%'}>
                  <Text fontSize={'1rem'} display={'flex'} alignItems={'center'}>Competência: <strong style={{ marginLeft: '.25rem' }}>{acao?.assunto}</strong> {acao?.competencia && <Img src={acao?.competencia?.icone} w={'2.25rem'} h={'2.25rem'}/>}</Text>
                  <Flex gap={'.75rem'} marginBottom={'.75rem'} alignItems={'center'}>
                    <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Data de inicio: <strong>{acao?.dataInicio}</strong></Text>
                    <Text color={'var(--Gray2)'} fontWeight='700' fontSize={'1.125rem'}>-</Text>
                    <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Data de fim: <strong>{acao?.dataEntrega}</strong></Text>
                  </Flex>
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--c4)'} mx={'2rem'} h={'auto'}/>
                <Flex flexDir={'column'} marginBottom='.75rem' w={'50%'}>
                  <Text fontSize={'1rem'}>Título: <strong>{acao?.titulo}</strong></Text>
                  <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Descrição: <strong>{acao?.descricao}</strong></Text>
                </Flex>
              </Flex>
              <Flex flexDir={'column'}>
                <Text color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} fontWeight={'600'}>Deixe um comentário sobre a conclusão desta ação</Text>
                <EditorTexto
                  initialValue={Comentario}
                  placeholder='Digite o comentário aqui'
                  className='editorSt-v2'
                  onChange={(e: string) => { setComentario(e) }}
                />
              </Flex>
            </Flex>
            <Flex padding={'1rem'} pt={'.5rem'} justifyContent={'end'} gap='.5rem'>
              <ButtonCmp onClick={onRequestClose} VarColor='c4'>Fechar</ButtonCmp>
              <ButtonCmp onClick={() => FinalizarAcao(id, acao?.id as string, Comentario, true)} VarColor='Rosa'>Finalizar ação e marcar progresso em 100%</ButtonCmp>
              <ButtonCmp onClick={() => FinalizarAcao(id, acao?.id as string, Comentario, false)} VarColor='Green2'>Finalizar ação</ButtonCmp>
            </Flex>
        </ModalBase>
  )
}

import styled from 'styled-components'

export const Container = styled.div<{isDisabled?: boolean}>`
  padding: 1rem 2.5rem;
  border-radius: 1.5rem;
  border: 1px solid var(--Azul);
  display: flex;
  width:100%;
  gap: .5rem;
  flex-direction: column;
  .gradText{
  background: var(--DegradeAzul);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  }

  ${({ isDisabled }) => isDisabled && `
    opacity:0;
  `}
`

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
font-family: 'Roboto','sans-serif';


span:first-child{
    margin-top: .5rem;
}

div:last-child{
    display: flex;
    flex-direction: column;
}

div:first-child{
    div{
        width: 12.5rem;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 15.625rem;
        svg{
            display:none
        }
    }
}

div:first-child:hover{
    
    cursor: pointer;
    svg{
        display: block;
        transition: all .3s ease;
    }

    img{
        filter: brightness(0.8);
        transition: all .3s ease;
    }
}



span{
    color: var(--a3);
    margin-bottom: 0.3rem;
    font-size: 14px;
}

strong{
    color: var(--terc3);
}

`

import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const OutlineContainer = styled(Flex)`
display: flex;
flex-direction: column;
padding: 1rem;
width: 100%;
border:1px solid var(--Azul);
border-radius: 1.25rem;

.gradText{
  background: var(--DegradeAzul);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  
  span{
    font-weight: 700;
  }
}

h3{
    color: var(--Gray1);
}

ul{
    margin-left: 1.5rem;
    margin-top: 1rem;
    li:not(last-child){
        margin-bottom: .5rem;
    }
}
`

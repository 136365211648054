/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { ModalBody } from './styles'
import { useState } from 'react'
import { appApi, urlApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { FaDownload } from 'react-icons/fa'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  reload: () => void
}

interface iErros {
  nome: string
  email: string
  cpf: string
  cargo: string
  celular: string
  departamento: string
  emailGestor: string
  cpfGestor: string
  nivelUsuario: string
  dataAdimissao: string
  enviarEmail: boolean
  squad: string
  papelSquad: string
  message?: string
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, isOpen, reload }) => {
  const toast = useToast()

  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState('')
  const [Erros, setErros] = useState<iErros[]>([])
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingBaixarErros, setIsLoadingBaixarErros] = useState(false)

  const AtualizarArquivo = (arquivo: any): void => {
    const formdata = new FormData()
    formdata.append('arquivo', arquivo.target.files[0])
    setArquivo(formdata)
    setNome(arquivo.target.files[0].name)
    toast({
      title: 'Arquivo selecionado com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
  }

  function EnviarEmMassa(): void {
    setIsLoading(true)
    appApi.post('Colaborador/v2/organograma/uploadcsv', Arquivo)
      .then(res => {
        toast({
          title: 'Colaboradores cadastrados com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        if (res.data.length > 0) {
          setErros(res.data)
        } else {
          onRequestClose()
        }
        reload()
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  function BaixarErrosCSV(): void {
    setIsLoadingBaixarErros(true)
    appApi.post('Colaborador/RetornoErrosCsv', Erros.map(e => ({
      nome: e.nome ?? '',
      email: e.email ?? '',
      cpf: e.cpf ?? '',
      cargo: e.cargo ?? '',
      departamento: e.departamento ?? '',
      emailGestor: e.emailGestor ?? '',
      cpfGestor: e.cpfGestor ?? '',
      nivelUsuario: e.nivelUsuario ?? '',
      dataAdimissao: e.dataAdimissao ?? '',
      enviarEmail: e.enviarEmail ?? false,
      squad: e.squad ?? '',
      papelSquad: e.papelSquad ?? '',
      celular: e.celular ?? '',
      message: e.message ?? ''
    } as iErros)), {
      responseType: 'blob'
    })
      .then(res => {
        const url = window.URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'RetornoErros.csv'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => setIsLoadingBaixarErros(false))
  }

  return (
    <ModalBase
      Titulo='Cadastrar colaboradores em massa'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='65rem'
    >
      <ModalBody>
        <span><strong>Instruções para gerar o arquivo no excel: </strong>
          Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
          Após isso, clique em "Salvar como..." e selecione o formato
          <b> CSV UTF-8 (Delimitado por vírgulas). </b>
          As colunas que estão marcadas com “*” são campos obrigatórios.
          Por favor, realize o preenchimento completo para correto envio.
          <small> Obrigatório a inserção do endereço de email</small>
        </span>

        <label htmlFor='Ifile'>{Arquivo ? Nome : 'Selecione um arquivo'}</label>
        <input onChange={(e) => AtualizarArquivo(e)} id='Ifile' type={'file'} />
        {Erros?.length > 0 &&
          <Flex flexDir={'column'} width={'100%'} border={'1px solid var(--Red)'} borderRadius='1rem' mt={'1rem'} as={'ul'} padding='1rem 1rem 1rem 2rem'>
          <Grid templateColumns={'repeat(3, 1fr)'} alignItems={'center'} mb={'1rem'}>
            <div></div>
            <Text textAlign={'center'} fontWeight='bold'>Erros na planilha</Text>
            <ButtonCmp
              onClick={BaixarErrosCSV}
              leftIcon={<FaDownload color='white' size={16}/>}
              VarColor='Red'
              isLoading={IsLoadingBaixarErros}
            >Baixar CSV de retorno de erros</ButtonCmp>
          </Grid>
          <Flex flexWrap={'wrap'} gap={'2rem'}>
            {Erros?.map((e, i) => {
              return (
                <li key={i} style={{ whiteSpace: 'nowrap', width: '100%' }}>{e.message}</li>
              )
            })}
          </Flex>
        </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={() => { window.location.href = `${urlApi}/Colaborador/ExemploCsv` }}
          VarColor='Rosa'
        >Baixar exemplo para preenchimento</ButtonCmp>

        <ButtonCmp
          isDisabled={PropInvalida(Arquivo)}
          onClick={EnviarEmMassa}
          VarColor='Green2'
          isLoading={IsLoading}
        >Enviar arquivo</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

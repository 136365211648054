import { Flex, InputProps } from '@chakra-ui/react'
import { FaQuestion } from 'react-icons/fa'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'

interface iFormProps extends InputProps {
  VarColor: string
  label: string
  size?: string
}

export const InfoTooltipCmp: React.FC<iFormProps> = ({ VarColor, label, size, ...rest }) => {
  return (
    <ElementoTooltipCmp bg={`var(--${VarColor})`} label={label}>
      <Flex _hover={{ background: `var(--${VarColor})` }} borderRadius={'50%'} w={size ?? '1.125rem'} h={size ?? '1.125rem'} alignItems={'center'} justifyContent={'center'} bg={`var(--${VarColor})`}>
        <FaQuestion size={10} color={'white'}/>
      </Flex>
    </ElementoTooltipCmp>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Img } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye, FaSave } from 'react-icons/fa'
import { iPeriodo } from '../../../..'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../../../../components/InputCmp'
import { appApi } from '../../../../../../../services/appApi'
import { UpdateElementByPosition } from '../../../../../../../Utils/Helper'
import { TableDiv } from './styles'

interface iProps {
  periodo: iPeriodo
  status: number
  isResponsavel: boolean
  tipo: number
}

export const TabelaIndicador: React.FC<iProps> = ({ periodo, isResponsavel, status, tipo }) => {
  const [Periodo, setPeriodo] = useState<iPeriodo>(periodo)
  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]

  function GetMesesDoPeriodoComValor(campo: 'real' | 'previsto', acumulado: boolean, mesInicial: number): any {
    const response: any[] = [<td>{acumulado ? 'Acumulado' : campo === 'real' ? 'Real' : 'Previsto'}</td>]
    let valorAcumulado: number = 0
    let primeiroMes: boolean = false
    let max = 6

    for (let i = mesInicial; i < 13; i++) {
      if (max <= 0) continue
      max--
      const mesAtual = Periodo?.meses?.find(e => e.numeroMes === i)
      if (!primeiroMes) primeiroMes = !!mesAtual
      if (mesAtual && !acumulado) {
        const indexMesAtual = Periodo.meses.findIndex(e => e.numeroMes === i)
        response.push(
          <td key={i}>
            <Flex justifyContent={'center'}>
              {status === 4
                ? <Flex justifyContent={'center'}>
                  {mesAtual[campo] ?? 0}
                </Flex>
                : <InputCmp
                type={'number'}
                value={mesAtual[campo] ?? 0}
                fontSize='11px'
                isDisabled={status === 2}
                OnChange={(e) => setPeriodo({
                  ...Periodo,
                  meses: UpdateElementByPosition(Periodo.meses, indexMesAtual, { ...mesAtual, [campo]: parseFloat(e) })
                })}
              />
              }
            </Flex>
          </td>
        )
      } else if (mesAtual && acumulado && primeiroMes) {
        if (tipo === 5) {
          valorAcumulado = parseFloat(mesAtual?.real as any ?? 0)
        } else if (mesAtual?.real) {
          valorAcumulado = parseFloat(mesAtual?.real as any) + valorAcumulado
        }
        response.push(
          <td key={i}>
            <Flex justifyContent={'center'}>
              {(valorAcumulado % 1 === 0 ? valorAcumulado.toString() : valorAcumulado.toFixed(2)).replace('.', ',')}
              {tipo === 5 && '%'}
            </Flex>
          </td>
        )
      } else {
        response.push(
          <td key={i}>
            <Flex fontWeight={700} textAlign={'center'} justifyContent={'center'}>-</Flex>
          </td>
        )
      }
    }

    return response // Retorna os componentes JSX
  }

  useEffect(() => {
    setPeriodo(periodo)
  }, [periodo])

  return (
    <Flex width={'100%'} flexDir={'column'} gap={'1rem'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Indicadores</th>
              {mesesAbreviados.slice(0, 6).map(e => {
                return (
                  <th>
                    {e}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>{GetMesesDoPeriodoComValor('real', false, 1)}</tr>
            <tr>{GetMesesDoPeriodoComValor('previsto', false, 1)}</tr>
            <tr>{GetMesesDoPeriodoComValor('real', true, 1)}</tr>
          </tbody>

        </table>
      </TableDiv>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Indicadores</th>
              {mesesAbreviados.slice(6).map(e => {
                return (
                  <th>
                    {e}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>{GetMesesDoPeriodoComValor('real', false, 7)}</tr>
            <tr>{GetMesesDoPeriodoComValor('previsto', false, 7)}</tr>
            <tr>{GetMesesDoPeriodoComValor('real', true, 7)}</tr>
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Menu } from '@chakra-ui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { StyledMenuButton } from '../../../../../components/NavBar/Components/StyledMenuButton'
import { StyledMenuItem } from '../../../../../components/NavBar/Components/StyledMenuItem'
import { StyledMenuList } from '../../../../../components/NavBar/Components/StyledMenuList'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia } from '../../../../../interfaces'
import { ImgText, Container, DropMenu } from './styles'

interface CardProps {
  Competencia: iCompetencia
  Selecionado: boolean
  onClickPreVisualizar: () => void
  Edit: () => void
  onDeleteCompetencia: (competenciaId: string) => void
}

export const CardCompetencia: React.FC<CardProps> = ({ onClickPreVisualizar, Edit, onDeleteCompetencia, Selecionado, Competencia }) => {
  const { SelectCompetencia } = useFormulario()

  return (
    <Container border={Selecionado ? 'v2' : 'c1'}>
      {
        !Competencia.doSistema
          ? <Flex justifyContent={'end'} position={'absolute'} top={'3px'} right={'-8px'}>
              <Menu>
                <StyledMenuButton color={'black'} _hover={{ bg: 'none' }} _active={{ bg: 'none' }} _focus={{ bg: 'none' }}>
                  <BsThreeDotsVertical size={16}/>
                </StyledMenuButton>
                <StyledMenuList>
                  <StyledMenuItem
                    onClick={() => Edit()}
                  >
                    <FaEdit style={{ marginRight: '.4rem' }} />
                    Editar
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={(() => onDeleteCompetencia(Competencia.id))}
                  >
                    <FaTrash style={{ marginRight: '.4rem' }} />
                    Excluir
                  </StyledMenuItem>
                </StyledMenuList>
              </Menu>
          </Flex>
          : <></>
      }

      <ImgText>
        <img src={Competencia.icone}/>
        <span>{Competencia.nome}</span>
      </ImgText>
      <Flex flexDir={'column'} gap={'.325rem'} marginTop={'.325rem'} >
        <ButtonCmp OutlineColor={'Rosa'} h={'1.25rem'} fontSize={'.65rem'} size={'xs'} onClick={() => onClickPreVisualizar()}>Pré-visualizar questões</ButtonCmp>
        {
          !Selecionado
            ? <ButtonCmp
              VarColor='Green2' size={'xs'} h={'1.25rem'} fontSize={'.75rem'} onClick={() => SelectCompetencia(Competencia.id)}
              >Adicionar</ButtonCmp>
            : <ButtonCmp
              h={'1.25rem'} fontSize={'.75rem'}
              onClick={() => SelectCompetencia(Competencia.id)}
              VarColor='Red'>Remover</ButtonCmp>
        }
      </Flex>
    </Container>
  )
}

import { Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'

export const PopUpAviso: React.FC = () => {
  const nav = useNavigate()
  return (
        <ModalBase Titulo='Atenção' styleHeader={{ alignItems: 'center', color: 'var(--secundario5)' }} isOpen onClose={() => nav('/Usuario/Empresa')} Width='30rem'>
            <Flex padding={'1rem'}>
                <Text fontSize={'1.25rem'} fontWeight={'500'}>Parece que você ainda não configurou um 9Box padrão, aperte em continuar para prosseguir com a configuração</Text>
            </Flex>
            <Flex padding={'0 1rem 1rem 1rem'} justifyContent={'center'}>
                <Button VarColor='Green2' onClick={() => nav('/Usuario/Empresa')}>Continuar</Button>
            </Flex>
        </ModalBase>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Flex, Text } from '@chakra-ui/react'
import { FaCalendarDay, FaStreetView } from 'react-icons/fa'

export type iFluxoEntidades='360-indicativa' | 'Feedback-discursivo' | '90-quantitativa' | 'Reuniao-one' | 'PDI'

interface iProps {
  titulo: string
  subtitulo: string
  tipo: iFluxoEntidades
  dataInicio?: string
  dataFim?: string
  isSelected?: boolean
  texto?: string
}

export const Card: React.FC<iProps> = ({ subtitulo, texto, titulo, tipo, isSelected, dataInicio, dataFim }) => {
  function ConverterDataHora(val: string): string {
    const bloco = val.split('T')
    const [year, month, day] = bloco[0].split('-')
    const [Hours, Minutes] = bloco[1].split(':')

    return `${day}/${month}/${year} - ${Hours}:${Minutes}`
  }

  return (
    <Flex
      gap={'4px'}
      justifyContent='center'
      flexDir={'column'}
      my={((dataInicio && dataFim)) ? '0' : '1.25rem'}
    >
      <Flex
        borderRadius={'1rem'}
        border={isSelected ? '4px solid var(--Azul)' : ''}
        color={isSelected ? 'var(--Azul)' : 'var(--Gray3)'}
        bg={isSelected ? 'var(--Gray6)' : 'var(--Gray6)'}
        flexDir={'column'}
        gap='4px'
        p={'1rem'}
        w={'11rem'}
        h='8.125rem'
        justifyContent={'center'}
      >
        <Flex gap={'6px'} alignItems='center' justifyContent={'center'}>
          <Flex>
            <FaStreetView size={40} />
          </Flex>
          <Text fontSize={'20px'} fontWeight={'bold'}>{titulo}</Text>
        </Flex>
        <Text textAlign={'center'} fontSize={'10px'} fontWeight={'400'}>{subtitulo}</Text>
      </Flex>
      {dataInicio && dataFim &&
        <Flex justifyContent={'center'} gap={'0.25rem'} alignItems={'center'}>
          <FaCalendarDay size={16} />
          {dataInicio && dataFim &&
            <Flex flexDir={'column'}>
              <Text fontWeight={500} fontSize={'0.75rem'}>de {ConverterDataHora(dataInicio)}</Text>
              <Text fontWeight={500} fontSize={'0.75rem'}>até {ConverterDataHora(dataFim)}</Text>
            </Flex>
          }
        </Flex>
      }
      {texto &&
        <Flex justifyContent={'center'} gap={'0.25rem'} alignItems={'center'}>
          <FaCalendarDay size={16} />
          <Text fontWeight={500} fontSize={'0.75rem'} w={'8rem'}>{texto}</Text>
        </Flex>
      }
    </Flex>
  )
}

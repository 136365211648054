import styled from 'styled-components'

export const ActionButton = styled.div`
display: flex;
padding: 1rem;
border-radius: 15px;
align-items: center;
background-color:${props => props.theme};
color: white;
svg{
    margin-right: 1rem;
}

span{
    font-weight: 700;
    font-size: 18px;
}

&:hover{
    cursor: pointer;
    transition: ease .3 all;
    filter: brightness(.9);
}
`

export const Container = styled.div`
  width: 100%;
  max-height: 47.313rem;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 10px; /* Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #4caf50, #81c784); /* Cor e gradiente do thumb */
    border-radius: 5px; /* Arredondamento */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #388e3c, #66bb6a); /* Cor ao passar o mouse */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Cor do fundo da barra de rolagem */
    border-radius: 5px; /* Arredondamento */
  }

  /* Para navegadores que não suportam webkit */
  scrollbar-width: thin; /* Largura fina no Firefox */
  scrollbar-color: var(--c6) #f1f1f1; /* Cor do thumb e do track no Firefox */
`

/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { ObterPercentual } from '../../../../../Utils/Helper'
import { iEscala } from '../../../../../interfaces'
import { IDadoGrafico } from '../..'

interface iProps{
  id: string
  grafico: IDadoGrafico
  escala: iEscala
  getBlob?: (blob: string) => void
}

export const ChartPercentual: React.FC<iProps> = ({ grafico, escala, id, getBlob }) => {
  function getSeries(): any {
    const sum = grafico.medias.reduce((partialSum, a) => partialSum + a, 0)
    const data = grafico.medias.map((e, i) => {
      return ObterPercentual(e, sum)
    })
    return [{
      name: '',
      data: data
    }]
  }

  const colors = [
    '#1961E0',
    '#27AE60',
    '#BDBDBD',
    '#F2C94C',
    '#F2994A'
  ]

  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        height: 350,
        width: '100%',
        type: 'bar'
      },
      plotOptions: {
        bar: {
          // columnWidth: '60%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      title: {
        text: grafico.nome,
        align: 'center',
        offsetY: 10,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: 'rgb(55, 61, 63)'
        }
      },
      subtitle: {
        text: 'Respostas ' + grafico.medias.reduce((partialSum, a) => partialSum + a, 0).toString(),
        align: 'center',
        margin: 15,
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#9699a2'
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (value: number): string {
          return `${value.toFixed(0)}%`
        },
        offsetY: -20,
        style: {
          fontSize: '14px',
          colors: ['#000']
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: escala.respostas.filter(e => e.peso !== -1).map(e => e.resposta),
        labels: {
          formatter: function (value: string) {
            return value
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    chart.updateSeries(getSeries())
    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    if (getBlob !== undefined) {
      getBlob(dataURL.imgURI)
    }
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}

/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormControl } from '@mui/base'
import { InputLabel } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import Drawer from '@mui/material/Drawer'
import { Flex, Text } from '@chakra-ui/react'
import { FaFilter } from 'react-icons/fa'

interface iFiltro {
  areas: string[]
  squads: string[]
  gestores: string[]
}

interface iDrawProps {
  isOpen: boolean
  onClose: () => void
  onRedefinir: () => void
  onFilter: (filtro: iFiltro) => void
  startValue?: iFiltro
  squads: iNomeId[]
  gestores: iNomeId[]
  areas: iNomeId[]
}

export const DrawerFiltro: React.FC<iDrawProps> = ({ isOpen, onClose, onRedefinir, onFilter, areas, gestores, squads, startValue }) => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        'NotoSans',
        'NotoSansThai',
        'Arial',
        'Roboto',
        "'Helvetica Neue'",
        'sans-serif'
      ].join(',')
    },
    shape: {
      borderRadius: 15
    }
  })

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220
      }
    }
  }

  const [Filtro, setFiltro] = useState<iFiltro>(startValue ?? {
    areas: [],
    squads: [],
    gestores: []
  })

  const handleChange = (value: any, name: string) => {
    if (name === 'areas' && value.length >= 1) {
      setFiltro({ ...Filtro, areas: Filtro.areas.some(e => e === value[Filtro.areas.length]) ? Filtro.areas.filter(e => { if (e !== value[Filtro.areas.length]) { return e } }) : [...Filtro.areas, value[Filtro.areas.length]] })
    } else if (name === 'gestores' && value.length >= 1) {
      setFiltro({ ...Filtro, gestores: Filtro.gestores.some(e => e === value[Filtro.gestores.length]) ? Filtro.gestores.filter(e => { if (e !== value[Filtro.gestores.length]) { return e } }) : [...Filtro.gestores, value[Filtro.gestores.length]] })
    } else if (name === 'squads' && value.length >= 1) {
      setFiltro({ ...Filtro, squads: Filtro.squads.some(e => e === value[Filtro.squads.length]) ? Filtro.squads.filter(e => { if (e !== value[Filtro.squads.length]) { return e } }) : [...Filtro.squads, value[Filtro.squads.length]] })
    }
  }

  return (
    <ThemeProvider theme={theme}>
        <Drawer
            open={isOpen}
            anchor={'right'}
            onClose={onClose}
            role={'presentation'}
        >
            <Flex p={'1.5rem'} flexDir={'column'} gap={'.75rem'}>
                <Flex width={'100%'} justifyContent={'space-between'} pb={'.75rem'} borderBottom={'1px solid var(--c3)'}>
                    <Text fontSize={'.875rem'}>Filtros</Text>
                    <MdClose onClick={onClose} size={18} />
                </Flex>

                <Flex gap={'.75rem'} flexDir={'column'}>
                    <div>
                        <FormControl>
                            <InputLabel id="demo-multiple-checkbox-label" style={{ fontSize: '.75rem' }}>Areas</InputLabel>
                            <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={areas.filter(e => Filtro.areas.some(a => a === e.id)).map(e => { return e.nome })}
                            onChange={(e) => handleChange(e.target.value, 'areas')}
                            input={<OutlinedInput label="Areas" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{ width: '20rem', fontSize: '.75rem', borderRadius: '.25rem' }}
                            size='small'
                            >
                            {areas.map((area, i) => (
                                <MenuItem key={i} value={area.id}>
                                <Checkbox checked={Filtro.areas.some(e => e === area.id)} />
                                <ListItemText primary={area.nome} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl>
                            <InputLabel id="demo-multiple-checkbox-label" style={{ fontSize: '.75rem' }}>Squads</InputLabel>
                            <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={squads.filter(e => Filtro.squads.some(a => a === e.id)).map(e => { return e.nome })}
                            onChange={(e) => handleChange(e.target.value, 'squads')}
                            input={<OutlinedInput label="Squads" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{ width: '20rem', fontSize: '.75rem', borderRadius: '.25rem' }}
                            size='small'
                            >
                            {squads.map((squad, i) => (
                                <MenuItem key={i} value={squad.id}>
                                <Checkbox checked={Filtro.squads.some(e => e === squad.id)} />
                                <ListItemText primary={squad.nome} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl>
                            <InputLabel id="demo-multiple-checkbox-label" style={{ fontSize: '.75rem' }}>Gestores</InputLabel>
                            <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={gestores.filter(e => Filtro.gestores.some(a => a === e.id)).map(e => { return e.nome })}
                            onChange={(e) => handleChange(e.target.value, 'gestores')}
                            input={<OutlinedInput label="Gestores" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            style={{ width: '20rem', fontSize: '.75rem', borderRadius: '.25rem' }}
                            size='small'
                            >
                            {gestores.map((gestor, i) => (
                                <MenuItem key={i} value={gestor.id}>
                                <Checkbox checked={Filtro.gestores.some(e => e === gestor.id)} />
                                <ListItemText primary={gestor.nome} />
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </div>
                    <Flex gap={'.5rem'} justifyContent={'end'}>
                        <ButtonCmp VarColor='c6' onClick={() => { onRedefinir(); setFiltro({ areas: [], gestores: [], squads: [] }) }}>Redefinir filtros</ButtonCmp>
                        <ButtonCmp VarColor='Green2' leftIcon={<FaFilter />} onClick={() => onFilter(Filtro)}>
                            Filtrar
                        </ButtonCmp>
                    </Flex>
                </Flex>
            </Flex>
        </Drawer>
    </ThemeProvider>
  )
}

import { Box, Flex, Menu, MenuList } from '@chakra-ui/react'
import { FaCheckCircle, FaChevronDown, FaCopy, FaPencilAlt, FaRegTimesCircle, FaTrashAlt } from 'react-icons/fa'
import { iCompetencia } from '../../interfaces'
import { iForm } from '../../pages/Desempenho/GestaoFormularios'
import { Tabela } from '../../pages/Desempenho/GestaoFormularios/components/Tabela'
import { AccordionContainer } from '../AccordionContainer'
import { ButtonCmp } from '../ButtonCmp'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'
import { MenuButtonCmp } from '../MenuButtonCmp'
import { StyledMenuItem } from '../NavBar/Components/StyledMenuItem'

interface iProps {
  IsIndicativa?: boolean
  formulario: iForm
  onSelect?: (formId: string) => void
  onDuplicar: (formId: string) => void
  onEditar: (formId: string) => void
  onDelete: (formId: string) => void
  onViewCompetencia: (comp: iCompetencia) => void
}

export const FormCard: React.FC<iProps> = ({ IsIndicativa, formulario, onSelect, onDuplicar, onDelete, onViewCompetencia, onEditar }) => {
  function ReturnIcon(e: boolean): any {
    if (e) {
      return <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />
    } else {
      return <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />
    }
  }

  return (
    <Flex w={'full'} bg={'white'} pt={'.75rem'} px={'1rem'} flexWrap={'wrap'} borderRadius={'.25rem'} boxShadow={'var(--SombraBackground)'}>
      <Flex w={'full'}>
        <Flex flexDir={'column'} gap={'.3rem'} flex={'1'}>
          <Flex gap={'.25rem'} alignItems={'end'}>
            <h4>
              Nome do formulário:
              <span style={{ fontWeight: 700 }}> {formulario.nome}</span>
            </h4>
          </Flex>
        </Flex>
        <Flex gap={'.5rem'}>
          <Flex gap={'.5rem'} alignSelf={'start'} fontSize={'.813rem'} mr={'.5rem'}>
            <Flex alignItems={'center'} justifyContent={'start'}>{ReturnIcon(formulario.justificativa)} Comentários nas questões</Flex>
              {!IsIndicativa && <><Flex alignItems={'center'} justifyContent={'start'}>{ReturnIcon(formulario.exibirCompetencia)} Exibir competências</Flex>
              <Flex alignItems={'center'} justifyContent={'start'}>{ReturnIcon(formulario.exibirPeso)} Exibir peso</Flex>
              <Flex alignItems={'center'} justifyContent={'start'}>{ReturnIcon(formulario.competencias?.some(e => e.discursiva))}   Questões discurssivas
              </Flex></>}
          </Flex>
          {onSelect && <ElementoTooltipCmp bg={'var(--Red)'} label={(IsIndicativa && !formulario.canSelect) ? 'Esse formulário não possui o mínimo de competências selecionadas de acordo com a pre-definição da pesquisa.' : ''}><ButtonCmp
              VarColor='Green2'
              isDisabled={IsIndicativa && !formulario.canSelect}
              onClick={() => onSelect(formulario.id)}
            >Selecionar</ButtonCmp>
          </ElementoTooltipCmp>}
          <Menu>
            <MenuButtonCmp
                Icon={<FaChevronDown size={8} style={{ marginLeft: '-4px' }}/>}
                VarColor='Rosa'
            >Opções
            </MenuButtonCmp>
            <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'}>
              <StyledMenuItem onClick={() => onDuplicar(formulario.id)}>
                <FaCopy style={{ marginRight: '.375rem' }} size={'14'}/>
                Duplicar
              </StyledMenuItem>
              {formulario.status !== 2 && <StyledMenuItem onClick={() => onEditar(formulario.id)}>
                <FaPencilAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                Editar
              </StyledMenuItem>}
              <StyledMenuItem onClick={() => onDelete(formulario.id)}>
                <FaTrashAlt style={{ marginRight: '.375rem' }} size={'14'}/>
                Excluir
              </StyledMenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      {formulario.pesquisas.length > 0 ? <Flex mb={formulario.competencias && formulario.competencias.filter(e => !e.discursiva).length > 0 ? '' : '1rem'} gap={'.25rem'} alignItems={'center'} fontWeight={'500'} maxW={'83%'} fontSize={'.75rem'} flexWrap={'wrap'}><Box bg={'var(--Green2)'} w={'.5rem'} h={'.5rem'} borderRadius={'50%'}></Box> Pesquisas ativas: {formulario.pesquisas.slice(0, 3).map((pesq, i) => { return (<Box fontWeight={'700'}>{pesq}{(i + 1) === 3 ? '' : ', '}</Box>) })}</Flex> : <Flex gap={'.25rem'} alignItems={'center'} fontSize={'.75rem'} fontWeight={'600'} color={'var(--c7)'} mb={formulario.competencias && formulario.competencias.filter(e => !e.discursiva).length > 0 ? '' : '1rem'}>Sem pesquisas ativas no momento com esse formulário</Flex>}
      {formulario.competencias && formulario.competencias.filter(e => !e.discursiva).length > 0 &&
      <AccordionContainer title='Lista de competências' hideHr>
        <Flex overflowX={'auto'} flexWrap={'nowrap'} gap={'1rem'} pb={'.75rem'}>
          <Tabela
            competencias={formulario.competencias?.filter(e => !e.discursiva)}
            onView={(e) => onViewCompetencia(e)}
          />
        </Flex>
      </AccordionContainer>}
    </Flex>
  )
}

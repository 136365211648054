import { Divider, Flex, Img, Text } from '@chakra-ui/react'
import { FaCheckCircle, FaClock, FaPauseCircle, FaSpinner } from 'react-icons/fa'
import { iAcao } from '../TabPDI'

interface iAcaoProps {
  acao: iAcao
}

export const CardAcao: React.FC<iAcaoProps> = ({ acao }) => {
  return (
    <Flex gap={'1rem'} w={'48%'} borderRadius={'.25rem'} padding={'1rem'} border={'1px solid var(--c4)'}>
      <Flex flexDir={'column'}flex={1} gap={'.75rem'}>
        <Flex gap={'.625rem'} alignItems={'center'}>
          <Flex borderRadius={'50%'} border={'1px dashed var(--Azul)'} w={'3rem'} h={'3rem'}>
            <Img w={'3rem'} h={'3rem'} src={acao.competencia ? acao.competencia?.icone : 'https://sigah.blob.core.windows.net/assets/icones/Competencias-22.svg'}/>
          </Flex>
          <Flex flexDir={'column'}>
            <Text fontWeight={400} fontSize={'.875rem'} lineHeight={'1rem'}>{acao.competencia ? 'Competência' : 'Assunto'}</Text>
            <Text fontWeight={700} fontSize={'1.125rem'} lineHeight={'1.325rem'}>{acao.competencia ? acao.competencia.nome : acao.assunto}</Text>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} gap={'.325rem'}>
          <Text fontSize={'.925rem'} lineHeight={'1rem'}><strong>O que vou fazer?</strong></Text>
          <Text fontSize={'.875rem'} lineHeight={'1rem'}>{acao.titulo}</Text>
        </Flex>
      </Flex>
      <Divider h={'90%'} borderColor={'var(--Azul)'} orientation={'vertical'} opacity={'.9'}/>
      <Flex alignItems={'center'} width={'28%'} justifyContent={'center'}>
      {
          (acao.status === 1 || acao.status === 2) && (
              <Flex alignItems={'center'} color='var(--Yellow)' flexDir={'column'}>
                  <FaPauseCircle size={28} />
                  <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} textAlign={'center'} fontWeight={'bold'}
                  >
                      Aguardando <br/>{acao.status === 1 ? 'aprovação' : 'alteração'}
                  </Text>
              </Flex>
          )
      }

      {
          (acao.status === 5) && (
              <Flex alignItems={'center'} color='var(--Azul)' flexDir={'column'}>
                  <FaSpinner size={28} />
                  <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Em andamento</Text>
              </Flex>
          )
      }

      {
          (acao.status === 7) && (
              <Flex alignItems={'center'} color='var(--Green2)' flexDir={'column'}>
                  <FaCheckCircle size={28} />
                  <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Concluído</Text>
              </Flex>
          )
      }

      {
          (acao.status === 6) && (
              <Flex alignItems={'center'} color='var(--Red)' flexDir={'column'}>
                  <FaClock size={28} />
                  <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Atrasada</Text>
              </Flex>
          )
      }
      </Flex>
    </Flex>
  )
}

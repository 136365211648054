import { Avatar, Flex, Text, useToast } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { iColaboradorBase, iRespostaEscala } from '../../../interfaces'
import { LayoutFull } from '../../Layouts/LayoutFull'
import { SkillAnswer, SkillQuestion } from '../RespostaPesquisa/styles'
import { useEffect, useState } from 'react'
import { Button } from '../../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { iLinhaTabela } from '../ResultadoIndividual'
import { Tabela } from '../ResultadoIndividual/components/Tabela'
import { UpdateElementByPosition } from '../../../Utils/Helper'

interface iResposta {
  questaoId: string
  respostaId: string
}

interface iColabXResposta {
  colaborador: iColaboradorBase
  resposta: iRespostaEscala
}

interface iQuestao {
  id: string
  pergunta: string
  respostaColaborador: iColabXResposta
  respostaGestor: iColabXResposta
}

export const ConsolidarResposta: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { id } = useParams<{ id: string }>()

  const [Model, setModel] = useState<iQuestao[]>([])
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])
  const [ModelTabela, setModelTabela] = useState<iLinhaTabela[]>([])
  const [Participantes, setParticipantes] = useState<number[]>([])

  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [Respondido, setRespondido] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)

  function ObterRespostas(): void {
    appApi.get(`RespostaPesquisa/${id}/Consolidar`)
      .then((e) => {
        setModel(e.data.respostas)
        setEscalaRespostas(e.data.escala)
        setModelTabela(e.data.tabela)
        setParticipantes(e.data.participantes)
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao obter as respostas, tente mais tarde!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  const isSelect = (perguntaId: string, resposId: string): boolean => Respostas.find(e => e.questaoId === perguntaId && e.respostaId === resposId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => Respostas.find(e => e.questaoId === perguntaId) !== undefined

  function SelResposta(questaoId: string, resposId: string): void {
    if (!Participantes.includes(7)) {
      setParticipantes([...Participantes, 7])
    }

    if (!isSelectQuestao(questaoId)) {
      setRespostas(oldArray => [...oldArray, { questaoId: questaoId, respostaId: resposId }])
    } else {
      if (!isSelect(questaoId, resposId)) {
        UpdateResposta(questaoId, resposId)
      }
    }

    const posCompetencia = ModelTabela.findIndex(e => e.questoes.some(r => r.idQuestao === questaoId))
    if (posCompetencia !== -1) {
      const posQuestao = ModelTabela[posCompetencia].questoes.findIndex(e => e.idQuestao === questaoId)
      if (posQuestao !== -1) {
        const copy = ModelTabela[posCompetencia]
        copy.questoes[posQuestao].consolidado = EscalaRespostas.find(e => e.id === resposId)?.peso.toFixed(2)
        const sum = copy.questoes.filter(e => e.consolidado).map(e => parseFloat(e.consolidado ?? '0') ?? 0).reduce((a, b) => a + b, 0)
        copy.consolidado = ((sum / copy.questoes.filter(e => e.consolidado).length) || 0).toString()
        setModelTabela(UpdateElementByPosition(
          [...ModelTabela],
          posCompetencia,
          copy
        ))
      }
    }
  }

  function UpdateResposta(questaoId: string, respostaId: string): void {
    const respostas = [...Respostas]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].respostaId = respostaId
      setRespostas(respostas)
    }
  }

  function Enviar(): void {
    if (Model.filter(r => !Respostas.some(e => e.questaoId === r.id)).length > 0) {
      toast({
        title: 'Responda todas as questões para prosseguir!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })

      return
    }
    setIsLoading(true)
    appApi.post(`RespostaPesquisa/${id}/Consolidar`, {
      respostas: Respostas
    })
      .then(() => {
        toast({
          title: 'Resposta enviada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setIsLoading(false)
        setRespondido(true)
      })
      .catch(err => {
        setIsLoading(false)
        toast({
          title: 'Erro ao enviar as respostas, tente mais tarde!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        console.log(err)
      })
  }

  function TemColuna(tipo: number): boolean {
    return Participantes.includes(tipo)
  }

  useEffect(() => {
    ObterRespostas()
  }, [])

  return (
    <LayoutFull>
      {Model.length > 0 && !Respondido &&
        <Flex bg={'var(--c1)'} flexDir={'column'} p='8rem'>
          <Flex mb={'5.625rem'} justifyContent={'space-around'}>
            <Flex onClick={ObterRespostas} alignItems={'center'} gap={'.5rem'}>
              <Avatar name={Model[0].respostaGestor.colaborador.nome} src={Model[0].respostaGestor.colaborador.avatar} />
              <Text>Gestor: {Model[0].respostaGestor.colaborador.nome}</Text>
            </Flex>

            <Flex alignItems={'center'} gap={'.5rem'}>
              <Avatar name={Model[0].respostaColaborador.colaborador.nome} src={Model[0].respostaColaborador.colaborador.avatar} />
              <Text>Avaliado: {Model[0].respostaColaborador.colaborador.nome}</Text>
            </Flex>
          </Flex>
          <Flex gap={'2rem'} flexDir='column'>
            {Model.map((e, i) => {
              return (
                <Flex key={i} flexDir={'column'} gap='1.5rem'>
                  <Text fontSize={'1.5rem'} fontWeight={'400'}>{e.pergunta}</Text>
                  <Flex justifyContent={'space-around'} gap={'1rem'}>
                    <Flex borderRadius={'.5rem'} p={'.5rem'} border={'1px solid var(--Gray4)'} alignItems={'center'} gap={'.5rem'}>
                      <Avatar size={'sm'} name={e.respostaGestor.colaborador.nome} src={e.respostaGestor.colaborador.avatar} />
                      <Text>Resposta do gestor: {e.respostaGestor.resposta.resposta}</Text>
                    </Flex>

                    <Flex borderRadius={'.5rem'} p={'.5rem'} border={'1px solid var(--Gray4)'} alignItems={'center'} gap={'.5rem'}>
                      <Avatar size={'sm'} name={e.respostaColaborador.colaborador.nome} src={e.respostaColaborador.colaborador.avatar} />
                      <Text>Resposta do colaborador: {e.respostaColaborador.resposta.resposta}</Text>
                    </Flex>
                  </Flex>
                  <SkillQuestion>
                    <div>
                      <Text fontSize={'18px'} my={'.5rem'}>Nota final</Text>
                    </div>
                    {
                      EscalaRespostas.map((r, i2) => {
                        return (
                          <SkillAnswer
                            key={i2}
                            type="button"
                            isSelected={isSelect(e.id, r.id)}
                            onClick={() => SelResposta(e.id, r.id)}
                          >
                            <div>
                              <FaCheckCircle />
                            </div>
                            <span>{r.resposta}</span>
                          </SkillAnswer>
                        )
                      })
                    }
                  </SkillQuestion>
                </Flex>
              )
            })}
          </Flex>
          <Flex w={'100%'}>
            <Tabela
              cores={[]}
              discursivas={[]}
              recomendacoes={[]}
              mediaGeral={0}
              temColuna={TemColuna}
              linhas={ModelTabela}
              apenasAutoAvaliacao={false}
            />
          </Flex>
          <Flex justifyContent={'center'} margin={'1rem 0rem'}>
            <Button
              isLoading={IsLoading}
              onClick={Enviar}
              size='lg'
              VarColor='v2'
            >Enviar resposta</Button>
          </Flex>
        </Flex>
      }
      {Respondido &&
        <Flex bg={'var(--c1)'} flexDir={'column'} h='100%' p='8rem'>
          <Text
            fontWeight={'400'}
            fontSize='x-large'
            style={{
              color: 'var(--c6)'
            }}
          >Respostas enviadas com sucesso!</Text>
          {(localStorage.getItem('token')) && (
              <Flex marginTop={'1rem'}>
                <Button
                  VarColor='Green2'
                  size={'lg'}
                  onClick={() => nav(-1)}
                >Voltar para reunião 1:1</Button>
              </Flex>
          )}
        </Flex>
      }
    </LayoutFull>
  )
}

import styled, { css } from 'styled-components'

interface ISkillAnswerProps {
  isSelected?: boolean
}

export const Container = styled.div`
background-color: white;
padding: 1rem;
`

export const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 1.5rem 2rem 1rem 2rem;
background-color: white;
align-items: center;

img{
  max-height: 10rem;
  max-width: 10rem;
  object-fit: scale-down;
}
`

export const SkillQuestion = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--c5);
  div {
    display: flex;
    align-items: baseline;
    h3 {
      margin: 2rem 1rem 1rem;
      font-weight: normal;
      color: black;
      font-size: 1.5rem;
    }
    span {
      color: var(--terc3);
    }
  }
  div + div {
    margin-top: 0.5rem;
  }
`

export const SkillAnswer = styled.button<ISkillAnswerProps>`
  background: var(--c2);
  border: 2px solid var(--AzulOpacoBg);
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  outline: 0;
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0.325rem;
  svg {
    font-size: 1.5rem;
    background: white;
    border-radius: 50%;
    color: var(--c4);
    ${(props) =>
      props.isSelected &&
      css`
        color: var(--Green3);
      `};
  }
  ${(props) =>
    props.isSelected &&
    css`
      background: var(--Azul);
    `};
  span {
    margin-left: .75rem;
    color: black;
    ${(props) =>
      props.isSelected &&
      css`
        color: var(--c1);
        font-weight: 800;
      `};
    font-size: .875rem;
  }
  :hover{
    ${(props) =>
      !props.isSelected &&
      css`
        background: var(--AzulOpacoBg);
      `};
  }
`

export const ComentarioContainer = styled.div`
  h3 {
    margin: 1rem 0rem .25rem;
    color: black;
  }
  textarea {
    background: white;
    border-radius: 0.25rem;
    padding: .75rem 1rem;
    border: 1;
    border-color: var(--c4);
    outline: 0;
    display: flex;
    width: 100%;
    min-height: 8rem;
    margin-top: 0.25rem;
    font-size: 1rem;
    color:black;
  }
`

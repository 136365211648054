import styled, { css } from 'styled-components'

export const ButtonMenu = styled.button<{selected?: boolean}>`
background: #FFFFFF;
border: 2px solid var(--Azul);
border-radius: 4px;
font-family: 'Roboto';
font-weight: 600;
font-size: .875rem;
color: var(--Azul);
padding: .75rem 1rem;


 ${props => !props.selected && css`
    &:hover{
      transition:var(--transitionBtns);
      background:var(--Azul);
      color: white;
    }
 `}

 ${props => props.selected && css`
     background: var(--Azul);
     color:white;
 `}
`

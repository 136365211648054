import { Flex, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../../../components/ElementoTooltipCmp'
import { ModalDelete } from '../../../../../../../../components/ModalDelete'
import { SelectCmp } from '../../../../../../../../components/SelectCmp'
import { appApi } from '../../../../../../../../services/appApi'
import { ModalEscala } from '../ModalEscala'

export interface iEscalaFlexivel {
  id: string
  nome: string
  questoes: iQuestaoEscala[]
  ocultarPeso: boolean
}

interface iQuestaoEscala {
  id: string
  alternativa: string
  peso?: number
}

export const Escala: React.FC<any> = ({ escalaId, onChangeEscala }: { escalaId: string, onChangeEscala: (e: string) => void }) => {
  const [EscalaId, setEscalaId] = useState(escalaId ?? '')
  const [Escalas, setEscalas] = useState<iEscalaFlexivel[]>([])
  const [IsCreating, setIsCreating] = useState(false)
  const [ModalEscalaIsOpen, setModalEscalaIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  function getEscala(): void {
    appApi.get('EscalaFlexivel')
      .then(res => setEscalas(res.data))
      .catch(err => console.log(err))
  }

  function DeleteEscala(): void {
    appApi.delete(`EscalaFlexivel/${EscalaId}`)
      .then(res => getEscala())
      .catch(err => console.log(err))
      .finally(() => setEscalaId(''))
  }

  function onSaveEscala(e: string): void {
    appApi.get('EscalaFlexivel')
      .then(res => {
        setEscalas(res.data)
        setEscalaId(e)
        onChangeEscala(e)
        setModalEscalaIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getEscala()
  }, [])

  useEffect(() => {
    setIsCreating(false)
  }, [EscalaId])

  useEffect(() => {
    if (IsCreating) {
      setModalEscalaIsOpen(true)
    }
  }, [IsCreating])

  return (
        <Flex flexDirection={'column'} width={'100%'}>
          <ModalEscala
            isOpen={ModalEscalaIsOpen}
            onClose={() => { setModalEscalaIsOpen(false); setIsCreating(false) }}
            onSave={(e) => { onSaveEscala(e) }}
            escala={IsCreating ? undefined : Escalas.find(e => e.id === EscalaId)}
          />
          <ModalDelete
            isOpen={ModalDeleteIsOpen}
            onConfirm={DeleteEscala}
            onRequestClose={() => { setModalDeleteIsOpen(false) }}
            message='Tem certeza que deseja excluir essa escala?'
            center
          />
          <Flex gap={'.5rem'} alignItems={'end'} mb={'.75rem'}>
            <SelectCmp title='Escala da questão' OnChange={(e) => { setEscalaId(e); onChangeEscala(e) }} value={EscalaId} w={'20rem'}>
              <option value={''}>Nenhuma</option>
              {Escalas.map(e => {
                return (
                  <option value={e.id}>{e.nome}</option>
                )
              })}
            </SelectCmp>
            <ElementoTooltipCmp bg={'var(--Azul)'} label={'Criar escala'}>
              <ButtonCmp VarColor='Green2' onClick={() => { setIsCreating(true) }}><FaPlus /></ButtonCmp>
            </ElementoTooltipCmp>
            <ElementoTooltipCmp bg={'var(--Rosa)'} label={EscalaId !== '' ? '' : 'Selecione uma escala para poder editá-la'}>
              <ButtonCmp VarColor='Rosa' disabled={EscalaId === ''} onClick={() => setModalEscalaIsOpen(true)}><FaPencilAlt /></ButtonCmp>
            </ElementoTooltipCmp>
            <ElementoTooltipCmp bg={'var(--Red)'} label={EscalaId !== '' ? '' : 'Selecione uma escala para poder excluí-la'}>
              <ButtonCmp VarColor='Red' disabled={EscalaId === ''} onClick={() => setModalDeleteIsOpen(true)}><FaTrash /></ButtonCmp>
            </ElementoTooltipCmp>
          </Flex>
          {EscalaId !== ''
            ? <RadioGroup width={'100%'}>
              <Stack direction={'column'} width={'100%'}>
                  {
                      Escalas?.find(e => e.id === EscalaId)?.questoes?.map((e, i) => {
                        return (
                            <Flex gap={'.5rem'}>
                              <Input
                                alignItems={'center'}
                                justifyContent={'center'}
                                textAlign={'center'}
                                border={'1px solid var(--c4)'}
                                h={'2.25rem'}
                                pr={'0rem'}
                                width={'4.75rem'}
                                value={e.peso}
                                _focus={{ border: '1px solid var(--c4)' }}
                                type={'number'}
                                readOnly
                                fontSize={'.875rem'}
                                placeholder={'Peso'}
                              />
                              <Flex
                                  key={e.id}
                                  borderRadius={'4px'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  border={'1px solid var(--c4)'}
                                  width={'100%'}
                                  padding={'0 .875rem'}
                              >
                                  <Flex width={'100%'}>
                                      <Radio marginRight={'.75rem'} value={e.id}/>
                                      <Input
                                          type={'text'}
                                          border={'none'}
                                          _focus={{ border: 'none' }}
                                          padding={'0'}
                                          h={'2.125rem'}
                                          value={e.alternativa}
                                          readOnly
                                          fontSize={'.875rem'}
                                          placeholder={'Digite sua alternativa aqui'}
                                      />
                                  </Flex>
                              </Flex>
                            </Flex>
                        )
                      })
                  }
              </Stack>
          </RadioGroup>
            : <Flex p={'2rem 1rem'} justifyContent={'center'} alignItems={'center'} fontWeight={700}>
              Selecione ou crie uma escala para essa questão
            </Flex>}
        </Flex>
  )
}

import { useEffect, useState } from 'react'
import { FaCheck, FaCog, FaPeopleArrows, FaRedo, FaTimes, FaUserCheck, FaUserFriends, FaUsers, FaUserTie } from 'react-icons/fa'
import { Container, IconText } from './styles'
import { appApi } from '../../../../../../../services/appApi'
import { Flex } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { useNavigate } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { parseJwt } from '../../../../../../../services/token'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'

interface iProps {
  etapa: iEtapaColaborador
  onReabrirEtapa: (id: string) => void
  processoId: string
}

interface iCardResponse {
  participantes: number[]
  formularioSelecionado: boolean
  formularioAprovado: boolean
  avaliacaoEncerrada: boolean
  resultadoLiberado: boolean
  respostas: iResposta[]
  avaliacaoId: string
  dataInicio: string
  dataFim: string
  configuracaoLiberada: boolean
  editarTrilhaLiderado?: boolean
}

interface iResposta {
  tipo: number
  respondido: boolean
}

export const CardPesquisaStackholder: React.FC<iProps> = ({ etapa, onReabrirEtapa, processoId }) => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iCardResponse>()

  function ObterParticipantes(): void {
    appApi.get(`PesquisaPercepcao/${etapa.interacoes[0].idExterno}/Detalhe`)
      .then(response => { setModel(response.data) })
      .catch(err => console.error(err))
  }

  function ObterNomeAvaliador(tipo: number): string {
    const data = {
      nomeAvaliador: ''
    }

    switch (tipo) {
      case 6:
        data.nomeAvaliador = 'Alta liderança'
        break
      case 4:
        data.nomeAvaliador = 'Outros'
        break
      case 3:
        data.nomeAvaliador = 'Liderados'
        break
      case 2:
        data.nomeAvaliador = 'Pares'
        break
      case 1:
        data.nomeAvaliador = 'Gestores'
        break
      case 0:
        data.nomeAvaliador = 'Autoavaliação'
        break
      default:
        break
    }

    return data.nomeAvaliador
  }

  function ObterIcone(tipo: number): any {
    switch (tipo) {
      case 0:
        return <FaUserCheck size={18} />
      case 1:
        return <FaUserTie size={18} />
      case 2:
        return <FaPeopleArrows size={18} />
      case 3:
        return <FaUsers size={18} />
      case 4:
        return <FaUserFriends size={18} />
      case 6:
        return <FaUserFriends size={18} />
    }
  }

  useEffect(() => {
    ObterParticipantes()
  }, [])

  return (
    <Container>
      {etapa.status !== 2 && (
        <>
          <h4>Data da pesquisa:</h4>
          <Flex mb='1rem' alignItems={'center'} justifyContent={(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) ? 'center' : 'flex-start'} ml={(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) ? '0' : '.8rem'} gap={'1rem'}>
            <span>{Model?.dataInicio ?? ''} - {Model?.dataFim ?? ''}</span>
            {(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) && <ButtonCmp
              VarColor='Rosa'
              rightIcon={<FaRedo />}
              onClick={() => { onReabrirEtapa(etapa.interacoes[0].idExterno); ObterParticipantes() }}
            >Reagendar pesquisa</ButtonCmp>}
          </Flex>
        </>
      )}

      <h4>Quem irá participar:</h4>
      {
        Model?.participantes.map((e: number, i) => {
          const respostasEixo = Model.respostas.filter(r => r.tipo === e)
          return (
            <IconText key={i}>
              {ObterIcone(e)}
              <span>{ObterNomeAvaliador(e)}</span>
              {respostasEixo.length > 1 &&
                <Flex
                  fontWeight={'700'}
                  ml={'.5rem'}
                  letterSpacing={'-1px'}
                  fontSize={'14px'}
                >{respostasEixo.filter(r => r.respondido).length}/{respostasEixo.length}
                </Flex>
              }
              {respostasEixo.every(r => r.respondido)
                ? <FaCheck
                  style={{ color: 'var(--Green3)' }}
                />
                : <FaTimes
                 style={{ color: 'var(--Red)' }}
                />
              }
            </IconText>
          )
        })
      }
      <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={'.5rem'}>
        {(!Model?.avaliacaoEncerrada && (parseJwt().adm === 'True' || Model?.editarTrilhaLiderado)) &&
          (<ElementoTooltipCmp label={(!Model?.configuracaoLiberada) ? 'É necessário reagendar a pesquisa' : ''} bg={'var(--Red)'}>
            <ButtonCmp
              leftIcon={<FaCog />}
              VarColor='Green2'
              onClick={() => nav(`/Jornada/Percepcao/Quantitativa/Participantes/${etapa.interacoes[0].idExterno}/Processo/${processoId}`)}
              isDisabled={(!Model?.configuracaoLiberada)}
            >Editar participantes</ButtonCmp>
          </ElementoTooltipCmp>)
        }
        <Flex position={'relative'}>
          {
            (!Model?.resultadoLiberado) && (
              <ElementoTooltipCmp label={'O resultado ainda não está disponível!'} bg={'var(--Red)'}>
                <ButtonCmp
                  VarColor='Green2'
                  disabled
                >Resultado</ButtonCmp>
              </ElementoTooltipCmp>
            )
          }
          {
            (Model?.resultadoLiberado) && (
              <ButtonCmp
                VarColor='Green2'
                onClick={() => nav(`/Jornada/Percepcao/Quantitativa/ResultadoIndividual/${Model.avaliacaoId}`)}
              >Resultado</ButtonCmp>
            )
          }
        </Flex>
      </Flex>
    </Container>
  )
}

import { Image, Text, View } from '@react-pdf/renderer'

interface iProps {
  variant: number
}
export const IconeTitulo: React.FC<iProps> = ({ variant }) => {
  const titulo = variant === 1
    ? 'Competências consideradas os maiores destaques positivos do avaliado na posição atual'
    : variant === 0
      ? 'Competências consideradas prioridades de desenvolvimento do avaliado na função atual'
      : variant === 2
        ? 'Você mais se motiva'
        : variant === 3
          ? 'Você menos se motiva'
          : variant === 4
            ? 'Outras oportunidades de desenvolvimento'
            : ''
  const icone = variant === 0
    ? 'https://sigah.blob.core.windows.net/onboard/icones/Vector_884e8ab6-fb3b-4b3b-8345-c73c8b3b8462.png'
    : variant === 1
      ? 'https://sigah.blob.core.windows.net/onboard/icones/Vector %281%29_b92e0abf-bf52-484d-b8df-56d9f86a0f51.png'
      : variant === 2
        ? 'https://sigah.blob.core.windows.net/onboard/icones/Component 81_c1d3395f-62a7-47c2-b060-82b5478ccc28.png'
        : variant === 3
          ? 'https://sigah.blob.core.windows.net/onboard/icones/Component 81 %281%29_30245163-37cb-4bae-8547-e9ef95e8f8ee.png'
          : variant === 4
            ? 'https://sigah.blob.core.windows.net/onboard/icones/Vector 3_acfef63d-3d99-4e9f-b709-9770613a6d96.png'
            : ''
  return (
        <View style={{ flexDirection: 'row' }}>
            <Image
                source={icone}
                style={{ height: 20, objectFit: 'scale-down', marginRight: 12 }}
            />
            <Text>{titulo}</Text>
        </View>
  )
}

import { Flex, useToast } from '@chakra-ui/react'
import { InputHTMLAttributes, useEffect, useState } from 'react'
import { iTag } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { CheckboxCmp } from '../CheckboxCmp'
import { SelectCmp } from '../SelectCmp'
import { Tag } from '../Tag'
import { Container, FlexTags, InputTag, MenuDrop } from './styles'

interface ITagInput extends InputHTMLAttributes<HTMLInputElement> {
  isGrey?: boolean
  defaultValue?: string
  selecionadas: string[]
  addTag: (tag: string) => void
  removeTag: (id: string) => void
}

export const CompleteTagContainer: React.FC<ITagInput> = ({ isGrey, selecionadas, defaultValue, addTag, removeTag, ...rest }) => {
  const [Txt, setTxt] = useState('')
  const [Tags, setTags] = useState<iTag[]>([])
  const [Squads, setSquads] = useState<iTag[]>([])
  const [Gestor, setGestor] = useState(false)
  const [SquadSelecionado, setSquadSelecionado] = useState('')
  const toast = useToast()
  function getTags(create?: boolean): void {
    appApi.get('Tag?tipo=1')
      .then(res => {
        const data: iTag[] = res.data
        setTags(data)
        if (create) {
          const buscar = data.find(e => e.texto === Txt)
          if (buscar !== undefined) {
            InternalSelect(buscar.id)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function getSquads(): void {
    appApi.get('Tag?tipo=2')
      .then(res => {
        const data: iTag[] = res.data
        setSquads(data)
      })
      .catch(err => console.log(err))
  }

  function CleanFields(): void {
    if (!defaultValue) {
      setSquadSelecionado('')
    }
    setTxt('')
  }
  function validaSquads(idSqad: string): void {
    const lista = Tags.filter(p => p.tagsPaiIds?.includes(idSqad))

    if (lista.filter(t => selecionadas.includes(t.id)).length > 0) {
      toast({
        title: 'O colaborador já tem um papel no Squad selecionado!',
        description: 'Só é permitido um papel por Squad.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    setSquadSelecionado(idSqad)
  }
  function InternalSelect(id: string): void {
    addTag(id)
    CleanFields()
  }

  function CriarTag(): void {
    const form = {
      texto: Txt,
      tipo: 1,
      gestor: Gestor,
      tagPaiId: SquadSelecionado
    }

    appApi.post('Tag', form)
      .then(() => {
        getTags(true)
        setTxt('')
      }).catch(err => console.error(err))
  }

  useEffect(() => {
    getTags()
    getSquads()
  }, [])

  useEffect(() => {
    getSquads()
  }, [selecionadas])

  useEffect(() => {
    if (defaultValue) {
      setSquadSelecionado(defaultValue)
    }
  }, [defaultValue])

  return (
    <>
      <Flex className={Squads.filter(e => selecionadas.find(r => r === e.id) !== undefined).length > 0 ? '' : 'disabled'} w={'100%'} gap={'0 1rem'}>
        <SelectCmp w={'100%'} value={SquadSelecionado} OnChange={(e) => validaSquads(e)}>
          <option value={''}>Selecione o squad</option>
          {
            Squads.filter(e => selecionadas.find(r => r === e.id) !== undefined).map((e) => {
              return (
                <option value={e.id}>{e.texto}</option>
              )
            })
          }
        </SelectCmp>
        <Flex h={'33px'}>
          <CheckboxCmp isChecked={Gestor} OnChange={(e) => setGestor(e)}>
            Liderança
          </CheckboxCmp>
        </Flex>
        <Container style={{ width: '100%' }}>
          <InputTag theme={isGrey ? 'c2' : 'c1'}>
            <input value={Txt} autoComplete='off' type={'text'} placeholder='Digite um papel' {...rest} onChange={e => setTxt(e.target.value)} />
            <button onClick={CriarTag}>+</button>
          </InputTag>
          <Flex position={'relative'} width={'100%'}>
            <MenuDrop>
              {
                Tags.filter((e: iTag) => {
                  if ((e.tagsPaiIds === undefined || e.tagsPaiIds === null) || e.tagsPaiIds.find(e => e === SquadSelecionado) === undefined || Txt === '' || selecionadas.find(r => r === e.id) !== undefined) {
                    return null
                  } else if (e.texto.toLocaleLowerCase().includes(Txt.toLocaleLowerCase())) {
                    return e
                  }
                  return null
                }).slice(0, 5).map((e: iTag, i) => {
                  return (
                    <li onClick={() => InternalSelect(e.id)} key={i}>{e.texto}</li>
                  )
                })
              }
            </MenuDrop>
          </Flex>
          {
            (Tags.filter(e => selecionadas.find(r => r === e.id) !== undefined).length > 0) && (
              <FlexTags>
                {
                  Tags.filter(e => selecionadas.find(r => r === e.id) !== undefined).map((e: iTag, i) => {
                    return (
                      <Tag hasX key={i} onDelete={() => removeTag(e.id)}>{(e.tagsPaiIds !== undefined && e.tagsPaiIds !== null) ? Squads.find(s => e.tagsPaiIds !== undefined && s.id === e.tagsPaiIds[0])?.texto as string + ' - ' + e.texto : e.texto}</Tag>
                    )
                  })
                }
              </FlexTags>

            )
          }
        </Container>
      </Flex>
    </>
  )
}

import styled from 'styled-components'

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem 1rem 0 1rem;

strong{
    color: var(--vm4);
    font-weight: 900;
}

small{
    color: var(--terc3);
    font-weight: 500;
}

span{
    color:var(--a5);
}
`

/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaEye, FaPaperPlane, FaPlus, FaQrcode, FaUserCircle } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ElementoTooltip } from '../../../components/ElementoTooltip'
import { SearchCmp } from '../../../components/SearchCmp'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { iGestaoConvite } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { CircleText } from './Components/CircleText'
import { ModalConvitePorApp } from './Components/ModalConvite'
import { ModalLinkAberto } from './Components/ModalLinkAberto'
import { TableConvites } from './Components/Table'

type iParamProps = {
  imersaoId: string
}

export const GestaoConvitesImersao: React.FC = () => {
  const toast = useToast()

  const param = useParams<iParamProps>()
  const nav = useNavigate()

  const [ModalAddApp, setModalAddApp] = useState(false)
  const [ModalLinkAbertoIsOpen, setModalLinkAbertoIsOpen] = useState(false)

  const [Model, setModel] = useState<iGestaoConvite>({} as iGestaoConvite)

  const [TxtPesquisa, setTxtPesquisa] = useState('')

  function getConvites(): void {
    appApi.get(`ImersaoExternaConvite/${param.imersaoId as string}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function ConvidarPoStatus(status: number): void {
    appApi.post(`ImersaoExternaConvite/${param.imersaoId as string}/Status?status=${status}`)
      .then(() => {
        getConvites()
        toast({
          title: 'Convites enviados com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => console.log(err))
  }

  function EnviarConvites(id: string): void {
    appApi.put('ImersaoExternaConvite/Enviar', [id])
      .then(() => {
        getConvites()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConvites()
  }, [])

  return (
        <Body>
            {
                (ModalAddApp) && (
                    <ModalConvitePorApp
                        isOpen={ModalAddApp}
                        rows={Model.convites}
                        imersaoId={param.imersaoId as string}
                        onClose={() => setModalAddApp(false)}
                        Reload={getConvites}
                    />
                )
            }

            <ModalLinkAberto
                imersaoId={param.imersaoId as string}
                isOpen={ModalLinkAbertoIsOpen}
                onClose={() => setModalLinkAbertoIsOpen(false)}
            />
            <WhiteContainer margin='1rem 0'>
                <Flex justifyContent='space-between' mb={'1rem'}>
                    <h1>{Model.titulo}</h1>
                    <Flex gap={'1rem'}>
                        <CircleText
                        fontsize='14px'
                        varColorCircle='Azul'
                        varColorText='black'
                        titulo={Model.countConviteUsuario?.disparos}
                        >
                            Disparos
                        </CircleText>
                        <CircleText
                        fontsize='14px'
                        varColorCircle='Rosa'
                        varColorText='black'
                        titulo={Model.countConviteUsuario?.convitesVisualizados + Model?.linkAberto}
                        >
                            Visualizações
                        </CircleText>
                        <CircleText
                        fontsize='14px'
                        varColorCircle='c5'
                        varColorText='black'
                        titulo={(Model.countConviteUsuario?.convitesEnviados - Model.countConviteUsuario?.convitesVisualizados)}
                        >
                            Não visualizados
                        </CircleText>
                    </Flex>
                </Flex>
                <Flex gap={'0rem 1rem'}>
                    <Flex justifyContent={'space-between'} width={'100%'} padding={'1rem'} flexDir={'column'} border={'1px solid var(--Azul)'} boxShadow='var(--SombraBackground)' borderRadius={'4px'}>
                        <Flex marginBottom={'1rem'} justifyContent={'center'} gap={'1rem'} alignItems={'center'}>
                            <Flex flexDir={'column'} alignItems={'center'}>
                                <FaUserCircle size={60}/>
                                <Text fontWeight={'bold'} style={{ marginTop: '.5rem' }}>Usuários</Text>
                            </Flex>
                            <Flex marginLeft={'.8rem'} gap={'.5rem 0rem'} flexDirection={'column'}>
                                <CircleText
                                    fontsize='14px'
                                    varColorCircle='Azul'
                                    varColorText='black'
                                    titulo={Model.countConviteUsuario?.disparos}
                                >Disparos
                                </CircleText>

                                <CircleText
                                    fontsize='14px'
                                    varColorCircle='Rosa'
                                    varColorText='black'
                                    titulo={Model.countConviteUsuario?.convitesVisualizados}
                                >Visualizações
                                </CircleText>

                                <CircleText
                                    fontsize='14px'
                                    varColorCircle='c5'
                                    varColorText='black'
                                    titulo={(Model.countConviteUsuario?.convitesEnviados - Model.countConviteUsuario?.convitesVisualizados)}
                                >Não visualizaram
                                </CircleText>
                            </Flex>
                        </Flex>
                        <Flex justifyContent={'center'}>
                            <ButtonCmp
                                VarColor='Green2'
                                leftIcon={<FaPlus />}
                                onClick={() => setModalAddApp(true)}
                            >Adicionar convite</ButtonCmp>
                        </Flex>
                    </Flex>

                    <Flex width={'100%'} justifyContent='space-between' padding={'1rem'} flexDir={'column'} border={'1px solid var(--Azul)'} boxShadow='var(--SombraBackground)' borderRadius={'4px'}>
                        <Flex justifyContent={'center'} flexDir='column' gap={'.25rem'} alignItems={'center'}>
                            <Flex flexDir={'column'} alignItems={'center'}>
                                <FaQrcode size={40} />
                                <Text fontWeight={'700'} style={{ marginTop: '.25rem' }}>Link aberto</Text>
                            </Flex>
                            <CircleText titulo={Model.linkAberto}
                                fontsize='14px'
                                varColorCircle='Rosa'
                                varColorText='black'
                            >
                                Visualizações
                            </CircleText>
                        </Flex>
                        <Flex justifyContent={'center'} alignItems={'end'} height={'100%'}>
                            <ButtonCmp onClick={() => setModalLinkAbertoIsOpen(true)} size={'md'} VarColor={'Blue2'} leftIcon={<FaEye color='white' />}>Visualizar link</ButtonCmp>
                        </Flex>
                    </Flex>
                </Flex>

                {
                    (Model.convites?.length > 0) && (
                        <>
                            <Flex justifyContent={'space-between'} margin={'1.5rem 0rem'}>
                                <h3>Lista de convidados</h3>
                                <SearchCmp
                                    EnterAction={() => {}}
                                    OnChange={setTxtPesquisa}
                                    placeholder='Buscar participante'
                                    width='15rem'
                                />
                            </Flex>
                            <TableConvites
                                rows={Model.convites.filter((e) => {
                                  if (TxtPesquisa === '') return e
                                  else if (e?.nome?.toLowerCase().includes(TxtPesquisa.toLowerCase())) return e
                                  return null
                                })}
                                onCheck={() => { }}
                                onSend={EnviarConvites}
                            />
                        </>
                    )
                }
                <Flex justifyContent={'end'} gap='.5rem' mt={'1rem'}>
                    <ButtonCmp onClick={() => nav(-1)} leftIcon={<FaAngleLeft />} size={'lg'} VarColor='c4'>Cancelar</ButtonCmp>
                    {(Model.convites?.length === 0) && <ElementoTooltip bg={'var(--Rosa)'} label={Model.convites?.length === 0 ? 'Adicione pelo menos um convite antes de disparar' : ''}>
                        <ButtonCmp
                        leftIcon={<FaPaperPlane />}
                        VarColor='Rosa'
                        onClick={() => ConvidarPoStatus(1)}
                        isDisabled
                    >Disparar não visualizados</ButtonCmp></ElementoTooltip>}
                    {!(Model.convites?.length === 0) && <ElementoTooltip bg={'var(--Rosa)'} label={'Redispara convites já enviados da imersão que ainda não foram realizados'}>
                        <ButtonCmp
                        leftIcon={<FaPaperPlane />}
                        VarColor='Rosa'
                        onClick={() => ConvidarPoStatus(1)}
                    >Disparar não visualizados</ButtonCmp></ElementoTooltip>}

                    {(Model.convites?.length === 0) && <ElementoTooltip bg={'var(--Azul)'} label={Model.convites?.length === 0 ? 'Adicione pelo menos um convite antes de disparar' : ''}>
                        <ButtonCmp
                        leftIcon={<FaPaperPlane />}
                        VarColor='Green2'
                        onClick={() => ConvidarPoStatus(0)}
                        isDisabled
                    >Disparar novos</ButtonCmp></ElementoTooltip>}
                    {!(Model.convites?.length === 0) && <ElementoTooltip bg={'var(--Azul)'} label={'Dispara convites que ainda não foram enviados'}><ButtonCmp
                        leftIcon={<FaPaperPlane />}
                        VarColor='Green2'
                        onClick={() => ConvidarPoStatus(0)}
                    >Disparar novos</ButtonCmp></ElementoTooltip>}
                </Flex>
            </WhiteContainer>
        </Body>
  )
}

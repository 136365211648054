import { useState } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { Flex } from '@chakra-ui/layout'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../components/ModalBase'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'

interface iProps{
  onSave: (dataInicio: string, hInicio: string, duracao: string) => void
  onClose: () => void
}

export const ModalConfiguracao: React.FC<iProps> = ({ onClose, onSave }) => {
  const [DataInicio, setDataInicio] = useState('')
  const [HInicio, setHInicio] = useState('')
  const [Duracao, setDuracao] = useState('5')

  return (
        <ModalBase
            isOpen
            onClose={onClose}
            Titulo="Configuração"
            Width='25rem'
        >
            <Flex padding={'1rem'} gap='.75rem' flexDir={'column'} alignItems='center'>
                <FormControl>
                    <InputCmp
                        label='Selecione a data da reunião'
                        type={'date'}
                        max={'2099-12-31T23:59'}
                        OnChange={setDataInicio}
                    />
                </FormControl>

                <Flex gap={'.75rem'} w={'100%'} mb={'.5rem'}>
                    <FormControl>
                        <InputCmp
                            label='Início'
                            type={'time'}
                            OnChange={setHInicio}
                        />
                    </FormControl>

                    <FormControl>
                        <SelectCmp title='Duração' OnChange={setDuracao}>
                            <option value={'5'}>5 minutos</option>
                            <option value={'10'}>10 minutos</option>
                            <option value={'15'}>15 minutos</option>
                            <option value={'30'}>30 minutos</option>
                            <option value={'60'}>1 hora</option>
                            <option value={'120'}>2 horas</option>
                            <option value={'180'}>3 horas</option>
                            <option value={'240'}>4 horas</option>
                            <option value={'300'}>5 horas</option>
                            <option value={'360'}>6 horas</option>
                            <option value={'420'}>7 horas</option>
                            <option value={'480'}>8 horas</option>
                            <option value={'540'}>9 horas</option>
                            <option value={'600'}>10 horas</option>
                        </SelectCmp>
                    </FormControl>
                </Flex>

                <Flex gap={'.5rem'}>
                    <ButtonCmp VarColor='c6' onClick={onClose}>Cancelar</ButtonCmp>
                    <ButtonCmp
                        onClick={() => onSave(DataInicio, HInicio, Duracao)}
                        VarColor='Green2'
                    >Continuar</ButtonCmp>
                </Flex>
            </Flex>
        </ModalBase>
  )
}

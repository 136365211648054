import { Spinner } from '@chakra-ui/react'
import { CSSProperties } from 'react'
import { ElementoTooltipCmp } from '../ElementoTooltipCmp'
import { Container } from './styles'

interface BoxProps{
  styles?: CSSProperties
  margin?: string
  IsLoading?: boolean
  IsActive?: boolean
  IsDisabled?: string
  padding?: string
}

export const WhiteContainer: React.FC<BoxProps> = ({ children, margin, IsLoading, IsActive, IsDisabled, padding }) => {
  return (
    <>
      {!IsLoading && <ElementoTooltipCmp label={IsDisabled} bg={'var(--Red)'} w={'full'}>
          <Container padding={padding} isActive={IsActive} margin={margin} style={(IsDisabled ? { opacity: '.65', pointerEvents: 'none', cursor: 'not-allowed' } : { })}>
            {children}
          </Container>
        </ElementoTooltipCmp>}
      {IsLoading &&
        <Container margin={margin}>
          <Spinner
            thickness='3px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.600'
            height={'11rem'}
            width='11rem'
            position={'absolute'}
            transform={'translate(-50%, -50%)'}
            left='42%'
            top='35%'
          />
        </Container>
      }
    </>
  )
}

import { iGrafico } from '../../../../../../../../interfaces'
import { GenerateChartBlob } from '../../../../../../../../Utils/Helper_chart'

export async function getBlobGraficoColuna(grafico: iGrafico): Promise<string> {
  const options = {
    series: [{
      name: '',
      data: grafico.dados[0].medias
    }],
    grid: {
      show: false
    },
    chart: {
      type: 'bar',
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        color: '#4472C4',
        columnWidth: '80%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
        colors: ['#58544D']
      },
      offsetY: -20,
      formatter: function (value: number): string {
        return `${value.toFixed(2)}`
      }
    },
    title: {
      text: grafico.dados[0].nome
    },
    yaxis: {
      show: false
    },
    xaxis: {
      categories: grafico.competencias
    }
  }
  return await GenerateChartBlob(options)
}

import { Chart, registerables } from 'chart.js'
import { iGrafico } from '../../../../../../../../interfaces'
import { FormatarCompetencia } from '../../../../../../../../Utils/Helper'
Chart.register(...registerables)

export const getBlobGraficoRadar = async (
  grafico: iGrafico,
  stepSize: number
): Promise<string | undefined> => {
  function getSeries(): Array<{ name: string, data: number[] }> {
    return grafico.dados.map((dado) => ({
      name: dado.nome,
      data: dado.medias
    }))
  }

  function ObterCor(pos: number): string {
    if (grafico.dados.length === 1) {
      return colors[pos]
    } else {
      return grafico.dados[1].nome.includes('Média sem autoavaliação') && pos === grafico.dados.length - 1 ? colors[colors.length - 1] : colors[pos]
    }
  }

  function AbreviarNomesGrandes(): string[] {
    return grafico.competencias.map((e) => {
      if (e.length > 19) {
        return FormatarCompetencia(e.substring(0, 19) + '...')
      } else {
        return FormatarCompetencia(e)
      }
    })
  }

  const colors = [
    'rgb(0, 143, 251)',
    'rgb(0, 227, 150)',
    '#F2C94C',
    'rgb(255, 69, 96)',
    '#fe00f4',
    '#BB6BD9',
    '#219653',
    '#F2994A'
  ]

  const Shapes = ['circle', 'star', 'triangle', 'rectRot', 'rectRounded']

  const options = {
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16
          }
        }
      }
    },
    scales: {
      r: {
        min: 0,
        max: 10,
        ticks: {
          min: 0,
          max: 10,
          stepSize: stepSize,
          font: {
            size: 12
          },
          z: 1
        },
        pointLabels: {
          color: '#000',
          font: {
            size: 16
          }
        },

        angleLines: {
          color: '#bdbdbd'
        },

        grid: {
          color: '#bdbdbd'
        }
      }
    }
  }

  const data = {
    labels: AbreviarNomesGrandes(),
    datasets: getSeries().map((e, i) => {
      return {
        label: e.name,
        data: e.data,
        fill: false,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: ObterCor(i),
        backgroundColor: ObterCor(i),
        borderColor: ObterCor(i),
        pointRadius: i === 0 ? 4 : 6,
        pointStyle: Shapes[i]
      }
    })
  }

  const tempCanvas = document.createElement('canvas')
  tempCanvas.style.display = 'none'
  if (tempCanvas) {
    document.body.appendChild(tempCanvas)
    const tempchart = new Chart(tempCanvas, {
      type: 'radar',
      data: data,
      options: options
    })

    tempchart.options.animation = false
    if (tempchart.options.animations) {
      tempchart.options.animations.colors = false
      tempchart.options.animations.x = false
    }

    // await sleepAsync(100)
    return tempCanvas.toDataURL('image/png')
  }
}

import { HTMLAttributes } from 'react'
import { Container } from './styles'

interface ButtonProps extends HTMLAttributes<HTMLElement>{
  bg: string
}

export const MenuButton: React.FC<ButtonProps> = ({ children, bg, ...rest }) => {
  return (
        <Container {...rest} theme={bg}>{children}</Container>
  )
}

import styled from 'styled-components'

export const Container = styled.div<{width?: string, color?: string}>`
display: flex;
padding: .125rem;
border-radius: 8px;
background: ${props => props.color ? props.color : 'var(--Azul)'};
width: ${props => props.width};
color: ${props => props.color ? props.color : 'var(--Azul)'};
svg{
    min-width: 2.25rem;
    min-height: 2.25rem;
    color: var(--Azul);
    margin-left: .75rem;
}
`

export const ContainerButton = styled.div<{hasAction?: boolean}>`
display: flex;
background-color: white;
border-radius: 8px;
font-size: 1.25rem;
max-width: auto;
padding: .75rem 1rem;
word-wrap: break-word;
align-items: center;
${props => {
return props.hasAction
? `&:hover{
    background-color: var(--Azul);
    cursor: pointer;
    transition: all .4s ease;
    color: white;
    span{
        color: white !important;
        background-image: white;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: white;
    }
    svg {
        color: white;
    }
}`
: ''
}}
`

import { Flex, Text } from '@chakra-ui/react'
import { FaClock, FaUser } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import { appApi } from '../../../../../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { parseJwt } from '../../../../../../../services/token'

interface iModel {
  tipoResponsavel: number
  tempoInserir: number
  isResponsavel: boolean
  cadastrado: boolean
  pdiId?: string
}

interface iProps {
  etapa: iEtapaColaborador
  isOpen: boolean
}
export const CardPDI: React.FC<iProps> = ({ etapa, isOpen }) => {
  const nav = useNavigate()
  const { colaboradorId } = useParams<{colaboradorId: string}>()
  const [Model, setModel] = useState<iModel>()

  const ObterResponsavel = (): string => {
    switch (Model?.tipoResponsavel) {
      case 0:
        return 'Gestor'
      case 1:
        return 'Colaborador'
      case 2:
        return 'Rh'
      default: return ''
    }
  }

  const obterPDI = (): void => {
    appApi.get(`PDIEtapaColaborador/${colaboradorId ?? ''}/Etapa/${etapa.id}/Detalhe`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?responsavelId=${parseJwt().cid}&colaboradorId=${colaboradorId}&etapaId=${etapa?.id ?? ''}`)
    } else {
      appApi.post(`PDIEtapaColaborador/${Model?.pdiId ?? ''}/Etapa/${etapa?.id ?? ''}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${Model?.pdiId ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      obterPDI()
    }
  }, [isOpen])

  return (
        <Flex flexDir={'column'} gap={'.5rem'}>
            <Flex gap={'.375rem'} alignItems='center'>
                <FaUser color='var(--Gray2)' size={18} />
                <Flex flexDir={'column'}>
                    <Text color={'var(--Gray2)'} fontWeight={'700'} fontSize='14px'>Responsável por inserir PDI</Text>
                    <Text fontWeight={'700'} color={'var(--terc3)'} fontSize={'14px'}>{ObterResponsavel()}</Text>
                </Flex>
            </Flex>

            <Flex gap={'.375rem'} alignItems='center'>
                <FaClock color='var(--Gray2)' size={18} />
                <Flex flexDir={'column'}>
                    <Text color={'var(--Gray2)'} fontWeight={'700'} fontSize='14px'>Tempo para inserir</Text>
                    <Text fontWeight={'700'} color={'var(--terc3)'} fontSize={'14px'}>{Model?.tempoInserir} dias</Text>
                </Flex>
            </Flex>

            {Model?.isResponsavel && !Model.cadastrado && (etapa.status === 0 || etapa.status === 6) &&
              <Flex justifyContent={'center'} flexDir={'column'} gap={'1rem'}>
                <ButtonCmp
                  onClick={() => CriarBasePDI('1')}
                  padding='1.6rem'
                  fontSize={'14px'}
                  h={'2rem'}
                  VarColor='Rosa'
                >Iniciar um novo PDI </ButtonCmp>
                {Model.pdiId &&
                  <ButtonCmp
                    onClick={() => CriarBasePDI('2')}
                    padding='1.6rem'
                    fontSize={'12px'}
                    h={'2rem'}
                    VarColor='Green2'
                  >Continuar no PDI atual (recomendado)</ButtonCmp>
                }
              </Flex>
            }
        </Flex>
  )
}

import { Divider, Flex, Image, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SigahApi } from '../../../../../../../services/appApi'
import { ObterToken } from '../../../../../../../Utils/Helper'
import { iCardEtapa } from '../../../../../../../interfaces'
import detrator from '../CardEnps/assets/image_53.png'
import neutro from '../CardEnps/assets/neutro.png'
import promotor from '../CardEnps/assets/promotor.png'

interface iCard {
  etapa: iCardEtapa
  isOpen: boolean
}

interface iResponse{
  pergunta: string
  nota: number
}

export const CardEnps: React.FC<iCard> = ({ etapa, isOpen }) => {
  const [Respostas, setRespostas] = useState<iResponse[]>([])

  function getResultado(): void {
    SigahApi.get(`api/Onboard/eNps/Respostas/${etapa.interacoes[0].idExterno}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        if (res.status === 200) {
          setRespostas(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function ObterCarinha(nota: number): any {
    if (nota <= 6) {
      return (
        <Flex flexDir={'column'}>
          <Text fontWeight={'400'} fontSize='14px' color='var(--Red)'>Detrator</Text>
          <Flex gap={'1rem'} alignItems='center'>
            <Image src={detrator}/>
            <Text fontWeight={'bold'} fontSize='18px' color='var(--Red)'>{nota}</Text>
          </Flex>
        </Flex>
      )
    } else if (nota > 6 && nota < 9) {
      return (
        <Flex flexDir={'column'}>
          <Text fontWeight={'400'} fontSize='14px' color='var(--Yellow)'>Neutro</Text>
          <Flex gap={'1rem'} alignItems='center'>
            <Image src={neutro}/>
            <Text fontWeight={'bold'} fontSize='18ox' color='var(--Yellow)'>{nota}</Text>
          </Flex>
        </Flex>
      )
    } else {
      return (
        <Flex flexDir={'column'}>
          <Text fontWeight={'400'} fontSize='14px' color='var(--Green3)'>Promotor</Text>
          <Flex gap={'1rem'} alignItems='center'>
            <Image src={promotor}/>
            <Text fontWeight={'500'} fontSize='18px' color='var(--Green3)'>{nota}</Text>
          </Flex>
        </Flex>
      )
    }
  }

  useEffect(() => {
    if (isOpen && Respostas.length === 0) {
      getResultado()
    }
  }, [isOpen])

  return (
    <Flex flexDir={'column'} gap={'1rem'}>
      {
        Respostas.length > 0 && Respostas.map((e, i) => {
          return (
            <Flex gap={'1rem'} alignItems={'start'} maxWidth={'30rem'} key={i}
              paddingBottom={'1rem'}
              flexDir={'column'}>
              <Text fontWeight={'500'} color='var(--Gray2)' fontSize={'14px'}>{e.pergunta}</Text>
              {ObterCarinha(e.nota)}
              <Divider w={'100%'} height={'.125rem'} bg={'#d0d0d083'}/>
            </Flex>
          )
        })
      }
      {
        (Respostas.length === 0) && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            <Text fontSize={'14px'}>Aguardando a resposta do colaborador</Text>
          </Flex>
        )
      }
    </Flex>
  )
}

import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBrain, FaCheckCircle } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { SelectCmp } from '../../../../components/SelectCmp'

interface props {
  isOpen: boolean
  isLoading: boolean
  onRequestClose: () => void
  ultimaAvaliacaoId: string
  pesquisas: Array<{ id: string, name: string }>
  onConfirm: (id: string, novasRecomendacoes: boolean) => void
}

export const ModalRecomendacoesIA: React.FC<props> = ({ isOpen, isLoading, onRequestClose, pesquisas, ultimaAvaliacaoId, onConfirm }) => {
  const [Pesquisa, setPesquisa] = useState(ultimaAvaliacaoId)
  const [GerarNovasRecomendacoes, setGerarNovasRecomendacoes] = useState(false)

  useEffect(() => {
    setPesquisa(ultimaAvaliacaoId)
  }, [ultimaAvaliacaoId])

  Modal.setAppElement('#root')
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
        <Flex flexDir={'column'} padding={'2rem'} w={'57rem'}>
          <Flex justifyContent={'space-between'} mb={'1rem'}>
            <h3>Gerar recomendações por IA</h3>
            <MdClose color='var(--Red)' cursor={'pointer'} size={20} onClick={onRequestClose}/>
          </Flex>
          <Flex flexDir={'column'} gap={'1rem'} justifyContent={'center'}>
              <SelectCmp value={Pesquisa} title='Pesquisas participadas pelo colaborador' OnChange={setPesquisa}>
                {pesquisas.map((e) => {
                  return (
                    <option value={e.id}>{e.name}</option>
                  )
                })}
              </SelectCmp>
              <CheckboxCmp OnChange={setGerarNovasRecomendacoes}>Gerar recomendações novas para essa pesquisa</CheckboxCmp>
              {GerarNovasRecomendacoes && <Alert borderRadius={'.25rem'} justifyContent='space-between' status='warning' px={'.75rem'} py={'.325rem'}>
                <Flex flexDir={'column'} fontSize={'.75rem'}>
                  <Flex>
                      <AlertIcon color={'#FF9900'} />
                      <AlertTitle>IMPORTANTE: </AlertTitle>
                      <AlertDescription>Ao selecionar essa opção, serão geradas novas recomendações por IA no resultado individual deste colaborador, substituindo</AlertDescription>
                  </Flex>
                  <AlertDescription>e perdendo as atuais.</AlertDescription>
                </Flex>
              </Alert>}
              <Flex gap={'.75rem'} justifyContent={'center'}>
                <ButtonCmp leftIcon={<FaCheckCircle/>} isLoading={isLoading} VarColor='Green2' h={'3rem'} onClick={() => onConfirm(Pesquisa, GerarNovasRecomendacoes)}>
                  Gerar com base na <br></br> pesquisa selecionada
                </ButtonCmp>
                <ButtonCmp isDisabled={GerarNovasRecomendacoes} leftIcon={<FaBrain/>} h={'3rem'} isLoading={isLoading} VarColor='Rosa' onClick={() => onConfirm(ultimaAvaliacaoId, false)}>
                  Gerar com base na ultima <br></br> pesquisa do colaborador
                </ButtonCmp>
              </Flex>
          </Flex>
        </Flex>
    </Modal>
  )
}

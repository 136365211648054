/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { IDadoGrafico } from '../..'

interface iProps {
  id: string
  grafico: IGrafico
  numeroRespostas: number
  getBlob?: (blob: string) => void
}

interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
}

export const PDIChart: React.FC<iProps> = ({ id, grafico, getBlob, numeroRespostas }) => {
  function AbreviarNomesGrandes(): string[] {
    return grafico.dados.map((e, i) => {
      if (e.nome.length > 20) {
        return e.nome.substring(0, 20) + '...'
      } else {
        return e.nome
      }
    })
  }

  function GetMedias(): number[] {
    const medias: number[] = []
    grafico.dados.forEach(e => e.medias.forEach(m => medias.push(m)))

    return medias
  }

  async function GerarGrafico(): Promise<void> {
    const options = {
      series: [{
        name: 'Quantidade de respostas nessa alternativa',
        data: GetMedias()
      }],
      colors: ['#1961E0', '#27AE60', '#BBBBBB', '#F2994A', '#EB5757'],
      labels: AbreviarNomesGrandes(),
      chart: {
        type: 'bar',
        height: '85%',
        width: '100%',
        animations: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -16,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          return val
        }
      },
      legend: {
        show: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: numeroRespostas,
        show: false
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    if (getBlob !== undefined) {
      getBlob(dataURL.imgURI)
    }
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}

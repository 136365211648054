import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
width: 37rem;
text-align: center;
h2{
    color:var(--secundario5);
    font-size: 1.5rem;
    line-height: 2.5rem;
}
`
export const TextoResponsavel = styled.span`
  font-weight: bold;
  color: var(--a4);
  font-size: 1rem;
  margin-bottom: 6px;
`

export const TextoDescricao = styled.span`
  font-weight: 400;
  color: var(--a4);
  font-size: 20px;
  margin-bottom:6px;
`

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { IDadoGrafico2 } from '../..'
import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import { FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { HSelect } from '../../../../../Utils/Helper'

interface iChartProps {
  model: IDadoGrafico2[]
}

export const BarChartIndicativa: React.FC<iChartProps> = ({ model }) => {
  const id = 'dalinho'
  const valorOrdenado = model.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))

  // function ObterIconeGrafico(index: number, media: string, value?: number[]): any {
  //   const stylePadrao = (color: string, pos: number): any => {
  //     return {
  //       position: 'absolute',
  //       top: `${(index * 69) + 57}`,
  //       transform: 'translate(-50%, 0)',
  //       left: `${pos === 0 ? (parseFloat(media) >= 0 ? '42%' : '46%') : (parseFloat(media) >= 0 ? '40%' : '48%')}`,
  //       color: `var(--${color})`
  //     }
  //   }

  //   if (value && value[0] === 4) {
  //     return <div key={index}>
  //       <FaMedal
  //         style={stylePadrao('Green2', 0)}
  //       />
  //       {value[1] && value[1] === 1
  //         ? <FaTools
  //           style={stylePadrao('Rosa', 1)}
  //         />
  //         : value[1] === 2
  //           ? <FaHeart
  //             style={stylePadrao('Azul', 1)}

  //           />
  //           : <FaFrown
  //             style={stylePadrao('Orange', 1)}
  //           />}</div>
  //   } else if (value && value[0] === 1) {
  //     return <div key={index}><FaTools
  //       style={stylePadrao('Rosa', 0)}
  //     />
  //       {value[1] && value[1] === 4
  //         ? <FaMedal
  //           style={stylePadrao('Green2', 1)}
  //         />
  //         : value[1] === 2
  //           ? <FaHeart
  //             style={stylePadrao('Azul', 1)}
  //           />
  //           : <FaFrown
  //             style={stylePadrao('Orange', 1)}
  //           />}</div>
  //   } else if (value && value[0] === 2) {
  //     return <div key={index}><FaHeart
  //       style={stylePadrao('Azul', 0)}
  //     /> {value[1] && value[1] === 4
  //       ? <FaMedal
  //         style={stylePadrao('Green2', 1)}
  //       />
  //       : value[1] === 1
  //         ? <FaTools
  //           style={stylePadrao('Rosa', 1)}
  //         />
  //         : <FaFrown
  //           style={stylePadrao('Orange', 1)}
  //         />}</div>
  //   } else if (value && value[0] === 3) {
  //     return <div key={index}><FaFrown
  //       style={stylePadrao('Orange', 0)}
  //     /> {value[1] && value[1] === 4
  //       ? <FaMedal
  //         style={stylePadrao('Green2', 1)}
  //       />
  //       : value[1] === 1
  //         ? <FaTools
  //           style={stylePadrao('Rosa', 1)}
  //         />
  //         : <FaHeart
  //           style={stylePadrao('Azul', 1)}
  //         />}</div>
  //   } else {
  //     return null
  //   }
  // }

  function ObterCor(value: IDadoGrafico2[]): string[] {
    const arr: string[] = []
    value?.forEach(e => {
      if (e.melhoria) {
        arr.push('var(--Orange)')
      } else {
        arr.push('var(--Green2)')
      }
    })

    return arr
  }

  function GerarGrafico(): void {
    const options = {
      series: [{
        data: HSelect(valorOrdenado, e => e.media, false)
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: valorOrdenado.length * 80,
        width: 1000,
        toolbar: {
          show: false
        }
      },
      annotations: {
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      dataLabels: {
        enabled: true,
        position: 'start',
        offsetX: 55,
        offsetY: -20,
        formatter: function (value: any, series: any) {
          return `C${((series.dataPointIndex as number) + 1)} (${valorOrdenado[series.dataPointIndex].media})`
        },
        style: {
          colors: ['#000']
        }
      },
      colors: ObterCor(valorOrdenado),
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: '20px',
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (value: any, series: any) {
              return valorOrdenado[series.dataPointIndex].nome + ' - '
            }
          },
          marker: {
            show: false
          }
        }
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        max: 1.2,
        min: -1.2
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [model])

  return (
    <Flex flexDir={'column'}>

      {/* <Flex direction={'column'} my={'1rem'}>
        <Flex justifyContent={'center'} gap={'1rem'}>
          <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Orange)'}></Box> Competências priorizadas para serem desenvolvidas</Flex>
          <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Green2)'}></Box> Demais competências</Flex>
        </Flex>
      </Flex> */}

      {/* <Box mb={'1rem'}>
        <Flex gap={'1rem'} justifyContent={'center'} mb={'.5rem'}>
          <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} />  Que você considera ser um destaque positivo</Text>
          <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: 'var(--Rosa)' }} />  Que você considera ter mais necessidade de desenvolvimento</Text>
        </Flex>
        <Flex gap={'1rem'} justifyContent={'center'}>
          <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaHeart style={{ color: 'var(--Azul)' }} />  Que você mais se motiva</Text>
          <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaFrown style={{ color: 'var(--Orange)' }} />  Que você menos se motiva</Text>
        </Flex>
      </Box> */}

      <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
        {/* {model.sort((a, b) => parseFloat(b.media) - parseFloat(a.media)).map((e, i) => {
          if (e.selo) {
            return (ObterIconeGrafico(i, e.media, e.selo))
          } else {
            return null
          }
        })} */}
        <div id={id}></div>
      </Flex>

      <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap='.5rem' px={'1rem'}>
        {valorOrdenado.map((e, i) => {
          return (
            <Text key={i}>C{i + 1}. {e.nome} ({e.media})</Text>
          )
        })}
      </Grid>
    </Flex>
  )
}

/* eslint-disable no-unneeded-ternary */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { iCompetencia, iModalPadrao } from '../../../../../interfaces'
import { ModalBody, ModalFooter, ImgVariant } from './styles'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { appApi } from '../../../../../services/appApi'
import { ModalTodasCompetencias } from '../ModalTodasCompetencias'
import { FaTrash } from 'react-icons/fa'

interface iProps extends iModalPadrao {
  avaliacaoId?: string
  pesquisaIniciada: boolean
}

export const ModalJaTrabalhadas: React.FC<iProps> = ({ OnCloseModal, isOpen, avaliacaoId, pesquisaIniciada }) => {
  const [Competencias, setCompetencias] = useState<iCompetencia[]>([])
  const [ModalTodasIsOpen, setModalTodasIsOpen] = useState(false)
  const [RecentementeColocado, setRecentementeColocado] = useState(false)
  const toast = useToast()

  function GetRecentementeColocado(id: string): void {
    appApi.get(`AvaliacaoPesquisa/${id}/RecentementeColocado`)
      .then(res => setRecentementeColocado(res.data))
      .catch(err => console.log(err))
  }

  function GetCompetenciasTrabalhadas(id: string): void {
    appApi.get(`AvaliacaoPesquisa/${id}/CompetenciasTrabalhadas`)
      .then(res => setCompetencias(res.data))
      .catch(err => console.log(err))
  }

  function OnChangeCompetencia(compsNova: string[]): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId}/MudarCompetencia`, { list: compsNova })
      .then(res => {
        toast({
          title: 'Competência atualizada com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000
        })
        setModalTodasIsOpen(false)
        GetCompetenciasTrabalhadas(avaliacaoId ?? '')
      })
      .catch(err => console.log(err))
  }

  function OnDeleteCompetencia(compAtual: string): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId}/RemoverCompetencia?atual=${compAtual}`)
      .then(res => {
        toast({
          title: 'Competência removida com sucesso',
          status: 'success',
          position: 'top',
          duration: 4000
        })
        setModalTodasIsOpen(false)
        GetCompetenciasTrabalhadas(avaliacaoId ?? '')
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (avaliacaoId) {
      GetCompetenciasTrabalhadas(avaliacaoId)
      GetRecentementeColocado(avaliacaoId)
    }
  }, [avaliacaoId])

  return (
    <ModalBase
      isOpen={isOpen as boolean}
      Titulo='Competências utilizadas no último PDI'
      onClose={OnCloseModal}
      Width='65rem'
    >
      <ModalTodasCompetencias
        isOpen={ModalTodasIsOpen}
        OnCloseModal={() => setModalTodasIsOpen(false)}
        onConfirm={OnChangeCompetencia}
        idsIgnorar={Competencias.map(e => e.id)}
      />
      <ModalBody>
        <section>
          {Competencias.length === 0 && <Flex justifyContent={'center'} fontSize='14px' fontWeight={700} color={'var(--Azul)'} py={'.813rem'}>Esse colaborador não possuí nenhuma competência selecionada</Flex>}
          {
            Competencias.map((e: iCompetencia, i) => {
              return (
                <Flex key={i} className='VariantColor' justifyContent={'space-between'}>
                  <Flex>
                    <ImgVariant borderColor={e.doSistema ? 'Azul' : 'AzulOpaco'} src={e.icone} />
                    <Flex flexDirection={'column'}>
                      <h4>{e.nome}</h4>
                      <small>{e.doSistema ? 'Padrão' : e.dataCriacao}</small>
                    </Flex>
                  </Flex>

                  {(!pesquisaIniciada || RecentementeColocado) && <Flex alignItems={'center'} gap={'.5rem'}>
                    <ButtonCmp OutlineColor='Red' onClick={() => OnDeleteCompetencia(e.id)}>
                      <FaTrash cursor={'pointer'}/>
                    </ButtonCmp>
                  </Flex>}
                </Flex>
              )
            })
          }
        </section>
      </ModalBody>
      <ModalFooter>
        <ButtonCmp VarColor='c6' mr={'.5rem'} onClick={() => OnCloseModal()}>Voltar</ButtonCmp>
        {(!pesquisaIniciada || RecentementeColocado) && <ButtonCmp mr={'.5rem'} onClick={() => setModalTodasIsOpen(true) } VarColor='Rosa'>Adicionar uma nova</ButtonCmp>}
        <ButtonCmp onClick={() => OnCloseModal()} VarColor='Green2'>Continuar</ButtonCmp>
      </ModalFooter>
    </ModalBase>
  )
}

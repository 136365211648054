/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../../services/appApi'
import {
  Container,
  HeaderContainer
} from './styles'
import { MultiplaEscolha } from './components/MultiplaEscolha'
import { CaixaDeSelecao } from './components/CaixaDeSelecao'
import { Discursiva } from './components/Discursiva'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { FormatarMensagemEditorDeTexto, PropInvalida, useQuery } from '../../../../Utils/Helper'
import { WhiteContainer } from '../../../../components/WhiteContainer'
import { Body } from '../../../Layouts/Body'
import { Escala } from './components/Escala'

type LocationProps = {
  paramId: string
}

interface iFormResponse {
  nomePesquisa: string
  txtEncerramento?: string
  linkRedirect?: string
  questoes: iFormQuestaoResponse[]
}

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
  obrigatoria: boolean
  alternativas?: iFormAlternativaResponse[]
  minCaixaSelecao?: number
  maxCaixaSelecao?: number
}

interface iFormAlternativaResponse {
  id: string
  texto: string
  peso?: number
}

interface iResposta {
  questaoId: string
  alternativaId: string
}

interface iRespostaDiscursiva {
  questaoId: string
  texto: string
}

interface iRespostaRequest {
  questaoId: string
  tipo: number
  alternativasId?: string[]
  texto?: string
}

export const PreenchimentoPesquisaInterna: React.FC = () => {
  const nav = useNavigate()
  const param = useParams<LocationProps>()
  const toast = useToast()
  const query = useQuery()
  const { pathname, search } = useLocation()
  const [Model, setModel] = useState<iFormResponse>()
  const [RespostasObjetiva, setRespostasObjetiva] = useState<iResposta[]>([])
  const [RespostasCaixaSelecao, setRespostasCaixaSelecao] = useState<iResposta[]>([])
  const [RespostasDiscursivas, setRespostasDiscursivas] = useState<iRespostaDiscursiva[]>([])
  const [Erros, setErros] = useState<string[]>([])
  const [TimeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [Respondido, setRespondido] = useState(false)

  function getFormulario(): void {
    if (pathname.includes('Formulario') && pathname.includes('Previsualizar')) {
      appApi.get(`PesquisaInterna/Formulario/${param.paramId as string}/Previsualizar`)
        .then(resp => setModel(resp.data)).catch(err => console.log(err))
    } else {
      appApi.get(`PesquisaInterna/${param.paramId as string}/Preenchimento`)
        .then(resp => setModel(resp.data)).catch(err => console.log(err))
    }
  }

  function SelRespostaObjetiva(questaoId: string, alternativaId: string): void {
    if (!isSelectQuestao(questaoId)) {
      setRespostasObjetiva(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      if (!isSelect(questaoId, alternativaId)) {
        UpdateRespostaObjetiva(questaoId, alternativaId)
      }
    }
  }

  function UpdateRespostaObjetiva(questaoId: string, alternativaId: string): void {
    const respostas = [...RespostasObjetiva]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].alternativaId = alternativaId
      setRespostasObjetiva(respostas)
    }
  }

  const isSelect = (perguntaId: string, alternativaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId && e.alternativaId === alternativaId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId) !== undefined

  const hasError = (perguntaId: string): boolean => Erros.find(e => e === perguntaId) !== undefined

  function SelecionarMultiplaEscolha(questaoId: string, alternativaId: string): void {
    if (RespostasCaixaSelecao.find(r => r.questaoId === questaoId && r.alternativaId === alternativaId) === undefined) {
      setRespostasCaixaSelecao(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      const copy = [...RespostasCaixaSelecao].filter(e => e.alternativaId !== alternativaId)
      setRespostasCaixaSelecao(copy)
    }
  }

  function UpdateDiscursiva(id: string, upTexto: string): void {
    console.log(RespostasDiscursivas)
    const pos = RespostasDiscursivas.findIndex(e => e.questaoId === id)
    if (pos !== -1) {
      const copy = [...RespostasDiscursivas]
      copy[pos].texto = upTexto
      setRespostasDiscursivas(copy)
    } else {
      setRespostasDiscursivas(oldArray => [...oldArray,
        {
          questaoId: id, texto: upTexto
        }])
    }
  }

  function ValidarTodasRespostas(): boolean {
    const naoRespondidas = Model?.questoes.filter(r => r.obrigatoria).map((e) => {
      if (RespostasObjetiva.find(r => r.questaoId === e.id) === undefined && RespostasCaixaSelecao.find(r => r.questaoId === e.id) === undefined && RespostasDiscursivas?.find(r => r.questaoId === e.id && r.texto.trim().length >= 3) === undefined) {
        return e.id
      }
      return null
    })
    const filtrado = naoRespondidas?.filter(e => e !== null)
    if (filtrado !== null && filtrado !== undefined && filtrado?.length > 0) {
      setErros(filtrado as string[])
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    const CaixaSelecaoInvalidas = Model?.questoes.filter(r => (r.obrigatoria || RespostasCaixaSelecao.some(e => e.questaoId === r.id)) && !PropInvalida(r.maxCaixaSelecao))
      .map((e) => {
        const respostas = RespostasCaixaSelecao.filter(r => r.questaoId === e.id)
        if (!(respostas.length >= (e.minCaixaSelecao ?? 0) && respostas.length <= (e.maxCaixaSelecao ?? 0))) {
          return e.id
        }
        return null
      })

    if (CaixaSelecaoInvalidas !== null && CaixaSelecaoInvalidas !== undefined && CaixaSelecaoInvalidas?.filter(r => r !== null).length > 0) {
      setErros(CaixaSelecaoInvalidas?.filter(e => e !== null) as string[])
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    return true
  }

  function Redirect(): void {
    if (query.get('quiosque')) {
      nav(`${pathname.replace('Preenchimento', 'Abertura')}${search}`)
      return
    }
    window.location.href = Model?.linkRedirect as string
  }

  function EnviarResposta(): void {
    if (ValidarTodasRespostas()) {
      const respostas: iRespostaRequest[] = []
      RespostasObjetiva.forEach((e) => {
        respostas.push({
          tipo: 0,
          questaoId: e.questaoId,
          alternativasId: [e.alternativaId]
        })
      })

      Model?.questoes.filter(r => r.tipo === 1).forEach((e) => {
        const resposta = RespostasDiscursivas?.find(t => t.questaoId === e.id)
        respostas.push({
          questaoId: e.id,
          tipo: 1,
          texto: resposta?.texto ?? ''
        } as iRespostaRequest)
      })

      Model?.questoes.filter(e => e.tipo === 5).forEach((e) => {
        respostas.push({
          questaoId: e.id,
          tipo: 5,
          alternativasId: RespostasCaixaSelecao.filter(r => r.questaoId === e.id).map((r) => {
            return r.alternativaId
          })
        })
      })

      appApi.post(`PesquisaInterna/${param.paramId as string}/Responder`, respostas)
        .then(() => {
          setRespondido(true)
          if (Model?.linkRedirect !== undefined && Model?.linkRedirect !== null) {
            const timeoutId = setTimeout(() => { Redirect() }, 5000)
            setTimeoutId(timeoutId)
          }
        })
        .catch(err => console.log(err))
    }
  }

  function ClicarVoltar(): void {
    if (query.get('quiosque')) {
      nav(`${pathname.replace('Preenchimento', 'Abertura')}${search}`)
      clearTimeout(Number(TimeoutId))
    } else {
      nav('/')
    }
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <WhiteContainer padding='0'>
        <HeaderContainer>
          <h1>{Model?.nomePesquisa}</h1>
          <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
        </HeaderContainer>
        <Box bg='var(--DegradeAzul)' className={'gradient-blue'} h={'3px'} w={'full'}></Box>
        {
          (!Respondido) && (
            <Container>
              {
                Model?.questoes?.map((e: iFormQuestaoResponse, i) => {
                  if (e.tipo === 0) {
                    return (
                      <MultiplaEscolha
                        key={i}
                        questao={e}
                        isSelect={isSelect}
                        SelecionarQuestao={SelRespostaObjetiva}
                        erro={hasError(e.id)}
                      />
                    )
                  } else if (e.tipo === 5) {
                    return (
                      <CaixaDeSelecao
                        key={i}
                        selecionadas={RespostasCaixaSelecao.length}
                        questao={e}
                        Selecionar={SelecionarMultiplaEscolha}
                        erro={hasError(e.id)}
                      />
                    )
                  } else if (e.tipo === 1) {
                    return (
                      <Discursiva
                        key={e.id}
                        questao={e}
                        erro={hasError(e.id)}
                        UpdateText={UpdateDiscursiva}
                      />
                    )
                  } else if (e.tipo === 6) {
                    return (
                      <Escala
                        key={i}
                        questao={e}
                        isSelect={isSelect}
                        SelecionarQuestao={SelRespostaObjetiva}
                        erro={hasError(e.id)}
                      />
                    )
                  }
                  return null
                })
              }

              {pathname.includes('Previsualizar')
                ? <Flex justifyContent={'end'} margin={'2rem 0 1rem 0rem'}>
                <ButtonCmp onClick={() => nav(-1)} w={'8rem'} VarColor={'c6'}>Voltar</ButtonCmp>
              </Flex>
                : <Flex justifyContent={'center'} margin={'2rem 0 1rem 0rem'}>
                <ButtonCmp onClick={EnviarResposta} w={'15rem'} VarColor='Green2'>Enviar resposta</ButtonCmp>
              </Flex>}
            </Container>
          )
        }

        {
          (Respondido) && (
            <Flex background={'white'} height={'100%'}>
              <Flex padding={'3rem 3rem'} flexDirection={'column'}>
                <h3 dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(Model?.txtEncerramento ?? 'Obrigado pela participação!') }}></h3>
                <Text fontWeight={'400'} style={{ color: 'var(--c6)', marginTop: '1rem' }}>Respostas enviadas com sucesso!</Text>
                {
                  (localStorage.getItem('token')) && (
                    <Flex marginTop={'1rem'}>
                      <ButtonCmp
                        VarColor='c6'
                        size={'lg'}
                        onClick={ClicarVoltar}
                      >Voltar</ButtonCmp>
                    </Flex>
                  )
                }
              </Flex>
            </Flex>
          )
        }

      </WhiteContainer>
    </Body>
  )
}

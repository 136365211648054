export const Chartimg: React.FC = () => {
  return (
        <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 0.5C32.7344 0.5 35 2.76562 35 5.5V30.5C35 33.3125 32.7344 35.5 30 35.5H5C2.1875 35.5 0 33.3125 0 30.5V5.5C0 2.76562 2.1875 0.5 5 0.5H30ZM10 15.5C8.59375 15.5 7.5 16.6719 7.5 18V25.5C7.5 26.9062 8.59375 28 10 28C11.3281 28 12.5 26.9062 12.5 25.5V18C12.5 16.6719 11.3281 15.5 10 15.5ZM15 25.5C15 26.9062 16.0938 28 17.5 28C18.8281 28 20 26.9062 20 25.5V10.5C20 9.17188 18.8281 8 17.5 8C16.0938 8 15 9.17188 15 10.5V25.5ZM25 20.5C23.5938 20.5 22.5 21.6719 22.5 23V25.5C22.5 26.9062 23.5938 28 25 28C26.3281 28 27.5 26.9062 27.5 25.5V23C27.5 21.6719 26.3281 20.5 25 20.5Z" fill="url(#paint0_linear_726_14006)" />
            <defs>
                <linearGradient id="paint0_linear_726_14006" x1="0" y1="18" x2="35" y2="18" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1961E0" />
                    <stop offset="1" stopColor="#66046B" />
                </linearGradient>
            </defs>
        </svg>
  )
}

export const ChartimgWhite: React.FC = () => {
  return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 0.5C32.7344 0.5 35 2.76562 35 5.5V30.5C35 33.3125 32.7344 35.5 30 35.5H5C2.1875 35.5 0 33.3125 0 30.5V5.5C0 2.76562 2.1875 0.5 5 0.5H30ZM10 15.5C8.59375 15.5 7.5 16.6719 7.5 18V25.5C7.5 26.9062 8.59375 28 10 28C11.3281 28 12.5 26.9062 12.5 25.5V18C12.5 16.6719 11.3281 15.5 10 15.5ZM15 25.5C15 26.9062 16.0938 28 17.5 28C18.8281 28 20 26.9062 20 25.5V10.5C20 9.17188 18.8281 8 17.5 8C16.0938 8 15 9.17188 15 10.5V25.5ZM25 20.5C23.5938 20.5 22.5 21.6719 22.5 23V25.5C22.5 26.9062 23.5938 28 25 28C26.3281 28 27.5 26.9062 27.5 25.5V23C27.5 21.6719 26.3281 20.5 25 20.5Z" fill="white"/>
    </svg>
  )
}

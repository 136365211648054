/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useMemo } from 'react'
import ApexCharts from 'apexcharts'

interface iChartProps{
  id: string
  promotores: number
  neutros: number
  detratores: number
  qtdPromotores: number
  qtdNeutros: number
  qtdDetratores: number
  nps: string
}

export const PieChart: React.FC<iChartProps> = ({ id, detratores, neutros, promotores, nps, qtdDetratores, qtdNeutros, qtdPromotores }) => {
  const series = useMemo(
    () =>
      [promotores, neutros, detratores],
    [promotores, neutros, detratores]
  )

  function GerarGrafico(): void {
    const options = {
      series: series,
      labels: ['Promotores', 'Neutros', 'Detratores'],
      colors: ['#27AE60', '#FFBF2A', '#EB5757'],
      chart: {
        type: 'donut',
        height: 320,
        width: 350
      },
      legend: {
        enabled: true,
        position: 'left',
        horizontalAlign: 'center',
        formatter: function(seriesName: any, opts: { w: { globals: { series: { [x: string]: any } } }, seriesIndex: string | number }) {
          return [seriesName, ' - ', opts.seriesIndex === 0 ? qtdPromotores : opts.seriesIndex === 1 ? qtdNeutros : qtdDetratores]
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0) + '%'
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: number) {
          return val.toFixed(1) + '%'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                offsetY: -8
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '0px',
                color: '#373d3f',
                formatter: function (w: any) {
                  return nps + '%'
                }
              }
            }
          }
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [detratores, neutros, promotores])

  return (
        <div id={id}></div>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iEtapaColaborador, iItemIntegracao } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { FaCheck, FaRegCommentDots } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { ElementoTooltipCmp } from '../../../../../../../components/ElementoTooltipCmp'
interface ITipoCardConfigIntegracao {
  etapa: iEtapaColaborador
  isOpen: boolean
}

export const CardIntegracao: React.FC<ITipoCardConfigIntegracao> = ({ etapa, isOpen }) => {
  const { colaboradorId } = useParams<{ colaboradorId: string }>()
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterInformacoesCheck(): void {
    appApi.get(`IntegracaoEtapaColaborador/${etapa.id}/ProcessoOnboard/${colaboradorId ?? ''}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }
  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterInformacoesCheck()
    }
  }, [isOpen])

  return (
    <Container>
      <h4>Informações para serem verificadas</h4>
      {
        Itens.map((e, i) => {
          const texto = e?.titulo ? `${e?.titulo}` : 'Sem assunto'
          return (
            <Flex key={i} flexDir={'column'}>
              <span >{i + 1}.  {texto}</span>
              {((e.checklist && e.checklist.length > 0) && etapa.status !== 2)
                ? (
                  <Flex ml={4} mb={'2'} alignItems='center' >
                    <Text>Ainda não foi respondido</Text>
                  </Flex>
                  )
                : (
                  <Flex direction={'column'} as={'ul'} mb={'2'}>
                    {e.checklist?.map((r, i2) => {
                      return (
                        <Flex key={i2} ml={4} gap={'2'} alignItems='center' >
                          {r.check ? <FaCheck color='var(--Green2)' size='1rem' /> : <MdClose color='var(--Red)' size='1rem' />}
                          <Text>{r.texto}</Text>
                          {r.comentario &&
                            <ElementoTooltipCmp
                              background={'var(--Azul)'}
                              label={`Comentário: ${r.comentario}`}
                            >
                              <FaRegCommentDots color='white' />
                            </ElementoTooltipCmp>
                          }
                        </Flex>
                      )
                    })}
                  </Flex>
                  )
              }
            </Flex>
          )
        })
      }
    </Container>
  )
}

import { Text } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { SkillAnswer, SkillQuestion } from '../../styles'

interface iFormQuestaoResponse {
  enunciado: string
  alternativaResposta?: string[]
}

interface iMultiplaEscolhaProps {
  questao: iFormQuestaoResponse
}

export const MultiplaEscolha: React.FC<iMultiplaEscolhaProps> = ({ questao }) => {
  return (
    <SkillQuestion>
      <div>
        <Text fontWeight={'600'}>{questao.enunciado}</Text>
      </div>
      {
        questao.alternativaResposta?.map((r, i2) => {
          return (
            <SkillAnswer
              key={i2}
              type="button"
              isSelected={true}
            >
              <FaCheckCircle />
              <span>{r}</span>
            </SkillAnswer>
          )
        })
      }
    </SkillQuestion>
  )
}

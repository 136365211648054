import { Flex, Text } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { SkillAnswer, SkillQuestion } from '../../styles'

interface iFormQuestaoResponse {
  enunciado: string
  alternativaResposta: string[]
}

interface iEscalaProps {
  questao: iFormQuestaoResponse
}

export const Escala: React.FC<iEscalaProps> = ({ questao }) => {
  return (
    <SkillQuestion>
      <div>
        <Text fontWeight={'600'}>{questao.enunciado}</Text>
      </div>
      {
        questao.alternativaResposta?.map((r, i2) => {
          return (
            <SkillAnswer
              key={i2}
              type="button"
              isSelected={true}
            >
              <FaCheckCircle />
              <Flex as={'span'}>{r}</Flex>
            </SkillAnswer>
          )
        })
      }
    </SkillQuestion>
  )
}

import { Box, Flex } from '@chakra-ui/react'
import { FaEye, FaPaperPlane, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { iCardAgendamento, iMensagens } from '../../../../../interfaces'
import { TableDiv } from './styles'

interface iProps {
  model: iCardAgendamento
  onDelete: (e: iMensagens) => void
  onSend: (e: iMensagens) => void
  onView: (e: iMensagens) => void
}

export const TabelaAgendamentos: React.FC<iProps> = ({ model, onDelete, onSend, onView }) => {
  return (
    <Flex width={'100%'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>
                <Flex alignItems='center' gap={'.5rem'}>
                    Status
                </Flex>
              </th>
              <th>
                <Flex alignItems='center' gap={'.5rem'}>
                    Destinatário
                </Flex>
              </th>
              <th>
                <Flex alignItems='center' gap={'.5rem'}>
                    Assunto
                </Flex>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {model.mensagens.map((e, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Flex width={'100%'} alignItems='center' justifyContent={'center'} gap={'.5rem'}>
                    <ElementoTooltipCmp bg={e.enviado ? 'var(--Green3)' : 'var(--Red)'} label={e.enviado ? `Email enviado: ${e.dataEnvio}` : 'Email ainda não foi enviado'}>
                      <Box w={'.5rem'} h={'.5rem'} _hover={{ bg: e.enviado ? 'var(--Green3-hover)' : 'var(--Red-hover)' }} bg={e.enviado ? 'var(--Green3)' : 'var(--Red)'} borderRadius={'50%'}></Box>
                    </ElementoTooltipCmp>
                      <Box>{e.enviado ? 'Enviado' : 'Pendente'}</Box>
                    </Flex>
                  </td>
                  <td>
                    <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                      {e.email}
                    </Flex>
                  </td>
                  <td style={{ maxWidth: '20rem' }}>
                    <Flex alignItems='center' justifyContent={'center'} w={'full'}>
                      {e.assunto}
                    </Flex>
                  </td>
                  <Box as={'td'} width={'5rem'}>
                    <Flex alignItems='center' gap={'.5rem'} justifyContent={'end'}>
                      <ElementoTooltipCmp bg={'var(--Rosa)'} label={'Visualizar detalhes'}>
                        <ButtonCmp small VarColor='Rosa' onClick={() => onView(e)}><FaEye size={14}/></ButtonCmp>
                      </ElementoTooltipCmp>
                      {e.enviado && <ElementoTooltipCmp bg={'var(--Azul)'} label={'Reenviar email'}>
                        <ButtonCmp small VarColor='Green2' onClick={() => onSend(e)}><FaPaperPlane size={14}/></ButtonCmp>
                      </ElementoTooltipCmp>}
                      {!e.enviado && <ElementoTooltipCmp bg={'Red'} label={'Cancelar envio'}>
                        <ButtonCmp small VarColor='Red' onClick={() => onDelete(e)}><FaTrash size={14}/></ButtonCmp>
                      </ElementoTooltipCmp>}
                    </Flex>
                  </Box>
                </tr>
              )
            })}
          </tbody>

        </table>
      </TableDiv>
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Divider, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text, toast, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { v4 as uuidv4 } from 'uuid'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { InputCmp } from '../../../../components/InputCmp'
import { WhiteModal } from '../../../../components/ModalCreateForm/styles'
import { iEscala } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'

interface iModalProps {
  isOpen: boolean
  onSave: (selecionado: string) => void
  onClose: () => void
  escala?: iEscala
}

export const ModalEscala: React.FC<iModalProps> = ({ isOpen, onClose, onSave, escala }) => {
  const toast = useToast()
  const [Escala, setEscala] = useState<iEscala>({ id: '', nome: '', fator: 0, respostas: [{ id: uuidv4(), peso: -1, resposta: 'Não me sinto em condições de avaliar' }] })
  const [IsLoading, setIsLoading] = useState(false)

  function postEscala(): void {
    if (Escala.nome.length < 5) {
      toast({
        title: 'O nome da escala deve conter no mínimo 5 caracteres',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }
    if (Escala.respostas.length < 3) {
      toast({
        title: 'A escala deve conter no mínimo 2 respostas',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }

    if (Escala.respostas.some(e => e.resposta.length === 0)) {
      toast({
        title: 'Há respostas em branco',
        status: 'error',
        duration: 4000,
        position: 'top'
      })
      return
    }

    setIsLoading(true)
    if (escala) {
      appApi.put(`Escala/${escala.id}`, Escala)
        .then(res => {
          onSave(res.data)
          setEscala({ id: '', nome: '', fator: 0, respostas: [{ id: uuidv4(), peso: -1, resposta: 'Não me sinto em condições de avaliar' }] })
          toast({
            title: 'Escala atualizada com sucesso',
            duration: 4000,
            status: 'success',
            position: 'top'
          })
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    } else {
      appApi.post('Escala/Escala?descobrirFator=true', Escala)
        .then(res => {
          onSave(res.data)
          setEscala({ id: '', nome: '', fator: 0, respostas: [{ id: uuidv4(), peso: -1, resposta: 'Não me sinto em condições de avaliar' }] })
          toast({
            title: 'Escala criada com sucesso',
            duration: 4000,
            status: 'success',
            position: 'top'
          })
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false))
    }
  }

  function UpdateArray(id: string, texto: string, isPeso: boolean): void {
    const copy = [...Escala.respostas]
    const pos = Escala.respostas.findIndex(e => e.id === id)
    if (!isPeso) {
      copy[pos].resposta = texto
    } else {
      copy[pos].peso = parseFloat(texto)
    }
    setEscala({ ...Escala, respostas: copy })
  }

  useEffect(() => {
    if (escala) {
      setEscala(escala)
    } else {
      setEscala({ id: '', nome: '', respostas: [{ id: uuidv4(), peso: -1, resposta: 'Não me sinto em condições de avaliar' }], fator: 0 })
    }
  }, [escala])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal style={{ width: '52rem' }}>
        <FormControl>
          <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} mb={'.25rem'}>
            <Text fontSize={'1.375rem'} lineHeight={'1.5rem'} fontWeight={'700'} textAlign={'center'}>Escala</Text>
					  <Text fontSize={'.813rem'} color={'var(--Red)'} fontWeight={'600'} textAlign={'center'}>Crie e/ou edite uma escala</Text>
          </Flex>
          <InputCmp mb={'.875rem'} label='Nome' OnChange={(e) => { setEscala({ ...Escala, nome: e }) }} value={Escala?.nome}/>
          <RadioGroup width={'100%'}>
              <FormLabel mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} wordBreak={'keep-all'}>Questões</FormLabel>
              <Stack direction={'column'} width={'100%'}>
                {
                  <>
                    {Escala.respostas.filter(e => e.peso !== -1).map((e, i) => {
                      return (
                        <Flex gap={'.5rem'}>
                          {<Input
                            alignItems={'center'}
                            justifyContent={'center'}
                            textAlign={'center'}
                            border={'1px solid var(--c4)'}
                            h={'2.25rem'}
                            width={'6rem'}
                            value={e.peso}
                            step={'0.1'}
                            type={'number'}
                            fontSize={'.875rem'}
                            placeholder={'Peso'}
                            min={0}
                            onChange={(r) => UpdateArray(e.id, r.target.value, true)}
                          />}
                          <Flex
                              key={e.id}
                              borderRadius={'4px'}
                              alignItems={'center'}
                              h={'2.25rem'}
                              justifyContent={'space-between'}
                              border={'1px solid var(--c4)'}
                              width={'100%'}
                              padding={'0 .875rem'}
                          >
                            <Flex width={'100%'}>
                                <Radio marginRight={'.75rem'} value={e.id}/>
                                <Input
                                    type={'text'}
                                    border={'none'}
                                    _focus={{ border: 'none' }}
                                    padding={'0'}
                                    h={'2.125rem'}
                                    mr={'.5rem'}
                                    isDisabled={e.peso === -1}
                                    value={e.resposta}
                                    fontSize={'.875rem'}
                                    placeholder={'Digite sua resposta aqui'}
                                    onChange={(r) => UpdateArray(e.id, r.target.value, false)}
                                />
                            </Flex>
                            {e.peso !== -1 && <Flex alignItems={'center'} justifyContent={'center'} color={'var(--Red)'} _hover={{ color: 'va(--Red-hover)' }} onClick={() => setEscala({ ...Escala, respostas: Escala.respostas.filter(a => { if (a.id !== e.id) { return a } }) })}>
                              <MdClose
                                  size={18}
                                  cursor={'pointer'}
                              />
                            </Flex>}
                          </Flex>
                        </Flex>
                      )
                    })}
                    {Escala.respostas.filter(e => e.peso === -1).map((e, i) => {
                      return (
                        <Flex gap={'.5rem'}>
                            {<Input
                            alignItems={'center'}
                            justifyContent={'center'}
                            textAlign={'center'}
                            border={'1px solid var(--c4)'}
                            h={'2.25rem'}
                            width={'6rem'}
                            step={'0.1'}
                            type={'number'}
                            isDisabled={true}
                            opacity={'1 !important'}
                            fontSize={'.875rem'}
                            placeholder={'-'}
                            onChange={(r) => {}}/>}
                            <Flex
                                key={e.id}
                                borderRadius={'4px'}
                                alignItems={'center'}
                                h={'2.25rem'}
                                justifyContent={'space-between'}
                                border={'1px solid var(--c4)'}
                                width={'100%'}
                                padding={'0 .875rem'}
                            >
                              <Flex width={'100%'}>
                                  <Radio marginRight={'.75rem'} value={e.id}/>
                                  <Input
                                      type={'text'}
                                      border={'none'}
                                      _focus={{ border: 'none' }}
                                      padding={'0'}
                                      h={'2.125rem'}
                                      mr={'.5rem'}
                                      isDisabled={e.peso === -1}
                                      value={e.resposta}
                                      fontSize={'.875rem'}
                                      placeholder={'Digite sua resposta aqui'}
                                      onChange={(r) => UpdateArray(e.id, r.target.value, false)}
                                  />
                              </Flex>
                              {e.peso !== -1 && <Flex alignItems={'center'} justifyContent={'center'} color={'var(--Red)'} _hover={{ color: 'va(--Red-hover)' }} onClick={() => setEscala({ ...Escala, respostas: Escala.respostas.filter(a => { if (a.id !== e.id) { return a } }) })}>
                                <MdClose
                                    size={18}
                                    cursor={'pointer'}
                                />
                              </Flex>}
                            </Flex>
                        </Flex>
                      )
                    })}
                </>
              }
            </Stack>
        </RadioGroup>

        <Flex marginTop={'.75rem'}>
            <Radio isReadOnly isChecked={true} borderColor={'var(--c5)'}/>
            <Input
                border={'none'}
                value={'Adicione mais uma opção'}
                borderBottom={'1px solid var(--Gray4)'}
                width={'fit-content'}
                boxShadow='none'
                h={'2.125rem'}
                borderRadius={0}
                color={'var(--c6)'}
                fontSize={'.875rem'}
                _hover={{ borderBottom: '1px solid var(--Azul)', boxShadow: 'var(--SombraBtns)', color: 'var(--Azul)' }}
                _focus={{ borderBottom: '1px solid var(--Gray4)' }}
                _active={{ borderBottom: '1px solid var(--Gray4)' }}
                readOnly
                cursor={'pointer'}
                onClick={() => setEscala({ ...Escala, respostas: [...Escala.respostas, { resposta: '', id: uuidv4(), peso: 0 }] })}
            />
          </Flex>
        </FormControl>

        <Flex justifyContent={'end'} gap={'.5rem'} mt={'.75rem'}>
          <ButtonCmp VarColor='c6' onClick={onClose}>Voltar</ButtonCmp>
          <ButtonCmp isLoading={IsLoading} VarColor='Green2' onClick={() => postEscala()}>Salvar</ButtonCmp>
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Avatar, Flex, InputProps, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { iNomeId } from '../../interfaces'
import { InputCmp } from '../InputCmp'
import { Container, Nome, Sobrenome } from './styles'

interface iFindProps extends InputProps {
  onChoice: (id: string) => void
  lista: iNomeId[]
  messageSucess: string
  selecionados: string[]
}

export const AddBox: React.FC<iFindProps> = ({ lista, placeholder, isInvalid, isReadOnly, isDisabled, onChoice, messageSucess, selecionados }) => {
  const [Texto, setTexto] = useState('')
  const toast = useToast()

  function internalSelect(id: string): void {
    onChoice(id)
    setTexto('')

    toast({
      title: messageSucess,
      status: 'success',
      isClosable: false,
      position: 'top',
      duration: 4000
    })
  }

  return (
    <Flex position={'relative'} flexDir={'column'} width={'100%'}>
      <Flex flexDir={'column'} width='100%'>
        <InputCmp
          value={Texto}
          borderColor={'var(--Gray4)'}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isInvalid={isInvalid}
          OnChange={(e) => {
            setTexto(e)
          }}
        />
      </Flex>
        {lista.length > 0 &&
          <Flex position={'absolute'} bg={'white'} zIndex={999} top={'32px'} w={'100%'} flexDir={'column'} gap={'.25rem'}>
            {
              lista.filter((e) => {
                if (isDisabled) {
                  return null
                } else if (Texto === '') {
                  return null
                } else if (selecionados.includes(e.id)) {
                  return null
                } else if (e.nome.toLocaleLowerCase().includes(Texto.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).slice(0, 3).map((e, i) => {
                return (
                  <Container onClick={() => internalSelect(e.id)} key={i} background='var(--c3)'>
                    <Avatar margin={'.5rem 0rem .5rem .5rem'} size={'sm'} src={e.avatar} />

                    <Flex padding={'0 .5rem'} width={'100%'} justifyContent='center' alignItems={'start'} flexDir={'column'}>
                      <Nome>{e.nome}</Nome>
                      <Sobrenome>{e.complemento ?? 'Departamento não identificado'}</Sobrenome>
                    </Flex>
                  </Container>
                )
              })
            }
          </Flex>
        }
    </Flex>
  )
}

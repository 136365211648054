import styled from 'styled-components'

export const TabelaIndividual = styled.table`
    margin-top: 1rem;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    width: 100%;
    border-collapse: collapse;
    border-radius: 1rem;
    box-shadow: 0 0 0 1px #00000050;
    -webkit-border-radius: 16px;
    -moz-border-radius: 1rem;
    td{
        border: 1px solid #00000050;
        padding: .8rem;
    }
    th{
        border: 1px solid #00000050;
        padding: 0rem .8rem 0rem .8rem;
    }
    thead > tr {
        background-color: #b9b9b970;
        th {
            :first-child {
                background-color: var(--Azul);
                color: #fff;
            }
        }
    }
    svg {
        font-size: 22px;
        margin: 0 .5rem;
    }
`

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: var(--c1);
border-radius: 0.75rem;
height: 7.125rem;
width: 9.925rem;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .5rem;
}
`

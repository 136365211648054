import { Flex, useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaArrowRight, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../../../components/Button'
import { ModalCreateForm } from '../../../../../../components/ModalCreateForm'
import { ModalDelete } from '../../../../../../components/ModalDelete'
import { ModalDuplicarForm } from '../../../../../../components/ModalDuplicarForm'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { PropInvalida, useQuery } from '../../../../../../Utils/Helper'
import { ContainerFormulario } from '../../../../../PesquisaPercepcao/Formularios/styles'

export interface iForm90 {
  formularioId?: string
}

interface iProps {
  onChange: (model: iForm90) => void
  onVoltar: () => void
  onAvancar: () => void
  model?: iForm90
}

export const Form90Quantitativa: React.FC<iProps> = ({ onChange, onAvancar, onVoltar, model }) => {
  const toast = useToast()
  const nav = useNavigate()
  const { id } = useParams<{id: string}>()
  const query = useQuery()

  const [Model, setModel] = useState<iForm90>({
    formularioId: ''
  })

  const [Formularios, setFormularios] = useState<iNomeId[]>([])
  const [Id, setId] = useState<string>()

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  function getFormularios(): void {
    appApi.get('Formulario?modo=0&status=2')
      .then(response => setFormularios(response.data))
      .catch(err => console.log(err))
  }

  function DelteFormulario(): void {
    appApi.delete(`Formulario/${Id ?? ''}`)
      .then(() => getFormularios())
      .catch(err => console.log(err))
  }

  function FormIsInvalid(): boolean {
    if (PropInvalida(Model.formularioId)) {
      toast({
        title: 'Selecione um formulário!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }
    return false
  }

  function InternalOnAvancar(): void {
    if (!FormIsInvalid()) {
      onAvancar()
    }
  }

  function CriarFormulario(texto: string): void {
    if (texto === '') {
      toast({
        title: 'Informe um nome para o formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    appApi.post('Formulario', { nome: texto })
      .then((res) => {
        nav(`/Jornada/FeedbackPersonalizado/${id}/Pesquisa/Formulario/${res.data}`)
      }).catch(err => console.log(err))
  }

  function Duplicar(formName: string): void {
    const form = { texto: formName }
    appApi.post(`Formulario/${Id ?? ''}/Duplicar`, form)
      .then((res) => {
        nav(`/Jornada/FeedbackPersonalizado/${id}/Pesquisa/VisualizarFormulario/${res.data}`)
      }).catch(err => console.log(err))
  }

  function onOpenModalDuplicar(formId: string): void {
    setModalDuplicarIsOpen(true)
    setId(formId)
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDelete(formId: string): void {
    setModalDeleteIsOpen(true)
    setId(formId)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function InternalOnChange(form: iForm90): void {
    onChange(form)
    if (query.get('formularioId')) {
      nav(`/Jornada/FeedbackPersonalizado/${id}`)
    }
  }

  useEffect(() => {
    getFormularios()
  }, [])

  useEffect(() => {
    if (model) {
      setModel(model)
    }
  }, [model])

  return (
    <Flex flexDir={'column'} gap='2rem'>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={ModalCreateIsOpen}
        onCreate={CriarFormulario}
        onClose={() => setModalCreateIsOpen(false)}
      />

      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(e) => Duplicar(e)}
        onClose={onCloseModalDuplicar}
      />

      <Flex flexDir={'column'} gap='1rem'>
        <h3>Selecione um formulário já criado ou elabore um novo</h3>
        <Button
          leftIcon={<FaPlus size={13} />}
          VarColor={'v2'}
          w={'fit-content'}
          onClick={() => setModalCreateIsOpen(true)}
        >Criar novo formulário</Button>
        <h3>Formulários salvos</h3>
        <section>
          {
            Formularios.map((e, i) => (
              <ContainerFormulario
                key={i}
                theme={'Gray6'}
              >
                <span>{e.nome}</span>
                <div>
                  {Model.formularioId !== e.id &&
                    <Button
                      VarColor='v2'
                      size={'sm'}
                      onClick={() => InternalOnChange({ ...Model, formularioId: e.id })}
                    >Selecionar</Button>
                  }

                  {Model.formularioId === e.id &&
                    <Button
                      size={'sm'}
                      VarColor='Rosa'
                      onClick={() => InternalOnChange({ ...Model, formularioId: undefined })}
                    >Deselecionar</Button>
                  }
                  <Button
                    size='lg'
                    VarColor='Green2'
                    height={'29px'}
                    onClick={() => onOpenModalDuplicar(e.id)}
                  >Duplicar</Button>
                  <button
                    onClick={() => onOpenModalDelete(e.id)}
                  ><FaTrashAlt size={11} /></button>
                </div>
              </ContainerFormulario>
            ))
          }
        </section>
      </Flex>

      <Flex justifyContent={'end'} gap='1rem'>
        <Button
          fontWeight={'500'}
          VarColor='Gray3'
          onClick={onVoltar}
        >Voltar</Button>

        <Button
          fontWeight={'500'}
          VarColor='Green2'
          rightIcon={<FaArrowRight />}
          onClick={InternalOnAvancar}
        >Avançar</Button>
      </Flex>
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-misused-promises */

import { Avatar, Box, Flex, Link, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { FaCalendarDay, FaCheck, FaClock, FaCopy, FaEye } from 'react-icons/fa'
import { IoArrowUndoSharp } from 'react-icons/io5'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { iDadoReuniao } from '../..'
import { appApi } from '../../../../../services/appApi'
import { transformarDuracao } from '../../../../../Utils/Helper'
import { FormatarDataCSharp, iModalAgendamentoInput } from '../../../../Jornada/ReuniaoOne/Assunto'
import { ModalRecusarAgendamento } from '../../../../Jornada/ReuniaoOne/Assunto/components/ModalRecusarAgendamento'
import { PopUpAceitar } from '../../../../Jornada/ReuniaoOne/Assunto/components/PopUpAceitar'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface iProps {
  model: iDadoReuniao
  reload: () => void
}
export const CardReuniaoOne: React.FC<iProps> = ({ model, reload }) => {
  const toast = useToast()
  const nav = useNavigate()

  const [PopUpAceitarIsOpen, setPopUpAceitarIsOpen] = useState(false)
  const [ModalRecusarAgendamentoIsOpen, setModalRecusarAgendamentoIsOpen] = useState(false)
  const [AceitarIsLoading, setAceitarIsLoading] = useState(false)

  function ResponderSolicitacao(aceita: boolean): void {
    if (aceita) {
      setAceitarIsLoading(true)
      appApi.patch(`ReuniaoOne/${model.id}/Aceitar?dataEscolhida=${FormatarDataCSharp(model.dataInicio ?? '')}`)
        .then(() => {
          setAceitarIsLoading(false)
          setPopUpAceitarIsOpen(true)
        })
        .catch(() => {
          toast({
            title: 'Erro ao agendar a reunião reunião!',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setAceitarIsLoading(false)
        })
    } else {
      setModalRecusarAgendamentoIsOpen(true)
    }
  }

  function RecusarConvite(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${model.id}/Recusar`, form)
      .then(() => {
        toast({
          title: 'Reunião recusada com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalRecusarAgendamentoIsOpen(false)
        reload()
      })
      .catch(err => console.log(err))
  }

  async function CopyLink(): Promise<void> {
    await navigator.clipboard.writeText(model?.link ?? '')
    toast({
      title: 'Copiado para área de transferência',
      status: 'success',
      isClosable: false,
      position: 'top',
      duration: 4000
    })
  }

  return (
    <Box border={'2px solid var(--c3)'} p={'.5rem'} borderRadius={'.25rem'}>
      {PopUpAceitarIsOpen &&
        <PopUpAceitar
          onClose={() => { setPopUpAceitarIsOpen(false); reload() }}
        />
      }

      {ModalRecusarAgendamentoIsOpen &&
        <ModalRecusarAgendamento
          alvo={model.colaborador}
          onClose={() => setModalRecusarAgendamentoIsOpen(false)}
          onRecusar={RecusarConvite}
        />
      }
      <Flex alignItems={'center'} justifyContent={'space-around'}>
        <Flex gap={'.5rem'} alignItems={'center'}>
          <Avatar name={model.colaborador.info1} src={model.colaborador.avatar} border={'1px solid var(--Azul)'}/>
          <Flex flexDir={'column'} justifyContent={'center'}>
            <Text fontSize={'13px'} fontWeight={'400'} lineHeight={'14px'}>{model.colaborador.info2}</Text>
            <Text fontWeight={'600'} fontSize={'15px'} lineHeight={'17px'}>{model.colaborador.info1}</Text>
          </Flex>
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} mx={'.5rem'} px={'1rem'} borderLeft={'1px solid var(--Gray5)'} borderRight={'1px solid var(--Gray5)'}>
          <Text fontSize={'13px'} fontWeight={'400'} lineHeight={'14px'}>Tipo</Text>
          <Text fontWeight={'600'} fontSize={'15px'} lineHeight={'17px'}>{model.tipo}</Text>
        </Flex>
        <Flex flexDir={'column'} justifyContent={'center'} mr={'.5rem'}>
          <Text fontSize={'13px'} fontWeight={'600'} lineHeight={'16px'}>Data: {model.dataInicio}</Text>
          <Text fontSize={'13px'} fontWeight={'600'} lineHeight={'16px'}>Duração: {transformarDuracao(model.duracao)}</Text>
        </Flex>
        {(model.status === 0) &&
          <Flex flexDir={'column'} gap='.5rem'>
            <ButtonCmp
              VarColor='Rosa'
              leftIcon={<IoArrowUndoSharp />}
              onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
            >Visualizar</ButtonCmp>

            <ButtonCmp
              VarColor='Green2'
              isLoading={AceitarIsLoading}
              leftIcon={<FaCheck />}
              onClick={() => ResponderSolicitacao(true)}
              disabled={model.dataInicio === 'Data não definida'}
            >Aceitar</ButtonCmp>

            <ButtonCmp
              VarColor='Red'
              leftIcon={<MdClose size={'1.2rem'} />}
              disabled={model.dataInicio === 'Data não definida'}
              onClick={() => ResponderSolicitacao(false)}
            >Recusar</ButtonCmp>
          </Flex>
        }

        {model.status === 2 &&
          <ButtonCmp
            VarColor='Red'
            leftIcon={<FaClock />}
            onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
          >Atrasada</ButtonCmp>
        }

        {(model.status === 1 || model.status === 3) &&
          <ButtonCmp
            VarColor='Rosa'
            leftIcon={<FaEye />}
            onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
          >Visualizar</ButtonCmp>
        }

        {(model.status === 4) &&
          <ButtonCmp
            VarColor='Rosa'
            leftIcon={<FaCalendarDay />}
            onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
          >Agendar</ButtonCmp>
        }
      </Flex>
      <Box mt={'.5rem'}>
        <Text fontSize={'.875rem'} fontWeight={'700'}>{model.assunto}</Text>
        <Text dangerouslySetInnerHTML={{ __html: model.descricao }} fontSize={'13px'}></Text>
        {model?.link && <Flex gap={'.325rem'} cursor={'pointer'} alignItems='center' mt={'.25rem'}><Link href={model?.link} fontSize={'13px'} color={'var(--Azul)'}>Clique aqui para acessar a reunião</Link> <ElementoTooltipCmp label={'Copiar link da reunião'}><Box onClick={CopyLink} _hover={{ color: 'var(--AzulOpaco)' }} ><FaCopy size={13}/></Box></ElementoTooltipCmp></Flex>}
      </Box>
    </Box>
  )
}

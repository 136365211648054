import {
  Drawer, DrawerBody,
  DrawerOverlay, DrawerContent, DrawerHeader,
  Flex, FormControl, Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { iFiltro } from '../..'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../../components/SelectCmp'
import { iNomeId } from '../../../../../../../interfaces'

interface iDrawProps {
  isOpen: boolean
  onClose: () => void
  onFilter: (model: iFiltro) => void
  tags: iNomeId[]
  areas: iNomeId[]
  cargos: iNomeId[]
  filtro: iFiltro
}

export const DrowerFiltroReuniaoOne: React.FC<iDrawProps> = ({ isOpen, onClose, onFilter, areas, cargos, tags, filtro }) => {
  const [Filtro, setFiltro] = useState<iFiltro>({
    cargoId: '',
    departamentoId: '',
    tagId: '',
    dataFim: '',
    dataInicio: '',
    status: ''
  })
  useEffect(() => {
    setFiltro(filtro)
  }, [])

  return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size={'sm'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader as={Flex} width={'100%'} alignItems={'center'} justifyContent={'space-between'} borderBottom={'2px solid var(--c3)'}>
                    <Text fontSize={'1rem'}>Filtros</Text>
                    <MdClose onClick={onClose} size={20} color={'var(--Red)'} cursor={'pointer'}/>
                </DrawerHeader>

                <DrawerBody>
                    <Flex gap={'1rem'} flexDir={'column'}>
                        <Flex gap={'.75rem'}>
                            <FormControl>
                                <InputCmp
                                    label={'Início'}
                                    value={Filtro.dataInicio}
                                    OnChange={(e) => setFiltro({ ...Filtro, dataInicio: e })}
                                    type={'date'}
                                    max={'2099-12-31T23:59'}
                                    borderColor={'var(--Gray4)'}
                                />
                            </FormControl>

                            <FormControl>
                                <InputCmp
                                    label={'Fim'}
                                    value={Filtro.dataFim}
                                    type={'date'}
                                    max={'2099-12-31T23:59'}
                                    borderColor={'var(--Gray4)'}
                                    OnChange={(e) => setFiltro({ ...Filtro, dataFim: e })}
                                />
                            </FormControl>
                        </Flex>
                        <FormControl>
                            <SelectCmp
                                title={'Status'}
                                value={Filtro.status}
                                OnChange={(e) => setFiltro({ ...Filtro, status: e })}
                            >
                                <option value={''}>Todos</option>
                                <option value={'5'}>Finalizadas</option>
                                <option value={'3'}>Reagendadas</option>
                                <option value={'2'}>Recusadas</option>
                                <option value={'0'}>Aguardando resposta</option>
                                <option value={'4'}>Atrasadas</option>
                            </SelectCmp>
                        </FormControl>

                        <FormControl>
                            <SelectCmp
                                title='Área'
                                value={Filtro.departamentoId}
                                OnChange={(e) => setFiltro({ ...Filtro, departamentoId: e })}
                            >
                                <option value={''}>Todas</option>
                                {areas.map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </SelectCmp>
                        </FormControl>

                        <FormControl>
                            <SelectCmp
                                title='Squad'
                                value={Filtro.tagId}
                                OnChange={(e) => setFiltro({ ...Filtro, tagId: e })}
                            >
                                <option value={''}>Todas</option>
                                {tags.filter(e => e.nome !== '').map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </SelectCmp>
                        </FormControl>

                        <FormControl>
                            <SelectCmp
                                title='Função'
                                value={Filtro.cargoId}
                                OnChange={(e) => setFiltro({ ...Filtro, cargoId: e })}
                            >
                                <option value={''}>Todas</option>
                                {cargos.map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </SelectCmp>
                        </FormControl>

                        <Flex gap={'.5rem'} justifyContent={'end'}>
                            <ButtonCmp
                                VarColor='c4'
                                onClick={onClose}
                            >Cancelar
                            </ButtonCmp>
                            <ButtonCmp
                                VarColor='Green2'
                                leftIcon={<FaFilter/>}
                                onClick={() => onFilter(Filtro)}
                            >Filtrar
                            </ButtonCmp>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
  )
}

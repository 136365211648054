import styled from 'styled-components'

export const Main = styled.div`
padding: 2rem 1rem;
border: 2px solid var(--c6);
border-radius: .25rem;
display: flex;
position: relative;
justify-content: center;
align-items: center;
gap: 7rem;
margin: 1rem 0rem;

h3 {
    margin-bottom: .5rem;
}

strong{
    font-weight: 900;
    font-size: .875rem;
    color: var(--Azul);
}

span{
    font-weight: 500;
    font-size: .875rem;
    line-height: 1rem;
}

div{
    display: flex;
    flex-direction: column;

    span:not(:last-child){
        margin-bottom: .3rem;
    }
}

`

import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../components/ButtonCmp'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  onConfirmComProgresso: () => void
  message: string
}

export const ModalConcluirAcao: React.FC<props> = ({ isOpen, onRequestClose, onConfirm, message, onConfirmComProgresso }) => {
  Modal.setAppElement('#root')

  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-default'
        >
          <Flex flexDir={'column'} p={'.75rem'} w={'35rem'} gap={'1.25rem'}>
            <Flex justifyContent={'space-between'}>
                <div></div>
                <h3 style={{ color: 'var(--Red)' }}>Atenção!</h3>
                <Flex alignItems={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}>
                  <MdClose size={19} onClick={onRequestClose} cursor={'pointer'}/>
                </Flex>
            </Flex>

            <Flex flexDir={'column'} gap={'1.25rem'} fontSize={'1rem'} fontWeight={'600'}>
                <span style={{ textAlign: 'center' }}>{message}</span>
                <Flex justifyContent={'center'} gap={'.75rem'}>
                    <ButtonCmp VarColor={'c5'} onClick={onRequestClose}>Cancelar</ButtonCmp>
                    <ButtonCmp VarColor='Rosa' onClick={() => {
                      onConfirm()
                      onRequestClose()
                    }}>Não, desejo apenas concluir a ação</ButtonCmp>
                    <ButtonCmp VarColor='Green2' onClick={() => {
                      onConfirmComProgresso()
                      onRequestClose()
                    }}>Sim</ButtonCmp>
                </Flex>
            </Flex>
          </Flex>
        </Modal>
  )
}

import { Flex } from '@chakra-ui/layout'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'

interface iProps {
  isOpen: boolean
  onRedirect: () => void
  onRequestClose: () => void
}

export const ModalAviso: React.FC<iProps> = ({ isOpen, onRedirect, onRequestClose }) => {
  return (
    <ModalBase
      isOpen={isOpen}
      Titulo={'Atenção'}
      onClose={onRequestClose}
    >
      <Flex p={'1.5rem'} pt={'0'} flexDir={'column'} gap='1rem'>
        <Flex flexDir={'column'} gap='.5rem' width={'25.5rem'}>
          <strong>Essa pesquisa não tem nenhuma avaliação em andamento</strong>
          <Flex justifyContent={'center'} gap={'1rem'}>
            <Button VarColor='Green2' onClick={onRedirect}>Visualizar avaliações</Button>
          </Flex>
        </Flex>
      </Flex>
    </ModalBase>
  )
}

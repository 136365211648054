import { Button, Flex, Input, useToast, Text, RadioGroup, Radio, Stack, Checkbox, Link } from '@chakra-ui/react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

// import { useNavigate } from 'react-router-dom'
import { appApi } from '../../services/appApi'
import { ValidaCPF, ValidarEmail } from '../../Utils/Helper'
import { LayoutFull } from '../Layouts/LayoutFull'
import { Container, HeaderContainer } from '../Layouts/LayoutFullAnswer/styles'

export const EnviarPI: React.FC = () => {
  const [Nome, setNome] = useState('')
  const [Email, setEmail] = useState('')
  const [CPF, setCPF] = useState('')
  const [Genero, setGenero] = useState('0')
  const [Aceite, setAceite] = useState(false)
  const { jobId } = useParams<{ jobId: string }>()

  const toast = useToast()
  //  const navigate = useNavigate()

  function enviarAvaliacao(): void {
    if (Nome.length < 5) {
      toast({
        title: 'Você deve informar seu nome completo',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }
    if (!ValidarEmail(Email)) {
      toast({
        title: 'Digite um email válido',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }

    if (!ValidaCPF(CPF)) {
      toast({
        title: 'Digite um CPF válido',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
      return
    }
    toast({
      title: 'carregando sua avaliação',
      // description: `Em breve um e-mail será enviado a ${Nome} ${Email}`,
      position: 'top-right',
      variant: 'left-accent',
      status: 'success',
      duration: 12000,
      isClosable: false
    })

    appApi.post('AssessmentPI/Send', { nome: Nome, email: Email, cpf: CPF, genero: Genero, jobId: jobId })
      .then(res => {
        console.log(res.data)
        const { url } = res.data
        toast({
          title: 'carregando sua avaliação',
          // description: `Em breve um e-mail será enviado a ${Nome} ${Email}`,
          position: 'top-right',
          variant: 'left-accent',
          status: 'success',
          duration: 12000,
          isClosable: false
        })
        window.location.replace(url)
        //     navigate('/AssessmentPi/Dashboard')
      }).catch(err => {
        toast({
          title: 'Não foi possível carregar a avaliação',
          description: 'Tente novamente mais tarde',
          position: 'top-right',
          variant: 'left-accent',
          status: 'error',
          duration: 9000,
          isClosable: false
        })
        console.log(err)
        //   navigate('/AssessmentPi/Dashboard')
      })
  }

  return <LayoutFull>
    <HeaderContainer>
                <img alt='Logo da empresa' src='https://cdn2.downdetector.com/static/uploads/c/300/4a0fc/Claro-logo_1.png' />
                <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
            </HeaderContainer>
            <Container>
    <Flex width={'100%'} justifyContent='center'>

    <Flex width={'80%'} p='2rem' direction='column' bgColor='white' minHeight={'400px'} borderRadius='2xl'>
    <Text fontWeight={'bold'} fontSize='2xl'>Bem vindo a etapa de perfil comportamental. </Text>

    <Flex mt='2rem' alignSelf={'center'} direction='column' width={'45rem'}>

    <Text>Antes de iniciar precisamos de alguns dados:</Text>

    <Text mt='1rem' fontWeight='bold' mb={2}>Qual o seu nome?</Text>
    <Input
      isInvalid={Nome.length < 8}
      errorBorderColor='red.300'
    onChange={(s) => setNome(s.target.value)} placeholder='Digite o seu nome' />
    <Text mt='1rem' fontWeight='bold' mb={2}>Qual o seu e-mail?</Text>
    <Input
          isInvalid={!ValidarEmail(Email)}
          errorBorderColor='red.300'
    onChange={(s) => setEmail(s.target.value)} value={Email} placeholder='Digite o seu email' />
    <Text mt='1rem' fontWeight='bold' mb={2}>Qual o seu CPF?</Text>
    <Input width={'25rem'}
          isInvalid={CPF.length < 11 || !ValidaCPF(CPF)}
          errorBorderColor='red.300'
    onChange={(s) => setCPF(s.target.value) } value={CPF} placeholder='Digite o seu CPF' />
    <Text mt='1rem' fontWeight='bold' mb={2}>Qual o seu gênero?</Text>
     <RadioGroup onChange={ (e) => setGenero(e)} value={Genero}>
      <Stack direction='row'>
        <Radio value='2'>Feminino</Radio>
        <Radio value='1'>Masculino</Radio>
        <Radio value='0'>Outro</Radio>
        <Radio value='3'>Prefiro não identificar</Radio>
      </Stack>
    </RadioGroup>
    <Text mt='1rem' fontWeight='bold' mb={2}>Para continuar você deve aceitar os termos de uso e privacidade <Link color='blue.600' href='#'>disponíveis aqui para consulta.</Link></Text>
    <Checkbox
        isChecked={Aceite}
        onChange={(e) => setAceite(e.target.checked)}
      >
        Li e concordo com os termos de uso
      </Checkbox>

    </Flex>
    <Flex gap={'1rem'} mt='3rem'>
    <Button isDisabled={!Aceite} width={'100%'} bgColor={'var(--Rosa)'}
    onClick={enviarAvaliacao}
    >Iniciar o teste</Button>

    </Flex>

    </Flex>
    </Flex>

            </Container>
    </LayoutFull>
}

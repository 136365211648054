import axios from 'axios'

export const urlLocal = process.env.REACT_APP_URL_LOCAL
export const urlSigah = process.env.REACT_APP_URL_SIGAH
export const urlApi = process.env.REACT_APP_URL_API

export const appApi = axios.create({
  baseURL: urlApi
})

appApi.interceptors.response.use(
  response => response,
  async error => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('logo')
        localStorage.removeItem('token')
        localStorage.removeItem('departamentosIdNineBox')
        localStorage.removeItem('squadsIdNineBox')
        localStorage.removeItem('gestoresIdNineBox')
        appApi.defaults.headers.common.Authorization = ''
        SigahApi.defaults.headers.common.Authorization = ''
        TestApi.defaults.headers.common.Authorization = ''
        window.location.reload()
      }
      // Aqui você pode executar o tratamento específico para erros de resposta
    } else if (error.request) {
      // console.log('Erro de requisição:', error.request)
    } else {
      // console.log('Erro:', error.message)
    }
    return await Promise.reject(error) // Passa o erro adiante
  }
)

export const SigahApi = axios.create({
  baseURL: urlSigah
})

export const TestApi = axios.create({
  baseURL: process.env.REACT_APP_URL_TESTE_API
})

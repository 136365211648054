import { Flex, Text } from '@chakra-ui/react'
import { iPontoAtencaoPDI } from '../../../../../interfaces'

interface iProps {
  model: iPontoAtencaoPDI
  pos?: number
}

export const CardItemPDI: React.FC<iProps> = ({ model, pos }) => {
  return (
        <Flex width={'100%'} height={'100%'} boxShadow={'var(--SombraBackground)'} minH={'10rem'}>
            <Flex borderBottomLeftRadius={'.25rem'} borderTopLeftRadius={'.25rem'} background={'var(--c2)'} pos={'relative'}>
                {pos && <h3 style={{ position: 'absolute', top: '3px', left: '8px', fontSize: '1.125rem' }}>{pos}°</h3>}
                <Flex width={'10rem'} textAlign={'center'} justifyContent={'center'} alignItems='center' flexDir={'column'} padding={'.5rem'} fontSize={'.8325rem'}>
                    <Flex width={'4rem'}>
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={model.avatar} />
                    </Flex>
                    <strong>{model.competencia.replace('/', ' / ')}</strong>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} gap='1rem' borderBottomRightRadius={'.325rem'} borderTopRightRadius={'.325rem'} padding={'1rem'} background={model.status === 0 ? 'var(--Green2)' : 'var(--Red)'} width={'-webkit-fill-available'}>
                <Flex height={'100%'} alignItems={'end'} justifyContent='space-between'>
                    <Flex flexDir={'column'} gap={'.75rem'} fontSize={'.875rem'}>
                        <Flex color={'white'} flexDir='column'>
                            <Text fontWeight={400}>Colaboradores que {model.status === 0 ? 'evoluíram' : 'pioraram'} nessa competência</Text>
                            <Text fontWeight={'700'} fontSize={'.9325rem'}>{model.colaboradores}</Text>
                        </Flex>
                        <Flex color={'white'} flexDir='column'>
                            <Text fontWeight={400}>Média</Text>
                            <Text fontWeight={'700'} fontSize={'.9325rem'}>{model.porcentagem?.toFixed(2)}</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}

import { Flex, FormControl } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { SwitchCmp } from '../../../../../components/SwitchCmp'
import { useLocation } from 'react-router'
import { WhiteModal } from './styles'

interface iModalProps {
  isOpen: boolean
  value: boolean
  hiddenSwitch?: boolean
  onCreate: (texto: string, isIndicativa: boolean) => void
  onClose: () => void
}

export const ModalCreateForm: React.FC<iModalProps> = ({ isOpen, value, hiddenSwitch, onClose, onCreate }) => {
  const [Texto, setTexto] = useState('')
  const [IsIndicativa, setIsIndicativa] = useState(value)

  useEffect(() => {
    setIsIndicativa(value)
  }, [value])
  const path = useLocation().pathname

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <WhiteModal>
        <FormControl as={Flex} flexDir={'column'} gap={'.25rem'}>
          <h3>Defina um nome para seu formulário</h3>
          <InputCmp
            value={Texto}
            borderColor={'var(--Gray4)'}
            OnChange={setTexto}
            type="text"
            placeholder='Digite o nome'
          />
        </FormControl>
        {!(path.includes('Quantitativa') || path.includes('Indicativa')) &&
          <SwitchCmp
            OnChange={setIsIndicativa}
            value={IsIndicativa}
            legendaEsquerda={'Quantitativa'}
            legendaDireita={'Indicativa'}
          />}

        <Flex justifyContent={'end'} gap={'.375rem'}>
          <ButtonCmp VarColor='c5' onClick={onClose}>Cancelar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onCreate(Texto, IsIndicativa)}>Criar</ButtonCmp>
        </Flex>
      </WhiteModal>
    </Modal>
  )
}

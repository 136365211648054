import styled from 'styled-components'

export const Container = styled.div`
display: flex;
white-space:nowrap;
align-items: center;
box-sizing: border-box;
border:solid var(--Rosa);
border-width:0.1rem;
border-radius: 0.25rem;
font-family: 'Roboto', sans-serif;
width: fit-content;
color: var(--Rosa);
height: 1.75rem;
padding-right: .5rem;
 svg{
     margin-right: .325rem;
     margin-left: .325rem;
 }
 &:hover{
     color: white;
     background: var(--Rosa-hover);
     cursor: pointer;
 }
`

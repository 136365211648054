/* eslint-disable no-tabs */
import { Box, Flex, Img, Text } from '@chakra-ui/react'
import Calendario from './Assets/calendarWeek.svg'
import Pastas from './Assets/pastas.svg'
import UserPlus from './Assets/userPlus.svg'
import Engrenagem from './Assets/engrenagens.svg'
import Clipboard from './Assets/clipboardCheck.svg'

interface iProps {
  color: string[]
}

export const TimelineCriarPesquisa: React.FC<iProps> = ({ color }) => {
  return (
		<Flex alignItems={'center'} mb={'3.5rem'} ml={'.7rem'}>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={'var(--Azul)'} border={'2px solid var(--Azul)'}>
					<Img src={Engrenagem} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-9px'} textAlign={'center'}>Selecionando o modelo</Text>
			</Flex>
			<Box bgColor={'var(--Azul)'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[0] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[0] ? color[0] : '#BBBBBB'} border={color[0] ? `2px solid ${color[0]}` : 'none' }>
					<Img src={Engrenagem} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-13px'} textAlign={'center'}>Configurações iniciais</Text>
			</Flex>
			<Box bgColor={color[0] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[1] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[1] ? color[1] : '#BBBBBB'} border={color[1] ? `2px solid ${color[1]}` : 'none' }>
					<Img src={Pastas} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-2px'} textAlign={'center'}>Formulário</Text>
			</Flex>
			<Box bgColor={color[1] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[2] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[2] ? color[2] : '#BBBBBB'} border={color[2] ? `2px solid ${color[2]}` : 'none' }>
					<Img src={UserPlus} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'-5px'} textAlign={'center'}>Adicionar participante</Text>
			</Flex>
			<Box bgColor={color[2] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[3] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[3] ? color[3] : '#BBBBBB'} border={color[3] ? `2px solid ${color[3]}` : 'none' }>
					<Img src={Calendario} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'12px'} textAlign={'center'}>Textos</Text>
			</Flex>
			<Box bgColor={color[3] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[4] ? 'var(--Azul)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[4] ? color[4] : '#BBBBBB'} border={color[4] ? `2px solid ${color[4]}` : 'none' }>
					<Img src={Clipboard} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} fontWeight={600} fontSize={'.875rem'} lineHeight={'.95rem'} top={'69px'} right={'0px'} textAlign={'center'}>Pesquisa criada</Text>
			</Flex>
		</Flex>
  )
}

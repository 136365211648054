import styled from 'styled-components'

export const Container = styled.div`
border-radius: 0.25rem;
background: white;
width: 100%;
border: 2px solid var(--c3);
box-sizing: border-box;
padding: .875rem;
`

import { Flex, Grid, Img, Text } from '@chakra-ui/react'
import { iBoxPDI } from '../..'
import IconeNegativo from '../../../../../assets/ICONE-AVISO.svg'
import IconePositivo from '../../../../../assets/ICONE-SAH.svg'

interface iProps{
  model: iBoxPDI[]
  status: number
}

export const BoxPDI: React.FC<iProps> = ({ model, status }) => {
  return (
        <Flex width={'100%'} flexDir='column' gap={'.5rem'}>
            <Flex fontWeight={'500'} color={'black'} fontSize={'1.25rem'} fontFamily={'Poppins'}>
                {status === 0 ? <Flex alignItems={'center'} gap='.5rem'><Img w={'2.325rem'} h={'2.325rem'} src={IconePositivo} /> Competências que evoluíram</Flex> : status === 1 ? <Flex alignItems={'center'} gap='.5rem'><Img w={'2.325rem'} h={'2.325rem'} src={IconeNegativo}/> Competências que pioraram</Flex> : 'Competências que mantiveram seu desempenho'}
            </Flex>
            <Grid gridTemplateColumns={`repeat(${model.filter(e => e.status === status).length > 2 ? '3' : '2'}, 1fr)`} p={'.5rem 1rem'} borderRadius={'.125rem'} bg={'#BBBBBB20'} border={`2px solid var(--${status === 0 ? 'Green2' : status === 1 ? 'Red' : 'c6'})`} boxShadow={'var(--SombraBackground)'}>{model.filter(e => e.status === status).map(e => {
              return <Flex textAlign={'center'} alignItems='center' flexDir={'column'} padding={'.5rem'} fontSize={'.875rem'} gap={'.25rem'} h={'148px'}>
                <Text fontWeight={'600'} fontSize={'14px'} color={`var(--${status === 0 ? 'Green2' : status === 1 ? 'Red' : 'Gray3'})`}>{e.statusMensagem}</Text>
                <Flex width={'4.5rem'} border={'3px dashed var(--Azul)'} borderRadius={'50%'} bg={'white'}>
                    <img style={{ width: '100%', height: '100%' }} src={e.avatar} />
                </Flex>
                <strong>{e.competencia?.replace('/', ' / ')}</strong>
            </Flex>
            })}</Grid>
        </Flex>
  )
}

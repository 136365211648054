import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { iReuniao } from '../..'
import { Legenda } from '../../../Configuracao/styles'

interface iProps{
  model: iReuniao
}

export const CardReuniao: React.FC<iProps> = ({ model }) => {
  function ObterNomeStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'Aguardando'
      case '1':
        return 'Aceita'
      case '2':
        return 'Recusada'
      case '3':
        return 'Reagendada'
      case '4':
        return 'atrasada'
      case '5':
        return 'Finalizada'
      case '6':
        return 'Agendamento'
      default: return ''
    }
  }

  function ObterCorStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'var(--Gray3)'
      case '1':
        return 'var(--Azul)'
      case '2':
        return 'var(--Rosa)'
      case '3':
        return 'var(--Purple1)'
      case '4':
        return 'var(--Red)'
      case '5':
        return 'var(--Green3)'
      case '6':
        return 'var(--Orange)'
      default: return ''
    }
  }

  return (
        <Box border={'2px solid var(--c4)'} padding={'1rem'} borderRadius={'.25rem'}>
            <Text fontWeight={'700'} fontSize={'.875rem'}>{model.assunto}</Text>
            <Flex
                mt={'.5rem'}
                gap={'.5rem'}
                alignItems={'center'}
            >
                <Avatar src={model.participante.avatar} size={'sm'} />
                <Text fontSize={'13px'}>{model.participante.nome}</Text>
            </Flex>

            <Flex justifyContent={'space-between'} alignItems={'center'} mt={'.65rem'}>
                <Legenda style={{ marginTop: '0' }}>
                    {model.dataInicio}
                </Legenda>
                <Text borderRadius={'6px'} color={'#fff'} bgColor={ObterCorStatus(model.status)} px={'.5rem'} h={'1.4rem'} fontSize={'12px'} fontWeight={'400'}>
                    {ObterNomeStatus(model.status)}
                </Text>
            </Flex>
        </Box>
  )
}

import { iGrafico } from '../../../../../../../../interfaces'
import { GenerateChartBlob } from '../../../../../../../../Utils/Helper_chart'

export async function getBlobGraficoStacked(titulo: string, grafico: iGrafico): Promise<string> {
  const colors = [
    '#1961E0',
    '#27AE60',
    '#BDBDBD',
    '#F2C94C',
    '#F2994A',
    '#FF5050',
    '#9B51E0'
  ]

  function getSeries(): any {
    return grafico.dados.map((e) => {
      return {
        name: e.nome,
        data: e.medias
      }
    })
  }

  const options = {
    colors: colors,
    series: getSeries(),
    chart: {
      type: 'bar',
      height: 550,
      stacked: true,
      stackType: '100%',
      animations: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    dataLabels: {
      formatter: function (value: number): string {
        return value.toFixed(0)
      }
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    title: {
      text: titulo,
      style: {
        fontSize: 18
      }
    },
    xaxis: {
      categories: grafico.competencias,
      tickAmount: 10,
      labels: {
        formatter: function (val: any) {
          return `${val}%`
        },
        style: {
          fontSize: 14
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (val: any) {
          return val
        },
        style: {
          fontSize: 14
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontWeight: 500,
      fontSize: 16,
      itemMargin: {
        horizontal: 16,
        vertical: 16
      },
      markers: {
        width: 18,
        height: 18,
        radius: 0,
        offsetX: -5
      }
    }
  }

  return await GenerateChartBlob(options)
}

import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { iQuestao, iRespostaXColaborador } from '../../../../../../../../Feedback/VisualizarRespostas'
import { GlobalStyles } from '../../styles'

interface iProps {
  questao: iQuestao
  respostas: iRespostaXColaborador[]
}

export const Discursiva: React.FC<iProps> = ({ questao, respostas }) => {
  const styles = StyleSheet.create({
    Container: {
      flexDirection: 'column',
      marginVertical: 0.8
    },
    Resposta: {
      marginVertical: 4,
      backgroundColor: '#F2F2F2',
      padding: 8
    },
    Data: {
      fontSize: 12,
      fontWeight: 400,
      marginLeft: 8
    }
  })
  return (
        <View style={styles.Container}>
            {respostas.map((res, i2) => {
              const resposta = res.respostas.find(e => e.questaoId === questao.id)
              if (resposta === undefined) return null
              return (
                <View key={i2} style={styles.Resposta}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
                        <Image src={res.avatar} style={GlobalStyles.Avatar} />
                        <Text style={styles.Data}>{resposta?.data}</Text>
                    </View>
                    <Text style={GlobalStyles.TextCheckBox}>{resposta?.comentario}</Text>
                </View>
              )
            })}
        </View>
  )
}

import { useNavigate } from 'react-router-dom'
import { Btn, StandBtn } from './styles'

interface IBtnBackNav {
  Caminho?: string
  Parametro?: any
  isNav: boolean
  onClick?: () => void
  Size: string
}
export const GreyButton: React.FC<IBtnBackNav> = ({ onClick, Size, Caminho, isNav, children, Parametro, ...rest }) => {
  const nav = useNavigate()
  function goBack(): void {
    nav(-1)
  }

  if (isNav) {
    return (
    <Btn onClick={goBack} heigth={Size + 'HeigthBtn'} size={Size + 'FontBtn'} padding={Size + 'PaddingBtn'} {...rest}>{children}</Btn>
    )
  }

  return (
    <StandBtn heigth={Size + 'HeigthBtn'} size={Size + 'FontBtn'} padding={Size + 'PaddingBtn'} onClick={onClick} {...rest}>{children}</StandBtn>
  )
}

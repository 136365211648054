import { Flex, Switch, Text } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

export const DashboardSwitch: React.FC = () => {
  const { pathname } = useLocation()
  const nav = useNavigate()
  const corporativo = pathname.includes('Corporativo')
  return (
        <Flex alignItems={'center'} gap={'.5rem'} fontSize={'.875rem'}>
            <Text color={corporativo ? '' : 'var(--Azul)'} fontWeight={'medium'}>Visão individual</Text>
            <Switch
                size={'md'}
                isChecked={corporativo}
                onChange={() => corporativo ? nav(`${pathname.replace('Corporativo', 'Individual')}`) : nav(`${pathname.replace('Individual', 'Corporativo')}`) }
            />
            <Text color={corporativo ? 'var(--Azul)' : ''} fontWeight={'medium'}>Visão corporativa</Text>
        </Flex>
  )
}

import styled, { css } from 'styled-components'

interface iBoxProps{
  margin?: string
  isActive?: boolean
  padding?: string
}

export const Container = styled.div<iBoxProps>`

${props => props.margin && css<iBoxProps>`
    margin: ${props => props.margin};`
}

background: var(--c1);
display: flex;
position: relative;
flex-direction: column;
padding: ${props => props.padding ? props.padding : '1rem'};
position: relative;
width: 100%;
border-radius: .25rem;
box-shadow: var(--SombraBackground);
border-left: ${props => props.isActive ? '.2rem solid var(--Azul)' : ''}
`

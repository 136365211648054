import { useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { InputCmp } from '../../../../../../components/InputCmp'
import { SelectCmp } from '../../../../../../components/SelectCmp'
import { IDepartment } from '../../../../../../interfaces'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface ICreateDepartmentModalProps {
  isOpen: boolean
  departmentList: IDepartment[]
  onRequestClose: () => void
  onCreateDepartment: (newDepartment: IDepartment) => void
}

export const CreateDepartmentModal: React.FC<ICreateDepartmentModalProps> = ({
  isOpen,
  departmentList,
  onRequestClose,
  onCreateDepartment
}) => {
  const [Nome, setNome] = useState('')
  const [IdPai, setIdPai] = useState<string>()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h3>Novo departamento</h3>
        <ModalBody>
          <InputCmp
            label='Nome da área'
            OnChange={setNome}
            placeholder='Digite um nome'
          />

          <SelectCmp title='Área superior' OnChange={setIdPai}>
            <option value={undefined}>Selecione um</option>
            {
              departmentList.map((e: IDepartment, i) => {
                return (
                  <option value={e.id} key={i}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </ModalBody>
        <ModalFooter>
          <ButtonCmp VarColor='c6' onClick={onRequestClose}>Fechar</ButtonCmp>
          <ButtonCmp VarColor='Green2' onClick={() => onCreateDepartment({ id: '', nome: Nome, departamentoPaiId: IdPai })}>Salvar</ButtonCmp>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

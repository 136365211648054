/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Alert, AlertDescription, Flex, FormControl, Input, useToast, Link, FormLabel, Switch, Button, Img } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { appApi } from '../../services/appApi'
import { ValidaCPF, ValidarEmail } from '../../Utils/Helper'
import { Container, StyledSpan } from './styles'
import LogoSah from '../../assets/LogoSah.png'
import FrontPageSah from '../../assets/FrontPageSah.png'
import FrontPageResponsivo from '../../assets/FrontPageResponsivo.png'

interface iLogin {
  emailCPF: string
  password: string
}

export const Login: React.FC = () => {
  const { isAuth, obterVersao } = useAuth()
  const toast = useToast()
  const nav = useNavigate()
  const [IsLoading, setIsLoading] = useState(false)
  const { register, handleSubmit } = useForm()

  const [Erros, setErrors] = useState<number[]>([])

  const [CPF, setCPF] = useState(false)

  function Login(form: any): void {
    const data: iLogin = form
    setErrors([])

    if (CPF) {
      if (!ValidaCPF(data.emailCPF)) {
        setErrors(oldArray => [...oldArray, 0])
        return
      }
    } else {
      if (!ValidarEmail(data.emailCPF)) {
        setErrors(oldArray => [...oldArray, 0])
        return
      }
    }
    setIsLoading(true)

    appApi.post('Auth/login', {
      emailCPF: CPF ? data.emailCPF.replace('.', '').replace(',', '').replace('-', '') : data.emailCPF,
      password: data.password
    })
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('logo', res.data.logo)
          appApi.defaults.headers.common.Authorization = `bearer ${res.data.token as string}`
          nav('/Colaborador')
        }
      })
      .catch(() => {
        toast({
          title: 'Credenciais inválidas',
          status: 'error',
          isClosable: false,
          position: 'bottom',
          duration: 4000
        })
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (isAuth()) {
      nav('/Colaborador')
    } else {
      obterVersao()
    }
  }, [])

  return (
    <Flex>
      <Container>
        <Flex flexDirection={'column'} minW={'370px'}>
          <Flex marginBottom={'2rem'} justifyContent={'center'}>
            <img src={LogoSah} width={'260px'} />
          </Flex>

          <Flex justifyContent={'space-between'} alignItems={'end'} mb={'.75rem'}>
            <Flex flexDir={'column'} w={'250px'}>
              <h3>Olá,</h3>
              <h3 style={{ fontWeight: 400, width: '250px' }}>Faça o seu login!</h3>
            </Flex>
            <FormControl display='flex' alignItems='center' justifyContent={'end'}>
              <FormLabel fontSize={'.875rem'} mr={'.5rem'} mb={0} htmlFor='email-alerts' >
                Utilizar CPF?
              </FormLabel>
              <Switch onChange={(e) => setCPF(e.target.checked)} />
            </FormControl>
          </Flex>

          <Flex marginBottom={'2rem'} flexDirection={'column'}>
            {
              Erros.length > 0 && (
                <Alert marginBottom={'.75rem'} borderRadius={'.25rem'} status='error' fontSize={'.875rem'} py={'.25rem'}>
                  <AlertDescription fontWeight={'bold'}>Campos inválidos</AlertDescription>
                </Alert>
              )
            }

            <form onSubmit={handleSubmit(Login)}>
              <FormControl marginBottom={'.75rem'} isInvalid={Erros.includes(0)} >
                <Input
                  isDisabled={IsLoading}
                  borderColor={'var(--Gray4)'}
                  {...register('emailCPF')}
                  placeholder={CPF ? 'CPF' : 'Email'}
                  borderRadius={'.25rem'}
                  fontSize={'.875rem'}
                  height={'2.125rem'}
                  size={'sm'}
                  id='lb-1'
                />
              </FormControl>

              <FormControl marginBottom={'.75rem'} isInvalid={Erros.includes(1)} >
                <Input
                  isDisabled={IsLoading}
                  borderColor={'var(--Gray4)'}
                  type={'password'}
                  {...register('password')}
                  placeholder='Senha'
                  borderRadius={'.25rem'}
                  fontSize={'.875rem'}
                  height={'2.125rem'}
                  size={'sm'}
                  id='lb-2'
                />
              </FormControl>

              <Flex flexDirection={'column'} marginTop={'1rem'}>
                <Flex justifyContent={'center'} marginBottom={'.5rem'}>
                  <StyledSpan onClick={() => nav('/Usuario/EsqueciSenha')}>Não sei minha senha</StyledSpan>
                </Flex>

                <Flex flexDirection={'column'} marginBottom={'.5rem'}>
                  <Button
                    type='submit'
                    size='sm'
                    borderRadius={'.25rem'}
                    fontSize={'.875rem'}
                    isLoading={IsLoading}
                    _hover={{ bg: 'var(--DegradeAzul)' }}
                    className={'button-trasition gradient-blue'}
                  >Entrar</Button>
                </Flex>

                <Flex justifyContent={'center'} mt={'1rem'} marginBottom={'.5rem'}>
                  <Link color={'var(--c7)'} onClick={() => nav('/Privacidade')}>Política de privacidade</Link>
                </Flex>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Container>
      <Flex className='d-none-login' w={'35%'} bg={'var(--Roxo)'} justifyContent={'start'}>
        <Img
          src={FrontPageResponsivo}
          style={{ width: '100%', maxWidth: '600px', height: '100vh', objectFit: 'cover' }}
        />
      </Flex>
    </Flex>
  )
}

import { useState } from 'react'
import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { appApi } from '../../services/appApi'
import { Body } from '../Layouts/Body'

export const Beta: React.FC = () => {
  const [Response, setResponse] = useState<string>()
  const [IsLoading, setIsLoading] = useState(false)
  const [Nome, setNome] = useState('')
  const [Definicao, setDefinicao] = useState('')
  function Execute(): void {
    setIsLoading(true)
    appApi.post('AssistentePDI', [{ nome: Nome, definicao: Definicao }])
      .then(e => {
        setResponse(e.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }
  return (
        <Body>
            <Flex gap={'1rem'} flexDir={'column'}>
                <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input value={Nome} onChange={(e) => setNome(e.target.value)} borderColor={'black'} />
                </FormControl>

                <FormControl>
                    <FormLabel>Definicão</FormLabel>
                    <Input value={Definicao} onChange={(e) => setDefinicao(e.target.value)} borderColor={'black'}/>
                </FormControl>

                <Flex>
                    <Button isLoading={IsLoading} bg={'var(--Rosa)'} onClick={Execute}>Enviar</Button>
                </Flex>
            </Flex>
            <Flex mt={'1rem'}>
            <span dangerouslySetInnerHTML={{ __html: Response?.replaceAll('\n', '<br/>') ?? '' }}></span>
            </Flex>
        </Body>
  )
}

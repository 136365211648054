/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable array-callback-return */
import { Avatar, Flex, FormLabel, Grid, Radio, RadioGroup, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaTrash, FaBriefcase, FaSave } from 'react-icons/fa'
import { GoOrganization } from 'react-icons/go'
import { MdGroup, MdManageAccounts } from 'react-icons/md'
import { iIndicador } from '../..'
import { AddBox } from '../../../../../components/AddBox'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { CheckboxCmp } from '../../../../../components/CheckboxCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { FindUser } from '../../../../../components/FindBox'
import { InputCmp } from '../../../../../components/InputCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { iColaboradorBase, iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { GetDataFromMonthDate, PropInvalida } from '../../../../../Utils/Helper'

export interface iIndicadorInput {
  nome?: string
  meta?: number
  tipoMeta: number
  medidaMeta: string
  dataInicio?: string
  prazo?: string
  departamentoId?: string
  cargoId?: string
  responsavelId?: string
  outrosIds?: string[]
  tipoCriacao?: number
  metaInversa?: boolean
  tabelaADM?: boolean
}

interface iModalProps {
  isOpen: boolean
  onClose: () => void
  onUpdate?: () => void
  onConfirm: (periodo: iIndicadorInput) => void
  indicadorEdit?: iIndicador
}

export const ModalIndicador: React.FC<iModalProps> = ({ isOpen, onConfirm, onClose, indicadorEdit, onUpdate }) => {
  const toast = useToast()
  const [Model, setModel] = useState<iIndicadorInput>({ medidaMeta: '', tipoMeta: 0 })
  const [IsDataAtual, setIsDataAtual] = useState(false)
  const [SemPrazo, setSemPrazo] = useState(false)
  const [Step, setStep] = useState(0)
  const [Colabs, setColabs] = useState<iColaboradorBase[]>([])
  const [Gestores, setGestores] = useState<iColaboradorBase[]>([])
  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [TipoCriacao, setTipoCriacao] = useState(0)
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [CargoIsInvalid, setCargoIsInvalid] = useState(false)
  const [DepartamentoIsInvalid, setDepartamentoIsInvalid] = useState(false)

  function GetColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => {
        setColabs(res.data)
      })
      .catch(err => console.log(err))
  }

  function getGestores(): void {
    appApi.get('Colaborador/Select/Gestor')
      .then(res => {
        setGestores(res.data)
      })
      .catch(err => console.log(err))
  }

  function getDepartamentos(): void {
    appApi.get('Departamento')
      .then(res => {
        setDepartamentos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getCargos(): void {
    appApi.get('Cargo')
      .then(res => {
        setCargos(res.data)
      })
      .catch(err => console.log(err))
  }

  function onEdit(): void {
    if (!OnCheckIndicador(true)) {
      return
    }

    appApi.patch(`Indicador/${indicadorEdit?.id}`, Model)
      .then(res => {
        if (onUpdate) onUpdate()
        onClose()
        setModel({ medidaMeta: '', tipoMeta: 0 })
        setStep(0)
        setIsDataAtual(false)
        setSemPrazo(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetColaboradores()
    getCargos()
    getGestores()
    getDepartamentos()
  }, [])

  useEffect(() => {
    if (indicadorEdit) {
      setModel({ ...indicadorEdit, dataInicio: indicadorEdit.dataInicio, prazo: indicadorEdit.prazo })
    } else {
      setModel({ medidaMeta: '', tipoMeta: 0 })
    }
  }, [indicadorEdit])

  function OnCheckIndicador(isUpdate?: boolean): boolean {
    if (!Model.nome || Model.nome.length < 5) {
      toast({
        title: 'O campo de nome precisa ter no mínimo 5 caracteres',
        status: 'error',
        duration: 3000,
        position: 'top'
      })
      return false
    }

    if (!Model.meta) {
      toast({
        title: 'Preencha o campo de meta',
        status: 'error',
        duration: 3000,
        position: 'top'
      })
      return false
    }

    if (Model.medidaMeta === '') {
      toast({
        title: 'Preencha o campo com a unidade de medida da sua meta',
        status: 'error',
        duration: 3000,
        position: 'top'
      })
      return false
    }

    if (!Model.dataInicio) {
      toast({
        title: 'Preencha o campo da data de início ou selecione a opção de iniciar seu indicador a partir deste mês',
        status: 'error',
        duration: 3000,
        position: 'top'
      })
      return false
    }

    if (!isUpdate && !Model.prazo && !SemPrazo) {
      toast({
        title: 'Preencha o campo de prazo ou selecione a opção para não definir o prazo de conclusão',
        status: 'error',
        duration: 3000,
        position: 'top'
      })
      return false
    }

    if (Model.prazo && new Date(Model.prazo ?? '') < new Date(Model.dataInicio ?? '')) {
      toast({
        title: 'O prazo para conclusão deve ser posterior a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return false
    }

    return true
  }

  function Clean(): void {
    if (!OnCheckIndicador()) {
      return
    }

    setStep(Step + 1)
  }

  function Submit(): void {
    const response = Model
    if (TipoCriacao === 0) {
      response.responsavelId = undefined
      response.outrosIds = undefined
      response.departamentoId = undefined
      response.cargoId = undefined
    }

    if (TipoCriacao === 1) {
      if (!response.responsavelId) {
        toast({
          title: 'Escolha um gestor para ser o responsável desse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      }
      response.outrosIds = undefined
      response.departamentoId = undefined
      response.cargoId = undefined
    }

    if (TipoCriacao === 2) {
      if (!response.responsavelId) {
        toast({
          title: 'Escolha um responsável para esse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      } else if (!response.outrosIds || response.outrosIds.length === 0) {
        toast({
          title: 'Selecione pelo menos uma pessoa para fazer parte do grupo avaliado nesse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      }
      response.departamentoId = undefined
      response.cargoId = undefined
    }

    if (TipoCriacao === 3) {
      if (!response.responsavelId) {
        toast({
          title: 'Escolha um responsável para esse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      } else if (!response.departamentoId) {
        toast({
          title: 'Escolha o departamento que participará desse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      }
      response.outrosIds = undefined
      response.cargoId = undefined
    }

    if (TipoCriacao === 4) {
      if (!response.responsavelId) {
        toast({
          title: 'Escolha um responsável para esse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      } else if (!response.cargoId) {
        toast({
          title: 'Escolha o cargo que participará desse indicador',
          status: 'error',
          duration: 3000,
          position: 'top'
        })
        return
      }
      response.outrosIds = undefined
      response.departamentoId = undefined
    }

    setModel({ medidaMeta: '', tipoMeta: 0 })
    setStep(0)
    setIsDataAtual(false)
    setSemPrazo(false)
    onConfirm({ ...response, TipoCriacao: TipoCriacao, dataInicio: GetDataFromMonthDate(Model.dataInicio), prazo: Model.prazo ? GetDataFromMonthDate(Model.prazo) : undefined } as iIndicadorInput)
  }

  return (
    <ModalBase
      Titulo={indicadorEdit ? 'Editar indicador' : 'Crie um novo indicador para sua empresa'}
      isOpen={isOpen}
      onClose={onClose}
      Width='60rem'
    >
      {Step === 0 && <Flex p={'.5rem 1rem 1.25rem 1rem'} gap={'.75rem'} alignItems='center' flexDir={'column'} justifyContent={'center'}>
        <Flex gap={'1rem'} w='100%'>
          <InputCmp
            label='Nome'
            placeholder='Digite o nome'
            value={Model.nome}
            OnChange={(e) => setModel({ ...Model, nome: e })}
          />
          <InputCmp
            type={'number'}
            label='Meta'
            value={Model.meta}
            OnChange={e => setModel({ ...Model, meta: parseFloat(e) })}
            placeholder='Digite o valor da sua meta'
          />
        </Flex>
        <Flex gap={'1rem'} w='100%'>
          <Flex flexDir={'column'} gap={'.325rem'} w='100%'>
            <InputCmp
              label='Data de início'
              type={'month'}
              value={Model.dataInicio?.slice(0, 7) ?? ''}
              OnChange={(e) => setModel({ ...Model, dataInicio: e })}
              isDisabled={IsDataAtual || !!indicadorEdit}
            />
            <CheckboxCmp isChecked={IsDataAtual} isDisabled={!!indicadorEdit} OnChange={(e) => { if (e) { setModel({ ...Model, dataInicio: new Date().toISOString().slice(0, 7) }) }; setIsDataAtual(e) }}>Iniciar a partir desse mês</CheckboxCmp>
          </Flex>
          <Flex flexDir={'column'} gap={'.325rem'} w='100%'>
            <InputCmp
              type={'month'}
              label='Prazo para conclusão'
              value={Model.prazo?.slice(0, 7) ?? ''}
              OnChange={(e) => setModel({ ...Model, prazo: e })}
              isDisabled={SemPrazo || !!indicadorEdit}
            />
            <CheckboxCmp isChecked={SemPrazo} isDisabled={!!indicadorEdit} OnChange={(e) => { if (e) { setModel({ ...Model, prazo: undefined }) }; setSemPrazo(e) }}>Não definir prazo de conclusão</CheckboxCmp>
          </Flex>
        </Flex>
        <Flex w='100%' gap={'1rem'} alignItems={'end'} mt={'.325rem'}>
          <Flex flexDir={'column'}>
            <FormLabel mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} wordBreak={'keep-all'}>Selecione o tipo e a unidade de medida da meta</FormLabel>
            <RadioGroup marginLeft={'.5rem'} w={'100%'} value={Model.tipoMeta.toString()} onChange={(e) => setModel({ ...Model, tipoMeta: parseInt(e), medidaMeta: e === '5' ? '%' : '', metaInversa: (e !== '5' && e !== '0') ? undefined : Model.metaInversa })} size={'sm'} pb='.5rem'>
              <Stack direction={'row'} spacing={2}>
                <Radio value={'0'}>Dinheiro</Radio>
                <Radio value={'5'}>Porcentagem</Radio>
                <Radio value={'1'}>Tempo</Radio>
                <Radio value={'2'}>Peso</Radio>
                <Radio value={'3'}>Distância</Radio>
                <Radio value={'4'}>Outro</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
          {Model.tipoMeta === 0 && <SelectCmp w={'100%'} value={Model.medidaMeta} OnChange={(e) => setModel({ ...Model, medidaMeta: e })}>
            <option value={''}>Selecione uma opção</option>
            <option value={'R$'}>Real (R$)</option>
            <option value={'$'}>Dólar ($)</option>
            <option value={'€'}>Euro (€)</option>
            <option value={'£'}>Libra (£)</option>
          </SelectCmp>}
          {Model.tipoMeta === 1 && <SelectCmp w={'100%'} value={Model.medidaMeta} OnChange={(e) => setModel({ ...Model, medidaMeta: e })}>
            <option value={''}>Selecione uma opção</option>
            <option value={'s'}>Segundos</option>
            <option value={'min'}>Minutos</option>
            <option value={'h'}>Horas</option>
            <option value={'dia'}>Dias</option>
            <option value={'sem'}>Semanas</option>
            <option value={'meses'}>Meses</option>
            <option value={'anos'}>Anos</option>
          </SelectCmp>}
          {Model.tipoMeta === 2 && <SelectCmp w={'100%'} value={Model.medidaMeta} OnChange={(e) => setModel({ ...Model, medidaMeta: e })}>
            <option value={''}>Selecione uma opção</option>
            <option value={'g'}>Gramas</option>
            <option value={'kg'}>Quilogramas</option>
            <option value={'t'}>Toneladas</option>
          </SelectCmp>}
          {Model.tipoMeta === 3 && <SelectCmp w={'100%'} value={Model.medidaMeta} OnChange={(e) => setModel({ ...Model, medidaMeta: e })}>
            <option value={''}>Selecione uma opção</option>
            <option value={'m'}>Metros</option>
            <option value={'km'}>Quilômetros</option>
            <option value={'mi'}>Milhas</option>
          </SelectCmp>}
          {Model.tipoMeta === 4 && <InputCmp value={Model.medidaMeta} placeholder='Digite a unidade de medida da sua meta, de forma abreviada' OnChange={(e) => setModel({ ...Model, medidaMeta: e })}/>}
          {Model.tipoMeta === 5 && <InputCmp value={Model.medidaMeta} isDisabled OnChange={(e) => {}}/>}
        </Flex>
        {!indicadorEdit && (Model.tipoMeta === 5) &&
          <Flex w='100%' gap={'1rem'} mt={'.325rem'}>
            <CheckboxCmp isChecked={Model.tabelaADM} OnChange={(e) => setModel({ ...Model, tabelaADM: e })}>Marque esta opção caso queira habilitar uma tabela exclusiva para os responsáveis adicionarem na forma de dinheiro os dados que serão convertidos em porcentagem</CheckboxCmp>
          </Flex>
        }
        {(Model.tipoMeta === 0 || Model.tipoMeta === 5) &&
          <Flex w='100%' gap={'1rem'} mt={'.325rem'}>
            <CheckboxCmp isChecked={Model.metaInversa} OnChange={(e) => setModel({ ...Model, metaInversa: e })}>Marque esta opção se o indicador for inversamente proporcional à meta, onde menores valores indicam maior progresso</CheckboxCmp>
          </Flex>
        }
      </Flex>}
      {Step === 1 && <Flex p={'1rem 1rem 1rem 1rem'} gap={'1rem'} alignItems='center' justifyContent={'center'}>
        <Tabs tabIndex={TipoCriacao} onChange={(e) => { setTipoCriacao(e); setModel({ ...Model, departamentoId: undefined, cargoId: undefined, outrosIds: undefined, responsavelId: undefined }) }}>
          <TabList gap={'.5rem'} border={'none'}>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><GoOrganization /> Geral para empresa</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><MdManageAccounts /> Selecionar por gestor</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><BsFillPersonFill /> Selecionar por pessoa</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><MdGroup /> Selecionar para departamento</Tab>
            <Tab gap={'.325rem'} px={'.5rem'} fontSize={'.875rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.25rem'} bgColor={'var(--Azul)'}><MdGroup />Selecionar para cargo</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={'0'} pt={'1.25rem'} pb={'.5rem'}>
              <Flex alignItems={'center'} justifyContent='center' fontWeight={600} fontSize={'14px'}>
                Esse indicador não sera direcionado à nenhum grupo específico de colaboradores e poderá ser gerenciado apenas pelo RH e por quem estiver listado nas configurações da empresa.
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} pt={'1.25rem'} pb={'.5rem'}>
              <Flex gap={'.5rem'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'.25rem'}>
                  <h4>Gestor responsável por este indicador</h4>
                  <FindUser
                    lista={Gestores}
                    onChoice={(e) => setModel({ ...Model, responsavelId: e })}
                    placeholder='Digite o nome do gestor'
                    selecionado={Model.responsavelId}
                  />
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} pt={'1.25rem'} pb={'.5rem'}>
              <Flex gap={'.5rem'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'.75rem'}>
                  <Flex gap={'.5rem'} flexDir={'column'}>
                    <Flex flexDir={'column'} gap={'.25rem'}>
                      <h4>Responsável por este indicador</h4>
                      <FindUser
                        lista={Gestores.filter(e => !Model.outrosIds?.some(a => e.id === a))}
                        onChoice={(e) => setModel({ ...Model, responsavelId: e })}
                        placeholder='Digite o nome do gestor'
                        selecionado={Model.responsavelId}
                      />
                    </Flex>
                  </Flex>
                  <h4>Defina o grupo de pessoas que vão compor esse indicador</h4>
                  <AddBox
                    lista={Colabs.filter(e => e.id !== Model.responsavelId)}
                    onChoice={(e) => { setModel({ ...Model, outrosIds: [...Model.outrosIds ?? [], e] }) }}
                    selecionados={Model.outrosIds ?? []}
                    placeholder='Digite o nome do colaborador'
                    messageSucess='Colaborador selecionado com sucesso'
                  />
                  <Grid gridTemplateColumns={'repeat(3, 1fr)'} gap={'.75rem'} my={'.75rem'}>
                    {Colabs.filter(e => { if (Model.outrosIds?.includes(e.id)) { return e } }).map(e => {
                      return (
                        <Flex bg={'var(--c2)'} px={'1rem'} py={'.75rem'} fontSize={'.875rem'} gap={'.5rem'}>
                            <Avatar src={e.avatar} w={'2rem'} h={'2rem'}/>
                            <Flex flexDir={'column'} justifyContent={'center'} gap={'.2rem'}>
                              <Text lineHeight='1rem' fontWeight={'700'}>{e.nome}</Text>
                              <Text lineHeight='.875rem' fontWeight={'400'} fontSize={'.813rem'}>{e.nomeDepartamento}</Text>
                            </Flex>
                            <Flex flex={'1'} justifyContent={'end'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}>
                              <ElementoTooltipCmp bg={'var(--Red)'} label={'Remover colaborador'}>
                                <FaTrash cursor={'pointer'} onClick={() => { setModel({ ...Model, outrosIds: Model.outrosIds?.filter(a => { if (a !== e.id) { return a } }) }) }} />
                              </ElementoTooltipCmp>
                            </Flex>
                        </Flex>
                      )
                    })}
                  </Grid>
                </Flex>
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} pt={'1.25rem'} pb={'.5rem'}>
              <Flex gap={'.5rem'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'.25rem'}>
                  <h4>Responsável por este indicador</h4>
                  <FindUser
                    lista={Gestores}
                    onChoice={(e) => setModel({ ...Model, responsavelId: e })}
                    placeholder='Digite o nome do gestor'
                    selecionado={Model.responsavelId}
                  />
                </Flex>
              </Flex>
              <Flex flexDir={'column'} w={'full'} mt={'1rem'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Área</FormLabel>
                <FindUser
                  lista={Departamentos}
                  selecionado={Model.departamentoId}
                  onChoice={(e) => setModel({ ...Model, departamentoId: e })}
                  placeholder='Digite o nome da área (Obrigatório)'
                  isNotUser
                  leftIcon={<FaBriefcase size={'1.3rem'} />}
                  isInvalid={DepartamentoIsInvalid}
                  onBlur={() => {
                    if (PropInvalida(Model.departamentoId)) {
                      setDepartamentoIsInvalid(true)
                    } else {
                      setDepartamentoIsInvalid(false)
                    }
                  }}
                />
                {DepartamentoIsInvalid &&
                  <Flex mt={'.125rem'} alignItems={'center'} gap='.5rem'>
                    <Text color={'var(--Red)'} fontSize={'.813rem'}>Campo inválido!</Text>
                  </Flex>
                }
              </Flex>
            </TabPanel>
            <TabPanel p={'0'} pt={'1.25rem'} pb={'.5rem'}>
              <Flex gap={'.5rem'} flexDir={'column'}>
                <Flex flexDir={'column'} gap={'.25rem'}>
                  <h4>Responsável por este indicador</h4>
                  <FindUser
                    lista={Gestores}
                    onChoice={(e) => setModel({ ...Model, responsavelId: e })}
                    placeholder='Digite o nome do gestor'
                    selecionado={Model.responsavelId}
                  />
                </Flex>
              </Flex>
              <Flex flexDir={'column'} w={'full'} mt={'1rem'}>
                <FormLabel color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'}>Cargo</FormLabel>
                <FindUser
                  lista={Cargos}
                  onChoice={(e) => setModel({ ...Model, cargoId: e })}
                  placeholder='Digite o nome da função (Obrigatório)'
                  isNotUser
                  leftIcon={<FaBriefcase size={'1.3rem'} />}
                  isInvalid={CargoIsInvalid}
                  selecionado={Model.cargoId}
                  onBlur={() => {
                    if (PropInvalida(Model.cargoId)) {
                      setCargoIsInvalid(true)
                    } else {
                      setCargoIsInvalid(false)
                    }
                  }}
                />
                {CargoIsInvalid &&
                  <Flex mt={'.125rem'} alignItems={'center'} gap='.5rem'>
                    <Text color={'var(--Red)'} fontSize={'.813rem'}>Campo inválido!</Text>
                  </Flex>
                }
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>}
      <Flex padding={'0 1rem 1rem 0'} justifyContent={'end'} gap={'.5rem'}>
        <ButtonCmp VarColor='c6' onClick={() => { if (Step === 0) { onClose() } else { setStep(Step - 1) } }}>{Step === 0 ? 'Cancelar' : 'Voltar'}</ButtonCmp>
        {!indicadorEdit && <>
          {Step === 0 && <ButtonCmp VarColor='Green2' onClick={Clean}>Próximo passo</ButtonCmp>}
          {Step === 1 && <ButtonCmp VarColor='Green2' onClick={Submit}>Criar</ButtonCmp>}
        </>}
        {indicadorEdit && <ButtonCmp VarColor='Green2' onClick={onEdit} leftIcon={<FaSave/>}>Salvar alterações</ButtonCmp>}
      </Flex>
    </ModalBase>
  )
}

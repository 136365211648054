import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

interface CardProps{
  border: string
}

export const Container = styled.div<CardProps>`
padding: .5rem;
border-radius: 0.25rem;
display: flex;
flex-direction: column;
position: relative;
justify-content: space-between;
background: var(--c2);
border: 1px solid var(--c4);
box-shadow: var(--SombraBackground);
`

export const ImgText = styled.div`
display: flex;
flex-direction: column;
align-items: center;
    span{
        font-weight: 500;
        font-size: 0.875rem;
        text-align: center;
    }

    img{
        height: 3.75rem;
        width: 3.75rem;
        border-radius: 50%;
        margin-bottom: .5rem;
        background: white;
        border: 2px solid var(--RosaOpaco);
    }
`

export const DropMenu = styled.ul`
list-style-type: none;
background: white;
box-shadow: var(--SombraBackground);
border-radius: .5rem;
position: absolute;
overflow-y: hidden;
max-height: 0rem;
z-index: 100;

li{
    padding: .5rem;
    cursor: pointer;

    svg{
        margin-right:.5rem;
    }
}

li:hover{
    background: var(--c2);
    font-weight: 500;
}

li:last-child{
    margin-bottom:.2rem;
}

li:first-child{
    margin-top:.2rem;
}
`

export const Menu = styled(Column)`
align-items: flex-end;
width: 3rem;
cursor: pointer;
&:hover{
    ul{
        transition: all .4s ease;
        max-height: 7.897rem;
    }
}
`

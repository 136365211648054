/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { FaCheck, FaClipboardList, FaCog, FaComment, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { appApi } from '../../../../../../../services/appApi'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { useNavigate, useParams } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { AdicionarDia, PropInvalida } from '../../../../../../../Utils/Helper'
import { useToast } from '@chakra-ui/react'
interface iCardReuniao {
  etapa: iEtapaColaborador
  isOpen: boolean
  onReload: () => void
}

interface iReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
  feedback?: string
}

export const CardReuniaoOne: React.FC<iCardReuniao> = ({ etapa, isOpen, onReload }) => {
  const toast = useToast()
  const nav = useNavigate()
  const { colaboradorId } = useParams<{ colaboradorId: string }>()

  const [Model, setModel] = useState<iReuniao>()

  function ObterEtapa(): void {
    appApi.get(`ReuniaoOneOld/${etapa.interacoes[0].idExterno ?? ''}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  function FinalizarReuniao(): void {
    appApi.patch(`EtapaColaborador/${colaboradorId ?? ''}/Etapa/${etapa.id}/Concluir`)
      .then(res => console.log(res)).then(() => {
        toast({
          title: 'Reunião finalizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onReload()
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      ObterEtapa()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon style={{ marginBottom: 0 }}>
        <FaUserAlt size={18}/>
        <div>
          <h4>Tipo de reunião</h4>
          <strong>{Model?.tipoReuniao === 0 ? 'Feedback com o gestor' : 'Feedback com o RH'}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={18} style={{ marginTop: !Model?.local ? '0' : '' }}/>
        <div>
          <h4>Local da reunião</h4>
          <strong>{Model?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Model?.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={18} />
            <div>
              <h4>Descrição</h4>
              <small dangerouslySetInnerHTML={{ __html: Model?.descricao ?? '' }}></small>
            </div>
          </FlexIcon>
        )
      }
      {
        (!PropInvalida(Model?.feedback)) && (
          <FlexIcon>
            <FaComment size={18} />
            <div>
              <h4>Comentário</h4>
              <small>{Model?.feedback}</small>
            </div>
          </FlexIcon>
        )
      }

      {
        (etapa.status !== 2 && etapa.status !== 1) && (
          <ButtonCmp
            VarColor='Green2'
            leftIcon={<FaCog />}
            onClick={() => nav(`/Processo/${colaboradorId ?? ''}/ReuniaoOne/${etapa.id}/Agendar`)}
          >Configurar reunião</ButtonCmp>
        )
      }

      {/* {etapa.status !== 2 && new Date().getTime() > new Date(AdicionarDia(Model?.dataInicio ?? '0001-01-01', 0, Model?.duracao)).getTime() && (
        <ButtonCmp
          mt={'1rem'}
          onClick={FinalizarReuniao}
          VarColor='Rosa'
          rightIcon={<FaCheck />}
          fontSize={'14px'}
          h={'2rem'}
        >Finalizar reunião</ButtonCmp>
      )} */}
    </Container>
  )
}

import styled from 'styled-components'

// borderRadius={'12px'} border='1px solid var(--gray200)'

export const Container = styled.table`
border-radius: 4px;
width: 100%;
border: 2px solid var(--c3);
padding: .5rem 0 0 0;
margin-top: 1rem;
border-spacing: 0 .25rem;
font-family: 'Roboto','sans-serif';
thead{
    tr{
        color: var(--Azul);
        font-weight: 600;
        font-size: .875rem;
    }
}

tbody{

    tr:nth-child(odd) {
        background-color: var(--c2);
    }
    
    tr,th{
        border-collapse: collapse;
        padding: .25rem .75rem;
        font-weight: 400;
    }
}
`

import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: .875rem;
  border-bottom: 1px solid var(--c4);
  div {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`

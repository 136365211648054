import {
  View,
  Image,
  Text
} from '@react-pdf/renderer'
import { FormatarCompetencia } from '../../../../../../../../Utils/Helper'
import { iCompetenciaPDI } from '../../../../../../../../interfaces'

interface iProps {
  competencia: iCompetenciaPDI
}
export const Competencia: React.FC<iProps> = ({ competencia }) => {
  const urlDefault = 'https://sigah.blob.core.windows.net/assets/icones/Icones-SISTEMA-01.png'

  return (
        <View>
            <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
            >
                <View
                    style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginRight: 16
                    }}
                >
                    <Image
                        src={competencia.avatar.replace('Competencias-', 'Icones-SISTEMA-').replace('svg', 'png') ?? urlDefault}
                        style={{ width: 50, height: 50 }}
                    />
                    <Text
                        style={{
                          marginTop: 2,
                          width: 90,
                          fontSize: 12,
                          fontWeight: 'semibold',
                          textAlign: 'center'
                        }}
                    >
                        {FormatarCompetencia(competencia.nome)}
                    </Text>
                </View>
                <View style={{ flexDirection: 'column', width: '100%' }}>
                    <Text
                        style={{
                          fontSize: 12,
                          width: '80%',
                          textAlign: 'left',
                          marginTop: 8
                        }}
                    >
                      Descrição: {competencia.descricao}
                    </Text>
                    {competencia.descricao &&
                      <Text style={{ fontSize: 10, marginTop: 5 }}>
                        {competencia.pergunta}
                      </Text>
                    }
                </View>
            </View>
        </View>
  )
}

import styled from 'styled-components'
import { Column, Flex } from '../../../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
padding: 1rem;
width: 592px;
text-align: center;
h2{
    color:var(--secundario5);
    font-size: 1.5rem;
    line-height: 2.5rem;
}
`

export const ModalHeader = styled(Flex)`
align-items:center;
justify-content: space-between;
border-bottom: 1px solid var(--c4);
padding: 1rem;

h2{
    color:var(--a3);
    font-size: 1.5rem;
}

svg{
    color:var(--a2);
    cursor:pointer;
}
`

export const ModalFooter = styled(Flex)`
justify-content: center;
padding: 1rem 0rem;
gap: 1rem;
`

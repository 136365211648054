import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'

export const LogOut: React.FC = () => {
  const nav = useNavigate()
  useEffect(() => {
    localStorage.removeItem('logo')
    localStorage.removeItem('token')
    appApi.defaults.headers.common.Authorization = ''
    nav('/')
  }, [])
  return <></>
}

import { Avatar, Flex, Img, Text } from '@chakra-ui/react'
import { FaCheckCircle, FaClipboardList, FaEye, FaRegTimesCircle } from 'react-icons/fa'
import { Container, ColumnText, RadiusAmarelo } from './styles'
import IAvaliadores from '../../../../../../assets/IAvaliadores.svg'
import { iPesquisa } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { useNavigate } from 'react-router-dom'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'

interface iCardProps {
  pesquisa: iPesquisa
}

export const CardPesquisa: React.FC<iCardProps> = ({ pesquisa }) => {
  const nav = useNavigate()
  return (
        <Container>
            <RadiusAmarelo />
            <Flex width={'100%'} justifyContent={'space-between'} pr='2rem'>
                <Flex alignItems={'center'}>
                    <Avatar w={'2.75rem'} h={'2.75rem'} marginRight='.5rem' name={pesquisa.participante.nome} src={pesquisa.participante.avatar} />
                    <ColumnText>
                        <h4 style={{ width: '10rem', fontSize: '1rem', fontWeight: 700 }}>{pesquisa.participante.nome}</h4>
                        {
                            (pesquisa.participante.complemento !== null) && (
                                <span title={pesquisa.participante.complemento}>{pesquisa.participante.complemento?.substring(0, 18)}</span>
                            )
                        }
                    </ColumnText>
                </Flex>

                <Flex alignItems={'center'}>
                    <Img width={'32px'} height={'32px'} src={IAvaliadores} />
                    <ColumnText>
                        <Text fontSize={'1.125rem'} lineHeight={'1.25rem'} fontWeight={600}>{pesquisa.avaliadores}</Text>
                        <span>Avaliadores</span>
                    </ColumnText>
                </Flex>

                <Flex alignItems={'center'}>
                    <FaClipboardList size={29} style={{ marginRight: '.5rem' }} />
                    <ColumnText>
                        <Text fontSize={'1.125rem'} lineHeight={'1.25rem'} fontWeight={600}>{pesquisa.respostas}</Text>
                        <span>Respostas</span>
                    </ColumnText>
                </Flex>

                {
                    (pesquisa.autoavaliacao !== null || pesquisa.lider !== null) && (
                        <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                            {
                                (pesquisa.autoavaliacao !== null) && (
                                    <Flex pl={'.75rem'} borderLeft={'1px solid var(--Gray4)'} marginBottom={'.3rem'} alignItems='center'>
                                        {(pesquisa.autoavaliacao) && <FaCheckCircle color='var(--Azul)' style={{ marginRight: '.3rem' }} />}
                                        {!pesquisa.autoavaliacao && <FaRegTimesCircle color='var(--Red)' style={{ marginRight: '.3rem' }} />}
                                        <small>Autoavaliação</small>
                                    </Flex>
                                )
                            }
                            {
                                (pesquisa.lider !== null) && (
                                    <Flex pl={'.75rem'} borderLeft={'1px solid var(--Gray4)'} marginBottom={'.3rem'} alignItems='center'>
                                        {pesquisa.lider && <FaCheckCircle color='var(--Azul)' style={{ marginRight: '.3rem' }} />}
                                        {!pesquisa.lider && <FaRegTimesCircle color='var(--Red)' style={{ marginRight: '.3rem' }} />}
                                        <small>Gestor</small>
                                    </Flex>
                                )
                            }
                        </Flex>
                    )
                }

                <Flex alignItems={'center'}>
                    <ElementoTooltipCmp label={pesquisa.resultado ? '' : 'Resultado indisponível'}>
                        <ButtonCmp
                            isDisabled={!pesquisa.resultado}
                            VarColor='Green2'
                            rightIcon={<FaEye/>}
                            onClick={() => nav(`/${!pesquisa.percepcao ? 'Desempenho/AnaliseDesempenho' : 'Jornada/Percepcao'}/${pesquisa.quantitativa ? 'Quantitativa' : 'Indicativa'}/ResultadoIndividual/${pesquisa.avaliacaoId}`)}
                        >Resultados</ButtonCmp>
                    </ElementoTooltipCmp>
                </Flex>
            </Flex>
        </Container>
  )
}

import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'
import { Column } from '../../../../../../styles/styledGlobal'

export const Head = styled(Flex)`
    justify-content: space-between;
    padding: 1rem;
    border-bottom: .1rem solid var(--c4);
    
    span{
        width: 50rem;
        font-weight: 500;
    }

    svg{
        color: var(--a2);
        cursor: pointer;
    }
`

export const Body = styled(Column)`
padding: 0 .75rem 0 .75rem;
`

export const Footer = styled(Flex)`
justify-content: end;
gap: .75rem;
padding: .75rem;
`

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IoMdCloseCircle } from 'react-icons/io'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { iPeriodo } from '../../../gestao'
import { TableDiv } from './styles'

interface iProps {
  periodo: iPeriodo
  isAprovado?: boolean
  // acumuladoAnterior: number
  tipoMeta: number
  onUpdate: (aprovado: boolean) => void
}

export const Tabela: React.FC<iProps> = ({ onUpdate, periodo, isAprovado, tipoMeta }) => {
  const [Periodo, setPeriodo] = useState<iPeriodo>(periodo)
  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]

  function GetMesesDoPeriodoComValor(campo: 'real' | 'previsto', acumulado: boolean): any {
    const response: any[] = [<td>{acumulado ? 'Acumulado' : campo === 'real' ? 'Real' : 'Previsto'}</td>]
    let valorAcumulado: number = 0
    let primeiroMes: boolean = false

    for (let i = 1; i < 13; i++) {
      const mesAtual = Periodo?.meses?.find(e => e.numeroMes === i)
      if (!primeiroMes) primeiroMes = !!mesAtual
      if (mesAtual && !acumulado) {
        const indexMesAtual = Periodo.meses.findIndex(e => e.numeroMes === i)
        response.push(
          <td key={i}>
            <Flex w={'4rem'} justifyContent={'center'} mx={'auto'}>
              {((mesAtual[campo] ?? 0) % 1 === 0 ? (mesAtual[campo] ?? 0).toString() : (mesAtual[campo] ?? 0).toFixed(2)).replace('.', ',')}
            </Flex>
          </td>
        )
      } else if (mesAtual && acumulado && primeiroMes) {
        if (mesAtual?.real) {
          if (tipoMeta === 5) {
            valorAcumulado = parseFloat(mesAtual?.real as any)
          } else {
            valorAcumulado = parseFloat(mesAtual?.real as any) + valorAcumulado
          }
        }
        response.push(
          <td key={i}>
            <Flex w={'5rem'} justifyContent={'center'} mx={'auto'}>
              {(valorAcumulado % 1 === 0 ? valorAcumulado.toString() : valorAcumulado.toFixed(2)).replace('.', ',')}
              {tipoMeta === 5 && '%'}
            </Flex>
          </td>
        )
      } else {
        response.push(
          <td key={i}>
            <Flex fontWeight={700} textAlign={'center'} justifyContent={'center'}>-</Flex>
          </td>
        )
      }
    }

    return response // Retorna os componentes JSX
  }

  useEffect(() => {
    setPeriodo(periodo)
  }, [periodo])

  return (
    <Flex width={'100%'} flexDir={'column'} gap={'1.25rem'}>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <th>Indicadores</th>
              {mesesAbreviados.map(e => {
                return (
                  <th>
                    {e}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            <tr>{GetMesesDoPeriodoComValor('real', false)}</tr>
            <tr>{GetMesesDoPeriodoComValor('previsto', false)}</tr>
            <tr>{GetMesesDoPeriodoComValor('real', true)}</tr>
          </tbody>

        </table>
      </TableDiv>
      <Flex justifyContent={'end'} mb={'1rem'}>
        <Flex gap={'.25rem'} mr={'.325rem'} fontSize={'24px'}>
          <Text fontSize={'14px'} mr={'.5rem'}>Selecione se deseja aprovar ou recusar o período</Text>
          <IoMdCloseCircle onClick={() => onUpdate(false)} cursor={'pointer'} color={isAprovado === false ? 'var(--Red)' : 'var(--c4)'}/> <IoCheckmarkCircle onClick={() => onUpdate(true)} cursor={'pointer'} color={isAprovado === true ? 'var(--Green2)' : 'var(--c4)'}/>
        </Flex>
      </Flex>
    </Flex>
  )
}

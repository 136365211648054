import styled from 'styled-components'
import { Column } from '../../../../../styles/styledGlobal'

export const Container = styled(Column)<{margin?: string}>`
border-radius: 1rem;
background: var(--c2);
padding: 1rem;
margin: ${props => props.margin};
`

export const ValueColumn = styled(Column)`
margin-top: .5rem;
span{
    font-weight: 500;
    color: var(--Preto);
}

h2{
    font-weight: 400;
}
`

import {
  Document,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'

import { Competencia } from './components/Competencia'
import { useState, useEffect } from 'react'
import { iCompetenciaPDI } from '../../../../../../interfaces'
import { GroupBy } from '../../../../../../Utils/Helper'
import { IconeTitulo } from './components/IconeTitulo'
import { iLinhaTabela } from '../../../../../PesquisaPercepcao/ResultadoIndividual'
import { iQuestao, iRespostaXColaborador } from '../../../../Feedback/VisualizarRespostas'
import { FormResposta } from './components/Feedback/FormResposta'
import { Tabela } from './components/Tabela'

export interface iPDFReuniao {
  competencias: iCompetenciaPDI[]
  tabela: iLinhaTabela[]
  respostasFeedback: iRespostaXColaborador[]
  questoesFeedback: iQuestao[]
}

interface iProps {
  model: iPDFReuniao
}

export const RelatorioPDF: React.FC<iProps> = ({ model }) => {
  const [Model, setModel] = useState<iPDFReuniao>({
    competencias: [],
    questoesFeedback: [],
    respostasFeedback: [],
    tabela: []
  })

  const styles = StyleSheet.create({
    DefaultPage: {
      flexDirection: 'column',
      padding: 32
    },
    Titulo: {
      paddingBottom: 75,
      paddingTop: 55,
      fontSize: 20,
      textAlign: 'center'
    },
    T_CompetenciaTrabalhada: {
      fontSize: 16
    },
    table: {
      display: 'flex',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    tableCol: {
      width: `${(100 / 3)}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableHeaderCol: {
      width: `${(100 / 3)}%`,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#66046B'
    },
    tableCell: {
      margin: 'auto',
      fontSize: 10,
      padding: 5
      // fontFamily: 'Roboto',
      // fontWeight: 'bold'
    },
    tableCellCompetencia: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B32'
    },
    tableCellQuestao: {
      fontSize: 10,
      padding: 5,
      // fontFamily: 'Roboto',
      // fontWeight: 'bold',
      backgroundColor: '#66046B15'
    }
  })

  useEffect(() => {
    setModel(model)
  }, [model])

  return (
    <Document>
      <Page fixed style={styles.DefaultPage} wrap>
        <View>
          <Text style={styles.Titulo}>RESULTADO CONSOLIDADO </Text>
          <View style={{ flexDirection: 'column', margin: -24 }}>
            {GroupBy(Model.competencias, (e) => e.status ?? 0).toList().map((grupo, i) => {
              return (
                <View key={i} style={{ flexDirection: 'column', margin: 24 }} wrap={false}>
                  <View style={{ flexDirection: 'row', marginBottom: 8 }}>
                    <IconeTitulo variant={parseInt(grupo.key)} />
                  </View>
                  <View style={{ flexDirection: 'column', margin: -5 }}>
                    {grupo.data.map((e, i2) => {
                      return (
                        <View key={i2} style={{ margin: 5 }}>
                          <Competencia
                            competencia={e}
                          />
                        </View>
                      )
                    })}
                  </View>
                </View>
              )
            })}
          </View>
        </View>
      </Page>

      {Model.tabela.length > 0 &&
        <Page orientation="landscape" style={styles.DefaultPage} wrap>
          <Tabela model={Model.tabela} />
        </Page>
      }

      {Model.questoesFeedback.length > 0 && Model.respostasFeedback.length > 0 &&
        <Page style={styles.DefaultPage} wrap>
          <View
            style={{
              flexDirection: 'column',
              margin: -16
            }}
          >
            {Model.questoesFeedback.map((questao, i) => {
              return (
                <FormResposta
                  key={i}
                  questao={questao}
                  respostas={Model.respostasFeedback}
                />
              )
            })}
          </View>
        </Page>
      }
    </Document>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CardOnboard } from './Components/CardOnboard'
import { InfoBox } from './styles'
import { CardCriarOnboard } from './Components/CardCriarOnboard'
import { useEffect, useState } from 'react'
import { ModalAdicionarEtapa } from './Components/ModalAdicionarEtapa'
import { iCardEtapa, iEtapa, iGrupoTrilha } from '../../../interfaces'
import { appApi, urlApi } from '../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { ModalDelete } from '../../../components/ModalDelete'
import { TagContainer } from '../../../components/TagContainer'
import { Alert, AlertIcon, Flex, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import { parseJwt } from '../../../services/token'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { ObterToken, PropInvalida } from '../../../Utils/Helper'
import { ModalChangeTrilha } from './Components/ModalChangeTrilha'
import { FaFileCsv, FaSearch } from 'react-icons/fa'
import { ModalDuplicarEtapa } from './Components/ModalDuplicarEtapa'
import { iAgendamento, ModalReplicarEtapa } from './Components/ModalReplicarEtapa'
import { SearchCmp } from '../../../components/SearchCmp'

export interface iPutOrdenacao {
  etapaId: string
  tempoInicio?: number
  tempoDuracao?: number
  etapaSuperior?: string
  etapaInferior?: string
  etapaPai?: string
}

type LocationProps = {
  trilhaId: string
}

export const ConfigurarTrilha: React.FC = () => {
  const { trilhaId } = useParams<LocationProps>()

  const toast = useToast()
  const nav = useNavigate()
  const [Search, setSearch] = useState('')
  const [Trilha, setTrilha] = useState<iGrupoTrilha>({
    categoria: 0,
    cor: '',
    id: '',
    nome: '',
    tags: [],
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  })

  const [Etapas, setEtapas] = useState<iCardEtapa[]>([])
  const [TipoEtapa, setTipoEtapa] = useState<number | undefined>(undefined)

  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  const redirectPage = (url: string): any => { window.location.href = url }
  const [ShowModalAddEtapa, setShowModalAddEtapa] = useState(false)
  const [ModalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const [Id, setId] = useState('')
  const [ModalTrilhaIsOpen, setModalTrilhaIsOpen] = useState(false)
  const [Etapa, setEtapa] = useState<iCardEtapa>()
  const [BkpEtapas, setBkpEtapas] = useState<iCardEtapa[]>([])

  const [ModalReplicarEtapaIsOpen, setModalReplicarEtapaIsOpen] = useState(false)

  useEffect(() => {
    if (trilhaId) {
      ObterTrilha()
      ObterEtapas()
    }
  }, [trilhaId])

  function ObterEtapas(): void {
    appApi.get(`Etapa?idTrilha=${trilhaId as string}`)
      .then(response => { setEtapas(response.data) })
      .catch(e => console.log(e))
  }

  function DeletarEtapa(): void {
    appApi.delete(`Etapa/${Id}`)
      .then(() => {
        toast({
          title: 'Etapa deletada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        ObterEtapas()
      })
      .catch(response => console.log(response))
  }

  function CopiarTrilha(id: string): void {
    appApi.post(`GrupoTrilha/Copy/${id}`)
      .then(() => {
        toast({
          title: 'Sucesso!',
          description: 'Trilha duplicada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function ObterTrilha(): void {
    appApi.get(`GrupoTrilha/${trilhaId as string}`)
      .then(response => {
        setTrilha(response.data)
      })
      .catch(err => console.error(err))
  }

  function AtualizarSelecao(tags: string[]): void {
    appApi.put(`GrupoTrilha/${trilhaId as string}/Tags`, tags)
      .then(() => ObterTrilha())
      .catch(err => console.log(err))
  }

  function addTag(tag: string): void {
    const tags = [...Trilha?.tags ?? []]
    tags.push(tag)
    setTrilha({ ...Trilha, tags: tags })
    AtualizarSelecao(tags)
  }

  function removeTag(id: string): void {
    const filter = Trilha?.tags?.filter(r => r !== id)
    setTrilha({ ...Trilha, tags: filter })
    AtualizarSelecao(filter ?? [])
  }

  function closeModalConfirm(): void {
    setModalConfirmIsOpen(false)
    setId('')
  }

  function onOpenModalDelete(id: string): void {
    setModalConfirmIsOpen(true)
    setId(id)
  }

  function onOpenModalReplicar(id: string): void {
    setModalReplicarEtapaIsOpen(true)
    setId(id)
  }

  function onCloseModalReplicar(): void {
    setModalReplicarEtapaIsOpen(false)
    setId('')
  }

  function AtualizarEtapas(etapaSelecionada: iCardEtapa, etapas: iCardEtapa[]): void {
    if (BkpEtapas.length === 0) {
      setBkpEtapas(Etapas)
    }
    setEtapa(etapaSelecionada)
    setEtapas(etapas)
  }

  function SalvarOrdenacao(etapa: iPutOrdenacao): void {
    appApi.put(`Etapa/${trilhaId ?? ''}/AtualizarOrdem`, {
      etapa: etapa,
      listaOrdenada: Etapas.filter(e => e.tipo !== 0).map(e => e.id)
    })
      .then(() => {
        toast({
          title: 'Trilha ordenada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        setModalTrilhaIsOpen(false)
        ObterEtapas()
        setEtapa(undefined)
        setBkpEtapas([])
      })
      .catch(err => console.log(err))
  }

  function CancelarEdicao(): void {
    setEtapas(BkpEtapas)
    setEtapa(undefined)
  }

  function BaixarCsvTrilha(): void {
    redirectPage(`${urlApi}/Etapa/${trilhaId}/EtapasTrilhaCsv?token=${ObterToken()}`)
  }

  function onOpenDuplicarEtapa(etapaId: string, tipo?: number): void {
    setId(etapaId)
    setTipoEtapa(tipo)
    setModalDuplicarIsOpen(true)
  }

  function onCloseDuplicarEtapa(): void {
    setId('')
    setModalDuplicarIsOpen(false)
  }

  function DuplicarEtapa(etapa: iEtapa): void {
    appApi.post(`Etapa/${Id}/Duplicar`, etapa)
      .then(() => {
        ObterEtapas()
        onCloseDuplicarEtapa()
        toast({
          title: 'Etapa duplicada com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch((err) => console.log(err))
  }

  function ReplicarEtapa(etapaBase: iEtapa, lista: string[], agendamento?: iAgendamento): void {
    appApi.post(`ProcessoOnboard/ReplicarEtapa/${Id}`, {
      colaboradores: lista,
      etapa: etapaBase,
      configuracaoInicio: agendamento
    })
      .then(() => {
        toast({
          title: 'Etapa replicada com sucesso!',
          status: 'success',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
        onCloseModalReplicar()
      })
      .catch(() => {
        toast({
          title: 'Erro ao replicar a etapa nos processos ativos!',
          status: 'error',
          duration: 2000,
          isClosable: false,
          position: 'top-right'
        })
        onCloseModalReplicar()
      })
  }

  return (
    <Body>
      {ModalTrilhaIsOpen &&
        <ModalChangeTrilha
          onClose={() => setModalTrilhaIsOpen(false)}
          etapa={Etapa}
          etapas={Etapas}
          onSave={SalvarOrdenacao}
        />
      }
      <ModalDelete
        isOpen={ModalConfirmIsOpen}
        onRequestClose={closeModalConfirm}
        onConfirm={DeletarEtapa}
        message={Etapas.find(e => e.etapaPai === Id) ? 'A etapa que está prestes a excluir tem outras vinculadas a ela, ao excluí-la você excluirá as etapa adicionadas a ela também. Deseja continuar?' : 'Deseja realmente excluir esta etapa?'}
        center
      />

      <ModalAdicionarEtapa
        OnCloseModal={() => { setShowModalAddEtapa(false) }}
        isOpen={ShowModalAddEtapa}
        tipo={Trilha.categoria}
      />

      {ModalDuplicarIsOpen &&
        <ModalDuplicarEtapa
          onClose={onCloseDuplicarEtapa}
          onSave={DuplicarEtapa}
          tipoEtapa={TipoEtapa}
        />
      }

      {ModalReplicarEtapaIsOpen &&
        <ModalReplicarEtapa
          onClose={onCloseModalReplicar}
          etapa={Etapas.find(e => e.id === Id)}
          onConfirm={(etapa, lista, agendamento) => ReplicarEtapa(etapa, lista, agendamento)}
        />
      }
      <Flex mb={'1rem'} justifyContent='space-between' alignItems={'center'}>
          <Flex gap='.5rem' flexDir={'column'} justifyContent={'center'}>
            <h1>{Trilha.categoria === 0 ? 'Trilha de onboarding' : Trilha.categoria === 1 ? 'Trilha de desenvolvimento' : Trilha.categoria === 3 ? 'Offboarding' : ''} - {Trilha?.nome}</h1>
            <ButtonCmp width={'4rem'} VarColor='c6' onClick={() => nav(`/Jornada/Configuracao?tipo=${Trilha.categoria}`)} size='lg'>Voltar</ButtonCmp>
          </Flex>
          <InfoBox theme={Trilha.categoria === 3 ? 'Red' : 'Azul'}>
            <span>{Etapas.length}</span>
            <Text>Etapas adicionadas</Text>
          </InfoBox>
        </Flex>

      {Trilha.padrao && <Alert mb={'1rem'} status='warning' justifyContent={'space-between'}>
        <Flex><AlertIcon />
        Essa é uma trilha padrão para visualização, porém para customiza-la  você deve duplica-la.</Flex>
        <ButtonCmp small onClick={() => CopiarTrilha(Trilha.id)} ml={4} size='sm' VarColor='Roxo'> Clique aqui para duplicar</ButtonCmp>
      </Alert>}

      <WhiteContainer>

        <Flex marginBottom={'2rem'} justifyContent={'space-between'} alignItems={'center'}>
          <Flex>
            <Text fontSize={'1.325rem'} lineHeight={'1.325rem'} fontWeight={700}>Selecione e configure as etapas para personalizar a trilha {Trilha.categoria === 0 ? 'onboarding' : Trilha.categoria === 1 ? 'desenvolvimento' : Trilha.categoria === 3 ? 'offboarding' : ''}</Text>
          </Flex>
          <Flex gap={'.5rem'}>
            <SearchCmp
              width='14rem'
              EnterAction={() => {}}
              OnChange={setSearch}
              placeholder='Busca'
            />
            <ButtonCmp VarColor='Green2' leftIcon={<FaFileCsv />} onClick={BaixarCsvTrilha}>
              Baixar CSV
            </ButtonCmp>
          </Flex>
        </Flex>

        <div>
          {
            Etapas.filter(e => { if (Search === '') { return true } else { return e.nome.includes(Search) } }).map((e: iCardEtapa, i) => {
              return (
                <CardOnboard
                  key={i}
                  etapa={e}
                  selecionada={Etapa}
                  isLast={i === Etapas.length - 1}
                  trilhaPadrao={Trilha.padrao}
                  EtapaAnterior={!PropInvalida(Etapas[i - 1]?.nome) ? Etapas[i - 1].nome : undefined}
                  onDeleteEtapa={onOpenModalDelete}
                  etapas={Etapas}
                  onAtualizarEtapas={AtualizarEtapas}
                  onRestaurar={CancelarEdicao}
                  onSalvar={() => setModalTrilhaIsOpen(true)}
                  onDuplicar={onOpenDuplicarEtapa}
                  onReplicar={onOpenModalReplicar}
                />
              )
            })
          }
          {
            (!Trilha.padrao || parseJwt().uid === '252110c3-295e-4c81-987c-b260b528bdf0') && (
              <CardCriarOnboard
                OnOpenModal={() => setShowModalAddEtapa(true)}
              />
            )
          }
        </div>
      </WhiteContainer>
    </Body>
  )
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Body } from '../../Layouts/Body'
import { Tab, TabList, TabPanel, TabPanels, Tabs, FormControl, Flex, Image, useToast, Text } from '@chakra-ui/react'
import { CardFuncionario } from './Components/CardFuncionario'
import { Box, GridBox, TabHead } from './styles'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaChartBar, FaCheckCircle, FaCog, FaDoorOpen, FaPlane, FaUserClock, FaUserPlus, FaUsers } from 'react-icons/fa'
import { parseJwt } from '../../../services/token'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { PieChart } from './Components/PieChart'
import { ModalAddProcesso } from './Components/ModalAddProcesso'
import { iNomeId, iPostProcessoOnboard } from '../../../interfaces'
import { ModalDelete } from '../../../components/ModalDelete'
import { SelectCmp } from '../../../components/SelectCmp'
import { SearchCmp } from '../../../components/SearchCmp'

interface iTheme {
  textColor: string
  buttonCmpColor: string
}

export interface iGraficoGestaoPesquisa {
  concluidos?: number
  atrasados?: number
  emAndamento?: number
  aguardandoAprovacao?: number
  voluntario?: number
  involuntario?: number
}

export interface iOnboard {
  id: string
  nomeTrilha: string
  andamentoPercentual: number
  status: number
  colaborador: iColaboradorResponse
  dataConclusao?: string
  mediaStakeholders?: number
  mediaAutoAvaliacao?: number
}

export interface iColaboradorResponse {
  id: string
  nome: string
  email: String
  avatar: string
  ativo: boolean
  nomeArea?: string
  urlSelf?: string
  dataDesligamento?: string
}

interface iModel {
  onboardings: iOnboard[]
  graficoEtapa?: iGraficoGestaoPesquisa
  graficoPdi?: iGraficoGestaoPesquisa
  graficoTurnOver?: iGraficoGestaoPesquisa
  filtroArea: iNomeId[]
  filtroTrilha: iNomeId[]
  editarTrilha: boolean
}

interface LoadingStates {
  [key: string]: boolean
}

export const GestaoFuncionarios: React.FC = () => {
  const { search } = useLocation()
  const toast = useToast()
  const tipo = search.split('tipo=')[1] ?? '0'
  const nav = useNavigate()
  const [IsLoading, setIsLoading] = useState(false)

  const [ModalAddProcessoIsopen, setModalAddProcessoIsopen] = useState(false)
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [FiltroTrilha, setFiltroTrilha] = useState<string>()
  const [FiltroDepartamento, setFiltroDepartamento] = useState<string>()
  const [MediaGeral, setMediaGeral] = useState('')
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({})

  const [Model, setModel] = useState<iModel>({
    onboardings: [],
    filtroArea: [],
    filtroTrilha: [],
    editarTrilha: false
  })

  const theme: iTheme = tipo === '0'
    ? {
        buttonCmpColor: 'var(--Blue2)',
        textColor: 'var(--Azul)'
      }
    : tipo === '1'
      ? {
          buttonCmpColor: 'var(--Purple1)',
          textColor: 'var(--Roxo)'
        }
      : tipo === '3'
        ? {
            buttonCmpColor: 'var(--Orange)',
            textColor: 'var(--Rosa)'
          }
        : {
            buttonCmpColor: '',
            textColor: ''
          }

  function get(): void {
    setIsLoading(true)

    appApi.get(`ProcessoOnboard/Gestao?tipo=${tipo}&trilhaId=${FiltroTrilha ?? ''}&departamentoId=${FiltroDepartamento ?? ''}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else {
          setModel({
            onboardings: [],
            filtroArea: [],
            filtroTrilha: [],
            editarTrilha: false
          })
        }

        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function orderByName(isDesc: boolean): void {
    const arr = [...Model.onboardings]
    arr.sort((a, b) => {
      return a.colaborador.nome.toLowerCase() > b.colaborador.nome.toLowerCase() ? 1 : -1
    })
    if (isDesc) arr.reverse()
    setModel({ ...Model, onboardings: arr })
  }

  function Create(form: iPostProcessoOnboard): void {
    appApi.post('ProcessoOnboard', form).then(() => {
      toast({
        title: 'Trilha cadastrada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      })
      nav(`/Rh/VisualizarPerfil/${form.colaboradorId ?? ''}`)
    }).catch(err => console.log(err))
  }

  function ExcluirOnboarding(): void {
    setLoadingStates({ ...loadingStates, [Id ?? '']: true })
    appApi.delete(`ProcessoOnboard/${Id as string}`)
      .then(() => {
        toast({
          title: 'Processo removido com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        get()
      })
      .catch(err => {
        console.log(err)
        setLoadingStates({ ...loadingStates, [Id ?? '']: false })
      })
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    get()
  }, [tipo, FiltroTrilha, FiltroDepartamento])

  useEffect(() => {
    if (tipo !== '1') {
      const data = Model?.onboardings.filter(e => e.mediaStakeholders).map(e => e.mediaStakeholders ?? 0)
      const sum = data.reduce((a, b) => a + b, 0)
      const avg = (sum / data.length) || 0
      setMediaGeral(avg.toFixed(2).replace('.', ','))
    } else {
      setMediaGeral('')
    }
  }, [Model.onboardings])

  return (
    <Body isLoading={IsLoading}>
      {
        (ModalAddProcessoIsopen) && (
          <ModalAddProcesso
            onRequestClose={() => setModalAddProcessoIsopen(false)}
            onCreate={Create}
          />
        )
      }
      <Flex justifyContent={'space-between'} marginBottom='2rem'>
        <Flex gap={'.325rem'} alignItems={'center'}>
          {
            tipo === '0' &&
            <FaUserClock size={32} />
          }

          {
            tipo === '1' &&
            <FaPlane size={32} />
          }

          {
            tipo === '3' &&
            <FaDoorOpen size={32} />
          }
          <h1>{tipo === '0' ? 'Trilha de onboarding' : tipo === '1' ? 'Trilha de desenvolvimento' : tipo === '3' ? 'Offboarding' : ''}</h1>
        </Flex>
        <Flex gap={'.5rem'}>
          {(parseJwt().adm === 'True' && tipo !== '3') &&
            <ButtonCmp
              VarColor='Green2'
              leftIcon={<FaUserPlus />}
              onClick={() => setModalAddProcessoIsopen(true)}
            >Atrelar trilha ao colaborador</ButtonCmp>}
            {Model.editarTrilha &&
              <ButtonCmp
                leftIcon={<FaCog />}
                VarColor='Rosa'
                onClick={() => nav(`/Jornada/Configuracao?tipo=${tipo}`)}>
                  {tipo === '3' ? 'Configurar offboarding' : 'Configurar trilha'}
              </ButtonCmp>
            }
        </Flex>
      </Flex>

      <Flex gap={'1rem'} marginBottom='2rem' width={'40rem'}>
        <FormControl>
          <SelectCmp title={tipo === '3' ? 'Modelo de offboarding' : 'Modelo de trilha'} OnChange={(e) => { setFiltroTrilha(e); setIsLoading(true) }} bg={'white'} borderColor={'var(--Gray4)'}>
            <option value={''}>Todos</option>
            {
              Model.filtroTrilha.map((e, i) => {
                return (
                  <option key={i} value={e.id}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </FormControl>

        <FormControl>
          <SelectCmp title='Área' OnChange={(e) => { setFiltroDepartamento(e); setIsLoading(true) }} bg={'white'} borderColor={'var(--Gray4)'}>
            <option value={''}>Todos</option>
            {
              Model.filtroArea.map((e, i) => {
                return (
                  <option key={i} value={e.id}>{e.nome}</option>
                )
              })
            }
          </SelectCmp>
        </FormControl>
      </Flex>
      <Flex marginBottom={'1rem'} justifyContent={'space-between'} gap='1rem'>
        <Box color={theme.textColor} style={{ flex: '1' }}>
          <Flex gap={'1rem'} alignItems={'center'}>
            {
              (tipo === '0' || tipo === '3') &&
              <FaUsers size={32} />
            }

            {
              tipo === '1' &&
              <FaPlane size={32} />
            }
            <Flex flexDir={'column'}>
              <strong>{Model.onboardings.filter(e => e.status !== 3 && e.status !== 4).length}</strong>
              {
                tipo === '0' &&
                <span>Onboarding ativos</span>
              }

              {
                tipo === '1' &&
                <span>Jornadas ativas</span>
              }

              {
                tipo === '3' &&
                <span>Em offboarding</span>
              }
            </Flex>
          </Flex>
        </Box>

        <Box color={theme.textColor} style={{ flex: '1' }}>
          <Flex gap={'1rem'} alignItems={'center'}>
            <FaCheckCircle size={32} />
            <Flex flexDir={'column'}>
              <strong>{Model.onboardings.filter(e => e.status === 3).length}</strong>
              {
                tipo === '0' &&
                <span>Onboarding concluídos</span>
              }

              {
                tipo === '1' &&
                <span>Jornadas concluídas</span>
              }

              {
                tipo === '3' &&
                <span>Offboarding concluídos</span>
              }
            </Flex>
          </Flex>
        </Box>

        {tipo !== '3' && <Box color={theme.textColor} style={{ flex: '1' }}>
          <Flex gap={'1rem'} alignItems={'center'} flex={'1'}>
            <FaChartBar size={60} />
            <Flex flexDir={'column'}>
              <strong>{MediaGeral || '0'}</strong>
              <span>Nota média atribuida pelos stakeholders</span>
            </Flex>
          </Flex>
        </Box>}

        {/* {tipo === '1' && Model.graficoPdi && !(Model.graficoPdi.atrasados === 0 && Model.graficoPdi.concluidos === 0 && Model.graficoPdi.aguardandoAprovacao === 0 && Model.graficoPdi.emAndamento === 0) && Permissoes.desempenho && !IsLoading && <Box color={theme.textColor} style={{ flex: '1' }}>
          <Flex marginBottom={'.5rem'} justifyContent={'center'}>
            <span>Status PDIs</span>
          </Flex>
          <Flex>
            <PieChart
              id='pdi'
              grafico={Model.graficoPdi}
              variant={'pdi'}
              theme={theme.textColor}
            />
          </Flex>
        </Box>} */}

        {/* {tipo === '3' && !IsLoading && Model.graficoTurnOver && <Box color={theme.textColor} style={{ flex: '1' }}>
          <Flex marginBottom={'.5rem'} justifyContent={'center'}>
            <span>Motivos do desligamento</span>
          </Flex>
          <Flex>
            <PieChart
              id='turnOver'
              grafico={Model.graficoTurnOver}
              variant={'turnOver'}
              theme={theme.textColor}
            />
          </Flex>
        </Box>} */}

        {Model.graficoEtapa && !(Model.graficoEtapa.emAndamento === 0 && Model.graficoEtapa.concluidos === 0 && Model.graficoEtapa.atrasados === 0) && !IsLoading && <Flex flexDir={'column'} bg={'var(--c1)'} gap={'.25rem'} alignItems={'center'} justifyContent={'center'} borderRadius={'.325rem'} border={'1px solid var(--Azul)'} p={'1rem'}>
          <Flex marginBottom={'.5rem'} justifyContent={'center'}>
            <Text fontWeight={700}>Status das etapas</Text>
          </Flex>
          <Flex>
            <PieChart
              id='etapa'
              grafico={Model.graficoEtapa}
              variant={'etapa'}
              theme={theme.textColor}
            />
          </Flex>
        </Flex>}
      </Flex>

      <Tabs isManual variant='enclosed'>
        <TabList>
          <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>{tipo === '3' ? 'Em offboarding' : 'Em andamento'}</Tab>
          <Tab backgroundColor={'white'} color={'var(--c6)'} borderRadius={'.25rem 0 0 0'} _selected={{ color: 'var(--Azul)', borderBottom: '1px solid var(--Azul)' }}>Concluídos</Tab>
        </TabList>
        <TabHead>
          <Flex width={'30%'} marginRight={'1rem'}>
            <FormControl marginRight={'1rem'} >
              <SelectCmp
                title='Ordenar por'
                borderColor={'var(--Gray4)'}
                OnChange={(e) => {
                  if (e === '0') {
                    orderByName(false)
                  } else if (e === '1') {
                    orderByName(true)
                  }
                }}
              >
                <option value={'0'}>Nome A-Z</option>
                <option value={'1'}>Nome Z-A</option>
              </SelectCmp>
            </FormControl>
          </Flex>
          <SearchCmp
            EnterAction={() => {}}
            OnChange={setTxtPesquisa}
            placeholder='Pesquisa'
          />
        </TabHead>
        <TabPanels style={{ background: 'white' }}>
          <TabPanel>

            <GridBox>
              {
                Model.onboardings.filter(e => e.status !== 3 && e.status !== 4).filter((val: iOnboard) => {
                  if (TxtPesquisa === '') {
                    return val
                  } else if (val.colaborador.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                    return val
                  }
                  return ''
                }).map((e: iOnboard, i) => {
                  return (
                    <CardFuncionario
                      key={i}
                      processo={e}
                      color={theme.buttonCmpColor}
                      onReload={get}
                      onOpenModalDelete={onOpenModalDelete}
                      loading={loadingStates[e.colaborador.id ?? '']}
                    />
                  )
                })
              }
            </GridBox>
            {
              (Model.onboardings.filter(e => e.status !== 3 && e.status !== 4).filter((val) => {
                if (TxtPesquisa === '') {
                  return val
                } else if (val.colaborador.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                  return val
                }
                return ''
              }).length === 0 && !IsLoading) && (
                <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                  <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                  {(tipo === '0') && (
                    <h3>Ainda não há processos de onboarding em andamento</h3>)
                  }
                  {(tipo === '1' || tipo === '2') && (
                    <h3>Ainda não há processos de desenvolvimento em andamento</h3>)
                  }
                  {(tipo === '3') && (
                    <h3>Ainda não há processos de offboarding em andamento</h3>)
                  }
                  </Flex>
              )
            }
          </TabPanel>
          <TabPanel >
            <GridBox>
              {
                Model.onboardings.filter((val: iOnboard) => {
                  if (TxtPesquisa === '') {
                    return val
                  } else if (val.colaborador.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                    return val
                  }
                  return ''
                }).filter(e => e.status === 3 || e.status === 4).map((e: iOnboard, i) => {
                  return (
                    <CardFuncionario
                      color={theme.buttonCmpColor}
                      onReload={get}
                      key={i}
                      processo={e}
                      onOpenModalDelete={onOpenModalDelete}
                      loading={loadingStates[e.colaborador.id ?? '']}
                    />
                  )
                })
              }
            </GridBox>

            {
              (Model.onboardings.filter(e => e.status === 3 || e.status === 4).filter((val) => {
                if (TxtPesquisa === '') {
                  return val
                } else if (val.colaborador.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                  return val
                }
                return ''
              }).length === 0 && !IsLoading) && (
                <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                  <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                  {(tipo === '0') && (
                    <h3>Ainda não há processos de onboarding finalizados</h3>)
                  }
                  {(tipo === '1' || tipo === '2') && (
                    <h3>Ainda não há processos de desenvolvimento finalizados</h3>)
                  }
                  {(tipo === '3') && (
                    <h3>Ainda não há processos de offboarding finalizados</h3>)
                  }
                  </Flex>
              )
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esse processo?'
        onRequestClose={onCloseModalDelete}
        onConfirm={ExcluirOnboarding}
        center
      />
    </Body>
  )
}

import { Flex, FormControl, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'
import { InputCmp } from '../../../../../components/InputCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { MultiColaborador } from '../../../../../components/MultiColaborador'
import { SelectCmp } from '../../../../../components/SelectCmp'
import { iCardEtapa, iEtapa, iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { IsEq, PropInvalida } from '../../../../../Utils/Helper'

export interface iAgendamento{
  dataInicio: string
  duracao: number
}

interface iProps {
  onClose: () => void
  onConfirm: (etapa: iEtapa, Selecionados: string[], agendamento?: iAgendamento) => void
  etapa?: iCardEtapa
}

export const ModalReplicarEtapa: React.FC<iProps> = ({ onClose, onConfirm, etapa }) => {
  const agendamentoDefault: iAgendamento = {
    duracao: 0,
    dataInicio: new Date().toISOString().split('T')[0] + 'T00:00:00'
  }

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])

  const [Opcao, setOpcao] = useState(0)

  const [Agendamento, setAgendamento] = useState<iAgendamento>()

  const [Etapa, setEtapa] = useState<iEtapa>({
    tempoInicio: 0,
    tempoDuracao: 0
  })

  const [IsLoading, setIsLoading] = useState(false)

  const isDisabled = Colaboradores.length === 0

  function getColaboradores(): void {
    appApi.get(`ProcessoOnboard/ListarPorIdBaseEtapa?idBase=${etapa?.id}`)
      .then((res) => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function InternalOnConfirm(): void {
    setIsLoading(true)
    onConfirm(Etapa, Selecionados, Agendamento)
  }

  useEffect(() => {
    getColaboradores()
  }, [])

  useEffect(() => {
    if (Opcao === 2 && PropInvalida(Agendamento)) {
      setAgendamento(agendamentoDefault)
    } else {
      setAgendamento(undefined)
    }
  }, [Opcao])

  return (
    <ModalBase
      isOpen
      onClose={onClose}
      Titulo={`Replicar a etapa "${etapa?.nome}" no processo de onboard`}
      Width='45rem'
      Subtitulo='Somente os colaboradores que estiverem nesta trilha e possuírem um processo ativo, excluindo aqueles que não passaram por esta etapa, estarão disponíveis para seleção.'
    >
      <ElementoTooltipCmp
        label={isDisabled ? 'Não foram encontrados processos que atendecem as condições!' : ''}
      >
        <Flex className={isDisabled ? 'disabled' : ''} flexDir={'column'} p='1rem' gap={'2rem'} w='100%'>
          <Flex flexDir={'column'} gap='.75rem'>
            <MultiColaborador
              colaboradores={Colaboradores}
              selecionados={Selecionados}
              onChoice={(e) => setSelecionados([...Selecionados, e])}
              onDelete={(e) => setSelecionados([...Selecionados.filter(r => r !== e)])}
              placeholder='Digite o nome do colaborador'
            />
            <ButtonCmp onClick={() => setSelecionados([...Colaboradores.map(e => e.id)])} w={'fit-content'} VarColor='Rosa'>Selcionar todos</ButtonCmp>
          </Flex>

        <Flex flexDir={'column'} gap='1.5rem'>
          <RadioGroup value={Opcao.toString()} onChange={(e) => setOpcao(parseInt(e))} size={'sm'}>
            <Stack direction={'column'}>
              <Radio value={'0'}>Definir a data da etapa de acordo com o inicio do onboarding.</Radio>
              <Radio value={'1'}>Definir a data da etapa de acordo com uma data pré-definida.</Radio>
              {IsEq(etapa?.tipo, 2, 7, 10) &&
                <Radio value={'2'}>Definir a data, hora e duração da reunião.</Radio>
              }
            </Stack>
          </RadioGroup>

          {Opcao === 0 && (
            <Flex flexDir={'column'} gap={'1rem'}>
              <Flex alignItems={'center'} gap={'1rem'}>
                <FormLabel w={'2rem'} pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>De</FormLabel>
                <InputCmp
                  width={'20rem'}
                  value={Etapa.tempoInicio}
                  type={'number'}
                  OnChange={(e) => setEtapa({ ...Etapa, tempoInicio: parseInt(e) })}
                />
                <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>dias de onboarding</FormLabel>
              </Flex>
              <Flex alignItems={'center'} gap={'1rem'}>
                <FormLabel w={'2rem'} pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>Até</FormLabel>
                <InputCmp
                  width={'20rem'}
                  type={'number'}
                  value={Etapa.tempoDuracao}
                  OnChange={(e) => setEtapa({ ...Etapa, tempoDuracao: parseInt(e) })}
                />
                <FormLabel pos={'relative'} mr={'0'} color={'var(--Gray1)'} fontSize={'14px'} mb={'.25rem'} wordBreak={'keep-all'}>dias de onboarding</FormLabel>
              </Flex>
            </Flex>
          )}

          {Opcao === 1 && (
            <Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
              <FormControl alignItems={'center'} margin={'.2rem 0'}>
                <InputCmp
                  label='Data de início'
                  width={'15rem'}
                  type={'date'}
                  min={new Date().toISOString().slice(0, 16)}
                  max={'2099-12-31T23:59'}
                  value={Etapa?.dataInicio ?? ''}
                  OnChange={(e) => setEtapa({ ...Etapa, dataInicio: e })}
                  isInvalid={false}
                />
              </FormControl>

              <FormControl alignItems={'center'} margin={'.2rem 0'}>
                <InputCmp
                  label='Data de fim'
                  width={'15rem'}
                  type={'date'}
                  value={Etapa?.dataFim ?? ''}
                  max={'2099-12-31T23:59'}
                  OnChange={(e) => setEtapa({ ...Etapa, dataFim: e })}
                  borderColor='var(--Gray4)'
                  isInvalid={false}
                />
              </FormControl>
            </Flex>
          )}

          {Opcao === 2 && (
            <Flex flexDir={'column'} gap={'.5rem'}>
              <FormControl alignItems={'center'} margin={'.2rem 0'}>
                <InputCmp
                  label='Data de início'
                  width={'15rem'}
                  type={'datetime-local'}
                  value={Agendamento?.dataInicio ?? '0001-01-01T00:00:00'}
                  max={'2099-12-31T23:59'}
                  OnChange={(e) => setAgendamento({ ...Agendamento ?? agendamentoDefault, dataInicio: e })}
                />
              </FormControl>

              <FormControl width={'30%'}>
                <SelectCmp
                    title='Duração'
                    value={Agendamento?.duracao.toString()}
                    OnChange={(e) => setAgendamento({ ...Agendamento ?? agendamentoDefault, duracao: parseInt(e) })}
                    borderColor={'var(--Gray4)'}
                  >
                    <option value={'5'}>5 minutos</option>
                    <option value={'10'}>10 minutos</option>
                    <option value={'15'}>15 minutos</option>
                    <option value={'30'}>30 minutos</option>
                    <option value={'60'}>1 hora</option>
                    <option value={'120'}>2 horas</option>
                    <option value={'180'}>3 horas</option>
                    <option value={'240'}>4 horas</option>
                    <option value={'300'}>5 horas</option>
                    <option value={'360'}>6 horas</option>
                    <option value={'420'}>7 horas</option>
                    <option value={'480'}>8 horas</option>
                    <option value={'540'}>9 horas</option>
                    <option value={'600'}>10 horas</option>
                </SelectCmp>
              </FormControl>
            </Flex>
          )}
        </Flex>

        <Flex gap={'.5rem'} justifyContent={'end'}>
          <ButtonCmp
            VarColor='c6'
            onClick={onClose
            }>Cancelar</ButtonCmp>
          <ButtonCmp
            VarColor='Green2'
            onClick={InternalOnConfirm}
            isLoading={IsLoading}
          >Replicar</ButtonCmp>
        </Flex>
        </Flex>
      </ElementoTooltipCmp>
    </ModalBase>
  )
}

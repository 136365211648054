/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useMemo } from 'react'
import ApexCharts from 'apexcharts'

interface iChartProps{
  id: string
  concluidos: number
  emAndamento: number
  atrasados: number
  emAprovacao: number
}

export const PieChart: React.FC<iChartProps> = ({ id, atrasados, emAndamento, concluidos, emAprovacao }) => {
  const series = useMemo(
    () =>
      [concluidos, emAndamento, atrasados, emAprovacao],
    [concluidos, emAndamento, atrasados, emAprovacao]
  )

  function GerarGrafico(): void {
    const options = {
      series: series,
      labels: [`Concluídos - ${concluidos}`, `Em andamento - ${emAndamento}`, `Atrasados - ${atrasados}`, `Em aprovação - ${emAprovacao}`],
      colors: ['#27AE60', '#FFBF2A', '#EB5757', '#1478eb'],
      chart: {
        type: 'donut',
        height: 330,
        width: 330
      },
      legend: {
        enabled: true,
        position: 'left'
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: any) {
            return ((val / (concluidos + emAndamento + atrasados + emAprovacao)) * 100).toFixed(0) + '%'
          }
        }
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'end',
        formatter: function (val: string) {
          return parseFloat(val).toFixed(1) + '%'
        },
        style: {
          fontSize: 12,
          fontWeight: 600
        },
        dropShadow: {
          enabled: true,
          blur: 1,
          color: '#000',
          opacity: 1
        }
      },
      // plotOptions: {
      //   pie: {
      //     donut: {
      //       labels: {
      //         show: true,
      //         value: {
      //           show: true,
      //           fontSize: '22px',
      //           fontFamily: 'Helvetica, Arial, sans-serif',
      //           fontWeight: 600,
      //           offsetY: -8
      //         },
      //         total: {
      //           show: true,
      //           showAlways: true,
      //           fontSize: '0px',
      //           color: '#373d3f',
      //           formatter: function (w: any) {
      //             return '%'
      //           }
      //         }
      //       }
      //     }
      //   }
      // },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }]
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [atrasados, emAndamento, concluidos, emAprovacao])

  return (
        <div id={id}></div>
  )
}

import { Flex, Text, Box, Link, Avatar, Tag, Menu, MenuButton, MenuItem, Image, MenuList } from '@chakra-ui/react'
import { FaPencilAlt, FaSync, FaClipboardList, FaCheckCircle, FaRegTimesCircle, FaTrash, FaPlus, FaCog, FaTasks } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iModel } from '../..'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { DashBox } from '../../../../../../components/PerfilColaborador/styles'
import { WhiteBox } from '../../../../../../components/WhiteBox'
import { appApi } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'
import { HSelect, PropInvalida, SubstringRoute, transformarDuracao } from '../../../../../../Utils/Helper'
import { Legenda } from '../../../Configuracao/styles'
import { DropText } from './components/DropText'

interface iProps {
  model: iModel
  onReagendar: () => void
  onDeleteFeedback: (id: string) => void
}

export const BoxConfiguracao: React.FC<iProps> = ({ model, onReagendar, onDeleteFeedback }) => {
  const isHost = model.configuracao.criador.id === parseJwt().cid
  const { reuniaoId } = useParams<{ reuniaoId: string }>()
  const nav = useNavigate()
  const { pathname } = useLocation()

  function ObterNomeStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'Aguardando'
      case '1':
        return 'Aceita'
      case '2':
        return 'Recusada'
      case '3':
        return 'Reagendada'
      case '4':
        return 'atrasada'
      case '5':
        return 'Finalizada'
      case '6':
        return 'Agendamento'
      default: return ''
    }
  }

  function ObterCorStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'var(--Gray3)'
      case '1':
        return 'var(--Azul)'
      case '2':
        return 'var(--Red)'
      case '3':
        return 'var(--Purple1)'
      case '4':
        return 'var(--Red)'
      case '5':
        return 'var(--Green3)'
      case '6':
        return 'var(--Orange)'
      default: return ''
    }
  }

  function CriarFeedback(): void {
    appApi.post(`Feedback?reuniaoId=${reuniaoId ?? ''}&participanteId=${model.configuracao.convidado.id}&gestorId=${model.configuracao.criador.id}`)
      .then(res => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${res.data as string ?? ''}/Formularios`))
      .catch(err => console.log(err))
  }

  return (
    <WhiteBox styles={{ minWidth: '18.5rem', maxWidth: '18.5rem', borderRadius: '.25rem' }}>
      <Flex alignItems={'center'} justifyContent='space-between'>
        <h3 style={{ fontSize: '1.125rem' }}>Configurações da 1:1</h3>
        {model.configuracao.status !== 5 && isHost &&
          <ElementoTooltipCmp
            label='Editar reunião'
            bg={'var(--Azul)'}
          >
              <FaCog
                cursor={'pointer'}
                size={22}
                color='var(--Azul)'
                onClick={() => nav(`${pathname.replace('Visualizar', 'Editar')}`)}
              />
          </ElementoTooltipCmp>
        }
      </Flex>
      <Flex gap={'3.5rem'} my={'1rem'}>
        <Box>
          <Text fontSize={'14px'} fontWeight={'700'}>Status</Text>
          <Tag
            color={'#fff'}
            bgColor={ObterCorStatus(model.configuracao.status)}
            size={'sm'}
          >
            {ObterNomeStatus(model.configuracao.status)}
          </Tag>
        </Box>
        <Box borderLeft={'1px solid var(--Gray4)'} pl={'.5rem'}>
          <Text fontSize={'14px'} fontWeight={'700'}>Tipo</Text>
          <Legenda>{model.configuracao.tipo}</Legenda>
        </Box>
      </Flex>
      <hr style={{ border: '1px solid #d0d0d083', margin: '.5rem 0' }} />
      <Text mb={'.5rem'} fontSize={'0.875rem'} fontWeight={'700'}>Participantes</Text>

      <Flex gap={'.4rem'} alignItems={'center'} mb={'.5rem'}>
        <Avatar size={'sm'} src={model.configuracao.criador.avatar} name={model.configuracao.criador.info1} />
        <Box color={'var(--Gray3)'}>
          <Text fontSize={'14px'} fontWeight={'400'}>
            {model.configuracao.criador.info1}
          </Text>
          <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
            Organizador
          </Text>
        </Box>
      </Flex>

      <Flex gap={'.4rem'} alignItems={'center'} mb={'.5rem'}>
        <Avatar size={'sm'} src={model.configuracao.convidado.avatar} name={model.configuracao.convidado.info1} />
        <Box color={'var(--Gray3)'}>
          <Text fontSize={'14px'} fontWeight={'400'}>
            {model.configuracao.convidado.info1}
          </Text>
          <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
            Convidado
          </Text>
        </Box>
      </Flex>
      <Flex alignItems={'center'} mb={'.5rem'}>
        {(model.configuracao.convidado.perfilPI) && (
          <DashBox style={{ marginTop: '.325rem', marginBottom: '.5rem', position: 'relative', width: '100%', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
            <Flex justifyContent={'center'}>
              <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
            </Flex>
            <Flex flexDirection={'column'}>
              <Flex flexDir='column' width='100%' alignItems={'center'}>
                {
                  (model.configuracao.convidado.perfilPI?.nomeSelo) && (
                    <Text fontWeight={'500'}>{model.configuracao.convidado.perfilPI?.nomeSelo}</Text>
                  )
                }
                {
                  (model.configuracao.convidado.perfilPI?.urlSelo) && (
                    <Image mt={'.3rem'} width={'2.5rem'} src={model.configuracao.convidado.perfilPI.urlSelo} />
                  )
                }
              </Flex>
              <Menu>
                <MenuButton display={'flex'} alignItems={'center'} as={ButtonCmp} h={'1.5rem'} OutlineColor='Green2' _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                  Baixar Relatorios
                </MenuButton>
                <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                  {
                    (!PropInvalida(model.configuracao.convidado.perfilPI?.urlResultado)) && (
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={model.configuracao.convidado.perfilPI?.urlResultado}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Perfil comportamental
                        </Box>
                      </MenuItem>
                    )
                  }

                  {
                    (!PropInvalida(model.configuracao.convidado.perfilPI?.urlQuadro)) && (
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={model.configuracao.convidado.perfilPI?.urlQuadro}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento pessoal
                        </Box>
                      </MenuItem>
                    )
                  }

                  {
                    (!PropInvalida(model.configuracao.convidado.perfilPI?.urlPlacard)) && (
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={model.configuracao.convidado.perfilPI?.urlPlacard}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Placard
                        </Box>
                      </MenuItem>
                    )
                  }

                  {
                    (!PropInvalida(model.configuracao.convidado.perfilPI?.urlQuadroGestor)) && (
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={model.configuracao.convidado.perfilPI?.urlQuadroGestor}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento gestores
                        </Box>
                      </MenuItem>
                    )
                  }

                </MenuList>
              </Menu>
            </Flex>
          </DashBox>
        )}
      </Flex>

      <DropText titulo='Data - Duração'>
        <Flex flexDirection={'column'}>
          <Legenda style={{ marginTop: '0' }}>{PropInvalida(model.configuracao.data) ? 'Data não definida' : model.configuracao.data}</Legenda>
          <Legenda style={{ marginTop: '0' }}>{transformarDuracao(model.configuracao.duracao)}</Legenda>
          {model.configuracao.reagendar && <ButtonCmp
            VarColor={'Rosa'}
            w={'6.4rem'}
            small
            mt={'.325rem'}
            onClick={onReagendar}
            rightIcon={<FaSync/>}
          >Reagendar</ButtonCmp>}
        </Flex>
      </DropText>

      {model.configuracao.descricao &&
        <DropText titulo='Descrição'>
          <Flex flexDirection={'column'}>
            <Legenda style={{ marginTop: '0' }} dangerouslySetInnerHTML={{ __html: model.configuracao.descricao }}></Legenda>
          </Flex>
        </DropText>
      }

      <DropText titulo='Feedback'>
        {!PropInvalida(model.feedback) && model.feedback &&
          <Flex flexDirection={'column'} gap='.5rem' color={'var(--Gray3)'}>
            <Legenda style={{ marginTop: '0' }}>{model.feedback.nome}</Legenda>
            <Flex gap={'.5rem'}>
              <Avatar src={model.feedback.criador.avatar} size='sm' name={model.feedback.criador.info1} />
              <Flex alignItems={'center'} gap={'.3rem'}>
                <Box>
                  <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'400'}>{model.feedback.criador.info1}</Text>
                  <Legenda>{model.feedback.criador.info2}</Legenda>
                </Box>
              </Flex>
            </Flex>

            <Flex gap={'.5rem'}>
              <Avatar src={model.feedback.convidado.avatar} size='sm' name={model.feedback.convidado.info1} />
              <Flex alignItems={'center'} gap={'.3rem'}>
                <Box>
                  <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'400'}>{model.feedback.convidado.info1}</Text>
                  <Legenda>{model.feedback.convidado.info2}</Legenda>
                </Box>
              </Flex>
            </Flex>
            {model.feedback.criador.id === parseJwt().cid &&
              <Flex gap={'1rem'} justifyContent='center'>
                {!model.feedback.formulario &&
                  <ButtonCmp
                    VarColor='Green2'
                    h={'1.8rem'}
                    w={'5rem'}
                    mt={'.6rem'}
                    fontSize={'10px'}
                    onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${model.feedback?.id ?? ''}/Formularios`)}
                    rightIcon={<FaTasks style={{ color: '#fff' }}
                    />}
                  >Configurar</ButtonCmp>
                }

                {model.feedback.formulario && PropInvalida(model.feedback.dataEnvio) &&
                  <ButtonCmp
                    VarColor='Green2'
                    w={'5rem'}
                    h={'1.8rem'}
                    mt={'.6rem'}
                    fontSize={'10px'}
                    onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${model.feedback?.id ?? ''}/Configuracao`)}
                    rightIcon={<FaCog style={{ color: '#fff' }}
                    />}
                  >Configurar</ButtonCmp>
                }

                {!PropInvalida(model.feedback.dataEnvio) &&
                  <ButtonCmp
                    VarColor='Green2'
                    w={'5rem'}
                    h={'1.8rem'}
                    mt={'.6rem'}
                    fontSize={'10px'}
                    onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${model.feedback?.id ?? ''}/Configuracao/Editar`)}
                    rightIcon={<FaPencilAlt style={{ color: '#fff' }}
                    />}
                  >Editar</ButtonCmp>
                }

                <ButtonCmp
                  VarColor={'Red'}
                  w={'5rem'}
                  h={'1.8rem'}
                  mt={'.6rem'}
                  fontSize={'10px'}
                  onClick={() => onDeleteFeedback(model.feedback?.id ?? '')}
                  rightIcon={<FaTrash style={{ color: '#fff' }}
                  />}
                >Excluir</ButtonCmp>
              </Flex>
            }
          </Flex>
        }
        {PropInvalida(model.feedback) &&
          <Flex padding={'0 0 .5rem 0'} justifyContent='center' width={'100%'}>
            <ButtonCmp
              VarColor='Green2'
              isDisabled={model.configuracao.status === 5 || model.configuracao.criador.id !== parseJwt().cid}
              rightIcon={<FaPlus />}
              small
              onClick={CriarFeedback}
            >Adicionar</ButtonCmp>
          </Flex>
        }
      </DropText>

      {HSelect(model.pontosDiscussao.filter(e => e.pesquisa), (e) => e.pesquisa, false).map((pesquisa, i) => {
        return (
          <DropText key={i} titulo='Pesquisa de percepção ativa'>
            <Flex flexDirection={'column'} color={'var(--Gray3)'}>
              <Text color={'var(--Gray3)'}>{pesquisa?.nome}</Text>
              <Legenda style={{ marginTop: '0' }}>Início: {pesquisa?.dataInicio}</Legenda>
              <Legenda style={{ marginTop: '0' }}>Fim: {pesquisa?.dataFim}</Legenda>
              <Flex mt={'.8rem'} color={'var(--Gray2)'} gap={'1rem'}>
                <Flex gap={'.5rem'} alignItems='center'>
                  <FaClipboardList size={32} />
                  <div>
                    <Text fontWeight={'bold'} color={'var(--Gray2)'}>{pesquisa?.respostas}/{pesquisa?.avaliadores}</Text>
                    <span>Respostas</span>
                  </div>
                </Flex>
                <Flex gap={'1rem'} pl={'.5rem'} borderLeft={'1px solid var(--Gray4)'}>
                  <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                    {
                      (!PropInvalida(pesquisa?.autoavaliacao)) && (
                        <Flex marginBottom={'.3rem'} alignItems='center'>
                          {pesquisa?.autoavaliacao && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                          {!pesquisa?.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                          <small>Autoavaliação</small>
                        </Flex>
                      )
                    }
                    {
                      (!PropInvalida(pesquisa?.lider)) && (
                        <Flex marginBottom={'.3rem'} alignItems='center'>
                          {pesquisa?.lider && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                          {!pesquisa?.lider && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                          <small>Gestor</small>
                        </Flex>
                      )
                    }
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </DropText>
        )
      })}

    </WhiteBox>
  )
}

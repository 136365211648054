import { FormControl, FormLabel, Text, Textarea, TextareaProps } from '@chakra-ui/react'

interface iFormProps extends TextareaProps {
  label?: string
  OnChange: (e: string) => void
  errorMessage?: string
  error?: boolean
}

export const TextAreaCmp: React.FC<iFormProps> = ({ label, error, errorMessage, OnChange, width, pl, height, ...rest }) => {
  return (
    <FormControl width={width ?? '100%'}>
      {label && <FormLabel color={'var(--Gray1)'} fontSize={'.875rem'} mb={'.25rem'}>{label}</FormLabel>}
      <Textarea pl={pl} {...rest} borderColor='var(--Gray4)' size={'xs'} height={height ?? '1.975rem'} width={width} onChange={(e) => OnChange(e.target.value)} fontSize={'.875rem'}/>
      {
        (error && errorMessage) && (
          <Text marginTop={'.125rem'} color={'var(--terc3)'} fontSize={'.75rem'}>{errorMessage}</Text>
        )
      }
    </FormControl>
  )
}

import { Avatar, Divider, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { WhiteContainer } from '../../../components/WhiteContainer'
import { Body } from '../../Layouts/Body'
import { ButtonCmp } from '../../../components/ButtonCmp'
import { useState, useEffect } from 'react'

import IconeLove from '../../../assets/IconeLove.svg'
import IconeMagoado from '../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../assets/IconePiscada.svg'
import IconeTriste from '../../../assets/IconeTriste.svg'

import { iColaboradorBase, iNomeId } from '../../../interfaces'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Emoji } from './Components/Emoji'
import { appApi } from '../../../services/appApi'
import { ButtonMenu } from './styles'
import { TextAreaCmp } from '../../../components/TextareaCmp'
import { FaCheck } from 'react-icons/fa'

interface iDadoPDI {
  origem: string
  totalAcaoConcluida: number
  totalAcao: number
  dataFinal: string
}

interface iAvaliacaoPDI {
  dadoPDI: iDadoPDI
  colaborador: iColaboradorBase
  acoes: iNomeId[]
  avaliadoIsUserLogado: boolean
}

interface iPostAvaliacao {
  notas: iAvaliacaoXCompetencia[]
  comentario: string
  encerrar: boolean
  adicionarAcao: boolean
}

interface iAvaliacaoXCompetencia {
  acaoId: string
  nota: number
}

type iLocation = {
  pdiId: string
}

export const AvaliacaoPDI: React.FC = () => {
  const toast = useToast()
  const { pdiId } = useParams<iLocation>()
  const emailResponsavel = new URLSearchParams(useLocation().search).get('emailResponsavel')
  const nav = useNavigate()
  const [Model, setModel] = useState<iAvaliacaoPDI>()
  const [Notas, setNotas] = useState<iAvaliacaoXCompetencia[]>([])
  const [Comentario, setComentario] = useState('')
  const [Opcao, setOpcao] = useState<string>()

  function PostAvaliacao(): void {
    if (Notas.length < (Model?.acoes.length ?? 0)) {
      toast({
        title: 'Você deve avaliar todas as competências!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
      return
    }
    const form: iPostAvaliacao = {
      comentario: Comentario,
      encerrar: Opcao === '3' ?? false,
      notas: Notas,
      adicionarAcao: Opcao === '0' || Opcao === '2'
    }

    emailResponsavel
      ? appApi.post(`PDI/${pdiId as string}/AvaliacaoResponsavel?emailResponsavel=${emailResponsavel}`, form)
        .then(() => {
          toast({
            title: 'PDI avaliado com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 2000
          })
          nav('/')
        })
        .catch(err => console.log(err))
      : appApi.post(`PDI/${pdiId as string}/Avaliacao`, form)
        .then(() => {
          toast({
            title: 'PDI avaliado com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 2000
          })
          nav('/Desempenho/PDI/Dashboard/Corporativo')
        })
        .catch(err => console.log(err))
  }

  function getPDI(): void {
    emailResponsavel
      ? appApi.get(`PDI/${pdiId as string}/AvaliacaoResponsavel?emailResponsavel=${emailResponsavel}`)
        .then(res => {
          const data: iAvaliacaoPDI = res.data
          setModel(data)
          if (data.acoes.some(e => e.nota)) {
            const arr: iAvaliacaoXCompetencia[] = []
            data.acoes.forEach((e, i) => {
              if (e.nota) {
                arr.push({ acaoId: e.id, nota: e.nota })
              }
            })
            setNotas(arr)
          }
        })
        .catch(err => console.log(err))
      : appApi.get(`PDI/${pdiId as string}/Avaliacao`)
        .then(res => {
          const data: iAvaliacaoPDI = res.data
          setModel(data)
          if (data.acoes.some(e => e.nota)) {
            const arr: iAvaliacaoXCompetencia[] = []
            data.acoes.forEach((e, i) => {
              if (e.nota) {
                arr.push({ acaoId: e.id, nota: e.nota })
              }
            })
            setNotas(arr)
          }
        })
        .catch(err => console.log(err))
  }

  function SelecionarNota(acaoId: string, nota: number): void {
    if (!Notas.some(r => r.acaoId === acaoId)) {
      setNotas([...Notas, {
        nota: nota,
        acaoId: acaoId
      }])
    } else {
      const copy = [...Notas]
      const pos = copy.findIndex(e => e.acaoId === acaoId)
      copy[pos].nota = nota
      setNotas(copy)
    }
  }

  useEffect(() => {
    getPDI()
  }, [])

  useEffect(() => {
    setOpcao(undefined)
  }, [Notas])

  const isOpcaoSelecionado = (id: string): boolean => Opcao === id

  return (
    <Body>
      {
        (Model) && (
          <WhiteContainer>
            <Flex flexDir={'column'} alignItems='center'>
              <Flex width={'100%'} gap={'1.5rem'} mb={'1rem'} alignItems={'center'}>
                <Flex alignItems={'center'}>
                  <Avatar src={Model?.colaborador.avatar} name={Model?.colaborador.nome} marginRight={'1rem'} size={'lg'} />
                  <Flex fontFamily={'Poppins'} flexDir={'column'}>
                    <Text lineHeight={'110%'} fontWeight={'400'}>Avaliado:</Text>
                    <Text lineHeight={'110%'} fontWeight={'700'} fontSize={'1.325rem'}>{Model?.colaborador.nome}</Text>
                    <Text lineHeight={'110%'} fontWeight={'500'} fontSize={'.875rem'}>{Model?.colaborador.nomeDepartamento}</Text>
                  </Flex>
                </Flex>
                <Divider orientation='vertical' borderColor={'var(--Gray4)'} />
                <Flex width={'14rem'} p={'.75rem 1rem'} bg={'var(--AzulOpacoBg)'} border={'2px solid var(--AzulOpaco)'} fontWeight={'400'} fontFamily={'Roboto'} flexDir={'column'} gap='.2rem' fontSize={'.875rem'} lineHeight={'1rem'} borderRadius={'.25rem'}>
                  <Text><strong>Origem:</strong> {Model.dadoPDI.origem}</Text>
                  <Text><strong>Ações concluidas:</strong> {Model.dadoPDI.totalAcaoConcluida}</Text>
                  <Text><strong>Total ações:</strong> {Model.dadoPDI.totalAcao}</Text>
                  <Text><strong>Data final:</strong> {Model.dadoPDI.dataFinal}</Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex gap={'1rem'} mb={'1rem'} flexDir={'column'}>
              <Divider borderWidth={'1px'} borderColor={'var(--c3)'}/>
              {
                Model.acoes?.map((e, i) => {
                  return (
                    <>
                      <Flex key={i} flexDir={'column'} gap={'1.5rem'} padding={'.75rem 1rem'}>
                        <Flex justifyContent={'start'} alignItems={'center'} gap={'.5rem'}>
                          <Flex right={'-.25rem'} top={'0'} flexDir={'column'} alignItems={'center'} gap='.25rem'>
                            <Image width={'3.25rem'} objectFit='scale-down' src={e.avatar ? e.avatar : 'https://sigah.blob.core.windows.net/icones/Competencias-27.svg'} border={'1px solid var(--Azul)'} borderRadius={'50%'} boxShadow={'var(--SombraBackground)'}/>
                          </Flex>
                          <Flex gap={'1rem'}>
                            <Flex justifyContent={'center'} flexDir={'column'} h={'100%'} gap={'.25rem'}>
                              <Text fontWeight={'900'} fontSize='.925rem' lineHeight={'.925rem'}>{e.nome}</Text>
                              <Text lineHeight={'1.125rem'}>Como você percebe a {Model.avaliadoIsUserLogado ? 'sua evolução' : 'evolução do colaborador'} nessa competência ao realizar a ação <strong>"{e.complemento}"</strong>?</Text>
                            </Flex>
                            {e.nota && <Flex alignItems={'end'} w={'20rem'}>
                              <Flex alignItems={'center'} color={'var(--Green2)'} gap='.325rem'>
                                <FaCheck/>Competência já avaliada
                              </Flex>
                            </Flex>}
                          </Flex>
                        </Flex>

                        <Flex justifyContent={'start'} gap={'1.25rem'} style={e.nota ? { cursor: 'not-allowed !important' } : {}}>
                          <Emoji
                            texto='Não melhorou em nada'
                            icon={IconeTriste}
                            disabled={!Notas.some(t => t.acaoId === e.id && t.nota === 1)}
                            jaRespondido={!!e.nota}
                            onClick={() => SelecionarNota(e.id, 1)}
                          />

                          <Emoji
                            texto='Precisa se esforçar mais'
                            icon={IconeMagoado}
                            disabled={!Notas.some(t => t.acaoId === e.id && t.nota === 2)}
                            jaRespondido={!!e.nota}
                            onClick={() => SelecionarNota(e.id, 2)}
                          />

                          <Emoji
                            texto='Está no caminho certo'
                            icon={IconeFeliz}
                            disabled={!Notas.some(t => t.acaoId === e.id && t.nota === 3)}
                            jaRespondido={!!e.nota}
                            onClick={() => SelecionarNota(e.id, 3)}
                          />

                          <Emoji
                            texto='Já melhorou muito'
                            icon={IconePiscada}
                            disabled={!Notas.some(t => t.acaoId === e.id && t.nota === 4)}
                            jaRespondido={!!e.nota}
                            onClick={() => SelecionarNota(e.id, 4)}
                          />

                          <Emoji
                            texto='Já atingiu o esperado'
                            icon={IconeLove}
                            disabled={!Notas.some(t => t.acaoId === e.id && t.nota === 5)}
                            jaRespondido={!!e.nota}
                            onClick={() => SelecionarNota(e.id, 5)}
                          />
                        </Flex>

                        {/* <Flex>
                                                  <ButtonCmp
                                                      onClick={() => nav(-1)}
                                                      VarColor='c5'
                                                  >Avaliar mais tarde</ButtonCmp>
                                              </Flex> */}
                      </Flex>
                      {((i + 1) !== Model.acoes.length) && <Divider borderWidth={'1px'} borderColor={'var(--c3)'}/>}
                    </>
                  )
                })
              }
              {
                (Notas.some(r => r.nota === 1) && (Model.dadoPDI.totalAcao !== Model.dadoPDI.totalAcaoConcluida)) && (
                  <Flex flexDir={'column'} gap={'1rem'} padding={'1rem'} borderRadius={'.25rem'} border={'2px solid var(--c3)'} mt={'.25rem'} mb={'-.25rem'}>
                    <Flex justifyContent={'center'}>
                      <Text fontSize={'1.125rem'} fontWeight={'700'} lineHeight={'1.325rem'}>Você recomendaria adicionar mais ações para que {Model.avaliadoIsUserLogado ? 'você' : 'ele'} trabalhe essa competência?</Text>
                    </Flex>
                    <Flex justifyContent={'center'} gap='.75rem'>
                      <ButtonMenu onClick={() => setOpcao('0')} selected={isOpcaoSelecionado('0')}>Sim, {Model.avaliadoIsUserLogado ? 'eu posso' : 'ele pode'} realizar mais ações</ButtonMenu>
                      <ButtonMenu onClick={() => setOpcao('1')} selected={isOpcaoSelecionado('1')}>Não, pode continuar com as ações aprovadas</ButtonMenu>
                    </Flex>
                  </Flex>
                )
              }

              {
                (Model.dadoPDI.totalAcao === Model.dadoPDI.totalAcaoConcluida) && (
                  <Flex flexDir={'column'} gap={'1rem'} padding={'1rem'} borderRadius={'.25rem'} border={'2px solid var(--c3)'} mt={'.25rem'} mb={'-.25rem'}>
                    <Flex justifyContent={'center'}>
                      <Text fontSize={'1.125rem'} fontWeight={'700'} lineHeight={'1.325rem'}>Todas as ações já foram finalizadas, você recomendaria adicionar mais ações para que {Model.avaliadoIsUserLogado ? 'você' : 'ele'} trabalhe essa competencia?</Text>
                    </Flex>
                    <Flex gap='.75rem' justifyContent={'center'}>
                      <ButtonMenu onClick={() => setOpcao('2')} selected={isOpcaoSelecionado('2')}>Sim, {Model.avaliadoIsUserLogado ? 'eu posso' : 'ele pode'} realizar mais ações</ButtonMenu>
                      <ButtonMenu onClick={() => setOpcao('3')} selected={isOpcaoSelecionado('3')}>Não, o PDI pode ser concluído</ButtonMenu>
                    </Flex>
                  </Flex>
                )
              }
            </Flex>
            <Flex flexDir={'column'} gap={'1rem'} py={'1rem'} borderTop={!((Notas.some(r => r.nota === 1) && (Model.dadoPDI.totalAcao !== Model.dadoPDI.totalAcaoConcluida)) || (Model.dadoPDI.totalAcao === Model.dadoPDI.totalAcaoConcluida)) ? '2px solid var(--c3)' : ''}>
              <h3>Gostaria de deixar algum comentário {Model.avaliadoIsUserLogado ? 'sobre seu PDI?' : 'para seu colaborador?'} </h3>
              <Flex>
                <TextAreaCmp
                  OnChange={(e) => setComentario(e)}
                  value={Comentario}
                />
              </Flex>
            </Flex>

            <Flex justifyContent={'center'} gap={'.5rem'}>
              <ButtonCmp onClick={() => nav(-1)} VarColor='c6'>Voltar</ButtonCmp>
              <ButtonCmp VarColor='Green2' onClick={() => {
                PostAvaliacao()
              }}>Enviar avaliação</ButtonCmp>
            </Flex>
          </WhiteContainer>
        )
      }
    </Body>
  )
}

import { Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { ButtonCmp } from '../ButtonCmp'
import { InputCmp } from '../InputCmp'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (dataFim: string) => void
  message: string
  dataMinima?: string
  center?: boolean
  status?: boolean
}

export const ModalReabrir: React.FC<props> = ({ isOpen, center, onRequestClose, onConfirm, message, status, dataMinima }) => {
  Modal.setAppElement('#root')
  const [Status, setStatus] = useState(status ?? false)
  const [DataFim, setDataFim] = useState('')
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => { onRequestClose(); if (!status) { setStatus(false) } }}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-poupUp'
        >
            <Flex pos={'absolute'} top={'10px'} right={'10px'}>
                <MdClose size={18} color={'var(--Red)'} cursor={'pointer'} onClick={onRequestClose} />
            </Flex>

            {!Status && (
                <Flex flexDir={'column'} gap={'.5rem'}>
                    <h3>Atenção!</h3>
                    <span style={{ textAlign: center ? 'center' : 'unset', fontSize: '.875rem' }}>{message}</span>
                    <Flex gap={'.5rem'}>
                        <ButtonCmp VarColor='c6' onClick={() => { onRequestClose(); if (!status) { setStatus(false) } }}>Cancelar</ButtonCmp>
                        <ButtonCmp VarColor='Green2' onClick={() => { setStatus(true) }}>Continuar</ButtonCmp>
                    </Flex>
                </Flex>
            )}

            {Status && (
                <Flex flexDir={'column'} gap={'.5rem'}>
                    <h3>Defina a data de término:</h3>
                    <InputCmp
                        width={'15rem'}
                        type={'datetime-local'}
                        min={dataMinima ? new Date(`${new Date().getFullYear()}-${dataMinima.split('/')[1]}-${dataMinima.split('/')[0]}T00:00:00Z`).toISOString().slice(0, 16) : new Date().toISOString().slice(0, 16)}
                        max={'2099-12-31T23:59'}
                        OnChange={setDataFim}
                    />
                    <Flex justifyContent={'center'} pl={'.2rem'}>
                        <ButtonCmp VarColor='Green2' onClick={() => { onConfirm(DataFim); onRequestClose(); if (!status) { setStatus(false) } }}>Confirmar</ButtonCmp>
                    </Flex>
                </Flex>
            )}

        </Modal>
  )
}

import { Text } from '@chakra-ui/react'
import { TextAreaCmp } from '../../../../../../components/TextareaCmp'
import { ComentarioContainer } from '../../styles'

interface iFormQuestaoResponse {
  enunciado: string
  alternativaResposta: string[]
}

interface iDiscursivaProps {
  questao: iFormQuestaoResponse
}

export const Discursiva: React.FC<iDiscursivaProps> = ({ questao }) => {
  return (
        <ComentarioContainer>
            <Text fontWeight={'600'}>{questao.enunciado}</Text>
            <TextAreaCmp
                value={questao.alternativaResposta[0]}
                placeholder="Deixe seu comentário"
                OnChange={() => {}}
            />
        </ComentarioContainer>
  )
}

import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Textarea } from '@chakra-ui/react'
import { iComentarioFeedback, iQuestao, iRespostaXColaborador } from '../..'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida } from '../../../../../../Utils/Helper'
import { useEffect, useState } from 'react'

interface iProps {
  variant: 'editar' | 'visualizar' | 'extra'
  respostas?: iRespostaXColaborador[]
  questao?: iQuestao
  comentario?: iComentarioFeedback
  onUpdate?: (id: string, value: string) => void
}

export const QuestaoComentario: React.FC<iProps> = ({ variant, respostas, questao, comentario, onUpdate }) => {
  const { cid } = parseJwt()
  const [Model, setModel] = useState<string>()

  function InternalUpdate(texto: string): void {
    if (onUpdate !== undefined) {
      onUpdate(questao?.id ?? '', texto)
      setModel(texto)
    }
  }

  useEffect(() => {
    if (questao && respostas && variant === 'editar' && onUpdate !== undefined) {
      setModel(respostas.find(e => e.colaboradorId === cid)?.respostas.find(e => e.questaoId === questao.id)?.comentario ?? '')
    }
  }, [variant])

  if (questao && respostas && variant === 'visualizar') {
    return (
      <Flex flexDir={'column'} gap='.5rem'>
        {respostas.map((res, i2) => {
          const resposta = res.respostas.find(e => e.questaoId === questao.id)
          if (PropInvalida(resposta)) return null
          return (
            <Box key={i2} my={'.25rem'} bgColor={'var(--Gray6)'} p={'.5rem'}>
              <Flex alignItems={'center'} gap={'.5rem'}>
                <ElementoTooltip label={res.nome}>
                  <Avatar src={res.avatar} size={'2xs'}/>
                </ElementoTooltip>
                <Text fontSize={'12px'} fontWeight={'400'}>{resposta?.data}</Text>
              </Flex>
              <Text>{resposta?.comentario?.split('\n').map(text => {
                return <p>{text}</p>
              })}</Text>
            </Box>
          )
        })}
      </Flex>
    )
  } else if (variant === 'extra') {
    return (
      <Box my={'.25rem'} bgColor={'var(--Gray6)'} p={'.5rem'}>
        <Flex alignItems={'center'} gap={'.5rem'}>
          <ElementoTooltip label={comentario?.nome}>
            <Avatar src={comentario?.avatar} size={'2xs'} />
          </ElementoTooltip>
          <Text fontSize={'12px'} fontWeight={'400'}>{comentario?.data}</Text>
        </Flex>
        <Text>{comentario?.comentario}</Text>
      </Box>
    )
  } else if (variant === 'editar') {
    return (
      <Textarea
        h={'10rem'}
        onChange={(e) => InternalUpdate(e.target.value)}
        placeholder="Deixe seu comentário"
        value={Model}
      />
    )
  }
  return null
}

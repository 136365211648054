import styled from 'styled-components'

export const Main = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    div{
        transition: .75s;
    }
`

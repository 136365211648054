import styled from 'styled-components'
import { Column } from '../../../../styles/styledGlobal'

export const ContainerFormulario = styled.div`
display: flex;
background: var(--c1);
box-shadow: var(--SombraBtns);
justify-content: space-between;
margin-bottom: .75rem;
border-radius: 0.25rem;
padding: .75rem 1rem;
align-items: center;
font-size: .875rem;
font-weight: 500;
font-family: 'Roboto','sans-serif';
flex-wrap: wrap;
`

export const ColumnText = styled(Column)`
align-items: flex-start;
justify-content: center;
min-width: 5rem;
max-width: 7rem;
h4 {
    font-size: .875rem;
    line-height: 1rem;
    font-weight: 400;
}
span {
    font-size: .813rem;
    font-weight: 900;
}
`

import { Flex, Text } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../../../components/ModalBase'

interface iModalComunicacao {
  isOpen: boolean
  onClose: () => void
}

export const ModalTag: React.FC<iModalComunicacao> = ({ isOpen, onClose }) => {
  return (
    <ModalBase Titulo='Como utilizar as TAGs' isOpen={isOpen} onClose={onClose} >
      <Flex maxWidth={'53.125rem'} borderTop={'1px solid var(--Gray4)'} borderBottom={'1px solid var(--Gray4)'} padding={'1rem'}>
        <Text fontSize={'.875rem'}>
          Para deixar seus critérios com uma cara mais personalizada nós criamos algumas TAGs que podem lhe ajudar.
          <br />
          <br />
          <strong>[EMAIL DO COLABORADOR]</strong>, digite o email do colaborador, para trazer seus dados.
          <br />
          <br />
          <strong>[META]</strong>, para colocar o valor da meta e <strong>[META%]</strong>, para colocar o valor já atingido da meta em % com circulo de progresso.
          <br />
          <br />
          <strong>[{'{porcentagem da meta}'} DA META]</strong>, para colocar uma fatia em porcentagem da meta, que será apresentada no formato <strong>VALOR NUMÉRICO (porcentagem%)</strong>.
          <br />
          <br />
          <strong>[MES-{'{mes inicial de 1-12}'} ATE MES-{'{mes final de 1-12}'} DE {'{ano}'}]</strong>, gera um gráfico de barras com valor acumulado e previsto dos meses indicados.
          <br />
          <br />
          <strong>[VALOR NEGATIVO {'{valor}'}]</strong>, para colocar um valor em destaque com a cor vermelha e <strong>[VALOR POSITIVO {'{valor}'}]</strong>, para colocar um valor em destaque com a cor verde. Não coloque sinais de "+" ou "-" no lugar dos campos de valor.
          <br />
          <br />
          <strong>[ANALISE PERIODO {'{ano do periodo}'}]</strong>, para trazer um gráfico que mostra os meses que ficaram acima ou abaixo do valor previsto desse ano.
          <br />
          <br />
          <strong>Não se esqueça que as TAGs devem ser sempre inseridas entre colchetes [ ], conforme estão escritas no texto acima. E as TAGs que tiverem {'{ }'}, deverão ser preenchidas apenas com números, referentes ao dado que deseja apresentar.</strong>
          <br />
        </Text>
      </Flex>
      <Flex padding={'.5rem'} justifyContent={'center'} alignItems={'center'}>
        <ButtonCmp onClick={onClose} VarColor='Green2'>Ok</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}

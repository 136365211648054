import { Skeleton, Stack } from '@chakra-ui/react'

interface IProps {
  linhas: number
  loading: boolean
  my?: string
  px?: string
}

const LoadingContainer: React.FC<IProps> = ({ linhas, loading, children, my, px }) => {
  const renderSkeletons = (): any => {
    const skeletons = []
    for (let i = 0; i < linhas; i++) {
      skeletons.push(<Skeleton key={i} height="20px" />)
    }
    return skeletons
  }

  return (
    <>
        {loading &&
            <Stack spacing={3} my={my} w={'100%'} px={px}>
                {renderSkeletons()}
            </Stack>
        }
        {!loading &&
            <>
                {children}
            </>
        }
    </>
  )
}
export default LoadingContainer

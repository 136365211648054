/* eslint-disable no-return-assign */
import { Avatar, Flex, Text } from '@chakra-ui/react'
import { FaFileDownload, FaTrashAlt } from 'react-icons/fa'
import { iAnexo, iComentario } from '../..'
import { DetalheArquivo } from '../../../../../../components/DetalheArquivo'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { iAvatarXInformacoes } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'

interface iProps {
  variant: 'comentario' | 'anexo'
  participante: iAvatarXInformacoes
  comentario?: iComentario
  anexo?: iAnexo
  onExcluir: (id: string, variant: 'comentario' | 'anexo') => void
}

export const VisualizarPontoDiscussao: React.FC<iProps> = ({ variant, anexo, comentario, participante, onExcluir }) => {
  return (
        <Flex padding={'0.5rem'} gap={'.5rem'} border={'1px solid var(--c4)'} flexDir={'column'} bg={'var(--AzulOpacoBg)'}>
            <Flex justifyContent={'space-between'}>
                <Flex gap={'.5rem'} alignItems={'center'}>
                    <Avatar src={participante.avatar} name={participante.info1} size={'xs'} />
                    <Text fontWeight={'600'} fontSize='12px'>{participante.info1}</Text>
                </Flex>
                <Flex gap={'.5rem'} cursor='pointer'>
                    {variant === 'anexo' &&
                        <ElementoTooltipCmp bg={'var(--Azul)'} label={'Baixar anexo'}>
                            <FaFileDownload onClick={() => window.location.href = anexo?.anexo.uri ?? ''} color='var(--Blue1)' />
                        </ElementoTooltipCmp>
                    }

                    {parseJwt().cid === participante.id &&
                        <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir'}>
                            <FaTrashAlt
                                onClick={() => onExcluir(anexo?.id ?? comentario?.id ?? '', variant)}
                                color='var(--Red)'
                            />
                        </ElementoTooltipCmp>
                    }

                </Flex>
            </Flex>
            {
                variant === 'comentario' &&
                <Text
                    fontSize={'14px'}
                    fontWeight='400'
                >{comentario?.comentario}</Text>
            }
            {
                variant === 'anexo' &&
                <DetalheArquivo
                    arquivo={anexo?.anexo ?? {
                      name: '',
                      size: '',
                      uri: ''
                    }}
                />
            }
        </Flex>
  )
}

import { Avatar, Box, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, useToast } from '@chakra-ui/react'
import { FaSave, FaTrash } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { useEffect, useState } from 'react'
import { NineBox } from '../../../../../components/NineBox'

import { ModalAlterarQuadrante } from '../ModalAlterarQuadrante'
import { iNineBoxAvaliacao, iPerfilColaborador } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'
import { appApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { ElementoTooltipCmp } from '../../../../../components/ElementoTooltipCmp'

interface iProps {
  model: iNineBoxAvaliacao
  colaborador: iPerfilColaborador
  reload: () => void
}

interface iNota {
  notaY: number
  notaX: number
}

export interface iPostAvliacao {
  colaboradorId: string
  boxId: string
  notaY: number
  notaX: number
  quandranteId: string
  comite: string[]
  comentario?: string
}

export const TabNineBox: React.FC<iProps> = ({ model, colaborador, reload }) => {
  const toast = useToast()
  const isAdm = parseJwt().adm === 'True'
  const [NomeQuadrante, setNomeQuadrante] = useState<string>()
  const [IsOpenDelete, setIsOpenDelete] = useState(false)
  const [AtualizacaoId, setAtualizacaoId] = useState('')
  const [IsOpenAlterarQuadrante, setIsOpenAlterarQuadrante] = useState(false)
  const [Notas, setNotas] = useState<iNota>({
    notaX: 0,
    notaY: 0
  })

  const [NotaXInconentente, setNotaXInconentente] = useState(false)
  const [NotaYInconentente, setNotaYInconentente] = useState(false)

  const [VisualizarTudo, setVisualizarTudo] = useState(false)

  function onOpenAlterarQuadrante(): void {
    setIsOpenAlterarQuadrante(true)
  }

  function onCloseAlterarQuadrante(): void {
    setIsOpenAlterarQuadrante(false)
  }

  function SelecionarQuadrante(nome: string, notaX?: number, notaY?: number): void {
    setNomeQuadrante(nome)
    VerificarIncoerencia(nome, notaX, notaY)
  }

  function onCloseModalDelete(): void {
    setAtualizacaoId('')
    setIsOpenDelete(false)
  }

  function VerificarIncoerencia(nome: string, notaX?: number, notaY?: number): void {
    setNotaXInconentente(false)
    setNotaYInconentente(false)
    const quadrante = model.nineBox.quadrantes.find(e => e.titulo === nome)
    if (quadrante) {
      const configx = model.nineBox.eixosX.find(e => e.id === quadrante.eixoX)
      const configy = model.nineBox.eixosY.find(e => e.id === quadrante.eixoY)
      if (notaX && notaY && configx && configy) {
        if (!(notaX >= configx?.rangeMinimo && notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(notaY >= configy?.rangeMinimo && notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      } else if (configx && configy) {
        if (!(Notas.notaX >= configx?.rangeMinimo && Notas.notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(Notas.notaY >= configy?.rangeMinimo && Notas.notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      }
    }
  }

  function AlterarNota(notas: iNota): void {
    setNotas(notas)
    const configY = model.nineBox.eixosY.find(r => notas.notaY >= r.rangeMinimo && notas.notaY <= r.rangeMaximo)
    const configX = model.nineBox.eixosX.find(r => notas.notaX >= r.rangeMinimo && notas.notaX <= r.rangeMaximo)
    if (configY && configX) {
      const quadrante = model.nineBox.quadrantes.find(r => r.eixoX === configX.id && r.eixoY === configY.id)
      if (quadrante) {
        setNomeQuadrante(quadrante.id)
        if (NotaXInconentente || NotaYInconentente) {
          VerificarIncoerencia(quadrante.id)
        }
      }
    }
  }

  function AvaliarColaborador(comite: string[], comentario?: string): void {
    const form: iPostAvliacao = {
      boxId: model.nineBox.id,
      colaboradorId: colaborador.id,
      comentario: comentario,
      comite: comite,
      notaX: Notas.notaX,
      notaY: Notas.notaY,
      quandranteId: NomeQuadrante ?? ''
    }

    appApi.post('Colaborador/NineBox', form)
      .then(() => {
        if (PropInvalida(colaborador?.avaliacaoNineBox)) {
          toast({
            title: 'Avaliação computada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          toast({
            title: 'Avaliação alterada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
        reload()
        onCloseAlterarQuadrante()
      })
      .catch(err => console.log(err))
  }

  function ExcluirAtualizacao(atualizacaoId: string): void {
    appApi.delete(`NineBox/Atualizacao/${colaborador.id}/${atualizacaoId}`)
      .then()
      .catch(err => console.log(err))
    window.location.reload()
  }

  useEffect(() => {
    if (!PropInvalida(colaborador.avaliacaoNineBox)) {
      setNotas({
        notaX: colaborador.avaliacaoNineBox?.notaX ?? 0,
        notaY: colaborador.avaliacaoNineBox?.notaY ?? 0
      })
      SelecionarQuadrante(colaborador?.avaliacaoNineBox?.idQuadrante ?? '', colaborador?.avaliacaoNineBox?.notaX, colaborador?.avaliacaoNineBox?.notaY)
    }
  }, [colaborador])

  useEffect(() => {
    if (AtualizacaoId !== '') {
      setIsOpenDelete(true)
    }
  }, [AtualizacaoId])

  return (
    <Flex flexDir={'column'} px={'1rem'}>
      <ModalDelete
        onRequestClose={onCloseModalDelete}
        isOpen={IsOpenDelete}
        message={'Deseja reverter as ultimas alterações feitas neste colaborador?'}
        center
        onConfirm={() => ExcluirAtualizacao(AtualizacaoId)}
      />
      <ModalAlterarQuadrante
        comiteAtivo={model.comiteAtivo}
        onClose={onCloseAlterarQuadrante}
        isOpen={IsOpenAlterarQuadrante}
        colaborador={colaborador}
        onAction={AvaliarColaborador}
      />
      <h3>Definição de quadrante</h3>
      <small style={{ color: 'var(--c7)' }}>Aqui você pode mudar o quadrante do colaborador manualmente sem considerar a definição por sua nota</small>
      <Flex my={'1rem'} gap={'.76rem'}>
        <Flex flexDirection={'column'}>
          <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'} color={NotaYInconentente ? 'var(--Red)' : ''}>
            {model.nineBox.configuracaoEixoY.titulo}
          </Box>
          <NumberInput
            isInvalid={NotaYInconentente}
            onChange={(e) => AlterarNota({ ...Notas, notaY: parseFloat(e) })}
            value={Notas.notaY}
            isDisabled={!isAdm}
            width={'7.9rem'}
            min={0}
            max={200}
            step={0.1}
            h={'1.75rem'}
            size={'sm'}
            borderRadius={'.25rem'}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex flexDirection={'column'}>
          <Box mb={'.15rem'} fontSize={'.875rem'} fontWeight={'700'} color={NotaXInconentente ? 'var(--Red)' : ''}>
            {model.nineBox.configuracaoEixoX.titulo}
          </Box>
          <NumberInput
            isInvalid={NotaXInconentente}
            onChange={(e) => AlterarNota({ ...Notas, notaX: parseFloat(e) })}
            isDisabled={!isAdm}
            value={Notas.notaX}
            width={'7.9rem'}
            min={0}
            max={200}
            step={0.1}
            h={'1.75rem'}
            size={'sm'}
            borderRadius={'.25rem'}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Flex>
      <Flex justifyContent={'center'} ml={'-6rem'} mt={'1.5rem'}>
        <NineBox variant='avaliar' model={model.nineBox}
          quadranteSelecionado={NomeQuadrante}
          selecionarQuadrante={SelecionarQuadrante}
        />
      </Flex>

      <Flex gap={'1rem'} flexDir={'column'} mb='1rem'>
        {model.comites.slice(0, VisualizarTudo ? model.comites.length : 2).map((r, i) => {
          return (
            <Flex flexDir={'column'} borderRadius={'.25rem'} border={'1px solid var(--c4)'} bg={'var(--AzulOpacoBg)'} padding='1rem' key={i}>
                <Flex justifyContent={'space-between'} alignItems={'center'}>
                  <Box>
                    <Text fontWeight={'bold'} fontSize={'0.813rem'}>Alterado por {r.responsavel.nome} em {r.dataAvaliacao}</Text>
                    {PropInvalida(r.avaliacaoAnterior) && (
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Avaliou no quadrante {r.avaliacaoAtual}
                      </Text>
                    )}

                    {(!PropInvalida(r.avaliacaoAnterior) && (r.avaliacaoAnterior !== r.avaliacaoAtual)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.avaliacaoAnterior} para {r.avaliacaoAtual}
                      </Text>
                    }

                    {((r.notaAnteriorX !== null && r.notaAnteriorX !== -1) && (r.notaNovaX !== -1) && (r.notaNovaX !== r.notaAnteriorX)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.notaAnteriorX?.toFixed(1)} para {r.notaNovaX?.toFixed(1)} no eixo de {model.nineBox.configuracaoEixoX.titulo}
                      </Text>
                    }
                    {((r.notaAnteriorX !== null && (r.notaAnteriorX === -1)) && (r.notaNovaX !== -1)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Atribuiu {r.notaNovaX?.toFixed(1)} como a primeira nota no eixo de {model.nineBox.configuracaoEixoX.titulo}
                      </Text>
                    }

                    {((r.notaAnteriorY !== null && r.notaAnteriorY !== -1) && (r.notaNovaY !== -1) && (r.notaNovaY !== r.notaAnteriorY)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Alterou de {r.notaAnteriorY?.toFixed(1)} para {r.notaNovaY?.toFixed(1)} no eixo de {model.nineBox.configuracaoEixoY.titulo}
                      </Text>
                    }
                    {((r.notaAnteriorY !== null && (r.notaAnteriorY === -1)) && (r.notaNovaY !== -1)) &&
                      <Text
                        fontWeight={'bold'}
                        fontSize={'0.813rem'}
                      >Atribuiu {r.notaNovaY?.toFixed(1)} como a primeira nota no eixo de {model.nineBox.configuracaoEixoY.titulo}
                      </Text>
                    }
                  </Box>
                  {r.atualizacaoId && model.comites.length > 1 &&
                  <ElementoTooltipCmp bg={'var(--Red)'} label={'Excluir'}>
                    <FaTrash style={{ color: 'var(--Red)', cursor: 'pointer' }} size={14} onClick={() => setAtualizacaoId(r.atualizacaoId ?? '')} />
                  </ElementoTooltipCmp>
                  }
                </Flex>
                {r.comentario && <Flex flexDir={'column'} mt={'.5rem'} padding={'.5rem 1rem'} bg='var(--c1)' border={'1px solid var(--AzulOpaco)'}>
                  <Text fontWeight={'700'} fontSize={'0.8135rem'}>Comentário</Text>
                  <Text fontSize={'0.875rem'}>{r.comentario}</Text>
                </Flex>}

                <Text my={'.5rem'} fontSize={'1.125rem'} fontWeight={'700'}>Comitê que realizou a calibração</Text>
                <Flex gap={'2rem'}>
                  {r.comite.map((c, i2) => {
                    return (
                      <Flex gap={'.325rem'} key={i2} alignItems={'center'}>
                        <Avatar size={'sm'} src={c.avatar} name={c.nome} />
                        <Flex flexDir={'column'}>
                          <Text fontSize={'.875rem'} fontWeight={'700'} lineHeight={'1.125rem'}>{c.nome}</Text>
                          <Text fontSize={'.875rem'} lineHeight={'.925rem'}>{c.complemento}</Text>
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>
          )
        })}
      </Flex>
      {!VisualizarTudo && model.comites.length > 2 &&
        <Text cursor={'pointer'} onClick={() => setVisualizarTudo(true)} fontWeight={'700'} fontSize={'.875rem'} textDecoration='underline' color={'var(--Azul)'}>Mostrar alterações anteriores </Text>
      }
      {parseJwt().adm === 'True' &&
        <Flex justifyContent={'flex-end'} gap={'1rem'} mb={'2rem'}>
          <ButtonCmp
            VarColor='Green2'
            leftIcon={<FaSave />}
            onClick={onOpenAlterarQuadrante}
          >Salvar</ButtonCmp>
        </Flex>}
    </Flex>
  )
}

import { ModalBody, Container, ModalFooter, ModalHeader } from './styles'
import { MdClose } from 'react-icons/md'
import { iEscala, iModalPadrao } from '../../../../../interfaces'
import { useState } from 'react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { Grid, GridItem, useToast } from '@chakra-ui/react'
import { BoxEscala } from '../../../../../components/BoxEscala'
import { appApi } from '../../../../../services/appApi'

interface IModal extends iModalPadrao {
  competenciaId: string
  questaoId?: string
  selecionadaId: string
  escalaPesquisa: string
  formId: string
  escalas: iEscala[]
}

export const ModalEscala: React.FC<IModal> = ({ OnCloseModal, isOpen, competenciaId, questaoId, formId, escalas, selecionadaId, escalaPesquisa }) => {
  if (!isOpen) {
    return null
  }
  const toast = useToast()
  const [EscalaId, setEscalaId] = useState<string>()

  function AtualizarEscala(): void {
    appApi.patch(`Formulario/${formId}/EscalaAlternativa?competenciaId=${competenciaId}&questaoId=${questaoId}&escalaId=${EscalaId}`)
      .then(res => {
        toast({
          title: 'Escala alterada com sucesso!',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        OnCloseModal()
      })
      .catch(err => console.log(err))
  }

  function DeleteEscala(): void {
    appApi.delete(`Formulario/${formId}/EscalaAlternativa?competenciaId=${competenciaId}&questaoId=${questaoId}`)
      .then(res => {
        toast({
          title: 'Escala alterada com sucesso!',
          duration: 4000,
          position: 'top',
          status: 'success'
        })
        OnCloseModal()
      })
      .catch(err => console.log(err))
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={OnCloseModal}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Container>
        <ModalHeader>
          <h3>Selecionar escala alternativa</h3>
          <MdClose
            onClick={() => OnCloseModal()}
            cursor={'pointer'}
            size={18}
          />
        </ModalHeader>

        <ModalBody>
          <Grid templateColumns={'repeat(2, 1fr)'} gap={'1rem'} maxH={'42rem'} overflowY={'auto'} pr={'1rem'}>
            {
              escalas.map((e: iEscala, i) => {
                if (e.fator !== 1) {
                  return (
                    <GridItem>
                      <BoxEscala
                        key={i}
                        Escala={e}
                        Selecionado={e.id === EscalaId}
                        jaSelecionada={selecionadaId}
                        escalaPesquisa={escalaPesquisa}
                        onClick={() => { if (e.id !== selecionadaId) { setEscalaId(e.id) } }}
                      />
                    </GridItem>
                  )
                } else {
                  return (<></>)
                }
              })
            }
          </Grid>
        </ModalBody>

        <ModalFooter>
          <ButtonCmp
            VarColor='c6'
            mr={'.5rem'}
            onClick={() => { OnCloseModal() }}
          >Voltar</ButtonCmp>
          <ButtonCmp
            VarColor='Green2'
            isDisabled={!EscalaId}
            onClick={() => { if (EscalaId === escalaPesquisa) { DeleteEscala() } else { AtualizarEscala() }; OnCloseModal() }}
          >Salvar</ButtonCmp>
        </ModalFooter>
      </Container>
    </Modal>
  )
}

import { Spinner } from '@chakra-ui/react'
import { CSSProperties } from 'react'
import { Container } from './styles'

interface BoxProps{
  styles?: CSSProperties
  margin?: string
  IsLoading?: boolean
}

export const WhiteBox: React.FC<BoxProps> = ({ children, margin, styles, IsLoading }) => {
  return (
    <>
      {!IsLoading && <Container margin={margin} style={styles}>
        {children}
      </Container>}
      {IsLoading &&
        <Container margin={margin} style={styles}>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            height={'11rem'}
            width='11rem'
            position={'absolute'}
            transform={'translate(-50%, -50%)'}
            left='42%'
            top='35%'
          />
        </Container>
      }
    </>
  )
}

import { Accordion, AccordionItem, AccordionButton, Flex, AccordionIcon, AccordionPanel } from '@chakra-ui/react'

interface iProps {
  title: string
  left?: boolean
  hideHr?: boolean
  isOpen?: boolean
}

export const AccordionContainer: React.FC<iProps> = ({ title, left, hideHr, children, isOpen }) => {
  return (
    <Accordion allowToggle w={'full'} index={isOpen ? 0 : undefined}>
      <AccordionItem>
        <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} mt={'.5rem'} px={'0'}>
          {!left && <hr style={{ borderTop: '1px solid var(--Azul)', flex: '1', opacity: hideHr ? '0' : '' }}></hr>}
          <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
            {title}
            <AccordionIcon />
          </Flex>
          {left && <hr style={{ borderTop: '1px solid var(--Azul)', flex: '1', opacity: hideHr ? '0' : '' }}></hr>}
        </AccordionButton>
        <AccordionPanel py={'0.25rem'} px={'0.25rem'}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, Box, FormLabel, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { ModalBase } from '../../../../../components/ModalBase'
import { MultiColaborador } from '../../../../../components/MultiColaborador'
import { TextAreaCmp } from '../../../../../components/TextareaCmp'
import { iNomeId, iPerfilColaborador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iProps {
  comiteAtivo: boolean
  isOpen: boolean
  colaborador: iPerfilColaborador
  onClose: () => void
  onAction: (comite: string[], comentario?: string,) => void
}

export const ModalAlterarQuadrante: React.FC<iProps> = ({ isOpen, comiteAtivo, onClose, onAction, colaborador }) => {
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Comite, setComite] = useState<string[]>([])
  const [Comentario, setComentario] = useState<string>()

  function obterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    obterColaboradores()
  }, [])

  return (
		<ModalBase Titulo={colaborador.avaliacaoNineBox ? 'Editar' : 'Salvar'} Width='40rem' isOpen={isOpen} onClose={onClose}>
			{!PropInvalida(colaborador.avaliacaoNineBox) && (
				<Flex gap={'1rem'} padding={'0 1rem 1rem 1rem'} flexDir={'column'}>
					<Flex >
						<Box as='div' fontWeight={'600'} fontSize={'14px'}>Você está tentando alterar o quadrante do colaborador <Box fontWeight={'900'} as='span'>{colaborador.nome}</Box>, desconsiderando assim as notas definidas para cada eixo. Deseja continuar?</Box>
					</Flex>

					<Flex flexDir={'column'}>
						<TextAreaCmp label='Deixe um comentário para registro da calibração' OnChange={(e) => setComentario(e)} placeholder='Comente aqui' size={'md'} height={'8.25rem'} />
					</Flex>

					{!comiteAtivo && <FormControl>
						<FormLabel>Participantes do comite</FormLabel>
						<MultiColaborador
							colaboradores={Colaboradores}
							onChoice={(e) => !PropInvalida(e) && setComite([...Comite, e])}
							selecionados={Comite}
							onDelete={(e) => setComite([...Comite.filter(r => r !== e)])}
						/>
					</FormControl>}
					<Flex justifyContent={'flex-end'} gap={'.5rem'}>
						<ButtonCmp
							VarColor='c6'
							onClick={onClose}>
							Cancelar
						</ButtonCmp>
						<ButtonCmp
							VarColor='Green2'
							leftIcon={<FaSave />}
							onClick={() => onAction(Comite, Comentario)}
						>Salvar</ButtonCmp>
					</Flex>
				</Flex>
			)}

			{PropInvalida(colaborador.avaliacaoNineBox) && (
				<Flex flexDir={'column'} gap={'1rem'} padding='0 1rem 1rem 1rem'>
					<Flex flexDir={'column'}>
						<TextAreaCmp label='Deixe um comentário para registro da calibração' OnChange={(e) => setComentario(e)} placeholder='Comente aqui' size={'md'} height={'8.25rem'} />
					</Flex>
					{!comiteAtivo && <FormControl>
						<FormLabel>Participantes do comite</FormLabel>
						<MultiColaborador
							colaboradores={Colaboradores}
							onChoice={(e) => !PropInvalida(e) && setComite([...Comite, e])}
							selecionados={Comite}
							onDelete={(e) => setComite([...Comite.filter(r => r !== e)])}
						/>
					</FormControl>}
					<Flex justifyContent={'flex-end'} gap={'1rem'} mb={'1rem'} p={'1rem'}>
						<ButtonCmp
							VarColor='c6'
							onClick={onClose}>
							Cancelar
						</ButtonCmp>
						<ButtonCmp
							VarColor='Green2'
							leftIcon={<FaSave />}
							onClick={() => onAction(Comite, Comentario)}
						>Salvar</ButtonCmp>
					</Flex>
				</Flex>
			)}
		</ModalBase>
  )
}

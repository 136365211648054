import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const ModalBody = styled(Column)`
padding: 1rem;
width: 61.563rem;
`

export const ModalHeader = styled(Flex)`
align-items:center;
justify-content: space-between;
border-bottom: 0.063rem solid var(--c4);
padding: 1rem;

svg{
    color:var(--Red);
    cursor:pointer;

    :hover{
      color:var(--Red-hover);
    }
}
`

export const InputLabel = styled(Column)<{marginBottom?: string}>`
margin-bottom: ${props => props.marginBottom};
label{
  font-family: 'Roboto','sans-serif';
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--a3);
}

textarea:focus {
  border: 0.063rem solid var(--v2);
}
`

export const ModalFooter = styled(Flex)`
justify-content: end;
gap: .5rem;
padding: 0 1rem 1rem 1rem;

a:first-child{
  margin-right: 1rem;
}
`

import { Avatar, Button, CircularProgress, CircularProgressLabel, Flex, Image, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react'
import { Container } from './styles'
import React, { useEffect, useState } from 'react'

import IconeLove from '../../../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../../../assets/IconeTriste.svg'

import { FaCheckCircle, FaClipboardList, FaEdit, FaEye, FaSearch, FaTasks, FaTrash } from 'react-icons/fa'
import { iColaboradorBase } from '../../../../../../interfaces'
import { useNavigate } from 'react-router-dom'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { parseJwt } from '../../../../../../services/token'
import { IoAlertCircle } from 'react-icons/io5'
import { ElementoTooltipCmp } from '../../../../../../components/ElementoTooltipCmp'
import { SearchCmp } from '../../../../../../components/SearchCmp'
import LoadingContainer from '../../../../../../components/LoadingContainer'
import { appApi } from '../../../../../../services/appApi'
import { Paginador } from '../../../../../../components/Paginador'
import { ModalDelete } from '../../../../../../components/ModalDelete'
import { SwitchCmp } from '../../../../../../components/SwitchCmp'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'

interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  origem: string
  totalAcoesRealizadas: number
  totalAcoes: number
  prazo: string
  notaUltimaAvaliacao?: number
  status: number
  totalAcoesAtrasadas: number
  avaliadoIsUserLogado?: boolean
}

interface iTabelaProps {
  onBaixarPDF: (id: string) => void
  slice?: number
  qtdElementos: number
  SendTexto: (texto: string) => void
  pendentes: string[]
  getQtd: (bool: boolean) => void
  filtros: string
}

export const Tabela: React.FC<iTabelaProps> = ({ pendentes, onBaixarPDF, qtdElementos, SendTexto, getQtd, filtros }) => {
  const nav = useNavigate()
  const [Texto, setTexto] = useState('')
  const [Dados, setDados] = useState<iDadoTabela[]>([])
  const [PaginaAtual, setPaginaAtual] = useState(0)
  const [Tamanho, setTamanho] = useState(6)
  const [IsLoading, setIsLoading] = useState(false)
  const [BuscarDesligado, setBuscarDesligado] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [IdAtual, setIdAtual] = useState<string>('')
  const toast = useToast()

  function onClose(): void {
    setModalDeleteIsOpen(false)
    setIdAtual('')
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setIdAtual(id)
  }

  function DeletarPDI(): void {
    appApi.delete(`PDI/${IdAtual}`).then(() => {
      toast({
        title: 'PDI removido com sucesso!',
        position: 'top-right',
        status: 'success',
        duration: 5000,
        isClosable: false
      })
      onClose()
      GetDados('')
    }).catch(err => err)
  }

  function GetDados(filtro: string): void {
    setIsLoading(true)
    appApi.get(`PDI/Dashboard/Corporativo/Tabela?pagina=${PaginaAtual}&busca=${Texto}&tamanho=${Tamanho}&buscarDesligados=${BuscarDesligado}&${filtro}`)
      .then(res => {
        if (res.data.length === 0 && PaginaAtual !== 0) {
          setPaginaAtual(0)
        }
        setDados(res.data)
        SendTexto(Texto)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function UltimaAvaliacao(status: number, nota?: number): any {
    if (status === 1) {
      return <Flex h={'full'} alignItems={'center'}><FaTasks color='var(--Azul)' size={20}/></Flex>
    } else if (status === 6) {
      return <Flex h={'full'} alignItems={'center'} fontWeight={'900'} color={'var(--Red)'}>Ação em atraso</Flex>
    }

    if (nota === 1) {
      return <Flex h={'full'} alignItems={'center'}><Image w={'2rem'} h={'2rem'} src={IconeTriste}/></Flex>
    } else if (nota === 2) {
      return <Flex h={'full'} alignItems={'center'}><Image w={'2rem'} h={'2rem'} src={IconeMagoado}/></Flex>
    } else if (nota === 3) {
      return <Flex h={'full'} alignItems={'center'}><Image w={'2rem'} h={'2rem'} src={IconeFeliz}/></Flex>
    } else if (nota === 4) {
      return <Flex h={'full'} alignItems={'center'}><Image w={'2rem'} h={'2rem'} src={IconePiscada}/></Flex>
    } else if (nota === 5) {
      return <Flex h={'full'} alignItems={'center'}><Image w={'2rem'} h={'2rem'} src={IconeLove}/></Flex>
    } else {
      return <Flex h={'full'} alignItems={'center'}>-</Flex>
    }
  }

  useEffect(() => {
    GetDados(filtros)
  }, [PaginaAtual, Tamanho, BuscarDesligado, filtros])

  return (
    <Flex flexDir={'column'} width={'100%'}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir o PDI deste colaborador?'
        onRequestClose={onClose}
        onConfirm={DeletarPDI}
      />
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Flex gap={'.5rem'} alignItems={'center'}><h3>PDI do time</h3>{pendentes?.length > 0 && <ElementoTooltipCmp bg={'var(--Red)'} label={'Há ações pendentes no(s) PDI(s) com sinal de alerta'}><Flex alignItems={'center'} color={'var(--Red)'} _hover={{ color: 'var(--Red-hover)' }}><IoAlertCircle size={26}/></Flex></ElementoTooltipCmp>}</Flex>
        <Flex gap={'1rem'} flex={1} justifyContent='end' alignItems={'center'}>
          <SwitchCmp
            OnChange={(e) => { setBuscarDesligado(e); getQtd(e) }}
            value={BuscarDesligado}
            legendaEsquerda={'Buscar colaboradores ativos'}
            legendaDireita={'Buscar colaboradores desligados'}
          />
          <SearchCmp
            EnterAction={() => GetDados('')}
            width={'30%'}
            OnChange={setTexto}
            placeholder='Pesquisa'
          />
          <ButtonCmp VarColor='Green2' leftIcon={<FaSearch/>} onClick={() => GetDados('')}>Buscar</ButtonCmp>
        </Flex>
      </Flex>
      <Paginador
          next={(e) => setPaginaAtual(e)}
          prev={(e) => setPaginaAtual(e)}
          tamanho={Tamanho}
          setTamanho={(e) => setTamanho(e)}
          opcoesTamanho={['6', '12']}
          paginaAtual={PaginaAtual}
          qtdElementos={qtdElementos}
          hideTop={true}>
        <LoadingContainer loading={IsLoading} linhas={14}>
          <Container>
              <thead>
                <tr>
                  <th>Colaborador</th>
                  <th>Origem</th>
                  <th>Quant. de ações</th>
                  <th>Atrasadas</th>
                  <th>Progresso</th>
                  <th>Prazo</th>
                  <th>Última avaliação</th>
                </tr>
              </thead>
              <tbody>
                {Dados?.map((e, i) => {
                  return (
                      <tr key={i}>
                        <th>
                          <Flex alignItems={'center'}>
                            <Avatar marginRight={'.75rem'} size={'sm'} src={e.colaborador.avatar} name={e.colaborador.nome} />
                            <Flex textAlign={'start'} flexDir={'column'}>
                              <strong>{e.colaborador.nome}</strong>
                              <span>{e.colaborador.nomeDepartamento}</span>
                            </Flex>
                          </Flex>
                        </th>
                        <th>{e.origem}</th>
                        <th>{e.totalAcoesRealizadas}/{e.totalAcoes}</th>
                        <th>{e.totalAcoesAtrasadas}</th>
                        <th>
                          <CircularProgress value={((e.totalAcoesRealizadas / e.totalAcoes) * 100)} color={getColor(((e.totalAcoesRealizadas / e.totalAcoes) * 100))} size={12} mt={'.25rem'}>
                            <CircularProgressLabel fontSize={'.75rem'} top={'45%'}>{((e.totalAcoesRealizadas / e.totalAcoes) * 100).toFixed(0)}%</CircularProgressLabel>
                          </CircularProgress>
                        </th>
                        <th>
                          {e.prazo}
                        </th>

                        <th>
                          <Flex justifyContent={'center'} h={'full'} alignItems={'center'}>
                            {
                              UltimaAvaliacao(e.status, e.notaUltimaAvaliacao)
                            }
                          </Flex>
                        </th>
                        <th>
                          <Flex justifyContent={'end'} gap={'.5rem'} marginRight={'1rem'}>
                          {(e.status === 0 || e.status === 1 || e.status === 5 || e.totalAcoesRealizadas === 0 || parseJwt().adm === 'True') &&
                            <Menu matchWidth={true} placement={'bottom-end'}>
                            <MenuButton _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} as={Button} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'} position={'relative'}><BsThreeDotsVertical />{pendentes.some(a => a === e.id) ? <IoAlertCircle style={{ position: 'absolute', top: '-20%', right: '-25%' }} color={'var(--Red)'} size={22}/> : <></>}</MenuButton>
                            <MenuList paddingY={'10px'} boxShadow={'0px 0px 5px rgb(0,0,0,0.3)'} borderRadius={'8px'} zIndex={999}>
                              {
                                (e.status === 0) && (
                                  <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaEdit />}>
                                    <span>Editar</span>
                                  </MenuItem>
                                )
                              }
                              {
                                (e.status === 1) && (
                                  <MenuItem onClick={() => nav(`/Rh/VisualizarPerfil/${e.colaborador.id}?pdiId=${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={pendentes.some(a => a === e.id) ? <IoAlertCircle color={'var(--Red)'} size={17} style={{ marginRight: '-.35rem' }}/> : <FaCheckCircle />}>
                                    <span>Aprovar</span>
                                  </MenuItem>
                                )
                              }
                              {
                                (e.status === 5) && (
                                  <>
                                    {!e.avaliadoIsUserLogado && <MenuItem onClick={() => nav(`/Desempenho/PDI/Avaliacao/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={pendentes.some(a => a === e.id) ? <IoAlertCircle color={'var(--Red)'} size={17} style={{ marginRight: '-.35rem' }}/> : <FaClipboardList />}>
                                      <span>Avaliar</span>
                                    </MenuItem>}
                                    <MenuItem onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaEye />}>
                                      <span>Visualizar</span>
                                    </MenuItem>
                                  </>
                                )
                              }
                              {
                                (e.totalAcoesRealizadas === 0 || parseJwt().adm === 'True') && (
                                  <MenuItem onClick={() => onOpenModalDelete(e.id)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaTrash />}>
                                    <span>Excluir</span>
                                  </MenuItem>
                                )
                              }
                              {/* {
                                (e.status === 5) && (
                                  <MenuItem onClick={() => onBaixarPDF(e.id)} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} icon={<FaFilePdf />}>
                                    <span>Baixar relatório PDF</span>
                                  </MenuItem>
                                )
                              } */}
                            </MenuList>
                          </Menu>
                          }
                          </Flex>
                        </th>
                      </tr>
                  )
                })}
              </tbody>
          </Container>
        </LoadingContainer>
      </Paginador>
    </Flex>
  )
}

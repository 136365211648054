import { Flex } from '@chakra-ui/react'
import Modal from 'react-modal'
import { ButtonCmp } from '../../../../components/ButtonCmp'

interface props {
  isOpen: boolean
  onRequestClose: () => void
}

export const ModalSucess: React.FC<props> = ({ isOpen, onRequestClose, children }) => {
  Modal.setAppElement('#root')
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
        <Flex flexDir={'column'} padding={'2rem 4rem'} gap={'1rem'}>
          <Flex textAlign={'center'} flexDir={'column'}>
            {children}
          </Flex>

            <Flex justifyContent={'center'}>
                <ButtonCmp VarColor='Green2' onClick={onRequestClose}>Fechar</ButtonCmp>
            </Flex>
        </Flex>
    </Modal>
  )
}

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import {
  Container,
  HeaderContainer,
  NpsContainer,
  SkillAnswer,
  SkillQuestion
} from './styles'

import { MetaButton } from '../../../components/Buttons/MetaButton'
import { iDemografia, iGrupo } from '../../../interfaces'
import { LayoutFull } from '../../Layouts/LayoutFull'
import { appApi } from '../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'

interface iRespostaDemografia {
  DemografiaId: string
  GrupoId: string
}

interface iRespostaNps {
  tipo: number
  notaResposta: number
}

interface iFormDemografia extends iDemografia {
  isError: boolean
}

interface iQuestaoNps {
  enunciado: string
  tipo: number
}

interface iFormQuestaoNps extends iQuestaoNps {
  isError: boolean
}

type LocationProps = {
  conviteId: string
}

export const RespostaNps: React.FC = () => {
  const nav = useNavigate()
  const { conviteId } = useParams<LocationProps>()
  const toast = useToast()

  const [Demografias, setDemografias] = useState<iFormDemografia[]>([])
  const [Questoes, setQuestoes] = useState<iFormQuestaoNps[]>([])

  const [RespostasDemografia, setRespostasDemografia] = useState<iRespostaDemografia[]>([])
  const [RespostasNps, setRespostasNps] = useState<iRespostaNps[]>([])

  const [isRespondido, setisRespondido] = useState(false)

  function getFormulario(): void {
    appApi.get(`NPS/Formulario/${conviteId as string}`)
      .then(resp => {
        setDemografias(resp.data.demografias)
        setQuestoes(resp.data.questoes)
      }).catch(err => {
        setisRespondido(true)
        console.log(err)
      })
  }

  function UpdateResposta(questaoId: string, respostaId: string): void {
    const respostas = [...RespostasDemografia]
    const pos = respostas.findIndex(e => e.DemografiaId === questaoId)
    if (pos !== -1) {
      respostas[pos].GrupoId = respostaId
      setRespostasDemografia(respostas)
    }
  }

  function SelResposta(questaoId: string, resposId: string): void {
    if (!isSelectDemografia(questaoId)) {
      setRespostasDemografia(oldArray => [...oldArray, { DemografiaId: questaoId, GrupoId: resposId }])
    } else {
      if (!isSelect(questaoId, resposId)) {
        UpdateResposta(questaoId, resposId)
      }
    }
  }

  function UpdateRespostaNps(tipo: number, resposta: number): void {
    const respostas = [...RespostasNps]
    const pos = respostas.findIndex(e => e.tipo === tipo)
    if (pos !== -1) {
      respostas[pos].notaResposta = resposta
      setRespostasNps(respostas)
    } else {
      respostas.push({
        notaResposta: resposta,
        tipo: tipo
      })
      setRespostasNps(respostas)
    }
  }

  const isSelect = (demoId: string, grupoId: string): boolean => RespostasDemografia.find(e => e.DemografiaId === demoId && e.GrupoId === grupoId) !== undefined
  const isSelectDemografia = (demId: string): boolean => RespostasDemografia.find(e => e.DemografiaId === demId) !== undefined
  const NpsisSelect = (tipo: number, resposId: number): boolean => RespostasNps.find(e => e.tipo === tipo && e.notaResposta === resposId) !== undefined

  function AddErrorStats(): void {
    const dems = [...Demografias]
    for (let i = 0; i < dems.length; i++) {
      const pos = RespostasDemografia.findIndex(e => e.DemografiaId === dems[i].id)
      if (pos === -1) {
        dems[i].isError = true
      } else if (dems[i].isError) {
        dems[i].isError = false
      }
    }
    setDemografias(dems)

    const quests = [...Questoes]
    for (let i = 0; i < quests.length; i++) {
      const pos = RespostasNps.findIndex(e => e.tipo === quests[i].tipo)
      if (pos === -1) {
        quests[i].isError = true
      } else if (quests[i].isError) {
        quests[i].isError = false
      }
    }

    setQuestoes(quests)
  }

  function postForm(): void {
    if (RespostasDemografia.length !== Demografias.length) {
      AddErrorStats()
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (RespostasNps.length !== Questoes.length) {
      AddErrorStats()
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      ConviteId: conviteId,
      NotaResposta: RespostasNps,
      ItensDemografiaId: RespostasDemografia.map((e) => {
        return e.GrupoId
      })
    }

    appApi.post('NPS/Responder', form).then(() => {
      setisRespondido(true)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <LayoutFull>
      <HeaderContainer>
        <h1>NPS e eNPS</h1>
        <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/assets/logoEmpresas/logoAh-novo.png' />
      </HeaderContainer>
      {
        (!isRespondido) && (
          <Container>
            {
              Demografias.map((e: iFormDemografia) => {
                return (
                  <SkillQuestion key={e.id}>
                    <div>
                      <h3>{e.nome}</h3>
                      {e.isError && <span>* resposta requerida</span>}
                    </div>
                    {
                      e.grupos.map((r: iGrupo, i2) => {
                        return (
                          <SkillAnswer
                            key={i2}
                            type="button"
                            isSelected={isSelect(e.id, r.id as string)}
                            onClick={() => SelResposta(e.id, r.id as string)}
                          >
                            <FaCheckCircle />
                            <span>{r.nome}</span>
                          </SkillAnswer>
                        )
                      })
                    }
                  </SkillQuestion>
                )
              })
            }

            <Flex display={'grid'} gap={'1rem 1rem'}>
              {
                Questoes.map((e, i) => {
                  return (
                    <Flex key={i} flexDirection={'column'}>
                      {e.isError &&
                        <span
                          style={{ color: 'var(--terc3)', marginBottom: '1rem' }}
                        >* resposta requerida</span>}
                      <NpsContainer>
                        <Flex>
                          <h4>{e.enunciado}</h4>
                        </Flex>
                        <Flex>
                          {
                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((r) => {
                              return (
                                <Flex
                                  key={r}
                                  marginRight={'1.5rem'}
                                  flexWrap={'wrap'}
                                  flexDirection={'column'}
                                  alignItems={'center'}
                                >
                                  <input onChange={() => UpdateRespostaNps(e.tipo, r)} checked={NpsisSelect(e.tipo, r)} type={'radio'} />
                                  <span>{r}</span>
                                </Flex>
                              )
                            })
                          }
                        </Flex>
                      </NpsContainer>
                    </Flex>
                  )
                })
              }
            </Flex>

            <Flex justifyContent={'center'} margin={'1rem 0rem'}>
              <MetaButton onClick={postForm} size='lg' bg='v2'>Enviar resposta</MetaButton>
            </Flex>
          </Container>
        )
      }

      {
        (isRespondido) && (
          <Flex background={'white'} height={'100%'}>
            <Flex padding={'3rem 3rem'} flexDirection={'column'}>
              <h3>Obrigado pela participação!</h3>
              <Text fontWeight={'400'} style={{ color: 'var(--c6)', marginTop: '1rem' }}> Participação contabilizada </Text>
              {
                (localStorage.getItem('token')) && (
                  <Flex marginTop={'1.5rem'}>
                    <Button
                      VarColor='c4'
                      size={'lg'}
                      onClick={() => nav('/')}
                    >Voltar</Button>
                  </Flex>
                )
              }
            </Flex>
          </Flex>
        )
      }

    </LayoutFull>
  )
}

export const Pesquisaimg: React.FC = () => {
  return (
    <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.834 24.2082C5.30161 24.2082 0 26.859 0 30.1252C0 32.776 3.47918 35.0126 8.28377 35.77V39.5923L13.0173 34.8588L8.28377 30.1252V33.3558C4.55607 32.6931 2.36679 31.1074 2.36679 30.1252C2.36679 28.8708 5.96431 26.575 11.834 26.575C17.7036 26.575 21.3011 28.8708 21.3011 30.1252C21.3011 30.989 19.5734 32.3618 16.5675 33.1192V35.5451C20.7449 34.6339 23.6679 32.5511 23.6679 30.1252C23.6679 26.859 18.3663 24.2082 11.834 24.2082Z" fill="url(#paint0_linear_726_14009)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0206 0C5.7981 0 0.765449 5.03265 0.765449 11.2551C0.765449 19.6965 12.0206 32.1575 12.0206 32.1575C12.0206 32.1575 23.2757 19.6965 23.2757 11.2551C23.2757 5.03265 18.243 0 12.0206 0ZM12.0208 15.2749C9.80195 15.2749 8.00113 13.4741 8.00113 11.2552C8.00113 9.03635 9.80195 7.23554 12.0208 7.23554C14.2397 7.23554 16.0405 9.03635 16.0405 11.2552C16.0405 13.4741 14.2397 15.2749 12.0208 15.2749Z" fill="url(#paint1_linear_726_14009)" />
      <defs>
        <linearGradient id="paint0_linear_726_14009" x1="0" y1="19.7962" x2="23.6679" y2="19.7962" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
        <linearGradient id="paint1_linear_726_14009" x1="0" y1="19.7962" x2="23.6679" y2="19.7962" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1961E0" />
          <stop offset="1" stopColor="#66046B" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const PesquisaimgWhite: React.FC = () => {
  return (
    <svg width="24" height="40" viewBox="0 0 24 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.834 24.2082C5.30161 24.2082 0 26.859 0 30.1252C0 32.776 3.47918 35.0126 8.28377 35.77V39.5923L13.0173 34.8588L8.28377 30.1252V33.3558C4.55607 32.6931 2.36679 31.1074 2.36679 30.1252C2.36679 28.8708 5.96431 26.575 11.834 26.575C17.7036 26.575 21.3011 28.8708 21.3011 30.1252C21.3011 30.989 19.5734 32.3618 16.5675 33.1192V35.5451C20.7449 34.6339 23.6679 32.5511 23.6679 30.1252C23.6679 26.859 18.3663 24.2082 11.834 24.2082Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0206 0C5.7981 0 0.765449 5.03265 0.765449 11.2551C0.765449 19.6965 12.0206 32.1575 12.0206 32.1575C12.0206 32.1575 23.2757 19.6965 23.2757 11.2551C23.2757 5.03265 18.243 0 12.0206 0ZM12.0208 15.2749C9.80195 15.2749 8.00113 13.4741 8.00113 11.2552C8.00113 9.03635 9.80195 7.23554 12.0208 7.23554C14.2397 7.23554 16.0405 9.03635 16.0405 11.2552C16.0405 13.4741 14.2397 15.2749 12.0208 15.2749Z" fill="white" />
    </svg>
  )
}

import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: 4px;
padding: 1rem;
display: flex;
flex-direction: column;
border: 2px solid var(--${props => props.theme});

ul{
    margin-left: 1.5rem;
    margin-top: 1rem;
    li:not(last-child){
        margin-bottom: .5rem;
    }
}
`

export const TipoPesquisa = styled.strong`
    display: flex;
    align-items: center;
    gap: .3rem;
    font-size: .9rem;
    cursor: pointer;
    :hover {
        filter: brightness(120%);
        text-decoration: underline;
    }

    svg {
        margin-top: .2rem;
    }
`

export const StatusContainer = styled.div`
display: flex;
align-items: center;
gap: .75rem;
hr{
    margin: 0 1rem;
    height: 100%;
}

div:last-child{

strong:nth-child(1){
color: var(--Orange);
}

strong:nth-child(2){
color: var(--Green2);
}

strong:nth-child(3){
color: var(--Azul);
}
}
`

export const ContainerStatusPesquisa = styled.div`
margin: 1rem 0;
display: flex;
div{
    flex-direction: column;
}

div:first-child{
    color: var(--Azul);
}

div:last-child{
    color: var(--Green2);
}


hr{
    margin: 0 1rem;
    width: .1rem;
}
`

export const ContainerStatusTurnOver = styled.div`
margin: 1rem 0;
display: flex;
align-items: center;
div{
    flex-direction: column;
}

h2{
    color: var(--Gray3);
    margin-right: 1rem;
}

div:last-child{
    span{
        font-weight: 500;
    }
    span:nth-child(1){
        color: var(--Azul);
        margin-bottom:.5rem;
    }

    span:nth-child(2){
        color: var(--v2);
        margin-bottom:.5rem;
    }

    span:nth-child(3){
        color: var(--terc3);
    }
}

`

export const LiLink = styled.li`
text-decoration: underline;
cursor: pointer;
`

import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { BackgroundCollapse, ColumnText, PreViewParticipante, RadiusLeft } from './styles'
import { useState } from 'react'
import { BoardBox } from '../BoardBox'
import { iProcessoOnboard } from '../../../../../interfaces'
import { Flex } from '@chakra-ui/react'
import { ButtonCmp } from '../../../../../components/ButtonCmp'

interface CardProps {
  processo: iProcessoOnboard
}

export const CardOnboard: React.FC<CardProps> = ({ processo }) => {
  const [isOpen, setisOpen] = useState(false)
  return (
        <BackgroundCollapse active={isOpen}>
            <PreViewParticipante>
                <Flex alignItems={'center'}>
                    <RadiusLeft theme={processo.trilha.cor} />
                    <ColumnText>
                        <h4>{processo.trilha.categoria}</h4>
                        <span>{processo.trilha.nome}</span>
                    </ColumnText>

                    <ColumnText>
                        <h4>Início</h4>
                        <span>{processo.dataInicio}</span>
                    </ColumnText>

                    <ColumnText>
                        <h4>Término</h4>
                        <span>{processo.dataFim}</span>
                    </ColumnText>

                </Flex>
                <Flex alignItems={'center'} marginRight='1rem'>
                    <ButtonCmp
                        margin='0rem 1rem 0rem 0rem'
                        VarColor='Rosa' size='sm'
                        rightIcon={!isOpen
                          ? <FaAngleDown className='IconRigth' />
                          : <FaAngleUp className='IconRigth' />}
                        onClick={() => setisOpen(!isOpen)}>Detalhes
                    </ButtonCmp>
                </Flex>
            </PreViewParticipante>
            <Flex flexDir={'column'} padding={'1rem 1rem 0rem 1rem'}>
                {
                    processo.etapas.map((e, i) => {
                      return (
                            <BoardBox
                                key={i}
                                etapa={e}
                                isLast={i === processo.etapas.length - 1}
                                disabledMenu={true}
                                onConcludeEtapa={() => { }}
                                onDeleteEtapa={() => { }}
                                onUpdateVisibility={() => { }}
                                onReabrirEtapa={() => { }}
                                onReload={() => {}}
                                processoId=''
                                podeAddEtapa={true}
                                colaboradorId=''
                                modalObservacao={() => { }}
                            />
                      )
                    })
                }
            </Flex>
        </BackgroundCollapse>
  )
}

import { FaPlus } from 'react-icons/fa'
import { Container } from './styles'
interface ICardCriarOnboard{
  OnOpenModal: () => void
}
export const CardCriarOnboard: React.FC<ICardCriarOnboard> = ({ OnOpenModal }) => {
  return (
    <>
    <Container>
        <div><FaPlus size={12}/></div>
        <div onClick={OnOpenModal}>
              <span>Adicionar nova etapa</span>
        </div>
      </Container>
    </>
  )
}

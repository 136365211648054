import { ChakraProvider } from '@chakra-ui/react'
import { GlobalStyle } from '../src/styles/global'
import { AuthProvider } from './contexts/AuthContext'
import { AppRoutes } from './Routes'
import { theme } from './styles/theme'

function App(): any {
  return (
    <ChakraProvider resetCSS={false} theme={theme}>
      <GlobalStyle />
      <AuthProvider>
          <AppRoutes />
      </AuthProvider>
    </ChakraProvider>
  )
}

export default App

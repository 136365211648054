import styled from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const Container = styled(Flex)`
justify-content: space-between;
border-radius: .25rem;
position: relative;
width: 100%;
background: var(--c1);
border: 0.125rem solid var(--AzulOpaco);
`

export const EndIcon = styled(Flex)`
align-items: start;
display: flex;
flex-direction: column;
svg{
    cursor: pointer;
    margin: .2rem .2rem 0 0;
}
`

export const ColumnText = styled(Column)`
margin-left: .5rem;

span{
font-weight: 400;
font-size: 0.875rem;
}
`

import { Box, Button, Flex, HStack, Text, VStack, Image, Tabs, TabList, Tab, TabPanels, TabPanel, CircularProgress, Tooltip } from '@chakra-ui/react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { useEffect, useState } from 'react'
import { FaArrowLeft, FaBriefcase, FaCheck, FaDownload, FaSync } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { IperfilDto } from '../TabelaPerfis'

import './styles.css'
import { appApi } from '../../../../services/appApi'
import { PropInvalida } from '../../../../Utils/Helper'
import { numberFormatter } from '../../Dashboard'

export const PerfilPi: React.FC = () => {
  const navigate = useNavigate()
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const { perfilId } = useParams<{ perfilId: string }>()
  // const { pathname } = useLocation()
  const [IsLoading, setIsLoading] = useState(true)
  const [BodyIsLoading, setBodyIsLoading] = useState(true)
  const [PdfUrl, setPdfUrl] = useState('')
  const [Relatorio, setRelatorio] = useState('Relatório comportamental')

  const onDownload = (): void => {
    const link = document.createElement('a')
    link.download = `${Relatorio}.pdf`
    link.href = PdfUrl
    link.click()
  }

  const [Model, setModel] = useState<IperfilDto>({
    perfil: {
      dataPesquisa: '',
      nome: '',
      perfil: '',
      id: '',
      urlImgPerfil: ''

    },
    outrosCargos: []
  })

  useEffect(() => {
    if (PropInvalida(perfilId)) {
      return
    }
    setBodyIsLoading(true)
    appApi.get(`AssessmentPi/Assessment/${perfilId ?? ''}`).then(res => {
      if (res.status === 200) {
        const data: IperfilDto = res.data
        setModel(data)
        setPdfUrl(data.perfil.urlPdfPerfil ?? '')
        setBodyIsLoading(false)
        console.log(data)
        console.log('data entrando')
      }
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      })
  }, [perfilId])
  useEffect(() => {
    if (PropInvalida(Model)) {
      return
    }
    setIsLoading(false)
  }, [Model])

  function definirRelatorio(value: string): void {
    switch (value) {
      case 'perfil':
        setIsLoading(true)
        setRelatorio('Relatório comportamental')
        setPdfUrl(Model.perfil.urlPdfPerfil ?? '')
        setIsLoading(false)
        break
      case 'quadro':
        setIsLoading(true)
        setRelatorio('Quadro de desenvolvimento pessoal')
        setPdfUrl(Model.perfil.urlPdfQuadroPessoal ?? '')
        setIsLoading(false)
        break
      case 'gestores':
        setIsLoading(true)
        setRelatorio('Quadro de desenvolvimento gestores')
        setPdfUrl(Model.perfil.urlPdfQuadroGestores ?? '')
        setIsLoading(false)
        break
      case 'placard':
        setIsLoading(true)
        setRelatorio('Placard')
        setPdfUrl(Model.perfil.urlPdfPlacard ?? '')
        setIsLoading(false)
        break

      default:
        setIsLoading(true)
        setRelatorio('Perfil comportamental')
        setPdfUrl(Model.perfil.urlPdfPerfil ?? '')
        setIsLoading(false)
        break
    }
  }

  const [numPages, setNumPages] = useState(1)
  const [page, setPage] = useState(1)

  function onDocumentLoadSuccess(resto: any): any {
    console.log(resto)
    const { numPages } = resto
    setNumPages(numPages)
  }

  return <Body isLoading={BodyIsLoading}>

    <Flex direction={'column'} justify='center'>
      <Flex width='70rem' maxWidth='90vw' justifyContent={'space-between'}>

        <Text fontWeight='medium' mb='1rem' fontSize='1.8rem' >{'Assessment PI®'}</Text>
        {IsLoading && (<CircularProgress isIndeterminate color='green.300' />)}
      </Flex>
      <Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>

        <Flex justifyContent={'space-between'} p='1.5rem' alignItems='start'>
          <HStack>

<Flex direction='column'>

            <Text fontWeight='medium' fontSize='1.8rem' maxWidth='450px' >{Model.perfil.nome}</Text>
            <Flex alignContent='center'>
              <Image src={Model.perfil.urlImgPerfil} width='230px' fit={'scale-down'} />
              <Text mt={'1rem'}>:{Model.perfil.perfil}</Text>
            </Flex>
</Flex>
            <Flex alignContent='center' direction={'column'}>
              <Image src={Model.perfil.urlImgSelo} width='80px' fit={'scale-down'} />
              <Text fontWeight={'medium'} mt={'1rem'}>{Model.perfil.selo}</Text>
            </Flex>
          </HStack>

          <HStack spacing={4}>
            {/* <Button leftIcon={<FaEnvelope size='.8rem'/>}
    onClick={() => navigate('/AssessmentPi/Dashboard')}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >Enviar avaliação</Button> */}
            <Button leftIcon={<FaArrowLeft size='.8rem' />}
              onClick={() => navigate(-1)}
              bg='gray.300' width='10rem' height='2.5rem' size='xs' >Voltar</Button>
          </HStack>
        </Flex>

        <Flex justifyContent='space-between' px='1rem' gap={'2rem'}>
          <Flex border={'2px'} borderColor={'var(--Green2)'} color={'var(--Green2)'} flex='1' borderRadius='2xl'
            flexDir={'column'} justifyContent={'center'}>

            <VStack>

              <Text>Quantidade de cargos aderentes</Text>
              <HStack>
                <FaCheck size={'2.5rem'} />
                <Text fontSize={'5xl'}>{Model.outrosCargos.filter(e => !e.adaptativo).length}</Text>
              </HStack>
            </VStack>

          </Flex>

          <Flex flex='1' border={'2px'} borderColor={'var(--Azul)'} color={'var(--Azul)'} borderRadius='2xl'
            flexDir={'column'} justifyContent={'center'}>

            <VStack>

              <Text>Quantidade de cargos flexibilizados</Text>
              <HStack>
                <FaSync size={'2.5rem'} />
                <Text fontSize={'5xl'}>{Model.outrosCargos.filter(e => e.adaptativo).length}</Text>
              </HStack>
            </VStack>

          </Flex>

        </Flex>

        <Box bgColor='white'>

          <Tabs mt={'1.5rem'} variant='enclosed-colored' borderRadius={'2xl'}>
            <TabList borderRadius={'2xl'}>
              <Tab color='gray.600'>Perfil</Tab>
              <Tab color='gray.600'>Cargos</Tab>
            </TabList>
            <TabPanels bgColor={'#fffDfb'} width={'100%'}>
              <TabPanel >
                <Flex>
                  <Flex direction={'column'}>
                    <Text m='1.5rem' fontWeight='bold' fontSize='1.2rem' >{Relatorio}</Text>

                    <HStack>
                      <Button
                        onClick={() => {
                          if (page > 1) { setPage(page - 1) }
                        }}
                        color={'gray.700'}>
                        {'<<'}
                      </Button>
                      <Text fontSize={'xl'} fontWeight='semibold' m='1'>{page} / {numPages}</Text>
                      <Button
                        onClick={() => {
                          if (page < numPages) { setPage(page + 1) }
                        }}
                        color={'gray.700'}>
                        {'>>'}
                      </Button>
                      <Tooltip hasArrow label={`Baixar PDF do ${Relatorio}`} bg='green.300' color='black'>

                        <Button
                          onClick={onDownload}
                          color={'blue.300'}>
                          <FaDownload />
                        </Button>
                      </Tooltip>

                    </HStack>
                    {!IsLoading && !PropInvalida(PdfUrl) && (<Document loading={<CircularProgress isIndeterminate color='green.300' width={'20rem'} />}
                      file={PdfUrl ?? ''}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className={'react-pdf__Page__canvas'}

                    >
                      <Page
                        scale={1}
                        key={`page_${1}`}
                        pageNumber={page}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                      />
                      {/* {Array.from(new Array(numPages), (el, index) => (
         <Page
         key={`page_${index + 1}`}
         pageNumber={index + 1}
         renderTextLayer={false}
         renderAnnotationLayer={false}
         />
       ))} */}

                    </Document>)}
                  </Flex>
                  <Flex direction={'column'} gap='.5rem' width={'100%'} alignItems='end'>

                    <Button
                      onClick={() => definirRelatorio('perfil')}
                      bg={Relatorio === 'Relatório comportamental' ? 'var(--Rosa)' : 'white'}
                      color={Relatorio !== 'Relatório comportamental' ? 'var(--Rosa)' : 'white'} borderColor='var(--Rosa)'
                      border={'2px'}
                      width='14rem' height='2.5rem' size='xs' >Relatório comportamental</Button>
                    <Button
                      onClick={() => definirRelatorio('quadro')}
                      bg={Relatorio === 'Quadro de desenvolvimento pessoal' ? 'var(--Rosa)' : 'white'}
                      color={Relatorio !== 'Quadro de desenvolvimento pessoal' ? 'var(--Rosa)' : 'white'} borderColor='var(--Rosa)'
                      border={'2px'}
                      width='14rem' height='2.5rem' size='xs' >Quadro de desenvolvimento pessoal</Button>

                    <Button
                      onClick={() => definirRelatorio('placard')}
                      bg={Relatorio === 'Placard' ? 'var(--Rosa)' : 'white'}
                      color={Relatorio !== 'Placard' ? 'var(--Rosa)' : 'white'} borderColor='var(--Rosa)'
                      border={'2px'}
                      width='14rem' height='2.5rem' size='xs' >Placard</Button>
                    <Button
                      onClick={() => definirRelatorio('gestores')}
                      bg={Relatorio === 'Quadro de desenvolvimento gestores' ? 'var(--Rosa)' : 'white'}
                      color={Relatorio !== 'Quadro de desenvolvimento gestores' ? 'var(--Rosa)' : 'white'} borderColor='var(--Rosa)'
                      border={'2px'}
                      width='14rem' height='2.5rem' size='xs' >Quadro de desenvolvimento gestores</Button>

                  </Flex>

                </Flex>

              </TabPanel>
              <TabPanel >
              {Model.outrosCargos.filter(e => !e.adaptativo).length > 0 &&
                <>
                  <Text m='1rem' fontSize={'xl'} fontWeight={'bold'}>Cargos em que {Model.perfil.nome} é aderente</Text>
                  <Box m='1rem' p='1rem' border={'1px'} borderRadius='lg' borderColor={'gray.300'}>
                    <Flex justifyContent={'space-between'}>
                      <Text textAlign={'center'} width={'20%'}>Cargo</Text>
                      <Text textAlign={'center'} width={'15%'}>Pessoas aderentes</Text>
                      <Text textAlign={'center'} width={'20%'}>Perfis aderentes</Text>
                      <Text textAlign={'center'} width={'15%'}>Pessoas flexibilizadas</Text>
                      <Text textAlign={'center'} width={'20%'}>Perfis flexibilizados</Text>
                    </Flex>
                    {Model.outrosCargos.filter(e => !e.adaptativo).map((c, i) => <Box p='0.5rem' bgColor={i % 2 === 0 ? 'gray.100' : 'white'} width={'100%'}>
                      <Flex justifyContent={'space-between'}>
                        <Text width={'20%'}><FaBriefcase /> {c.nome}</Text>
                        <Text textAlign={'center'} width={'15%'}>{numberFormatter.format(c.numAderentes)}</Text>
                        <Text textAlign={'center'} width={'20%'}>{c.perfisAderentes.map((p, i) => i === c.perfisAderentes.length - 1 ? p : `${p}, `)}</Text>
                        <Text textAlign={'center'} width={'15%'}>{c.perfisAdaptativos.length > 0 ? numberFormatter.format(c.numAdaptativos) : ''}</Text>
                        <Text textAlign={'center'} width={'20%'}>{c.perfisAdaptativos.map((p, i) => i === c.perfisAdaptativos.length - 1 ? p : `${p}, `)}</Text>

                      </Flex>
                    </Box>)}
                  </Box>
                </>
                }

                {Model.outrosCargos.filter(e => e.adaptativo).length > 0 &&
                <>
                  <Text m='1rem' fontSize={'xl'} fontWeight={'bold'}>Cargos em que {Model.perfil.nome} pode ser flexibilizado</Text>
                  <Box m='1rem' p='1rem' border={'1px'} borderRadius='lg' borderColor={'gray.300'}>
                    <Flex justifyContent={'space-between'}>
                      <Text textAlign={'center'} width={'20%'}>Cargo</Text>
                      <Text textAlign={'center'} width={'15%'}>Pessoas aderentes</Text>
                      <Text textAlign={'center'} width={'20%'}>Perfis aderentes</Text>
                      <Text textAlign={'center'} width={'15%'}>Pessoas adaptativas</Text>
                      <Text textAlign={'center'} width={'20%'}>Perfis adaptativos</Text>
                    </Flex>
                    {Model.outrosCargos.filter(e => e.adaptativo).map((c, i) => <Box p='0.5rem' bgColor={i % 2 === 0 ? 'gray.100' : 'white'} width={'100%'}>
                      <Flex justifyContent={'space-between'}>
                        <Text width={'20%'}><FaBriefcase /> {c.nome}</Text>
                        <Text textAlign={'center'} width={'15%'}>{numberFormatter.format(c.numAderentes)}</Text>
                        <Text textAlign={'center'} width={'20%'}>{c.perfisAderentes.map((p, i) => i === c.perfisAderentes.length - 1 ? p : `${p}, `)}</Text>
                        <Text textAlign={'center'} width={'15%'}>{c.perfisAdaptativos.length > 0 ? numberFormatter.format(c.numAdaptativos) : ''}</Text>
                        <Text textAlign={'center'} width={'20%'}>{c.perfisAdaptativos.map((p, i) => i === c.perfisAdaptativos.length - 1 ? p : `${p}, `)}</Text>

                      </Flex>
                    </Box>)}
                  </Box>
                </>}
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Box>
        <Flex justifyContent={'end'} m='1.5rem'>

        </Flex>
      </Box>
    </Flex>
  </Body>
}
